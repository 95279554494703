import { useSelection } from "@tm/utils"
import { Loader, Box } from "@tm/components"
import { CisVoucherType } from "@tm/models"
import { useParams } from "react-router"
import InvoiceTable from "./components/InvoiceTable"
import InvoiceDetails from "./components/InvoiceDetails"
import { CisDisplayMode } from "../../business/model"
import { useFilterStore } from "../../business/state"
import { useInvoices } from "../../data/hooks/useInvoices"
import { NoResultHint } from "../_shared/NoResultHint"
import { RowStackWithPadding, VerticalDivider } from "../_shared/StyledComponents"
import { ErrorMessage } from "../_shared/ErrorMessage"

type Props = {
    cisDisplayMode?: CisDisplayMode
}

type RouteProps = {
    id?: string
}

const INVOICES_VOUCHER_TYPE_ID = CisVoucherType.Offers
const stableArrayFunction = () => []
export default function InvoicesComponent({ cisDisplayMode }: Props) {
    const { selectedIds: openedConnectedVouchers, toggleSelected: toggleOpenedConnectedVouchers } = useSelection(stableArrayFunction)
    const matchParams = useParams<RouteProps>()

    const filters = useFilterStore((state) => state.filters)
    const { invoices, invoicesData, invoicesLoading, invoicesError, invoicesLoadNextPage } = useInvoices(filters?.invoices)

    function loadNextPage() {
        invoicesLoadNextPage && invoicesLoadNextPage()
    }

    let content = null
    if (invoicesLoading) {
        content = <Loader />
    } else if (!invoices?.length) {
        content = <NoResultHint />
    } else if (invoicesError) {
        content = <ErrorMessage />
    } else if (invoices?.length && invoicesData) {
        content = (
            <RowStackWithPadding divider={<VerticalDivider />}>
                <Box width={matchParams.id ? "440px" : "100%"} display="flex" overflow="hidden">
                    <InvoiceTable
                        key="InvoiceTable"
                        variant={matchParams.id ? "small" : "normal"}
                        voucherTypeId={INVOICES_VOUCHER_TYPE_ID}
                        invoices={invoicesData}
                        compact={!!matchParams.id}
                        openedConnectedVouchers={openedConnectedVouchers}
                        selectedInvoiceId={matchParams.id || undefined}
                        onExpandRowClick={toggleOpenedConnectedVouchers}
                        loadNextPage={loadNextPage}
                    />
                </Box>
                {!!matchParams.id && (
                    <InvoiceDetails
                        key="InvoiceDetails"
                        voucherTypeId={INVOICES_VOUCHER_TYPE_ID}
                        cisDisplayMode={cisDisplayMode}
                        endOfList={!invoicesLoadNextPage}
                        invoices={invoicesData}
                        selectedInvoiceId={matchParams.id}
                    />
                )}
            </RowStackWithPadding>
        )
    }

    return (
        <Box display="flex" px={1.5} flex={1}>
            {content}
        </Box>
    )
}
