import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createQueryString, parseQueryString, renderRoute } from "@tm/utils"

export const useDetailsInModal = (match: any) => {
    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
    const queryParams = parseQueryString(location.search)

    const handleDetailsPage = (quantity: number, params: any, detailsModalRoute: string) => {
        queryParams.initialQuantity = quantity
        Morpheus.showView("1", renderRoute(detailsModalRoute!, { ...match.params, ...params }) + createQueryString(queryParams))
    }

    return { articleDetailsInModal, handleDetailsPage }
}
