import { ETyresCarType, TyreFilter } from "@tm/models"
import { isArray } from "lodash"
import { createBaseQuery } from "../../../../data/helpers"
import { MainState } from "../../../main"
import { Statics } from "../../../../data/statics"
import { AttributeFiltersRequest } from "../../../../data/repositories/model"
import { ListState } from "../model"

export function createAttributeFiltersRequest(
    state: MainState,
    articleListCount?: number,
    searchLevel?: number
): AttributeFiltersRequest | undefined {
    const {
        searchValue,
        lastSearch: { byMatchcode, carType },
        selectedFilters: { manufacturer },
    } = state.list

    const selectedCriteria = getSelectedCriteria(state.list)

    const selectedSuppliers = manufacturer?.filter((x) => x !== undefined) ?? []

    const { width, height, inch } = state.summary.selectedFilters
    const matchCodeSize = byMatchcode ? searchValue : (createBaseQuery(width?.value, height?.value, inch?.value) ?? searchValue)

    return {
        query: matchCodeSize.trim(),
        searchLevel: searchLevel ?? 2,
        carTypes: carType ?? Statics.getCarTypes().map((x) => +x.query as ETyresCarType),
        width: width?.value ?? "",
        height: height?.value ?? "",
        inch: inch?.value ?? "",
        articleListCount,
        selectedCriteria,
        selectedSuppliers,
    }
}

function getSelectedCriteria(state: ListState): TyreFilter[] {
    const { selectedFilters, filters } = state

    const selectedCriteria = Object.keys(selectedFilters)
        .filter((filterKey) => filterKey !== "availability" && filterKey !== "manufacturer")
        .map((filterKey) => {
            if (isArray(selectedFilters[filterKey])) {
                return selectedFilters[filterKey] as TyreFilter[]
            }

            const filter = selectedFilters[filterKey] as TyreFilter
            return !filter ? undefined : [filter]
        })
        .flatMap((x) => x)
        .filter((x) => !!x) as TyreFilter[]

    const additionalCriteria: TyreFilter[] = []

    selectedCriteria.forEach((groupCriteria) => {
        switch (groupCriteria.group) {
            case "fuelEfficiency":
            case "wetGripClass":
            case "externalRolling":
                const criteria = filters[groupCriteria.group]?.filter((x) => x.query < groupCriteria.query)
                if (criteria?.length) {
                    additionalCriteria.push(...criteria)
                }
                break

            default:
                break
        }
    })

    return selectedCriteria.concat(additionalCriteria)
}
