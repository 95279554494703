import { FC, ReactNode, useState, useEffect } from "react"
import { Box, Button, Icon, Stack, Tooltip, Typography } from "@tm/components"
import { useStyle, useUser, useWorkTask, useCostEstimationModuleParameters } from "@tm/context-distribution"
import { Collapsible, SelectionList, SelectionListItem } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ECounterType, SearchTreeProductGroup, VehicleType } from "@tm/models"
import { TmaHelper, concat } from "@tm/utils"

import { Predictive } from "../../../data/model"
import { PredictiveRepairTime } from "../../../data/model/predictive"
import { getBundleParams } from "../../../utils"
import AddToCostEstimationButton from "../../widget/components/add-to-cost-estimation-button"

import {
    ButtonContainer,
    CARTELLIGENCE_LOGO,
    DataProviderBox,
    DataProviderLogo,
    FooterBox,
    FooterLoader,
    NoDataBox,
    NodeGroupBox,
    NodesBox,
    OverlayLoader,
    RepairTimesBox,
    SAD_CAR,
    WrapperBox,
} from "./StyledComponents"

type Props = {
    canRequestCalculation?: boolean
    className?: string
    description?: ReactNode
    loading: boolean
    predictiveNodes?: Predictive.GetPredictiveNodesResponse
    topNodes?: Predictive.GetTopNodesResponse
    onAddToRepairTimeToCostEstimation(repairTime: PredictiveRepairTime): void
    onApplyProductGroups(productGroups: Array<SearchTreeProductGroup>): void
    onRemoveRepairTimeFromCostEstimation(itemId: string): void
    onRequestCalculation?(productGroups: Array<SearchTreeProductGroup>): void
}

function mapPredictiveNodeToSelectionListItem(node: Predictive.PredictiveProductGroup): SelectionListItem {
    return {
        query: node.id.toString(),
        name: concat(" ", node.name, node.priority != undefined && ` (${node.priority}%)`),
    }
}

function mapSelectionListItemToProductGroup(item: SelectionListItem): SearchTreeProductGroup {
    return {
        id: parseInt(item.query),
        name: item.name.replace(/ \(\d{1,3}%\)$/, ""), // Remove percentage if available
    }
}

type NodeGroupType = "topProductGroups" | "topRepairTimes" | "predictiveProductGroups" | "predictiveRepairTimes"

export const Component: FC<Props> = ({
    canRequestCalculation,
    className,
    description,
    loading,
    predictiveNodes,
    topNodes,
    onAddToRepairTimeToCostEstimation,
    onApplyProductGroups,
    onRemoveRepairTimeFromCostEstimation,
    onRequestCalculation,
}: Props) => {
    const localization = useLocalization()
    const { translate, translateText, number } = localization
    const { workTask } = useWorkTask() ?? {}
    const { userSettings } = useUser() ?? {}
    const { hasCostEstimation } = useCostEstimationModuleParameters()

    const [selectedNodes, setSelectedNodes] = useState<Array<SelectionListItem>>([])
    const [openedNodeGroups, setOpenedNodeGroups] = useState<Partial<Record<NodeGroupType, boolean>>>({})

    useEffect(() => {
        const hasPredictiveNodes = !!predictiveNodes?.productGroups.length || !!predictiveNodes?.repairTimes.length

        setOpenedNodeGroups({
            topProductGroups: !hasPredictiveNodes,
            topRepairTimes: !hasPredictiveNodes,
            predictiveProductGroups: hasPredictiveNodes,
            predictiveRepairTimes: hasPredictiveNodes,
        })
    }, [predictiveNodes])

    function handleSelectNode(items: Array<SelectionListItem>) {
        setSelectedNodes(items)
    }

    function handleApplyNodes() {
        if (!selectedNodes.length) {
            return
        }
        onApplyProductGroups(selectedNodes.map(mapSelectionListItemToProductGroup))
        TmaHelper.GeneralCountEvent.Call(ECounterType.PartsIndicatorApply)
    }

    function handleRequestCalculation() {
        if (!selectedNodes.length || !canRequestCalculation) {
            return
        }
        onRequestCalculation?.(selectedNodes.map(mapSelectionListItemToProductGroup))
        TmaHelper.GeneralCountEvent.Call(ECounterType.PartsIndicatorCalculate)
    }

    function handleNodeGroupToggle(type: NodeGroupType, isOpened: boolean) {
        switch (type) {
            case "topProductGroups":
            case "topRepairTimes":
                setOpenedNodeGroups({
                    topProductGroups: isOpened,
                    topRepairTimes: isOpened,
                    predictiveProductGroups: !isOpened,
                    predictiveRepairTimes: !isOpened,
                })
                break
            case "predictiveProductGroups":
            case "predictiveRepairTimes":
                setOpenedNodeGroups({
                    topProductGroups: !isOpened,
                    topRepairTimes: !isOpened,
                    predictiveProductGroups: isOpened,
                    predictiveRepairTimes: isOpened,
                })
                break
        }
    }

    function renderDescription() {
        if (!description) {
            return
        }

        return <Typography marginTop="0.5em">{description}</Typography>
    }

    function renderProductGroups(productGroups: Array<Predictive.PredictiveProductGroup> | undefined) {
        if (!productGroups?.length) {
            return (
                <Box className={style.productGroups}>
                    <Typography>{translate(12410)}</Typography>
                </Box>
            )
        }

        return (
            <SelectionList
                className={style.productGroups}
                groups={[{ priority: "high", items: productGroups.map(mapPredictiveNodeToSelectionListItem) }]}
                selected={selectedNodes}
                onChange={handleSelectNode}
                size="m"
                localization={localization}
            />
        )
    }

    function renderRepairTimes(repairTimes: Array<Predictive.PredictiveRepairTime> | undefined) {
        if (!repairTimes?.length) {
            return (
                <RepairTimesBox>
                    <Typography>{translate(12411)}</Typography>
                </RepairTimesBox>
            )
        }

        return (
            <RepairTimesBox>
                {repairTimes.map((x, idx) => (
                    <Stack direction="row" key={idx} alignItems="center" margin="0.25em 0">
                        <Typography sx={{ flex: 1 }}>{x.description}</Typography>
                        <Typography margin="0 1em">
                            {concat(" ", number(x.time, 1), userSettings?.repairTimeOptions?.division == 1 && translateText(12412))}
                        </Typography>
                        {workTask && hasCostEstimation && (
                            <AddToCostEstimationButton
                                repairTime={x}
                                workTaskId={workTask.id}
                                onAdd={onAddToRepairTimeToCostEstimation}
                                onRemove={onRemoveRepairTimeFromCostEstimation}
                            />
                        )}
                    </Stack>
                ))}
            </RepairTimesBox>
        )
    }

    function getNodeGroupTitle(type: NodeGroupType): string {
        switch (type) {
            case "topProductGroups":
                return concat(" ", translateText(12414), translateText(755))
            case "topRepairTimes":
                return concat(" ", translateText(12414), translateText(83))
            case "predictiveProductGroups":
                return concat(" ", translateText(1630), translateText(755))
            case "predictiveRepairTimes":
                return concat(" ", translateText(1630), translateText(83))
        }
    }

    function renderNodeGroupContent(type: NodeGroupType): ReactNode {
        switch (type) {
            case "topProductGroups":
                return renderProductGroups(topNodes?.productGroups)
            case "topRepairTimes":
                return renderRepairTimes(topNodes?.repairTimes)
            case "predictiveProductGroups":
                return renderProductGroups(predictiveNodes?.productGroups)
            case "predictiveRepairTimes":
                return renderRepairTimes(predictiveNodes?.repairTimes)
        }
    }

    function renderNodeGroup(type: NodeGroupType) {
        switch (type) {
            case "topProductGroups":
            case "topRepairTimes":
                if (!topNodes?.productGroups.length && !topNodes?.repairTimes.length) {
                    return
                }

                break
            case "predictiveProductGroups":
            case "predictiveRepairTimes":
                if (!predictiveNodes?.productGroups.length && !predictiveNodes?.repairTimes.length) {
                    return
                }

                break
        }

        return (
            <Collapsible
                name={getNodeGroupTitle(type)}
                initiallyOpened={openedNodeGroups[type]}
                onChangeOpen={handleNodeGroupToggle.bind(undefined, type)}
            >
                {renderNodeGroupContent(type)}
            </Collapsible>
        )
    }

    function renderNodes() {
        if (!topNodes && !predictiveNodes) {
            return
        }

        if (
            !topNodes?.productGroups.length &&
            !topNodes?.repairTimes.length &&
            !predictiveNodes?.productGroups.length &&
            !predictiveNodes?.repairTimes.length
        ) {
            if (loading) {
                return
            }

            return (
                <NoDataBox>
                    {SAD_CAR}
                    <Typography variant="h3">{translate(216)}</Typography>
                </NoDataBox>
            )
        }

        return (
            <NodesBox>
                <NodeGroupBox>
                    {renderNodeGroup("predictiveProductGroups")}
                    {renderNodeGroup("topProductGroups")}
                </NodeGroupBox>
                <NodeGroupBox>
                    {renderNodeGroup("predictiveRepairTimes")}
                    {renderNodeGroup("topRepairTimes")}
                </NodeGroupBox>
            </NodesBox>
        )
    }

    function renderProductGroupActions() {
        if (!topNodes?.productGroups.length && !predictiveNodes?.productGroups.length) {
            return
        }

        const maxCount = getBundleParams().maxSelectedCategoriesForFastCalculation || 0
        const isDisabledBySelectedItems = maxCount !== 0 ? selectedNodes?.length > maxCount : false

        return (
            <ButtonContainer>
                <Tooltip title={translateText(1041)} enterDelay={1000}>
                    <Box>
                        <Button startIcon={<Icon name="check" />} disabled={!selectedNodes.length} onClick={handleApplyNodes}>
                            {translate(1041)}
                        </Button>
                    </Box>
                </Tooltip>
                {
                    // If the link to the service calculator if needed
                    onRequestCalculation && workTask?.vehicle?.vehicleType === VehicleType.PassengerCar && (
                        <Tooltip
                            title={isDisabledBySelectedItems ? translateText(13025).replace("{0}", maxCount.toString()) : translateText(1042)}
                            enterDelay={isDisabledBySelectedItems ? 0 : 1000}
                        >
                            <Box>
                                <Button
                                    startIcon={<Icon name="fastclick" />}
                                    variant="outlined"
                                    disabled={!canRequestCalculation || !selectedNodes.length || isDisabledBySelectedItems}
                                    onClick={handleRequestCalculation}
                                >
                                    {translate(1042)}
                                </Button>
                            </Box>
                        </Tooltip>
                    )
                }
            </ButtonContainer>
        )
    }

    function renderDataProvider() {
        return (
            <DataProviderBox>
                <Typography variant="label" sx={{ textTransform: "uppercase" }}>
                    {translate(12415)}
                </Typography>
                <DataProviderLogo href="https://cartelligence.eu/" target="__blank" title="CARTELLIGENCE">
                    {CARTELLIGENCE_LOGO}
                </DataProviderLogo>
            </DataProviderBox>
        )
    }

    function renderFooter() {
        return (
            <FooterBox>
                {renderProductGroupActions()}
                {renderLoader(true)}
                {renderDataProvider()}
            </FooterBox>
        )
    }

    function renderLoader(footerMode: boolean) {
        if (!loading) {
            return
        }

        const hasNodes =
            topNodes?.productGroups.length ||
            topNodes?.repairTimes.length ||
            predictiveNodes?.productGroups.length ||
            predictiveNodes?.repairTimes.length

        // Show overlay loader only when no nodes are shown yet and
        // show loader in footer only when nodes are shown already
        if ((hasNodes && !footerMode) || (!hasNodes && footerMode)) {
            return
        }

        return footerMode ? <FooterLoader /> : <OverlayLoader />
    }

    return (
        <WrapperBox className={className}>
            {renderDescription()}
            {renderNodes()}
            {renderFooter()}
            {renderLoader(false)}
        </WrapperBox>
    )
}

const style = useStyle({
    productGroups: {
        padding: "0.25em 0.6em",
        lineHeight: 1.4, // Fix unnecessary scrollbar
        $nest: {
            "&.selection-list": {
                paddingLeft: "0.4em",
                $nest: {
                    ".selection-list__item": {
                        margin: "0.5em 0",
                    },
                    ".selection-list__item__value-wrapper": {
                        marginLeft: "0.75em",
                    },
                    ".selection-list__item__value": {
                        lineHeight: 1.3, // Fix character clipping (e.g. 'g')
                    },
                },
            },
        },
    },
})(Component)
