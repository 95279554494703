import { Button, Dropdown, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { MileType, VehicleType } from "@tm/models"
import { formatMileage, useModelDetails } from "@tm/utils"
import { Suspense } from "react"
import { style } from "typestyle"
import { MergedVehicle } from "../../../../../data/hooks"
import { textOpacity } from "../style"

type Props = {
    vehicles: Array<MergedVehicle>
    vehicleIndex: number
    setVehicleIndex: (index: number) => void
    editVehicle: boolean
    onVehicleEdit: (edit: boolean) => void
}

function VehicleThumbnail(props: { tecDocTypeID: number }) {
    const model = useModelDetails({ modelId: props.tecDocTypeID, vehicleType: VehicleType.PassengerCar })
    return <img src={model?.modelDetails?.thumbnail} className={style({ height: "30px", width: "auto", marginRight: "1em", alignSelf: "end" })} />
}

export function VehicleSelection(props: Props) {
    const { vehicles, setVehicleIndex, vehicleIndex, editVehicle, onVehicleEdit } = props
    const { translateText, number } = useLocalization()

    const { mileAge } = vehicles[vehicleIndex].vehicleInfo
    const { plateId } = vehicles[vehicleIndex].vehicleInfo

    return (
        <div className={style({ paddingTop: "1em" })}>
            <Text className={textOpacity} size="l" modifiers={["block", "strong"]}>
                {translateText(99)}
            </Text>
            <div className={style({ display: "flex", justifyContent: "space-between", margin: ".5em 0" })}>
                <div>
                    {!editVehicle && (
                        <div className={style({ display: "flex" })}>
                            <Suspense fallback={<Loader visible />}>
                                <VehicleThumbnail tecDocTypeID={vehicles[vehicleIndex].vehicleInfo.tecDocTypeId} />
                            </Suspense>
                            <div>
                                <Text modifiers={["strong", "block"]}>{vehicles[vehicleIndex].vehicleInfo.displayName}</Text>
                                <div className={style({ display: "flex", alignItems: "center", paddingTop: ".5em" })}>
                                    {plateId && <Text>{plateId} | </Text>}
                                    {mileAge && (
                                        <Text className={style({ marginLeft: "0.5em" })}>
                                            {`${translateText(1491)}: ${formatMileage(
                                                number(parseInt(`${mileAge}`.replace(/[\.,\D]/g, "")), 0),
                                                MileType.Kilometer
                                            )}`}
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {editVehicle && (
                        <Dropdown<MergedVehicle>
                            items={vehicles}
                            value={vehicles[vehicleIndex]}
                            onChange={(item) => setVehicleIndex(vehicles.indexOf(item))}
                            itemView={({ vehicleInfo }) => <>{vehicleInfo.displayName}</>}
                            className={style({ minWidth: "12em" })}
                        />
                    )}
                </div>
                <div className={style({ alignSelf: "end" })}>
                    {editVehicle ? (
                        <Button icon="check" skin="success" onClick={() => onVehicleEdit(false)} />
                    ) : (
                        <Button onClick={() => onVehicleEdit(true)}>{translateText(347)}</Button>
                    )}
                </div>
            </div>
        </div>
    )
}
