import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"
import { concat } from "@tm/utils"
import { getStyleTheme } from "@tm/context-distribution"
import { FC, Fragment, HTMLAttributes } from "react"

function getComponentStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()

    return {
        display: "flex",
        alignItems: "center",
        $nest: {
            "&__separator": {
                alignSelf: "stretch",
                margin: `0 ${margin.m}`,
                width: "1px",
                backgroundColor: "#acacac",
            },
        },
    }
}

type Props = HTMLAttributes<HTMLDivElement>

export const SeparatorList: FC<Props> = (props) => {
    const className = style(getComponentStyles())

    return (
        <div {...props} className={concat(" ", props.className, className)}>
            {!Array.isArray(props.children)
                ? props.children
                : props.children.map((x, idx, children) => (
                      <Fragment key={idx}>
                          {x}
                          {idx < children.length - 1 && <div className={`${className}__separator`} />}
                      </Fragment>
                  ))}
        </div>
    )
}
