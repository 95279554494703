import { em } from "csx"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Headline, Loader, Tag, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { formatDate, initSelector } from "@tm/utils"
import { ESufficentSelections } from "../../../../data/enums"
import { bem, dateToString } from "../../../../data/helpers"
import { CustomDateField, CustomDropdown } from "../../../_shared"
import { MainActions, MainState } from "../../../main"
import { DetailsState } from "../../business"

const selector = initSelector(
    (s: MainState) => s.details.selectedValues,
    (s) => s.details.extendedCarInfo,
    (s) => s.manager.vehicle
)

export default function SelectionFilters() {
    const { translateText } = useLocalization()
    const actions = useActions(MainActions, "updateMMTList", "loadVKNImages", "updateFields", "loadCarExtendedInfo")
    const workTask = useWorkTask()

    const [selectedValues, extendedCarInfo, vehicle] = useSelector(selector)
    const {
        sufficientSelection,
        constructionPeriods,
        landCertificates,
        registrationDate,
        equipmentLine,
        make,
        model,
        transmissionType,
        type,
        loading,
    } = extendedCarInfo

    const style = useMemo(() => getStyle(), [])

    const handleFieldChange = (path: string, value: any) => {
        if (path == "doors") {
            value = parseInt(value)
        }
        if (path == "constructionPeriod") {
            value = constructionPeriods?.find((x) => `${dateToString(x.dateFrom)} - ${dateToString(x.dateTo)}` == value)
        }

        actions.updateFields(path, value)

        if (value && path == "initialReg" && vehicle?.initialRegistration != new Date(value)) {
            workTask?.attachToWorkTask({ vehicle: { ...workTask.workTask?.vehicle!, initialRegistration: new Date(value) } })
        }

        actions.loadCarExtendedInfo()
    }

    const renderFields = () => {
        const constrPeriods = constructionPeriods?.length && constructionPeriods.map((x) => `${dateToString(x.dateFrom)} - ${dateToString(x.dateTo)}`)
        const selectedConstrPeriod =
            selectedValues.constructionPeriod &&
            `${dateToString(selectedValues.constructionPeriod.dateFrom)} - ${dateToString(selectedValues.constructionPeriod?.dateTo)}`

        const hasInitialReg = !!selectedValues.initialReg
        const hasConstrPeriod = !!constrPeriods && !!selectedValues.constructionPeriod

        return (
            <div className={bem(style.fieldsWrapper, loading && "loading")}>
                {!(hasConstrPeriod || hasInitialReg) && (
                    <div className={style.item}>
                        <Text className={style.itemTitle}>{translateText(1841)}</Text>

                        <div className={style.itemContent}>
                            {!selectedValues.initialReg && (
                                <CustomDateField
                                    floatingLabel
                                    label={translateText(124)}
                                    ignoreOnSelect
                                    value={selectedValues.initialReg}
                                    minDate={registrationDate}
                                    openToDate={selectedValues.initialReg || registrationDate}
                                    onChange={(value) => handleFieldChange("initialReg", value)}
                                    path={["initialReg"]}
                                    useUtc
                                />
                            )}

                            {!!constrPeriods && !selectedValues.constructionPeriod && (
                                <CustomDropdown
                                    className={style.contrPeriod}
                                    items={constrPeriods}
                                    defaultItem={translateText(1480)}
                                    onChange={(value) => handleFieldChange("constructionPeriod", value)}
                                    selectedValue={selectedConstrPeriod}
                                />
                            )}
                        </div>
                    </div>
                )}

                {!!landCertificates?.length && !selectedValues.kbaNo && (
                    <div className={style.item}>
                        <Text className={style.itemTitle}>{translateText(1880)}</Text>

                        <div className={style.itemContent}>
                            <CustomDropdown
                                items={landCertificates}
                                defaultItem={translateText(103)}
                                onChange={(value) => handleFieldChange("kbaNo", value)}
                                selectedValue={selectedValues.kbaNo}
                            />
                        </div>
                    </div>
                )}

                {!!equipmentLine?.length && !selectedValues.equipmentLine && (
                    <div className={style.item}>
                        <Text className={style.itemTitle}>{translateText(1843)}</Text>

                        <div className={style.itemContent}>
                            <CustomDropdown
                                items={equipmentLine}
                                defaultItem={translateText(1484)}
                                onChange={(value) => handleFieldChange("equipmentLine", value)}
                                selectedValue={selectedValues.equipmentLine}
                            />
                        </div>
                    </div>
                )}

                {((!!make?.length && !selectedValues.make) ||
                    (!!model?.length && !selectedValues.model && !!type?.length && !selectedValues.type)) && (
                    <div className={style.item}>
                        <Text className={style.itemTitle}>{translateText(1844)}</Text>

                        {!!make?.length && !selectedValues.make && (
                            <div className={style.itemContent}>
                                <CustomDropdown
                                    items={make}
                                    defaultItem={translateText(1062)}
                                    onChange={(value) => handleFieldChange("make", value)}
                                    selectedValue={selectedValues.make}
                                />
                            </div>
                        )}

                        {!!model?.length && !selectedValues.model && (
                            <div className={style.itemContent}>
                                <CustomDropdown
                                    items={model}
                                    defaultItem={translateText(1064)}
                                    onChange={(value) => handleFieldChange("model", value)}
                                    selectedValue={selectedValues.model}
                                />
                            </div>
                        )}

                        {!!type?.length && !selectedValues.type && (
                            <div className={style.itemContent}>
                                <CustomDropdown
                                    items={type.map((x) => x.toString())}
                                    defaultItem={translateText(1829)}
                                    onChange={(value) => handleFieldChange("type", value)}
                                    selectedValue={selectedValues.type?.toString()}
                                />
                            </div>
                        )}
                    </div>
                )}

                {!!transmissionType?.length && !selectedValues.transmissionType && (
                    <div className={style.item}>
                        <Text className={style.itemTitle}>{translateText(1842)}</Text>

                        <div className={style.itemContent}>
                            <CustomDropdown
                                items={transmissionType.map((x) => x.toString())}
                                defaultItem={translateText(1366)}
                                onChange={(value) => handleFieldChange("transmissionType", value)}
                                selectedValue={selectedValues.transmissionType?.toString()}
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderBreadCrumbs = () => {
        const items: Array<keyof DetailsState["selectedValues"]> = []
        orderList.forEach((x) => selectedValues[x] && items.push(x))

        return items.map((key, idx) => (
            <Tag
                key={idx}
                skin="primary"
                className={style.tag}
                value={getStringFromField(selectedValues, key)}
                label={getLabelFromField(key, translateText)}
                onCloseClick={() => handleFieldChange(key, "")}
                layout="bordered"
            />
        ))
    }
    return (
        <>
            <div className={style.breadcrumbs}>
                <Headline className={style.headline} size="xs">
                    {((sufficientSelection == ESufficentSelections.Distinct || Object.values(selectedValues).some((x) => x)) &&
                        translateText(1846)) ||
                        translateText(1840)}
                </Headline>
                {renderBreadCrumbs()}
                {loading && <Loader />}
            </div>
            {sufficientSelection && sufficientSelection != ESufficentSelections.Distinct && renderFields()}
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        fieldsWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexWrap: "wrap",
            marginTop: theme.margin.m,
            $nest: {
                "&--loading": {
                    pointerEvents: "none",
                    opacity: theme.opacity.disabled,
                },
            },
        },
        breadcrumbs: {
            display: "flex",
            alignItems: "center",
        },
        headline: {
            marginRight: theme.margin.s,
        },
        tag: {
            marginLeft: theme.margin.s,
        },
        item: {
            display: "flex",
            alignItems: "center",

            flexWrap: "wrap",
            marginLeft: theme.margin.s,
            marginBottom: theme.margin.s,
            $nest: {
                ".dropdown": {
                    width: em(11), // TODO
                },
                ".react-datepicker-wrapper": {
                    width: em(11),
                },
                ".dropdown>.btn": {
                    height: em(2.5),
                    paddingTop: theme.margin.s,
                },
            },
        },
        itemTitle: {
            marginRight: theme.margin.m,
        },
        itemContent: {
            display: "flex",
        },
        contrPeriod: {
            marginLeft: theme.margin.m,
        },
    })(SelectionFilters)
}

function getStringFromField(obj: DetailsState["selectedValues"], key: keyof DetailsState["selectedValues"]) {
    switch (key) {
        case "constructionPeriod": {
            const value = obj[key]
            return value && `${dateToString(value.dateFrom)} - ${dateToString(value.dateTo)}`
        }
        case "initialReg":
            return formatDate(typeof obj[key] == "string" ? new Date(obj[key]!) : obj[key]!, "d") // "d": "dd.MM.yyyy"
        default:
            return obj[key]
    }
}

function getLabelFromField(key: keyof DetailsState["selectedValues"], translateText: (a: number) => string) {
    switch (key) {
        case "constructionPeriod":
            return translateText(1480)
        case "initialReg":
            return translateText(124)
        case "equipmentLine":
            return translateText(1484)
        case "kbaNo":
            return translateText(103)
        case "make":
            return translateText(1062)
        case "model":
            return translateText(1064)
        case "transmissionType":
            return translateText(1366)
        case "type":
            return translateText(1829)
        default:
            return ""
    }
}

const orderList: Array<keyof DetailsState["selectedValues"]> = [
    "initialReg",
    "constructionPeriod",
    "kbaNo",
    "transmissionType",
    "equipmentLine",
    "type",
    "make",
    "model",
]
