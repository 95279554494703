import { Icon } from "@tm/components"
import { useCallback, useEffect, useState } from "react"
import { closeModalOrDrawer, useUnsubscribe } from "@tm/utils"
import { Article, channel, EReplaceButtonBundle } from "@tm/models"
import { Genart } from "../../data/models"
import CollapsibleWrapper from "./collapsibleWrapper"
import PartItem from "./genArts/PartItem"
import ProductGroupSearch from "./ProductGroupSearch"
import { useFastServiceStore, useWorkContext, worksActions } from "../../data"

type Props = {
    initialGenArt: Genart
}

export function ProductCreation({ initialGenArt }: Props) {
    const [genArt, setGenArt] = useState(initialGenArt)
    const subscriptionReplacePart = useUnsubscribe()
    const { step, path } = useWorkContext()

    const works = useFastServiceStore((state) => state.worksState.works[step])

    useEffect(() => {
        const foundGenArt = works.flatMap((work) => work.genArts).find((gA) => gA.genArtNr === initialGenArt.genArtNr)

        if (foundGenArt) {
            setGenArt(foundGenArt)
        }
    }, [works, genArt.article?.id, initialGenArt.genArtNr])

    const handleQuantityArticleChange = useCallback((articleId: string, quantity: number) => {
        worksActions.updateArticleQuantity(path || genArt.label, step, quantity, articleId)
    }, [])

    const handleShowPartAlternatives = (internalIdentifier?: string) => {
        subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce(
            "PARTS/REPLACE_PART",
            ({ part: replacePart, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.FastService) {
                    return true
                }

                if (internalIdentifier) {
                    worksActions.removeCustomArticle(step, path, internalIdentifier)
                }

                worksActions.replaceArticle(path, step, (replacePart as Article).id, replacePart as Article, true, genArt)
                closeModalOrDrawer(isNewList)
            }
        )

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            uniProductGroups: {
                ids: [genArt.genArtNr],
            },
            replaceButtonMicro: EReplaceButtonBundle.FastService,
            forceReload: true,
            inModal: true,
        })
    }

    return (
        <CollapsibleWrapper icon={<Icon name="fast-calculator" color="primary" size="1.5em" />} textId={1667}>
            {genArt?.article ? (
                <PartItem
                    genArt={genArt}
                    article={genArt.article}
                    onQuantityArticleChange={handleQuantityArticleChange}
                    showPartAlternatives={() => handleShowPartAlternatives()}
                    disableCheckToggle
                    enableOeReplace
                    enableReplaceWithoutFsArticle
                    selectedGenArts={[genArt]}
                />
            ) : (
                <ProductGroupSearch handleShowAlternatives={handleShowPartAlternatives} genArt={genArt} />
            )}
        </CollapsibleWrapper>
    )
}
