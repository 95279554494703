import { Suspense, useMemo, useRef } from "react"
import {
    RepairTimeOptions,
    WorkTaskInfo,
    useTelesalesCustomerNumber,
    useUser,
    useWorkTask,
    useAddToBasketModuleParameters,
    useShowNewArticleList,
} from "@tm/context-distribution"
import { Badge, WarningPrompt } from "@tm/controls"
import { Box, Button, Icon, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import {
    Article,
    channel,
    ConfigParams,
    GetMainServicesRequest,
    GetMainServicesResponse,
    IMicros,
    RegisteredModels,
    RepairTimeProvider,
    Vehicle,
} from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import {
    encodeUniqueId,
    getRepairTimesProvider,
    getRepairTimesProviderEnumByString,
    mapArticleToAddCatalogPartListRequest,
    mapMainServicesToAddRepairTimeListRequest,
    showWarehouseDataMissingError,
    useActiveVehicleDataProviders,
    useDefaultErpSystem,
    useExternalCatalogUrl,
} from "@tm/utils"

import { useProductGroupRepairTimes } from "@bundles/parts"
import { useErpInfoMulti } from "../../data/hooks/useErpInfoMulti"
import { useHasRepairTimesForProductGroup } from "../../data/hooks/useHasRepairTimesForProductGroup"
import { useWarehouse } from "../../data/hooks/useWarehouse"
import { useAddArticleExternalUrl } from "../../helpers"
import { useBasketMemo } from "../../hooks/useBasketMemo"
import { getBundleParams } from "../../utils"
import { ApprovalWarningPrompt } from "../_shared/ApprovalWarningPrompt"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

type Props = IMicros["basket"]["add-to-cost-estimate"]

function AddToCostEstimateComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { data: articles, disabled, variant, vehicleId, foundBySearchTerm, workTask, erpType, vehicleImageBase64, customerId } = props
    const workTaskId = workTask.id
    const { addToBasketExternalSystemId, nextLight } = getBundleParams()

    const { enableAddingButtonsAfterErp } = Morpheus.getParams<ConfigParams>()

    const { translateText, languageId } = useLocalization()
    const {
        userContext: {
            parameter: { catalogLight },
        },
        userSettings,
    } = useUser() ?? {}
    const theme = useTheme()
    const costEstimationButtonColor = theme.overwrites?.components?.addToCostEstimation?.button?.color
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { costEstimation, basket } = useWorkTaskBasketState(workTaskId)
    const { addToNextBasket, showCostEstimationButton } = useAddToBasketModuleParameters()

    const { activeProviders } = useActiveVehicleDataProviders(workTaskId)

    const { erpSystemConfig } = useDefaultErpSystem()
    const { erpInfos, erpInfoLoaded } = useErpInfoMulti(articles, erpType, erpSystemConfig?.id, telesalesCustomerNo, vehicleId, foundBySearchTerm)
    const warehouseData = useWarehouse(articles[0]?.id, erpSystemConfig?.id) // Note: currently only working correctly for only one article

    const basketMemo = useBasketMemo(workTask)
    const hasListV2 = useShowNewArticleList()
    const { fittingGroup } = useHasRepairTimesForProductGroup(workTaskId, articles[0]) // Note: currently only working correctly for only one article
    const repairTimeProviders = useProductGroupRepairTimes([articles[0].productGroup.id], true).productGroupRepairTimes?.productGroups[
        articles[0].productGroup.id
    ]

    const { loadingExternalCatalogUrl, externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })
    const externalBasketUrl = useAddArticleExternalUrl(
        // Note: currently only working correctly for only one article
        externalCatalogUrl,
        languageId,
        articles[0].quantity,
        articles[0].traderArticleNo,
        articles[0].supplierArticleNo,
        articles[0].supplier.id,
        articles[0].productGroup.id
    )

    const dialogRef = useRef<WarningPrompt>(null)

    const buttonMinWidth = useMemo(() => {
        if (variant === "large") {
            return "6.25em"
        }

        if (costEstimationButtonColor === "highlight") {
            return "5.5rem"
        }

        return "3em"
    }, [variant, costEstimationButtonColor])

    const buttonDisabled = useMemo(() => {
        return (
            catalogLight ||
            disabled ||
            articles.some((x) => !x.showAddToBasket) ||
            (enableAddingButtonsAfterErp && !erpInfoLoaded) ||
            loadingExternalCatalogUrl
        )
    }, [catalogLight, disabled, articles, enableAddingButtonsAfterErp, erpInfoLoaded, loadingExternalCatalogUrl])

    const isInOrder = useMemo(() => {
        return basket.state.basketOrderGroups?.some(
            (orderGroup) =>
                orderGroup.orderGroup.distributorId === erpSystemConfig?.id &&
                orderGroup.basketParts.some(
                    (part) =>
                        part.partItem.orderItem?.isIncluded &&
                        articles[0].supplierArticleNo === part.partItem.articleInformation.articleNumber &&
                        articles[0].supplier.id === part.partItem.articleInformation.supplierId &&
                        articles[0].productGroup.id === part.partItem.articleInformation.productGroupId
                )
        )
    }, [articles, basket.state.basketOrderGroups, erpSystemConfig?.id])

    const icon = useMemo(() => {
        if (hasListV2) {
            return "add-repair-times"
        }

        return languageId === "1" ? "voucher-kva" : "voucher-kva-international"
    }, [languageId, hasListV2])

    // if the MDM module has no costestimation button, don't render it
    if (showCostEstimationButton === false || nextLight) {
        return null
    }

    function addArticlesToBasket() {
        const container = Container.getInstance<{ key: string; value: { state: boolean } }>(RegisteredModels.ViewState)
        const subscribe = container.subscribe("COST_ESTIMATION_VISIBLE") // TODO: create and use container action
        subscribe.save?.({ key: `${encodeUniqueId(workTaskId)}__COST_ESTIMATION_VISIBLE`, value: { state: true } })

        basket.actions
            .addCatalogParts(
                mapArticleToAddCatalogPartListRequest(
                    articles,
                    workTaskId,
                    vehicleId,
                    customerId,
                    foundBySearchTerm,
                    basketMemo.position,
                    erpInfos,
                    warehouseData.warehouse,
                    undefined,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description
                )
            )
            .then(() => props.onAddCatalogArticleToBasketFinished?.(articles))

        props.onAddToBasket?.(articles)
    }

    function addRepairTimeToBasket(vehicle: Vehicle) {
        const { repairTimeProviders: repairTimeProvidersFromProps, getRepairTimesUrl } = props

        if (!repairTimeProviders?.length || !repairTimeProvidersFromProps.length) {
            return
        }

        const repairTimeProvider = getRepairTimesProviderEnumByString(
            getRepairTimesProvider(
                repairTimeProviders,
                repairTimeProvidersFromProps,
                activeProviders?.repairTimes ?? userSettings?.activeVehicleDataProviders?.repairTimes
            )
        )

        if (!repairTimeProvider || !userSettings?.repairTimeOptions) {
            showModal(getRepairTimesUrl(articles[0], repairTimeProviders[0]))
            return
        }

        addRepairTimesToBasket(
            articles[0], // Note: currently only working correctly for only one article
            vehicle,
            repairTimeProvider,
            userSettings.repairTimeOptions
        ).then(({ showRepairTimesModal }) => {
            if (showRepairTimesModal) {
                showModal(getRepairTimesUrl(articles[0], repairTimeProvider))
            }
        })
    }

    async function addToBasket() {
        // Always wait for attachVehicle to be sure, it is done before calling ShowWorkTaskBasket
        // Better solution will be done here https://jira.dvse.de/browse/NEXTINT-197
        if (vehicleImageBase64) {
            await costEstimation.actions.attachVehicleImage(vehicleImageBase64)
        }

        // Only add article if it is not already in basket
        if (!isInOrder) {
            if (externalBasketUrl) {
                showModal(externalBasketUrl)
            } else if (addToNextBasket) {
                addArticlesToBasket()
            }
        }

        if (workTask.vehicle) {
            addRepairTimeToBasket(workTask.vehicle)
        }

        articles.forEach((article) => channel("WORKTASK").publish("BASKET/ARTICLE_QUANTITY_CHANGED", { article, quantity: 1 }))
    }

    function addRepairTimesToBasket(
        article: Article,
        vehicle: Vehicle,
        repairTimeProvider: RepairTimeProvider,
        repairTimeOptions: RepairTimeOptions
    ): Promise<{ showRepairTimesModal: boolean }> {
        const request: GetMainServicesRequest = {
            repairTimeProvider,
            modelId: vehicle.tecDocTypeId,
            productGroupId: article.productGroup.id.toString(),
            vehicleType: vehicle.vehicleType,
            fittingSideFilter: article.fittingSide,
            manufacturerId: vehicle.tecDocManufacturerId,
            defaultRepairTimeDivision: repairTimeOptions.division,
            useManufacturerRepairTimeDivision: repairTimeOptions.useManufacturerRepairTimeDivision,
        }

        return new Promise((resolve, reject) => {
            // TODO: create and use container action
            Container.getInstance<GetMainServicesResponse>(RegisteredModels.RepairTimes_MainServices)
                .subscribe(request)
                .load()
                .then(({ mainServices, repairTimeDivision }) => {
                    if (!mainServices.length) {
                        reject()
                        return
                    }

                    if (mainServices.length > 1 || getBundleParams().alwaysShowRepairTimesModalAfterCostEstimationButtonClick) {
                        resolve({ showRepairTimesModal: true })
                        return
                    }

                    const service = mainServices[0]
                    costEstimation.actions.addRepairTimes(
                        mapMainServicesToAddRepairTimeListRequest(
                            workTaskId,
                            repairTimeProvider,
                            [
                                {
                                    ...service,
                                    productGroupIds:
                                        !service.productGroupIds || service.productGroupIds.length // TODO: check if "service.productGroupIds.length" shouldn't rather be negotiaded
                                            ? [article.productGroup.id]
                                            : service.productGroupIds,
                                },
                            ],
                            vehicle.id,
                            repairTimeDivision,
                            article.fittingSide
                        )
                    )

                    resolve({ showRepairTimesModal: false })
                })
        })
    }

    async function handleButtonClick() {
        if (warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            warehouseData.refetchWarehouseData()
            return
        }

        if (erpInfos.some((erpData) => erpData.itemOrderableInformation)) {
            dialogRef.current?.show()
        } else {
            addToBasket()
        }
    }

    return (
        <Box className="tk-basket add-to-cost-estimate">
            <Box zIndex={1} position="relative">
                {fittingGroup?.hasWorks && <Badge skin="dark" value={<Icon name="check" width="12px" height="12px" />} title={translateText(1298)} />}
            </Box>
            <Box minWidth={buttonMinWidth}>
                <Button
                    className="btn btn--bordered"
                    startIcon={
                        <Icon
                            name={icon}
                            width={costEstimationButtonColor ? "38px !important" : undefined} // costEstimationButtonColor only used for Create-business
                        />
                    }
                    title={translateText(917)}
                    variant="bordered"
                    color={costEstimationButtonColor ?? undefined}
                    onClick={handleButtonClick}
                    disabled={buttonDisabled}
                    fullWidth
                />
            </Box>
            <ApprovalWarningPrompt ref={dialogRef} erpInfos={erpInfos} onConfirm={addToBasket} />
        </Box>
    )
}

function showModal(url: string | undefined) {
    if (url) {
        Morpheus.showView("1", url)
    }
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}

    if (!workTask) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToCostEstimateComponent {...props} workTask={workTask} />
        </Suspense>
    )
}
