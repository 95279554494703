import { getLocalization } from "@tm/localization"
import { ArticleErpIcon, ErpIconActionType, ErpIconType, ErpInformation } from "@tm/models"

export function erpInformationResponeMapper(response: ErpInformation): ErpInformation {
    return {
        ...response,
        specialIcons: specialIconsMapper(response),
    }
}

function specialIconsMapper(erpInfo: ErpInformation): ArticleErpIcon[] {
    const { translateText } = getLocalization()
    let { specialIcons = [] } = erpInfo

    const iconIndex = specialIcons.findIndex((icon) => icon.type === ErpIconType.SafetyDataSheets)
    const safetyShietIcon = specialIcons[iconIndex]

    if (erpInfo?.showSafetyDataSheets && (!safetyShietIcon || (safetyShietIcon.useIconFromUrl && !safetyShietIcon?.url))) {
        specialIcons = specialIcons.with(iconIndex, {
            // replacing special icon from erp to prevent multiple safetysheeticons
            topmotiveId: "attention-dark",
            useIconFromUrl: false,
            url: "",
            description: safetyShietIcon.description ?? translateText(1616),
            type: ErpIconType.SafetyDataSheets,
            actionType: ErpIconActionType.SafetyDataSheetsDetailPage,
        })
    }

    if (erpInfo?.isSellOffArticle) {
        specialIcons.push({
            topmotiveId: "sale-off",
            useIconFromUrl: false,
            url: "",
            description: translateText(13786),
            actionType: ErpIconActionType.Unknown,
            type: ErpIconType.SellOff,
        })
    }

    if (erpInfo?.isNonReturnable) {
        specialIcons.push({
            topmotiveId: "no-return",
            useIconFromUrl: false,
            url: "",
            description: translateText(13790),
            actionType: ErpIconActionType.Unknown,
            type: ErpIconType.NonRefundable,
        })
    }

    return specialIcons
}
