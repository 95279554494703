import { MainState } from "@bundles/wheels/components/main"
import { Restriction } from "@bundles/wheels/data/model"
import { styled, Stack, List, ListItemPaper, Typography, ScrollContainer, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const StyledList = styled(List)({
    display: "flex",
    flexDirection: "column",
    gap: 1,
    flexBasis: "50%",
})

enum MountingPadsTabs {
    TireRestrictions = "TireRestrictions",
    RimRestrictions = "RimRestrictions",
}

export function MountingPads() {
    const { translateText } = useLocalization()
    const [selectedTab, setSelectedTab] = useState<MountingPadsTabs | undefined>(undefined)
    const { rimInfoTireRestrictionsResponse, rimInfoRimRestrictionsResponse, selectedTireSize } = useSelector((s: MainState) => ({
        rimInfoTireRestrictionsResponse: s.wheelsDetails.rimInfoTireRestrictionsResponse,
        rimInfoRimRestrictionsResponse: s.wheelsDetails.rimInfoRimRestrictionsResponse,
        selectedTireSize: s.wheelsDetails.selectedTireSize,
    }))

    useEffect(() => {
        if (!selectedTireSize && rimInfoRimRestrictionsResponse?.data?.length > 0) {
            setSelectedTab(MountingPadsTabs.RimRestrictions)
        } else if (rimInfoTireRestrictionsResponse?.data?.length > 0) {
            setSelectedTab(MountingPadsTabs.TireRestrictions)
        } else if (rimInfoRimRestrictionsResponse?.data?.length > 0) {
            setSelectedTab(MountingPadsTabs.RimRestrictions)
        }
    }, [])

    const renderTabControl = () => {
        return (
            <Stack direction="row" ml="2em">
                <Button
                    color={selectedTab === MountingPadsTabs.TireRestrictions ? "highlight" : "secondary"}
                    onClick={() => setSelectedTab(MountingPadsTabs.TireRestrictions)}
                    disabled={!rimInfoTireRestrictionsResponse?.data?.length || !selectedTireSize}
                    size="extralarge"
                >
                    {translateText(965)}
                </Button>
                <Button
                    color={selectedTab === MountingPadsTabs.RimRestrictions ? "highlight" : "secondary"}
                    onClick={() => setSelectedTab(MountingPadsTabs.RimRestrictions)}
                    disabled={!rimInfoRimRestrictionsResponse?.data?.length}
                    size="extralarge"
                    sx={{ marginLeft: ".2em" }}
                >
                    {translateText(966)}
                </Button>
            </Stack>
        )
    }

    const renderItem = (item: Restriction) => {
        return (
            <ListItemPaper sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h4">{item.restrictionId}</Typography>
                <Typography>{item.description}</Typography>
            </ListItemPaper>
        )
    }

    const renderActivePageContent = (data: Restriction[]) => {
        const index = Math.round(data.length / 2)
        const firstColumn = data.slice(0, index)
        const secondColumn = data.slice(index)
        return (
            <Stack direction="row" gap={2}>
                <StyledList>{firstColumn.map(renderItem)}</StyledList>
                <StyledList>{secondColumn.map(renderItem)}</StyledList>
            </Stack>
        )
    }

    return (
        <Stack>
            {renderTabControl()}
            <ScrollContainer sx={{ maxHeight: "14em" }}>
                {selectedTab === MountingPadsTabs.TireRestrictions && renderActivePageContent(rimInfoTireRestrictionsResponse.data)}
                {selectedTab === MountingPadsTabs.RimRestrictions && renderActivePageContent(rimInfoRimRestrictionsResponse.data)}
            </ScrollContainer>
        </Stack>
    )
}
