import { FastServiceStore } from "../../data"
import { getHistoryDetails } from "../../data/repositories"
import { useEffect, useState } from "react"

export function useFastServiceShareHistory(historyId: string | null) {
    const [isLoading, setIsLoading] = useState(false)
    const [history, setHistory] = useState<FastServiceStore | undefined>(undefined)

    async function getSharedHistoryDetails(historyId: string | undefined) {
        if (!historyId) {
            setIsLoading(false)
            return
        }

        setIsLoading(true)
        const detailedHistory = await getHistoryDetails(historyId)

        if (detailedHistory) {
            setHistory(detailedHistory)
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if (historyId) {
            getSharedHistoryDetails(historyId)
        }
    }, [historyId])

    return {
        isLoading,
        history
    }
}