import { Grid, Skeleton, Stack } from "@tm/components"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { SkeletonArticleItem } from "./SkeletonArticleItem"
import { SkeletonArticleItemTile } from "./SkeletonArticleItemTile"

type Props = {
    canLoadNextArticles?: boolean
    loadNextPage?: () => void
    skeletonArticleCount?: number
    skeletonCategory?: boolean
    isTile?: boolean
}

export function NextPageLoader(props: Props) {
    const { canLoadNextArticles, loadNextPage, isTile } = props

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1, // Percentage from which the element is considered visible
    })

    useEffect(() => {
        if (canLoadNextArticles && inView) {
            loadNextPage?.()
        }
    }, [inView])

    // deactivate the animation as long as it is not visible to avoid unnecessary load
    const pulsOrInactive = inView ? "pulse" : false

    return isTile ? (
        <Grid display="grid" gridTemplateColumns="repeat(auto-fit, minmax(240px, 1fr))" gap="1rem">
            {Array.from(Array(props.skeletonArticleCount || 2).keys()).map((_, index) => (
                <SkeletonArticleItemTile key={index} animation={pulsOrInactive} />
            ))}
        </Grid>
    ) : (
        <Stack ref={ref} spacing={1}>
            {props.skeletonCategory && <Skeleton variant="rectangular" height="40px" animation={pulsOrInactive} />}
            {Array.from(Array(props.skeletonArticleCount || 2).keys()).map((_, index) => (
                <SkeletonArticleItem key={index} animation={pulsOrInactive} />
            ))}
        </Stack>
    )
}
