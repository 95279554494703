import { Vehicle } from "@tm/models"
import * as Repositories from ".."
import { getCommonItems, mapStateForSaving } from "."
import { DeepPartial, VKNImage } from "../models"
import { createCarExtendedRequest, createEreLinkImagesRequest, createImagePositionRequest } from "../../components/details/business/helpers"
import { MainState } from "../../components/main"
import { ESufficentSelections } from "../enums"
import { reduce } from "../../components/details/business"

export const recreateStore = async (
    progress: () => void,
    vehicle?: Vehicle,
    data?: DeepPartial<ReturnType<typeof mapStateForSaving>>
): Promise<DeepPartial<MainState> | undefined> => {
    try {
        let store: DeepPartial<MainState> = { manager: { vehicle } }
        if (!data?.vehicle || data.vehicle != vehicle?.tecDocTypeId) {
            return store
        }

        let request

        const groups = await Repositories.getCarInfo(data.vehicle)

        const selectedMmtGroup = groups.mmtGroups.find((x) => x.svgEre == data.selectedMmtGroup)

        const selectedErePosition = data.selectedErePositon

        progress()
        // console.log(1, store)

        store = {
            ...store,
            navigation: data.navigation,
            summary: {
                cars: { groups: groups.mmtGroups, usedTecDoc: data.vehicle },
                selectedMmtGroup: {
                    ...selectedMmtGroup,
                    selectedErePosition,
                },
            },
            details: {
                savedCriterias: data.savedCriterias,
                savedSelectedValues: data.savedSelectedValues,
                selectedValues: {
                    ...data.savedValues,
                },
                criterias: data.criterias,
                selectionAvailable: data.selectionAvailable,
                extendedCarInfo: {
                    sufficientSelection: data.extendedSufficent,
                },
                // extendedCarInfo: data.extendedCarInfo,
            },
            manager: {
                vehicle,
            },
        }

        store = {
            ...store,
            details: {
                ...store.details,
                selectedCriterias: data.selectedCriterias ?? [],
                mmtList: data.mmtList,
            },
        }

        // progress()
        // console.log(2, store)

        request = createEreLinkImagesRequest(store as any)
        const { criteria: vknCriterias, imageGroups: items, sufficientSelection } = await Repositories.getEreLinkImages(request)
        store = {
            ...store,
            details: reduce(store.details as any, {
                type: "VKN_IMAGES_LOADED",
                payload: {
                    fromModal: false,
                    items,
                    vknCriterias,
                    sufficientSelection,
                    vehicle: store.manager?.vehicle as Vehicle,
                    fromRestore: true,
                },
            }),
        }

        const selectedVKNImage = store.details?.vknImages?.items?.find((x) => x?.imageName == data.selectedVKNImage) as VKNImage
        if (selectedVKNImage) {
            store = {
                ...store,
                details: reduce(store.details as any, {
                    type: "SELECT_VKN_IMAGE",
                    payload: selectedVKNImage,
                }),
            }
        }

        progress()
        // console.log(3, store)

        if (store.details?.vknImages?.sufficientSelection != ESufficentSelections.Distinct) {
            const request = createCarExtendedRequest(store as any)
            if (request) {
                const carExtendedInfo = await Repositories.getCarExtendedInfo(request)
                store = {
                    ...store,
                    details: reduce(store.details as any, {
                        type: "CAR_INFO_EXTENDED_LOADED",
                        payload: carExtendedInfo,
                        selectedMmtGroup: store.summary?.selectedMmtGroup as any,
                        vehicle: store.manager?.vehicle as any,
                    }),
                }

                progress()
                // console.log(4, store)

                if (store.details?.vknImages?.loading) {
                    const ereLInkReq = createEreLinkImagesRequest(store as any)
                    const { criteria: vknCriterias, imageGroups: items, sufficientSelection } = await Repositories.getEreLinkImages(ereLInkReq)
                    store = {
                        ...store,
                        details: reduce(store.details as any, {
                            type: "VKN_IMAGES_LOADED",
                            payload: {
                                fromModal: false,
                                items,
                                vknCriterias,
                                sufficientSelection,
                                vehicle: store.manager?.vehicle as Vehicle,
                                fromRestore: true,
                            },
                        }),
                    }
                }
            }
        }

        progress()
        // console.log(5, store)

        if (selectedVKNImage && !selectedVKNImage?.isStartImage) {
            const svgImage = await Repositories.getSvgImage(selectedVKNImage.imageName)
            store = {
                ...store,
                details: reduce(store.details as any, {
                    type: "SVGIMAGE_LOADED",
                    payload: svgImage,
                }),
            }
        }

        progress()
        // console.log(6, store)
        request = createImagePositionRequest(store as any)
        if (!request) {
            return store
        }

        const imagePositions = await Repositories.getImagePositions(request)
        store = {
            ...store,
            details: reduce(store.details as any, {
                type: "IMAGE_POSITIONS_LOADED",
                payload: { ...imagePositions, vehicle: store.manager?.vehicle as Vehicle },
            }),
        }

        return store
    } catch (_) {
        console.error("Error on restoring state")
    }
}
