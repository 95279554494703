import { Box, styled } from "@tm/components"
import tinycolor from "tinycolor2"

export const WorkTaskHistoryContainer = styled(Box)(() => ({
    position: "fixed",
    width: "100%",
    background: "rgba(0,0,0,0.3)",
    zIndex: 20,
    top: 0,
    bottom: 0,
    left: 0,
}))

export const WorkTaskHistoryContent = styled(Box)(({ theme }) => {
    const { hover } = theme.overwrites?.components?.worktaskModuleNavigation?.workTaskHistoryModal?.border || {}
    const hoverBorderColor = hover?.color || tinycolor(theme.colors?.primary).lighten(20).toHexString()

    return {
        position: "relative",
        backgroundColor: theme.colors?.light,
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem 1rem 1rem 1rem",
        height: "100%",
        border: "1px solid transparent",
        borderRadius: 0,
        transition: "border 200ms ease",
        ":hover": {
            border: `1px solid ${hoverBorderColor}`,
        },
    }
})
