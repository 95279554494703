import { ArticleErpInfo } from "@tm/models"
import { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { createContext, useContextSelector } from "use-context-selector"

import { ensureContextAvailability } from "../../helpers"
import { BaseArticleData } from "../../models"

export type ArticlesProviderProps = PropsWithChildren<{
    setHasRendered: Dispatch<SetStateAction<boolean>>
    erpInfos: ArticleErpInfo[]
}>

export type ArticlesProviderBaseProps = PropsWithChildren<{
    setHasRendered: Dispatch<SetStateAction<boolean>>
}>

export const ArticlesContext = createContext<BaseArticleData | undefined>(undefined)

export function useArticlesContext<TContext extends BaseArticleData>(): TContext
export function useArticlesContext<TContext extends BaseArticleData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useArticlesContext<TContext extends BaseArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticlesContext, (context) => {
        const validatedContext = ensureContextAvailability("ArticlesContext", context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
