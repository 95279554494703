import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"

export const saveConfiguratorImage = (vehicleId: string, workTaskId: string, configurationFile: string) => {
    const url = `${getBundleParams().wheelsHistoryServiceUrl}/SaveConfigurationFile`
    const authorization = getStoredAuthorization()
    const body = { vehicleId, workTaskId, configurationFile }

    return ajax<string>({ url, body, authorization, method: "POST" })
}
