import { useLocalization } from "@tm/localization"
import { Accordion, AccordionDetails, AccordionSummary, Box, Radio, Typography } from "@tm/components"
import { useEffect, useState } from "react"
import SpareParts from "./SpareParts"
import SummaryWorks from "./SummaryWorks"
import { summaryActions, useDrivemotiveStore } from "../../../data/state"
import { CalculationsMapped } from "../../../data/models/calculation"
import FixedPrices from "./FixedPrices"

export default function CalculationDetails() {
    const { translateText } = useLocalization()
    const calculations = useDrivemotiveStore((state) => state.summary.calculations)

    const labourValues = useDrivemotiveStore((state) => state.summary.selectedBooking?.labour_values)
    const [selectedCalculation, setSelectedCalculation] = useState(calculations?.[0])

    useEffect(() => {
        if (calculations && calculations.length > 0) {
            setSelectedCalculation(calculations[0])
            summaryActions.setSelectedArticleAndWork(calculations[0])
        }
    }, [calculations])

    const handleRadioChange = (calculation: CalculationsMapped) => {
        setSelectedCalculation(calculation)
        summaryActions.setSelectedArticleAndWork(calculation)
    }

    return (
        <>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", paddingRight: "1em" }}>
                {/* Will be needed later */}
                {/* <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                    {translateText(13661)}
                    <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(totalPrice, currencyString)}</Box>
                </Box> */}
            </Box>
            {calculations?.map((calculation, index) => (
                <Accordion sx={{ mb: ".3em" }} key={calculation.title} disableGutters defaultExpanded={index === 0}>
                    <AccordionSummary sx={{ ml: "1em" }}>
                        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                            <Typography>{calculation.title}</Typography>
                            <Radio
                                style={{ transform: "scale(1.8)", marginRight: "1em", backgroundColor: "transparent" }}
                                disableRipple
                                checked={selectedCalculation === calculation}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleRadioChange(calculation)
                                }}
                            />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {calculation.totalPriceWorks && (
                            <SummaryWorks summaryWorks={calculation.summaryWorks} totalPriceWorks={calculation.totalPriceWorks} />
                        )}
                        {labourValues && <FixedPrices />}
                        {calculation.summaryArticles && <SpareParts articles={calculation.summaryArticles} />}
                    </AccordionDetails>
                </Accordion>
            ))}
            {!calculations?.length &&
                Array.isArray(labourValues) &&
                labourValues.length > 0 &&
                labourValues.map((labourValue, index) => (
                    <Accordion sx={{ mb: ".3em" }} key={labourValue.title || index} disableGutters defaultExpanded={index === 0}>
                        <AccordionSummary sx={{ ml: "1em" }}>
                            <Box display="flex" width="100%" justifyContent="flex=end" alignItems="center">
                                <Radio
                                    style={{ transform: "scale(1.8)", marginRight: "1em", backgroundColor: "transparent" }}
                                    disableRipple
                                    checked
                                />
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>{labourValues && <FixedPrices />}</AccordionDetails>
                    </Accordion>
                ))}

            {!calculations?.length && !labourValues?.length && (
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 2 }}>
                    <Typography>{translateText(163)}</Typography>
                </Box>
            )}
        </>
    )
}
