import { Button } from "@tm/controls"
import { RimItem, SizeInfo } from "../../../data/model"
import { em } from "csx"

type SizeButtonProps = {
    item: RimItem
    size?: string
    isActive: boolean
    sizeInfo: SizeInfo
    onSelect(item: RimItem, sizeInfo?: SizeInfo): void
}

export default function SizeButton({ item, size, isActive, sizeInfo, onSelect }: SizeButtonProps) {
    return (
        <Button
            style={{ marginLeft: em(0), marginBottom: em(0.2) }}
            isActive={isActive && size == sizeInfo.size}
            onClick={() => onSelect(item, sizeInfo)}
        >
            {sizeInfo.size}
        </Button>
    )
}
