import { useStyle } from "@tm/context-distribution"
import { Text, Icon } from "@tm/controls"
import { em } from "csx"
import { classes } from "@tm/utils"
import { FastCalculator } from "@tm/data"
import { FC } from "react"
import { bem, getCalcStateIcon } from "../../data/helpers"
import CalcInput from "./calcInput"

type Props = {
    item: FastCalculator.CalcDropDownItem
    className?: string
    onDropdownSelect(): void
}

const CalcDropdownItem: FC<Props> = ({ item, className, onDropdownSelect }) => {
    const icon = getCalcStateIcon(item.icon)
    const labels = item.label ? item.label.split(/\-(?=[^-]*$)/) : []

    return (
        <div className={classes(bem((item.icon && style.center) || undefined), className)}>
            {item.icon && (
                <button
                    type="button"
                    className="btn btn--icon-only"
                    onMouseDown={(e) => {
                        e.stopPropagation()
                        return onDropdownSelect()
                    }}
                >
                    {icon && <Icon name={icon} />}
                    {item.label}
                </button>
            )}
            <div className={style.buttons}>
                {item.favoriteInput && <CalcInput item={item.favoriteInput} className={style.padding} />}
                {item.removeInput && <CalcInput item={item.removeInput} />}
            </div>
            <div className={style.spaceBetween}>
                {labels &&
                    labels.map((label, index) => (
                        <Text key={index} className={style.buttons}>
                            {label}
                        </Text>
                    ))}
            </div>
        </div>
    )
}

const style = useStyle({
    center: {
        display: "flex",
        justifyContent: "center",
    },
    spaceBetween: {
        display: "flex",
        justifyContent: "space-between",
        $nest: {
            "div:last-child": {
                textAlign: "right",
            },
        },
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    padding: {
        paddingRight: em(0.25),
    },
})(CalcDropdownItem)

export default CalcDropdownItem
