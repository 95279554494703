import { Box, styled } from "@tm/components"
import { Button, Icon, List, Loader, Text, PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useSelector } from "react-redux"
import { useCallback, useMemo } from "react"
import { MainState } from "../../../main"
import { ValueItemsDefinition } from "../../business"
import { Fixture } from "../../../../data/repositories/wheels-loadRimFixtures/model"
import { RimDetailsArticle } from "../../../../data/model"
import { fixtureItems, technicalDataFields } from "../../business/helper"

type Props = {
    isLoading: boolean
    hidePrintButton?: boolean
}

function TechnicalData({ isLoading, hidePrintButton }: Props) {
    const { translateText } = useLocalization()
    const { selectedTireSize, selectedRimDetailsArticle, hostettlerCombinations, isSSW } = useSelector((s: MainState) => ({
        selectedTireSize: s.wheelsDetails.selectedTireSize,
        selectedRimDetailsArticle: s.wheelsDetails.selectedRimDetailsArticle,
        hostettlerCombinations: s.wheelsDetails.rimItem?.hostettlerCombinations,
        sswCombinations: s.wheelsDetails.rimItem?.sswCombinations,
        isSSW: s.wheelsDetails.rimItem?.isSSW,
    }))

    const loading = useSelector<MainState, boolean | undefined>((s) => s.wheelsDetails.rimDetailsArticlesResponse.loading)

    let HSCombinationInfo: { information?: string; montageauflagen?: string } | undefined

    if (isSSW) {
        const selectedRimSize = selectedRimDetailsArticle?.rimDetails?.find((rim) => rim.reifen == selectedTireSize?.tyreSize?.split(" ")?.[0])
        HSCombinationInfo = useMemo(
            () => ({
                information: selectedRimSize?.information,
                montageauflagen: selectedRimSize?.montageauflagen,
            }),
            [selectedRimDetailsArticle, selectedTireSize]
        )
    } else {
        HSCombinationInfo = useMemo(() => {
            const selectedRimArticle = (
                selectedRimDetailsArticle?.wheelSize ? hostettlerCombinations?.[selectedRimDetailsArticle.wheelSize] : []
            )?.find((y) => y.articleNumber == selectedRimDetailsArticle?.artNr)
            return {
                information: selectedRimArticle?.information,
                montageauflagen: selectedRimArticle?.montageauflagen,
            }
        }, [selectedRimDetailsArticle])
    }

    const renderItem = (item: ValueItemsDefinition<keyof RimDetailsArticle>) => {
        return (
            <>
                <Text modifiers="strong">{translateText(item.description)}</Text>
                {item.id != 8 && ((selectedRimDetailsArticle && <Text>{selectedRimDetailsArticle[item.value] as any}</Text>) || "")}
                {item.id == 8 && <Icon name={selectedRimDetailsArticle?.[item.value] ? "snowflake" : "close"} />}
            </>
        )
    }

    const renderFixtureItem = (item: ValueItemsDefinition<keyof Fixture>) => {
        return (
            <>
                <Text modifiers="strong">{typeof item.description === "number" ? translateText(item.description) : item.description}</Text>
                {(selectedRimDetailsArticle?.fixture && <Text>{selectedRimDetailsArticle?.fixture[item?.value] || "-"}</Text>) || "-"}
            </>
        )
    }

    const handleCertificateUrl = useCallback(() => {
        const certificateUrl = selectedTireSize?.pathToCertificate
        certificateUrl && window.open(certificateUrl, "_blank")
    }, [selectedTireSize])

    const renderCertificateReport = () => {
        if (!selectedTireSize?.pathToCertificate) {
            return null
        }

        return (
            <>
                <Text className="title" modifiers="block">
                    {translateText(1022)}
                </Text>
                <Button disabled={isLoading} className="pdf-btn" icon="download" size="s" onClick={handleCertificateUrl}>
                    {translateText(1021)}
                </Button>
            </>
        )
    }

    const renderHSInfo = () => {
        return (
            <StyledPanelSection size="xs" title={translateText(12965)}>
                {HSCombinationInfo?.information && <Text size="m">{HSCombinationInfo.information}</Text>}
                {HSCombinationInfo?.montageauflagen && (
                    <StyledText size="l">
                        {HSCombinationInfo.montageauflagen.charAt(0)?.toUpperCase() + HSCombinationInfo.montageauflagen.slice(1)}
                    </StyledText>
                )}
            </StyledPanelSection>
        )
    }

    if (loading) {
        return (
            <div className="article-list__panel article-list__status">
                <Loader />
            </div>
        )
    }

    if (!selectedRimDetailsArticle) {
        return (
            <div className="article-list__panel article-list__status">
                <Text>{translateText(323)}</Text>
            </div>
        )
    }

    return (
        <div className="wheels-technical-data article-details">
            {!hidePrintButton && renderCertificateReport()}
            <hr />
            <List className="key-value-list" items={technicalDataFields} view={renderItem} />
            {(HSCombinationInfo?.information || HSCombinationInfo?.montageauflagen) && renderHSInfo()}
            {selectedRimDetailsArticle?.fixture && (
                <Box>
                    <Text className="title">{translateText(13802)}</Text>
                    <hr />
                    <List className="key-value-list" items={fixtureItems} view={renderFixtureItem} />
                </Box>
            )}
        </div>
    )
}

export default TechnicalData

const StyledPanelSection = styled(PanelSection)(({ theme }) => ({
    margin: theme.margin?.s ?? ".25em",
    ".panel__title": {
        padding: 0,
        border: 0,
    },
    ".panel__content": {
        marginTop: theme.margin?.m ?? ".5em",
    },
}))

const StyledText = styled(Text)(({ theme }) => ({
    color: theme.colors?.danger ?? "red",
    fontWeight: theme.font?.boldWeight ?? "bold",
    paddingTop: theme.margin?.s ?? ".25em",
}))
