import { Autocomplete, Box, SearchButton, TextField, alpha, colors, filledInputClasses, styled } from "@tm/components"
import { VehicleSuggestions } from "./useVehicleSearchAutocomplete"

export const StyledAutoComplete = styled(Autocomplete<VehicleSuggestions, false, true>)(({ theme, size }) => {
    const backgroundColor = theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100]
    const focusBorderColor = theme.overwrites?.components?.textfield?.focus?.border || theme.palette.primary.main
    const cutEdge = theme.overwrites?.components?.textfield?.cutEdge
    return {
        ...(cutEdge && {
            paddingRight: "15px",
        }),
        [`.${filledInputClasses.root}`]: {
            padding: "5px",
            opacity: 1,
            color: "black",
            backgroundColor,
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
            "&:before": {
                content: "none",
            },
            "&:after": {
                content: "none",
            },
            "&:hover": {
                backgroundColor,
            },
            [`&.${filledInputClasses.adornedEnd}.${filledInputClasses.focused}`]: {
                boxShadow: cutEdge ? "none !important" : "0 4px 10px 0 rgba(0,0,0,.2)",
            },
            [`&.${filledInputClasses.focused}`]: {
                backgroundColor,
                borderColor: `${focusBorderColor} !important`,
                boxShadow: cutEdge ? "none !important" : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            },
            input: {
                fontSize: "18px",
            },
            ...(size === "small" && {
                padding: "1.5px",

                input: {
                    fontSize: "16px",
                },
            }),
        },
    }
})

export const StyledTextField = styled(TextField, { shouldForwardProp: (prop) => prop !== "hideOutline" })<{ hideOutline?: boolean }>(({
    theme,
    hideOutline,
}) => {
    const borderColor = theme.overwrites?.components?.textfield?.border?.color || theme.palette.primary.main
    const cutEdge = theme.overwrites?.components?.textfield?.cutEdge
    return {
        [`.${filledInputClasses.root}`]: {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor,
            ...(!hideOutline && { border: `1px solid ${borderColor}` }),
            borderRadius: theme.radius?.default || "3px",

            ...(!cutEdge && {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            }),
            fontSize: "18px",

            ...(cutEdge && {
                [`&::after`]: {
                    content: "''",
                    position: "absolute",
                    width: 0,
                    height: 0,
                    border: "7px solid transparent",
                    right: "-1px",
                    bottom: "-1px",
                    borderColor: "transparent #fff #fff transparent",
                },

                [`&::before`]: {
                    content: "''",
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth: "7px",
                    borderColor: `transparent  ${borderColor}  ${borderColor} transparent`,
                    background: theme.overwrites?.components?.textfield?.backgroundColor,
                },
            }),
        },
    }
})

export const InlineSearchButton = styled(SearchButton)(({ theme }) => {
    const cutEdge = theme.overwrites?.components?.textfield?.cutEdge
    return {
        "&.MuiButton-sizeMedium": {
            borderRadius: `${theme.radius?.default || "2px"} !important`,
            ...(!cutEdge && {
                borderTopLeftRadius: "0 !important",
                borderBottomLeftRadius: "0 !important",
            }),
            padding: "7.5px",
        },
    }
})

export const GroupHeader = styled(Box)(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "6px 10px",
    textTransform: "uppercase",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
}))
