import { CarParkResponse, RawCarParkResponse, RawCarparkAttribute, RawAttribute, RawAttributeHeader } from "./model"
import { CarPark, CarParkAttribute } from "../../model"

export function mapCarParkResponse(response: RawCarParkResponse): CarParkResponse {
    const carParks = response.carparkAttributes.map(mapCarparkAttributes)
    const headers = response.attributeHeaders.map(mapHeaders)
    const hasMultipleCarParks = checkMultipleCarParks(response.attributeHeaders)

    return {
        carParks,
        headers,
        hasMultipleCarParks,
    }
}

function mapCarparkAttributes(carparkAttributes: RawCarparkAttribute): CarPark {
    return {
        carparkId: carparkAttributes.carparkId,
        imageId2D: carparkAttributes.imageId,
        imageId3D: carparkAttributes.imageId3D,
        loadIndex: carparkAttributes.loadIndex,
        speedIndex: carparkAttributes.speedIndex,
        attributes: carparkAttributes.attributes.map(mapAttributes),
    }
}

function mapAttributes(carparkAttributes: RawAttribute): CarParkAttribute {
    return {
        attributeId: carparkAttributes.attributeId,
        description: carparkAttributes.description,
        value: carparkAttributes.attributeValue,
    }
}

function mapHeaders(carparkAttributes: RawAttributeHeader): CarParkAttribute {
    return {
        attributeId: carparkAttributes.attributeId,
        description: carparkAttributes.description,
    }
}

function checkMultipleCarParks(attributeHeaders: RawAttributeHeader[]): boolean {
    if (!attributeHeaders.length) {
        return false
    }

    return attributeHeaders.some((x) => x.attributeId && x.description)
}
