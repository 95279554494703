import { ETyresSeason, TyresSeason } from "@tm/models"

export const getCurrentSeason = () => {
    const date = new Date()
    let season: TyresSeason = ETyresSeason.winter
    const currentMonth = date.getMonth() + 1

    if (currentMonth >= 3 && currentMonth < 9) {
        season = ETyresSeason.summer
    }

    return season
}
