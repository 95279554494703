import { classes, useCountryCodeToLicensePlate, useStyle } from "@tm/context-distribution"
import { Button, Headline, LicensePlateField, RealTable, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ECounterType, VehicleShortInfo, VehicleType } from "@tm/models"
import { concat, TmaHelper } from "@tm/utils"
import { FC, useEffect, useState } from "react"
import { useSelectedVehicleLookup } from "../../../data/hooks/useSelectedLookupConfig"

type Props = {
    vehicleType: VehicleType
    vehicles: Array<VehicleShortInfo>
    selectedVehicle?: VehicleShortInfo
    limitItems?: boolean
    onSelect(vehicle: VehicleShortInfo): void
    onApply(item: VehicleShortInfo): void
    onDetailsClick?(item: VehicleShortInfo): void
}

const ITEMS_TO_SHOW_STEP_SIZE = 5

export const CustomerVehicles: FC<Props> = (props) => {
    const { translate, translateText, date } = useLocalization()

    const { vehicleType, vehicles, limitItems } = props

    const [selectedVehicle, setSelectedVehicle] = useState<VehicleShortInfo | undefined>()
    const [itemsToShow, setItemsToShow] = useState(ITEMS_TO_SHOW_STEP_SIZE)

    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicleType)
    const { plateCode } = useCountryCodeToLicensePlate(selectedVehicle?.countryCode)

    useEffect(() => {
        setSelectedVehicle(props.selectedVehicle)
    }, [props.selectedVehicle])

    function handleSelect(item: VehicleShortInfo) {
        if (!selectedVehicle || selectedVehicle.id != item.id) {
            setSelectedVehicle(item)
            props.onSelect(item)
        }
    }

    function handleApply(item: VehicleShortInfo) {
        props.onApply(item)
    }

    function handleDetailsClick(item: VehicleShortInfo, e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        props.onDetailsClick && props.onDetailsClick(item)
    }

    function showNextItems() {
        setItemsToShow((prev) => prev + ITEMS_TO_SHOW_STEP_SIZE)
    }

    function renderVehicleDescription(item: VehicleShortInfo) {
        return (
            <RealTable.Cell>
                <Text modifiers="strong">{concat(" ", item.manufacturer, item.modelSeries, item.model)}</Text>
            </RealTable.Cell>
        )
    }

    function renderLicensePlate(item: VehicleShortInfo) {
        return (
            <RealTable.Cell>
                {item.plateId && <LicensePlateField readonly size="s" shortCountryCode={plateCode} value={item.plateId} />}
            </RealTable.Cell>
        )
    }

    function renderVin(item: VehicleShortInfo) {
        return (
            <RealTable.Cell>
                <Text>{item.vin}</Text>
            </RealTable.Cell>
        )
    }

    function renderInitialRegistration(item: VehicleShortInfo) {
        return <RealTable.Cell>{item.initialRegistration && <Text>{date(item.initialRegistration, "d")}</Text>}</RealTable.Cell>
    }

    function renderCustomerName(item: VehicleShortInfo) {
        return (
            <RealTable.Cell>
                <Text>{concat(" ", item.firstName, item.lastName) ?? item.companyName}</Text>
            </RealTable.Cell>
        )
    }

    function renderCustomerNumber(item: VehicleShortInfo) {
        return (
            <RealTable.Cell>
                <Text>{item.customerNo}</Text>
            </RealTable.Cell>
        )
    }

    function renderActions(item: VehicleShortInfo) {
        const handleClick = () => {
            TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSelectVehicle)

            handleApply({
                ...item,
                registrationNoTypeId: item.registrationNoTypeId || selectedVehicleLookup.lookupTypeId,
                countryCode: selectedVehicleLookup.countryCode,
            })
        }

        return (
            <RealTable.Cell>
                {props.onDetailsClick && (
                    <Button size="s" title={translateText(43)} onClick={(e) => handleDetailsClick(item, e)}>
                        {translateText(43)}
                    </Button>
                )}
                <Button size="s" title={translateText(22)} onClick={handleClick}>
                    {translateText(22)}
                </Button>
            </RealTable.Cell>
        )
    }

    function getColumns() {
        return [
            <RealTable.Column
                key="column.customerVehicles.renderVehicleDescription"
                className={style.tableColumn}
                renderItemContent={renderVehicleDescription}
            >
                {translate(610)}
            </RealTable.Column>,
            <RealTable.Column
                key="column.customerVehicles.renderLicensePlate"
                className={classes(style.tableColumn, style.tableColumn__licensePlate)}
                renderItemContent={renderLicensePlate}
            >
                {translate(21)}
            </RealTable.Column>,
            <RealTable.Column key="column.customerVehicles.renderVin" className={style.tableColumn} renderItemContent={renderVin}>
                {translate(101)}
            </RealTable.Column>,
            <RealTable.Column
                key="column.customerVehicles.renderInitialRegistration"
                className={style.tableColumn}
                renderItemContent={renderInitialRegistration}
            >
                {translate(1367)}
            </RealTable.Column>,
            <RealTable.Column key="column.customerVehicles.renderCustomerName" className={style.tableColumn} renderItemContent={renderCustomerName}>
                {translate(107)}
            </RealTable.Column>,
            <RealTable.Column
                key="column.customerVehicles.renderCustomerNumber"
                className={style.tableColumn}
                renderItemContent={renderCustomerNumber}
            >
                {translate(102)}
            </RealTable.Column>,
            <RealTable.Column
                key="column.customerVehicles.renderActions"
                className={classes(style.tableColumn, style.tableColumn__actions)}
                renderItemContent={renderActions}
            />,
        ]
    }

    return (
        <div className={style.wrapper}>
            <Headline className={style.headline}>{translate(12504)}:</Headline>
            <Text modifiers="block" className={style.preText}>
                {translate(12505)}
            </Text>

            <RealTable
                className={style.table}
                data={limitItems ? vehicles.slice(0, itemsToShow - 1) : vehicles}
                columns={getColumns()}
                onClickRow={handleSelect}
                onDoubleClickRow={handleApply}
                getRowClassName={(item) => classes(style.tableRow, selectedVehicle?.id === item.id && "is-selected")}
            />

            {limitItems && itemsToShow < vehicles.length && (
                <Button icon="down" onClick={showNextItems} className={style.showMoreButton}>
                    {translate(12506)}
                </Button>
            )}
        </div>
    )
}

const style = useStyle({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1em",
    },
    headline: {},
    preText: {
        marginTop: "0.5em",
    },
    table: {},
    tableRow: {},
    tableColumn: {},
    tableColumn__licensePlate: {
        $nest: {
            ".input__inner": {
                padding: "0 5px",
            },
        },
    },
    tableColumn__actions: {
        whiteSpace: "nowrap",
        textAlign: "right",
    },
    showMoreButton: {
        margin: "0.5em auto 0 auto",
    },
})(CustomerVehicles)
