import { useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, RouteComponentProps } from "@tm/utils"
import { RepairTimeProvider } from "@tm/models"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { CategorySelectionTreeSelectionState, IActions, Actions, CategoryType } from "./business"
import CategoryList from "./helper-components/node-list"
import BreadcrumbsTable from "./helper-components/breadcrumbs-table"
import { RepairTimesRouteParams, SearchTreeNode } from "../../data"
import { ModuleOpenedPayload } from "../../business"
import getVehicle from "../../data/helpers/getVehicle"

type Props = RouteComponentProps<RepairTimesRouteParams> & {
    state: CategorySelectionTreeSelectionState
    actions: IActions
    provider: RepairTimeProvider
    moduleOpenedPayload?: ModuleOpenedPayload
}

function CategorySelectionTreeComponent(props: Props) {
    const { translateText } = useLocalization()
    const { categories, breadcrumbs } = props.state
    const { subCategoryId, mainCategoryId, workTaskId, repairTimeId, type } = props.match.params
    const context = useUser()?.userContext
    const workTask = useWorkTask()?.workTask
    const vehicle = getVehicle(workTask?.vehicle, context?.system.systemType)

    useEffect(() => {
        if (vehicle && vehicle.tecDocTypeId) {
            if ((!breadcrumbs.length && !categories.length) || !mainCategoryId) {
                // when the last breadcrumb is removed
                props.actions.loadCategories({
                    repairTimeProvider: props.provider,
                    parentNodeId: 0,
                    modelId: vehicle.tecDocTypeId,
                    vehicleType: vehicle.vehicleType,
                })
            }
        }
    }, [vehicle?.tecDocTypeId, vehicle?.vehicleType, mainCategoryId])

    const handleSelectCategory = (category: SearchTreeNode) => {
        props.actions.selectBreadcrumb(category)
    }

    useEffect(() => {
        if (vehicle && vehicle.tecDocTypeId) {
            if (mainCategoryId && categories.length) {
                const category = categories.find((x) => x.id === parseInt(mainCategoryId))

                if (!category) {
                    return
                }

                if (!breadcrumbs.some((breadcrumb) => breadcrumb.id === parseInt(mainCategoryId))) {
                    handleSelectCategory(category)
                } else {
                    props.actions.loadCategories({
                        repairTimeProvider: props.provider,
                        parentNodeId: parseInt(mainCategoryId),
                        modelId: vehicle.tecDocTypeId,
                        vehicleType: vehicle.vehicleType,
                    })
                }
            }
        }

        if (breadcrumbs && breadcrumbs.length && !mainCategoryId) {
            props.actions.resetBreadcrumb(CategoryType.main)
        }
    }, [mainCategoryId, breadcrumbs, categories])

    useEffect(() => {
        if (vehicle && vehicle.tecDocTypeId) {
            if (subCategoryId && categories.length) {
                const category = categories.find((x) => x.id === parseInt(subCategoryId))

                if (!category) {
                    return
                }

                if (!breadcrumbs.some((breadcrumb) => breadcrumb.id === parseInt(subCategoryId))) {
                    handleSelectCategory(category)
                }
            }
        }

        if (breadcrumbs && breadcrumbs.length > 1 && !subCategoryId) {
            props.actions.resetBreadcrumb(CategoryType.sub)
        }
    }, [breadcrumbs, subCategoryId, categories])

    const handleRedirect = (categoryId: number) => {
        const { history } = props

        const routeParams: RepairTimesRouteParams = {
            workTaskId,
            repairTimeId,
            mainCategoryId: !mainCategoryId ? categoryId.toString() : mainCategoryId,
            subCategoryId: mainCategoryId && (!subCategoryId || parseInt(subCategoryId) !== categoryId) ? categoryId.toString() : subCategoryId,
            type,
        }

        const pathname = renderRoute(props.match.path, routeParams)
        history.push(pathname)
    }

    const handleSelectNode = (categoryId: number) => {
        const { moduleOpenedPayload, actions } = props
        const category = categories.find((x) => x.id === categoryId)

        if (!category) {
            return
        }

        handleSelectCategory(category)
        handleRedirect(categoryId)
        if (moduleOpenedPayload) {
            actions.updateModuleURL(moduleOpenedPayload)
        }
    }

    const handleResetCategory = (categoryType: CategoryType) => {
        const {
            moduleOpenedPayload,
            actions: { resetBreadcrumb, updateModuleURL },
            history,
            match,
        } = props

        resetBreadcrumb(categoryType)

        const resetRouteParams: RepairTimesRouteParams = {
            ...match.params,
            mainCategoryId: categoryType === CategoryType.main ? undefined : mainCategoryId,
            subCategoryId: categoryType === CategoryType.main || categoryType === CategoryType.sub ? undefined : subCategoryId,
            repairTimeId: undefined,
        }

        const pathname = renderRoute(match.path, resetRouteParams)
        history.push(pathname)
        if (moduleOpenedPayload) {
            updateModuleURL(moduleOpenedPayload)
        }
    }

    return (
        <div className="repair-times__categories">
            <BreadcrumbsTable breadcrumbs={breadcrumbs} onReset={handleResetCategory} />
            {!subCategoryId && (
                <div className="node-list">
                    {!categories.length ? (
                        <Loader />
                    ) : (
                        <CategoryList
                            headlineNumber={translateText(130)}
                            headlineDescription={translateText(131)}
                            nodes={categories}
                            onSelectNode={handleSelectNode}
                            selectedNode={subCategoryId ? categories.find((x) => x.id === parseInt(subCategoryId)) : undefined}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default connectComponent(Actions, withRouter(CategorySelectionTreeComponent))
