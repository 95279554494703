import { AmountField, AmountItem, Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, Vehicle, RepairTimeProvider, IMicros, RequestArticleDetailsPayload } from "@tm/models"
import {
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    renderRoute,
    encodeUniqueId,
    uniqueId,
    RouteComponentProps,
    withRouter,
    getRepairTimeProviders,
} from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { useMicro } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { FC, useCallback, useState } from "react"
import { useDetailsInModal } from "../../../data/hooks"
import { MainState } from "../../main"

type Props = RouteComponentProps & {
    item: Article
    onDeleteClick(item: Article): void
    onQuantityChange(item: Article, value: AmountItem): void
    repairTimesRoute: string
    vehicle?: Vehicle
    detailsModalRoute: string
}

const OverviewItem: FC<Props> = ({ item, onDeleteClick, onQuantityChange, repairTimesRoute, vehicle, match, detailsModalRoute }) => {
    const { userSettings } = useUser() || {}
    const { handleDetailsPage } = useDetailsInModal(match)
    const { repairTimeProviders } = getRepairTimeProviders()

    const handleArticleDetails = useCallback(
        (item: Article, request: RequestArticleDetailsPayload) => {
            const params = {
                productGroupId: item.productGroup.id.toString(),
                supplierId: item.supplier.id.toString(),
                supplierArticleNo: item.supplierArticleNo.toString(),
                partsDetailsSubPage: request.subPage || "overview",
            }

            if (detailsModalRoute) {
                handleDetailsPage(item.quantity, params, detailsModalRoute)
            }
        },
        [item.internalId]
    )

    const getRepairTimesUrl = useCallback((article: Article, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
        if (article.productGroup && repairTimeProviders.length && userSettings) {
            let provider
            if (Array.isArray(rtProviders)) {
                const activeRTProvider = userSettings.activeVehicleDataProviders.repairTimes
                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider) {
                return
            }

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    workTaskId: encodeUniqueId(uniqueId()),
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide,
                })
            )
        }
    }, [])

    return (
        <PartItemComp
            onArticleDetailsClick={handleArticleDetails}
            article={item}
            onDeleteClick={onDeleteClick}
            onQuantityChange={onQuantityChange}
            getRepairTimesUrl={getRepairTimesUrl}
            vehicleId={vehicle?.id}
        />
    )
}

export default withRouter(OverviewItem)

type PartItemCompProps = {
    article: Article
    onDeleteClick: (item: Article) => void
    onQuantityChange: (item: Article, value: AmountItem) => void
    getRepairTimesUrl: (article: Article, rtProviders: RepairTimeProvider | RepairTimeProvider[]) => string | undefined
    vehicleId?: string
    onArticleDetailsClick: (item: Article, request: RequestArticleDetailsPayload) => void
}

const PartItemComp: FC<PartItemCompProps> = ({ article, onDeleteClick, onQuantityChange, getRepairTimesUrl, vehicleId, onArticleDetailsClick }) => {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()
    const [opened, setOpened] = useState(false)

    const providers = useSelector((s: MainState) => s.tyresList.repairTimeAvailabilities?.[article.productGroup?.id])

    const handleToggleOpened = () => {
        setOpened(!opened)
    }

    const handleQuantityChange = useCallback((amountItem: AmountItem) => {
        onQuantityChange(article, amountItem)
    }, [])

    const handleDelete = () => {
        onDeleteClick(article)
    }

    const handleDetails = (request: RequestArticleDetailsPayload) => {
        onArticleDetailsClick(article, request)
    }

    const renderActions = useCallback(() => {
        return (
            <div className="tk-basket">
                <div className="add-to-basket">
                    <div>
                        <AmountField value={article.quantity} onChange={handleQuantityChange} />
                        <Button title={translateText(624)} icon="delete" layout={["ghost"]} onClick={handleDelete} />
                    </div>
                    {renderMicro!("basket", "add-to-basket", {
                        data: [article],
                        buttonText: translateText(133),
                        hideQuantityField: true,
                    })}
                </div>
            </div>
        )
    }, [])

    return (
        <>
            {renderMicro!("parts", "part-item", {
                part: article,
                vehicleId,
                renderBuyActions: renderActions,
                rtProviders: providers,
                isCompact: !opened,
                showArticleImage: true,
                showActions: true,
                canFilterArticleAttributes: false,
                onToggleCollapse: handleToggleOpened,
                getRepairTimesUrl,
                onRequestArticleDetails: handleDetails,
            })}
        </>
    )
}
