import { useCallback, useEffect, useMemo, useState } from "react"
import { useCisCustomer, useWorkTask } from "@tm/context-distribution"
import { getLocalization } from "@tm/localization"
import { CisNote, CisNoteType, channel } from "@tm/models"
import { Box, Button, Dialog, Divider, Icon, IconButton, Stack, Tooltip, Typography } from "@tm/components"
import { getBundleParams } from "../../utils"

function getFilteredNotes(notes?: CisNote[]) {
    return notes?.filter(
        (note) => note.type === CisNoteType.Warning || note.type === CisNoteType.Information || note.type === CisNoteType.WarehouseInformation
    )
}

function PartnerNotesComponent() {
    const { translateText } = getLocalization()
    const { workTaskId, workTask } = useWorkTask() || {}
    const { activateOtherSalesOfficeHint } = getBundleParams()
    const { cisCustomer: partner } = useCisCustomer()
    const [showModal, setShowModal] = useState(false)

    const notes = useMemo(
        () => {
            if (!partner || !workTask || workTask.customer?.refCustomerNo !== partner.requestedCustomerNo) {
                return []
            }
            const result = getFilteredNotes(partner.notes) || []
            if (activateOtherSalesOfficeHint && (partner.state === 4 || partner.state === 5) && partner.stateDescription) {
                result.push({
                    type: CisNoteType.Warning,
                    text: partner.stateDescription,
                    description: "",
                    iconUrl: "",
                    url: "",
                    urlText: "",
                    imageUrl: "",
                })
            }
            return result
        },
        // Please don't add partner nor workTask to the deps. They're always gonna change, even if they have the same content 😒
        [partner?.customerNo, workTaskId]
    )

    useEffect(() => {
        if (notes.length) {
            setShowModal(true)
        }
    }, [notes])

    useEffect(() => {
        if (workTaskId) {
            return channel("WORKTASK", workTaskId).subscribe("PARTNER/OPEN_NOTES_MODAL", () => {
                setShowModal(true)
            })
        }
    }, [workTaskId])

    // Actually used to bring back the focus to the verhicle search
    const handleCloseModal = useCallback(() => {
        channel("TELESALES").publish("PARTNERNOTES/HIDE", true)
        setShowModal(false)
    }, [setShowModal])

    if (!notes.length) {
        return null
    }

    const warningNotes = notes.filter((note) => note.type === CisNoteType.Warning)
    const infoNotes = notes.filter((note) => note.type === CisNoteType.Information)
    const whInfoNotes = notes.filter((note) => note.type === CisNoteType.WarehouseInformation)

    type NoteTextProps = {
        text: string
    }
    function NoteText(props: NoteTextProps) {
        return (
            <Box maxWidth={700}>
                <Typography variant="body1" textAlign="justify" sx={{ wordBreak: "break-word" }}>
                    {props.text}
                </Typography>
            </Box>
        )
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault()
            handleCloseModal()
        }
    }

    return (
        <>
            <Tooltip title={translateText(12848)}>
                <IconButton onClick={() => setShowModal(true)} sx={{ padding: 0 }} color="error">
                    <Icon name="attention-dark" />
                </IconButton>
            </Tooltip>

            <Dialog
                open={showModal}
                onAbort={handleCloseModal}
                onOutsideClick={handleCloseModal}
                onKeyDown={(e) => handleOnKeyDown(e)}
                position="top"
                skin={warningNotes.length > 0 ? "error" : "info"}
                customSkinIcon={warningNotes.length > 0 ? <Icon name="attention-dark" color="error" size="24px" /> : undefined}
                maxWidth="lg"
            >
                <Stack spacing={2} pl={1} pr={2}>
                    <Typography variant="h2">{translateText(12848)}</Typography>
                    <Stack spacing={1} overflow="auto" maxHeight="85vh">
                        <Stack spacing={0.2}>
                            {warningNotes.map((note, index) => (
                                <NoteText key={`warning_${index}`} text={note.text} />
                            ))}
                        </Stack>

                        {!!warningNotes.length && !!infoNotes.length && <Divider />}

                        <Stack spacing={0.2}>
                            {infoNotes.map((note, index) => (
                                <NoteText key={`info_${index}`} text={note.text} />
                            ))}
                        </Stack>

                        {(!!warningNotes.length || !!infoNotes.length) && !!whInfoNotes.length && <Divider />}

                        <Stack spacing={0.2}>
                            {whInfoNotes.map((note, index) => (
                                <NoteText key={`whInfo_${index}`} text={note.text} />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
                <Box display="flex">
                    <Button color="primary" onClick={handleCloseModal} size="large">
                        {translateText(316)}
                    </Button>
                </Box>
            </Dialog>
        </>
    )
}

export default PartnerNotesComponent
