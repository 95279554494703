import { Button, SimpleCard, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { TechnicalData } from "./rim-details-tabs-v2/technicalData"
import { MountingPads } from "./rim-details-tabs-v2/mountingPads"
import { Accessories } from "./rim-details-tabs-v2/accessories"
import { MainState } from "../../main"

enum RimDetailsTabs {
    TechnicalDataTab = "TechnicalDataTab",
    MountingPadsTab = "MountingPadsTab",
    AccessoriesTab = "AccessoriesTab",
}
export function RimInformationV2() {
    const { translateText } = useLocalization()
    const [selectedTab, setSelectedTab] = useState<RimDetailsTabs>(RimDetailsTabs.TechnicalDataTab)
    const { selectedRimDetailsArticle, rimInfoTireRestrictionsResponse, rimInfoRimRestrictionsResponse, loading } = useSelector((s: MainState) => ({
        selectedRimDetailsArticle: s.wheelsDetails.selectedRimDetailsArticle,
        rimInfoTireRestrictionsResponse: s.wheelsDetails.rimInfoTireRestrictionsResponse,
        rimInfoRimRestrictionsResponse: s.wheelsDetails.rimInfoRimRestrictionsResponse,
        loading: s.wheelsDetails.rimDetailsArticlesResponse.loading,
    }))

    useEffect(() => {
        setSelectedTab(RimDetailsTabs.TechnicalDataTab)
    }, [selectedRimDetailsArticle])

    const renderTabControl = () => {
        const mountingPadsDisabled =
            loading ||
            rimInfoTireRestrictionsResponse.loading ||
            rimInfoRimRestrictionsResponse.loading ||
            (!rimInfoTireRestrictionsResponse?.data?.length && !rimInfoRimRestrictionsResponse?.data?.length)

        return (
            <Stack direction="row" ml="2em">
                <Button
                    color={selectedTab === RimDetailsTabs.TechnicalDataTab ? "highlight" : "secondary"}
                    onClick={() => setSelectedTab(RimDetailsTabs.TechnicalDataTab)}
                    size="extralarge"
                >
                    {translateText(941)}
                </Button>
                <Button
                    color={selectedTab === RimDetailsTabs.MountingPadsTab ? "highlight" : "secondary"}
                    onClick={() => setSelectedTab(RimDetailsTabs.MountingPadsTab)}
                    size="extralarge"
                    sx={{ marginLeft: ".2em" }}
                    disabled={mountingPadsDisabled}
                >
                    {translateText(942)}
                </Button>
                <Button
                    color={selectedTab === RimDetailsTabs.AccessoriesTab ? "highlight" : "secondary"}
                    disabled={!selectedRimDetailsArticle?.fixture}
                    onClick={() => setSelectedTab(RimDetailsTabs.AccessoriesTab)}
                    size="extralarge"
                    sx={{ marginLeft: ".2em" }}
                >
                    {translateText(13802)}
                </Button>
            </Stack>
        )
    }

    const renderActivePageContent = () => {
        switch (selectedTab) {
            case RimDetailsTabs.TechnicalDataTab:
                return <TechnicalData />
            case RimDetailsTabs.MountingPadsTab:
                return <MountingPads />
            case RimDetailsTabs.AccessoriesTab:
                return <Accessories />
            default:
                return <TechnicalData />
        }
    }

    return (
        <Stack>
            {renderTabControl()}
            <SimpleCard sxContent={{ padding: "0 1em", height: "18em" }} sx={{ padding: "0 0.5em 0.75em" }}>
                {renderActivePageContent()}
            </SimpleCard>
        </Stack>
    )
}
