export const VOLTAGE_THRESHOLD_WARNING = 13
export const VOLTAGE_THRESHOLD_DANGER = 11.7

export const getBatteryVoltageIconName = (voltage: number): string => {
    let iconName = "warning-dark"

    if (voltage >= VOLTAGE_THRESHOLD_WARNING) {
        iconName = "success-dark"
    } else if (voltage < VOLTAGE_THRESHOLD_DANGER) {
        iconName = "error-filled"
    }

    return iconName
}

export const getBatteryVoltageIconSkin = (voltage: number): "warning" | "success" | "danger" => {
    let skin: "warning" | "success" | "danger" = "warning"

    if (voltage >= VOLTAGE_THRESHOLD_WARNING) {
        skin = "success"
    } else if (voltage < VOLTAGE_THRESHOLD_DANGER) {
        skin = "danger"
    }

    return skin
}
