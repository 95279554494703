import { Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { StackRow } from "../../StyledComponents"
import DialogHeaderButtons from "../DialogHeaderButtons"

type Props = {
    disableConfirmButton: boolean
    disableResetButton: boolean
    onCloseButtonClick(): void
    onConfirmButtonClick(): void
    onResetButtonClick(): void
}

export default function DialogHeader(props: Props) {
    const { disableConfirmButton, disableResetButton, onConfirmButtonClick, onCloseButtonClick, onResetButtonClick } = props

    const { translateText } = useLocalization()

    return (
        <StackRow justifyContent="space-between" alignItems="flex-start">
            <Typography variant="h2">{translateText(13929)}</Typography>
            <DialogHeaderButtons
                disableConfirmButton={disableConfirmButton}
                disableResetButton={disableResetButton}
                onCloseButtonClick={onCloseButtonClick}
                onConfirmButtonClick={onConfirmButtonClick}
                onResetButtonClick={onResetButtonClick}
            />
        </StackRow>
    )
}
