import { FC, memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Scrollbar } from "@tm/controls"
import { useDisplayListV2 } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { Article, RegisteredModels, SearchFilters } from "@tm/models"
import { Container } from "@tm/nexus"
import { getBundleParams } from "../../utils"
import VehiclePanel from "./components/vehicle-panel"
import { Actions } from "./business"
import { MainState } from "../main"
import { OverviewList } from "./components/OverviewList"
import { OverviewListV2 } from "./components/OverviewListV2"

export type OverviewComponentProps = {
    repairTimesRoute: string
    detailsModalRoute: string
}

const Overview: FC<OverviewComponentProps> = ({ repairTimesRoute, detailsModalRoute }) => {
    const isHostettler = getBundleParams()?.isHostettler
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const displayListV2 = useDisplayListV2()
    const dispatch = useDispatch()

    const { rimItem, tireItem, mountingEnabled, mountingItem } = useSelector((s: MainState) => ({
        rimItem: s.overview.rimItem,
        tireItem: s.overview.tireItem,
        mountingEnabled: s.overview.mountingEnabled,
        mountingItem: s.overview.mountingItem,
    }))

    function searchMountItem(query: string) {
        const request = { query, searchFilter: SearchFilters.All }

        if (mountingItem) {
            return
        }

        dispatch(Actions.setOverviewLoading(true))

        Container.getInstance<Array<Article>>(RegisteredModels.Articles)
            .subscribe(request)
            .load()
            .then((articles) => {
                if (articles && articles.length >= 1) {
                    dispatch(Actions.setMountingItem({ ...articles[0], quantity: 4 }))
                }

                dispatch(Actions.setOverviewLoading(false))
            })
    }

    useEffect(() => {
        if (isHostettler && mountingEnabled) {
            if (rimItem && tireItem) {
                searchMountItem("E995-KOMPLETTRAD")
            }
        }
    }, [rimItem, tireItem, isHostettler, mountingEnabled])

    return (
        <div className="wheels__overview__page">
            <Scrollbar>
                <VehiclePanel repairTimesRoute={repairTimesRoute} />
                {displayListV2 && !isWm ? (
                    <OverviewListV2 />
                ) : (
                    <OverviewList repairTimesRoute={repairTimesRoute} detailsModalRoute={detailsModalRoute} />
                )}
            </Scrollbar>
        </div>
    )
}

export default memo(Overview)
