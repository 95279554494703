import { useLocalization } from "@tm/localization"
import { Divider, Stack, Typography } from "@tm/components"

type Props = {
    totalNet?: number
    totalGross?: number
    currencyCode?: string
}

export default function Totals({ totalGross, totalNet, currencyCode }: Props) {
    const { currency, translateText } = useLocalization()
    if (!totalGross && !totalNet) {
        return null
    }

    return (
        <Stack spacing={0.5}>
            <Divider />
            <Stack alignItems="flex-end">
                <Stack direction="row" gap={8} pr={2}>
                    <Typography>{translateText(495)}</Typography> <Typography>{currency(totalNet ?? 0, currencyCode ?? "")}</Typography>
                </Stack>
                <Stack direction="row" gap={8} pr={2}>
                    <Typography>{translateText(494)}</Typography> <Typography>{currency(totalGross ?? 0, currencyCode ?? "")}</Typography>
                </Stack>
            </Stack>
            <Divider />
        </Stack>
    )
}
