import { Scrollbar } from "@tm/controls"
import { FC, PropsWithChildren, useEffect, useRef } from "react"
import { scrollPosition } from "../../helpers/subject"

type ScrollbarType = PropsWithChildren<{
    container?: Element
    scrollTop?(top?: number): void
    view?: any
}>

const ScrollBarWrapper: FC<ScrollbarType> = ({ children }) => {
    const scrollbarRef = useRef<ScrollbarType | null>(null)

    useEffect(() => {
        const sub = scrollPosition.subscribe({
            next: (val) => {
                scrollbarRef.current?.view?.scroll({ top: val, behavior: "smooth" })
            },
        })
        return () => {
            sub.unsubscribe()
        }
    }, [])

    const handleRef = (ref: ScrollbarType | null) => {
        scrollbarRef.current = ref
    }

    return <Scrollbar onRef={handleRef}>{children}</Scrollbar>
}

export default ScrollBarWrapper
