import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { WorkCategory, WorkType, VatRate, CustomWork } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { Dialog, Typography, Box, Button } from "@tm/components"
import WorkDialogFields from "./WorkDialogFields"
import { EditWorkMode } from "../../../business"
import EditButton from "../../../../_shared/EditButton"
import { createCustomWork } from "../../../../../data/mapper"
import { addCustomRepairTimeItems } from "../../../../../data/repositories/custom-items/repair-times"
import { BasicBasketWorkInfo, BasketWork } from "../../../../../models"
import { StackRow } from "../../../../StyledComponents"
import { AddCustomRepairTimeItemsRequest, CostEstimation } from "../../../../../data/model"

type Props = {
    disabled?: boolean
    costEstimation?: CostEstimation
    repairTimeDivision: number
    showRepairTimesInHours: boolean
    work: BasketWork
    workTask: WorkTaskInfo
    onEditWork(work: BasketWork, repairTimeDivision: number, editWorkMode: EditWorkMode, editBasketWorkInfo: BasicBasketWorkInfo): void
    onReplaceWorkWithCustomWork(workToReplace: BasketWork, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string): void
}

export default function WorkDialogTrigger(props: Props) {
    const { work, workTask, showRepairTimesInHours, costEstimation, repairTimeDivision, disabled } = props
    const { workItem, calculatedWork } = work
    const { hourlyRates, costEstimationOptions } = costEstimation ?? {}
    const { translateText } = useLocalization()
    const [actualWorkNumber, setWorkNumber] = useState<string>("")
    const [actualDescription, setDescription] = useState<string>("")
    const [actualTime, setTime] = useState<number | undefined>(workItem.timeInMinutes)
    const [actualHourlyRate, setHourlyRate] = useState<number | undefined>(
        workItem.hourlyRate || hourlyRates?.[WorkCategory.WorkshopWork] || hourlyRates?.[WorkCategory.NotCategorized]
    )
    const [actualFixedPriceValue, setFixedPriceValue] = useState<number | undefined>(calculatedWork?.regularPrice?.value)
    const [actualWorkCategory, setWorkCategory] = useState<WorkCategory>(WorkCategory.WorkshopWork)
    const [actualWorkVatRate, setWorkVatRate] = useState<VatRate | undefined>(calculatedWork?.vatRate)
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
    const [showEditWarning, setShowEditWarning] = useState(false)

    function handleEditWorkClick(_saveAsCustomRepairTime: boolean, editBasketWorkInfo: BasicBasketWorkInfo, editWorkMode?: EditWorkMode) {
        const { categoryOfWork, description, workNumber, time, hourlyRate, fixedPriceValue, vatRate } = editBasketWorkInfo
        if (editWorkMode === EditWorkMode.editCustomWork || editWorkMode === EditWorkMode.editRepairTime) {
            props.onEditWork(work, repairTimeDivision, editWorkMode, editBasketWorkInfo)

            if (editWorkMode === EditWorkMode.editCustomWork && _saveAsCustomRepairTime) {
                const request: AddCustomRepairTimeItemsRequest = {
                    customRepairTimeItemsToAdd: [
                        {
                            workId: workNumber,
                            category: categoryOfWork,
                            description,
                            workTime: time,
                            customHourlyRate: hourlyRate,
                            fixedPriceValue,
                            vatRate,
                        },
                    ],
                    repairTimeDivision,
                    priceVatMode: costEstimationOptions?.priceVatMode,
                }
                addCustomRepairTimeItems(request)
            }
        } else {
            setWorkNumber(workNumber)
            setTime(time)
            setDescription(description)
            setWorkVatRate(vatRate)
            setFixedPriceValue(fixedPriceValue)
            setHourlyRate(hourlyRate)
            setWorkCategory(categoryOfWork)
            setShowEditWarning(true)
        }
    }

    function handleEditWarningConfirm() {
        props.onReplaceWorkWithCustomWork(
            work,
            repairTimeDivision,
            createCustomWork(
                actualWorkNumber,
                actualWorkCategory,
                actualDescription,
                actualTime,
                actualHourlyRate,
                actualFixedPriceValue,
                workItem.rebate,
                workItem.surcharge,
                actualWorkVatRate
            ),
            workTask.vehicle?.id
        )
        // props.onCloseWorkEditor(workItem.id)
    }

    let title = workItem.providerName || ""
    let icon = "repairtimes"
    let iconTooltip = translateText(83)
    let showSaveAsCustomRepairTimeButton = false
    switch (workItem.type) {
        case WorkType.CustomWork: {
            title = translateText(920)
            icon = "individual-repairtimes"
            iconTooltip = translateText(920)
            showSaveAsCustomRepairTimeButton = true
            break
        }
        case WorkType.CustomMainWork: {
            icon = "repairtime-modified"
            iconTooltip = translateText(1473)
            break
        }
        default:
            break
    }

    if (!costEstimation) {
        return null
    }

    return (
        <>
            <EditButton onClick={() => setShowEditDialog(true)} disabled={disabled} />
            <Dialog open={showEditDialog} position="middle" fullWidth maxWidth="lg">
                <WorkDialogFields
                    currencyCode={workItem.currencyCode}
                    work={work}
                    costEstimation={costEstimation}
                    icon={icon}
                    iconTooltip={iconTooltip}
                    repairTimeDivision={repairTimeDivision}
                    showRepairTimesInHours={showRepairTimesInHours}
                    showSaveAsCustomRepairTimeButton={showSaveAsCustomRepairTimeButton}
                    title={title}
                    onClose={() => setShowEditDialog(false)}
                    onConfirm={handleEditWorkClick}
                />
            </Dialog>
            <Dialog open={showEditWarning} skin="warning" position="top" fullWidth>
                <StackRow spacing={2}>
                    <Typography>{translateText(933)}</Typography>
                    <Box textAlign="center" display="flex" flexWrap="nowrap">
                        <Button variant="contained" onClick={() => setShowEditWarning(false)}>
                            {translateText(584)}
                        </Button>
                        <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleEditWarningConfirm}>
                            {translateText(585)}
                        </Button>
                    </Box>
                </StackRow>
            </Dialog>
        </>
    )
}
