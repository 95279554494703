import { ESufficentSelections } from "../../../../data/enums"
import { ImagePositionsRequest } from "../../../../data/repositories"
import { MainState } from "../../../main"

export function createImagePositionRequest(state: MainState): ImagePositionsRequest | undefined {
    const {
        details: { selectedVKNImage, mmtList, savedCriterias },
        summary: { selectedMmtGroup },
        manager: { vehicle },
    } = state
    if (selectedVKNImage?.imageName && selectedMmtGroup?.selectedErePosition) {
        return {
            imageName: selectedVKNImage.imageName,
            mmt: (mmtList.sufficientSelection == ESufficentSelections.Distinct && mmtList.items[0]) || selectedMmtGroup.mmtList[0],
            criteria: savedCriterias[vehicle?.tecDocTypeId!],
            positions: selectedMmtGroup?.selectedErePosition,
        }
    }
}
