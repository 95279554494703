import { Stack } from "@tm/components"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { ContinueButton } from "./continueButton"
import { RimDetailsHeaderV2 } from "./rimDetailsHeaderV2"
import { MainState } from "../../main"
import { Actions } from "../business"
import { RimInformationV2 } from "./rimInformationV2"

type WheelsDetailsComponentProps = {
    repairTimesRoute: string
}
export function RimDetailsV2({ repairTimesRoute }: WheelsDetailsComponentProps) {
    const dispatch = useDispatch()

    const { selectedSize, initialized } = useSelector((s: MainState) => s.wheelsDetails)

    useEffect(() => {
        selectedSize && !initialized && dispatch(Actions.loadRimDetailsArticles())
    }, [initialized, selectedSize])

    return (
        <Stack flex={1}>
            <ContinueButton />
            <Stack direction="column">
                <RimDetailsHeaderV2 repairTimesRoute={repairTimesRoute} />
                <RimInformationV2 />
            </Stack>
        </Stack>
    )
}
