import { useCallback, useEffect, useState } from "react"
import { renderRoute, encodeUniqueId, uniqueId, TmaHelper } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { channel, RequestArticleListPayload, TreeNode } from "@tm/models"
import { useHistory, useParams } from "react-router"
import { useWorkTaskId } from "@tm/context-distribution"
import { Searchtree, SearchtreeTip } from "@tm/components"
import SearchField from "../search-field"
import { SearchTree } from "../../widget/business"
import { getBundleParams } from "../../../utils"
import { Repositories } from "../../../data"
import useBreadCrumbHandler from "../../../hooks/breadCrumbhandler/useBreadCrumbHandler"

type Props = {
    universalSearchRoute: string
    tree?: SearchTree
    onApplyProductGroups?(productGroups: number[]): void
    hideBestseller?: boolean
    showTreeTip?: boolean
}

export default function UniSearchWidget(props: Props) {
    const { tree, showTreeTip } = props
    const params = useParams()
    const workTaskId = useWorkTaskId()
    const history = useHistory()
    const { newBreadcrumbSelected, breadCrumbState, isLoading, resetSelectedNode } = useBreadCrumbHandler(undefined, "universalParts")
    const { searchtreeNodeVariant } = getBundleParams()
    const mode = searchtreeNodeVariant === "fastClick" ? "fastClick" : "fast-click-2.0"
    const { translateText } = useLocalization()
    const [currentShortcutKey, setCurrentShortcutKey] = useState<string>("")

    useEffect(() => {
        const unsubscribe = channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (rez.key === "UNI") {
                setCurrentShortcutKey(rez.key + Math.random())
            }
        })
        return unsubscribe
    }, [])

    const handleUniSearch = useCallback(
        (query: string) => {
            const url = renderRoute(props.universalSearchRoute, {
                ...params,
                workTaskId: workTaskId || encodeUniqueId(uniqueId()),
            })
            TmaHelper.UniParts.Search.KeywordSearchFromStartPage(query, `${url}?query=${query}`)
            history.push(`${url}?query=${query}`)
        },
        [history, params, props.universalSearchRoute, workTaskId]
    )

    const handleNodeClick = useCallback(
        (node: TreeNode, fastClick?: boolean) => {
            newBreadcrumbSelected(node)
            if ((!node.hasChildNodes && breadCrumbState.treeId) || (fastClick && breadCrumbState.treeId)) {
                const request: RequestArticleListPayload = {
                    uniNode: { treeId: breadCrumbState.treeId, nodeId: node.id, history: [] },
                }

                TmaHelper.UniParts.SearchTree.NodeSelected({ mode, nodeId: node.id, treeId: breadCrumbState.treeId })
                resetSelectedNode()
                channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
            }
        },
        [breadCrumbState.treeId, newBreadcrumbSelected, resetSelectedNode]
    )

    const handleOnBreadCrumbClick = useCallback(
        (node: TreeNode) => {
            newBreadcrumbSelected(node)
        },
        [newBreadcrumbSelected]
    )

    const renderTree = () => {
        if (!tree) {
            return null
        }

        return (
            <Searchtree
                tree={breadCrumbState.initialTree}
                breadCrumbs={breadCrumbState.breadCrumbs}
                selectedNode={breadCrumbState?.selectedNode}
                applyProductGroups={props.onApplyProductGroups}
                showBestseller={!props.hideBestseller}
                onNodeClick={handleNodeClick}
                onBreadCrumbClick={handleOnBreadCrumbClick}
                loading={isLoading}
                variant="full"
                layout="small"
                hideSecondLevel
            />
        )
    }

    return (
        <>
            <div className="upper">
                <SearchField
                    key={currentShortcutKey}
                    placeholder={translateText(194)}
                    minLengthForSearch={getBundleParams().minimumSearchLength.universalSearch}
                    onSearch={handleUniSearch}
                    autofocus
                    autofocusTimeout={100}
                    getSuggestions={(query) => Repositories.getUniProductGroupSuggestions(query)}
                />
                {mode === "fast-click-2.0" && showTreeTip && <SearchtreeTip />}
            </div>

            {renderTree()}
        </>
    )
}
