import { ChangeEvent, memo, useCallback, useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useDeleteNote, useSaveNote } from "@tm/utils"
import { Box, DefaultIcon as MuiIcon, Typography } from "@tm/components"
import { Button, Icon, Loader, Skeleton } from "@tm/controls"
import { ConfigParams, Note, NoteTypes } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { FormControlFullWidth } from "./styled-components/formcontrol-full-width"
import { TextFieldWithInlineCounter } from "./styled-components/textfield-with-inline-counter"
import { FormHelperTextInline } from "./styled-components/formhelpertext-inline"

interface IProps {
    label: string
    noteId: string
    note?: Note
    placeholder: string
    itemIcon: string
    isLoading: boolean
    noteType: NoteTypes
}
const MAXINPUTCHARS = 500
export const NoteItem = memo<IProps>(({ label, noteId, placeholder, note, isLoading, itemIcon, noteType }) => {
    const { translateText } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [value, setValue] = useState<string>()
    const { saveNote, saveNoteInProgress } = useSaveNote()
    const { deleteNote, deleteNoteInProgress } = useDeleteNote()

    useEffect(() => {
        if (note?.text && !value) {
            setValue(note?.text)
        }
    }, [note?.text, value])

    const onSave = useCallback(async () => {
        if (!value) {
            return
        }

        await saveNote({
            noteId,
            type: noteType,
            text: value,
            version: note?.version,
        })
    }, [value, noteType, saveNote, noteId, note?.version])

    const onDelete = useCallback(async () => {
        await deleteNote(noteId)
        setValue("")
    }, [deleteNote, noteId])

    const handleOnInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value)
    }

    return (
        <Box pt={1} display="flex">
            <Box flex={2} alignItems="center" display="flex" textAlign="right">
                <Box pr={1}>
                    <MuiIcon>
                        <Icon name={itemIcon} size="s" />
                    </MuiIcon>
                </Box>
                <Typography>{label}</Typography>
            </Box>
            <Box flex={9}>
                {isLoading ? (
                    <Skeleton variant="text" height="40px" />
                ) : (
                    <FormControlFullWidth>
                        <TextFieldWithInlineCounter
                            id="component-note-text-small"
                            value={value}
                            placeholder={placeholder}
                            maxRows={5}
                            size="small"
                            multiline
                            fullWidth
                            inputProps={{
                                maxLength: MAXINPUTCHARS,
                            }}
                            onChange={handleOnInputChange}
                        />
                        <FormHelperTextInline aria-describedby="component-note-text-small">
                            {`${(value || "").length} / ${MAXINPUTCHARS}`}
                        </FormHelperTextInline>
                    </FormControlFullWidth>
                )}
            </Box>
            <Box pl={1} margin="auto 0" textAlign="right">
                {deleteNoteInProgress ? (
                    <Box px={1} display="inline-block">
                        <Loader visible delay={0} />
                    </Box>
                ) : (
                    <Button
                        title={translateText(69)}
                        onClick={onDelete}
                        size="s"
                        icon="delete"
                        disabled={isLoading || deleteNoteInProgress || !note?.text}
                        skin={deleteButtonColorError ? "danger" : undefined}
                    />
                )}
                {saveNoteInProgress ? (
                    <Box pl={0.6} display="inline-block">
                        <Loader visible delay={0} />
                    </Box>
                ) : (
                    <Button
                        title={translateText(9)}
                        onClick={onSave}
                        size="s"
                        icon="check"
                        skin="success"
                        disabled={isLoading || !value || value === note?.text}
                    />
                )}
            </Box>
        </Box>
    )
})
