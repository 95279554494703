import { Box, Button, Icon, Loader, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { batch, useDispatch, useSelector } from "react-redux"
import { Tyre, TyreSelection } from "@bundles/tyres/data/models"
import { Text } from "@tm/controls"
import { EFilterNames, ETyresSeason } from "@tm/models"
import { createSpecificQuery } from "@bundles/tyres/data/helpers"
import { Statics } from "@bundles/tyres/data/statics"
import { TmaHelper } from "@tm/utils"
import { useEffect, useState } from "react"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { TyreListAccordion } from "./TyreListAccordion"

type Props = {
    handleVehicleRedirect: () => void
    sameSizeTyres: Tyre[]
    mixSizeTyres: Tyre[]
    error: boolean | undefined
    loading: boolean | undefined
}

export function SuggestionsV2({ handleVehicleRedirect, sameSizeTyres, mixSizeTyres, error, loading }: Props) {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const [mixSizeTyresState, setMixSizeTyresState] = useState(mixSizeTyres)
    const vehicle = useSelector((state: MainState) => state.manager.vehicle)

    useEffect(() => {
        setMixSizeTyresState(mixSizeTyres)
    }, [mixSizeTyres])

    const handleVariantSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tyreItem: Tyre, selection: TyreSelection) => {
        e.stopPropagation()
        const updatedItems = mixSizeTyresState.map((item) => (item === tyreItem ? { ...item, selection } : item))
        setMixSizeTyresState(updatedItems)
    }

    const handleTyreSelect = (tyreItem: Tyre, season?: ETyresSeason) => {
        const queryFront = `${tyreItem.sizeFront} ${createSpecificQuery(tyreItem.loadIndexFront, tyreItem.speedIndexFront)}`.trim()
        const queryRear = `${tyreItem.sizeRear} ${createSpecificQuery(tyreItem.loadIndexRear, tyreItem.speedIndexRear)}`.trim()
        const query = tyreItem.selection === "front" ? queryFront : queryRear

        const params = { size: query, season: season ? [season] : [], carType: undefined }
        const seasonFilter = Statics.seasons.find((x) => x.query === season)

        TmaHelper.UniParts.Search.TyresSelection(query)

        batch(() => {
            if (seasonFilter) {
                dispatch(MainActions.resetSummaryFilter(EFilterNames.season, true))
                dispatch(MainActions.updateSummaryFilters(EFilterNames.season, seasonFilter))
            }
            dispatch(MainActions.updateSearchValue(query))
            dispatch(MainActions.loadTyresList(false, false, true, true))
            dispatch(MainActions.changeStep("list", params))
        })
    }

    if (!vehicle) {
        return (
            <StyledWrapper>
                <VehicleIcon name="vehicle-front" />
                <Typography variant="h2">{translateText(12720)}</Typography>
                <Button onClick={handleVehicleRedirect} sx={{ marginTop: "1.5em" }} startIcon={<Icon name="add" />}>
                    <Typography variant="body1">{translateText(457)}</Typography>
                </Button>
            </StyledWrapper>
        )
    }

    if (loading) {
        return (
            <StyledWrapper>
                <Loader />
            </StyledWrapper>
        )
    }

    if (error) {
        return (
            <StyledWrapper>
                <Text modifiers={["block"]}>{translateText(163)}</Text>
            </StyledWrapper>
        )
    }

    return (
        <>
            {!!sameSizeTyres?.length && <TyreListAccordion tyreList={sameSizeTyres} handleTyreSelect={handleTyreSelect} sameSize />}
            {!!mixSizeTyresState?.length && (
                <TyreListAccordion
                    tyreList={mixSizeTyresState}
                    handleTyreSelect={handleTyreSelect}
                    handleVariantSelect={handleVariantSelect}
                    sameSize={false}
                />
            )}
        </>
    )
}

const StyledWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 1 100%",
})

const VehicleIcon = styled(Icon)({
    height: "10em",
    width: "10em",
    marginTop: "3em",
})
