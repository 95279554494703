import { useQuery } from "react-query"
import { getMemoInfo } from "../repositories"

const KEY = "memotool_useGetMemoInfo"
export function useGetMemoInfo(manufacturerName?: string, vehicleId?: string) {
    const queryEnabled = !!manufacturerName && !!vehicleId

    const { data, isLoading, error } = useQuery(
        [KEY, manufacturerName],
        () => {
            return getMemoInfo(manufacturerName!)
        },
        { staleTime: 30 * 60 * 1000, cacheTime: 30 * 60 * 1000, enabled: queryEnabled, retry: 2 }
    )

    return { repairInstructions: data?.repairInstructions, isLoading, error }
}
