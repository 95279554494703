import { useLocalization } from "@tm/localization"
import { renderRoute, ButtonKeyDefinition, createQueryString } from "@tm/utils"
import { Widget, InputGroup } from "@tm/controls"
import { CisVoucherType } from "@tm/models"
import { useShowCisOptions } from "@tm/context-distribution"
import { Box, Divider, LinkButton, Loader, Typography, TextField, Icon, Stack } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { ChangeEvent, useState, KeyboardEvent } from "react"
import { CompletedOrderListItem, OpenOrderListItem } from "../../data/model"
import { useClosedOrdersRecent } from "../../data/hooks/useClosedOrdersRecent"
import { useOpenOrdersRecent } from "../../data/hooks/useOpenOrdersRecent"
import { TypographyTitle, VerticalDivider, RowStack } from "../_shared/StyledComponents"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"
import StgOrdersTable from "../_shared/tables/StgOrdersTable"

type Props = {
    subpageRoute: string
    titleTextId: string
}
type RouteProps = {
    subpage?: string
    voucherTypeId?: string
    id?: string
    subId?: string
}

export default function WidgetStgOrdersComponent(props: Props) {
    const { subpageRoute, titleTextId } = props
    const { translateText, translate } = useLocalization()
    const matchParams = useParams<RouteProps>()
    const history = useHistory()
    const { cisOptions } = useShowCisOptions()

    const { openOrders, openOrdersLoading } = useOpenOrdersRecent()
    const { closedOrders, closedOrdersLoading } = useClosedOrdersRecent()

    const [searchText, setSearchText] = useState<string>()

    function handleSearchTextChange({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) {
        if (value && value.length >= 40) {
            return
        }
        setSearchText(value)
    }

    function handleArticleNumberKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter && searchText) {
            let url = renderRoute(subpageRoute, { ...matchParams, subpage: "vouchers", voucherTypeId: CisVoucherType.ArticleSearchInOrders })
            url += createQueryString({ query: encodeURIComponent(searchText) })
            history.push(url)
        }
    }

    function renderDetailsButton(url: string, disabled?: boolean) {
        return (
            <Box display="flex" justifyContent="flex-end" sx={{ transform: "translateY(.5rem)" }}>
                <LinkButton to={url} disabled={disabled}>
                    {translate(43)}
                </LinkButton>
            </Box>
        )
    }

    function renderVouchers(cisVoucherType: CisVoucherType) {
        const voucherType = cisOptions?.voucherTypes?.find((type) => type.typeId === cisVoucherType)

        if (voucherType) {
            const url = renderRoute(subpageRoute, { ...matchParams, subpage: "vouchers", voucherTypeId: voucherType.typeId })

            const vouchersLoading = cisVoucherType === CisVoucherType.ClosedOrders ? closedOrdersLoading : openOrdersLoading
            const vouchers = cisVoucherType === CisVoucherType.ClosedOrders ? closedOrders : openOrders

            return (
                <Box width="50%">
                    <TypographyTitle>{voucherType.description}</TypographyTitle>
                    {vouchersLoading ? (
                        <Box height="11.5em" display="flex" alignContent="center">
                            <Loader />
                        </Box>
                    ) : (
                        <Box height="11.5em" display="flex">
                            <StgOrdersTable
                                orders={vouchers as (OpenOrderListItem | CompletedOrderListItem)[]}
                                variant="small"
                                cisVoucherType={cisVoucherType}
                                singleSearch
                            />
                        </Box>
                    )}
                    {renderDetailsButton(url, vouchersLoading)}
                </Box>
            )
        }
        return <Typography>COMING SOON</Typography>
    }

    function renderSearchItemInOrders() {
        const voucherType = cisOptions?.voucherTypes?.find((type) => type.typeId === CisVoucherType.ArticleSearchInOrders)

        if (voucherType) {
            const url = renderRoute(subpageRoute, { ...matchParams, subpage: "vouchers", voucherTypeId: voucherType.typeId })
            return (
                <Box flex={1}>
                    <TypographyTitle>{voucherType.description}</TypographyTitle>
                    <Box width="450px" my="60px" ml="60px">
                        <InputGroup>
                            <TextField
                                value={searchText}
                                onKeyDown={handleArticleNumberKeyDown}
                                onChange={handleSearchTextChange}
                                placeholder={translateText(1104)}
                                fullWidth
                                sx={{ ".MuiFilledInput-root": { paddingRight: 0 }, marginRight: "0.8em" }}
                                InputProps={{
                                    endAdornment: (
                                        <LinkButton
                                            to={url + (searchText ? `?query=${encodeURIComponent(searchText)}` : "")}
                                            color="primary"
                                            sx={{ minWidth: "15px", left: "5px" }}
                                            startIcon={<Icon name="search" />}
                                        />
                                    ),
                                }}
                            />
                        </InputGroup>
                    </Box>
                    {renderDetailsButton(url)}
                </Box>
            )
        }
        return <Typography>COMING SOON</Typography>
    }

    return (
        <Widget
            size="4x4"
            iconName="voucher"
            title={translate(titleTextId)}
            header={<WidgetHeaderTitleComponent iconName="voucher" titleTextId={titleTextId} />}
            active
        >
            {!cisOptions?.voucherTypes ? (
                <Loader />
            ) : (
                <Stack divider={<Divider />} justifyContent="center" spacing={1} height="100%">
                    <RowStack divider={<VerticalDivider />} flex={1}>
                        {renderVouchers(CisVoucherType.OpenOrders)}
                        {renderVouchers(CisVoucherType.ClosedOrders)}
                    </RowStack>
                    {renderSearchItemInOrders()}
                </Stack>
            )}
        </Widget>
    )
}
