import { TirePressure } from "@tm/models"

export enum StatusIdType {
    SerialNumber = 0,
    VIN = 1,
}

export type StatusData = {
    tirePressure?: TirePressure
    odometer?: number
    odometerUnit?: string
    brakeFluidWarning?: boolean
    serviceRequiredDistance?: number
    serviceRequiredTime?: number
    engineCoolantFluidWarning?: boolean
    engineOilLifeRemaining?: number
    telematicsDeviceVoltage?: number
    batteryUnit?: string
    gpsPositions?: GpsPosition
}

export type GpsPosition = {
    longitude: number
    latitude: number
    altitude: number
    headingOrientation: number
}
