import { ListFilter, TyreFilter } from "@tm/models"

export function mapTyreFilterToListFilter(tyreFilter: TyreFilter): ListFilter {
    if (tyreFilter?.group === "manufacturer") {
        return {
            id: +tyreFilter.query,
            value: tyreFilter.value,
            name: tyreFilter.value,
            group: tyreFilter.group,
            isSelected: true,
            isSelectable: true,
        }
    }

    return {
        id: tyreFilter.groupId!,
        value: tyreFilter.query,
        name: tyreFilter.value,
        group: tyreFilter.group,
        isSelected: true,
        isSelectable: true,
    }
}
