import { useStyle, useUser } from "@tm/context-distribution"
import { Button, Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { SystemType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { initSelector, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { percent } from "csx"
import { batch, useSelector } from "react-redux"
import { FC } from "react"
import { EurotaxSelectionSteps } from "../../data/enums"
import { bem } from "../../data/helpers"
import { ErePosition, MmtGroup } from "../../data/models"
import { MainActions, vehicleSelector } from "../main/business"
import { ErrorMessage, EurotaxPanel } from "../_shared"
import Graphical from "./components/graphical"

type Props = RouteComponentProps & {
    defaultRoute: string
}

const selector = initSelector(vehicleSelector, (s) => s.summary)

const SummaryComponent: FC<Props> = ({ history, defaultRoute, match }) => {
    const [
        vehicle,
        {
            cars: { groups, error, loading },
            selectedMmtGroup,
        },
    ] = useSelector(selector)
    const { translateText } = useLocalization()
    const user = useUser()

    const redirectToDashboard = () => {
        const url = renderRoute(defaultRoute, { ...match.params })
        history.push(url)
    }

    const actions = useActions(MainActions, "changeStep", "selectErePosition", "sendDataToEurotaxDetails", "loadVKNImages")

    const handleGraphicClick = (item: MmtGroup, position: ErePosition) => {
        batch(() => {
            actions.selectErePosition(item, position)
            actions.sendDataToEurotaxDetails()
            actions.loadVKNImages()
            actions.changeStep(EurotaxSelectionSteps.DETAILS)
        })
    }

    if (loading) {
        return (
            <div className={bem(style.summary, "loading")}>
                <Loader />
            </div>
        )
    }

    if (error) {
        const additionalContent = user?.userContext?.system.systemType == SystemType.Next && (
            <Button icon="dashboard" onClick={redirectToDashboard}>
                {" "}
                {translateText(760)}
            </Button>
        )

        return (
            <EurotaxPanel>
                {vehicle && (
                    <ErrorMessage description={`${vehicle?.manufacturer + vehicle?.modelSeries} ${translateText(1493)}`}>
                        {additionalContent}
                    </ErrorMessage>
                )}

                {!vehicle && (
                    <ErrorMessage icon="no-results" title={100}>
                        {additionalContent}
                    </ErrorMessage>
                )}

                {/* <Text className={style.vehicleInfo} modifiers={["block", "strong"]}>{translateText(100)}
					</Text>} */}
            </EurotaxPanel>
        )
    }

    return (
        <div className={bem(style.summary)}>
            {/* <Category /> */}
            {groups.map((item, idx) => (
                <Graphical
                    key={idx}
                    index={idx}
                    item={item}
                    onSelect={handleGraphicClick.bind(undefined, item)}
                    selectedMmtGroup={selectedMmtGroup}
                    multiselection={groups.length > 1}
                />
            ))}
        </div>
    )
}

const style = useStyle({
    summary: {
        height: percent(100),
        display: "flex",
        flex: "1",
        overflow: "hidden",
        $nest: {
            "&--loading": {
                justifyContent: "center",
                alignItems: "center",
            },
            "&--covered": {
                pointerEvents: "none",
                // opacity: theme.opacity.disabled
            },
        },
    },
    vehicleInfo: {
        textAlign: "center",
    },
})(SummaryComponent)

export default withRouter(SummaryComponent)
