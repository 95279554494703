import { useState } from "react"
import { BasketPart, BasicBasketPartInfo } from "../../../../../models"
import { PartDialogTrigger } from "../../../../_shared/PartDialogTrigger"
import PartPriceCalculation from "./PartPriceCalculation"

type Props = {
    disabled?: boolean
    part: BasketPart
    currencyCode: string
    hidePurchasePrice: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    onEditPart?(part: BasketPart, editBasketPartInfo: BasicBasketPartInfo): void
}

export default function PartDialogManager(props: Props) {
    const {
        part,
        hidePurchasePrice,
        showManufacturer,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        currencyCode,
        disabled,
        onEditPart,
    } = props

    const [actualRegularPrice, setActualRegularPrice] = useState<number | undefined>()
    const [actualRebate, setActualRebate] = useState<number | undefined>()
    const [actualSurcharge, setActualSurcharge] = useState<number | undefined>()
    const [resetValues, setResetValues] = useState(false)
    const [fieldsChanged, setFieldsChanged] = useState(false)

    function handlePartPriceChange(rebate: number, surcharge: number, regularPrice: number) {
        setActualSurcharge(surcharge)
        setActualRebate(rebate)
        setActualRegularPrice(regularPrice)
        if (resetValues) {
            setResetValues(false)
        }
    }

    return (
        <PartDialogTrigger
            disableButton={disabled}
            part={part}
            showManufacturer={showManufacturer}
            showSupplierArticleNumbers={showSupplierArticleNumbers}
            showWholesalerArticleNumbers={showWholesalerArticleNumbers}
            disableConfirmButton={!fieldsChanged}
            disableResetButton={!fieldsChanged}
            onConfirmButtonClick={() => onEditPart?.(part, { rebate: actualRebate, surcharge: actualSurcharge, regularPrice: actualRegularPrice })}
            onResetButtonClick={() => setResetValues(true)}
            dialogFields={
                <PartPriceCalculation
                    defaultCurrencyCode={currencyCode}
                    part={part}
                    hidePurchasePrice={hidePurchasePrice}
                    resetValues={resetValues}
                    onChangePartPrice={handlePartPriceChange}
                    onChangeFields={(val) => setFieldsChanged(val)}
                />
            }
        />
    )
}
