import {
    Badge,
    BadgeProps,
    Box,
    Button,
    CellContentPosition,
    Icon,
    LicensePlate,
    Stack,
    Table,
    TableCellData,
    TableColumnData,
    TableRowData,
    Tooltip,
    Typography,
} from "@tm/components"
import { useLocalization } from "@tm/localization"
import { getWorkTaskLabellingFromListItem } from "@bundles/worktask/helpers"
import { plateCountryCodeMapper } from "@tm/utils"
import { LicensePlateComponentInitiator } from "@tm/models"
import { WorkTaskListItem } from "../business/model"
import { CompleteWorkTaskButtonComponent } from "../../_shared/CompleteWorkTaskButton"

type Props = {
    hideTimeColumn?: boolean
    workTasks: WorkTaskListItem[]
    defaultPlateCode: string
    deletionPromptOpen: boolean
    onWorkTaskCompleted: () => void
    onPromptToggle: (open: boolean) => void
    onCreateNewWorkTask: (workTask: WorkTaskListItem, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onLoadWorkTask: (workTaskId: string) => void
}

export default function WorkTasksList(props: Props) {
    const {
        workTasks,
        hideTimeColumn,
        defaultPlateCode,
        deletionPromptOpen,
        onWorkTaskCompleted,
        onPromptToggle,
        onCreateNewWorkTask,
        onLoadWorkTask,
    } = props
    const localization = useLocalization()
    const { date, translateText } = localization

    function renderAuditCell(workTask: WorkTaskListItem) {
        const { modifiedDate } = workTask

        return modifiedDate && <Typography>{date(modifiedDate, "t")}</Typography>
    }

    function renderTotalNumbersCell(workTask: WorkTaskListItem) {
        const { partPositionsTotal, workPositionsTotal } = workTask

        return (
            <>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Icon name="article" />
                    <Badge color="primary" size="small" badgeContent={partPositionsTotal || "0"} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Icon name="repairtimes" />
                    <Badge color="primary" size="small" badgeContent={workPositionsTotal || "0"} />
                </Stack>
            </>
        )
    }
    function renderDetailsCell(workTask: WorkTaskListItem) {
        const labelling = getWorkTaskLabellingFromListItem(workTask, localization)
        return (
            <>
                <Typography>{labelling.title}</Typography>
                {!!labelling.info && <Typography variant="body3">{labelling.info}</Typography>}
            </>
        )
    }

    function renderVoucherAndLicensePlateCell(workTask: WorkTaskListItem) {
        const { vehiclePlateId, voucherNo, voucherType, vehicle } = workTask
        const vehicleCountryCode = plateCountryCodeMapper(vehicle?.countryCode || defaultPlateCode)

        return (
            <Stack gap={0.5}>
                {voucherType && (
                    <Typography title={`${voucherType} ${voucherNo}`} variant="body3">
                        {voucherType} {voucherNo}
                    </Typography>
                )}
                {vehiclePlateId && (
                    <LicensePlate
                        size="large"
                        showCountryCode={!!vehicleCountryCode}
                        countryCode={vehicleCountryCode || ""}
                        value={vehiclePlateId}
                        licensePlateComponentInitiator={LicensePlateComponentInitiator.WorkTaskSearch}
                    />
                )}
            </Stack>
        )
    }

    function renderOrderStatusCell(workTask: WorkTaskListItem) {
        const { transmittedOrderStatus } = workTask

        let icon = ""
        let title = ""
        let color: BadgeProps["color"]

        switch (transmittedOrderStatus) {
            case 0:
                icon = "add"
                title = translateText(1888)
                break
            case 1:
                icon = "paperplane"
                title = translateText(1887)
                color = "secondary"
                break
            case 2:
                icon = "partially-available"
                title = translateText(1886)
                color = "warning"
                break
            case 3:
                icon = "check"
                title = translateText(1885)
                color = "success"
                break
            default:
                break
        }

        return (
            <Box>
                {transmittedOrderStatus >= 0 && (
                    <Tooltip title={title}>
                        <Box>
                            {transmittedOrderStatus >= 0 ? <Icon name="order-history" /> : <Icon name="article" />}
                            <Badge color={color} size="small" badgeContent={<Icon name={icon} height="12px" width="12px" />} sx={{ right: "16px" }} />
                        </Box>
                    </Tooltip>
                )}
            </Box>
        )
    }

    function renderActionCell(workTask: WorkTaskListItem) {
        return (
            <Stack direction="row" gap="0.3rem">
                {(workTask.vehicle || workTask.customer) && (
                    <Button startIcon={<Icon name="add" />} onClick={(e) => onCreateNewWorkTask(workTask, e)} title={translateText(12442)} />
                )}
                <CompleteWorkTaskButtonComponent
                    onPromptToggle={onPromptToggle}
                    workTaskId={workTask.workTaskId}
                    onWorkTaskCompleted={onWorkTaskCompleted}
                />
            </Stack>
        )
    }

    function getColumns() {
        const columns: TableColumnData[] = []

        if (!hideTimeColumn) {
            columns.push({ header: "", gridSize: "75px" }) // Audit
        }

        columns.push(
            { header: "", gridSize: "75px" }, // Total Numbers
            { header: "", gridSize: "2fr", alignContent: CellContentPosition.left }, // Details
            { header: "", gridSize: "1fr" }, // License plate and voucher number
            { header: "", gridSize: "32px" }, // Order status
            { header: "", gridSize: "96px", alignContent: CellContentPosition.right } // Actions
        )

        return columns
    }

    function getCells(workTask: WorkTaskListItem) {
        const data: TableCellData[] = []

        if (!hideTimeColumn) {
            data.push({ displayValue: renderAuditCell(workTask), id: "1" })
        }

        data.push(
            {
                displayValue: renderTotalNumbersCell(workTask),
                id: "2",
            },
            { displayValue: renderDetailsCell(workTask), id: "3" },
            { displayValue: renderVoucherAndLicensePlateCell(workTask), id: "4" },
            { displayValue: renderOrderStatusCell(workTask), id: "4" },
            { displayValue: renderActionCell(workTask), id: "4" }
        )

        return data
    }

    const displayData: TableRowData[] = workTasks.map((workTask) => ({
        cells: getCells(workTask),
        id: `${workTask.workTaskId}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return null
    }

    return (
        <Table
            overflowY="unset"
            headerStyle="hidden"
            columns={getColumns()}
            rows={displayData}
            rowCap={4}
            headerBackground="transparent"
            onRowClick={deletionPromptOpen ? undefined : (row) => onLoadWorkTask(row.id)}
            rowStyles={{ cursor: "pointer" }}
        />
    )
}
