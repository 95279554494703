import { useUpdateCompilationSorting } from "@bundles/compilations/data/hooks/useCompilations"
import { Button, DraggableList, Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Compilation } from "@tm/models"
import { useParams } from "react-router"
import { CompilationListItem } from "./CompilationsListItem"
import CompilationCreator from "./creator"

type RouteParams = {
    compilationId?: string
}

type Props = {
    compilations: Array<Compilation>
    showCreator: boolean
    onOpenCompilationDetails(id: string): void
    openCompilationList(): void
    onShowCreator(): void
    query?: string
}

export function CompilationsList(props: Props) {
    const { compilations, onOpenCompilationDetails, openCompilationList, showCreator, onShowCreator, query } = props

    const { translateText } = useLocalization()
    const { compilationId: activeCompilationId } = useParams<RouteParams>()

    const { updateCompilationSorting } = useUpdateCompilationSorting(query)

    function handleReorder(from: string, to: string) {
        updateCompilationSorting({ fromCompilationId: from, toCompilationId: to })
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
                <Typography variant="h3">{translateText(12791)}</Typography>
                <Button size="small" onClick={openCompilationList} startIcon={<Icon name="arrow-right" />} />
            </Stack>
            {showCreator && <CompilationCreator onClose={onShowCreator} short />}
            <DraggableList items={compilations.map((x) => x.id)} onReorder={handleReorder}>
                {compilations.map((compilation) => (
                    <CompilationListItem
                        key={compilation.id}
                        compilation={compilation}
                        isActive={activeCompilationId === compilation.id}
                        onOpenCompilationDetails={onOpenCompilationDetails}
                    />
                ))}
            </DraggableList>
        </>
    )
}
