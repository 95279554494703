import { Icon, styled } from "@tm/components"

export const StyledHeaderIcon = styled(Icon)({
    width: "4em",
    marginRight: "0.4em",
    verticalAlign: "middle",
    opacity: 1,
})

export const StyledCarInfoIcon = styled(Icon)({
    margin: "0 0.5em",
    width: "1.5em",
    height: "1.5em",
    "& > p": {
        lineHeight: "2rem",
    },
})
