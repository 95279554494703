import { useEffect, useMemo } from "react"
import isFuture from "date-fns/isFuture"
import isValid from "date-fns/isValid"
import { atomFamily, useRecoilState } from "recoil"
import { channel } from "@tm/models"
import { decodeUniqueId } from "@tm/utils"

type WebkatState = {
    url: string
    sessionExpires: Date
}

const webkatStateAtom = atomFamily<WebkatState | undefined, string>({
    key: "misc.lkqWebkat.state",
    default: undefined,
})

const NO_WORKTASK = "no-worktask"

export function useCachedWebkatUrl(originalUrl: string | undefined, workTaskId: string | undefined) {
    const [webkatState, setWebkatState] = useRecoilState(webkatStateAtom(workTaskId ?? NO_WORKTASK))

    // if the module is closed by click of the cross, the store should be cleard
    useEffect(() => {
        const unsub = channel("WORKTASK", decodeUniqueId(workTaskId || NO_WORKTASK)).subscribe("MODULE/CLOSED", (module) => {
            if (module.includes("web-catalog")) {
                setWebkatState(undefined)
            }
        })
        return unsub
    }, [])

    const originFilter = useMemo(() => {
        try {
            if (!originalUrl) {
                return undefined
            }

            // Try parsing the original url (with added protocol if not present) and get the origin from it
            return new URL(originalUrl.replace(/^:?\/\//, `https://`)).origin
            // eslint-disable-next-line no-empty
        } catch {}
    }, [originalUrl])

    useEffect(() => {
        function handlePostMessage(e: MessageEvent<{ webkatState: WebkatState }>) {
            try {
                // Only accept related events
                if ((!originFilter || e.origin.includes(originFilter)) && e.data.webkatState) {
                    setWebkatState({
                        url: e.data.webkatState.url,
                        sessionExpires: new Date(e.data.webkatState.sessionExpires), // ISO 8601 Format
                    })
                }
                // eslint-disable-next-line no-empty
            } catch {}
        }

        window.addEventListener("message", handlePostMessage)

        return () => {
            window.removeEventListener("message", handlePostMessage)
        }
    }, [originFilter, setWebkatState])

    // Should be not memorized, because it depends on the current date time and therefor can change every render.
    const valid = webkatState ? checkIfStateIsValid(webkatState) : false

    return valid ? webkatState?.url : undefined
}

function checkIfStateIsValid(state: WebkatState): boolean {
    return isValid(state.sessionExpires) && isFuture(state.sessionExpires) && !!state.url
}
