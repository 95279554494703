import { Box, Modal } from "@tm/components"
import { Button, Headline } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { createRef, ReactNode, useEffect, useState } from "react"

type Props = {
    resetFilterButton?: ReactNode
    disabled?: boolean
    children: ReactNode
}

export function FilterModal({ resetFilterButton, disabled, children }: Props) {
    const modalRef = createRef<HTMLDivElement>()

    const { translateText } = useLocalization()
    const [showFilterModal, setShowFilterModal] = useState(false)

    useEffect(() => {
        if (disabled && showFilterModal) {
            setShowFilterModal(!disabled)
        }
    }, [disabled])

    const handleShowFilterModal = () => {
        setShowFilterModal(true)
    }

    const handleCloseFilterModal = () => {
        setShowFilterModal(false)
    }

    return (
        <>
            <Button layout={["holo"]} disabled={disabled} onClick={handleShowFilterModal}>
                {translateText(209)}
            </Button>
            {showFilterModal && (
                <Modal open={showFilterModal} onOutsideClick={handleCloseFilterModal}>
                    <Box className="tk-parts filter-modal" ref={modalRef}>
                        <Box className="filter-modal__header">
                            <Headline>{translateText(209)}</Headline>
                            <Box>{resetFilterButton}</Box>
                        </Box>
                        {children}
                    </Box>
                </Modal>
            )}
        </>
    )
}
