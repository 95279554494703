import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { getVehicleRegistrationNoLabelTextIds } from "@tm/utils"
import { CarModelDetails, RegistrationNoType, TruckModelDetails, Vehicle, VehicleType } from "@tm/models"
import { PanelSection } from "@tm/controls"
import { Box, Tooltip } from "@tm/components"
import { MouseEvent, Fragment, ReactNode, useEffect, useMemo } from "react"
import { constructionYearToString } from "../../../helpers/construction-year"
import Dictionary, { DictionaryItem, DictionaryItemPair, DictionaryValue } from "../../_shared/Dictionary"

type Props = {
    modelDetails: CarModelDetails | TruckModelDetails
    registrationNumberType?: RegistrationNoType
    vehicleType: VehicleType
    setManufacturerData?(items: DictionaryItemPair[]): void
}

function TecDocDetailsComponent({ modelDetails, registrationNumberType, vehicleType, setManufacturerData }: Props) {
    const { translateText, number } = useLocalization()
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const { vehicle } = workTask ?? {}

    if (!modelDetails && !vehicle) {
        return null
    }

    const renderRegNoValue = (value: DictionaryValue): ReactNode => {
        // eslint-disable-next-line no-param-reassign
        value = value?.toString() ?? ""
        // Define minimum length, two lines are okay
        if (value.length < 50) {
            return value
        }

        return (
            <div className="regno-wrapper">
                <div className="regno-wrapper__preview">{value}</div>
                <div className="regno-wrapper__popover">{value}</div>
            </div>
        )
    }

    function getPowerHp(): DictionaryItem | undefined {
        let item: string | undefined

        if ((modelDetails as TruckModelDetails).enginePowerHpFrom) {
            item = `${(modelDetails as TruckModelDetails).enginePowerHpFrom}`
        }
        if ((modelDetails as TruckModelDetails).enginePowerHpTo) {
            item += ` - ${(modelDetails as TruckModelDetails).enginePowerHpTo} ${translateText(330)}`
        }

        if (!item) {
            return
        }
        return { key: `${translateText(94)} (${translateText(330)})`, value: item }
    }

    function getPowerKw(): DictionaryItem | undefined {
        let item: string | undefined

        if ((modelDetails as TruckModelDetails).enginePowerKwFrom) {
            item = `${(modelDetails as TruckModelDetails).enginePowerKwFrom}`
        }
        if ((modelDetails as TruckModelDetails).enginePowerKwTo) {
            item += ` - ${(modelDetails as TruckModelDetails).enginePowerKwTo} ${translateText(329)}`
        }

        if (!item) {
            return
        }
        return { key: `${translateText(94)} (${translateText(329)})`, value: item }
    }

    const handleSelectEngineCode = (vehicle: Vehicle, code: string | undefined, e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        attachToWorkTask?.({ vehicle: { ...vehicle, engineCode: code } })
    }

    const renderEngineCodes = (value: DictionaryValue) => {
        const engineCodes: Array<string> = value?.toString()?.split(",") ?? []
        const engineCode = vehicle?.engineCode?.trim()

        return engineCodes.map((code, idx) => {
            // eslint-disable-next-line no-param-reassign
            code = code.trim()

            let className = "engine-codes__code "
            if (code === engineCode) {
                className += "is-selected "
            }

            return (
                <Fragment key={code}>
                    <span
                        className={className}
                        onClick={(e) => vehicle && handleSelectEngineCode(vehicle, code !== engineCode ? code : undefined, e)}
                        title={vehicle && (code !== engineCode ? translateText(1546) : translateText(1547))}
                    >
                        {code}
                    </span>
                    {idx < engineCodes.length - 1 ? "," : false}
                </Fragment>
            )
        })
    }

    function renderTypeId(value: DictionaryValue): ReactNode {
        // eslint-disable-next-line no-param-reassign
        value = value?.toString() || "-/-"

        const [topmotiveTypeId, tecdocTypeId] = value.split("/")

        return (
            <Tooltip title={`${translateText(123)} ${tecdocTypeId ?? "-"}`}>
                <Box minWidth="100px">{topmotiveTypeId ?? "-"}</Box>
            </Tooltip>
        )
    }

    // WARNING ! Do not return JSX in value field, just string or number, use valueRenderer to render ! It will break print !
    const items = useMemo(() => {
        let result: Array<DictionaryItem> = [
            {
                key: translateText(105),
                value: modelDetails.engineCodes?.join(", ") ?? "",
                valueRenderer: renderEngineCodes,
            },
        ]

        if (vehicleType === VehicleType.CommercialVehicle) {
            const kw = getPowerKw()
            const hp = getPowerHp()

            if (kw && hp) {
                result.push(kw, hp)
            }
        } else {
            result.push({
                key: translateText(20),
                value: `${(modelDetails as CarModelDetails).enginePowerKw}/${(modelDetails as CarModelDetails).enginePowerHp}`,
            })
        }

        if (modelDetails.topmotiveTypeId !== undefined || modelDetails.tecdocTypeId !== undefined) {
            result.push({
                key: `${translateText(13462)} / ${translateText(123)}`,
                value: `${modelDetails.topmotiveTypeId ?? "-"} / ${modelDetails.tecdocTypeId ?? "-"}`,
                valueRenderer: renderTypeId,
                keyRenderer: (key) => key?.split("/")?.first()?.trim() ?? "",
            })
        }

        const constructionType = {
            key: (modelDetails as TruckModelDetails).constructionTypeDescription ? translateText(994) : translateText(303),
            value: (modelDetails as TruckModelDetails).constructionTypeDescription
                ? (modelDetails as TruckModelDetails).constructionTypeDescription
                : (modelDetails as CarModelDetails).bodyType,
        }

        if (
            vehicleType === VehicleType.CommercialVehicle &&
            (modelDetails as TruckModelDetails).tonnage &&
            (modelDetails as TruckModelDetails).axisConfigDescription &&
            (modelDetails as TruckModelDetails).constructionTypeDescription
        ) {
            result.push(
                { key: translateText(1904), value: (modelDetails as TruckModelDetails).axisConfigDescription || "" },
                {
                    key: translateText(1905),
                    value: (modelDetails as TruckModelDetails).tonnage && number((modelDetails as TruckModelDetails).tonnage),
                }
            )
        }

        result.push(
            {
                key: translateText(299),
                value:
                    ((modelDetails as TruckModelDetails).engineTypeDescription && vehicleType === VehicleType.CommercialVehicle
                        ? (modelDetails as TruckModelDetails).engineTypeDescription
                        : (modelDetails as CarModelDetails).fuelType) || "",
            },
            { key: translateText(298), value: (modelDetails as CarModelDetails).driveType || translateText(1779) },
            { key: constructionType.key, value: constructionType.value || "" },
            { key: translateText(128), value: modelDetails.engineCapacityCcm || translateText(1779) },
            { key: translateText(305), value: (modelDetails as CarModelDetails).cylinderCount || translateText(1779) },
            { key: translateText(304), value: (modelDetails as CarModelDetails).valvesPerCylinder || translateText(1779) },
            {
                key: translateText(306),
                value: (modelDetails as TruckModelDetails).engineTypeDescription || "",
            },
            {
                key: translateText(127),
                value: `${constructionYearToString(modelDetails.constructionYearFrom)} - ${constructionYearToString(
                    modelDetails.constructionYearTo
                )}`,
            }
        )

        const itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(registrationNumberType)

        if (itemLabelTextIds?.label && (modelDetails as CarModelDetails).registrationNos?.length) {
            result = [
                ...result.slice(0, 1),
                {
                    key: translateText(itemLabelTextIds.label),
                    value: (modelDetails as CarModelDetails).registrationNos?.join(", ") ?? "",
                    valueRenderer: renderRegNoValue,
                },
                ...result.slice(1),
            ]
        }

        return result
    }, [modelDetails, vehicleType])

    useEffect(() => {
        if (!setManufacturerData) {
            return
        }

        const manufacturerData: DictionaryItemPair[] =
            items?.map((item) => ({
                key: item.key,
                value: item.value,
            })) ?? []

        setManufacturerData(manufacturerData)
    }, [items])

    return (
        <div className="tecdoc">
            <PanelSection>
                <Dictionary items={items} />
            </PanelSection>
        </div>
    )
}

export default TecDocDetailsComponent
