import {
    ErpInformation,
    ErpInformationRequest,
    ErpInformationRequestItem,
    ErpInformationResponse,
    ErpSystemConfigMode,
    GetAdvertisementCategoriesResponse,
    GetAdvertisementRequest,
    GetAdvertisementResponse,
    GetBonusInformationRequest,
    GetBonusInformationResponse,
    GetCompleteDeliveryTourRequest,
    GetCompleteDeliveryTourResponse,
    GetErpConfigResponse,
    GetNotificationRequest,
    GetNotificationResponse,
    ItemTour,
    ShowOrderStatusError,
    ShowOrderStatusRequest,
    ShowOrderStatusResponse,
    StatusMessageType,
    TeccomErpInformation,
} from "@tm/models"
import { ajaxAuth, mapTour, notUndefinedOrNull } from "@tm/utils"

import { getBundleParams } from "../../../utils"
import { mapErpInformation, mapGetAdvertisementResponse, mapTeccomErpInformation } from "../../mapper"
import { GetNextToursRequest, GetPromotedItemsRequest, RequestMissingArticlesRequest, RequestMissingArticlesResponse } from "../../model"

export * from "./list"

export function getServiceUrl(): string {
    return getBundleParams().serviceUrl
}

export function getErpInfoDetails(request: Omit<ErpInformationRequest, "items"> & { item: ErpInformationRequestItem }): Promise<ErpInformation> {
    const { item, distributorId, telesalesCustomerNo, foundBySearchTerm } = request

    return new Promise<ErpInformation>((resolve, reject) => {
        ajaxAuth<ErpInformationResponse, ErpInformationRequest>({
            url: `${getServiceUrl()}/GetErpInfosDetails`,
            method: "POST",
            body: {
                items: [item],
                telesalesCustomerNo,
                distributorId,
                foundBySearchTerm,
            },
        }).then((response) => {
            const responseItem = response?.items?.find((x) => x.itemId === request.item.itemId)

            if (responseItem) {
                const data = mapErpInformation(responseItem)

                if (data) {
                    resolve(data)
                } else {
                    reject()
                }
            } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject("No corresponding item in response")
            }
        }, reject)
    })
}

export function getErpInfosTeccom(
    request: ErpInformationRequestItem,
    telesalesCustomerNo: string | undefined,
    distributorId: number | undefined
): Promise<TeccomErpInformation> {
    const url = `${getServiceUrl()}/GetTeccomErpInfos`
    const body: ErpInformationRequest = { items: [request], telesalesCustomerNo, distributorId }

    return new Promise<TeccomErpInformation>((resolve, reject) => {
        ajaxAuth({ url, body, method: "POST" }).then((response) => {
            const responseItem = response?.items?.find((item: TeccomErpInformation) => item.itemId === request.itemId)
            if (responseItem) {
                const data = mapTeccomErpInformation(responseItem)
                if (data) {
                    resolve(data)
                } else {
                    reject()
                }
            } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject("No corresponding item in response")
            }
        }, reject)
    })
}

export function getNextTours(body: GetNextToursRequest) {
    const url = `${getServiceUrl()}/GetNextTours`

    return ajaxAuth<{ tours?: Array<ItemTour> }>({ url, body }).then((response) => (response?.tours || []).map(mapTour).filter(notUndefinedOrNull))
}

export function getPromotedItems(telesalesCustomerNo: string | undefined, distributorId: number | undefined): Promise<Array<string>> {
    const url = `${getServiceUrl()}/GetPromotedItems`
    const body: GetPromotedItemsRequest = { telesalesCustomerNo, distributorId }

    return new Promise<Array<string>>((resolve, reject) => {
        ajaxAuth({ url, body }).then((response) => {
            if (!response) {
                reject()
            } else if (response.hasErrors) {
                reject(response.errorText)
            } else {
                resolve(response.wholesalerArticleNos)
            }
        }, reject)
    })
}

export function requestMissingArticles(body: RequestMissingArticlesRequest): Promise<RequestMissingArticlesResponse> {
    const url = `${getServiceUrl()}/RequestMissingArticles`

    return new Promise<RequestMissingArticlesResponse>((resolve, reject) => {
        ajaxAuth({ url, body, method: "POST" }).then((response) => {
            if (!response) {
                reject()
            } else if (response.hasErrors) {
                reject(response.errorText)
            } else {
                resolve(response)
            }
        }, reject)
    })
}

export function getCompleteDeliveryTour(body: GetCompleteDeliveryTourRequest): Promise<GetCompleteDeliveryTourResponse | undefined> {
    const url = `${getServiceUrl()}/GetCompleteDeliveryTour`
    return ajaxAuth({ url, body, method: "POST" })
}

export function getBonusInformation(body: GetBonusInformationRequest): Promise<GetBonusInformationResponse> {
    const url = `${getServiceUrl()}/GetBonusInformation`

    return new Promise<GetBonusInformationResponse>((resolve, reject) => {
        ajaxAuth({ url, body, method: "POST" }).then((response) => {
            if (!response) {
                reject()
            } else if (response.hasErrors) {
                reject(response.errorText)
            } else {
                resolve(response)
            }
        }, reject)
    })
}

export function getAdvertisementCategories() {
    const url = `${getServiceUrl()}/GetAdvertisementCategories`
    const body = {}

    return ajaxAuth<GetAdvertisementCategoriesResponse>({ url, body })
}

export function getAdvertisement(body: GetAdvertisementRequest): Promise<GetAdvertisementResponse> {
    const url = `${getServiceUrl()}/GetAdvertisement`

    return new Promise<GetAdvertisementResponse>((resolve, reject) => {
        ajaxAuth({ url, body, method: "POST" }).then((response) => {
            const result = mapGetAdvertisementResponse(response)
            if (result) {
                resolve(result)
            } else {
                reject()
            }
        }, reject)
    })
}

export function getNotification(body: GetNotificationRequest): Promise<GetNotificationResponse | undefined> {
    const url = `${getServiceUrl()}/GetNotification`
    return ajaxAuth({ url, body, method: "GET" })
}

export function getErpConfig(): Promise<GetErpConfigResponse> {
    const defaultErpConfig: GetErpConfigResponse = {
        mode: ErpSystemConfigMode.Default,
        useOrderByDistributor: false,
        useSendCostEstimation: false,
    }

    return ajaxAuth<GetErpConfigResponse>({
        method: "GET",
        url: `${getServiceUrl()}/GetErpConfig`,
    })
        .then((response) => response ?? defaultErpConfig)
        .catch(() => defaultErpConfig)
}

export async function showOrderStatus(body: ShowOrderStatusRequest): Promise<ShowOrderStatusResponse> {
    type Response = ShowOrderStatusResponse | ShowOrderStatusError

    const response = await ajaxAuth<Response>({
        method: "POST",
        url: `${getServiceUrl()}/ShowOrderStatus`,
        body,
    })

    if (!response || response.hasErrors) {
        throw response
    }

    return {
        ...response,
        orderStatus: response.orderStatus.map((x) => ({
            ...x,
            orderStatusMessage: x.orderStatusMessage
                ? {
                      ...x.orderStatusMessage,
                      type: x.orderStatusMessage.type ?? StatusMessageType.Error, // The property is being removed by the JSON serializer in the service when it has a value of 0 so use a fallback here
                  }
                : undefined,
        })),
    }
}
