import { PropsWithChildren } from "react"
import { ListParamsContext, useListParamsContext } from "../../../ContextProvider/ListParams"
import { OffersListParams } from "../../../models"
import { useListParams } from "./useListParams"

export function OffersListParamsProvider(props: PropsWithChildren<unknown>) {
    const params = useListParams()
    return <ListParamsContext.Provider value={params}>{props.children}</ListParamsContext.Provider>
}

export function useOffersListParamsContext<TContext extends OffersListParams>(): TContext
export function useOffersListParamsContext<TContext extends OffersListParams, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useOffersListParamsContext<TContext extends OffersListParams, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useListParamsContext(selector as never)
}
