import { Box, Dialog } from "@tm/components"
import { useCallback, useRef, useState } from "react"
import { AddCustomArticleItemsRequest, AddCustomPartListRequest, NoteTypes, SelectedPriceTypeMode, TmaEModule } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { TmaHelper, useSaveNote } from "@tm/utils"
import { WorkTaskInfo } from "@tm/context-distribution"
import { BasicBasketPartInfo } from "@bundles/basket/models"
import CustomPartDialogFields from "./CustomPartDialogFields"
import { ArticleNoteRefProps, ControllableArticleNote } from "../../../../_shared/article-note"
import { CostEstimation } from "../../../../../data/model"
import { addCustomArticleItems } from "../../../../../data/repositories/custom-items/articles"
import { createCustomPartObject } from "../../../../../data/mapper"
import { useBasketMemo } from "../../../../../hooks/useBasketMemo"

type Props = {
    workTask: WorkTaskInfo
    costEstimation?: CostEstimation
    openDialog: boolean
    currencyCode: string
    onAddCustomPart(request: AddCustomPartListRequest): void
    onClose(): void
}

export function NewCustomPartDialog(props: Props) {
    const { currencyCode, costEstimation, openDialog, workTask, onAddCustomPart, onClose } = props
    const { id, customer, vehicle } = workTask
    const { customerDefaults, defaultPartVatRates, costEstimationOptions } = costEstimation || {}

    const { translateText } = useLocalization()
    const { saveNote } = useSaveNote()
    const basketMemo = useBasketMemo(workTask)
    const [articleNote, setArticleNote] = useState<string>()

    const articleNoteRef = useRef<ArticleNoteRefProps>(null)

    async function handleAddCustomPartToBasketClick(
        saveAsCustomArticle: boolean,
        basicBasketPartInfo: BasicBasketPartInfo,
        quantityValue: number,
        category?: number
    ) {
        const { description, partNumber, purchasePrice, retailPrice, rebate, surcharge, vatRate } = basicBasketPartInfo
        onAddCustomPart({
            workTaskId: id,
            customParts: [
                createCustomPartObject(
                    partNumber,
                    retailPrice,
                    purchasePrice,
                    description ?? "",
                    quantityValue,
                    rebate,
                    surcharge,
                    vatRate,
                    basketMemo.position
                ),
            ],
            vehicleId: vehicle?.id,
            customerId: customer?.id,
            usePercentageValues: true,
            log: TmaHelper.AddCustomToBasket.GetUserInteractionLog("customArticle", TmaEModule.ARTICLE_COMPILATIONS),
            costEstimationOptions,
        })

        if (saveAsCustomArticle) {
            const request: AddCustomArticleItemsRequest = {
                customArticleItemsToAdd: [
                    {
                        articleNumber: partNumber,
                        description: description ?? "",
                        vatRate,
                        category,
                        retailPrice: !costEstimationOptions?.priceTypeMode ? retailPrice : undefined,
                        purchasePrice: costEstimationOptions?.priceTypeMode === SelectedPriceTypeMode.Purchase ? purchasePrice : undefined,
                        isDefaultForCategory: false,
                    },
                ],
                priceVatMode: costEstimationOptions?.priceVatMode,
            }
            const response = await addCustomArticleItems(request)

            if (articleNote && response) {
                await saveNote({
                    noteId: response.addedCustomArticleItems[0]?.id,
                    type: NoteTypes.CUSTOM_ARTICLE,
                    text: articleNote,
                })
            }
        }
    }

    const handleSaveAsCustomArticle = useCallback(() => {
        if (articleNoteRef.current) {
            articleNoteRef.current.show({
                onChange: (value) => {
                    setArticleNote(value)
                },
            })
        }
    }, [])

    return (
        <Dialog open={openDialog} position="middle" fullWidth maxWidth="lg">
            <CustomPartDialogFields
                titleText={translateText(13906)}
                costEstimationOptions={costEstimationOptions}
                defaultCurrencyCode={currencyCode}
                surcharge={customerDefaults?.partsSurcharge}
                rebate={customerDefaults?.partsRebate}
                customPartVatRates={defaultPartVatRates}
                onAddCustomPart={handleAddCustomPartToBasketClick}
                onSaveAsCustomArticle={handleSaveAsCustomArticle}
                onClose={onClose}
            />
            <Box py={1} px={1.5}>
                <ControllableArticleNote ref={articleNoteRef} />
            </Box>
        </Dialog>
    )
}
