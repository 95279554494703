import { IAttributesItem } from "../../../../data/models"

export * from "./createArticleRequest"
export * from "./createAttributeFiltersRequest"
export * from "./createNextArticlesRequest"
export * from "./filterList"
export * from "./filterUsedCriteria"
export * from "./getSelectedCriterias"

export const getAttributeString = (attr: IAttributesItem) => `${attr.id}|${attr.value || attr.description}`
