import { CisVoucherType } from "@tm/models"
import { Stack } from "@tm/components"
import { useParams } from "react-router"
import { AllOrderListItem } from "../../../../data/model"
import { RowStackWithPadding, VerticalDivider } from "../../../_shared/StyledComponents"
import { COMPACT_WIDTH, FULL_WIDTH, VoucherRouteProps } from "../../component"
import PvOrdersTable from "../../../_shared/tables/PvOrdersTable"
import PvOrderDetails from "./PvOrderDetails"

type Props = {
    voucherTypeId: number
    vouchers?: AllOrderListItem[]
    onLoadNextPage(): void
}

export default function PvVouchersComponent(props: Props) {
    const { voucherTypeId, vouchers } = props
    const matchParams = useParams<VoucherRouteProps>()
    switch (voucherTypeId) {
        case CisVoucherType.AllOrders:
            return (
                <RowStackWithPadding divider={<VerticalDivider />}>
                    <Stack width={matchParams.id ? COMPACT_WIDTH : FULL_WIDTH}>
                        <PvOrdersTable
                            orders={vouchers as AllOrderListItem[]}
                            selectedVoucherId={matchParams.id || undefined}
                            variant={matchParams.id ? "small" : "normal"}
                            onLoadNextPage={props.onLoadNextPage}
                        />
                    </Stack>
                    {!!matchParams.id && (
                        <PvOrderDetails orders={vouchers as AllOrderListItem[]} selectedVoucherId={matchParams.id} voucherTypeId={voucherTypeId} />
                    )}
                </RowStackWithPadding>
            )
        default:
            return null
    }
}
