import { useCallback } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { ConfigParams, SearchFilters } from "@tm/models"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { usePartsRoutes } from "./usePartsRoutes"

export function useHandleStartDirectSearch() {
    const { workTaskId } = useWorkTask() ?? {}
    const routes = usePartsRoutes()
    const { replacePartDirectSearchUrl } = Morpheus.getParams<ConfigParams>()

    return useCallback(
        (query: string, searchFilter: SearchFilters, openInModal = false) => {
            if (!workTaskId) {
                return
            }
            const searchParams = new URLSearchParams()
            searchParams.append("query", query)
            searchParams.append("searchFilter", String(searchFilter))
            if (routes.directSearch?.list && !openInModal) {
                const url = renderRoute(routes.directSearch?.list, { workTaskId: encodeUniqueId(workTaskId) })
                Morpheus.getHistory().push(`${url}?${searchParams.toString()}`)
            } else if (routes.partAlternatives?.list && openInModal) {
                const url = renderRoute(replacePartDirectSearchUrl, { workTaskId: encodeUniqueId(workTaskId) })
                Morpheus.showView("1", `${url}?${searchParams.toString()}`)
            }
        },
        [routes, workTaskId, replacePartDirectSearchUrl]
    )
}
