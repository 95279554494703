import { Button, Icon, RangeSlider, Tooltip } from "@tm/controls"
import { TyreFilter } from "@tm/models"
import { useStyle } from "@tm/context-distribution"
import { bem, equals } from "@tm/utils"
import { em } from "csx"
import { FC } from "react"
import { MinimizedRangeSlider } from "./minimizedRangeSlider"

type Props = {
    title: string
    icon: string
    items: TyreFilter[]
    selectedItem?: TyreFilter
    onChange(value: TyreFilter): void
    onReset(): void
    minimized?: boolean
}

const CustomRangeSlider: FC<Props> = ({ minimized, title, icon, items, selectedItem, onChange, onReset }) => {
    const handleRangeSliderChange = (idx: number) => {
        onChange(items[idx])
    }

    const renderButton = (item: TyreFilter, index: number) => {
        return (
            <Button size="s" key={index} className={bem(style.buttonNumbers, item.value)} onClick={handleRangeSliderChange.bind(this, index)}>
                {item.value}
            </Button>
        )
    }

    if (!items.length) {
        return null
    }

    return (
        <div className={style.sliderWrapper}>
            <Tooltip content={title}>
                <Icon name={icon} className={style.sliderIcon} />
            </Tooltip>
            <div className={style.sliderContent}>
                {minimized && (
                    <MinimizedRangeSlider
                        onChange={handleRangeSliderChange}
                        items={items}
                        selectedItempos={(selectedItem && items.findIndex((item) => equals(item, selectedItem))) || 0}
                    />
                )}
                {!minimized && (
                    <>
                        <RangeSlider
                            onChange={handleRangeSliderChange}
                            value={(selectedItem && items.findIndex((item) => equals(item, selectedItem))) || 0}
                            max={items.length - 1}
                            min={0}
                        />
                        <div className={style.sliderButtons}>{items.map(renderButton)}</div>
                    </>
                )}
            </div>
            <Button layout={["ghost"]} size="s" icon="synchronize" disabled={!selectedItem} className={style.resetIcon} onClick={onReset} />
        </div>
    )
}

export default CustomRangeSlider

const style = useStyle({
    sliderWrapper: {
        marginTop: em(0.5),
        display: "flex",
        alignItems: "center",
    },
    sliderIcon: {
        marginRight: em(1),
    },
    sliderContent: {
        flex: 1,
        flexDirection: "column",
    },
    sliderButtons: {
        marginTop: em(0.5),
        display: "flex",
        justifyContent: "space-between",
    },
    resetIcon: {
        marginLeft: em(1),
        cursor: "pointer",
    },
    buttonNumbers: {
        backgroundColor: "#2196f3",
        color: "#fff",
        textShadow: "0 0 3px #202020",
        $nest: {
            "&--A": {
                backgroundColor: "#2d7327",
            },
            "&--B": {
                backgroundColor: "#55d545",
            },
            "&--C": {
                backgroundColor: "#b5e941",
            },
            "&--D": {
                backgroundColor: "#f6ed4e",
            },
            "&--E": {
                backgroundColor: "#f6a328",
            },
            "&--F": {
                backgroundColor: "#dd901f",
            },
            "&--G": {
                backgroundColor: "#e12816",
            },
            "&:hover": {
                textShadow: "none",
            },
        },
    },
})(CustomRangeSlider)
