import { useCallback, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Tooltip } from "@tm/components"
import { FilterNames, TyreFilter } from "@tm/models"
import { classes, getIconByGroup, getTitleByGroup } from "@tm/utils"
import { Button } from "@tm/controls"
import { getStyleTheme, useStyle } from "@tm/context-distribution"

type CarTypeSeasonFilterName = Extract<FilterNames, "carType" | "season" | "tyreCount">

type Props = {
    filterName: CarTypeSeasonFilterName
    items: TyreFilter[]
    selectedItemValue?: TyreFilter | TyreFilter[]
    className?: string
    disabled?: boolean
    buttonSize?: "s" | "l" | "m" | "xl"
    onChange(filterName: CarTypeSeasonFilterName, value: TyreFilter): void
}

export function CarTypeSeasonFilters({ filterName, items, onChange, disabled, className, selectedItemValue, buttonSize }: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    function isValueSelected(item: TyreFilter) {
        if (disabled) {
            return false
        }

        if (Array.isArray(selectedItemValue)) {
            return selectedItemValue.map((x) => x.query).includes(item.query)
        }

        return selectedItemValue?.query === item?.query
    }

    const handleOnChange = useCallback((item: TyreFilter) => () => onChange(filterName, item), [filterName])

    return (
        <Box className={classes(className, style.wrapper)}>
            {items.map((item) => (
                <Tooltip title={translateText(getTitleByGroup(item.query))} key={item.value}>
                    <Button
                        icon={getIconByGroup(item.query)}
                        size={buttonSize || "l"}
                        disabled={disabled}
                        isActive={isValueSelected(item)}
                        fakeButton
                        onClick={handleOnChange(item)}
                        className={classes("filter", style.marginBottom)}
                    />
                </Tooltip>
            ))}
        </Box>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            marginLeft: "-0.2em",
        },
        marginBottom: {
            marginBottom: theme.margin.m,
            marginLeft: "0.2em",
        },
    })(CarTypeSeasonFilters)
}
