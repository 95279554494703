import { DecodedMatchParams, EncodedMatchParams } from "../models"

export function decodeAllParams(params: EncodedMatchParams): DecodedMatchParams {
    const res: { [key: string]: string | string[] } = {}
    Object.keys(params).forEach((x) => {
        const y = x as keyof EncodedMatchParams
        if (y === "search") {
            const [size, carType, season] = (params[y] && decodeURIComponent(params[y]!)?.split("&")) || []
            res.size = size
            res.carType = carType?.split(",")
            res.season = season?.split(",")
            return
        }
        if (y === "view" && !params[y]) {
            res[y] = "summary"
        }
        if (params[y]) {
            res[y] = decodeURIComponent(params[y]!)
        }
    })
    return res as DecodedMatchParams
}
