import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useAvailabilityStatus, useWorkTaskId } from "@tm/context-distribution"
import { ArticleErpInfo } from "@tm/models"
import { emptyFunc } from "../../../ListV2/helpers"
import { useSortArticles } from "../../../ListV2/hooks/useSortArticles"
import { ArticleType, RequestType } from "./WheelsListContext"
import { useDefaultErpSystem } from "../../../ListV2/hooks"
import { AvailabilityFilterType } from "../../../../business"
import { filterArticlesByAvailability } from "../../../TyresList/helpers/filterArticlesByAvailability"
import { useParticularContext, WheelsListType } from "../../../PureList/ContextProviders/Particular/ParticularContext"
import { WheelsListData } from "./models"

export function useWheelsList(erpInfos: ArticleErpInfo[]): WheelsListData {
    const contextProvider = useParticularContext<WheelsListType>()
    const { request, fetchArticles, handleAutoNextPage, selectedAvailability, initializeScrollContainerSelector, type, setArticleCount } =
        contextProvider ?? {}

    const workTaskId = useWorkTaskId()
    const [articles, setArticles] = useState<ArticleType>([])
    const [filteredArticles, setFilteredArticles] = useState<ArticleType>([])
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()
    const defaultErpSystemId = useDefaultErpSystem()?.id
    const availabilityFilterChecked = selectedAvailability === AvailabilityFilterType.Primary
    const secondAvailabilityFilterChecked = selectedAvailability === AvailabilityFilterType.Secondary

    const { sortedArticleList, selectedSorting, handleSorting } = useSortArticles(filteredArticles || [], erpInfos)

    const initialize = () => {
        setArticles([])
        setFilteredArticles([])
        if (!initializeScrollContainerSelector) {
            return
        }
        const scrollableElement = document.querySelector(initializeScrollContainerSelector) as HTMLElement
        if (scrollableElement) {
            scrollableElement.scrollTop = 0
        }
    }

    const articlesQuery = useQuery({
        queryKey: [`articlesList${type}`, workTaskId, request, selectedAvailability],
        queryFn: () =>
            fetchArticles?.(request as RequestType).then((response) => {
                if (!response?.length) {
                    throw new Error("No Articles")
                }
                setArticles((prevArticles) => {
                    const isSameResponse = JSON.stringify(prevArticles) === JSON.stringify(response)
                    return isSameResponse ? prevArticles : [...prevArticles, ...response]
                })

                return response
            }),
        enabled: !!request,
    })

    const pageSize = request?.pageSize ?? 15

    const applyFilter = () => {
        if (articles.length) {
            if (selectedAvailability && defaultErpSystemId && availabilityStatusIdsToShow) {
                const filteredData = filterArticlesByAvailability(
                    articles,
                    erpInfos,
                    defaultErpSystemId,
                    availabilityFilterChecked,
                    secondAvailabilityFilterChecked,
                    availabilityStatusIdsToShow,
                    availabilityStatusIdsToShowSecondary
                )
                setFilteredArticles(filteredData)
                if (filteredData.length < pageSize) {
                    handleAutoNextPage?.()
                }
            } else {
                setFilteredArticles(articles)
            }
        }
    }

    useEffect(() => {
        if (request?.pageIndex === 1) {
            initialize()
        }
    }, [request])

    useEffect(() => {
        if (erpInfos.some((x) => x.state === "success")) {
            applyFilter()
            setArticleCount?.(articles.length)
        }
    }, [articles, selectedAvailability, request, erpInfos])

    const isLoaded = (articlesQuery.isSuccess || articlesQuery.isError) && !articlesQuery.isFetching

    return {
        requestStatus: articlesQuery.status,
        isEnabled: true,
        isLoading: articlesQuery.isFetching,
        isLoaded,
        isSuccess: articlesQuery.isSuccess,
        isFailed: articlesQuery.isError,
        articles,
        displayArticles: sortedArticleList,
        articleCount: sortedArticleList.length,
        pageCount: request?.pageIndex ?? 0,
        hasNextPage: false,
        isFetchingNextPage: false,
        productGroupIds: [],
        supplierIds: [],
        loadNextPage: emptyFunc,
        sorting: { sortedArticleList, selectedSorting, handleSorting },
        isStalling:
            !!selectedAvailability &&
            !sortedArticleList.length &&
            request?.pageIndex === 1 &&
            (!erpInfos?.length || !erpInfos.some((x) => x.state === "success" || x.state === "error")),
    }
}
