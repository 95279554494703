import { IBundle } from "@tm/morpheus"

import AddingTools from "./components/adding-tools"
import ArticleAddedMessage from "./components/article-added-message"
import CentralOrder from "./components/central-order"
import CostEstimation from "./components/cost-estimation"
import Navigation from "./components/navigation"
import Notifications from "./components/notifications"
import ShellExportButton from "./components/navigation/components/ShellExportButton"
import ShowAllOrdersButton from "./components/show-all-orders-button"
import Summary from "./components/summary"
import Totals from "./components/totals"
import WorktaskOrder from "./components/worktask-order"
import WorktaskOrderButton from "./components/worktask-order-button"
import AddToBasket from "./micros/add-to-basket"
import AddToBasketOePart from "./micros/add-to-basket-oe-part"
import AddToBasketWholesalerPart from "./micros/add-to-basket-wholesaler-part"
import AddToCostEstimate from "./micros/add-to-cost-estimate"
import AddToCostEstimateOePart from "./micros/add-to-cost-estimate-oe-part"
import ChangeDistributorButton from "./micros/change-distributor-button"
import ActiveOrderModal from "./components/active-order-modal"
import ExportVoucherButton from "./components/ExportVoucherButton"
import { initBasketBundle, version } from "./utils"
import { useCreateCostEstimationVoucher } from "./data/hooks/useCreateCostEstimationVoucher"
import { useBasketParts } from "./hooks/basketState/useBasketParts"
import { useBasketWorks } from "./hooks/basketState/useBasketWorks"
import { useBasketImports } from "./hooks/basketState/useBasketImports"
import { useInvalidateWorkTaskBasketQueries } from "./data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"
import { useWorkTaskBasketState } from "./hooks/basketState/useWorkTaskBasketState"
import {
    getShowQuantitiesByOeArticle,
    getShowQuantitiesBySupplierArticle,
    getShowWholesalerArticleQuantities,
} from "./data/repositories/basket/article-quantities"
import { useBasketMemo } from "./hooks/useBasketMemo"
import { useWarehouse } from "./data/hooks/useWarehouse"
import { useAddArticleExternalUrl } from "./helpers"

/**
 * This is a namespace that contains documentation elements belonging to the Bundles Basket
 * domain.
 *
 * @namespace Basket
 */

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        AddingTools,
        ArticleAddedMessage,
        CostEstimation,
        Navigation,
        Notifications,
        CentralOrder,
        ShellExportButton,
        ShowAllOrdersButton,
        Summary,
        Totals,
        WorktaskOrder,
        WorktaskOrderButton,
        ActiveOrderModal,
        ExportVoucherButton,
    ],
    micros: [AddToBasket, AddToBasketOePart, AddToBasketWholesalerPart, AddToCostEstimate, ChangeDistributorButton, AddToCostEstimateOePart],
    init: initBasketBundle,
}

export default bundle

export {
    useCreateCostEstimationVoucher,
    useBasketParts,
    useBasketWorks,
    useBasketImports,
    useInvalidateWorkTaskBasketQueries,
    useWorkTaskBasketState,
    getShowQuantitiesByOeArticle,
    getShowQuantitiesBySupplierArticle,
    getShowWholesalerArticleQuantities,
    useBasketMemo,
    useWarehouse,
    useAddArticleExternalUrl,
}

export type { VoucherIncludedWork, VoucherPart, VoucherWork } from "./data/model/cost-estimation"
