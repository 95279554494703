import { Box, Button, Dialog, Icon, LicensePlate, Paper, Stack, Typography, VehicleImage } from "@tm/components"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CustomerVehicleShortInfo, LicensePlateComponentInitiator } from "@tm/models"
import { useState } from "react"
import { getBundleParams } from "../../../utils"

type Props = {
    vehicle: CustomerVehicleShortInfo
    handleDetachVehicle(vehicle: CustomerVehicleShortInfo): void
    handleApplyVehicle(vehicle: CustomerVehicleShortInfo): void
}

export default function VehicleCard({ vehicle, handleDetachVehicle, handleApplyVehicle }: Props) {
    const [isDialogActive, setIsDialogActive] = useState(false)
    const { date, translateText } = useLocalization()

    const { modelThumbnail, modelSeriesThumbnail, manufacturer, modelSeries, model, plateId, attachedOn, vehicleType } = vehicle

    const shortenedModelThumbnail = modelThumbnail?.replace(/https?:/, "") || ""
    const shortenedModelSeriesThumbnail = modelSeriesThumbnail?.replace(/https?:/, "") || ""
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)
    return (
        <Paper className="card--vehicle" sx={{ position: "relative", width: "100%" }}>
            <div className="card__actions">
                {!getBundleParams().nextLight && (
                    <>
                        <Button
                            size="small"
                            title={translateText(521)}
                            startIcon={<Icon name="close" />}
                            onClick={() => setIsDialogActive(true)}
                            sx={{ marginRight: "2px" }}
                        >
                            {translateText(624)}
                        </Button>
                        <Dialog
                            open={isDialogActive}
                            skin="warning"
                            customSkinIcon={
                                <Icon name="attention-dark" size="26px" sx={(theme) => ({ marginRight: "1rem", fill: theme.palette.warning.main })} />
                            }
                            position="top"
                            maxWidth="md"
                            alignDisplayIconCenter
                        >
                            <Stack direction="row" alignItems="center" gap={2}>
                                <Typography variant="h4">{translateText(1034)}</Typography>
                                <Stack direction="row" gap={1}>
                                    <Button onClick={() => setIsDialogActive(false)}>{translateText(584)}</Button>
                                    <Button color="success" startIcon={<Icon name="check" />} onClick={() => handleDetachVehicle(vehicle)}>
                                        {translateText(585)}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Dialog>
                        <Button
                            size="small"
                            title={translateText(520)}
                            startIcon={<Icon name="paperclip" />}
                            onClick={() => {
                                handleApplyVehicle(vehicle)
                            }}
                        >
                            {translateText(13)}
                        </Button>
                    </>
                )}
            </div>
            <Box mt={3} mb={1}>
                <VehicleImage
                    className="card--vehicle__image"
                    modelImage={shortenedModelThumbnail}
                    modelSeriesImage={shortenedModelSeriesThumbnail}
                    vehicleType={vehicleType}
                />
            </Box>
            <Typography variant="body1" fontWeight="bold">
                {manufacturer} {modelSeries} {model}
            </Typography>
            {plateId && (
                <LicensePlate
                    value={plateId}
                    countryCode={plateCode}
                    size="large"
                    licensePlateComponentInitiator={LicensePlateComponentInitiator.CustomerList}
                />
            )}
            <Typography variant="body1">
                {translateText(228)} {attachedOn ? date(attachedOn, "f") : ""}
            </Typography>
        </Paper>
    )
}
