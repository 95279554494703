import { Button, Divider, Icon, SimpleCard, Stack, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { EFilterNames, ETyreCountType, TyreFilter } from "@tm/models"
import { Statics } from "@bundles/tyres/data/statics"
import { useWidgetFilters } from "@bundles/tyres/hooks/widgetFilters"
import { getIconByGroup } from "@tm/utils"
import { SpecialCarTypes } from "@bundles/tyres/data/enums"
import { ComponentType, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react"
import { getTyreCountFilters } from "@bundles/tyres/data/helpers"
import { useTyresMatchCodeSearchType } from "@tm/context-distribution"
import { StandardTyreSizes } from "../../_shared/StandardTyreSizes"
import { CarTypeSeasonFilters } from "../../_shared/carTypeSeasonFilters"
import { DiameterFilters } from "../../_shared/DiameterFilters"
import { CustomDropdown } from "../../_shared/customDropdown"
import { MatchCodeSearch } from "../../_shared/matchCodeSearch"

const StyledDiameterFilters = styled(DiameterFilters, { shouldForwardProp: (prop) => prop !== "multiTireSizes" })<{ multiTireSizes?: boolean }>(
    ({ multiTireSizes }) => ({
        flex: 1,
        visibility: multiTireSizes === undefined || multiTireSizes === true ? "visible" : "hidden",
    })
)

const StyledFilters = styled(CarTypeSeasonFilters, { shouldForwardProp: (prop) => prop !== "noWrap" })<{ noWrap?: boolean }>(({ noWrap }) => ({
    ".MuiButton-sizeLarge": {
        height: "3em",
        width: "3em",
    },
    flexWrap: noWrap ? "nowrap" : "wrap",
}))

const StyledCarTypeDropdown = styled(CustomDropdown)({
    ".popover__inner": {
        marginLeft: "4em",
    },
    minWidth: "5em",
    ".btn": {
        minHeight: "3em",
    },
})

const StyledSeasonDropdown = styled(CustomDropdown)({
    minWidth: "4em",
    ".btn": {
        minHeight: "3em",
    },
})

const StyledLabel = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
}))

const StyledButton = styled(Button)(({ theme }) => ({
    alignSelf: "end",
    "&:focus": {
        backgroundColor: theme.palette.primary.light,
    },
}))
export function TyresSearchWidgetCompactV2() {
    const { translateText } = useLocalization()
    const cardRef = useRef<HTMLImageElement | null>(null)
    const searchButtonRef = useRef<HTMLButtonElement | null>(null)
    const [showCompactWidget, setShowCompactWidget] = useState<boolean>(false)
    const {
        state: { loading, filters, selectedFilters, selectedFiltersDefaultVehicle, standardTyreSizes },
        handlers: { handleFilterChange, handleNormalSearch, handleStandardSizeSearch },
    } = useWidgetFilters()
    const showMatchCodeSearch = useTyresMatchCodeSearchType(true)

    const vehicleStandardTyreSizes = useMemo(() => {
        return standardTyreSizes?.filter((sizes) => sizes.carType.toString() === selectedFilters.carType?.[0].query)
    }, [selectedFilters.carType, standardTyreSizes])

    const cardObserver = useMemo(
        () =>
            new ResizeObserver((entries) => {
                const card = entries[0].target as HTMLImageElement
                setShowCompactWidget(card.clientWidth < 460)
            }),
        []
    )

    useLayoutEffect(() => {
        const card = cardRef.current
        card && cardObserver.observe(card)
        return () => {
            card && cardObserver.unobserve(card)
        }
    }, [cardRef, cardObserver])

    const SeasonItemView: ComponentType<TyreFilter> = useCallback((item) => {
        return <Icon name={getIconByGroup(item.query)} sx={{ alignSelf: "center" }} />
    }, [])

    const CarTypeItemView: ComponentType<TyreFilter> = useCallback((item) => {
        return (
            <Stack direction="row" gap={1}>
                <Icon name={getIconByGroup(item.query)} />
                <Typography>{translateText(item.value)}</Typography>
            </Stack>
        )
    }, [])

    const CarTypeCoverView: ComponentType = useCallback(() => {
        return <Icon name={getIconByGroup(selectedFilters.carType?.[0].query ?? "")} />
    }, [selectedFilters.carType])

    const handleFocus = () => {
        searchButtonRef?.current?.focus()
    }

    const renderExtendedWidget = () => {
        return (
            <Stack direction="column" sx={{ flexBasis: "80%" }} gap={1}>
                {showMatchCodeSearch && <MatchCodeSearch value="" onButtonClick={handleStandardSizeSearch} />}
                <Stack direction="row" gap={1}>
                    <Stack direction="column" gap={0.5} sx={{ flexBasis: "80%" }}>
                        <StyledLabel variant="label">{translateText(610)}</StyledLabel>
                        <StyledFilters
                            filterName={EFilterNames.carType}
                            disabled={loading}
                            items={Statics.getCarTypes()}
                            selectedItemValue={selectedFiltersDefaultVehicle ?? selectedFilters?.carType}
                            onChange={handleFilterChange}
                        />
                    </Stack>
                    <Stack
                        direction="column"
                        gap={0.5}
                        sx={{
                            flexBasis: "20%",
                            visibility: "hidden",
                            // visibility: getTyreCountFilters(selectedFilters.carType.first()?.query).length > 0 ? "visible" : "hidden",
                        }}
                    >
                        <StyledLabel variant="label">{translateText(13917)}</StyledLabel>
                        <StyledFilters
                            noWrap
                            filterName={EFilterNames.tyreCount}
                            disabled={loading}
                            items={getTyreCountFilters(selectedFilters.carType.first()?.query)}
                            selectedItemValue={selectedFilters?.tyreCount}
                            onChange={handleFilterChange}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" gap={1}>
                    <Stack direction="column" gap={0.5} sx={{ flexBasis: "40%" }}>
                        <StyledLabel variant="label">{translateText(1235)}</StyledLabel>
                        <StyledFilters
                            noWrap
                            filterName={EFilterNames.season}
                            disabled={loading || selectedFilters.carType.every((x) => SpecialCarTypes.includes(+x.value))}
                            items={Statics.seasons}
                            selectedItemValue={selectedFilters?.season}
                            onChange={handleFilterChange}
                        />
                    </Stack>
                    <Stack direction="column" gap={0.5} sx={{ flexBasis: "60%" }}>
                        <StyledLabel variant="label">{translateText(712)}</StyledLabel>
                        <StyledDiameterFilters
                            widths={filters.width}
                            heights={filters.height}
                            inches={filters.inch}
                            selectedHeight={selectedFilters.height}
                            selectedInch={selectedFilters.inch}
                            selectedWidth={selectedFilters.width}
                            onChange={handleFilterChange}
                            loading={loading}
                            maxItemsToShow={3}
                            layout={["iconRight"]}
                            customCoverView
                            onFocus={handleFocus}
                        />
                        <StyledDiameterFilters
                            widths={filters.width}
                            heights={filters.height}
                            inches={filters.inch}
                            selectedHeight={selectedFilters.height}
                            selectedInch={selectedFilters.inch}
                            selectedWidth={selectedFilters.width}
                            onChange={handleFilterChange}
                            loading={loading}
                            maxItemsToShow={3}
                            layout={["iconRight"]}
                            customCoverView
                            multiTireSizes={selectedFilters.tyreCount?.query !== ETyreCountType.singleTire}
                            disabled
                        />
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    const renderCompactWidget = () => {
        return (
            <Stack direction="column" sx={{ flexBasis: "80%" }} gap={1}>
                {showMatchCodeSearch && <MatchCodeSearch value="" onButtonClick={handleStandardSizeSearch} />}
                <Stack direction="row" gap={1} sx={{ minHeight: "4em", flexBasis: "100%" }}>
                    <Stack direction="column" gap={0.5} sx={{ flexBasis: "33%" }}>
                        <StyledLabel variant="label">{translateText(610)}</StyledLabel>
                        <StyledCarTypeDropdown
                            filterName={EFilterNames.carType}
                            isActive={false}
                            loading={loading}
                            items={Statics.getCarTypes()}
                            onChange={handleFilterChange}
                            selectedValue={selectedFiltersDefaultVehicle ?? selectedFilters?.carType?.[0]}
                            withResetButton={false}
                            maxItemsToShow={4}
                            submitOnTab
                            layout={["iconRight"]}
                            ItemView={CarTypeItemView}
                            CoverView={CarTypeCoverView}
                            customCoverView
                        />
                    </Stack>
                    <Stack
                        direction="column"
                        gap={0.5}
                        sx={{
                            flexBasis: "33%",
                            display: "none",
                            // visibility: getTyreCountFilters(selectedFilters.carType.first()?.query).length > 0 ? "visible" : "hidden",
                        }}
                    >
                        <StyledLabel variant="label">{translateText(13917)}</StyledLabel>
                        <StyledFilters
                            noWrap
                            filterName={EFilterNames.tyreCount}
                            disabled={loading}
                            items={getTyreCountFilters(selectedFilters.carType.first()?.query)}
                            selectedItemValue={selectedFilters?.tyreCount}
                            onChange={handleFilterChange}
                        />
                    </Stack>
                    <Stack direction="column" gap={0.5} sx={{ flexBasis: "33%" }}>
                        <StyledLabel variant="label">{translateText(1235)}</StyledLabel>
                        <StyledSeasonDropdown
                            filterName={EFilterNames.season}
                            isActive={false}
                            loading={loading}
                            items={Statics.seasons}
                            onChange={handleFilterChange}
                            selectedValue={selectedFilters?.season?.[0]}
                            withResetButton={false}
                            submitOnTab
                            layout={["iconRight"]}
                            ItemView={SeasonItemView}
                        />
                    </Stack>
                </Stack>
                <Stack direction="column" gap={0.5} sx={{ flexBasis: "100%" }}>
                    <StyledLabel variant="label">{translateText(712)}</StyledLabel>
                    <StyledDiameterFilters
                        widths={filters.width}
                        heights={filters.height}
                        inches={filters.inch}
                        selectedHeight={selectedFilters.height}
                        selectedInch={selectedFilters.inch}
                        selectedWidth={selectedFilters.width}
                        onChange={handleFilterChange}
                        loading={loading}
                        maxItemsToShow={3}
                        layout={["iconRight"]}
                        customCoverView
                        onFocus={handleFocus}
                    />
                    <StyledDiameterFilters
                        widths={filters.width}
                        heights={filters.height}
                        inches={filters.inch}
                        selectedHeight={selectedFilters.height}
                        selectedInch={selectedFilters.inch}
                        selectedWidth={selectedFilters.width}
                        onChange={handleFilterChange}
                        loading={loading}
                        maxItemsToShow={3}
                        layout={["iconRight"]}
                        customCoverView
                        multiTireSizes={selectedFilters.tyreCount?.query !== ETyreCountType.singleTire}
                        disabled
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <SimpleCard icon="tire" title={translateText(240)}>
            <Stack direction="column" gap={1} ref={cardRef}>
                <Stack gap={1} divider={vehicleStandardTyreSizes?.length ? <Divider orientation="vertical" flexItem /> : undefined} direction="row">
                    {showCompactWidget ? renderCompactWidget() : renderExtendedWidget()}
                    <Stack
                        sx={{
                            height: "80%",
                            flexBasis: "15%",
                            visibility: vehicleStandardTyreSizes?.length ? "visible" : "hidden",
                            minWidth: "fit-content",
                        }}
                    >
                        <StandardTyreSizes direction="column" tyreSizes={vehicleStandardTyreSizes} onSizeClick={handleStandardSizeSearch} />
                    </Stack>
                </Stack>
                <StyledButton
                    ref={searchButtonRef}
                    size="large"
                    color="primary"
                    onClick={handleNormalSearch}
                    disabled={!selectedFilters.height && !selectedFilters.inch && !selectedFilters.width}
                >
                    {translateText(135)}
                </StyledButton>
            </Stack>
        </SimpleCard>
    )
}
