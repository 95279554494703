import { useTyresMatchCodeSearchType, useUser } from "@tm/context-distribution"
import { Toolbar, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Box, Button, Icon, styled, Switch } from "@tm/components"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { getBundleParams } from "../../../utils"
import AttributeFilters from "./attributeFilters"
import { PurchasePriceSwitch } from "../../_shared/purchasePriceSwitch"
import { MatchCodeSearch } from "../../_shared/matchCodeSearch"
import { FilterModal } from "./filtersModal"

export function TopFiltersComponent() {
    const { translateText } = useLocalization()
    const user = useUser()
    const showMatchCodeSearch = useTyresMatchCodeSearchType()
    const { useNewNavbar } = getBundleParams()

    const actions = useActions(MainActions, "updateSearchValue", "loadTyresList", "changeStep", "resetAllAttributeFilters")
    const { searchValue, clippedFilters, atributeFilters } = useSelector((s: MainState) => ({
        searchValue: s.list.searchValue,
        clippedFilters: s.list.clippedFilters,
        atributeFilters: s.list.selectedFilters,
    }))

    const handleSearch = (newSearchValue?: string) => {
        newSearchValue && actions.updateSearchValue(newSearchValue)
        actions.loadTyresList(true)
        actions.changeStep("list", { size: newSearchValue, season: undefined, carType: undefined })
    }

    const handleAllFiltersReset = () => {
        actions.resetAllAttributeFilters()
        actions.loadTyresList(undefined, true)
    }

    const handleChangeCompactView = (compactView: boolean) => {
        // actions.setArticleListCompactView(compactView)

        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    compactView,
                },
            })
        }
    }

    const handleChangeShowArticleImages = (showArticleImages: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    showArticleImages,
                },
            })
        }
    }

    const renderTooltipContent = () => {
        return (
            <Tooltip className="tk-parts list-options__tooltip" content={translateText(1805)}>
                <Switch
                    checked={user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeShowArticleImages(event.target.checked)}
                    label={translateText(795)}
                />
            </Tooltip>
        )
    }

    const isResetBtnEnabled = Object.values(atributeFilters).some((x) => (Array.isArray(x) ? x.length : x))

    const renderFilterModalButton = () => {
        if (useNewNavbar) {
            return clippedFilters ? (
                <FilterModal>
                    <AttributeFilters inModal />
                </FilterModal>
            ) : null
        }

        return (
            <FilterModal disabled={!clippedFilters}>
                <AttributeFilters inModal />
            </FilterModal>
        )
    }

    return (
        <StyledWrapper useNewNavbar={useNewNavbar}>
            {useNewNavbar && <Toolbar />}
            {showMatchCodeSearch && (
                <Toolbar title={translateText(1883)}>
                    <MatchCodeSearch value={searchValue} onButtonClick={handleSearch} />
                </Toolbar>
            )}
            <Toolbar className="tk-parts" title={translateText(361)}>
                <Box className="list-options">
                    <Tooltip content={renderTooltipContent()} event="click" style="primary" className="tk-parts list-options">
                        {useNewNavbar ? (
                            <Button variant="outlined" startIcon={<Icon name="settings" />} />
                        ) : (
                            <Button variant="outlined">{translateText(361)}</Button>
                        )}
                    </Tooltip>
                </Box>
            </Toolbar>
            <PurchasePriceSwitch />
            <StyledCompactViewToolbar title={translateText(3009)}>
                <Tooltip className="tk-parts" content={translateText(1804)}>
                    <Switch
                        checked={user?.userSettings?.articleListSettings?.viewOptions?.compactView}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeCompactView(event.target.checked)}
                    />
                </Tooltip>
            </StyledCompactViewToolbar>
            <Toolbar className="tk-parts" title={translateText(209)}>
                {renderFilterModalButton()}
                <Button
                    variant="outlined"
                    disabled={!isResetBtnEnabled}
                    onClick={handleAllFiltersReset}
                    title={translateText(1664)}
                    startIcon={<Icon name="remove-filter" />}
                />
            </Toolbar>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "useNewNavbar",
})<{ useNewNavbar?: boolean }>(({ useNewNavbar }) => ({
    flex: 1,
    justifyContent: !useNewNavbar ? "flex-end" : "unset",
    display: "flex",
    marginLeft: useNewNavbar ? "9.8%" : "0",
}))

const StyledCompactViewToolbar = styled(Toolbar)({
    "&:before": {
        borderLeft: "transparent !important",
    },
})
