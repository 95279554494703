import {
    CarModelDetails,
    RegistrationNoDetails,
    RegistrationNoType,
    ShowBikeModelDetailsResponse,
    ShowCarModelDetailsResponse,
    ShowTruckModelDetailsResponse,
    Vehicle,
    VehicleType,
} from "@tm/models"
import { uniqueId } from "@tm/utils"

/** This function enhances a GSI Vehicle with the supplied registration number data */
export function enhanceVehicle(vehicle: Vehicle, registrationNoDetails?: RegistrationNoDetails, registrationTypeId?: RegistrationNoType): Vehicle {
    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["vehicle"] }] */
    // Only add not already existing values
    vehicle.plateId = vehicle.plateId ?? registrationNoDetails?.plateId
    vehicle.vin = vehicle.vin ?? registrationNoDetails?.vin
    vehicle.registrationNo = vehicle.registrationNo ?? registrationNoDetails?.registrationNo ?? registrationNoDetails?.typScheinNr
    vehicle.registrationTypeId = registrationTypeId // Set registration type id to the one used for this data
    vehicle.initialRegistration = vehicle.initialRegistration ?? registrationNoDetails?.initialRegistration
    vehicle.engineCode = vehicle.engineCode ?? (registrationNoDetails?.engineCodes?.length === 1 ? registrationNoDetails.engineCodes[0] : undefined)
    vehicle.longlife = vehicle.longlife !== undefined ? vehicle.longlife : registrationNoDetails?.longlife
    vehicle.nextGeneralInspection = vehicle.nextGeneralInspection ?? registrationNoDetails?.nextGeneralInspection
    vehicle.thirdPartyIds = vehicle.thirdPartyIds ?? registrationNoDetails?.thirdPartyIds

    return vehicle
}

/** This function maps a ModelDetailsResponse to a GSI Vehicle */
export function mapModelDetailsToVehicle(
    details: ShowCarModelDetailsResponse,
    modelDetails: CarModelDetails,
    vehicleType: VehicleType,
    registrationTypeId?: RegistrationNoType
): Vehicle {
    const { modelSeries, manufacturer } = details

    // Map general TecDoc data
    let vehicle: Vehicle = {
        id: uniqueId(),
        tecDocManufacturerId: manufacturer?.id ?? modelDetails.manufacturerId,
        tecDocModelId: modelSeries?.id ?? modelDetails.modelSeriesId,
        tecDocTypeId: modelDetails.id,
        manufacturer: manufacturer?.description ?? "",
        modelSeries: modelSeries?.description ?? "",
        model: modelDetails.description,
        manufacturerThumbnail: manufacturer?.thumbnail ?? modelDetails.manufacturerThumbnail,
        modelSeriesThumbnail: modelSeries?.modelSeriesThumbnail ?? modelDetails.modelSeriesThumbnail,
        modelThumbnail: modelDetails.thumbnail,
        vehicleType,
    }

    // Map registration data
    vehicle = enhanceVehicle(vehicle, modelDetails.registrationNoDetails, registrationTypeId)

    // If vehicle has no engine code and TecDoc data only contains one, set it
    if (!vehicle.engineCode) {
        vehicle.engineCode = modelDetails.engineCodes?.length === 1 ? modelDetails.engineCodes[0] : undefined
    }

    // If vehicle has no registration no and TecDoc data only contains one, set it
    if (!vehicle.registrationNo) {
        vehicle.registrationNo = modelDetails.registrationNos?.length === 1 ? modelDetails.registrationNos[0] : undefined
    }

    return vehicle
}

export function mapBikeModelDetailsResponse(bikeModelDetails: ShowBikeModelDetailsResponse | undefined): ShowCarModelDetailsResponse | undefined {
    if (!bikeModelDetails) {
        return
    }

    const { manufacturer, modelSeries, modelDetails } = bikeModelDetails

    return {
        manufacturer: {
            ...manufacturer,
            bodyTypeIds: [],
        },
        modelSeries: {
            ...modelSeries,
            bodyTypeIds: [],
            mainModelSeriesDescription: "",
            mainModelSeriesId: -1,
            mainModelSeriesSortId: -1,
        },
        modelDetails: {
            ...modelDetails,
            cylinderCount: modelDetails.cylinderCount ?? 0,
            doorCount: modelDetails.doorCount ?? 0,
            engineCapacityLiter: modelDetails.engineCapacityLiter ?? 0,
            engineCapacityTaxation: modelDetails.engineCapacityTaxation ?? 0,
            enginePowerHp: modelDetails.enginePowerHp ?? 0,
            enginePowerKw: modelDetails.enginePowerKw ?? 0,
            engineType: modelDetails.engineType as never,
            fuelTankCapacity: modelDetails.fuelTankCapacity ?? 0,
            valvesPerCylinder: modelDetails.valvesPerCylinder ?? 0,
            voltage: modelDetails.voltage ?? 0,
            /** @todo: rethink this - currently we have to fill all properties of car model details */
        },
    }
}

export function mapTruckModelDetailsResponse(truckModelDetails: ShowTruckModelDetailsResponse | undefined): ShowCarModelDetailsResponse | undefined {
    if (!truckModelDetails?.modelDetails) {
        return
    }

    const { manufacturer, modelSeries, modelDetails } = truckModelDetails

    return {
        manufacturer: {
            ...manufacturer,
            bodyTypeIds: [],
        },
        modelSeries: {
            ...modelSeries,
            bodyTypeIds: [],
            mainModelSeriesDescription: "",
            mainModelSeriesId: -1,
            mainModelSeriesSortId: -1,
        },
        modelDetails: {
            ...modelDetails,
            registrationNos: modelDetails.registrationNo ? [modelDetails.registrationNo] : [],
            enginePowerHp: modelDetails.enginePowerHpFrom,
            enginePowerKw: modelDetails.enginePowerKwFrom,
            engineType: modelDetails.engineType as never,
            /** @todo: rethink this - currently we have to fill all properties of car model details */
            bodyType: "",
            brakeSystem: "",
            brakeType: "",
            catalystType: "",
            cylinderCount: 0,
            driveType: "",
            engineCapacityLiter: 0,
            fuelMixtureFormation: "",
            fuelType: "",
            hasAutodataInspectionData: false,
            hasAutodataRepairTimes: false,
            hasAutodataTechnicalData: false,
            hasHaynesProInspectionData: false,
            hasHaynesProTechnicalData: false,
            priority: 0,
            transmissionType: "",
            vehicleType: "",
            doorCount: 0,
            engineCapacityTaxation: 0,
            fuelTankCapacity: 0,
            hasABS: false,
            hasTCS: false,
            valvesPerCylinder: 0,
            voltage: 0,
        },
    }
}
