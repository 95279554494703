import { Divider, Stack } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { renderRoute } from "@tm/utils"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useCompilations, useShowCompilationPdf } from "../../data/hooks/useCompilations"
import { getBundleParams, isWM } from "../../utils"
import CompilationDetailsComponent from "./components/details"
import CompilationsListComponent from "./components/list"
import { Navigation } from "./components/navigation"
import SingleCompilationsComponent from "./components/singleWatchlist/component"

type RouteParams = {
    workTaskId: string
    compilationId?: string
}

export default function CompilationsComponent() {
    const { userContext } = useUser()
    const [query, setQuery] = useState("")
    const { compilations, isLoading, error } = useCompilations(query || undefined)
    const [showCreator, setShowCreator] = useState(false)
    const params = useParams<RouteParams>()
    const history = useHistory()
    const { compilationDetailsRoute, compilationsRoute } = getBundleParams()

    useEffect(() => {
        if (compilations && !compilations.length && params.compilationId) {
            openCompilations(true)
        }
    }, [compilations, params.compilationId])

    function openCompilations(replace = false) {
        openUrl(renderRoute(compilationsRoute, params), replace)
    }

    function openCompilationDetails(compilationId: string, replace = false) {
        window.document.querySelector(".module.worktask .worktask__content")?.scroll({ top: 0 })
        openUrl(renderRoute(compilationDetailsRoute, { ...params, compilationId }), replace)
    }

    function openExpandedOrOtherDetails() {
        if (!isWM() && compilations && compilations?.length > 1) {
            const toBeOpenedId = compilations.filter((c) => c.id !== params.compilationId).first()?.id
            return toBeOpenedId && openCompilationDetails(toBeOpenedId, true)
        }
        return openCompilations(true)
    }

    function openUrl(url: string, replace = false) {
        if (replace) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    const { compilationId } = params
    const { showCompilationPdf, loadingCompilationPdf } = useShowCompilationPdf(compilationId)

    // Use for anonym login (Industy Catalog) a fullscreen Compilationlist
    if (userContext.isAnonym) {
        return <SingleCompilationsComponent compilationId={compilations?.first()?.id} />
    }

    return (
        <Stack gap={2} flex={1}>
            <Navigation
                compilationId={compilationId}
                showCreator={showCreator}
                onShowCreator={() => setShowCreator(true)}
                searchCompilation={setQuery}
                openCompilations={openCompilations}
                loadingCompilationPdf={loadingCompilationPdf}
                onPrintButtonClick={() => showCompilationPdf()}
            />
            <Stack direction="row" gap={1} p={1} divider={<Divider orientation="vertical" />}>
                <CompilationsListComponent
                    compilations={compilations}
                    loading={isLoading}
                    error={error as any}
                    showCreator={showCreator}
                    compact={!!compilationId}
                    onHideCreator={() => setShowCreator(false)}
                    openCompilationDetails={openCompilationDetails}
                    openCompilationList={openCompilations}
                    query={query || undefined}
                />
                {compilationId && (
                    <CompilationDetailsComponent
                        compilationId={compilationId}
                        encodedWorkTaskId={params.workTaskId}
                        openCompilationList={openExpandedOrOtherDetails}
                    />
                )}
            </Stack>
        </Stack>
    )
}
