import { TyresCarTypeAddon, ETyresCarType, ETyresSeason, EFilterNames, TyreFilter, FilterNames, ETyreCountType } from "@tm/models"
import { getBundleParams } from "../../utils"
import { CarTypeAddons } from "./addonMocks"

function getCarTypes(singleItem?: boolean): TyreFilter[] {
    const { addOns } = getBundleParams()

    const tyresCarTypes: TyresCarTypeAddon[] = Array.isArray(addOns)
        ? addOns.filter((x: TyresCarTypeAddon) => x.Id !== ETyresCarType.SUV)
        : CarTypeAddons.filter((x) => x.Default && x.Id !== ETyresCarType.SUV)

    const carTypes = singleItem ? [tyresCarTypes?.[0]] : tyresCarTypes

    return carTypes.map((x, idx) => ({
        group: EFilterNames.carType,
        value: x.Description,
        query: x.Id.toString(),
        info: undefined,
        bitValue: 1 << idx,
    }))
}

const seasons: TyreFilter[] = Object.values(ETyresSeason)
    .filter((x) => x !== ETyresSeason.default)
    .map((season) => ({ query: season, value: season, group: EFilterNames.season }))

const multiSelectionFilters: Array<FilterNames> = [
    EFilterNames.manufacturer,
    EFilterNames.loadIndex,
    EFilterNames.speedIndex,
    EFilterNames.oeIdentifier,
    EFilterNames.extras,
    EFilterNames.season,
    EFilterNames.carType,
    // TODO check extra unused keys
    // "studed",
    // "studdable",
    // "silent",
]

const tyreCountFilters: Array<TyreFilter> = [
    {
        value: ETyreCountType.singleTire,
        query: ETyreCountType.singleTire,
        group: EFilterNames.tyreCount,
    },
    {
        value: ETyreCountType.carTires,
        query: ETyreCountType.carTires,
        group: EFilterNames.tyreCount,
    },
    {
        value: ETyreCountType.motorcycleTires,
        query: ETyreCountType.motorcycleTires,
        group: EFilterNames.tyreCount,
    },
]

function getDefaultCarType(): TyreFilter {
    return getCarTypes().find((x) => x.query === ETyresCarType.PKW.toString())!
}

function getDefaultTyreCountFilter(): TyreFilter {
    return tyreCountFilters.find((x) => x.query === ETyreCountType.singleTire)!
}

export const Statics = {
    getDefaultCarType,
    getCarTypes,
    getDefaultTyreCountFilter,
    seasons,
    multiSelectionFilters,
    tyreCountFilters,
}
