import { Article, Item, RepairTimeProvider, ArticleInformation } from "@tm/models"
import { getArticleBonusSystemsWithPoints, getArticleBonusSystemsWithoutPoints, notUndefinedOrNull } from "@tm/utils"
import { BasketErpInfo, BasketErpInfoGroup, BasketOrderGroup, BasketPart, BasketWork } from "../../models"
import {
    BasketErpInformationRequestItem,
    BasketErpInformationResponseItem,
    CalculatePartRequest,
    CalculateWorkRequest,
    CalculatedOrderItem,
    CalculatedPart,
    ErpInformationItem,
    CalculatedWork,
    PartItem,
    WorkItem,
    WorkResponse,
    ArticleInformationRequest,
    OrderItemRequest,
    OrderItem,
    CostEstimationItemRequest,
    CostEstimationItem,
    PdfOrderGroup,
    PdfOrderItem,
    UpdateOrderRequestItem,
} from "../model"
import { mapOrderOptionsToSelectedOrderOptions } from "./order-options"
import { CalculateBonusItemRequest, CalculateBonusItemResponse } from "../model/CalculateBonusPoints"

function mapLinkedItem(
    workTaskId: string,
    linkedItem: PartItem,
    selectedPartIds: string[],
    beingUpdatedPartIds?: string[],
    erpinfo?: BasketErpInformationResponseItem,
    calculatedOrderItems?: CalculatedOrderItem[],
    calculatedParts?: CalculatedPart[]
): BasketPart {
    const linkedItemerpInfo = erpinfo?.linkedItems?.find((item) => item.itemId === linkedItem.id)
    const calculatedOrderItem = calculatedOrderItems?.find((calculatedItem) => calculatedItem.partItemId === linkedItem.id)
    const calculatedPart = calculatedParts?.find((part) => part.id === linkedItem.id)

    return {
        ...mapBasketPartItem(workTaskId, linkedItem, selectedPartIds, beingUpdatedPartIds),
        erpInfoResponse: linkedItemerpInfo,
        calculatedOrderItem,
        calculatedPart,
    }
}

function getPartErpResponse(partItemId: string, mainPartId?: string, erpInformation?: BasketErpInfo[]): BasketErpInformationResponseItem | undefined {
    let partItemErpInfo: BasketErpInformationResponseItem | undefined
    erpInformation?.forEach((info) =>
        info.erpInfos?.items?.forEach((item) => {
            // Linked Items
            if (mainPartId) {
                item?.linkedItems?.forEach((linkedItem) => {
                    if (linkedItem.itemId === partItemId) {
                        partItemErpInfo = linkedItem
                    }
                })
            } else if (item.itemId === partItemId) {
                partItemErpInfo = item
            }
        })
    )

    return partItemErpInfo
}

function getPartErpStatus(partItemId: string, mainPartId?: string, erpInformation?: BasketErpInfo[]): boolean {
    let isLoading = false
    erpInformation?.forEach((info) =>
        info.request.items?.forEach((item) => {
            // Linked Items
            if (mainPartId) {
                item?.linkedItems?.forEach((linkedItem) => {
                    if (linkedItem.itemId === partItemId) {
                        isLoading = info.erpInfosLoading
                    }
                })
            } else if (item.itemId === partItemId) {
                isLoading = info.erpInfosLoading
            }
        })
    )

    return isLoading
}

function getPartErpRequest(partItem: PartItem, erpInfoGroups?: BasketErpInfoGroup[]): BasketErpInformationRequestItem | undefined {
    return erpInfoGroups?.find((group) => group.parts.some((part) => part.itemId === partItem.id))?.parts.find((part) => part.itemId === partItem.id)
}

export function mapBasketPartItem(
    workTaskId: string,
    partItem: PartItem,
    selectedPartIds: string[],
    beingUpdatedPartIds?: string[],
    collapsedOrderOptionsBasketPartIds?: string[],
    expandedNoteBasketPartIds?: string[],
    orderOptionsBeingUpdatedPartIds?: string[],
    erpInfoBeingLoadedPartIds?: string[],
    article?: Article,
    erpInfoGroups?: BasketErpInfoGroup[],
    erpInformation?: BasketErpInfo[],
    calculatedOrderItems?: CalculatedOrderItem[],
    calculatedParts?: CalculatedPart[],
    availableRepairTimeProviders?: Record<number, RepairTimeProvider[]>,
    bonusPoints?: CalculateBonusItemResponse
): BasketPart {
    const erpInfoResponse = getPartErpResponse(partItem.id, partItem.mainPartId, erpInformation)
    const erpInfosLoading = getPartErpStatus(partItem.id, partItem.mainPartId, erpInformation)
    const erpInfoRequest = getPartErpRequest(partItem, erpInfoGroups)
    const calculatedOrderItem = calculatedOrderItems?.find((calculatedItem) => calculatedItem.partItemId === partItem.id)
    const calculatedPart = calculatedParts?.find((part) => part.id === partItem.id)
    const productGroupId = article?.productGroup.id ?? partItem.articleInformation.productGroupId

    return {
        partItem: {
            ...partItem,
            articleInformation: {
                ...partItem.articleInformation,
                additionalDescription: article?.additionalDescription ?? partItem.articleInformation.additionalDescription,
                description: article?.description ?? partItem.articleInformation.description,
                productGroupId,
                productGroupName: article?.productGroup.name ?? partItem.articleInformation.productGroupName,
                thumbnailUrl: article?.thumbnail ?? partItem.articleInformation.thumbnailUrl,
            },
        },
        states: {
            isBeingUpdated: beingUpdatedPartIds?.includes(partItem.id),
            isErpInfoBeingLoaded: erpInfoBeingLoadedPartIds?.includes(partItem.id) || erpInfosLoading,
            isNoteExpanded: expandedNoteBasketPartIds?.includes(partItem.id),
            isOrderOptionsBeingUpdated: orderOptionsBeingUpdatedPartIds?.includes(partItem.id),
            isOrderOptionsCollapsed: collapsedOrderOptionsBasketPartIds?.includes(partItem.id),
            isSelected: selectedPartIds.includes(partItem.id),
        },
        bonusPoints,
        bonusSystemsWithoutPoints: article ? getArticleBonusSystemsWithoutPoints(article) : undefined,
        erpInfoRequest,
        erpInfoResponse,
        article,
        linkedItems: partItem.linkedItems?.map((linkedItem) =>
            mapLinkedItem(
                workTaskId,
                linkedItem,
                selectedPartIds,
                beingUpdatedPartIds,
                erpInfoResponse,
                calculatedOrderItem?.linkedItems,
                calculatedPart?.linkedItems
            )
        ),
        calculatedOrderItem,
        calculatedPart,
        selectedOrderOptions: erpInfoResponse?.orderOptions
            ? mapOrderOptionsToSelectedOrderOptions(erpInfoResponse.orderOptions, workTaskId)
            : undefined,
        references: article?.references,
        repairTimeProviders: availableRepairTimeProviders && productGroupId ? availableRepairTimeProviders[productGroupId] : undefined,
    }
}

export function mapErpInformationRequestItem(item: PartItem): BasketErpInformationRequestItem {
    return {
        itemId: item.id,
        wholesalerArticleNumber: item.articleInformation.wholesalerArticleNumber,
        dataSupplierArticleNumber: item.articleInformation.articleNumber,
        dataSupplierId: item.articleInformation.supplierId,
        dataSupplierName: item.articleInformation.supplierName,
        productGroupId: item.articleInformation.productGroupId,
        productGroupName: item.articleInformation.productGroupName,
        articleDescription: item.articleInformation.description,
        quantityValue: item.quantity.value,
        warehouseId: item.orderItem?.warehouseId,
        vehicleId: item.vehicleId,
        foundBySearchTerm: item.foundBySearchTerm,
        isIncludedInOrder: item.orderItem?.isIncluded,
        articleInfoType: item.articleInformation.articleInfoType,
        oeArticleOrigin: item.articleInformation.oeArticleOrigin,
        linkedItems: item.linkedItems?.map((linkedtItem) => mapErpInformationRequestItem(linkedtItem)),
        externalBasketItemId: item.externalId,
    }
}

export function mapBasketWorkItem(
    workItem: WorkItem,
    selectedWorkIds: string[],
    beingUpdatedWorkIds: string[],
    expandedWorkIds: string[],
    works?: WorkResponse[],
    calculatedWorks?: CalculatedWork[],
    workEstimationWithError?: boolean
): BasketWork {
    const estimatedWork = works?.find((item) => item.id === workItem.id)
    const calculatedWork = calculatedWorks?.find((calc) => calc.id === workItem.id)
    return {
        workItem: {
            ...workItem,
            includedWorks: estimatedWork?.includedWorks || workItem.includedWorks, // The included works have the same type in work and WorkItem
        },
        states: {
            isSelected: selectedWorkIds.includes(workItem.id),
            isExpanded: expandedWorkIds.includes(workItem.id),
            isBeingUpdated: beingUpdatedWorkIds?.includes(workItem.id),
        },
        estimatedWork,
        calculatedWork,
        hasErrors: workEstimationWithError,
    }
}

export function getMatchingCatalogArticle(partItem: PartItem, articles: Article[]) {
    return articles.find(
        (article) =>
            !!partItem.articleInformation.articleNumber &&
            !!partItem.articleInformation.supplierId &&
            !!partItem.articleInformation.productGroupId &&
            partItem.articleInformation.supplierId === article.supplier.id &&
            partItem.articleInformation.articleNumber === article.supplierArticleNo &&
            partItem.articleInformation.productGroupId === article.productGroup.id
    )
}

export function getMatchingBonusPoints(partItem: PartItem, calculatedBonusPointItems?: CalculateBonusItemResponse[]) {
    return calculatedBonusPointItems?.find(
        (bonusPointItem) =>
            !!partItem.articleInformation.articleNumber && !!partItem.articleInformation.supplierId && partItem.id === bonusPointItem.id
    )
}

export function mapCalculatePartRequest(partItem: PartItem, erpInformation: BasketErpInfo[]): CalculatePartRequest {
    const erpInfoResponse = getPartErpResponse(partItem.id, partItem.mainPartId, erpInformation)

    return {
        id: partItem.id,
        articleInformation: mapArticleinformationRequest(partItem.articleInformation),
        itemRole: partItem.itemRole,
        linkedItems: partItem.linkedItems?.map((linkedtItem) => mapCalculatePartRequest(linkedtItem, erpInformation)),
        mainPartId: partItem.mainPartId,
        quantity: partItem.quantity,
        costEstimationItem: mapCostEstimationItemRequest(partItem.costEstimationItem),
        orderItem: mapOrderItemrequest(partItem.orderItem),
        erpInformationItem: mapErpInformationItem(erpInfoResponse),
    }
}

export function mapCalculateBonusItemRequest(partItem: PartItem, articles: Article[]): CalculateBonusItemRequest | undefined {
    const article = getMatchingCatalogArticle(partItem, articles)

    if (article?.availableBonusSystems?.some((bonusSystem) => !!bonusSystem.value)) {
        return {
            id: partItem.id,
            isInOrder: partItem.orderItem?.isIncluded,
            quantityValue: partItem.quantity.value,
            bonusPointsPerUnit: mapBonusPoints(article),
        }
    }
}

function mapBonusPoints(article: Article): Record<number, number> {
    return getArticleBonusSystemsWithPoints(article).reduce(
        (bonusPoints, bonusSystem) => ({ ...bonusPoints, [bonusSystem.id]: bonusSystem.value }),
        {}
    )
}

function mapArticleinformationRequest(articleInformation: ArticleInformation): ArticleInformationRequest {
    return {
        articleInfoType: articleInformation.articleInfoType,
        articleNumber: articleInformation.articleNumber,
        description: articleInformation.description,
        productGroupName: articleInformation.productGroupName,
        supplierName: articleInformation.supplierName,
        wholesalerArticleNumber: articleInformation.wholesalerArticleNumber,
    }
}

function mapOrderItemrequest(orderItem?: OrderItem): OrderItemRequest | undefined {
    if (!orderItem) {
        return undefined
    }

    return {
        isIncluded: orderItem.isIncluded,
        retailPrice: orderItem.retailPrice,
        customOrderPriceCalculation: orderItem.customOrderPriceCalculation,
    }
}

function mapCostEstimationItemRequest(costEstimationItem?: CostEstimationItem): CostEstimationItemRequest | undefined {
    if (!costEstimationItem) {
        return undefined
    }

    return {
        currencyCode: costEstimationItem.currencyCode,
        currencySymbol: costEstimationItem.currencySymbol,
        isIncluded: costEstimationItem.isIncluded,
        retailNetPrice: costEstimationItem.retailNetPrice,
        rebate: costEstimationItem.rebate,
        purchaseNetPrice: costEstimationItem.purchaseNetPrice,
        surcharge: costEstimationItem.surcharge,
        vatRates: costEstimationItem.vatRates,
    }
}

function mapErpInformationItem(erpInfoResponse?: BasketErpInformationResponseItem): ErpInformationItem | undefined {
    if (!erpInfoResponse) {
        return undefined
    }

    return {
        itemId: erpInfoResponse.itemId,
        isReplacementPart: erpInfoResponse.isReplacementPart,
        isOrderable: erpInfoResponse.isOrderable,
        prices: erpInfoResponse.prices,
    }
}

export function mapCalculateWorkRequest(workItem: WorkItem, works?: WorkResponse[]): CalculateWorkRequest {
    const work = works?.find((item) => item.id === workItem.id)
    return {
        id: workItem.id,
        provider: workItem.provider,
        providerWorkId: work?.providerWorkId || workItem.providerWorkId,
        type: workItem.type,
        description: work?.description || workItem.description,
        category: workItem.category,
        hourlyRate: workItem.hourlyRate,
        fixedPriceValue: workItem.fixedPriceValue,
        surcharge: workItem.surcharge,
        rebate: workItem.rebate,
        currencyCode: workItem.currencyCode,
        currencySymbol: workItem.currencySymbol,
        vatRates: workItem.vatRates,
        isIncluded: work?.isIncluded || workItem.isIncluded,
        customTimeInMinutes: workItem.customTimeInMinutes,
        timeInMinutes: work?.timeInMinutes || workItem.timeInMinutes,
        calculatedTimeInMinutes: work?.calculatedTimeInMinutes,
        hasCalculationError: work?.hasCalculationError,
    }
}

function mapBasketPartToItem(part: BasketPart, mainPartItem?: PartItem): Item {
    return {
        id: part.partItem.id,
        version: part.partItem.version,
        parentItem: mainPartItem
            ? {
                  id: mainPartItem.id,
                  version: mainPartItem.version,
              }
            : undefined,
    }
}

export function mapBasketPartsToItems(parts: BasketPart[], partItems?: PartItem[]) {
    return parts.map((part) => {
        let mainPartItem: PartItem | undefined
        if (part.partItem.mainPartId) {
            mainPartItem = partItems?.find((partItem) => partItem.id === part.partItem.mainPartId)
        }
        return mapBasketPartToItem(part, mainPartItem)
    })
}

function mapBasketWorkToItem(work: BasketWork): Item {
    return {
        id: work.workItem.id,
        version: work.workItem.version,
    }
}

export function mapBasketWorksToItems(works: BasketWork[]) {
    return works.map(mapBasketWorkToItem)
}

export function mapPdfOrderGroup(basketOrderGroup: BasketOrderGroup): PdfOrderGroup {
    return {
        distributorName: basketOrderGroup.orderGroup.distributorName,
        warehouseName: basketOrderGroup.orderGroup.warehouseName,
        orderItems: basketOrderGroup.basketParts.map((basketPart) => mapPdfOrderItem(basketPart)).filter(notUndefinedOrNull),
        orderOptions: basketOrderGroup.selectedOrderOptions,
    }
}

function mapPdfOrderItem(basketPart?: BasketPart): PdfOrderItem | undefined {
    if (!basketPart) {
        return undefined
    }
    const { partItem, erpInfoResponse, linkedItems } = basketPart

    return {
        id: partItem.id,
        articleInformation: {
            aditionalDescription: partItem.articleInformation.additionalDescription,
            articleNumber: partItem.articleInformation.articleNumber,
            description: partItem.articleInformation.description,
            productGroupName: partItem.articleInformation.productGroupName,
            supplierName: partItem.articleInformation.supplierName,
            wholesalerArticleNumber: partItem.articleInformation.wholesalerArticleNumber,
        },
        isIncluded: partItem.orderItem?.isIncluded ?? false,
        isOrderable: (partItem.orderItem?.isOrderable && erpInfoResponse?.isOrderable) ?? false,
        quantityValue: partItem.quantity.value,
        availabilityStatusDescription: erpInfoResponse?.availability.shortDescription ?? erpInfoResponse?.availability.description,
        erpPrices: erpInfoResponse?.prices,
        erpTotals: erpInfoResponse?.totalPrices,
        retailPrice: partItem.orderItem?.retailPrice,
        linkedItems: linkedItems?.map((linkedItem) => mapPdfOrderItem(linkedItem)).filter(notUndefinedOrNull),
    }
}

export function mapUpdateOrderPartRequest(partItem: PartItem, erpInformation: BasketErpInfo[]): UpdateOrderRequestItem {
    const erpInfoResponse = getPartErpResponse(partItem.id, partItem.mainPartId, erpInformation)
    return {
        itemId: partItem.id,
        hasAdditionalCosts: erpInfoResponse?.hasAdditionalCosts,
        isIncludedInOrder: partItem.orderItem?.isIncluded,
        isOrderable: erpInfoResponse?.isOrderable,
        tour: erpInfoResponse?.tour,
        warehoues: erpInfoResponse?.warehouses,
    }
}
