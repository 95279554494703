import { useEffect } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { useDisplayListV2 } from "@tm/context-distribution"
import { channel } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Box, styled } from "@tm/components"
import { getBundleParams } from "@bundles/wheels/utils"
import { WheelSelectionSteps } from "../../data/enums"
import ApplyButton from "../_shared/applyBtn"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { Actions } from "./business"
import TiresListComponent from "./components/tiresArticleList"
import { TiresFilters } from "./components/tiresFilters"
import { TyresArticleListV2 } from "./components/TyresArticleListV2"
import { TyresFiltersV2 } from "./components/TyresFiltersV2"

type Props = {
    repairTimesRoute: string
    detailsModalRoute?: string
}

export default function TyresList({ repairTimesRoute, detailsModalRoute }: Props) {
    const dispatch = useDispatch()
    const { request, selectedItems } = useSelector((s: MainState) => ({ selectedItems: s.tyresList.selectedItems, request: s.tyresList.request }))
    const displayListV2 = useDisplayListV2()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const isLKQ = getBundleParams()?.version === "LKQ"

    useEffect(() => {
        const unsubscribeErpModifiers = channel("GLOBAL").subscribe("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
            dispatch(Actions.setErpInformations(erpInfo))
        })

        dispatch(Actions.loadTiresList())

        return () => {
            unsubscribeErpModifiers?.()
        }
    }, [])

    const handleApplyButton = () => {
        batch(() => {
            dispatch(Actions.sendTireToOverview())
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, true))
            selectedItems && dispatch(MainActions.saveTyresListTab(selectedItems[0]))
        })
    }

    const handleSkipTab = () => {
        dispatch(MainActions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, false))
    }

    const renderList = () => {
        if (displayListV2 && !isWm) {
            return !request ? null : <TyresArticleListV2 />
        }

        return <StyledTiresList repairTimesRoute={repairTimesRoute} detailsModalRoute={detailsModalRoute} />
    }

    const renderFilters = () => {
        if (displayListV2 && !isWm) {
            return <TyresFiltersV2 />
        }
        return <StyledTiresFilters />
    }

    return (
        <StyledWrapperBox className="tyres-list" isLKQ={isLKQ}>
            <ApplyButton // render with Portal
                showApplyBtn
                disabledApplyBtn={selectedItems?.length !== 1}
                onApply={handleApplyButton}
                showSkipBtn
                onSkip={handleSkipTab}
            />
            {renderFilters()}
            {renderList()}
        </StyledWrapperBox>
    )
}

const StyledWrapperBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isLKQ",
})<{ isLKQ: boolean }>(({ isLKQ }) => ({
    width: "100%",
    display: "flex",
    flexDirection: isLKQ ? "row-reverse" : "row",
}))

const StyledTiresFilters = styled(TiresFilters)({
    flexBasis: "22em",
})

const StyledTiresList = styled(TiresListComponent)({
    flex: 1,
})
