import { Box, LinearProgress, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Article, OrderVoucherSupplierItem, RepairTimeProvider, RequestArticleDetailsPayload, Vehicle } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { useMemo, useState } from "react"
import { QueryFunctionContext, QueryOptions, useQueries } from "react-query"
import { Models } from "../../../../data"
import { KeyValueItemProps } from "../../../../data/model"
import { getArticleDetails } from "../../../../data/repositories"
import { ArticleComparisonItem } from "./ArticleComparisonItem"
import { getArticleUniqueId } from "../../business/helpers"

type Props = {
    partsToCompare: Article[]
    showArticleImages: boolean
    vehicle?: Vehicle
    customerId?: string
    foundBySearchTerm?: string
    previouslyOrderedArticles: Array<OrderVoucherSupplierItem>
    onRequestArticleDetails(request: RequestArticleDetailsPayload): void
    onUnselectArticle?(articleUniqueId: string): void
    getRepairTimesUrl?(article: Article, rtProviders: Array<RepairTimeProvider> | RepairTimeProvider): string | undefined
    onOpenPage?(): void
    onCloseWithSelection?(selectedPart: Article | null): void
    hideAddToBasketButton?: boolean
}

function createRequest(part: Article, vehicle: Vehicle | undefined): Models.DetailsRequest {
    const request: Models.DetailsRequest = {
        productGroupId: part.productGroup.id,
        supplierId: part.supplier.id,
        supplierArticleNo: part.supplierArticleNo,
        vehicleLinkageId: part.vehicleLinkageId,
        engineCode: vehicle?.engineCode,
    }

    // Only add the modelId/vehicleType if a vehicleLinkageId is also present,
    // otherwise details should be loaded without any vehicle relation
    if (vehicle && request.vehicleLinkageId) {
        request.vehicleType = vehicle.vehicleType
        request.modelId = vehicle.tecDocTypeId
    }

    return request
}

type QueryKey = [string, Models.DetailsRequest]

function loadPartDetails({ queryKey: [, request] }: QueryFunctionContext<QueryKey>): Promise<Models.ArticleDetailsResponse> {
    return getArticleDetails(request)
}

export function ArticleComparison({
    customerId,
    foundBySearchTerm,
    getRepairTimesUrl,
    onOpenPage,
    onRequestArticleDetails,
    onUnselectArticle,
    onCloseWithSelection,
    partsToCompare,
    previouslyOrderedArticles,
    showArticleImages,
    vehicle,
    hideAddToBasketButton,
}: Props) {
    const { translateText } = useLocalization()
    const [expandProperties, setExpandProperties] = useState(true)
    const [expandDetailedInfo, setExpandDetailedInfo] = useState(false)
    const [expandedReferences, setExpandedReferences] = useState(false)
    const [expandImages, setExpandImages] = useState(false)
    const [hoveredAttribute, setHoveredAttribute] = useState<string>()
    const [selectedPartId, setSelectedPartId] = useState<string | null>(null)

    const queries = useQueries(
        partsToCompare.map<QueryOptions<Models.ArticleDetailsResponse, unknown, Models.ArticleDetailsResponse, QueryKey>>((part) => ({
            queryKey: ["partComparison_partDetails", createRequest(part, vehicle)],
            queryFn: loadPartDetails,
        }))
    )

    const partsDetails = useMemo(() => queries.map((x) => x.data).filter(notUndefinedOrNull), [queries])
    const isLoading = partsDetails.length < partsToCompare.length

    function handleExpandProperties() {
        setExpandProperties(!expandProperties)
    }

    function handleExpandImages() {
        setExpandImages(!expandImages)
    }
    function handleExpandDetailedInfo() {
        setExpandDetailedInfo(!expandDetailedInfo)
    }

    function handleExpandReferences() {
        setExpandedReferences(!expandedReferences)
    }

    function handleItemHover(item: KeyValueItemProps | undefined) {
        setHoveredAttribute(item ? item.description : undefined)
    }

    function handleSelectPart(articleUniqueId: string) {
        setSelectedPartId(articleUniqueId)
        const selectedPart = partsToCompare.find((part) => getArticleUniqueId(part) === articleUniqueId)
        onCloseWithSelection?.(selectedPart || null)
    }

    function renderItems() {
        return partsDetails.map((partDetails, index) => {
            const part = partsToCompare[index]
            const previouslyOrdered = previouslyOrderedArticles.some(
                (x) => x.supplierArticleNumber === part.supplierArticleNo && x.supplierId === part.supplier.id
            )

            return (
                <ArticleComparisonItem
                    key={part.id}
                    article={{
                        ...part,
                        references: partDetails.article?.references ?? part.references,
                        images: partDetails.images,
                    }}
                    showArticleImage={showArticleImages}
                    expandDetailedInfo={expandDetailedInfo}
                    expandProperties={expandProperties}
                    expandReferences={expandedReferences}
                    expandImages={expandImages}
                    vehicleId={vehicle?.id}
                    customerId={customerId}
                    foundBySearchTerm={foundBySearchTerm}
                    previouslyOrdered={previouslyOrdered}
                    hoveredAttribute={hoveredAttribute}
                    onRequestArticleDetails={onRequestArticleDetails}
                    onUnselectArticle={partsToCompare.length > 2 ? onUnselectArticle : undefined}
                    getRepairTimesUrl={getRepairTimesUrl}
                    onToggleExpandDetailedInfo={handleExpandDetailedInfo}
                    onToggleExpandImages={handleExpandImages}
                    onToggleExpandProperties={handleExpandProperties}
                    onToggleExpandReferences={handleExpandReferences}
                    onSetHoveredAttribute={handleItemHover}
                    onOpenPage={onOpenPage}
                    hideAddToBasketButton={hideAddToBasketButton}
                    isSelected={selectedPartId === getArticleUniqueId(part)}
                    onSelectArticle={handleSelectPart}
                />
            )
        })
    }

    return (
        <div className={`article-comparision items-${partsToCompare.length}`}>
            <Typography variant="h1">{translateText(1818)}</Typography>
            {isLoading && !!partsToCompare.length && (
                <Box mt={1}>
                    <Typography mb={0.5}>{translateText(201)}...</Typography>
                    <LinearProgress variant="determinate" value={(partsDetails.length / partsToCompare.length) * 100} />
                </Box>
            )}
            <Stack direction="row" spacing={2} mt={1}>
                {!isLoading && renderItems()}
            </Stack>
        </div>
    )
}
