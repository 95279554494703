import { IBundle } from "@tm/morpheus"

import ArticleNotes from "./components/article-notes"
import CompactViewSwitch from "./components/compact-view-switch"
import ConsumableReplace from "./micro/consumable-replace"
import Details from "./components/details"
import DetailsDocuments from "./components/details-documents"
import DetailsFormulars from "./components/details-formulars"
import DetailsFrame from "./components/details-frame"
import DetailsHead from "./components/details-head"
import DetailsIndustrialInfo from "./components/details-industrial-info"
import DetailsNavigation from "./components/details-navigation"
import DetailsReferences from "./components/details-references"
import DirectSearchWidget from "./components/direct-search-widget"
import ExternalSystemDocuments from "./micro/external-system-documents"
import Filters from "./components/filters"
import History from "./components/history"
import List from "./components/list"
import ListOptions from "./components/list-options"
import ListV2 from "./components/ListV2"
import Manager from "./components/manager"
import ModalNavigation from "./components/modal-navigation"
import Navigation from "./components/navigation"
import OePartItem from "./micro/oe-part-item"
import Part from "./components/part"
import PartItem from "./micro/part-item"
import PartsIndicatorWidget from "./components/parts-indicator-widget"
import PurchasePriceSwitch from "./components/purchase-price-switch"
import { PureList, PureListBundle } from "./components/PureList"
import Search from "./components/search"
import SearchWidget from "./components/search-widget"
import SearchtreeV2 from "./components/searchtreeV2"
import { SimpleSearchWidgetBundle, SimpleSearchWidget } from "./components/simple-search-widget"
import ToastBarcodeSearch from "./components/toast-barcode-search"
import UniSearchWidget from "./components/uni-search-widget"
import VehicleSearchWidget from "./components/vehicle-search-widget"
import { WidgetBundle, Widget } from "./components/widget"
import WidgetAiStart from "./components/widget-ai-start"
import type { WidgetProps, TreeConfigKeys, TreeConfig } from "./components/widget"
import { initPartsBundle, version } from "./utils"
import { TyresList, TyresListBundle } from "./components/TyresList"
import { useProductGroupRepairTimes } from "./components/ListV2/ContextProvider/ProductGroupRepairTimes"
import { useArticleListSortingConfiguration } from "./components/ListV2/ArticleListConfiguration"
import { PartsAlternatives } from "./components/ListV2/PartsAlternatives"
import { OeParts } from "./components/ListV2/OeParts"
import { getArticleUniqueId } from "./components/list/business/helpers"
import { openPartDetailsInModal } from "./utils/modalHelper"
import { ArticleComparison } from "./components/list/components/comparison"
import { SortSelection } from "./components/_shared/SortSelection"
import { WheelsList, WheelsListBundle } from "./components/WheelsList"

const bundle: IBundle = {
    ...version,
    // debug: true,
    stylesUrl: "~/styles.css",
    components: [
        ArticleNotes,
        CompactViewSwitch,
        Details,
        DetailsDocuments,
        DetailsFormulars,
        DetailsFrame,
        DetailsHead,
        DetailsIndustrialInfo,
        DetailsNavigation,
        DetailsReferences,
        DirectSearchWidget,
        Filters,
        History,
        List,
        ListOptions,
        ListV2,
        Manager,
        ModalNavigation,
        Navigation,
        Part,
        PartsIndicatorWidget,
        PurchasePriceSwitch,
        PureListBundle,
        TyresListBundle,
        WheelsListBundle,
        Search,
        SearchWidget,
        SearchtreeV2,
        SimpleSearchWidgetBundle,
        ToastBarcodeSearch,
        UniSearchWidget,
        VehicleSearchWidget,
        WidgetBundle,
        WidgetAiStart,
    ],
    micros: [ExternalSystemDocuments, OePartItem, PartItem, ConsumableReplace],
    init: initPartsBundle,
}

export default bundle

type PartsBundleType = typeof bundle
export type { PartsBundleType }

// export available to be used in other bundles
export {
    //
    WidgetBundle,
    Widget,
    WidgetProps,
    TreeConfigKeys,
    TreeConfig,
    //
    SimpleSearchWidgetBundle,
    SimpleSearchWidget,
    //
    TyresList,
    WheelsList,
    //
    PureList,
    //
    OeParts,
    PartsAlternatives,
    ArticleComparison,
    SortSelection,
    //
    openPartDetailsInModal,
    getArticleUniqueId,
    useProductGroupRepairTimes,
    useArticleListSortingConfiguration,
}
