import { percent, rem } from "csx"
import { Loader } from "@tm/controls"
import { useStyle } from "@tm/context-distribution"
import { FC, PropsWithChildren } from "react"

type Props = PropsWithChildren<{
    loading?: boolean
}>

export const WrapperFrame: FC<Props> = ({ loading, children }) => {
    return (
        <div className={style.wrapper}>
            {loading && <Loader className={style.loader} />}
            {children}
        </div>
    )
}

const style = useStyle({
    wrapper: {
        flex: 1,
        height: percent(100),
        width: percent(100),
    },
    loader: {
        width: rem(3),
        height: rem(3),
        position: "relative",
        top: percent(20),
        left: percent(50),
    },
})(WrapperFrame)
