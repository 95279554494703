import { Button, Icon, Modal, Paper, Stack, Typography, styled, BasketButton } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ECounterType, RequestArticleDetailsPayload, channel } from "@tm/models"
import { TmaHelper, notUndefinedOrNull } from "@tm/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { getBundleParams } from "../../../utils"
import { openPartDetailsInModal } from "../../../utils/modalHelper"
import { ArticleComparison } from "../../list/components/comparison"
import { useArticlesErpInfos } from "../hooks/useArticleItem/useArticlesErpInfos"
import { useHandleAddToBasket } from "../hooks/useArticleItem/useHandleAddToBasket"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { useGetRepairTimesUrl } from "../hooks/useGetRepairTimesUrl"
import { useVehicle } from "../hooks/useVehicle"
import { ErpInfosData } from "../models"
import { useErpInfosContext } from "../ContextProvider"
import { useArticleListActionsContext } from "../ContextProvider/ArticleListActions"

export type ArticleSelectionProps = {
    hideBasketButton?: boolean
    maxArticleNumberToCompareReached?: boolean
    showArticleComparision?: boolean
    className?: string
}

const StyledPaper = styled(Paper)(({ theme }) => {
    const articleSelectionTop = theme.overwrites?.components?.articleSelection?.top

    return {
        position: "absolute",
        zIndex: "100",
        padding: theme.spacing(1, 2),
        top: articleSelectionTop || 0,
        right: "1.5em",
        border: "1px solid transparent",

        "&:hover": {
            border: `1px solid ${theme.colors?.primary}`,
        },
    }
})

export function ArticleSelection(props: ArticleSelectionProps) {
    const { selectedArticles } = useArticleSelection()
    const erpInfosData = useErpInfosContext()
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(!!selectedArticles.length)
    }, [selectedArticles])

    if (!show) {
        return null
    }

    return <ArticleSelectionComponent {...props} erpInfosData={erpInfosData} />
}

export function ArticleSelectionComponent(props: ArticleSelectionProps & { erpInfosData: ErpInfosData }) {
    const { showArticleComparision, hideBasketButton, maxArticleNumberToCompareReached, className, erpInfosData } = props
    const { translateText } = useLocalization()
    const { selectedArticles, deselectAll, deselectArticle, maximumPartsToCompare } = useArticleSelection()
    const [openCompare, setOpenCompare] = useState(false)
    const [quantity] = useState(1)
    const articlesErpInfo = useArticlesErpInfos(selectedArticles, quantity, erpInfosData)
    const { updateBasketQuantities } = useArticleListActionsContext()
    const handleAddToBasketInternal = useHandleAddToBasket(selectedArticles)

    const getRepairTimesUrl = useGetRepairTimesUrl()

    const getMaximumPartsToCompare = useMemo(
        () => maxArticleNumberToCompareReached ?? maximumPartsToCompare <= selectedArticles.length,
        [maximumPartsToCompare, selectedArticles, maxArticleNumberToCompareReached]
    )

    const vehicle = useVehicle()
    const history = useHistory()
    const searchParams = new URLSearchParams(history.location.search)
    const currentSearchQuery = searchParams.get("query") || ""
    const { workTaskId } = useWorkTask() ?? {}

    const basketButtonVisible = !hideBasketButton && selectedArticles.length >= 1

    const handleAddToBasket = useCallback(async () => {
        await handleAddToBasketInternal(
            quantity,
            articlesErpInfo.map((x) => (x.default?.state === "success" ? x.default.response : undefined)).filter(notUndefinedOrNull)
        )
        updateBasketQuantities?.(selectedArticles)
        deselectAll()
    }, [handleAddToBasketInternal, quantity, articlesErpInfo, updateBasketQuantities, selectedArticles, deselectAll])

    function handleRequestArticleDetails(request: RequestArticleDetailsPayload) {
        setOpenCompare(false)

        if (!workTaskId) {
            openPartDetailsInModal(request, {}, getBundleParams().partsRoutes)
        } else {
            channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
        }
    }

    const handleCompareEvent = () => {
        setOpenCompare(true)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListCompareArticles)
    }

    return (
        <>
            <StyledPaper className={className} elevation={3} sx={{ position: "sticky", margin: "0 150px 0 auto", width: "500px", top: "10px" }}>
                <Stack direction="row" spacing={0.5}>
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <Typography
                            variant="label"
                            fontWeight={getMaximumPartsToCompare ? "bold" : undefined}
                            color={getMaximumPartsToCompare ? "highlight" : undefined}
                        >
                            {translateText(1835).replace("{0}", maximumPartsToCompare.toString())}
                        </Typography>
                        <Typography variant="label">
                            {selectedArticles.length} {translateText(479)}
                        </Typography>
                        {showArticleComparision && (
                            <Button onClick={handleCompareEvent} disabled={selectedArticles.length <= 1}>
                                {translateText(1817)}
                            </Button>
                        )}
                    </Stack>
                    {basketButtonVisible && <BasketButton onAddToBasket={handleAddToBasket} disabled={false} onRemoveFromBasket={() => {}} className="article-selection"/>}
                    <Button startIcon={<Icon name="cancel-x" />} title={translateText(317)} onClick={deselectAll} />
                </Stack>
            </StyledPaper>
            <Modal open={openCompare} onOutsideClick={() => setOpenCompare(false)}>
                <ArticleComparison
                    partsToCompare={selectedArticles}
                    showArticleImages // TODO
                    vehicle={vehicle}
                    foundBySearchTerm={currentSearchQuery}
                    previouslyOrderedArticles={[]}
                    onUnselectArticle={deselectArticle}
                    onRequestArticleDetails={handleRequestArticleDetails}
                    getRepairTimesUrl={getRepairTimesUrl}
                    hideAddToBasketButton={hideBasketButton}
                />
            </Modal>
        </>
    )
}
