import { useCallback, useState } from "react"
import { PanelSection, SubTitle, Toolbar, Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ETyresSeason, TyresSeason } from "@tm/models"
import { Box, Button, Icon, styled, Tooltip, Typography } from "@tm/components"
import { Tyre } from "../../data/models"

type Props = {
    items: Tyre[]
    onTyreSelect(item: Tyre, isFrontTyres?: boolean, season?: TyresSeason): void
}

type State = {
    sortedList: Tyre[]
    sortAsc: boolean
    listIsSorted: boolean
    propertySorted: string
    isFrontTyres: boolean
}

export function DriveRightTyresTable({ items, onTyreSelect }: Props) {
    const { translateText } = useLocalization()

    const [state, setState] = useState<State>({
        sortedList: [],
        sortAsc: false,
        listIsSorted: false,
        propertySorted: "",
        isFrontTyres: false,
    })

    const handleSelection = (ev: React.MouseEvent<HTMLElement>, item: Tyre, isFrontTyres: boolean, season?: TyresSeason) => {
        ev.stopPropagation()
        onTyreSelect(item, isFrontTyres, season)
    }

    const renderTableHeadItem = (text: string, propertyName: string, isFrontTyres: boolean) => {
        return (
            <Box className="sortable is-clickable" onClick={() => sortElements(propertyName, isFrontTyres)}>
                <Typography fontSize="small">{text}</Typography>
                {propertyName === state.propertySorted && state.isFrontTyres === isFrontTyres && (
                    <Icon name={state.sortAsc ? "up" : "down"} className="icon--tiny" />
                )}
            </Box>
        )
    }

    const renderTyreSize = useCallback((item: Tyre, isFront: boolean) => {
        switch (true) {
            case item[isFront ? "seasonSummerFront" : "seasonSummerRear"] === true &&
                item[isFront ? "seasonWinterFront" : "seasonWinterRear"] === true:
                item[isFront ? "seasonFront" : "seasonRear"] = ETyresSeason.allSeason
                break
            case item[isFront ? "seasonSummerFront" : "seasonSummerRear"] === true:
                item[isFront ? "seasonFront" : "seasonRear"] = ETyresSeason.summer
                break
            case item[isFront ? "seasonWinterFront" : "seasonWinterRear"] === true:
                item[isFront ? "seasonFront" : "seasonRear"] = ETyresSeason.winter
                break
            default:
                break
        }
        return renderSize(item, isFront)
    }, [])

    const renderSize = (item: Tyre, isFront: boolean) => {
        return (
            <Table.Cell>
                <Typography onClick={(ev: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => handleSelection(ev, item, isFront)}>
                    {item[isFront ? "sizeFront" : "sizeRear"]} {item[isFront ? "loadIndexFront" : "loadIndexRear"]}
                    {item[isFront ? "speedIndexFront" : "speedIndexRear"]}
                </Typography>
            </Table.Cell>
        )
    }

    const sortElements = (propertyName: string, isFrontTyres: boolean) => {
        const sortByAsc = state.sortAsc ? 1 : -1
        let sortedArray: Tyre[] = []

        if (propertyName === "size") {
            sortedArray = items.sort((a, b) =>
                a[isFrontTyres ? "sizeFront" : "sizeRear"] > b[isFrontTyres ? "sizeFront" : "sizeRear"] ? -sortByAsc : sortByAsc
            )
        }

        if (propertyName === "rimSize") {
            sortedArray = items.sort((a, b) =>
                a[isFrontTyres ? "rimSizeFront" : "rimSizeRear"] > b[isFrontTyres ? "rimSizeFront" : "rimSizeRear"] ? -sortByAsc : sortByAsc
            )
        }

        if (propertyName === "offset") {
            sortedArray = items.sort((a, b) =>
                a[isFrontTyres ? "rimOffsetFront" : "rimOffsetRear"] > b[isFrontTyres ? "rimOffsetFront" : "rimOffsetRear"] ? -sortByAsc : sortByAsc
            )
        }

        setState((prevState) => ({
            sortedList: sortedArray,
            sortAsc: !prevState.sortAsc,
            listIsSorted: true,
            propertySorted: propertyName,
            isFrontTyres,
        }))
    }

    return (
        <StyledPanelSection>
            <StyledHeader>
                <StyledSubtitle>{translateText(353)}</StyledSubtitle>
                <StyledSubtitle>{translateText(354)}</StyledSubtitle>
            </StyledHeader>
            <Table
                scrollable
                data={state.listIsSorted ? state.sortedList : items}
                onClickRow={onTyreSelect}
                getRowClassName={() => "link is-clickable"}
                columns={[
                    <Table.Column key="size" className="size" renderItemContent={(item) => renderTyreSize(item, true)}>
                        {renderTableHeadItem(translateText(968), "size", true)}
                    </Table.Column>,
                    <Table.Column
                        key="rim"
                        className="rim"
                        renderItemContent={(item: Tyre) => (
                            <Table.Cell>
                                <Typography
                                    onClick={(ev: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => handleSelection(ev, item, true)}
                                >
                                    {item.rimSizeFront}
                                </Typography>
                            </Table.Cell>
                        )}
                    >
                        {renderTableHeadItem(translateText(1029), "rimSize", true)}
                    </Table.Column>,
                    <Table.Column
                        key="offset"
                        className="offset"
                        renderItemContent={(item: Tyre) => (
                            <Table.Cell>
                                <Typography
                                    onClick={(ev: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => handleSelection(ev, item, true)}
                                >
                                    {item.rimOffsetFront}
                                </Typography>
                            </Table.Cell>
                        )}
                    >
                        {renderTableHeadItem(translateText(1028), "offset", true)}
                    </Table.Column>,
                    <Table.Column
                        key="season"
                        className="season"
                        renderItemContent={(item: Tyre) => (
                            <Table.Cell>
                                <Toolbar modifiers={["title-before", "no-border"]}>
                                    <Tooltip title={translateText(800)}>
                                        <Button
                                            className="tyre-search__frame"
                                            startIcon={<Icon name="sun" />}
                                            disabled={!item.seasonSummerFront}
                                            onClick={(ev) => {
                                                handleSelection(ev, item, true, ETyresSeason.summer)
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title={translateText(801)}>
                                        <Button
                                            className="tyre-search__frame"
                                            startIcon={<Icon name="snow" />}
                                            disabled={!item.seasonWinterFront}
                                            onClick={(ev) => {
                                                handleSelection(ev, item, true, ETyresSeason.winter)
                                            }}
                                        />
                                    </Tooltip>
                                </Toolbar>
                            </Table.Cell>
                        )}
                    >
                        {translateText(1235)}
                    </Table.Column>,
                    <Table.Column key="size" className="size" renderItemContent={(item) => renderTyreSize(item, false)}>
                        {renderTableHeadItem(translateText(968), "size", false)}
                    </Table.Column>,
                    <Table.Column
                        key="rim"
                        className="rim"
                        renderItemContent={(item: Tyre) => (
                            <Table.Cell>
                                <Typography
                                    onClick={(ev: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => handleSelection(ev, item, false)}
                                >
                                    {item.rimSizeRear}
                                </Typography>
                            </Table.Cell>
                        )}
                    >
                        {renderTableHeadItem(translateText(1029), "rimSize", false)}
                    </Table.Column>,
                    <Table.Column
                        key="offset"
                        className="offset"
                        renderItemContent={(item: Tyre) => (
                            <Table.Cell>
                                <Typography
                                    onClick={(ev: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => handleSelection(ev, item, false)}
                                >
                                    {item.rimOffsetRear}
                                </Typography>
                            </Table.Cell>
                        )}
                    >
                        {renderTableHeadItem(translateText(1028), "offset", false)}
                    </Table.Column>,
                    <Table.Column
                        key="season"
                        className="season"
                        renderItemContent={(item: Tyre) => (
                            <Table.Cell>
                                <Toolbar modifiers={["title-before", "no-border"]}>
                                    <Tooltip title={translateText(800)}>
                                        <Button
                                            className="tyre-search__frame"
                                            startIcon={<Icon name="sun" />}
                                            disabled={!item.seasonSummerRear}
                                            onClick={(ev) => {
                                                handleSelection(ev, item, false, ETyresSeason.summer)
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title={translateText(801)}>
                                        <Button
                                            className="tyre-search__frame"
                                            startIcon={<Icon name="snow" />}
                                            disabled={!item.seasonWinterRear}
                                            onClick={(ev) => {
                                                handleSelection(ev, item, false, ETyresSeason.winter)
                                            }}
                                        />
                                    </Tooltip>
                                </Toolbar>
                            </Table.Cell>
                        )}
                    >
                        {translateText(1235)}
                    </Table.Column>,
                ]}
            />
        </StyledPanelSection>
    )
}

const StyledSubtitle = styled(SubTitle)({
    color: "#000000",
})

const StyledHeader = styled(Box)({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    marginRight: "1em",
})

const StyledPanelSection = styled(PanelSection)(({ theme }) => ({
    flex: "1",
    "& .panel__content": {
        padding: `${theme.margin?.m} 0`,
        height: "100% !important",
        "& .fancy-list": {
            "&__head": {
                paddingRight: theme.margin?.l || "0.75em",
                paddingLeft: theme.margin?.l || "0.75em",
                "& .sortable": {
                    display: "flex",
                    alignItems: "center",
                    "& .icon": {
                        marginTop: "0.1em",
                    },
                },
            },
            "& .sortable .season": {
                marginLeft: "1.5em",
            },
        },
    },
    ".fancy-list__block": {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 100%",
        marginLeft: theme.margin?.m,
        "& .size": {
            flex: "2 1 200%",
        },
        "& .toolbar__inner": {
            padding: 0,
        },
    },
}))
