import { ajaxAuth } from "@tm/utils"
import { AddReturnItemRequest, Item, ShowReturnsTotalNumbersRequest, ShowReturnsTotalNumbersResponse } from "@tm/models"
import { getCisServiceUrl } from "../.."
import {
    ChangeReturnQuantityRequest,
    SubmitReturnOrderRequest,
    SubmitReturnOrderResponse,
    EditReturnInfoRequest,
    ChangeReturnReasonRequest,
    ShowReturnReasonsFromMdmResponse,
    SubmitReturnOrderMailRequest,
    ShowReturnItemResponse,
    ShowReturnItemsResponse,
} from "../../model"

function getServiceUrl() {
    return `${getCisServiceUrl()}/ReturnItems`
}

export function addReturnItem(body: AddReturnItemRequest) {
    const url = `${getServiceUrl()}/AddReturnItem`
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function changeReturnQuantity(body: ChangeReturnQuantityRequest) {
    const url = `${getServiceUrl()}/ChangeReturnQuantity`
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function changeReturnReason(body: ChangeReturnReasonRequest) {
    const url = `${getServiceUrl()}/ChangeReturnReason`
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function editReturnInfo(body: EditReturnInfoRequest) {
    const url = `${getServiceUrl()}/EditReturnInfo`
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function excludeFromReturn(item: Item) {
    const url = `${getServiceUrl()}/ExcludeFromReturn`
    const body = { item }
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function excludeItemsFromReturn(itemList: Item[]) {
    const url = `${getServiceUrl()}/ExcludeItemsFromReturn`
    const body = { itemList }
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function includeInReturn(item: Item) {
    const url = `${getServiceUrl()}/IncludeInReturn`
    const body = { item }
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function includeItemsInReturn(itemList: Item[]) {
    const url = `${getServiceUrl()}/IncludeItemsInReturn`
    const body = { itemList }
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function removeReturnItem(id: string) {
    const url = `${getServiceUrl()}/RemoveReturnItem`
    const body = { id }
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export function removeReturnItemList(idList: string[]) {
    const url = `${getServiceUrl()}/RemoveReturnItemList`
    const body = { idList }
    return ajaxAuth<void>({ url, body, method: "POST" })
}

export async function submitReturnOrder(body: SubmitReturnOrderRequest) {
    const url = `${getServiceUrl()}/SubmitReturnOrder`
    return ajaxAuth<SubmitReturnOrderResponse>({ url, body, method: "POST" }, undefined, undefined, true)
}

export async function showReturnItem(body: { id: string }) {
    const url = `${getServiceUrl()}/ShowReturnItem`
    return ajaxAuth<ShowReturnItemResponse>({ url, body }, undefined, undefined, true)
}

export async function showReturnItems(body?: { customerNo?: string }) {
    const url = `${getServiceUrl()}/ShowReturnItems`
    return ajaxAuth<ShowReturnItemsResponse>({ url, body }, undefined, undefined, true)
}

export async function showTotalNumbers(body: ShowReturnsTotalNumbersRequest) {
    const url = `${getServiceUrl()}/ShowTotalNumbers`
    return ajaxAuth<ShowReturnsTotalNumbersResponse>({ url, body })
}

export async function showReturnReasonsFromMdm() {
    const url = `${getServiceUrl()}/ShowReturnReasonsFromMdm`
    return ajaxAuth<ShowReturnReasonsFromMdmResponse>({ url })
}

export async function submitReturnOrderMail(body: SubmitReturnOrderMailRequest) {
    const url = `${getServiceUrl()}/SubmitReturnOrderMail`
    return ajaxAuth<SubmitReturnOrderResponse>({ url, body, method: "POST" }, undefined, undefined, true)
}

export async function printReturnItems(body: any) {
    const url = `${getServiceUrl()}/PrintReturnItems`
    return ajaxAuth<number[]>({ url, body, responseType: "blob", method: "POST" })
}
