import { Toolbar, Switch } from "@tm/controls"
import { Switch as SwitchMui } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useDispatch, useSelector } from "react-redux"
import { MainState } from "../main"
import { BundleActions } from "../../business"
import { getBundleParams } from "../../utils"
import { useShowNewArticleList, useStyle } from "@tm/context-distribution"
import { useMemo } from "react"

function MountingSwitch() {
    const { translateText } = useLocalization()

    const dispatch = useDispatch()
    const isHostettler = getBundleParams()?.isHostettler
    const showNewArticleList = useShowNewArticleList()

    const style = useMemo(() => getStyle(), [])

    if (!isHostettler) {
        return null
    }

    const { mountingEnabled } = useSelector((s: MainState) => ({
        mountingEnabled: s.overview.mountingEnabled,
    }))

    const handleChange = (includeMount: boolean) => {
        dispatch(BundleActions.setMountingSwitchValue(includeMount))
    }

    if (showNewArticleList) {
        return (
            <Toolbar className={style.compactViewToolbar} title={translateText(13056)}>
                <SwitchMui checked={mountingEnabled} onChange={(event, checked) => handleChange(checked)} />
            </Toolbar>
        )
    }

    return (
        <Toolbar className="tk-parts switch mounting-switch" title={translateText(13056)}>
            <Switch status={mountingEnabled} onChange={handleChange} alignLabel="left" />
        </Toolbar>
    )
}

export default MountingSwitch

function getStyle() {
    return useStyle({
        compactViewToolbar: {
            $nest: {
                "&:before": {
                    borderLeft: "transparent !important",
                },
            },
        },
    })(MountingSwitch)
}
