import { ToggleSwitch } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useState } from "react"
import { PriceType } from "@tm/models"
import { BasketPart } from "../../../../../../models"
import { CustomOrderPriceCalculation } from "../../../../../../data/model"
import { findPrice } from "../../../../../../helpers"
import { ValueLabelStack } from "../../../../../ValueLabelStack"
import { StackRow, NumberTextField } from "../../../../../StyledComponents"
import { PartDialogTrigger } from "../../../../PartDialogTrigger"
import EditedPartErpPrices from "../../../../EditedPartErpPrices"

type Props = {
    disabled?: boolean
    part: BasketPart
    hidePurchasePrice?: boolean
    onEditOrderPrice?(part: BasketPart, customOrderPriceCalculation: CustomOrderPriceCalculation): void
}

export function PartEditor({ disabled, part, hidePurchasePrice, onEditOrderPrice }: Props) {
    const { erpInfoResponse, partItem } = part
    const { customOrderPriceCalculation } = partItem.orderItem || {}
    const { translateText } = useLocalization()
    const erpPrices = erpInfoResponse?.prices ? Object.values(erpInfoResponse?.prices) : undefined
    const purchasePrice = findPrice(erpPrices, PriceType.Purchase)
    const retailPrice = findPrice(erpPrices, PriceType.Retail)
    const minimumPurchasePrice = findPrice(erpPrices, PriceType.ValidationPurchaseMinPrice)?.value
    const [validationError, setValidationError] = useState("")

    const [initialRebate, initialFixedPrice, initalCurrencyCode, initialCurrencySymbol] = useMemo(() => {
        return [
            customOrderPriceCalculation?.rebate ?? 0,
            customOrderPriceCalculation?.amount ?? purchasePrice?.value,
            customOrderPriceCalculation?.currencyCode_Iso_4217 ?? purchasePrice?.currencyCode_Iso_4217,
            customOrderPriceCalculation?.currencySymbol ?? purchasePrice?.currencySymbol,
        ]
    }, [customOrderPriceCalculation, purchasePrice])

    const [editingRebate, setEditingRebate] = useState<boolean>()
    const [actualFixedPrice, setFixedPrice] = useState(initialFixedPrice)
    const [actualRebate, setRebate] = useState(initialRebate)
    const [actualCalculatedPrice, setCalculatedPrice] = useState<number>()
    const [fieldsHaveChanged, setFieldsHaveChanged] = useState(false)

    useEffect(() => {
        setEditingRebate(!!customOrderPriceCalculation?.rebate)
    }, [customOrderPriceCalculation?.rebate])

    useEffect(() => {
        setFixedPrice(initialFixedPrice)
    }, [initialFixedPrice])

    useEffect(() => {
        setRebate(initialRebate)
    }, [initialRebate])

    useEffect(() => {
        if (editingRebate && purchasePrice) {
            const calculatedPrice = purchasePrice.value - purchasePrice.value * (initialRebate / 100)
            setCalculatedPrice(calculatedPrice)
        } else if (!editingRebate) {
            setCalculatedPrice(initialFixedPrice)
        }
    }, [editingRebate, initialFixedPrice, purchasePrice, initialRebate])

    useEffect(() => {
        setFieldsHaveChanged(initialFixedPrice !== actualFixedPrice || initialRebate !== actualRebate)
    }, [actualRebate, actualFixedPrice, initialFixedPrice, initialRebate])

    useEffect(() => {
        if (actualCalculatedPrice && minimumPurchasePrice) {
            if (actualCalculatedPrice >= minimumPurchasePrice) {
                setValidationError("")
            } else {
                setValidationError(translateText(13930))
            }
        }
    }, [actualCalculatedPrice, minimumPurchasePrice])

    function handleFixedPriceChange(value: number | undefined) {
        setFixedPrice(value)
        setCalculatedPrice(value)
    }

    function handleChangeRebate(value: number) {
        setRebate(value ?? 0)
        if (purchasePrice) {
            const newPrice = purchasePrice.value - purchasePrice.value * (value / 100)
            setCalculatedPrice(newPrice)
        }
    }

    function handleToggleSwitchChange() {
        setEditingRebate(!editingRebate)
        resetValues()
    }

    function resetValues() {
        setCalculatedPrice(initialFixedPrice)
        setRebate(initialRebate)
        setFixedPrice(initialFixedPrice)
    }

    function handleConfirmButtonClick() {
        onEditOrderPrice?.(part, {
            priceType: PriceType.Purchase,
            amount: editingRebate ? undefined : actualFixedPrice,
            rebate: editingRebate ? actualRebate : undefined,
            currencySymbol: initialCurrencySymbol,
            currencyCode_Iso_4217: initalCurrencyCode,
        })
    }

    return (
        <PartDialogTrigger
            disableButton={disabled}
            part={part}
            showManufacturer
            showSupplierArticleNumbers
            showWholesalerArticleNumbers
            disableConfirmButton={validationError !== "" || !fieldsHaveChanged}
            disableResetButton={!fieldsHaveChanged}
            onConfirmButtonClick={handleConfirmButtonClick}
            onResetButtonClick={resetValues}
            onCloseButtonClick={resetValues}
            dialogFields={
                <StackRow flex={1} gap={3}>
                    <ToggleSwitch
                        size="small"
                        right={{ value: translateText(54), key: true }}
                        left={{ value: translateText(246), key: true }}
                        selected={editingRebate}
                        onChange={handleToggleSwitchChange}
                    />
                    <EditedPartErpPrices hidePurchasePrice={hidePurchasePrice} purchasePrice={purchasePrice} retailPrice={retailPrice} />
                    <ValueLabelStack label={translateText(89)} value="1" alignValueCenter />
                    {editingRebate ? (
                        <NumberTextField
                            label={`${translateText(54)} %`}
                            value={actualRebate}
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1,
                            }}
                            validateNow
                            errorHandling={[{ errorMessage: validationError }]}
                            onChange={(e) => handleChangeRebate(Number(e.target.value))}
                        />
                    ) : (
                        <NumberTextField
                            label={`${translateText(55)} ${initalCurrencyCode}`}
                            value={actualFixedPrice}
                            inputProps={{
                                min: 0,
                                max: 99999.99,
                                step: 1,
                            }}
                            validateNow
                            errorHandling={[{ errorMessage: validationError }]}
                            onChange={(e) => handleFixedPriceChange(Number(e.target.value))}
                        />
                    )}
                    <ValueLabelStack label={`${translateText(57)} ${initalCurrencyCode}`} value={actualCalculatedPrice} />
                </StackRow>
            }
        />
    )
}
