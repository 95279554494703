import { ImagePositionsResponse } from "."

export function mapImagePositionResponse(response: ImagePositionsResponse): ImagePositionsResponse {
    return {
        ...response,
        criteria: response.criteria.map((x) => {
            const { isEnabled, ...rest } = x
            return rest
        }),
    }
}
