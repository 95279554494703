import { ajaxAuth } from "@tm/utils"
import { getServiceUrl } from "."

export function importAppointment(): Promise<void> {
    return ajaxAuth({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportAppointment`,
        // body: request,
    })
}
