import { MenuItem, MenuList, ClickAwayListener } from "@tm/components"
import { useCallback, useEffect, useMemo, useRef, useState, KeyboardEvent } from "react"
import { useLocalization } from "@tm/localization"
import { useHistory, useLocation } from "react-router"
import { TmaHelper, encodeUniqueId, renderRoute } from "@tm/utils"
import { useWorkTaskId } from "@tm/context-distribution"
import { Suggestions } from "./Suggestions"
import { Hit, getUniProductGroupSuggestions } from "../../../../../data/repositories"
import { usePartsRoutes } from "../../../hooks/usePartsRoutes"
import { SearchField } from "./SearchField"
import { getBundleParams } from "../../../../../utils"
import useBreadCrumbHandler from "../../../../../hooks/breadCrumbhandler/useBreadCrumbHandler"

export function UniPartsSearch() {
    const workTaskId = useWorkTaskId()
    const { translateText } = useLocalization()
    const { search } = useLocation()
    const { resetSelectedNode } = useBreadCrumbHandler(undefined, "universalParts")
    const searchFieldRef = useRef<HTMLDivElement | null>(null)
    const timerRef = useRef<ReturnType<typeof setTimeout>>()
    const urlQuery = useMemo(() => new URLSearchParams(search).get("query") ?? "", [search])
    const [query, setQuery] = useState(urlQuery)
    const [suggestions, setSuggestions] = useState<Hit[]>([])
    const routes = usePartsRoutes()
    const history = useHistory()
    const [selectedSuggest, setSelectedSuggest] = useState<string>()
    const [showSuggestions, setShowSuggestions] = useState(false)

    useEffect(() => {
        setQuery(urlQuery)
    }, [urlQuery])

    useEffect(() => {
        clearTimeout(timerRef.current)
        const trimmedQuery = query.trim()
        if (trimmedQuery.length >= 2) {
            timerRef.current = setTimeout(async () => {
                const result = await getUniProductGroupSuggestions(trimmedQuery)
                if (result) {
                    setSuggestions(result)
                } else {
                    setSuggestions([])
                }

                resetSelectedNode()
            }, 200)
        } else {
            setSuggestions([])
        }
    }, [query])

    const handleChange = useCallback((value: string) => {
        setQuery(value)
        setShowSuggestions(true)
    }, [])

    const handleStartSearch = useCallback(
        (directQuery?: string) => {
            const route = routes.universalParts?.list
            if (!route || !workTaskId) {
                return
            }
            const searchQuery = directQuery ?? selectedSuggest ?? query.trim()
            if (searchQuery.length < getBundleParams().minimumSearchLength.universalSearch) {
                return
            }
            const baseUrl = renderRoute(route, { workTaskId: encodeUniqueId(workTaskId) })
            history.push(`${baseUrl}/unisearch?query=${encodeURIComponent(searchQuery)}`)
            TmaHelper.UniParts.Search.SetSearchContext("universalParts", searchQuery)
            setQuery(searchQuery)
            setShowSuggestions(false)
        },
        [routes, history, query, selectedSuggest]
    )

    const handleKeyStroke = useCallback(
        (e: KeyboardEvent) => {
            const selectionIndex = suggestions.findIndex((x) => x.productGroup === selectedSuggest)
            if (e.code === "ArrowUp") {
                e.preventDefault()
                if (selectionIndex <= 0) {
                    setSelectedSuggest(suggestions[suggestions.length - 1]?.productGroup)
                } else {
                    setSelectedSuggest(suggestions[selectionIndex - 1]?.productGroup)
                }
            } else if (e.code === "ArrowDown") {
                e.preventDefault()
                if (selectionIndex === -1 || selectionIndex === suggestions.length - 1) {
                    setSelectedSuggest(suggestions[0]?.productGroup)
                } else {
                    setSelectedSuggest(suggestions[selectionIndex + 1]?.productGroup)
                }
            }
        },
        [selectedSuggest, suggestions]
    )

    return (
        <>
            <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
                <SearchField
                    id="uniPartsSearchQuery"
                    value={query}
                    ref={searchFieldRef}
                    onChange={handleChange}
                    onClick={() => setShowSuggestions(true)}
                    onStartSearch={handleStartSearch}
                    onKeyDown={handleKeyStroke}
                    placeholder={translateText(194)}
                />
            </ClickAwayListener>

            {searchFieldRef.current && (
                <Suggestions anchor={searchFieldRef.current} isOpen={showSuggestions && !!suggestions.length}>
                    {/* 375px = 10 items in the list */}
                    <MenuList variant="menu" sx={{ maxHeight: "375px", overflowY: "auto" }}>
                        {suggestions.map((x) => (
                            <MenuItem
                                selected={selectedSuggest === x.productGroup}
                                key={x.productGroup}
                                onClick={() => handleStartSearch(x.productGroup)}
                            >
                                {x.productGroup}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Suggestions>
            )}
        </>
    )
}
