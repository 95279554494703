import { useStyle } from "@tm/context-distribution"
import { FittingPositionControl, PanelSection, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FittingPosition } from "@tm/models"
import { em, rem } from "csx"
import { useSelector } from "react-redux"
import { FC } from "react"
import { MainState } from "../../main"
import { HeadlineBox } from "../../shared"

const TirePressureComponent: FC<any> = () => {
    const { translateText } = useLocalization()

    const tirePresure = useSelector((s: MainState) => s.manager.statusData?.tirePressure)
    const { unit, ...rest } = tirePresure || {}

    let content = <FittingPositionControl selected={FittingPosition.None} onChange={() => {}} tirePressure={tirePresure} />

    if (!Object.keys(rest).length) {
        content = (
            <Text className={style.textPadding} size="l">
                {translateText(12775)}
            </Text>
        )
    }

    return (
        <PanelSection className={style.noMargin}>
            <HeadlineBox textId={12497} />
            <div className={style.tireContent}>{content}</div>
        </PanelSection>
    )
}

export default TirePressureComponent

const style = useStyle({
    tireContent: {
        marginBottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        $nest: {
            svg: {
                height: em(5),
            },
        },
    },
    textPadding: {
        padding: rem(1),
    },
    noMargin: {
        margin: 0,
    },
})(TirePressureComponent)
