import { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { Article, ArticleErpInfo, GetArticleListByMatchCodeRequest, SensorItemsRequest, TyreArticle } from "@tm/models"
import { ArticlesContext, useArticlesContext } from "../../../ListV2/ContextProvider/Articles/ArticlesContext"
import { useAfterPaintEffect } from "../../../ListV2/helpers"
import { WheelsListData } from "./models"
import { useWheelsList } from "./useWheelsList"

export type RequestType = GetArticleListByMatchCodeRequest | SensorItemsRequest
export type ArticleType = Article[] | TyreArticle[]

export type WheelsListProviderProps = PropsWithChildren<{
    setHasRendered: Dispatch<SetStateAction<boolean>>
    erpInfos: ArticleErpInfo[]
}>

export function WheelsListProvider({ children, setHasRendered, erpInfos }: WheelsListProviderProps) {
    const wheelsListData = useWheelsList(erpInfos)

    useAfterPaintEffect(() => {
        if (wheelsListData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [wheelsListData.requestStatus])

    return <ArticlesContext.Provider value={wheelsListData}>{children}</ArticlesContext.Provider>
}

export function useWheelsListContext<TContext extends WheelsListData>(): TContext
export function useWheelsListContext<TContext extends WheelsListData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useWheelsListContext<TContext extends WheelsListData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useArticlesContext(selector as never)
}
