import { useLocalization } from "@tm/localization"
import { Icon, Stack, VehicleHistory, TableColumnData, Table, TableCellData, Box, SortDirection, Tooltip, LicensePlate } from "@tm/components"
import { LicensePlateComponentInitiator, Note, UserContext, WorktaskInfo } from "@tm/models"
import { getShortCountryCodeByUserContext } from "@tm/utils"
import { Sort } from "../../../data/repositories"
import { getStatusText } from "../../../helpers"
import WorkTaskActions from "./WorkTaskActions"

type Props = {
    allowOpenClosedWorktask?: boolean
    checkVoucherTypeId?: boolean
    externalSystemId?: number
    sentOrderRoute?: string
    sentOrderVoucherTypeId?: number
    userContext?: UserContext | undefined
    userHasCooperationId?: boolean
    workTaskNotes?: Array<Note>
    workTasks?: Array<WorktaskInfo>
    onCreateNewWorkTask(workTask: WorktaskInfo, vehicle?: string): void
    onScrollBottom(): void
    onSearchForWorkTasks(sort?: Sort): void
}

export default function WorkTasksTableComponent({
    allowOpenClosedWorktask,
    checkVoucherTypeId,
    externalSystemId,
    sentOrderRoute,
    sentOrderVoucherTypeId,
    userContext,
    userHasCooperationId,
    workTasks,
    workTaskNotes,
    onCreateNewWorkTask,
    onScrollBottom,
    onSearchForWorkTasks,
}: Props) {
    const { translateText, date } = useLocalization()
    if (!workTasks) {
        return null
    }

    function getRowColor(rowData: WorktaskInfo): string | undefined {
        if (!rowData || !rowData.status || !workTasks) {
            return undefined
        }

        switch (rowData.status) {
            case 1:
                return "#99d43c"
            case 2:
                return "#ff5959"
            case 4:
            default:
                return "#ebb434"
        }
    }

    function handleSort(field: string, direction: SortDirection) {
        if (direction === false) {
            onSearchForWorkTasks()
            return
        }
        onSearchForWorkTasks({ field, desc: direction === "desc" })
    }

    function renderWorkTaskNote(workTaskInfo: WorktaskInfo) {
        const note = workTaskNotes?.find((note) => note.noteId === workTaskInfo.id)
        if (!note) {
            return <></>
        }
        return (
            <Tooltip title={note?.text}>
                <Icon width={20} height={20} name="note" />
            </Tooltip>
        )
    }

    function renderVehicle(workTaskInfo: WorktaskInfo) {
        return (
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Box>{workTaskInfo.vehicle}</Box>
                <Box>
                    {workTaskInfo.vehicleHistory?.length > 0 && (
                        <VehicleHistory
                            vehicle={{
                                description: workTaskInfo.vehicle,
                                id: workTaskInfo.vehicleId,
                                plateId: workTaskInfo.plateId,
                                vin: workTaskInfo.vin,
                                registrationNumber: workTaskInfo.registrationNumber,
                            }}
                            vehicleHistory={workTaskInfo.vehicleHistory}
                            handleSelect={(v: string) => onCreateNewWorkTask(workTaskInfo, v)}
                            userContext={userContext}
                            displayCurrentVehicle
                        />
                    )}
                </Box>
            </Stack>
        )
    }

    function renderLicensePlate(workTaskInfo: WorktaskInfo) {
        const countryCode = getShortCountryCodeByUserContext(userContext)

        if (!workTaskInfo.plateId || !countryCode) {
            return null
        }

        return (
            <LicensePlate
                value={workTaskInfo.plateId}
                countryCode={countryCode}
                size="extralarge"
                licensePlateComponentInitiator={LicensePlateComponentInitiator.WorkTaskHistory}
            />
        )
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(1759).toLocaleUpperCase() },
            { header: translateText(1760).toLocaleUpperCase(), onSort: (direction) => handleSort("partnerno", direction) },
            { header: translateText(108).toLocaleUpperCase(), onSort: (direction) => handleSort("company", direction) },
            {
                header: translateText(98).toLocaleUpperCase(),
                onSort: (direction) => handleSort("createddate", direction),
                startSortDirection: "desc",
            },
        ]
        if (userHasCooperationId) {
            columns.push({ header: translateText(28).toLocaleUpperCase() })
        }
        columns.push(
            { header: translateText(341).toLocaleUpperCase(), onSort: (direction) => handleSort("employee", direction) },
            { header: translateText(1761).toLocaleUpperCase(), onSort: (direction) => handleSort("commission", direction) },
            { header: translateText(13012).toLocaleUpperCase() },
            { header: translateText(1782).toLocaleUpperCase(), onSort: (direction) => handleSort("vehicle", direction) },
            { header: translateText(21).toLocaleUpperCase(), onSort: (direction) => handleSort("plateid", direction) },
            { header: translateText(90).toLocaleUpperCase() },
            {}
        )

        return columns
    }

    function getCellData(workTask: WorktaskInfo) {
        const cellData: TableCellData[] = [
            { displayValue: getStatusText(workTask.status), id: "workTask_0" },
            { displayValue: workTask.partnerNo, id: "workTask_1" },
            { displayValue: workTask.company, id: "workTask_2" },
            { displayValue: date(workTask.createdDate, "g"), id: "workTask_3" },
        ]

        if (userHasCooperationId) {
            cellData.push({ displayValue: workTask.cooperationMemberDisplayName, id: "workTask_4" })
        }
        cellData.push(
            { displayValue: workTask.employee, id: "workTask_5" },
            { displayValue: workTask.commision, id: "workTask_6" },
            { displayValue: renderWorkTaskNote(workTask), id: "workTask_7" },
            { displayValue: renderVehicle(workTask), id: "workTask_8" },
            { displayValue: renderLicensePlate(workTask) ?? workTask.plateId, id: "workTask_9" },
            { displayValue: workTask.partsCount, id: "workTask_10" },
            {
                displayValue: (
                    <WorkTaskActions
                        allowOpenClosedWorktask={allowOpenClosedWorktask}
                        checkVoucherTypeId={checkVoucherTypeId}
                        externalSystemId={externalSystemId}
                        sentOrderRoute={sentOrderRoute}
                        sentOrderVoucherTypeId={sentOrderVoucherTypeId}
                        workTaskInfo={workTask}
                        handleClickCreateNewWorktask={onCreateNewWorkTask}
                    />
                ),
                id: "workTask_11",
            }
        )

        return cellData
    }
    const displayData = workTasks?.map((workTask, index) => ({
        cells: getCellData(workTask),
        id: `${index}`,
        customRow: false,
        active: false,
        leftRowIndicatorColor: getRowColor(workTask),
    }))

    return <Table fontSize={14} columns={getColumns()} rows={displayData} overflowY="auto" onScrollBottom={onScrollBottom} />
}
