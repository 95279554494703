import { getStoredAuthorization, ajax, TmaHelper } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { GetArticleListByMatchCodeRequest, GetArticleListByMatchCodeResponse } from "./model"
import { mapTireArticlesResponse } from "./mapper"
import { GetArticlesListMappedResponse, GetArticlesRequest, getCurrentWorkTaskId, GetUniArticlesResponse } from "@tm/models"

export function loadTiresArticles(request: GetArticleListByMatchCodeRequest) {
    const url = `${getServiceUrl()}/GetArticleListByMatchCode`
    const authorization = getStoredAuthorization()
    const body = request

    TmaHelper.UniParts.Search.WheelsSelection(request?.query ?? "")

    return new Promise<GetArticlesListMappedResponse>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((response: GetArticleListByMatchCodeResponse) => {
            if (response) {
                sendTmaInfo(url, request, response)

                return resolve(mapTireArticlesResponse(response))
            }

            reject()
        }, reject)
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.uniArticlesServiceUrl
}

function sendTmaInfo(url: string, request: GetArticleListByMatchCodeRequest, response: GetArticleListByMatchCodeResponse) {
    TmaHelper.UniParts.List.Results(request as GetArticlesRequest, response as GetUniArticlesResponse, getCurrentWorkTaskId() ?? "", url, undefined)
}
