import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Image, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { em } from "csx"
import { FC } from "react"

const VehicleDetails: FC<any> = () => {
    const { translate } = useLocalization()
    const worktaskVehicle = useWorkTask()?.workTask?.vehicle

    if (!worktaskVehicle) {
        return null
    }

    return (
        <div className={style.vehicle}>
            <div className={style.vehicleTitle}>{translate(453)}</div>
            <div className={style.wrapImages}>
                {worktaskVehicle.manufacturerThumbnail && <Image className={style.manufacturerImage} url={worktaskVehicle.manufacturerThumbnail} />}
                {worktaskVehicle.modelThumbnail && <Image className={style.modelImage} url={worktaskVehicle.modelThumbnail} />}
            </div>
            <div className="headline--l">{worktaskVehicle.manufacturer}</div>
            <div className={style.model}>
                {worktaskVehicle.modelSeries}, {worktaskVehicle.model}
            </div>
            <div className="headline--xs">{worktaskVehicle.vin}</div>
            <Text>{translate(101)}</Text>
        </div>
    )
}

const style = useStyle({
    vehicle: {},
    vehicleTitle: {
        textTransform: "uppercase",
        fontSize: em(1),
        color: "#727272",
    },
    wrapImages: {
        display: "flex",
        alignItems: "center",
    },
    manufacturerImage: {
        maxHeight: em(8),
        maxWidth: em(8),
    },
    modelImage: {
        maxWidth: em(10),
        maxHeight: em(10),
    },
    model: {
        fontSize: em(1.125),
        color: "#494949",
        opacity: 0.87,
        marginBottom: em(1.25),
    },
    text: {
        fontSize: em(0.8),
        color: "#202020",
        opacity: 0.54,
    },
})(VehicleDetails)

export default VehicleDetails
