import { FC, Suspense, useCallback, useEffect, useMemo, useState } from "react"
import { VehicleType, channel } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { convertDataURIToBlob, RouteComponentProps, withRouter } from "@tm/utils"
import { Loader } from "@tm/components"
import { useVrcLookupModules } from "../../data/hooks/vrc-lookup"
import Upload from "./components/upload"
import Result from "./components/result"
import Confirm from "./components/confirm"
import { useTransferInfo } from "../../data/hooks/fast-up"
import { TransferInfoResponse, upload } from "../../data/repositories/fast-up"
import SharedLayout from "./components/_shared-layout"
import { RetryError } from "./components/retry-error"
import { useSelectedVehicleLookup } from "../../data/hooks/useSelectedLookupConfig"

type Props = RouteComponentProps & {
    transferInfo: TransferInfoResponse
    refreshTransferInfo(): Promise<unknown>
}

type VrcFileInfo = {
    isImage: boolean
    rotation?: number
}

export type VrcLocalFile = Required<VrcFileInfo> & {
    data: string
}

export type VrcUploadedFile = Required<VrcFileInfo> & {
    data?: string
    fastUpId: string
    url: string
}

type State =
    | {
          mode: "upload"
          file?: VrcLocalFile
      }
    | {
          mode: "confirm"
          file: VrcLocalFile | VrcUploadedFile
      }
    | {
          mode: "result"
          file: VrcUploadedFile
      }

/** Vehicle Registration Certificate Scan */
const VrcScan: FC<Props> = (props: Props) => {
    const modules = useVrcLookupModules()

    const { workTaskId } = useWorkTask() || {}
    const { selectedVehicleLookup, setSelectedVehicleLookup, selectableWithoutDuplicates } = useSelectedVehicleLookup(VehicleType.PassengerCar)

    const { transferInfo, refreshTransferInfo } = props

    const [state, setState] = useState<State>({ mode: "upload" })
    const [activeModuleIdString, setActiveModuleIdString] = useState<string | undefined>()

    // by default the selected lookup type should find a corresponding vrc module
    const lookupDependingModule = useMemo(() => {
        const selectedLookupIsoCode = selectedVehicleLookup?.countryCode?.toLowerCase()
        return modules.find((vrcModule) => vrcModule.countryCodes?.some((cc) => cc.toLocaleLowerCase() === selectedLookupIsoCode)) // for now all vrc module will only have one countrycode
    }, [modules, selectedVehicleLookup])

    // if the user selects a scanner the activeModuleId will be changed, so this is the selected module
    const selectedModule = useMemo(
        () => modules.find((vrcModule) => vrcModule.moduleId.toString() === activeModuleIdString),
        [activeModuleIdString, modules]
    )

    // this module will be used for the scan
    const activeModule = useMemo(() => selectedModule ?? lookupDependingModule ?? modules[0], [lookupDependingModule, selectedModule, modules])

    // this should set the vehicle lookup depending on the userselected vrc module
    useEffect(() => {
        const selectedLookup = selectableWithoutDuplicates.find((lookup) =>
            selectedModule?.countryCodes?.some((cc) => cc.toLowerCase() === lookup?.countryCode?.toLowerCase())
        )
        selectedLookup && setSelectedVehicleLookup(selectedLookup)
    }, [selectedModule, selectableWithoutDuplicates, setSelectedVehicleLookup])

    useEffect(() => {
        if (workTaskId) {
            channel("WORKTASK", workTaskId).publish("MODULE/OPENED", { title: "{{12617}}", isSelected: true, url: "" })
            return () => {
                channel("WORKTASK", workTaskId).publish("MODULE/CLOSED", `${props.location.pathname}${props.location.search}`)
            }
        }
    }, [workTaskId])

    const onLanguageChange = useCallback(
        (value: string) => {
            setActiveModuleIdString && setActiveModuleIdString(value)
        },
        [setActiveModuleIdString]
    )

    const resetTransferId = useCallback(async () => {
        // if the file was already uploaded (so it has an url)
        // the FastUp transferId cannot be reused so get a new one
        if (state.file && "url" in state.file) {
            await refreshTransferInfo()
        }
    }, [state.file])

    const handleFileUpload = useCallback(
        (file: VrcLocalFile | VrcUploadedFile) => {
            setState({ mode: "confirm", file })
        },
        [resetTransferId]
    )

    const handleFileChange = useCallback(
        (file: VrcLocalFile) => {
            setState({ mode: "confirm", file })
        },
        [resetTransferId]
    )

    const handleResetFile = useCallback(async () => {
        resetTransferId()
        setState({ mode: "upload", file: undefined })
    }, [resetTransferId])

    const handleConfirm = useCallback(
        async (file: VrcLocalFile | VrcUploadedFile) => {
            if ("data" in file) {
                if (file.data) {
                    // file is not yet uploaded to FastUp
                    const files = [convertDataURIToBlob(file.data)]
                    const response = await upload({
                        transferId: transferInfo.transferId,
                        files,
                        fileType: file.isImage ? "image" : "pdf",
                    })

                    if (response?.storageUris.length) {
                        setState({
                            mode: "result",
                            file: {
                                data: file.data,
                                url: response.storageUris[0],
                                isImage: !response.storageUris[0].endsWith(".pdf"),
                                fastUpId: transferInfo.transferId,
                                rotation: file.rotation ?? 0,
                            },
                        })
                    }
                }
            } else {
                // file is already uploaded to FastUp
                setState({
                    mode: "result",
                    file: {
                        url: file.url,
                        data: file.data,
                        isImage: !file.url.endsWith(".pdf"),
                        fastUpId: transferInfo.transferId,
                        rotation: file?.rotation ?? 0,
                    },
                })
            }
        },
        [transferInfo?.transferId]
    )

    if (!activeModule) {
        return null
    }

    switch (state.mode) {
        case "upload":
            return (
                <Upload
                    modules={modules}
                    activeModule={activeModule}
                    setActiveModule={onLanguageChange}
                    transferInfo={transferInfo}
                    onFileUpload={handleFileUpload}
                    flagOnly={!!lookupDependingModule}
                />
            )
        case "confirm":
            return (
                <Confirm
                    file={state.file}
                    module={activeModule}
                    resetFile={handleResetFile}
                    updateFile={handleFileChange}
                    onConfirm={handleConfirm}
                />
            )
        case "result":
            return <Result file={state.file} selectedVrcModule={activeModule} resetFile={handleResetFile} />
        default:
            return null
    }
}

function Wrapper(props: Props) {
    const { transferInfo, isError: transferInfoFailedToLoad, refetch: refreshTransferInfo, isRefetching } = useTransferInfo()
    const fallback = (
        <SharedLayout>
            <Loader />
        </SharedLayout>
    )

    if (transferInfoFailedToLoad) {
        return (
            <SharedLayout>
                <RetryError onRetry={refreshTransferInfo} />
            </SharedLayout>
        )
    }

    if (isRefetching || !transferInfo?.transferId) {
        return fallback
    }

    return (
        <Suspense fallback={fallback}>
            <VrcScan {...props} transferInfo={transferInfo} refreshTransferInfo={refreshTransferInfo} />
        </Suspense>
    )
}

export default withRouter(Wrapper)
