import { AvailabilityStatus, TyreArticle } from "@tm/models"
import { useMemo } from "react"
import { getAttributeString } from "."
import { AvailabilityFilterType } from "../model"

export const filterList = (
    data: TyreArticle[],
    selectedAttr: string[],
    availability: AvailabilityFilterType,
    availabilityStatusIdsToShow?: AvailabilityStatus[],
    availabilityStatusIdsToShowSecondary?: AvailabilityStatus[]
) => {
    let items = [...data]
    return useMemo(() => {
        if (availability || selectedAttr.length) {
            items = items.filter((part) => {
                let visible = true
                if (availability === AvailabilityFilterType.Primary && availabilityStatusIdsToShow?.length) {
                    const mod = part.erpInformation?.availability
                    if (mod && mod.type && !availabilityStatusIdsToShow.includes(mod.type)) {
                        visible = false
                    }
                } else if (availability === AvailabilityFilterType.Secondary && availabilityStatusIdsToShowSecondary?.length) {
                    const mod = part.erpInformation?.availability
                    if (mod && mod.type && !availabilityStatusIdsToShowSecondary.includes(mod.type)) {
                        visible = false
                    }
                }

                if (selectedAttr.length) {
                    const allAttr = [...part.attributes?.[0]?.topAttributes, ...part.attributes?.[0]?.articleAttributes]
                    if (!(selectedAttr.length && selectedAttr.every((i) => allAttr.some((att) => i === getAttributeString(att))))) {
                        visible = false
                    }
                }

                return visible
            })
        }

        return [...items]
    }, [items, selectedAttr, availability, availabilityStatusIdsToShowSecondary, availabilityStatusIdsToShow])
}
