import { managerActions, navigationActions, fastServiceStore } from ".."
import { bundleChannel } from "../../helpers"
import { AlertMessageTypes, CalculationHistoryStatus } from "../models"
import { getInsertHistoryRequest, getUpdateHistoryRequest, insertHistory, updateHistory } from "../repositories"
import { replacer } from "../sessionStorage/helper"

export async function saveHistoryForWorktaskShare() {
    const { stepNavigationState, calculationStatus, calculationHistoryId, navigation } = fastServiceStore.getState()
    const activeStep = navigation.active

    if (!activeStep || activeStep === "start") {
        return
    }

    const selectedStepValue = stepNavigationState?.steps?.find((s) => s.stepName === activeStep)
    const activeStepStatus = selectedStepValue?.calculationStatus

    if (calculationStatus) {
        return await updateHistoryData(activeStepStatus ?? calculationStatus, activeStep, calculationHistoryId, true, true)
    } else {
        return await saveHistoryData(activeStep, true, true)
    }
}

export function changeStepWithHistory(selectedStep: string, saveFromButton?: boolean) {
    const { stepNavigationState, calculationStatus, calculationHistoryId } = fastServiceStore.getState()

    navigationActions.changeStep(selectedStep)

    const selectedStepValue = stepNavigationState?.steps?.find((s) => s.stepName === selectedStep)
    const activeStepStatus = selectedStepValue?.calculationStatus

    if (activeStepStatus !== undefined) {
        if (activeStepStatus > calculationStatus) {
            managerActions.setCalculationStatus(activeStepStatus)
            if (calculationStatus) {
                updateHistoryData(activeStepStatus, selectedStep, calculationHistoryId)
            } else {
                saveHistoryData(selectedStep)
            }
        }
    }

    if (saveFromButton) {
        if (calculationStatus) {
            updateHistoryData(activeStepStatus ?? calculationStatus, selectedStep, calculationHistoryId, saveFromButton)
        } else {
            saveHistoryData(selectedStep, saveFromButton)
        }
    }
}

async function saveHistoryData(selectedStep: string, saveFromButton?: boolean, savedFromWorktaskShare?: boolean) {
    const { maintenancePlan, version, calculationStatus } = fastServiceStore.getState()
    const vehicleId = maintenancePlan.stateVehicle?.id
    const vinNumber = maintenancePlan.vehicleFields.vin
    const calculationId = maintenancePlan.calcState?.calcId

    managerActions.setHistoryLoading(true)
    const JSONState = JSON.stringify(fastServiceStore.getState(), replacer)
    const request = getInsertHistoryRequest(vehicleId, vinNumber, calculationId, JSONState, version)

    if (!request) {
        managerActions.setHistoryLoading(false)
        return undefined
    }

    try {
        const response = await insertHistory(request)
        managerActions.setHistoryLoading(false)
        managerActions.setCalculationHistoryId(response.id)
        bundleChannel().publish("ALERT_MESSAGE", {
            messageType: AlertMessageTypes.HISTORY,
            message: saveFromButton ? "savedFromButton" : selectedStep,
            severity: "success",
        })

        return updateHistoryData(calculationStatus, selectedStep, response.id, saveFromButton, savedFromWorktaskShare)
    }
    catch (error) {
        managerActions.setHistoryLoading(false)
        return undefined
    }
}

async function updateHistoryData(
    newHistoryStatus: CalculationHistoryStatus,
    selectedStep: string,
    calculationHistoryId?: string,
    saveFromButton?: boolean,
    savedFromWorktaskShare?: boolean
) {
    const JSONState = JSON.stringify(fastServiceStore.getState(), replacer)

    const request = getUpdateHistoryRequest(calculationHistoryId, newHistoryStatus, JSONState, savedFromWorktaskShare)

    if (!request) {
        return undefined
    }

    try {
        await updateHistory(request)
        bundleChannel().publish("ALERT_MESSAGE", {
            messageType: AlertMessageTypes.HISTORY,
            message: saveFromButton ? "savedFromButton" : selectedStep,
            severity: "success",
        })

        return { calculationId: calculationHistoryId, success: true }
    }
    catch (error) {
        return undefined
    }
}