import { useEffect, useMemo } from "react"
import { Article } from "@tm/models"
import { replaceArticlesQuantityWithQuantityReplacement, usePartsViewOptions } from "../../../../hooks"
import { ArticlesByQueryRequest } from "../../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../../data/repositories/uni-parts"
import { useArticleListConfiguration } from "../../../../ArticleListConfiguration"
import { fetchUniversalPartsArticles } from "../../../../data/fetchUniversalPartsArticles"
import { replaceArticlesQuantityWithSuggestedQuantity, useArticlesBase } from "../../../../hooks/useArticlesBase"
import { BaseArticleData, UniversalPartsListParams } from "../../../../models"
import { publishModuleInfo } from "../../../../helpers"
import { usePartsAlternativesModuleState } from "../../../../PartsAlternatives/PartsAlternativesModuleState"

const QUERY_KEY = "UniversalParts.useArticlesByQuery"

export function useArticlesByQuery(params: UniversalPartsListParams, isEnabled: boolean): BaseArticleData {
    const { productGroups, suppliers, attributes, startParams, extendedAssortment, isFiltersLoading } = params

    const { pageSize } = useArticleListConfiguration()
    const partToReplaceQuantity = usePartsAlternativesModuleState((x) => x?.partToReplaceQuantity)
    const {
        partsViewSettings: { quantitySuggestionEnabled },
    } = usePartsViewOptions()

    const request = useMemo<ArticlesByQueryRequest | null>(() => {
        if (!isEnabled || isFiltersLoading || startParams.type !== "unisearch" || !startParams.query || !productGroups.length) {
            return null
        }

        return {
            pageSize,
            query: startParams.query,
            selectedProductGroups: productGroups,
            selectedSuppliers: suppliers,
            selectedCriteria: attributes,
            extendedAssortment,
            productGroupOrdering: productGroups.map((x) => x.id),
        }
    }, [isEnabled, isFiltersLoading, pageSize, productGroups, suppliers, startParams, attributes, extendedAssortment])

    useEffect(() => {
        function resetNoResult() {
            params.setNoResult(false)
        }
        resetNoResult()

        publishModuleInfo("{{1009}}", startParams.type === "unisearch" && startParams.query ? `"${startParams.query}"` : "")
    }, [request])

    function createQuerySelectFunc(): ((articles: Article[]) => Article[]) | undefined {
        if (!partToReplaceQuantity && !quantitySuggestionEnabled) {
            return undefined
        }
        return (articles) => {
            if (partToReplaceQuantity) {
                articles = replaceArticlesQuantityWithQuantityReplacement(articles, partToReplaceQuantity)
            } else if (quantitySuggestionEnabled) {
                articles = replaceArticlesQuantityWithSuggestedQuantity(articles)
            }
            return articles
        }
    }

    return useArticlesBase(
        request,
        (context) => fetchUniversalPartsArticles(PartsRepository.getArticlesByQuery, context),
        QUERY_KEY,
        createQuerySelectFunc()
    )
}
