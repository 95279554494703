import { Tag, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { combiner, createQueryString, parseQueryString, renderRoute, useWindowSize } from "@tm/utils"
import { useSelector } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router"
import { createSelector } from "reselect"
import { Badge, Box, Icon, Button, Loader, Typography, styled, Tooltip } from "@tm/components"
import { useEffect, useRef, useState } from "react"
import { DatSelectionSteps } from "../../data/enums"
import { MatchParams } from "../../data/models"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import CalculationActions from "./components/CalculationActions"
import SummaryActions from "./components/SummaryActions"
import TotalsCalculation from "../calculation/components/totals"

type Props = {
    route: string
    priceInNav: boolean
}

const selector = createSelector(
    [
        (s: MainState) => s.navigation,
        (s: MainState) => s.extern.transferBtnEnabled,
        (s: MainState) => s.extern.transferLoading,
        (s: MainState) => s.manager.userIsRegistered,
        (s: MainState) => s.calculation.initialSparePartsPrice,
        (s: MainState) => s.calculation.items,
    ],
    combiner.array
)

export default function NavigationComponent({ route, priceInNav }: Props) {
    const [state, transferBtnEnabled, transferLoading, userIsRegistered, initialSparePartsPrice, items] = useSelector(selector)
    const location = useLocation()
    const history = useHistory()
    const matchParams = useParams<MatchParams>()
    const { translateText } = useLocalization()
    const { view } = matchParams
    const actions = useActions(MainActions, "changeStep", "initNav", "reset", "reloadIframe", "getCalculationData")
    const [width] = useWindowSize()
    let blockChange = false
    const titleRef = useRef<HTMLDivElement>(null)
    const firstToolbarRef = useRef<HTMLDivElement>(null)
    const secondToolbarRef = useRef<HTMLDivElement>(null)
    const [hasAdditional, setHasAdditional] = useState(false)
    const showInitialSparePartsPrice = items && items.filter((x) => !!x?.selectedPart)?.length > 0

    const handleSettingsClick = () => {
        if (view === DatSelectionSteps.DATSETTINGS) {
            actions.reloadIframe()
        }
        actions.changeStep(DatSelectionSteps.DATSETTINGS)
    }
    const handleStartCalculation = () => {
        actions.getCalculationData(true, translateText)
        actions.changeStep("calculation")
    }

    useEffect(() => {
        if (matchParams.view && matchParams.view !== state.active && !blockChange && state.initialized) {
            actions.changeStep(matchParams.view)
            blockChange = true
            setTimeout(() => {
                blockChange = false
            }, 1000)
        }
    }, [matchParams.view])

    useEffect(() => {
        initialSparePartsPrice && setHasAdditional(showInitialSparePartsPrice)
    }, [initialSparePartsPrice, showInitialSparePartsPrice])

    useEffect(() => {
        const { active, params } = state

        if (active && !blockChange) {
            const rrparams = { ...matchParams, ...params, view: active }
            const url = renderRoute(route, rrparams)

            const queryParams = parseQueryString(location.search)
            if (!matchParams.view || matchParams.view === active) {
                history.replace(url + createQueryString(queryParams))
            } else {
                // queryParams["initialQuantity"] = params?.quantity
                history.push(url + createQueryString(queryParams))
            }

            blockChange = true
            setTimeout(() => {
                blockChange = false
            }, 1000)
        }
    }, [state.active, state.params])

    useEffect(() => {
        if (!state.initialized) {
            actions.initNav(matchParams)
        }
    }, [])

    const renderPriceAndKVAButton = () => {
        if (!priceInNav) {
            return <CalculationActions />
        }

        const diffWidth =
            (titleRef.current?.offsetWidth ?? 0) + (firstToolbarRef.current?.offsetWidth ?? 0) + (secondToolbarRef.current?.offsetWidth ?? 0)
        if (width - diffWidth < (hasAdditional ? 1250 : 900)) {
            return (
                <Box sx={{ display: "flex", flexWrap: "wrap-reverse", justifyContent: "right" }}>
                    <Box sx={{ marginRight: "1em", marginTop: "1em" }}>
                        <Tooltip
                            color="primary"
                            variant="light"
                            noWidthLimit
                            title={
                                <StyledTotalsCalculation>
                                    <TotalsCalculation />
                                </StyledTotalsCalculation>
                            }
                            className="tooltipButton"
                            placement="right"
                        >
                            <Tag label={translateText(13004)} size="m" layout="bordered" />
                        </Tooltip>
                    </Box>
                    <CalculationActions />
                </Box>
            )
        }

        return (
            <Toolbar className="totalSumKva">
                <TotalsCalculation />
                <CalculationActions />
            </Toolbar>
        )
    }

    return (
        <StyledWrraper className="toolbar">
            <Box className="flexCentered" ref={titleRef}>
                <Typography variant="h2" paddingRight="1em">
                    {translateText(3140)}
                </Typography>
            </Box>
            <Toolbar className="wrappableToolbar" title={translateText(176)}>
                <Box className="toolbarWrapper" ref={firstToolbarRef}>
                    <Button
                        startIcon={<Icon name="summary" />}
                        variant="text"
                        color={state.active === DatSelectionSteps.SUMARRY ? "primary" : undefined}
                        onClick={() => actions.changeStep(DatSelectionSteps.SUMARRY)}
                    >
                        {translateText(409)}
                    </Button>
                    <Button
                        startIcon={<Icon name="damage-calculation" />}
                        variant="text"
                        color={state.active === DatSelectionSteps.EXTERN ? "primary" : undefined}
                        disabled={view !== DatSelectionSteps.EXTERN && !state.externVisited}
                        onClick={() => actions.changeStep(DatSelectionSteps.EXTERN)}
                    >
                        {translateText(1613)}
                    </Button>
                    <Button
                        startIcon={<Icon name="calculation" />}
                        variant="text"
                        color={state.active === DatSelectionSteps.CALCULATION ? "primary" : undefined}
                        disabled={view !== DatSelectionSteps.CALCULATION && !state.calcVisited}
                        onClick={() => actions.changeStep(DatSelectionSteps.CALCULATION)}
                    >
                        {translateText(613)}
                    </Button>
                </Box>
            </Toolbar>
            <Toolbar className="wrappableToolbar" title={translateText(177)}>
                <Box className="toolbarWrapper" ref={secondToolbarRef}>
                    <Button
                        variant="text"
                        color={view === DatSelectionSteps.DATSETTINGS ? "primary" : undefined}
                        disabled={!userIsRegistered}
                        onClick={handleSettingsClick}
                    >
                        {`DAT ${translateText(141)}`}
                    </Button>

                    <Button
                        startIcon={<Icon name="synchronize" />}
                        variant="text"
                        color="error"
                        disabled={!state.externVisited}
                        onClick={actions.reset}
                    >
                        {translateText(48)}
                    </Button>
                </Box>
            </Toolbar>

            <Toolbar className="prices">
                <div id="glass-prices" />
            </Toolbar>

            {view === DatSelectionSteps.SUMARRY && <SummaryActions />}
            {view === DatSelectionSteps.CALCULATION && renderPriceAndKVAButton()}

            {view === "extern" && (
                <Box className="flexCentered">
                    <Badge badgeContent={transferLoading ? <Loader size="extrasmall" /> : null}>
                        <Button
                            sx={{ height: "2.5em" }}
                            color="highlight"
                            startIcon={<Icon name="calculation" />}
                            disabled={!transferBtnEnabled || transferLoading}
                            onClick={handleStartCalculation}
                        >
                            {translateText(3165)}
                        </Button>
                    </Badge>
                </Box>
            )}
        </StyledWrraper>
    )
}

const StyledTotalsCalculation = styled(Box)(() => ({
    ".articles-summary": {
        display: "flex",
        margin: "0 0 0.4em 0",
        ".panel": {
            "&.total": {
                ".panel": {
                    "&__content": {
                        ".price": {
                            ".value": {
                                color: "#f3ae21",
                            },
                        },
                    },
                },
            },

            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
            marginRight: "1em",
            fontWeight: "bold",
            border: "none",
            display: "flex",
            flexDirection: "column",
            borderBottom: ".3em solid #c3c3c3",
            ".panel": {
                "&__title": {
                    border: "none",
                    fontSize: "medium",
                    padding: 0,
                    margin: 0,
                },
                "&__content": {
                    ".price": {
                        display: "flex",
                        position: "relative",
                        flexWrap: "wrap",
                        padding: 0,
                        margin: 0,
                        ".value": {
                            fontSize: "medium",
                            textIndent: 0,
                            "&.initial-price": {
                                flex: "0 0 100%",
                            },
                        },
                    },
                },
            },
        },
    },
}))

const StyledWrraper = styled(Box)(() => ({
    display: "flex",
    flex: "0 !important",
    padding: ".5rem 1rem",

    ".wrappableToolbar": {
        flex: "unset",
        "&__inner": {
            whiteSpace: "nowrap",
        },
        "&__content": {
            flexWrap: "wrap",
        },
    },

    ".toolbarWrapper": {
        display: "flex",
        flexWrap: "wrap",
    },

    ".flexCentered": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    ".prices": {
        flex: 1,
        justifyContent: "flex-end",
        "&:before": {
            content: "unset",
        },
    },

    ".totalSumKva": {
        marginLeft: "auto",
        ".toolbar": {
            display: "inline-flex",
            position: "relative",
            padding: 0,
            "&::before": {
                position: "absolute",
                top: 0,
                left: 0,
            },
            "&__inner": {
                paddingRight: ".16em",
                paddingLeft: ".65em",
            },
            "&__content": {
                marginRight: 0,
                padding: 0,
                svg: {
                    marginRight: ".3em",
                },
            },
        },
        ".articles-summary": {
            alignContent: "left",
            display: "inline-flex",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            height: "100%",
            marginBottom: 0,
            paddingRight: 0,

            ".panel": {
                backgroundColor: "transparent",
                boxShadow: "none",
                border: "none",
                borderBottom: "0.35em #c3c3c3 solid",
                padding: 0,
                marginBottom: "-0.5em",
                marginRight: "1em",
                fontWeight: "bold",
                width: "100%",
                "&.total": {
                    ".panel": {
                        "&__content": {
                            ".price": {
                                ".value": {
                                    color: "#f3ae21",
                                },
                            },
                        },
                    },
                },
                ".panel": {
                    "&__title": {
                        border: "none",
                        fontSize: "medium",
                        fontWeight: "normal",
                        paddingBottom: 0,
                    },
                    "&__content": {
                        ".price": {
                            display: "flex",
                            position: "relative",
                            flexWrap: "wrap",
                            paddingBottom: 0,
                            marginTop: ".35em",
                            ".value": {
                                textIndent: 0,
                                fontSize: "medium",
                                fontWeight: "bold",
                                "&.initial-price": {
                                    flex: "0 0 100%",
                                },
                            },
                        },
                    },
                },
            },
        },
    },
}))
