import { Stack, styled } from "@tm/components"

export const StyledModuleHeader = styled(Stack, {
    name: "DirectSearchModuleHeader",
    slot: "root",
    overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
    flexDirection: "row",
    alignItems: "flex-end",
    padding: `${theme.spacing(0.75)} ${theme.spacing(2)}`,
    backgroundColor: theme.overwrites?.components?.partListV2?.backgroundColor,
}))
