import { LocalizationProps, withLocalization } from "@tm/localization"
import { bindSpecialReactMethods } from "@tm/utils"
import { SeparatorList, Tag, TagIcon, Text, Button } from "@tm/controls"
import { ArticleReference, ArticleReferenceType, ModificationState } from "@tm/models"
import { Component, ReactNode } from "react"

type Props = LocalizationProps & {
    references: Array<ArticleReference>
    onSelect?(reference: ArticleReference): void
    onShowMoreReferences(referenceType: ArticleReferenceType): void
}

class ArticleReferencesComponent extends Component<Props> {
    private referencesListRefs: Array<HTMLElement> = []

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleReferencesOverflowing)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleReferencesOverflowing)
    }

    componentDidUpdate() {
        setTimeout(this.handleReferencesOverflowing, 25)
    }

    handleReferenceListRef(ref: HTMLElement) {
        this.referencesListRefs.push(ref)
    }

    handleReferencesOverflowing() {
        this.referencesListRefs.forEach((x) => {
            const lastChild = x.lastElementChild
            if (!lastChild) {
                return
            }

            // if no reference number is overflowing (last reference number is still on the same line as the parent element and not wrapped)
            // add a class to the list otherwise remove it
            if (lastChild.getBoundingClientRect().top > x.getBoundingClientRect().top) {
                x.classList.add("article__references--overflowing")
            } else {
                x.classList.remove("article__references--overflowing")
            }
        })
    }

    renderReference(reference: ArticleReference) {
        const { onSelect } = this.props

        let className = "article__reference"
        const icons: Array<TagIcon> = []

        switch (reference.modificationState) {
            case ModificationState.Added:
                icons.push({ name: "plus" /* , tooltip: translateText(894) */ })
                className += " article__reference--added"
                break
            case ModificationState.Removed:
                icons.push({ name: "minus" /* , tooltip: translateText(895) */ })
                className += " article__reference--removed"
                break
            default:
                break
        }

        return (
            <Tag
                className={className}
                layout="ghost"
                value={reference.referenceNo}
                icons={icons}
                onClick={onSelect ? () => onSelect(reference) : undefined}
            />
        )
    }

    renderReferenceGroup(referenceType: ArticleReferenceType, label: ReactNode, className: string) {
        const {
            onShowMoreReferences,
            localization: { translate },
        } = this.props
        const references = this.props.references.filter((x) => x.referenceType === referenceType)

        return (
            <div className="article__reference-group">
                {!!references.length && (
                    <Text className="article__reference-group__description" size="s">
                        {label}
                    </Text>
                )}
                <SeparatorList
                    onRef={this.handleReferenceListRef}
                    className={`article__references ${className}`}
                    items={references}
                    renderItem={this.renderReference}
                />
                {!!references.length && (
                    <Button className="more-btn" onClick={() => onShowMoreReferences(referenceType)} layout={["ghost"]} size="xs">
                        {translate(44)}
                    </Button>
                )}
            </div>
        )
    }

    render() {
        const { translate } = this.props.localization

        if (!this.props.references.length) {
            return null
        }

        return (
            <>
                {this.renderReferenceGroup(ArticleReferenceType.UtilityNo, translate(132), "article__references--utility")}
                {this.renderReferenceGroup(ArticleReferenceType.ReplacementArticleNo, translate(685), "article__references--replacement")}
                {this.renderReferenceGroup(ArticleReferenceType.ReplacedByArticleNo, translate(686), "article__references--replaced-by")}
            </>
        )
    }
}

export default withLocalization(ArticleReferencesComponent)
