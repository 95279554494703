import { Button, Icon, Loader, ModuleHeaderSlot } from "@tm/components"
import {
    WorkTask,
    WorkTaskInfo,
    useWorkTaskOrderModuleParameters,
    useReplaceUrlTags,
    useTelesalesCustomerNumber,
    useUser,
    useWorkTask,
} from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ECounterType, OrderingType, WorkTaskStatus } from "@tm/models"
import { TmaHelper, ajax, renderRoute, useExternalCatalogUrl } from "@tm/utils"
import { Suspense, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import DmsExportButton from "../../../../../../dms/src/components/export-button/component"
import { useCreateCostEstimationVoucher } from "../../../../data/hooks/useCreateCostEstimationVoucher"
import { ESendCostEstimationMode } from "../../../../data/model"
import { useWorkTaskBasketState } from "../../../../hooks/basketState/useWorkTaskBasketState"
import { TransferButtons } from "../../../_shared/transfer-buttons"
import { StackRow } from "../../../StyledComponents"
import { OfferState } from "../../business"
import ActionButtonsDialogs from "./ActionButtonsDialogs"
import { ImportExportButtons } from "./ImportExportButtons"

type Props = {
    orderRoute: string
    orderButtonTextId: string
    orderButtonIcon: string
    orderRoutePreActionExternalSystemId?: number
    showDmsExportButton?: boolean
    state: OfferState
}

/**
 * Action Buttons for Basket Bundle
 * @memberof Basket
 * @param props
 * @returns Action button Component
 */
function ActionButtons(
    props: Props & { workTask: WorkTaskInfo; workTaskNotYetPersistedInDatabase: boolean; createWorkTask: WorkTask["createWorkTask"] | undefined }
) {
    const {
        orderButtonTextId,
        orderButtonIcon,
        orderRoute,
        orderRoutePreActionExternalSystemId,
        workTask,
        showDmsExportButton,
        state,
        workTaskNotYetPersistedInDatabase,
        createWorkTask,
    } = props

    const history = useHistory()
    const params = useParams()
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId: orderRoutePreActionExternalSystemId, telesalesCustomerNo })
    const replacedExternalCatalogUrl = useReplaceUrlTags(externalCatalogUrl, {})
    const { costEstimation, workTaskBasket } = useWorkTaskBasketState(workTask.id)
    const { resetCostEstimation, importFromExternalBasket, exportToExternalBasket, resetExternalModalUrl } = costEstimation.actions
    const { createCostEstimationVoucher, creatingCostEstimationVoucher } = useCreateCostEstimationVoucher(workTask, state)
    const { hasWorkTaskOrder } = useWorkTaskOrderModuleParameters()

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [showErrorDetailsDialog, setShowErrorDetailsDialog] = useState<boolean>(false)
    const [showExternalBasketDialog, setShowExternalBasketDialog] = useState<boolean>(false)
    const [sendingPreAction, setSendingPreAction] = useState<boolean>(false)

    const { hasItems, costEstimationLoading, externalBasketErrorDetails, externalModalUrl } = costEstimation.state
    const disableButtons = costEstimationLoading || !hasItems

    useEffect(() => {
        if (externalBasketErrorDetails) {
            setShowErrorDetailsDialog(true)
        }
    }, [externalBasketErrorDetails])

    useEffect(() => {
        setShowExternalBasketDialog(!!externalModalUrl)
    }, [externalModalUrl])

    function handleRemoveAllItemsDialog() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateReset)
        setShowDeleteDialog(true)
    }

    function handleSave() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateSave)
        createCostEstimationVoucher()
    }

    function handleExternalBasketClosed() {
        resetExternalModalUrl()
        importFromExternalBasket()
    }

    async function handleExportToExternalBasket() {
        if (workTaskNotYetPersistedInDatabase) {
            await createWorkTask?.({
                workTaskId: workTask.id,
                skipRedirect: true,
            })
        }

        await exportToExternalBasket()
    }

    function openOrderUrl() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateBackToBasket)

        const url = renderRoute(orderRoute, params)

        if (!orderRoutePreActionExternalSystemId) {
            history.push(url)
        } else if (replacedExternalCatalogUrl) {
            setSendingPreAction(true) // semicolon needed
            // Call to external system before redirecting to basket
            ajax({ url: replacedExternalCatalogUrl, method: "GET" }).then(
                () => {
                    history.push(url)
                },
                () => {
                    setSendingPreAction(false)
                }
            )
        }
    }

    return (
        <StackRow>
            {showDmsExportButton && (
                <ModuleHeaderSlot title="" hideDivider>
                    <DmsExportButton />
                </ModuleHeaderSlot>
            )}
            <ModuleHeaderSlot title="" hideDivider>
                <StackRow spacing={0.5}>
                    <Button disabled={disableButtons} onClick={handleRemoveAllItemsDialog}>
                        {translateText(48)}
                    </Button>
                    {creatingCostEstimationVoucher ? (
                        <Loader size="small" />
                    ) : (
                        <Button color="success" disabled={disableButtons} onClick={handleSave}>
                            {translateText(9)}
                        </Button>
                    )}
                    <TransferButtons workTaskId={workTask.id} disabled={disableButtons} mode={ESendCostEstimationMode.CostEstimation} />
                    <ImportExportButtons
                        // the export button must always be active, even if the cost estimate is empty, see ticket
                        // https://jira.dvse.de/browse/NEXT-28964
                        disabled={costEstimationLoading || !workTaskBasket?.hasLinkedExternalBasket ? "import" : false}
                        onImport={importFromExternalBasket}
                        onExport={handleExportToExternalBasket}
                    />
                    {userContext?.parameter.orderingType !== OrderingType.CentralOrderingWithoutWorkTaskOrder && hasWorkTaskOrder && (
                        <Button
                            color="highlight"
                            endIcon={<Icon name={orderButtonIcon || "orders"} />}
                            onClick={openOrderUrl}
                            disabled={disableButtons || sendingPreAction}
                        >
                            {translateText(orderButtonTextId || "50")}
                        </Button>
                    )}
                </StackRow>
            </ModuleHeaderSlot>
            <ActionButtonsDialogs
                workTaskId={workTask.id}
                showDeleteDialogDefault={showDeleteDialog}
                showErrorDetailsDialogDefault={showErrorDetailsDialog}
                showExternalBasketDialogDefault={showExternalBasketDialog}
                externalDialogBasketUrl={externalModalUrl}
                externalBasketErrorDetails={externalBasketErrorDetails}
                onResetCostEstimation={resetCostEstimation}
                onCloseExternalBasketDialog={handleExternalBasketClosed}
            />
        </StackRow>
    )
}

export default function Wrapper(props: Props) {
    const { workTask, createWorkTask } = useWorkTask() ?? {}

    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <ActionButtons
                {...props}
                workTask={workTask}
                workTaskNotYetPersistedInDatabase={workTask?.statusValue === WorkTaskStatus.Undefined}
                createWorkTask={createWorkTask}
            />
        </Suspense>
    )
}
