import { MenuItemWidget, Select, SelectChangeEvent, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CalculatedCostType } from "@tm/models"

type Props = {
    onSelectedTypeChanged(item: CalculatedCostType | undefined): void
    value: CalculatedCostType
    availableCostTypes: Array<CalculatedCostType>
}

export default function CostTypeSelect({ onSelectedTypeChanged, value, availableCostTypes }: Props) {
    const { translateText } = useLocalization()

    function getFilterLocalization(filter: CalculatedCostType): string {
        switch (filter) {
            case CalculatedCostType.Unknown:
                return translateText(1205)
            case CalculatedCostType.DatEre:
                return translateText(13541)
            case CalculatedCostType.DatGlas:
                return translateText(13540)
            case CalculatedCostType.DatPackage:
                return translateText(13539)
            case CalculatedCostType.Vin:
                return translateText(13538)
            case CalculatedCostType.DatFiOnline:
                return translateText(13552)
            default:
                return ""
        }
    }

    function onSelectionChanged(selectionEvent: SelectChangeEvent<unknown>) {
        const filter = selectionEvent.target.value as CalculatedCostType
        if (filter !== undefined) {
            onSelectedTypeChanged(filter)
        }
    }

    return (
        <Stack direction="column">
            <Typography variant="label">{translateText(13526)}</Typography>
            <Select
                color="primary"
                sx={{ minWidth: "300px" }}
                variant="filled"
                value={value}
                onChange={onSelectionChanged}
                disableUnderline
            >
                {availableCostTypes.map((item) => {
                    return (
                        <MenuItemWidget key={item.toString()} value={item}>
                            {getFilterLocalization(item)}
                        </MenuItemWidget>
                    )
                })}
            </Select>
        </Stack>
    )
}
