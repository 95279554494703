import { PayloadAction } from "@tm/morpheus"
import { RequestArticleListPayload, RepairTimeProvider, Article } from "@tm/models"
import { BundleActionType } from "../../../business"
import { DetailsHeadState } from "./model"
import { Models } from "../../../data"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "CHANGE_ARTICLE_QUANTITY"; payload: { article: Article; quantity: number } }
    | { type: "LOAD_ARTICLE_LIST"; payload: RequestArticleListPayload; targetComponentId: string }
    | { type: "SET_PRODUCTGROUP_REPAIRTIMES"; payload: Array<RepairTimeProvider> }

const DEFAULT_STATE: DetailsHeadState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): DetailsHeadState {
    switch (action.type) {
        case "DETAILSHEAD_RESET": {
            return {
                ...state,
                detailsRequest: undefined,
                detailsResponse: undefined,
                minQuantity: undefined,
                maxQuantity: undefined,
                division: undefined,
                repairTimeAvailabilities: undefined,
            }
        }
        case "DETAILSHEAD_LOADING": {
            return {
                ...state,
                detailsRequest: action.payload.request,
                foundBySearchTerm: action.payload.foundBySearchTerm,
            }
        }
        case "DETAILSHEAD_LOADED": {
            return {
                ...state,
                detailsResponse: action.payload,
            }
        }
        case "SET_PRODUCTGROUP_REPAIRTIMES": {
            return {
                ...state,
                repairTimeAvailabilities: action.payload,
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            if (state.detailsResponse?.article?.id === action.payload.article.id) {
                return {
                    ...state,
                    detailsResponse: {
                        ...state.detailsResponse,
                        article: {
                            ...state.detailsResponse.article,
                            quantity: action.payload.quantity,
                        },
                    },
                }
            }
            break
        }
        default:
            break
    }

    return state
}

export function transmit(action: ComponentActionType): PayloadAction | undefined {
    switch (action.type) {
        case "DETAILSHEAD_LOADED":
        case "DETAILSHEAD_RESET":
        case "LOAD_ARTICLE_LIST":
        case "SET_SECTION_URL":
            return action
        default:
            break
    }
}
