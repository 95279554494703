import { Article, TyreArticle, Vehicle } from "@tm/models"
import { Wheels } from "@tm/data"
import { Repositories } from ".."
import { LOADING_SCREEN_DEFAULT_STATE } from "../../components/loading-screen/business"
import { createSensorArticleFiltersRequest } from "../../components/loading-screen/business/helper"
import { MainState } from "../../components/main"
import { NavigationTab } from "../../components/navigation/business/model"
import { RDKS_LIST_DEFAULT_STATE } from "../../components/rdks-list/business"
import { createSensorArticlesRequest } from "../../components/rdks-list/business/helper"
import { TYRES_LIST_DEFAULT_STATE } from "../../components/tyres-list/business"
import { createArticleListRequest, createAttributeFiltersRequest } from "../../components/tyres-list/business/helpers"
import { VEHICLE_SELECTION_DEFAULT_STATE } from "../../components/vehicle-selection/business"
import { reduce as overviewReduce } from "../../components/overview/business"
import { WHEELS_DETAILS_DEFAULT_STATE } from "../../components/wheels-details/business"
import { BASE_DEFAULT_STATE, getErpInfoHistory } from "../../components/wheels-list/business/baseReducer"
import { CONFIGURATOR_DEFAULT_STATE } from "../../components/wheels-list/business/configuratorReducer"
import { createRimArticlesRequest, mapSelectedFiltersForRequest } from "../../components/wheels-list/business/helper"
import { WheelSelectionSteps } from "../enums"
import { HistoryData } from "../models"
import { mapRimItemForOverview } from "."
import { createRimDetArticlesRequest } from "../../components/wheels-details/business/helper"
import { RimDetailsArticle, RimItem } from "../model"
import { RimFilters } from "../../components/wheels-list/business"
import { getConfiguratorImage } from "../repositories/wheels-getConfiguratorImage"

export const initHistoryNav = async (
    state: MainState,
    displayStepLoader: (currentStep: number, totalSteps: number) => void,
    vehicle?: Vehicle,
    data?: HistoryData,
    workTaskId?: string,
    showAvailability?: boolean,
    telesalesCustomerNo?: string,
    isTyresAvailable?: boolean
): Promise<Partial<MainState> | undefined> => {
    let newReducer: Partial<MainState> = {
        manager: { ...state.manager, keyValueData: data, keyValueDataLoading: false, savedWorktaskId: workTaskId, historyInit: true },
        vehicleSelection: { ...state.vehicleSelection },
    }

    if (!vehicle) {
        return newReducer
    }

    try {
        if (data?.selectedCarParkId && data?.rimListTab) {
            let { activeStep } = data

            if ((activeStep === WheelSelectionSteps.TIRESLIST || activeStep === WheelSelectionSteps.TIREDETAILS) && !isTyresAvailable) {
                activeStep = WheelSelectionSteps.OVERVIEW
            }

            const highest = data.highestStepReached ?? activeStep

            displayStepLoader(1, highest)

            const carParkResponse = await Repositories.loadCarPark(vehicle?.tecDocTypeId, vehicle?.vehicleType)
            const selectedCarPark = carParkResponse?.carParks.find((x) => x.carparkId == data.selectedCarParkId)
            const selectedTireSize = data?.tyresTab?.selectedFilters.tyreSize?.value ?? data?.wheelsDetailsTab?.selectedTireSize?.value

            if (!selectedCarPark || !activeStep) {
                throw "selectedcarPark or activeStpe not found"
            }

            const rdksFilters = await Repositories.loadSensorFilters(createSensorArticleFiltersRequest(vehicle)!)

            let rdksList: Article[] = []
            if (rdksFilters?.manufacturer.length) {
                const response = await Wheels.loadSensorItems(
                    createSensorArticlesRequest(
                        rdksFilters,
                        data?.tpmsTab?.selectedFilters ?? RDKS_LIST_DEFAULT_STATE.selectedFilters,
                        vehicle,
                        false,
                        data?.tpmsTab?.pageIndex
                    )
                )
                rdksList = response.articles
            }

            const selectedRdks: Article | undefined = rdksList.find(
                (article) => article.internalId == data.tpmsTab?.article.internalId && article.traderArticleNo == data.tpmsTab.article.traderArticleNo
            )

            let rimList: RimItem[] = []
            let rimFilters: RimFilters | undefined
            const selectedRegNoHostettler = state.vehicleSelection.selectedRegistrationNo
            const isAdditionalManufacturerSelected = data.rimListTab.isAdditionalManufacturerSelected ?? false

            if (selectedRegNoHostettler) {
                const { rimArticles, filters } = await Repositories.loadRimArticleFilter(
                    selectedRegNoHostettler,
                    mapSelectedFiltersForRequest(data?.rimListTab.selectedFilters),
                    data.selectedCarParkId
                )
                rimList = rimArticles
                rimFilters = filters
            } else {
                rimList = await Repositories.loadRimItems(
                    createRimArticlesRequest(selectedCarPark.carparkId, data?.rimListTab.selectedFilters, isAdditionalManufacturerSelected, 1)
                )
                if (data?.rimListTab.pageIndex > 1) {
                    for (let i = 2; i <= data?.rimListTab.pageIndex; i++) {
                        const list = await Repositories.loadRimItems(
                            createRimArticlesRequest(selectedCarPark.carparkId, data?.rimListTab.selectedFilters, isAdditionalManufacturerSelected, i)
                        )
                        if (!list?.length) {
                            break
                        }
                        rimList = rimList.concat(list)
                    }
                }
            }

            if (showAvailability) {
                const data = await getErpInfoHistory(rimList, telesalesCustomerNo)
                rimList = data ?? rimList
            }

            const selectedRim = rimList.find(
                (x) => x.idRimDesign == data?.rimListTab?.article.idRimDesign && x.supplierId == data?.rimListTab.article.supplierId
            )

            if (!selectedRim) {
                throw "selectedRim nout found"
            }

            newReducer = {
                ...newReducer,
                rdksList: {
                    ...RDKS_LIST_DEFAULT_STATE,
                    articles: {
                        ...RDKS_LIST_DEFAULT_STATE.articles,
                        data: rdksList,
                        error: false,
                        loading: false,
                        pageIndex: 1,
                        loadingAutoItems: false,
                        noMoreRdksArticles: false,
                        loadingNextItems: false,
                    },
                    filters: {
                        manufacturer: rdksFilters?.manufacturer ?? [],
                        productGroupId: rdksFilters?.productGroupId,
                    },
                    selectedArticles: [selectedRdks!],
                    selectedFilters: {
                        ...RDKS_LIST_DEFAULT_STATE.selectedFilters,
                        availability: data.tpmsTab?.selectedFilters.availability || 0,
                        extendedAssortment: data.tpmsTab?.selectedFilters.extendedAssortment || false,
                        manufacturer: data.tpmsTab?.selectedFilters.manufacturer,
                    },
                },
                navigation: {
                    activeStep,
                    isRDKSAvailable: !!rdksList.length,
                    isTyresAvailable,
                    selectedTabs: initSelectedTabs(Math.max(activeStep, highest)),
                },
                loadingScreen: {
                    ...LOADING_SCREEN_DEFAULT_STATE,
                    loading: false,
                    ...(carParkResponse?.carParks && { carParkItems: carParkResponse.carParks }),
                    ...(carParkResponse?.headers && { carParkHeaders: carParkResponse.headers }),
                    hasMultipleCarParks: carParkResponse?.hasMultipleCarParks,
                    latestVehicleUsed: vehicle.id,
                    showVehicleSelection: carParkResponse?.hasMultipleCarParks || state.loadingScreen.showVehicleSelection,
                },
                vehicleSelection: {
                    ...VEHICLE_SELECTION_DEFAULT_STATE,
                    ...state.vehicleSelection,
                    selectedCarPark,
                    sendSelectedCarpark: selectedCarPark,
                },
                wheelsList: {
                    base: {
                        ...BASE_DEFAULT_STATE,
                        carPark: selectedCarPark,
                        selectedCarParkId: selectedCarPark.carparkId,
                        selectedRimItem: selectedRim,
                        selectedSize: data.rimListTab.selectedSize,
                        articles: {
                            ...BASE_DEFAULT_STATE.articles,
                            data: rimList,
                            loading: false,
                            error: false,
                            hasMoreItems: !selectedRegNoHostettler,
                            pageIndex: data.rimListTab.pageIndex ?? 1,
                        },
                        filters: {
                            ...BASE_DEFAULT_STATE.filters,
                            ...rimFilters,
                            loading: false,
                            error: false,
                        },
                        sensorListResult: { data: rdksList },
                        selectedFilters: data.rimListTab.selectedFilters,
                        displayStateReset: data.rimListTab.pageIndex > 1,
                        initFromHistory: true,
                        initialized: true,
                        isAdditionalManufacturerSelected,
                    },
                    configurator: {
                        ...CONFIGURATOR_DEFAULT_STATE,
                        selectedColor: data.configurator?.selectedColor,
                    },
                },
                wheelsDetails: {
                    ...WHEELS_DETAILS_DEFAULT_STATE,
                    rimItem: selectedRim,
                    selectedSize: data.rimListTab.selectedSize,
                    ...(selectedTireSize && { savedTireSize: selectedTireSize }),
                },
            }

            if (!data.rimListTab.article.idRimDesign) {
                throw "irRimDesign was not found"
            }

            if (data?.wheelsDetailsTab) {
                displayStepLoader(2, highest)

                const HSArticleId = selectedRim.hostettlerCombinations?.[data.rimListTab.selectedSize]?.map((x) => x.articleNumber).join(";")
                const rimsResult = await Repositories.loadRimDetailsArticles(
                    createRimDetArticlesRequest(
                        data?.selectedCarParkId,
                        data.rimListTab.selectedSize,
                        data.rimListTab.article.idRimDesign,
                        data.rimListTab.selectedFilters,
                        HSArticleId,
                        selectedRegNoHostettler,
                        selectedRim.isSSW,
                        selectedRim.isConti,
                        selectedRim.rimErpInformations,
                        isAdditionalManufacturerSelected
                    )
                )

                let selectedRimArticle: RimDetailsArticle | undefined
                const { artNr, eanCode, rimSize, quantity } = data.wheelsDetailsTab?.selectedArticle || {}
                const mappedRimsResult: typeof rimsResult = {}

                Object.entries(rimsResult).forEach(
                    ([key, _]) =>
                        (mappedRimsResult[key] = rimsResult[key].map((rim) => {
                            if (rim.artNr == artNr && rim.eanCode == eanCode && rim.rimSize == rimSize) {
                                const foundRim = { ...rim, quantity: quantity ?? rim.quantity }
                                selectedRimArticle = foundRim
                                return foundRim
                            }
                            return { ...rim, quantity: rim.quantity || 4 }
                        }))
                )

                let configCarString = ""
                try {
                    configCarString = await getConfiguratorImage(vehicle?.id, workTaskId || "")
                } catch (error: any) {
                    console.debug(error)
                }

                newReducer = {
                    ...newReducer,
                    wheelsDetails: {
                        ...newReducer.wheelsDetails!,
                        selectedArticleFromState: selectedRimArticle,
                        selectedRimDetailsArticle: selectedRimArticle,
                        rimDetailsArticlesResponse: {
                            data: mappedRimsResult,
                            error: false,
                            loading: false,
                        },
                    },
                    tyresList: {
                        ...TYRES_LIST_DEFAULT_STATE,
                        selectedFilters: {
                            ...TYRES_LIST_DEFAULT_STATE.selectedFilters,
                            tyreSize: data.tyresTab?.selectedFilters.tyreSize ?? data.wheelsDetailsTab.selectedTireSize,
                        },
                        filters: {
                            ...TYRES_LIST_DEFAULT_STATE.filters,
                            tyreSize: data.wheelsDetailsTab.tireSizes,
                            loading: false,
                        },
                    },
                    ...(!!selectedRimArticle && {
                        overview: overviewReduce(newReducer.overview, {
                            type: "SEND_RIM_ARTICLE_TO_OVERVIEW",
                            payload: {
                                rimItem: mapRimItemForOverview(selectedRimArticle),
                                tireSizes: [],
                                configuratorImage: configCarString,
                                selectedTireSize: undefined,
                            },
                        }),
                    }),
                }
            }

            if (highest >= WheelSelectionSteps.RDKSLIST) {
                displayStepLoader(3, highest)
            }

            if (data?.tpmsTab && newReducer.rdksList) {
                const selectedRdks = rdksList.find(
                    (article) =>
                        article.internalId == data.tpmsTab?.article.internalId && article.traderArticleNo == data.tpmsTab.article.traderArticleNo
                )

                if (!data.wheelsDetailsTab) {
                    throw "wheelsDetailsTab not found"
                }

                newReducer = {
                    ...newReducer,
                    rdksList: {
                        ...newReducer.rdksList,
                        articles: {
                            ...RDKS_LIST_DEFAULT_STATE.articles,
                            data: rdksList,
                            error: false,
                            loading: false,
                            pageIndex: data.tpmsTab.pageIndex ?? 1,
                            loadingAutoItems: false,
                            noMoreRdksArticles: false,
                            loadingNextItems: false,
                        },
                        selectedArticles: [selectedRdks!],
                        filters: {
                            manufacturer: rdksFilters?.manufacturer ?? [],
                            productGroupId: rdksFilters?.productGroupId,
                        },
                        ...(data?.tpmsTab && {
                            selectedFilters: data.tpmsTab.selectedFilters,
                        }),
                        selectedFilters: {
                            availability: data.tpmsTab?.selectedFilters.availability || 0,
                            extendedAssortment: data.tpmsTab?.selectedFilters.extendedAssortment || false,
                            manufacturer: data.tpmsTab?.selectedFilters.manufacturer,
                        },
                        displayStateReset: data.tpmsTab.pageIndex > 1,
                    },
                    ...(!!selectedRdks && {
                        overview: overviewReduce(newReducer.overview, {
                            type: "SEND_SENSOR_TO_OVERVIEW",
                            payload: selectedRdks,
                        }),
                    }),
                }
            }

            if (highest >= WheelSelectionSteps.TIRESLIST) {
                displayStepLoader(5, highest)
            }

            if (data?.tyresTab && data?.wheelsDetailsTab) {
                const { availability, ...otherFilters } = data.tyresTab.selectedFilters
                // data.tyresTab.selectedFilters has availability possibly undefined.
                const selectedFiltersWithAvailability = {
                    ...otherFilters,
                    availability: availability ?? 0,
                }

                if (!newReducer.tyresList) {
                    throw "tyresList reducer not found"
                }

                let request

                if (data.tyresTab.selectedFilters) {
                    newReducer = {
                        ...newReducer,
                        tyresList: {
                            ...newReducer.tyresList,
                            selectedFilters: selectedFiltersWithAvailability,
                        },
                    }
                    // Typescript goes dumb when using spread
                    newReducer.tyresList = newReducer.tyresList || TYRES_LIST_DEFAULT_STATE
                    request = createArticleListRequest(newReducer.tyresList, data.tyresTab.pageIndex, true)
                } else {
                    request = createArticleListRequest(newReducer.tyresList, data.tyresTab.pageIndex, false)
                }

                if (!request) {
                    throw "selectedFilters size empty"
                }

                const tiresList = await Repositories.loadTiresArticles(request)

                const tiresFiltersRequest = createAttributeFiltersRequest(newReducer.tyresList, vehicle?.vehicleType)

                if (!tiresFiltersRequest) {
                    throw "tiresFiltersRquest or selectedRDKSArticles not found"
                }

                // displayStepLoader(6, highedStep)

                const tiresFilters = await Repositories.loadTiresFilters(tiresFiltersRequest)

                const { loadIndex, speedIndex } = tiresList.usedCriteria

                let selectedTire: TyreArticle | undefined

                const tiresArray = tiresList.uniArticles.map((article) => {
                    if (article.internalId == data.tyresTab?.article.internalId && article.traderArticleNo == data.tyresTab.article.traderArticleNo) {
                        const foundTireArticle = { ...article, quantity: data.tyresTab.article.quantity ?? article.quantity }
                        selectedTire = foundTireArticle
                        return foundTireArticle
                    }
                    return article
                })

                if (highest == WheelSelectionSteps.OVERVIEW) {
                    displayStepLoader(7, highest)
                }

                newReducer = {
                    ...newReducer,
                    tyresList: {
                        ...TYRES_LIST_DEFAULT_STATE,
                        ...newReducer.tyresList,
                        request,
                        articles: {
                            ...TYRES_LIST_DEFAULT_STATE.articles,
                            data: tiresArray,
                            error: false,
                            count: tiresList.articleListCount ?? Number.MAX_VALUE,
                            loading: false,
                            loadingNextItems: false,
                            loadingAutoItems: false,
                        },
                        displayStateReset: data.tyresTab.pageIndex > 1,
                        selectedFilters: {
                            ...newReducer.tyresList.selectedFilters,
                            ...(loadIndex?.length && { loadIndex }),
                            ...(speedIndex?.length && { speedIndex }),
                        },
                        filters: {
                            ...newReducer.tyresList.filters,
                            tyreSize: data.wheelsDetailsTab.tireSizes,
                            loading: false,
                            ...tiresFilters,
                        },
                        selectedItems: [selectedTire!],
                        initialized: true,
                    },
                    ...(!!selectedTire && {
                        overview: overviewReduce(newReducer.overview, {
                            type: "SEND_TIRE_TO_OVERVIEW",
                            payload: selectedTire,
                        }),
                    }),
                }
            }
        }

        return newReducer
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.debug("loading history error", error)
        return newReducer
    }
}

const initSelectedTabs = (step: WheelSelectionSteps): Record<string, NavigationTab> => {
    const obj: Record<string, NavigationTab> = {}
    for (let i = 0; i <= step; i++) {
        obj[i] = { wasSelected: true, quantity: undefined, params: {} }
    }
    return obj
}
