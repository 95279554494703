import { useMemo } from "react"
import { ExternalModule } from "@tm/models"
import { Typography } from "@tm/components"

type ListItemComponentProps = {
    item: ExternalModule
    className?: string
    onClick: (item: ExternalModule) => void
}

function ListItemComponent(props: ListItemComponentProps) {
    const { item, className, onClick } = props
    const description = item.catalogDescription || item.description

    const logoParam = useMemo(() => {
        return item.parameter?.find((param) => param.key === "URL_Logo")
    }, [item.parameter])

    const itemClassName = useMemo(() => {
        return `item${className ? ` ${className}` : ""}`
    }, [className])

    async function handleClick() {
        onClick(item)
    }

    return (
        <div className={itemClassName} onClick={handleClick}>
            {logoParam && <img src={logoParam.value} alt={description} />}
            <Typography whiteSpace="pre-wrap">{description}</Typography>
        </div>
    )
}

export default ListItemComponent
