import { ArticleIdentifier, OE, FittingPosition, ArticleAttribute, Article, Vehicle } from "@tm/models"
import { equals, uniqueId } from "@tm/utils"
import { FastCalculator } from "@tm/data"
import { ArticleAttributeItem, CalcArticle, CalcGenArt, NotFoundArticle } from "../model"

export * from "./dateHelper"
export * from "./storeProvider"
export * from "./getCalcInputLabel"
export * from "./mapFastCalculatorData"
export * from "./bem"
export * from "./userSettingsContextMethods"
export * from "./bundleChannel"
export * from "./getCalcInputIcon"
export * from "./saveVehicleToWorkTask"
export * from "./selectors"
export * from "./checkboxSelection"
export * from "./buttonHelpers"

export function isNumberType(valueType: FastCalculator.ECalcInputValue): boolean {
    return (
        valueType == FastCalculator.ECalcInputValue.Long ||
        valueType == FastCalculator.ECalcInputValue.Int ||
        valueType == FastCalculator.ECalcInputValue.Decimal
    )
}

export type ArticleIdentifierExt = ArticleIdentifier & {
    id?: string
    internalId?: number
    fittingPosition?: FittingPosition
}

export function mapCalcArticleToArticleIdentifier(part: CalcArticle, productGroupId: number, tecdocTypeNo?: number): ArticleIdentifierExt {
    return {
        id: part.id ?? uniqueId(),
        internalId: part.iArtNr ?? 0,
        supplierId: part.supplierId,
        supplierArticleNo: part.supplierArtNr,
        productGroupId,
        tecdocTypeNo,
        fittingPosition: part.binKrit100 ?? 0,
    }
}

export function mapCalcArticleToOePart(part: CalcArticle): OE.OePart {
    return {
        description: part.oeLabel,
        number: part.hArtNr,
        id: part.id,
        manufacturerId: part.supplierId,
        manufacturerName: part.supplierName,
        thumbnailUrl: part.img,
        quantity: isNumberType(part.quantity.valueType) ? part.quantity.value : 1,
    }
}
// note: SVC is using xtestdb and it won't work when we debug next on dev system.
// debug: comment the last 2 parts (line 62 and 63) in order to make it work on dev system.
export function createArticleComparer(calcArticle: CalcArticle, genArt: CalcGenArt): (article: Article) => boolean {
    return (article: Article) =>
        // article.productGroup.id == genArt.genArtNr &&
        article.supplierArticleNo == calcArticle.supplierArtNr &&
        article.supplier.id == calcArticle.supplierId &&
        article.internalId == calcArticle.iArtNr
    // article.traderArticleNo == calcArticle.hArtNr
}

export function mapGenartToNotFoundArticle(genart: CalcGenArt): NotFoundArticle {
    return {
        label: genart.label,
        price: genart.price,
        quantity: genart.quantity,
        removeInput: genart.removeInput,
        totalPrice: genart.totalPrice,
    }
}

export function getProductGroupIds(productGrpIds: string | undefined): Array<number> | undefined {
    if (!productGrpIds) {
        return
    }

    const productGroupIds = decodeURIComponent(productGrpIds)
        .split(",")
        .filter(Boolean)
        .map((item) => {
            return item.split(";")?.[0]
        })
        // eslint-disable-next-line radix
        .map((x) => parseInt(x, 10))
        .filter((x) => !Number.isNaN(x))
        .sort((a, b) => a - b) // Important for the creation of the calcKeyShard (same key shard regardless of order)

    if (!productGroupIds.length) {
        return
    }

    return productGroupIds
}

export function getTopProductGroups(productGrpIds: string | undefined): Array<FastCalculator.TopProductGroup> | undefined {
    if (!productGrpIds) {
        return
    }

    let topProductGroups: FastCalculator.TopProductGroup[] = []
    const groups = decodeURIComponent(productGrpIds).split(",").filter(Boolean)

    groups.forEach((item) => {
        const group = item.split(";")
        const productGroup: FastCalculator.TopProductGroup = {
            GenArtNr: Number(group[0]),
            ...(group[1] && group[1].length > 0 && { BinKrit100: Number(group[1]) }),
        }
        topProductGroups = topProductGroups.concat(productGroup)
    })

    return topProductGroups
}

export const isVehicleChanged = (newVehicle: Vehicle, oldVehicle?: Vehicle) => {
    if (!oldVehicle) {
        return true
    }

    const isRegDateChanged =
        (oldVehicle.initialRegistration && !newVehicle.initialRegistration?.isSame(oldVehicle.initialRegistration)) ||
        !oldVehicle.initialRegistration ||
        !newVehicle.initialRegistration

    const isMileageChanged = oldVehicle.mileAge !== newVehicle.mileAge
    const isEngineCodeChanged = oldVehicle.engineCode !== newVehicle.engineCode
    const isLongLifeChanged = oldVehicle.longlife !== newVehicle.longlife

    const { dataAvailabilities: _, ...newVehicleWithoutAvailabilites } = newVehicle
    const { dataAvailabilities: __, ...oldVehicleWithoutAvailabilites } = oldVehicle
    return (
        !equals(newVehicleWithoutAvailabilites, oldVehicleWithoutAvailabilites) ||
        isRegDateChanged ||
        isMileageChanged ||
        isEngineCodeChanged ||
        isLongLifeChanged
    )
}

export function attributesHelper(highlightSlfa: boolean, article: Article, slfaAttributes: ArticleAttributeItem[]): Article | number {
    const matchedAttributes: ArticleAttribute[] = []

    article.attributes.forEach((attr) => {
        const allAttributes = [...attr.topAttributes, ...attr.partsListAttributes, ...attr.articleAttributes, ...attr.vehicleAttributes]

        allAttributes.forEach((attribute) => {
            const isSlfaMatch = slfaAttributes.some((slfa) => slfa.description == attribute.description && slfa.value == attribute.value)

            if (isSlfaMatch) {
                // eslint-disable-next-line no-unused-expressions, no-param-reassign
                highlightSlfa ? (attribute.highlight = true) : matchedAttributes.push(attribute)
            }
        })
    })

    return highlightSlfa ? article : matchedAttributes.length
}
