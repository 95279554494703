import { useLocalization } from "@tm/localization"
import { ErpPrice } from "@tm/models"
import { PanelSection } from "@tm/controls"
import { RefObject } from "react"
import PriceTable from "../../../_shared/price-table"

type Props = {
    prices?: Array<ErpPrice>
    graduatedPrices?: Array<ErpPrice>
    isReplacementPart: boolean
    wrapperRef: RefObject<HTMLDivElement> | undefined
}

export default function PricesComponent(props: Props) {
    const { translateText } = useLocalization()
    const { prices, graduatedPrices } = props

    if (!prices?.length && !graduatedPrices?.length) {
        return null
    }

    return (
        <div ref={props.wrapperRef}>
            <PanelSection className="stock-info__prices" title={translateText(140)} size="s">
                <PriceTable prices={prices} graduatedPrices={graduatedPrices} isReplacementPart={props.isReplacementPart} />
            </PanelSection>
        </div>
    )
}
