import { Image, PopoverActions, ImageViewer, ImageViewerRefActions, ResizablePopover } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { isValidElement, useRef, useState } from "react"
import { Models, Repositories } from "../../data"

export type Props = {
    thumbnailClassName: string
    thumbnailUrl: string
    thumbnailDescription?: string
    thumbnailFallbackUrl?: string
    internalArticleId: number
    vehicleLinkageId?: number
    images?: Array<Models.ArticleImage>
    enableImageViewer: boolean
    customThumbnailComponent?: React.ReactNode
    startIndex?: number
    title?: string
    onClick?(): void
}

function ArticleImageComponent({
    images,
    internalArticleId,
    vehicleLinkageId,
    startIndex,
    thumbnailClassName,
    thumbnailUrl,
    thumbnailFallbackUrl,
    enableImageViewer,
    customThumbnailComponent,
    thumbnailDescription,
    title,
    onClick,
}: Props) {
    const [imagesLoading, setImagesLoading] = useState(false)
    const [articleImages, setArticleImages] = useState(images || [])
    const [hasFallbackThumbnail, setHasFallbackThumbnail] = useState(false)
    const [open, setOpen] = useState(false)
    const { translate } = useLocalization()
    const imageContainerRef = useRef<HTMLDivElement>(null)
    const popoverRef = useRef<PopoverActions>(null)
    const handleLoadImages = () => {
        setImagesLoading(true)

        Repositories.getArticleImages(internalArticleId, vehicleLinkageId)
            .then(
                (response) => {
                    setArticleImages(response)
                    setImagesLoading(false)
                },
                () => setImagesLoading(false)
            )
            .finally(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"))
                }, 150)
            })
    }

    const handleThumbnailLoadError = () => {
        if (thumbnailFallbackUrl) {
            setHasFallbackThumbnail(true)
        }
    }

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        handleLoadImages()
        setOpen(true)
    }

    const imageViewerActionsRef = useRef<ImageViewerRefActions>(null)
    const onResize = () => {
        imageViewerActionsRef?.current?.fitImageToContent()
    }
    const onResizeStop = () => {
        popoverRef?.current?.updatePosition()
    }
    const CustomThumbnail =
        customThumbnailComponent && isValidElement(customThumbnailComponent) ? (
            <customThumbnailComponent.type
                {...customThumbnailComponent.props}
                onClick={() => {
                    handleTooltipOpen()
                    onClick && onClick()
                    customThumbnailComponent.props.onClick && customThumbnailComponent.props.onClick()
                }}
            />
        ) : (
            customThumbnailComponent
        )

    return (
        <div
            ref={imageContainerRef}
            style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignSelf: "stretch",
                flex: 1,
            }}
        >
            <Image
                type="article"
                className={thumbnailClassName}
                src={!hasFallbackThumbnail ? thumbnailUrl : (thumbnailFallbackUrl ?? "")}
                title={thumbnailDescription}
                onClick={() => {
                    handleTooltipOpen()
                    onClick && onClick()
                }}
                onError={handleThumbnailLoadError}
            />
            {CustomThumbnail}

            {enableImageViewer && open && (
                <ResizablePopover
                    open
                    action={popoverRef}
                    anchorEl={imageContainerRef.current}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    sx={{ ml: 1 }}
                    onClose={handleTooltipClose}
                    onResizeStop={onResizeStop}
                    onResize={onResize}
                    title={title}
                >
                    {!imagesLoading && !articleImages.length && <div className="article__no-images-message">{translate(375)}</div>}
                    {articleImages.length && (
                        <ImageViewer actions={imageViewerActionsRef} images={articleImages} startIndex={startIndex} isLoading={false} />
                    )}
                </ResizablePopover>
            )}
        </div>
    )
}

export default ArticleImageComponent
