import { useAddCustomWorkList, useAddRepairTimeList, useBasketUpdateWorkflow } from "../../data/hooks/workTaskBasket"

async function resolve() {}

export function useBasketWorks() {
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const addCustomWorkList = useAddCustomWorkList(handleBasketUpdateWorkflow)
    const addRepairTimeList = useAddRepairTimeList(handleBasketUpdateWorkflow)
    return { addCustomWorkList, addRepairTimeList }
}
