import { BundleMicro } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const addArticlesToBasket: BundleMicro = {
    name: "rd-add-articles-to-basket",
    reduce,
    component,
}

export default addArticlesToBasket
