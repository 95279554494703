import { Box, BoxProps, Checkbox, ListItemIcon, Loader, TextField, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { getCurrencyFromUserContext } from "@tm/utils"
import { isTruncatedCustomPart } from "@bundles/fast-service/helpers/isTruncatedCustomPart"
import { useFastServiceStore, useWorkContext, worksActions } from "../../../data"
import { Genart } from "../../../data/models"
import { ListItemComponent } from "./shared/ListItemComponent"

type Props = {
    genArt: Genart
} & BoxProps

export default function CustomPartItem({ genArt }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { step, path } = useWorkContext()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const { loading, selectedItems, inputsLocked } = useFastServiceStore((state) => ({
        loading: state.articlesLoading,
        selectedItems: state.worksState.selectedItems,
        inputsLocked: state.inputsLocked,
    }))

    const customPartInternalIdentifier = `${step}_${genArt.label}`

    const [alreadySet, setAlreadySet] = useState(false)
    const [isSelected, setIsSelected] = useState<boolean>(false)
    const [articleNumber, setArticleNumber] = useState("")
    const [description, setDescription] = useState<string>(genArt.label)
    const [price, setPrice] = useState("0")
    const [quantity, setQuantity] = useState(1)

    const customPart = useMemo(() => {
        return selectedItems[step]?.[path]?.selectedCustomParts?.find((cP) => cP.internalIdentifier === customPartInternalIdentifier)
    }, [step, path, selectedItems])

    useEffect(() => {
        if (alreadySet) {
            return
        }
        if (!customPart) {
            return
        }

        if (articleNumber === "" && customPart.articleNumber) {
            setArticleNumber(customPart.articleNumber)
        }

        if (description === "" && customPart.description) {
            setDescription(customPart.description)
        }

        if (price === "0" && customPart.retailPrice) {
            setPrice(customPart.retailPrice.toString())
        }

        if (customPart.quantityValue && quantity !== customPart.quantityValue) {
            setQuantity(customPart.quantityValue)
        }

        if (isSelected === false && customPart.isChecked) {
            setIsSelected(true)
        }

        setAlreadySet(true)
    }, [customPart])

    useEffect(() => {
        if (
            customPart?.articleNumber !== articleNumber ||
            customPart?.description !== description ||
            customPart?.quantityValue !== quantity ||
            customPart?.retailPrice !== +price ||
            customPart?.isChecked !== isSelected
        ) {
            handleCustomArticleChange()
        }
    }, [description, price, quantity, articleNumber, isSelected])

    const isTruncated = isTruncatedCustomPart(customPart)

    const handleSelection = () => {
        setIsSelected((previous) => !previous)
    }

    const handleSetArticleNumber = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (customPart?.articleNumber !== event.target.value) {
            setArticleNumber(event.target.value)
        }
    }

    const handleSetDescription = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (customPart?.description !== event.target.value) {
            setDescription(event.target.value)
        }
    }

    const handleSetPrice = (event: ChangeEvent<HTMLInputElement>) => {
        const inputPrice = event.target.value
        const regex = /^[0-9]*([.|,][0-9]{0,2})?$/

        if (regex.test(inputPrice) || inputPrice === "0" || inputPrice === "") {
            setPrice(inputPrice)
        }
    }

    const handleSetQuantity = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (customPart?.quantityValue !== +event.target.value) {
            setQuantity(+event.target.value)
        }
    }

    const handleCustomArticleChange = () => {
        let formattedPrice = 0
        if (typeof price === "string") {
            formattedPrice = +price.replace(/,/g, ".")
        }

        worksActions.setUpdateCustomPart(step, path, customPartInternalIdentifier, articleNumber, quantity, formattedPrice, description, isSelected)
    }

    return (
        <ListItemComponent disablePadding>
            <Box padding="16px" width="100%">
                <Box display="flex" alignItems="center">
                    <ListItemIcon>
                        <Checkbox
                            size="medium"
                            onChange={handleSelection}
                            checked={isSelected}
                            disabled={(genArt.isMandatory && !isTruncated) || inputsLocked}
                        />
                    </ListItemIcon>
                    <Typography variant="h4" id={genArt.label}>
                        {loading ? <Loader size="small" /> : genArt.label}
                    </Typography>
                </Box>
                {isSelected && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="0.5em">
                        <Box display="flex" flexDirection="row" gap="1em" justifyContent="flex-start">
                            <TextField
                                label={translateText(87)}
                                onChange={handleSetArticleNumber}
                                value={articleNumber}
                                size="extralarge"
                                disabled={inputsLocked}
                                required
                            />
                            <TextField
                                label={translateText(13467)}
                                onChange={handleSetDescription}
                                value={description}
                                size="extralarge"
                                sx={{ width: "20em" }}
                                disabled={inputsLocked}
                                required
                            />
                        </Box>

                        <TextField label={currencyCode} onChange={handleSetPrice} value={price} size="extralarge" disabled={inputsLocked} />
                        <TextField
                            label={translateText(89)}
                            type={!inputsLocked ? "number" : ""}
                            onChange={handleSetQuantity}
                            size="extralarge"
                            value={quantity}
                            disabled={inputsLocked}
                        />
                    </Box>
                )}
            </Box>
        </ListItemComponent>
    )
}
