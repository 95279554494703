import { BundleComponent } from "@tm/morpheus"
import { FastCalculatorWidget } from "./FastCalculatorWidget"
import { withStoreProvider } from "../../data/helpers"

const widget: BundleComponent = {
    name: "widget",
    component: withStoreProvider(FastCalculatorWidget as any),
}

export default widget
