import { ajaxAuth, TmaHelper } from "@tm/utils"
import { ImportRepairEstimationRequest, ImportResponse, TmaEModule } from "@tm/models"
import { getBasketServiceUrl } from "../.."

function getServiceUrl() {
    return `${getBasketServiceUrl()}/repairEstimation`
}

export function importToCostEstimation(body: ImportRepairEstimationRequest, tmaModule: TmaEModule) {
    const url = `${getServiceUrl()}/Import`

    // eslint-disable-next-line no-param-reassign
    body.log = TmaHelper.AddCustomToBasket.GetUserInteractionLog("import", tmaModule)

    return ajaxAuth<ImportResponse>({ url, body, method: "POST" })
}
