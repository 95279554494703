import { useEffect, useRef, useState } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { IFrame } from "@tm/controls"
import { Authority } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { decodeUniqueId, parseQueryString, RouteComponentProps, useMessage, withRouter } from "@tm/utils"
import { useSelector } from "react-redux"
import { styled } from "@tm/components"
import { WrapperFrame } from "../_shared/wrapperFrame"
import { Actions, HaynesproState } from "./business"
import { LoginRequest } from "../../data"
import { buildBaseRequestParameters, compareAndSetRequestUrl } from "../../data/helpers/common"
import ReplaceArticleModal from "../_shared/replaceArticleModal"
import { getWorktaskId } from "../../data/helpers"

type RouteProps = {
    workTaskId: string
}

type Props = RouteComponentProps<RouteProps> & {
    id: string
    loginUrl: string
    loginRequest: LoginRequest
}

function HaynesproFrame({ id, loginRequest: paramsFromConfig, loginUrl, history: { location }, match: { params } }: Props) {
    const { languageId } = useLocalization()
    const workTaskId = getWorktaskId() ?? decodeUniqueId(params.workTaskId ?? "")
    const actions = useActions(Actions)
    const userContext = useUser()?.userContext
    const vehicle = useWorkTask()?.workTask?.vehicle
    const { isLoading: isDemoLoading } = Authority.useGetActivatableModules(userContext)
    const [loginRequest, setLoginRequest] = useState(paramsFromConfig)
    const parameters = useSelector((s: HaynesproState) => s.parameters)

    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState("")
    const mounted = useRef(false)
    const [, setResize] = useState<number | undefined>()

    const buildRequestParameters = () => {
        const request = buildBaseRequestParameters(loginRequest, languageId, workTaskId, userContext, vehicle)
        return request
    }

    useEffect(() => {
        const queryParams: Record<string, any> = parseQueryString(location.search) ?? {}
        const { moduleMode, errorCodes } = queryParams
        if (moduleMode && errorCodes) {
            setLoginRequest({
                ...loginRequest,
                moduleMode,
                errorCodes,
            })
        }
    }, [])

    const handleLoadingCallback = () => {
        mounted.current && setLoading(false)
    }

    useMessage((data) => {
        if (data.invalidSessionMvc) {
            compareAndSetRequestUrl(
                buildRequestParameters,
                loginUrl,
                setLoading,
                setUrl,
                actions.updateParameters,
                parameters,
                url,
                data.invalidSessionMvc
            )
        }
    })

    useEffect(() => {
        mounted.current = true
        const unSub = channel("GLOBAL").subscribe("MVC/RESIZE", () => mounted.current && setResize(Math.random()))

        return () => {
            unSub?.()
            mounted.current = false
        }
    }, [])

    useEffect(() => {
        if (workTaskId && userContext?.id && vehicle && !isDemoLoading) {
            compareAndSetRequestUrl(buildRequestParameters, loginUrl, setLoading, setUrl, actions.updateParameters, parameters, url)
        }
    }, [vehicle?.id, vehicle?.engineCode, vehicle?.longlife, userContext?.id, workTaskId, loginRequest, isDemoLoading])

    return (
        <>
            <ReplaceArticleModal />
            <WrapperFrame loading={loading}>
                {url && (
                    <StyledIFrame hide={loading} url={url} contextDependent id={`iframe_${id}`} onLoad={handleLoadingCallback} refreshOnUrlChanged />
                )}
            </WrapperFrame>
        </>
    )
}

const StyledIFrame = styled(IFrame, {
    shouldForwardProp: (prop) => prop !== "hide",
})<{ hide?: boolean }>(({ hide }) => ({
    flex: 1,
    height: "100%",
    width: "100%",
    ...(hide && {
        display: "none",
    }),
}))

export default withRouter(HaynesproFrame)
