import { Fragment, ReactNode, useEffect, useState } from "react"
import { useSelectedVehicleLookup } from "@tm/context-distribution"
import { Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { BikeModel, CarModel, ECounterType, RegistrationNoType, TruckModel, VehicleImportData, VehicleType } from "@tm/models"
import { VehicleImage, Typography, Box, Alert, Button, Stack, Icon, Loader, Image, DatePicker } from "@tm/components"
import { TmaHelper } from "@tm/utils"
import { getVinSuggestions } from "../../data/repositories/vin-picker"
import { onlyDigits } from "../../helpers"
import { constructionYearToDate, renderDateRange } from "../../helpers/construction-year"
import { getBundleParams } from "../../utils"
import { VinInputField } from "./VinInput"
import { ColumnHeader, ColumnHeaderActive, StyledTextField, Subtitle, textFieldStyles } from "./StyledComponents"

type Props<T> = {
    models: T[]
    selectedModel?: T
    initialVin?: string
    engineCodeFilter?: string

    onSelect(model: T): void
    onApply(model: T, plateId?: string, vin?: string, initialRegistration?: Date | null, mileAge?: number): void
    onDetailsClick(model: T): void
    onSelectEngineCode?(code?: string): void

    modelLoading?: boolean
    importedData?: VehicleImportData
    vehicleType: VehicleType

    showManufacturerThumbnail: boolean
    showModelThumbnail: boolean
    showRegNoColumn?: boolean | string

    customerVehiclesAvailable?: boolean
    searchQuery?: string
    inlineFilterElement?: ReactNode
}

enum ColumnClasses {
    Infos = "infos",
    Enginecode = "enginecode",
    Daterange = "daterange",
    PowerKw = "powerKw",
    PowerHp = "powerHp",
    Capacity = "capacity",
    CylinderCount = "cylinderCount",
    Fuel = "fuel",
    AxisConfig = "axisConfig",
    Tonnage = "tonnage",
    Actiopns = "actions",
    Inputs = "inputs",
    Power = "power",
    RegNo = "reg-no",
}

export default function ModelListComponent<T extends CarModel | BikeModel | TruckModel>(props: Props<T>) {
    const { language, translateText, translate, date, number } = useLocalization()
    const {
        importedData,
        models,
        engineCodeFilter,
        modelLoading,
        vehicleType,
        showRegNoColumn,
        searchQuery,
        customerVehiclesAvailable,
        inlineFilterElement,
        showManufacturerThumbnail,
        showModelThumbnail,
        initialVin,
    } = props
    const [selectedModel, setSelectedModel] = useState<T | undefined>(props.selectedModel)
    const [selectedModelPlateId, setSelectedModelPlateId] = useState<string>()
    const [selectedModelVin, setSelectedModelVin] = useState<string | undefined>(initialVin)
    const [selectedModelInitialRegistration, setSelectedModelInitialRegistration] = useState<Date | null>()
    const [selectedModelMileAge, setSelectedModelMileAge] = useState<number>()
    const [validVin, setValidVin] = useState<boolean>()
    const [dateError, setDateError] = useState<string>("")
    const [sortModels, setSortModels] = useState<T[] | undefined>()
    const [activeSort, setActiveSort] = useState<ColumnClasses>()
    const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">()
    const { selectedVehicleLookup } = useSelectedVehicleLookup()

    useEffect(() => {
        setSelectedModel(props.selectedModel)
    }, [props.selectedModel])

    function getDataSourceLabel(dataSourceId: RegistrationNoType) {
        switch (dataSourceId) {
            case RegistrationNoType.Kba:
                return translateText(12592)
            case RegistrationNoType.TypenscheinDehrendinger:
            case RegistrationNoType.TypenscheinSchweizCarat:
            case RegistrationNoType.TypenscheinSchweizEsa:
            case RegistrationNoType.TypenscheinSchweizEurotax:
            case RegistrationNoType.TypenscheinSchweizHoststettler:
                return translateText(1730)
            case RegistrationNoType.KennzeichenEstland:
            case RegistrationNoType.KennzeichenFinnlandAd:
            case RegistrationNoType.KennzeichenFinnlandHlGroup:
            case RegistrationNoType.KennzeichenFinnlandParkanon:
            case RegistrationNoType.KennzeichenFinnlandSpareParts:
            case RegistrationNoType.KennzeichenIslandOderVrmIsland:
            case RegistrationNoType.KennzeichenNiederlande:
            case RegistrationNoType.KennzeichenNiederlandeCarat:
            case RegistrationNoType.KennzeichenNiederlandeSchwenker:
            case RegistrationNoType.KennzeichenNorwegenNbk:
            case RegistrationNoType.KennzeichenPortugal:
            case RegistrationNoType.KennzeichenSchwedenBanner:
            case RegistrationNoType.KennzeichenSchwedenKgk:
            case RegistrationNoType.KennzeichenSchwedenSchwenker:
            case RegistrationNoType.VINFranceInforauto:
                return translateText(21)
            case RegistrationNoType.NationalcodeAustria:
            case RegistrationNoType.NationalcodeFrankreich:
            case RegistrationNoType.NationalCodeFrankreichCarat:
            case RegistrationNoType.NationalcodeFrankreichHerthBuss:
                return translateText(1218)
            case RegistrationNoType.KTypNr:
                return translateText(123)
            case RegistrationNoType.TopmotiveVin:
            case RegistrationNoType.VinNorwegen:
            case RegistrationNoType.VinPortugal:
            case RegistrationNoType.VinSlowakei:
            case RegistrationNoType.VinSlowenien:
                return translateText(1931)
            case RegistrationNoType.Motorcode:
                return translateText(105)
            case RegistrationNoType.KeywordSearch:
                return translateText(1094)
            case RegistrationNoType.TopmotiveTypeId:
                return translateText(13462)
            default:
                break
        }
    }

    function handleKeyStroke(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter" && selectedModel) {
            handleApply(selectedModel)
        }
    }

    function handleDetailsClick(item: T, e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarDetails)
        props.onDetailsClick(item)
    }

    function handleSelect(item: T) {
        if (!selectedModel || selectedModel !== item) {
            setSelectedModel(item)
            if (importedData) {
                setSelectedModelInitialRegistration(importedData.initialRegistration)
                setSelectedModelMileAge(importedData.mileAge)
                setSelectedModelPlateId(importedData.plateId)
                setSelectedModelVin(importedData.vin)
            } else {
                setSelectedModelInitialRegistration(item.registrationNoDetails?.initialRegistration)
                setSelectedModelMileAge(undefined)
                setSelectedModelPlateId(item.registrationNoDetails?.plateId)
                setSelectedModelVin(item.registrationNoDetails?.vin)
            }

            props.onSelect(item)
        }
    }

    function handleApply(item: T) {
        if (dateError) {
            return
        }

        if (selectedModel === item) {
            props.onApply(item, selectedModelPlateId, selectedModelVin, selectedModelInitialRegistration, selectedModelMileAge)
        } else {
            props.onApply(item)
        }
    }

    function handleGetVinSuggestions(query: string): Promise<string[]> {
        if (!selectedModel) {
            return Promise.resolve([])
        }

        return getVinSuggestions(query, selectedModel.id)
    }

    function handlePlateIdChange(plateId: string) {
        if (!selectedModel) {
            return
        }

        setSelectedModelPlateId(plateId.toUpperCase())
    }

    function handleVinChangeConfirm(value: string) {
        if (!selectedModel) {
            return
        }

        setSelectedModelVin(value)
    }

    function handleVinChange(_: string, valid?: boolean) {
        setValidVin(valid)
    }

    function handleInitialRegistrationChange(initialRegistriation: Date | null) {
        if (!selectedModel) {
            return
        }

        setSelectedModelInitialRegistration(initialRegistriation)
    }

    function handleMileAgeChange(mileAge: string) {
        if (!selectedModel) {
            return
        }

        const formattedMileAge = onlyDigits(mileAge)
        const parsedMileAge = parseInt(formattedMileAge)
        setSelectedModelMileAge(!Number.isNaN(parsedMileAge) ? parsedMileAge : undefined)
    }

    function handleEngineCodeFilter(code: string | undefined, e: React.MouseEvent) {
        e?.stopPropagation()
        props.onSelectEngineCode?.(code)
    }

    function sortIndicator(type: ColumnClasses) {
        const direction = sortDirection === "ASC" ? "down" : "up"
        const icon = activeSort === type ? direction : "down"
        return <Icon name={icon} size="1rem" />
    }

    function sortCell(type: ColumnClasses) {
        let sortOrder: "ASC" | "DESC" = "ASC"
        if (activeSort === type) {
            sortOrder = sortDirection === "ASC" ? "DESC" : "ASC"
        }
        const sortedModels = [...models].sort((a, b) => {
            let x
            let y
            switch (type) {
                case ColumnClasses.Infos:
                    x = a.description
                    y = b.description
                    break
                case ColumnClasses.Enginecode:
                    x = a.engineCodes
                    y = b.engineCodes
                    break
                case ColumnClasses.Daterange:
                    x = new Date(
                        a.constructionYearFrom?.year ? a.constructionYearFrom?.year : 0,
                        a.constructionYearFrom?.month ? a.constructionYearFrom?.month : 0
                    )
                    y = new Date(
                        b.constructionYearFrom?.year ? b.constructionYearFrom?.year : 0,
                        b.constructionYearFrom?.month ? b.constructionYearFrom?.month : 0
                    )
                    break
                case ColumnClasses.Power:
                    x = (a as CarModel | BikeModel).enginePowerHp
                    y = (b as CarModel | BikeModel).enginePowerHp
                    break
                case ColumnClasses.PowerHp:
                    x = (a as TruckModel).enginePowerHpFrom
                    y = (b as TruckModel).enginePowerHpFrom
                    break
                case ColumnClasses.PowerKw:
                    x = (a as TruckModel).enginePowerKwFrom
                    y = (b as TruckModel).enginePowerKwFrom
                    break
                case ColumnClasses.Capacity:
                    x = a.engineCapacityCcm
                    y = b.engineCapacityCcm
                    break

                case ColumnClasses.CylinderCount:
                    x = (a as CarModel | BikeModel).cylinderCount
                    y = (b as CarModel | BikeModel).cylinderCount
                    break

                case ColumnClasses.Fuel:
                    if (vehicleType === VehicleType.CommercialVehicle) {
                        x = (a as TruckModel).engineTypeDescription
                        y = (b as TruckModel).engineTypeDescription
                    } else {
                        x = (a as CarModel | BikeModel).fuelType
                        y = (b as CarModel | BikeModel).fuelType
                    }
                    break
                case ColumnClasses.AxisConfig:
                    x = (a as TruckModel).axisConfigDescription
                    y = (b as TruckModel).axisConfigDescription
                    break
                case ColumnClasses.Tonnage:
                    x = (a as TruckModel).tonnage
                    y = (b as TruckModel).tonnage
                    break
                default:
                    break
            }

            if (x && y) {
                if (sortOrder === "DESC") {
                    // eslint-disable-next-line no-nested-ternary
                    return x > y ? 1 : x < y ? -1 : 0
                }
                // eslint-disable-next-line no-nested-ternary
                return x > y ? -1 : x < y ? 1 : 0
            }
            return 0
        })
        setSortModels(sortedModels)
        setActiveSort(type)
        setSortDirection(sortOrder)
    }

    function renderEngineCodes(codes: string[]) {
        return codes.map((code, index) => {
            const className = `is-filter-element ${engineCodeFilter === code ? "selected" : ""}`

            return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                    {props.onSelectEngineCode ? (
                        <span className={className} onClick={(e) => handleEngineCodeFilter(engineCodeFilter === code ? undefined : code, e)}>
                            {code}
                        </span>
                    ) : (
                        code
                    )}
                    {index === codes.length - 1 ? "" : ", "}
                </Fragment>
            )
        })
    }

    function renderModelCell(item: T) {
        const dataSource = item.dataSourceId !== undefined ? getDataSourceLabel(item.dataSourceId) : undefined
        const manufacturerThumbnail =
            showManufacturerThumbnail && !getBundleParams().hideManufacturerLogos && item.manufacturerThumbnail?.replace(/https?:/, "")

        return (
            <Table.Cell>
                {dataSource && (
                    <Subtitle variant="label">
                        {translate(1388)}: {dataSource}
                    </Subtitle>
                )}

                <strong>{item.fullDescription || item.description}</strong>
                {(!!manufacturerThumbnail || showModelThumbnail) && (
                    <div className="thumbs">
                        {!!manufacturerThumbnail && (
                            <Image
                                loading="lazy"
                                src={manufacturerThumbnail}
                                type="manufacturer"
                                style={{ width: "auto", maxWidth: "5.5rem", height: "2rem", marginLeft: "0.5rem" }}
                            />
                        )}
                        {showModelThumbnail && (
                            <VehicleImage
                                loading="lazy"
                                modelImage={item.thumbnail}
                                modelSeriesImage={item.modelSeriesThumbnail}
                                vehicleType={vehicleType}
                            />
                        )}
                    </div>
                )}
            </Table.Cell>
        )
    }

    function renderRegistrationNumberCell(item: T) {
        const registrationNumber =
            item.registrationNoDetails?.additionalDetails?.find((x) => x.type === 23)?.value ??
            item.registrationNoDetails?.registrationNo ??
            item.registrationNoDetails?.typScheinNr

        return <Table.Cell>{registrationNumber}</Table.Cell>
    }

    function renderEngineCodeCell(item: T) {
        return <Table.Cell>{item.engineCodes?.length ? renderEngineCodes(item.engineCodes) : "-"}</Table.Cell>
    }

    function renderConstructionYearCell(item: T) {
        return <Table.Cell>{renderDateRange(item, date)}</Table.Cell>
    }

    function renderPowerCell(item: CarModel | BikeModel) {
        return (
            <Table.Cell>
                {!item.enginePowerKw && !item.enginePowerHp
                    ? "-"
                    : `${item.enginePowerKw ? `${item.enginePowerKw} ${translate(329)}` : "-"} / ${
                          item.enginePowerHp ? `${item.enginePowerHp} ${translate(330)}` : "-"
                      }`}
            </Table.Cell>
        )
    }

    function renderPowerHpCell(item: TruckModel) {
        let hp = ""
        if (item.enginePowerHpFrom) {
            hp += `${item.enginePowerHpFrom}`
        }
        if (item.enginePowerHpTo) {
            hp += ` - ${item.enginePowerHpTo}`
        }

        return <Table.Cell>{hp ? `${hp} ${translate(330)}` : "-"}</Table.Cell>
    }

    function renderPowerKwCell(item: TruckModel) {
        let kw = ""
        if (item.enginePowerKwFrom) {
            kw += `${item.enginePowerKwFrom}`
        }
        if (item.enginePowerKwTo) {
            kw += ` - ${item.enginePowerKwTo}`
        }

        return <Table.Cell>{kw ? `${kw} ${translate(329)}` : "-"}</Table.Cell>
    }

    function renderCapacityCell(item: CarModel | BikeModel) {
        return <Table.Cell>{item.engineCapacityCcm ? `${item.engineCapacityCcm} ${translate(331)}` : "-"}</Table.Cell>
    }

    function renderCylinderCountCell(item: CarModel | BikeModel) {
        return <Table.Cell>{item.cylinderCount || "-"}</Table.Cell>
    }

    function renderFuelTypeCell(item: CarModel | BikeModel) {
        return <Table.Cell>{item.fuelType || "-"}</Table.Cell>
    }

    function renderEngineTypeCell(item: TruckModel) {
        return <Table.Cell>{item.engineTypeDescription || "-"}</Table.Cell>
    }

    function renderAxisConfigCell(item: TruckModel) {
        return <Table.Cell>{item.axisConfigDescription || "-"}</Table.Cell>
    }

    function renderTonnageCell(item: TruckModel) {
        return <Table.Cell>{item.tonnage ? number(item.tonnage) : "-"}</Table.Cell>
    }

    const handleClickApplyEvent = (item: T) => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSelectVehicle)

        handleApply({
            ...item,
            registrationTypeId: item.registrationNoTypeId || selectedVehicleLookup.lookupTypeId,
            countryCode: item.countryCode || selectedVehicleLookup.countryCode,
        })
    }

    function renderActionsCell(item: T) {
        return (
            <Table.Cell>
                <Stack direction="row" gap={1}>
                    <Button size="small" onClick={(e) => handleDetailsClick(item, e)}>
                        {translateText(43)}
                    </Button>
                    <Button size="small" onClick={() => handleClickApplyEvent(item)}>
                        {translateText(22)}
                    </Button>
                </Stack>
            </Table.Cell>
        )
    }

    function renderVehicleDataCell(item: T) {
        if (!selectedModel || selectedModel !== item) {
            return <Table.Cell />
        }

        const constructionYearFrom = item.constructionYearFrom ? constructionYearToDate(item.constructionYearFrom) : undefined

        return (
            <Table.Cell>
                <div className="inner" onDoubleClick={(e) => e.stopPropagation()}>
                    {
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label className="tip-message">{translateText(819)}</label>
                    }
                    <div className="vehicle-data-inputs">
                        <StyledTextField
                            placeholder={translateText(21).toUpperCase()}
                            value={selectedModelPlateId}
                            onChange={(e) => handlePlateIdChange(e.target.value)}
                        />
                        <DatePicker
                            localisation={{ locale: language, region: null }}
                            value={selectedModelInitialRegistration || null}
                            onChange={(value) => handleInitialRegistrationChange(value || null)}
                            minDate={constructionYearFrom}
                            textfieldProps={{
                                sx: { ...textFieldStyles },
                            }}
                            onError={(reason) => setDateError(reason ? translateText(13931) : "")}
                            errorMessage={dateError}
                        />
                        <StyledTextField
                            placeholder={translateText(125).toUpperCase()}
                            value={selectedModelMileAge?.toString()}
                            onChange={(e) => handleMileAgeChange(e.target.value)}
                            inputProps={{ maxLength: 7 }}
                        />
                        <VinInputField
                            showEdit
                            vehicleData={{
                                plateId: selectedModelPlateId,
                                vin: selectedModelVin,
                                tecDocManufacturerId: item.manufacturerId,
                            }}
                            textFieldProps={{
                                placeholder: translateText(101),
                            }}
                            onVinChange={handleVinChange}
                            onVinChangeConfirm={handleVinChangeConfirm}
                            getVinSuggestions={handleGetVinSuggestions}
                        />
                    </div>
                    {modelLoading ? (
                        <Loader size="small" />
                    ) : (
                        <Button
                            disabled={!!dateError || validVin === false}
                            startIcon={<Icon name="check" />}
                            color="success"
                            onClick={() => handleApply(item)}
                        />
                    )}
                </div>
            </Table.Cell>
        )
    }

    function renderColumnHeader(className: ColumnClasses, text: ReactNode) {
        const Component = activeSort === className ? ColumnHeaderActive : ColumnHeader

        return (
            <Component onClick={() => sortCell(className)}>
                {sortIndicator(className)}
                {text}
            </Component>
        )
    }

    function renderCommercialTableColumns() {
        const columns = [
            <Table.Column key={ColumnClasses.Infos} className={ColumnClasses.Infos} renderItemContent={renderModelCell}>
                {renderColumnHeader(ColumnClasses.Infos, translate(74))}
            </Table.Column>,
            <Table.Column key={ColumnClasses.Enginecode} className={ColumnClasses.Enginecode} renderItemContent={renderEngineCodeCell}>
                {renderColumnHeader(ColumnClasses.Enginecode, translate(105))}
            </Table.Column>,
            <Table.Column key={ColumnClasses.Daterange} className={ColumnClasses.Daterange} renderItemContent={renderConstructionYearCell}>
                {renderColumnHeader(ColumnClasses.Daterange, translate(127))}
            </Table.Column>,
            <Table.Column key={ColumnClasses.PowerKw} className={ColumnClasses.PowerKw} renderItemContent={renderPowerKwCell}>
                {renderColumnHeader(
                    ColumnClasses.PowerKw,
                    <>
                        {translate(94)} ({translate(329)})
                    </>
                )}
            </Table.Column>,
            <Table.Column key={ColumnClasses.PowerHp} className={ColumnClasses.PowerHp} renderItemContent={renderPowerHpCell}>
                {renderColumnHeader(
                    ColumnClasses.PowerHp,
                    <>
                        {translate(94)} ({translate(330)})
                    </>
                )}
            </Table.Column>,
            <Table.Column key={ColumnClasses.Capacity} className={ColumnClasses.Capacity} renderItemContent={renderCapacityCell}>
                {renderColumnHeader(ColumnClasses.Capacity, translate(128))}
            </Table.Column>,
            <Table.Column key={ColumnClasses.Fuel} className={ColumnClasses.Fuel} renderItemContent={renderEngineTypeCell}>
                {renderColumnHeader(ColumnClasses.Fuel, translate(299))}
            </Table.Column>, // Fueltype is in the engineType field for commercial vehicles
            <Table.Column key={ColumnClasses.AxisConfig} className={ColumnClasses.AxisConfig} renderItemContent={renderAxisConfigCell}>
                {renderColumnHeader(ColumnClasses.AxisConfig, translate(1904))}
            </Table.Column>,
            <Table.Column key={ColumnClasses.Tonnage} className={ColumnClasses.Tonnage} renderItemContent={renderTonnageCell}>
                {renderColumnHeader(ColumnClasses.Tonnage, translate(1905))}
            </Table.Column>,
            <Table.Column key="actions" className="actions" renderItemContent={renderActionsCell} />,
            <Table.Column key="inputs" className="inputs" renderItemContent={renderVehicleDataCell} />,
        ]

        return columns
    }

    function renderTableColumns() {
        const columns = [
            <Table.Column key={`${ColumnClasses.Infos}-2`} className={ColumnClasses.Infos} renderItemContent={renderModelCell}>
                {renderColumnHeader(ColumnClasses.Infos, translate(74))}
            </Table.Column>,
            <Table.Column key={`${ColumnClasses.Enginecode}-2`} className={ColumnClasses.Enginecode} renderItemContent={renderEngineCodeCell}>
                {renderColumnHeader(ColumnClasses.Enginecode, translate(105))}
            </Table.Column>,
            <Table.Column key={`${ColumnClasses.Daterange}-2`} className={ColumnClasses.Daterange} renderItemContent={renderConstructionYearCell}>
                {renderColumnHeader(ColumnClasses.Daterange, translate(127))}
            </Table.Column>,
            <Table.Column key={`${ColumnClasses.Power}-2`} className={ColumnClasses.Power} renderItemContent={renderPowerCell}>
                {renderColumnHeader(ColumnClasses.Power, translate(94))}
            </Table.Column>,
            <Table.Column key={`${ColumnClasses.Capacity}-2`} className={ColumnClasses.Capacity} renderItemContent={renderCapacityCell}>
                {renderColumnHeader(ColumnClasses.Capacity, translate(128))}
            </Table.Column>,

            <Table.Column
                key={`${ColumnClasses.CylinderCount}-2`}
                className={ColumnClasses.CylinderCount}
                renderItemContent={renderCylinderCountCell}
            >
                {renderColumnHeader(ColumnClasses.CylinderCount, translate(305))}
            </Table.Column>,

            showRegNoColumn ? (
                <Table.Column key={`${ColumnClasses.RegNo}-2`} className={ColumnClasses.RegNo} renderItemContent={renderRegistrationNumberCell}>
                    {typeof showRegNoColumn === "string" ? showRegNoColumn : undefined}
                </Table.Column>
            ) : (
                <Table.Column key={`${ColumnClasses.Fuel}-2`} className={ColumnClasses.Fuel} renderItemContent={renderFuelTypeCell}>
                    {renderColumnHeader(ColumnClasses.Fuel, translate(299))}
                </Table.Column>
            ),

            <Table.Column key="actions-2" className="actions" renderItemContent={renderActionsCell} />,
            <Table.Column key="inputs-2" className="inputs" renderItemContent={renderVehicleDataCell} />,
        ]

        return columns
    }

    return (
        <div className="models" onKeyPress={handleKeyStroke}>
            <Typography variant="h2" mb={1}>
                {translate(12501)}:
            </Typography>
            <Box mb={2}>
                {customerVehiclesAvailable ? (
                    <Typography>{translate(12502)}</Typography>
                ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon name="bulb" />
                        <Typography marginLeft="0.25em">{translate(12503)}</Typography>
                    </div>
                )}
            </Box>
            {inlineFilterElement}
            {vehicleType === VehicleType.PassengerCar && // Only show message for PKW - see NEXT-16515
                models.length >= 1 &&
                models[0].dataSourceId === RegistrationNoType.KeywordSearch &&
                !searchQuery?.includes(models[0].fullDescription || models[0].description) && ( // Don't show message if search query is containing the vehicle description - see NEXT-16516
                    <Alert severity="warning" title={translateText(12773)} sx={{ mt: "0.5rem" }}>
                        {translateText(12774)}
                    </Alert>
                )}
            <Table
                data={sortModels || models}
                columns={vehicleType === VehicleType.CommercialVehicle ? renderCommercialTableColumns() : renderTableColumns()}
                onClickRow={handleSelect}
                onDoubleClickRow={handleApply}
                className={vehicleType === VehicleType.CommercialVehicle ? "commercial" : ""}
                getRowClassName={(item) => `models__item ${props.selectedModel === item ? "is-selected" : ""}`}
            />
        </div>
    )
}
