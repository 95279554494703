import { useAvailabilityStatus, useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { Button, Headline, Icon, Loader, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, AvailabilityStatus, RepairTimeProvider, RequestArticleDetailsPayload, SystemType } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { classes, encodeUniqueId, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { em } from "csx"
import { batch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { FC, useEffect } from "react"
import { getBundleParams } from "../../../utils"
import { AvailabilityFilterType } from "../../../business"
import { WheelSelectionSteps } from "../../../data/enums"
import { useDetailsInModal } from "../../../data/hooks"
import { MainState } from "../../main"
import { SensorArticle } from "../../_shared"
import { Actions } from "../business"

type Props = RouteComponentProps<any> & {
    detailsModalRoute?: string
    articleAlternativesRoute: string
    repairTimesRoute: string
    className?: string
}

const selector = createSelector(
    (s: MainState) => ({
        articles: s.rdksList.articles,
        selectedItems: s.rdksList.selectedArticles,
        erpInformations: s.rdksList.erpInformations,
        productGroupTopicIds: s.rdksList.productGroupTopicIds,
    }),
    (x) => x
)

const SensorListTable: FC<Props> = ({ articleAlternativesRoute, repairTimesRoute, className, detailsModalRoute, match }) => {
    let scrollTop = 0
    let blockScroll = false

    const user = useUser()
    const actions = useActions(
        Actions,
        "saveTpmsTab",
        "changeStep",
        "loadNextSensorsList",
        "selectRDKSArticle",
        "sendArticleToOverview",
        "changeQuantity",
        "getProductGroupTopicIds"
    )
    const { translateText } = useLocalization()
    const {
        articles: { data: items, error, loading, noMoreRdksArticles, autoNextCount, loadingAutoItems, loadingNextItems },
        erpInformations,
        selectedItems,
        productGroupTopicIds,
    } = useSelector(selector)
    const { availability } = useSelector((s: MainState) => s.rdksList.selectedFilters)
    const vehicle = useSelector((s: MainState) => s.manager.vehicle)
    const productGroupId = items.first()?.productGroup.id || -1
    const { tecrmiTdGenartsRoute, haynesProTdGenartsRoute } = getBundleParams()
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()

    const workTask = useWorkTask()
    const workTaskId = encodeUniqueId(workTask?.workTaskId ?? "")

    const { articleDetailsInModal, handleDetailsPage } = useDetailsInModal(match)
    const isNotStandalone = user.userContext.system.systemType === SystemType.Next

    useEffect(() => {
        Object.keys(productGroupTopicIds).length === 0 && vehicle && productGroupId && actions.getProductGroupTopicIds(vehicle, [productGroupId])
    }, [])

    const handleArticleDetails = (article: Article, request: RequestArticleDetailsPayload) => {
        const params = {
            productGroupId: article.productGroup.id.toString(),
            supplierId: article.supplier.id.toString(),
            supplierArticleNo: article.supplierArticleNo,
            partsDetailsSubPage: request.subPage || "overview",
        }

        if (articleDetailsInModal && user?.userContext?.system.systemType == SystemType.Next && detailsModalRoute) {
            handleDetailsPage(article.quantity, params, detailsModalRoute)
        } else {
            actions.changeStep({ step: WheelSelectionSteps.RDKSDETAILS, params, quantity: article.quantity }, false)
        }
    }

    const handleAddToBasketBtn = (item: Article) => {
        batch(() => {
            actions.selectRDKSArticle(item)
            actions.sendArticleToOverview()
            actions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, true)
            actions.saveTpmsTab(item)
        })
    }

    const handleHaynesProTechnicalDataClick = () => {
        if (productGroupId && haynesProTdGenartsRoute) {
            Morpheus.showView(
                "1",
                renderRoute(haynesProTdGenartsRoute, {
                    workTaskId,
                    topicId: productGroupTopicIds?.[productGroupId]?.[RepairTimeProvider.HaynesProCar],
                })
            )
        }
    }

    const handleTecrmiTechnicalDataClick = () => {
        if (productGroupId && tecrmiTdGenartsRoute) {
            Morpheus.showView(
                "1",
                renderRoute(tecrmiTdGenartsRoute, {
                    workTaskId,
                    productGroupId,
                })
            )
        }
    }

    const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
        const el = e.target as HTMLElement
        if (!noMoreRdksArticles && el.scrollHeight - el.scrollTop <= el.clientHeight + 100 && el.scrollTop > scrollTop && !blockScroll) {
            actions.loadNextSensorsList()
            blockScroll = true
            scrollTop = el.scrollTop
        }
    }

    // TOOD: this filter items part can be merged with the one from tyres
    // recheck this later, there are too many cases on the render part as well
    let parts = items

    const getFilteredPartsByAvailability = (parts: Array<Article>, filter: Array<AvailabilityStatus>) => {
        return parts.filter((part) => {
            const quantityModifiers = erpInformations.find(
                (modifier) => modifier && (modifier.itemId == (part as any).id || modifier.itemId == part.internalId.toString())
            )
            const mod = quantityModifiers?.availability

            return !mod || !mod.type || filter.includes(mod.type)
        })
    }

    if (availability === AvailabilityFilterType.Primary && availabilityStatusIdsToShow?.length) {
        parts = getFilteredPartsByAvailability(parts, availabilityStatusIdsToShow)
    } else if (availability === AvailabilityFilterType.Secondary && availabilityStatusIdsToShowSecondary?.length) {
        parts = getFilteredPartsByAvailability(parts, availabilityStatusIdsToShowSecondary)
    }

    const { maxAutoRequest, minAvailableItems } = getBundleParams()

    useEffect(() => {
        if (availability && parts.length < minAvailableItems && !noMoreRdksArticles && autoNextCount < maxAutoRequest) {
            actions.loadNextSensorsList(true)
        }
    }, [availability, parts])

    const renderLoading = () => {
        return (
            <div className={className}>
                <div className="article-list__panel article-list__status">
                    <Loader />
                </div>
            </div>
        )
    }

    if (loading) {
        return renderLoading()
    }

    if (!loadingAutoItems && (error || !parts.length)) {
        return (
            <div className={className}>
                <div className="article-list__panel article-list__status">
                    <div className="article-list__no-result">
                        <Icon name="no-results" size="xl" />
                        <Headline>{translateText(809)}</Headline>
                        <Text size="s" modifiers={["block"]}>
                            {translateText(1088)}
                        </Text>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={className}>
            <Scrollbar onScroll={handleScroll}>
                <div className="tk-parts">
                    <div className="article-group__description">
                        <div className="article-group__description-content" style={{ display: "flex", width: "100%" }}>
                            <div className="article-group__description__left">
                                <Text size="l">{translateText(12513)}</Text>
                                {!!haynesProTdGenartsRoute &&
                                    productGroupTopicIds?.[productGroupId]?.[RepairTimeProvider.HaynesProCar] &&
                                    isNotStandalone && (
                                        <Button
                                            onClick={handleHaynesProTechnicalDataClick}
                                            icon="tech-data"
                                            className="article-group__description__left__td-button"
                                            layout={["holo"]}
                                        >
                                            {`HaynesPro - ${translateText(941)}`}
                                        </Button>
                                    )}
                                {!!tecrmiTdGenartsRoute &&
                                    workTask?.workTask?.vehicle?.dataAvailabilities?.technicalData?.tecRMI &&
                                    isNotStandalone && (
                                        <Button
                                            onClick={handleTecrmiTechnicalDataClick}
                                            icon="tech-data"
                                            className="article-group__description__left__td-button"
                                            layout={["holo"]}
                                        >
                                            {`TecRMI - ${translateText(941)}`}
                                        </Button>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="article-list">
                        {parts.map((x, idx) => (
                            <SensorArticle
                                articleAlternativesRoute={articleAlternativesRoute}
                                key={idx}
                                item={x}
                                isSelected={!!selectedItems && selectedItems?.some((selectedItem) => x.internalId === selectedItem.internalId)}
                                repairTimesRoute={repairTimesRoute}
                                onArticleDetailsClick={handleArticleDetails}
                                onQuantityChange={actions.changeQuantity}
                                onSelect={actions.selectRDKSArticle}
                                onAddToBasket={handleAddToBasketBtn}
                                isDisabled
                            />
                        ))}
                    </div>
                </div>
                {loadingAutoItems && autoNextCount != 0 && (
                    <div className={classes("article-list__panel", "article-list__status", style.loadWrapper)}>
                        <Text>Trying to load more data</Text>
                        <Loader />
                    </div>
                )}
                {loadingNextItems && renderLoading()}
            </Scrollbar>
        </div>
    )
}

export default withRouter(SensorListTable)

const style = useStyle({
    loadWrapper: {
        display: "flex",
        flexDirection: "column",
        height: em(5),
    },
})(SensorListTable)
