import { GetArticlesListMappedResponse } from "@tm/models"
import { mapTyreArticleToArticle } from "@tm/utils"
import { GetArticleListByMatchCodeResponse } from "./model"

export function mapTireArticlesResponse(response: GetArticleListByMatchCodeResponse): GetArticlesListMappedResponse {
    return {
        ...response,
        uniArticles: response.uniArticles.length > 0 ? response.uniArticles.map((x) => mapTyreArticleToArticle(x)) : [],
    }
}
