import { useDefaultErpSystem } from "@tm/utils"
import { ReactNode, useEffect, useState } from "react"
import { Article, AvailabilityStatus, ErpSystemConfigMode } from "@tm/models"
import { useAvailabilityStatus } from "@tm/context-distribution"
import { useErpInfo } from "@bundles/erp"
import { AvailabilityFilterType } from "../../../business"

type Props = {
    children: ReactNode | ReactNode[]
    article: Article
    availability?: AvailabilityFilterType
}

export function AvailibilityHideWrapper(props: Props) {
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()

    const defaultErp = useErpInfo(props.article, "list")

    const { erpConfig } = useDefaultErpSystem()
    const partnerConfig =
        erpConfig.mode === ErpSystemConfigMode.Partnersystems && erpConfig.erpSystemConfigs && erpConfig.erpSystemConfigs.length > 1
            ? erpConfig.erpSystemConfigs[1]
            : undefined

    const partnerErp = useErpInfo(props.article, "list", undefined, partnerConfig)

    const [hide, setHide] = useState(false)

    useEffect(() => {
        if (props.availability) {
            if (
                !partnerErp.loading &&
                partnerErp.erpInfo &&
                availabilityStatusIdsToShowSecondary &&
                props.availability === AvailabilityFilterType.Secondary
            ) {
                if (
                    availabilityStatusIdsToShowSecondary?.find((showType: AvailabilityStatus) => showType === partnerErp.erpInfo?.availability.type)
                ) {
                    setHide(false)
                } else {
                    setHide(true)
                }
            } else if (!defaultErp.loading) {
                if (availabilityStatusIdsToShow?.find((showType: AvailabilityStatus) => showType === defaultErp.erpInfo?.availability.type)) {
                    setHide(false)
                } else {
                    setHide(true)
                }
            }
        } else {
            setHide(false)
        }
    }, [
        defaultErp.loading,
        partnerErp.loading,
        props.availability,
        availabilityStatusIdsToShowSecondary,
        availabilityStatusIdsToShow,
        defaultErp.erpInfo?.availability.type,
        partnerErp.erpInfo,
    ])

    if (hide) {
        return null
    }

    return <>{props.children}</>
}
