import { Dialog } from "@tm/components"
import { AddCustomWorkListRequest } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { WorkTaskInfo } from "@tm/context-distribution"
import { BasicBasketWorkInfo } from "@bundles/basket/models"
import { AddCustomRepairTimeItemsRequest, CostEstimation } from "../../../../../data/model"
import { addCustomRepairTimeItems } from "../../../../../data/repositories/custom-items/repair-times"
import { createCustomWork } from "../../../../../data/mapper"
import WorkDialogFields from "./WorkDialogFields"

type Props = {
    workTask: WorkTaskInfo
    costEstimation?: CostEstimation
    openDialog: boolean
    currencyCode: string
    repairTimeDivision: number
    showRepairTimesInHours: boolean
    onAddCustomWork(request: AddCustomWorkListRequest): void
    onClose(): void
}

export function NewCustomWorkDialog(props: Props) {
    const { currencyCode, costEstimation, openDialog, workTask, repairTimeDivision, showRepairTimesInHours, onAddCustomWork, onClose } = props
    const { id, vehicle } = workTask
    const { costEstimationOptions } = costEstimation || {}

    const { translateText } = useLocalization()

    async function handleAddCustomWorkClick(saveAsCustomRepairTime: boolean, editBasketWorkInfo: BasicBasketWorkInfo) {
        const { categoryOfWork, description, workNumber, time, hourlyRate, fixedPriceValue, vatRate, rebate, surcharge } = editBasketWorkInfo

        onAddCustomWork({
            workTaskId: id,
            repairTimeDivision,
            customWorks: [createCustomWork(workNumber, categoryOfWork, description, time, hourlyRate, fixedPriceValue, rebate, surcharge, vatRate)],
            vehicleId: vehicle?.id,
            usePercentageValues: true,
            costEstimationOptions,
        })

        if (saveAsCustomRepairTime) {
            const request: AddCustomRepairTimeItemsRequest = {
                customRepairTimeItemsToAdd: [
                    {
                        workId: workNumber,
                        category: categoryOfWork,
                        description,
                        workTime: time,
                        customHourlyRate: hourlyRate,
                        fixedPriceValue,
                        vatRate,
                    },
                ],
                repairTimeDivision,
                priceVatMode: costEstimationOptions?.priceVatMode,
            }
            addCustomRepairTimeItems(request)
        }
    }

    if (!costEstimation) {
        return null
    }

    return (
        <Dialog open={openDialog} position="middle" fullWidth maxWidth="lg">
            <WorkDialogFields
                currencyCode={currencyCode}
                costEstimation={costEstimation}
                icon="individual-repairtimes"
                iconTooltip={translateText(920)}
                repairTimeDivision={repairTimeDivision}
                showRepairTimesInHours={showRepairTimesInHours}
                showSaveAsCustomRepairTimeButton
                title={translateText(920)}
                onClose={onClose}
                onConfirm={handleAddCustomWorkClick}
            />
        </Dialog>
    )
}
