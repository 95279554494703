import { Box, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo } from "react"
import { DropDown } from "./dropDown"
import { TyresCritsResponse } from "../../../../../data/repositories"
import { splitSeasonFromTyreModel } from "../../../../../helpers/splitSeasonFromTyreModel"
import { getSeasonFromTyreModel } from "../../../../../helpers/getSeasonFromTyreModel"
import { enableFreeInputMode } from "../../../../../helpers/enableFreeInputMode"
import { compareTyreFilters } from "../../../../../helpers/compareTyreFilters"
import { AdditionalSpecs, ExtendEFilterNames, SelectedFilters, TyreModelsWithSeason } from "../../../../../data/models"
import { loadTiresCrits } from "../../../../../helpers/tires"

type Props = {
    selectedFilters: SelectedFilters
    setSelectedFiltersTemp: (filters?: SelectedFilters) => void
    tyresCrits: Partial<TyresCritsResponse>
    freeInputMode: boolean
    setFreeInputMode: (freeMode: boolean) => void
    loading: boolean
    previousFieldsCompleted: boolean
    tyreSpecValues: string[]
}

export function AdditionalSpecifications(props: Props) {
    const { selectedFilters, setSelectedFiltersTemp, tyresCrits, freeInputMode, setFreeInputMode, loading, previousFieldsCompleted, tyreSpecValues } =
        props
    const { width, height, inch, loadIndex, speedIndex, manufacturer, manufacturerId, tyreModel, size } = selectedFilters
    const { translateText } = useLocalization()

    const mainSizesSelected = !!(width && height && inch && loadIndex && speedIndex)
    const tyreModelWithSeason: TyreModelsWithSeason[] | undefined = tyresCrits.tyreModel && splitSeasonFromTyreModel(tyresCrits.tyreModel)
    const tyreModelValues = tyresCrits.tyreManufacturer?.some((x) => x.value === manufacturer)
        ? (tyreModelWithSeason?.map((x) => x.modelName) ?? [])
        : []

    const manufacturerFocus = useMemo(() => {
        if (tyreSpecValues.some((value) => value === size) && !manufacturer && !loading) {
            return true
        }

        return !manufacturer && !loading && tyreSpecValues.length <= 1
    }, [tyreSpecValues, size, manufacturer, loading])

    useEffect(() => {
        const additionalSpecs: AdditionalSpecs = {
            manufacturer,
            manufacturerId,
            tyreModel,
        }
        const tempAdditionalSpecs: AdditionalSpecs = { ...additionalSpecs }

        if (tempAdditionalSpecs.manufacturer === "" && tyresCrits.tyreManufacturer?.length === 1) {
            tempAdditionalSpecs.manufacturer = tyresCrits.tyreManufacturer[0].value
            tempAdditionalSpecs.manufacturerId = tyresCrits.tyreManufacturer[0].query
        }

        if (tempAdditionalSpecs.tyreModel === "" && tyresCrits.tyreModel?.length === 1) {
            const modelValue = splitSeasonFromTyreModel(tyresCrits.tyreModel)
            tempAdditionalSpecs.tyreModel = modelValue[0].modelName
        }

        if (mainSizesSelected && !(JSON.stringify(tempAdditionalSpecs) === JSON.stringify(additionalSpecs))) {
            const newFilters = { ...selectedFilters, ...tempAdditionalSpecs }
            setSelectedFiltersTemp(newFilters)
            compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
        }
    }, [tyresCrits.tyreManufacturer])

    const handleFilterSelect = (path: string, filter: string) => {
        if (!enableFreeInputMode(tyresCrits, path, filter)) {
            setFreeInputMode(true)
        }

        switch (path) {
            case ExtendEFilterNames.manufacturer:
                const tyreManufacturerId = tyresCrits.tyreManufacturer?.find((tyreManufacturer) => tyreManufacturer.value === filter)?.query
                const newFilters = { ...selectedFilters, manufacturer: filter, manufacturerId: tyreManufacturerId ?? filter, tyreModel: "" }

                setSelectedFiltersTemp(newFilters)
                compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
                break
            case ExtendEFilterNames.tyreModel:
                let modelSeason

                if (tyreModelWithSeason) {
                    modelSeason = getSeasonFromTyreModel(filter, tyreModelWithSeason)
                }

                setSelectedFiltersTemp({ ...selectedFilters, tyreModel: filter, season: modelSeason ?? "" })
                break
            default:
                break
        }
    }

    if (!previousFieldsCompleted) {
        return null
    }

    return (
        <>
            <Typography marginBottom="1em" variant="h4">
                {translateText(13076)}
            </Typography>
            <StyledBox>
                <DropDown
                    items={tyresCrits.tyreManufacturer?.map((x) => x.value) ?? []}
                    name={translateText(71)}
                    path={ExtendEFilterNames.manufacturer}
                    selectedValue={manufacturer ?? ""}
                    onChange={handleFilterSelect}
                    focusInput={manufacturerFocus}
                    isLoading={freeInputMode ? false : loading}
                    disableDropDown={freeInputMode ? false : loading}
                />
                <DropDown
                    items={tyreModelValues}
                    name={translateText(13077)}
                    path={ExtendEFilterNames.tyreModel}
                    selectedValue={tyreModel ?? ""}
                    onChange={handleFilterSelect}
                    focusInput={!!(manufacturer && !tyreModel) && !loading}
                    isLoading={freeInputMode ? false : loading}
                    disableDropDown={freeInputMode ? false : manufacturer === "" && loading}
                />
            </StyledBox>
        </>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    gap: ".5em",
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(1),
}))
