import { useLocalization } from "@tm/localization"
import { Toolbar, Button } from "@tm/controls"
import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { useModuleNavigation } from "@tm/context-distribution"
import { useLocation } from "react-router-dom"
import { useApplyButtonState } from "../../data/hooks/useApplyButtonState"
import { bundleChannel } from "../../business"

function TransitionsComponent() {
    const { translate } = useLocalization()
    const { dataChanged, valid } = useApplyButtonState()
    const { closeTab } = useModuleNavigation()
    const { pathname, search } = useLocation()

    const handleAttach = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerSave)
        bundleChannel().publish("APPLY_CHANGES", {})

        // Close tab after saving changes
        const currentUrl = `${pathname}${search}`
        closeTab(currentUrl)
    }

    return (
        <Toolbar className="tk-crm transitions">
            <Button id="crm__apply-button" skin="success" size="l" onClick={handleAttach} disabled={!valid}>
                {dataChanged ? translate(52) : translate(22)}
            </Button>
        </Toolbar>
    )
}

export default TransitionsComponent
