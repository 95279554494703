import { Vehicle, VehicleType } from "@tm/models"
import { VehicleOverviewState, VehicleExtended } from "./model"
import { BundleActionType } from "../../../business"

export type ComponentActionType =
    | BundleActionType
    | { type: "VEHICLE_OVERVIEW_LOADED"; payload: Vehicle[] }
    | { type: "VEHICLE_OVERVIEW_DESCRIPTION_UPDATED"; payload: { vehicleId: string; vehicleDescription: string } }

const DEFAULT_STATE: VehicleOverviewState = {
    vehicles: [],
    loading: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): VehicleOverviewState {
    switch (action.type) {
        case "VEHICLE_OVERVIEW_LOADED": {
            const vehicles = action.payload

            state = {
                ...state,
                loading: false,
            }

            if (state.vehicles) {
                const mappedVehicles = vehicles.map((v) => {
                    return {
                        ...v,
                        description: `${v.manufacturer ? v.manufacturer : ""} ${v.modelSeries ? v.modelSeries : ""} ${v.model ? v.model : ""}`,
                    }
                })

                return {
                    ...state,
                    vehicles: mappedVehicles,
                }
            }

            return state
        }
        case "VEHICLE_OVERVIEW_DESCRIPTION_UPDATED": {
            if (state.vehicles) {
                const { vehicleId, vehicleDescription } = action.payload
                let vehicles: VehicleExtended[]
                if (state.vehicles.some((v) => v.id === vehicleId)) {
                    vehicles = state.vehicles.map((v) => {
                        if (v.id === vehicleId) {
                            return {
                                ...v,
                                manufacturer: vehicleDescription,
                                modelSeries: "",
                                model: "",
                            }
                        }
                        return { ...v }
                    })
                } else {
                    vehicles = [
                        ...state.vehicles,
                        {
                            id: vehicleId,
                            manufacturer: vehicleDescription,
                            modelSeries: "",
                            model: "",
                            tecDocManufacturerId: -1,
                            tecDocTypeId: -1,
                            tecDocModelId: -1,
                            vehicleType: VehicleType.CommercialVehicle,
                        },
                    ]
                }
                return {
                    ...state,
                    vehicles,
                }
            }

            return state
        }
        default:
            break
    }
    return state
}
