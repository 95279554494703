import { DRTyre } from "../../models"
import { DriveRightTyresRespone, FrontTyre, RearTyre } from "./model"

type ResponseType = {
    tyres: ResponseTyres
}

type ResponseTyres = {
    frontTyres: FrontTyre[]
    rearTyres: RearTyre[]
}

export function mapDriveRightTyresResponse(response: ResponseType): DriveRightTyresRespone {
    const {
        tyres: { frontTyres, rearTyres },
    } = response
    return {
        frontTyres: (frontTyres && frontTyres.map(mapFrontTyre)) || [],
        rearTyres: (rearTyres && rearTyres.map(mapRearTyre)) || [],
    }
}

export function mapFrontTyre(tyre: FrontTyre): DRTyre {
    return {
        height: tyre.whRelationFront,
        width: tyre.widthFront,
        inch: tyre.diameterFront,
        loadIndex: tyre.loadIndexFront,
        speedindex: tyre.speedIndexFront,
        size: tyre.sizeFront,
        rimSize: tyre.rimSizeFront,
        offsetValue: tyre.rimOffsetFront,
        isSummer: tyre.saisonSummer,
        isWinter: tyre.saisonWinter,
    }
}

export function mapRearTyre(tyre: RearTyre): DRTyre {
    return {
        height: tyre.whRelationRear,
        width: tyre.widthRear,
        inch: tyre.diameterRear,
        loadIndex: tyre.loadIndexRear,
        speedindex: tyre.speedIndexRear,
        size: tyre.sizeRear,
        rimSize: tyre.rimSizeRear,
        offsetValue: tyre.rimOffsetRear,
        isSummer: tyre.saisonSummer,
        isWinter: tyre.saisonWinter,
    }
}
