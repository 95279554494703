import { Text, Icon, SeparatorList, Tag, TagIcon, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    AdditionalReferenceArticleInformation,
    ArticleInfoGroup,
    ArticleInfo,
    EArticleModificationState,
    ArticleReference,
    ArticleReferenceType,
    ModificationState,
} from "@tm/models"
import { concat } from "@tm/utils"

import { Tooltip } from "@tm/components"
import { ReactNode, useMemo } from "react"
import { ReferenceLink } from "./reference-link"
import { getBundleParams } from "../../../utils"

type Props = {
    articleModificationState: EArticleModificationState
    isAddedReferencedArticle: boolean
    hideLinks?: boolean
    information: AdditionalReferenceArticleInformation
    references: Array<ArticleReference>
    onReferenceSelect(reference: ArticleReference): void
}

export default function AdditionalInformationComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const { articleModificationState, information, hideLinks } = props

    const refs = useMemo(
        (): Array<ReactNode> =>
            Object.entries(props.references.filter((x) => x?.modificationState).groupBy((x) => x.referenceType))
                .map(([type, references]) => renderReferenceGroup(parseInt(type), references))
                .filter((x) => x),
        [props.references]
    )

    function renderModificationState() {
        let icon: string | undefined
        let message: ReactNode

        if (articleModificationState === EArticleModificationState.ArticleAdded) {
            icon = "plus"
            message = translate(888)
        } else if (articleModificationState === EArticleModificationState.VehicleLinkageAdded) {
            icon = "plus"
            message = translate(889)
        } else if (props.isAddedReferencedArticle) {
            icon = "plus"
            message = translate(13116)
        } else {
            return
        }

        return (
            <div className="article__information__state icon-wrapper">
                {icon && <Icon name={icon} />}
                <Text size="s">{message}</Text>
            </div>
        )
    }

    function renderInformation(info: ArticleInfo, idx: number) {
        return (
            <Text key={idx} modifiers="block" size="s">
                {info.content || info.description}
            </Text>
        )
    }

    function renderInformationGroup(group: ArticleInfoGroup, idx: number) {
        if (!group.member.length) {
            return
        }

        return (
            <div key={idx} className="article__information__category">
                {!!group.name && <Text className="article__information__category__name">{group.name}</Text>}
                <div className="article__information__category__content">{group.member.map(renderInformation)}</div>
            </div>
        )
    }

    function renderTextInformation() {
        if (!information) {
            return
        }
        return information.textInformation.map(renderInformationGroup)
    }

    function renderLinks() {
        if (!information || hideLinks) {
            return
        }

        const links: Array<ArticleInfo> = ([] as Array<ArticleInfo>).concat.apply(
            [],
            information.links.map((x) => x.member)
        )

        if (!links.length) {
            return
        }

        return (
            <div className="article__links">
                {links.map((link, idx) => (
                    <ReferenceLink link={link} key={idx} />
                ))}
            </div>
        )
    }

    function renderReference(reference: ArticleReference) {
        let className = "article__reference"
        const icons: Array<TagIcon> = []

        switch (reference.modificationState) {
            case ModificationState.Added:
                icons.push({ name: "plus" /* , tooltip: "Vom Teilehändler hinzugefügt" */ })
                className += " article__reference--added"
                break
            case ModificationState.Removed:
                icons.push({ name: "minus" /* , tooltip: "Vom Teilehändler entfernt" */ })
                className += " article__reference--removed"
                break
            default:
                break
        }

        return (
            <Tag
                className={className}
                layout="ghost"
                value={concat(" ", reference.referenceNo, reference.manufacturer && `(${reference.manufacturer})`)}
                icons={icons}
                onClick={() => props.onReferenceSelect(reference)}
            />
        )
    }

    function renderReferenceGroup(referenceType: ArticleReferenceType, references: Array<ArticleReference>) {
        if (!references.length) {
            return
        }

        const label = references[0].description

        return (
            <div className="article__information__reference-group" key={referenceType}>
                {!!label && (
                    <Text className="article__information__reference-group__description" size="s">
                        {label}
                    </Text>
                )}
                <SeparatorList className="article__information__references" items={references} renderItem={renderReference} />
            </div>
        )
    }

    const modificationState = renderModificationState()
    const textInformation = renderTextInformation()
    const links = renderLinks()

    if (!modificationState && !textInformation?.length && !refs.length && !links) {
        return null
    }

    const { imsIcon, showTooltipOnImsIcon } = getBundleParams()

    const showImsImage = () => {
        if (imsIcon) {
            if (showTooltipOnImsIcon) {
                return (
                    <Tooltip title={translateText(912)}>
                        <Image width={18} height={18} url={imsIcon} />
                    </Tooltip>
                )
            }

            return <Image width={18} height={18} url={imsIcon} />
        }

        return <Icon name="trader-icon" />
    }

    return (
        <div className="article__information-group article__information-group--highlight">
            <div className="article__information-group__description icon-wrapper">
                {showImsImage()}
                <Text size="xs"> {translate(526 /* 891 */)}</Text>
            </div>
            <div className="article__information">
                {(modificationState || textInformation) && (
                    <div className="article__information__column">
                        {modificationState}
                        {textInformation}
                    </div>
                )}
                {links && <div className="article__information__column">{links}</div>}
                {!!refs.length && <div className="article__information__references-wrapper">{refs}</div>}
            </div>
        </div>
    )
}
