import { useCallback, useState } from "react"
import { Divider, GlobalNavigationItem, Icon, Menu, MenuItem, Stack } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useHistory, useRouteMatch } from "react-router"
import { useLocalization } from "@tm/localization"
import { Container } from "@tm/nexus"
import { PortalContainer } from "@tm/models"
import { MenuItems } from "./components/MenuItems"
import { AdditionalMenuItem, AdditionalMenuItems } from "./components/AdditionalMenuItems"
import { AdditionalMdmMenuItems } from "./components/AdditionalMdmMenuItems"
import { getBundleParams } from "../../utils"

type Props = {
    url: string
    hideDefaultMenu: boolean
    additionalItems: AdditionalMenuItem[]
    showUserName?: boolean
}

export function UserSettingsMenuButton({ url, additionalItems, hideDefaultMenu, showUserName }: Props) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>()
    const { userContext } = useUser()
    const { translateText } = useLocalization()
    const history = useHistory()
    const match = useRouteMatch<{ url: string; workTaskId?: string }>()
    const { hideLogout } = getBundleParams()
    const showMenu = !hideDefaultMenu || !!additionalItems?.length || !hideLogout

    function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (match.url === url) {
            history.goBack()
            return
        }

        if (!showMenu) {
            history.push(url)
        } else {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = useCallback(() => {
        setAnchorEl(undefined)
    }, [])

    const handleLogout = useCallback(() => {
        const container = Container.getInstance("Portal") as PortalContainer
        container.action("logout")(true, true, false)

        handleClose()
    }, [handleClose])

    return (
        <>
            <GlobalNavigationItem
                tooltip={translateText(141)}
                onIconClick={handleButtonClick}
                className="user-settings"
                active={match.url === url}
                componentType="element"
            >
                <Stack direction="row" gap={0.5}>
                    {showUserName ? (userContext.account?.username ?? "-") : null}
                    <Icon name="user-settings" />
                </Stack>
            </GlobalNavigationItem>

            {showMenu && (
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {!hideDefaultMenu && <MenuItems onClick={handleClose} />}
                    {/* entrys over uiconfig file */}
                    {additionalItems?.length && <AdditionalMenuItems items={additionalItems} onClick={handleClose} />}
                    {/* entrys over the displaysettings at externalModules =>  https://jira.dvse.de/browse/NEXT-25423 and https://jira.dvse.de/browse/MDM-5124 */}
                    <AdditionalMdmMenuItems onClick={handleClose} />
                    {/* make sure to not use a fragment as a child of the menu */}
                    {!hideLogout && <Divider />}
                    {!hideLogout && <MenuItem onClick={handleLogout}>{translateText(367)}</MenuItem>}
                </Menu>
            )}
        </>
    )
}
