import { ChangeEvent, useState } from "react"
import { Box, Button, Dialog, Icon, IconButton, TextField, Typography, LoadingContainer, Link } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { useGetLoginSuccess } from "../../hooks/useGetLoginSuccess"
import { mainActions } from "../../data/state"
import { CredentialInputKeys, DrivemotiveLoginRequest } from "../../data/models"
import { getBundleParams } from "../../utils"

export default function LoginDialog() {
    const user = useUser()
    const { translateText } = useLocalization()
    const [open, setOpen] = useState(true)
    const [credentials, setCredentials] = useState<{ [key in CredentialInputKeys]: string }>({ email: "", password: "" })

    const loginToDrivemotive = useGetLoginSuccess({
        onLoginSuccess() {
            user.setUserSetting("DRIVEMOTIVE_SETTINGS", { Email: credentials.email, Password: credentials.password })
            mainActions.setShowLoginDialog(false)
            mainActions.setIsLoginDone(true)
        },
    })

    const loginDisabled = credentials.email.trim().length === 0 || credentials.password.trim().length === 0 || loginToDrivemotive.isLoading
    const isLoading = !user.userContext || loginToDrivemotive.isLoading
    const [marketingText, marketingUrlPlaceholder] = translateText(13900).split(" - ", 2)
    const { loginMarketingUrl } = getBundleParams()

    function closeDialog() {
        setOpen(false)
    }

    function handleLogin() {
        if (loginDisabled) {
            return
        }

        const request: DrivemotiveLoginRequest = { email: credentials.email, password: credentials.password }
        loginToDrivemotive.mutate(request)
    }

    function handleInputChange(key: CredentialInputKeys, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {
            target: { value },
        } = event

        setCredentials((prev) => ({
            ...prev,
            [key]: value,
        }))
    }

    return (
        <Dialog open={open} fullWidth maxWidth="sm" position="top">
            <LoadingContainer isLoading={isLoading}>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="11px">
                    <Typography variant="h2">{translateText(13517)}</Typography>
                    <Box display="flex" alignItems="center" gap="13px">
                        <IconButton onClick={closeDialog}>
                            <Icon name="close" />
                        </IconButton>
                        <Button color="success" onClick={handleLogin} disabled={loginDisabled}>
                            <Typography variant="h4">{translateText(13110)}</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body2">{translateText(13686)}</Typography>
                    <Box display="flex" flexDirection="column" gap="18px" marginTop="13px" width="70%">
                        <TextField
                            fullWidth
                            size="large"
                            label={translateText(109)}
                            value={credentials.email}
                            onChange={(event) => handleInputChange(CredentialInputKeys.Email, event)}
                        />

                        <TextField
                            fullWidth
                            size="large"
                            label={translateText(187)}
                            value={credentials.password}
                            onChange={(event) => handleInputChange(CredentialInputKeys.Password, event)}
                            type="password"
                        />
                        {loginToDrivemotive.isError && (
                            <Typography variant="body3" color="error">
                                {translateText(12632)}
                            </Typography>
                        )}
                    </Box>
                    <Box display="flex" gap="0.2em" marginTop="13px">
                        <Typography variant="body2">{`${marketingText} - `}</Typography>
                        <Link href={loginMarketingUrl}>{marketingUrlPlaceholder}</Link>
                    </Box>
                </Box>
            </LoadingContainer>
        </Dialog>
    )
}
