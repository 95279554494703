import { Article } from "@tm/models"
import { mergeAndRemoveArticleDuplicates } from "../../../list/business/helpers"

export function mergeArticlesWithErpAlternatives(articles: Article[], replaceErpAlternatives: Article[]): Article[] {
    return mergeAndRemoveArticleDuplicates([...replaceErpAlternatives], articles, undefined, false)
}

export function replaceArticlesQuantityWithQuantityReplacement(articles: Article[], replaceQuantity: number): Article[] {
    return articles.map((article) => {
        return {
            ...article,
            quantity: replaceQuantity,
            initialQuantity: replaceQuantity,
        }
    })
}

export function replaceArticlesQuantityWithSuggestedQuantity(articles: Article[]): Article[] {
    return articles.map((article) => {
        if (!article.suggestedQuantity) {
            return article
        }

        return {
            ...article,
            quantity: article.suggestedQuantity,
            initialQuantity: article.suggestedQuantity,
        }
    })
}
