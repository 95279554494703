import { useLocalization } from "@tm/localization"
import { renderRoute, mapVoucherTypeForUrl } from "@tm/utils"
import { TableCellData, CellContentPosition, TableColumnData } from "@tm/components"
import { VoucherType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { useHistory, useParams } from "react-router"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { CostEstimationVoucher } from "../../../../../data/model"
import { getBundleParams } from "../../../../../utils"
import CostEstimationActions from "./CostEstimationActions"
import { StyledTable } from "../../../../_shared/StyledComponents"
import { VoucherRouteParams } from "../../../../../business"
import CustomerInfo from "../../../../_shared/cost-estimations/CustomerInfo"
import VehicleInfo from "../../../../_shared/VehicleInfo"

type Props = {
    costEstimations: CostEstimationVoucher[]
    selectedIds?: string[]
    shortCountryCode: string
    onSelectCostEstimation(costEstimationId: string): void
    onDeleteCostEstimations(ids: string[]): void
}

export default function CostEstimationsList(props: Props) {
    const { costEstimations, shortCountryCode, selectedIds, onDeleteCostEstimations, onSelectCostEstimation } = props
    const { translateText, currency, date } = useLocalization()
    const params = useParams<VoucherRouteParams>()
    const history = useHistory()

    function handleDetailsClick(voucherId: string) {
        const url = renderRoute(getBundleParams().vouchersDetailsRoute, {
            ...params,
            type: mapVoucherTypeForUrl(VoucherType.CostEstimation),
            id: encodeURIComponent(voucherId),
        })

        if (params.workTaskId) {
            history.push(url)
        } else {
            Morpheus.closeView("1", url)
        }
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(470) }, // voucherNumber
            { header: translateText(98) }, // voucherCreationDate day
            { header: translateText(335) }, // voucherCreationDate time
            { header: translateText(940), alignContent: CellContentPosition.right }, // voucherTotalPrice
            { header: translateText(107) }, // CustomerInfo
            { header: translateText(99) }, // VehicleInfo
            { alignContent: CellContentPosition.right }, // CostEstimationActions
        ]
        return columns
    }

    function getCells(costEstimation: CostEstimationVoucher): TableCellData[] {
        return [
            { displayValue: costEstimation.voucherNumber, id: "1" },
            { displayValue: date(costEstimation.voucherCreationDate, "d"), id: "2" },
            { displayValue: date(costEstimation.voucherCreationDate, "t"), id: "3" },
            { displayValue: currency(costEstimation.voucherTotalPrice, costEstimation.currencyCode || costEstimation.currencySymbol), id: "4" },
            { displayValue: <CustomerInfo costEstimation={costEstimation} showCustomerNumber />, id: "5" },
            {
                displayValue: (
                    <VehicleInfo
                        vehicleDescription={costEstimation.vehicleDescription}
                        showVehicleMilage
                        vehicleMileage={costEstimation.vehicleMileAge}
                        vehiclePlateId={costEstimation.vehiclePlateId}
                        shortCountryCode={shortCountryCode}
                    />
                ),
                id: "6",
            },
            {
                displayValue: (
                    <CostEstimationActions
                        isSelected={selectedIds?.includes(costEstimation.id) || false}
                        costEstimation={costEstimation}
                        onDeleteCostEstimations={onDeleteCostEstimations}
                        onOpenDetails={handleDetailsClick}
                        onSelectCostEstimation={onSelectCostEstimation}
                    />
                ),
                id: "7",
            },
        ]
    }

    const displayData = costEstimations.map((costEstimation) => ({
        cells: getCells(costEstimation),
        id: `${costEstimation.id}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} rowCap={4} />
}
