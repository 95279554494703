import { connectComponent } from "@tm/morpheus"
import { IMicros } from "@tm/models"
import { FC } from "react"
import OePart from "../../components/_shared/oe/oe-article"

type Props = IMicros["parts"]["oe-part-item"]

const OePartItem: FC<Props> = ({ oePart, ...rest }) => {
    return <OePart {...rest} part={oePart} />
}

export default connectComponent({}, OePartItem)
