import { channel, getCurrentWorkTaskId, KeyValueStateContainer, RegisteredModels } from "@tm/models"
import { BundleComponent } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { decodeUniqueId, RouteComponentProps, withRouter } from "@tm/utils"
import { useDispatch } from "react-redux"
import { FC, useEffect } from "react"
import { generateKey, withStoreProvider } from "../../data/helpers"

const EurotaxWorktaskManager: FC<RouteComponentProps<{ workTaskId: string }>> = ({ match: { params } }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        const unsub = channel("WORKTASK", getCurrentWorkTaskId()).subscribe("MODULE/CLOSED", (module) => {
            const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(params.workTaskId ?? "")
            if (module.includes("eurotax") && !module.includes("damage-calculation")) {
                const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
                container.action("deleteKeyValue")(generateKey(undefined, workTaskId))
                dispatch({ type: "EUROTAX_RESET_STORE" })
            }
        })
        return unsub
    }, [])
    return null
}

const transmit = (action: any) => {
    switch (action.type) {
        case "EUROTAX_RESET_STORE":
            return action
    }
}

const managerComponent: BundleComponent = {
    name: "manager",
    component: withStoreProvider(withRouter(EurotaxWorktaskManager)),
    // receive: console.log,
    transmit,
}

export default managerComponent
