import { Box, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { memo, FC } from "react"

type Prop = {
    message?: string
}

export const TyresError: FC<Prop> = memo(({ message }) => {
    const { translateText } = useLocalization()
    return (
        <Box className="article-list__panel article-list__status">
            <Box className="article-list__no-result">
                <Icon name="no-results" size="2.25em" />
                <Box className="headline">{message || translateText(809)}</Box>
                <Box className="block">{translateText(1088)}</Box>
            </Box>
        </Box>
    )
})
