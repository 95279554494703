import { useDefaultArticleItemState } from "@tm/components"
import { Article, ErpInformation, SupplierArticleDto } from "@tm/models"
import { isEqual } from "lodash"
import { useCallback, useMemo, useState } from "react"
import { useSetRecoilState } from "recoil"
import { useWorkTask } from "@tm/context-distribution"
import { isSameArticle } from "@tm/utils"
import {
    useArticleErpInfos,
    useArticleTradeReferences,
    useHandleAddToBasket,
    useHandleAddToCostEstimation,
    useHandleRemoveFromBasket,
    useLoadErpInfos,
} from "../../ListV2/hooks"

import {
    useActiveVehicleDataProvidersContext,
    useErpInfosContext,
    useListOptionsContext,
    useProductGroupRepairTimesDataContext,
} from "../../ListV2/ContextProvider"
import { useBasketQuantitiesContext } from "../../ListV2/ContextProvider/BasketQuantities"
import { isAddButtonDisabled } from "../../ListV2/helpers"
import { RecommendedArticlesChainState } from "../../ListV2/states"

export function useArticleBasketActions(article: Article) {
    const [articleQuantity, setArticleQuantity] = useState(article.quantity ?? 1)
    const [originalQuantity, setOriginalQuantity] = useState(article.quantity ?? 1)
    const erpInfosData = useErpInfosContext()
    const { workTaskId } = useWorkTask() ?? {}

    const tradeReferences = useArticleTradeReferences(article)
    const loadErpInfos = useLoadErpInfos(erpInfosData, tradeReferences)
    const articleErpInfos = useArticleErpInfos(article, articleQuantity, erpInfosData)

    const activeVehicleDataProviders = useActiveVehicleDataProvidersContext()
    const { basketQuantities } = useBasketQuantitiesContext()
    const { externalCatalogUrl } = useListOptionsContext()
    const { productGroupRepairTimes } = useProductGroupRepairTimesDataContext()
    const { updateBasketQuantities } = useBasketQuantitiesContext()
    const repairTimeProviders = productGroupRepairTimes?.productGroups[article.productGroup.id]

    // this is workaround! We need access to the parent article item action to send the callback.
    const crossSelling = useDefaultArticleItemState((x) => x.crossSelling)
    const setArticlesChain = useSetRecoilState(RecommendedArticlesChainState(workTaskId))

    const defaultArticleErpInfoResponse = articleErpInfos.default?.state === "success" ? articleErpInfos.default.response : undefined

    const addToBasket = useHandleAddToBasket(article)
    const removeFromBasket = useHandleRemoveFromBasket()
    const addToKva = useHandleAddToCostEstimation(
        article,
        addToBasket,
        activeVehicleDataProviders.activeProviders,
        externalCatalogUrl,
        repairTimeProviders
    )

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupId: article.productGroup.id,
                } as SupplierArticleDto)
            ),
        [basketQuantities, article]
    )

    const postAddToBasket = useCallback(async () => {
        updateBasketQuantities([article])
    }, [article, updateBasketQuantities])

    const handleAddToBasket = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            await addToBasket(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()

            setArticlesChain((prev) => {
                const isInChain = prev?.some((articleGroup) => articleGroup?.some((item) => isSameArticle(article, item)))

                if (isInChain) {
                    return prev
                }

                const newArticlesChain = [...(prev ?? []), [article]]

                localStorage.setItem(`recommendedArticles/${workTaskId}`, JSON.stringify(newArticlesChain))

                return newArticlesChain
            })

            crossSelling?.loadNextRecommendedArticle?.(article)
        },
        [article, addToBasket, articleQuantity, defaultArticleErpInfoResponse, postAddToBasket]
    )

    const handleAddToCostEstimation = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            await addToKva(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
        },
        [addToKva, articleQuantity, defaultArticleErpInfoResponse, postAddToBasket]
    )

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setArticleQuantity(value)

            if (loadErpInfo) {
                setOriginalQuantity(value)

                if (articleErpInfos.default) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.default.erpSystem })
                }
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [article, loadErpInfos, articleErpInfos]
    )

    const addButtonsDisabled = useMemo(() => isAddButtonDisabled(articleErpInfos), [articleErpInfos])

    return {
        originalQuantity,
        articleQuantity,
        basketQuantity,
        articleErpInfos,
        addButtonsDisabled,
        handleAddToBasket,
        handleAddToCostEstimation,
        handleRemoveFromBasket,
        handleChangeQuantity,
    }
}
