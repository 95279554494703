import {
    ErpInformation,
    HasRepairTimesRequest,
    HasRepairTimesResponse,
    RegisteredModels,
    RepairTimeProvider,
    TyresSeason,
    ETyresCarType,
    TyreFilter,
    GetArticleListByMatchCodeRequest,
    TyreArticle,
    GetArticlesListMappedResponse,
    TyresUsedCriteria,
    EFilterNames,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { equals, getProductGroupsIdsFromArticles, getRepairTimeProviders, getRepairTimeProvidersByNames, getValue } from "@tm/utils"
import { batch } from "react-redux"
import { UniArticles } from "@tm/data"
import { Repositories } from "../../../data"
import { BundleActions, BundleActionTypes } from "../../../data/business"
import { FilterType } from "../../../data/enums"
import { addOrRemoveItem, createBaseQuery } from "../../../data/helpers"
import { TyresCritsResponse } from "../../../data/repositories"
import { Statics } from "../../../data/statics"
import { MainState } from "../../main"
import { MainActions, MainActionsType } from "../../main/business"
import { SummaryState } from "../../summary/business"
import { createArticleListRequest, createAttributeFiltersRequest, createNextArticlesListRequest, filterUsedCriteria } from "./helpers"
import { AvailabilityFilterType, IListFilters, ListState } from "./model"
import { AttributeFiltersResponse } from "../../../data/repositories/model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "TYRES_ARTICLES_LOADING" }
    | { type: "TYRES_ARTICLES_LOADED"; payload: GetArticlesListMappedResponse }
    | { type: "TYRES_ARTICLES_ERROR" }
    | { type: "TYRES_NEXT_ARTICLES_LOADED"; payload: { articles: TyreArticle[]; auto?: boolean; isV2?: boolean } }
    | { type: "TYRES_NEXT_ARTICLES_LOADING"; payload: boolean }
    | { type: "TYRES_NEXT_ARTICLES_ERROR" }
    | { type: "ATTRIBUTE_FILTERS_LOADING" }
    | { type: "ATTRIBUTE_FILTERS_LOADED"; payload: AttributeFiltersResponse }
    | { type: "ATTRIBUTE_FILTERS_ERROR" }
    | { type: "UPDATE_TOP_FILTER"; payload: { path: IListFilters; value?: TyreFilter } }
    | { type: "RESET_TOP_FILTER" }
    | { type: "UPDATE_ATRIBUTE_FILTER"; payload: { path: IListFilters; value: any } }
    | { type: "CHANGE_AVAILABILITY"; payload: AvailabilityFilterType }
    | { type: "RESET_ATRIBUTE_FILTER"; payload: { path: IListFilters } }
    | { type: "RESET_ALL_ATRIBUTE_FILTERS" }
    | { type: "TYRES_TOP_FILTERS_LOADED"; payload: TyresCritsResponse }
    | { type: "TYRES_TOP_FILTERS_LOADING"; payload: string }
    | { type: "TYRES_TOP_FILTERS_ERROR" }
    | { type: "SET_ERP_INFORMATIONS"; payload: ErpInformation[] }
    | { type: "TOGGLE_FILTER_CLIP"; payload: FilterType }
    | { type: "TOGGLE_FILTER_OPEN"; payload: FilterType }
    | { type: "SET_CLIPPED_FILTERS"; payload: FilterType }
    | { type: "UPDATE_USED_PRODGROUPS" }
    | { type: "SET_LAST_SEARCH"; payload: { summaryState: SummaryState; byMatchCode: boolean } }
    | { type: "SET_PRODUCTGROUP_REPAIRTIMES"; payload: { [key: number]: Array<RepairTimeProvider> } }
    | { type: "UPDATE_USED_CRITERIA"; payload: { loadIndex: TyreFilter[] | undefined; speedIndex: TyreFilter[] | undefined } | undefined }
    | { type: "SET_REQUEST"; payload: { request: GetArticleListByMatchCodeRequest } }
    | { type: "SET_DEFAULT_QUANTITY"; payload: { defaultQuantity: number } }
    | { type: "INIT_SEASONS" }

const DEFAULT_STATE: ListState = {
    clippedFilters: FilterType.None,
    searchValue: "",
    initialized: false,
    seasonInitialized: false,
    filters: {
        season: Statics.seasons, //  on left filters
        externalRolling: [],
        oeIdentifier: [],
        fuelEfficiency: [],
        speedIndex: [],
        weight: [],
        wetGripClass: [],
        loadIndex: [],
        manufacturer: [],
        extras: [],
        // TODO check extra unused keys
        // silent: [],
        // studdable: [],
        // studed: []
    },
    selectedFilters: {
        availability: AvailabilityFilterType.None,
    },
    articles: {
        data: [],
        pageIndex: 1,
        count: 0,
        loadingNextItems: false,
        defaultQuantity: 4,
        autoNextCount: 0,
    },
    lastSearch: {},
    repairTimeAvailabilities: [],
}

export function reduce(state = { ...DEFAULT_STATE }, action: MainActionsType): ListState {
    switch (action.type) {
        case "SET_LAST_SEARCH": {
            const { summaryState, byMatchCode } = action.payload
            return {
                ...state,
                lastSearch: {
                    ...state.lastSearch,
                    byMatchcode: byMatchCode,
                    ...(!byMatchCode && {
                        carType: summaryState.selectedFilters.carType.map((x) => +x.query as ETyresCarType),
                        seasons: summaryState.selectedFilters.season?.map((season) => season.query as TyresSeason),
                        size: createBaseQuery(
                            summaryState.selectedFilters.width?.value,
                            summaryState.selectedFilters.height?.value,
                            summaryState.selectedFilters.inch?.value
                        ),
                    }),
                    ...(byMatchCode && {
                        carType: Statics.getCarTypes().map((x) => +x.query as ETyresCarType),
                        seasons: undefined,
                        size: state.searchValue,
                    }),
                },
            }
        }
        case "TYRES_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loading: true,
                    data: [],
                    error: false,
                    count: 0,
                    autoNextCount: 0,
                    pageIndex: 1,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    nextArticlesError: false,
                },
                filters: {
                    ...state.filters,
                    leftLoading: true,
                },
            }
        }

        case "TYRES_NEXT_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    ...((action.payload && {
                        loadingAutoItems: true,
                    }) || {
                        loadingNextItems: true,
                    }),
                    autoNextCount: (action.payload && state.articles.autoNextCount + 1) || 0,
                },
            }
        }

        case "TYRES_NEXT_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    nextArticlesError: true,
                },
            }
        }

        case "RESET_ALL_ATRIBUTE_FILTERS": {
            return {
                ...state,
                selectedFilters: {
                    ...DEFAULT_STATE.selectedFilters,
                },
            }
        }

        case "UPDATE_ATRIBUTE_FILTER": {
            const { path, value } = action.payload

            const oldStoredValues = getValue(state.selectedFilters, [path])
            let newValues = equals(value, oldStoredValues) ? undefined : value
            if (Statics.multiSelectionFilters.includes(path)) {
                // if is multiSelection
                newValues = addOrRemoveItem(
                    oldStoredValues,
                    value,
                    (i1: TyreFilter, i2: TyreFilter) => i1.query === i2.query && i1.group === i2.group
                )
            }

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: newValues,
                },
            }
        }

        case "RESET_ATRIBUTE_FILTER": {
            const { path } = action.payload
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: (Statics.multiSelectionFilters.includes(path) && []) || undefined,
                },
            }
        }

        case "CHANGE_AVAILABILITY": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    autoNextCount: 0,
                },
                selectedFilters: {
                    ...state.selectedFilters,
                    availability: action.payload,
                },
            }
        }

        case "TYRES_ARTICLES_LOADED": {
            const { usedCriteria, uniArticles, articleListCount, usedQuery } = action.payload
            const { loadIndex, speedIndex, season, oeIdentifier } = usedCriteria

            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: uniArticles,
                    error: false,
                    count: articleListCount ?? Number.MAX_VALUE,
                    pageIndex: 1,
                    loading: false,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                },
                selectedFilters: {
                    ...state.selectedFilters,
                    ...(loadIndex?.length && { loadIndex }),
                    ...(speedIndex?.length && { speedIndex }),
                    ...(oeIdentifier?.length && { oeIdentifier }),
                    season: season?.length === Statics.seasons.length ? [] : season,
                },
                ...(usedQuery && {
                    searchValue: usedQuery,
                }),
            }
        }
        case "TYRES_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loading: false,
                    data: [],
                    error: true,
                    count: 0,
                    pageIndex: 1,
                    loadingNextItems: false,
                },
                filters: {
                    ...state.filters,
                    leftLoading: false,
                },
            }
        }
        case "INIT": {
            const { size } = action.payload.matchParams

            return {
                ...state,
                ...(size && {
                    searchValue: size,
                }),
            }
        }

        case "ATTRIBUTE_FILTERS_LOADING": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    leftLoading: true,
                },
            }
        }

        case "ATTRIBUTE_FILTERS_LOADED": {
            const { externalRolling, oeIdentifier, fuelEfficiency, speedIndex, weight, wetGripClass, loadIndex, manufacturer, extras } =
                action.payload

            return {
                ...state,
                filters: {
                    ...state.filters,
                    leftLoading: false,
                    externalRolling,
                    oeIdentifier,
                    fuelEfficiency,
                    speedIndex,
                    weight,
                    wetGripClass,
                    loadIndex,
                    manufacturer,
                    extras,
                },
            }
        }
        case "ATTRIBUTE_FILTERS_ERROR": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    leftLoading: false,
                    externalRolling: [],
                    oeIdentifier: [],
                    fuelEfficiency: [],
                    speedIndex: [],
                    weight: [],
                    wetGripClass: [],
                    loadIndex: [],
                    manufacturer: [],
                    extras: [],
                },
            }
        }
        case "UPDATE_SEARCH_VALUE": {
            return {
                ...state,
                searchValue: action.payload,
            }
        }
        case "TYRES_NEXT_ARTICLES_LOADED": {
            const { articles, isV2 } = action.payload
            const newData = [...state.articles.data, ...articles]
            return {
                ...state,
                articles: {
                    ...state.articles,
                    ...(isV2 && {
                        loadingAutoItems: false,
                    }),
                    loadingNextItems: false,
                    loading: false,
                    data: newData,
                    pageIndex: state.articles.pageIndex + 1,
                },
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            const { article, quantity } = action.payload
            const tyres = state.articles.data.map((tyre) => ({
                ...tyre,
                ...((tyre.id === article.id || tyre.id === article.internalId.toString()) && { quantity: quantity || article.quantity }),
            }))

            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: tyres,
                },
            }
        }
        case "TOGGLE_FILTER_CLIP": {
            return {
                ...state,
                clippedFilters: state.clippedFilters ^ action.payload,
            }
        }
        case "SET_CLIPPED_FILTERS": {
            return {
                ...state,
                clippedFilters: action.payload,
                clipedFiltersLoaded: true,
            }
        }
        case "UPDATE_USED_PRODGROUPS": {
            return {
                ...state,
                lastSearch: {
                    ...state.lastSearch,
                    seasons: state.selectedFilters.season?.map((x) => x.query as TyresSeason),
                    carType: state.lastSearch.carType ?? Statics.getCarTypes().map((x) => +x.query as ETyresCarType),
                },
            }
        }
        case "SET_ERP_INFORMATIONS": {
            const data = state.articles.data.map((x) => {
                if (x.erpInformation) {
                    return x
                }

                const erpInfo = x.erpInformation ?? action.payload.find((erp) => x.id === erp.itemId)
                return {
                    ...x,
                    erpInformation: erpInfo,
                }
            })
            return {
                ...state,
                // erpInformations,
                articles: {
                    ...state.articles,
                    loading: false,
                    loadingAutoItems: false,
                    data: [...data],
                },
            }
        }

        case "SET_PRODUCTGROUP_REPAIRTIMES": {
            return {
                ...state,
                repairTimeAvailabilities: {
                    ...state.repairTimeAvailabilities,
                    ...action.payload,
                },
            }
        }

        case "UPDATE_USED_CRITERIA": {
            if (!action.payload) {
                return state
            }

            const { loadIndex, speedIndex } = action.payload

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    ...(loadIndex?.length && { loadIndex }),
                    ...(speedIndex?.length && { speedIndex }),
                },
            }
        }

        case "SET_REQUEST": {
            const { request } = action.payload

            return {
                ...state,
                request,
            }
        }
        case "SET_DEFAULT_QUANTITY": {
            const { defaultQuantity } = action.payload

            return {
                ...state,
                articles: {
                    ...state.articles,
                    defaultQuantity,
                },
            }
        }
        case "INIT_SEASONS": {
            return {
                ...state,
                seasonInitialized: true,
            }
        }
        default:
            return state
    }
}

function loadTyresList(
    byMatchCode?: boolean,
    fromFilters?: boolean,
    summaryFilter?: boolean,
    searchFromCoCData?: boolean
): AsyncAction<MainActionsType, MainState> {
    return (dispatch, getState) => {
        if (!fromFilters) {
            dispatch({ type: "SET_LAST_SEARCH", payload: { summaryState: getState().summary, byMatchCode: !!byMatchCode } })
            dispatch(resetAllAttributeFilters())
        }

        const state = getState()
        const { defaultQuantity } = state.list.articles

        if (byMatchCode === undefined && fromFilters === false) {
            byMatchCode = state.list.lastSearch.byMatchcode
        }

        const request = createArticleListRequest(state, byMatchCode, fromFilters, summaryFilter, searchFromCoCData)
        if (!request) {
            return
        }

        if (!state.list.seasonInitialized) {
            dispatch({ type: "INIT_SEASONS" })
        }

        batch(() => {
            dispatch({ type: "SET_REQUEST", payload: { request } })
            dispatch({ type: "TYRES_ARTICLES_LOADING" })
        })

        UniArticles.getArticleListByMatchCode(request, defaultQuantity).then(
            (response) =>
                batch(() => {
                    dispatch({ type: "TYRES_ARTICLES_LOADED", payload: response })

                    dispatch(loadProductGroupRepairTimes(response.uniArticles))

                    if (response.usedCriteria.height || response.usedCriteria.inch || response.usedCriteria.width) {
                        dispatch(MainActions.loadSummaryFilters())
                    }

                    dispatch(loadAttributesFilters(response.usedCriteria, response.articleListCount, response.searchLevel))
                }),
            () =>
                batch(() => {
                    dispatch({ type: "TYRES_ARTICLES_ERROR" })
                    dispatch(MainActions.loadSummaryFilters())

                    if (!fromFilters) {
                        dispatch({ type: "ATTRIBUTE_FILTERS_ERROR" })
                    }
                })
        )
    }
}

function setDefaultQuantity(defaultQuantity?: number): AsyncAction<MainActionsType, MainState> {
    return (dispatch) => {
        defaultQuantity && dispatch({ type: "SET_DEFAULT_QUANTITY", payload: { defaultQuantity } })
    }
}

function loadNextTyresList(auto?: boolean, isV2?: boolean): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const state = getState()
        const { defaultQuantity } = state.list.articles

        const request = createNextArticlesListRequest(state.list)
        if (!request || state.list.articles.loadingNextItems || state.list.articles.loadingAutoItems) {
            return
        }

        dispatch({ type: "SET_REQUEST", payload: { request } })

        dispatch({ type: "TYRES_NEXT_ARTICLES_LOADING", payload: !!auto })

        return UniArticles.getArticleListByMatchCode(request, defaultQuantity).then(
            (response) => dispatch({ type: "TYRES_NEXT_ARTICLES_LOADED", payload: { articles: response.uniArticles, auto, isV2 } }),
            () => dispatch({ type: "TYRES_NEXT_ARTICLES_ERROR" })
        )
    }
}

function loadProductGroupRepairTimes(articles: Array<TyreArticle>): AsyncAction<MainActionsType, MainState> {
    return (dispatch, getState) => {
        const {
            manager: { vehicle },
        } = getState()
        const { repairTimeProviderIds } = getRepairTimeProviders()

        const productGroupIds = getProductGroupsIdsFromArticles(articles)

        const providers = getRepairTimeProvidersByNames(repairTimeProviderIds)

        if (!vehicle || !providers.length || !productGroupIds.length) {
            return
        }

        const request: HasRepairTimesRequest = {
            repairTimeProvider: providers,
            modelId: vehicle.tecDocTypeId,
            productGroupIds,
            vehicleType: vehicle.vehicleType,
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then((response) => {
                if (response) {
                    dispatch({ type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: response.productGroups })
                }
            })
    }
}

function loadAttributesFilters(
    usedCriteria?: TyresUsedCriteria | undefined,
    articleListCount?: number,
    searchLevel?: number
): AsyncAction<MainActionsType, MainState> {
    return (dispatch, getState) => {
        const request = createAttributeFiltersRequest(getState(), articleListCount)
        if (!request) {
            return
        }
        dispatch({ type: "ATTRIBUTE_FILTERS_LOADING" })

        Repositories.getAttributeFilters(request).then(
            (response) =>
                batch(() => {
                    dispatch({ type: "ATTRIBUTE_FILTERS_LOADED", payload: response })

                    // workaround : exclude from selectedFilters the loadIndexes and speedIndexes that are not in the filters response
                    dispatch({ type: "UPDATE_USED_CRITERIA", payload: filterUsedCriteria(response, usedCriteria) })
                }),
            () => dispatch({ type: "ATTRIBUTE_FILTERS_ERROR" })
        )
    }
}

function updateAttributeFilter(path: IListFilters, value: TyreFilter): MainActionsType {
    return { type: "UPDATE_ATRIBUTE_FILTER", payload: { path, value } }
}

function resetAttributeFilter(path: IListFilters): MainActionsType {
    return { type: "RESET_ATRIBUTE_FILTER", payload: { path } }
}

function resetAllAttributeFilters(): MainActionsType {
    return { type: "RESET_ALL_ATRIBUTE_FILTERS" }
}

const updateSearchValue = (value: string): MainActionsType => ({ type: "UPDATE_SEARCH_VALUE", payload: value })

function changeAvailabilityFilter(value: AvailabilityFilterType): MainActionsType {
    return { type: "CHANGE_AVAILABILITY", payload: value }
}

function setErpInformations(erpInfo: ErpInformation[]): MainActionsType {
    return { type: "SET_ERP_INFORMATIONS", payload: erpInfo }
}

function toggleFilterClip(filterType: FilterType): ComponentActionType {
    return { type: "TOGGLE_FILTER_CLIP", payload: filterType }
}

function setFiltersClip(filterType: FilterType): ComponentActionType {
    return { type: "SET_CLIPPED_FILTERS", payload: filterType }
}

const updateUsedProdGroups = (): ComponentActionType => ({ type: "UPDATE_USED_PRODGROUPS" })

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    updateAttributeFilter,
    resetAttributeFilter,
    loadTyresList,
    setDefaultQuantity,
    loadNextTyresList,
    resetAllAttributeFilters,
    changeAvailabilityFilter,
    setErpInformations,
    updateSearchValue,
    toggleFilterClip,
    setFiltersClip,
    updateUsedProdGroups,
}
