import { SubTitle } from "@tm/controls"
import { FC, memo, PropsWithChildren, ReactNode } from "react"

type Props = PropsWithChildren<{
    size?: "small" | "medium" | "large" | "fill"
    clickable?: boolean
    subtitle?: ReactNode
    bemModifier?: string
    title?: string
    onClick?: () => void
}>

export const Detail: FC<Props> = memo((props) => {
    const { size, subtitle, bemModifier, title, onClick, clickable } = props

    let className = "detail "
    if (size && size !== "medium") {
        className += `detail--${size} `
    }
    if (bemModifier) {
        className += `detail--${bemModifier} `
    }
    if (clickable && onClick) {
        className += "is-clickable "
    }

    return (
        <div className={className} onClick={onClick} title={title}>
            {!!subtitle && <SubTitle size="xs">{subtitle}</SubTitle>}
            {props.children}
        </div>
    )
})
