import { ajaxAuth, RequestMethod } from "@tm/utils"

export type PagedRequest = {
    pageIndex?: number
    pageSize?: number
}

export type PagedResponse = {
    moreResultsLeft?: boolean
}

export async function callService<TReq, TRes>(url: string, request: TReq, method: RequestMethod = "GET") {
    try {
        return await ajaxAuth<TRes>(
            {
                method,
                url,
                body: request,
            },
            undefined,
            undefined,
            true
        )
    } catch {}
}
