import { useStyle, useUser, WithUserSettingsProps } from "@tm/context-distribution"
import { IFrame } from "@tm/controls"
import { classes, decodeUniqueId, RouteComponentProps, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { percent } from "csx"
import { FC, useEffect, useRef, useState } from "react"
import { WrapperFrame } from "../_shared/wrapperFrame"
import { LoginRequest } from "../../data"
import { buildIframeUrl, getCatalogNumber } from "../../data/helpers/common"
import { getWorktaskId } from "../../data/helpers"

type RouteProps = {
    workTaskId: string
}

type Props = RouteComponentProps<RouteProps> &
    WithUserSettingsProps & {
        id: string
        loginUrl: string
        loginRequest: LoginRequest
        className?: string
        platformId?: number
    }

const DatRegistrationFrame: FC<Props> = ({ id, loginRequest, loginUrl, match: { params }, className, platformId }) => {
    const { languageId } = useLocalization()
    const workTaskId = getWorktaskId() ?? decodeUniqueId(params.workTaskId ?? "FAKE_WORKTASK")
    const user = useUser()
    const userContext = user?.userContext
    const userSettings = user?.userSettings

    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState("")
    const mounted = useRef(false)

    const buildRequestParameters = () => {
        const catNr = getCatalogNumber(userContext)
        let request: LoginRequest = {
            ...loginRequest,
            platformId,
            languageId,
            mdmSessionId: userContext?.id,
            catNr,
            clientId: catNr,
        }

        if (userSettings?.repairShop) {
            const { repairShop } = userSettings

            request = {
                ...request,
                name: repairShop.companyName,
                name2: repairShop.contactPerson?.firstName,
                name3: repairShop.contactPerson?.lastName,
                address: repairShop.postalAddress?.street,
                postCode: repairShop.postalAddress?.zip,
                city: repairShop.postalAddress?.city,
                country: repairShop.postalAddress?.country,
                phone: repairShop.contactInfo?.phone?.replace(/\s+/g, ""),
                email: repairShop.contactInfo?.email,
            }
        }

        return request
    }

    const handleLoadingCallback = () => {
        mounted.current && setLoading(false)
    }

    useEffect(() => {
        if (workTaskId && userContext?.id && userSettings) {
            const url = buildIframeUrl(loginUrl, buildRequestParameters())
            setLoading(true)
            setUrl(url)
        }
    }, [userContext?.id, userSettings, workTaskId])

    useEffect(() => {
        mounted.current = true

        return () => {
            setUrl("")
            document.getElementById(`iframe_${id}`)?.remove()
            mounted.current = false
        }
    }, [])

    return (
        <WrapperFrame loading={loading}>
            {url && (
                <IFrame
                    url={url}
                    className={classes(style.wrapper, className, loading ? style.hide : "")}
                    contextDependent
                    id={`iframe_${id}`}
                    onLoad={handleLoadingCallback}
                    refreshOnUrlChanged
                />
            )}
        </WrapperFrame>
    )
}

const style = useStyle({
    hide: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        height: percent(100),
        width: percent(100),
    },
})(DatRegistrationFrame)

export default withRouter(DatRegistrationFrame)
