import { VehicleType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { withRouter, RouteComponentProps, renderRoute } from "@tm/utils"
import { filledInputClasses, MenuItem, Select, SelectChangeEvent, styled } from "@tm/components"
import { SelectionsState } from "../business"

type Props = RouteComponentProps<{ vehicleType: string }> & {
    state: SelectionsState
}

const StyledSelect = styled(Select)(({ theme }) => {
    return {
        [`.${filledInputClasses.input}`]: {
            fontSize: "16px",
            lineHeight: "32px !important",
            padding: "16px 58px 16px 38px !important",
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
        },
        ":hover": {
            [`.${filledInputClasses.input}`]: {
                background: theme.colors?.primary,
            },
        },
        "&.Mui-focused": {
            [`.${filledInputClasses.input}`]: {
                borderTopLeftRadius: theme.radius?.default || "3px",
                borderBottomLeftRadius: theme.radius?.default || "3px",
            },
        },
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: theme.radius?.default || "3px",
        borderBottomLeftRadius: theme.radius?.default || "3px",
    }
})

function VehicleTypeSwitch(props: Props) {
    const vehicleType: VehicleType = parseInt(props.match.params.vehicleType)
    const vehicleTypes: Array<{ vehicleType: VehicleType }> = []
    if (props.state.referencedVehicleCount) {
        vehicleTypes.push({ vehicleType: VehicleType.PassengerCar })
    }
    if (props.state.referencedBikesCount) {
        vehicleTypes.push({ vehicleType: VehicleType.Motorcycle })
    }
    if (props.state.referencedTrucksCount) {
        vehicleTypes.push({ vehicleType: VehicleType.CommercialVehicle })
    }

    if (vehicleTypes.length <= 1) {
        return null
    }

    function handleVehicleTypeChange(e: SelectChangeEvent<unknown>) {
        const newType = e.target.value as VehicleType
        if (newType !== vehicleType) {
            props.history.push(
                renderRoute(props.match.path, {
                    ...props.match.params,
                    vehicleType: newType,
                    manufacturerId: undefined,
                    mainModelSeriesId: undefined,
                    modelSeriesId: undefined,
                    modelId: undefined,
                }) + (props.location.search || "")
            )
        }
    }

    return (
        <StyledSelect onChange={handleVehicleTypeChange} defaultValue={vehicleType} disableUnderline variant="filled" iconOnTheRight color="primary">
            {vehicleTypes.map((vehicle) => {
                return (
                    <MenuItem key={vehicle.vehicleType} value={vehicle.vehicleType}>
                        <DropDownItemView vehicleType={vehicle.vehicleType} />
                    </MenuItem>
                )
            })}
        </StyledSelect>
    )
}

function DropDownItemView({ vehicleType }: { vehicleType: VehicleType }) {
    const { translateText } = useLocalization()
    switch (vehicleType) {
        case VehicleType.PassengerCar:
            return <>{translateText(1234)}</>
        case VehicleType.Motorcycle:
            return <>{translateText(730)}</>
        case VehicleType.CommercialVehicle:
            return <>{translateText(1915)}</>
        default:
            return null
    }
}

export default withRouter(VehicleTypeSwitch)
