import { Button, Icon, OAuthButton } from "@tm/components"
import { useUser, useCostEstimationModuleParameters } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"

type Props = {
    disabled: boolean | "import" | "export"
    onImport(): void
    onExport(): void
}

export function ImportExportButtons({ disabled, onImport, onExport }: Props) {
    const { translate } = useLocalization()
    const { userContext, updateExternalAuthenticationTokens } = useUser()
    const { showImportFromExternalBasket, showExportToExternalBasket, requiredAuthModeForExternalBasket } = useCostEstimationModuleParameters()
    const requiresAuthorization =
        requiredAuthModeForExternalBasket &&
        !Object.keys(userContext.externalAuthenticationTokens).some((tokenId) => tokenId.includes(requiredAuthModeForExternalBasket))

    function handleAuthFinished(status: "success" | "error" | "abort") {
        // Also reload tokens on "abort" because sometimes the feedback from the OAuth popup isn't working correctly
        if (status === "success" || status === "abort") {
            updateExternalAuthenticationTokens()
        }
    }

    if (!showImportFromExternalBasket && !showExportToExternalBasket) {
        return null
    }

    if (requiresAuthorization) {
        return (
            <OAuthButton tokenId={requiredAuthModeForExternalBasket} onAuthorizationFinished={handleAuthFinished}>
                {translate(13870)}
            </OAuthButton>
        )
    }

    return (
        <>
            {showImportFromExternalBasket && (
                <Button disabled={disabled === true || disabled === "import"} onClick={onImport}>
                    {translate(13868)}
                </Button>
            )}
            {showExportToExternalBasket && (
                <Button disabled={disabled === true || disabled === "export"} color="highlight" endIcon={<Icon name="orders" />} onClick={onExport}>
                    {translate(13867)}
                </Button>
            )}
        </>
    )
}
