import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router"
import { ArticleAttribute, ReplaceButtonBundle, FittingPosition, ListFilter, OE, ECounterType } from "@tm/models"
import { TmaHelper, equals } from "@tm/utils"
import { useRecoilState } from "recoil"
import { useArticleListSorting } from "../../../hooks/useArticleListSorting"
import { OePartsStartParams, OePartsListParams } from "../../../models"
import { getOePositionsFromCache, mapArticleAttribute } from "../../../helpers"
import { AttributeFiltersAtom } from "../../../states"
import { useFilterStorageKey } from "../../../hooks/useFilterStorageKey"

type AttributeChangeHandlerType = (attribute: ArticleAttribute, selected: boolean) => void

function getStartParamsFromUrl(urlSearch: string): OePartsStartParams {
    const searchParams = new URLSearchParams(urlSearch)
    const oePositionsCacheId = searchParams.get("oePositionsCacheId") ?? undefined
    const replaceButtonBundle = searchParams.get("replaceButtonMicro") as ReplaceButtonBundle | null
    const extendedAssortment = searchParams.get("extendedAssortment") ?? undefined
    const disableExtendedAssortment = searchParams.get("disableExtendedAssortment") ?? undefined

    if (oePositionsCacheId) {
        return {
            type: "oe",
            oePositionsCacheId,
            replaceButtonBundle,
            extendedAssortment,
            disableExtendedAssortment,
        }
    }
    return {
        type: "default",
        replaceButtonBundle,
    }
}

/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useListParams(): OePartsListParams {
    const location = useLocation()
    const sorting = useArticleListSorting()
    const { clear: clearSorting } = sorting

    const [startParams, setStartParams] = useState<OePartsStartParams>(() => getStartParamsFromUrl(location.search))

    const storageKey = useFilterStorageKey(startParams)
    const [attributeFilters, setAttributeFilters] = useRecoilState(AttributeFiltersAtom(storageKey))

    const [productGroups, setProductGroups] = useState<ListFilter[]>([])
    const [suppliers, setSuppliers] = useState<ListFilter[]>([])
    const [extendedAssortment, setExtendedAssortment] = useState(false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [showAvailableSecondary, setShowAvailableSecondary] = useState(false)
    const attributeChangeHandler = useRef<AttributeChangeHandlerType[]>([])
    const [pageIndex, setPageIndex] = useState(0)
    const [noResult, setNoResult] = useState(false)
    const [isFiltersLoading, setIsFiltersLoading] = useState(false)
    const [fittingPosition, setFittingPosition] = useState<FittingPosition>(FittingPosition.None)

    const [oePositions, setOePositions] = useState<OE.OePosition[]>(() => {
        if (startParams.type === "oe") {
            return getOePositionsFromCache(startParams.oePositionsCacheId)
        }

        return []
    })

    const { selectedOePart, selectedOeNumber } = useMemo(() => {
        const selectedPosition = oePositions.find((x) => x.isSelected)
        if (!selectedPosition) {
            return {
                selectedOePart: undefined,
                selectedPosition: undefined,
            }
        }

        TmaHelper.ArticleListFiltered.ArticleListFiltered.List.OePositionChanged(selectedPosition.number)

        const selectedReplacement = selectedPosition.replacements?.find((x) => x.isSelected)
        const selectedOeReplacementPart = selectedReplacement?.parts?.first() || selectedPosition.parts?.first()

        return {
            selectedOePart: selectedOeReplacementPart,
            selectedOeNumber: selectedPosition,
        }
    }, [oePositions])

    useEffect(
        function resetOnStartParamsChanged() {
            const newStartParams = getStartParamsFromUrl(location.search)

            if (!equals(newStartParams, startParams)) {
                setStartParams(newStartParams)
                setProductGroups((prev) => (prev.length ? [] : prev))
                setSuppliers((prev) => (prev.length ? [] : prev))
                setExtendedAssortment(false)
                clearSorting()
            }
        },
        [location, startParams, clearSorting]
    )

    const toggleProductGroup = useCallback((filter: ListFilter) => {
        setProductGroups((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleSupplier = useCallback((filter: ListFilter) => {
        setSuppliers((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleAttribute = useCallback((attribute: ArticleAttribute) => {
        setAttributeFilters((state) => {
            const attrFilter = mapArticleAttribute(attribute)
            const existingIndex = state.findIndex((attr) => attr.query === attrFilter.query)
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListInlineFilter, !!existingIndex)

            if (existingIndex === -1) {
                attributeChangeHandler.current.forEach((x) => x(attribute, true))
                return [...state, attrFilter]
            }

            attributeChangeHandler.current.forEach((x) => x(attribute, false))

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const changeOePosition = useCallback((position: OE.OePosition, replacement?: OE.OeNumber) => {
        setOePositions((prev) => {
            if (prev.some((pos) => pos.isSelected && pos === position && pos.replacements?.some((repl) => repl.isSelected && replacement === repl))) {
                return prev
            }
            return prev.map((pos) => {
                if (pos.number === position.number && pos.description === position.description) {
                    return {
                        ...pos,
                        isSelected: true,
                        replacements: pos.replacements?.map((repl) => ({
                            ...repl,
                            isSelected: replacement && repl.number === replacement.number,
                        })),
                    }
                }
                return { ...pos, isSelected: false, replacements: pos.replacements?.map((repl) => ({ ...repl, isSelected: false })) }
            })
        })
    }, [])

    const handleChangeExtendedAssortment = useCallback((checked: boolean) => setExtendedAssortment(checked), [])
    const setAvailability = useCallback((checked: boolean) => setShowAvailable(checked), [])
    const setAvailabilitySecondary = useCallback((checked: boolean) => setShowAvailableSecondary(checked), [])

    const nextPage = useCallback(() => {
        setPageIndex((index) => index + 1)
    }, [])

    return {
        listType: "oe",
        startParams,
        pageIndex,
        productGroups,
        suppliers,
        attributes: attributeFilters,
        fittingPosition,
        extendedAssortment,
        showAvailable,
        showAvailableSecondary,
        noResult,
        isFiltersLoading,
        oePositions,
        selectedOePart,
        selectedOeNumber,
        changeOePosition,
        setProductGroups,
        setSuppliers,
        setAttributes: setAttributeFilters,
        toggleProductGroup,
        toggleSupplier,
        toggleAttribute,
        setExtendedAssortment: handleChangeExtendedAssortment,
        setAvailability,
        setAvailabilitySecondary,
        setNoResult,
        setIsFiltersLoading,
        setFittingPosition,
        nextPage,
        sorting,
    }
}
