import { Dialog } from "@tm/components"
import { useState } from "react"
import { AddCustomArticleItemsRequest, CostEstimationOptions, SelectedPriceTypeMode, VatRate } from "@tm/models"
import { useLocalization } from "@tm/localization"
import CustomPartDialogFields from "./CustomPartDialogFields"
import EditButton from "../../../../_shared/EditButton"
import { BasketPart, BasicBasketPartInfo } from "../../../../../models"
import { addCustomArticleItems } from "../../../../../data/repositories/custom-items/articles"

type Props = {
    part: BasketPart
    disabled?: boolean
    currencyCode: string
    hidePurchasePrice?: boolean
    costEstimationOptions?: CostEstimationOptions
    onEditPart?(part: BasketPart, editBasketPartInfo: BasicBasketPartInfo): void
}

export function CustomPartDialogTrigger({ disabled, part, currencyCode, hidePurchasePrice, costEstimationOptions, onEditPart }: Props) {
    const { priceTypeMode, priceVatMode } = costEstimationOptions ?? {}
    const { translateText } = useLocalization()
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false)

    function handleEditPart(save: boolean, editBasketPartInfo: BasicBasketPartInfo, category?: number) {
        const { description: customPartDescription, partNumber: customPartNumber, vatRate, regularPrice } = editBasketPartInfo
        onEditPart?.(part, editBasketPartInfo)
        if (save && customPartDescription) {
            const request: AddCustomArticleItemsRequest = {
                customArticleItemsToAdd: [
                    {
                        articleNumber: customPartNumber,
                        description: customPartDescription,
                        vatRate,
                        category,
                        purchasePrice: priceTypeMode === SelectedPriceTypeMode.Purchase ? regularPrice : undefined,
                        retailPrice: !priceTypeMode ? regularPrice : undefined,
                        isDefaultForCategory: false,
                    },
                ],
                priceVatMode,
            }
            addCustomArticleItems(request)
        }
    }

    return (
        <>
            <EditButton onClick={() => setShowEditDialog(true)} disabled={disabled} />
            <Dialog open={showEditDialog} position="middle" fullWidth maxWidth="lg">
                <CustomPartDialogFields
                    part={part}
                    defaultCurrencyCode={currencyCode}
                    titleText={translateText(757)}
                    hidePurchasePrice={hidePurchasePrice}
                    onClose={() => setShowEditDialog(false)}
                    onEditPart={handleEditPart}
                />
            </Dialog>
        </>
    )
}
