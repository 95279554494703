import { Box } from "@tm/components"
import { Route, Switch } from "react-router"
import Morpheus from "@tm/morpheus"
import { PartsBundleParams } from "@tm/models"
import { ArticleListConfigurationProvider } from "./ArticleListConfiguration"
import { DirectSearch } from "./DirectSearch"
import { UniversalParts } from "./UniversalParts"
import { ExternalUniversalParts } from "./UniversalParts/ExternalUniversalParts"
import { VehicleParts } from "./VehicleParts"
import { useActiveVehicleDataProvider } from "./hooks/useActiveVehicleDataProvider"
import { Offers } from "./Offers"

export function ListV2Component() {
    useActiveVehicleDataProvider()
    const { uniPartsIsExternalSystem } = Morpheus.getParams<PartsBundleParams>("parts")
    return (
        <ArticleListConfigurationProvider>
            <Box zIndex={0} display="flex" width="100%" flexDirection="column">
                <Switch>
                    <Route path="/:workTaskId/parts/vehicles/list/:searchType?" component={VehicleParts} />
                    <Route path="/:workTaskId/parts/direct/list/" component={DirectSearch} />
                    <Route
                        path="/:workTaskId/parts/universal/list/:searchType?"
                        component={uniPartsIsExternalSystem ? ExternalUniversalParts : UniversalParts}
                    />
                    <Route path="/:workTaskId/parts/offers/list" component={Offers} />
                    <Route path="/:workTaskId/parts-offers/list/offers" component={Offers} />
                </Switch>
            </Box>
        </ArticleListConfigurationProvider>
    )
}
