import { MouseEvent, ReactNode, useState } from "react"
import { Collapsible, Scrollbar, SearchField } from "@tm/controls"
import { Button, Grid, Icon, Loader, styled } from "@tm/components"
import { isInfoAvailable } from "@bundles/tyres/data/helpers/infoDialogHelper"
import { FilterType } from "../../data/enums"
import { InfoDialog } from "./InfoDialog"

type Props = {
    filterId: FilterType
    title: string
    loading?: boolean
    children: ReactNode
    disabled?: boolean
    searchValue?: string
    active?: boolean
    searchEnabled?: boolean
    resetBtnDisabled?: boolean
    onSearchChange?(value: string): void
    onSearchVisibility?(): void
    onReset?(): void
    onCollapsibleChange?: () => void
    inModal?: boolean
    onToggle?: (filterId: FilterType) => void
    clippedFilters: FilterType
}

export function Filter({
    children,
    title,
    active,
    filterId,
    clippedFilters,
    disabled,
    loading,
    inModal,
    onCollapsibleChange,
    onReset,
    onSearchChange,
    onSearchVisibility,
    onToggle,
    resetBtnDisabled,
    searchEnabled,
    searchValue,
}: Props) {
    const [displayDialog, setDisplayDialog] = useState(false)

    const handleToggleSearch = (ev: React.MouseEvent<HTMLElement>) => {
        onSearchVisibility?.()
        ev.stopPropagation()
    }

    const handleXButton = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onReset?.()
    }

    const handleToogleClip = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onToggle?.(filterId)
    }

    const onInfoClick = (e: MouseEvent) => {
        e.stopPropagation()
        setDisplayDialog(true)
    }

    const handleDialogCLose = () => {
        setDisplayDialog(false)
    }

    const renderHeader = () => {
        return (
            <>
                {isInfoAvailable(filterId) && <StyledButton variant="text" onClick={onInfoClick} startIcon={<Icon name="info" />} />}
                {onSearchChange && <StyledButton variant="text" startIcon={<Icon name="search" />} onClick={handleToggleSearch} />}
                {onReset && (
                    <StyledButton
                        variant="text"
                        className="resetButton"
                        disabled={resetBtnDisabled && !disabled}
                        onClick={handleXButton}
                        startIcon={<Icon name="remove-filter" />}
                    />
                )}
                {onToggle && <StyledButton variant="text" disabled={false} onClick={handleToogleClip} startIcon={<Icon name="paperclip" />} />}
                {loading && <StyledLoader />}
            </>
        )
    }

    const wrapItem = (content: ReactNode) => {
        if (inModal) {
            return (
                <Grid height="20em" m={3} sm={4} xs={6} container>
                    <Scrollbar>{content}</Scrollbar>
                </Grid>
            )
        }
        return content
    }

    if ((!inModal ? clippedFilters : ~clippedFilters) & filterId) {
        return null
    }

    return (
        <>
            {wrapItem(
                <StyledCollapsible
                    className={disabled ? "disabled" : undefined}
                    name={title}
                    renderHeaderAppendix={renderHeader}
                    initiallyOpened={active}
                    onChangeOpen={onCollapsibleChange}
                >
                    {searchEnabled && <SearchField value={searchValue} showClear placeholder="Search..." onChange={onSearchChange} />}
                    {children}
                    {displayDialog && <InfoDialog filterId={filterId} onDialogCLose={handleDialogCLose} />}
                </StyledCollapsible>
            )}
        </>
    )
}

const StyledButton = styled(Button)({
    margin: "0 4px",
    width: "15px",
    height: "15px",

    "&.resetButton": {
        width: "1.5em !important",
        height: "1.5em !important",
    },
})

const StyledCollapsible = styled(Collapsible)(({ theme }) => ({
    visibility: "visible",
    "&.disabled": {
        cursor: "default",
        pointerEvents: "none",
        opacity: theme.opacity?.disabled,
        "& .collapsible__content": {
            display: "none",
        },
    },
}))

const StyledLoader = styled(Loader)({
    flex: "none",
    width: "1em",
    height: "1em",
    "& span": {
        width: "1em !important",
        height: "1em !important",
    },
})
