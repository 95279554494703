import { Select, MenuItemWidget, FormControl, InputLabel, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useRef, useState } from "react"
import { DRTire, SelectedFilters } from "../../../../../data/models"

type Props = {
    items: DRTire[]
    selectedValue?: string
    isLoading: boolean | undefined
    onChange: (filter: DRTire) => void
    selectedFilters: SelectedFilters
}

export function SizeDropDownWrapper({ items, onChange, selectedValue, isLoading, selectedFilters }: Props) {
    const { translateText } = useLocalization()
    const { width, height, inch, loadIndex, speedIndex } = selectedFilters
    const [openDropDown, setOpenDropDown] = useState(false)
    const dropDownRef = useRef<HTMLInputElement | null>(null)

    const mainSizesEmpty = !(width !== "" || height !== "" || inch !== "" || loadIndex !== "" || speedIndex !== "")

    useEffect(() => {
        if ((!selectedValue || selectedValue === "") && mainSizesEmpty && !isLoading && items.length > 0) {
            const timer = setTimeout(() => {
                setOpenDropDown(!!items && !isLoading)
                if (dropDownRef.current) {
                    dropDownRef.current.focus()
                }
            }, 300)
            return () => clearTimeout(timer)
        }

        setOpenDropDown(false)
    }, [isLoading, dropDownRef, selectedValue, mainSizesEmpty])

    const handleChange = (item: DRTire) => {
        onChange(item)
    }

    const StyledComponentsSelect = styled(Select)(() => {
        return {
            ".MuiOutlinedInput-notchedOutline legend span": { display: "none" },
            ".MuiSelect-icon": { left: "auto" },
            ".MuiSelect-select.MuiSelect-filled": {
                padding: "16px 14px 0 10px",
                fontSize: "18px",
            },
        }
    })

    return (
        <FormControl sx={{ width: "10em", minWidth: "15em", backgroundColor: "#f5f2f2" }}>
            <InputLabel
                id="demo-simple-select-label"
                shrink
                sx={{
                    textTransform: "capitalize",
                    fontSize: "18px",
                    transform: "translate(10px, 9px) scale(1)",
                    "&&.MuiInputLabel-shrink": {
                        transform: "translate(10px, 4px) scale(.6)",
                    },
                    "&&.Mui-focused": {
                        color: "text.primary",
                    },
                }}
                variant="filled"
            >
                {translateText(13073)}
            </InputLabel>
            <StyledComponentsSelect
                label={translateText(13073)}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
                value={selectedValue || ""}
                MenuProps={{ PaperProps: { style: { maxHeight: 250, fontSize: "18px" } } }}
                isloading={!!isLoading}
                defaultOpen={openDropDown}
            >
                {items.map((x, idx) => (
                    <MenuItemWidget
                        sx={{ fontSize: "18px" }}
                        onClick={() => handleChange(x)}
                        key={(x.id || idx).toString()}
                        value={`${x.size} ${x.loadIndex ?? ""}${x.speedIndex ?? ""}`}
                    >
                        {`${x.size} ${x.loadIndex ?? ""}${x.speedIndex ?? ""}`}
                    </MenuItemWidget>
                ))}
            </StyledComponentsSelect>
        </FormControl>
    )
}
