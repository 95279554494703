import { GetAdvertisementRequest, GetAdvertisementResponse, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

export function useGetAdvertisements(advertisementCategoryId: string | undefined) {
    const { data, isLoading, isSuccess, isError } = useQuery({
        queryKey: ["Advertisement", advertisementCategoryId],
        queryFn: async () => {
            const request: GetAdvertisementRequest = {
                advertisementId: advertisementCategoryId!,
            }

            const response = await Container.getInstance<GetAdvertisementResponse>(RegisteredModels.ERP_GetAdvertisement).subscribe(request).load()

            return response?.advertisements
        },
        enabled: !!advertisementCategoryId,
        refetchOnMount: true,
        staleTime: 30 * 60 * 1000, // 30 minutes
    })

    return { advertisements: data, isLoading, isLoaded: !isLoading && (isSuccess || isError), isError }
}
