import { Button, Dialog, Icon, Loader, Stack, Tooltip, Typography } from "@tm/components"
import { useCisCustomer, useReplaceUrlTags, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CisCustomerState, CisNoteType, ECounterType, ErpSystemConfig, OrderingType, WorkTaskStatus, channel } from "@tm/models"
import { notUndefinedOrNull, TmaHelper, useErpConfig, useExternalCatalogUrl } from "@tm/utils"
import { Suspense, useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router"
import { throttle, sortBy } from "lodash"
import { BASKET_ERP_CACHING_TIME, useInvalidateWorkTaskBasketQueries } from "../../../data/hooks/workTaskBasket"
import { useWorkTaskBasketState } from "../../../hooks/basketState/useWorkTaskBasketState"
import { BasketOrderGroup } from "../../../models"
import { getBundleParams } from "../../../utils"
import BasketClearDialog from "../../ClearBasketDialog"
import { OrderButton, StackRow } from "../../StyledComponents"
import AdditionalCostsDialog from "./components/AdditionalCostsDialog"
import ExternalOrderDialog from "./components/ExternalOrderDialog"
import { useHandlePostSendOrderAction } from "../../../hooks/useHandlePostSendOrderAction"
import { useOrderResult } from "./hooks/useOrderResult"
import NotUpToDateDialog from "./components/NotUpToDateDialog"

export type WorkTaskOrderButtonConfigProps = {
    textId?: number | string
    useTextAsTitle?: boolean
    externalSystemId?: number
    openExternalSystemAfterSuccessfulOrder?: boolean
    hideAdditionalCostsDialog?: boolean
}

type Props = WorkTaskOrderButtonConfigProps & {
    workTaskId: string
    orderGroupsLoading: boolean
    orderGroups?: BasketOrderGroup[]
    distributorId?: number
}

function WorkTaskOrderButton(props: Props) {
    const {
        externalSystemId,
        workTaskId,
        orderGroups,
        orderGroupsLoading,
        openExternalSystemAfterSuccessfulOrder,
        useTextAsTitle,
        hideAdditionalCostsDialog,
        distributorId,
    } = props
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const { translateText, isTranslationId, languageId } = useLocalization()
    const matchParams = useParams<{ view: string }>()
    const { userContext, userSettings } = useUser() ?? {}
    const { workTask } = useWorkTask() ?? {}
    const { cisCustomer } = useCisCustomer(!!userContext.hasTelesales)
    const telesalesPartner = userContext.hasTelesales ? cisCustomer : undefined
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const { invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()
    const { basket, erp } = useWorkTaskBasketState(workTaskId)
    const { sendWorkTaskOrders, removeParts, setOrderSending } = basket.actions
    const { basketOrderGroups, showAdditionalCostsConfirmationAllErpGroups, orderSending } = basket.state
    const { results, technicalErrors } = useOrderResult(workTaskId)
    const { loadingExternalCatalogUrl, externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const { handlePostSendOrderAction } = useHandlePostSendOrderAction()
    const oeExternalSystemId = useMemo(() => {
        return userContext?.externalModules?.find(
            (ext) => ext.description === "OE-Teile" && ext.parameter?.some((param) => param.key === "CatalogType" && param.value === "Call_OE_Basket")
        )?.id
    }, [userContext?.externalModules])

    const { loadingExternalCatalogUrl: loadingOeExternalCatalogUrl, externalCatalogUrl: oeExternalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: oeExternalSystemId,
        telesalesCustomerNo,
    })

    const replacedOeExternalCatalogUrl = useReplaceUrlTags(oeExternalCatalogUrl, { languageId })
    const replacedExternalCatalogUrl = useReplaceUrlTags(externalCatalogUrl, { languageId })
    const [showOeExternalUrl, setShowOeExternalUrl] = useState<boolean>(false)
    const [showExternalOrderDialog, setShowExternalDialog] = useState<boolean>(false)
    const [orderCompletedShown, setOrderCompletedShown] = useState<boolean>(false)
    const [showAdditionalCostsDialog, setShowAdditionalCostsDialog] = useState(false)
    const [showBasketNotUpToDateDialog, setShowBasketNotUpToDateDialog] = useState(false)
    const [showStaleErpInfoDialog, setShowStaleErpInfoDialog] = useState(false)
    const [showWorkTaskLockedDialog, setShowWorkTaskLockedDialog] = useState(false)
    const [showClearBasketDialog, setShowBasketClearDialog] = useState(false)
    const [loadingExternalSystem, setLoadingExternalSystem] = useState<boolean>(false)
    const [erpSystem, setErpSystem] = useState<ErpSystemConfig>()
    const [openExternal, setOpenExternal] = useState<boolean>(false)

    const [
        hasPartsIncludedInOrder,
        hasPartsExcludedFromOrder,
        hasOeParts,
        hasPartsBeingUpdated,
        hasIncludedPartsOrderable,
        hasPartsLoadingErpInfo,
        orderOptionsLoading,
        hasOrderOptionsError,
    ] = useMemo(() => {
        return [
            !!orderGroups?.some((orderGroup) => orderGroup.hasPartsIncludedInOrder),
            !!orderGroups?.some((orderGroup) => orderGroup.hasPartsExcludedFromOrder),
            !!orderGroups?.some((orderGroup) => orderGroup.hasOeParts),
            !!orderGroups?.some((orderGroup) => orderGroup.hasPartsBeingUpdated),
            !!orderGroups?.some((orderGroup) => orderGroup.hasIncludedPartsOrderable),
            !!orderGroups?.some((orderGroup) => orderGroup.hasPartsLoadingErpInfo),
            !!orderGroups?.some((orderGroup) => orderGroup.orderOptionsLoading),
            !!orderGroups?.some((orderGroup) => orderGroup.orderOptionsGroup?.disableOrder),
        ]
    }, [orderGroups])

    useEffect(() => {
        results.forEach((result) => {
            if (result.result.basketIsNotUpToDate) {
                setShowBasketNotUpToDateDialog(true)
            } else {
                handlePostSendOrderAction(result.result.postSendOrderActions)
            }
        })
    }, [results])

    const telesalesPartnerNotes = useMemo(() => {
        return telesalesPartner?.notes?.filter(
            (note) => note.type === CisNoteType.Warning || note.type === CisNoteType.Information || note.type === CisNoteType.WarehouseInformation
        )
    }, [telesalesPartner?.notes])

    useEffect(() => {
        if ((results.length || technicalErrors.length) && replacedOeExternalCatalogUrl && hasOeParts && !showOeExternalUrl && !orderCompletedShown) {
            setShowOeExternalUrl(true)
            setShowExternalDialog(true)
            setOrderCompletedShown(true)
        } else if (results.some((result) => result.result.allHaveSucceeded) && replacedExternalCatalogUrl && openExternalSystemAfterSuccessfulOrder) {
            setShowExternalDialog(true)
        }
    }, [
        results,
        technicalErrors,
        replacedOeExternalCatalogUrl,
        hasOeParts,
        showOeExternalUrl,
        orderCompletedShown,
        replacedExternalCatalogUrl,
        openExternalSystemAfterSuccessfulOrder,
    ])

    const handleOrderButtonClick = useCallback((orderAction: () => void) => {
        throttle(orderAction, 500, { leading: true })()
    }, [])

    // Hide order button (in worktask context) if only central ordering is allowed
    if (userContext?.parameter.orderingType === OrderingType.CentralOrderingWithoutWorkTaskOrder) {
        return null
    }

    function showOeDialog() {
        setShowBasketClearDialog(false)
        if (replacedOeExternalCatalogUrl && hasOeParts && !showOeExternalUrl) {
            setShowOeExternalUrl(true)
            setShowExternalDialog(true)
        } else {
            setShowOeExternalUrl(false)
        }
    }

    function clearBasket() {
        const partIds: Array<string> = []
        orderGroups?.forEach((group) => {
            group.basketParts.forEach((part) => {
                if (part.partItem.orderItem?.isIncluded) {
                    partIds.push(part.partItem.id)
                }
            })
        })
        if (partIds.length) {
            removeParts(partIds)
        }

        showOeDialog()
    }

    function handleClearBasketConfirm() {
        clearBasket()
        if (userContext.hasTelesales) {
            channel("WORKTASK", workTaskId).publish("BASKET/EXTERNAL_ORDER_SENT", { isPartialOrder: hasPartsExcludedFromOrder })
        }
    }

    function handleConfirmAdditionalCosts() {
        setShowAdditionalCostsDialog(false)
        setOrderSending(false)
        submitOrder(openExternal, erpSystem, distributorId)
    }

    function handleConfirmStaleErpInfo() {
        setShowStaleErpInfoDialog(false)
        setOrderSending(false)
        invalidatePartsRequests(workTaskId)
    }

    function handleExternalSystemLoaded() {
        setLoadingExternalSystem(false)
    }

    function renderOrderButton(
        key: string | number,
        highlight: boolean,
        orderAction: () => void,
        disabled: boolean,
        text: string,
        isLast: boolean,
        icon?: string
    ) {
        let content = (
            <OrderButton
                color={highlight ? "highlight" : undefined}
                endIcon={<Icon name={icon || "orders"} />}
                onClick={(e) => {
                    e.stopPropagation()
                    TmaHelper.GeneralCountEvent.Call(ECounterType.BasketOrder)
                    handleOrderButtonClick(orderAction)
                    if (externalSystemId) {
                        setLoadingExternalSystem(true)
                    }
                }}
                disabled={disabled}
                key={key}
                title={useTextAsTitle ? text : undefined}
            >
                {useTextAsTitle ? undefined : text}
            </OrderButton>
        )

        if (telesalesPartnerNotes?.length && workTask) {
            if (isLast) {
                content = (
                    <Stack gap={0.25}>
                        {content}
                        <Button
                            color="error"
                            onClick={() => {
                                channel("WORKTASK", workTask.id).publish("PARTNER/OPEN_NOTES_MODAL", {})
                            }}
                            startIcon={<Icon name="attention-dark" />}
                        >
                            {translateText(12846)}
                        </Button>
                    </Stack>
                )
            }
        } else if (telesalesPartner?.state === CisCustomerState.TSMBlocked && telesalesPartner.stateDescription) {
            content = (
                <>
                    <Tooltip title={telesalesPartner.stateDescription}>
                        <Icon name="info" />
                    </Tooltip>
                    {content}
                </>
            )
        }

        return content
    }

    function validateOrder(openExternal: boolean, extSys: ErpSystemConfig | undefined, distributorId: number | undefined) {
        const showAdditionalCostsConfirmation = distributorId
            ? basketOrderGroups?.some(
                  (orderGroup) => orderGroup.orderGroup.distributorId === distributorId && orderGroup.showAdditionalCostsConfirmation
              )
            : showAdditionalCostsConfirmationAllErpGroups

        if (
            userContext.hasTelesales &&
            ((workTask?.telesalesStatusValue as WorkTaskStatus) === WorkTaskStatus.Canceled ||
                (workTask?.telesalesStatusValue as WorkTaskStatus) === WorkTaskStatus.Completed)
        ) {
            setShowWorkTaskLockedDialog(true)
        } else if (!hideAdditionalCostsDialog && showAdditionalCostsConfirmation) {
            setShowAdditionalCostsDialog(true)
            setErpSystem(extSys)
            setOpenExternal(openExternal)
        } else {
            setOrderSending(true)
            submitOrder(openExternal, extSys, distributorId)
        }
    }

    function submitOrder(openExternal: boolean, extSys: ErpSystemConfig | undefined, distributorId: number | undefined) {
        if (openExternal && hasPartsIncludedInOrder) {
            setShowExternalDialog(true)
        } else if (!hasPartsIncludedInOrder && replacedOeExternalCatalogUrl && hasOeParts) {
            showOeDialog()
        } else if (hasPartsIncludedInOrder) {
            const now = Date.now()
            const erpDataIsStale = erp.basketErpInformation
                .map((x) => x.dataUpdatedAt)
                .filter(notUndefinedOrNull)
                .some((x) => now - x > BASKET_ERP_CACHING_TIME)

            if (erpDataIsStale) {
                setShowStaleErpInfoDialog(true)
            } else {
                sendWorkTaskOrders(extSys, distributorId)
            }
        }
    }

    function handleCloseExternalDialog() {
        setOrderSending(false)
        setShowExternalDialog(false)
        if (!showOeExternalUrl) {
            if (userSettings?.orderOptions.repairShopResponse?.showResetBasketDialogAfterExport && !openExternalSystemAfterSuccessfulOrder) {
                setShowBasketClearDialog(true)
            } else {
                clearBasket()
            }
        } else {
            setShowOeExternalUrl(false)
        }
    }

    // This method deserves a proper refactoring
    function renderButtons() {
        if (matchParams.view?.toLowerCase() === "details") {
            return null
        }

        const orderButtons = []

        let disabled = orderSending
        if (erpSystemConfigs && !disabled) {
            if (!disabled) {
                disabled = userContext ? !!userContext.parameter.orderDisabled : true
            }

            if (!disabled) {
                disabled = orderGroupsLoading || hasPartsBeingUpdated
            }

            if (!disabled) {
                disabled = !hasPartsIncludedInOrder
                if (disabled && oeExternalSystemId) {
                    disabled = loadingOeExternalCatalogUrl || !hasOeParts
                }
            }

            if (!disabled) {
                disabled = orderOptionsLoading
            }

            if (!disabled) {
                disabled = erp.basketErpInfosIndicator.isLoading || hasPartsLoadingErpInfo || (!oeExternalSystemId && !hasIncludedPartsOrderable)
            }

            if (!disabled && externalSystemId) {
                disabled = loadingExternalCatalogUrl
            }

            if (!disabled && userContext?.hasTelesales && workTask?.statusValue === WorkTaskStatus.Completed) {
                disabled = true
            }

            if (!disabled && hasOrderOptionsError) {
                disabled = true
            }

            const tsmDisabled = telesalesPartner?.state === CisCustomerState.TSMBlocked

            let showOthersAsPrioB = false
            let extSystems = erpSystemConfigs.filter((system) => system.useForWorkTaskOrder)

            // Will be displayed after the other systems
            const manual = extSystems.find((x) => x.displayMode === "81") // "Manuelle Bestellung"
            if (manual) {
                // Create a copy, so we can splice the manual system.
                extSystems = [...extSystems]
                extSystems.splice(extSystems.indexOf(manual), 1)
                showOthersAsPrioB = true
            }

            const textId = props.textId || 50
            const text = typeof textId === "number" || isTranslationId(textId) ? translateText(textId) : textId

            // Order buttons that have orderButtonsSettings defined are displayed first.
            // This is the desired behavior for NEXT-17521, but it should also be made configurable.
            // In general this is probably not the ideal solution. The display configuration should also come from the mdm.
            const { orderButtonsSettings } = getBundleParams()
            if (orderButtonsSettings) {
                Object.entries(orderButtonsSettings).forEach(([id, settings]) => {
                    const system = extSystems.find((x) => x.id.toString() === id)
                    if (system) {
                        // Create a copy, so we can splice the system
                        extSystems = [...extSystems]
                        extSystems.splice(extSystems.indexOf(system), 1)

                        let systemText = text
                        if (settings.text) {
                            systemText =
                                typeof settings.text === "number" || isTranslationId(settings.text) ? translateText(settings.text) : settings.text
                        } else if (extSystems.length > 1 || useOrderByDistributor) {
                            systemText += ` (${system.description})`
                        }

                        let isDisabled = disabled
                        if (tsmDisabled && !settings.keepEnabledForTsmBlocked) {
                            isDisabled = true
                        }

                        const isLast = !extSystems.length && !manual && !useOrderByDistributor
                        orderButtons.push(
                            renderOrderButton(
                                system.id,
                                !showOthersAsPrioB,
                                () => {
                                    validateOrder(!!externalSystemId && !openExternalSystemAfterSuccessfulOrder, system, undefined)
                                },
                                isDisabled,
                                systemText,
                                isLast,
                                settings.icon
                            )
                        )
                    }
                })
            }

            sortBy(extSystems, ["sortNumber"])
                .reverse() // The buttons of the external systems are displayed from right to left
                .forEach((system, index) => {
                    let systemText = text
                    if (extSystems.length > 1 || useOrderByDistributor) {
                        systemText += ` (${system.description})`
                    }

                    const isLast = extSystems.length === index + 1 && !manual && !useOrderByDistributor
                    orderButtons.push(
                        renderOrderButton(
                            system.id,
                            !showOthersAsPrioB,
                            () => {
                                validateOrder(!!externalSystemId && !openExternalSystemAfterSuccessfulOrder, system, undefined)
                            },
                            disabled || tsmDisabled,
                            systemText,
                            isLast
                        )
                    )
                })

            if (manual) {
                const isLast = !useOrderByDistributor
                orderButtons.push(
                    renderOrderButton(
                        manual.id,
                        true,
                        () => {
                            validateOrder(false, manual, undefined)
                        },
                        disabled || tsmDisabled,
                        translateText(1792),
                        isLast
                    )
                )
            }

            if (useOrderByDistributor) {
                const isLast = true
                orderButtons.push(
                    renderOrderButton(
                        distributorId ?? "orderByDistributor",
                        !showOthersAsPrioB,
                        () => {
                            validateOrder(false, undefined, distributorId)
                        },
                        disabled || tsmDisabled,
                        text,
                        isLast
                    )
                )
            }
        }

        return orderButtons
    }

    return (
        <Stack direction="row" alignItems="flex-start" gap={1}>
            {orderSending && <Loader />}
            {renderButtons()}
            <ExternalOrderDialog
                showExternalOrderDialog={showExternalOrderDialog}
                loadingExternalSystem={loadingExternalSystem}
                replacedExternalCatalogUrl={replacedExternalCatalogUrl}
                orderGroups={orderGroups}
                replacedOeExternalCatalogUrl={hasOeParts && workTask && replacedOeExternalCatalogUrl ? replacedOeExternalCatalogUrl : undefined}
                onCloseDialog={handleCloseExternalDialog}
                onExternalSystemLoaded={handleExternalSystemLoaded}
            />
            <BasketClearDialog
                showClearBasketDialog={showClearBasketDialog}
                onCloseDialog={showOeDialog}
                onConfirmDialog={handleClearBasketConfirm}
            />
            <AdditionalCostsDialog
                showAdditionalCostsDialog={showAdditionalCostsDialog}
                onCloseDialog={() => setShowAdditionalCostsDialog(false)}
                onConfirmDialog={handleConfirmAdditionalCosts}
            />
            <NotUpToDateDialog
                showNotUpToDateDialog={showBasketNotUpToDateDialog}
                dialogText={translateText(13707)}
                onConfirmDialog={() => setShowBasketNotUpToDateDialog(false)}
            />
            <NotUpToDateDialog
                showNotUpToDateDialog={showStaleErpInfoDialog}
                dialogText={translateText(13578)}
                onConfirmDialog={handleConfirmStaleErpInfo}
            />
            <Dialog open={showWorkTaskLockedDialog} position="top" fullWidth skin="warning">
                <StackRow justifyContent="space-between">
                    <Typography>{translateText(13255)}</Typography>
                    <Button startIcon={<Icon name="close" />} variant="text" onClick={() => setShowWorkTaskLockedDialog(false)} />
                </StackRow>
            </Dialog>
        </Stack>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <WorkTaskOrderButton {...props} />
        </Suspense>
    )
}
