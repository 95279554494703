import { ajaxAuth } from "@tm/utils"
import { CompilationsFindResponse } from "@tm/models"
import type {
    CompilationsFindResponseWM,
    CompilationShowAmountsResponse,
    ArticleUpdateRequest,
    CompilationUpdateRequest,
    CompilationCreateRequest,
    ArticlesDeleteRequest,
    CompilationShowResponse,
    CompilationImportArticlesRequest,
} from "../model"
import { mapArticleToAddRequest } from "../mapper/wm"
import { CompilationArticleInterface } from "../interfaces/compilation-article-interface"
import { getCompilationsServiceUrl } from "../../utils"

export function addArticleWM(articles: CompilationArticleInterface): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/ArticlesAdd`
    const body = {
        compilationId: articles.compilationId,
        articles: mapArticleToAddRequest(articles.articles),
    }

    return ajaxAuth({ url, body, method: "POST" })
}

export function createCompilationWM(body: CompilationCreateRequest): Promise<string> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationCreate`

    return ajaxAuth({ url, body, method: "POST" }).then((response) => response.compilationId)
}

export function deleteArticlesWM(body: ArticlesDeleteRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/ArticlesDelete`

    return ajaxAuth({ url, body, method: "POST" })
}

export function deleteCompilationsWM(compilationIds: string[]): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationsDelete`
    const body = { compilationIds }

    return ajaxAuth({ url, body, method: "POST" })
}

export function importArticlesWM(body: CompilationImportArticlesRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/tbd`

    return ajaxAuth({ url, body, method: "POST" })
}

export function findCompilationsWM(query?: string, pageIndex?: number, pageSize?: number): Promise<CompilationsFindResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationsFind`
    const body = { query, pageIndex, pageSize }

    return ajaxAuth<CompilationsFindResponseWM>({ url, body })
}

export function showAmountsWM(compilationId?: string): Promise<CompilationShowAmountsResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationShowAmounts`
    const body = { compilationId }

    return ajaxAuth<CompilationShowAmountsResponse>({ url, body })
}

export function showCompilationWM(compilationId: string) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationShow`
    const body = { compilationId }

    return ajaxAuth<CompilationShowResponse>({ url, body })
}

export function updateArticleWM(body: ArticleUpdateRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/ArticleUpdate`

    return ajaxAuth({ url, body, method: "POST" })
}

export function updateCompilationWM(body: CompilationUpdateRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationUpdate`

    return ajaxAuth({ url, body, method: "POST" })
}
