import { useTheme } from "@tm/components"
import { useMemo } from "react"
import tinycolor from "tinycolor2"

type ButtonVariants = "text" | "textLight"
const LightnessFactor = 20
const DarknessFactor = 7

export function useWorkTaskHistoryTheme() {
    const theme = useTheme()
    const {
        fontFamily,
        hoverBackgroundColor,
        iconFill: buttonIconFill,
        hoverIconFill,
        height,
        paddingInline,
        fontSize,
    } = theme.overwrites?.components?.worktaskModuleNavigation?.workTaskHistoryButton || {}

    const iconOpacity = theme.opacity?.primary

    const headerColor = theme.colors?.header
    const isHeaderColorDark = tinycolor(headerColor).isDark()

    const iconFill = useMemo(() => {
        if (buttonIconFill) {
            return buttonIconFill
        }

        if (headerColor) {
            return theme.palette.getContrastText(headerColor)
        }

        return isHeaderColorDark ? "#fff" : "#000"
    }, [buttonIconFill, headerColor, isHeaderColorDark, theme.palette])

    const buttonHoverBackgroundColor = useMemo(() => {
        if (hoverBackgroundColor) {
            return hoverBackgroundColor
        }

        if (isHeaderColorDark) {
            return tinycolor(headerColor).lighten(LightnessFactor).toHexString()
        }

        return tinycolor(headerColor).darken(DarknessFactor).toHexString()
    }, [headerColor, hoverBackgroundColor, isHeaderColorDark])

    const buttonVariant: ButtonVariants = isHeaderColorDark ? "textLight" : "text"

    return {
        buttonFontSize: fontSize,
        buttonFontFamily: fontFamily,
        buttonHoverBackgroundColor,
        buttonVariant,
        hoverIconFill,
        iconFill,
        iconOpacity,
        buttonHeight: height,
        buttonPaddingInline: paddingInline,
    }
}
