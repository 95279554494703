import { BikeModel, CarModel, RegistrationNoType, TruckModel } from "@tm/models"
import { useCallback } from "react"
import { setSelectedVehicle } from "../../../data/repositories/vrm-lookup"
import { useVehiclesByVinOrRegNo } from "../business"
import SearchComponent from "./SearchComponent"
import { VehicleSearchProps } from "../ComponentSwitch"

export default function SearchByVinOrRegNoComponent(
    props: VehicleSearchProps & { type: "vin" | "regNo"; lookupTypeId: RegistrationNoType; forceUpdate?: boolean }
) {
    const { query, vehicleType, type, lookupTypeId, forceUpdate } = props

    const modelResponse = useVehiclesByVinOrRegNo({
        query,
        vehicleType,
        type,
        lookupTypeId,
        forceUpdate,
    })

    const handleApply = useCallback(
        (model: CarModel | BikeModel | TruckModel) => {
            setSelectedVehicle({
                query,
                lookupTypeId,
                tecDocTypeId: model.id,
            })
        },
        [query, lookupTypeId]
    )

    return modelResponse ? <SearchComponent {...props} modelResponse={modelResponse} onApplyModel={handleApply} /> : <></>
}
