import { Icon, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    disabled?: boolean
    onClick(): void
}

export default function EditButton({ disabled, onClick }: Props) {
    const { translateText } = useLocalization()
    return <Button variant="text" title={translateText(122)} onClick={onClick} startIcon={<Icon name="edit_change" />} disabled={disabled} />
}
