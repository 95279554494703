import { css, StyleProps, withStyle, getStyleTheme } from "@tm/context-distribution"
import { Button, Radio, Text, Toolbar, Tooltip } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { em, padding, percent } from "csx"
import { PureComponent } from "react"
import { Criteria } from "../../data/models"
import { CustomTooltip, OverflowTooltip } from "."
import { classes } from "@tm/utils"

type Props = LocalizationProps &
    StyleProps<typeof stylesheet> & {
        criterias: Criteria[] // details
        selectedCriterias?: Criteria[] // details

        onChange(value: Criteria): void
        onReset?(value: string): void
    }

class MonoselectFilters extends PureComponent<Props> {
    handleFilterChange(item: any) {
        this.props.onChange(item)

        CustomTooltip.hide() // hide tooltip
    }

    handleReset(item: string) {
        this.props.onReset?.(item)

        CustomTooltip.hide() // hide tooltip
    }

    renderCriteria(item: Criteria, idx: number) {
        const { selectedCriterias } = this.props
        const isChecked = !!selectedCriterias?.find((x) => x.code == item.code)

        return (
            <OverflowTooltip label={item.description} key={idx}>
                <div className="is-clickable filter-item selection-list__item--high-priority">
                    <Radio checked={isChecked} onCheck={this.handleFilterChange.bind(this, item)} size="s" />
                    <div className="selection-list__item" onClick={this.handleFilterChange.bind(this, item)}>
                        <Text className="selection-list__item__value" size="s">
                            {item.description}
                        </Text>
                    </div>
                </div>
            </OverflowTooltip>
        )
    }

    render() {
        const { style, criterias, selectedCriterias } = this.props
        const enabledReset = selectedCriterias?.some((x) => criterias.some((y) => y.code == x.code && y.compatGroup == x.compatGroup))
        return (
            <>
                <Button
                    className={style.resetButton}
                    disabled={!enabledReset}
                    onClick={this.handleReset.bind(this, criterias[0].compatGroup)}
                    layout={["ghost"]}
                    icon="synchronize"
                />

                <Toolbar className={classes(style.filters)}>
                    <div className={style.criteriaList}>{criterias.map((item, idx) => this.renderCriteria(item, idx))}</div>
                </Toolbar>
            </>
        )
    }
}

function stylesheet() {
    const theme = getStyleTheme()
    return css({
        filters: {
            $nest: {
                ".toolbar__content": {
                    flexWrap: "wrap",
                },
                ".toolbar__content .btn": {
                    margin: em(0.2),
                },
                ".toolbar__content .btn__content": {
                    minWidth: "12vh",
                },
                ".radio": {
                    marginBottom: theme.margin.s,
                },

                ".checkbox": {
                    opacity: 1,
                },
            },
        },
        criteriaList: {
            display: "flex",
            flex: 1,
            flexDirection: "column",

            $nest: {
                ".filter-item": {
                    display: "flex",

                    ".selection-list__item": {
                        padding: padding(0, theme.margin.s),
                        display: "flex",
                        width: percent(100),
                    },
                    ".selection-list__item__value": {
                        whiteSpace: "initial",
                        wordBreak: "initial",
                        lineHeight: 1.5,
                    },
                },
            },
        },
        resetButton: {
            alignSelf: "flex-end",
        },
    })
}

export default withLocalization(withStyle(stylesheet, MonoselectFilters))
