import { useCallback, useState } from "react"
import { UserContext, UserModuleType } from "@tm/models"
import { getLocalization } from "@tm/localization"
import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { useExternalCatalogUrl } from "@tm/utils"

export const findParameterInTelesalesModule = (parameterName: string, userContext: UserContext): string | null => {
    const telesalesModule = userContext.modules?.find((x) => x.type === UserModuleType.Telesales)
    return telesalesModule?.parameters?.find((x) => x.key === parameterName)?.value || null
}

export function getCollectiveCustomerParam(userContext: UserContext) {
    const collectiveCustomerParam = userContext.parameter.collectiveCustomer
    if (collectiveCustomerParam) {
        return collectiveCustomerParam
    }

    return findParameterInTelesalesModule("CollectiveCustomer", userContext)
}

export const useVideoUrlParam = (): string | null => {
    const userContext = useUser()?.userContext

    if (!userContext) {
        return null
    }

    return findParameterInTelesalesModule("URL_Video", userContext)
}

export const subtractDaysFromDate = (date: Date, days: number): Date => {
    const d = new Date(date)
    d.setDate(d.getDate() - days)
    return d
}

export function addDaysToDate(date: Date, days: number) {
    const newDate = new Date(new Date(date).setDate(date.getDate() + days))

    if (newDate.getTime() > new Date().getTime()) {
        return new Date()
    }

    return new Date(new Date(date).setDate(date.getDate() + days))
}

export function getStatusText(key: number): string {
    const { translateText } = getLocalization()
    switch (key) {
        case 1:
            return translateText(1786)
        case 2:
            return translateText(1093)
        case 3:
            return translateText(13248)
        case 4:
            return translateText(13029)
        default:
            return key ? key.toString() : "-"
    }
}

export function usePartnerInfoAction(externalSystemId?: number) {
    const { userContext } = useUser()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const externalSystem = userContext.externalModules?.find((x) => x.id === externalSystemId)

    function findParameterWithValue(key: string, value: number, params?: Array<{ key: string; value: string }>): boolean | undefined {
        return params?.some((x) => x.key.toLowerCase() === key.toLowerCase() && x.value === value.toString())
    }

    const inModal = externalSystem && findParameterWithValue("target", 1, externalSystem.parameter)
    const inNewWindow = externalSystem && findParameterWithValue("target", 2, externalSystem.parameter)
    const inIframe = externalSystem && findParameterWithValue("target", 3, externalSystem.parameter)
    const displayBehavior = externalSystem && findParameterWithValue("displaybehavior", 531, externalSystem.parameter)

    const handleButtonClick = useCallback(() => {
        if (inModal) {
            setModalOpen(true)
        } else if (inNewWindow) {
            window.open(externalCatalogUrl, "_blank")
        } else if (inIframe) {
            window.open(`/external01?url=${externalCatalogUrl}`)
        }
    }, [inModal, inNewWindow, inIframe, externalCatalogUrl])

    const handleModalClose = useCallback(() => {
        setModalOpen(false)
    }, [setModalOpen])

    return { externalCatalogUrl, modalOpen, handleButtonClick, handleModalClose, inModal, displayBehavior }
}
