import { IBundle } from "@tm/morpheus"

import Banner from "./components/banner"
import BrandInformation from "./components/brand-information"
import CrmButton from "./components/crm-button"
import { DemoAlertBundle } from "./components/demo-module-alert"
import { DemoAlertTruckBundle } from "./components/demo-module-alert-truck"
import DemoModuleWidget from "./components/demo-module-widget"
import ExternalCatalogs from "./components/external-catalogs"
import ExternalCatalogsInIframe from "./components/external-catalogs-iframe"
import ExternalWidgetsContainer from "./components/externalWidgetsContainer"
import FastCheck from "./components/fastcheck"
import FormConfirmation from "./components/form-confirmation"
import HeaderButton from "./components/header-button"
import Headline from "./components/headline"
import Icon from "./components/icon"
import IFrameModule from "./components/iframe-module"
import IFrameModuleExternalId from "./components/iframe-module-extId"
import IFrameUniversalTagbased from "./components/iframe-universal-tagbased"
import IFrameWidget from "./components/iframe-widget"
import ImageWidget from "./components/image-widget"
import { DashboardIndustryBundle } from "./components/layouts/dashboard/dashboard-industry"
import { DashboardTemplateSixBundle } from "./components/layouts/dashboard/dashboard-template-six"
import { StartTemplateFiveBundle } from "./components/layouts/start/start-template-five"
import LkqWebkat from "./components/lkq-webkat"
import { ModuleGroup, ModuleGroupBundle } from "./components/module-group"
import ModuleNavigation from "./components/module-navigation"
import type { MultiWidgetProps } from "./components/MultiWidget"
import { MultiWidget, MultiWidgetBundle } from "./components/MultiWidget"
import PageNavigation from "./components/page-navigation"
import ShortcutsKey from "./components/shortcuts-key"
import StartPageButton from "./components/startpage-button"
import TabComponent from "./components/tab-component"
import { TabControl, TabControlBundle } from "./components/tab-control"
import Text from "./components/text"
import ToolbarNavigationDropdown from "./components/toolbar-navigation-dropdown"
import WidgetButton from "./components/widget-button"
import WidgetConceptPage from "./components/widget-concept-page"
import WidgetCoverOnly from "./components/widget-cover-only"
import type { NavigationItemConfigProps } from "./components/widget-navigation-dropdown"
import { WidgetNavigationDropdown, WidgetNavigationDropdownBundle } from "./components/widget-navigation-dropdown"
import WidgetOpen from "./components/widget-open"
import WorktaskTabIcon from "./components/worktask-tab-icon"
import { ActivateModuleMicro } from "./micros/activate-module"
import DevspireCmsLoader from "./plugins/DevspireCmsLoader"
import ModuleMountMessengerPlugin from "./plugins/module-mount-messenger"
import ShouldRenderByExtModule from "./plugins/should-render-by-ext-module"
import ShouldComopnentRender from "./plugins/should-render-by-mdm-module"
import MountWorktaskMessenger from "./plugins/worktask-message-on-mount"

import IndustryLoginButton from "./components/industry-login-button"
import { initMiscBundle, version } from "./utils"
import { getExternalCatalogUrl } from "./data/repositories/url"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        Banner,
        BrandInformation,
        CrmButton,
        DemoAlertBundle,
        DemoAlertTruckBundle,
        DemoModuleWidget,
        ExternalCatalogs,
        ExternalCatalogsInIframe,
        ExternalWidgetsContainer,
        FastCheck,
        FormConfirmation,
        HeaderButton,
        Headline,
        IFrameModule,
        IFrameModuleExternalId,
        IFrameUniversalTagbased,
        IFrameWidget,
        Icon,
        ImageWidget,
        IndustryLoginButton,
        LkqWebkat,
        ModuleGroupBundle,
        ModuleNavigation,
        PageNavigation,
        ShortcutsKey,
        StartPageButton,
        TabComponent,
        TabControlBundle,
        Text,
        ToolbarNavigationDropdown,
        WidgetButton,
        WidgetConceptPage,
        WidgetCoverOnly,
        WidgetNavigationDropdownBundle,
        WidgetOpen,
        WorktaskTabIcon,
        StartTemplateFiveBundle,
        DashboardTemplateSixBundle,
        DashboardIndustryBundle,
        MultiWidgetBundle,
    ],
    micros: [ActivateModuleMicro],
    plugins: [DevspireCmsLoader, ModuleMountMessengerPlugin, MountWorktaskMessenger, ShouldComopnentRender, ShouldRenderByExtModule],
    init: initMiscBundle,
}

export default bundle

// export available to be used in other bundles
export {
    // widgets
    MultiWidget,
    MultiWidgetProps,
    NavigationItemConfigProps,
    TabControl,
    TabControlBundle,
    WidgetNavigationDropdown,
    WidgetNavigationDropdownBundle,
    getExternalCatalogUrl,
}
