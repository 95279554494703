import { useUser } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { styled, Switch, Switch as SwitchMui } from "@tm/components"

type Props = {
    isV2?: boolean
}

export function PurchasePriceSwitch({ isV2 }: Props) {
    const user = useUser()
    const { userSettingsUpdating } = user
    const { translateText } = useLocalization()

    if (user?.userContext?.parameter.purchasePricesDisabled) {
        return null
    }

    const handleChange = (showPurchasePrice: boolean) => {
        if (user?.userSettings?.articleListSettings) {
            user.setUserSetting("SHOW_PURCHASE_PRICE", showPurchasePrice)
        }
    }

    const canShowSwitch = (): boolean => {
        const externalModules = user?.userContext?.externalModules

        let isInfoEnabled = false
        if (externalModules) {
            externalModules.map((externalModule) => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                }
            })
        }
        return isInfoEnabled
    }

    return (
        <>
            {canShowSwitch() && (
                <StyledToolbar className={classes("tk-parts purchase-price-switch")} title={translateText(1132)}>
                    {isV2 ? (
                        <SwitchMui
                            checked={user?.userSettings?.showPurchasePrice}
                            onChange={(event, checked) => handleChange(checked)}
                            disabled={userSettingsUpdating}
                        />
                    ) : (
                        <Switch
                            checked={user?.userSettings?.showPurchasePrice}
                            onChange={(event) => handleChange(event.target.checked)}
                            disabled={userSettingsUpdating}
                        />
                    )}
                </StyledToolbar>
            )}
        </>
    )
}

const StyledToolbar = styled(Toolbar)({
    "&:before": {
        borderLeft: "transparent",
    },
})
