import { useRef, useEffect } from "react"
import { Loader, Table } from "@tm/controls"
import { registerOutsideClick } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Street } from "../../../data/models/address-picker"

type Props = {
    onSelect(street: Street): void
    onClickOutside(): void
    addressSuggest: Array<Street>
    adressSuggLoading: boolean
}

function AddressSuggestBox(props: Props) {
    const wrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (wrapperRef.current) {
            const handleOutsideClick = (e: any) => {
                for (let i = 0; i < e.path.length; i++) {
                    const node = e.path[i]
                    if (node.classList && node.classList.contains("address-field")) {
                        return
                    }
                }
                props.onClickOutside()
            }

            const unregisterOutsideClick = registerOutsideClick(wrapperRef.current, handleOutsideClick)
            return () => {
                unregisterOutsideClick()
            }
        }
    }, [])

    const { onSelect, addressSuggest } = props
    const { translate } = useLocalization()

    const handleClick = (street: Street) => {
        if (onSelect && street) {
            onSelect(street)
        }
    }

    const renderTableColumns = () => {
        return [
            <Table.Column key="zip" className="zip" renderItemContent={(data) => <Table.Cell>{data.postcode}</Table.Cell>}>
                {translate(112)}
            </Table.Column>,
            <Table.Column key="city" className="city" renderItemContent={(data) => <Table.Cell>{data.region}</Table.Cell>}>
                {translate(113)}
            </Table.Column>,
            <Table.Column key="street" className="street" renderItemContent={(data) => <Table.Cell>{data.name}</Table.Cell>}>
                {translate(111)}
            </Table.Column>,
        ]
    }

    let suggestClassName = "address-suggest-box suggest"
    let suggestBoxClassName = "suggest__box"

    if (addressSuggest.length) {
        suggestClassName += " suggest--open"
        suggestBoxClassName += " is-visible"
    }

    return (
        <div className={suggestClassName} ref={wrapperRef}>
            <div className={suggestBoxClassName}>
                <Table data={addressSuggest} columns={renderTableColumns()} onClickRow={handleClick} />
                <Loader visible={props.adressSuggLoading} />
            </div>
        </div>
    )
}

export default AddressSuggestBox
