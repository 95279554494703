import { CisContact } from "@tm/models"
import { Stack, Typography } from "@tm/components"
import Field from "./Field"
import ElectronicAddress from "./ElectronicAddress"
import { StyledStack } from "./StyledComponents"

type Props = {
    contact: CisContact
}

export default function ContactComponent({ contact }: Props) {
    const { jobDescription, lastName, contactDescription, electronicAddresses, firstName } = contact
    return (
        <Field title={jobDescription}>
            <Stack>
                <Typography>
                    {firstName} {lastName}
                </Typography>
                <Typography>{contactDescription}</Typography>
                <StyledStack>
                    {electronicAddresses?.map((electronicAddress, idx) => <ElectronicAddress key={idx} electronicAddress={electronicAddress} />)}
                </StyledStack>
            </Stack>
        </Field>
    )
}
