import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetCarExtendedInfoRequest, GetCarExtendedInfoResponse } from "."
import { getBundleParams } from "../../../utils"
import { mapCarExtendedInfoResponse } from "./mapper"

export * from "./model"

export function getCarExtendedInfo(request: GetCarExtendedInfoRequest) {
    const url = `${getServiceUrl()}/GetCarInfoExtended`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<GetCarExtendedInfoResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => resolve(mapCarExtendedInfoResponse(response)), reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.eurotaxVehicleSelectionUrl
}
