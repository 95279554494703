import { Alert, Box } from "@tm/components"
import { useLocalization } from "@tm/localization"

export function ErrorMessage() {
    const { translate } = useLocalization()

    return (
        <Box>
            <Alert severity="error">{translate(1261)}</Alert>
        </Box>
    )
}
