import { Component, ComponentType, createElement } from "react"
import { Provider } from "react-redux"
import { Store } from "redux"

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(component: ComponentType<Omit<P, keyof StoreModel>>): ComponentType<P> {
    // eslint-disable-next-line react/prefer-stateless-function
    return class extends Component<P> {
        render() {
            const { store } = this.props
            return createElement(Provider, { store }, createElement(component, this.props))
        }
    }
}
