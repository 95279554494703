import { useEffect } from "react"
import { margin } from "csx"
import { batch, useDispatch, useSelector } from "react-redux"
import { useLocalization } from "@tm/localization"
import { TmaHelper } from "@tm/utils"
import { EFilterNames, TyreFilter } from "@tm/models"
import { Box, Button, Icon, styled } from "@tm/components"
import { useTyresV2 } from "@tm/context-distribution"
import { SpecialCarTypes } from "../../../data/enums"
import { Statics } from "../../../data/statics"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { ISummaryFilters, createSizeQuery } from "../business"
import { CustomDropdown } from "../../_shared/customDropdown"
import { CarTypeSeasonFilters } from "../../_shared/carTypeSeasonFilters"
import { DiameterFilters } from "../../_shared/DiameterFilters"

type Props = {
    className?: string
}

export function FiltersComponent({ className }: Props) {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const { selectedFilters, filters } = useSelector((s: MainState) => s.summary)
    const hasTyresV2 = useTyresV2()

    useEffect(() => {
        if (filters.height?.length === 1 && selectedFilters.width && !selectedFilters.height) {
            handleFiltersChange(EFilterNames.height, filters.height.first())
        } else if (filters.inch?.length === 1 && selectedFilters.height && !selectedFilters.inch) {
            handleFiltersChange(EFilterNames.inch, filters.inch.first())
        }
    }, [filters.height, filters.inch])

    const handleFiltersChange = (path: ISummaryFilters, filter: TyreFilter | undefined) => {
        batch(() => {
            dispatch(MainActions.resetSummaryFilter(path, hasTyresV2))
            if (filter) {
                dispatch(MainActions.updateSummaryFilters(path, filter))
            }
            dispatch(MainActions.loadSummaryFilters())
        })
    }

    const handleNormalSearchBtn = () => {
        const { carType, season } = selectedFilters

        const size = createSizeQuery(selectedFilters)

        const params = { size, season: season?.map((s) => s.value), carType: carType?.map((x) => x.query) }

        TmaHelper.UniParts.Search.TyresSelection(size)

        batch(() => {
            dispatch(MainActions.updateSearchValue(size))
            dispatch(MainActions.loadTyresList(false, false, true))
            dispatch(MainActions.changeStep("list", params))
            dispatch(MainActions.toggleTyresAccordion(false))
        })
    }

    const handleResetAll = () => {
        batch(() => {
            dispatch(MainActions.resetSummaryFilter("inch", hasTyresV2))
            dispatch(MainActions.setTyreSizes())
            dispatch(MainActions.loadSummaryFilters())
        })
    }

    const handleResetTyreSizes = () => {
        batch(() => {
            dispatch(MainActions.setTyreSizes())
            dispatch(MainActions.loadSummaryFilters())
        })
    }

    return (
        <Box position="relative">
            <StyledWrapper loading={filters.loading} className={className}>
                <CarTypeSeasonFilters
                    filterName={EFilterNames.carType}
                    disabled={filters.loading}
                    items={Statics.getCarTypes()}
                    selectedItemValue={selectedFilters?.carType}
                    onChange={handleFiltersChange}
                />
                <CarTypeSeasonFilters
                    filterName={EFilterNames.season}
                    disabled={filters.loading || selectedFilters.carType.every((x) => SpecialCarTypes.includes(+x.query))}
                    items={Statics.seasons}
                    selectedItemValue={selectedFilters?.season}
                    onChange={handleFiltersChange}
                />

                <Box display="flex" flexDirection="column">
                    <DiameterFilters
                        widths={filters.width}
                        heights={filters.height}
                        inches={filters.inch}
                        selectedHeight={selectedFilters.height}
                        selectedInch={selectedFilters.inch}
                        selectedWidth={selectedFilters.width}
                        onChange={handleFiltersChange}
                        loading={filters.loading}
                        onResetAll={handleResetTyreSizes}
                        maxItemsToShow={3}
                    />

                    <StyledToolbar>
                        <StyledDropdown
                            filterName={EFilterNames.loadIndex}
                            submitOnTab
                            defaultItem={translateText(12403)}
                            items={filters.loadIndex}
                            onChange={handleFiltersChange}
                            selectedValue={selectedFilters.loadIndex}
                            withResetButton
                            disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
                            isActive={false}
                            maxItemsToShow={3}
                        />
                    </StyledToolbar>

                    <StyledToolbar>
                        <StyledDropdown
                            filterName={EFilterNames.speedIndex}
                            submitOnTab
                            defaultItem={translateText(719)}
                            items={filters.speedIndex}
                            onChange={handleFiltersChange}
                            selectedValue={selectedFilters.speedIndex}
                            withResetButton
                            disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
                            isActive={false}
                            maxItemsToShow={3}
                        />
                    </StyledToolbar>

                    <StyledToolbar>
                        <StyledDropdown
                            filterName={EFilterNames.oeIdentifier}
                            submitOnTab
                            defaultItem={translateText(720)}
                            items={filters.oeIdentifier}
                            onChange={handleFiltersChange}
                            selectedValue={selectedFilters.oeIdentifier}
                            withResetButton
                            disableAutoSelect
                            disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
                            isActive={false}
                            maxItemsToShow={3}
                        />
                    </StyledToolbar>
                </Box>
                <StyledFooter>
                    <Button onClick={handleResetAll} startIcon={<Icon name="synchronize" />} />
                    <Button
                        sx={{ marginLeft: ".2em" }}
                        color="success"
                        onClick={handleNormalSearchBtn}
                        disabled={!selectedFilters.width || !selectedFilters.inch}
                    >
                        {translateText(135)}
                    </Button>
                </StyledFooter>
            </StyledWrapper>

            {filters.loading && <Box position="absolute" top="50%" right="50%" className="loader loader-spinner loader-spinner--visible" />}
        </Box>
    )
}

const StyledWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "loading",
})<{ loading?: boolean }>(({ theme, loading }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.margin?.m,
    opacity: loading ? theme.opacity?.disabled : "1",
    pointerEvents: loading ? "none" : "inherit",
    ".popover": {
        position: loading ? "fixed" : "absolute",
    },
}))

const StyledFooter = styled(Box)(({ theme }) => ({
    margin: theme.margin?.xl,
    display: "flex",
    justifyContent: "flex-end",
}))

const StyledToolbar = styled(Box)(({ theme }) => ({
    marginBottom: theme.margin?.m,
    display: "flex",
}))

const StyledDropdown = styled(CustomDropdown)(({ theme }) => ({
    flex: "1",
    margin: margin(0, theme.margin?.s || 0),
    "& .btn": {
        padding: theme.margin?.m,
        lineHeight: "1.3",
    },
    "&--small": {
        width: "5.5em",
    },
}))
