import { Text, Tooltip } from "@tm/controls"
import { FC, PropsWithChildren, useEffect, useRef, useState } from "react"

type Props = PropsWithChildren<{
    label: string
}>

const OverflowTooltip: FC<Props> = ({ children, label }) => {
    const [isOverflowed, setIsOverflow] = useState(false)
    const containerRef = useRef<HTMLDivElement>()

    const updateOverflowStatus = () => {
        if (containerRef.current) {
            const element = containerRef.current
            setIsOverflow(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
        }
    }

    useEffect(() => {
        if (containerRef.current) {
            updateOverflowStatus()
        }
    }, [containerRef.current])

    useEffect(() => {
        window.addEventListener("resize", updateOverflowStatus)
        return () => {
            window.removeEventListener("resize", updateOverflowStatus)
        }
    }, [])

    return (
        <Tooltip style="primary" position="right" content={<Text>{label}</Text>} disabled={!isOverflowed}>
            <div ref={(elem: HTMLDivElement) => (containerRef.current = elem)}>{children}</div>
        </Tooltip>
    )
}

export default OverflowTooltip
