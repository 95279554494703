import { Button, Headline, Modal } from "@tm/controls"
import { bindSpecialReactMethods } from "@tm/utils"
import { withLocalization, LocalizationProps } from "@tm/localization"
import { Component, createRef, PropsWithChildren, ReactNode } from "react"
import { isSearchbarActive } from "../../../helper"

type Props = PropsWithChildren<
    LocalizationProps & {
        resetFilterButton?: ReactNode
        searchbarToggle?: boolean // Just used to make the component rerender
    }
>

type State = {
    showFilterModal: boolean
    filtersChanged: boolean
}

class FilterModal extends Component<Props, State> {
    private modalRef = createRef<HTMLDivElement>()

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            showFilterModal: false,
            filtersChanged: true,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResizeGridItems)
    }

    componentDidUpdate() {
        this.handleResizeGridItems()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResizeGridItems)
    }

    handleResizeGridItems() {
        const grid = this.modalRef.current?.querySelector<HTMLElement>(".filters")
        const items = grid?.querySelectorAll<HTMLElement>(".filter")

        if (grid && items) {
            resizeAllGridItems(grid, items)
        }
    }

    handleClick() {
        window.setTimeout(() => this.handleResizeGridItems())
    }

    handleShowFilterModal() {
        this.setState({ showFilterModal: true })
    }

    handleCloseFilterModal() {
        this.setState({ showFilterModal: false })
    }

    handleApplyFilter() {
        this.handleCloseFilterModal()
    }

    render() {
        const {
            children,
            resetFilterButton,
            localization: { translate },
        } = this.props
        const { showFilterModal, filtersChanged } = this.state

        return (
            <>
                <Button layout={["holo"]} onClick={this.handleShowFilterModal}>
                    {translate(209)}
                </Button>
                {showFilterModal && (
                    <Modal onClose={this.handleCloseFilterModal}>
                        <div className="tk-parts filter-modal" ref={this.modalRef}>
                            <div className="filter-modal__header">
                                <Headline>{translate(209)}</Headline>
                                <div>
                                    {resetFilterButton}
                                    <Button icon="check" disabled={!filtersChanged} onClick={this.handleApplyFilter}>
                                        {translate(1041)}
                                    </Button>
                                </div>
                            </div>
                            <div className="filter-modal__content" onClick={this.handleClick}>
                                {children}
                            </div>
                        </div>
                    </Modal>
                )}
            </>
        )
    }
}

export default withLocalization(FilterModal)

function resizeGridItem(item: HTMLElement, rowHeight: number, index: number) {
    const { height } = item.getBoundingClientRect()

    let extraSpace = 0
    if (isSearchbarActive(index)) {
        extraSpace = 2
    }

    item.style.gridRowEnd = `span ${Math.ceil(height / rowHeight) + extraSpace}`
}

function resizeAllGridItems(grid: HTMLElement, items: NodeListOf<HTMLElement>) {
    const gridStyle = window.getComputedStyle(grid)
    const rowHeight = parseInt(gridStyle.getPropertyValue("grid-auto-rows"))

    if (isNaN(rowHeight)) {
        return
    }

    grid.style.gridAutoRows = "auto"
    grid.style.alignItems = "self-start"

    for (let i = 0; i < items.length; i++) {
        resizeGridItem(items[i], rowHeight, i)
    }

    grid.removeAttribute("style")
}
