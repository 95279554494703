import { FC } from "react"
import { Box, Typography } from "@tm/components"
import { Toolbar, XorSwitch, XorSwitchPositions } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { withRouter } from "@tm/utils"
import { graphicActions, mainActions, treeNavigationActions, useEdsStore } from "../../data/state"
import { TreeOptions } from "../../models"

const Header: FC = () => {
    const { translateText } = useLocalization()
    const treeOption = useEdsStore((state) => state.main.treeOption)

    const switchState: XorSwitchPositions = treeOption === TreeOptions.Category ? "left" : "right"

    function handleSwitch() {
        mainActions.toggleSearchOption()
        treeNavigationActions.setSelectedNode(undefined)
        treeNavigationActions.setTreeNavigation(undefined)
        graphicActions.setSelectedUnit(undefined)
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: "1em" }}>
            <Typography sx={{ marginLeft: "0.5em" }} variant="h1">
                EDS
            </Typography>

            <Toolbar title={translateText(13381)}>
                <Box display="flex" alignItems="center">
                    <Typography>{translateText(13393)}</Typography>
                    <XorSwitch status={switchState} onChange={handleSwitch} />
                    <Typography>{translateText(13394)}</Typography>
                </Box>
            </Toolbar>
        </Box>
    )
}

export default withRouter(Header)
