import { styled } from "@tm/components"
import { memo } from "react"
import SearchTreeV2 from "../../searchtreeV2/component"

export const SearchTree = memo(
    styled(SearchTreeV2, { shouldForwardProp: (prop) => prop !== "inModal" })<{ inModal?: boolean }>(({ inModal, theme }) => {
        const partListBackgroundColor = theme.overwrites?.components?.partListV2?.backgroundColor
        return {
            // Overwrite for certain catalogs which use a backgroundColor for Searchtree
            ...(partListBackgroundColor && {
                background: partListBackgroundColor,
                borderBottom: `1px solid #d2d2d2`,
                marginLeft: theme.spacing(2),
                ...(!inModal && {
                    width: "98vw",
                }),
            }),

            overflow: "visible",
            transform: "none",
            "> .MuiBox-root": {
                paddingLeft: theme.spacing(1),
            },
            ".MuiCollapse-root": {
                position: "absolute",
                height: "auto",
                background: "white",
                width: "100%",
                top: 46,
            },
        }
    })
)
