import { RequestArticleListPayload } from "@tm/models"

export const createVehiclePartsRequestPayload = (
    tecDocTypeId: number,
    productGroupIds: number[],
    manufacturerIds?: number[]
): RequestArticleListPayload => {
    return {
        productGroups: {
            ids: productGroupIds,
            supplierIds: manufacturerIds,
        },
        tecDocTypeId,
        forceReload: true,
        inModal: true,
        useNewModal: true,
        customerAlsoBought: true,
        extendedAssortment: false,
    }
}

export const createUniversalSearchRequestPayload = (query: string, uniProductGroupIds: number[]): RequestArticleListPayload => {
    return {
        uniSearch: {
            query,
        },
        productGroups: { ids: uniProductGroupIds },
        forceReload: true,
        inModal: true,
        useNewModal: true,
        customerAlsoBought: true,
        extendedAssortment: false,
    }
}
