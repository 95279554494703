/* eslint-disable no-console */
import { DamageCalculationEurotaxBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"
import { initRepositories } from "./data/repositories"

export const version = {
    name: "dmg-eurotax",
    version: "2.0",
}

let bundleParams: DamageCalculationEurotaxBundleParams

export function initDamageCalculationEurotaxBundle(params: DamageCalculationEurotaxBundleParams) {
    bundleParams = {
        ...params,
        redirectURL: `${window.location.origin}/bundles/damage-calculation-eurotax/redirect.html`,
    }
    checkBundleParams(bundleParams)
    initRepositories()
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        // eslint-disable-next-line no-throw-literal
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}

function checkBundleParams(params: DamageCalculationEurotaxBundleParams) {
    if (!isDevtoolEnabled()) {
        return
    }

    if (!params.vehicleServiceUrl) {
        console.warn(`${version.name}: The parameter 'vehicleServiceUrl' has to be set in the configuration`)
    }

    if (!params.ereCalculationServiceUrl) {
        console.warn(`${version.name}: The parameter 'ereCalculationServiceUrl' has to be set in the configuration`)
    }

    if (!params.vinPickerUrl) {
        console.warn(`${version.name}: The parameter 'vinPickerUrl' has to be set in the configuration`)
    }
}
