import { useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { Widget, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModulePropsMultiWidget, useMultiWidgetState } from "@tm/utils"
import { Box } from "@tm/components"
import DirectSearchWidgetContent from "../_shared/direct-search-widget-content"
import { ComponentState, Actions, IActions } from "./business"

type Props = {
    state: ComponentState
    actions: IActions
    className?: string
    showComponentKey?: string
    directSearchRoute: string
    showHints?: boolean
    storeId?: string
    contentOnly?: boolean
}

function DirectSearchWidgetComponent(props: Props) {
    const { showComponentKey, state, storeId, contentOnly } = props
    const [selectedMultiWidgetTab] = useMultiWidgetState({ storeId })
    const { translateText } = useLocalization()

    useEffect(() => {
        props.actions.loadQueryHistory()
    }, [])

    const renderContent = () => {
        return (
            <DirectSearchWidgetContent
                directSearchRoute={props.directSearchRoute}
                actions={props.actions}
                queryHistory={state.queryHistory}
                showHints={props.showHints}
            />
        )
    }

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    const className = `tk-parts direct-search-widget ${props.className || ""}`

    if (contentOnly) {
        return (
            <Box display="flex" justifyContent="center" width="100%">
                {renderContent()}
            </Box>
        )
    }

    return (
        <Widget id="parts__direct-search-widget" className={className} iconName="catalog" title={translateText(993)} size="6x3" active>
            <Text className="label">{translateText(1046)}</Text>
            {renderContent()}
        </Widget>
    )
}

export default connectComponent(Actions, DirectSearchWidgetComponent)
