import { useLocalization } from "@tm/localization"
import { PanelSection, List, Headline } from "@tm/controls"
import { concat, RouteComponentProps, withRouter } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { ArticleReferenceType } from "@tm/models"
import { ReactElement } from "react"
import { Models } from "../../data"
import { BaseDetailsRouteProps } from "../../business"
import { Actions, DetailsState } from "./business"
import KeyValueItem from "./components/KeyValueItem"
import KeyButtonItem, { KeyButtonItemValue } from "./components/KeyButtonItem"
import Attributes from "./components/Attributes"
import { KeyValueItemProps, OeMarketPrice } from "../../data/model"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps<BaseDetailsRouteProps> & {
    state: DetailsState
}

function DetailsComponent(props: Props) {
    const {
        state: { detailsResponse, erpResponse },
    } = props
    const { showErpQuantityPackingOnDetails } = getBundleParams()
    const { translate, translateText } = useLocalization()

    const renderSectionDetailedInfos = () => {
        if (!detailsResponse?.article) {
            return
        }

        const { traderDescription, description, additionalDescription, status, packaging, references, isReplacementArticle } = detailsResponse.article
        const eanNumbers = references?.filter((ref) => ref?.referenceType === ArticleReferenceType.EuropeanArticleNo) ?? []

        const items: Array<KeyValueItemProps> = [
            { description: translateText(3116), value: traderDescription },
            { description: translateText(377), value: description },
            { description: translateText(378), value: additionalDescription },
        ]

        if (status?.length) {
            items.push({
                description: translateText(379),
                value: status.map((item) => {
                    let text = item.description
                    if (item.country && status.length > 1) {
                        text += ` (${item.country})`
                    }
                    return { text }
                }),
                valuesAsList: true,
            })
        }

        items.push({
            description: showErpQuantityPackingOnDetails ? translateText(12812) : translateText(383),
            value: concat(" ", packaging?.packagingUnit?.toString(), packaging?.isSelfServicePackaging ? `(${translateText(384)})` : undefined),
        })

        // show Quantity informations from ERP - NEXT-15475
        if (showErpQuantityPackingOnDetails && erpResponse) {
            const erpArticleQuantityPackingUnit = erpResponse?.quantity?.quantityPackingUnit?.toString()
            items.push({
                description: translateText(12813),
                value: erpArticleQuantityPackingUnit || "-",
            })
        }

        items.push(
            { description: translateText(385), value: packaging?.quantityPerUnit?.toString() ?? "" },
            {
                description: translateText(386),
                value: packaging ? [packaging.batchSize?.toString() ?? "", packaging.batchSize2?.toString() ?? ""] : "",
            },
            {
                description: translateText(387),
                value: eanNumbers.map((x) => ({
                    text: x.referenceNo,
                    modificationState: x.modificationState,
                    asTag: true,
                })),
            }
        )

        if (isReplacementArticle) {
            items.push({ description: "", value: translateText(13366), highlight: true })
        }

        return (
            <PanelSection title={translate(407)} size="s">
                <List
                    view={KeyValueItem}
                    items={items.filter((x) => x.value && (!Array.isArray(x.value) || (x.value.length && x.value.some((y) => !!y))))}
                    className="key-value-list key-value-list--attributes"
                />
            </PanelSection>
        )
    }

    const renderSectionAdditionalInformation = () => {
        if (!detailsResponse || !detailsResponse.article || !detailsResponse.article.additionalReferenceArticleInformation) {
            return
        }
        const { textInformation, links: linkGroups } = detailsResponse.article.additionalReferenceArticleInformation

        const items: Array<KeyValueItemProps> = []
        textInformation.forEach((x) => items.push({ description: x.name, value: x.member.map((y) => y.content || y.description) }))

        const links: Array<KeyButtonItemValue> = ([] as Array<KeyButtonItemValue>).concat.apply(
            [],
            linkGroups.map((x) =>
                x.member.map((y) => {
                    return { mediaType: y.mediaType, description: y.description || y.content, content: y.content }
                })
            )
        )

        if (!items.length && !links.length) {
            return
        }

        return (
            <PanelSection title={translate(526)} size="s">
                <List view={KeyValueItem} items={items.filter((x) => x.value)} className="key-value-list key-value-list--additional-info" />
                {!!links.length && (
                    <List view={KeyButtonItem} items={[{ description: "Links", value: links }]} className="key-value-list key-value-list--links" />
                )}
            </PanelSection>
        )
    }

    const renderSectionAttributes = () => {
        if (!detailsResponse?.article) {
            return null
        }
        return <Attributes attributes={detailsResponse.article.attributes} />
    }

    const renderSectionAdditionalInfo = () => {
        if (!detailsResponse) {
            return
        }

        const { additionalInfos } = detailsResponse

        if (!additionalInfos) {
            return
        }

        const { supplierInformations, traderInformations } = additionalInfos

        if ((!supplierInformations || !supplierInformations.length) && (!traderInformations || !traderInformations.length)) {
            return null
        }

        return (
            <PanelSection title={translate(413)} size="s">
                {supplierInformations && supplierInformations.map((x, idx) => renderInfoList(x, idx, "supplied"))}
                {traderInformations && traderInformations.map((x, idx) => renderInfoList(x, idx, "trader"))}
            </PanelSection>
        )
    }

    const renderMarketPrices = () => {
        if (!detailsResponse?.oePrices) {
            return
        }

        const Renderers: Array<ReactElement> = []

        detailsResponse.oePrices.forEach((oePrice) => {
            const name = oePrice.vehicleManufacturerName
            Renderers.push(
                <PanelSection title={name} size="xs" className="panel--inline">
                    <List
                        key={name}
                        view={(oeMarketPrice: OeMarketPrice) => {
                            return (
                                <>
                                    <div className="key-value-list__key-cell">{oeMarketPrice.oeArticleNo}</div>
                                    <div className="key-value-list__value-cell">{`${oeMarketPrice.price} ${oeMarketPrice.currencyCode}`}</div>
                                </>
                            )
                        }}
                        items={oePrice.prices}
                        className="key-value-list marketplace-price__key-value-list-item"
                    />
                </PanelSection>
            )
        })

        return Renderers.length > 0 ? (
            <PanelSection title={translateText(12462)} size="s" className="marketplace-price">
                {Renderers}
            </PanelSection>
        ) : null
    }

    const renderInfoList = (infoList: Models.AdditionalInfoList, idx: number, reactKeyPrefix: string) => {
        return (
            <PanelSection key={`${reactKeyPrefix}-list-${idx}`} className="article-details__additional-infos panel__section" size="s">
                <Headline size="s">{infoList.title}</Headline>
                {infoList.informations.map(renderInfo)}
            </PanelSection>
        )
    }

    const renderInfo = (info: Models.AdditionalInfo, idx: number) => {
        if (info.isUrl) {
            return (
                <p key={idx}>
                    <a href={info.content} target="_blank" rel="noopener noreferrer">
                        {info.content}
                    </a>
                </p>
            )
        }

        return <p key={idx}>{getTextWithCorrectLineBreaks(info.content)}</p>
    }

    const getTextWithCorrectLineBreaks = (text: string) => {
        const tokens: Array<JSX.Element> = []

        text.split("\n").forEach((t, idx) => {
            tokens.push(<span key={`span_${idx}`}>{t}</span>)
            tokens.push(<br key={`br_${idx}`} />)
        })

        tokens.splice(tokens.length - 1, 1)

        return tokens.length === 1 ? tokens[0].props.children : <span>{tokens}</span>
    }

    return (
        <div className="article-details__overview">
            {renderSectionDetailedInfos()}
            {renderSectionAdditionalInformation()}
            {renderSectionAttributes()}
            {renderSectionAdditionalInfo()}
            {renderMarketPrices()}
        </div>
    )
}

export default connectComponent(Actions, withRouter(DetailsComponent))
