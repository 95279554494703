import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Article, ErpSystemConfig, IMicros, PriceType } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { TableCellData, TableRowData, Loader } from "@tm/components"
import { AvailabilityWrapper, useErpInfo, useArticleWithChangedTradeReference } from "@bundles/erp"

type Props = {
    article: Article
    index: number
    erpSystemConfig: ErpSystemConfig
    onMultiplePartnerSystemsPopoverClosed(isHidden: boolean): void
}

export default function PartnerSystemRowComponent({ erpSystemConfig, article, index, onMultiplePartnerSystemsPopoverClosed }: Props): TableRowData {
    const { renderMicro } = useMicro<IMicros>()
    const { currency } = useLocalization()

    const { erpInfo, loading } = useErpInfo(article, "list", undefined, erpSystemConfig)
    const mergedArticle = useArticleWithChangedTradeReference(article, erpSystemConfig)

    const basketArticles = useMemo(() => (mergedArticle ? [mergedArticle] : []), [mergedArticle])

    if (!mergedArticle) {
        return { cells: [], id: `${index}` }
    }

    function renderPrice(type: PriceType) {
        const price = erpInfo?.prices?.find((p) => p.type === type)

        if (!price) {
            return ""
        }

        return <>{currency(price.value, price.currencySymbol || "")}</>
    }

    function renderAvailability() {
        return (
            <>
                {loading ? (
                    <Loader size="small" />
                ) : (
                    <AvailabilityWrapper
                        hasReplacedArticles={!!(erpInfo?.articles && erpInfo.articles.length)}
                        availability={erpInfo?.availability}
                        tour={erpInfo?.tour}
                        warehouses={erpInfo?.warehouses}
                        requestedQuantity={erpInfo?.quantity?.requestedValue}
                        specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
                        useSpecialProcurement={erpInfo?.useSpecialProcurement}
                    />
                )}
            </>
        )
    }

    function getRowData() {
        const cellData: TableCellData[] = []

        cellData.push({ displayValue: erpSystemConfig.description, id: "partner_system_popover_1" })
        cellData.push({ displayValue: renderPrice(PriceType.Purchase), id: "partner_system_popover_2" })
        cellData.push({ displayValue: renderPrice(PriceType.Retail), id: "partner_system_popover_3" })
        cellData.push({ displayValue: renderAvailability(), id: "partner_system_popover_4" })
        cellData.push({
            displayValue: renderMicro("basket", "add-to-basket", {
                data: basketArticles,
                erpSystemConfig,
                onMultiplePartnerSystemsPopoverClosed,
            }),
            id: "partner_system_popover_5",
        })

        return cellData
    }

    return {
        cells: getRowData(),
        id: `${index}`,
    }
}
