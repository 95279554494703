import { Fragment } from "react"
import { Divider } from "@tm/components"
import { useCostEstimationModuleParameters, useUser } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { AdditionalMenuItemComponent, MenuItem, isLinkMenuItem, isExternalModuleMenuItem } from "./AdditionalMenuItem"
import { getBundleParams } from "../../../utils"

export type AdditionalMenuItem = MenuItem &
    Partial<MenuItem> & {
        children?: MenuItem[]
    }

type Props = {
    items: AdditionalMenuItem[]
    onClick?(): void
}

function generateKey(item: MenuItem): string {
    if (isLinkMenuItem(item) && item.path) {
        return item.path
    }
    if (isExternalModuleMenuItem(item)) {
        if (item.externalModuleConfiguration) {
            return `${item.externalModuleConfiguration.typeId}-${item.externalModuleConfiguration.documentTypeId}`
        }
    }
    return ""
}

function showMenuItem(item: MenuItem, userContext: UserContext): boolean {
    const { parameter, modules } = userContext
    const { disabledCostEstimation } = useCostEstimationModuleParameters()
    const { hideHourlyRatesWithoutCostEstimation } = getBundleParams()

    // check if menu item "Prices" or "Hourly Rates" should be displayed
    if (
        (parameter.purchasePricesDisabled &&
            !modules?.some((x) => x.type === UserModuleType.MarginTool) &&
            isLinkMenuItem(item) &&
            item.path === "/usersettings/prices") ||
        (isLinkMenuItem(item) && item.path === "/usersettings/hourly-rates" && hideHourlyRatesWithoutCostEstimation && disabledCostEstimation)
    ) {
        return false
    }

    return true
}

export function AdditionalMenuItems({ items, onClick }: Props) {
    const { userContext } = useUser() ?? {}

    return (
        <>
            {items.map((parent, index) => (
                <Fragment key={generateKey(parent)}>
                    <AdditionalMenuItemComponent item={parent} onClick={onClick} />
                    {parent.children?.map(
                        (x) =>
                            showMenuItem(x, userContext) && (
                                <AdditionalMenuItemComponent key={generateKey(x)} item={x} isSubMenuItem onClick={onClick} />
                            )
                    )}
                    {items.length - index > 2 && <Divider />}
                </Fragment>
            ))}
        </>
    )
}
