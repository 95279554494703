import { Memo } from "@tm/models"
import { Text } from "@tm/controls"
import { memo } from "react"

function TableRow({ memo }: { memo: Memo }) {
    let text = <Text>{memo.text}</Text>

    if (memo.linkUrl) {
        text = (
            <a href={memo.linkUrl} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        )
    }

    return (
        <tr>
            <td>
                <Text>{memo.label}</Text>
            </td>
            <td>{text}</td>
        </tr>
    )
}

export const MemoTooltipIconTable = memo(({ memos }: { memos: Array<Memo> }) => {
    const left = memos.filter((_, index) => index % 2 === 0)
    const right = memos.filter((_, index) => index % 2 !== 0)

    return (
        <div className="memo-tooltip-icon__table__wrapper">
            <table>
                <tbody>
                    {left.map((memo, i) => (
                        <TableRow key={i} memo={memo} />
                    ))}
                </tbody>
            </table>
            <table>
                <tbody>
                    {right.map((memo, i) => (
                        <TableRow key={i} memo={memo} />
                    ))}
                </tbody>
            </table>
        </div>
    )
})
