import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { CarParkResponse, CarParkResult } from "./model"
import { mapCarParkResponse } from "./mapper"
import { VehicleType } from "@tm/models"

export function loadCarPark(kTypNr: number | undefined, vehicleType: VehicleType | undefined): Promise<CarParkResponse> {
    const url = `${getServiceUrl()}/GetCarpark`
    const authorization = getStoredAuthorization()
    const body = { kTypNr, vehicleType }

    return ajax<CarParkResult>({ url, body, authorization, method: "GET" }).then((data) => {
        if (data?.carpark?.carparkAttributes?.length) {
            return mapCarParkResponse(data.carpark)
        }
        throw new Error("cartype request failed")
    })
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.vehicleServiceUrl
}
