import { EFilterNames, ETyresCarType, TyreFilter, TyresSeason } from "@tm/models"
import { equals } from "@tm/utils"
import { SummaryMultiFilters } from "@bundles/tyres/components/summary/business"
import { TyreFilterSizes, addOrRemoveItem, getAvailableTyreSize } from "../../data/helpers"
import { Filters, IWidgetFilters, SelectedFilters } from "./model"
import { TyresCritsRequest } from "../../data/repositories"
import { Statics } from "../../data/statics"

export function createTyresCritsRequest(selectedFilters: SelectedFilters): TyresCritsRequest {
    const crits: TyresCritsRequest = {
        width: selectedFilters.width?.query ?? "",
        height: selectedFilters.height?.query ?? "",
        inch: selectedFilters.inch?.query ?? "",
        ...(selectedFilters.season && {
            seasons: selectedFilters.season?.map((season) => season.query as unknown as TyresSeason),
        }),
        carTypes: selectedFilters.carType?.map((x) => +x.query as ETyresCarType) ?? [],
    }

    return crits
}

export function updateSelectedFilters(selectedFilters: SelectedFilters, filters: Filters, path: IWidgetFilters, value: TyreFilter): SelectedFilters {
    let newValues: TyreFilter | TyreFilter[] | undefined

    // for multi selection
    const oldStoredValues = selectedFilters?.[path]
    if (SummaryMultiFilters.includes(path)) {
        newValues = addOrRemoveItem(oldStoredValues as TyreFilter[], value)
    } else {
        newValues = equals(value, oldStoredValues) ? undefined : value
    }

    // for single selection
    if (path === EFilterNames.season || path === EFilterNames.carType) {
        newValues = [value]
    }

    let carTypeSizes: TyreFilterSizes | undefined
    if (path === EFilterNames.carType) {
        newValues = selectedFilters.untouched ? (value ? [value] : undefined) : newValues

        if (!newValues || !(newValues as TyreFilter[])?.length) {
            newValues = [Statics.getDefaultCarType()]
        }
        carTypeSizes = getAvailableTyreSize(newValues as TyreFilter[], filters)
    }

    return {
        ...selectedFilters,
        [path]: newValues,
        ...(path === EFilterNames.carType &&
            selectedFilters.untouched && {
                [path]: value ? [value] : undefined,
                untouched: false,
            }),
        ...(carTypeSizes && {
            width: carTypeSizes.width,
            inch: carTypeSizes.inch,
            height: carTypeSizes.height ? carTypeSizes.height : undefined,
        }),
        ...(path === EFilterNames.carType && {
            tyreCount: Statics.getDefaultTyreCountFilter(),
            untouched: false,
        }),
    }
}

export function resetSummaryFilter(selectedFilters: SelectedFilters, path: IWidgetFilters, tyresV2?: boolean): SelectedFilters {
    switch (path) {
        case EFilterNames.width:
            return {
                ...selectedFilters,
                height: undefined,
                inch: undefined,
                [path]: undefined,
            }

        case EFilterNames.height:
            return {
                ...selectedFilters,
                inch: undefined,
                [path]: undefined,
            }
        case EFilterNames.carType:
        case EFilterNames.season:
            return {
                ...selectedFilters,
                ...(tyresV2 && {
                    [path]: undefined,
                }),
            }
        default:
            return { ...selectedFilters }
    }
}
