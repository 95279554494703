import { ArticleErpInfo, GetArticlesRequest } from "@tm/models"
import { useEffect, useMemo } from "react"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { fetchDirectSearchOrVehiclePartsArticles } from "../../../data/fetchDirectSearchOrVehiclePartsArticles"
import { getArticleGroupsWithExactMatches } from "../../../helpers"
import { replaceArticlesQuantityWithSuggestedQuantity, useArticlesBase } from "../../../hooks/useArticlesBase"
import { ProductGroupsArticleData } from "../../../models"
import { usePartsViewOptions, useVehicle, useDisplayFilter } from "../../../hooks"
import { useProductGroupsListParamsContext } from "../ListParams"

const QUERY_KEY = "PartsReplacements.useArticles"

/**
 * Get information about the current article data of the direct search module.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useArticles(erpInfos: ArticleErpInfo[], isEnabled: boolean): ProductGroupsArticleData {
    const params = useProductGroupsListParamsContext()
    const {
        productGroups,
        suppliers,
        attributes,
        startParams,
        extendedAssortment,
        fittingPosition,
        sorting: { activateDefault: activateDefaultSorting },
    } = params

    const { pageSize } = useArticleListConfiguration()
    const vehicle = useVehicle()
    const {
        partsViewSettings: { quantitySuggestionEnabled },
    } = usePartsViewOptions()

    const request = useMemo<GetArticlesRequest | null>(() => {
        if (!isEnabled || !suppliers.length) {
            return null
        }

        let productGroupIds = productGroups.map((x) => x.id)
        if (!productGroupIds.length) {
            productGroupIds = startParams.productGroupIds
        }

        return {
            pageSize,
            fittingSideFilter: fittingPosition,
            productGroupIds,
            supplierIds: suppliers.map((x) => x.id),
            attributeFilter: attributes?.map((attr) => attr.query).join("|"),
            extendedAssortment,
            vehicleType: vehicle?.vehicleType,
            modelId: vehicle?.tecDocTypeId,
            engineCode: vehicle?.engineCode,
        }
    }, [
        isEnabled,
        pageSize,
        startParams,
        suppliers,
        productGroups,
        attributes,
        extendedAssortment,
        vehicle?.vehicleType,
        vehicle?.tecDocTypeId,
        vehicle?.engineCode,
        fittingPosition,
    ])

    const articlesBase = useArticlesBase(
        request,
        fetchDirectSearchOrVehiclePartsArticles,
        QUERY_KEY,
        quantitySuggestionEnabled ? replaceArticlesQuantityWithSuggestedQuantity : undefined
    )
    const { isSuccess, articles, articleCount, pageCount, hasNextPage } = articlesBase

    const articleGroups = useMemo(() => getArticleGroupsWithExactMatches(articles, hasNextPage), [articles, hasNextPage])
    const articleGroupsCombined = useMemo(
        () => [...articleGroups.exactMatch, ...articleGroups.other],
        [articleGroups.exactMatch, articleGroups.other]
    )

    useEffect(
        function resetNoResult() {
            params.setNoResult(false)
        },
        [request]
    )

    useEffect(
        function setNoResult() {
            if (isSuccess && !articleCount && pageCount === 1) {
                params.setNoResult(true)
            }
        },
        [isSuccess]
    )

    useEffect(() => {
        if (isSuccess) {
            activateDefaultSorting(articleCount, pageCount, articleGroupsCombined)
        }
    }, [activateDefaultSorting, articleCount, articleGroupsCombined, isSuccess, pageCount])

    const exactMatchDisplayFilter = useDisplayFilter(articleGroups.exactMatch, params, erpInfos)
    const displayFilter = useDisplayFilter(articleGroups.other, params, erpInfos)

    return {
        ...articlesBase,
        isStalling: (exactMatchDisplayFilter.stalling || displayFilter.stalling) && pageCount === 1,
        isFrontendFiltered: displayFilter.isFrontendFiltered,
        articleGroups: displayFilter.articleGroups,
        exactMatchArticleGroups: exactMatchDisplayFilter.articleGroups,
    }
}
