import { Button, buttonClasses, ButtonProps, styled } from "@tm/components"
import { useWorkTaskHistoryTheme } from "../hooks/useWorkTaskHistoryTheme"

type StyledButtonForwardProps = {
    hoverBackgroundColor?: string
    iconFill?: string
    hoverIconFill?: string
    iconOpacity?: number
    height?: string
    paddingInline?: string
    fontSize?: string
    fontFamily?: string
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop: string) =>
        !["hoverBackgroundColor", "iconFill", "hoverIconFill", "iconOpacity", "height", "paddingInline"].includes(prop),
})<StyledButtonForwardProps>(({ hoverBackgroundColor, iconFill, hoverIconFill, iconOpacity, height, paddingInline, fontSize, fontFamily }) => ({
    flexFlow: "column",
    marginInline: "0.2rem",
    paddingInline: `${paddingInline || "0.7rem"} !important`,
    fontSize: `${fontSize || "1rem"} !important`,
    gap: "0.3rem",
    fontFamily,
    height,
    ":hover": {
        backgroundColor: `${hoverBackgroundColor} !important`,
        [`&.${buttonClasses.root}`]: {
            [`.${buttonClasses.startIcon}`]: {
                "> svg": {
                    fill: hoverIconFill,
                    opacity: iconOpacity,
                },
            },
        },
    },
    [`&.${buttonClasses.root}`]: {
        [`.${buttonClasses.startIcon}`]: {
            "> svg": {
                fill: iconFill,
                height: "1.5rem",
                width: "1.5rem",
            },
        },
    },
}))

export default function OpenWorkTaskHistoryButton(props: ButtonProps) {
    const {
        buttonFontSize,
        buttonFontFamily,
        buttonHoverBackgroundColor,
        buttonVariant,
        iconFill,
        hoverIconFill,
        iconOpacity,
        buttonHeight,
        buttonPaddingInline,
    } = useWorkTaskHistoryTheme()

    return (
        <StyledButton
            {...props}
            variant={buttonVariant}
            size="large"
            hoverBackgroundColor={buttonHoverBackgroundColor}
            iconFill={iconFill}
            hoverIconFill={hoverIconFill}
            iconOpacity={iconOpacity}
            height={buttonHeight}
            paddingInline={buttonPaddingInline}
            fontSize={buttonFontSize}
            fontFamily={buttonFontFamily}
        >
            {props.children}
        </StyledButton>
    )
}
