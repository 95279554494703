import { useEffect, useRef } from "react"
import { useLocalization } from "@tm/localization"
import { styled, Typography, Box, Button, TextField } from "@tm/components"
import { setValue } from "@tm/utils"
import { DateField } from "@tm/controls"
import { tyresWheelsActions, useFastServiceStore } from "../../../data"
import { CollapsibleData, Genart, TyreAxles, ExtendETyreSeason, WheelsAndTyresTabNames } from "../../../data/models"
import { ProductCreation } from "../../_shared/ProductCreation"

type Props = {
    collapsibleName: string
    tabName: string
    info: CollapsibleData
    isExpanded: boolean
    genArtItem: Genart
    handleCompleteCollapsible: (collapsibleName: string) => void
}

export function CollapsibleContent({ tabName, info, collapsibleName, isExpanded, genArtItem, handleCompleteCollapsible }: Props) {
    const { translateText } = useLocalization()
    const tyrePressureRef = useRef<HTMLInputElement>(null)
    const profileDepthRef = useRef<HTMLInputElement>(null)
    const dotRef = useRef<HTMLInputElement>(null)

    const { selectedTyreSpecification, tireSpecifications, inputsLocked } = useFastServiceStore((state) => ({
        selectedTyreSpecification: state.tyresWheels.selectedTyreSpecification,
        tireSpecifications: state.maintenancePlan.tireSpecifications,
        inputsLocked: state.inputsLocked
    }))

    useEffect(() => {
        if (collapsibleName === "tyrePressure" && isExpanded) {
            tyrePressureRef.current && tyrePressureRef.current.focus()
        } else if (collapsibleName === "tyreDot" && isExpanded) {
            profileDepthRef.current && profileDepthRef.current.focus()
        }
    }, [isExpanded])

    const handleCollapsibleData = (path: keyof CollapsibleData, filter: string | Date) => {
        const newCollapseInfo = setValue({ ...info }, [path as string], filter)
        tyresWheelsActions.updateCollapsibleData(tabName, collapsibleName, newCollapseInfo)
    }

    const handleEnter = (key: React.KeyboardEvent<HTMLDivElement>) => {
        if (key.code === "Enter") {
            let allowed = false

            if (collapsibleName === "tyreDot") {
                allowed = !!info.profileDepth && !!info.dot
            } else {
                allowed = !!Object.values(info).length
            }

            if (allowed) {
                handleCompleteCollapsible(collapsibleName)
            }
        }
    }

    const hadleChangeFocusOnEnter = (key: React.KeyboardEvent<HTMLDivElement>) => {
        if (key.code === "Enter" && !!info.profileDepth) {
            dotRef.current?.focus()
        }
    }

    const renderFrontAxlePressure = () => {
        if (selectedTyreSpecification?.frontAxle?.pressure) {
            return (
                <CenterDiv>
                    <Typography variant="h4">{translateText(13654)}</Typography>
                    <Typography variant="h4" style={{ fontWeight: "bold", paddingLeft: ".5em" }}>
                        {`${selectedTyreSpecification?.frontAxle?.pressure} ${translateText(13666)}`}
                    </Typography>
                </CenterDiv>
            )
        }
        return <></>
    }

    const renderRearAxlePressure = () => {
        if (selectedTyreSpecification?.rearAxle?.pressure) {
            return (
                <CenterDiv>
                    <Typography variant="h4">{translateText(13655)}</Typography>
                    <Typography variant="h4" style={{ fontWeight: "bold", paddingLeft: ".5em" }}>
                        {`${selectedTyreSpecification?.rearAxle?.pressure} ${translateText(13666)}`}
                    </Typography>
                </CenterDiv>
            )
        }
        return <></>
    }

    const renderPressure = () => {
        switch (tabName) {
            case WheelsAndTyresTabNames.FrontLeft:
                return renderFrontAxlePressure()
            case WheelsAndTyresTabNames.FrontRight:
                return renderFrontAxlePressure()
            case WheelsAndTyresTabNames.RearLeft:
                return renderRearAxlePressure()
            case WheelsAndTyresTabNames.RearRight:
                return renderRearAxlePressure()
            case WheelsAndTyresTabNames.SpareWheel:
                let tyreSpecification: TyreAxles | undefined

                tireSpecifications?.forEach((axleSpecifications) => {
                    tyreSpecification = axleSpecifications?.specifications?.find(
                        (specification) => (specification.season as number | undefined) === ExtendETyreSeason.spareTyre
                    )
                })

                if (tyreSpecification?.frontAxle?.pressure) {
                    return (
                        <CenterDiv>
                            <Typography variant="h4">{translateText(13656)}</Typography>
                            <Typography variant="h4">{`${selectedTyreSpecification?.frontAxle?.pressure} ${translateText(13666)}`}</Typography>
                        </CenterDiv>
                    )
                }
                return <></>
            default:
                return <></>
        }
    }

    switch (collapsibleName) {
        case "general":
            return null
        case "tyrePressure":
            return (
                <StyledBox>
                    <TextField
                        variant="outlined"
                        placeholder={translateText(13081)}
                        onChange={(e) => handleCollapsibleData("tyrePressure", e.target.value)}
                        value={info.tyrePressure ?? ""}
                        inputRef={tyrePressureRef}
                        inputProps={{ inputMode: "numeric" }}
                        onKeyDown={handleEnter}
                        disabled={inputsLocked}
                    />
                    {renderPressure()}
                </StyledBox>
            )
        case "tyreDot":
            return (
                <StyledBox>
                    <TextField
                        variant="outlined"
                        placeholder={translateText(13082)}
                        value={info?.profileDepth ?? ""}
                        onChange={(e) => handleCollapsibleData("profileDepth", e.target.value)}
                        inputRef={profileDepthRef}
                        inputProps={{ inputMode: "numeric" }}
                        onKeyDown={hadleChangeFocusOnEnter}
                        disabled={inputsLocked}
                    />
                    <PaddingTextField
                        variant="outlined"
                        placeholder={translateText(13083)}
                        value={info?.dot ?? ""}
                        onChange={(e) => handleCollapsibleData("dot", e.target.value)}
                        inputProps={{ inputMode: "numeric" }}
                        inputRef={dotRef}
                        onKeyDown={handleEnter}
                        disabled={inputsLocked}
                    />
                </StyledBox>
            )
        case "rimCondition":
            return (
                <StyledBox>
                    <Typography variant="label">{translateText(13084)}:</Typography>
                    <PaddingButton
                        variant={info?.rimCondition === "aluminium" ? "outlined" : "contained"}
                        size="large"
                        onClick={() => {
                            handleCollapsibleData("rimCondition", "aluminium")
                        }}
                        disabled={inputsLocked}
                    >
                        {translateText(13085)}
                    </PaddingButton>
                    <PaddingButton
                        variant={info?.rimCondition === "steel" ? "outlined" : "contained"}
                        size="large"
                        onClick={() => {
                            handleCollapsibleData("rimCondition", "steel")
                        }}
                        disabled={inputsLocked}
                    >
                        {translateText(13086)}
                    </PaddingButton>
                </StyledBox>
            )
        case "brakeSystem":
            return (
                <DateField
                    size="xl"
                    useUtc
                    value={info.brakeSystem}
                    openToDate={info.brakeSystem || new Date()}
                    onChange={(e) => {
                        const dateObject = e instanceof Date ? e : new Date(`${e?.toString()}Z`)
                        handleCollapsibleData("brakeSystem", dateObject)
                    }}
                    disabled={inputsLocked}
                />
            )
        case "wholesaler":
            return <ProductCreation initialGenArt={genArtItem} />
        default:
            return null
    }
}

const CenterDiv = styled(Box)({
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1.5em",
    paddingTop: "1em",
})

const PaddingButton = styled(Button)({
    marginLeft: ".5em",
})

const PaddingTextField = styled(TextField)({
    paddingLeft: ".5em",
})

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
})
