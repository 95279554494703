import { css, getStyleTheme, StyleProps, withStyle, withUserContext, WithUserContextProps } from "@tm/context-distribution"
import { Button, Icon, Loader, Scrollbar, SubTitle, Text } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { channel, IMicros, SystemType, TmaEModule, Vehicle, OE } from "@tm/models"
import Morpheus, { importMicro, WithMicroProps } from "@tm/morpheus"
import { bindSpecialReactMethods, equals, QuerySearchType, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { em } from "csx"
import { createRef, Component, Fragment } from "react"
import { bem, connector, createArticleListRequest, displayPartPosition, getTextWidth } from "../../../../data/helpers"
import { Part, PartsGroup } from "../../../../data/models"
import { MainState } from "../../../main"
import { Actions, DetailsState, IActions } from "../../business"

type Props = LocalizationProps &
    WithMicroProps<IMicros> &
    StyleProps<typeof stylesheet> &
    StoreProps &
    RouteComponentProps &
    WithUserContextProps & {
        actions: IActions
        hideXBtn?: boolean // needed for custom tooltip for desplay the X btn
        closeTooltip?: () => void
        maximumHeight?: number
    }

type StoreProps = {
    parts: DetailsState["parts"]
    selectedPartItem: DetailsState["selectedPartItem"]
    selectedImagePosition: DetailsState["selectedImagePosition"]
    vehicle?: Vehicle
}
@importMicro
class PartsSelection extends Component<Props> {
    btnRef = createRef<Button>()

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    componentDidUpdate(prevProps: Props) {
        const {
            parts: { items },
        } = this.props
        const {
            parts: { items: prevItems },
        } = prevProps

        if (!equals(prevItems, items) && items?.length == 1 && items.first()?.parts?.length == 1) {
            const firstItem = items.first()?.parts.first()
            firstItem && this.btnRef?.current?.props?.onClick?.(undefined)
        }
    }

    handlePartSelect(part: Part) {
        const {
            actions,
            vehicle,
            parts: { items },
            localization: { translateText },
            userContext,
        } = this.props

        if (!vehicle) {
            return
        }

        const request = createArticleListRequest(part, items, vehicle, translateText, userContext)

        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchContextV2(TmaEModule.GPI_EUROTAX, "", QuerySearchType.Undefined)

        // this.props.closeTooltip?.()
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
        actions.selectPartItem(part) // TODO move to partsComponent ->
        // actions.sendDataToArticleList() //todo
    }

    renderGroupItem(item: PartsGroup, idx: number) {
        const {
            localization: { translateText },
            style,
        } = this.props

        const [mainParts, exchangeParts] = splitPartsByExhangeValue(item.parts)
        const mainPartsGroupedOnRows = Object.values(mainParts.groupBy((x) => ~~(mainParts.findIndex((y) => y == x) / 2)))
        const exchandePartsGroupedOnRows = Object.values(exchangeParts.groupBy((x) => ~~(exchangeParts.findIndex((y) => y == x) / 2)))
        return (
            <div key={`group__${idx}`} className={style.group}>
                <Text modifiers="strong" className={bem(style.description, item.description == "-" && "withoutText")}>
                    {item.description == "-" ? translateText(1812) : item.description}
                </Text>
                <div className={style.items}>{mainPartsGroupedOnRows.map(this.renderPartRow.bind(this, false))}</div>
                {!!exchangeParts.length && <div className={style.items}>{exchandePartsGroupedOnRows.map(this.renderPartRow.bind(this, true))}</div>}
            </div>
        )
    }

    renderPartRow(hasExchangeParts: boolean, partItems: Part[], rowIDX: number) {
        const {
            localization: { translateText },
            selectedPartItem,
            style,
            selectedImagePosition,
            renderMicro,
            vehicle,
            parts: { items },
            userContext: { system: { systemType = SystemType.Next } = {} } = {},
        } = this.props

        // some parts already contains "exchange" value at the end of description -> so we have to add the text only for the parts which doen't have.
        const subtitle = selectedImagePosition?.description?.toLowerCase().includes(translateText(1879).toLowerCase())
            ? selectedImagePosition?.description
            : `${selectedImagePosition?.description} ${translateText(1879)}`

        const { showoePrice } = Morpheus.getParams<{ [key: string]: string }>("eurotax")

        return (
            <div key={rowIDX} className={style.partItems}>
                {hasExchangeParts && <SubTitle> {subtitle}</SubTitle>}
                <div className={style.row}>
                    {partItems.map((part, btnIDX) => {
                        const { links, rechts } = part
                        const buttonTitle = displayPartPosition(links, rechts, translateText)

                        if (systemType == SystemType.Redesign) {
                            return (
                                <Fragment key={`${rowIDX}-${btnIDX}`}>
                                    {renderMicro?.("standalone", "rd-eurotax-openOeArticleList", {
                                        btnRef: this.btnRef,
                                        getGroups: () => items,
                                        className: style.button,
                                        part,
                                        vehicle,
                                        text: `${buttonTitle} ${part.partNumber}`,
                                        isActive: selectedPartItem?.partNumber == part.partNumber,
                                        hideOePrice: !+showoePrice,
                                    })}
                                </Fragment>
                            )
                        }
                        return (
                            <Button
                                ref={this.btnRef}
                                fakeButton
                                className={style.button}
                                key={`${rowIDX}-${btnIDX}`}
                                onClick={this.handlePartSelect.bind(this, part)}
                                isActive={selectedPartItem?.partNumber == part.partNumber}
                            >
                                {`${buttonTitle} ${part.partNumber}`}
                            </Button>
                        )
                    })}
                </div>
            </div>
        )
    }

    getWidth() {
        const {
            parts: { items },
            localization: { translateText },
            selectedImagePosition,
        } = this.props
        const subtitle = `${translateText(352)}/${translateText(758)}`

        const theme = getStyleTheme()

        const bigestItemTitle =
            (items?.length && items.reduce((a, b) => (a.description.length > b.description.length ? a : b))?.description.toUpperCase()) || ""

        const mainTitleWidth = (getTextWidth(selectedImagePosition?.description ?? "", `bold ${theme.font.textSize.l} Open Sans`) ?? 0) + 15 // where 30 is close btn
        const itemTitleWidth = getTextWidth(bigestItemTitle, `600 ${theme.font.textSize.m} 'Open Sans'`) ?? 0
        const subtitleWidth = getTextWidth(subtitle.toUpperCase(), `normal ${theme.font.subtitleSize.xs} 'Open Sans'`) ?? 0

        let buttonWidth = 0
        items?.forEach((item) => {
            item.parts.forEach((part, _, parts) => {
                const text = `${displayPartPosition(part.links, part.rechts, translateText)} ${part.partNumber}`
                const columnsNr = (parts.length > 1 && 2) || 1
                const spaceBeetWeen = (parts.length > 1 && 3) || 0
                const btnWidth = columnsNr * ((getTextWidth(text, `600 ${theme.font.textSize.m} Open Sans`) ?? 0) + 44) + spaceBeetWeen // where 30 is btn padding (2 * 1em)
                if (btnWidth > buttonWidth) {
                    buttonWidth = btnWidth
                }
            })
        })

        return Math.max(mainTitleWidth, itemTitleWidth, subtitleWidth, buttonWidth)
    }

    getHeight() {
        const {
            parts: { items },
            maximumHeight,
        } = this.props

        let height = 2 // title + subtitle (2em + 1em margin)
        items?.forEach((x) => {
            const [mainParts, exchangeParts] = splitPartsByExhangeValue(x.parts)

            const mainRows = ~~((mainParts.length + 1) / 2)

            if (x.description) {
                height += 1.8
            } // title
            height += mainRows * 2.8 // buttonheight

            if (exchangeParts.length) {
                const exhangeRows = ~~((exchangeParts.length + 1) / 2)
                height += 0.875 // subtitle height
                height += exhangeRows * 2.8 // buttonheight
            }
        })

        if (maximumHeight && height > maximumHeight / 15) {
            // maximumH /em size
            height = maximumHeight / 15
        }

        return em(height)
    }

    render() {
        const {
            parts: { loading, items },
            localization: { translateText },
            maximumHeight,
            style,
            selectedImagePosition,
        } = this.props

        if (loading) {
            return (
                <div className={bem(style.parts, "loading")}>
                    <Loader />
                </div>
            )
        }

        if (!items || items.length < 1) {
            return (
                <div className={style.noResults}>
                    <Text className={style.headline} size="l" modifiers={["strong"]}>
                        {selectedImagePosition?.description}
                    </Text>
                    <div className={style.noResultsItem}>
                        <Icon className="icon--no-result" name="no-results" size="l" />
                        <Text className={style.noResults} size="m" modifiers={["strong"]}>
                            {translateText(12775)}
                        </Text>
                    </div>
                </div>
            )
        }

        return (
            <div className={style.parts} style={{ width: this.getWidth(), height: this.getHeight() }}>
                <Text className={style.headline} size="l" modifiers={["strong"]}>
                    {selectedImagePosition?.description}
                </Text>
                <SubTitle size="xs">{`${translateText(352)}/${translateText(758)}`}</SubTitle>
                <div className={style.partsContent}>
                    <Scrollbar>{items && items.map(this.renderGroupItem)}</Scrollbar>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ details: state, manager: { vehicle } }: MainState): StoreProps {
    const { parts, selectedPartItem, selectedImagePosition } = state

    return {
        parts,
        selectedPartItem,
        vehicle,
        selectedImagePosition,
    }
}

export default connector(withLocalization(withRouter(withUserContext(withStyle(stylesheet, PartsSelection)))), mapStateToProps, Actions)

function stylesheet() {
    const theme = getStyleTheme()

    return css({
        noResults: {
            display: "flex",
            flexDirection: "column",
        },
        noResultsItem: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1em",
            margin: "0.5em",
            width: "100%",
        },
        parts: {
            display: "flex",
            flexDirection: "column",
            "&--loading": {
                justifyContent: "center",
                display: "flex",
                flex: 1,
            },
        },
        headline: {
            marginRight: em(1),
        },
        partsContent: {
            flexDirection: "column",
            alignItems: "flex-start",
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
        },
        group: {
            marginTop: theme.margin.m,
            flex: 1,
            flexDirection: "column",
        },
        items: {
            marginTop: theme.margin.m,
            display: "flex",
            flexDirection: "column",
            aligItems: "stretch",
        },
        partItems: {
            display: "flex",
            flexDirection: "column",
        },
        row: {
            display: "flex",
        },
        button: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.margin.xs,
            flex: 1,
        },
        description: {
            textTransform: "capitalize",
            whiteSpace: "pre-wrap",
            "&--withoutText": {
                height: em(1),
                display: "block",
            },
        },
    })
}

function splitPartsByExhangeValue(parts: Part[]) {
    // split parts in 2 categories -> one for items with isExchange == true and one for the remaining one
    return parts.reduce<[Part[], Part[]]>(
        (output, part) => {
            ;(!part.isExchange && output[0].push(part)) || output[1].push(part)
            return output
        },
        [[], []]
    )
}
