import { Dropdown, Icon, Loader } from "@tm/controls"
import { FC } from "react"
import { TireSize } from "../../../data/model"

type Props = {
    items: TireSize[]
    selectedValue?: TireSize
    onChange(value: TireSize): void
    loading?: boolean
    hideCheckIcon?: boolean
}

const TireSizeDropDown: FC<Props> = ({ selectedValue, items, loading, onChange, hideCheckIcon }) => {
    const handleDropDownChange = (dropDownItem: DropDownItem) => {
        const selectedItem = items.find((x) => x.id == dropDownItem.id)
        selectedItem && onChange(selectedItem)
    }

    const dropDownItems = items.map((item) => ({
        value: item.height ? `${item.width}/${item.height} R${item.inch}` : `${item.width} R${item.inch}`,
        id: item.id,
        selected: selectedValue && selectedValue.id == item.id,
        hideIcon: hideCheckIcon,
    }))

    const selectedItem = dropDownItems.find((x) => selectedValue && selectedValue.id == x.id)

    if (loading) {
        return <Loader />
    }

    return (
        <Dropdown disabled={!items.length} items={dropDownItems} itemView={DropDownItemView} onChange={handleDropDownChange} value={selectedItem} />
    )
}

type DropDownItem = {
    id: number
    value: string
    selected?: boolean
    hideIcon?: boolean
}

const DropDownItemView: FC<DropDownItem> = ({ id, value, selected, hideIcon }) => {
    return (
        <div style={{ display: "flex", paddingLeft: ".3em", width: "7em", justifyContent: "space-between" }}>
            {value}
            {selected && !hideIcon && <Icon size="s" name="check" />}
        </div>
    )
}

export default TireSizeDropDown
