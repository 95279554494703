import { useRecoilValue, useSetRecoilState } from "recoil"
import { useCallback, useMemo } from "react"
import { Article } from "@tm/models"
import { SelectedArticlesState } from "../states"
import { getBundleParams } from "../../../utils"
import { getInternalIdFromArticleUniqueId } from "../../list/business/helpers"

export function useItemSelection<T extends { id?: number | string }>(
    selectedArticles?: Array<T>,
    toggleSelectedArticle?: (item: T) => void,
    isArticleSelected?: (item: T) => boolean,
    deselectArticle?: (id: string) => void,
    deselectAll?: () => void,
    maximumPartsToCompare?: number
) {
    // any is for typescript workaround. the normal type is Article...
    const defaultSelectedArticles: any = useRecoilValue(SelectedArticlesState)

    const isDefaultArticleSelected = useCallback(
        (article: T) => defaultSelectedArticles.some((x: { id: string | number | undefined }) => x.id === article.id),
        [defaultSelectedArticles]
    )

    const { maximumPartsToCompare: defaultMaximumPartsToCompare } = getBundleParams()

    const setSelectedArticles = useSetRecoilState(SelectedArticlesState)

    const defaultToggleSelectedArticle = useCallback(
        (article: any) => {
            setSelectedArticles((prev) => (prev.some((x) => x.id === article.id) ? prev.filter((x) => x.id !== article.id) : [...prev, article]))
        },
        [setSelectedArticles]
    )

    const defaultDeselectArticle = useCallback(
        (articleUniqueId: string) => {
            const { internalId } = getInternalIdFromArticleUniqueId(articleUniqueId)

            if (Number.isNaN(internalId)) {
                return
            }

            setSelectedArticles((prev) => prev.filter((x) => x.internalId !== internalId))
        },
        [setSelectedArticles]
    )

    const defaultDeselectAll = useCallback(() => {
        setSelectedArticles([])
    }, [setSelectedArticles])

    return useMemo(
        () => ({
            selectedArticles: selectedArticles ?? defaultSelectedArticles,
            toggleSelectedArticle: toggleSelectedArticle ?? defaultToggleSelectedArticle,
            deselectArticle: deselectArticle ?? defaultDeselectArticle,
            deselectAll: deselectAll ?? defaultDeselectAll,
            isArticleSelected: isArticleSelected ?? isDefaultArticleSelected,
            maximumPartsToCompare: maximumPartsToCompare ?? defaultMaximumPartsToCompare,
        }),
        [
            selectedArticles,
            defaultSelectedArticles,
            toggleSelectedArticle,
            defaultToggleSelectedArticle,
            deselectArticle,
            defaultDeselectArticle,
            deselectAll,
            defaultDeselectAll,
            isArticleSelected,
            isDefaultArticleSelected,
            maximumPartsToCompare,
            defaultMaximumPartsToCompare,
        ]
    )
}
