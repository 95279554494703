import { PropsWithChildren, useMemo } from "react"
import { createContext, useContextSelector } from "use-context-selector"

const defaults = {
    disableSelection: false,
    disableDeleteButton: true,
    disableBasketButton: false,
    disableArticleGrouping: false,
    disableCostEstimationButton: false,
    disableReplaceButton: false,
    disableAlternatives: false,
    disableDescriptionCompactView: true,
    disableReplaceWithQuantity: true,
    disableErpChannel: true,
    displayOnlyQuantity: false,
    displayPriceDependingOnQuantity: false,
}

export type ArticleListFeaturesDisable = typeof defaults

/**
 * Shall not be used directly outside of this file.
 * Use export `useArticleListFeaturesDisable` instead.
 */
const ArticleListFeaturesDisableContext = createContext<ArticleListFeaturesDisable>(defaults)

/** You can provide partial of the disable flags! The rest are taken from defaults */
export function ArticleListFeaturesDisableProvider(props: PropsWithChildren<Partial<ArticleListFeaturesDisable>>) {
    const { children, ...disables } = props

    const featuresDisables = useMemo(
        () =>
            Object.keys(defaults).reduce((acc, key) => {
                const typedKey = key as keyof typeof defaults
                acc[typedKey] = disables[typedKey] ?? defaults[typedKey]
                return acc
            }, {} as ArticleListFeaturesDisable),
        [disables]
    )

    return <ArticleListFeaturesDisableContext.Provider value={featuresDisables}>{children}</ArticleListFeaturesDisableContext.Provider>
}

export function useArticleListFeaturesDisable<TContext extends ArticleListFeaturesDisable>(): TContext
export function useArticleListFeaturesDisable<TContext extends ArticleListFeaturesDisable, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useArticleListFeaturesDisable<TContext extends ArticleListFeaturesDisable, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticleListFeaturesDisableContext, (context) => {
        const newContext = context ?? defaults

        if (!selector) {
            return newContext
        }

        return selector(newContext as TContext)
    }) as TContext | TReturnType
}
