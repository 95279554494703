import { getParameterFromUserContextModule, useTyresMatchCodeSearchType, useTyresV2, useUser } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Box, Switch, styled, ToggleSwitch, ToggleSwitchItem, SelectedTypography } from "@tm/components"
import { ArticleListV2DisplayBehavior, UserModuleType } from "@tm/models"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { PurchasePriceSwitch } from "../../_shared/purchasePriceSwitch"
import { MatchCodeSearch } from "../../_shared/matchCodeSearch"

export function TopFiltersV2() {
    const { translateText } = useLocalization()
    const user = useUser()

    const actions = useActions(MainActions, "updateSearchValue", "loadTyresList", "changeStep")
    const { searchValue } = useSelector((s: MainState) => ({ searchValue: s.list.searchValue }))

    const tyresDisplayBehaviorInMdm = getParameterFromUserContextModule(user.userContext, UserModuleType.TMTires, "DisplayBehavior")
    const tyresVersion = useTyresV2()
    const showMatchCodeSearch = useTyresMatchCodeSearchType()
    const showDisplayBehaviorSwitch = tyresDisplayBehaviorInMdm === ArticleListV2DisplayBehavior.Both && tyresVersion
    const tyresDisplayBehaviorInUserSettings = user?.userSettings?.articleListSettings?.viewOptions?.displayBehavior

    const handleSearch = (newSearchValue?: string) => {
        newSearchValue && actions.updateSearchValue(newSearchValue)
        actions.loadTyresList(true)
        actions.changeStep("list", { size: newSearchValue, season: undefined, carType: undefined })
    }

    const handleChangeCompactView = (compactView: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    compactView,
                },
            })
        }
    }

    const handleChangeShowArticleImages = (showArticleImages: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    showArticleImages,
                },
            })
        }
    }

    function handleChangeDisplayBehavior() {
        if (user.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    displayBehavior:
                        tyresDisplayBehaviorInUserSettings === ArticleListV2DisplayBehavior.List
                            ? ArticleListV2DisplayBehavior.Tile
                            : ArticleListV2DisplayBehavior.List,
                },
            })
        }
    }

    function renderSwitchLabel(item: ToggleSwitchItem<string>) {
        return (
            <SelectedTypography variant="body3" selected={tyresDisplayBehaviorInUserSettings === item.key}>
                {item.value}
            </SelectedTypography>
        )
    }

    return (
        <StyledWrapper>
            {showMatchCodeSearch && (
                <MatchcodeToolbar title={translateText(135)}>
                    <MatchCodeSearch value={searchValue} onButtonClick={handleSearch} isV2 />
                </MatchcodeToolbar>
            )}
            <Toolbar title={translateText(795)}>
                <Switch
                    checked={user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages}
                    onChange={(event, checked) => handleChangeShowArticleImages(checked)}
                    disabled={user.userSettingsUpdating}
                />
            </Toolbar>
            <PurchasePriceSwitch isV2 />
            <StyledToolbar title={translateText(3009)}>
                <Switch
                    checked={user?.userSettings?.articleListSettings?.viewOptions?.compactView}
                    onChange={(event, checked) => handleChangeCompactView(checked)}
                    disabled={user.userSettingsUpdating || tyresDisplayBehaviorInUserSettings === ArticleListV2DisplayBehavior.Tile}
                />
            </StyledToolbar>
            {showDisplayBehaviorSwitch && (
                <StyledToolbar title={translateText(13731).replace(":", "")}>
                    <ToggleSwitch
                        left={{ value: translateText(13925), key: ArticleListV2DisplayBehavior.List }}
                        right={{ value: translateText(13926), key: ArticleListV2DisplayBehavior.Tile }}
                        disabled={user.userSettingsUpdating}
                        selected={tyresDisplayBehaviorInUserSettings}
                        onChange={handleChangeDisplayBehavior}
                        renderLabel={renderSwitchLabel}
                    />
                </StyledToolbar>
            )}
        </StyledWrapper>
    )
}
const MatchcodeToolbar = styled(Toolbar)({
    ".toolbar__inner": {
        paddingLeft: "0",
    },
})

const StyledWrapper = styled(Box)({
    flex: "1",
    justifyContent: "unset",
    display: "flex",
})

const StyledToolbar = styled(Toolbar)({
    "&:before": {
        borderLeft: "transparent !important",
    },
})
