import { styled, Table } from "@tm/components"

export const StyledTable = styled(Table, {
    shouldForwardProp: (prop) => prop !== "variant",
})(({ variant }) => ({
    position: variant === "small" ? "absolute" : undefined,
    top: variant === "small" ? "0" : undefined,
    bottom: variant === "small" ? "0" : undefined,
    overflowY: "auto",
    paddingRight: "1em",
}))
