import { EArticleModificationState, FittingPosition } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { RimDetailsArticle, RimArticle } from "../model"

export const mapRimItemtoArticle = (x: RimDetailsArticle): RimArticle => {
    return {
        accessoryDescription: "",
        additionalDescription: "",
        additionalReferenceArticleInformation: { headInformation: [], links: [], textInformation: [] },
        traderArticleNo: x.articleDetails?.traderArticleNo || "",
        attributes: [],
        articleModificationState: EArticleModificationState.NotModified,
        description: x.artDescription,
        existImage: false,
        thumbnail: `/bundles/wheels/images/axle-image__bit${x.axleBit}.png`,
        existPdf: false,
        fittingSide: FittingPosition.None,
        id: uniqueId(),
        isPartsListAvailable: true,
        status: [
            {
                id: 7,
                description: "",
            },
        ],
        imageCoordinates: "",
        requestErpInfo: true,
        productGroup: {
            id: x.productGroupId,
            name: x.productGroupName,
            sortId: 0,
            thumbnail: "",
        },
        supplier: {
            id: x.supplierId,
            abbreviation: x.supplierName,
            name: x.supplierName,
            isTopPriority: false,
        },
        supplierArticleNo: x.artNr,
        quantity: x.quantity || 4,
        initialQuantity: 1,
        references: [],
        information: [],
        vehicleInformation: [],
        internalId: x.articleDetails && +(x.articleDetails?.id || 0),
        applicationType: x.appType,
        applicationTypeId: x.appTypeID,
    }
}
