import { fastServiceStore } from "../store"
import { getInitialState } from "."

export function resetEndPage() {
    fastServiceStore.setState(getInitialState(), false, "Reset End Page Slice")
}

export function setLoadedArticles() {
    fastServiceStore.setState(
        {
            articlesLoading: false,
        },
        false,
        "Set loaded articles"
    )
}

export function setErrorArticles() {
    fastServiceStore.setState(
        {
            articlesLoading: false,
        },
        false,
        "Set Error Articles"
    )
}

export function setLoadingArticles() {
    fastServiceStore.setState(
        {
            articlesLoading: true,
        },
        false,
        "Set loading articles"
    )
}

export function setPrintLoaded(printUrl: string, expiresAt?: number, pdfId?: string) {
    fastServiceStore.setState(
        {
            printError: false,
            printLoading: false,
            pdfUrl: printUrl,
            pdfExpiresAt: expiresAt,
            pdfId,
        },
        false,
        "Set print loaded"
    )
}

export function setPrintFailed() {
    fastServiceStore.setState(
        {
            printError: true,
            printLoading: false,
        },
        false,
        "Set print failed"
    )
}

export function setPrintLoading() {
    fastServiceStore.setState(
        {
            printError: false,
            printLoading: true,
        },
        false,
        "Set print loading"
    )
}

export function setCalculationDone() {
    fastServiceStore.setState(
        {
            calculationDone: true,
        },
        false,
        "Set calculation done"
    )
}

export function setInputsLocked() {
    fastServiceStore.setState(
        {
            inputsLocked: true,
        },
        false,
        "Set inputs locked"
    )
}
