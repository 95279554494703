import { Tyre } from "@bundles/tyres/data/models"

export const extractTyreParameters = (tyreItem: Tyre, front?: boolean) => {
    const [width, secondPart] = front ? tyreItem.sizeFront?.split("/") || "" : tyreItem.sizeRear?.split("/") || ""

    const indexR = secondPart?.indexOf("R")
    const ratio = secondPart?.substring(0, indexR)
    const diameter = secondPart?.substring(indexR)

    return [width, ratio, diameter]
}
