import { Box, Button, styled } from "@tm/components"
import { TyreFilter } from "@tm/models"
import { useEffect, useState } from "react"

export type RangeProps = {
    items: TyreFilter[]
    selectedItempos: number
    onChange?(newValue: number): void
}

export function MinimizedRangeSlider({ items = [], selectedItempos, onChange }: RangeProps) {
    const [sliderPos, setSliderPos] = useState(selectedItempos)
    const [curentValue, setCurentValue] = useState(items[selectedItempos])
    const [showTooltip, setShowTooltip] = useState(false)

    const FIRST_POS = 0
    const MIDDLE_POS = Math.floor((items.length - 1) / 2)
    const LAST_POS = items.length - 1

    const className = "range-slider"

    useEffect(() => {
        if (selectedItempos !== sliderPos) {
            setSliderPos(selectedItempos)
        }
    }, [selectedItempos])

    const calculateSliderPosition = () => {
        const p = (sliderPos / (items.length - 1)) * 100
        return p - 7 - (8 * p) / 100
    }

    const onChangeHandler = (e: any) => {
        setSliderPos(e.target.value)
        setCurentValue(items[e.target.value])
    }

    const handleMouseUp = (e: any) => {
        if (onChange && selectedItempos !== e.target.value) {
            onChange(e.target.value)
        }
    }

    const handleSelectFirst = () => {
        onChange?.(FIRST_POS)
    }
    const handleSelectMiddle = () => {
        onChange?.(MIDDLE_POS)
    }
    const handleSelectLast = () => {
        onChange?.(LAST_POS)
    }

    return (
        <Box>
            <Box
                className={`${className} customSlider `}
                onMouseEnter={() => {
                    setShowTooltip(true)
                }}
                onMouseLeave={setShowTooltip.bind(undefined, false)}
            >
                {showTooltip && (
                    <StyledPopUp style={{ left: `${calculateSliderPosition()}%` }} className="range-slider__value">
                        {curentValue.value}
                    </StyledPopUp>
                )}
                <input value={sliderPos} min={0} max={items.length - 1} type="range" onChange={onChangeHandler} onMouseUp={handleMouseUp} />
            </Box>
            <StyledButtonsBox>
                <StyledButtonNumbers size="small" key={0} onClick={handleSelectFirst}>
                    {items.first()?.value}
                </StyledButtonNumbers>
                {MIDDLE_POS >= 1 && (
                    <StyledButtonNumbers size="small" key={1} onClick={handleSelectMiddle}>
                        {items[MIDDLE_POS]?.value}
                    </StyledButtonNumbers>
                )}
                {LAST_POS >= 1 && (
                    <StyledButtonNumbers size="small" key={2} onClick={handleSelectLast}>
                        {items.last()?.value}
                    </StyledButtonNumbers>
                )}
            </StyledButtonsBox>
        </Box>
    )
}

const StyledButtonNumbers = styled(Button)({
    "&&": {
        backgroundColor: "#2196f3",
        color: "#fff",
    },
})

const StyledPopUp = styled("span")({
    fontSize: "0.8em",
    padding: "0.5em 1em 0.5em 1em",
    whiteSpace: "nowrap",
    top: "-140%",
})

const StyledButtonsBox = styled(Box)({
    marginTop: "0.5em",
    display: "flex",
    justifyContent: "space-between",
})
