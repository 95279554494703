import { InputGroup, SearchField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useCallback, useEffect, useState } from "react"
import { Box, Icon, Typography, styled, SearchButton } from "@tm/components"
import { getBundleParams } from "@bundles/tyres/utils"
import { getCurrentSeason } from "@bundles/tyres/data/helpers/getCurrentSeason"

type Props = {
    value: string
    onButtonClick(value: string): void
    withTitle?: boolean
    isV2?: boolean
}

export function MatchCodeSearch({ value, onButtonClick, withTitle, isV2 }: Props) {
    const getSeasonPrefix = useCallback(() => getCurrentSeason(true), [])
    const { translateText } = useLocalization()
    const { useNewNavbar } = getBundleParams()

    const [searchValue, setValue] = useState(value)

    useEffect(() => setValue(value), [value])

    const handleSubmit = (newValue: string) => newValue && onButtonClick(newValue)

    return (
        <Box minWidth="12em">
            {withTitle && <Typography verticalAlign="sub">{translateText(1233)}</Typography>}
            <StyledInputGroup>
                <StyledSearchField
                    value={searchValue}
                    showClear
                    placeholder={isV2 ? `${translateText(12881)} 205/55R16` : `${getSeasonPrefix()}2055516 91H`}
                    tooltip="2055516, 2055516 91H, S2055516"
                    onChange={setValue}
                    onChangeConfirm={handleSubmit}
                    forceShowTooltipOnHover
                    useNewNavbar={useNewNavbar}
                />
                <StyledButton onClick={() => handleSubmit(searchValue)} startIcon={<Icon name="search" />} disabled={!searchValue} />
            </StyledInputGroup>
        </Box>
    )
}

const StyledInputGroup = styled(InputGroup)({
    height: "2em",
})

const StyledSearchField = styled(SearchField, {
    shouldForwardProp: (prop) => prop !== "useNewNavbar",
})<{ useNewNavbar?: boolean }>(({ useNewNavbar }) => ({
    width: useNewNavbar ? "22.4em" : "inherit",
}))

const StyledButton = styled(SearchButton)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "3.2em !important",
})
