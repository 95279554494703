import { FC, HTMLAttributes } from "react"

type Props = HTMLAttributes<HTMLDivElement> & {
    bemModifier?: string
}

const ArticleCell: FC<Props> = (props) => {
    const { bemModifier, className, ...rest } = props

    let defaultClassName = "article__cell "
    if (bemModifier) {
        defaultClassName += `article__cell--${bemModifier} `
    }
    if (className) {
        defaultClassName += className
    }

    return (
        <div {...(rest as any)} className={defaultClassName}>
            {props.children}
        </div>
    )
}

export default ArticleCell
