import { combineReducers } from "redux"

import { reduce as managerReducer, Actions as managerActions, ComponentActionType as SummaryComponentActionType } from "../../moduleManager/business"
import { reduce as summaryReducer, Actions as summaryActions, ComponentActionType as ManagerComponentActionType } from "../../summary/business"
import { reduce as listReducer, Actions as listActions, ComponentActionType as ListComponentActionType } from "../../list/business"
import {
    reduce as navigationReducer,
    Actions as navigationActions,
    ComponentActionType as NavigationComponentActionType,
} from "../../navigation/business"

export const reduce = combineReducers({
    manager: managerReducer,
    navigation: navigationReducer,
    summary: summaryReducer,
    list: listReducer,
})

export type IMainActions = typeof MainActions

export const MainActions = {
    ...managerActions,
    ...summaryActions,
    ...listActions,
    ...navigationActions,
}

export type MainActionsType = SummaryComponentActionType | ListComponentActionType | NavigationComponentActionType | ManagerComponentActionType
