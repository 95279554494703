import { ArticleAttribute, ArticleAttributes, TyreArticleAttribute, TyreArticleAttributes } from "@tm/models"

export function getTyreAttributeFromIdValue(attributes: ArticleAttributes[] | TyreArticleAttributes[], id: number, value: string) {
    let foundArticleAttribute: TyreArticleAttribute | ArticleAttribute | undefined

    for (let i = 0; i <= attributes?.length; i++) {
        const attribute = attributes[i]

        foundArticleAttribute =
            attribute.topAttributes?.find((a) => a.id === id && a.value === value) ??
            attribute.articleAttributes?.find((a) => a.id === id && a.value === value) ??
            attribute.partsListAttributes?.find((a) => a.id === id && a.value === value) ??
            attribute.vehicleAttributes?.find((a) => a.id === id && a.value === value)

        if (foundArticleAttribute) {
            break
        }
    }

    return foundArticleAttribute
}
