import { ETyresCarType, ETyresSeason, TyresSeason } from "@tm/models"

export const getIconByGroup = (group: ETyresCarType | TyresSeason | string) => {
    switch (group) {
        case ETyresSeason.summer:
            return "sun"
        case ETyresSeason.allSeason:
            return "all-weather"
        case ETyresSeason.winter:
            return "snow"
    }

    switch (+group) {
        case ETyresCarType.Agrar:
            return "tractor"
        case ETyresCarType.Anhanger:
            return "trailer"
        case ETyresCarType.EM:
            return "excavator"

        case ETyresCarType.Industrie:
            return "fork-lift"
        case ETyresCarType.LKW:
            return "truck2"
        case ETyresCarType.LLKW:
            return "truck"
        case ETyresCarType.Militar:
            return "military"
        case ETyresCarType.Motorrad:
            return "bike"
        case ETyresCarType.PKW:
            return "car"
        case ETyresCarType.SUV:
            return "car"
    }

    return ""
}
