import { useEffect, useState } from "react"

import { useFilters, useManufacturersByQuery, useModelsByQuery } from "../../../data/hooks"
import { useSelectedVehicleLookup } from "../../../data/hooks/useSelectedLookupConfig"
import SearchComponent from "./SearchComponent"
import { VehicleSearchProps } from "../ComponentSwitch"

export default function SearchStandardComponent(props: VehicleSearchProps) {
    const { query, vehicleType } = props

    const [filters, setFilters] = useFilters(vehicleType)
    const [manufacturerId, setManufacturerId] = useState<number>()
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicleType)

    useEffect(() => {
        setManufacturerId(undefined)
    }, [query])

    useEffect(() => {
        // When the imported data contains an engine code set it as active filter (only once)
        if (props.importedData?.engineCode) {
            setFilters((prev) => ({ ...prev, engineCode: props.importedData?.engineCode }))
        }
    }, [props.importedData?.engineCode, setFilters])

    const manufacturerResponse = useManufacturersByQuery({ vehicleType, query, selectedFilters: {} })

    // "sorting" is not used for query search, so we will remove it here
    // so a change of this property doesn't trigger a new service call
    const selectedFilters = { ...filters }
    delete selectedFilters.sorting

    const modelResponse = useModelsByQuery({
        vehicleType,
        query,
        manufacturerIds: manufacturerId ? [manufacturerId] : undefined,
        selectedFilters,
        selectedRegistrationNoType: selectedVehicleLookup.lookupTypeId,
        countryCode: selectedVehicleLookup.countryCode,
    })

    return (
        <SearchComponent
            {...props}
            modelResponse={modelResponse}
            manufacturerResponse={manufacturerResponse}
            manufacturerId={manufacturerId}
            setManufacturerId={setManufacturerId}
        />
    )
}
