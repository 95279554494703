import { Box, Icon, styled, Typography } from "@tm/components"
import { getBundleParams } from "@bundles/parts/utils"
import { useRecoilValue } from "recoil"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { ChainItem } from "./ChainItem"
import { RecommendedArticlesChainState } from "../../../states"

export function RecommendedArticlesChain() {
    const { maxDisplayedRecommandations } = getBundleParams()
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const articlesChain = useRecoilValue(RecommendedArticlesChainState(workTaskId))

    if (!articlesChain?.length) {
        return null
    }

    const latestArticleGroups = articlesChain.slice(-(maxDisplayedRecommandations ?? 5))

    const chainItemGroup = () => {
        return (
            <>
                {latestArticleGroups?.map((articleGroup, itemIndex) => (
                    <>
                        {articleGroup?.map((article, articleIndex) => <ChainItem item={article} key={`${article?.id}-${articleIndex}`} />)}
                        {itemIndex < latestArticleGroups?.length - 1 && <StyledIcon key={`arrow-${itemIndex}`} name="arrow-right" />}
                    </>
                ))}
            </>
        )
    }

    return (
        <>
            <StyledTypography variant="body2">{translateText(13939).toUpperCase()}</StyledTypography>
            <StyledBox>{chainItemGroup()}</StyledBox>
        </>
    )
}

const StyledBox = styled(Box)({
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    overflowX: "auto",
    height: "5.5em",
})

const StyledIcon = styled(Icon)({
    display: "flex",
    width: "4em",
})

const StyledTypography = styled(Typography)({
    marginLeft: "inherit",
    marginBottom: "inherit",
    fontSize: "smaller",
})
