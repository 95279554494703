import { useLocalization } from "@tm/localization"
import { VatRateType, SelectedPriceVatMode } from "@tm/models"
import { Divider } from "@tm/components"
import { B2CTotals } from "@tm/data/vouchers/costEstimations"
import { PartsAndWorks } from "./PartsAndWorks"
import Total from "../../Total"
import { TotalStack } from "../../../../../../../../../_shared/StyledComponents"

type Props = {
    currencyCode: string
    totals?: B2CTotals
}

export function B2CNet({ totals, currencyCode }: Props) {
    const { translateText, currency, number } = useLocalization()
    const vatRateReplacement = totals?.vatAmounts?.find((vat) => vat.vatType === VatRateType.Replacement)

    if (!totals) {
        return null
    }

    return (
        <TotalStack>
            <PartsAndWorks currencyCode={currencyCode} totals={totals} priceVatMode={SelectedPriceVatMode.Net} />
            <Divider />
            <Total
                label={translateText(650)}
                value={currency(totals?.offerPriceVatExcluded || 0, currencyCode)}
                labelVariant="h3"
                valueVariant="h3"
            />
            <Divider />
            {totals?.vatAmounts
                ?.filter((vat) => vat.vatType !== VatRateType.Replacement)
                .map((vatAmount) => (
                    <Total
                        label={`${translateText(651)} ${number(vatAmount ? vatAmount.vatRate : 0)}%`}
                        value={currency(vatAmount.vatAmount || 0, currencyCode)}
                        labelVariant="body2"
                        valueVariant="body2"
                        key={vatAmount.vatType.toString()}
                    />
                ))}
            {vatRateReplacement?.vatAmount && (
                <Total
                    label={translateText(12827)}
                    value={currency(vatRateReplacement.vatAmount, currencyCode)}
                    labelVariant="body2"
                    valueVariant="body2"
                />
            )}
            {!!totals?.alreadyTaxedTotalCosts && (
                <Total
                    label={translateText(12828)}
                    value={currency(totals.alreadyTaxedTotalCosts, currencyCode)}
                    labelVariant="body2"
                    valueVariant="body2"
                />
            )}
            <Divider />
            <Total
                label={translateText(62)}
                value={currency(totals?.offerPriceVatIncluded || 0, currencyCode)}
                labelVariant="h3"
                valueVariant="h3"
                labelBold
                valueBold
            />
        </TotalStack>
    )
}
