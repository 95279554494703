import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { FiltersSensorsRequest, FiltersSensorsResponse } from "./model"

export function loadSensorFilters(request: FiltersSensorsRequest) {
    const url = `${getServiceUrl()}/Filters/GetTpmsProductGroups`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<FiltersSensorsResponse>({ method: "GET", url, body, authorization })
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.articlesServiceUrl
}
