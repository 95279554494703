import { VehicleImage, Typography, Divider, Loader, Button, Icon, Box, styled } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, Customer, ESubModules, getCurrentWorkTaskId, LogEntry, RegisteredModels, UserModuleType, Vehicle } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { equals, getCostPerCalculation, initSelector, renderRoute, uniqueId } from "@tm/utils"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { createSelector } from "reselect"
import { WarningPrompt } from "@tm/controls"
import { useRef } from "react"
import { EreSelectionSteps, HistoryEntry } from "../../data/models"
import { MainState } from "../main"
import {
    customerFieldsSelector,
    customerSelector,
    MainActions,
    modelStateSelector,
    vehicleFieldsSelector,
    vehicleModelsSelector,
    vehicleSelector,
} from "../main/business"
import { LastCalculations } from "./components"
import CustomerFields from "./components/customerFields"
import Header from "./components/header"
import HistorySelection from "./components/historySelection"
import VehicleSelection from "./components/vehicle-selection/vehicleSelection"
import VehicleFields from "./components/vehicleFields"
import { MatchParams } from "./components/models"

const selector = initSelector(vehicleSelector, customerSelector, vehicleModelsSelector, customerFieldsSelector, vehicleFieldsSelector)

const vehicleModelState = createSelector(modelStateSelector, (modelState) => {
    const { initialRegistration, mileage, plateNumber, vin } = modelState as any
    return { initialRegistration, mileage, plateNumber, vin }
})

export default function SummaryComponent() {
    const { translateText } = useLocalization()
    const user = useUser()
    const workTask = useWorkTask()
    const history = useHistory()
    const matchParams = useParams<MatchParams>()

    const modelState = useSelector((s: MainState) => vehicleModelState(s, translateText))
    const actions = useActions(MainActions)

    const [vehicle, customer, vehicleModels, customerInfoFields, vehicleInfoFields] = useSelector(selector)

    const { models, vehicleModelsLoaded, vehicleModelsLoading } = vehicleModels

    const warningRef = useRef<WarningPrompt>(null)

    const redirectToDashboard = () => {
        const url = renderRoute("/:workTaskId", { ...matchParams })
        history.push(url)
    }

    const renderNoVehiclesAssociated = () => (
        <Box margin="1em 2em" display="inline-flex">
            <Typography>{translateText(1492)}</Typography>
            {vehicle?.modelThumbnail && (
                <StyledNoVehicleBox>
                    <VehicleImage
                        modelImage={vehicle.modelThumbnail}
                        modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                        vehicleType={vehicle.vehicleType}
                    />
                </StyledNoVehicleBox>
            )}
            <Typography>
                {vehicle!.manufacturer} {vehicle!.modelSeries} {translateText(1493)}
            </Typography>
            <Box flex="0" display="inline-flex" alignItems="center">
                <Button sx={{ margin: "0 2em" }} startIcon={<Icon name="dashboard" />} onClick={redirectToDashboard}>
                    {translateText(760)}
                </Button>
            </Box>
        </Box>
    )

    const renderLastCalculations = () => <LastCalculations handleOpenHistoryCalculation={handleOpenHistoryCalculation} />

    const handleOpenHistoryCalculation = (historyEntry: HistoryEntry, openTool: boolean) => {
        const {
            vehicle: { nextVehicleId },
        } = historyEntry.customerData
        if (vehicle?.id !== nextVehicleId) {
            workTask?.attachToWorkTask({ vehicle: nextVehicleId })
        } else {
            openHistory(historyEntry, openTool)
        }

        channel("WORKTASK", getCurrentWorkTaskId()).subscribeOnce("VEHICLE/LOADED", () => openHistory(historyEntry, openTool))
    }
    const openHistory = (historyEntry: HistoryEntry, openTool?: boolean) => {
        Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", {
            type: "ERE process",
            message: `A process from ERE history (${historyEntry.historyId}) has been triggered`,
        } as LogEntry)

        if (openTool) {
            handleOpenExternalTool(historyEntry)
        } else {
            actions.changeStep(EreSelectionSteps.CALCULATION)
            actions.openPreviousCalculation(historyEntry)
        }
    }

    const handleNewCalculation = () => {
        const invalid = Object.values(modelState).some((val) => val.length)

        if (!user?.userContext?.modules || invalid) {
            return
        }

        const calculationCost = getCostPerCalculation(UserModuleType.TMDamageCalculationEurotax, ESubModules.ShowEREClick, user.userContext)

        if (calculationCost) {
            warningRef?.current?.show()
        } else {
            handleOpenExternalTool()
        }
    }

    const handleOpenExternalTool = (historyEntry?: HistoryEntry) => {
        const invalid = !historyEntry && Object.values(modelState).some((val) => val.length)

        if (!user?.userContext || !user?.userContext.modules || invalid) {
            return
        }
        const module = user!.userContext.modules.find((x) => x.type === 31)

        if (!module) {
            return
        }

        updateWorkTaskInfo()

        actions.initExtern(module.moduleSubId, historyEntry)
    }

    const updateWorkTaskInfo = () => {
        if (!vehicle) {
            return
        }

        const { initialRegistration, vin, plateNumber, mileage } = vehicleInfoFields
        const { firstName, lastName } = customerInfoFields

        const updatedVehicle: Vehicle = {
            ...vehicle,
            ...(initialRegistration !== vehicle.initialRegistration && { initialRegistration }),
            ...(vin !== vehicle.vin && { vin }),
            ...(plateNumber !== vehicle.plateId && { plateId: plateNumber }),
            ...(mileage !== vehicle.mileAge && { mileAge: mileage }),
        }
        const shouldUpdateVehicle = !equals(vehicle, updatedVehicle)

        const fieldsHaveValueNewCustomer = !customer && (!!firstName?.length || !!lastName?.length)
        const fieldsAreModified = customer && (customer.firstName !== firstName || customer.lastName !== lastName)
        const shouldUpdateCustomer: boolean = !!fieldsHaveValueNewCustomer || !!fieldsAreModified

        const updatedCustomer: Customer = {
            id: uniqueId(),
            ...customer,
            firstName,
            lastName,
        }

        if (shouldUpdateCustomer || shouldUpdateVehicle) {
            workTask?.attachToWorkTask({
                ...(shouldUpdateVehicle && { vehicle: updatedVehicle }),
                ...(shouldUpdateCustomer && { customer: updatedCustomer }),
            })
        }
    }

    if (!workTask?.workTask?.vehicle) {
        return <StyledContentBox>{renderLastCalculations()}</StyledContentBox>
    }

    if (vehicleModelsLoading) {
        return (
            <StyledContentBox>
                <Loader />
            </StyledContentBox>
        )
    }

    if (vehicle && vehicleModelsLoaded && !models?.length) {
        return <StyledContentBox>{renderNoVehiclesAssociated()}</StyledContentBox>
    }

    return (
        <Box width="100%" margin="0 1em">
            <Header />

            <StyledCollectDataBox>
                <Typography variant="h2" mb={1}>
                    {translateText(1466)}
                </Typography>
                <Typography variant="body1">{translateText(1467)}</Typography>

                <HistorySelection onOpenHistoryCalculation={handleOpenHistoryCalculation} />
                <Divider light sx={{ my: 2 }} />
                <VehicleFields />
                <VehicleSelection onStartCalculation={handleNewCalculation} />
                <Divider light sx={{ my: 2 }} />
                <CustomerFields />
            </StyledCollectDataBox>

            <WarningPrompt
                ref={warningRef}
                confirmationButtonText={translateText(1628)}
                onConfirm={() => handleOpenExternalTool()}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onCancel={() => {}}
                text={translateText(1627)}
            />
        </Box>
    )
}

const StyledNoVehicleBox = styled(Box)({
    width: "3em",
    margin: "0 1em",
    height: "1.5em",

    img: {
        width: "3em",
        height: "1.5em",
    },
})

const StyledContentBox = styled(Box)({
    width: "100%",
    margin: "0 1em",
    display: "flex",
    flexDirection: "column",
})

const StyledCollectDataBox = styled(Box)({
    ".history-selection": {
        display: "flex",
        flex: "1",
        padding: "1em 0",
        alignItems: "center",

        ".options": {
            display: "inline-flex",

            ".dropdown": {
                margin: "0 0.5em",
                width: "15em",
            },
        },
    },
})
