import { MainActions } from "@bundles/damage-calculation-dat/components/main/business"
import { ICalculationItem } from "@bundles/damage-calculation-dat/data/model"
import { calculationSelector } from "@bundles/damage-calculation-dat/selectors"
import { PureList } from "@bundles/parts"
import { ArticleListFeaturesDisableProvider } from "@bundles/parts/components/ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { DamageModulesType, ParticularStateProvider } from "@bundles/parts/components/PureList/ContextProviders/Particular/ParticularContext"
import { useLocalization } from "@tm/localization"
import { Article, OE } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { mapOePartToOeOePart } from "@tm/utils"
import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"

type Props = {
    requestAlternatives: (item: ICalculationItem) => void
}

export function ArticleListV2(props: Props) {
    const actions = useActions(MainActions, "updateTotals", "replaceArticle", "setErpArticles", "selectArticle", "changeOeArticleQuantity")
    const { items } = useSelector(calculationSelector)
    const { translateText } = useLocalization()

    const oeArticlesWithArticles = useMemo(
        () =>
            items.map((item) => ({
                article: item.selectedPart || null,
                oeArticle: item.selectedPart ? null : mapOePartToOeOePart(item.oeArticle),
            })),
        [items]
    )

    function mapArticle(item: ICalculationItem) {
        if (item.selectedPart) {
            return item.selectedPart
        }
        return mapOePartToOeOePart(item.oeArticle)
    }

    const articles = useMemo(() => items.map((i) => mapArticle(i)), [items])

    const toggleSelectedArticle = useCallback(
        (article: Article | OE.OePart) => {
            const { id } = article

            const item = items.find((i) => i.oeArticle.oeArticleNumber === id || i.selectedPart?.id === id)
            if (!item) {
                return
            }
            return actions.selectArticle(translateText, item)
        },
        [items, actions, translateText]
    )

    const selectedArticles = items.filter((x) => x.isSelected).map((i) => mapArticle(i))
    const isArticleSelected = useCallback(
        (article: Article | OE.OePart) => {
            const { id } = article
            return !!selectedArticles.find((x) => x.id === id)
        },
        [selectedArticles]
    )

    const onChangeQuantity = useCallback(
        (article: Article | OE.OePart, quantity: number) => {
            if ((article as OE.OePart).number) {
                const foundOe = items.find((x) => x.oeArticle.oeArticleNumber === article.id)
                if (foundOe) {
                    return actions.changeOeArticleQuantity(translateText, foundOe, quantity)
                }
            }
            const foundArticle = items.find((x) => x.selectedPart?.id === article.id)
            return actions.changeOeArticleQuantity(translateText, foundArticle!, quantity)
        },
        [items, actions, translateText]
    )

    const handleReplaceButton = useCallback(
        (item: Article | OE.OePart) => {
            if ((item as OE.OePart).number) {
                const oeFound = items.find((x) => x.oeArticle.oeArticleNumber === item.id)
                if (oeFound) {
                    return props.requestAlternatives(oeFound)
                }
            }
            const articleFound = items.find((x) => x.selectedPart?.id === item.id)
            return props.requestAlternatives(articleFound!)
        },
        [items, props.requestAlternatives]
    )

    const context: DamageModulesType = useMemo(
        () => ({
            type: "damageModulesList",
            articles: oeArticlesWithArticles,
            request: JSON.stringify(articles),
            selectedArticles,
            toggleSelectedArticle,
            isArticleSelected,
            handleReplaceButton,
            onChangeQuantity,
            isErpChannelActivated: true,
        }),
        [oeArticlesWithArticles, articles, selectedArticles, toggleSelectedArticle, isArticleSelected, handleReplaceButton, onChangeQuantity]
    )

    return (
        <ArticleListFeaturesDisableProvider disableErpChannel={false}>
            <ParticularStateProvider value={context}>
                <PureList listId="damageModulesList" listType="damageModulesList" />
            </ParticularStateProvider>
        </ArticleListFeaturesDisableProvider>
    )
}
