import { AttributeFilterModel, EFilterNames, FilterNames, TyreFilter } from "@tm/models"
import { VehRecords } from "../../components/moduleManager/business"
import { Filters } from "../../components/summary/business"

export function mapVehicleRecordsResponse(attributeFilters: Array<AttributeFilterModel>, filters: Filters) {
    const widthGroupId = filters.width.first()?.groupId
    const heightGroupId = filters.height.first()?.groupId
    const inchGroupId = filters.inch.first()?.groupId

    const widthRecords = attributeFilters.find((x) => x.id === widthGroupId)
    const heightRecords = attributeFilters.find((x) => x.id === heightGroupId)
    const inchRecords = attributeFilters.find((x) => x.id === inchGroupId)

    const vehRecords: VehRecords = {
        ...(widthRecords && {
            width: mapAttributeFilterModelToTyreFilter(EFilterNames.width, widthRecords),
        }),
        ...(heightRecords && {
            height: mapAttributeFilterModelToTyreFilter(EFilterNames.height, heightRecords),
        }),
        ...(inchRecords && {
            inch: mapAttributeFilterModelToTyreFilter(EFilterNames.inch, inchRecords),
        }),
        // speedIndex: mapAttributeFilterModeltoTireFilter(vehRecords.find(x => x.id == FilterIDS.SpeedIndex)),
        // loadIndex: mapAttributeFilterModeltoTireFilter(vehRecords.find(x => x.id == FilterIDS.LoadIndex)),
        // oeIdentifier: mapAttributeFilterModeltoTireFilter(vehRecords.find(x => x.id == FilterIDS.OeIdentifier)),
    }

    return vehRecords
}

function mapAttributeFilterModelToTyreFilter(group: FilterNames, attr?: AttributeFilterModel): TyreFilter | undefined {
    if (!attr) {
        return undefined
    }

    return {
        group,
        groupId: attr.id,
        value: attr.value,
        query: attr.value,
    }
}
