import { Article } from "@tm/models"
import { useCallback, useMemo } from "react"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { mapDistinctValues } from "../../helpers"
import { BaseArticleData } from "../../models"

export type ArticlePage = {
    data: Article[]
    nextPageIndex: number | undefined
}

export const QUERY_KEY = "listv2.articles"

export type QueryKey<T> = [string, string, T | null]

type GetArticlesFunction<TRequest, TResponse extends ArticlePage> = (context: QueryFunctionContext<QueryKey<TRequest>>) => Promise<TResponse>

export function useArticlesBase<TRequest, TResponse extends ArticlePage>(
    request: TRequest | null,
    getArticles: GetArticlesFunction<TRequest, TResponse>,
    queryKey: string,
    selectFunc?: (articles: TResponse["data"]) => TResponse["data"]
): BaseArticleData {
    const articlesQuery = useInfiniteQuery({
        enabled: !!request,
        queryKey: [QUERY_KEY, queryKey, request],
        queryFn: getArticles,
        getNextPageParam(lastPage) {
            return lastPage.nextPageIndex
        },
        select: (data) => {
            if (!selectFunc) {
                return data
            }

            return {
                ...data,
                pages: data.pages.map((page) => ({
                    ...page,
                    data: selectFunc(page.data),
                })),
            }
        },
        cacheTime: 0,
    })

    const { hasNextPage = false, fetchNextPage } = articlesQuery
    const isLoaded = !!request && (articlesQuery.isSuccess || articlesQuery.isError)
    const articles = useMemo(() => articlesQuery.data?.pages.flatMap((page) => page.data) ?? [], [articlesQuery.data])

    const supplierIds = useMemo(() => mapDistinctValues(articles, (x) => x.supplier.id), [articles])
    const productGroupIds = useMemo(() => mapDistinctValues(articles, (x) => x.productGroup.id), [articles])

    const loadNextPage = useCallback(() => {
        fetchNextPage()
    }, [fetchNextPage])

    return {
        requestStatus: articlesQuery.status,
        isEnabled: true,
        isLoading: (articlesQuery.isIdle && articlesQuery.isSuccess) || articlesQuery.isLoading,
        isFetchingNextPage: articlesQuery.isFetchingNextPage,
        isLoaded,
        isSuccess: articlesQuery.isSuccess,
        isFailed: isLoaded && !articles.length,
        articleCount: !hasNextPage ? articles.length : Infinity,
        pageCount: articlesQuery.data?.pages.length ?? 0,
        articles,
        supplierIds,
        productGroupIds,
        loadNextPage,
        hasNextPage,
    }
}
