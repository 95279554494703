import { useUser } from "@tm/context-distribution"
import { useMemo } from "react"
import { ExternalModule, LinkTarget } from "@tm/models"
import { MdmMenuEntrys } from "../usersettings-menu/components/AdditionalMdmMenuItems"

export type ExternalModuleMdmMenu = ExternalModule & {
    target?: LinkTarget
}

export function useMdmMenuEntrys() {
    const { userContext } = useUser()

    const getLinkTarget = (extModule: ExternalModule) => {
        const linkTarget = extModule.parameter?.find((para) => para.key === "Target")
        if (linkTarget) {
            return linkTarget?.value as LinkTarget
        }
        return LinkTarget.None
    }

    return useMemo(() => {
        // only run if we have external modules and mdmEntrys are empty
        if (userContext.externalModules) {
            const nonSortingEntrys: ExternalModuleMdmMenu[] = []

            // find all externalModuls for grouping and sort them
            const mdmGroups: MdmMenuEntrys[] = userContext.externalModules
                .filter((exMod) => exMod.parameter?.find((para) => para.key === "ExternalSystemType" && para.value === "14"))
                .sort((a, b) => {
                    const aSort = a.parameter?.find((para) => para.key === "GroupSorting")?.value
                    const bSort = b.parameter?.find((para) => para.key === "GroupSorting")?.value

                    if (!aSort || !bSort) {
                        return 1
                    }

                    return parseInt(aSort) > parseInt(bSort) ? 1 : -1
                })
                .map((mod) => ({ name: mod.description, order: mod.parameter?.find((para) => para.key === "GroupSorting")?.value, entrys: [] }))

            // find all modules that should be displayed in the settings menu
            const externeDisplayMenu: ExternalModuleMdmMenu[] = userContext.externalModules?.filter((exMod) =>
                exMod.parameter?.find((para) => para.key === "DisplayInSettings" && para.value === "500")
            )

            if (externeDisplayMenu) {
                // for all settings entrys, get the first id of the groupsorting and add it to the groupEntry
                externeDisplayMenu.forEach((entry) => {
                    const groupIndex = entry.parameter?.find((para) => para.key === "GroupSorting")?.value.substring(0, 1)
                    const group = mdmGroups.find((group) => group.order === groupIndex)

                    const menuEntry = { ...entry, target: getLinkTarget(entry) }

                    if (group) {
                        group.entrys.push(menuEntry)
                    } else {
                        nonSortingEntrys.push(menuEntry)
                    }
                })
                // sort all entrys in group
                mdmGroups.forEach((entry) => {
                    entry.entrys.sort((a, b) => {
                        const aSortNumber = a.parameter?.find((para) => para.key === "GroupSorting")?.value.substring(2, 3)
                        const bSortNumber = b.parameter?.find((para) => para.key === "GroupSorting")?.value.substring(2, 3)
                        if (!aSortNumber || !bSortNumber) {
                            return 1
                        }

                        return parseInt(aSortNumber) > parseInt(bSortNumber) ? 1 : -1
                    })
                })

                if (nonSortingEntrys.length > 0) {
                    mdmGroups.push({ entrys: nonSortingEntrys, name: "", order: "999" })
                }
            }

            return mdmGroups
        }
    }, [userContext.externalModules])
}
