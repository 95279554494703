import { getBundleParams } from "@bundles/tyres/utils"
import { Box, styled } from "@tm/components"
import { PanelSection } from "@tm/controls"
import { useCallback } from "react"
import { Suggestions } from "./suggestions"
import { SummaryHeader } from "./SummaryHeader"
import { FiltersComponent } from "./filters"
import { SummaryProps } from "../component"
import { vehicleRedirect } from "../business"

export function Summary(props: SummaryProps) {
    const { isFiltersOnRightSide, useNewNavbar } = getBundleParams()

    const handleVehicleRedirect = useCallback(() => {
        vehicleRedirect(props)
    }, [props])

    return (
        <StyledWrapper>
            {!useNewNavbar && <SummaryHeader />}
            <StyledContent isFiltersOnRightSide={isFiltersOnRightSide}>
                <StyledPanelSection>
                    <FiltersComponent />
                </StyledPanelSection>
                <StyledSuggestions isFiltersOnRightSide={isFiltersOnRightSide} onVehicleRedirect={handleVehicleRedirect} />
            </StyledContent>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
})

const StyledContent = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isFiltersOnRightSide",
})<{ isFiltersOnRightSide?: boolean }>(({ isFiltersOnRightSide }) => ({
    display: "flex",
    flex: 1,
    flexDirection: isFiltersOnRightSide ? "row-reverse" : "row",
}))

const StyledPanelSection = styled(PanelSection)(({ theme }) => ({
    flexBasis: "22em",
    padding: theme.margin?.m,
}))

const StyledSuggestions = styled(Suggestions, {
    shouldForwardProp: (prop) => prop !== "isFiltersOnRightSide",
})<{ isFiltersOnRightSide?: boolean }>(({ isFiltersOnRightSide, theme }) => ({
    marginLeft: theme.margin?.m,
    marginRight: isFiltersOnRightSide ? theme.margin?.m : undefined,
    flex: 1,
}))
