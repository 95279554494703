import { RegistrationNoType, VehicleType, TruckModuleType } from "@tm/models"
import { createQueryString, encodeUniqueId, parseQueryString, renderRoute, uniqueId } from "@tm/utils"

import { getBundleParams } from "../utils"
import { VehicleParams } from "../components/search/OldSearchResultList"
import { SkippedRegNoSearches } from "../business"

type QueryParamType = string | number | boolean | undefined
type QueryParams = Record<string, QueryParamType>

function createParams(original: QueryParams, additional?: QueryParams) {
    return { ...original, workTaskId: original.workTaskId || encodeUniqueId(uniqueId()), ...additional }
}

type VehicleIdentificationParams = {
    vehicleType: VehicleType
    manufacturerId?: number
    mainModelSeriesId?: number
    modelSeriesId?: number
    modelId?: number
}
export function getVehicleIdentificationUrlWithLookup(matchParams: QueryParams, params: VehicleIdentificationParams) {
    let search = ""

    if (window.location.search) {
        search = `${window.location.search}`
    }

    return renderRoute(getBundleParams().vehiclesRoute, createParams(matchParams, params)) + search
}

export function getVehicleIdentificationUrl(matchParams: QueryParams, params: VehicleIdentificationParams, route?: string) {
    // Skip the mainModelSeries step, when hideMainModelSeries is active
    if (params.manufacturerId && params.mainModelSeriesId === undefined && getBundleParams().hideMainModelSeries) {
        params = { ...params, mainModelSeriesId: 0 }
    }
    return renderRoute(route ?? getBundleParams().vehiclesRoute, createParams(matchParams, params)) + (window.location.search || "")
}

type VehicleIdentificationRawParams = {
    vehicleType: string
    manufacturerId?: string
    mainModelSeriesId?: string
    modelSeriesId?: string
    modelId?: string
}

export function getValidatedVehicleIdentificationParams(params: VehicleIdentificationRawParams): VehicleIdentificationParams {
    const { vehicleType, manufacturerId, mainModelSeriesId, modelSeriesId, modelId } = params

    return {
        vehicleType: vehicleType && !Number.isNaN(parseInt(vehicleType)) ? parseInt(vehicleType) : 1,
        manufacturerId: manufacturerId && !Number.isNaN(parseInt(manufacturerId)) ? parseInt(manufacturerId) : undefined,
        mainModelSeriesId: mainModelSeriesId && !Number.isNaN(parseInt(mainModelSeriesId)) ? parseInt(mainModelSeriesId) : undefined,
        modelSeriesId: modelSeriesId && !Number.isNaN(parseInt(modelSeriesId)) ? parseInt(modelSeriesId) : undefined,
        modelId: modelId && !Number.isNaN(parseInt(modelId)) ? parseInt(modelId) : undefined,
    }
}

type VehicleSearchParams = {
    vehicleType: VehicleType
    query: string
    registrationNoType?: RegistrationNoType
    checkAndMergeRegNoDetails?: boolean
    forceUpdate?: boolean
}

export function getVehicleSearchUrl(matchParams: QueryParams, params: VehicleSearchParams) {
    const { forceUpdate, registrationNoType, checkAndMergeRegNoDetails, ...rest } = params

    let url = renderRoute(getBundleParams().vehicleSearchUrl, createParams(matchParams, { ...rest, query: encodeURIComponent(rest.query) }))

    const queryParams: QueryParams = (parseQueryString(window.location.search) as QueryParams) ?? {}

    // we need to remove it from the url, and only add RegNoType if its set over params
    // build for NEXT-28665
    delete queryParams[VehicleParams.RegNoType]

    if (registrationNoType !== undefined) {
        queryParams[VehicleParams.RegNoType] = registrationNoType
    }

    if (checkAndMergeRegNoDetails) {
        queryParams[VehicleParams.CheckAndMergeRegNoDetails] = true
    }

    if (forceUpdate) {
        queryParams[VehicleParams.ForceUpdate] = true
    }

    if (Object.keys(queryParams).length) {
        url += createQueryString(queryParams)
    }

    return url
}

export const vehicleDetailsSubPageDatInfo = "datInfo"
export const vehicleDetailsSubPageMemoInfo = "memoInfo"
export const vehicleDetailsSubPageMemoTechnicalNotes = "memoTechnicalNotes"

type VehicleDetailsParams = {
    vehicleType: VehicleType
    vehicleId: string | number
    query?: string
    registrationNo?: string // contains e.g. "golf4" or "039557"
    registrationNoType?: RegistrationNoType // shows where this vehicle was found
    checkAndMergeRegNoDetails?: boolean
    plateId?: string
    vin?: string
    initialRegistration?: Date
}

export function getVehicleDetailsUrl(matchParams: QueryParams, params: VehicleDetailsParams) {
    const { query, registrationNo, registrationNoType, checkAndMergeRegNoDetails, plateId, vin, initialRegistration, ...rest } = params

    let url = renderRoute(
        getBundleParams().vehicleDetailsUrl,
        createParams(matchParams, { ...rest }) // spreading only needed because TypeScript otherwise shows an error
    )

    const queryParams: QueryParams = {}

    if (query) {
        queryParams[VehicleParams.SearchQuery] = query
    }

    if (registrationNoType !== undefined && !SkippedRegNoSearches.includes(registrationNoType)) {
        queryParams[VehicleParams.RegNo] = registrationNo
        queryParams[VehicleParams.RegNoType] = registrationNoType
    }

    if (checkAndMergeRegNoDetails) {
        queryParams[VehicleParams.CheckAndMergeRegNoDetails] = true
    }

    if (plateId) {
        queryParams[VehicleParams.PlateId] = plateId
    }

    if (vin) {
        queryParams[VehicleParams.Vin] = vin
    }

    if (initialRegistration) {
        queryParams[VehicleParams.InitialRegistration] = initialRegistration.toISOString()
    }

    if (Object.keys(queryParams).length) {
        url += createQueryString(queryParams)
    }

    return url
}

export function getTruckModuleUrl(matchParams: QueryParams, type: TruckModuleType) {
    const { truckModuleRoutes } = getBundleParams()
    const path = truckModuleRoutes?.[type]
    if (!path) {
        return ""
    }
    return renderRoute(path, createParams(matchParams))
}
