import { Booking, BookingCalculation, LabourValues, SelectedWork } from "../../models"
import { ArticleForOe, CalculationsMapped, NotFoundArticle, OeProductGroup } from "../../models/calculation"
import { MainSlice } from "./model"

export function moduleOpened(state: MainSlice): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            moduleOpened: true,
        },
    }
}

export function setWorktaskId(state: MainSlice, worktaskId: string): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            worktaskId,
        },
    }
}

export function setShowLoginDialog(state: MainSlice, showLoginDialog: boolean): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            showLoginDialog,
        },
    }
}

export function setIsLoginDone(state: MainSlice, isLoginDone: boolean): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            isLoginDone,
        },
    }
}

export function drivemotiveLogin(state: MainSlice): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            showLoginDialog: false,
        },
    }
}

export function changeStep(state: MainSlice, navigationStep: string): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            navigationStep,
        },
    }
}

export function makeCalculationTabAvailable(state: MainSlice) {
    return {
        main: {
            ...state.main,
            isCalculationTabAvailable: true,
        },
    }
}

export function setBookingsList(state: MainSlice, bookings: Booking[]): Partial<MainSlice> {
    return {
        summary: {
            ...state.summary,
            bookings,
        },
    }
}

export function setSelectedBooking(state: MainSlice, selectedBooking: Booking): Partial<MainSlice> {
    return {
        summary: {
            ...state.summary,
            selectedBooking,
        },
        main: {
            ...state.main,
            isCalculationTabAvailable: false,
        },
    }
}

export function setShowBookingDetails(state: MainSlice, showBookingDetails: boolean): Partial<MainSlice> {
    return {
        summary: {
            ...state.summary,
            showBookingDetails,
        },
    }
}

export function setSelectedBookingMessage(state: MainSlice, bookingMessage: string): Partial<MainSlice> {
    if (state.summary.selectedBooking) {
        return {
            summary: {
                ...state.summary,
                selectedBooking: {
                    ...state.summary.selectedBooking,
                    message: bookingMessage,
                },
            },
        }
    }

    return state
}

export function setSelectedBookingPreferredTime(state: MainSlice, prefferedTime: string): Partial<MainSlice> {
    if (state.summary.selectedBooking) {
        return {
            summary: {
                ...state.summary,
                selectedBooking: {
                    ...state.summary.selectedBooking,
                    preferred_time: prefferedTime,
                },
            },
        }
    }

    return state
}

export function setCalculationArticlesAndWorks(state: MainSlice, bookingCalculations: BookingCalculation[] = []) {
    let workshopRate = 1
    if (bookingCalculations[0]?.calculations_id?.workshop_rates && bookingCalculations[0]?.calculations_id?.workshop_rates[0].hour_rate) {
        workshopRate = bookingCalculations[0]?.calculations_id?.workshop_rates[0].hour_rate
    }

    const calculations = bookingCalculations.map((calculation) => {
        const summaryArticles = calculation?.calculations_id?.required_parts?.parts
        const summaryWorks = calculation?.calculations_id?.selected_works

        const totalPriceArticles = {
            totalQuantity: 0,
            value: 0,
        }

        const totalPriceWorks = {
            totalQuantity: 0,
            value: 0,
        }

        if (summaryArticles && summaryArticles.length > 0) {
            totalPriceArticles.totalQuantity = summaryArticles.length
            summaryArticles.forEach((part) => {
                part.alternatives?.forEach((alternative) => {
                    alternative.parts?.forEach((alternativePart) => {
                        if (alternativePart.partPrice) {
                            totalPriceArticles.value += alternativePart.partPrice
                        }
                    })
                })
            })
        }

        let totalHoursWorked = 0
        if (summaryWorks && summaryWorks.length > 0) {
            totalPriceWorks.totalQuantity = summaryWorks.length
            summaryWorks.forEach((work) => {
                if (work.hours) {
                    totalHoursWorked += work.hours

                    totalPriceWorks.value += work.hours * workshopRate
                }
            })
        }

        return {
            totalPriceArticles,
            summaryArticles,
            summaryWorks,
            totalPriceWorks,
            totalHoursWorked,
            title: calculation.calculations_id.description,
        }
    })

    return {
        summary: {
            ...state.summary,
            calculations,
            hourRate: workshopRate,
        },
    }
}

export function setSelectedArticleAndWork(state: MainSlice, selectedArticleAndWork?: CalculationsMapped) {
    return {
        summary: {
            ...state.summary,
            selectedArticleAndWork,
        },
        calculation: {
            ...state.calculation,
            calculationWorks: selectedArticleAndWork?.summaryWorks,
        },
    }
}

export function setSelectedLabourValues(state: MainSlice, labourValues?: LabourValues[]): Partial<MainSlice> {
    if (state.summary.selectedBooking) {
        return {
            summary: {
                ...state.summary,
                selectedBooking: {
                    ...state.summary.selectedBooking,
                    labour_values: labourValues,
                },
            },
        }
    }

    return state
}

export function changeQuantity(state: MainSlice, item: ArticleForOe | NotFoundArticle, quantity: number): Partial<MainSlice> {
    const newParts = state.calculation.calculationArticles?.map((productGroup) => {
        const articles = productGroup.articles.map((part) => {
            if (item.oeNumber === part.oeNumber) {
                return { ...part, quantity }
            }
            return part
        })
        return { ...productGroup, articles }
    })

    let totalSumParts = 0
    newParts?.map((article) =>
        article.articles.forEach((part) => {
            if (part.article?.artPreise?.[0].preis) {
                totalSumParts += part.article.artPreise?.[0].preis * part.quantity
            }
        })
    )

    const articlesPrice = {
        totalQuantity: newParts?.flatMap((part) => part.articles).length,
        value: totalSumParts,
    }

    let totalSumRepairTimes = 0
    state.calculation.calculationWorks?.forEach((work) => {
        if (work.hours && state.summary.hourRate) {
            totalSumRepairTimes += work.hours * state.summary.hourRate
        }
    })

    let totalFixedPrices = 0
    state.summary.selectedBooking?.labour_values?.forEach((labourValue) => {
        if (labourValue?.price && labourValue?.title) {
            totalFixedPrices += labourValue.price
        }
    })

    const repairTimesPrice = {
        totalQuantity: state.calculation.calculationWorks?.length,
        value: totalSumRepairTimes,
    }

    const fixedPrice = {
        totalQuantity: state.summary.selectedBooking?.services.length,
        value: totalFixedPrices,
    }

    const totalPrice = {
        totalQuantity: 0,
        value: totalSumParts + totalSumRepairTimes + totalFixedPrices,
    }

    return {
        calculation: {
            ...state.calculation,
            calculationArticles: newParts,
            totals: {
                ...state.calculation.totals,
                articlesPrice,
                repairTimesPrice,
                fixedPrice,
                totalPrice,
            },
        },
    }
}

export function setCalculationArticles(state: MainSlice, articles?: OeProductGroup[]) {
    if ((articles && articles?.length > 0) || !!state.summary.selectedBooking?.labour_values?.length) {
        const articleList = state.calculation.calculationArticles?.length ? state.calculation.calculationArticles : articles
        let totalSumParts = 0
        articleList?.map((article) =>
            article.articles.forEach((item) => {
                if (item.article?.artPreise?.[0].preis) {
                    totalSumParts += item.article.artPreise?.[0].preis * item.quantity
                }
            })
        )

        const articlesPrice = {
            totalQuantity: articleList?.flatMap((item) => item.articles).length,
            value: totalSumParts,
        }

        let totalFixedPrices = 0
        state.summary.selectedBooking?.labour_values?.forEach((labourValue) => {
            if (labourValue?.price && labourValue?.title) {
                totalFixedPrices += labourValue.price
            }
        })

        let totalSumRepairTimes = 0
        state.calculation.calculationWorks?.forEach((work) => {
            if (work.hours && state.summary.hourRate) {
                totalSumRepairTimes += work.hours * state.summary.hourRate
            }
        })

        const repairTimesPrice = {
            totalQuantity: state.calculation.calculationWorks?.length,
            value: totalSumRepairTimes,
        }

        const fixedPrice = {
            totalQuantity: state.summary.selectedBooking?.services.length,
            value: totalFixedPrices,
        }

        const totalPrice = {
            totalQuantity: 0,
            value: totalSumParts + totalSumRepairTimes + totalFixedPrices,
        }

        return {
            calculation: {
                ...state.calculation,
                calculationArticles: articleList,
                totals: {
                    ...state.calculation.totals,
                    articlesPrice,
                    repairTimesPrice,
                    fixedPrice,
                    totalPrice,
                },
            },
        }
    }
    return {
        calculation: {
            ...state.calculation,
            calculationArticles: [],
            totals: {
                ...state.calculation.totals,
                articlesPrice: {
                    totalQuantity: 0,
                    value: 0,
                },
            },
        },
    }
}

export function deleteArticleFromCalculation(state: MainSlice, article?: ArticleForOe, notFoundArticle?: NotFoundArticle) {
    let parts: OeProductGroup[] | undefined = state.calculation.calculationArticles

    if (article) {
        parts = state.calculation.calculationArticles?.map((part) => {
            const articles: ArticleForOe[] = part.articles.filter((item) => item.oeNumber !== article.oeNumber)
            return { ...part, articles }
        })
    }

    if (notFoundArticle) {
        if (state.calculation.calculationArticles?.some((art) => "label" in art && art.label.length)) {
            parts = state.calculation.calculationArticles.filter((calculationArticle) => calculationArticle.label !== notFoundArticle.label)
        } else if (
            state.calculation.calculationArticles?.some((calculationArticle) => calculationArticle.articles.some((article) => "oeNumber" in article))
        ) {
            parts = state.calculation.calculationArticles?.map((calculationArticle) => {
                const filteredArticles = calculationArticle.articles.filter((art) => art.oeNumber !== notFoundArticle.oeNumber)

                return {
                    ...calculationArticle,
                    articles: filteredArticles,
                }
            })
        }
    }

    let totalSumParts = 0
    parts?.map((part) =>
        part.articles.forEach((item) => {
            if (item.article?.artPreise?.[0].preis) {
                totalSumParts += item.article.artPreise?.[0].preis * item.quantity
            }
        })
    )

    const articlesPrice = {
        totalQuantity: parts?.flatMap((item) => item.articles).length,
        value: totalSumParts,
    }

    let totalSumRepairTimes = 0
    state.calculation.calculationWorks?.forEach((work) => {
        if (work.hours && state.summary.hourRate) {
            totalSumRepairTimes += work.hours * state.summary.hourRate
        }
    })

    let totalFixedPrices = 0
    state.summary.selectedBooking?.labour_values?.forEach((labourValue) => {
        if (labourValue?.price && labourValue?.title) {
            totalFixedPrices += labourValue.price
        }
    })

    const repairTimesPrice = {
        totalQuantity: state.calculation.calculationWorks?.length,
        value: totalSumRepairTimes,
    }

    const fixedPrice = {
        totalQuantity: state.summary.selectedBooking?.services.length,
        value: totalFixedPrices,
    }

    const totalPrice = {
        totalQuantity: 0,
        value: totalSumParts + totalSumRepairTimes + totalFixedPrices,
    }

    return {
        calculation: {
            ...state.calculation,
            calculationArticles: parts,
            totals: {
                ...state.calculation.totals,
                articlesPrice,
                repairTimesPrice,
                fixedPrice,
                totalPrice,
            },
        },
    }
}

export function deleteWorkFromCalculation(state: MainSlice, work: SelectedWork) {
    const newWorks = state.calculation.calculationWorks?.filter((stateWork) => stateWork.workId !== work.workId)

    let totalSumParts = 0
    state.calculation.calculationArticles?.map((article) =>
        article.articles.forEach((part) => {
            if (part.article?.artPreise?.[0].preis) {
                totalSumParts += part.article.artPreise?.[0].preis * part.quantity
            }
        })
    )

    const articlesPrice = {
        totalQuantity: state.calculation.calculationArticles?.flatMap((part) => part.articles).length,
        value: totalSumParts,
    }

    let totalSumRepairTimes = 0
    newWorks?.forEach((newWork) => {
        if (newWork.hours && state.summary.hourRate) {
            totalSumRepairTimes += newWork.hours * state.summary.hourRate
        }
    })

    let totalFixedPrices = 0
    state.summary.selectedBooking?.labour_values?.forEach((labourValue) => {
        if (labourValue?.price && labourValue?.title) {
            totalFixedPrices += labourValue.price
        }
    })

    const fixedPrice = {
        totalQuantity: state.summary.selectedBooking?.services.length,
        value: totalFixedPrices,
    }

    const repairTimesPrice = {
        totalQuantity: newWorks?.length,
        value: totalSumRepairTimes,
    }

    const totalPrice = {
        totalQuantity: 0,
        value: totalSumParts + totalSumRepairTimes + totalFixedPrices,
    }
    return {
        calculation: {
            ...state.calculation,
            calculationWorks: newWorks,
            totals: {
                ...state.calculation.totals,
                articlesPrice,
                repairTimesPrice,
                fixedPrice,
                totalPrice,
            },
        },
    }
}
