import { KeyValueStateContainer, RegisteredModels, TyresBundleParams } from "@tm/models"
import { Container } from "@tm/nexus"
import { DEFAULT_STATE as DEFAULT_SUMMARY_STATE } from "./components/summary/business"
import { bundleChannel } from "./data/channels"
import { getCurrentSeason } from "./data/helpers"
import { Statics } from "./data/statics"

export const version = {
    name: "tyres",
    version: "2.0",
}

function mapParams(params: TyresBundleParams): TyresBundleParams {
    return {
        ...params,
        pageSize: +params.pageSize || 25,
        maxAutoRequest: params.maxAutoRequest || 3,
        minAvailableItems: params.minAvailableItems || 4,
        traderReferenceId: params.traderReferenceId,
    }
}

let bundleParams: TyresBundleParams

export function initTyresBundle(params: TyresBundleParams) {
    bundleParams = mapParams(params)

    // TODO: Maybe find a better way to initialize the default states?
    const carTypes = Statics.getCarTypes()
    DEFAULT_SUMMARY_STATE.filters.carType = carTypes
    DEFAULT_SUMMARY_STATE.selectedFilters.carType = carTypes
    DEFAULT_SUMMARY_STATE.selectedFilters.season = Statics.seasons?.filter((x) => x.query === getCurrentSeason())

    // Check required bundle params
    const requiredParams: Array<keyof TyresBundleParams> = ["articleListServiceUrlByQuery", "tyresCritsServiceUrl", "tyresServiceUrl"]
    requiredParams.forEach((x) => {
        if (!params[x]) {
            console.warn(`${version.name}: The parameter '${x}' has to be set in the configuration`)
        }
    })

    const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
    container
        .action("loadKeyValue")("TYRES_CLIPPED_FILTERS")
        .then((data) => {
            bundleChannel().publish("LOAD_TYRES_CLIPPED_FILTERS", { source: "manager", value: +data })
        })

    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
