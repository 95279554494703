import { ArticleInfoType, CostEstimationOptions } from "@tm/models"
import PartDialogManager from "./PartDialogManager"
import { CustomPartDialogTrigger } from "./CustomPartDialogTrigger"
import { BasketPart, BasicBasketPartInfo } from "../../../../../models"

type Props = {
    part: BasketPart
    currencyCode: string
    costEstimationOptions?: CostEstimationOptions
    hidePurchasePrice: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    disableControls?: boolean
    isLinkedItem?: boolean
    creatingCostEstimation: boolean
    onEditPart?(part: BasketPart, editBasketPartInfo: BasicBasketPartInfo): void
}

export default function PartEditButton(props: Props) {
    const {
        hidePurchasePrice,
        showManufacturer,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        costEstimationOptions,
        part,
        currencyCode,
        disableControls,
        isLinkedItem,
        creatingCostEstimation,
        onEditPart,
    } = props
    const { costEstimationItem, articleInformation } = part.partItem

    const disable = !costEstimationItem?.isIncluded || disableControls || isLinkedItem || creatingCostEstimation

    if (articleInformation.articleInfoType === ArticleInfoType.CustomArticle) {
        return (
            <CustomPartDialogTrigger
                disabled={disable}
                hidePurchasePrice={hidePurchasePrice}
                costEstimationOptions={costEstimationOptions}
                part={part}
                currencyCode={currencyCode}
                onEditPart={onEditPart}
            />
        )
    }
    return (
        <PartDialogManager
            disabled={disable}
            currencyCode={currencyCode}
            hidePurchasePrice={hidePurchasePrice}
            part={part}
            showManufacturer={showManufacturer}
            showSupplierArticleNumbers={showSupplierArticleNumbers}
            showWholesalerArticleNumbers={showWholesalerArticleNumbers}
            onEditPart={onEditPart}
        />
    )
}
