import { Typography } from "@tm/components"
import { ElementType, PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import { style } from "typestyle"

export type TextLinkProps = PropsWithChildren<{
    size?: "l"
    to?: string
    onClick?: () => void
    closeMenu: () => void
    component: ElementType
}>

export default function TextLink(props: TextLinkProps) {
    const handleClick = () => {
        props.onClick?.()
        props.closeMenu()
    }

    const contentClassName = style({ display: "flex", alignItems: "center" })
    let content = (
        <div className={contentClassName} onClick={handleClick}>
            {props.children}
        </div>
    )
    if (!props.onClick && props.to) {
        content = (
            <Link className={contentClassName} onClick={handleClick} to={props.to}>
                {props.children}
            </Link>
        )
    }

    return (
        <Typography modifiers="block" size={props.size} component={props.component}>
            {content}
        </Typography>
    )
}
