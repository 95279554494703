import { useStyle } from "@tm/context-distribution"
import { Headline, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { rem } from "csx"
import { useSelector } from "react-redux"
import { classes } from "@tm/utils"
import { FC } from "react"
import { statusDataSelector } from "../../../main/business"
import { MaintenanceItem } from "./components"

const MaintenanceItemsComponent: FC<any> = () => {
    const { translateText } = useLocalization()
    const statusData = useSelector(statusDataSelector)

    if (!statusData) {
        return null
    }

    const { engineCoolantFluidWarning, brakeFluidWarning } = statusData

    let content = (
        <div className={style.maintenanceItems}>
            {engineCoolantFluidWarning != undefined && <MaintenanceItem title={translateText(12500)} ok={!engineCoolantFluidWarning} />}
            {brakeFluidWarning != undefined && <MaintenanceItem title={translateText(12499)} ok={!brakeFluidWarning} />}
        </div>
    )

    if (!engineCoolantFluidWarning && !brakeFluidWarning) {
        content = (
            <div className={style.maintenanceItems}>
                <Text className={style.textPadding} size="l">
                    {translateText(12775)}
                </Text>
            </div>
        )
    }

    return (
        <div className={style.maintenance}>
            <Headline className={classes(style.title, style.textPadding)} size="l">
                {translateText(576)}
            </Headline>

            {content}
        </div>
    )
}

const style = useStyle({
    maintenance: {
        flex: 1,
    },
    maintenanceItems: {
        display: "flex",
    },
    textPadding: {
        padding: rem(1),
    },
    title: {
        borderBottom: "1px solid rgba(33,150,243,.32)",
    },
})(MaintenanceItemsComponent)

export default MaintenanceItemsComponent
