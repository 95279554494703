import { TyreFilter } from "@tm/models"
import { Box, Button, Icon } from "@tm/components"
import { FC, memo, useState } from "react"
import { isInfoAvailable } from "@bundles/tyres/data/helpers/infoDialogHelper"
import { useTyresV2 } from "@tm/context-distribution"
import { SelectionCheckbox } from "./selectionCheckbox"
import { InfoDialog } from "./InfoDialog"

type Props = {
    items: TyreFilter[]
    selectedValues?: TyreFilter[]
    searchValue?: string
    onChange(selectedValue: TyreFilter): void
}

export function SelectionItems({ items, searchValue, selectedValues, onChange }: Props) {
    const filteredItems = items.filter((x) => x.value && x.value.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase() ?? ""))

    return (
        <Box style={{ paddingRight: "0.35em" }}>
            {filteredItems.map((item) => (
                <SelectionBox key={`${item.group}_${item.value}`} onChange={onChange} selectedValues={selectedValues} item={item} />
            ))}
        </Box>
    )
}

type SelectionProps = {
    selectedValues?: TyreFilter[]
    item: TyreFilter
    onChange(selectedValue: TyreFilter): void
}

const SelectionBox: FC<SelectionProps> = memo(({ selectedValues, item, onChange }) => {
    const [displayDialog, setDisplayDialog] = useState(false)
    const tyresV2 = useTyresV2()
    const checked = !!selectedValues?.find((x) => x.group === item.group && item.value === x.value)

    const handleInfoClick = () => {
        setDisplayDialog(true)
    }

    const handleDialogCLose = () => {
        setDisplayDialog(false)
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <SelectionCheckbox
                    key={item.value}
                    label={item.value}
                    onChange={() => onChange(item)}
                    selected={checked}
                    info={item.info}
                    articleCount={tyresV2 ? item.articleCount : undefined}
                />

                {item.valueKey && isInfoAvailable(item.valueKey) && (
                    <Button
                        sx={{ display: "flex", justifyContent: "space-between" }}
                        variant="text"
                        onClick={handleInfoClick}
                        startIcon={<Icon name="info" />}
                        size="small"
                    />
                )}
            </Box>
            {displayDialog && item.valueKey && <InfoDialog filterId={item.valueKey} onDialogCLose={handleDialogCLose} />}
        </>
    )
})
