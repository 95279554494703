import { useLocalization } from "@tm/localization"
import { ConfirmationDialogKeys, CustomWork, WorkType } from "@tm/models"
import { Button, Checkbox, Icon, Stack, Switch } from "@tm/components"
import { WorkTaskInfo } from "@tm/context-distribution"
import DeleteButton from "../../../../_shared/DeleteButton"
import { BasicBasketWorkInfo, BasketWork } from "../../../../../models"
import { CostEstimation } from "../../../../../data/model"
import WorkDialogTrigger from "./WorkDialogTrigger"
import { EditWorkMode } from "../../../business"

type Props = {
    disableControls?: boolean
    work: BasketWork
    costEstimation?: CostEstimation
    showRepairTimesInHours: boolean
    workTask: WorkTaskInfo
    repairTimeDivision: number
    onIncludeExcludeWork(work: BasketWork): void
    onRemoveWorks(ids: string[]): void
    onResetRepairTimes(work: BasketWork): void
    onSelectWork(id: string): void
    onEditWork(work: BasketWork, repairTimeDivision: number, editWorkMode: EditWorkMode, editBasketWorkInfo: BasicBasketWorkInfo): void
    onReplaceWorkWithCustomWork(workToReplace: BasketWork, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string): void
}

export default function WorkActions(props: Props) {
    const {
        work,
        disableControls,
        costEstimation,
        showRepairTimesInHours,
        workTask,
        repairTimeDivision,
        onIncludeExcludeWork,
        onRemoveWorks,
        onResetRepairTimes,
        onSelectWork,
        onEditWork,
        onReplaceWorkWithCustomWork,
    } = props
    const { workItem, states } = work
    const { isIncluded, type, id } = workItem
    const { translateText } = useLocalization()

    return (
        <Stack direction="row" spacing={1}>
            <Switch
                label={translateText(49)}
                checked={isIncluded}
                labelPlacement="start"
                size="small"
                onChange={() => onIncludeExcludeWork(work)}
                disabled={disableControls}
            />
            <WorkDialogTrigger
                costEstimation={costEstimation}
                disabled={!isIncluded || disableControls}
                repairTimeDivision={repairTimeDivision}
                showRepairTimesInHours={showRepairTimesInHours}
                work={work}
                workTask={workTask}
                onEditWork={onEditWork}
                onReplaceWorkWithCustomWork={onReplaceWorkWithCustomWork}
            />
            <Button
                title={translateText(48)}
                onClick={() => onResetRepairTimes(work)}
                disabled={type !== WorkType.CustomMainWork || disableControls}
                startIcon={<Icon name="refresh" />}
                variant="text"
            />
            <DeleteButton
                ids={[id]}
                buttonTitleId={624}
                disableButton={disableControls}
                dialogTextId={836}
                onConfirmRemove={(ids) => ids && onRemoveWorks(ids)}
                confirmationKey={ConfirmationDialogKeys.DeleteBasketItem}
            />
            <Checkbox checked={states.isSelected || false} onChange={() => onSelectWork(id)} disabled={disableControls} />
        </Stack>
    )
}
