import { useLocalization } from "@tm/localization"
import { TableCellData, TableColumnData, TableRowData } from "@tm/components"
import { VoucherType } from "@tm/models"
import { useParams } from "react-router"
import VehicleInfo from "../../../../_shared/VehicleInfo"
import { CostEstimationVoucher } from "../../../../../data/model"
import { VoucherRouteParams } from "../../../../../business"
import { useOpenDetails } from "../../../../../hooks/useOpenDetails"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { StyledTable } from "../../../../_shared/StyledComponents"

type Props = {
    costEstimations: CostEstimationVoucher[]
    shortCountryCode: string
}

export default function CompactCostEstimationsList({ costEstimations, shortCountryCode }: Props) {
    const { translateText, date } = useLocalization()
    const params = useParams<VoucherRouteParams>()
    const selectedVoucherId = params.id && decodeURIComponent(params.id)
    const { openDetails } = useOpenDetails()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(470) }, // voucherNumber
            { header: translateText(98) }, // voucherCreationDate
            { header: translateText(99) }, // VehicleInfo
        ]
        return columns
    }

    function getCells(costEstimation: CostEstimationVoucher) {
        const data: TableCellData[] = [
            { displayValue: costEstimation.voucherNumber, id: "1", maxWidth: 80 },
            { displayValue: date(costEstimation.voucherCreationDate, "d"), id: "2" },
        ]

        data.push({
            displayValue: (
                <VehicleInfo
                    vehicleDescription={costEstimation.vehicleDescription}
                    vehicleMileage={costEstimation.vehicleMileAge}
                    vehiclePlateId={costEstimation.vehiclePlateId}
                    shortCountryCode={shortCountryCode}
                    licensePlateOnTop
                />
            ),
            id: "3",
        })
        return data
    }

    function onRowClick(row: TableRowData) {
        openDetails(row.id, VoucherType.CostEstimation)
    }

    const displayData = costEstimations.map((costEstimation) => ({
        cells: getCells(costEstimation),
        id: `${costEstimation.id}`,
        customRow: false,
        active: selectedVoucherId === costEstimation.id,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable variant="small" columns={getColumns()} rows={displayData} rowCap={4} onRowClick={onRowClick} />
}
