import { useInfiniteQuery } from "react-query"
import { useState, useMemo, useEffect } from "react"
import { ArticleErpInfo, GetArticleListByMatchCodeRequest, TyreArticle } from "@tm/models"
import { ArticleSorting, useSortArticles } from "@bundles/parts/components/ListV2/hooks/useSortArticles"
import { useAvailabilityStatus, useWorkTaskId } from "@tm/context-distribution"
import { TyresListType, useParticularContext } from "@bundles/parts/components/PureList/ContextProviders/Particular/ParticularContext"
import { AvailabilityFilterType } from "@bundles/parts/business"
import { UniArticles } from "@tm/data"
import { useDefaultErpSystem } from "@bundles/parts/components/ListV2/hooks"
import { emptyFunc } from "@bundles/parts/components/ListV2/helpers"

import { BaseArticleData } from "../../../ListV2/models"
import { filterArticlesByAvailability } from "../../helpers/filterArticlesByAvailability"

export type TyresListData = BaseArticleData<TyreArticle> & {
    sorting?: ArticleSorting
    totalArticlesNumber?: number
}

export function useTyresList(erpInfos: ArticleErpInfo[], defaultQuantity?: number): TyresListData {
    const contextProvider = useParticularContext<TyresListType>()
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()
    const [failMessage, setFailMessage] = useState<string | undefined>()
    const [filteredArticles, setFilteredArticles] = useState<TyreArticle[]>([])
    const { request, selectedAvailability, maxAutoRequest } = contextProvider ?? {}
    const [fetchedXTimes, setFetchedXTimes] = useState(0)

    const workTaskId = useWorkTaskId()

    const isEnabled = !!request

    const defaultErpSystemId = useDefaultErpSystem()?.id
    const availabilityFilterChecked = selectedAvailability === AvailabilityFilterType.Primary
    const secondAvailabilityFilterChecked = selectedAvailability === AvailabilityFilterType.Secondary

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, isFetching, isSuccess, isError } = useInfiniteQuery({
        queryKey: ["tyresArticlesList", workTaskId, request?.selectedCriteria, selectedAvailability],
        queryFn: async ({ pageParam = 1 }) => {
            try {
                const response = await UniArticles.getArticleListByMatchCode(
                    {
                        ...(request as GetArticleListByMatchCodeRequest),
                        pageIndex: pageParam,
                    },
                    defaultQuantity
                )
                return { ...response, pageParam }
            } catch (error) {
                if (error instanceof Error) {
                    setFailMessage(error.message)
                }
            }
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage?.uniArticles || lastPage.uniArticles.length === 0) {
                return undefined
            }
            const { articleListCount, pageIndex, pageSize } = lastPage

            if (articleListCount < pageSize * pageIndex) {
                return undefined
            }
            return allPages.length + 1
        },
        enabled: isEnabled,
        keepPreviousData: true,
    })

    const articles = useMemo(() => data?.pages?.map((x) => x?.uniArticles ?? []).flat(), [data])

    const applyFilter = () => {
        if (articles?.length) {
            if (selectedAvailability && defaultErpSystemId && availabilityStatusIdsToShow) {
                const filteredData = filterArticlesByAvailability(
                    articles,
                    erpInfos,
                    defaultErpSystemId,
                    availabilityFilterChecked,
                    secondAvailabilityFilterChecked,
                    availabilityStatusIdsToShow,
                    availabilityStatusIdsToShowSecondary
                )
                setFilteredArticles(filteredData as TyreArticle[])
            } else {
                setFilteredArticles(articles)
            }
        }
    }

    useEffect(() => {
        applyFilter()
    }, [articles, selectedAvailability, request])

    useEffect(() => {
        if (!request || !selectedAvailability || isFetchingNextPage || isFetching) {
            return
        }

        if (!hasNextPage || !maxAutoRequest || filteredArticles.length >= request.pageSize || fetchedXTimes >= maxAutoRequest - 1) {
            return
        }

        fetchNextPage()
        setFetchedXTimes((prev) => prev + 1)
    }, [
        isFetching,
        isFetchingNextPage,
        selectedAvailability,
        request,
        fetchNextPage,
        hasNextPage,
        maxAutoRequest,
        filteredArticles.length,
        fetchedXTimes,
    ])

    const { sortedArticleList, selectedSorting, handleSorting } = useSortArticles(filteredArticles || [], erpInfos)

    return {
        requestStatus: status,
        isEnabled: true,
        isLoading: isFetching,
        isLoaded: (isSuccess || isError) && !isFetching,
        isSuccess,
        isFailed: isError,
        articles: articles ?? [],
        failMessage,
        displayArticles: sortedArticleList as TyreArticle[],
        articleCount: articles?.length ?? 0,
        pageCount: data?.pages?.length ?? 1,
        hasNextPage: !!hasNextPage,
        isFetchingNextPage,
        productGroupIds: [],
        supplierIds: [],
        loadNextPage: hasNextPage ? fetchNextPage : emptyFunc,
        sorting: { sortedArticleList, selectedSorting, handleSorting },
        totalArticlesNumber: data?.pages[0]?.articleListCount,
    }
}
