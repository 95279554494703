import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import merge from "lodash/merge"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity, pageNavigation } = getStyleTheme()
    const component = {
        back: "transparent",
        fore: pageNavigation && pageNavigation.itemColor ? pageNavigation.itemColor : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        hover: {
            back: color(colors.light).setAlpha(0.15).toRgbString(),
            fore:
                pageNavigation && pageNavigation.itemHoverColor
                    ? pageNavigation.itemHoverColor
                    : color(colors.light).setAlpha(opacity.primary).toRgbString(),
        },
        active: {
            back: color(colors.dark).lighten(84).toString(),
            fore: colors.dark,
        },
        more: {
            fore: colors.dark,
        },
        border: {
            color:
                pageNavigation && pageNavigation.itemBorderLeftColor
                    ? pageNavigation.itemBorderLeftColor
                    : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        },
    }

    const themeStyles: NestedCSSProperties = {
        // height: "3.5rem",
        $nest: {
            "&:hover:not(&--selected)": {
                background: "none",
            },
            "&--selected": {
                transform: "translateY(0)",
                position: "relative",
                background: "white",
                $nest: {
                    "&:after": {
                        content: '""',
                        position: "absolute",
                        background: "white",
                        height: "10px",
                        left: "-2px",
                        right: "-2px",
                        bottom: "-10px",
                    },
                    ".icon": {
                        fill: `${color(component.active.fore).setAlpha(0.54).toRgbString()} !important`,
                    },
                    ".icon:hover": {
                        fill: `${color(component.active.fore).setAlpha(0.87).toRgbString()} !important`,
                    },
                },
            },
            "&__close": {
                $nest: {
                    ".icon": {
                        fill: `${color(component.fore).setAlpha(0.54).toRgbString()} !important`,
                    },
                    "&:hover .icon": {
                        fill: `${color(component.fore).setAlpha(0.87).toRgbString()} !important`,
                    },
                },
            },
        },
    }
    const mergedStyles = merge(baseStyles, themeStyles)
    return mergedStyles
}
