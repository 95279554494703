import { Box, Loader } from "@tm/components"
import { getComponentFromBundle } from "@tm/utils"
import { WidgetBundle as CatalogWidgetBundle } from "@bundles/parts"
import { WidgetIndustryBundle as VehicleWidgetIndustryBundle } from "@bundles/vehicle"
import { Suspense } from "react"
import { dashboardIndustryChannel } from "./channel"
import { useDashboardIndustry } from "./hooks/useDashboardIndustry"
import { Overrides } from "./models"

type Props = {
    overrides?: Overrides
}

export function DashboardIndustry(props: Props) {
    return (
        <Suspense fallback={<Loader />}>
            <DashboardIndustryComponent {...props} />
        </Suspense>
    )
}

function DashboardIndustryComponent(props: Props) {
    const CatalogWidget = getComponentFromBundle(CatalogWidgetBundle, dashboardIndustryChannel)
    const VehicleWidget = getComponentFromBundle(VehicleWidgetIndustryBundle, dashboardIndustryChannel)

    const { overrides } = props

    const {
        otherModules: { catalogWidgetTree },
        checkOrder,
    } = useDashboardIndustry(overrides)

    return (
        <>
            <Box padding="10px">
                <VehicleWidget />
            </Box>
            <Box>
                <CatalogWidget
                    singleWidget={false}
                    trees={catalogWidgetTree}
                    sx={{
                        width: "100%",
                        height: "40em",
                        order: checkOrder("catalogWidget"),
                    }}
                    isOnIndustryDashboard
                />
            </Box>
        </>
    )
}
