import { OePart, OE } from "@tm/models"

export function mapOePartRD(item: OePart): OE.OePartRD {
    return {
        oeNumber: item.oeArticleNumber,
        description: item.description,
        additionalDescription: item.additionalDescription || "",
        currency: item.currencyCode,
        price: item.oePriceValue || 0,
        oeReplacements: item.replacements,
        quantity: item.quantityValue || 1,
        isSelected: true,
    }
}
