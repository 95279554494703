import { Authority } from "@tm/data"
import { channel } from "@tm/models"
import { useCallback, useEffect, useMemo, useState } from "react"

const listeningTimeoutInSeconds = 2
const bundleSaveTimeoutInMinutes = 1

export function useWorktaskShare() {
    const [bundlesShareState, setBundlesShareState] = useState<{ [bundle: string]: { saved: boolean; shareKey?: string; shareKeyValue?: string } }>(
        {}
    )
    const [listeningTimeoutReached, setListeningTimeoutReached] = useState(false)
    const [bundleSaveTimeoutReached, setBundleSaveTimeoutReached] = useState(false)
    const [allBundlesSaved, setAllBundlesSaved] = useState(false)

    const { shareKey, isLoading: isShareKeyLoading, isError } = Authority.useLoginShareKey(allBundlesSaved)

    const checkIfBundlesSaved = useCallback(() => {
        if (Object.keys(bundlesShareState)?.length === 0 && listeningTimeoutReached) {
            setAllBundlesSaved(true)
            return
        }

        setAllBundlesSaved(Object.values(bundlesShareState)?.every((bundle) => bundle.saved))
    }, [bundlesShareState, listeningTimeoutReached])

    useEffect(() => {
        const shareReceivedUnsub = channel("WORKTASK").subscribe("WORKTASK/SHARE_RECEIVED", ({ module }) => {
            setBundlesShareState((prev) => ({
                ...prev,
                [module]: {
                    ...(prev[module] || {}),
                    saved: false,
                },
            }))
        })

        const shareReadyUnsub = channel("WORKTASK").subscribe("WORKTASK/SHARE_READY", ({ module, shareKey, shareKeyValue }) => {
            setBundlesShareState((prev) => ({
                ...prev,
                [module]: {
                    saved: true,
                    shareKey,
                    shareKeyValue,
                },
            }))
            checkIfBundlesSaved()
        })

        channel("WORKTASK").publish("WORKTASK/SHARE", {})

        const listeningTimeoutId = setTimeout(() => {
            setListeningTimeoutReached(true)
        }, listeningTimeoutInSeconds * 1000)

        const bundleSaveTimeoutId = setTimeout(
            () => {
                setBundleSaveTimeoutReached(true)
            },
            bundleSaveTimeoutInMinutes * 60 * 1000
        )

        return () => {
            shareReceivedUnsub()
            shareReadyUnsub()
            clearTimeout(listeningTimeoutId)
            clearTimeout(bundleSaveTimeoutId)
        }
    }, [])

    useEffect(() => {
        if (bundleSaveTimeoutReached || (Object.keys(bundlesShareState)?.length === 0 && listeningTimeoutReached)) {
            setAllBundlesSaved(true)
        }
    }, [listeningTimeoutReached, bundleSaveTimeoutReached, bundlesShareState])

    const shareLink = useMemo(() => {
        if (!shareKey || isError) {
            return ""
        }

        const { pathname, search, origin } = window.location

        const searchParams = new URLSearchParams(search)

        Object.values(bundlesShareState)?.forEach((bundle) => {
            if (bundle?.shareKey && bundle?.shareKeyValue) {
                searchParams.set(bundle.shareKey, bundle.shareKeyValue)
            }
        })

        const redirect = `${pathname.replace(/^\//, "")}?${searchParams.toString()}`
        const catalog = sessionStorage.getItem("catalog")

        const url = new URL(`/login/${catalog}`, origin)
        url.searchParams.append("shareKey", shareKey)
        url.searchParams.append("redirect", redirect)

        return url.toString()
    }, [shareKey, isError, bundlesShareState])

    return {
        shareLink,
        isError,
        isLoading: !allBundlesSaved || isShareKeyLoading,
    }
}
