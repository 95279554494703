import { channel, SystemType } from "@tm/models"

export const generateKey = (ktypeNr?: number, workTaskId?: string) => {
    const [userContext] = channel("GLOBAL")
        .last(1, "USER/CONTEXT_LOADED")
        .map((x) => x.content.context)

    if (userContext?.system?.systemType == SystemType.Next) {
        return `WHEELS_${workTaskId}`
    }
    return `WHEELS_${ktypeNr}`
}
