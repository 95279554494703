import { Box, styled } from "@tm/components"
import { useStyle } from "@tm/context-distribution"
import { Icon, Text } from "@tm/controls"
import { classes } from "@tm/utils"
import { em, percent } from "csx"
import { FC, ReactNode, useEffect, useRef, useState } from "react"

type Props = {
    selectionKey?: boolean
    children?: ReactNode
    name?: string
    isFirst?: boolean
    headerData?: JSX.Element
    headerExtraData?: JSX.Element | null
    isCompleted?: boolean
    icon?: string
    onCheckOpened?: (checkOffsetTop: number) => void
}

const CheckToken: FC<Props> = ({ children, isFirst, headerData, onCheckOpened, headerExtraData, name, isCompleted, icon, selectionKey }) => {
    const [isContentOpen, setIsContentOpen] = useState<boolean>(!isCompleted && !!isFirst)
    const headerRef = useRef<any>(null)

    useEffect(() => {
        selectionKey ? (isCompleted ? setIsContentOpen(!isCompleted) : setIsContentOpen(!!isCompleted)) : setIsContentOpen(!isCompleted)
    }, [isCompleted])

    useEffect(() => {
        if (isContentOpen && headerRef?.current) {
            onCheckOpened?.(headerRef.current.offsetTop)
        }
    }, [isContentOpen])

    const renderHeaderName = (componentName: string) => {
        return (
            <div className={style.headerName}>
                {icon && <Icon className={classes(style.rightSeparator, style.svgMinWIdth)} name={icon} />}
                <Text size="xl" className={classes(style.oneLineText, isContentOpen ? style.normalFont : style.lightFont)}>
                    {componentName}
                </Text>
            </div>
        )
    }

    const handleOpenCollapsible = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        setIsContentOpen(!isContentOpen)
    }

    const preventBubble = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }

    return (
        <StyledWrapper ref={headerRef}>
            <StyledRow onClick={handleOpenCollapsible}>
                <div
                    className={classes(
                        style.rounded,
                        !isContentOpen ? (isCompleted || selectionKey ? style.checked : style.checkedButNotSubmited) : style.notChecked
                    )}
                >
                    {!isContentOpen ? <Icon name="check" /> : <div className={style.notCheckedInsidePoint} />}
                </div>

                <div className={classes(style.header, isContentOpen ? style.headerStyickyWhenOpen : "")}>
                    <div className={style.headerLeftContentBlock}>
                        {name && renderHeaderName(name)}
                        {headerExtraData && headerExtraData}
                    </div>
                    {headerData && headerData}
                </div>

                <div
                    onClick={preventBubble}
                    style={{
                        opacity: isContentOpen ? 1 : 0,
                        display: isContentOpen ? "flex" : "none",
                        transition: "opacity .2s ease-out",
                        rowGap: "0.5em",
                    }}
                    className={style.contentWrapper}
                >
                    {children && children}
                </div>
            </StyledRow>
        </StyledWrapper>
    )
}

export default CheckToken

const StyledWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flex: "1",
    position: "relative",
    borderLeft: "1px solid #9a9a9a8a",
    marginLeft: em(2),
    paddingLeft: em(2),
    paddingRight: em(1),
})

const StyledRow = styled(Box)({
    paddingBottom: "1em",
    paddingTop: "1em",
    marginBottom: "1em",
    borderBottom: "1px solid #9a9a9a8a",
})

const style = useStyle({
    rounded: {
        borderRadius: "100%",
        boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
        width: em(3),
        height: em(3),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: em(0.5),
        left: em(-1.5),
        transition: "all .2s ease-out",
        cursor: "pointer",
    },
    header: {
        marginBottom: em(1),
        cursor: "pointer",
        display: "flex",
    },
    headerStyickyWhenOpen: {
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "#efefef",
    },
    contentWrapper: {
        display: "flex",
    },
    checked: {
        backgroundColor: "#fff",
        $nest: {
            svg: {
                fill: "#55d132",
            },
        },
    },
    checkedButNotSubmited: {
        backgroundColor: "#fff",
        $nest: {
            svg: {
                opacity: ".3",
            },
        },
    },
    notChecked: {
        backgroundColor: "#dadada",
    },
    multiselectWrapper: {
        width: percent(100),
        paddingRight: em(2),
        $nest: {
            ".fancy-list__item": {
                justifyContent: "space-between",
            },
        },
    },
    notCheckedInsidePoint: {
        width: em(1),
        height: em(1),
        borderRadius: "100%",
        border: "1px solid #8c8c8c",
    },
    submitButton: {
        padding: em(0.7),
        marginRight: "auto",
        marginLeft: "auto",
    },
    rightSeparator: {
        marginRight: em(0.5),
    },
    svgMinWIdth: {
        minWidth: em(1.5),
    },
    bigRightSeparator: {
        marginRight: em(2),
    },
    oneLineText: {
        whiteSpace: "nowrap",
    },
    headerName: {
        minWidth: em(15),
    },
    headerContentFlex: {
        display: "flex",
        flexWrap: "wrap",
    },
    headerContentBlock: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
    },
    headerLeftContentBlock: {
        display: "flex",
        flexDirection: "column",
        gap: "0.4em",
    },
    lightFont: {
        fontWeight: 100,
    },
    normalFont: {
        fontWeight: 400,
    },
})(CheckToken)
