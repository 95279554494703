import { DegreesColorsResponse, DegreesColorsRawResponse } from "./model"
import { sortColors } from "../../helpers/sortColors"
import { Color } from "../../model"

export function mapResponseToDegreeColorsResponse(response: DegreesColorsRawResponse): DegreesColorsResponse {
    const { vehicleDegrees } = response.degreesColors
    const unsortedVehicleColors: Color[] = response.degreesColors.vehicleColors || []
    const colors = sortColors(unsortedVehicleColors) || []
    const degrees = vehicleDegrees || []

    return { colors, degrees }
}
