import { useLocalization } from "@tm/localization"
import { Box, TableCellData, Icon, Typography, Tooltip, TableColumnData, CellContentPosition, styled } from "@tm/components"
import { OrderIdSource, OrderVoucherList, PriceType, VoucherType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { getBundleParams } from "../../../../../utils"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { StyledTable } from "../../../../_shared/StyledComponents"
import VoucherDetailsButton from "../../../../_shared/VoucherDetailsButton"
import VehicleInfo from "../../../../_shared/orders/OrderVehicleInfo"
import CustomerInfo from "../../../../_shared/orders/CustomerInfo"
import ErpState from "../../../../_shared/orders/ErpState"

type Props = {
    orders: OrderVoucherList[]
    shortCountryCode: string
}

const ValueText = styled(Typography)({})
ValueText.defaultProps = { variant: "body2" }

export default function OrdersList({ orders, shortCountryCode }: Props) {
    const { translateText, currency, date } = useLocalization()
    const { userSettings } = useUser() ?? {}

    const { erpOrderState } = getBundleParams()

    function renderTotalRetailCell(order: OrderVoucherList) {
        const totalPrices = order.totals ? order.totals.totalPrices[PriceType.Retail] : undefined
        return totalPrices ? currency(totalPrices.value, totalPrices.currencyCode || totalPrices.currencySymbol) : "-"
    }

    function renderTotalPurchaseCell(order: OrderVoucherList) {
        const totalPrices = order.totals ? order.totals.totalPrices[PriceType.Purchase] : undefined
        return (
            <ValueText color="primary">
                {totalPrices ? currency(totalPrices.value, totalPrices.currencyCode || totalPrices.currencySymbol) : "-"}
            </ValueText>
        )
    }

    function renderWarehouseNameCell(order: OrderVoucherList) {
        if (!order.orderRecipient) {
            return null
        }

        const { warehouseName, distributorName } = order.orderRecipient

        let description = distributorName
        if (description && warehouseName) {
            description += ` - ${warehouseName}`
        } else if (warehouseName) {
            description = warehouseName
        }

        return description
    }

    function renderCommentCell(order: OrderVoucherList) {
        if (!order.customerComment) {
            return null
        }
        return (
            <Tooltip title={order.customerComment} variant="dark">
                <Box>
                    <Icon name="message" color="highlight" />
                </Box>
            </Tooltip>
        )
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(700) }, // orderNumbers
            { header: translateText(98) }, // orderDate day
            { header: translateText(335) }, // orderDate time
        ]

        if (userSettings?.showPurchasePrice) {
            columns.push({ header: translateText(12853), alignContent: CellContentPosition.right }) // TotalPurchase
        }

        columns.push({ header: translateText(12852), alignContent: CellContentPosition.right }) // TotalRetail

        if (erpOrderState) {
            columns.push({ header: translateText(212) }) // ErpState
        }

        columns.push(
            { header: translateText(144) }, // WarehouseName
            { header: translateText(107) }, // CustomerInfo
            { header: translateText(147) }, // VehicleInfo
            {}, // Comment
            { alignContent: CellContentPosition.right } // VoucherDetailsButton
        )

        return columns
    }

    function getCells(order: OrderVoucherList) {
        const data: TableCellData[] = [
            {
                displayValue: (
                    <ValueText>{order.orderNumbers?.find((orderNumber) => orderNumber.type === OrderIdSource.Wholesaler)?.number}</ValueText>
                ),
                id: "1",
            },
            { displayValue: date(order.orderDate, "d"), id: "2" },
            { displayValue: date(order.orderDate, "t"), id: "3" },
        ]

        if (userSettings?.showPurchasePrice) {
            data.push({ displayValue: renderTotalPurchaseCell(order), id: "4" })
        }

        data.push({ displayValue: renderTotalRetailCell(order), id: "5" })

        if (erpOrderState) {
            data.push({ displayValue: <ErpState orderVouchers={orders} order={order} />, id: "6" })
        }

        data.push(
            { displayValue: renderWarehouseNameCell(order), id: "7" },
            { displayValue: <CustomerInfo order={order} />, id: "8" },
            { displayValue: <VehicleInfo order={order} shortCountryCode={shortCountryCode} />, id: "9" },
            { displayValue: renderCommentCell(order), id: "10" },
            { displayValue: <VoucherDetailsButton variant="normal" voucherId={order.orderVoucherId} voucherType={VoucherType.Order} />, id: "11" }
        )

        return data
    }

    const displayData = orders.map((order) => ({
        cells: getCells(order),
        id: `${order.orderVoucherId}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} rowCap={4} />
}
