import { RefObject, useCallback, useMemo } from "react"
import { Icon, Stack, Typography } from "@tm/components"
import { useCountryCodeToLicensePlate, useUser, useWorkTask } from "@tm/context-distribution"
import { Size, SuggestionFieldButtonGroup } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType } from "@tm/models"
import { RouteComponentProps, TmaHelper, isVin, useAvailableVehicleSearchOptions, withRouter } from "@tm/utils"
import { ModelsResponse, useResetFilters, useSelectedVehicleLookup } from "../../../../../data/hooks"
import { useVehicleSearchOptionsQuota } from "../../../../../data/hooks/useVehicleSearchOptionsQuota"
import { showModelsByQuery } from "../../../../../data/repositories/cars/showModels"
import { setSelectedVehicle, showVehiclesByRegistrationNo, showVehiclesByVin, VrmLookupResponse } from "../../../../../data/repositories/vrm-lookup"
import { getLookupType } from "../../../../../helpers"
import { getVehicleSearchUrl } from "../../../../../helpers/routing"
import { handleUniqueVehicleFound } from "../../../../../helpers/vrm"
import LicensePlateSearchField from "../../../../_shared/LicensePlateSearchField"
import { QuotaText } from "../../../../_shared/QuotaText"
import { ConfigProps } from "../component"

type RouteParams = {
    workTaskId: string
}

/** @todo reduce amount of properties somehow? */
type Props = ConfigProps &
    RouteComponentProps<RouteParams> & {
        showCountryCode?: boolean
        subTextIcon?: string
        subTextSize?: Size
        subText?: string
        autoFocus?: boolean
        onlyNumbersAndDigits?: boolean
        /** Is also called when the conflict dialog is opened. Keep that in mind when using this function. */
        onVehicleAttached?(): void
        hidePlateFx?: boolean
        // overwrites the one comes from the usercontext in case it should be always a specific, eg BM -> I
        countryCode?: string

        inputFieldRef?: RefObject<SuggestionFieldButtonGroup<unknown>>
    }

function DefaultLicensePlateComponent(props: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { attachToWorkTask } = useWorkTask() ?? {}
    const { registrationNoType, history, match, vehicleType, onVehicleAttached } = props
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicleType)
    const resetFilters = useResetFilters(vehicleType)
    const { quota, invalidateStore } = useVehicleSearchOptionsQuota(registrationNoType)
    const { plateCode } = useCountryCodeToLicensePlate(props.countryCode)
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()

    const redirectToVehicleSearch = useCallback(
        (query: string, checkAndMergeRegNoDetails?: boolean) => {
            resetFilters() // Reset filters before opening search page
            history.push(getVehicleSearchUrl(match.params, { vehicleType, query, registrationNoType, checkAndMergeRegNoDetails }))
        },
        [resetFilters, history, match.params, vehicleType, registrationNoType]
    )

    const defaultVehicleCountryCode = useMemo(() => availableVehicleSearches.defaultVehicleLookup?.countryCode, [availableVehicleSearches])

    const handleSearch = useCallback(
        async (query: string): Promise<void> => {
            if (!attachToWorkTask) {
                return
            }

            // Special for Create Business: replace dashes in query (see: NEXT-9621) 1 of 2
            if (getLookupType(userContext) === RegistrationNoType.KennzeichenPortugal) {
                query = query?.replace(/-/g, "")
            }

            if (!query || query.length < 2) {
                return
            }

            // If registrationNoType is set call the VRM Relay endpoint
            if (registrationNoType && registrationNoType !== RegistrationNoType.KennzeichenNorwegenNbk) {
                const searchByVin = isVin(query)
                const showVehicles = searchByVin ? showVehiclesByVin : showVehiclesByRegistrationNo

                let response: VrmLookupResponse

                try {
                    response = await showVehicles({
                        query,
                        lookupTypeId: registrationNoType,
                        forceUpdateRegistrationNoDetails: true,
                        vehicleType,
                    })
                } catch (error) {
                    redirectToVehicleSearch(query)
                    return
                }

                const { carModels, truckModels, customerVehicles } = response ?? {}

                const models = carModels.length ? carModels : truckModels
                const model = models[0]

                // for now only automatically attach the customer vehicle if there aren't any or only one "normal" models found
                // to keep the option to select another vehicle for the user (until it is possible to delete already existing customer vehicles)
                if (customerVehicles.length === 1 && models.length <= 1) {
                    await handleUniqueVehicleFound(
                        customerVehicles[0],
                        customerVehicles[0].vehicleType,
                        query,
                        attachToWorkTask,
                        undefined,
                        selectedVehicleLookup?.countryCode ?? defaultVehicleCountryCode
                    )
                    onVehicleAttached?.()
                } // If the model was found via keyword search (which could return fuzzy results) we will not automatically attach the vehicle.
                else if (
                    !customerVehicles.length &&
                    models.length === 1 &&
                    model.id &&
                    models[0].dataSourceId &&
                    models[0].dataSourceId !== RegistrationNoType.KeywordSearch
                ) {
                    await setSelectedVehicle({
                        query,
                        lookupTypeId: registrationNoType,
                        tecDocTypeId: model.id,
                    })

                    await handleUniqueVehicleFound(
                        model,
                        vehicleType,
                        query,
                        attachToWorkTask,
                        undefined,
                        selectedVehicleLookup?.countryCode ?? defaultVehicleCountryCode
                    )
                    onVehicleAttached?.()
                } else {
                    redirectToVehicleSearch(query)
                }
            } else {
                const checkAndMerge = !registrationNoType

                let response: ModelsResponse

                try {
                    response = await showModelsByQuery({ query, selectedFilters: {} })
                } catch (error) {
                    redirectToVehicleSearch(query, checkAndMerge)
                    return
                }

                const { models = [], customerVehicles = [] } = response ?? {}

                // for now only automatically attach the customer vehicle if there aren't any or only one "normal" models found
                // to keep the option to select another vehicle for the user (until it is possible to delete already existing customer vehicles)
                if (customerVehicles.length === 1 && models.length <= 1) {
                    await handleUniqueVehicleFound(
                        customerVehicles[0],
                        customerVehicles[0].vehicleType,
                        query,
                        attachToWorkTask,
                        undefined,
                        selectedVehicleLookup?.countryCode
                    )
                    onVehicleAttached?.()
                }
                // If the model was found via keyword search (which could return fuzzy results) we will not automatically attach the vehicle.
                else if (
                    !customerVehicles.length &&
                    models.length === 1 &&
                    models[0].dataSourceId &&
                    models[0].dataSourceId !== RegistrationNoType.KeywordSearch
                ) {
                    await handleUniqueVehicleFound(models[0], vehicleType, query, attachToWorkTask, checkAndMerge, selectedVehicleLookup?.countryCode)
                    onVehicleAttached?.()
                } else {
                    redirectToVehicleSearch(query, checkAndMerge)
                }
            }
            invalidateStore()
        },
        [
            attachToWorkTask,
            userContext,
            registrationNoType,
            invalidateStore,
            vehicleType,
            selectedVehicleLookup?.countryCode,
            defaultVehicleCountryCode,
            onVehicleAttached,
            redirectToVehicleSearch,
        ]
    )

    const handleAttachVehicleId = useCallback(
        (vehicleId: string, query: string) => {
            TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query })
            attachToWorkTask?.({ vehicle: vehicleId }).then(onVehicleAttached)
        },
        [attachToWorkTask, onVehicleAttached]
    )

    return (
        <div className="special-license-plates">
            <LicensePlateSearchField
                onSearch={handleSearch}
                onAttachVehicleId={handleAttachVehicleId}
                size={props.size || "xl"}
                className={props.className}
                shortCountryCode={plateCode}
                showCountryCode={props.showCountryCode === undefined ? !!props.countryCode : props.showCountryCode}
                placeholder={props.placeholder}
                searchType={props.searchType}
                onlyNumbersAndDigits={props.onlyNumbersAndDigits}
                hidePlateFx={props.hidePlateFx}
                inputFieldRef={props.inputFieldRef}
            />
            <div className="search-container__hint">
                {props.subTextIcon && props.subText !== "" && <Icon className="" name={props.subTextIcon} />}
                <Stack direction="row" spacing="8px">
                    <Typography variant="label">{props.subText ?? translateText(1020)}</Typography>
                    <QuotaText quota={quota} />
                </Stack>
            </div>
        </div>
    )
}

export default withRouter(DefaultLicensePlateComponent)
