import { important, padding, rem } from "csx"
import { useMemo } from "react"
import { batch, useSelector } from "react-redux"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Badge, Button, Headline } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { AddWholesalerPartListRequest, getCurrentWorkTaskId } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { initSelector } from "@tm/utils"
import { mapArticleToWholesalerPart } from "../../../data/helpers"
import { Actions } from "../business"
import { DOPState } from "../business/model"
import { useBasketParts } from "@bundles/basket"

const selector = initSelector(
    (s: DOPState) => s.model.articles,
    (s) => s.loading,
    (s) => s.errrMessage
)

// eslint-disable-next-line no-empty-pattern
export default function NavigationComponent() {
    const { translateText } = useLocalization()
    const [articles, loading] = useSelector(selector)
    const actions = useActions(Actions)
    const { addWholesalerPartList } = useBasketParts()
    const style = useMemo(() => getStyle(), [])

    const workTaskId = getCurrentWorkTaskId()!

    const handleReset = () => {
        batch(() => {
            actions.resetData()
            actions.loadData()
        })
    }

    const handleAddToBasket = () => {
        const request: AddWholesalerPartListRequest = {
            wholesalerParts: articles?.map(mapArticleToWholesalerPart.bind(undefined, translateText)) ?? [],
            workTaskId,
        }
        addWholesalerPartList(request)
    }

    return (
        <div className={classes(style.wrapper, "module toolbar")}>
            <Headline className={style.headline} size="s">
                {translateText(12443)}
                <Badge loading={loading} />
            </Headline>

            <Button className={style.reset} layout={["ghost"]} skin="danger" icon="synchronize" disabled={false} onClick={handleReset}>
                {translateText(48)}
            </Button>

            <div className={style.actions}>
                <Button
                    disabled={!articles?.length}
                    icon="cart"
                    onClick={handleAddToBasket}
                    skin={(articles?.length && "highlight") || undefined}
                    layout={["bordered"]}
                >
                    {translateText(133)}
                </Button>
            </div>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            flex: 0,
            display: "flex",
            padding: important(padding(0, rem(1))),
            justifyContent: "space-between",
        },
        actions: {
            alignItems: "center",
            justifySelf: "flex-end",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
        },
        headline: {
            position: "relative",
        },
        error: {
            marginRight: theme.margin.s,
        },
        reset: {
            marginRight: theme.margin.s,
        },
    })(NavigationComponent)
}
