import { classes, useStyle } from "@tm/context-distribution"
import { em, margin, padding, percent, rem } from "csx"
import { FC, PropsWithChildren } from "react"

type Props = PropsWithChildren<{
    miscCell?: boolean
    className?: string
}>

const Cell: FC<Props> = ({ miscCell, children }) => {
    return <div className={classes(style.cell, miscCell && style.cellAdditional)}>{children}</div>
}

const style = useStyle({
    cell: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        margin: margin(0, em(0.5)),
        width: percent(100),
        $nest: {
            "&:hover": {
                $nest: {
                    ".minor-text": {
                        opacity: 0.87,
                    },
                    ".icon": {
                        opacity: 0.87,
                    },
                },
            },
            ".icon": {
                marginBottom: rem(1),
            },
            ".text": {
                marginBottom: em(0.5),
            },
            ".text--highlight": {
                marginBottom: rem(0.5),
            },
        },
    },
    cellAdditional: {
        padding: padding(em(2), 0),
        $nest: {
            "&:first-child": {
                margin: margin(0, em(0.5), 0, 0),
            },
            "&:last-child": {
                margin: margin(0, 0, 0, em(0.5)),
            },
            ".icon": {
                marginRight: em(0.5),
            },
        },
    },
})(Cell)

export default Cell
