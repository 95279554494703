import { Dialog, Divider, Grid, Paper, Stack } from "@tm/components"
import { ReactNode, useState } from "react"
import { BasketPart } from "../../../models"
import DialogHeader from "./DialogHeader"
import PartBasicInfo from "./PartBasicInfo"
import EditButton from "../EditButton"

type Props = {
    disableButton?: boolean
    part: BasketPart
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    dialogFields: ReactNode
    disableResetButton: boolean
    disableConfirmButton: boolean
    onResetButtonClick(): void
    onConfirmButtonClick(): void
    onCloseButtonClick?(): void
}

export function PartDialogTrigger(props: Props) {
    const {
        disableButton,
        dialogFields,
        disableConfirmButton,
        disableResetButton,
        part,
        showManufacturer,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        onConfirmButtonClick,
        onResetButtonClick,
        onCloseButtonClick,
    } = props
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false)

    function handleConfirmButtonClick() {
        onConfirmButtonClick()
        setShowEditDialog(false)
    }

    function handleCloseButtonClick() {
        onCloseButtonClick?.()
        setShowEditDialog(false)
    }

    return (
        <>
            <EditButton onClick={() => setShowEditDialog(true)} disabled={disableButton} />
            <Dialog open={showEditDialog} position="middle" fullWidth maxWidth="lg">
                <Stack gap={2}>
                    <DialogHeader
                        disableConfirmButton={disableConfirmButton}
                        disableResetButton={disableResetButton}
                        onCloseButtonClick={handleCloseButtonClick}
                        onConfirmButtonClick={handleConfirmButtonClick}
                        onResetButtonClick={onResetButtonClick}
                    />
                    <Paper>
                        <Grid container columns={9} flex={1} flexWrap="wrap" gap={4}>
                            <Grid item sm={3}>
                                <PartBasicInfo
                                    part={part}
                                    showManufacturer={showManufacturer}
                                    showSupplierArticleNumbers={showSupplierArticleNumbers}
                                    showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                                />
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item sm={5} alignContent="center">
                                {dialogFields}
                            </Grid>
                        </Grid>
                    </Paper>
                </Stack>
            </Dialog>
        </>
    )
}
