import { Omit } from "@tm/utils"
import { Component, ComponentType, createElement } from "react"
import { connect, Provider } from "react-redux"
import { Action, bindActionCreators, Dispatch, Store } from "redux"

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(component: ComponentType<Omit<P, keyof StoreModel>>): ComponentType<P> {
    return class extends Component<P> {
        render() {
            const { store } = this.props
            return createElement(Provider, { store }, createElement(component, this.props))
        }
    }
}

type ActionType<V> = { actions?: V }
export function connector<P extends U & ActionType<V>, U, V, S>(
    component: ComponentType<P>,
    mapStToPr?: (store: S) => U,
    actions?: V
): ComponentType<Omit<P, keyof U | keyof ActionType<V>>> {
    function mapDisptachToProps(dispatch: Dispatch<Action<V>>, props: P): P {
        return {
            ...props,
            actions: actions && bindActionCreators(actions || {}, dispatch),
        }
    }
    function mapStateToProps(store: S, props: P): P {
        return {
            ...props,
            ...mapStToPr?.(store),
        }
    }
    return connect(mapStateToProps, mapDisptachToProps)(component as ComponentType<any>) as any
}
