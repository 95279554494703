import { useStyle } from "@tm/context-distribution"
import { Button, Headline, Icon, Toolbar } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { bindSpecialReactMethods, classes, initSelector, renderRoute, withRouter } from "@tm/utils"
import { important, padding, rem } from "csx"
import { useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import { Component, FC } from "react"
import { EurotaxSelectionSteps } from "../../data/enums"
import { IMainActions, MainActions, MainState } from "../main"
import { NavigationState } from "./business"

type Props = LocalizationProps &
    RouteComponentProps<RouteProps> & {
        state: NavigationState
        actions: IMainActions
        eurotaxRoute: string
    }

type RouteProps = {
    view?: string
}

class NavigationComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    componentDidUpdate(prevProps: Props) {
        const {
            state: { active },
            match,
            history,
            actions,
            eurotaxRoute,
        } = this.props

        if (active != prevProps.state.active) {
            const url = renderRoute(eurotaxRoute, { ...match.params, view: active || EurotaxSelectionSteps.SUMMARY })
            if (active == EurotaxSelectionSteps.NONE) {
                history.replace(url + location.search)
            } else {
                match.url != url && history.push(url + location.search)
            }
        }

        if (match.params.view != prevProps.match.params.view) {
            actions.changeStep(match.params.view as EurotaxSelectionSteps)
        }
    }

    componentDidMount() {
        const {
            match: { params },
            state: { active },
            actions,
        } = this.props
        if (params.view != active) {
            // onRefresh
            actions.changeStep(EurotaxSelectionSteps.SUMMARY)
        }
    }

    render() {
        const { translateText } = this.props.localization
        const {
            match,
            state: { params },
            actions,
        } = this.props
        const { view } = match.params

        return (
            <div className={classes(style.wrapper, "module toolbar tk-eurotax-navigation")} id="tk-eurotax-navigation">
                <Headline size="s">
                    <Icon name="group" size="s" />
                    {translateText(571)}
                </Headline>
                <Toolbar title={translateText(176)}>
                    <Button fakeButton onClick={() => actions.changeStep(EurotaxSelectionSteps.SUMMARY)} isActive={view == "summary"} icon="overview">
                        {translateText(409)}
                    </Button>
                    <Button
                        fakeButton
                        disabled={!params[EurotaxSelectionSteps.DETAILS] && view != "details"}
                        onClick={() => actions.changeStep(EurotaxSelectionSteps.DETAILS)}
                        isActive={view == "details"}
                        icon="details"
                    >
                        {translateText(43)}
                    </Button>
                </Toolbar>
            </div>
        )
    }
}

const selector = initSelector((s: MainState) => s.navigation)

const NAV = withLocalization(withRouter(NavigationComponent))

const NavWrapper: FC<Partial<Props>> = ({ eurotaxRoute }) => {
    const [state] = useSelector(selector)
    const actions = useActions(MainActions)
    return <NAV {...{ eurotaxRoute: eurotaxRoute!, actions, state }} />
}

const style = useStyle({
    wrapper: {
        flex: 0,
        display: "flex",
        padding: important(padding(0, rem(1))),
    },
})(NavWrapper)

export default NavWrapper
