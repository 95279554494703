import { ErpPrice, ErpPriceDictionary, Price, PriceDictionary } from "@tm/models"
import { ItemErpInformation } from "../model"
import { BasketPart } from "../../models"

// TODO Basket V2, when the sendOrder is refactored this mapper should be removed.
function mapErpPriceToPrice(erpPrice?: ErpPrice): Price | undefined {
    if (!erpPrice) {
        return undefined
    }

    const { currencyCode_Iso_4217, taxIncluded, description, priceUnit, type, ...price } = erpPrice
    return {
        ...price,
        currencyCode: currencyCode_Iso_4217,
        vatIncluded: taxIncluded,
        typeName: description,
        unit: priceUnit,
        type: type ?? 0, // Some articles like 31005.00308 in default have a price with type 0, which is removed in the json response from service
    }
}

// TODO Basket V2, when the sendOrder is refactored this mapper should be removed.
function mapErpPriceDictionaryToDictionary(erpPrices?: ErpPriceDictionary): PriceDictionary | undefined {
    if (!erpPrices) {
        return undefined
    }

    const dict: PriceDictionary = {}
    Object.values(erpPrices).forEach((erpPrice) => {
        if (erpPrice) {
            // Some articles like 31005.00308 in default have a price with type 0, which is removed in the json response from service
            dict[erpPrice.type ?? 0] = mapErpPriceToPrice(erpPrice)
        }
    })

    return dict
}

// TODO Basket V2, when the sendOrder is refactored this mapper should be removed.
function mapErpPriceArrayToDictionary(erpPrices?: ErpPrice[]): PriceDictionary | undefined {
    if (!erpPrices) {
        return undefined
    }

    const dict: PriceDictionary = {}
    erpPrices.forEach((erpPrice) => {
        if (erpPrice) {
            dict[erpPrice.type ?? 0] = mapErpPriceToPrice(erpPrice)
        }
    })

    return dict
}

// TODO Basket V2, when the sendOrder is refactored this mapper should be removed.
// Warehouse prices should be a dictionarry instead of an array
function mapWarehousePrices(prices?: ErpPrice[] | { [key: string]: ErpPrice }) {
    if (!prices || Array.isArray(prices)) {
        return mapErpPriceArrayToDictionary(prices)
    }
    return mapErpPriceDictionaryToDictionary(prices)
}

export function mapItemErpInformation(basketParts?: BasketPart[]): ItemErpInformation[] | undefined {
    if (!basketParts?.length) {
        return undefined
    }

    return basketParts.map<ItemErpInformation>((part) => {
        const { partItem, erpInfoResponse, linkedItems, bonusPoints, selectedOrderOptions, calculatedOrderItem } = part
        const { prices, totalPrices, graduatedPrices, warehouses, hasGraduatedPurchasePrice, hasObligatedArticles, ...response } =
            erpInfoResponse ?? {}
        return {
            ...response,
            id: partItem.id,
            warehouses: warehouses?.map((warehouse) => ({
                ...warehouse,
                prices: mapWarehousePrices(warehouse.prices), // article 36171 in coparts has warehouse prices or J1313020 in Neimcke
            })),
            unitPrices: mapErpPriceDictionaryToDictionary(prices),
            cumulatedPrices: mapErpPriceDictionaryToDictionary(totalPrices),
            bonusPoints: bonusPoints?.bonusPointsPerUnit ? Object.values(bonusPoints.bonusPointsPerUnit)[0] : undefined,
            graduatedPurchasePrices: graduatedPrices?.map(mapErpPriceToPrice), // article 004109 in Neimcke
            hasGraduatedPrices: hasGraduatedPurchasePrice,
            hasMandatoryArticles: hasObligatedArticles,
            linkedItems: mapItemErpInformation(linkedItems),
            selectedOrderOptions,
            customOrderPrices: calculatedOrderItem?.customOrderPrices,
        } as ItemErpInformation
    })
}
