import { Box, Stack } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Article, ArticleListSortingMode, GetProductGroupTopicIdsResponse } from "@tm/models"
import { getUIA } from "@tm/utils"
import { Children, memo, PropsWithChildren, ReactNode } from "react"
import { useTyreArticleSelection } from "@bundles/parts/components/WheelsList/hooks"
import { CombinedParticularState, useParticularContext } from "@bundles/parts/components/PureList/ContextProviders/Particular/ParticularContext"
import PartsSelection from "@bundles/parts/components/WheelsList/PartsSelection"
import { getBundleParams } from "../../../../utils"
import ArticleGroupFilterArea, { ArticleGroupFilterAreaProps } from "../ArticleGroupFilterArea"
import { ArticleListSortSelection } from "./ArticlelistSortSelection"
import { GroupHeader } from "./GroupHeader"
import { GroupHeaderButtonArea } from "./GroupHeaderButtonArea"
import { GroupHeaderHeadline } from "./GroupHeaderHeadline"

export type Props = PropsWithChildren<unknown> & {
    title: string
    titleContent?: ReactNode
    groupArticlesCount?: number
    maximumSortableArticleCount?: number

    // Passed to ArticleGroupFilterArea
    filterAreaProps?: Omit<ArticleGroupFilterAreaProps, "modelId">

    // Passed to GroupHeaderButtonArea
    productGroupId?: number
    productGroupTopicIds?: GetProductGroupTopicIdsResponse
    calculatorRoute?: string

    // Passed to ArticleListSortSelection
    currentSorting?: ArticleListSortingMode
    onChangeSorting?(sorting: ArticleListSortingMode | undefined): void
}

/**
 * Articlelist Group header, can be costimized by Theme overrides
 * by default it use the Highlight.light color
 * to use monochrom style, add 'components.partlist.groupHeader.monochrom: true' to the Theme.json
 * the ArticleGroupFilter area will also be effect by this styling
 * also it is possible to overwrite the colors
 * @param props
 * @memberof Parts
 * @returns Groupheader with ArticleGroupFilters if its enbled and the vehicle use has informations
 */
function ArticleGroupHeaderComponent(props: Props) {
    const modelId = useWorkTask()?.workTask?.vehicle?.tecDocTypeId
    const worktaskId = useWorkTask()?.workTaskId
    const showVehicleRecordsFilters = useUser().userSettings?.articleListSettings?.viewOptions?.showVehicleRecordsFilters
    const vehicleRecordsEnabled = getBundleParams().vehicleRecordsEnabled && (showVehicleRecordsFilters ?? true)

    const filtersAvailable = (props.filterAreaProps?.filterOptions?.attributeFilters.length ?? 0) > 0 && modelId

    const combinedContext = useParticularContext<CombinedParticularState>()

    const selection = useTyreArticleSelection(
        (combinedContext?.selectedArticles as Article[]) ?? [],
        combinedContext?.toggleSelectedArticle as (article: Article) => void
    )

    function renderButtons() {
        if (props.productGroupId === undefined) {
            return
        }

        if (props.productGroupTopicIds && props.calculatorRoute) {
            return (
                <GroupHeaderButtonArea
                    productGroupId={props.productGroupId}
                    productGroupTopicIds={props.productGroupTopicIds}
                    calculatorRoute={props.calculatorRoute}
                />
            )
        }

        if (props.productGroupTopicIds) {
            return <GroupHeaderButtonArea productGroupId={props.productGroupId} productGroupTopicIds={props.productGroupTopicIds} />
        }

        if (props.calculatorRoute) {
            return <GroupHeaderButtonArea productGroupId={props.productGroupId} calculatorRoute={props.calculatorRoute} />
        }
    }

    const renderPartsSelection = () => {
        if (selection && selection.selectedArticles.length > 1) {
            return (
                <PartsSelection
                    selectedParts={selection.selectedArticles}
                    workTaskId={worktaskId ?? ""}
                    hideAddToBasketButtonInArticleComparison
                    onUnselectAll={selection.deselectAll}
                />
            )
        }
    }

    return (
        <Box position="sticky" top={0} zIndex={3}>
            <GroupHeader className="ArticleGroupHeader" {...getUIA("ArticelListHeader")}>
                <GroupHeaderHeadline variant="h3">
                    {props.title} {props.groupArticlesCount !== undefined ? `(${props.groupArticlesCount})` : ""} {props.titleContent}
                </GroupHeaderHeadline>
                <Box display="flex" alignItems="center" gap="1em">
                    {renderPartsSelection()}
                    <Stack direction="row" spacing={1}>
                        {renderButtons()}
                        {props.onChangeSorting && (
                            <ArticleListSortSelection
                                value={props.currentSorting}
                                onChange={props.onChangeSorting}
                                groupArticlesCount={props.groupArticlesCount}
                                maximumSortableArticleCount={props.maximumSortableArticleCount}
                            />
                        )}
                    </Stack>
                </Box>
            </GroupHeader>
            {vehicleRecordsEnabled && filtersAvailable && modelId && <ArticleGroupFilterArea {...props.filterAreaProps} modelId={modelId} />}
            {props?.children && Children.only(props.children)}
        </Box>
    )
}

export const ArticleGroupHeader = memo(ArticleGroupHeaderComponent)
