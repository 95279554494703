import { RegistrationNoType, ShowConfigResponse, VehicleLookupConfig } from "@tm/models"
import { ajaxAuth } from "@tm/utils"

import { getBundleParams } from "../../utils"

function getOptionsServiceUrl() {
    return getBundleParams().optionsServiceUrl
}

export function showConfig() {
    return ajaxAuth<ShowConfigResponse>({
        url: `${getOptionsServiceUrl()}/ShowOptions`,
    }).then((response) => {
        response = mapShowConfigResponse(response)
        if (!response) {
            throw new Error()
        }
        return response
    })
}

function mapShowConfigResponse(data: any): ShowConfigResponse | undefined {
    if (!data) {
        return
    }

    return {
        defaultVehicleLookup: mapVehicleLookupConfig(data.defaultVehicleLookup),
        configuredVehicleLookups: data.configuredVehicleLookups?.map(mapVehicleLookupConfig) ?? [],
    }
}

function mapVehicleLookupConfig(data: any): VehicleLookupConfig | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        lookupTypeId: data.lookupTypeId ?? RegistrationNoType.Kba, // This fallback is really important! The enum value of "Kba" is 0 (zero) which will be removed by the service response formatter.
    }
}
