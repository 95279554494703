import { TyreArticleAttribute, TyreFilter } from "@tm/models"

export function mapTyreAttributeToTyreFilter(articleAttribute?: TyreArticleAttribute): TyreFilter | undefined {
    if (!articleAttribute) {
        return undefined
    }

    return {
        group: articleAttribute.group,
        groupId: articleAttribute.id,
        query: articleAttribute.value,
        value: articleAttribute.value,
    }
}
