import { Children, PropsWithChildren, ReactNode } from "react"
import { border, px } from "csx"
import { Box, styled } from "@tm/components"

type Props = PropsWithChildren<{
    icon: ReactNode
    expandIcon: ReactNode
    expanded: boolean
    onClick?: (expanded: boolean) => void
    underlinedHeader?: boolean
}>

export default function Accordion({ children, icon, expandIcon, expanded, onClick, underlinedHeader }: Props) {
    const result = Children.toArray(children)

    const handleClick = () => {
        onClick?.(!expanded)
    }

    return (
        <Box>
            <UnderlinedBox onClick={handleClick} underlinedHeader={underlinedHeader}>
                <Box sx={{ display: "flex" }}>{expanded ? expandIcon : icon}</Box>
                {result[0]}
            </UnderlinedBox>
            {expanded && <Box sx={{ height: "100%", marginLeft: "3em" }}>{result[1]}</Box>}
        </Box>
    )
}

const UnderlinedBox = styled(Box, { shouldForwardProp: (prop) => prop !== "underlinedHeader" })<{ underlinedHeader?: boolean }>(
    ({ underlinedHeader }) => ({
        display: "flex",
        gap: "1em",
        alignItems: "center",
        padding: ".5em 1em",
        ...(underlinedHeader && { borderBottom: border({ color: "#e2e2e2", style: "solid", width: px(2) }) }),
    })
)
