import { useState } from "react"
import { MenuItem, SelectTextField } from "@tm/components"
import { VatRateType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useVatRateText } from "@tm/utils"
import { VatRateSelectionItem } from "../data/model"

type Props = {
    vatType?: VatRateType
    vatRates: VatRateSelectionItem[]
    onChange(vatRate: VatRateSelectionItem): void
}

export function VatRateSelector({ vatRates, vatType, onChange }: Props) {
    const { translateText } = useLocalization()
    const { vatRateTypeDisplayLongText } = useVatRateText(translateText)
    const [selectedVatRate, setSelectedVatRate] = useState<VatRateSelectionItem | undefined>(undefined)

    function getVatRateType() {
        if (!selectedVatRate) {
            const findVat = vatRates.find((e) => (vatType ? e.vatType === vatType : e.isSelected))
            const vatRate = findVat || vatRates[0]
            setSelectedVatRate(vatRate)
        }
        return selectedVatRate?.vatType || undefined
    }

    function handleSelectVatRateType(newVatType: VatRateType) {
        const matchingVatRate = vatRates?.find((e) => e.vatType === newVatType)
        if (matchingVatRate) {
            setSelectedVatRate(matchingVatRate)
            onChange(matchingVatRate)
        }
    }

    return (
        <SelectTextField value={getVatRateType()} onChange={(e) => handleSelectVatRateType(parseInt(e.target.value))} size="large">
            {vatRates.map((item, idx) => {
                return (
                    <MenuItem key={idx} value={item.vatType}>
                        {vatRateTypeDisplayLongText({ vatRate: item.vatValue, vatType: item.vatType })}
                    </MenuItem>
                )
            })}
        </SelectTextField>
    )
}
