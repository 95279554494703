import { Tyre } from "@bundles/tyres/data/models"

export const filterTyres = (list: Tyre[]): [Tyre[], Tyre[]] => {
    return list.reduce<[Tyre[], Tyre[]]>(
        ([sameSizeTyres, mixSizeTyres], item) => {
            if (item.sizeFront === item.sizeRear) {
                sameSizeTyres.push(item)
            } else {
                mixSizeTyres.push(item)
            }
            return [sameSizeTyres, mixSizeTyres]
        },
        [[], []]
    )
}
