import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Checkbox, Dropdown, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getInfosByPrio } from "../../../data/helpers"
import { DTCGenart, Item } from "../../../data/model"
import { Actions } from "../business"
import { MainState, SelectedFilter } from "../business/model"
import CollapsibleWrapper from "./collapsibleWrapper"
import ErrorInfoItem from "./renderInfos"

type Props = {
    item: Item
    initiallyOpened?: boolean
    workTaskId: string
}

export default function ErrorCodeItem({ item: { code, infos, noResult }, initiallyOpened, workTaskId }: Props) {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const actions = bindActionCreators(Actions, dispatch)

    const selected = useSelector((s: MainState) => s.selectedData)
    const style = useMemo(() => getStyle(), [])

    const [showMoreButton, setShowMoreButton] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>({ filter: translateText(12995) })

    const [prioHigh, prioLow] = getInfosByPrio(
        infos.filter((x) => x.category === selectedFilter.filter || selectedFilter.filter === translateText(12995))
    )

    const handleSelectErrorItems = useCallback(
        (item: DTCGenart) => () => {
            const newItem = { ...item, code }
            actions.selectItem([newItem])
        },
        [actions, code]
    )

    const handlePreventOnClick = useCallback((e: React.MouseEvent<Element>) => {
        e?.preventDefault()
        e?.stopPropagation()
    }, [])

    const handleShowMoreButton = useCallback(() => {
        setShowMoreButton(!showMoreButton)
    }, [showMoreButton])

    const renderAppendix = (): JSX.Element | null => {
        const thisSelectedErrors = selected.filter((x) => x.code === code)

        if (!thisSelectedErrors?.length) {
            return null
        }

        return (
            <div className={style.appendix}>
                {thisSelectedErrors.map((selectedError, idx) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                        className={style.genarts}
                        onClick={handlePreventOnClick}
                    >
                        <Checkbox checked onToggle={handleSelectErrorItems(selectedError)} />
                        <Text>{selectedError.genbez}</Text>
                    </div>
                ))}
            </div>
        )
    }

    const renderHeader = useCallback(() => {
        const categories: SelectedFilter[] = [
            { filter: translateText(12995) },
            ...infos
                .map((x) => x.category)
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((category) => ({ filter: category })),
        ]

        return (
            <div className={style.gridHeader}>
                <Text>{translateText(12956)}</Text>
                <Text>{translateText(12957)}</Text>
                <Dropdown<SelectedFilter>
                    onClick={handlePreventOnClick}
                    layout={["holo"]}
                    items={categories}
                    itemView={(item) => <div>{item.filter}</div>}
                    onChange={setSelectedFilter}
                    value={selectedFilter}
                    disabled={false}
                    className={style.categoryDropdown}
                />
            </div>
        )
    }, [translateText, handlePreventOnClick, infos, selectedFilter, setSelectedFilter])

    if (noResult) {
        return (
            <CollapsibleWrapper errorCode={code} noContent={noResult} initiallyOpened={false}>
                {renderHeader()}
            </CollapsibleWrapper>
        )
    }

    return (
        <CollapsibleWrapper errorCode={code} noContent={noResult} renderAppendix={renderAppendix} initiallyOpened={initiallyOpened}>
            <div style={{ padding: ".5em" }}>
                {renderHeader()}
                {!noResult && !!prioHigh.length && <ErrorInfoItem workTaskId={workTaskId} infos={prioHigh} code={code} />}
                {!noResult && !!prioLow.length && (
                    <>
                        <Button fakeButton className={style.button} onClick={handleShowMoreButton}>
                            {showMoreButton ? translateText(12959) : translateText(12958)}
                        </Button>
                        {showMoreButton && <ErrorInfoItem workTaskId={workTaskId} infos={prioLow} code={code} />}
                    </>
                )}
            </div>
        </CollapsibleWrapper>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useStyle({
        button: {
            display: "block",
            margin: "auto",
            marginTop: ".5rem",
            marginBottom: ".5rem",
            width: "fit-content",
        },
        gridHeader: {
            display: "grid",
            gridTemplateColumns: " 3fr 2fr 1.5fr ",
            paddingLeft: "3em",
            alignItems: "baseline",
            paddingRight: "20em",
            marginLeft: theme.margin.m,
            marginBottom: theme.margin.m,
        },
        genarts: {
            display: "inline-flex",
            alignItems: "center",
            flexWrap: "nowrap",
            marginBottom: theme.margin.s,
            marginTop: theme.margin.s,
            $nest: {
                ".checkbox": {
                    marginRight: theme.margin.m,
                },
            },
            marginLeft: theme.margin.m,
        },
        categoryDropdown: {
            width: "12em",
            justifySelf: "center",
        },
        appendix: {
            flexBasis: "100%",
            borderLeft: "1px solid #9a9a9a8a",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
    })(ErrorCodeItem)
}
