import { Stack, styled, Image } from "@tm/components"

const CountryCodeBox = styled(Stack)({
    width: "25px",
    backgroundColor: "#003bb2",
    color: "white",
    display: "flex",
    alignItems: "center",
    fontSize: "11px",
    padding: "2px",
})

type Props = {
    licensePlateCode?: string
    hideEuroIcon?: boolean
}

export function LicensPlateEuroCode(props: Props) {
    const { licensePlateCode, hideEuroIcon } = props
    return (
        <CountryCodeBox>
            {!hideEuroIcon && <Image src="styles/default/images/eu-stars.svg" width="15px" />} {licensePlateCode}
        </CountryCodeBox>
    )
}
