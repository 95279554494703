import { Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { StackRow } from "../StyledComponents"

type Props = {
    disableConfirmButton: boolean
    disableResetButton: boolean
    onCloseButtonClick(): void
    onConfirmButtonClick(): void
    onResetButtonClick(): void
}

export default function DialogHeaderButtons(props: Props) {
    const { disableConfirmButton, disableResetButton, onConfirmButtonClick, onCloseButtonClick, onResetButtonClick } = props
    const { translateText } = useLocalization()

    return (
        <StackRow gap={0.5}>
            <Button
                title={translateText(48)}
                onClick={onResetButtonClick}
                disabled={disableResetButton}
                startIcon={<Icon name="refresh" />}
                size="extralarge"
            />
            <Button title={translateText(70)} onClick={onCloseButtonClick} startIcon={<Icon name="close" />} size="extralarge" />
            <Button
                title={translateText(9)}
                onClick={onConfirmButtonClick}
                startIcon={<Icon name="check" />}
                color="success"
                disabled={disableConfirmButton}
                size="extralarge"
            />
        </StackRow>
    )
}
