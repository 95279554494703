import { ShowConfigResponse, Vehicle, VehicleType } from "@tm/models"

import { useEffect, useState } from "react"
import { showGsiVehicle } from "../../data/repositories/gsi"
import type { DetailsProps } from "./component"
import { DetailsByVehicle } from "./DetailsByVehicle"

type Props = DetailsProps & {
    vehicleType: VehicleType
    vehicleId: string
    workTaskId?: string
    availableVehicleSearches?: ShowConfigResponse
}

export function DetailsByVehicleId(props: Props) {
    const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined)

    useEffect(() => {
        let isMounted = true

        showGsiVehicle(props.vehicleId).then((data) => {
            if (isMounted) {
                setVehicle(data)
            }
        })

        return () => {
            isMounted = false
        }
    }, [props.vehicleId])

    return (
        <DetailsByVehicle
            vehicle={vehicle}
            history={props.history}
            location={props.location}
            match={props.match}
            actions={props.actions}
            tabs={props.tabs}
            vehicleType={VehicleType.Undefined}
        />
    )
}
