import { Box, styled, Typography, Image, ImageContainer, useDefaultArticleItemState } from "@tm/components"
import { Article } from "@tm/models"

type Props = {
    item: Article
}

const expandedImageStylings = { size: 50, marginTop: -9 }
const collapsedImageStylings = { size: 36, marginTop: -5 }

export function ChainItem({ item }: Props) {
    const { thumbnailFallbackUrl } = useDefaultArticleItemState((x) => x.displayArticle.image)
    const showArticleImages = useDefaultArticleItemState((x) => x.options.showArticleImages)
    const expandedList = useDefaultArticleItemState((x) => x.expanded)

    const imageStylings = expandedList ? expandedImageStylings : collapsedImageStylings

    return (
        <StyledChainItemBox>
            <Box mr="5px" ml="5px">
                {showArticleImages && (
                    <ImageContainer mr="5px" ml="5px" imageStylings={imageStylings} data-clickable>
                        <StyledImage src={item?.thumbnail || thumbnailFallbackUrl || ""} />
                    </ImageContainer>
                )}
            </Box>
            <Box mr="1em" ml="5px">
                <Typography>{item.productGroup?.name}</Typography>
                <Typography fontWeight="600">
                    {item.supplier?.name} | {item.supplierArticleNo}
                </Typography>
            </Box>
        </StyledChainItemBox>
    )
}

const StyledChainItemBox = styled(Box)(({ theme }) => ({
    border: "1px solid #b4b4b4",
    display: "flex",
    flexGrow: "2",
    margin: "0 3px",
    minWidth: "auto",
    maxWidth: "fit-content",
    textWrap: "nowrap",
    ":hover": {
        borderColor: `${theme.palette.primary.main}`,
        cursor: "pointer",
    },
}))

const StyledImage = styled(Image)({
    width: "100%",
    height: "100%",
})
