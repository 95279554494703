import { useCallback, useEffect, useMemo, useState } from "react"
import {
    ArticleListType,
    channel,
    Article,
    SupplierArticleDto,
    TyreArticle,
    WheelsArticleItemState,
    TyreArticleAttribute,
    TyreArticleAttributes,
} from "@tm/models"
import { ArticleItem, ArticleItemStateProvider } from "@tm/components"
import { copyToClipboard } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { isEqual } from "lodash"
import {
    useArticleActions,
    useDisplayArticle,
    useHandleClickDetails,
    useHandleClickImage,
    useHandleClickReference,
    useArticleImages,
    useArticleNotes,
    useArticleNotifications,
    useWatchlist,
    useArticleExtendedDeliveryInfo,
    useShowArticleDetails,
    useGetRepairTimesUrl,
    useHandleClickAvailability,
    useHandleRemoveFromBasket,
    useArticleTradeReferences,
    useLoadErpInfos,
    useArticleOptions,
} from "../ListV2/hooks"
import AvailabilityComponent from "../../../../erp/src/_shared/availability-wrapper"
import { useDmsArticleInfo } from "../../../../dms/src/hooks/useDmsArticleInfo"
import { useArticleBasketActions } from "../PureList/hooks"
import {
    useBasketQuantitiesContext,
    useErpInfosContext,
    usePreviouslyOrderedArticlesContext,
    useProductGroupRepairTimesDataContext,
} from "../ListV2/ContextProvider"
import { useArticleListConfiguration } from "../ListV2/ArticleListConfiguration"
import { CombinedParticularState, useParticularContext } from "../PureList/ContextProviders/Particular/ParticularContext"
import { useTyreArticleSelection } from "./hooks"
import { useArticleListFeaturesDisable } from "../ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { getTyreAttributeFromIdValue } from "../TyresList/helpers/getTyreAttributeFromIdValue"

type WheelsArticleItemProps = {
    article: Article | TyreArticle
}

export function WheelsArticleItem(props: WheelsArticleItemProps) {
    const { article } = props
    const options = useArticleOptions(article)
    const listType: ArticleListType = "pure"
    const { translateText } = useLocalization()
    const disables = useArticleListFeaturesDisable()

    const particularContext = useParticularContext<CombinedParticularState>()

    const [expanded, setExpanded] = useState(!options.compactView)

    const isSupplierSelected = useMemo(
        () => !!particularContext?.selectedFilters?.find((x) => x.group === "manufacturer" && x.id === article.supplier.id),
        [particularContext?.selectedFilters, article.supplier.id]
    )

    const { articleErpInfos, addButtonsDisabled, handleAddToCostEstimation } = useArticleBasketActions(article)
    const erpInfosData = useErpInfosContext()
    const displayArticle = useDisplayArticle(article, particularContext?.selectedFilters ?? [], undefined, isSupplierSelected)
    const { productGroupRepairTimes } = useProductGroupRepairTimesDataContext()
    const articleImages = useArticleImages(article)
    const notes = useArticleNotes(article)
    const getRepairTimesUrl = useGetRepairTimesUrl()

    const previouslyOrderedArticles = usePreviouslyOrderedArticlesContext()
    const showArticleNotes = useArticleListConfiguration((config) => config.enableArticleNotes)
    const feedbackConfiguration = useArticleListConfiguration((config) => config.feedbackConfiguration)
    const showExtendedDelivery = useArticleListConfiguration((config) => !config.hideExtendedDeliveryComponent)
    const [articleQuantity, setArticleQuantity] = useState(article.quantity)
    const [originalQuantity, setOriginalQuantity] = useState(article.quantity)

    const showArticleDetails = useShowArticleDetails()
    const handleClickDetails = useHandleClickDetails(article, articleQuantity)
    const handleClickAvailability = useHandleClickAvailability(article, articleQuantity, erpInfosData)
    const { handleClickImage, handleCloseImage, showImageViewer } = useHandleClickImage(article)
    const handleClickReference = useHandleClickReference(article, articleQuantity, showArticleDetails)
    const { basketQuantities, updateBasketQuantities } = useBasketQuantitiesContext()
    const tradeReferences = useArticleTradeReferences(article)
    const loadErpInfos = useLoadErpInfos(erpInfosData, tradeReferences)

    useEffect(() => {
        setExpanded(!options.compactView)
    }, [options.compactView])

    const toggleExpand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    const alreadyOrdered = useMemo(() => {
        return (
            previouslyOrderedArticles?.some((x) => x.supplierArticleNumber === article.supplierArticleNo && x.supplierId === article.supplier.id) ??
            false
        )
    }, [previouslyOrderedArticles, article.supplierArticleNo, article.supplier.id])

    const repairTimeProviders = productGroupRepairTimes?.productGroups[article.productGroup.id]
    const repairTimesUrl = useMemo(() => {
        if (!!repairTimeProviders?.length && repairTimeProviders) {
            return getRepairTimesUrl(article, repairTimeProviders)
        }
    }, [repairTimeProviders, getRepairTimesUrl, article])

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupId: article.productGroup.id,
                } as SupplierArticleDto)
            ),
        [basketQuantities, article]
    )

    const articleActions = useArticleActions({
        article,
        articleErpInfo: articleErpInfos.default,
        feedbackConfiguration,
        isNotesEnabled: showArticleNotes,
        listType,
        notes: notes.notes,
        repairTimesUrl,
        onClickDetails: handleClickDetails,
        toggleExpand,
        toggleNotes: notes.toggle,
        hideAlternatives: disables.disableAlternatives,
        disableDeleteButton: disables.disableDeleteButton,
    })

    const removeFromBasket = useHandleRemoveFromBasket()

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setArticleQuantity(value)

            if (loadErpInfo) {
                setOriginalQuantity(value)

                if (articleErpInfos.default) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.default.erpSystem })
                }
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }

            particularContext?.onChangeQuantity?.(article, value)
        },
        [particularContext, article, articleErpInfos.default, articleErpInfos.alternative, loadErpInfos]
    )

    const handleAddToBasket = useCallback(
        async (quantity?: number) => {
            particularContext?.onAddToBasket?.(article, quantity ?? articleQuantity)
        },
        [particularContext, article, articleQuantity]
    )

    const notifications = useArticleNotifications(articleErpInfos.default)
    const watchList = useWatchlist(article)
    const selection = useTyreArticleSelection(
        (particularContext?.selectedArticles as Article[]) ?? [],
        particularContext?.toggleSelectedArticle as (article: Article) => void
    )
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(showExtendedDelivery, articleErpInfos.default)
    const dmsArticleInfo = useDmsArticleInfo(article)

    const handleCopyArticleNumber = useCallback(
        (number: string) => {
            copyToClipboard(number)
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
        },
        [translateText]
    )

    const handleClickAttribute = useMemo(() => {
        if (!particularContext?.handleClickAttribute) {
            return undefined
        }

        return (id: number, openInModal: boolean, value: string) => {
            if (!value) {
                return
            }

            const foundArticleAttribute = getTyreAttributeFromIdValue(article.attributes, id, value)

            if (foundArticleAttribute) {
                particularContext.handleClickAttribute!(foundArticleAttribute as TyreArticleAttribute)
            }
        }
    }, [particularContext?.handleClickAttribute, article.attributes])

    const showExtendedAvailabilityContainer =
        !!erpInfosData.erpConfig?.erpSystemConfigs && erpInfosData.erpConfig?.erpSystemConfigs.filter((x) => x.useForGetErpInformation).length > 1

    const articleState = useMemo<WheelsArticleItemState>(
        () => ({
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            isVehicleDependent: false,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            quantity: articleQuantity,
            selection,
            showImageViewer,
            type: particularContext?.articleItemType ?? "default",
            watchList,
            basketQuantity,
            showExtendedAvailabilityContainer,
            addToBasketButtonDisabled: addButtonsDisabled,
            addToCostEstimationButtonDisabled: true,
            showDescriptionCompactView: !disables.disableDescriptionCompactView,
            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            handleClickAttribute,
            toggleExpand,
            AvailabilityComponent,
            largeCheckbox: !!particularContext?.largeCheckbox,
        }),
        [
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            articleQuantity,
            selection,
            showImageViewer,
            particularContext?.articleItemType,
            watchList,
            basketQuantity,
            showExtendedAvailabilityContainer,
            addButtonsDisabled,
            disables.disableDescriptionCompactView,
            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
            handleClickAttribute,
            particularContext?.largeCheckbox,
        ]
    )
    return (
        <ArticleItemStateProvider value={articleState}>
            <ArticleItem variant="default" />
        </ArticleItemStateProvider>
    )
}
