import { Filter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Text } from "@tm/controls"
import { FC, memo, useEffect, useMemo, useState } from "react"
import { ISensorFilters } from "../rdks-list/business"
import { SelectionCheckbox } from "."

type Props = {
    items: Filter[]
    selectedItems: Filter[]
    searchValue?: string
    onchange: (path: ISensorFilters, item: Filter) => void
    extendedAssortment: boolean
}

const SelectionSensorFilters = memo<Props>(({ items, selectedItems, searchValue, onchange, extendedAssortment }) => {
    const [showMore, setShowMore] = useState<boolean>(false)

    const manuelFilteredItems: Filter[] = useMemo<Filter[]>(() => {
        return items.filter((x) => x.name && x.name.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase() ?? ""))
    }, [items, selectedItems])

    const topPriorityList = manuelFilteredItems.filter((x) => x.isTopPriority)
    const notSoPriorityList = manuelFilteredItems.filter((x) => !x.isTopPriority)

    const { translateText } = useLocalization()

    useEffect(() => {
        setShowMore(extendedAssortment)
    }, [extendedAssortment])

    const renderList = (item: Filter, idx: number) => {
        return (
            <MyComp
                key={idx}
                item={item}
                index={idx}
                handleFilterChange={onchange}
                selected={!!selectedItems?.find((x) => x.group == item.group && item.name == x.name)}
            />
        )
    }

    const handleShowMore = () => {
        setShowMore(!showMore)
    }

    return (
        <>
            {topPriorityList.map(renderList)}
            {notSoPriorityList && (
                <div className="selection-list__indent">
                    <Text className=" is-clickable" size="xs" onClick={handleShowMore}>
                        {translateText(showMore ? 169 : 168)}
                    </Text>
                    {showMore && notSoPriorityList.map(renderList)}
                </div>
            )}
        </>
    )
})

export default SelectionSensorFilters

type MyCompProps = { item: Filter; index: number; selected: boolean; handleFilterChange: (path: ISensorFilters, item: Filter) => void }

const MyComp: FC<MyCompProps> = memo(({ item, index, selected, handleFilterChange }) => {
    const handleChange = () => {
        handleFilterChange("manufacturer", item)
    }

    return (
        <SelectionCheckbox
            label={item.name ?? "-"}
            onChange={handleChange}
            selected={selected}
            blockModifier={!item.isTopPriority}
            key={item.group + index.toString()}
        />
    )
}, areeq)

// memo it up!
function areeq(prevProps: MyCompProps, nextProps: MyCompProps) {
    return nextProps.item.name === prevProps.item.name && prevProps.selected === nextProps.selected
}
