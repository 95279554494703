import { MainState } from "../../../main"

export function createSizeQuery(selectedFilters: MainState["summary"]["selectedFilters"]) {
    const { width, height, inch } = selectedFilters
    let size = ""
    if (width && inch) {
        size = `${width.value + (height?.value ? "/" : "") + (height?.value || "")}R${inch.value} `
    }

    return size.trim()
}
