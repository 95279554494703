import { Stack } from "@tm/components"
import { RefObject, useMemo } from "react"
import { SuggestionFieldButtonGroup } from "@tm/controls"
import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { useAvailableVehicleSearchOptions } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { useSelectedVehicleLookup } from "../../data/hooks"
import { VehicleSearchField } from "./VehicleSearchField"
import { VehicleSearchfieldV1Props } from "./VehicleSearchFieldSwitch"
import SpecialLicensePlatesComponent from "../widget/components/special-license-plates/component"

export function VehicleSearchV1(props: VehicleSearchfieldV1Props) {
    const { inputFieldRef, className, ...restProps } = props

    // moved code here from SpecialLicensePlatesComponent to have the info about rendering of SpecialLicensePlatesComponent
    const { userContext } = useUser() ?? {}
    const { selectedVehicleLookup } = useSelectedVehicleLookup(props.vehicleType)
    const {
        availableVehicleSearches: { configuredVehicleLookups },
    } = useAvailableVehicleSearchOptions()
    const { traderId } = userContext.principal ?? {}

    // These are all selectable lookups that are not used in the default search.
    const selectableLookups = configuredVehicleLookups.filter(
        (x) => x.isSelectable && !x.isUsedInDefaultSearch && x.supportedVehicleTypes.includes(props.vehicleType)
    )
    let selectedLookupConfig: VehicleLookupConfig | undefined

    if (selectedVehicleLookup?.lookupTypeId !== undefined) {
        selectedLookupConfig = configuredVehicleLookups.find(
            (lookup) => lookup.lookupTypeId === selectedVehicleLookup.lookupTypeId && lookup.isSelectable
        )
        // If no lookup is selected but there is only one selectable lookup that is not used in default search, we expect this
        // lookup to be selected.
    } else if (selectableLookups.length === 1) {
        ;[selectedLookupConfig] = selectableLookups
    }

    const hideSpecialLicensePlate = useMemo(
        () => shouldBeHidden(selectedLookupConfig, props.vehicleType, traderId),
        [selectedLookupConfig, userContext, props.vehicleType, traderId]
    )

    return (
        <Stack className={className} maxWidth="100%">
            {!(props.hideSpecialLicensePlateSearchField || hideSpecialLicensePlate) && (
                <SpecialLicensePlatesComponent
                    {...props?.specialLicensePlatesProps}
                    vehicleType={props.vehicleType}
                    inputFieldRef={inputFieldRef as RefObject<SuggestionFieldButtonGroup<unknown>>}
                    className="vehicle-widget__special-license-plates-micro"
                    selectedLookupConfig={selectedLookupConfig}
                />
            )}

            <VehicleSearchField
                {...restProps}
                inputFieldRef={props.hideSpecialLicensePlateSearchField || hideSpecialLicensePlate ? inputFieldRef : undefined}
            />
        </Stack>
    )
}

const tradersWithFakeSearchField = [
    746, // krautlipt
    105, // hlgroup
    404, // kpro-online-platform
    1591, // Automatic ehf
]

function shouldBeHidden(selectedLookup: VehicleLookupConfig | undefined, vehicleType: VehicleType, traderId: number | undefined) {
    // Always render if the catalog was configured to have a separate search field
    if (traderId && tradersWithFakeSearchField.some((id) => id === traderId) && vehicleType === VehicleType.PassengerCar) {
        return false
    }

    // Don't render if the lookup is not configured or the lookup is already used in default search field
    if (!selectedLookup || selectedLookup.isUsedInDefaultSearch) {
        return true
    }

    // Don't render if the lookup is not available for current vehicle type
    if (!selectedLookup.supportedVehicleTypes.includes(vehicleType)) {
        return true
    }

    return false
}
