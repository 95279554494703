import { Icon, Stack, Tooltip, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Quota } from "@tm/models"

export type Props = {
    quota?: Quota
    marginY?: string
    direction?: "row" | "column"
}

export function QuotaText({ quota, marginY, direction }: Props) {
    const { translateText } = useLocalization()

    if (!quota) {
        return null
    }

    const used = `${quota?.used || "0"}`
    const limit = `${quota?.limit || "-"}`

    if (direction === "row") {
        return (
            <Tooltip
                title={
                    <Stack sx={{ color: "white" }}>
                        <Typography variant="label">{translateText(quota.hasQuotaAvailable ? 13346 : 1524)}</Typography>
                        <Stack direction="row">
                            <Typography variant="label" fontWeight="bold">
                                {used}
                            </Typography>
                            <Typography variant="label"> / </Typography>
                            <Typography variant="label" fontWeight="bold">
                                {limit}
                            </Typography>
                        </Stack>
                    </Stack>
                }
            >
                <Stack direction="row" spacing={0.5} alignItems="center" pl={0.5}>
                    <Typography variant="label" fontWeight="bold">
                        {used}
                    </Typography>
                    <Icon name="info" size="18px" />
                </Stack>
            </Tooltip>
        )
    }

    return (
        <Stack direction="row" spacing="4px" marginY={marginY}>
            <Typography variant="label">{translateText(quota.hasQuotaAvailable ? 13346 : 1524)}</Typography>
            <Typography variant="label" fontWeight="bold">
                {used}
            </Typography>
            <Typography variant="label"> / </Typography>
            <Typography variant="label" fontWeight="bold">
                {limit}
            </Typography>
        </Stack>
    )
}
