import { Box, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect } from "react"
import { DropDown } from "./dropDown"
import { SizeDropDownWrapper } from "./sizeDropDownWrapper"
import { TyresCritsResponse } from "../../../../../data/repositories"
import { enableFreeInputMode } from "../../../../../helpers/enableFreeInputMode"
import { compareTyreFilters } from "../../../../../helpers/compareTyreFilters"
import { DRTire, ExtendEFilterNames, SelectedFilters, SizeSpecs, TireType } from "../../../../../data/models"
import { loadTiresCrits } from "../../../../../helpers/tires"

type Props = {
    selectedFilters: SelectedFilters
    setSelectedFiltersTemp: (filters?: SelectedFilters) => void
    tires: DRTire[]
    tabType: string
    setSelectedTire: (tire?: TireType) => void
    tyresCrits: Partial<TyresCritsResponse>
    freeInputMode: boolean
    setFreeInputMode: (freeMode: boolean) => void
    loading: boolean
    loadingTyresData: boolean
}

export function SizeSpecifications(props: Props) {
    const {
        selectedFilters,
        setSelectedFiltersTemp,
        tires,
        tabType,
        setSelectedTire,
        tyresCrits,
        freeInputMode,
        setFreeInputMode,
        loading,
        loadingTyresData,
    } = props

    const { width, height, inch, loadIndex, speedIndex, size } = selectedFilters
    const { translateText } = useLocalization()
    const isWidthHeightInchSelected = !!(width && height && inch)

    useEffect(() => {
        const sizeSpecs: SizeSpecs = {
            width,
            height,
            inch,
            loadIndex,
            speedIndex,
        }
        const tempSizeSpecs: SizeSpecs = { ...sizeSpecs }

        if (tempSizeSpecs.width === "" && tyresCrits.width?.length === 1) {
            tempSizeSpecs.width = tyresCrits.width[0].value
        }

        if (tempSizeSpecs.height === "" && tyresCrits.height?.length === 1) {
            tempSizeSpecs.height = tyresCrits.height[0].value
        }

        if (tempSizeSpecs.inch === "" && tyresCrits.inch?.length === 1) {
            tempSizeSpecs.inch = tyresCrits.inch[0].value
        }

        if (tempSizeSpecs.loadIndex === "" && tyresCrits.loadIndex?.length === 1) {
            tempSizeSpecs.loadIndex = tyresCrits.loadIndex[0].value
        }

        if (tempSizeSpecs.speedIndex === "" && tyresCrits.speedIndex?.length === 1) {
            tempSizeSpecs.speedIndex = tyresCrits.speedIndex[0].value
        }

        if (!(JSON.stringify(tempSizeSpecs) === JSON.stringify(sizeSpecs))) {
            const newFilters = { ...selectedFilters, ...tempSizeSpecs }
            setSelectedFiltersTemp(newFilters)
            compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
        }
    }, [tyresCrits.width, tyresCrits.height, tyresCrits.inch, tyresCrits.loadIndex, tyresCrits.speedIndex])

    const updateRearAndSpareTire = (passedTabType: string) => {
        if (passedTabType === "rear") {
            setSelectedTire(TireType.RearTire)
        }

        if (passedTabType === "spareWheel") {
            setSelectedTire(TireType.SpareTire)
        }
    }

    const handleSizeFilterSelect = (filter: DRTire) => {
        const newFilters = { ...selectedFilters, ...filter }
        newFilters.axleSpecs = {}
        newFilters.manufacturer = ""
        newFilters.tyreModel = ""
        newFilters.season = ""
        updateRearAndSpareTire(tabType)
        setSelectedFiltersTemp(newFilters)
        compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
    }

    const handleFilterSelect = (path: string, filter: string) => {
        let newFilters: SelectedFilters

        if (!enableFreeInputMode(tyresCrits, path, filter)) {
            setFreeInputMode(true)
        }

        switch (path) {
            case ExtendEFilterNames.width:
                newFilters = {
                    ...selectedFilters,
                    width: filter,
                    height: "",
                    inch: "",
                    loadIndex: "",
                    speedIndex: "",
                    size: "",
                }
                setSelectedFiltersTemp(newFilters)
                compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
                break
            case ExtendEFilterNames.height:
                newFilters = {
                    ...selectedFilters,
                    height: filter,
                    inch: "",
                    loadIndex: "",
                    speedIndex: "",
                    size: "",
                }
                setSelectedFiltersTemp(newFilters)
                compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
                break
            case ExtendEFilterNames.inch:
                newFilters = {
                    ...selectedFilters,
                    inch: filter,
                    loadIndex: "",
                    speedIndex: "",
                    size: "",
                }
                setSelectedFiltersTemp(newFilters)
                compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
                break
            case ExtendEFilterNames.loadIndex:
                newFilters = {
                    ...selectedFilters,
                    loadIndex: filter,
                    speedIndex: "",
                    size: "",
                }
                setSelectedFiltersTemp(newFilters)
                compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
                break
            case ExtendEFilterNames.speedIndex:
                newFilters = {
                    ...selectedFilters,
                    speedIndex: filter,
                    size: "",
                }
                setSelectedFiltersTemp(newFilters)
                compareTyreFilters(selectedFilters, newFilters) && loadTiresCrits(newFilters)
                break
            default:
                break
        }
    }

    return (
        <>
            <Typography marginBottom="0.5em" variant="h3">
                {translateText(13071)}
            </Typography>
            <Typography marginBottom="1em" variant="h4">
                {translateText(13072)}
            </Typography>
            <StyledBox>
                <SizeDropDownWrapper
                    items={tires}
                    onChange={handleSizeFilterSelect}
                    selectedValue={(!!size && !!loadIndex && `${size} ${loadIndex ?? ""}${speedIndex ?? ""}`) || undefined}
                    isLoading={loadingTyresData}
                    selectedFilters={selectedFilters}
                />
                <DropDown
                    items={tyresCrits.width?.map((x) => x.value) ?? []}
                    name={translateText(713)}
                    path={ExtendEFilterNames.width}
                    selectedValue={tyresCrits.width?.length === 1 ? tyresCrits.width[0].value : (width ?? "")}
                    onChange={handleFilterSelect}
                    focusInput={!tires && !width && !loading}
                    isLoading={!width ? loading : false}
                    disableDropDown={freeInputMode ? false : loading}
                    inputMode="numeric"
                />
                <Typography variant="h3">/</Typography>
                <DropDown
                    items={tyresCrits.height?.map((x) => x.value) ?? []}
                    name={translateText(714)}
                    path={ExtendEFilterNames.height}
                    selectedValue={tyresCrits.height?.length === 1 ? tyresCrits.height[0].value : (height ?? "")}
                    onChange={handleFilterSelect}
                    focusInput={!!(width && !height && !loading)}
                    isLoading={freeInputMode ? false : loading}
                    disableDropDown={freeInputMode ? false : loading}
                    inputMode="numeric"
                />
                <Typography variant="h3">R</Typography>
                <DropDown
                    items={tyresCrits.inch?.map((x) => x.value) ?? []}
                    name={translateText(743)}
                    path={ExtendEFilterNames.inch}
                    selectedValue={tyresCrits.inch?.length === 1 ? tyresCrits.inch[0].value : (inch ?? "")}
                    onChange={handleFilterSelect}
                    focusInput={!!(height && !inch && !loading)}
                    isLoading={freeInputMode ? false : loading}
                    disableDropDown={freeInputMode ? false : loading}
                    inputMode="numeric"
                />
            </StyledBox>
            <StyledBox>
                <DropDown
                    items={tyresCrits.loadIndex?.map((x) => x.value) ?? []}
                    name={translateText(13074)}
                    path={ExtendEFilterNames.loadIndex}
                    selectedValue={tyresCrits.loadIndex?.length === 1 ? tyresCrits.loadIndex[0].value : (loadIndex ?? "")}
                    onChange={handleFilterSelect}
                    focusInput={!!(isWidthHeightInchSelected && !loadIndex && !loading)}
                    isLoading={freeInputMode ? false : loading}
                    disableDropDown={freeInputMode ? false : loading}
                    inputMode="numeric"
                />
                <DropDown
                    items={tyresCrits.speedIndex?.map((x) => x.value) ?? []}
                    name={translateText(719)}
                    path={ExtendEFilterNames.speedIndex}
                    selectedValue={tyresCrits.speedIndex?.length === 1 ? tyresCrits.speedIndex[0].value : (speedIndex ?? "")}
                    onChange={handleFilterSelect}
                    focusInput={!!(isWidthHeightInchSelected && loadIndex && !speedIndex && !loading)}
                    isLoading={freeInputMode ? false : loading}
                    disableDropDown={freeInputMode ? false : loading}
                />
            </StyledBox>
        </>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    gap: ".5em",
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(1),
}))
