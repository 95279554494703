import { Genart } from "../data/models"

export const addOrRemoveOeArticle = (selectedGenArts: Genart[], item: Genart) => {
    let newSelectedGenarts: Genart[]
    const inArray = selectedGenArts?.some((genArt) => genArt.fsArticle?.id === item.fsArticle?.id) ?? false

    if (inArray) {
        newSelectedGenarts = selectedGenArts?.filter((genArt) => genArt.fsArticle?.id !== item.fsArticle?.id) || []
    } else {
        newSelectedGenarts = [...(selectedGenArts || []), item]
    }

    return newSelectedGenarts
}
