import { TabControl } from "@tm/controls"
import { useStyle } from "@tm/context-distribution"
import { em } from "csx"
import { FastCalculator } from "@tm/data"
import { FC } from "react"
import { SummaryContext } from "../../../data/model"
import { BorderedCollapsible, SelectionItems } from "../../_shared"
import { mainActions, useFastCalculatorStore, useStoreContext } from "../../../state"

type Props = {
    maxSelections: number
}

const Services: FC<Props> = ({ maxSelections }) => {
    const instance = useStoreContext()

    const { selectedServices, selectedCalcState, unsavedServices } = useFastCalculatorStore((state) => ({
        selectedServices: state.selectedServices,
        selectedCalcState: state.selectedCalcState,
        unsavedServices: state.unsavedServices,
    }))

    const context = selectedCalcState?.context as SummaryContext | undefined
    const selections = context?.categories?.selections
    if (!selections) {
        return null
    }

    const label = `${context?.categories?.label} (${selectedServices.length}/${maxSelections})`
    const validSelections = selections.filter((x) => x.items.length)

    function handleNavigation(tab: string) {
        const selection = selections?.find((x) => x.label == tab)
        selection && mainActions.setSelectedCalcSelection(instance, selection)
    }

    function renderCategories(calcSelection: FastCalculator.CalcSelection, idx: number) {
        return (
            <TabControl.Tab key={idx} identifier={calcSelection.label}>
                {calcSelection.label}
            </TabControl.Tab>
        )
    }

    function mergeSelectionsWithLocalSelections(items: FastCalculator.CalcSelectionItem[]) {
        const mergedSelections: FastCalculator.CalcSelectionItem[] = []

        items.map((selection) => {
            const localSelection = unsavedServices?.find((s) => s.id == selection.id)
            if (localSelection) {
                mergedSelections.push({ ...selection, isSelected: !localSelection?.isSelected })
            } else {
                mergedSelections.push(selection)
            }
        })

        return mergedSelections
    }

    return (
        <BorderedCollapsible name={label ?? ""} initiallyOpened className={style.categories}>
            {validSelections.map((calcSelection, idx) => (
                <div key={idx} className={style.category}>
                    <TabControl selectedTabIdentifier={calcSelection?.label ?? ""} onTabSelect={handleNavigation}>
                        {[calcSelection].map(renderCategories)}
                    </TabControl>
                    {calcSelection && (
                        <SelectionItems
                            items={mergeSelectionsWithLocalSelections(calcSelection.items)}
                            disableNewSelection={selectedServices.length >= maxSelections}
                        />
                    )}
                </div>
            ))}
        </BorderedCollapsible>
    )
}

const style = useStyle({
    categories: {
        $nest: {
            ".collapsible__content": {
                display: "flex",
                flexWrap: "wrap",
            },
        },
    },
    category: {
        flex: 1,
        $nest: {
            "&:first-of-type": {
                marginRight: em(0.5),
            },
        },
    },
})(Services)

export default Services
