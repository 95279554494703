import { TyreFilter, Vehicle } from "@tm/models"
import { BundleActions, BundleActionTypes } from "../../../data/business"
import { MainActionsType } from "../../main/business"
import { IVehicleRecordFilter } from "../../summary/business"

export type VehRecords = Partial<Record<IVehicleRecordFilter, TyreFilter>>

export type ModuleManagerState = {
    vehicle?: Vehicle
    vehRecords: VehRecords
}

export type ComponentActionType = BundleActionTypes | { type: "SAVE_VEHICLE_RECORDS"; payload: VehRecords }

const DEFAULT_STATE: ModuleManagerState = {
    vehRecords: {},
}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): ModuleManagerState => {
    switch (action.type) {
        case "VEHICLE_SET": {
            return { ...state, vehicle: action.payload }
        }
        case "SAVE_VEHICLE_RECORDS": {
            return {
                ...state,
                vehRecords: {
                    ...state.vehRecords,
                    ...action.payload,
                },
            }
        }
        default:
            return state
    }
}

const saveVehicleRecords = (records: VehRecords): ComponentActionType => ({ type: "SAVE_VEHICLE_RECORDS", payload: records })

export const Actions = { ...BundleActions, saveVehicleRecords }
