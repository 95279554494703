import { styled } from "@tm/components"
import { Icon, Loader, Image } from "@tm/controls"
import { ECounterType } from "@tm/models"
import { concat, TmaHelper } from "@tm/utils"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"

type Props = {
    image?: string
    imageFallbackType?: "manufacturer" | "car" | "bike"
    description?: string
    content?: string
    isLoading?: boolean
    isCurrent?: boolean
    isLast?: boolean
    resetUrl?: string
}

export default function SelectionsCard(props: PropsWithChildren<Props>) {
    const resetFunction = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionReset)
    }

    function renderContent() {
        if (props.isLoading) {
            return <Loader />
        }

        return (
            <>
                {props.image && <StyledImage className="selections__image" url={props.image} type={props.imageFallbackType} />}
                {props.description && <div className="selections__title subtitle">{props.description}</div>}
                {props.content && <div className="selections__headline">{props.content}</div>}
                {props.resetUrl && (
                    <Link to={props.resetUrl} onClick={resetFunction} className="btn btn--ghost">
                        <Icon name="close" size="s" className="icon--reset" />
                    </Link>
                )}
            </>
        )
    }

    return (
        <li className={concat(" ", "card selections__card", props.isCurrent ? "card--current" : "card--decent")}>
            <div className="selections__content">{renderContent()}</div>
            {!props.isLast && <div className="card__arrow" />}
        </li>
    )
}

const StyledImage = styled(Image)({
    width: "auto",
    minWidth: "3em",
})
