import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import Manager from "./components/manager"
import TyresSearchWidget from "./components/tyres-search-widget"
import TyresSearch from "./micros/tyres-search"
import { TyresSearchWidgetCompact, TyresSearchWidgetCompactBundle } from "./components/tyres-search-widget-compact"
import { initTyresBundle, version } from "./utils"
import { TyresError } from "./components/list/components"

const bundle: IBundle & { debug?: boolean } = {
    ...version,
    debug: true,
    components: [Main, Manager, TyresSearchWidget, TyresSearchWidgetCompactBundle],
    micros: [TyresSearch],
    init: initTyresBundle,
}

export default bundle

export { TyresSearchWidgetCompact, TyresSearchWidgetCompactBundle, TyresError }
