import { useState, useRef, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { withRouter, decodeUniqueId, renderRoute, TmaHelper, uniqueId, equals, RouteComponentProps } from "@tm/utils"
import { Box, Loader } from "@tm/components"
import { Customer, RegistrationNoType, TitleType, channel } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { IActions, Actions } from "./business"
import { bundleChannel } from "../../business"
import { CustomerRepository } from "../../data"
import CustomerDetailsInformation from "./components/information"
import { useSetApplyButtonState } from "../../data/hooks/useApplyButtonState"
import { useInvalidateWorkTaskBasketQueries } from "@bundles/basket"

type Props = RouteComponentProps<{ customerId?: string }> & {
    actions: IActions
    hideDiscount?: boolean
}

function CustomerDetailsComponent(props: Props) {
    const { workTaskId, attachToWorkTask } = useWorkTask() ?? {}
    const { translate } = useLocalization()
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()

    const [customer, setCustomer] = useState<Customer>()
    const [loading, setLoading] = useState(false)
    const setApplyButtonState = useSetApplyButtonState()

    const initialCustomer = useRef<Customer | undefined>(undefined)

    useEffect(() => {
        const customerId = props.match.params.customerId ? decodeUniqueId(props.match.params.customerId) : undefined
        props.actions.start(props.match.params.customerId)
        setApplyButtonState({ dataChanged: false, valid: false })

        if (customerId) {
            setLoading(true)
            let active = true
            CustomerRepository.load(customerId).then((customer) => {
                if (active) {
                    initialCustomer.current = customer
                    // initial empty values if field not exist
                    const initialCustomerModel: Customer = {
                        ...customer,
                        sparePartsSurcharge: customer.sparePartsSurcharge || 0,
                        repairTimesSurcharge: customer.repairTimesSurcharge || 0,
                        sparePartsDiscount: customer.sparePartsDiscount || 0,
                        repairTimesDiscount: customer.repairTimesDiscount || 0,
                    }
                    setCustomer(initialCustomerModel)
                    props.actions.loaded(initialCustomerModel)
                    setApplyButtonState({ dataChanged: false, valid: true })
                    setLoading(false)
                }
            })

            return () => {
                active = false
            }
        }

        const EMPTY_CUSTOMER = {
            id: uniqueId(),
            titleType: TitleType.Undefined,
            sparePartsSurcharge: 0,
            repairTimesSurcharge: 0,
            sparePartsDiscount: 0,
            repairTimesDiscount: 0,
        }

        setCustomer(EMPTY_CUSTOMER)
        setApplyButtonState({ dataChanged: true, valid: false })
        setLoading(false)
    }, [props.match.params.customerId])

    useEffect(() => {
        const openCustomer = (data: { vehicle?: string }) => {
            if (customer && workTaskId) {
                setTimeout(() => {
                    Morpheus.closeView("1")
                }, 150)
                attachToWorkTask?.({ customer: equals(initialCustomer.current, customer) ? customer.id : customer, vehicle: data.vehicle })
                channel("WORKTASK", workTaskId).publish("MODULE/CLOSED", location.pathname + location.search)
                TmaHelper.VehicleSelection.Apply(RegistrationNoType.VehicleBase)
                invalidateAllWorkTaskBasketRequests(workTaskId)
                props.history.push(renderRoute("/:workTaskId", props.match.params))
            }
        }

        return bundleChannel().subscribe("APPLY_CHANGES", openCustomer)
    }, [attachToWorkTask, customer, invalidateAllWorkTaskBasketRequests, props.history, props.match.params, workTaskId])

    const handleChange = (cust: Customer, valid: boolean) => {
        setApplyButtonState({ dataChanged: !equals(initialCustomer.current, cust), valid })
        setCustomer(cust)
    }

    let content = <h3>{translate(318)}</h3>
    if (loading) {
        content = <Loader />
    } else if (customer) {
        content = <CustomerDetailsInformation model={customer} onChange={handleChange} hideDiscount={props.hideDiscount} />
    }

    return (
        <Box m={2} className="tk-crm customer-details">
            {content}
        </Box>
    )
}

export default connectComponent(Actions, withRouter(CustomerDetailsComponent))
