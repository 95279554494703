import { ESufficentSelections } from "../../../../data/enums"
import { EreLinkImagesRequest } from "../../../../data/repositories"
import { MainState } from "../../../main"

export function createEreLinkImagesRequest(state: MainState): EreLinkImagesRequest | undefined {
    const {
        details: { selectedCriterias, mmtList },
        summary: { selectedMmtGroup },
    } = state
    if (selectedMmtGroup?.selectedErePosition) {
        return {
            vehicles: mmtList.sufficientSelection == ESufficentSelections.Distinct ? mmtList.items : selectedMmtGroup.mmtList,
            erePosition: selectedMmtGroup.selectedErePosition,
            criteria: selectedCriterias,
        }
    }
}
