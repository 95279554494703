import { Badge, Button, Icon } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { bindSpecialReactMethods } from "@tm/utils"
import { Component } from "react"
import { RedesignHelper } from "../rd-helper"
import { Actions, IActions } from "./business"
import { AddToRDBasketState } from "./business/model"

type Props = LocalizationProps &
    IMicros["standalone"]["rd-add-articles-to-basket"] & {
        state: AddToRDBasketState
        actions: IActions
    }

class AddToRDBasketComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleClick() {
        const { items, sourceId, tmaEventId } = this.props
        RedesignHelper.addItemsToRDBasket(items, this.props.actions.addItemToRdBasket, sourceId, tmaEventId)
    }

    render() {
        const {
            items,
            localization: { translateText },
            state: { states },
            buttonText,
        } = this.props
        // const loading = items[0] && states[items[0].id] && states[items[0].id].loading
        const loadedSuccesfully = items[0] && states[items[0].id] && states[items[0].id].loadedSuccesfully

        return (
            <div className="tk-standalone rd-add-articles-to-basket">
                {loadedSuccesfully && <Badge skin="dark" value={<Icon name="check" />} />}
                <Button
                    title={translateText(937)}
                    className="add-to-basket__button"
                    layout={["bordered"]}
                    skin="highlight"
                    onClick={this.handleClick}
                    icon="cart"
                    disabled={false}
                >
                    {buttonText && <span className="btn__text">{buttonText}</span>}
                </Button>
            </div>
        )
    }
}

export default connectComponent(Actions, withLocalization(AddToRDBasketComponent))
