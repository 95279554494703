import { ExternalModule } from "@tm/models"
import { ExternalCatalogComponentProps } from "../component"
import ItemCover from "./ItemCover"

type DropdownItemComponentProps = Pick<ExternalCatalogComponentProps, "itemClassName"> & {
    item: ExternalModule
    onClick: (item: ExternalModule) => void
}

function DropdownItemComponent(props: DropdownItemComponentProps) {
    const { item, itemClassName, onClick } = props
    const description = item.catalogDescription || item.description
    const logoParam = item.parameter?.find((param) => param.key === "URL_Logo")
    const className = `item ${itemClassName || ""}`.trim()

    async function handleClick() {
        onClick(item)
    }

    return (
        <div key={`drop-${item.description}-${item.type}`} className={className} onClick={handleClick}>
            <ItemCover title={description} logo={logoParam?.value} />
        </div>
    )
}

export default DropdownItemComponent
