import { Box, Button, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleGroup } from "../components/ArticleGroup"
import { ArticleSelection } from "../components/ArticleSelection"
import { NoSearchResult } from "../components/NoSearchResult"
import { useListOptionsContext } from "../ContextProvider"
import { useOffersArticlesContext, useOffersListParamsContext } from "./ContextProvider"
import { WholesalerArticleItem } from "../components/WholesalerArticleItem/WholesalerArticleItem"
import { NextPageLoader } from "../components/NextPageLoader"
import { emptyFunc } from "../helpers"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"

export function OffersList() {
    const { translateText } = useLocalization()

    const options = useListOptionsContext()

    const {
        noResult,
        startParams: { title },
    } = useOffersListParamsContext()

    const { isEnabled, hasNextPage, isLoaded, isLoading, isFetchingNextPage, isStalling, articleGroups, wholesalerArticles, loadNextPage } =
        useOffersArticlesContext()

    const showPageLoader = isLoading || isStalling || isFetchingNextPage || !isEnabled

    return (
        <>
            <ModalModuleHeader title={title} />
            <Box sx={{ overflowX: "hidden", overflowY: "scroll" }} paddingX={1} height="100%">
                {((!isFetchingNextPage && isLoaded && !isLoading && !articleGroups.length && !wholesalerArticles?.length) || noResult) && (
                    <NoSearchResult searchType="direct" />
                )}

                {isLoaded && !isStalling && (
                    <>
                        <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

                        {articleGroups.map((group) => {
                            switch (group.type) {
                                case "default":
                                    return (
                                        <ArticleGroup
                                            key={group.productGroup.id}
                                            group={group}
                                            currentSorting={undefined}
                                            onChangeSorting={emptyFunc}
                                            showFilterOptions={false}
                                            usedAttributeFilters={[]}
                                            onToggleAttributeFilter={emptyFunc}
                                            onResetAttributeFilters={emptyFunc}
                                            productGroupTopicIds={[]}
                                        />
                                    )
                                case "WholesalerArticle":
                                    return (
                                        <Stack padding={0.5} key="WholesalerArticles">
                                            {group.articles?.map((wholesalerArticle) => (
                                                <WholesalerArticleItem key={wholesalerArticle.wholesalerArticleNumber} article={wholesalerArticle} />
                                            ))}
                                        </Stack>
                                    )
                                default:
                                    return null
                            }
                        })}

                        <Stack padding={0.5} key="WholesalerArticles">
                            {!!wholesalerArticles?.length &&
                                wholesalerArticles.map((wholesalerArticle) => (
                                    <WholesalerArticleItem key={wholesalerArticle.wholesalerArticleNumber} article={wholesalerArticle} />
                                ))}
                        </Stack>
                    </>
                )}

                {showPageLoader && (
                    <NextPageLoader
                        loadNextPage={loadNextPage}
                        canLoadNextArticles={hasNextPage}
                        skeletonArticleCount={articleGroups.length ? 1 : 5}
                        skeletonCategory
                    />
                )}

                {hasNextPage && (
                    <Box display="flex" justifyContent="center" margin="20px">
                        <Button color="highlight" onClick={loadNextPage}>
                            {translateText(12430)}
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    )
}
