import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { DriveRightTyresRespone } from "./model"
import { mapDriveRightTyresResponse } from "./mapper"

export * from "./model"

export function loadDriveRightTyres(kTypNr: number) {
    const url = `${getServiceUrl()}/DriveRightTyres`
    const authorization = getStoredAuthorization()
    const body = { kTypNr }

    return new Promise<DriveRightTyresRespone>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => {
            if (response && response.tyres) {
                resolve(mapDriveRightTyresResponse(response))
            } else {
                reject()
            }
        }, reject)
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.tyresServiceUrl
}
