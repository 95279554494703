import { Box, Checkbox, Icon, ListItemButton, ListItemIcon, Loader, Typography } from "@tm/components"
import { CollapsibleWrapper, PartItem } from ".."
import { useFastServiceStore } from "../../../data"
import { Genart, Work } from "../../../data/models"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"
import { ListItemComponent } from "./shared/ListItemComponent"
import OePartItem from "./OePartItem"
import CustomPartItem from "./CustomPartItem"

type Props = {
    work: Work
    onSelect: (item: Genart) => void
    selectedGenArts?: Genart[]
    isDefect?: boolean
    onQuantityArticleChange: (articleId: string, quantity: number) => void
    onOeArticleChange: (fsArticleId: string, quantity: number, price: number, designation: string, oeNumber: string) => void
    showPartAlternatives: (genArt: Genart, article?: FSArticle, oeReplace?: boolean) => void
}

export default function GenArts({
    work,
    onSelect,
    selectedGenArts,
    isDefect,
    onQuantityArticleChange,
    onOeArticleChange,
    showPartAlternatives,
}: Props) {
    const items = work.genArts
    const specialReplace = work.providerId === "319000287"
    const { loading, inputsLocked } = useFastServiceStore((state) => ({
        loading: state.articlesLoading,
        inputsLocked: state.inputsLocked,
    }))

    const handleSelection = (item: Genart) => {
        onSelect(item)
    }

    const renderItems = (item: Genart, idx: number) => {
        if (!item.isSelected && !isDefect) {
            return null
        }

        if (item.article) {
            return (
                <PartItem
                    key={idx}
                    genArt={item}
                    fsArticle={item.fsArticle}
                    article={item.article}
                    onQuantityArticleChange={onQuantityArticleChange}
                    showPartAlternatives={showPartAlternatives}
                    disableCheckToggle={item.isMandatory}
                    enableOeReplace={specialReplace}
                    selectedGenArts={selectedGenArts}
                />
            )
        }

        if (item.isMandatory && item.fsArticle?.oeId) {
            return (
                <OePartItem
                    key={idx}
                    genArt={item}
                    selectedGenArts={selectedGenArts}
                    onHandleSelection={handleSelection}
                    onOeArticleChange={onOeArticleChange}
                    enableOeReplace={specialReplace}
                    showPartAlternatives={showPartAlternatives}
                />
            )
        }

        if (item.isMandatory && !item.isSelected) {
            return <CustomPartItem key={idx} genArt={item} />
        }

        return (
            <ListItemComponent key={idx} disablePadding>
                <ListItemButton role={undefined} onClick={() => handleSelection(item)} dense disabled={item.isMandatory}>
                    <ListItemIcon>
                        <Checkbox
                            size="medium"
                            checked={selectedGenArts?.some((x) => x.label === item.label && x.genArtNr === item.genArtNr) || false}
                            disabled={item.isMandatory || inputsLocked}
                        />
                    </ListItemIcon>
                    <Typography variant="h4" id={item.label}>
                        {loading ? <Loader size="small" /> : item.label}
                    </Typography>
                </ListItemButton>
            </ListItemComponent>
        )
    }

    return (
        <CollapsibleWrapper icon={<Icon name="fast-calculator" color="primary" size="1.5em" />} textId={1667}>
            <Box display="flex" gap="0.5em" flexDirection="column" width="100%">
                {items.map(renderItems)}
            </Box>
        </CollapsibleWrapper>
    )
}
