import { Box, CellContentPosition, Icon, Table, TableCellData, TableColumnData, TableRowData, styled, Image } from "@tm/components"
import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import Accordion from "../../_shared/Accordion"
import { LabourValues } from "../../../data/models"
import { useDrivemotiveStore } from "../../../data/state"
import NoResultHint from "../../_shared/NoResult"

export default function FixedPrices() {
    const { userContext } = useUser()
    const { translateText, currency } = useLocalization()
    const currencyString = getCurrencyFromUserContext(userContext)

    const fixedPrice = useDrivemotiveStore((state) => state.summary.selectedBooking?.labour_values)
    const [isExpanded, setIsExpanded] = useState(!!fixedPrice?.length)

    if (!fixedPrice) {
        return <NoResultHint />
    }

    const handleClick = () => {
        setIsExpanded((prevState) => !prevState)
    }

    const handleGetTotalPrice = () => {
        return fixedPrice.reduce((total, item) => {
            return total + (item.price || 0)
        }, 0)
    }

    const tableColumns: TableColumnData[] = [{}, { alignContent: CellContentPosition.right }, { alignContent: CellContentPosition.right }]

    const getCells = (fixedPriceCell: LabourValues) => {
        const title = (
            <StyledBox>
                <StyledImage src={fixedPriceCell.image} />
                <StyledTitleBox sx={{ fontWeight: "bold" }}>{fixedPriceCell.title}</StyledTitleBox>
                <br />
            </StyledBox>
        )

        const cellData: TableCellData[] = [
            { displayValue: title, id: "title" },
            {
                displayValue: <></>,
                id: "Fixed titles",
            },
            {
                displayValue: fixedPriceCell.price && (
                    <Box sx={{ display: "flex" }}>
                        {translateText(53)} <Box sx={{ marginLeft: "1em" }}>{currency(fixedPriceCell.price, currencyString)}</Box>
                    </Box>
                ),
                id: "Fixed Prices",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] = fixedPrice.map((fixed: LabourValues) => ({
        cells: getCells(fixed),
        id: `${fixed.title}`,
        rowIndicatorWidth: 7,
    }))

    return (
        <Box sx={{ marginTop: "1em", display: "flex", flexDirection: "column", flex: "1", gap: "0.5em", backgroundColor: "#f5f5f5" }}>
            <Accordion icon={<Icon name="down" />} expandIcon={<Icon name="up" />} expanded={isExpanded} onClick={handleClick}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    {translateText(13902)}
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        {translateText(13899)}
                        {!!fixedPrice?.length && (
                            <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(handleGetTotalPrice(), currencyString)}</Box>
                        )}
                    </Box>
                </Box>
                <StyledTable
                    columns={tableColumns}
                    rows={displayData}
                    headerBackground="transparent"
                    fontSize={14}
                    headerStyle="default"
                    rowCap={0}
                    overflowY="auto"
                    rowStyle="uniform"
                />
            </Accordion>
        </Box>
    )
}

const StyledTable = styled(Table)({
    ".row>span": {
        alignContent: "flex-start",
        flexWrap: "wrap",
    },
})

const StyledImage = styled(Image)({
    width: "3em",
    display: "flex",
})

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
})

const StyledTitleBox = styled(Box)({
    marginLeft: "1em",
})
