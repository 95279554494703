import { AsyncAction } from "@tm/morpheus"
import { clone, equals, getValue } from "@tm/utils"
import { EFilterNames, TyreFilter } from "@tm/models"
import { Repositories } from "../../../data"
import { BundleActionTypes, BundleActions } from "../../../data/business"
import { getAvailableTyreSize, removeFields } from "../../../data/helpers"
import { TyresCritsRequest, TyresCritsResponse } from "../../../data/repositories"
import { Statics } from "../../../data/statics"
import { MainState } from "../../main"
import { MainActionsType } from "../../main/business"
import { ISummaryFilters, SelectedFilters, SummaryMultiFilters, SummaryState } from "./model"
import { DRTyre } from "../../../data/models"
import { createTyresCritsRequest } from "./helpers/createTyresCritsRequest"

export * from "./model"
export * from "./helpers"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "DRIVE_RIGHT_TYRES_LOADING" }
    | { type: "DRIVE_RIGHT_TYRES_LOADED"; payload: { frontTyres: DRTyre[]; rearTyres: DRTyre[]; usedTecDoc?: number } }
    | { type: "DRIVE_RIGHT_TYRES_ERROR" }
    | { type: "SUMMARY_FILTERS_LOADING" }
    | { type: "SUMMARY_FILTERS_LOADED"; payload: { response: TyresCritsResponse; request: TyresCritsRequest } }
    | { type: "SUMMARY_FILTERS_ERROR" }
    | { type: "UPDATE_SUMMARY_FILTERS"; payload: { path: ISummaryFilters; value: TyreFilter } }
    | { type: "RESET_SUMMARY_FILTER"; payload: { path: ISummaryFilters; selectedFilters: SelectedFilters; hasTyresV2: boolean } }
    | { type: "SET_TIRE_SIZES" }
    | { type: "TOGGLE_TYRES_ACCORDION"; payload?: boolean | undefined }
    | { type: "SET_SUMMARY_WITH_VEHICLE"; payload: boolean }

export const DEFAULT_STATE: SummaryState = {
    driveRightTyres: {
        frontTyres: [],
        rearTyres: [],
    },
    filters: {
        initialized: false,
        width: [],
        height: [],
        inch: [],
        speedIndex: [],
        loadIndex: [],
        oeIdentifier: [],
        carType: [],
        season: Statics?.seasons,
        carTypeAndSeason: undefined, // keep undefined once initialized!
        tyreCount: Statics.tyreCountFilters,
    },
    selectedFilters: {
        carType: [],
        season: [],
        untouched: true,
        tyreCount: Statics.getDefaultTyreCountFilter(),
    },
    toggleTyresAccordion: false,
    summaryWithVehicle: false,
}

export function reduce(state = { ...DEFAULT_STATE }, action: MainActionsType): SummaryState {
    switch (action.type) {
        case "SUMMARY_FILTERS_LOADING": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    loading: true,
                },
            }
        }
        case "SUMMARY_FILTERS_LOADED": {
            const { request, response } = action.payload
            const selectedFilters = clone(state.selectedFilters)
            if (selectedFilters.width && !response.width.find((x) => x.value === selectedFilters.width!.value)) {
                delete selectedFilters.width
            }
            if (selectedFilters.height && !response.height.find((x) => x.value === selectedFilters.height!.value)) {
                delete selectedFilters.height
            }
            if (selectedFilters.inch && !response.inch.find((x) => x.value === selectedFilters.inch!.value)) {
                delete selectedFilters.inch
            }
            if (selectedFilters.speedIndex && !response.speedIndex.find((x) => x.value === selectedFilters.speedIndex!.value)) {
                delete selectedFilters.speedIndex
            }
            if (selectedFilters.loadIndex && !response.loadIndex.find((x) => x.value === selectedFilters.loadIndex!.value)) {
                delete selectedFilters.loadIndex
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...response,
                    loading: false,
                    initialized: true,
                },
                lastFiltersSearch: JSON.stringify(request),
                selectedFilters,
            }
        }

        case "SUMMARY_FILTERS_ERROR": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    loading: false,
                    initialized: true,
                },
            }
        }

        case "DRIVE_RIGHT_TYRES_LOADING": {
            return {
                ...state,
                driveRightTyres: {
                    ...state.driveRightTyres,
                    loading: true,
                },
            }
        }
        case "DRIVE_RIGHT_TYRES_LOADED": {
            const { frontTyres, rearTyres, usedTecDoc } = action.payload
            return {
                ...state,
                driveRightTyres: {
                    ...state.driveRightTyres,
                    loading: false,
                    frontTyres,
                    rearTyres,
                    lastTecDocSearched: usedTecDoc,
                },
            }
        }
        case "DRIVE_RIGHT_TYRES_ERROR": {
            return {
                ...state,
                driveRightTyres: {
                    ...state.driveRightTyres,
                    loading: false,
                    error: true,
                },
            }
        }
        case "UPDATE_SUMMARY_FILTERS": {
            const { value, path } = action.payload

            const oldStoredValues: TyreFilter | TyreFilter[] | undefined = getValue(state.selectedFilters, [path])
            let newValues = equals(value, oldStoredValues) ? oldStoredValues : value

            // for multi selection
            // if (SummaryMultiFilters.includes(path)) {
            //     newValues = addOrRemoveItem(oldStoredValues as TyreFilter[], value)
            // }

            // for single selection
            if (path === EFilterNames.season || path === EFilterNames.carType) {
                newValues = [value]
            }

            let carTypeSizes
            if (path === EFilterNames.carType) {
                newValues = state.selectedFilters.untouched ? [value] : newValues
                // take all car types and then select the first one and select the sizes based on that
                carTypeSizes = getAvailableTyreSize(newValues as TyreFilter[], state.filters)
            }

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: newValues,
                    untouched: state.selectedFilters.untouched && path === EFilterNames.carType ? false : state.selectedFilters.untouched,
                    ...(carTypeSizes && {
                        width: carTypeSizes.width,
                        inch: carTypeSizes.inch,
                        height: carTypeSizes.height ? carTypeSizes.height : undefined,
                    }),
                },
            }
        }
        case "RESET_SUMMARY_FILTER": {
            const { path, selectedFilters, hasTyresV2 } = action.payload
            return {
                ...state,
                selectedFilters: {
                    ...selectedFilters,
                    [path]: SummaryMultiFilters.includes(path) && !hasTyresV2 ? selectedFilters[path] : undefined,
                    tyreCount: Statics.getDefaultTyreCountFilter(),
                },
            }
        }
        case "TYRES_ARTICLES_LOADED": {
            const { usedCriteria } = action.payload
            const { width, height, inch } = usedCriteria

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    ...(width && { width }),
                    ...(height && { height }),
                    ...(inch && { inch }),
                    // ...(loadIndex?.length && { loadIndex }),
                    // ...(speedIndex?.length && { speedIndex }),
                },
            }
        }
        case "INIT": {
            const { matchParams, tyresV2 } = action.payload
            const allCarTypes = Statics.getCarTypes()
            const carType: TyreFilter[] = []
            matchParams.carType?.forEach((y) => {
                const newVal = allCarTypes.find((x) => x.query === y)
                if (newVal) {
                    carType.push(newVal)
                }
            })

            if (!carType.length && tyresV2) {
                carType.push(allCarTypes?.[0])
            }

            if (!carType.length && !tyresV2) {
                carType.push(...allCarTypes)
            }

            const selectedSeason = Statics.seasons?.filter((x) => matchParams.season?.some((season) => season === x.value))

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    ...(carType?.length && {
                        carType,
                    }),
                    ...(selectedSeason?.length && {
                        season: selectedSeason,
                    }),
                    untouched: !carType.length,
                },
            }
        }
        case "TYRES_ARTICLES_LOADING": {
            return {
                ...state,
                ignoreVehRecors: true,
            }
        }
        case "SAVE_VEHICLE_RECORDS": {
            if (state.ignoreVehRecors) {
                return state
            }
            return {
                ...state,

                selectedFilters: {
                    ...state.selectedFilters,
                    ...action.payload,
                },
            }
        }
        case "SET_TIRE_SIZES": {
            const { width, inch, height } =
                (state.selectedFilters.carType?.length > 0 && getAvailableTyreSize(state.selectedFilters.carType, state.filters)) || {}

            if (!width && !inch && !height) {
                return state
            }

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    width,
                    inch,
                    height,
                },
            }
        }
        case "TOGGLE_TYRES_ACCORDION": {
            return {
                ...state,
                toggleTyresAccordion: typeof action.payload === "boolean" ? action.payload : !state.toggleTyresAccordion,
            }
        }
        case "SET_SUMMARY_WITH_VEHICLE": {
            return {
                ...state,
                summaryWithVehicle: action.payload,
            }
        }
        default:
            return state
    }
}

function loadDriveRightTyres(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const {
            summary: {
                driveRightTyres: { lastTecDocSearched },
            },
            manager: { vehicle },
        } = getState()
        if (vehicle && vehicle.tecDocTypeId !== lastTecDocSearched) {
            dispatch({ type: "DRIVE_RIGHT_TYRES_LOADING" })
            Repositories.loadDriveRightTyres(vehicle.tecDocTypeId).then(
                (response) => dispatch({ type: "DRIVE_RIGHT_TYRES_LOADED", payload: { ...response, usedTecDoc: vehicle.tecDocTypeId } }),
                () => dispatch({ type: "DRIVE_RIGHT_TYRES_ERROR" })
            )
        }
    }
}

function loadSummaryFilters(): AsyncAction<MainActionsType, MainState> {
    return (dispatch, getState) => {
        const request = createTyresCritsRequest(getState())
        if (!request || JSON.stringify(request) === getState().summary.lastFiltersSearch) {
            return
        }

        dispatch({ type: "SUMMARY_FILTERS_LOADING" })

        Repositories.loadTyresCrits(request).then(
            (response) => dispatch({ type: "SUMMARY_FILTERS_LOADED", payload: { response, request } }),
            () => dispatch({ type: "SUMMARY_FILTERS_ERROR" })
        )
    }
}

const updateSummaryFilters = (path: ISummaryFilters, filter: TyreFilter): ComponentActionType => ({
    type: "UPDATE_SUMMARY_FILTERS",
    payload: { path, value: filter },
})

function resetSummaryFilter(path: ISummaryFilters, hasTyresV2: boolean): AsyncAction<MainActionsType, MainState> {
    return (dispatch, getState) => {
        let {
            summary: { selectedFilters },
        } = getState()

        switch (path) {
            case EFilterNames.carType:
                selectedFilters = removeFields(
                    selectedFilters,
                    EFilterNames.tyreCount,
                    EFilterNames.loadIndex,
                    EFilterNames.speedIndex,
                    EFilterNames.oeIdentifier
                )
                break
            case EFilterNames.season:
                selectedFilters = removeFields(selectedFilters, EFilterNames.loadIndex, EFilterNames.speedIndex, EFilterNames.oeIdentifier)
                break
            case EFilterNames.width:
                selectedFilters = removeFields(
                    selectedFilters,
                    EFilterNames.height,
                    EFilterNames.inch,
                    EFilterNames.loadIndex,
                    EFilterNames.speedIndex,
                    EFilterNames.oeIdentifier
                )
                break
            case EFilterNames.height:
                selectedFilters = removeFields(
                    selectedFilters,
                    EFilterNames.inch,
                    EFilterNames.loadIndex,
                    EFilterNames.speedIndex,
                    EFilterNames.oeIdentifier
                )
                break
            case EFilterNames.inch:
                selectedFilters = removeFields(selectedFilters, EFilterNames.loadIndex, EFilterNames.speedIndex, EFilterNames.oeIdentifier)
                break
            default:
                break
        }

        dispatch({ type: "RESET_SUMMARY_FILTER", payload: { path, selectedFilters, hasTyresV2 } })
    }
}

const toggleTyresAccordion = (state?: boolean | undefined): ComponentActionType => ({ type: "TOGGLE_TYRES_ACCORDION", payload: state })

const setSummaryWithVehicle = (state: boolean): ComponentActionType => ({ type: "SET_SUMMARY_WITH_VEHICLE", payload: state })

const setTyreSizes = (): ComponentActionType => ({ type: "SET_TIRE_SIZES" })

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    loadDriveRightTyres,
    loadSummaryFilters,
    updateSummaryFilters,
    resetSummaryFilter,
    setTyreSizes,
    toggleTyresAccordion,
    setSummaryWithVehicle,
}
