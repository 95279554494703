import { create } from "zustand"
import { ServiceNode, ServiceSubNode } from "../models"

type NodesState = {
    loadingNodes: boolean
    nodes?: ServiceNode[]
    selectedNode?: ServiceNode
    selectedSubNode?: ServiceSubNode
    setSelectedNode: (nodes?: ServiceNode) => void
    setSelectedSubNode: (node?: ServiceSubNode) => void
    setLoadingNodes: (loading: boolean) => void
    setNodes: (nodes?: ServiceNode[]) => void
    resetNodesStore: () => void
}

const initialState = {
    loadingNodes: false,
    nodes: undefined,
    selectedNode: undefined,
    selectedSubNode: undefined,
}

export const useNodesStore = create<NodesState>((set) => ({
    ...initialState,
    setSelectedNode: (node) => set({ selectedNode: node }),
    setSelectedSubNode: (node) => set({ selectedSubNode: node }),
    setLoadingNodes: (loading) => set({ loadingNodes: !!loading }),
    setNodes: (nodes) => set({ nodes, loadingNodes: false }),
    resetNodesStore: () => set(initialState),
}))
