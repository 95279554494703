import { Box, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback, useMemo, useRef } from "react"
import { getBundleParams } from "../../../utils"
import { ArticleGroup } from "../components/ArticleGroup"
import { ArticleSelection } from "../components/ArticleSelection"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { useListOptionsContext } from "../ContextProvider"
import { useProductGroupTopicIdsDataContext } from "../ContextProvider/ProductGroupTopicIds"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { useVehiclePartsArticlesContext } from "./ContextProvider"
import { useVehiclePartsArticleListActionsContext } from "./ContextProvider/ArticleListActions"
import { useVehiclePartsListParamsContext } from "./ContextProvider/ListParams"
import { useResizeErpColumnInDefaultArticleItem } from "../hooks/useResizeErpContainerColumn"

export function VehiclePartsList() {
    const { translateText } = useLocalization()

    const {
        noResult,
        isFiltersLoading,
        attributes,
        setAttributes,
        sorting: { productGroups: sortingProductGroups },
    } = useVehiclePartsListParamsContext()
    const options = useListOptionsContext()
    const { articleGroups, isLoading, isLoaded, isFetchingNextPage, hasNextPage, loadNextPage, isStalling, isFrontendFiltered } =
        useVehiclePartsArticlesContext()

    const mainContainerRef = useRef<HTMLElement>(null)
    useResizeErpColumnInDefaultArticleItem(mainContainerRef.current, !!articleGroups.length)

    const activateSortingForProductGroups = useVehiclePartsListParamsContext().sorting.setForProductGroups
    const productGroupTopicIds = useProductGroupTopicIdsDataContext()

    const { toggleQuickFilter } = useVehiclePartsArticleListActionsContext()

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const calculatorRoute = getBundleParams().fastCalculatorModalRoute
    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const { selectedArticles } = useArticleSelection()
    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    const showPageLoader = ((hasNextPage || isLoading || isFiltersLoading) && !isFrontendFiltered) || isStalling || isFetchingNextPage

    if (noResult) {
        return <NoSearchResult searchType="vehicle" />
    }

    return (
        <Box position="relative" top={distance} ref={mainContainerRef}>
            <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={sortingProductGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                        calculatorRoute={calculatorRoute}
                        showFilterOptions={showVehicleRecordsFilters}
                        usedAttributeFilters={usedAttributeFilters}
                        onToggleAttributeFilter={toggleQuickFilter}
                        onResetAttributeFilters={handleResetAttributeFilters}
                        productGroupTopicIds={productGroupTopicIds}
                    />
                ))}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
