import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    label: string
    value: number | string | undefined
    alignValueCenter?: boolean
}

export function ValueLabelStack({ label, value, alignValueCenter }: Props) {
    const { number } = useLocalization()

    let formatedValue = value
    if (typeof value == "number") {
        formatedValue = number(value, 2)
    }
    return (
        <Stack>
            <Typography whiteSpace="nowrap" variant="label" textTransform="uppercase">
                {label}
            </Typography>
            <Typography variant="body2" alignSelf={alignValueCenter ? "center" : "flex-start"}>
                {formatedValue}
            </Typography>
        </Stack>
    )
}
