import { Button, Collapsible, CollapsibleProps } from "@tm/controls"
import { classes } from "@tm/utils"
import { forwardRef, PropsWithChildren, useImperativeHandle, useRef } from "react"

export type FilterComponentProps = CollapsibleProps & {
    clipped: boolean
    opened: boolean
    onToggleClip(): void
    onToggleOpen(): void
    onChangeFilter(): void
}

type Handlers = {
    collapse(): void
}

const FilterComponent = forwardRef<Handlers, PropsWithChildren<FilterComponentProps>>((props, ref) => {
    const { clipped, opened, className, disabled, children } = props

    const collapsibleRef = useRef<Collapsible | null | undefined>(null)

    useImperativeHandle(ref, () => ({
        collapse: () => {
            collapsibleRef.current?.collapse()
        },
    }))

    function handleReset(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation()
        props.onChangeFilter()
    }

    function handleToggleClip(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation()
        props.onToggleClip()
    }

    function handleToggleOpen() {
        if (disabled) {
            return
        }
        props.onToggleOpen()
    }

    function handleRef(ref: Collapsible | null) {
        collapsibleRef.current = ref
    }

    function renderDropdownFilterHeaderAppendix() {
        return (
            <>
                <Button layout={["ghost"]} disabled={disabled} icon="close" onClick={handleReset} />
                <Button layout={["ghost"]} className="btn--clip" icon="paperclip" onClick={handleToggleClip} />
            </>
        )
    }

    const collapsibleProps = {
        ...props,
        className: classes("filter", clipped && "filter--clipped", className),
        asDropDown: !clipped,
        renderHeaderAppendix: renderDropdownFilterHeaderAppendix,
        onChangeOpen: handleToggleOpen,
        initiallyOpened: opened,
        ref: handleRef,
    }

    return <Collapsible {...collapsibleProps}>{children}</Collapsible>
})

export default FilterComponent
