import { Divider, Loader, Stack, Typography } from "@tm/components"
import { useTelesalesCustomerNumber, useWorkTask, WorkTaskInfo } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ChangeCostEstimationDetailsRequest, CisVoucherType, ECounterType, RegisteredModels, ShowCostEstimationDetailsResponse } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { renderRoute, TmaHelper } from "@tm/utils"
import { Suspense, useEffect, useRef } from "react"
import { useLocation, useParams } from "react-router"
import { PostCreateActionType } from "../../business"
import { useCreateCostEstimationVoucher } from "../../data/hooks/useCreateCostEstimationVoucher"
import { useTransferCostEstimation } from "../../data/hooks/useTransferCostEstimation"
import { ESendCostEstimationMode } from "../../data/model"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import ErrorAlert from "../_shared/ErrorAlert"
import { useOrderResult } from "../_shared/order-button/hooks/useOrderResult"
import OrderCompleted from "../_shared/parts-list/components/OrderCompleted"
import TransferCompleted from "../_shared/transfer-completed"
import { OfferState } from "./business"
import { Actions, IActions } from "./business/actions"
import CustomerOverview from "./components/CustomerOverview"
import Details from "./components/details"
import { CostEstimationNavigation } from "./components/navigation"
import Note from "./components/note"
import { CostEstimationVehicleInfo } from "./components/vehicle-info"

type Props = {
    state: OfferState
    actions: IActions
    cisVoucherUrl?: string
    partsDirectListRoute: string
    partsVehicleListRoute: string
    partRepairTimesRoute: string
    showAllPrices?: boolean
    voucherTypeId?: CisVoucherType
    partsDetailBasketRoute?: string
    costEstimationRoute: string
    orderRoute: string
    orderButtonTextId: string
    orderButtonIcon: string
    orderRoutePreActionExternalSystemId?: number
    exportTarget?: string
    showExportVoucherButton?: boolean
    showDmsExportButton?: boolean
    customerListRoute: string
    customerDetailsRoute: string
}

function CostEstimation(props: Props & { workTask: WorkTaskInfo }) {
    const {
        actions,
        state,
        partRepairTimesRoute,
        partsDirectListRoute,
        partsVehicleListRoute,
        showAllPrices,
        cisVoucherUrl,
        workTask,
        voucherTypeId,
        partsDetailBasketRoute,
        costEstimationRoute,
        orderRoute,
        orderButtonTextId,
        orderButtonIcon,
        orderRoutePreActionExternalSystemId,
        exportTarget,
        showDmsExportButton,
        showExportVoucherButton,
        customerDetailsRoute,
        customerListRoute,
    } = props

    const { offerDetails, note } = state
    const { translateText } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const location = useLocation()
    const matchParams = useParams<{ workTaskId?: string; view?: string }>()
    const costEstimationUrl = renderRoute(costEstimationRoute, matchParams)
    const costEstimationIsActive = location.pathname === costEstimationUrl
    const detailsIsActive = matchParams.view === partsDetailBasketRoute
    const { response, resetState } = useTransferCostEstimation(workTask.id, telesalesCustomerNo, ESendCostEstimationMode.CostEstimation)
    const savingDetails = useRef<boolean>(false)
    const { createCostEstimationVoucher, creatingCostEstimationVoucher } = useCreateCostEstimationVoucher(workTask, state)

    const { workTaskBasketWithError, costEstimation } = useWorkTaskBasketState(workTask.id)
    const { results, technicalErrors, clearOrderSent } = useOrderResult(workTask.id)

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []

        // the worktask should exist in the DB
        if (workTask.no) {
            const nexusSub = Container.getInstance<ShowCostEstimationDetailsResponse>(RegisteredModels.Worktask_CostEstimationDetails).subscribe({
                workTaskId: workTask.id,
            })

            unsubscriptions.push(
                nexusSub.addListener("loaded", (response) => {
                    actions.setCostEstimationDetails(response)
                    savingDetails.current = false
                }),
                nexusSub.addListener("updated", () => {
                    nexusSub.load()
                    savingDetails.current = false
                })
            )

            nexusSub.load()
        }

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTask])

    function saveCostEstimationDetails(workTaskId: string, request: ChangeCostEstimationDetailsRequest) {
        const costEstimationDetialsSubscription = Container.getInstance<ShowCostEstimationDetailsResponse>(
            RegisteredModels.Worktask_CostEstimationDetails
        ).subscribe({ workTaskId })
        if (costEstimationDetialsSubscription.save && !savingDetails.current) {
            savingDetails.current = true
            costEstimationDetialsSubscription.save(request)
        }
    }

    function updateClientAdvisor(clientAdvisor: string) {
        if (!workTask || !offerDetails.version || clientAdvisor === offerDetails.clientAdvisor) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function handleModifyCostEstimationNote(memo: string) {
        actions.setCostEstimationNote(memo)
    }

    function handleUpdateCostEstimationNote(memo: string) {
        if (!workTask || !offerDetails.version) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateDueDate(dueDate: Date) {
        if (!workTask || !offerDetails.version || dueDate === offerDetails.dueDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateEndDate(endDate: Date) {
        if (!workTask || !offerDetails.version || endDate === offerDetails.endDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateMechanic(mechanic: string) {
        if (!workTask || !offerDetails.version || mechanic === offerDetails.mechanic) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateReferenceNumber(referenceNumber: string) {
        if (!workTask || !offerDetails.version || referenceNumber === offerDetails.referenceNumber) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateStartDate(startDate: Date) {
        if (!workTask || !offerDetails.version || startDate === offerDetails.startDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function handlePrintCostEstimation() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimatePrint)
        createCostEstimationVoucher(PostCreateActionType.ShowPdf)
    }
    return (
        <Stack alignSelf="stretch" flex={1} gap={1} mt={0.5}>
            <CostEstimationNavigation
                state={state}
                workTaskId={workTask.id}
                costEstimationIsActive={costEstimationIsActive}
                costEstimationUrl={costEstimationUrl}
                creatingCostEstimation={creatingCostEstimationVoucher}
                customerEmail={workTask.customer?.email}
                detailsIsActive={detailsIsActive}
                orderRoute={orderRoute}
                orderButtonTextId={orderButtonTextId}
                orderButtonIcon={orderButtonIcon}
                orderRoutePreActionExternalSystemId={orderRoutePreActionExternalSystemId}
                exportTarget={exportTarget}
                showDmsExportButton={showDmsExportButton}
                showExportVoucherButton={showExportVoucherButton}
                onGenerateCostEstimationLink={() => createCostEstimationVoucher(PostCreateActionType.PublishLink)}
                onPrintCostEstimation={handlePrintCostEstimation}
                onSendCostEstimation={() => createCostEstimationVoucher(PostCreateActionType.SendChatMessage)}
            />
            <Stack px={2} gap={1} divider={<Divider light />} overflow="auto">
                <CustomerOverview customerDetailsRoute={customerDetailsRoute} customerListRoute={customerListRoute} />
                {response && (
                    <TransferCompleted
                        workTaskId={workTask.id}
                        telesalesCustomerNo={telesalesCustomerNo}
                        mode={ESendCostEstimationMode.CostEstimation}
                        onClearCostEstimationSent={() => resetState()}
                    />
                )}
                {workTaskBasketWithError || !!costEstimation.state.externalBasketHasTechnicalErrors ? (
                    <ErrorAlert />
                ) : (
                    <OrderCompleted
                        // showOrderConfirmationPdf={showOrderConfirmationPdf} // Only set for PV
                        cisVoucherUrl={cisVoucherUrl}
                        // externalSystemId={externalSystemId} // Only set for Neimcke
                        workTaskId={workTask.id}
                        results={results}
                        technicalErrors={technicalErrors}
                        onClearOrderSent={clearOrderSent}
                    />
                )}
                <CostEstimationVehicleInfo
                    cisVoucherUrl={cisVoucherUrl}
                    creatingCostEstimationVoucher={creatingCostEstimationVoucher}
                    partRepairTimesRoute={partRepairTimesRoute}
                    partsDirectListRoute={partsDirectListRoute}
                    partsVehicleListRoute={partsVehicleListRoute}
                    showAllPrices={showAllPrices}
                    voucherTypeId={voucherTypeId}
                    workTask={workTask}
                />
                <Details
                    details={offerDetails}
                    hasWorktask={!!workTask.no}
                    disabled={workTask.no ? savingDetails.current : true}
                    onUpdateClientAdvisor={updateClientAdvisor}
                    onUpdateDueDate={updateDueDate}
                    onUpdateEndDate={updateEndDate}
                    onUpdateMechanic={updateMechanic}
                    onUpdateReferenceNumber={updateReferenceNumber}
                    onUpdateStartDate={updateStartDate}
                />
                {workTask.no && (
                    <Stack>
                        <Typography variant="h3">{translateText(1700)}</Typography>
                        <Note
                            disabled={workTask.no ? savingDetails.current : true}
                            note={note}
                            onUpdateNote={handleUpdateCostEstimationNote}
                            onModifyNote={handleModifyCostEstimationNote}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={<Loader />}>
            <CostEstimation {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
