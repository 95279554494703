import { Article, DefaultArticleItemState, ListOptions } from "@tm/models"
import { useMemo } from "react"
import { ArticleListFeaturesDisable, useArticleListFeaturesDisable } from "../../ArticleListConfiguration/ArticleListFeaturesDisable"
import { useListOptionsContext } from "../../ContextProvider"
import { usePartsViewOptions } from "../usePartsViewOptions"
import { useDisableReason } from "./useDisableReason"

export function useArticleOptions(article: Article): DefaultArticleItemState["options"] {
    const options = useListOptionsContext()
    const disables = useArticleListFeaturesDisable()

    const {
        partsViewSettings: { compactView, quantitySuggestionEnabled },
    } = usePartsViewOptions()
    const hasSuggestedQuantity = quantitySuggestionEnabled && !!article.suggestedQuantity

    const disableReason = useDisableReason(article)

    return useMemo(
        () => mergeOptions(options, disables, { compactView, hasSuggestedQuantity, disableReason }),
        [options, disables, compactView, hasSuggestedQuantity, disableReason]
    )
}

export function useOeArticleOptions(): DefaultArticleItemState["options"] {
    const options = useListOptionsContext()
    const disables = useArticleListFeaturesDisable()

    const {
        partsViewSettings: { compactView },
    } = usePartsViewOptions()

    return useMemo(() => mergeOptions(options, disables, { compactView }), [options, disables, compactView])
}

// TODO add here more disable cases
function mergeOptions(
    options: ListOptions,
    disables: ArticleListFeaturesDisable,
    other: Partial<DefaultArticleItemState["options"]>
): DefaultArticleItemState["options"] {
    return {
        ...options,
        ...other,
        hideCostEstimationButton: options.hideCostEstimationButton || disables.disableCostEstimationButton,
        hideAddToBasketButton: options.hideAddToBasketButton || disables.disableBasketButton,
        hideReplaceButtonWithQuantity: disables.disableReplaceWithQuantity,
        displayOnlyQuantity: disables.displayOnlyQuantity,
        displayPriceDependingOnQuantity: disables.displayPriceDependingOnQuantity,
    }
}
