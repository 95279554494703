import tinycolor from "tinycolor2"
import { ExportVoucherErrorResponse } from "../business/import/vouchers"

export function isCloserToWhiteOrBlack(color: string): "white" | "black" {
    const lightness = tinycolor(color).toHsl().l
    return lightness < 0.5 ? "white" : "black"
}

type DmsError = {
    exceptionMessage?: string
    traceId?: string
    userErrorMessage?: string
}

export function isDmsError(obj: unknown): obj is DmsError {
    const allowedKeys: (keyof DmsError)[] = ["exceptionMessage", "traceId", "userErrorMessage"]

    if (typeof obj !== "object" || obj === undefined || obj === null) {
        return false
    }

    // Makes sure that at least one of the keys of the type DmsError in present in `obj`
    if (!allowedKeys.some((key) => key in obj && typeof (obj as Record<string, unknown>)[key] === "string")) {
        return false
    }

    const objectKeys = Object.keys(obj)
    for (let i = 0; i < objectKeys.length; i++) {
        if (!allowedKeys.includes(objectKeys[i] as keyof DmsError)) {
            return false
        }
    }

    return true
}

export function isExportVoucherErrorResponse(obj: unknown): obj is ExportVoucherErrorResponse {
    return (
        obj !== null &&
        typeof obj === "object" &&
        "message" in obj &&
        typeof obj.message === "string" &&
        "traceId" in obj &&
        typeof obj.traceId === "string"
    )
}
