import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import { withRouter } from "react-router-dom"
import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, styled, Typography, useTheme } from "@tm/components"
import { getParameterFromUserContextModule, useDisplayListV2, useNewTyreSearch, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"

import { PanelSection, Scrollbar } from "@tm/controls"
import { ArticleListV2DisplayBehavior, UserModuleType } from "@tm/models"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { getBundleParams } from "../../utils"
import { TyresFiltersV2 } from "./components/TyresFiltersV2"
import { ListComponent, TyresListV2 } from "./components"
import AttributeFilters from "./components/attributeFilters"
import { FiltersComponent } from "../summary/components/filters"

type Props = RouteComponentProps<RouteProps> & {
    repairTimesRoute: string
    detailsModalRoute?: string
}

type RouteProps = {
    size?: string
    season?: string
    carType?: string
}

function TyresListComponent({ repairTimesRoute, detailsModalRoute }: Props) {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { isFiltersOnRightSide } = getBundleParams()
    const user = useUser()

    const toggleTyresAccordionState = useSelector((state: MainState) => state.summary.toggleTyresAccordion)
    const displayListV2 = useDisplayListV2()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const hasNewTyreSearch = useNewTyreSearch()

    const tyresDisplayBehaviorInMdm = getParameterFromUserContextModule(user.userContext, UserModuleType.TMTires, "DisplayBehavior")
    const tyresDisplayBehaviorInUserSettings = user?.userSettings?.articleListSettings?.viewOptions?.displayBehavior

    useEffect(() => {
        if (user.userSettings) {
            if (tyresDisplayBehaviorInMdm && tyresDisplayBehaviorInMdm !== ArticleListV2DisplayBehavior.Both) {
                user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                    ...user.userSettings.articleListSettings,
                    viewOptions: {
                        ...user.userSettings.articleListSettings?.viewOptions,
                        displayBehavior: tyresDisplayBehaviorInMdm,
                    },
                })
            }
            if (!tyresDisplayBehaviorInUserSettings) {
                user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                    ...user.userSettings.articleListSettings,
                    viewOptions: {
                        ...user.userSettings.articleListSettings?.viewOptions,
                        displayBehavior: ArticleListV2DisplayBehavior.List,
                    },
                })
            }
        }
    }, [user.userSettings?.articleListSettings?.viewOptions?.displayBehavior])

    const renderList = () => {
        if (displayListV2 && !isWm) {
            return <TyresListV2 />
        }

        return <StyledListComponent key="filters" detailsModalRoute={detailsModalRoute} repairTimesRoute={repairTimesRoute} />
    }

    const renderFilters = () => {
        if (displayListV2 && !isWm) {
            return (
                <Box display="flex">
                    <TyresFiltersV2 />
                </Box>
            )
        }

        return (
            <StyledPanelSection key="list">
                <Scrollbar>
                    {hasNewTyreSearch && (
                        <Accordion disableGutters expanded={toggleTyresAccordionState}>
                            <StyledAccordionSummary onClick={() => dispatch(MainActions.toggleTyresAccordion())}>
                                <Icon name="tyre-search" sx={{ mr: "5px", opacity: 1 }} />
                                <Typography sx={{ fontSize: "inherit", fontWeight: 600, opacity: 1 }}>{translateText(3162)}</Typography>
                            </StyledAccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                <FiltersComponent />
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <Box marginLeft={theme.margin?.m} paddingTop={theme.margin?.m} className="subtitle bordered">
                        {translateText(3163)}
                    </Box>
                    <AttributeFilters />
                </Scrollbar>
            </StyledPanelSection>
        )
    }
    return (
        <Box width="100%" display="flex">
            {isFiltersOnRightSide ? [renderList(), renderFilters()] : [renderFilters(), renderList()]}
        </Box>
    )
}

export default withRouter(TyresListComponent)

const StyledListComponent = styled(ListComponent)({
    flex: "1",
})

const StyledAccordionSummary = styled(AccordionSummary)({
    flexDirection: "row",
    mr: "5px",
    ".MuiAccordionSummary-expandIconWrapper .icon": {
        opacity: 1,
    },
    ".MuiAccordionSummary-content": {
        alignItems: "center",
    },
})

const StyledPanelSection = styled(PanelSection)(({ theme }) => ({
    flexBasis: "22em",
    padding: theme.margin?.m,
    margin: `0 ${theme.margin?.s} ${theme.margin?.m} ${theme.margin?.s}`,
    display: "flex",
    flexDirection: "column",
    "& .panel__content": {
        padding: "0",
        flex: "1",
    },
}))
