import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { concat } from "@tm/utils"
import { getStyleTheme } from "@tm/context-distribution"
import { FC, HTMLAttributes, ReactNode } from "react"

function getComponentStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()

    return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: `0 ${margin.l}`,
        $nest: {
            "&__line": {
                flex: 1,
                width: "1px",
                backgroundColor: "#acacac",
                $nest: {
                    "&:not(:first-child)": {
                        marginTop: margin.l,
                    },
                    "&:not(:last-child)": {
                        marginBottom: margin.l,
                    },
                },
            },
        },
    }
}

type Props = HTMLAttributes<HTMLDivElement> & {
    top?: ReactNode
    bottom?: ReactNode
}

export const VerticalRule: FC<Props> = (props) => {
    const { top, bottom, ...rest } = props

    const className = style(getComponentStyles())

    return (
        <div {...rest} className={concat(" ", props.className, className)}>
            {top}
            <div className={`${className}__line`} />
            {bottom}
        </div>
    )
}
