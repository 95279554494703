import { useLocalization } from "@tm/localization"
import { Widget } from "@tm/controls"
import { Box, Image, Loader, Stack, Typography, useTheme, Hotline } from "@tm/components"
import { useCisCustomer, useHotline, useUser } from "@tm/context-distribution"
import { RowStack, TextBody1Bold, TypographyTitle, VerticalDivider } from "../_shared/StyledComponents"
import EmailComponent from "../_shared/customer/Email"
import PhoneComponent from "../_shared/customer/Phone"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"

type Props = {
    description: string
    imageUrl: string
    phone: string
    eMail: string
    widgetSize?: "2x2" | "4x2"
    showContact?: boolean
    titleTextId?: string
}

export default function WidgetContacts({ imageUrl, eMail, titleTextId, widgetSize, description, phone, showContact }: Props) {
    const { translate } = useLocalization()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()
    const { userContext } = useUser()
    const theme = useTheme()
    const hotlineInfo = useHotline(userContext.parameter.hotline ?? "")

    let size = widgetSize || "2x2"
    if (showContact) {
        size = "4x2"
    }
    const contact = cisCustomer?.contacts?.first()

    function renderSupport() {
        if (hotlineInfo) {
            return <Hotline headline={hotlineInfo.headline} phone={hotlineInfo.phone} email={hotlineInfo.email} valueColor={theme.colors?.dark} />
        }

        return (
            <>
                <TextBody1Bold>{description}</TextBody1Bold>
                {phone && <PhoneComponent phone={phone} />}
                {eMail && <EmailComponent email={eMail} />}
            </>
        )
    }
    return (
        <Widget
            size={size}
            active
            title={translate(titleTextId || 1120)}
            iconName="catalog"
            header={<WidgetHeaderTitleComponent iconName="catalog" titleTextId={titleTextId || 1120} />}
        >
            <RowStack divider={<VerticalDivider />} flex={1}>
                {showContact && cisCustomerLoading && <Loader />}
                {showContact && contact && (
                    <Stack flex={1}>
                        <TypographyTitle>{translate(13813)}</TypographyTitle>
                        <Typography variant="body2">{`${contact.firstName ?? ""} ${contact.lastName ?? ""}`}</Typography>
                        {contact.electronicAddresses?.length && <EmailComponent email={contact.electronicAddresses[0].value} />}
                    </Stack>
                )}
                <Stack flex={1}>
                    <TypographyTitle>{translate(823)}</TypographyTitle>
                    {!!imageUrl && (
                        <Box>
                            <Image height={60} src={imageUrl} />
                        </Box>
                    )}
                    {renderSupport()}
                </Stack>
            </RowStack>
        </Widget>
    )
}
