import { useMemo } from "react"
import {
    Article,
    ArticleReference,
    AdditionalInformationLink,
    ArticleReferenceParms,
    DisplayArticle,
    ModificationState,
    ArticleReferenceType,
    ListFilter,
    ArticleAttributeParams,
    ETecdocInfoType,
} from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { toPairs } from "lodash"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../../../utils"
import { useVehicleRecordsData } from "../useVehicleRecords"
import { getIconFromMediaType, mapArticleReferences, mapAttributes } from "./mapper"

export function useDisplayArticle(
    article: Article,
    selectedAttributes: ListFilter[],
    supplierLogo?: string,
    isSupplierSelected?: boolean
): DisplayArticle {
    const { translateText } = useLocalization()
    const partsUiConfig = getBundleParams()
    const { attributes: vehicleRecordsAttributes } = useVehicleRecordsData()
    const hideDealerPartNumber = useUser()?.userContext.parameter.hideDealerPartNumber

    // Hersteller infos
    const supplier = useMemo<DisplayArticle["supplier"]>(
        () => ({
            supplier: article.supplier,
            supplierLogo,
            selected: isSupplierSelected,
        }),
        [article.supplier, supplierLogo, isSupplierSelected]
    )

    return useMemo(() => {
        // Artikelbilder
        const image: DisplayArticle["image"] = {
            thumbnailUrl: article.thumbnail,
            thumbnailDescription: undefined,
            thumbnailFallbackUrl: undefined,
            customThumbnailComponent: undefined,
            startIndex: undefined,
        }

        // Bekommt man sicher auch noch hübscher hin, weiß grade nicht wie :D
        let copyButtonTrader = false
        let copyButtonSupplier = false
        if (typeof partsUiConfig.showCopyNumberButton === "object" || Array.isArray(partsUiConfig.showCopyNumberButton)) {
            copyButtonTrader = partsUiConfig.showCopyNumberButton.find((val) => val === "article-list--trader")
            copyButtonSupplier = partsUiConfig.showCopyNumberButton.find((val) => val === "article-list--supplier")
        }

        // Artikelnummer
        const numbers: DisplayArticle["numbers"] = {
            hideDealerPartNumber,
            traderArticleNo: article.traderArticleNo,
            supplierArticleNo: article.supplierArticleNo,
            copyButtonTrader,
            copyButtonSupplier,
        }

        // Bonussystem
        let bonus: DisplayArticle["bonus"]
        if (article.availableBonusSystems?.length) {
            bonus = { ...article.availableBonusSystems[0] }
        }

        // Artikelbeschreibung
        const description: DisplayArticle["description"] = {
            position: "bottom",
            traderDescriptopnPosition: "bottom",
            additionalDescriptions: article.additionalReferenceArticleInformation?.headInformation,
            missingVehicleConnectionTitle: undefined,
            description: article.description,
        }

        // Artikelattribute TOP
        const attributes: DisplayArticle["attributes"] = article.attributes.map((attributes) => {
            // topArticleAttributes have to include vehicleAttributes and partsListAttributes to make sure they are shown in the compact article listv2
            const topArticleAttributes = mapAttributes(
                translateText,
                [...attributes.topAttributes, ...attributes.vehicleAttributes, ...attributes.partsListAttributes],
                article.productGroup.id,
                vehicleRecordsAttributes,
                selectedAttributes
            )
            return {
                topArticleAttributes,
                additionalAttributes: mapAttributes(
                    translateText,
                    [...attributes.articleAttributes],
                    article.productGroup.id,
                    vehicleRecordsAttributes,
                    selectedAttributes,
                    topArticleAttributes
                ),
            }
        })

        // split References to IMS Informations and default References, should be changed in Service if it is possible
        const imsReferences: ArticleReferenceParms[] = []
        const rawArticleReferences: ArticleReference[] = []

        article.references.forEach((ref) => {
            if (ref?.modificationState === ModificationState.Removed || ref?.referenceType === ArticleReferenceType.OeReferenceNo) {
                // imsReferences will be shown in the additional information
                imsReferences.push(mapArticleReferences(ref))
            } else {
                // will be shown in it's own block above the addtional information
                rawArticleReferences.push(ref)
            }
        })

        // Alle weiteren Referzenzen
        const references: DisplayArticle["references"] = rawArticleReferences.map(mapArticleReferences)
        const mergedGeneralInformations = [...(article.information ?? []), ...(article.vehicleInformation ?? [])]

        const additionalInformation: DisplayArticle["additionalInformation"] = {
            references: imsReferences,
            generalInformation: toPairs(mergedGeneralInformations.groupBy((x) => x.description)).map(([title, infos]) => {
                return [title, infos.map((x) => x.content)]
            }),
            textInformation: article.additionalReferenceArticleInformation?.textInformation.map((group) => [
                group.name,
                group.member.map((x) => x.description),
            ]),
            headInformation: article.additionalReferenceArticleInformation?.headInformation,
            links: article.additionalReferenceArticleInformation?.links.reduce<AdditionalInformationLink[]>((p, c) => {
                return [
                    ...p,
                    ...c.member.map<AdditionalInformationLink>((member) => ({
                        href: member.content,
                        name: member.description,
                        icon: getIconFromMediaType(member.mediaType),
                    })),
                ]
            }, []),
        }

        let sharedAttributes: ArticleAttributeParams[] | undefined
        if (article.sharedAttributes) {
            sharedAttributes = mapAttributes(
                translateText,
                [...article.sharedAttributes],
                article.productGroup.id,
                vehicleRecordsAttributes,
                selectedAttributes
            )
        }

        return {
            numbers,
            image,
            supplier,
            bonus,
            description,
            attributes,
            references,
            additionalInformation,
            sharedAttributes,
            modificationState: article.articleModificationState,
        }
    }, [article, partsUiConfig.showCopyNumberButton, supplier, vehicleRecordsAttributes, hideDealerPartNumber])
}
