import { useCallback, useState } from "react"
import { Icon, Stack, Button, Tooltip, LinkButton } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ECounterType, PartsBundleParams } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import Morpheus from "@tm/morpheus"

type Props = {
    vehicleId?: string
    partsDirectListUrl: string
    partsVehicleListUrl: string
    onCreateCustomPartButtonClick(): void
}

export default function AddPart(props: Props) {
    const { partsVehicleListUrl, partsDirectListUrl, vehicleId, onCreateCustomPartButtonClick } = props
    const { translateText } = useLocalization()
    const { disableArticleListAccess } = Morpheus.getParams<PartsBundleParams>("parts")

    const [openTooltip, setOpenTooltip] = useState<boolean>(false)

    const handleOpenTooltip = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateAddArticle)
        setOpenTooltip(!openTooltip)
    }, [openTooltip])

    const handleCreateButtonClick = useCallback(() => {
        onCreateCustomPartButtonClick()
        setOpenTooltip(false)
    }, [])

    return (
        <Tooltip
            title={
                <Stack spacing={0.5}>
                    <Button startIcon={<Icon name="individual-article" />} variant="outlined" onClick={handleCreateButtonClick}>
                        {translateText(757)}
                    </Button>
                    {!disableArticleListAccess && (
                        <LinkButton startIcon={<Icon name="catalog" />} variant="outlined" to={vehicleId ? partsVehicleListUrl : partsDirectListUrl}>
                            {translateText(136)}
                        </LinkButton>
                    )}
                </Stack>
            }
            variant="light"
            disableHoverListener
            open={openTooltip}
            onClickAway={() => setOpenTooltip(false)}
        >
            <Button
                // remove title from button if used inside from Tooltip to prevent double title use MUI errors
                // correct way is to use a Popper and a Tooltiop outside around the Button
                aria-label={translateText(1507)}
                onClick={handleOpenTooltip}
                startIcon={<Icon name="plus" />}
                variant="contained"
                color="highlight"
            />
        </Tooltip>
    )
}
