import { AsyncAction } from "@tm/morpheus"
import { clone } from "@tm/utils"
import { BundleActions, BundleActionTypes } from "../../../business"
import { StatusData, StatusIdType } from "../../../data/model"
import { useContainer } from "../../../data/repositories"
import { MainState } from "../../main"
import { MainActionsType } from "../../main/business"

export type ModuleManagerState = {
    statusData?: StatusData
    statusDataLoaded?: boolean
    statusDataLoading?: boolean
    errorStatus?: boolean
    vinUsed?: string
}

export type ComponentActionType = BundleActionTypes

const DEFAULT_STATE: ModuleManagerState = {}

export const reduce = (state = clone(DEFAULT_STATE), action: MainActionsType): ModuleManagerState => {
    switch (action.type) {
        case "GET_STATUS_DATA_LOADED": {
            const { statusData, vin } = action.payload
            return {
                ...state,
                statusData,
                statusDataLoaded: true,
                statusDataLoading: false,
                errorStatus: false,
                vinUsed: vin,
            }
        }
        case "GET_STATUS_LOADING": {
            return {
                ...state,
                statusDataLoading: true,
                statusDataLoaded: false,
                errorStatus: false,
            }
        }
        case "GET_STATUS_DATA_ERROR": {
            return {
                ...state,
                statusDataLoaded: false,
                statusDataLoading: false,
                errorStatus: true,
            }
        }
    }
    return state
}

const getStatusData =
    (vin: string): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        dispatch({ type: "GET_STATUS_LOADING" })

        const container = useContainer()
        container
            .action("getStatusData")(vin, StatusIdType.VIN)
            .then(
                (result) => {
                    dispatch({ type: "GET_STATUS_DATA_LOADED", payload: { statusData: result, vin } })
                },
                (_) => dispatch({ type: "GET_STATUS_DATA_ERROR" })
            )
    }

export type IActions = typeof Actions
export const Actions = { ...BundleActions, getStatusData }
