import { Button, Dropdown, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ReactElement, useEffect, useState } from "react"
import { getComponentStyles } from "./styles"
import Overlay from "./overlay"
import { VrcLocalFile } from "../../../component"

type SimpleDevice = {
    deviceId?: string
    label: string
}

function DropdownItemView(props: SimpleDevice): ReactElement | null {
    return <>{props.label}</>
}

type Props = {
    onImageUpload(image: VrcLocalFile): void
}

const LOCAL_STORAGE_KEY = "VRC_LOOKUP_SELECTED_CAMERA"

function Camera(props: Props) {
    const classNames = getComponentStyles()

    const { translateText } = useLocalization()
    const [devices, setDevices] = useState<Array<SimpleDevice>>()
    const [currentDeviceId, setCurrentDeviceId] = useState<string | undefined>(() => localStorage.getItem(LOCAL_STORAGE_KEY) || undefined)
    const [stream, setStream] = useState<MediaStream>()

    useEffect(() => {
        let unmounted = false

        navigator.mediaDevices.enumerateDevices().then((rawDevices) => {
            if (unmounted) {
                return
            }

            setDevices(
                rawDevices
                    .filter((device) => device.kind === "videoinput")
                    .map((device, index) => ({
                        deviceId: device.deviceId || undefined,
                        label: device.label || `Camera ${index + 1}`,
                    }))
            )
        })

        return () => {
            unmounted = true
        }
    }, [])

    if (!devices) {
        return <Loader />
    }

    if (!devices.length) {
        return <Text>{translateText(12623)}</Text>
    }

    const currentDevice = devices.find((device) => device.deviceId === currentDeviceId) || devices[0]

    const handleChangeDevice = (device: SimpleDevice) => {
        setCurrentDeviceId(device.deviceId)
        if (device.deviceId) {
            localStorage.setItem(LOCAL_STORAGE_KEY, device.deviceId)
        }
    }

    const handleStartClick = () => {
        navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: { deviceId: { exact: currentDevice.deviceId } },
            })
            .then(setStream)
    }

    return (
        <div>
            <Text modifiers="block">{translateText(12624)}</Text>
            <Dropdown
                className={classNames.dropDown}
                items={devices}
                itemView={DropdownItemView}
                value={currentDevice}
                onChange={handleChangeDevice}
            />
            <Button skin="highlight" className={classNames.button} onClick={handleStartClick}>
                {translateText(12625)}
            </Button>

            {stream && <Overlay stream={stream} onClose={() => setStream(undefined)} onImageUpload={props.onImageUpload} />}
        </div>
    )
}

export default function Wrapper(props: Props) {
    const { translateText } = useLocalization()

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices || !navigator.mediaDevices.getUserMedia) {
        return <Text>{translateText(12622)}</Text>
    }

    return <Camera {...props} />
}
