import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import { Badge, Box, Button, Icon, Loader, Stack } from "@tm/components"
import { useTelesalesCustomerNumber, useWorkTask, useAddToBasketModuleParameters } from "@tm/context-distribution"
import { WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ArticleInfoType, ConfigParams, ErpSystemConfigMode, IMicros, WorkTaskStatus, channel } from "@tm/models"
import Morpheus from "@tm/morpheus"
import {
    concat,
    decodeUniqueId,
    mapArticleToAddCatalogPartListRequest,
    mapArticleToAddWholesalerPartListRequest,
    mapArticleToReplaceByCatalogPartRequest,
    showWarehouseDataMissingError,
    useDefaultErpSystem,
    useDetailedErpInfoStore,
    useExternalCatalogUrl,
} from "@tm/utils"

import { useParams } from "react-router"
import { useIsArticleAvailableForMultipleDistributors, useErpInfo } from "@bundles/erp"

import PartnerSystemsPopover from "../../components/partner-systems-popover"
import { useErpInfoMulti } from "../../data/hooks/useErpInfoMulti"
import { useWarehouse } from "../../data/hooks/useWarehouse"
import { useAddArticleExternalUrl } from "../../helpers"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { useBasketMemo } from "../../hooks/useBasketMemo"
import { getBundleParams } from "../../utils"
import { ApprovalWarningPrompt } from "../_shared/ApprovalWarningPrompt"
import { QuantityField } from "./components/QuantityField"

type Props = IMicros["basket"]["add-to-basket"]
type RouteParams = {
    workTaskId?: string // Used from the central ordering (article details)
}

function AddToBasketComponent(props: Props & { workTaskId: string; addToNextBasket: boolean }) {
    const { workTask, createWorkTask } = useWorkTask() ?? {}

    const {
        data: articles,
        advertisementCategoryId,
        articleInfoType,
        buttonText,
        customerId,
        foundBySearchTerm,
        hideBasketButton,
        hideQuantityField,
        partToReplaceId,
        searchType,
        variant,
        vehicleId = workTask?.vehicle?.id,
        openPopoverOnMultiplePartnerSystems,
        onMultiplePartnerSystemsPopoverClosed,
        vehicleImageBase64,
        workTaskId,
        origin,
        erpType,
        shownWithWarehouseSelector,
        addToNextBasket,
        hideBadge,
        width,
    } = props

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [multiplePartnerSystemsPopoverCurrentlyClosed, setMultiplePartnerSystemsPopoverCurrentlyClosed] = useState(false)
    const [articlesState, setArticlesState] = useState<Article[]>(articles)

    const { addToBasketExternalSystemId } = getBundleParams()
    const { enableAddingButtonsAfterErp } = Morpheus.getParams<ConfigParams>()

    const { translateText, languageId } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { costEstimation, basket } = useWorkTaskBasketState(workTaskId)

    const { erpSystemConfig } = useDefaultErpSystem(props.erpSystemConfig)
    const { erpInfos, erpInfoLoaded } = useErpInfoMulti(articles, erpType, erpSystemConfig?.id, telesalesCustomerNo, vehicleId, foundBySearchTerm)
    const { erpConfig } = useErpInfo(articles[0], erpType || "list", undefined, undefined, foundBySearchTerm)

    // Store results for other components, so that they don't need to load it again
    const detailedErpStore = useDetailedErpInfoStore(workTaskId)
    const { addCatalogParts, addWholesalerParts, replacePartWithCatalogPart, removeParts } = basket.actions
    const { basketOrderGroups, basketLoading } = basket.state

    useEffect(
        function storeErpResults() {
            if (erpInfos.length && erpInfoLoaded) {
                detailedErpStore.addInfos(erpInfos)
            }
        },
        [erpInfoLoaded]
    )

    const isAvailableForMultipleDistributors = useIsArticleAvailableForMultipleDistributors(articles[0], erpConfig.erpSystemConfigs ?? [])

    const warehouseData = useWarehouse(articles[0]?.id, erpSystemConfig?.id) // Note: currently only working correctly for only one article

    const basketMemo = useBasketMemo(workTask, searchType)
    const { loadingExternalCatalogUrl, externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })
    const externalBasketUrl = useAddArticleExternalUrl(
        // Note: currently only working correctly for only one article
        externalCatalogUrl,
        languageId,
        articles[0]?.quantity,
        articles[0]?.traderArticleNo,
        articles[0]?.supplierArticleNo,
        articles[0]?.supplier.id,
        articles[0]?.productGroup.id
    )

    const [articleGetsAddedToBasket, setArticleGetsAddedToBasket] = useState(false)

    const dialogRef = useRef<WarningPrompt>(null)

    const buttonWidth = useMemo(() => {
        if (variant === "small") {
            return "20px"
        }

        if (buttonText) {
            return "13em"
        }

        return "6em"
    }, [variant, buttonText])

    useEffect(() => {
        setArticlesState(articles)
    }, [articles])

    const buttonDisabled = useMemo(() => {
        return (
            props.disabled ||
            articles.some((x) => !x.showAddToBasket) ||
            !workTaskId ||
            (enableAddingButtonsAfterErp && !erpInfoLoaded) ||
            loadingExternalCatalogUrl
        )
    }, [props.disabled, articles, workTaskId, enableAddingButtonsAfterErp, erpInfoLoaded, loadingExternalCatalogUrl])

    const [quantity, ids] = useMemo(() => {
        const distributorId = props.erpSystemConfig?.id
        const warehouseId = shownWithWarehouseSelector ? warehouseData.warehouse?.id : undefined
        let quantityValue = 0
        const partIds: string[] = []
        if (basketOrderGroups) {
            articles.forEach((article) => {
                const { supplierArticleNo, supplier, productGroup } = article
                basketOrderGroups
                    ?.filter(
                        (group) =>
                            (!distributorId || group.orderGroup.distributorId === distributorId) &&
                            (!warehouseId || group.orderGroup.warehouseId === warehouseId)
                    )
                    .forEach((group) => {
                        group.basketParts.forEach((part) => {
                            const { articleNumber, supplierId, productGroupId } = part.partItem.articleInformation
                            if (articleNumber === supplierArticleNo && supplierId === supplier.id && productGroupId === productGroup.id) {
                                quantityValue += part.partItem.quantity.value
                                partIds.push(part.partItem.id)
                            }
                        })
                    })
            })
        }
        return [quantityValue, partIds]
    }, [articleInfoType, articles, basketOrderGroups, props.erpSystemConfig?.id, shownWithWarehouseSelector, warehouseData.warehouse?.id])

    function handleQuantityChange(article: Article, newQuantity: number) {
        const articleToChange = articlesState.find((x) => x.id === article.id)
        if (newQuantity !== articleToChange?.quantity) {
            const articleStateQuantity = articlesState.map((art) => {
                if (art.id !== article.id) {
                    return art
                }
                return {
                    ...art,
                    quantity: newQuantity,
                }
            })
            setArticlesState(articleStateQuantity)
            channel("WORKTASK").publish("BASKET/ARTICLE_QUANTITY_CHANGED", { article, quantity: newQuantity })
        }
    }

    function addArticlesToBasket(
        workTaskId: string,
        articles: Array<Article>,
        customerId?: string,
        vehicleId?: string,
        foundBySearchTerm?: string,
        articleInfoType?: ArticleInfoType,
        memo?: string,
        advertisementCategoryId?: string
    ) {
        setArticleGetsAddedToBasket(true)

        // TODO: Check where add-to-basket is used for wholesaler articles and migrate them to use the "add-to-basket-wholesaler-part" micro
        if (articleInfoType === ArticleInfoType.WholesalerArticle) {
            addWholesalerParts(
                mapArticleToAddWholesalerPartListRequest(
                    articles,
                    workTaskId,
                    vehicleId,
                    customerId,
                    memo,
                    warehouseData.warehouse,
                    advertisementCategoryId,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description,
                    erpInfos,
                    origin
                )
            ).finally(() => setArticleGetsAddedToBasket(false))
        } else if (partToReplaceId && articles.length === 1) {
            // Note: currently only working correctly for only one article
            replacePartWithCatalogPart(
                mapArticleToReplaceByCatalogPartRequest(
                    partToReplaceId,
                    articles[0],
                    workTaskId,
                    vehicleId,
                    customerId,
                    foundBySearchTerm,
                    memo,
                    warehouseData.warehouse,
                    advertisementCategoryId,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description
                )
            ).catch(() => setArticleGetsAddedToBasket(false)) // catch used since the alternative modal closes automatically
        } else {
            if (hideQuantityField) {
                articles.map((article) => {
                    // TODO: return value of map not used, check if this is a bug
                    const erpInfo = erpInfos?.find((erp) => erp.itemId === article.id)

                    return {
                        ...article,
                        quantity: erpInfo?.quantity?.division && erpInfo.quantity.division > 1 ? erpInfo.quantity.division : article.quantity || 1,
                    }
                })
            }
            addCatalogParts(
                mapArticleToAddCatalogPartListRequest(
                    articles,
                    workTaskId,
                    vehicleId,
                    customerId,
                    foundBySearchTerm,
                    memo,
                    erpInfos,
                    warehouseData.warehouse,
                    advertisementCategoryId,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description,
                    origin
                )
            )
                .then((response) => props.onAddCatalogArticleToBasketFinished?.(response))
                .finally(() => setArticleGetsAddedToBasket(false))
        }
    }

    async function addToBasket() {
        // Always wait for attachVehicle to be sure, it is done before calling ShowWorkTaskBasket
        // Better solution will be done here https://jira.dvse.de/browse/NEXTINT-197
        if (vehicleImageBase64) {
            await costEstimation.actions.attachVehicleImage(vehicleImageBase64)
        }

        if (addToNextBasket !== false) {
            if (multiplePartnerSystemsPopoverCurrentlyClosed) {
                updateMultiplePartnerSystemsPopoverClosed(false)
            }

            addArticlesToBasket(
                workTaskId,
                articlesState,
                customerId,
                vehicleId,
                foundBySearchTerm,
                articleInfoType,
                basketMemo.position,
                advertisementCategoryId
            )

            // Reset quantity of articles back to initial after adding to the basket
            articles.forEach((article) => handleQuantityChange(article, article.initialQuantity))

            props.onAddToBasket?.(articles)
        }
    }

    async function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
        if (warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            warehouseData.refetchWarehouseData()
            return
        }

        if (erpInfos?.some((erpData) => erpData.itemOrderableInformation)) {
            if (onMultiplePartnerSystemsPopoverClosed) {
                updateMultiplePartnerSystemsPopoverClosed(true)
            }
            dialogRef.current?.show()
        } else if (externalBasketUrl) {
            if (createWorkTask && workTaskId && workTask?.statusValue === WorkTaskStatus.Undefined) {
                await createWorkTask({ workTaskId, skipRedirect: true })
            }

            Morpheus.showView("1", externalBasketUrl)
        } else if (
            erpConfig.mode === ErpSystemConfigMode.Partnersystems &&
            erpConfig.erpSystemConfigs &&
            erpConfig.erpSystemConfigs?.length > 1 &&
            !anchorEl &&
            !!openPopoverOnMultiplePartnerSystems &&
            isAvailableForMultipleDistributors
        ) {
            setAnchorEl(e.currentTarget)
        } else {
            addToBasket()
        }
    }

    function handleRemovePart(article: Article, basketIds: string[]) {
        if (!workTaskId) {
            return
        }

        removeParts(basketIds, true).then(() => {
            channel("WORKTASK", workTaskId).publish("BASKET/ARTICLE_REMOVED", {})
        })
    }

    function renderBadge() {
        if (articleGetsAddedToBasket) {
            return (
                <Badge
                    title={translateText(1299)}
                    badgeContent={<Loader size="extrasmall" />}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: "initial" }}
                />
            )
        }

        if (!workTaskId || articles.length !== 1) {
            // Note: currently only working correctly for only one article
            return null
        }

        if (!quantity || !ids.length) {
            return
        }

        return (
            <Badge
                size="small"
                title={translateText(1299)}
                onClick={() => !basketLoading && handleRemovePart(articles[0], ids)} // Note: currently only working correctly for only one article
                badgeContent={
                    <Stack direction="row">
                        {quantity}
                        <Icon name="close" width="12px" height="12px" sx={{ marginLeft: "2px" }} />
                    </Stack>
                }
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{ position: "initial" }}
            />
        )
    }

    function updateMultiplePartnerSystemsPopoverClosed(isHidden: boolean) {
        if (!onMultiplePartnerSystemsPopoverClosed) {
            return
        }

        onMultiplePartnerSystemsPopoverClosed(isHidden)
        setMultiplePartnerSystemsPopoverCurrentlyClosed(isHidden)
    }

    function renderButton() {
        if (hideBasketButton) {
            return null
        }

        return (
            <>
                {!hideBadge && renderBadge()}

                <Box minWidth={width || buttonWidth}>
                    <Button
                        title={translateText(937)}
                        variant={!buttonDisabled ? "bordered" : undefined}
                        onClick={(e) => handleButtonClick(e)}
                        disabled={buttonDisabled}
                        color={!buttonDisabled ? "highlight" : undefined}
                        startIcon={<Icon name={partToReplaceId ? "replace" : "cart"} />}
                        fullWidth
                        className="addToBasketButton"
                        sx={width ? { width } : undefined}
                    >
                        {buttonText ? <>{buttonText}</> : ""}
                    </Button>
                </Box>
                <ApprovalWarningPrompt
                    ref={dialogRef}
                    erpInfos={erpInfos}
                    onCancel={() => updateMultiplePartnerSystemsPopoverClosed(false)}
                    onConfirm={addToBasket}
                />
            </>
        )
    }

    function renderQuantityField() {
        if (articles.length !== 1) {
            return null
        }
        const erpInfo = erpInfos?.find((erp) => erp.itemId === articles[0].id)

        // Note: currently only working correctly for only one article
        return (
            <QuantityField
                {...props}
                minQuantity={erpInfo?.quantity?.minQuantity}
                maxQuantity={erpInfo?.quantity?.maxQuantity}
                division={erpInfo?.quantity?.division}
                article={articles[0]}
                onQuantityChange={handleQuantityChange}
            />
        )
    }

    function renderPartnerSystemsPopover() {
        return (
            <PartnerSystemsPopover
                anchorEl={anchorEl}
                article={articles[0]}
                closePopup={() => {
                    setAnchorEl(null)
                }}
            />
        )
    }

    const disabled = articles.some((x) => !x.showAddToBasket)

    return (
        <Box className="tk-basket" title={disabled ? translateText(938) : undefined}>
            <Box className={concat(" ", "add-to-basket", disabled && "add-to-basket--disabled")}>
                {renderPartnerSystemsPopover()}
                {renderQuantityField()}
                {renderButton()}
            </Box>
        </Box>
    )
}

export default function Wrapper(props: Props) {
    const { workTaskId } = useWorkTask() ?? {}
    const { showBasketButton, addToNextBasket } = useAddToBasketModuleParameters()

    const params = useParams<RouteParams>()
    const decodedWorkTaskId = decodeUniqueId(params.workTaskId ?? "") // TODO: Check if workTaskId from useWorkTask couldn't be used here instead - WorkTaskId from params can be null (for example: Neimcke/PV Startpage -> Offers widget - see NEXT-13118)

    const id = workTaskId ?? decodedWorkTaskId ?? props.generatedWorktaskId
    // if the MDM config at the BasketModule has no Basketbutton, don't render it
    if (!id || showBasketButton === false) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToBasketComponent {...props} workTaskId={id} addToNextBasket={addToNextBasket} />
        </Suspense>
    )
}
