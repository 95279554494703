import { SearchFilters, RequestArticleListPayload, RequestArticleDetailsPayload, PartsRoutes, CategoryWithDetailsType } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { SearchType } from "../../../business"
import { Route } from "./model"
import { advertisementCategoryDivider } from "../../../utils"

export * from "./model"

export const URL_UNICRITERIA_SEPARATOR = "$;$"

export function getArticleListRoute(
    request: RequestArticleListPayload & { direct?: { extendedSearch?: boolean } },
    routes?: PartsRoutes,
    showNewPartsVersion?: boolean
): Route | undefined {
    if (!routes) {
        return
    }

    let searchType: SearchType
    let route
    const queryParams = new URLSearchParams()

    if (request.direct) {
        const { query, searchFilter, extendedSearch, productGroupIds, oeManufacturerIds, oeInformationWithoutCar } = request.direct
        if (!query) {
            return
        }

        route = routes.directSearch.list

        searchType = SearchType.DIRECT
        queryParams.set("query", query)

        if (searchFilter) {
            queryParams.set("searchFilter", searchFilter.toString())
        }

        if (Array.isArray(productGroupIds) && productGroupIds.length) {
            queryParams.set("productGroupIds", productGroupIds.join(","))
        }

        if (extendedSearch) {
            queryParams.set("extendedSearch", extendedSearch.toString())
        }

        if (oeManufacturerIds) {
            queryParams.set("oeManufacturerIds", oeManufacturerIds.join(","))
        }

        if (oeInformationWithoutCar) {
            queryParams.set("oeInformationWithoutCar", oeInformationWithoutCar.toString())
        }
    } else if (request.synonym) {
        const { query } = request.synonym
        if (!query) {
            return
        }

        route = routes.vehicleParts.list

        searchType = SearchType.SYNONYM
        queryParams.set("query", query)
    } else if (request.uniSearch) {
        const { query } = request.uniSearch
        if (!query) {
            return
        }

        route = routes.universalParts?.list

        searchType = SearchType.UNISEARCH
        queryParams.set("query", query)
    } else if (request.node) {
        const { treeId, nodeId, history } = request.node
        if (!treeId || !nodeId) {
            return
        }

        route = routes.vehicleParts.list

        searchType = SearchType.NODE
        queryParams.set("treeId", treeId.toString())
        queryParams.set("nodeId", nodeId.toString())
        if (history) {
            queryParams.set("history", history.join("+"))
        }
    } else if (request.uniNode) {
        const { treeId, nodeId, history } = request.uniNode
        if (!treeId || !nodeId) {
            return
        }

        route = routes.universalParts?.list

        searchType = SearchType.UNINODE
        queryParams.set("treeId", treeId.toString())
        queryParams.set("nodeId", nodeId.toString())
        if (history) {
            queryParams.set("history", history.join("+"))
        }
    } else if (request.productGroups) {
        const { ids, supplierIds, attributes, treeId, nodeId, history } = request.productGroups
        if (!ids.length) {
            return
        }

        route = routes.vehicleParts.list

        searchType = SearchType.PRODUCTGROUPS
        queryParams.set("productGroupIds", ids.join(","))

        if (supplierIds?.length) {
            queryParams.set("supplierIds", supplierIds.join(","))
        }

        if (attributes?.length) {
            if (attributes.every((x) => typeof x === "string")) {
                queryParams.set("attributes", attributes.join("$"))
            } else {
                // TODO: Replace with bundle message bus somehow (Hermes)
                const id = uniqueId()
                sessionStorage.setItem(id, JSON.stringify(attributes))
                queryParams.set("attributesCacheId", id)
            }
        }

        if (treeId != undefined && nodeId != undefined) {
            queryParams.set("treeId", treeId.toString())
            queryParams.set("nodeId", nodeId.toString())
        }
        if (history) {
            queryParams.set("history", history.join("+"))
        }
    } else if (request.uniProductGroups) {
        const { ids } = request.uniProductGroups
        if (!ids.length) {
            return
        }

        route = routes.universalParts?.list

        searchType = SearchType.UNIPRODUCTGROUPS
        queryParams.set("productGroupIds", ids.join(","))
    } else if (request.oeReferences) {
        const { nos, productGroupIds } = request.oeReferences
        if (!nos.length || !productGroupIds.length) {
            return
        }

        route = routes.vehicleParts.list

        searchType = SearchType.OE
        queryParams.set("oeReferenceNos", nos.join(","))
        queryParams.set("productGroupIds", productGroupIds.join(","))
    } else if (request.traderArticleNos) {
        const { wholesalerParts, showNotFoundArticles, hideDuplicatesWithDifferentProductGroups, hideArticleListWithoutResults } =
            request.traderArticleNos
        if (!wholesalerParts?.length) {
            return
        }

        route = routes.directSearch.list

        searchType = SearchType.TRADERARTICLENOS

        queryParams.set("traderArticleNos", wholesalerParts.map((x) => x.wholesalerArticleNumber).join(","))

        if (showNotFoundArticles) {
            queryParams.set("showNotFoundArticles", "true")
        }

        if (hideDuplicatesWithDifferentProductGroups) {
            queryParams.set("hideDuplicatesWithDifferentProductGroups", "true")
        }

        if (hideArticleListWithoutResults) {
            queryParams.set("hideArticleListWithoutResults", "true")
        }
    } else if (request.offers) {
        route = routes.offerParts.list
        searchType = SearchType.OFFERS
        queryParams.set(
            "advertisementCategory",
            request.offers.advertisementCategory.id + advertisementCategoryDivider + request.offers.advertisementCategory.description
        )

        if (request.offers.tabTitle) {
            queryParams.set("tabTitle", request.offers.tabTitle)
        }
    } else if (request.general) {
        const { productGroupIds, supplierArticleNo, supplierIds, query } = request.general

        if (supplierArticleNo && supplierArticleNo.trim().length) {
            route = routes.directSearch.list

            searchType = SearchType.DIRECT
            queryParams.set("searchFilter", SearchFilters.SupplierArticleNo.toString())

            if (productGroupIds && productGroupIds.length) {
                queryParams.set("productGroupIds", productGroupIds.join(","))
            }

            if (supplierIds && supplierIds.length) {
                queryParams.set("supplierIds", supplierIds.join(","))
            }

            queryParams.set("query", supplierArticleNo.trim())
        } else if (query) {
            route = routes.universalParts?.list

            searchType = SearchType.UNISEARCH
            queryParams.set("query", query)

            if (productGroupIds && productGroupIds.length) {
                queryParams.set("productGroupIds", productGroupIds.join(","))
            }

            if (supplierIds && supplierIds.length) {
                queryParams.set("supplierIds", supplierIds.join(","))
            }
        } else {
            return
        }
    } else if (Array.isArray(request.oePositions) && request.oePositions.length) {
        searchType = SearchType.OE_POSITIONS
        route = routes.directSearch.list
    } else {
        return
    }

    // Also be able to submit oe positions for different search types
    if (Array.isArray(request.oePositions) && request.oePositions.length) {
        // TODO: Replace with bundle message bus somehow (Hermes)
        const id = uniqueId()
        sessionStorage.setItem(id, JSON.stringify(request.oePositions))
        queryParams.set("oePositionsCacheId", id)
    }

    if (request.replaceButtonMicro) {
        queryParams.set("replaceButtonMicro", request.replaceButtonMicro ?? "")
    }

    if (request.fastCalculator) {
        if (request.fastCalculator.isFastService) {
            queryParams.set("isFastService", "true")
        }
        queryParams.set("isFastCalculator", "true")
        queryParams.set("isModal", "true")

        const { alternatives, oeNrs } = request.fastCalculator

        if (Array.isArray(alternatives) && alternatives.length) {
            // TODO: Replace with bundle message bus somehow (Hermes)
            const id = uniqueId()
            const data = { alternatives, oeNrs }
            sessionStorage.setItem(id, JSON.stringify(data))
            queryParams.set("fastCalculatorAlternativesCacheId", id)
        }
    }

    if (request.isModal) {
        queryParams.set("isModal", "true")
    }

    if (request.fittingPosition) {
        queryParams.set("fittingPosition", request.fittingPosition.toString())
    }

    if (request.equipmentCode) {
        queryParams.set("equipmentCode", request.equipmentCode.toString())
    }

    if (request.partToReplaceId) {
        queryParams.set("partToReplaceId", request.partToReplaceId)
    }

    if (request.isOeReplacement) {
        queryParams.set("isOeReplacement", request.isOeReplacement.toString())
    }

    if (request.forceReload) {
        // add the flag with a random number to always have a different url
        // and trigger the receive of the new url
        queryParams.set("forceReload", Math.round(Math.random() * 1000).toString())
    }

    let callNewModal = showNewPartsVersion && request.useNewModal
    if (request.inModal) {
        if (Array.isArray(request.oePositions) && request.oePositions.length && showNewPartsVersion && !request.ignoreNewModal) {
            route = "/parts/oe"
            callNewModal = true
        } else {
            route = routes.partAlternatives.list
        }

        if (callNewModal && (route === routes.partAlternatives.list || request.forcePartsAlternatives)) {
            queryParams.set("searchType", SearchType[searchType].toLowerCase())
            route = "/parts/alternatives"
        }
    }

    if (request.hideOePrice) {
        queryParams.set("hideOePrice", "true")
    }

    if (request.extendedAssortment) {
        queryParams.set("extendedAssortment", "true")
    }

    if (request.disableExtendedAssortment) {
        queryParams.set("disableExtendedAssortment", "true")
    }

    if (request.forcePartsAlternatives) {
        queryParams.set("forcePartsAlternatives", "true")
    }

    if (request.customerAlsoBought) {
        queryParams.set("customerAlsoBought", "true")
    }

    if (request.partToReplaceQuantity) {
        queryParams.set("partToReplaceQuantity", request.partToReplaceQuantity.toString())
    }

    return {
        route: callNewModal && route ? route : `${route}/${SearchType[searchType].toLowerCase()}`,
        queryString: queryParams.toString(),
        inModal: request.inModal,
        callNewModal,
    }
}

export function getArticleDetailsRoute(
    request: RequestArticleDetailsPayload & { partToReplaceId?: string },
    articleDetailsInModal?: boolean,
    routes?: Partial<PartsRoutes>
): Route | undefined {
    if (!routes) {
        return
    }

    // Article details should always be opened in a modal
    if (articleDetailsInModal) {
        request.inModal = true
    }

    const queryParams = new URLSearchParams()

    const { article } = request

    if ("productGroupId" in article) {
        const { productGroupId, supplierId, supplierArticleNo, supplierName } = article

        if (productGroupId && supplierId && supplierArticleNo) {
            queryParams.set("productGroupId", productGroupId.toString())
            queryParams.set("supplierId", supplierId.toString())
            queryParams.set("supplierArticleNo", supplierArticleNo)
        }

        if (supplierName) {
            queryParams.set("supplierName", supplierName)
        }
    } else if ("traderArticleNo" in article) {
        const { traderArticleNo } = article

        if (traderArticleNo) {
            queryParams.set("traderArticleNo", traderArticleNo)
        }
    }

    // Check if any of the required query param ist set - if not ignore the invalid request
    if (!Array.from(queryParams).length) {
        return
    }

    if (request.vehicleLinkageId) {
        queryParams.set("vehicleLinkageId", request.vehicleLinkageId.toString())
    }

    if (request.foundBySearchTerm) {
        queryParams.set("foundBySearchTerm", request.foundBySearchTerm)
    }

    if (request.initialQuantity) {
        queryParams.set("initialQuantity", request.initialQuantity.toString())
    }

    if (request.partToReplaceId) {
        queryParams.set("partToReplaceId", request.partToReplaceId)
    }

    if (request.disableAddToBasket) {
        queryParams.set("disableAddToBasket", "1")
    }

    let encodedSubPage = ""
    let encodedScrollTo = ""

    if (request.subPage) {
        encodedSubPage = encodeURIComponent(request.subPage)

        if (request.scrollTo) {
            encodedScrollTo = `/${encodeURIComponent(request.scrollTo)}`
        }
    }

    const categoryType: CategoryWithDetailsType = (request.categoryType as CategoryWithDetailsType) || "directSearch"
    let route = routes[categoryType]?.details

    // If article details should be opened in a modal replace url and add the path separator
    if (route && request.inModal) {
        const modalRoute = routes[categoryType]?.modalDetails

        // Try to use the special modal url if available
        route = modalRoute || route

        // avoid double "^" in url
        if (!route.includes("^")) {
            route += "^"
        }

        return {
            route: `${route}/0/0/0/${encodedSubPage}${encodedScrollTo}`,
            queryString: queryParams.toString(),
            inModal: true,
        }
    }

    return {
        route: `${route}/0/0/0/${encodedSubPage}${encodedScrollTo}`,
        queryString: queryParams.toString(),
    }
}
