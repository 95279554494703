import { RouteComponentProps, withRouter } from "@tm/utils"
import { useTyresV2 } from "@tm/context-distribution"
import { Summary } from "./components/Summary"
import { SummaryV2 } from "./components/SummaryV2"

export type SummaryProps = RouteComponentProps & {
    vehicleSelectionRoute: string
}
function SummaryComponent(props: SummaryProps) {
    const tyresV2 = useTyresV2()

    return tyresV2 ? <SummaryV2 {...props} /> : <Summary {...props} />
}

export default withRouter(SummaryComponent)
