import { CSSProperties } from "react"
import { CisElectronicAddress } from "@tm/models"
import { Typography } from "@tm/components"

type TableDataType = {
    label: string
    value: string
}

const style: CSSProperties = {
    width: "100%",
    paddingLeft: "20px",
}

export function renderLabelValue(contactOption: TableDataType) {
    return (
        <tr key={contactOption.label + contactOption.value}>
            <td>
                <Typography>{contactOption.label}</Typography>
            </td>
            <td style={style}>
                <Typography fontWeight="bold">{contactOption.value}</Typography>
            </td>
        </tr>
    )
}

export const renderElectronicAddresses = (eAddresses: Array<CisElectronicAddress>) => {
    if (!eAddresses) {
        return null
    }

    const lvpValues: Array<TableDataType> = []
    eAddresses.forEach((eAddress) =>
        lvpValues.push({
            label: eAddress.description,
            value: eAddress.value,
        })
    )

    return <table>{lvpValues.map(renderLabelValue)}</table>
}
