import { Typography, ModuleHeaderSlot } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Options } from "./Options"
import { StyledModuleHeader } from "../StyledComponents"

type Props = {
    title?: string
}

export function ModalModuleHeader({ title }: Props) {
    const { translateText } = useLocalization()

    return (
        <StyledModuleHeader>
            <Typography variant="h1" pr={2} pb={1}>
                {title || translateText(993)}
            </Typography>
            <ModuleHeaderSlot>
                <Options />
            </ModuleHeaderSlot>
        </StyledModuleHeader>
    )
}
