import { ESufficentSelections } from "../../../../data/enums"
import { GetPartsRequest } from "../../../../data/repositories"
import { MainState } from "../../../main"

export function createGetPartsRequest(state: MainState): GetPartsRequest | undefined {
    const {
        summary: { selectedMmtGroup },
        details: { selectedImagePosition, mmtList, savedCriterias, criterias },
        manager: { vehicle },
    } = state
    const imagePostion = selectedImagePosition
    const imageName = imagePostion?.imageName
    const masterCode = imagePostion?.mastercode

    const activeMMT = (mmtList.sufficientSelection == ESufficentSelections.Distinct && mmtList.items[0]) || selectedMmtGroup?.mmtList?.[0]
    let posCode = imagePostion?.posCode
    const regex = /\d+/g
    posCode = posCode?.match(regex)?.first() || ""

    const criteria = criterias?.base?.map((crit) => {
        if (savedCriterias?.[vehicle?.tecDocTypeId!]?.find((c) => c.code === crit.code)) {
            crit.isEnabled = true
        } else {
            crit.isEnabled = false
        }

        return crit
    })

    if (mmtList && imageName && imagePostion) {
        return {
            imageName,
            position: imagePostion,
            mmts: (activeMMT && [activeMMT]) || [],
            masterCode,
            criteria: criteria ?? [],
            ...(vehicle?.tecDocTypeId && {
                kType: vehicle.tecDocTypeId,
            }),
        }
    }
}
