import { IBundle } from "@tm/morpheus"
import Compilations from "./components/compilations"
import HeaderButton from "./components/header-button"
import WatchListButton from "./components/watchlist-button"
import Widget from "./components/widget"
import AddToCompilation from "./micros/add-to-compilation"
import AddToCompilationBasket from "./micros/add-to-compilation-basket"
import { initCompilationsBundle, isWM, version } from "./utils"
import { useAddArticle, useCompilations, useCompilationsContains, useCreateCompilation, useDeleteArticles } from "./data/hooks/useCompilations"
import { mapArticleToCompilationArticle } from "./data/mapper"
import type { Compilation } from "./data"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [Compilations, Widget, HeaderButton, WatchListButton],
    micros: [AddToCompilation, AddToCompilationBasket],
    init: initCompilationsBundle,
}

export default bundle

export { useCompilations, useCompilationsContains, mapArticleToCompilationArticle, useCreateCompilation, useAddArticle, useDeleteArticles, isWM }

export type { Compilation }
