import { Button, Checkbox, Table, Text } from "@tm/controls"
import { Box, Icon, styled, Tooltip } from "@tm/components"
import { Item, SelectionKeys } from "../../../data/models"

export type Props = {
    selectionId: string
    selectionKey: SelectionKeys
    multiSelect: boolean
    items: Item[]
    onSelect?: (item: string[], selectionId: string, selectionKey: SelectionKeys) => void
    updateMultiSelections?: (id: string, selectionId: string) => void
    selectedIds: string[]
    withSubmit?: boolean
    proposedItem?: boolean
    readonly?: boolean
}

export default function Select(props: Props) {
    const { selectionKey, onSelect, multiSelect, items, selectionId, updateMultiSelections, selectedIds, withSubmit, proposedItem, readonly } = props

    const renderDescription = (item: Item) => {
        return (
            <Table.Cell>
                <Text size="l">
                    {item.label}
                    {item?.isSelected && item?.selectionReason && (
                        <Tooltip title={item?.selectionReason}>
                            <StyledSelectedItemIcon name="info-filled" />
                        </Tooltip>
                    )}
                </Text>
            </Table.Cell>
        )
    }

    const renderCheckbox = (item: Item) => {
        return (
            <Table.Cell>
                <Checkbox size="l" onLabelClick={() => undefined} checked={!!selectedIds?.find((x) => x === item.id)} disabled={readonly} />
            </Table.Cell>
        )
    }

    const renderCheckboxSingle = (item: Item) => {
        return (
            <Table.Cell>
                <Checkbox size="l" onLabelClick={() => undefined} checked={!!items?.find((i) => item.id === i?.id)?.isSelected} disabled={readonly} />
            </Table.Cell>
        )
    }

    const handleSingleSelect = (checkedItem: Item) => {
        if (!checkedItem) {
            return
        }

        const currentItem = items.find((item) => item.id === checkedItem.id)

        if (currentItem?.isSelected) {
            onSelect?.([], selectionId, selectionKey)
        } else {
            onSelect?.([checkedItem.id], selectionId, selectionKey)
        }
    }

    const handlePropositonAccept = () => {
        const proposition = items.find((i) => i.isSelected)
        if (proposition) {
            handleSingleSelect(proposition)
        }
    }

    const handeSelections = (item: Item) => {
        updateMultiSelections?.(selectionId, item.id)
    }

    const handleMultiSelections = () => {
        // in case of un-check we need to send the previous selected values so the WS knows to unselect them
        // but do not send the ones that are checked by us and also checked by the WS
        const idsToSend = items
            .filter((y) => (selectedIds.includes(y.id) && !y.isSelected) || (y.isSelected && !selectedIds.includes(y.id)))
            .map((z) => z.id)
        onSelect?.(idsToSend, selectionId, selectionKey)
    }

    return (
        <>
            {!multiSelect && (
                <StyledBox>
                    <Table
                        data={items}
                        onClickRow={readonly ? () => undefined : handleSingleSelect}
                        className="selectWrapper"
                        columns={[
                            <Table.Column
                                key="maintenancePlanDescription"
                                className="maintenancePlanDescription"
                                renderItemContent={renderDescription}
                            />,
                            <Table.Column key="maintenancePlanCheckbox" renderItemContent={renderCheckboxSingle} />,
                        ]}
                        getRowClassName={(data) => `models__item ${data.isSelected ? "is-selected" : ""}`}
                    />

                    {proposedItem && (
                        <Button className="submitButton" size="l" skin="highlight" icon="arrows_down" onClick={handlePropositonAccept} disabled={readonly} />
                    )}
                </StyledBox>
            )}

            {multiSelect && (
                <StyledBox>
                    <Table
                        data={items}
                        onClickRow={readonly ? () => undefined : handeSelections}
                        className="selectWrapper"
                        columns={[
                            <Table.Column
                                key="maintenancePlanDescription"
                                className="maintenancePlanDescription"
                                renderItemContent={renderDescription}
                            />,
                            <Table.Column key="maintenancePlanCheckbox" renderItemContent={renderCheckbox} />,
                        ]}
                        getRowClassName={(data) => `models__item ${selectedIds.includes(data.id) ? "is-selected" : ""}`}
                    />

                    {withSubmit && (
                        <Button
                            className="submitButton"
                            disabled={!selectedIds.length || readonly}
                            size="l"
                            skin="highlight"
                            icon="arrows_down"
                            onClick={handleMultiSelections}
                        />
                    )}
                </StyledBox>
            )}
        </>
    )
}

const StyledBox = styled(Box)({
    display: "flex",
    marginBottom: "1em",
    flex: 1,
    "& .selectWrapper": {
        width: "100%",
        paddingRight: "1em",
        "& .fancy-list__item": {
            justifyContent: "space-between",
        },
    },
    "& .submitButton": {
        padding: ".7em",
        height: "3em",
        position: "sticky",
        top: "calc(50% - 1.5em)",
    },
})

const StyledSelectedItemIcon = styled(Icon)({
    verticalAlign: "middle",
    marginLeft: "1em",
    cursor: "pointer",
})
