import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Icon } from "@tm/controls"
import { MessageAppointment, Button as ComButton, styled } from "@tm/components"
import { Message, useChatMessagesReadonly, useCalendarInfos, useOpenFastCalc } from "../../../../../../data/hooks"
import { AttachmentTypeAppointment, MetaDataType } from "../../../../../../data"
import EditAppointmentButtons from "../../../../../_shared/edit-appointment-buttons"

type Props = {
    appointmentId: string
    date: Date | string
    endDate: Date | string
    initialMetadata: AttachmentTypeAppointment
    message: Message
    isOwnMessage: boolean
}

const ActionButtonContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    "> *": {
        marginBottom: "5px",
    },
})

export function AppointmentResponse(props: Omit<Props, "initialMetadata">) {
    const initial = useInitialAppointmentRequest(props.message.chatId, props.appointmentId)

    if (!initial) {
        return null
    }

    return <Appointment {...props} initialMetadata={initial.metadata} />
}

export default function Appointment({ appointmentId, date, endDate, initialMetadata, message, isOwnMessage }: Props) {
    const { translateText, date: formatDate } = useLocalization()
    const response = useNextAppointmentResponse(message.chatId, message, appointmentId)
    const { services, calendarUrl } = useCalendarInfos(initialMetadata, date, endDate)
    const { handleFastCalculatorClick } = useOpenFastCalc(message.chatId, initialMetadata)

    if (!appointmentId || !date || !Date.parse(date.toString())) {
        return null
    }

    const success = response?.metadata.action === "customerConfirmation" || response?.metadata.action === "repairshopConfirmation"
    const serviceList = services.map((s) => `${s.label} - ${s.duration}h`)
    const totalWorkingHours = useMemo(
        () =>
            services?.reduce((a, b) => {
                return a + b.duration
            }, 0),
        [services]
    )

    return (
        <MessageAppointment
            left={!isOwnMessage}
            appointmentAccept={!response ? undefined : success}
            listEntrys={serviceList}
            headline={translateText(3085)}
            subline={`${translateText(99)} ${initialMetadata?.vehicleInfo?.displayName}`}
            actionButton={
                <ActionButtonContainer>
                    {handleFastCalculatorClick && (
                        <div>
                            <ComButton variant="outlined" onClick={handleFastCalculatorClick} startIcon={<Icon name="fastclick" />}>
                                {translateText(1579)}
                            </ComButton>
                        </div>
                    )}
                    {!response && (
                        <EditAppointmentButtons totalWorkingHours={totalWorkingHours} appointmentId={appointmentId} date={date} message={message} />
                    )}
                </ActionButtonContainer>
            }
            acceptText={translateText(3069)}
            declineText={translateText(3068)}
            downloadButtonText={formatDate(new Date(date), "HH:mm | dd MMM")}
            downloadhref={calendarUrl || ""}
            downloadFileName="Download.ics"
            dateTime={formatDate(new Date(message.inserted), "g")}
        >
            {response?.message.text || initialMetadata.note}
        </MessageAppointment>
    )
}

/**
 * messageToSearch should be an initial appointment or a proposal response.
 * The result is the last appointmentResponse or the next proposal response.
 *
 * Example 1
 *   Appointment <- messageToSearch
 *   Confirmation
 *   Rejection <- result
 *
 * Example 2
 *   Appointment <- messageToSearch
 *   Rejection
 *   Proposal <- result
 *   Confirmation
 *
 * Example 3
 *   Appointment
 *   Confirmation
 *   Proposal  <- messageToSearch
 *   Rejection <- result
 */
function useNextAppointmentResponse(chatId: string, messageToSearch: Message, appointmentId: string) {
    const allMessages = useChatMessagesReadonly(chatId)

    return useMemo(() => {
        if (!allMessages) {
            return
        }

        let messageWasFound = false
        let result

        for (let i = 0; i < allMessages.length; i++) {
            const message = allMessages[i]
            const metadata: MetaDataType | undefined = message.appMetaData && JSON.parse(message.appMetaData)
            if ((metadata?.type === "appointment" || metadata?.type === "appointmentResponse") && metadata.appointmentId === appointmentId) {
                if (messageWasFound) {
                    result = { message, metadata }

                    if (metadata.action === "customerProposal" || metadata.action === "repairshopProposal") {
                        return result
                    }
                }

                if (message === messageToSearch) {
                    // compare references
                    messageWasFound = true
                }
            }
        }

        return result
    }, [allMessages, appointmentId, messageToSearch])
}

function useInitialAppointmentRequest(chatId: string, appointmentId: string) {
    const allMessages = useChatMessagesReadonly(chatId)

    return useMemo(() => {
        if (!allMessages) {
            return
        }

        for (let i = 0; i < allMessages.length; i++) {
            const message = allMessages[i]
            const metadata: MetaDataType | undefined = message.appMetaData && JSON.parse(message.appMetaData)
            if (metadata?.type === "appointment" && metadata.appointmentId === appointmentId) {
                return { message, metadata }
            }
        }
    }, [allMessages, appointmentId])
}
