import { DateField, DateFieldProps } from "@tm/controls"
import { LocalizationProps } from "@tm/localization"
import { FC } from "react"
import { cssRaw } from "typestyle"

type Props = Omit<DateFieldProps, keyof LocalizationProps> & {}

const CustomDateField: FC<Props> = ({ ...rest }) => {
    return <DateField {...rest} />
}

cssRaw(`
.tk-eurotax .react-datepicker-popper .react-datepicker__current-month, .tk-eurotax .react-datepicker-popper .react-datepicker__navigation {
    display: none;
  }
  .tk-eurotax .react-datepicker-popper .react-datepicker__header__dropdown {
    height: 2em;
    display: flex;
    flex: 1;
    font-size: 1rem;
    align-items: center;
  }
  .tk-eurotax .react-datepicker-popper .react-datepicker__year-dropdown-container, .tk-eurotax .react-datepicker-popper .react-datepicker__month-dropdown-container {
    width: 50%;
    display: flex;
  }
  .tk-eurotax .react-datepicker-popper .react-datepicker__year-dropdown-container {
    justify-content: flex-start;
  }
  .tk-eurotax .react-datepicker-popper .react-datepicker__month-dropdown-container {
    justify-content: flex-end;
  }
`)

export default CustomDateField
