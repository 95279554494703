import { useMutation, UseMutateAsyncFunction, useQueryClient } from "react-query"
import { WorktaskInfo, WorkTaskReadModel } from "@tm/models"
import { createWorktask, FindWorktaskQuery, findWorktasks, showWorktask } from "../repositories"

export enum WORKTASK_REQUEST_KEYS {
    WORKTASK_LOAD = "WORKTASK_LOAD",
    WORKTASK_CREATE = "WORKTASK_CREATE",
    WORKTASK_FIND = "WORKTASK_FIND",
}

export const useShowWorktask = (): {
    showWorktask: UseMutateAsyncFunction<WorkTaskReadModel | undefined, unknown, string>
    isLoading: boolean
} => {
    const mutation = useMutation(WORKTASK_REQUEST_KEYS.WORKTASK_LOAD, showWorktask)

    return { showWorktask: mutation.mutateAsync, isLoading: mutation.isLoading }
}

export const useCreateWorktask = (): {
    createWorktask: UseMutateAsyncFunction<WorkTaskReadModel, unknown, string>
    isLoading: boolean
} => {
    const mutation = useMutation(WORKTASK_REQUEST_KEYS.WORKTASK_CREATE, createWorktask)

    return { createWorktask: mutation.mutateAsync, isLoading: mutation.isLoading }
}

export const useFindWorktask = (): {
    findWorktask: UseMutateAsyncFunction<{ worktasks: WorktaskInfo[]; pageIndex: number }, unknown, FindWorktaskQuery>
    isLoading: boolean
    fetched: boolean
} => {
    const queryClient = useQueryClient()
    const mutation = useMutation<{ worktasks: WorktaskInfo[]; pageIndex: number }, unknown, FindWorktaskQuery>(
        WORKTASK_REQUEST_KEYS.WORKTASK_FIND,
        findWorktasks,
        {
            onSuccess: (data, variables) => {
                queryClient.setQueryData([WORKTASK_REQUEST_KEYS.WORKTASK_FIND, variables], data)
            },
        }
    )

    return { findWorktask: mutation.mutateAsync, isLoading: mutation.isLoading, fetched: mutation.isSuccess }
}
