import { Stack } from "@tm/components"
import { useState } from "react"
import { ArticleErpInfo, WheelsBundleParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { ArticleListConfigurationProvider } from "../ListV2/ArticleListConfiguration"
import { ArticleListActionsProvider } from "../ListV2/ContextProvider/ArticleListActions"
import { TyresError } from "../../../../tyres/src/components/list/components"
import { NextPageLoader } from "../ListV2/components/NextPageLoader"
import { ComposerComponent, ContextComposer } from "../ListV2/ContextProvider"
import { BasketQuantitiesProvider } from "../ListV2/ContextProvider/BasketQuantities"
import { ListOptionsProvider } from "../ListV2/ContextProvider/ListOptions"
import { ErpInfosProvider } from "../ListV2/ContextProvider/ErpInfos"
import { NotesProvider } from "../ListV2/ContextProvider/Notes"
import { useWheelsListContext, WheelsListProvider } from "./ContextProvider/WheelsList"
import { EmptyListParamsProvider } from "../ListV2/ContextProvider/ListParams"
import { EmptyFiltersProvider } from "../ListV2/ContextProvider/Filters"
import { TradeReferenceProvider } from "../ListV2/ContextProvider/TradeReferences"
import { PreviouslyOrderedArticlesProvider } from "../ListV2/ContextProvider/PreviouslyOrderedArticles"
import { ActiveVehicleDataProvidersProvider } from "../ListV2/ContextProvider/ActiveVehicleDataProviders"
import { ProductGroupRepairTimesProvider } from "../ListV2/ContextProvider/ProductGroupRepairTimes"
import { WheelsArticleItem } from "./WheelsArticleItem"
import { useParticularContext, WheelsListType } from "../PureList/ContextProviders/Particular/ParticularContext"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"

export function WheelsList() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [ArticleListConfigurationProvider],
        [ListOptionsProvider, { isEnabled: true }],
        [EmptyListParamsProvider],
        [EmptyFiltersProvider],
        [WheelsListProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [ArticleListActionsProvider],
    ]

    return (
        <ContextComposer components={components}>
            <WheelsListComponent />
        </ContextComposer>
    )
}

function WheelsListComponent() {
    const wheelsArticlesUnfiltered = useWheelsListContext((context) => context.articles)
    const wheelsArticles = useWheelsListContext((context) => context.displayArticles)
    const isLoading = useWheelsListContext((wheelsListContext) => wheelsListContext.isLoading)
    const isSuccess = useWheelsListContext((wheelsListContext) => wheelsListContext.isSuccess)
    const isFailed = useWheelsListContext((wheelsListContext) => wheelsListContext.isFailed)
    const isStalling = useWheelsListContext((wheelsListContext) => wheelsListContext.isStalling)
    const sorting = useWheelsListContext((context) => context.sorting)
    const contextProvider = useParticularContext<WheelsListType>()
    const { maximumSortableArticleCount } = Morpheus.getParams<WheelsBundleParams>("tyres")

    const { request, headerTitle, headerTitleContent, selectedAvailability } = contextProvider ?? {}

    if (!request) {
        return null
    }
    if (
        (isLoading && request.pageIndex === 1) ||
        (!isFailed && ((!wheelsArticles?.length && !selectedAvailability) || (!wheelsArticlesUnfiltered?.length && !!selectedAvailability))) ||
        isStalling
    ) {
        return <NextPageLoader skeletonCategory skeletonArticleCount={5} />
    }

    if ((isSuccess && !wheelsArticles?.length) || isFailed) {
        return <TyresError />
    }

    return (
        <Stack flex={1} position="relative">
            <ArticleGroupHeader
                title={headerTitle || ""}
                titleContent={headerTitleContent}
                groupArticlesCount={wheelsArticles?.length}
                currentSorting={sorting?.selectedSorting}
                onChangeSorting={maximumSortableArticleCount ? sorting?.handleSorting : undefined}
                maximumSortableArticleCount={maximumSortableArticleCount}
            />
            <Stack padding={0.5}>{wheelsArticles?.map((article) => <WheelsArticleItem key={article.internalId} article={article} />)}</Stack>
        </Stack>
    )
}
