import { PropsWithChildren } from "react"
import { useArticlesContext } from "../../../ListV2/ContextProvider"
import { BasketQuantitiesContext, useBasketQuantities } from "../../../ListV2/ContextProvider/BasketQuantities"

export function BasketQuantitiesProvider(props: PropsWithChildren<{ isEnabled: boolean; compilationId: string }>) {
    const { articles } = useArticlesContext()
    const articleBasketQuantities = useBasketQuantities(articles, props.isEnabled, props.compilationId)

    return <BasketQuantitiesContext.Provider value={articleBasketQuantities}>{props.children}</BasketQuantitiesContext.Provider>
}
