import { filledInputClasses, styled, TextField, Typography } from "@tm/components"
import tinycolor from "tinycolor2"

export const ColumnHeader = styled("div")({
    cursor: "pointer",
    display: "flex",
})

export const ColumnHeaderActive = styled(ColumnHeader)(({ theme }) => ({
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
}))

export const textFieldStyles = {
    marginTop: 0,
    color: "inherit",
    [`.${filledInputClasses.root}`]: {
        height: "100%",
        color: "inherit",
    },
    [`.${filledInputClasses.input}`]: {
        fontSize: "1rem",
    },
}

export const StyledTextField = styled(TextField)(() => ({
    ...textFieldStyles,
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
    flex: 1,
    marginBottom: "0.5em",
    display: "block",
    textTransform: "uppercase",
    color: tinycolor(theme.colors?.dark).lighten(32).toHexString(),
    opacity: theme.opacity?.primary,
    fontSize: "0.7rem",
}))
