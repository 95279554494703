import { useMemo } from "react"
import { Box, Loader } from "@tm/components"
import { Voucher } from "../../../../data/model"
import StandardVoucherDetailsHeader from "./StandardVoucherDetailsHeader"
import Totals from "../../../_shared/Totals"
import StandardVoucherItemsTable from "../../../_shared/voucher-items-table/StandardVoucherItemsTable"
import TexkatVoucherItemsTable from "../../../_shared/voucher-items-table/TexkatVoucherItemsTable"
import { CisDisplayMode } from "../../../../business/model"
import { useOrderDetails } from "../../../../data/hooks/useOrderDetails"
import { NoResultHint } from "../../../_shared/NoResultHint"
import { ErrorMessage } from "../../../_shared/ErrorMessage"
import { ColumnStack } from "../../../_shared/StyledComponents"

type Props = {
    endOfList: boolean
    selectedVoucherId: string
    voucherTypeId?: number
    vouchers: Voucher[]
    cisDisplayMode?: CisDisplayMode
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function StandardVoucherDetails({ endOfList, selectedVoucherId, voucherTypeId, vouchers, cisDisplayMode, onOpenNewVoucher }: Props) {
    const selectedVoucher = useMemo(() => {
        return vouchers.find((voucher) => voucher.voucherId === selectedVoucherId)
    }, [vouchers, selectedVoucherId])

    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && { voucherId: selectedVoucher.voucherId, voucherTypeId }
    )

    const { orderValueNet, orderValueTotal, currencyCode, voucherItems } = orderDetails || {}

    let content = null
    if (selectedVoucher) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (voucherItems?.length && orderDetails) {
            content = (
                <ColumnStack>
                    <StandardVoucherDetailsHeader voucher={selectedVoucher} orderDetails={orderDetails} onOpenNewVoucher={onOpenNewVoucher} />
                    {cisDisplayMode === "texkat" ? (
                        <TexkatVoucherItemsTable voucherItems={voucherItems} />
                    ) : (
                        <StandardVoucherItemsTable voucherItems={voucherItems} voucherTypeId={voucherTypeId} />
                    )}
                    <Totals totalGross={orderValueTotal} totalNet={orderValueNet} currencyCode={currencyCode} />
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    } else if (endOfList) {
        content = <NoResultHint />
    }

    return <Box flex={1}>{content}</Box>
}
