import { ChangeEvent, useState } from "react"

export function useTextFieldSubmit<T>(initialValue: T, onSubmitCallback: (submittedValue: T) => void) {
    const [inputValue, setInputValue] = useState<T>(initialValue)
    const [submittedValue, setSubmittedValue] = useState<T>()

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as unknown as T
        setInputValue(value)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSubmit()
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        handleSubmit()
    }

    const handleSubmit = () => {
        if (inputValue !== submittedValue) {
            setSubmittedValue(inputValue)
            onSubmitCallback(inputValue)
        }
    }

    return {
        inputValue,
        inputActions: {
            onChange: handleChange,
            onKeyDown: handleKeyDown,
            onBlur: handleBlur,
        },
        handleSubmit,
    }
}
