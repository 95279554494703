import { Button } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { batch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { FC } from "react"
import { MainState } from "../../main"
import { Actions } from "../business"

type Props = {}

const selector = createSelector(
    (s: MainState) => ({
        showRimSettings: s.wheelsList.base.showRimSettings,
        colors: s.wheelsList.configurator.colors,
        show3D: s.wheelsList.configurator.show3D,
    }),
    (x) => x
)

const LeftActions: FC<Props> = ({}) => {
    const actions = useActions(Actions, "changeConfiguratorDisplay", "changeRimInfoAndSettingVisibility", "changeDialogDisplay")
    const { colors, showRimSettings, show3D } = useSelector(selector)

    const handleDialog = () => {
        batch(() => {
            actions.changeRimInfoAndSettingVisibility(false, false)
            actions.changeConfiguratorDisplay()
            actions.changeDialogDisplay(true)
        })
    }

    const handleOnClickButton = () => {
        actions.changeRimInfoAndSettingVisibility(false, !showRimSettings)
    }

    return (
        <div className="action-icons">
            <Button isActive={showRimSettings} disabled={!colors || !colors?.length} onClick={handleOnClickButton} icon="pencil" />
            <Button icon="no-results" onClick={handleDialog} />
        </div>
    )
}

export default LeftActions
