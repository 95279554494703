import { Box, Card, CardActionArea, CardContent, Tooltip, styled } from "@tm/components"
import { UnitShortV2Dto } from "../../../models"
import OverflowTypography from "../../_shared/OverflowTypography"
import UnitDetails from "../../_shared/UnitDetails"

type GraphicSelectionCardProps = {
    unit: UnitShortV2Dto
    onCardClick: (unit: UnitShortV2Dto) => void
    hasTooltip?: boolean
}

export default function GraphicSelectionCard({ unit, onCardClick, hasTooltip }: GraphicSelectionCardProps) {
    function getThumbnail(imageNames: string[]) {
        return imageNames.first()?.replace("%size%", "250")
    }

    function handleCardClick() {
        onCardClick?.(unit)
    }

    const tooltipTitle = unit.attributes?.map((attribute) => {
        if (attribute.code === "note") {
            return attribute.values
        }
        return null
    })

    return (
        <Card sx={{ width: "300px", minHeight: "300px" }}>
            <Tooltip variant="light" title={hasTooltip && tooltipTitle ? tooltipTitle.join("") : ""}>
                <CardActionArea sx={{ height: "100%" }} onClick={handleCardClick}>
                    <StyledCardContent>
                        <Box sx={{ width: "100%" }}>
                            <OverflowTypography align="center" title={unit.name} content={unit.name} />
                        </Box>
                        <StyledImg src={getThumbnail(unit.imageNames)} alt={unit.name} />
                        <UnitDetails code={unit.code} attributes={unit.attributes} />
                    </StyledCardContent>
                </CardActionArea>
            </Tooltip>
        </Card>
    )
}

const StyledCardContent = styled(CardContent)({
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    height: "100%",
})

const StyledImg = styled("img")({
    objectFit: "cover",
    margin: "auto auto",
})
