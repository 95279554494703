import { Box, DatePicker, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Item, OrderOptions } from "@tm/models"
import { useEffect, useState } from "react"
import { isSameDay } from "@bundles/basket/helpers"
import { isValidDate } from "@tm/utils"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onUpdateDeliveryDate(deliveryDate?: Date, selectedOptionsItem?: Item): void
}

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setUTCHours(0, 0, 0, 0) // so dates selected in the datepicker can be properly compared

export default function DeliveryDateOptionsComponent(props: Props) {
    const { translateText, translate, language } = useLocalization()
    const {
        options: { deliveryDate, selectedOptionsItem },
        disableFields,
        onUpdateDeliveryDate,
    } = props

    const [selectedDate, setSelectedDate] = useState<Date | null>(deliveryDate ?? null)

    useEffect(() => {
        if (isValidDate(selectedDate) && !isSameDay(selectedDate, deliveryDate ?? null)) {
            onUpdateDeliveryDate(selectedDate ?? undefined, selectedOptionsItem)
        }

        // Do not add the other dependencies in the dependencies array as it is causing an infinite loop of requests
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="delivery-time" />
                <Typography variant="h4">{translate(13834)}</Typography>
            </BoxWithAlignItems>
            <BoxWithColumnPaddingRight pl="35px" pt="4px">
                <Box mb={0.5}>
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        disableHighlightToday
                        onChange={setSelectedDate}
                        label={translateText(98)}
                        value={selectedDate}
                        disabled={disableFields}
                        minDate={tomorrow}
                        errorMessage={translateText(13936)}
                    />
                </Box>
            </BoxWithColumnPaddingRight>
        </BoxWithColumnPaddingRight>
    )
}
