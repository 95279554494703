import { cloneDeep } from "lodash"
import { Article } from "@tm/models"
import { NavigationState } from "./model"
import { BundleActionTypes, BundleActions } from "../../../business"
import { WheelSelectionSteps } from "../../../data/enums"

export type ComponentActionType = BundleActionTypes

const DEFAULT_STATE: NavigationState = {
    activeStep: WheelSelectionSteps.NONE,
    selectedTabs: {},
}

export function reduce(state = { ...DEFAULT_STATE }, action: ComponentActionType): NavigationState {
    switch (action.type) {
        case "CHANGE_STEP": {
            const { params, disableNextSteps, quantity, enablePreviousStep } = action.payload
            let { step } = action.payload
            const { selectedTabs, isRDKSAvailable, isTyresAvailable } = state

            if (step) {
                if ((step == WheelSelectionSteps.RDKSLIST || step == WheelSelectionSteps.RDKSDETAILS) && !isRDKSAvailable) {
                    step = WheelSelectionSteps.TIRESLIST
                }

                if ((step == WheelSelectionSteps.TIRESLIST || step == WheelSelectionSteps.TIREDETAILS) && !isTyresAvailable) {
                    step = WheelSelectionSteps.OVERVIEW
                }
            }

            const newSelectedTabs = cloneDeep(selectedTabs)

            if (disableNextSteps) {
                Object.keys(newSelectedTabs).forEach((tab, index) => {
                    if (index > step) {
                        newSelectedTabs[tab].wasSelected = false
                    }
                })
            }

            if (enablePreviousStep) {
                for (let i = 0; i < step; i++) {
                    if (newSelectedTabs[i]) {
                        newSelectedTabs[i].wasSelected = true
                    } else {
                        newSelectedTabs[i] = {
                            wasSelected: true,
                        }
                    }
                }
            }

            return {
                ...state,
                activeStep: step,
                selectedTabs: {
                    ...newSelectedTabs,
                    [step]: {
                        wasSelected: true,
                        quantity: quantity ?? newSelectedTabs?.[step]?.quantity,
                        params: {
                            ...newSelectedTabs?.[step]?.params,
                            ...params,
                        },
                    },
                },
            }
        }
        case "SEND_SENSOR_ITEMS_RESPONSE": {
            return {
                ...state,
                isRDKSAvailable: !!action.payload.length,
            }
        }
        case "INIT_WHEELS_LOGIC": {
            return {
                ...state,
                isTyresAvailable: action.payload.isTyresAvailable,
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            const { quantity } = action.payload
            return {
                ...state,
                selectedTabs: {
                    ...state.selectedTabs,
                    [state.activeStep]: {
                        ...state.selectedTabs[state.activeStep],
                        quantity,
                    },
                },
            }
        }
        default:
            return state
    }
}

function sendTireToOverview(article: Article): ComponentActionType {
    return {
        type: "SEND_TIRE_TO_OVERVIEW",
        payload: article,
    }
}

function sendSensorToOverview(article: Article): ComponentActionType {
    return {
        type: "SEND_SENSOR_TO_OVERVIEW",
        payload: article,
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    sendTireToOverview,
    sendSensorToOverview,
}
