import { VoucherType } from "@tm/models"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useRecoilState } from "recoil"
import { useMemo } from "react"
import { compareDesc } from "date-fns"
import { DateUnit } from "@tm/utils"
import * as Data from ".."
import { CostEstimationVoucher } from "../model"
import { voucherListFilterAtom } from "../states/listFilters"
import { useShowOnlyUserVouchers } from "./useShowOnlyUserVouchers"

const COST_ESTIMATION_KEY = "vouchers__useVoucherList__cost-estimations"
const ORDERS_KEY = "vouchers__useVoucherList__orders"
const RETURNS_KEY = "vouchers__useVoucherList__returns"

export function useVoucherList(
    voucherType: VoucherType,
    workTaskId?: string,
    customerId?: string,
    pageIndex?: number,
    pageSize?: number,
    enabled?: boolean,
    searchQuery?: string
) {
    const { showOnlyUserVouchers: filterByUser, showOnlyUserVouchersLoaded } = useShowOnlyUserVouchers()
    const [listFilters] = useRecoilState(voucherListFilterAtom(workTaskId))
    const vehicleId = listFilters.selectedVehicleId
    const enabledCall = enabled !== false && showOnlyUserVouchersLoaded

    const { data: costEstimations, isLoading: costEstimationsLoading } = useQuery(
        [COST_ESTIMATION_KEY, customerId, vehicleId, searchQuery, pageIndex, pageSize, filterByUser],
        () => {
            if (searchQuery) {
                return Data.showCostEstimationsByArticle({ searchStr: searchQuery, customerId, vehicleId, pageIndex, pageSize, filterByUser })
            }
            if (customerId && vehicleId) {
                return Data.showCostEstimationsByVehicles({ vehicleIds: [vehicleId], pageIndex, pageSize, filterByUser })
            }
            if (customerId) {
                return Data.showCostEstimationsByCustomer({ customerId, pageIndex, pageSize, filterByUser })
            }

            return Data.showAllCostEstimations({ pageIndex, pageSize, filterByUser })
        },
        {
            enabled: enabledCall && voucherType === VoucherType.CostEstimation,
            staleTime: 10 * 1000, // 10 seconds
        }
    )

    const { data: orders, isLoading: ordersLoading } = useQuery(
        [ORDERS_KEY, customerId, vehicleId, searchQuery, pageIndex, pageSize, filterByUser],
        () => {
            if (searchQuery) {
                return Data.showOrdersByArticle({ searchStr: searchQuery, customerId, vehicleId, pageIndex, pageSize, filterByUser })
            }
            if (customerId && vehicleId) {
                return Data.showOrdersByVehicles({ vehicleIds: [vehicleId], pageIndex, pageSize, filterByUser })
            }
            if (customerId) {
                return Data.showOrdersByCustomer({ customerId, pageIndex, pageSize, filterByUser })
            }
            return Data.showAllOrders({ pageIndex, pageSize, filterByUser })
        },
        {
            enabled: enabledCall && voucherType === VoucherType.Order,
            staleTime: 10 * 1000, // 10 seconds
        }
    )

    const { data: returns, isLoading: returnsLoading } = useQuery(
        [RETURNS_KEY, customerId, vehicleId, searchQuery, pageIndex, pageSize, filterByUser],
        () => {
            if (searchQuery) {
                return Data.showReturnsByArticle({ searchStr: searchQuery, customerId, vehicleId, pageIndex, pageSize, filterByUser })
            }

            return Data.showAllReturns({ pageIndex, pageSize, filterByUser })
        },
        {
            enabled: enabledCall && voucherType === VoucherType.Return,
            staleTime: 10 * 1000, // 10 seconds
        }
    )

    const filteredCostEstimations = useMemo(() => {
        return costEstimations
            ?.filter(
                (x) =>
                    (x.voucherCreationDate.isAfter(listFilters.startDate, DateUnit.Day) ||
                        x.voucherCreationDate.isSame(listFilters.startDate, DateUnit.Day)) &&
                    (x.voucherCreationDate.isBefore(listFilters.endDate, DateUnit.Day) ||
                        x.voucherCreationDate.isSame(listFilters.endDate, DateUnit.Day))
            )
            .sort((a, b) => compareDesc(a.voucherCreationDate, b.voucherCreationDate))
    }, [listFilters, costEstimations])

    const filteredOrders = useMemo(() => {
        return orders
            ?.filter(
                (x) =>
                    (x.orderDate.isAfter(listFilters.startDate, DateUnit.Day) || x.orderDate.isSame(listFilters.startDate, DateUnit.Day)) &&
                    (x.orderDate.isBefore(listFilters.endDate, DateUnit.Day) || x.orderDate.isSame(listFilters.endDate, DateUnit.Day))
            )
            .orderBy((x) => x.orderDate, true)
    }, [listFilters, orders])

    const filteredReturns = useMemo(() => {
        return returns
            ?.filter(
                (x) =>
                    (x.returnDate.isAfter(listFilters.startDate, DateUnit.Day) || x.returnDate.isSame(listFilters.startDate, DateUnit.Day)) &&
                    (x.returnDate.isBefore(listFilters.endDate, DateUnit.Day) || x.returnDate.isSame(listFilters.endDate, DateUnit.Day))
            )
            .orderBy((x) => x.returnDate, true)
    }, [listFilters, returns])

    return {
        costEstimations: filteredCostEstimations,
        costEstimationsLoading,
        orders: filteredOrders,
        ordersLoading,
        returns: filteredReturns,
        returnsLoading,
    }
}

export function useDeleteCostEstimation() {
    const queryClient = useQueryClient()
    const mutation = useMutation(Data.deleteCostEstimations, {
        onSuccess: (_, costEstimationIds) => {
            queryClient.setQueriesData<CostEstimationVoucher[] | undefined>(COST_ESTIMATION_KEY, (prev) =>
                prev?.filter((voucher) => !costEstimationIds.includes(voucher.id))
            )
        },
    })

    return { deleteCostEstimations: mutation.mutateAsync }
}
