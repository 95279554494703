import { ETyresSeason, TyreFilter, TyresSeason, VehicleType } from "@tm/models"
import { SelectedFilters } from "../../components/summary/business"
import { getCurrentSeason } from "./getCurrentSeason"
import { mapVehicleTypeToTyresCarType } from "./mapVehicleTypeToTyresCarType"

export function getProductGroupsFromCarTypeAndSeason(vehicleType: VehicleType, carTypeAndSeason: TyreFilter[]) {
    const carType = mapVehicleTypeToTyresCarType(vehicleType)

    const carTypes = carTypeAndSeason.filter((x) => x.value.split(",").first() === carType.toString())

    return carTypes.map((x) => +x.query)
}

export function getProductGroupFromCarTypeAndSeason(vehicleType: VehicleType, carTypeAndSeason: TyreFilter[], selectedFilters: SelectedFilters) {
    const seasons = selectedFilters.season?.length ? selectedFilters.season?.map((season) => season.query as ETyresSeason) : [getCurrentSeason()]

    const carType = mapVehicleTypeToTyresCarType(vehicleType)

    let carTypes = carTypeAndSeason.filter((x) => x.value.split(",").first() === carType.toString())

    if (carTypes.length > 1) {
        carTypes = carTypes?.filter((x) => {
            const season = x.value?.split(",")?.[1]
            return seasons?.some((s) => s === season)
        })
    }

    return +(carTypes?.first()?.query || 0)
}
