import { ChangeEvent, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Box, Button, Loader, TextField, Typography, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { SystemType } from "@tm/models"
import { encodeUniqueId, uniqueId } from "@tm/utils"
import { Repositories } from "../../data"
import { getWorkTaskId, isNumber, isValidYQVin } from "../../data/helpers"
import { graphicActions, mainActions, treeNavigationActions, useEdsStore, vehicleActions } from "../../data/state"
import { EdsErrorMessageIds, NavigationSteps } from "../../models"

export default function Vehicle() {
    const { translateText } = useLocalization()
    const history = useHistory()
    const systemType = useUser()?.userContext?.system.systemType

    const [vin, setVin] = useState("")
    const savedVin = useEdsStore((state) => state.vehicle.data.vin)
    const vehicle = useEdsStore((state) => state.vehicle.data.yqVehicle)
    const vinSearchError = useEdsStore((state) => state.vehicle.error)
    const vinSearchLoading = useEdsStore((state) => state.vehicle.isLoading)
    const vehicleLocked = useEdsStore((state) => state.vehicle.isLocked)

    useEffect(() => {
        if (vin !== savedVin) {
            setVin(savedVin)
            if (!vehicle) {
                searchVehicle(savedVin)
            }
        }
        // NOTE: Do not add VIN as dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedVin, vehicle])

    function handleVinChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        if (event?.target?.value || event?.target?.value === "") {
            setVin(event.target.value.toUpperCase())
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter") {
            searchVehicle(vin)
        }
    }

    function searchVehicle(vinToSearch: string) {
        if (isValidYQVin(vinToSearch) && !vinSearchLoading) {
            vehicleActions.setLoading(true)
            vehicleActions.setVin(vinToSearch)
            treeNavigationActions.setSelectedNode(undefined)
            treeNavigationActions.setTreeNavigation(undefined)
            graphicActions.setSelectedUnit(undefined)

            Repositories.vinSearch(vinToSearch)
                .then((res) => {
                    if (res?.error?.message) {
                        vehicleActions.setError(res?.error?.message)
                        return
                    }

                    const yqVehicle = res?.data?.vehicles?.[0]

                    if (!yqVehicle) {
                        vehicleActions.setError(EdsErrorMessageIds.VehicleNotFound)
                        return
                    }

                    yqVehicle.manufacturerId = +(res?.manufacturerId ?? 0)

                    vehicleActions.setYQVehicle(yqVehicle)
                    mainActions.changeStep(NavigationSteps.GraphicSearch)
                })
                .catch(() => {
                    vehicleActions.setError(EdsErrorMessageIds.VehicleNotFound)
                })
        }
    }

    function handleOpenNewWorkTaskWithVin() {
        history.push(`/${encodeUniqueId(uniqueId())}?vin=${vin}`)
    }

    function handleNavigateToStart() {
        const worktaskId = getWorkTaskId()
        if (worktaskId) {
            history.push(`/${encodeUniqueId(worktaskId)}`)
        }
    }

    function handleOpenNewWorktaskWithEds() {
        history.push(`/${encodeUniqueId(uniqueId())}/eds`)
    }

    const renderError = () => {
        // eslint-disable-next-line eqeqeq
        const vehicleNotFoundInEds = vehicleLocked && vinSearchError == EdsErrorMessageIds.VehicleNotFound

        if (vinSearchError) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
                        <Icon name="info-filled" skin="danger" />
                        <Typography variant="h4">{isNumber(vinSearchError) ? translateText(vinSearchError) : vinSearchError}</Typography>
                    </Box>
                    {systemType === SystemType.Next && (
                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5em", alignSelf: "flex-end" }}>
                            <Typography variant="body1">{translateText(13398)}</Typography>
                            <Button onClick={vehicleNotFoundInEds ? handleNavigateToStart : handleOpenNewWorkTaskWithVin}>
                                <Icon name="car" />
                                <Typography>{translateText(13399)}</Typography>
                            </Button>
                        </Box>
                    )}
                </Box>
            )
        }
    }

    return (
        <StyledBox loading={vinSearchLoading}>
            {vinSearchLoading && <StyledLoader />}
            <Box sx={{ display: "flex", gap: "2em", paddingTop: "1em" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <StyledVinField
                        disabled={vehicleLocked}
                        onKeyDown={handleKeyDown}
                        hasValue={!!vin}
                        size="large"
                        error={(vin !== "" && !isValidYQVin(vin)) || !!vinSearchError}
                        variant="outlined"
                        id="outlined-basic"
                        inputProps={{ maxLength: 17 }}
                        inputCounter
                        label={translateText(101)}
                        value={vin}
                        onChange={handleVinChange}
                        helperText={translateText(EdsErrorMessageIds.VehicleNotFound)}
                    />
                    <Button size="medium" disabled={vinSearchLoading || vehicleLocked} onClick={() => searchVehicle(vin)}>
                        <Icon name="search" />
                    </Button>
                </Box>

                {vinSearchError && renderError()}

                {vehicleLocked && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
                            <Icon name="info-filled" skin="warning" />
                            <Typography variant="h4">{translateText(13420)}</Typography>
                        </Box>
                        {systemType === SystemType.Next && (
                            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5em", alignSelf: "flex-end" }}>
                                <Typography variant="body1">{translateText(13398)}</Typography>
                                <Button onClick={handleOpenNewWorktaskWithEds}>
                                    <Icon name="car" />
                                    <Typography>{translateText(13421)}</Typography>
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </StyledBox>
    )
}

const StyledVinField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "hasValue",
})<{ hasValue?: boolean }>(({ hasValue }) => ({
    ...(!hasValue && {
        "& .MuiInputLabel-root.Mui-focused": {
            transform: "translate(14px, -9px) scale(0.75)",
        },
        "& .MuiInputLabel-root": {
            transform: "translate(14px, 8px) scale(1)",
        },
        "& .MuiInputLabel-root.MuiFormLabel-filled": {
            transform: "translate(14px, -9px) scale(0.75)",
        },
    }),
    "& .MuiFormHelperText-root": {
        top: "50%",
        bottom: "unset !important",
    },
}))

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "loading",
})<{ loading?: boolean }>(({ theme, loading }) => ({
    ...(loading && {
        position: "relative",
        ":not(.loader)": {
            opacity: theme.opacity?.disabled,
        },
        pointerEvents: "none",
    }),
}))

const StyledLoader = styled(Loader)({
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
})
