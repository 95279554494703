import { useAvailabilityStatus, useUser } from "@tm/context-distribution"
import { Scrollbar } from "@tm/controls"
import { ConfigParams, SystemType, TyreArticle } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { classes, createQueryString, parseQueryString, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { useSelector } from "react-redux"
import { FC, memo, useEffect, useState } from "react"
import { Box, Loader, styled, Typography } from "@tm/components"
import { useParams } from "react-router"
import { TyresError } from "."
import { getBundleParams } from "../../../utils"
import { addOrRemoveItem } from "../../../data/helpers"
import { IAttributesItem, MatchParams } from "../../../data/models"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { getAttributeString } from "../business/helpers"
import { filterList } from "../business/helpers/filterList"
import { ArticleItem } from "../../_shared/articleItem"

type Props = {
    repairTimesRoute: string
    className?: string
    detailsModalRoute?: string
}

const TyresListComponent: FC<Props> = ({ repairTimesRoute, className, detailsModalRoute }) => {
    const matchParams = useParams<MatchParams>()
    let scrollTop = 0

    const { articles, vehicle, availability } = useSelector((s: MainState) => ({
        articles: s.list.articles,
        vehicle: s.manager.vehicle,
        availability: s.list.selectedFilters.availability,
    }))

    const user = useUser()

    const [selectedAttributes, setSelectedAttr] = useState<string[]>([])

    const { error, loading, loadingNextItems, loadingAutoItems, data, nextArticlesError, count, pageIndex } = articles
    const actions = useActions(MainActions, "loadNextTyresList", "changeQuantity", "changeStep")

    const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
        const el = e.target as HTMLElement
        if (el.scrollHeight - el.scrollTop <= el.clientHeight + 100 && el.scrollTop > scrollTop) {
            actions.loadNextTyresList()
            scrollTop = el.scrollTop
        }
    }

    const handleSelectedAttribute = (_: TyreArticle, item: IAttributesItem) => {
        setSelectedAttr(addOrRemoveItem(selectedAttributes, getAttributeString(item)))
    }

    const handleArticleDetails = (article: TyreArticle, path?: string) => {
        const params = {
            productGroupId: article.productGroup.id,
            supplierId: article.supplier.id,
            supplierArticleNo: article.supplierArticleNo,
            quantity: article.quantity,
            partsDetailsSubPage: path,
        }

        const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()

        const queryParams = parseQueryString(location.search)
        queryParams.initialQuantity = params?.quantity

        if (articleDetailsInModal && user?.userContext?.system.systemType === SystemType.Next && detailsModalRoute) {
            Morpheus.showView("1", renderRoute(detailsModalRoute, { ...matchParams, ...params }) + createQueryString(queryParams))
        } else {
            actions.changeStep("details", params)
        }
    }

    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()
    const items = filterList(data, selectedAttributes, availability, availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary)

    const { maxAutoRequest, minAvailableItems, pageSize, isFiltersOnRightSide } = getBundleParams()

    useEffect(() => {
        if (
            availability &&
            items.length < minAvailableItems &&
            pageIndex < count / pageSize &&
            !nextArticlesError &&
            articles.autoNextCount < maxAutoRequest
        ) {
            actions.loadNextTyresList(true)
        }
    }, [availability, items])

    if (error) {
        return (
            <Box className={className}>
                <TyresError />
            </Box>
        )
    }

    const renderRow = (article: TyreArticle) => {
        return (
            <ArticleItem
                key={article.id}
                onArticleAttributeSelect={handleSelectedAttribute}
                selectedArticleAttributes={selectedAttributes}
                repairTimesRoute={repairTimesRoute}
                hidden={loadingAutoItems && !article.erpInformation}
                vehicle={vehicle}
                onArticleDetailsClick={handleArticleDetails}
                onChangeStandaloneQuantity={actions.changeQuantity}
                item={article}
            />
        )
    }

    return (
        <Box className={className}>
            <Scrollbar onScroll={handleScroll}>
                <Box className="tk-parts">
                    {!loading && !loadingAutoItems && !items.length && <TyresError />}
                    {loading && (
                        <Box className="article-list__panel article-list__status">
                            <Loader />
                        </Box>
                    )}
                    <StyledArticleList isFiltersOnRightSide={isFiltersOnRightSide} className="list article-list">
                        {items.map(renderRow)}
                    </StyledArticleList>
                </Box>
                {loadingAutoItems && articles.autoNextCount !== 0 && (
                    <StyledLoadWrapper className={classes("article-list__panel", "article-list__status")}>
                        <Typography>Try to load more data</Typography>
                        <Loader />
                    </StyledLoadWrapper>
                )}

                {loadingNextItems && (
                    <Box className="article-list__panel article-list__status">
                        <Loader />
                    </Box>
                )}
            </Scrollbar>
        </Box>
    )
}

const StyledLoadWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "5em",
})

const StyledArticleList = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isFiltersOnRightSide",
})<{ isFiltersOnRightSide?: boolean }>(({ isFiltersOnRightSide }) => ({
    padding: isFiltersOnRightSide ? "7px 10px 0 0 !important" : undefined,
    "& .article__cell--description": {
        marginRight: "1em",
        minWidth: "20em",
        flex: "1",
    },
    "& .article__cell--icon": {
        flex: "1",
    },
    "& .article__cell--erp-information .erp-info__prices .price__group .price": {
        display: "block",
        textAlign: "right",
    },
    "& .article__cell--erp-information .erp-info__prices .price__group .price .price__icon": {
        marginBottom: "-0.25em",
    },
}))

export default memo(TyresListComponent)
