import { useEffect } from "react"
import { ArticlesContext, ArticlesProviderBaseProps, useArticlesContext } from "../../../ContextProvider/Articles"
import { OffersArticleData } from "../../../models"
import { useArticles } from "./useArticles"
import { useOffersListParamsContext } from "../ListParams"
import { publishModuleInfo, useAfterPaintEffect } from "../../../helpers"
import { useGetAdvertisements } from "./useGetAdvertisements"

export function OffersArticlesProvider({ setHasRendered, children }: ArticlesProviderBaseProps) {
    const params = useOffersListParamsContext()

    const {
        startParams: { advertisementCategory, tabTitle },
    } = params

    const { advertisements, isLoaded } = useGetAdvertisements(advertisementCategory?.id)

    const articlesData = useArticles(params, isLoaded, advertisements)

    useEffect(() => {
        if (advertisementCategory?.id) {
            publishModuleInfo(tabTitle ?? "{{1276}}", advertisementCategory.description || "")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertisementCategory?.id])

    useAfterPaintEffect(() => {
        if (articlesData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articlesData.requestStatus])

    return <ArticlesContext.Provider value={articlesData}>{children}</ArticlesContext.Provider>
}

export function useOffersArticlesContext<TContext extends OffersArticleData>(): TContext
export function useOffersArticlesContext<TContext extends OffersArticleData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useOffersArticlesContext<TContext extends OffersArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useArticlesContext(selector as never)
}
