import { RangeSlider, Tooltip } from "@tm/controls"
import { TyreFilter } from "@tm/models"
import { equals } from "@tm/utils"
import { Box, Button, Icon, styled, Theme, useTheme } from "@tm/components"
import { MinimizedRangeSlider } from "./minimizedRangeSlider"

type Props = {
    title: string
    icon: string
    items: TyreFilter[]
    minimized?: boolean
    selectedItem?: TyreFilter
    onChange(value: TyreFilter): void
    onReset(): void
    isV2?: boolean
}

export function CustomRangeSlider({ title, icon, items, selectedItem, minimized, onChange, onReset, isV2 }: Props) {
    const theme = useTheme()

    const handleRangeSliderChange = (idx: number) => {
        onChange(items[idx])
    }

    const renderButton = (item: TyreFilter, index: number) => {
        return (
            <StyledButtonNumbers theme={theme} colorValue={item.value} size="small" key={index} onClick={() => handleRangeSliderChange(index)}>
                {item.value}
            </StyledButtonNumbers>
        )
    }

    if (!items.length) {
        return null
    }

    const selectedIndex = selectedItem ? items.findIndex((item) => item.query === selectedItem.query) : 0

    return (
        <StyledSliderWrapper sx={isV2 ? { width: "100%" } : undefined}>
            <Tooltip content={title}>
                <StyledIcon name={icon} />
            </Tooltip>
            <StyledSliderContent>
                {minimized && <MinimizedRangeSlider onChange={handleRangeSliderChange} items={items} selectedItempos={selectedIndex} />}
                {!minimized && (
                    <>
                        <RangeSlider onChange={handleRangeSliderChange} value={selectedIndex} max={items.length - 1} min={0} />
                        <StyledSliderButtons>{items.map(renderButton)}</StyledSliderButtons>
                    </>
                )}
            </StyledSliderContent>
            <StyledResetButton variant="text" size="small" startIcon={<Icon name="synchronize" />} disabled={!selectedItem} onClick={onReset} />
        </StyledSliderWrapper>
    )
}

const getColorByValue = (value: string, theme?: Theme) => {
    switch (value) {
        case "A":
            return "#2d7327"
        case "B":
            return "#55d545"
        case "C":
            return "#b5e941"
        case "D":
            return "#f6ed4e"
        case "E":
            return "#f6a328"
        case "F":
            return "#dd901f"
        case "G":
            return "#e12816"
        default: // Default color
            return theme?.palette?.primary?.main
    }
}

const StyledResetButton = styled(Button)({
    marginLeft: "1em",
    cursor: "pointer",
})

const StyledSliderButtons = styled(Box)({
    marginTop: "0.5em",
    display: "flex",
    justifyContent: "space-between",
})

const StyledSliderWrapper = styled(Box)({
    marginTop: "0.5em",
    display: "flex",
    alignItems: "center",
})

const StyledIcon = styled(Icon)({
    marginRight: "1em",
})

const StyledSliderContent = styled(Box)({
    flex: "1",
    flexDirection: "column",
})

const StyledButtonNumbers = styled(Button)<{ colorValue?: string; theme?: Theme }>(({ colorValue, theme }) => ({
    "&&": {
        color: "#fff",
        textShadow: "0 0 3px #202020",
        backgroundColor: `${getColorByValue(colorValue ?? "", theme)} !important`,
    },
    "&:hover": {
        textShadow: "none",
    },
}))
