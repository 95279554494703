import { useMemo } from "react"
import { SxProps, Theme, styled } from "@tm/components"
import { CommunicationChannel, getComponentFromBundle } from "@tm/utils"

import { WidgetBundle as CustomerWidgetBundle } from "@bundles/crm"
import { WidgetBundle as VehicleWidgetBundle } from "@bundles/vehicle"
import { WidgetBundle as VoucherWidgetBundle } from "@bundles/vouchers"

import { MultiWidget } from "../../MultiWidget"
import TabControl, { ItemDataConfig } from "../../tab-control/component"
import { Empty } from "./Empty"

type Props = {
    items: Record<string, ItemDataConfig>
    channel: CommunicationChannel
    sx?: SxProps<Theme>
    hideTabs?: boolean
}

export function MultiWidgets({ items, channel, sx, hideTabs }: Props) {
    const VoucherWidget = useMemo(() => (items.VOUCHERS ? getComponentFromBundle(VoucherWidgetBundle, channel) : Empty), [items.VOUCHERS, channel])
    const CustomerWidget = useMemo(() => (items.CUSTOMER ? getComponentFromBundle(CustomerWidgetBundle, channel) : Empty), [items.CUSTOMER, channel])
    const VehicleWidget = useMemo(() => (items.VEHICLE ? getComponentFromBundle(VehicleWidgetBundle, channel) : Empty), [items.VEHICLE, channel])

    return (
        <StyledMultiWidget sx={sx} key="multiWidget" className="multiwidget">
            {!hideTabs && <TabControl items={items} layout="round-border-bottom" />}
            <VehicleWidget className="widget widget--car widget--h2" showComponentKey="VEHICLE" hideCover indicator={false} />
            <CustomerWidget
                className="widget--customer tk-crm widget widget--h2"
                listRoute="/:workTaskId/customer/list"
                detailsRoute="/:workTaskId/customer/details/:customerId?"
                showComponentKey="CUSTOMER"
                hideCover
            />
            <VoucherWidget className="widget--vouchers tk-vouchers widget widget--h2" showComponentKey="VOUCHERS" hideCover />
        </StyledMultiWidget>
    )
}

const StyledMultiWidget = styled(MultiWidget)({
    width: "100%",
    ".widget": {
        flex: 1,
        padding: 0,
    },
    ".widget__inner .widget__inner": {
        padding: 0,
    },
    ".widget__card": {
        height: "24em",
    },
})
