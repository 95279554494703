import { createContext, useContext } from "react"

export enum StoreInstanceEnum {
    normal = "normal",
    modal = "modal",
}

export type StoreInstance = {
    storeInstance: StoreInstanceEnum | string
    worktaskId: string | undefined
}

export const StoreContext = createContext<StoreInstance | null>(null)

export const useStoreContext = (): StoreInstance => {
    const instanceKey = useContext(StoreContext)
    if (!instanceKey) {
        // In order to use FastCalculator store you need to wrap it with context,
        return { storeInstance: StoreInstanceEnum.normal, worktaskId: undefined }
    }

    return instanceKey
}
