import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Badge, Button, Checkbox, Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Tooltip } from "@tm/components"
import { hasGenart } from "../../../data/helpers"
import { DTCGenart, Info } from "../../../data/model"
import { Actions } from "../business"
import { MainState, SelectedError } from "../business/model"
import { useWorkTaskBasketState } from "@bundles/basket"
import { PartsIndicator } from "../../_shared/PartsIndicator"

type Props = {
    code: string
    infos: Info[]
    workTaskId: string
}

export default function ErrorInfoItem({ code, infos, workTaskId }: Props) {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const actions = bindActionCreators(Actions, dispatch)
    const style = useMemo(() => getStyle(), [])
    const { costEstimation } = useWorkTaskBasketState(workTaskId)

    const selectedData = useSelector((s: MainState) => s.selectedData)

    const itemsInCostEstimation = useMemo(() => {
        return costEstimation.state.works?.filter((work) => work.workItem.providerWorkId === code).map((work) => work.workItem.description)
    }, [costEstimation.state.works, code])

    const handleSelectItem = useCallback(
        (items?: DTCGenart[]) => () => {
            const erritems: SelectedError[] =
                items
                    ?.filter((item) => item.isvalid)
                    .map((x) => {
                        return { ...x, code }
                    }) || []
            actions.selectItem(erritems)
        },
        [actions, code]
    )

    const handleCostEstimationButtonClick = useCallback(
        (info: Info) => () => {
            if (!workTaskId || itemsInCostEstimation?.includes(info.causeDescription)) {
                return
            }

            actions.addCustomWorksToBasket(info, code, workTaskId, translateText, costEstimation.actions.addCustomWorks)
        },
        [translateText, actions, code, workTaskId, itemsInCostEstimation, costEstimation.actions.addCustomWorks]
    )

    const renderErrorContent = (info: Info) => {
        return (
            <div className={style.content}>
                <div className={classes(style.grid, "top")}>
                    <Text size="l" modifiers={["strong"]}>
                        {info.locationDescription || " - "}
                    </Text>
                    <Text>{info.causeDescription || " - "}</Text>
                    <Text className={style.categoryText}>{info.category || " - "}</Text>
                </div>
                <div className={style.content}>
                    {info.genarts &&
                        info.genarts.map((gen, idx) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={idx} className={style.genart}>
                                    <Checkbox
                                        checked={hasGenart(selectedData, gen.genartnr, code)}
                                        onToggle={handleSelectItem([gen])}
                                        disabled={!gen.isvalid}
                                    />
                                    <Text>{gen.genbez}</Text>
                                    {gen.match && <PartsIndicator />}
                                </div>
                            )
                        })}
                </div>
            </div>
        )
    }

    const renderInfo = (info: Info, index: number) => {
        const isInCostEstimation = !!itemsInCostEstimation?.filter((x) => x.toLowerCase().endsWith(info.locationDescription.toLowerCase()))?.length
        const isChecked = !!info?.genarts?.find((x) => hasGenart(selectedData, x.genartnr, code))

        return (
            <div className={style.tableitem} key={index}>
                <Checkbox
                    size="l"
                    disabled={!info.genarts?.length || !info.genarts.filter((genart) => genart.isvalid).length}
                    onToggle={handleSelectItem(info.genarts)}
                    onLabelClick={handleSelectItem(info.genarts)}
                    checked={isChecked}
                />

                {renderErrorContent(info)}
                <div>
                    <Tooltip title={translateText(12994)}>
                        <div className={style.kvabutton}>
                            <Button disabled={isInCostEstimation} onClick={handleCostEstimationButtonClick(info)} id="voucher-kva" icon="repairtimes">
                                {translateText(13142)}
                            </Button>
                            {isInCostEstimation && <Badge skin="success" value={<Icon name="check" />} />}
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }

    return <>{infos.map(renderInfo)}</>
}

function getStyle() {
    const theme = getStyleTheme()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useStyle({
        tableitem: {
            paddingTop: theme.margin.l,
            paddingBottom: theme.margin.l,
            display: "grid",
            gridTemplateColumns: "3em 1fr 20em",
            borderTop: "0.1em solid #e2e2e2",
            $nest: {
                "&:last-child": {
                    borderBottom: "0.1em solid #e2e2e2",
                },
                ".checkbox__frame": {
                    height: "0.8em",
                    width: "0.8em",
                },
                ".btn>.icon": {
                    height: "1em",
                    width: "1em",
                },
            },
        },
        content: {
            display: "flex",
            flexDirection: "column",
        },
        grid: {
            display: "grid",
            gridTemplateColumns: " 3fr 2fr 1.5fr ",
            marginLeft: theme.margin.m,
            marginBottom: theme.margin.m,
        },
        gridHeader: {
            display: "grid",
            gridTemplateColumns: " 3fr 2fr 1.5fr ",
        },
        genart: {
            display: "inline-flex",
            alignItems: "center",
            flexWrap: "nowrap",
            marginBottom: theme.margin.m,
            $nest: {
                ".checkbox": {
                    marginRight: theme.margin.m,
                },
            },
            marginLeft: theme.margin.m,
        },
        centered: {
            justifySelf: "center",
        },
        categoryText: {
            justifySelf: "center",
            marginRight: "1em",
        },
        kvabutton: {
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
            $nest: {
                ".badge>.icon": {
                    zoom: ".75",
                },
            },
        },
    })(ErrorInfoItem)
}
