import { useQuery } from "react-query"
import { CalculatedCostType, CausedCostsType, GetCalculatedCostsRequest } from "@tm/models"
import { isValidDate } from "@tm/utils"
import * as Data from "../repositories/repairShops/repairShops"
import { CisFilters } from "../../business/model"

const KEY = "cis_useCalculatedCosts"
export function useCalculatedCosts(filters: CisFilters | undefined) {
    // We add 23h and 59 minutes to ensure that data from the same day is not accidentally filtered by the backend.
    // The backend service expects us to send a date including hours and minutes.
    const newDateTo = filters?.dateTo ? new Date(filters.dateTo) : undefined
    newDateTo?.setHours(23, 59, 59)

    const request: GetCalculatedCostsRequest = {
        dateFrom: filters?.dateFrom,
        dateTo: newDateTo,
        calculatedCostType: filters?.calculatedCostType ?? CalculatedCostType.Unknown,
        causedCostsType: filters?.causedCostsType ?? CausedCostsType.All,
    }
    const { data, isLoading } = useQuery([KEY, filters], () => Data.getCalculatedCosts(request), {
        enabled: isValidDate(request.dateFrom) && isValidDate(request.dateTo),
    })

    return { calculatedCosts: data?.calculatedCosts, calculatedCostsLoading: isLoading }
}
