import { FittingPosition, SensorItemsRequest, Vehicle, VehicleType } from "@tm/models"
import { RDKSListState } from "./model"
import { getBundleParams } from "../../../utils"

export function createSensorArticlesRequest(
    filters: RDKSListState["filters"],
    selectedFilters: RDKSListState["selectedFilters"],
    vehicle: Vehicle,
    extendedAssortment?: boolean,
    pageIndex?: number
): SensorItemsRequest | undefined {
    let supplierIds = filters.manufacturer.map((x) => x.id)
    if (selectedFilters.manufacturer?.length) {
        supplierIds = selectedFilters.manufacturer?.map((x) => x.id || 0)
    }

    if (!supplierIds.length || !filters.productGroupId) {
        return undefined
    }

    return {
        productGroupIds: [filters.productGroupId],
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType || VehicleType.PassengerCar,
        supplierIds,
        extendedAssortment: extendedAssortment || selectedFilters.extendedAssortment,
        fittingSideFilter: FittingPosition.None,
        pageIndex: pageIndex ?? 1,
        pageSize: getBundleParams().tyresPageSize,
    }
}

export function createNextSensorArticlesRequest(
    state: RDKSListState,
    vehicle: Vehicle,
    extendedAssortment?: boolean
): SensorItemsRequest | undefined {
    const {
        filters,
        selectedFilters,
        articles: { pageIndex },
    } = state
    const pageSize = getBundleParams().tyresPageSize

    let supplierIds = filters.manufacturer.map((x) => x.id)
    if (selectedFilters.manufacturer?.length) {
        supplierIds = selectedFilters.manufacturer?.map((x) => x.id || 0)
    }

    if (!supplierIds.length || !filters.productGroupId) {
        return undefined
    }

    if (pageIndex > state.articles.data.length / pageSize) {
        return undefined
    }

    return {
        productGroupIds: [filters.productGroupId],
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType || VehicleType.PassengerCar,
        supplierIds,
        extendedAssortment: extendedAssortment || selectedFilters.extendedAssortment,
        fittingSideFilter: FittingPosition.None,
        pageIndex: pageIndex + 1,
        pageSize,
    }
}
