import { ajax, createBufferedRequestFunction, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"

export const getSupplierLogo = createBufferedRequestFunction({
    callService: getSupplierLogos,
    mapServiceResponseToResponse: (serviceResponse, request) => serviceResponse[request],
})

function getSupplierLogos(supplierIds: Array<number>) {
    const url = `${getServiceUrl()}/GetSupplierLogos`
    const authorization = getStoredAuthorization()
    const body = { supplierIds: supplierIds.join(",") }

    return ajax<{ logos?: { [supplierId: string]: string } }>({ url, body, authorization }).then((data) => data?.logos || {})
}

function getServiceUrl(): string {
    return getBundleParams().partsServiceUrl
}
