import { Stack } from "@tm/components"
import { ArticleErpInfo } from "@tm/models"
import { useState } from "react"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"
import { ComposerComponent, ContextComposer } from "../ContextProvider"
import { ActiveVehicleDataProvidersProvider } from "../ContextProvider/ActiveVehicleDataProviders"
import { BasketQuantitiesProvider, WholesalerBasketQuantitiesProvider } from "../ContextProvider/BasketQuantities"
import { ListOptionsProvider } from "../ContextProvider/ListOptions"
import { NotesProvider } from "../ContextProvider/Notes"
import { ProductGroupRepairTimesProvider } from "../ContextProvider/ProductGroupRepairTimes"
import { ProductGroupTopicIdsProvider } from "../ContextProvider/ProductGroupTopicIds"
import { SupplierLogosProvider } from "../ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ContextProvider/TradeReferences"
import { WatchListProvider } from "../ContextProvider/WatchList"
import { FiltersAndResultsProvider } from "./ContextProvider/FiltersAndResults"
import { WholeSalerArticleNumberListParamsProvider } from "./ContextProvider/ListParams"
import { WholesalerArticleNumberList } from "./WholesalerArticleNumberList"
import { MultiErpInfosProvider } from "../ContextProvider/ErpInfos/MultiErpInfosProvider"
import { ArticleListActionsProvider } from "../ContextProvider/ArticleListActions"

export default function WholesalerArticleNumbers() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [WholeSalerArticleNumberListParamsProvider],
        [FiltersAndResultsProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [ProductGroupTopicIdsProvider, { isEnabled: hasListRendered }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [MultiErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [WholesalerBasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [ArticleListActionsProvider],
    ]
    return (
        <ContextComposer components={components}>
            <Stack overflow="hidden">
                <ModalModuleHeader />
                <WholesalerArticleNumberList />
            </Stack>
        </ContextComposer>
    )
}
