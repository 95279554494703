import { FC, useEffect, useMemo, useState } from "react"
import { classes } from "typestyle"
import { useIsFirstRender } from "../../helpers"
import { NextStep, ScrollWrapper } from "../_shared"
import { stepNavigationActions, worksActions, useFastServiceStore } from "../../data"
import WorksList from "./components/WorksList"
import { getComponentStyles } from "../_shared/styles"
import { StepNames } from "@bundles/fast-service/data/models"

type Props = {
    step: string
    onCompletionChange?: (completed: boolean) => void
}

export const Works: FC<Props> = (props) => {
    const isFirstMount = useIsFirstRender()
    const classNames = useMemo(() => getComponentStyles(), [])

    const workData = useFastServiceStore((state) => state.worksState.works[props.step])
    const disableTyresSystem = useFastServiceStore((state) => state.tyresWheels.disableTyresSystem)

    const [isComplete, setIsComplete] = useState(false)

    useEffect(() => {
        if (workData) {
            worksActions.setDefaultNextPanel(props.step)
        }
    }, [workData])

    useEffect(() => {
        !isFirstMount && stepNavigationActions.completeStep(props.step)

        if (isComplete) {
            stepNavigationActions.updateNextStep(props.step)
        }

        props.onCompletionChange?.(isComplete)
    }, [isComplete])

    const showNextStepButton = () => props.step === StepNames.TiresWheels ? disableTyresSystem : true

    return (
        <ScrollWrapper>
            <div className={classes(classNames.wrapperMargins, classNames.wrapperMinHeight)}>
                <WorksList step={props.step} setCompleted={setIsComplete} />
            </div>
            {showNextStepButton() && <NextStep currentStepName={props.step} buttonTextId={414} icon="arrows_down" active={isComplete} />}
        </ScrollWrapper>
    )
}
