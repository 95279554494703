import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { RimFixturesRequest, RimFixturesResponse } from "./model"

export async function loadRimFixtures(request: RimFixturesRequest) {
    const url = `${getServiceUrl()}/GetRimFixtures`
    const authorization = getStoredAuthorization()
    const body = request

    const response = await ajax<RimFixturesResponse>({ url, body, authorization, method: "GET" }).then((response) => response?.rimFixtures)

    if (!response) {
        throw new Error("Empty response")
    }

    return response
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}
