import { FastCalculation, Vehicle, CarModelDetailsResponse, WorkCategory, ECalcOrigin, TmaEModule, CustomWork } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { TmaHelper } from "@tm/utils"

import { FastCalculator } from "@tm/data"
import { BasketCalcStateContext } from "../../../data/model"

export function createInitCalculationRequest(
    vehicle: Vehicle,
    fromWidget: boolean,
    origin: ECalcOrigin,
    calcId?: string,
    isWorkshop?: boolean
): FastCalculator.FastCalcRequest {
    return {
        calcWorkflow: FastCalculator.ECalcWorkflow.Hyperion,
        ignoreCache: false,
        preferePreviewCalcStates: fromWidget,
        calcId: calcId || "",
        vehicleId: vehicle.id,
        historySize: 10,
        eTag: "",
        sprNr: 4,
        kTypNr: vehicle.tecDocTypeId,
        invalidInputs: [],
        commands: [],
        calcOrigin: isWorkshop ? ECalcOrigin.SvcConnect : origin,
    }
}

export function createButtonInputRequest(
    calcButton: FastCalculator.CalcStateButton,
    parentCalcState: FastCalculator.CalcState,
    vehicle: Vehicle,
    kTypNr: number,
    selectedCalcState: FastCalculator.CalcState,
    languageId: string,
    calcOrigin: ECalcOrigin,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number,
    requestQueueId?: string
): FastCalculator.ClickButtonRequest {
    return {
        calcButton,
        parentCalcState,
        preferePreviewCalcStates: false,
        vehicleId: vehicle.id,
        calcOrigin,
        requestQueueId,
        kTypNr,
        ...((calcButton.type == FastCalculator.ECalcButtonState.StartCalculation ||
            calcButton.type == FastCalculator.ECalcButtonState.StartNewCalculation) && {
            initialDataCalcState: {
                ...selectedCalcState,
                context: undefined,
            },
            initialDataCalcStateContext: {
                type: FastCalculator.ECalcState.InitialData,
                initialData: createInitialData(vehicle, languageId, vehicle.id, carModel, defaultHourlyRate),
            },
        }),
    }
}

export function createSubmitInputRequest(
    calcInput: FastCalculator.CalcInput,
    parentCalcState: FastCalculator.CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): FastCalculator.SubmitInputRequest {
    return {
        calcInput,
        parentCalcState,
        preferePreviewCalcStates: parentCalcState.type == FastCalculator.ECalcState.FastCockpitPreviewNext,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createSelectionItemClickRequest(
    items: FastCalculator.CalcSelectionItem[],
    parentCalcState: FastCalculator.CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): FastCalculator.SelectSelectionItemRequest {
    return {
        items,
        parentCalcState,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createSubmitDropdownItemClickRequest(
    calcInput: FastCalculator.CalcInput,
    calcDropdownItem: FastCalculator.CalcDropDownItem,
    parentCalcState: FastCalculator.CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): FastCalculator.SubmitDropdownItemClickRequest {
    return {
        calcInput,
        calcDropdownItem,
        parentCalcState,
        preferePreviewCalcStates: parentCalcState.type == FastCalculator.ECalcState.FastCockpitPreviewNext,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createImportCalculationRequest(
    calcStates: FastCalculator.CalcState,
    defaultHourlyRate?: number,
    worktask?: WorkTaskInfo,
    memo?: string
): FastCalculation.ImportFastCalculationRequest | undefined {
    const context = calcStates.context as BasketCalcStateContext | undefined
    if (!worktask?.vehicle || !context) {
        return
    }

    const works: FastCalculation.RepairTime[] = []
    const customWorks: CustomWork[] = []

    const customOeArticles = context.oeParts.filter((x) => !x.oeArticleNumber)
    const customParts = context.customParts.map((x) => ({
        ...x,
        articleNumber: x.articleNumber ? x.articleNumber : (x.articleNumber.match(/[^\s]+/)?.[0] ?? ""),
    }))

    context.works.forEach((x) => {
        // Hourly rate set individually on work or globally for the whole calculation
        const hourlyRate = x.alternativeHourlyRate ?? context.labourRate / 100 // Labour rate has a factor of 100

        if (hourlyRate != defaultHourlyRate) {
            // If hourly rate is different then the default the work has to be a custom one
            customWorks.push({
                hourlyRate,
                customWorkNumber: x.providerWorkId ?? "",
                description: x.description,
                time: x.time ?? 0,
                categoryOfWork: WorkCategory.NotCategorized,
            })
        } else if (!x.providerWorkId) {
            customWorks.push({
                hourlyRate,
                customWorkNumber: "",
                description: x.description,
                time: x.time ?? 0,
                categoryOfWork: WorkCategory.NotCategorized,
            })
        } else {
            works.push({
                description: x.description,
                providerWorkId: x.providerWorkId ?? "",
                time: x.time ?? 0,
                categoryOfWork: WorkCategory.WorkshopWork,
                oeReferenceNumber: x?.oeReferenceNumber,
            })
        }
    })

    return {
        workTaskId: worktask.id,
        vehicleId: worktask.vehicle.id,
        parts: context.parts.map((x) => {
            const { garagePrice, ...rest } = x
            return {
                ...rest,
                ...(!x.wholesalerArticleNumber && { garagePrice }),
                ...(memo && { memo }),
            }
        }),
        oeParts: context.oeParts
            .filter((x) => x.oeArticleNumber)
            .map((x) => ({
                ...x,
                quantityValue: x.quantityValue ?? 1, // TODO: for now default to quantity of 1 but really the calculation should not be "importable" as long as the quantities of all oe parts are not set
                oePriceValue: x.oePriceValue || undefined, // replace oePriceValue of 0 with undefined, otherwise there is an error during import
                currencyCode: x.currencyCode ?? context.wkz,
            })),
        customParts: [
            ...customOeArticles.map((x) => {
                return { ...x, quantityValue: x.quantityValue || 1, garagePrice: x.oePriceValue }
            }),
            ...customParts.map((x) => ({
                ...x,
                quantityValue: x.quantityValue ?? 1, // TODO: for now default to quantity of 1 but really the calculation should not be "importable" as long as the quantities of all custom parts are not set
            })),
        ],
        works,
        customWorks,
        repairTimesProvider: context.repairTimesProvider,
        repairTimeDivision: 1, // TODO: division depends on the repairTimesProvider (HaynesPro = 1) and should be supplied by the FastCalculator service
        log: TmaHelper.AddCustomToBasket.GetUserInteractionLog("import", TmaEModule.FAST_CALCULATOR),
    }
}

export function createInitialDataRequest(
    selectedCalcState: FastCalculator.CalcState,
    vehicle: Vehicle,
    languageId: string,
    calcOrigin: ECalcOrigin,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number,
    customerNo?: string,
    preferePreviewCalcStates = false
): FastCalculator.SubmitInitialDataRequest {
    return {
        preferePreviewCalcStates,
        kTypNr: vehicle.tecDocTypeId,
        historySize: 10,
        initialDataCalcState: {
            ...selectedCalcState,
            context: undefined,
        },
        initialDataCalcStateContext: {
            type: FastCalculator.ECalcState.InitialData,
            initialData: createInitialData(vehicle, languageId, vehicle.id, carModel, defaultHourlyRate, customerNo),
        },
        calcOrigin,
    }
}

export function createCalcProductGroupsRequest(
    productGroupIds: number[],
    vehicle: Vehicle,
    languageId: string,
    calcOrigin: ECalcOrigin,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number,
    origin?: string,
    topProductGroups?: FastCalculator.TopProductGroup[]
): FastCalculator.CalcProductGroupsRequest {
    let calcOriginLabel = "Origin:Searchtree"
    let rewritedCalcOrigin = calcOrigin
    if (origin && +origin) {
        calcOriginLabel = `Origin:${ECalcOrigin[+origin]}`
        rewritedCalcOrigin = ECalcOrigin.Next | +origin
    }

    let calcKeyShards = []
    if (topProductGroups) {
        const topProductGroupsArray = topProductGroups?.map((x) => {
            if (x.BinKrit100) {
                return `${x.GenArtNr};${x.BinKrit100}`
            }
            return `${x.GenArtNr}`
        })
        calcKeyShards = [vehicle.id, calcOriginLabel, productGroupIds.join(","), topProductGroupsArray.join(",")]
    } else {
        calcKeyShards = [vehicle.id, calcOriginLabel, productGroupIds.join(",")]
    }

    return {
        kTypNr: vehicle.tecDocTypeId,
        calcKeyShards,
        initialData: createInitialData(vehicle, languageId, vehicle.id, carModel, defaultHourlyRate),
        genArtNrs: productGroupIds,
        topProductGroups,
        calcOrigin: rewritedCalcOrigin,
    }
}

function createInitialData(
    vehicle: Vehicle,
    languageId: string,
    vehicleId: string,
    carModel?: CarModelDetailsResponse,
    defaultHourlyRate?: number,
    customerNo?: string
): FastCalculator.InitialDataCalcStateData {
    return {
        cylinders: carModel?.modelDetails?.cylinderCount ?? 0,
        eTypArt: vehicle.vehicleType,
        engineCode: vehicle.engineCode ?? "",
        engineCodes: carModel?.modelDetails?.engineCodes ?? [],
        isLonglife: vehicle.longlife,
        kTypNr: vehicle.tecDocTypeId,
        labourRate: defaultHourlyRate ? defaultHourlyRate * 100 : undefined,
        mileage: vehicle.mileAge ?? 0,
        monthYearDateFormat: languageId == "1" ? "dd.MM.yyyy" : "MM.dd.yyyy",
        numberLocal: "",
        registrationDate: vehicle.initialRegistration ? new Date(vehicle.initialRegistration.setHours(12)) : undefined,
        nextVehicleId: vehicleId,
        vin: vehicle.vin,
        erpLoginInfos: { customerId: customerNo },
    }
}

export function createAddPartFromArticleListRequest(
    article: FastCalculator.ArticleData,
    searchType: FastCalculator.ESearchLink,
    parentCalcState: FastCalculator.CalcState,
    vehicleId: string,
    kTypNr: number,
    calcOrigin: ECalcOrigin
): FastCalculator.AddPartFromArticleListRequest {
    return {
        ...article,
        parentCalcState,
        searchType,
        vehicleId,
        calcOrigin,
        kTypNr,
    }
}

export function createAddCustomPartToConsumableRequest(
    id: string,
    label: string,
    price: number,
    calcId?: string,
    calcETag?: string,
    consumableId?: string,
    articleNumber?: string
): FastCalculator.AddCustomPartToConsumableRequest {
    return {
        calcId: calcId || "",
        calcETag: calcETag || "",
        consumableId: consumableId || "",
        id,
        articleNumber: articleNumber || "",
        label,
        price,
    }
}

export function createMemo({ vehicle, customer }: WorkTaskInfo): string | undefined {
    const memoParts = []

    if (vehicle) {
        if (vehicle.plateId) {
            memoParts.push(vehicle.plateId)
        } else {
            memoParts.push(vehicle.manufacturer, vehicle.modelSeries, vehicle.model)
        }
    }

    if (customer?.firstName) {
        memoParts.push(customer.firstName)
    }

    if (customer?.lastName) {
        memoParts.push(customer.lastName)
    }

    if (memoParts.length) {
        return memoParts.join(" ")
    }
}
