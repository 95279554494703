import { useMemo } from "react"
import { useCostEstimationModuleParameters, useWorkTaskOrderModuleParameters } from "@tm/context-distribution"
import { useErpConfigLoadable } from "@tm/utils"
import { OrderGroup } from "../../data/model/WorkTaskBasket"
import { useOrderOptionsMulti } from "../../data/hooks/useOrderOptions"
import { OrderOptionsGroup } from "../../models"
import { findCurrentActiveOption, useMultiAdditionalOrderOption } from "../useAdditionalOrderInformation"

export function useBasketOrderOptions(workTaskId: string, orderGroups?: OrderGroup[], telesalesCustomerNo?: string) {
    const additionalOrderOptions = useMultiAdditionalOrderOption()
    const { isErpInfoEnabled } = useErpConfigLoadable()
    const { useSendOrder: basketUseSendOrder } = useWorkTaskOrderModuleParameters()
    const { useSendOrder: costEstimationUseSendOrder } = useCostEstimationModuleParameters()

    const orderOptionsEnabled = useMemo(() => {
        return isErpInfoEnabled && (basketUseSendOrder || costEstimationUseSendOrder)
    }, [isErpInfoEnabled, basketUseSendOrder, costEstimationUseSendOrder])

    const allOrderOptions = useOrderOptionsMulti(
        orderGroups
            ?.filter((orderGroup) => orderGroup.partIds.length) // OrderOptions should not be invoked if there are no parts
            .map((group) => {
                return {
                    workTaskId,
                    telesalesCustomerNo,
                    distributorId: group.distributorId,
                    warehouseId: group.warehouseId,
                }
            }) || [],
        orderOptionsEnabled
    )

    const [orderOptionsGroups, orderOptionsLoading, orderOptionsFetching] = useMemo<[OrderOptionsGroup[], boolean, boolean]>(() => {
        let loading = false
        let fetching = false
        return [
            allOrderOptions
                .filter((e) => (e.orderOptions as any) !== "reject")
                .map<OrderOptionsGroup>((option) => {
                    loading ||= option.loading
                    fetching ||= option.fetching
                    const optionalAdditionalInformation = additionalOrderOptions.find(
                        findCurrentActiveOption(workTaskId, { warehouseId: option.request.warehouseId, distributorId: option.request.distributorId })
                    )

                    return {
                        distributorId: option.request.distributorId,
                        warehouseId: option.request.warehouseId,
                        orderOptions:
                            option.orderOptions && typeof option.orderOptions !== "string"
                                ? {
                                      ...option.orderOptions,
                                      orderComment: optionalAdditionalInformation?.customerOrderComment || option.orderOptions?.orderComment,
                                      customerOrderNumber:
                                          optionalAdditionalInformation?.customerOrderNumber || option.orderOptions?.customerOrderNumber,
                                  }
                                : undefined,
                        isLoading: option.loading,
                        isFetching: option.fetching,
                        hasErrors: option.orderOptionsHasError,
                        errorText: option.errorText,
                        disableOrder: option.disableOrder,
                    }
                }),
            loading,
            fetching,
        ]
    }, [allOrderOptions, additionalOrderOptions, workTaskId])

    const orderOptionsLoaded = useMemo(() => {
        let loaded = true
        // if there are no order options to load, loaded should be true..
        if (orderOptionsEnabled) {
            loaded = allOrderOptions.every((option) => option.loaded)
        }
        return loaded
    }, [orderOptionsEnabled, allOrderOptions])

    return {
        orderOptionsGroups,
        orderOptionsLoading: orderOptionsLoading || orderOptionsFetching,
        orderOptionsLoaded,
        orderOptionsWithError: allOrderOptions.some((option) => option.isError),
    }
}
