import { WholesalerArticle, WholesalerArticleItemState } from "@tm/models"
import { ArticleItem, ArticleItemStateProvider } from "@tm/components"
import { useCallback, useMemo, useState } from "react"
import { useHandleRemoveFromBasket } from "../../hooks/useArticleItem/useHandleRemoveFromBasket"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useHandleAddWholesalerArticleToBasket } from "../../hooks/useArticleItem/useHandleAddWholesalerPartToBasket"
import { useErpInfosContext, useListOptionsContext } from "../../ContextProvider"
import { isAddButtonDisabled, isSameWholesalerArticle, mapWholesaleDtoFromWholesalerArticle, mapWholesalerArticleToArticle } from "../../helpers"
import { useWholesalerBasketQuantitiesContext } from "../../ContextProvider/BasketQuantities"
import { useArticleErpInfos, useLoadErpInfos } from "../../hooks"

type WholesalerArticleItemProps = {
    article: WholesalerArticle
}

export function WholesalerArticleItem({ article }: WholesalerArticleItemProps) {
    const options = useListOptionsContext()
    const erpInfosData = useErpInfosContext()
    const loadErpInfos = useLoadErpInfos(erpInfosData)

    const [quantity, setQuantity] = useState<number>(article.quantity?.confirmedValue || article.quantity?.value || 1)
    const [originalQuantity] = useState(article.quantity?.value ?? 1)

    const addToBasket = useHandleAddWholesalerArticleToBasket(article)
    const removeFromBasket = useHandleRemoveFromBasket()
    const { basketQuantities, updateBasketQuantities } = useWholesalerBasketQuantitiesContext()!

    const articleMapped = useMemo(() => mapWholesalerArticleToArticle(article), [article])
    const articleErpInfos = useArticleErpInfos(articleMapped, quantity, erpInfosData)

    const basketQuantity = useMemo(() => {
        const wholesalerArticleDto = mapWholesaleDtoFromWholesalerArticle(article)
        return basketQuantities?.find((q) => isSameWholesalerArticle(q.article, wholesalerArticleDto))
    }, [basketQuantities, article])

    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await addToBasket(internalQuantity ?? quantity ?? 1)
            updateBasketQuantities([article])
        },
        [addToBasket, article, quantity, updateBasketQuantities]
    )

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setQuantity(value)

            if (loadErpInfo) {
                loadErpInfos({ article: articleMapped, quantity: value, erpSystem: articleErpInfos.default?.erpSystem })

                if (articleErpInfos.alternative) {
                    loadErpInfos({ article: articleMapped, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [articleErpInfos.alternative, articleErpInfos.default?.erpSystem, articleMapped, loadErpInfos]
    )

    const addButtonsDisabled = useMemo(() => isAddButtonDisabled(articleErpInfos), [articleErpInfos])

    const articleState = useMemo<WholesalerArticleItemState>(
        () => ({
            type: "WholesalerArticle",
            article,
            isVehicleDependent: false,
            options,
            basketQuantity,
            quantity,
            originalQuantity,
            articleErpInfos,
            addToBasketButtonDisabled: addButtonsDisabled,
            addToCostEstimationButtonDisabled: addButtonsDisabled,
            handleAddToBasket,
            handleChangeQuantity,
            handleRemoveFromBasket,
            AvailabilityComponent,
        }),
        [
            article,
            options,
            basketQuantity,
            quantity,
            originalQuantity,
            articleErpInfos,
            addButtonsDisabled,
            handleAddToBasket,
            handleChangeQuantity,
            handleRemoveFromBasket,
        ]
    )

    return (
        <ArticleItemStateProvider value={articleState}>
            <ArticleItem variant="WholesalerArticle" />
        </ArticleItemStateProvider>
    )
}
