import { Memo, MemoType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { PanelSection } from "@tm/controls"
import { RefObject } from "react"
import { getBundleParams } from "../../../utils"
import { MemoTooltipIconTable } from "../../../_shared/memo-tooltip-icon/table"

type Props = {
    memos?: Array<Memo>
    wrapperRef: RefObject<HTMLDivElement> | undefined
}

export default function MemoTooltipIcon(props: Props) {
    const { translateText } = useLocalization()

    const iconName = getBundleParams().memoTooltipIcon
    if (!iconName) {
        return null
    }

    const memos = props.memos?.filter((m) => m.type === MemoType.Hyperlink || m.type === MemoType.AdditionalDescription)
    if (!memos?.length) {
        return null
    }

    return (
        <div ref={props.wrapperRef}>
            <PanelSection size="s" title={translateText(180)}>
                <MemoTooltipIconTable memos={memos} />
            </PanelSection>
        </div>
    )
}
