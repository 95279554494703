import { Box } from "@tm/components"
import { Dropdown, TextField, Text } from "@tm/controls"
import { FC, memo, useState } from "react"

type Props = {
    items: string[]
    selectedValue?: string
    defaultItem?: string
    disabled?: boolean
    onChange?(value: string): void
    hasInput?: boolean
    loading?: boolean
    label?: string
    layout?: Array<ButtonLayout>
    className?: string
    maxItemsToShow?: number
}

type DropDownItemType = {
    value: string
    index: number
}

type ButtonLayout = "none" | "rounded" | "ghost" | "shaded" | "holo" | "bordered" | "iconRight" | "circle" | "dropshadow"

export default function CustomDropdown({
    items,
    selectedValue,
    defaultItem,
    disabled,
    hasInput,
    loading,
    label,
    layout,
    className,
    maxItemsToShow,
    onChange,
}: Props) {
    const [inputValue, setInputValue] = useState("")

    const visibleItems = items.filter((x) => !inputValue || x.includes(inputValue))
    const dropDownItems = mapStringArrayToDropdownItems(visibleItems)

    if (defaultItem) {
        dropDownItems.unshift({ value: defaultItem, index: -1 })
    } // add default item into array

    const foundItem = dropDownItems.find((item) => item.value === selectedValue)
    const selectedItem = foundItem || dropDownItems.first()

    const handleDropDownChange = (dropDownItem: DropDownItemType) => {
        if (dropDownItem.index === -1) {
            // eslint-disable-next-line no-param-reassign
            dropDownItem.value = ""
        }
        onChange && onChange(dropDownItem.value)
    }
    const handleInputConfirm = (value: string) => {
        const fountItem = items.find((x) => x === value)
        fountItem && onChange && onChange(fountItem)
    }

    const renderDropDownInput = () => (
        <TextField
            label={label}
            floatingLabel
            value={inputValue}
            layout={["holo"]}
            preventBubbling
            onChange={(value) => setInputValue(value)}
            onChangeConfirm={handleInputConfirm}
        />
    )

    return (
        <Dropdown
            disabled={!items.length || disabled}
            items={loading ? [] : dropDownItems}
            itemView={DropDownItemView}
            layout={layout}
            amountItemsToShow={maxItemsToShow || 10}
            value={selectedItem}
            onChange={handleDropDownChange}
            inputView={hasInput ? renderDropDownInput : undefined}
            className={className}
        />
    )
}

const DropDownItemView: FC<DropDownItemType> = memo(({ value }) => {
    return (
        <Box style={{ display: "flex", justifyContent: "center" }}>
            <Text>{value}</Text>
        </Box>
    )
})

const mapStringArrayToDropdownItems = (items?: string[]): DropDownItemType[] => {
    if (!items) {
        return []
    }

    return items.map((value, index) => {
        return { value, index }
    })
}
