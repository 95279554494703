import { Box, CellContentPosition, Icon, Table, TableCellData, TableColumnData, TableRowData, styled } from "@tm/components"
import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import Accordion from "../../_shared/Accordion"
import { LabourValues } from "../../../data/models"
import { useDrivemotiveStore } from "../../../data/state"

export default function LabourValuesList() {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const fixedPrice = useDrivemotiveStore((state) => state.summary.selectedBooking?.labour_values)

    const [isExpanded, setIsExpanded] = useState(!!fixedPrice?.length)

    if (!fixedPrice) {
        return null
    }

    const handleClick = () => {
        setIsExpanded((prevState) => !prevState)
    }

    const tableColumns: TableColumnData[] = [
        { header: translateText(617).toUpperCase() },
        { header: translateText(57).toUpperCase(), alignContent: CellContentPosition.right },
    ]

    const getCells = (fixedPriceCell: LabourValues) => {
        const title = (
            <Box sx={{ width: "70em" }}>
                {fixedPriceCell.title}
                <br />
            </Box>
        )

        const cellData: TableCellData[] = [
            { displayValue: title, id: "title" },
            {
                displayValue: fixedPriceCell.price && <Box sx={{ display: "flex" }}>{currency(fixedPriceCell.price, currencyString)}</Box>,
                id: "Sum",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] = fixedPrice.map((fixed: LabourValues, index: number) => ({
        cells: getCells(fixed),
        id: `${index}`,
        rowIndicatorWidth: 2,
    }))

    const handleGetTotalPrice = () => {
        return fixedPrice.reduce((total, item) => {
            return total + (item.price || 0)
        }, 0)
    }

    return (
        <StyledBox>
            <Accordion icon={<Icon name="down" />} expandIcon={<Icon name="up" />} expanded={isExpanded} onClick={handleClick} underlinedHeader>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    {translateText(13902)}
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        {translateText(13899)}
                        {!!fixedPrice?.length && (
                            <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(handleGetTotalPrice(), currencyString)}</Box>
                        )}
                    </Box>
                </Box>
                {fixedPrice?.length > 0 && (
                    <StyledTable
                        columns={tableColumns}
                        rows={displayData}
                        headerBackground="transparent"
                        fontSize={14}
                        headerStyle="default"
                        rowCap={0}
                        overflowY="auto"
                        rowStyle="uniform"
                    />
                )}
            </Accordion>
        </StyledBox>
    )
}

const StyledTable = styled(Table)({
    ".row>span": {
        alignContent: "flex-start",
        flexWrap: "wrap",
    },
})
const StyledBox = styled(Box)({
    marginTop: "1em",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    gap: "0.5em",
})
