import {
    useBasketUpdateWorkflow,
    useImportFastCalculation,
    useImportVoucherItems,
    useImportVouchers,
    useImportToCostEstimation,
} from "../../data/hooks/workTaskBasket"

async function resolve() {}

export function useBasketImports() {
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const importVoucherItems = useImportVoucherItems(handleBasketUpdateWorkflow)
    const importVoucher = useImportVouchers(handleBasketUpdateWorkflow)
    const importFastCalculation = useImportFastCalculation(handleBasketUpdateWorkflow)
    const importToCostEstimation = useImportToCostEstimation(handleBasketUpdateWorkflow)
    return { importVoucherItems, importVoucher, importFastCalculation, importToCostEstimation }
}
