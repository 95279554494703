import { PropsWithChildren, useMemo } from "react"
import { ArticleSelectionParams, Article } from "@tm/models"
import { CompilationShowViewModelItem } from "../../../../../data"
import { mapCompilationShowViewModelItemToArticle } from "../../../../../data/mapper"
import { ArticleSelectionProvider } from "../providers/ArticleSelectionProvider"

type ArticleSelectionProps = {
    selectedIds: string[]
    selectedArticles: CompilationShowViewModelItem[] | undefined
    handleSelectPart: (partId: string, moveMode?: boolean) => void
    unselectAll: () => void
}

export function ArticleSelectionWrapper({
    children,
    selectedIds,
    selectedArticles,
    handleSelectPart,
    unselectAll,
}: PropsWithChildren<ArticleSelectionProps>) {
    const articleSelection: ArticleSelectionParams<Article> = useMemo(
        () => ({
            selectedArticles: selectedArticles?.map((a) => mapCompilationShowViewModelItemToArticle(a) as Article) ?? [],
            toggleSelectedArticle: (article: Article) => handleSelectPart(article.id),
            deselectArticle: (id: string) => handleSelectPart(id),
            deselectAll: unselectAll,
            isArticleSelected: (article: Article) => selectedIds.includes(article.id),
        }),
        [selectedArticles, unselectAll, selectedIds, handleSelectPart]
    )

    return <ArticleSelectionProvider value={articleSelection}>{React.Children.only(children)}</ArticleSelectionProvider>
}
