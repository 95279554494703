import { em, percent } from "csx"
import { useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router"
import { getStyleTheme, useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { Demo, Widget, WidgetProps, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Authority, FastCalculator } from "@tm/data"
import { ECounterType } from "@tm/models"
import { TmaHelper, classes, renderRoute } from "@tm/utils"
import { Box, LinkButton, Loader, styled } from "@tm/components"
import { submitVehicleFieldInputs } from "@bundles/fast-calculator/data/helpers/submitVehicleFieldInput"
import { bem, getLabourRate, isVehicleChanged } from "../../data/helpers"
import { CalculationWidgetContext, SummaryContext } from "../../data/model"
import { hasFastCalculatorData } from "../../helpers"
import { CalcStateButton, ErrorMessage } from "../_shared"
import LastCalculationComponent from "./components/lastCalculation"
import SummaryPreview from "./components/summaryPreview"
import { mainActions, StoreContext, StoreInstanceEnum, useFastCalculatorStore, useStoreContext } from "../../state"
import { initFastCalculator, initialData } from "../../business"

type Props = {
    fastCalculatorRoute: string
    height?: number
    className?: string
    size?: WidgetSizes
    hideCover?: boolean
}

function WidgetComp({ fastCalculatorRoute, height, className, size, hideCover }: Props) {
    const { userContext } = useUser()
    const instance = useStoreContext()
    const history = useHistory()
    const matchParams = useParams()
    const activeWorkTask = useWorkTask()
    const userSettings = useUser()
    const localization = useLocalization()
    const isFCDemo = Authority.useCheckDemoModuleActive(userContext, "fastCalculator", "topmotive")
    const style = useMemo(() => getStyle(), [])

    const { selectedCalcState, vehicle, isError, isLoading, engineCode, initialRegistration, longLife, mileage, context } = useFastCalculatorStore(
        (state) => ({
            selectedCalcState: state.selectedCalcState,
            vehicle: state.vehicle,
            isError: state.error,
            isLoading: state.loading,
            engineCode: state.engineCode,
            initialRegistration: state.initialRegistration,
            longLife: state.longLife,
            mileage: state.mileage,
            context: state.selectedCalcState?.context as SummaryContext | undefined,
        })
    )

    const [activeState, setActiveState] = useState(false)
    const [hasFastCalculator, setHasFastCalculator] = useState(false)

    const workTaskVehicle = activeWorkTask?.workTask?.vehicle

    const { inputs } = useMemo(
        () => ({
            inputs: context?.inputs ?? [],
        }),
        [context]
    )

    useEffect(() => {
        if (!workTaskVehicle) {
            return
        }

        if (workTaskVehicle.dataAvailabilities) {
            setHasFastCalculator(hasFastCalculatorData(workTaskVehicle))
        }

        if (isVehicleChanged(workTaskVehicle, vehicle)) {
            mainActions.setVehicle(instance, workTaskVehicle)
            submitVehicleFieldInputs(inputs, workTaskVehicle, instance, vehicle)
        }
    }, [workTaskVehicle])

    useEffect(() => {
        if (activeState) {
            if (workTaskVehicle && workTaskVehicle.id !== vehicle?.id) {
                mainActions.setVehicle(instance, workTaskVehicle)
                initFastCalculator(instance, true)
            } else if (
                vehicle &&
                selectedCalcState &&
                !(FastCalculator.ECalcState.PresentableCalcState & selectedCalcState?.type) &&
                selectedCalcState?.type !== FastCalculator.ECalcState.InitialData
            ) {
                // special case
                initFastCalculator(instance, true)
            }
            // when switching tabs FastCalc should be invoked to retrieve the calc state for widget
            else if (
                selectedCalcState?.type &&
                selectedCalcState?.type !== FastCalculator.ECalcState.InitialData &&
                !(FastCalculator.ECalcState.WidgetCalcState & selectedCalcState?.type)
            ) {
                if (selectedCalcState?.type === FastCalculator.ECalcState.FastCockpitNext) {
                    return
                }

                initFastCalculator(instance, true)
            }
            // usually when selected calc state is 1 we need to invoke submitInitialData
            else {
                shouldInitialDataSubmit() && workTaskVehicle && mainActions.setVehicle(instance, workTaskVehicle)
                setInitialData()
            }
        }
    }, [activeState, selectedCalcState?.type])

    useEffect(() => {
        if (workTaskVehicle && hideCover) {
            setActiveState(true)
            initFastCalculator(instance, true)
        }
    }, [hideCover, workTaskVehicle])

    const shouldInitialDataSubmit = (): boolean => {
        if (!workTaskVehicle || !vehicle) {
            return false
        }

        return (
            (workTaskVehicle.engineCode || "") !== (vehicle.engineCode || "") ||
            (workTaskVehicle.engineCode || "") !== (engineCode || "") ||
            workTaskVehicle.initialRegistration?.toString() !== vehicle.initialRegistration?.toString() ||
            workTaskVehicle.initialRegistration?.toString() !== initialRegistration?.toString() ||
            workTaskVehicle.mileAge !== vehicle.mileAge ||
            workTaskVehicle.mileAge !== mileage ||
            workTaskVehicle.longlife !== vehicle.longlife ||
            workTaskVehicle.longlife !== longLife
        )
    }

    const setInitialData = () => {
        if (selectedCalcState?.type === FastCalculator.ECalcState.InitialData || shouldInitialDataSubmit()) {
            initialData(
                instance,
                localization.languageId,
                getLabourRate(userSettings?.userSettings),
                activeWorkTask?.workTask?.customer?.refCustomerNo,
                true
            )
        }
    }

    // #region useless
    const handleWidgetClick = () => {
        if (!activeState) {
            TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarFastCalculator)
            setActiveState(true)
            initFastCalculator(instance, true)
        }
    }

    const handleChangeRoute = (calcStateType?: number) => {
        if (calcStateType === FastCalculator.ECalcState.InitialData) {
            setInitialData()
        }

        const fastCalcUrl = renderRoute(fastCalculatorRoute, { ...matchParams })
        history.push(fastCalcUrl)
    }

    const renderCover = () => {
        return (
            <StyledBox>
                {isFCDemo && <Demo className={style.demo} displayMode="edge" />}
                <div style={{ width: workTaskVehicle ? "12em" : "50%" }} className={classes("fast-calculator-next_logo", style.logo)} />
            </StyledBox>
        )
    }

    const renderFooter = () => {
        const fastCalcUrl = renderRoute(fastCalculatorRoute, { ...matchParams })
        const buttonText = selectedCalcState?.context?.chooseServiceButtonLabel
        const buttons = selectedCalcState?.buttonGroups

        if (selectedCalcState?.type == FastCalculator.ECalcState.LastCalcPreviewNext || !buttons?.length) {
            return
        }

        if (!isLoading) {
            return (
                <div className={style.widgetActions}>
                    {buttons.map((buttonGroup: FastCalculator.CalcStateButtonGroup) =>
                        buttonGroup?.buttons?.map((button: FastCalculator.CalcStateButton) => (
                            <CalcStateButton
                                onClick={() => {
                                    TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarServiceCalc)
                                }}
                                item={button}
                                key={button.id}
                                changeFCRoute={handleChangeRoute}
                                isFromWidget
                            />
                        ))
                    )}
                    <LinkButton
                        onClick={() => {
                            TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarFastCalculator)
                        }}
                        to={fastCalcUrl}
                    >
                        {buttonText}
                    </LinkButton>
                </div>
            )
        }
    }

    const getWidgetProps = () => {
        const widgetClassName = bem(style.widget, className, !hasFastCalculator && "disabled", activeState && isLoading && "loading")

        let widgetProps: WidgetProps = {
            className: widgetClassName,
            cover: renderCover(),
            size: size || "4x2",
        }

        if (workTaskVehicle) {
            widgetProps = {
                ...widgetProps,
                iconName: "service-calculator",
                title: "Fast Calculator",
                active: hasFastCalculator && activeState,
                onClick: handleWidgetClick,
                footer: renderFooter(),
            }
        }

        return widgetProps
    }

    const renderContent = () => {
        let content

        switch (selectedCalcState?.type) {
            case FastCalculator.ECalcState.FastCockpitPreviewNext:
                content = workTaskVehicle && (
                    <StyledBox>
                        <SummaryPreview />
                    </StyledBox>
                )
                break
            case FastCalculator.ECalcState.LastCalcPreviewNext:
                content = (
                    <StyledBox>
                        <LastCalculationComponent
                            fastCalculatorRoute={fastCalculatorRoute}
                            changeFCRoute={handleChangeRoute}
                            selectedContext={selectedCalcState?.context as CalculationWidgetContext | undefined}
                            selectedCalcState={selectedCalcState}
                        />
                    </StyledBox>
                )
                break
            default:
                break
        }

        if (isError) {
            content = <ErrorMessage titleID={787} descriptionID={401} />
        }

        return content
    }
    // #endregion

    if (!workTaskVehicle || !hasFastCalculator) {
        return <Widget height={height} {...getWidgetProps()} />
    }
    // else if ( isLoading)
    //     return <Widget active height={props.height} className={bem(style.widget, "loading")}><Loader visible={activeState} /></Widget>

    return (
        <Widget height={height} {...getWidgetProps()}>
            {isFCDemo && <Demo className={style.demo} displayMode="edge" />}
            {isLoading ? <StyledLoader /> : renderContent()}
        </Widget>
    )
}

export function FastCalculatorWidget(props: Props) {
    const [hasWorktaskId, setHasWorktaskId] = useState(false)
    const worktask = useWorkTask()?.workTask

    useEffect(() => {
        if (!hasWorktaskId && worktask) {
            setHasWorktaskId(true)
        }
    }, [worktask, hasWorktaskId])

    const context = useMemo(() => ({ storeInstance: StoreInstanceEnum.normal, worktaskId: worktask?.id }), [worktask?.id])

    if (!hasWorktaskId) {
        return null
    }

    return (
        <StoreContext.Provider value={context}>
            <WidgetComp {...props} />
        </StoreContext.Provider>
    )
}

const StyledLoader = styled(Loader)({
    position: "absolute",
    top: percent(50),
    left: percent(50),
    zIndex: 1,
})

const StyledBox = styled(Box)({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
})

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        widget: {
            $nest: {
                "&--loading .widget__content": {},
                "&--disabled": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                },
                "&--loading": {
                    pointerEvents: "none",
                    $nest: {
                        ">:not(.loader)": {
                            opacity: theme.opacity.disabled,
                        },
                    },
                },
                ".widget__content > div": {
                    flexWrap: "wrap",
                },
                "&--hostettler-fast-calc": {
                    flex: "1 1 auto",
                },
            },
        },
        logo: {
            height: em(12),
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        },
        widgetActions: {
            display: "flex",
            flexWrap: "wrap",
            $nest: {
                ".btn": {
                    marginBottom: theme.margin.m,
                },
            },
        },
        demo: {
            zoom: 3,
            position: "absolute",
            top: 0,
            right: 0,
        },
    })(WidgetComp)
}
