import { ajaxAuth } from "@tm/utils"
import { getBasketServiceUrl } from "../.."
import {
    CalculateWorkTaskBasketRequest,
    CalculateWorkTaskBasketResponse,
    ShowWorkTaskBasketRequest,
    ShowWorkTaskBasketResponse,
    CalculateCentralOrderRequest,
    CalculateCentralOrderResponse,
    CalculateBonusPointsRequest,
    CalculateBonusPointsResponse,
} from "../../model"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Basket`
}

export function showWorkTaskBasket(body: ShowWorkTaskBasketRequest) {
    const url = `${getServiceUrl()}/ShowWorkTaskBasket`
    return ajaxAuth<ShowWorkTaskBasketResponse>({ url, body }, undefined, undefined, true)
}

export function calculateWorkTaskBasket(body: CalculateWorkTaskBasketRequest) {
    const url = `${getServiceUrl()}/CalculateWorkTaskBasket`
    return ajaxAuth<CalculateWorkTaskBasketResponse>({ url, body, method: "POST" })
}

export function calculateBonusPoints(body: CalculateBonusPointsRequest) {
    const url = `${getServiceUrl()}/CalculateBonusPoints`
    return ajaxAuth<CalculateBonusPointsResponse>({ url, body, method: "POST" })
}

export function calculateCentralOrder(body: CalculateCentralOrderRequest) {
    const url = `${getServiceUrl()}/CalculateCentralOrder`
    return ajaxAuth<CalculateCentralOrderResponse>({ url, body, method: "POST" })
}
