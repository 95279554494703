import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { validateDmsApiUrl } from "@tm/utils"
import { StatusCodes } from "http-status-codes"
import { useCallback, useEffect, useRef, useState } from "react"

import { DMS } from "@tm/models"
import { connectionChannel, messageChannel, settingsChannel } from "../../business/messaging"
import { useDmsInfo } from "../hooks"

const CHECK_INTERVAL_MS = 30000

type Props = {
    apiUrl: string
    apiKey: string | undefined
}

function Gateway(props: Props) {
    const { apiUrl, apiKey } = props
    const { name: dmsName } = useDmsInfo()
    const { translateText, language } = useLocalization()

    const frame = useRef<HTMLIFrameElement>(null)

    const isConnected = useRef(false)
    const [count, setCount] = useState(0)

    useEffect(() => {
        isConnected.current = false
    }, [apiUrl])

    useEffect(() => {
        let connectionTimeout: number
        const setConnectionTimeout = () => {
            window.clearTimeout(connectionTimeout)
            connectionTimeout = window.setTimeout(
                () => {
                    isConnected.current = false
                },
                CHECK_INTERVAL_MS * 2 - 50
            )
        }

        setConnectionTimeout()

        const heartbeat = window.setInterval(() => {
            if (document.visibilityState !== "hidden") {
                connectionChannel().publish("REQUEST_CONNECTION_STATUS", {})
            } else {
                setConnectionTimeout()
            }
        }, CHECK_INTERVAL_MS)

        const gateway = frame.current?.contentWindow

        const unsub2 = connectionChannel().subscribe("REQUEST_CONNECTION_STATUS", (request) => {
            if (request.forceReload) {
                setCount((prev) => prev + 1)
                return // There is no need to handle this request because the iframe will reload
            }

            if (!gateway || !isConnected.current) {
                connectionChannel().publish("CONNECTION_STATUS_RECEIVED", { isConnected: false, error: translateText(1921).replace("{0}", dmsName) })
                return
            }

            try {
                gateway.postMessage({ type: "showConnectionStatus", language, version: 1, apiKey }, apiUrl)
            } catch (error: any) {
                connectionChannel().publish("CONNECTION_STATUS_RECEIVED", { isConnected: false, error })
            }
        })

        const unsub3 = settingsChannel().subscribe("LOAD_SETTINGS", () => {
            try {
                gateway?.postMessage({ type: "showSettings", language, version: 1, apiKey }, apiUrl)
            } catch {}
        })

        const unsub = messageChannel().subscribe("SEND_DMS_REQUEST", (e) => {
            console.debug("SENDING: ", e)

            if (!gateway || !isConnected.current) {
                messageChannel().publish("DMS_RESPONSE_RECEIVED", { ...e, status: StatusCodes.SERVICE_UNAVAILABLE })
                return
            }

            try {
                gateway.postMessage({ ...e, language, version: 1, apiKey }, apiUrl)
            } catch (error) {
                messageChannel().publish("DMS_RESPONSE_RECEIVED", { ...e, status: StatusCodes.INTERNAL_SERVER_ERROR })
            }
        })

        const handleResponse = (e: MessageEvent) => {
            if (!apiUrl.toLowerCase().startsWith(e.origin.toLowerCase()) || !e.data) {
                return
            }

            if (!DMS.isValidDmsMessageType(e.data.type)) {
                return
            }

            isConnected.current = true
            setConnectionTimeout()

            if (e.data.type === "showConnectionStatus") {
                connectionChannel().publish("CONNECTION_STATUS_RECEIVED", {
                    isConnected: e.data.response?.isConnected ?? false,
                    error: e.data.response?.error,
                })
                return
            }

            if (e.data.type === "showSettings" && e.data.status === StatusCodes.OK) {
                if (e.data.response) {
                    settingsChannel().publish("SETTINGS_LOADED", e.data.response)

                    if (e.data.response?.application) {
                        const dmsInfo = {
                            name: e.data.response.application.id,
                            icon: e.data.response.application.icon,
                        }

                        ;(window as any).__dmsInfo = dmsInfo
                        localStorage.setItem("DMS_INFO", JSON.stringify(dmsInfo))
                    }
                }

                return
            }

            console.debug("RECEIVING: ", e.data)

            messageChannel().publish("DMS_RESPONSE_RECEIVED", e.data)
        }

        window.addEventListener("message", handleResponse, false)

        return () => {
            window.clearTimeout(connectionTimeout)
            window.clearInterval(heartbeat)
            unsub2()
            unsub3()
            unsub()
            window.removeEventListener("message", handleResponse, false)
        }
    }, [apiUrl, apiKey, language])

    const handleFrameLoaded = useCallback(() => {
        isConnected.current = true
        connectionChannel().publish("REQUEST_CONNECTION_STATUS", {})
        settingsChannel().publish("LOAD_SETTINGS", {})
    }, [])

    return (
        <iframe
            title="dms-gateway"
            id="dms-gateway"
            src={`${apiUrl}?forceReload=${count}`}
            height="1"
            width="1"
            ref={frame}
            onLoad={handleFrameLoaded}
            style={{
                position: "absolute",
                visibility: "hidden",
            }}
        />
    )
}

export default function Wrapper() {
    const { apiUrl, apiKey } = useUser().userSettings?.dmsSettings ?? {}

    if (!apiUrl || !validateDmsApiUrl(apiUrl)) {
        return null
    }

    return <Gateway apiUrl={apiUrl} apiKey={apiKey} />
}
