import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    Accordion,
    AccordionDetails,
    MuiAccordionSummary,
    Box,
    Button,
    ExpandMore as ExpandMoreIcon,
    Loader,
    Icon,
    styled,
    Typography,
} from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel, IMicros } from "@tm/models"
import { useGetMemoInfo } from "../../data/hooks"

type StyledProps = {
    isModal?: boolean
}

const StyledWrapperBox = styled(Box)({
    margin: "1.5em 0",
})

// TODO: This component needs to be added in @tm/components
const StyledAccordion = styled(Accordion, {
    shouldForwardProp: (prop) => prop !== "isModal",
})<StyledProps>((props) => {
    const { isModal, theme } = props

    // Normal Accordion
    if (isModal) {
        return {
            margin: `${theme?.margin?.m} 0`,
            backgroundColor: "#f6f6f6",
        }
    }

    // Slim Accordion
    return {
        margin: `${theme?.margin?.m} 0`,
        backgroundColor: "transparent",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&.Mui-expanded": {
            minHeight: "30px",
            margin: `${theme?.margin?.m} 0`,
        },
    }
})

// TODO: This component needs to be added in @tm/components or migrated to the existing customized `AccordionSummary` there
const StyledAccordionSummary = styled(MuiAccordionSummary, {
    shouldForwardProp: (prop) => prop !== "isModal",
})<StyledProps>((props) => {
    // Normal Accordion
    if (props.isModal) {
        return {
            backgroundColor: "#f6f6f6",
            flexDirection: "row-reverse",
        }
    }

    // Slim Accordion
    return {
        backgroundColor: "transparent",
        flexDirection: "row-reverse",
        minHeight: "30px",
        "& .MuiAccordionSummary-content": {
            margin: "5px 0",
        },
        "&.Mui-expanded": {
            minHeight: "30px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "5px 0",
        },
    }
})

// TODO: This component needs to be added in @tm/components
const StyledAccordionDetails = styled(AccordionDetails)({
    padding: "8px 16px 8px 40px",
})

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme?.margin?.m,
}))

type Props = IMicros["memo-tool"]["memo-info"]

function MemoInfoMicro(props: Props) {
    const { translateText } = useLocalization()
    const { vehicleId, manufacturer, isModal } = props

    if (!vehicleId) {
        return null
    }

    const [expanded, setExpanded] = useState<number>(0)
    const memoInfoRef = useRef<HTMLDivElement>(null)

    const handleChange = useCallback(
        (panel: number) => (event: SyntheticEvent<Element, Event>, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : 0)
        },
        [setExpanded]
    )

    const { repairInstructions, isLoading, error } = useGetMemoInfo(manufacturer, vehicleId)

    useEffect(() => {
        if (!isModal && !isLoading && !!repairInstructions) {
            memoInfoRef.current?.scrollIntoView()
        }
    }, [isLoading, repairInstructions, isModal])

    const renderError = useCallback(() => {
        if (!repairInstructions?.length) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(13216), closeDelay: 3000, skin: "danger" })
        }
        return <Typography variant="body2">{translateText(558)}</Typography>
    }, [repairInstructions])

    const renderContent = useMemo(
        () =>
            repairInstructions?.map((repairInstruction) => (
                <StyledAccordion
                    isModal={isModal}
                    key={repairInstruction.id}
                    expanded={expanded === repairInstruction.id}
                    onChange={handleChange(repairInstruction.id)}
                >
                    <StyledAccordionSummary isModal={isModal} expandIcon={<ExpandMoreIcon />}>
                        {repairInstruction.title}
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        {repairInstruction?.instructions?.map((instruction, key) => (
                            <StyledButton
                                key={`${repairInstruction.id}_${key}`}
                                startIcon={<Icon name="file-pdf" />}
                                href={`http:${instruction.link}`}
                                target="_blank"
                            >
                                {instruction.title}
                            </StyledButton>
                        ))}
                    </StyledAccordionDetails>
                </StyledAccordion>
            )),
        [repairInstructions, expanded]
    )

    return (
        <StyledWrapperBox ref={memoInfoRef}>
            {isLoading && <Loader />}
            {!error && !isLoading && renderContent}
            {(error || !repairInstructions?.length) && !isLoading && renderError()}
        </StyledWrapperBox>
    )
}
export default MemoInfoMicro
