import { RepairTimeProvider } from "@tm/models"
import { OfferDetails } from "../../../data/model"

export type OfferState = {
    costEstimationDetailsLoading: boolean
    note: string
    offerDetails: OfferDetails
}

export type SelectedWorkInfo = {
    isIncluded: boolean
}

export enum PartListItemActions {
    Details = 1,
    RepairTimes = 2,
}

export enum VisibilityStatus {
    allVisible = 1,
    noneVisible = 2,
    someVisible = 3,
}

export type ExpandedRepairTimes = {
    providerId: RepairTimeProvider
    expandedWorks: Array<number | string>
}

export enum EditWorkMode {
    editCustomWork,
    replaceWorkWithCustomWork,
    editRepairTime,
}
