import { AddWholesalerPartListRequest, WholesalerPart } from "@tm/models"
import { encodeUniqueId, renderRoute, showWarehouseDataMissingError, TmaHelper, uniqueId } from "@tm/utils"

import { getBundleParams } from "../../../utils"
import { PostMessageRequest } from "../../../data"
import { getBasketPositionMemo, getCatalogPartMemo } from "../business/helpers"
import { PostMessageControllerComponent } from "../component"

export default function handleAddWholesalerPartsToBasket(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { addWholesalerPartsToBasket, sourceId } = data

    if (!addWholesalerPartsToBasket || !addWholesalerPartsToBasket.length) {
        return
    }

    const {
        workTask,
        userSettings,
        workTaskTruckData,
        erpSystemConfig,
        warehouseData,
        refetchWarehouseData,
        localization,
        history,
        addWholesalerPartList,
    } = this.props

    if (!warehouseData || warehouseData.hasErrors) {
        showWarehouseDataMissingError(localization.translateText)
        refetchWarehouseData()
        return
    }

    const memo = getBasketPositionMemo(userSettings?.orderOptions, workTask, workTaskTruckData)

    const wholesalerParts: WholesalerPart[] = []
    addWholesalerPartsToBasket.forEach((x) => {
        if (x.wholesalerArticleNumber) {
            wholesalerParts.push({
                wholesalerArticleNumber: x.wholesalerArticleNumber.trim(),
                description: x.description,
                additionalDescription: undefined,
                manufacturerName: undefined,
                productGroupName: undefined,
                quantityValue: x.quantity,
                memo: getCatalogPartMemo(x.orderMemo, memo),
                warehouseId: warehouseData.defaultWarehouse?.id,
                warehouseName: warehouseData.defaultWarehouse?.name,
                distributorId: erpSystemConfig?.id,
                distributorName: erpSystemConfig?.description,
            })
        }
    })

    if (wholesalerParts.length) {
        const workTaskId = workTask?.id || uniqueId()

        const request: AddWholesalerPartListRequest = {
            workTaskId,
            customerId: workTask?.customer?.id,
            vehicleId: workTask?.vehicle?.id,
            wholesalerParts,
            log: TmaHelper.PostMessage.AddToBasket.GetUserInteractionLog(sourceId),
        }

        if (!workTask?.id) {
            let url = `/${encodeUniqueId(workTaskId)}`

            const { offersDetailsUrl } = getBundleParams()
            if (sourceId === "TM_ACTIONS_APP" && offersDetailsUrl) {
                url = renderRoute(offersDetailsUrl, { workTaskId: encodeUniqueId(workTaskId) })
            }

            history.push(url)
        }

        addWholesalerPartList(request)
    }
}
