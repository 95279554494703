import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { BikeModel, CarManufacturer, CarModel, RegistrationNoType, TruckModel, VehicleShortInfo, VehicleType } from "@tm/models"
import { parseQueryString, renderRoute, TmaHelper, useRegNoCaption } from "@tm/utils"
import { useEffect, useMemo, useState } from "react"

import { useHistory, useLocation, useRouteMatch } from "react-router"
import { VrmLookupResponse } from "@bundles/vehicle/data/repositories/vrm-lookup"
import { Box } from "@mui/material"
import { bundleChannel, createVehicleFromModel, deleteImportedVehicleDetails } from "../../../business"
import { Filters, ManufacturersResponse, useFilters, useSetAvailableFilters } from "../../../data/hooks"
import { getVehicleDetailsUrl } from "../../../helpers/routing"
import ModelListComponent from "../../_shared/ModelList"
import { VehicleParams } from "../OldSearchResultList"
import { CustomerVehicles } from "./CustomerVehicles"
import ManufacturerListComponent from "../../_shared/ManufacturerList"
import { useSelectedVehicleLookup } from "../../../data/hooks/useSelectedLookupConfig"
import { getBundleParams } from "../../../utils"
import { RouteParams, VehicleSearchProps } from "../ComponentSwitch"

export default function ResultLists(
    props: VehicleSearchProps & {
        results: VrmLookupResponse
        manufacturerResponse?: ManufacturersResponse
        lookupTypeId?: RegistrationNoType
        manufacturerId?: number
        setManufacturerId?(manufacturerId?: number): void
        onApplyModel?(model: CarModel | BikeModel | TruckModel): void
    }
) {
    const { vehicleType, results, importedDataLoading, autoApplySingleVehicle, allowVehicleChange } = props
    const { topManufacturers = [], primaryManufacturers = [], secondaryManufacturers = [] } = props.manufacturerResponse ?? {}
    const { customerVehicles, carModels } = results

    const showManufacturers =
        topManufacturers.length + primaryManufacturers.length + secondaryManufacturers.length > 1 &&
        (!!props.manufacturerId || (results?.carModels?.length || 0) > 1) // If the vehicle is found by the tecdoc id, we might get multiple manufacturers by the query but only one model

    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch<RouteParams>()
    const [filters, setFilters] = useFilters(vehicleType)
    const { translateText } = useLocalization()
    const { attachToWorkTask } = useWorkTask() ?? {}
    const setAvailableFilters = useSetAvailableFilters(vehicleType)
    const [model, setModel] = useState<CarModel | BikeModel | TruckModel>()
    const [customerVehicle, setCustomerVehicle] = useState<VehicleShortInfo>()
    /** @todo Rethink this: set column label based on type of vehicle search */
    const showRegNoColumn: boolean | string = translateText(useRegNoCaption(props.lookupTypeId)) || false

    useEffect(() => {
        // importedDataLoading check can be removed after the dms data is being sent via url parameters
        if (!importedDataLoading && autoApplySingleVehicle && results?.carModels.length === 1) {
            handleApply(results?.carModels[0])
        }

        setModel(undefined)
    }, [results, importedDataLoading, autoApplySingleVehicle, allowVehicleChange])

    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicleType)

    useEffect(() => {
        return bundleChannel().subscribe("APPLY_BUTTON_CLICKED", () => {
            if (customerVehicle) {
                handleApplyCustomerVehicle(customerVehicle)
            } else if (model) {
                handleApply(
                    model,
                    model.registrationNoDetails?.plateId,
                    model.registrationNoDetails?.vin,
                    model.registrationNoDetails?.initialRegistration
                )
            }
        })
    }, [model, customerVehicle])

    useEffect(() => {
        if (customerVehicle || model) {
            bundleChannel().publish("SET_APPLY_BUTTON_STATUS", { disabled: false })
        }

        return () => bundleChannel().publish("SET_APPLY_BUTTON_STATUS", { disabled: true })
    }, [model, customerVehicle])

    useEffect(() => {
        setAvailableFilters({
            bodyTypes: results?.filters?.bodyTypes ?? [],
            modelYears: results?.filters?.modelYears ?? [],
            fuelTypes: results?.filters?.fuelTypes ?? [],
            engineCodes: results?.filters?.engineCodes ?? [],
            engineCapacities: results?.filters?.engineCapacities ?? [],
            sorting: false,
        })

        // If the result was loaded and the selected filters contain any value
        // that is not supplied as possible filter by the service remove it
        // MLE 26.09.22: Added because of NEXT-21544
        setFilters((oldFilters) => {
            if (!results) {
                return oldFilters
            }

            const newFilters = { ...oldFilters }

            // The rules defines which filter key will be removed when it is not available in the modelResponse
            const rules: Array<[unknown[] | undefined, keyof Filters]> = [
                [results.filters?.bodyTypes, "bodyTypeId"],
                [results.filters?.modelYears, "modelYear"],
                [results.filters?.fuelTypes, "fuelType"],
                [results.filters?.engineCodes, "engineCode"],
                [results.filters?.engineCapacities, "engineCapacity"],
            ]

            rules.forEach(([items, propertyName]) => {
                if (!items?.length) {
                    delete newFilters[propertyName]
                }
            })

            return newFilters
        })
    }, [results, setAvailableFilters, setFilters])

    const shouldCheckAndMergeRegNoDetails = useMemo(() => {
        return new URLSearchParams(location.search).get(VehicleParams.CheckAndMergeRegNoDetails) === "true"
    }, [location.search])

    function handleManufacturerSelect(manufacturer: CarManufacturer) {
        props.setManufacturerId?.(props.manufacturerId !== manufacturer.id ? manufacturer.id : undefined)
    }

    function selectModel(vehicleModel: CarModel | BikeModel | TruckModel) {
        setCustomerVehicle(undefined)
        setModel(vehicleModel)
    }

    function selectCustomerVehicle(vehicle: VehicleShortInfo) {
        setModel(undefined)
        setCustomerVehicle(vehicle)
    }

    function handleApply(
        vehicleModel: CarModel | BikeModel | TruckModel,
        plateId?: string,
        vin?: string,
        initialRegistration?: Date,
        mileAge?: number
    ) {
        const modifiedVehicle = { ...vehicleModel }

        if (modifiedVehicle.countryCode === undefined && selectedVehicleLookup.countryCode) {
            modifiedVehicle.countryCode = selectedVehicleLookup.countryCode
        }

        if (modifiedVehicle.registrationNoTypeId === undefined) {
            modifiedVehicle.registrationNoTypeId = selectedVehicleLookup.lookupTypeId
        }

        props.onApplyModel?.(modifiedVehicle)

        if (props.importedData) {
            plateId = plateId || props.importedData.plateId
            vin = vin || props.importedData.vin
            initialRegistration = initialRegistration || props.importedData.initialRegistration
            mileAge = mileAge || props.importedData.mileAge
        }

        createVehicleFromModel({
            model: modifiedVehicle,
            vehicleType,
            searchQuery: props.query,

            referenceId: props.importedData?.referenceId,

            plateId,
            vin,
            initialRegistration,
            engineCode: props.importedData?.engineCode || filters.engineCode,

            mileAge,
            mileageType: props.importedData?.mileageType,

            nextGeneralInspection: props.importedData?.nextGeneralInspection,
            nextServiceDate: props.importedData?.nextServiceDate,

            registrationNo: props.importedData?.registrationNo,
            registrationNoTypeId: modifiedVehicle.dataSourceId,

            countryCode: modifiedVehicle.countryCode,
            selectedLookupType: modifiedVehicle.registrationNoTypeId,

            checkAndMergeRegNoDetails: shouldCheckAndMergeRegNoDetails,
        }).then((vehicle) => {
            TmaHelper.VehicleSelection.List.Search({ dataSourceId: vehicleModel.dataSourceId, query: props.query })

            attachToWorkTask?.({ vehicle }, allowVehicleChange)
            deleteImportedVehicleDetails()

            history.push(renderRoute(parseQueryString(location.search)?.redirect?.toString() || "/:workTaskId", match.params))
        })
    }

    function handleApplyCustomerVehicle(vehicle: VehicleShortInfo) {
        TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query: props.query })

        attachToWorkTask?.({ vehicle: vehicle.id })
        deleteImportedVehicleDetails()

        history.push(renderRoute(parseQueryString(location.search)?.redirect?.toString() || "/:workTaskId", match.params))
    }

    function handleDetailsClick(vehicleModel: CarModel) {
        history.push(
            getVehicleDetailsUrl(match.params, {
                query: props.query,
                vehicleType,
                vehicleId: vehicleModel.id,
                registrationNo: props.query,
                registrationNoType: vehicleModel.dataSourceId,
                checkAndMergeRegNoDetails: shouldCheckAndMergeRegNoDetails,
                plateId: vehicleModel.registrationNoDetails?.plateId,
                vin: vehicleModel.registrationNoDetails?.vin,
                initialRegistration: vehicleModel.registrationNoDetails?.initialRegistration,
            })
        )
    }

    function handleDetailsClickForCustomerVehicle(vehicle: VehicleShortInfo) {
        history.push(
            getVehicleDetailsUrl(match.params, {
                query: props.query,
                vehicleType,
                vehicleId: vehicle.id,
            })
        )
    }

    /** @todo Remove and let the service set the vin as `registrationDetails.vin` for each `CarModel` in the response */
    // If the current search result is from DAT vin lookup set the initial vin to the search query
    let initialVin
    if (props.lookupTypeId === RegistrationNoType.DatVin) {
        initialVin = props.query
    }

    return (
        <>
            {!!customerVehicles?.length && !getBundleParams().disableCustomerVehicle && (
                <CustomerVehicles
                    vehicleType={vehicleType}
                    vehicles={customerVehicles}
                    selectedVehicle={customerVehicle}
                    limitItems={!!carModels?.length}
                    onSelect={selectCustomerVehicle}
                    onApply={handleApplyCustomerVehicle}
                    onDetailsClick={handleDetailsClickForCustomerVehicle}
                />
            )}
            {!!carModels?.length && (
                <ModelListComponent
                    importedData={props.importedData}
                    selectedModel={model}
                    models={carModels}
                    engineCodeFilter={filters.engineCode}
                    onSelect={selectModel}
                    onApply={handleApply}
                    onDetailsClick={handleDetailsClick}
                    onSelectEngineCode={(engineCode) => setFilters((current) => ({ ...current, engineCode }))}
                    initialVin={initialVin}
                    vehicleType={vehicleType}
                    showManufacturerThumbnail
                    showModelThumbnail={vehicleType !== VehicleType.CommercialVehicle}
                    showRegNoColumn={showRegNoColumn}
                    customerVehiclesAvailable={!!customerVehicles?.length}
                    searchQuery={props.query}
                    inlineFilterElement={
                        showManufacturers && (
                            <ManufacturerListComponent
                                primaryManufacturers={primaryManufacturers}
                                secondaryManufacturers={secondaryManufacturers}
                                topManufacturers={topManufacturers}
                                selectedManufacturerId={props.manufacturerId}
                                onSelect={handleManufacturerSelect}
                            />
                        )
                    }
                />
            )}
        </>
    )
}
