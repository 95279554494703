import { useLocalization } from "@tm/localization"
import {
    Button,
    CellContentPosition,
    Icon,
    Loader,
    TableCellData,
    TableColumnData,
    TableRowData,
    Typography,
    Tooltip,
    Box,
    styled,
    css,
} from "@tm/components"
import { channel, IMicros, SystemType, TmaEModule } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { useMicro } from "@tm/morpheus"
import { Scrollbar } from "@tm/controls"
import { important } from "csx"
import { useEffect, useState } from "react"
import { TmaHelper } from "@tm/utils"
import { StyledTable } from "./StyledComponents"
import { ImageMapAreaV2Dto, PartSectionV2Dto, PartV2Dto } from "../../models"
import { graphicActions, useEdsStore } from "../../data/state"
import { createArticleListRequest } from "../../data/helpers"

type Props = {
    loading?: boolean
    areas: ImageMapAreaV2Dto[]
    selectedArea?: ImageMapAreaV2Dto
    partSections: PartSectionV2Dto[]
}

const ActionsCell = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: "1em",
})
const StyledNoteBox = styled(Box)(() => ({
    display: "flex",
    gap: "1em",
}))
const StyledScrollbar = styled(Scrollbar)({
    overflowX: important("hidden"),
    marginBottom: important("0px"),
})

// hacky way to gnereate a className to pass it to a micro
const actionButtonCss = css({
    userSelect: "all",
    cursor: "pointer",
})

export function OeArticleList({ loading, areas, selectedArea, partSections }: Props) {
    const { translateText } = useLocalization()
    const systemType = useUser()?.userContext?.system.systemType
    const { renderMicro } = useMicro<IMicros>()

    const vehicle = useEdsStore.getState()?.vehicle?.data?.yqVehicle
    const vin = useEdsStore.getState()?.vehicle?.data?.vin

    const parts = partSections.flatMap((partSection) => partSection.parts)

    const [disableRowFocus, setDisableRowFocus] = useState(false)

    useEffect(() => {
        if (selectedArea) {
            scrollToActiveRow()
        }
    }, [selectedArea])

    const handleOpenOeArticleSearch = (part: PartV2Dto) => () => {
        const request = createArticleListRequest(part, parts)

        if (!request) {
            return
        }

        request.skipTma = true
        request.ignoreNewModal = true
        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SubmitExternal(request.direct?.query, TmaEModule.EXTERNAL_DATA_SOURCE, { vin })

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(32).toLocaleUpperCase() },
            { header: translateText(12658).toLocaleUpperCase() },
            { header: translateText(617).toLocaleUpperCase() },
            { alignContent: CellContentPosition.right },
        ]

        return columns
    }

    function onRowClick(row: TableRowData) {
        setDisableRowFocus(true)
        const selectedAreaFromTable = areas?.find((area) => area.areaCode === row.cells[0].displayValue)
        graphicActions.setSelectedArea(selectedAreaFromTable)
    }

    function renderActionsCell(item: PartV2Dto) {
        const partspecs = item.attributes?.find((atribute) => atribute.code === "partspec")
        const notes = item.attributes?.find((attribute) => attribute.code === "note")
        const comment1 = item.attributes?.find((atribute) => atribute.code === "comment1")
        const comment2 = item.attributes?.find((atribute) => atribute.code === "comment2")
        const manufacturerNote = item.attributes?.find((atribute) => atribute.code === "manufacturerNote")
        const hasNotes = !partspecs?.values && !notes?.values && !comment1?.values && !comment2?.values && !manufacturerNote?.values

        return (
            <ActionsCell>
                {item.attributes?.length && (
                    <Tooltip
                        variant="light"
                        disableHoverListener={hasNotes}
                        title={
                            <>
                                <Typography fontWeight={600}>{translateText(14)}:</Typography>
                                {partspecs?.values && (
                                    <StyledNoteBox>
                                        <Typography fontWeight="bold">●</Typography>
                                        <Box>
                                            {Object.values(partspecs.values).map((partspec, index) => (
                                                <Typography key={index}>{partspec.replace(";", "; ")}</Typography>
                                            ))}
                                        </Box>
                                    </StyledNoteBox>
                                )}
                                {notes?.values && (
                                    <StyledNoteBox>
                                        <Typography fontWeight="bold">●</Typography>
                                        <Box>
                                            {Object.values(notes.values).map((note, index) => (
                                                <Typography key={index}>{note.replace(";", "; ")}</Typography>
                                            ))}
                                        </Box>
                                    </StyledNoteBox>
                                )}
                                {comment1?.values && (
                                    <StyledNoteBox>
                                        <Typography fontWeight="bold">●</Typography>
                                        <Box>
                                            {Object.values(comment1.values).map((comment, index) => (
                                                <Typography key={index}>{comment.replace(";", "; ")}</Typography>
                                            ))}
                                        </Box>
                                    </StyledNoteBox>
                                )}
                                {comment2?.values && (
                                    <StyledNoteBox>
                                        <Typography fontWeight="bold">●</Typography>
                                        <Box>
                                            {Object.values(comment2.values).map((comment, index) => (
                                                <Typography key={index}>{comment.replace(";", "; ")}</Typography>
                                            ))}
                                        </Box>
                                    </StyledNoteBox>
                                )}
                                {manufacturerNote?.values && (
                                    <StyledNoteBox>
                                        <Typography fontWeight="bold">●</Typography>
                                        <Box>
                                            {Object.values(manufacturerNote.values).map((note, index) => (
                                                <Typography key={index}>{note.replace(";", "; ")}</Typography>
                                            ))}
                                        </Box>
                                    </StyledNoteBox>
                                )}
                            </>
                        }
                    >
                        <Icon name="info" color={hasNotes ? "lightgrey" : ""} />
                    </Tooltip>
                )}

                {systemType === SystemType.Redesign &&
                    renderMicro?.("standalone", "rd-eds-openOeArticleList", {
                        type: "button",
                        className: actionButtonCss.name,
                        part: item,
                        getParts: () => parts,
                        children: <Icon name="search" />,
                        title: translateText(22),
                        manufacturerName: vehicle?.brand,
                        size: "small",
                    })}

                {systemType === SystemType.Next && (
                    <Button size="small" title={translateText(22)} onClick={handleOpenOeArticleSearch(item)}>
                        <Icon name="search" />
                    </Button>
                )}
            </ActionsCell>
        )
    }

    function renderOeNumberCell(item: PartV2Dto) {
        if (systemType === SystemType.Redesign) {
            return renderMicro?.("standalone", "rd-eds-openOeArticleList", {
                type: "text",
                className: actionButtonCss.name,
                part: item,
                getParts: () => parts,
                children: item.partNumber,
                title: "",
                manufacturerName: vehicle?.brand,
                size: "body2",
            })
        }

        return (
            <Typography variant="body2" className={actionButtonCss.name} onClick={handleOpenOeArticleSearch(item)}>
                {item.partNumber}
            </Typography>
        )
    }

    function getCells(part: PartV2Dto): TableCellData[] {
        return [
            { displayValue: part.areaCode, id: "1" },
            { displayValue: renderOeNumberCell(part), id: "2" },
            { displayValue: part.displayName, id: "3" },
            { displayValue: renderActionsCell(part), id: "4" },
        ]
    }

    if (loading) {
        return (
            <Loader
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    zIndex: 1,
                }}
            />
        )
    }

    const displayData: TableRowData[] = parts
        .filter((part) => !!part.partNumber)
        .map((part, index) => ({
            cells: getCells(part),
            id: `${index}`,
            customRow: false,
            active: selectedArea?.areaCode === part.areaCode,
        }))

    function scrollToActiveRow() {
        if (disableRowFocus) {
            setDisableRowFocus(false)
            return
        }

        const element = document.querySelector(".scrollbar__view .row-active")?.children[0]
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        }
    }

    return (
        <StyledScrollbar>
            <StyledTable columns={getColumns()} rows={displayData} rowCap={2} headerStyle="default" onRowClick={onRowClick} />
        </StyledScrollbar>
    )
}
