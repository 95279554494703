import { Box, Icon, IconButton, Typography } from "@tm/components"
import { FC } from "react"

type UnitNavigationProps = {
    title?: string
    hideNavigation?: boolean
    navigateLeft: () => void
    navigateRight: () => void
}

const UnitNavigation: FC<UnitNavigationProps> = ({ title, hideNavigation, navigateLeft, navigateRight }) => {
    if (hideNavigation) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                <Typography>{title}</Typography>
            </Box>
        )
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%" }}>
            <IconButton onClick={navigateLeft}>
                <Icon name="arrow-left" />
            </IconButton>
            <Typography>{title}</Typography>
            <IconButton onClick={navigateRight}>
                <Icon name="arrow-right" />
            </IconButton>
        </Box>
    )
}

export default UnitNavigation
