import { ChangeItemsResponse, UserInteractionLog } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { useAddToBasketModuleParameters } from "@tm/context-distribution"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useInvalidateWorkTaskBasketQueries } from "../workflow/useInvalidateWorkTaskBasketQueries"

export function useRemovePartList(
    handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>,
    onSuccess?: (response: ChangeItemsResponse | undefined, request: { workTaskId: string; partIds: string[]; isExternalCall?: boolean }) => void
) {
    const { invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()

    const { addToNextBasket } = useAddToBasketModuleParameters()
    const { mutateAsync: removeItemList } = useMutation(
        (request: { workTaskId: string; partIds: string[]; isExternalCall?: boolean; log?: UserInteractionLog }) =>
            Data.removeItemList(request.workTaskId, request.partIds, undefined, request.log),
        {
            onSuccess:
                onSuccess ??
                ((response, request) => {
                    if (response) {
                        if (request.isExternalCall) {
                            invalidatePartsRequests(request.workTaskId)
                        }

                        return handleBasketUpdateWorkflow(request.workTaskId, response)
                    }
                }),
        }
    )

    const removeParts = useCallback(
        (request: { workTaskId: string; partIds: string[]; isExternalCall?: boolean; log?: UserInteractionLog }) => {
            if (addToNextBasket !== false) {
                return removeItemList(request)
            }
            return Promise.reject(new Error("no function"))
        },
        [removeItemList, addToNextBasket]
    )

    return removeParts
}
