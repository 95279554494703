import { Box, Loader, styled } from "@tm/components"
import { useActions, useMicro } from "@tm/morpheus"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useUser } from "@tm/context-distribution"
import { IMicros } from "@tm/models"
import { useCallback } from "react"
import { MainState } from "../../main"
import { Actions } from "../business"
import { RimErpInfo } from "../../../data/model"
import { getBundleParams } from "../../../utils"

type Props = {
    informations: RimErpInfo[]
    onSelect: (rimErpInfo: RimErpInfo) => void
}

const selector = createSelector(
    (s: MainState) => ({
        selectedRimSize: s.wheelsList.base.selectedRimSize,
    }),
    (x) => x
)

export default function StockTable({ informations, onSelect }: Props) {
    const actions = useActions(Actions, "saveSelectedRimSize")
    const { selectedRimSize } = useSelector(selector)
    const showPurchasePrice = useUser().userSettings?.showPurchasePrice
    const { renderMicro } = useMicro<IMicros>()
    const isLKQ = getBundleParams()?.version === "LKQ"

    const renderAvailabilityIcon = useCallback(
        (info: RimErpInfo) => {
            if (!info.availability?.type) {
                return
            }

            return renderMicro("erp", "display-only-availability", {
                availability: info.availability,
                warehouses: info.warehouses,
                ...(!isLKQ && { isCompact: true }),
                ...(isLKQ && { tour: info.tour }),
            })
        },
        [informations.length]
    )

    const renderPrice = useCallback(
        (info: RimErpInfo) => {
            const purchasePrice = info.prices?.[0]
            const retailPrice = info.prices?.[1]

            if (!purchasePrice && !retailPrice) {
                return
            }
            const price = showPurchasePrice ? purchasePrice : retailPrice

            if (!price?.value || !price?.currencySymbol) {
                return <Box>{`${price?.value.toFixed(2)}`}</Box>
            }

            return <Box>{`${price.value.toFixed(2)} ${price.currencySymbol}`}</Box>
        },
        [informations, showPurchasePrice]
    )

    const handleSelect = (info: RimErpInfo) => {
        onSelect(info)
        actions.saveSelectedRimSize(info)
    }

    return (
        <Box pl={0.5} pr={0.5} mb={0.5}>
            {informations.map((info: RimErpInfo, index: number) => {
                return (
                    <StyledRow key={index} isSelected={info == selectedRimSize} onClick={() => handleSelect(info)} isLKQ={isLKQ}>
                        <StyledDescriptionBox> {info.description} </StyledDescriptionBox>
                        {info.loading && <Loader size="small" sx={{ justifyContent: "end" }} />}
                        <StyledBox> {renderAvailabilityIcon(info)} </StyledBox>
                        <Box textAlign="right"> {renderPrice(info)} </Box>
                    </StyledRow>
                )
            })}
        </Box>
    )
}

const StyledRow = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isLKQ",
})<{ isSelected: boolean; isLKQ: boolean }>(({ theme, isSelected, isLKQ }) => ({
    display: "grid",
    gridTemplateColumns: isLKQ ? "1.2fr 1.8fr 1fr" : "1.8fr 0.8fr 1.4fr",
    justifyContent: "space-between",
    alignItems: "center",
    border: `solid ${theme.margin?.xs} transparent`,
    padding: theme.margin?.s,
    fontSize: theme.font?.textSize.s,
    height: theme.overwrites?.toolkits?.wheels?.rimList?.stockTableRow ?? "1.8rem",
    ":hover": {
        border: `solid ${theme.margin?.xs} ${theme.colors?.primary}`,
        borderRadius: theme.radius?.default,
    },
    ...(isSelected && {
        border: `solid ${theme.margin?.xs} ${theme.colors?.primary}`,
        borderRadius: theme.radius?.default,
    }),
    ":nth-of-type(even)": {
        background: "#efefef",
    },
}))

const StyledDescriptionBox = styled(Box)({
    lineHeight: "1.5em",
})

const StyledBox = styled(Box)(() => ({
    textAlign: "center",
    "& .image": {
        maxHeight: "1.3rem",
    },
    ".availability--has-image .availability__image": {
        maxHeight: "1.3rem",
    },
}))
