import { TyreFilter, TyresUsedCriteria } from "@tm/models"
import { AttributeFiltersResponse } from "../../../../data/repositories/model"

export function filterUsedCriteria(filters: AttributeFiltersResponse, usedCriteria: TyresUsedCriteria | undefined) {
    if (!usedCriteria || !filters) {
        return undefined
    }

    const availableLoadIndexes = filters.loadIndex?.map((x: TyreFilter) => x.query) ?? []
    const availableSpeedIndexes = filters.speedIndex?.map((x: TyreFilter) => x.query) ?? []

    const adjustedLoadIndex = usedCriteria.loadIndex?.filter((l: TyreFilter) => availableLoadIndexes.includes(l.query))
    const adjustedSpeedIndex = usedCriteria.speedIndex?.filter((s: TyreFilter) => availableSpeedIndexes.includes(s.query))

    return {
        loadIndex: adjustedLoadIndex,
        speedIndex: adjustedSpeedIndex,
    }
}
