import { Article, ArticleListSortingMode, AttributeFilterModel, GetProductGroupTopicIdsResponse } from "@tm/models"
import { chunk } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ArticleGroupHeader, Props as ArticleGroupHeaderProps } from "../../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { useVehicleRecordsData } from "../hooks/useVehicleRecords"
import { ArticleGroupParams } from "../models"
import { DefaultArticleItem } from "./DefaultArticleItem"

type Props = {
    group: ArticleGroupParams
    currentSorting: ArticleListSortingMode | undefined
    onChangeSorting(productGroupId: number, sorting: ArticleListSortingMode | undefined): void
    calculatorRoute?: string
    productGroupTopicIds?: GetProductGroupTopicIdsResponse
    showFilterArea?: boolean
}

type PropsWithVehicleRecords = Props & {
    showFilterOptions: boolean
    usedAttributeFilters: string[]
    prefilledAttributeFilters?: string[]
    onToggleAttributeFilter(attribute: AttributeFilterModel): void
    onResetAttributeFilters(): void
}

export function ArticleGroup(props: Props | PropsWithVehicleRecords) {
    const { group, currentSorting, onChangeSorting, calculatorRoute, productGroupTopicIds } = props

    const filterAreaProps = useFilterAreaProps("showFilterOptions" in props ? props : undefined)
    const handleChangeSorting = useCallback(
        (value: ArticleListSortingMode | undefined) => onChangeSorting(group.productGroup.id, value),
        [group, onChangeSorting]
    )

    return (
        <>
            <ArticleGroupHeader
                title={group.productGroup.name}
                productGroupId={group.productGroup.id}
                groupArticlesCount={!group.incomplete ? group.articles?.length : undefined}
                currentSorting={currentSorting}
                onChangeSorting={handleChangeSorting}
                calculatorRoute={calculatorRoute}
                filterAreaProps={filterAreaProps}
                productGroupTopicIds={productGroupTopicIds}
            />
            {chunk(group.articles, 10).map((articles, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ArticleChunk key={index} articles={articles} delayInMs={index * 500} />
            ))}
        </>
    )
}

function ArticleChunk(props: { articles: Article[]; delayInMs?: number }) {
    const delayInMs = Math.abs(props.delayInMs ?? 0)
    const [show, setShow] = useState(delayInMs === 0)

    useEffect(() => {
        if (delayInMs === 0) {
            return
        }

        window.setTimeout(() => setShow(true), delayInMs)
    }, [delayInMs])

    if (!show) {
        return null
    }

    return (
        <>
            {props.articles.map((article) => (
                <DefaultArticleItem key={article.id} article={article} />
            ))}
        </>
    )
}

function useFilterAreaProps(props: PropsWithVehicleRecords | undefined): ArticleGroupHeaderProps["filterAreaProps"] | undefined {
    const { usedAttributeFilters, onToggleAttributeFilter, onResetAttributeFilters, group, showFilterOptions, prefilledAttributeFilters } =
        props ?? {}
    const { productGroupFilters } = useVehicleRecordsData()

    return useMemo<ArticleGroupHeaderProps["filterAreaProps"]>(() => {
        const filterOptions = productGroupFilters?.find((x) => x.id === group?.productGroup.id)
        if (!showFilterOptions || !filterOptions || !usedAttributeFilters || !onToggleAttributeFilter || !onResetAttributeFilters) {
            return
        }

        return {
            filterOptions,
            usedAttributeFilters,
            prefilledAttributeFilters,
            onSelectProductGroupFilter: onToggleAttributeFilter,
            onDeselectProductGroupFilter: onToggleAttributeFilter,
            onDeselectAllProductGroupFilter: onResetAttributeFilters,
        }
    }, [
        productGroupFilters,
        showFilterOptions,
        usedAttributeFilters,
        prefilledAttributeFilters,
        onToggleAttributeFilter,
        onResetAttributeFilters,
        group?.productGroup.id,
    ])
}
