import { useUser } from "@tm/context-distribution"
import { Image, Loader, PanelSection, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, IMicros, RepairTimeProvider, SystemType, TmaEModule, getCurrentWorkTaskId } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import {
    encodeUniqueId,
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    renderRoute,
    RouteComponentProps,
    TmaHelper,
    uniqueId,
    withRouter,
} from "@tm/utils"
import { useSelector } from "react-redux"
import { getBundleParams } from "@bundles/wheels/utils"
import { MainState } from "../../main"

type Props = RouteComponentProps & {
    repairTimesRoute: string
}

function VehiclePanel({ match, repairTimesRoute }: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser()

    const { repairTimeProviders } = getRepairTimeProviders()
    const isLKQ = getBundleParams()?.version === "LKQ"

    const { rimItem, tireItem, sensorItem, configuratorImage, mountingEnabled, mountingItem, loading, vehicle } = useSelector((s: MainState) => ({
        rimItem: s.overview.rimItem,
        tireItem: s.overview.tireItem,
        sensorItem: s.overview.sensorItem,
        configuratorImage: s.overview.configuratorImage,
        mountingEnabled: s.overview.mountingEnabled,
        mountingItem: s.overview.mountingItem,
        loading: s.overview.loading,
        vehicle: s.manager.vehicle,
    }))

    const items = React.useMemo(
        () => [rimItem, sensorItem, tireItem, mountingEnabled ? mountingItem : undefined].filter((x) => !!x) as Article[],
        [rimItem, sensorItem, tireItem, mountingEnabled, mountingItem]
    )

    const getRepairTimesUrl = (article: any, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
        if (article.productGroup && repairTimeProviders.length && userSettings) {
            let provider
            if (Array.isArray(rtProviders)) {
                const activeRTProvider = userSettings.activeVehicleDataProviders.repairTimes

                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider) {
                return
            }

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    workTaskId: encodeUniqueId(uniqueId()),
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide,
                })
            )
        }
    }

    const renderStandAloneButtons = () => {
        return renderMicro!("standalone", "rd-add-articles-to-basket", {
            items,
            buttonText: translateText(133),
            sourceId: "TM_WHEELS",
            tmaEventId: TmaEModule.WHEEL_CONFIGURATOR,
        })
    }

    const renderNextActionsButtons = () => {
        TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext("wheels", "", getCurrentWorkTaskId())

        if (isLKQ) {
            return null
        }

        return (
            <>
                {renderMicro!("basket", "add-to-basket", {
                    data: items,
                    buttonText: translateText(133),
                    disabled: !items.length,
                    vehicleImageBase64: configuratorImage,
                })}
                {repairTimeProviders?.length > 0 &&
                    renderMicro!("basket", "add-to-cost-estimate", {
                        data: items,
                        repairTimeProviders,
                        getRepairTimesUrl,
                        vehicleImageBase64: configuratorImage,
                    })}
            </>
        )
    }

    if (!vehicle) {
        return null
    }

    return (
        <PanelSection className="vehicle_info">
            <div className="flex-wrapper">
                <div className="car_info">
                    <Text modifiers="strong">{`${vehicle.manufacturer} ${vehicle.modelSeries} ${vehicle.model}`}</Text>
                </div>
                {configuratorImage && (
                    <div className="car_logo">
                        <Image url={configuratorImage} />
                    </div>
                )}

                <div className="basket-elements">
                    {loading && <Loader />}
                    {!loading &&
                        !!items.length &&
                        (userContext?.system.systemType !== SystemType.Next ? renderStandAloneButtons() : renderNextActionsButtons())}
                </div>
            </div>
        </PanelSection>
    )
}

export default withRouter(VehiclePanel)
