import { useStyle } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { initSelector } from "@tm/utils"
import { padding, rem } from "csx"
import { useSelector } from "react-redux"
import { Box, Tooltip } from "@tm/components"
import { FC, ReactNode } from "react"
import { constructionYearToString } from "../../../../helper"
import Dictionary, { DictionaryItem, DictionaryValue } from "../../../_shared/dictionary"
import { DocumentsState } from "../../bussiness/model"

const selector = initSelector((s: DocumentsState) => s.carModel)

const VehicleAcceptance: FC = () => {
    const [vehicle] = useSelector(selector)
    const { translateText } = useLocalization()

    if (!vehicle) {
        return null
    }
    const { modelDetails } = vehicle

    if (!modelDetails) {
        return null
    }
    const { vehicleType } = modelDetails
    const carType = parseInt(vehicleType)

    const getPowerHp = (): DictionaryItem | undefined => {
        let item

        if (modelDetails.enginePowerHpFrom) {
            item = `${modelDetails.enginePowerHpFrom}`
        }
        if (modelDetails.enginePowerHpTo) {
            item += ` - ${modelDetails.enginePowerHpTo} ${translateText(330)}`
        }

        if (!item) {
            return
        }
        return { key: `${translateText(94)} (${translateText(330)})`, value: item }
    }

    const getPowerKw = (): DictionaryItem | undefined => {
        let item

        if (modelDetails.enginePowerKwFrom) {
            item = `${modelDetails.enginePowerKwFrom}`
        }
        if (modelDetails.enginePowerKwTo) {
            item += ` - ${modelDetails.enginePowerKwTo} ${translateText(329)}`
        }

        if (!item) {
            return
        }
        return { key: `${translateText(94)} (${translateText(329)})`, value: item }
    }

    function renderTypeId(value: DictionaryValue): ReactNode {
        const newValue = value?.toString() || "-/-"
        const [topmotiveTypeId, tecdocTypeId] = newValue.split("/")

        return (
            <Tooltip title={`${translateText(123)} ${tecdocTypeId ?? "-"}`}>
                <Box minWidth="100px">{topmotiveTypeId ?? "-"}</Box>
            </Tooltip>
        )
    }

    const items: Array<DictionaryItem> = [{ key: translateText(105), value: (modelDetails.engineCodes || []).join(", ") }]

    if (carType == VehicleType.CommercialVehicle && getPowerHp && getPowerKw) {
        const kw = getPowerKw()
        const hp = getPowerHp()

        if (kw && hp) {
            items.push(kw, hp)
        }
    } else {
        items.push({ key: translateText(20), value: `${modelDetails.enginePowerKw}/${modelDetails.enginePowerHp}` })
    }

    items.push({
        key: translateText(13462),
        value: `${modelDetails.topmotiveTypeId ?? "-"} / ${modelDetails.tecdocTypeId ?? "-"}`,
        valueRenderer: renderTypeId,
    })

    const constructionType = {
        key: modelDetails.constructionTypeDescription ? translateText(994) : translateText(303),
        value: modelDetails.constructionTypeDescription ? modelDetails.constructionTypeDescription : modelDetails.bodyType,
    }

    if (
        carType == VehicleType.CommercialVehicle &&
        modelDetails.tonnage &&
        modelDetails.axisConfigDescription &&
        modelDetails.constructionTypeDescription
    ) {
        items.push({ key: translateText(1904), value: modelDetails.axisConfigDescription }, { key: translateText(1905), value: modelDetails.tonnage })
    }

    items.push(
        {
            key: translateText(299),
            value:
                modelDetails.engineTypeDescription && carType == VehicleType.CommercialVehicle
                    ? modelDetails.engineTypeDescription
                    : modelDetails.fuelType || "",
        },
        { key: translateText(298), value: modelDetails.driveType || translateText(1779) },
        { key: constructionType.key, value: constructionType.value },
        { key: translateText(128), value: modelDetails.engineCapacityCcm || translateText(1779) },
        { key: translateText(305), value: modelDetails.cylinderCount || translateText(1779) },
        { key: translateText(304), value: modelDetails.valvesPerCylinder || translateText(1779) },
        {
            key: translateText(306),
            value: modelDetails.engineTypeDescription || "",
        },
        {
            key: translateText(127),
            value: `${constructionYearToString(modelDetails.constructionYearFrom)} - ${constructionYearToString(modelDetails.constructionYearTo)}`,
        }
    )

    return (
        <div className={style.carDetails}>
            <PanelSection>
                <Dictionary items={items} />
            </PanelSection>
        </div>
    )
}

const style = useStyle({
    carDetails: {
        flex: 1,
        padding: padding(0, rem(0.5)),
    },
})(VehicleAcceptance)

export default VehicleAcceptance
