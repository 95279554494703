import { ArticleItem } from "@tm/components"
import { FC } from "react"
import { ArticleListFeaturesDisableProvider } from "../../ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { OeArticleItemStateProvider } from "../../ListV2/components/OeArticle/OeArticleItemStateProvider"
import { PureListArticlesResponse } from "../../ListV2/models"
import { PureArticleItem } from "../PureArticleItem"

type Props = {
    response: PureListArticlesResponse
}

export const ArticlesWithOeArticles: FC<Props> = ({ response }) => {
    if (response?.type === "damageModulesList") {
        return (
            <>
                {response.oeArticles.map((x) => {
                    if (x.article === null && x.oeArticle) {
                        return (
                            <ArticleListFeaturesDisableProvider
                                disableBasketButton
                                disableCostEstimationButton
                                disableReplaceWithQuantity={x.oeArticle.manufacturerName === "DAT"}
                                displayPriceDependingOnQuantity
                            >
                                <OeArticleItemStateProvider article={x.oeArticle}>
                                    <ArticleItem variant="OE" />
                                </OeArticleItemStateProvider>
                            </ArticleListFeaturesDisableProvider>
                        )
                    }
                    if (x.article) {
                        return (
                            <ArticleListFeaturesDisableProvider
                                key={x.article.internalId}
                                disableBasketButton
                                disableCostEstimationButton
                                disableReplaceWithQuantity={false}
                            >
                                <PureArticleItem article={x.article} />
                            </ArticleListFeaturesDisableProvider>
                        )
                    }
                    return null
                })}
            </>
        )
    }
    return null
}
