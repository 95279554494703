import { styled } from "@tm/components"
import { Headline, Icon, Loader, PanelSection } from "@tm/controls"
import { concat, getUIA } from "@tm/utils"
import { PropsWithChildren, RefObject } from "react"

const StyledLoader = styled(Loader)({
    height: "24px",
    width: "24px",
    marginLeft: "1rem",
})

type Props = PropsWithChildren<{
    title: string
    icon?: string
    showLoader?: boolean
    classPostfix?: string
    id?: string
    wrapperRef?: RefObject<HTMLDivElement>
}>

export default function UserSettingsArea(props: Props) {
    return (
        <div id={props.id} ref={props.wrapperRef} {...getUIA("SettingsContainer", props.id)}>
            <PanelSection
                className={concat(" ", "user-settings__area", props.classPostfix && `user-settings__area__${props.classPostfix}`)}
                title={
                    <>
                        {props.icon && <Icon name={props.icon} size="xs" />}
                        <Headline size="s">{props.title}</Headline>
                        <StyledLoader visible={!!props.showLoader} />
                    </>
                }
            >
                {props.children}
            </PanelSection>
        </div>
    )
}
