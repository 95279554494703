import { styled, Box } from "@tm/components"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback, useMemo, useRef } from "react"
import { ArticleGroup } from "../components/ArticleGroup"
import { ArticleListWrapper } from "../components/ArticleListWrapper"
import { ArticleSelection } from "../components/ArticleSelection"
import { NoSearchResult } from "../components/NoSearchResult"
import { useListOptionsContext } from "../ContextProvider"
import { useProductGroupTopicIdsDataContext } from "../ContextProvider/ProductGroupTopicIds"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { useWholeSalerArticleNumberListParamsContext, useWholesalerArticlesContext } from "./ContextProvider"
import { WholesalerArticleItem } from "../components/WholesalerArticleItem"
import { NextPageLoader } from "../components/NextPageLoader"
import { emptyFunc } from "../helpers"
import { useResizeErpColumnInDefaultArticleItem } from "../hooks/useResizeErpContainerColumn"

const ModalArticleListWrapper = styled(ArticleListWrapper)({
    height: "100%",
    paddingLeft: 0,
    paddingRight: 1,
    margin: 1,
    overflow: "hidden",
})

export function WholesalerArticleNumberList() {
    const {
        noResult,
        attributes,
        toggleAttribute,
        setAttributes,
        sorting: { productGroups: sortingProductGroups, setForProductGroups: activateSortingForProductGroups },
        isFiltersLoading,
    } = useWholeSalerArticleNumberListParamsContext()

    const { wholesalerArticles, articleGroups, isFrontendFiltered, isLoading, isStalling, isFailed, isLoaded, isEnabled } =
        useWholesalerArticlesContext()

    const options = useListOptionsContext()

    const productGroupTopicIds = useProductGroupTopicIdsDataContext()

    const mainContainerRef = useRef<HTMLElement>(null)
    useResizeErpColumnInDefaultArticleItem(mainContainerRef.current, !!articleGroups.length)

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const showPageLoader = ((isLoading || isFiltersLoading) && !isFrontendFiltered) || isStalling || !isEnabled

    return (
        <ModalArticleListWrapper>
            <Box ref={mainContainerRef}>
                <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

                {((isLoaded && !isLoading && !articleGroups.length && !wholesalerArticles?.length) || (isFailed && !showPageLoader) || noResult) && (
                    <NoSearchResult searchType="direct" />
                )}

                {articleGroups.map((group) => {
                    switch (group.type) {
                        case "default":
                            return (
                                <ArticleGroup
                                    key={group.productGroup.id}
                                    group={group}
                                    currentSorting={sortingProductGroups[group.productGroup.id]}
                                    onChangeSorting={handleChangeSorting}
                                    showFilterOptions={showVehicleRecordsFilters}
                                    usedAttributeFilters={usedAttributeFilters}
                                    onToggleAttributeFilter={toggleAttribute}
                                    onResetAttributeFilters={handleResetAttributeFilters}
                                    productGroupTopicIds={productGroupTopicIds}
                                />
                            )
                        case "WholesalerArticle":
                        default:
                            return null
                    }
                })}

                {!!wholesalerArticles?.length &&
                    !isFrontendFiltered &&
                    wholesalerArticles.map((wholesalerArticle) => (
                        <WholesalerArticleItem key={wholesalerArticle.wholesalerArticleNumber} article={wholesalerArticle} />
                    ))}

                {showPageLoader && (
                    <NextPageLoader
                        loadNextPage={emptyFunc}
                        canLoadNextArticles={false}
                        skeletonArticleCount={articleGroups.length ? 1 : 5}
                        skeletonCategory
                    />
                )}
            </Box>
        </ModalArticleListWrapper>
    )
}
