import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { Button } from "@tm/controls"
import SubUserEditor from "./SubUserEditor"
import { UserAdminConfig, PermissionContainer } from "../../../data/model"

type Props = {
    reloadSubUsers(): void
    permissionContainer?: PermissionContainer
    userAdminConfig: UserAdminConfig
}

function SubUserCreation(props: Props) {
    const [create, setCreate] = useState(false)
    const { translateText } = useLocalization()

    const handleCloseEditor = (reload: boolean) => {
        setCreate(false)

        if (reload) {
            props.reloadSubUsers()
        }
    }

    return (
        <>
            <Button skin="success" icon="plus" onClick={() => setCreate(true)}>
                {translateText(1244)}
            </Button>
            {create && (
                <SubUserEditor
                    type="create"
                    headline={1244}
                    onclose={handleCloseEditor}
                    userAdminConfig={props.userAdminConfig}
                    permissionContainer={props.permissionContainer}
                />
            )}
        </>
    )
}

export default SubUserCreation
