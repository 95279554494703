import { WorkTaskInfo } from "@tm/context-distribution"
import { Article, CatalogPart, CustomPart, CustomWork, FastCalculation, OePart, TmaEModule, WorkCategory } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { Genart, RecordWorks, RepairTime, SpecificationsGenart } from "../data/models"
import { FastServiceWorkTimes } from "../data/repositories/fastcalculator-getMaintenancePlan/model"

export function createImportCalculationRequest(
    articles: Article[],
    customParts: CustomPart[],
    consumables: SpecificationsGenart[],
    repairTimes: RepairTime[],
    allWorks: RecordWorks,
    defaultHourlyRate?: number,
    worktask?: WorkTaskInfo,
    memo?: string,
    currencyCode?: string,
    oeArticles?: (Genart | undefined)[]
): FastCalculation.ImportFastCalculationRequest | undefined {
    if (!worktask?.vehicle) {
        return
    }

    const works: FastCalculation.RepairTime[] = []
    const customWorks: CustomWork[] = []
    const oeArticleParts: OePart[] = []

    consumables.forEach((consumable) => {
        if (consumable.quantity) {
            customParts.push({
                description: consumable.article?.genArtLabel ?? "",
                quantityValue: consumable.quantity,
                articleNumber: consumable.article?.supplierArtNr,
                garagePrice: consumable.article?.price,
            })
        }
    })

    let repairTimesProvider: number | undefined

    repairTimes.forEach((repairTime) => {
        const hourlyRate = repairTime.alternativeHourlyRate ?? defaultHourlyRate
        const workRepairTime = Object.values(allWorks)
            .flat()
            .find((x) => x.label === repairTime.label)

        if (hourlyRate !== defaultHourlyRate) {
            customWorks.push({
                hourlyRate,
                customWorkNumber: repairTime.repairTimeId ?? " ",
                description: repairTime.label,
                time: repairTime.quantity ?? 0,
                categoryOfWork: WorkCategory.NotCategorized,
            })
        } else if (!repairTime.repairTimeId && !workRepairTime) {
            customWorks.push({
                hourlyRate,
                customWorkNumber: "",
                description: repairTime.label,
                time: repairTime.quantity ?? 0,
                categoryOfWork: WorkCategory.NotCategorized,
            })
        } else {
            // eslint-disable-next-line no-unused-expressions
            workRepairTime?.providerId
                ? works.push({
                      description: repairTime.label,
                      providerWorkId: workRepairTime.providerId,
                      time: repairTime.quantity ?? 0,
                      categoryOfWork: WorkCategory.WorkshopWork,
                      includedWorks: mapIncludedWork(repairTime.included),
                  })
                : works.push({
                      description: repairTime.label,
                      providerWorkId: repairTime.repairTimeId,
                      time: repairTime.quantity ?? 0,
                      categoryOfWork: WorkCategory.WorkshopWork,
                      includedWorks: mapIncludedWork(repairTime.included),
                  })

            repairTimesProvider = repairTime.provider ?? repairTimesProvider
        }
    })

    if (oeArticles) {
        oeArticles.forEach((oeArticle) => {
            if (oeArticle?.fsArticle?.quantity) {
                if (!oeArticle?.fsArticle?.supplierId) {
                    customParts.push({
                        supplierName: oeArticle.fsArticle.supplierName,
                        articleNumber: oeArticle.fsArticle.oeNrs[0],
                        description: oeArticle.fsArticle.oeLabel,
                        quantityValue: oeArticle.fsArticle.quantity,
                        garagePrice: oeArticle.fsArticle.price && oeArticle.fsArticle.price > 0 ? oeArticle.fsArticle.price : undefined,
                    })
                } else {
                    oeArticleParts.push({
                        oeArticleNumber: oeArticle.genArtNr.toString(),
                        quantityValue: oeArticle.fsArticle.quantity,
                        currencyCode: currencyCode ?? "",
                        oePriceValue: oeArticle.fsArticle.price && oeArticle.fsArticle.price > 0 ? oeArticle.fsArticle.price : undefined,
                        description: oeArticle.fsArticle.oeLabel || "",
                    })
                }
            }
        })
    }

    return {
        workTaskId: worktask.id,
        vehicleId: worktask.vehicle.id,
        parts: articles.map((x) => mapArticleToCatalogPart(x, memo)),
        customParts,
        works,
        customWorks,
        repairTimeDivision: 1,
        repairTimesProvider,
        oeParts: oeArticleParts || undefined,
        log: TmaHelper.AddCustomToBasket.GetUserInteractionLog("import", TmaEModule.FAST_SERVICE),
    }
}

function mapArticleToCatalogPart(article: Article, memo?: string): CatalogPart {
    return {
        dataSupplierArticleNumber: article.supplierArticleNo,
        dataSupplierId: article.supplier.id,
        dataSupplierName: article.supplier.name,
        productGroupId: article.productGroup.id,
        productGroupName: article.productGroup.name,
        quantityValue: article.quantity,
        additionalDescription: article.additionalDescription,
        description: article.description,
        fittingSide: article.fittingSide,
        memo,
        thumbnailUrl: article.thumbnail,
        wholesalerArticleNumber: article.traderArticleNo,
    }
}

function mapIncludedWork(includedWorks?: FastServiceWorkTimes[]): FastCalculation.IncludedWork[] {
    if (!includedWorks) {
        return []
    }
    let works: FastCalculation.IncludedWork[] = []
    works = includedWorks.map((item) => {
        return {
            providerWorkId: item.repairTimeId,
            categoryOfWork: item.workType,
            description: item.label,
            time: item.value,
            isvisible: true,
        }
    })
    return works
}
