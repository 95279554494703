import { useLocalization } from "@tm/localization"
import { carMunValidate } from "@tm/utils"
import { FC } from "react"
import { getBundleParams } from "../../../../../../utils"
import { MergedVehicle } from "../../../../../../data/hooks"
import WideButton from "../../wide-button"
import { openWorkTask } from "../../../../../../bundle-channel"

type Props = {
    vehicle: MergedVehicle
}

const VehicleTelematics: FC<Props> = ({ vehicle }) => {
    const { translateText } = useLocalization()

    let route: string | undefined
    const carMunicationRoute = getBundleParams().carmunicationRouteWithoutWorkTaskId
    const telematicsRoute = getBundleParams().telematicsRouteWithoutWorkTaskId

    const shouldOpenCarmunication = carMunicationRoute && carMunValidate(vehicle.vehicleInfo.telematicDeviceId, vehicle.vehicleInfo.vin)

    if (shouldOpenCarmunication) {
        route = carMunicationRoute
    } else if (telematicsRoute && vehicle.vehicleInfo.tecDocManufacturerId === 16) {
        route = telematicsRoute
    }

    if (!route) {
        return null
    }

    const handleClick = () => {
        openWorkTask({ selectedChatVehicle: vehicle, subRoute: route })
    }

    return <WideButton text={translateText(3062)} onClick={handleClick} icon="telematics" />
}

export default VehicleTelematics
