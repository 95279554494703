import { border, em } from "csx"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Card, Grid, Loader, Scrollbar, Text } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { classes, initSelector } from "@tm/utils"
import { ESufficentSelections } from "../../../../data/enums"
import { bem } from "../../../../data/helpers"
import { VKNImage } from "../../../../data/models"
import { CustomTooltip } from "../../../_shared"
import { MainActions, MainState } from "../../../main"
import { Tooltip } from "@tm/components"

const wrapTooltip = (content: JSX.Element, title?: string) => {
    if (title) {
        return (
            <Tooltip color="primary" title={title}>
                {content}
            </Tooltip>
        )
    }

    return content
}

// const { } = useSelector((state: MainState) => ({
//     visibleImages: state.vknImages.items.filter(x => x.isVisible),
//     sufficientSelection: state.extendedCarInfo.sufficientSelection,
//     loading: state.vknImages.loading,
//     selectedVKNImage: state.selectedVKNImage
// }))

const selector = initSelector(({ details }: MainState) => ({
    visibleImages: details.vknImages.items.filter((x) => x.isVisible),
    sufficientSelection: details.extendedCarInfo.sufficientSelection,
    loading: details.vknImages.loading,
    selectedVKNImage: details.selectedVKNImage,
}))
export default function DetailsImages() {
    const actions = useActions(MainActions, "updateSelectedVKNImage", "loadSvgImage", "loadImagePositions")
    const [{ loading, selectedVKNImage, sufficientSelection, visibleImages }] = useSelector(selector)
    const style = useMemo(() => getStyle(), [])

    function renderImage(item: VKNImage, idx: number) {
        const className = classes({
            "is-active": selectedVKNImage?.imageName == item.imageName,
            [style.card]: true,
        })

        return (
            <Grid l={6} key={idx}>
                {wrapTooltip(
                    <Card className={className} onClick={() => handleVknImageSelect(item)}>
                        {/* <div className={style.imageWrapper}> */}
                        <img className={style.graphicViewer} src={`data:image/png;base64,${item.png}`} />
                        {/* </div> */}
                        {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <Text>{item.imageTitle}</Text>
                        </div> */}
                    </Card>
                )}
            </Grid>
        )
    }

    function handleVknImageSelect(image: VKNImage) {
        actions.updateSelectedVKNImage(image)

        if (!image.isStartImage) {
            actions.loadSvgImage()
        } else {
            actions.loadImagePositions()
        }

        CustomTooltip.hide()
    }

    if (loading && !visibleImages.length) {
        return (
            <div className={bem(style.detailsImages, "loading")}>
                <Loader />
            </div>
        )
    }

    return (
        <div className={bem(style.detailsImages, !!sufficientSelection && sufficientSelection != ESufficentSelections.Distinct && "covered")}>
            <Scrollbar>
                <Grid container className={style.cardContainer}>
                    {visibleImages.map(renderImage)}
                </Grid>
            </Scrollbar>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        detailsImages: {
            borderTop: border({ color: "#e2e2e2", style: "solid", width: em(0.1) }),
            marginTop: theme.margin.m,
            flex: 1,
            $nest: {
                "&--loading": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
                "&--covered": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                },
            },
        },
        graphicViewer: {
            maxHeight: "100%",
            maxWidth: "100%",
            width: "auto",
            height: "auto",
            margin: "auto",
        },
        imageWrapper: {
            flex: 1,
        },
        cardContainer: {
            paddingRight: "0 .25em",
        },
        card: {
            position: "relative",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            flex: "1",
            height: "100%",
            $nest: {
                ".card__inner": {
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                },
            },
        },
    })(DetailsImages)
}
