import { BundleMicro } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers"
import { reduce } from "./bussines"
import component from "./component"

const openRtModal: BundleMicro = {
    name: "search",
    component: withStoreProvider(component as any),
    reduce,
}

export default openRtModal
