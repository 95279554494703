import { important, percent } from "csx"
import { useState } from "react"
import { SubTitle, SuggestionFieldButtonGroup } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EFilterNames, channel } from "@tm/models"
import { Box, SimpleCard, styled, SxProps, Theme } from "@tm/components"
import { SpecialCarTypes } from "../../../data/enums"
import { Statics } from "../../../data/statics"
import { useWidgetFilters } from "../../../hooks/widgetFilters"
import { CarTypeSeasonFilters } from "../../_shared/carTypeSeasonFilters"
import { DiameterFilters } from "../../_shared/DiameterFilters"

const StyledWrapper = styled(Box)({
    display: "flex",
})

const StyledLabel = styled(SubTitle)({
    overflow: important("visible"),
})

const StyledCarTypeFilters = styled(Box)({
    flexBasis: percent(55),
})

const StyledSeasonsFilters = styled(Box)({
    flexBasis: percent(45),
})

const StyledDiameterFilters = styled(DiameterFilters)({
    flex: 1,
})

type Props = {
    sx?: SxProps<Theme>
}

export function TyresSearchWidgetCompactV1(props: Props) {
    const { translateText } = useLocalization()
    const {
        state: { loading, filters, selectedFilters },
        handlers: { handleFilterChange, handleResetAll, handleNormalSearch },
    } = useWidgetFilters()

    const [query, setQuery] = useState<string>("")

    const handleMatchCodeSearch = () => {
        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { size: query })
    }

    return (
        <SimpleCard className="tyresWidgetCompact" icon="tire" title={translateText(240)} sx={props.sx}>
            <SubTitle>{translateText(1883)}</SubTitle>
            <SuggestionFieldButtonGroup
                value={query}
                onChange={setQuery}
                onChangeConfirm={handleMatchCodeSearch}
                placeholder={translateText(1828)}
                handleSearchButtonClick={handleNormalSearch}
                suggestions={[]}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                requestSuggestions={() => {}}
                tooltip={translateText(1836)}
            />
            <br />

            <SubTitle>{translateText(1562)}</SubTitle>
            <StyledWrapper>
                <StyledCarTypeFilters>
                    <StyledLabel>{translateText(1782)}</StyledLabel>
                    <CarTypeSeasonFilters
                        filterName={EFilterNames.carType}
                        disabled={loading}
                        items={Statics.getCarTypes()}
                        selectedItemValue={selectedFilters?.carType}
                        onChange={handleFilterChange}
                    />
                </StyledCarTypeFilters>
                <StyledSeasonsFilters>
                    <StyledLabel>{translateText(738)}</StyledLabel>
                    <CarTypeSeasonFilters
                        filterName={EFilterNames.season}
                        disabled={loading || selectedFilters.carType.every((x) => SpecialCarTypes.includes(+x.value))}
                        items={Statics.seasons}
                        selectedItemValue={selectedFilters?.season}
                        onChange={handleFilterChange}
                    />
                </StyledSeasonsFilters>
            </StyledWrapper>
            <Box>
                <StyledLabel>{translateText(743)}</StyledLabel>
                <StyledDiameterFilters
                    widths={filters.width}
                    heights={filters.height}
                    inches={filters.inch}
                    selectedHeight={selectedFilters.height}
                    selectedInch={selectedFilters.inch}
                    selectedWidth={selectedFilters.width}
                    onChange={handleFilterChange}
                    onSearch={handleNormalSearch}
                    onResetAll={handleResetAll}
                    loading={loading}
                    maxItemsToShow={6}
                />
            </Box>
        </SimpleCard>
    )
}
