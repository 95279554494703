import {
    ModuleOption,
    ModuleOptionPrice,
    ModuleOptionPriceType,
    SessionStorageKeys,
    SetModuleActivationRequest,
    SetModuleActivationResponse,
    channel,
} from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { ReactText } from "react"

export function getModulePrice(moduleOption: ModuleOption): ModuleOptionPrice | undefined {
    const price = moduleOption.modulePrice

    if (!price || price?.type === ModuleOptionPriceType.Unknown) {
        return undefined
    }

    return price
}

export function handleFullActivation(
    activateModule: (request: SetModuleActivationRequest) => Promise<SetModuleActivationResponse | undefined>,
    moduleName: string,
    request: SetModuleActivationRequest,
    translateText: (key: ReactText) => string
) {
    activateModule(request)
        .then(() => {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                message: translateText(12792).replace("{0}", moduleName),
                skin: "success",
            })
        })
        .catch((ex) => {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                message: translateText(12821),
                closeDelay: 5000,
                skin: "danger",
            })
        })
}

export function handleDemoActivation(
    activateModule: (request: SetModuleActivationRequest) => Promise<SetModuleActivationResponse | undefined>,
    request: SetModuleActivationRequest,
    workTaskId: string | undefined,
    moduleRoute: string | undefined,
    translateText: (key: ReactText) => string
) {
    activateModule(request)
        .then(() => {
            if (moduleRoute && workTaskId) {
                removeMvcSessions()
                location.href = renderRoute(moduleRoute, { workTaskId: encodeUniqueId(workTaskId) })
                return
            }

            Morpheus.closeView("1")
            removeMvcSessions()
            setTimeout(() => location.reload(), 20)
        })
        .catch((ex) => {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                message: translateText(12821),
                closeDelay: 5000,
                skin: "danger",
            })
        })
}

export function removeMvcSessions() {
    Object.keys(sessionStorage)
        .filter((x) => x.indexOf(SessionStorageKeys.mvcSessionId) > -1 || x.indexOf(SessionStorageKeys.invalidSessionMvc) > -1)
        .forEach((x) => sessionStorage.removeItem(x))
}

export function getDialogText(
    modulePrice: ModuleOptionPrice,
    moduleName: string,
    translateText: (key: ReactText) => string,
    numberFormat: (value: number, decimal?: number) => string
) {
    const price = `${numberFormat(modulePrice.value, 2)} ${modulePrice.currencySymbol}`

    switch (modulePrice.type) {
        case ModuleOptionPriceType.PricePerMonth:
            return translateText(13792).replace("{0}", moduleName).replace("{1}", price)

        case ModuleOptionPriceType.PricePerUse:
            return translateText(13793).replace("{0}", moduleName).replace("{1}", price)
        default:
            return ""
    }
}
