import { Article } from "@tm/models"
import { Part } from "../../../../data/models"

export const mapDatGlassPartToArticle = (part: Part): Article => {
    return {
        supplier: {
            id: part.dataSupplierId,
            name: part.dataSupplierName,
            isTopPriority: false,
            abbreviation: "",
        },
        supplierArticleNo: part.dataSupplierArticleNumber,
        traderArticleNo: part.wholesalerArticleNumber ?? "",
        productGroup: {
            id: part.productGroupId,
            name: part.productGroupName,
            sortId: 0,
            thumbnail: part.article?.thumbnail ?? "",
        },
        quantity: part.article?.quantity ?? 1,
        internalId: part.article?.id ?? -1,
        traderDescription: part.article?.traderDescription ?? "",
        information: part.article?.information ?? [],
        attributes: part.article?.attributes ?? [],
        references: part.article?.references ?? [],
        status: part.article?.status ?? [],
        isAccessory: part.article?.isAccessory ?? false,
        isPartsListAvailable: part.article?.isPartsListAvailable ?? false,
        isAccessoryListAvailable: part.article?.isAccessoryListAvailable ?? false,
        thumbnail: part.thumbnailUrl || part.article?.thumbnail || "",
        existImage: part.article?.existImage ?? false,
        accessoryDescription: "",
        additionalDescription: "",
        additionalReferenceArticleInformation: {
            headInformation: [],
            links: [],
            textInformation: [],
        },
        articleModificationState: 0,
        description: part.article?.traderDescription ?? "",
        existPdf: false,
        id: `${part.article?.id}`,
        imageCoordinates: "",
        initialQuantity: part.article?.initialQuantity ?? 1,
        isExactMatch: false,
        isMandatoryCertificationRequired: false,
        isReplacementArticle: true,
        isTraderAddition: false,
        matchingArticleNo: "",
        options: {
            showInArticleList: true,
            blocked: false,
            ignoreInSearch: false,
            highlight: false,
            modified: false,
            addedByWholesaler: false,
            articleInformationEditedByWholesaler: false,
        },
        priority: -1,
        requestErpInfo: true,
        showAddToBasket: false,
        showDbPrices: false,
        vehicleInformation: [],
        vehicleLinkageId: part.article?.vehicleLinkageId ?? 1,
    }
}
