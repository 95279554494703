import { useCallback, useEffect, useState } from "react"
import { useTyresV2, useWorkTask } from "@tm/context-distribution"
import { EFilterNames, ETyresSeason, TyreFilter, channel } from "@tm/models"
import { createBaseQuery, getAvailableTyreSize, getCurrentSeason } from "../../data/helpers"
import { Statics } from "../../data/statics"
import { resetSummaryFilter, updateSelectedFilters } from "./helper"
import { IWidgetFilters, SelectedFilters } from "./model"
import { useGetTyresCrits } from "./useGetTyresCrits"
import { useGetStandardTyreSizes } from "../useGetStandardTyreSizes"

const useWidgetFilters = () => {
    const tyresV2 = useTyresV2()
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
        carType: Statics.getCarTypes(tyresV2),
        season: Statics.seasons?.filter((x) => x.value === getCurrentSeason()),
        untouched: true,
        tyreCount: Statics.getDefaultTyreCountFilter(),
    })

    const [selectedFiltersDefaultVehicle, setSelectedFiltersDefaultVehicle] = useState<TyreFilter | undefined>(undefined)

    const vehicleType = useWorkTask()?.workTask?.vehicle?.vehicleType

    const { filters, loading } = useGetTyresCrits(selectedFilters)

    const { standardTyreSizes } = useGetStandardTyreSizes()

    useEffect(() => {
        const defaultVehicle = selectedFilters.carType?.find((x) => x.bitValue === vehicleType)
        setSelectedFiltersDefaultVehicle(defaultVehicle)
        setTyreSizes(defaultVehicle)
    }, [vehicleType])

    useEffect(() => {
        if (selectedFilters.untouched || !filters) {
            return
        }

        if (filters.height?.length === 1 && selectedFilters.width && !selectedFilters.height) {
            handleFilterChange(EFilterNames.height, filters.height.first()!)
        } else if (filters.inch?.length === 1 && selectedFilters.height && !selectedFilters.inch) {
            handleFilterChange(EFilterNames.inch, filters.inch.first()!)
        }
    }, [filters?.height, filters?.inch])

    const setTyreSizes = useCallback(
        (defaultVehicle?: TyreFilter | undefined) => {
            const { width, inch, height } = defaultVehicle
                ? getAvailableTyreSize([defaultVehicle], filters) || {}
                : (selectedFilters.carType?.length > 0 && getAvailableTyreSize(selectedFilters.carType, filters)) || {}

            if (!width && !inch && !height) {
                return
            }

            setSelectedFilters((x) => ({ ...x, width, inch, height, untouched: false }))
        },
        [selectedFilters?.carType]
    )

    const handleNormalSearch = useCallback(() => {
        const { carType, season, width, height, inch } = selectedFilters
        const size = createBaseQuery(width?.value, height?.value, inch?.value)

        if (!size) {
            return
        }

        const payload = {
            size,
            season: season?.map((s) => s.value as unknown as ETyresSeason),
            carType: carType?.map((x) => x.query).join(),
        }

        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", payload)
    }, [selectedFilters])

    const handleStandardSizeSearch = useCallback((size: string) => {
        if (!size) {
            return
        }

        const payload = {
            size,
            season: undefined,
            carType: undefined,
        }

        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", payload)
    }, [])

    const handleFilterChange = useCallback(
        (path: IWidgetFilters, filter: TyreFilter) => {
            if (path === EFilterNames.carType) {
                setSelectedFiltersDefaultVehicle(undefined)
            }

            setSelectedFilters((prevSelectedFilters) => {
                let newSelectedFilters = resetSummaryFilter(prevSelectedFilters, path, tyresV2)
                if (filter) {
                    newSelectedFilters = updateSelectedFilters(newSelectedFilters, filters, path, filter)
                }
                return newSelectedFilters
            })
        },
        [filters, tyresV2]
    )

    const handleResetAll = () => setTyreSizes()

    return {
        state: {
            loading,
            filters,
            selectedFilters,
            selectedFiltersDefaultVehicle,
            standardTyreSizes,
        },
        handlers: {
            handleResetAll,
            handleNormalSearch,
            handleFilterChange,
            handleStandardSizeSearch,
        },
    }
}

export default useWidgetFilters
