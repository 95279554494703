import {
    Article,
    ArticleAttribute,
    AttributeFilterModel,
    CompareWithVehicleRecordsRequest,
    CompareWithVehicleRecordsResponse,
    FittingPosition,
    ShowVehicleRecordsResponse,
    ShowVehicleRecordsViewMode,
    Vehicle,
} from "@tm/models"
import { useCallback, useEffect, useMemo } from "react"
import { QueryFunctionContext, useQuery } from "react-query"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useWorkTask } from "@tm/context-distribution"
import {
    compareWithVehicleRecords,
    showVehicleRecords,
    articleAddedToBasket,
    quickFilterApplied,
    inlineFilterApplied,
} from "../../../../../vehicle-records/src/data/repository"
import { VehicleRecordsActions, VehicleRecordsData } from "../models"
import {
    mapAttributeToVehicleRecordSingleEventRequest,
    mapCompareWithVehicleRecordsRequest,
    mapFilterToVehicleRecordSingleEventRequest,
    mapVehicleRecordArticleAddedToBasketEventRequest,
} from "../../../data/mapper/vehicle-records"
import { VehicleRecordsState } from "../states"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { hasVehicleRecordDatVinOnly } from "../../../helper"

const QUERY_KEY_LOAD = "useVehicleRecords.load"
const QUERY_KEY_COMPARE = "useVehicleRecords.compare"

type QueryKeyLoad = [typeof QUERY_KEY_LOAD, string | undefined, number[]]
type QueryKeyCompare = [typeof QUERY_KEY_COMPARE, CompareWithVehicleRecordsRequest | undefined]

/**
 * This is the Hook to get the Vehicle Records Data
 * @returns the loaded vehicle records data (filters and suggested attributes)
 */
export function useVehicleRecordsData(): VehicleRecordsData {
    const { workTaskId } = useWorkTask() ?? {}
    return useRecoilValue(VehicleRecordsState(workTaskId))
}

/**
 * Load the vehicle records (SLFA) data for the current vehicle and the supplied product groups.
 * Return value is not reference stable and should therefore not be used as any dependency for other hooks.
 */
export function useLoadVehicleRecords(
    vehicle: Vehicle | undefined,
    productGroupIds: number[],
    articles: Article[],
    isEnabled: boolean
): VehicleRecordsActions {
    const { workTaskId } = useWorkTask() ?? {}
    const setVehicleRecordsState = useSetRecoilState(VehicleRecordsState(workTaskId))
    const { viewOptions: vehicleRecordsEnabled } = useArticleListConfiguration()

    const { data, refetch, isLoading } = useQuery({
        enabled: isEnabled && !!vehicle?.id && !!productGroupIds.length,
        queryKey: [QUERY_KEY_LOAD, vehicle?.id, productGroupIds],
        queryFn: fetchVehicleRecords,
    })

    const compareRequest = useMemo(() => {
        if (vehicle?.id) {
            return mapCompareWithVehicleRecordsRequest(
                vehicle?.id,
                articles.filter((article) => productGroupIds.includes(article.productGroup.id))
            )
        }
    }, [articles, vehicle?.id, productGroupIds])

    const queryCompare = useQuery({
        enabled: isEnabled && !!compareRequest,
        queryKey: [QUERY_KEY_COMPARE, compareRequest],
        queryFn: compareAttributes,
    })

    const productGroupFilters = useMemo(() => data?.productGroupFilters ?? [], [data])
    const attributes = useMemo(() => queryCompare.data?.attributes ?? [], [queryCompare.data])

    const refresh = useCallback(() => {
        refetch()
        queryCompare.refetch()
    }, [])

    const handleArticleAddedToBasket = useCallback(
        async (article: Article) => {
            if (vehicle) {
                const request = mapVehicleRecordArticleAddedToBasketEventRequest(vehicle, article)
                await articleAddedToBasket(request)
                if (!hasVehicleRecordDatVinOnly()) {
                    refresh()
                }
            }
        },
        [vehicle?.id]
    )

    const handleSelectInlineFilter = useCallback(
        async (attribute: ArticleAttribute, article: Article, fittingPosition?: FittingPosition) => {
            if (vehicle) {
                const request = mapAttributeToVehicleRecordSingleEventRequest(vehicle, attribute, article.productGroup.id, fittingPosition)
                await inlineFilterApplied(request)
            }
        },
        [vehicle?.id]
    )

    const handleSelectQuickFilter = useCallback(
        async (filter: AttributeFilterModel) => {
            if (vehicle) {
                const request = mapFilterToVehicleRecordSingleEventRequest(vehicle, filter)
                await quickFilterApplied(request)
            }
        },
        [vehicle?.id]
    )

    useEffect(() => {
        setVehicleRecordsState((prev) => {
            let result = prev
            if (attributes !== result.attributes) {
                result = { ...result, attributes }
            }
            if ((isLoading || queryCompare.isLoading) !== result.isLoading) {
                result = { ...result, isLoading }
            }
            if (productGroupFilters !== result.productGroupFilters) {
                result = { ...result, productGroupFilters }
            }
            return result
        })
    }, [isLoading, queryCompare.isLoading, productGroupFilters, attributes])

    return useMemo(
        () => ({
            refresh,
            handleArticleAddedToBasket,
            handleSelectQuickFilter,
            handleSelectInlineFilter,
        }),
        [refresh, handleArticleAddedToBasket, handleSelectQuickFilter, handleSelectInlineFilter]
    )
}

function fetchVehicleRecords({
    queryKey: [, vehicleId, productGroupIds],
}: QueryFunctionContext<QueryKeyLoad>): Promise<ShowVehicleRecordsResponse | undefined> {
    return showVehicleRecords({
        vehicleId,
        productGroupIds: productGroupIds.join(","),
        viewMode: ShowVehicleRecordsViewMode.ShowQuickFilter,
        fittingSide: undefined,
    })
}

function compareAttributes({ queryKey: [, request] }: QueryFunctionContext<QueryKeyCompare>): Promise<CompareWithVehicleRecordsResponse | undefined> {
    if (!request?.attributes.length) {
        return Promise.resolve(undefined)
    }
    return compareWithVehicleRecords(request)
}
