import {
    Article,
    EFilterNames,
    GetArticleListByMatchCodeRequest,
    GetArticlesListMappedResponse,
    HasRepairTimesRequest,
    HasRepairTimesResponse,
    RegisteredModels,
    RepairTimeProvider,
    TyreArticle,
    TyreFilter,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import {
    clone,
    equals,
    getProductGroupsIdsFromArticles,
    getRepairTimeProviders,
    getRepairTimeProvidersByNames,
    getValue,
    isSameArticle,
} from "@tm/utils"
import { Container } from "@tm/nexus"
import { batch } from "react-redux"
import { AvailabilityFilterType, BundleActionTypes, BundleActions } from "../../../business"
import { Repositories } from "../../../data"
import { WheelSelectionSteps } from "../../../data/enums"
import { addOrRemoveItem, getCurrentSeason } from "../../../data/helpers"
import { IListFilters } from "../../../data/models"
import { UsedCriteria } from "../../../data/repositories/wheels-loadTiresArticles/model"
import { TiresFiltersResponse } from "../../../data/repositories/wheels-loadTiresFilters/model"
import { Statics } from "../../../data/statics"
import { MainState } from "../../main"
import { createArticleListRequest, createAttributeFiltersRequest, createNextArticlesListRequest, filterUsedCriteria } from "./helpers"
import { TyresListState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "ATTRIBUTE_FILTERS_LOADING" }
    | { type: "ATTRIBUTE_FILTERS_LOADED"; payload: TiresFiltersResponse }
    | { type: "ATTRIBUTE_FILTERS_ERROR" }
    | { type: "TYRES_ARTICLES_LOADING"; payload: { fromFilters?: boolean } }
    | { type: "TYRES_ARTICLES_LOADED"; payload: GetArticlesListMappedResponse & { loadIndex?: string; speedIndex?: string } }
    | { type: "TYRES_ARTICLES_ERROR" }
    | { type: "SELECT_TIRE_ARTICLE"; payload: { item: Article; isMultipleSelect?: boolean } }
    | { type: "TYRES_NEXT_ARTICLES_LOADED"; payload: { articles: TyreArticle[]; auto?: boolean } }
    | { type: "TYRES_NEXT_ARTICLES_LOADING"; payload: boolean }
    | { type: "TYRES_NEXT_ARTICLES_ERROR" }
    | { type: "UPDATE_ATRIBUTE_FILTER"; payload: { path: IListFilters; value: any } }
    | { type: "CHANGE_AVAILABILITY"; payload: AvailabilityFilterType }
    | { type: "RESET_ATRIBUTE_FILTER"; payload: { path: IListFilters } }
    | { type: "RESET_ALL_ATRIBUTE_FILTERS" }
    | { type: "SET_PRODUCTGROUP_REPAIRTIMES"; payload: { [key: number]: Array<RepairTimeProvider> } }
    | { type: "UPDATE_USED_CRITERIA"; payload: { loadIndex: TyreFilter[] | undefined; speedIndex: TyreFilter[] | undefined } | undefined }
    | { type: "SET_REQUEST"; payload: { request: GetArticleListByMatchCodeRequest } }

export const TYRES_LIST_DEFAULT_STATE: TyresListState = {
    initialized: false,
    filters: {
        season: Statics.seasons,
        externalRolling: [],
        oeIdentifier: [],
        fuelEfficiency: [],
        speedIndex: [],
        weight: [],
        wetGripClass: [],
        loadIndex: [],
        manufacturer: [],
        extras: [],
        // TODO check extra unused keys
        // characteristics: [],
        tyreSize: [],
    },
    selectedFilters: {
        season: {
            group: EFilterNames.season,
            query: getCurrentSeason(),
            value: getCurrentSeason(),
        },
        availability: AvailabilityFilterType.None,
    },
    articles: {
        autoNextCount: 0,
        data: [],
        pageIndex: 1,
        count: 0,
    },
    erpInformations: [],
    repairTimeAvailabilities: [],
}

export function reduce(state = clone(TYRES_LIST_DEFAULT_STATE), action: ComponentActionType): TyresListState {
    switch (action.type) {
        case "TYRES_ARTICLES_LOADING": {
            const { fromFilters } = action.payload
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loading: true,
                    data: [],
                    error: false,
                    count: 0,
                    autoNextCount: 0,
                    pageIndex: 1,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    nextArticlesError: false,
                },
                ...(!fromFilters && {
                    filters: {
                        ...state.filters,
                        loading: true,
                    },
                }),
            }
        }

        case "TYRES_NEXT_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    ...((action.payload && {
                        loadingAutoItems: true,
                    }) || {
                        loadingNextItems: true,
                    }),
                    autoNextCount: (action.payload && state.articles.autoNextCount + 1) || 0,
                },
            }
        }
        case "TYRES_NEXT_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    nextArticlesError: true,
                },
            }
        }
        case "RESET_ALL_ATRIBUTE_FILTERS": {
            const { tyreSize } = state.selectedFilters
            return {
                ...state,
                selectedFilters: {
                    ...TYRES_LIST_DEFAULT_STATE.selectedFilters,
                    tyreSize,
                },
            }
        }
        case "UPDATE_ATRIBUTE_FILTER": {
            const { path, value } = action.payload

            const oldStoredValues = getValue(state.selectedFilters, [path])
            let newValues = equals(value, oldStoredValues) ? undefined : value

            if (Statics.multiSelectionFilters.includes(path)) {
                // if is multiSelection
                newValues = addOrRemoveItem(oldStoredValues, value, (i1: TyreFilter, i2: TyreFilter) => i1.query == i2.query && i1.group == i2.group)
            }

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: newValues,
                },
            }
        }
        case "CHANGE_AVAILABILITY": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    autoNextCount: 0,
                },
                selectedFilters: {
                    ...state.selectedFilters,
                    availability: action.payload,
                },
            }
        }
        case "RESET_ATRIBUTE_FILTER": {
            const { path } = action.payload
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: (Statics.multiSelectionFilters.includes(path) && []) || undefined,
                },
            }
        }
        case "TYRES_ARTICLES_LOADED": {
            const { usedCriteria, uniArticles, articleListCount } = action.payload
            const { loadIndex, speedIndex } = usedCriteria

            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: uniArticles,
                    error: false,
                    count: articleListCount ?? Number.MAX_VALUE,
                    loading: false,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                },
                selectedFilters: {
                    ...state.selectedFilters,
                    ...(loadIndex?.length && { loadIndex }),
                    ...(speedIndex?.length && { speedIndex }),
                },
                initialized: true,
            }
        }

        case "TYRES_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loading: false,
                    data: [],
                    error: true,
                    count: 0,
                    pageIndex: 1,
                    loadingNextItems: false,
                },
                filters: {
                    ...state.filters,
                    loading: false,
                },
            }
        }

        case "ATTRIBUTE_FILTERS_LOADING": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    loading: true,
                },
            }
        }

        case "ATTRIBUTE_FILTERS_LOADED": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    loading: false,
                    ...action.payload,
                },
            }
        }

        case "ATTRIBUTE_FILTERS_ERROR": {
            return {
                ...state,
                filters: {
                    ...TYRES_LIST_DEFAULT_STATE.filters,
                    loading: false,
                },
            }
        }

        case "TYRES_NEXT_ARTICLES_LOADED": {
            const { articles, auto } = action.payload
            const newData = [...state.articles.data, ...articles]
            return {
                ...state,
                articles: {
                    ...state.articles,
                    ...(auto && {
                        loadingAutoItems: false,
                    }),
                    loadingNextItems: false,
                    loading: false,
                    data: newData,
                    pageIndex: state.articles.pageIndex + 1,
                },
            }
        }

        case "CHANGE_ARTICLE_QUANTITY": {
            const { article, quantity } = action.payload
            const tires = state.articles.data.map((tire) => ({
                ...tire,
                ...((tire.id == article.id || tire.id == article.internalId) && { quantity: quantity || article.quantity }),
            }))

            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: tires,
                },
            }
        }

        case "SELECT_TIRE_ARTICLE": {
            if (action.payload?.isMultipleSelect) {
                const selectedItem = action.payload.item

                const isAlreadySelected = state.selectedItems?.some((article) => isSameArticle(article, selectedItem))

                return {
                    ...state,
                    selectedItems: isAlreadySelected
                        ? state.selectedItems?.filter((article) => !isSameArticle(article, selectedItem))
                        : [...(state.selectedItems || []), selectedItem],
                }
            }

            return {
                ...state,
                selectedItems: (!equals(state?.selectedItems?.[0], action.payload.item) && [action.payload.item]) || undefined,
            }
        }

        case "SEND_RIM_ARTICLE_TO_OVERVIEW": {
            const { tireSizes, selectedTireSize } = action.payload

            return {
                ...state,
                filters: {
                    ...state.filters,
                    tyreSize: tireSizes,
                },
                selectedFilters: {
                    ...state.selectedFilters,
                    tyreSize: selectedTireSize,
                },
                initialized: false,
            }
        }

        case "SET_ERP_INFORMATIONS": {
            const data = state.articles.data.map((x) => {
                if (x.erpInformation) {
                    return x
                }

                const erpInfo = x.erpInformation ?? action.payload.find((erp) => x.id === erp.itemId)
                return {
                    ...x,
                    erpInformation: erpInfo,
                }
            })
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loading: false,
                    loadingAutoItems: false,
                    data: [...data],
                },
            }
        }

        case "SET_PRODUCTGROUP_REPAIRTIMES": {
            return {
                ...state,
                repairTimeAvailabilities: {
                    ...state.repairTimeAvailabilities,
                    ...action.payload,
                },
            }
        }

        case "UPDATE_USED_CRITERIA": {
            if (!action.payload) {
                return state
            }

            const { loadIndex, speedIndex } = action.payload

            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    ...(loadIndex?.length && { loadIndex }),
                    ...(speedIndex?.length && { speedIndex }),
                },
            }
        }

        case "SET_REQUEST": {
            const { request } = action.payload

            return {
                ...state,
                request,
            }
        }
        default:
            return state
    }
}

function loadTiresList(fromFilters?: boolean, path?: IListFilters): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        if (!fromFilters && path !== EFilterNames.season) {
            dispatch(resetAllFilters())
        }

        const {
            tyresList,
            vehicleSelection,
            manager: { vehicle },
        } = getState()

        const request = createArticleListRequest(tyresList, 1, fromFilters, vehicleSelection, vehicle?.vehicleType)
        if (!request) {
            return
        }

        dispatch({ type: "SET_REQUEST", payload: { request } })

        dispatch({ type: "TYRES_ARTICLES_LOADING", payload: { fromFilters } })
        Repositories.loadTiresArticles(request).then(
            (response) => {
                dispatch({ type: "TYRES_ARTICLES_LOADED", payload: response })

                dispatch(loadProductGroupRepairTimes(response.uniArticles))

                if (!fromFilters) {
                    dispatch(loadAttributesFilters(response.usedCriteria))
                }
            },
            () => {
                dispatch({ type: "TYRES_ARTICLES_ERROR" })
            }
        )
    }
}

function loadProductGroupRepairTimes(articles: Array<TyreArticle>): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const {
            manager: { vehicle },
        } = getState()

        const productGroupIds = getProductGroupsIdsFromArticles(articles)
        const { repairTimeProviderIds } = getRepairTimeProviders()

        const providers = getRepairTimeProvidersByNames(repairTimeProviderIds)

        if (!vehicle || !providers.length || !productGroupIds.length) {
            return
        }

        const request: HasRepairTimesRequest = {
            repairTimeProvider: providers,
            modelId: vehicle.tecDocTypeId,
            productGroupIds,
            vehicleType: vehicle.vehicleType,
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then((response) => {
                if (response) {
                    dispatch({ type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: response })
                }
            })
    }
}

function loadNextTiresList(auto?: boolean): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const {
            tyresList: state,
            manager: { vehicle },
        } = getState()

        const request = createNextArticlesListRequest(state, vehicle?.vehicleType)
        if (!request || state.articles.loadingNextItems || state.articles.loadingAutoItems) {
            return
        }

        dispatch({ type: "SET_REQUEST", payload: { request } })

        dispatch({ type: "TYRES_NEXT_ARTICLES_LOADING", payload: !!auto })

        Repositories.loadTiresArticles(request).then(
            (response) => dispatch({ type: "TYRES_NEXT_ARTICLES_LOADED", payload: { articles: response.uniArticles, auto } }),
            () => dispatch({ type: "TYRES_NEXT_ARTICLES_ERROR" })
        )
    }
}

function selectItem(item: Article, isMultipleSelect?: boolean): ComponentActionType {
    return { type: "SELECT_TIRE_ARTICLE", payload: { item, isMultipleSelect } }
}

function loadAttributesFilters(usedCriteria?: UsedCriteria | undefined): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const {
            tyresList,
            manager: { vehicle },
        } = getState()

        const request = createAttributeFiltersRequest(tyresList, vehicle?.vehicleType)
        if (!request) {
            return
        }

        dispatch({ type: "ATTRIBUTE_FILTERS_LOADING" })

        Repositories.loadTiresFilters(request).then(
            (response) =>
                batch(() => {
                    dispatch({ type: "ATTRIBUTE_FILTERS_LOADED", payload: response })

                    // workaround : exclude from selectedFilters the loadIndexes and speedIndexes  that are not in the filters response
                    dispatch({ type: "UPDATE_USED_CRITERIA", payload: filterUsedCriteria(response, usedCriteria) })
                }),
            (_error) => dispatch({ type: "ATTRIBUTE_FILTERS_LOADING" })
        )
    }
}

function sendTireToOverview(newSelectedItem?: Article | undefined): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const {
            tyresList: { selectedItems },
        } = getState()
        dispatch({ type: "SEND_TIRE_TO_OVERVIEW", payload: newSelectedItem ?? selectedItems?.[0]! })
    }
}

function saveTyresListTab(selectedArticle: Article): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch, getState) => {
        const { pageIndex } = getState().tyresList.articles
        const { selectedFilters } = getState().tyresList

        dispatch(
            BundleActions.saveData({
                tyresTab: {
                    article: {
                        internalId: selectedArticle.internalId,
                        traderArticleNo: selectedArticle.traderArticleNo,
                        quantity: selectedArticle.quantity,
                    },
                    pageIndex,
                    selectedFilters,
                },
                activeStep: WheelSelectionSteps.OVERVIEW,
                highestStepReached: WheelSelectionSteps.OVERVIEW,
            })
        )
    }
}

function updateFilter(path: IListFilters, value: TyreFilter): ComponentActionType {
    return { type: "UPDATE_ATRIBUTE_FILTER", payload: { path, value } }
}

function resetFilter(path: IListFilters): ComponentActionType {
    return { type: "RESET_ATRIBUTE_FILTER", payload: { path } }
}

function resetAllFilters(): ComponentActionType {
    return { type: "RESET_ALL_ATRIBUTE_FILTERS" }
}

function changeAvailabilityFilter(value: AvailabilityFilterType): ComponentActionType {
    return { type: "CHANGE_AVAILABILITY", payload: value }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    loadNextTiresList,
    updateFilter,
    sendTireToOverview,
    loadTiresList,
    resetFilter,
    selectItem,
    resetAllFilters,
    changeAvailabilityFilter,
    saveTyresListTab,
}
