import { useState } from "react"
import { Button, ConfirmationDialog, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Article, ArticleInformation } from "@tm/models"
import { SetterOrUpdater } from "recoil"
import { useWorkTask } from "@tm/context-distribution"
import { areArticlesEqual } from "@bundles/basket/helpers"
import { LoaderSmall } from "../StyledComponents"

type Props = {
    buttonTitleId: number
    /**
     * A confirmationKey must be send for showing the Checkbox. The confirmationKeys must be unique and the new ones cand be added into the ConfirmationDialogKeys enum from @tm/models
     */
    confirmationKey?: string
    dialogTextId: number
    disableButton?: boolean
    ids?: Array<string>
    showDialog?: boolean
    onConfirmRemove(ids?: Array<string>): void
    articleInformation?: ArticleInformation
    setRecommendedArticlesChain?: SetterOrUpdater<Article[][]>
}

export default function DeleteButton(props: Props) {
    const {
        dialogTextId,
        ids,
        buttonTitleId,
        confirmationKey,
        disableButton,
        showDialog,
        onConfirmRemove,
        articleInformation,
        setRecommendedArticlesChain,
    } = props
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(showDialog || false)

    function handleConfirmClick() {
        onConfirmRemove(ids)
        setShowDeleteDialog(false)
        if (articleInformation && setRecommendedArticlesChain) {
            setRecommendedArticlesChain((prev) => {
                const newArticlesChain = prev
                    .map((articleGroup) => articleGroup.filter((article) => !areArticlesEqual(article, articleInformation)))
                    .filter((articleGroup) => articleGroup.length > 0)
                if (newArticlesChain?.length > 0) {
                    localStorage.setItem(`recommendedArticles/${workTaskId}`, JSON.stringify(newArticlesChain))
                } else {
                    localStorage.removeItem(`recommendedArticles/${workTaskId}`)
                }

                return newArticlesChain
            })
        }
    }

    return (
        <>
            <Button
                title={translateText(buttonTitleId)}
                onClick={() => setShowDeleteDialog(true)}
                startIcon={!showDeleteDialog ? <Icon name="delete" /> : undefined}
                disabled={disableButton}
                variant="text"
            >
                {showDeleteDialog && <LoaderSmall />}
            </Button>
            <ConfirmationDialog
                open={showDeleteDialog}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                onConfirm={() => handleConfirmClick()}
                onCancel={() => setShowDeleteDialog(false)}
                confirmationKey={confirmationKey}
            >
                {translateText(dialogTextId)}
            </ConfirmationDialog>
        </>
    )
}
