import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import merge from "lodash/merge"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const themeStyles: NestedCSSProperties = {
        color: "#fff",
        $nest: {
            "&__close": {
                $nest: {
                    ".icon": {
                        fill: "#fff",
                    },
                },
            },
            "&:not(&--selected)": {
                borderLeftColor: "#fff",
            },
            "&:hover:not(&--selected)": {
                borderLeftColor: "#fff",
                color: "#fff",
                $nest: {
                    ".icon": {
                        fill: "#fff",
                    },
                },
            },
            "&:last-child": {
                borderRight: "2px solid #fff",
            },
        },
    }

    const mergedStyles = merge(baseStyles, themeStyles)
    return mergedStyles
}

export function getOverflowMenuStyles(baseStyles: NestedCSSProperties) {
    const { colors } = getStyleTheme()
    const moreComponent = {
        hover: {
            back: color(colors.dark).setAlpha(0.05).toRgbString(),
        },
    }

    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&__btn": {
                $nest: {
                    ".icon": {
                        fill: "#fff",
                    },
                    "&:hover": {
                        background: moreComponent.hover.back,
                        $nest: {
                            ".icon": {
                                fill: "#fff",
                            },
                        },
                    },
                    "&:focus": {
                        background: moreComponent.hover.back,
                        color: "inherit",
                        $nest: {
                            ".icon": {
                                fill: "#fff",
                            },
                        },
                    },
                },
            },
        },
    }
    const mergedStyles = merge(baseStyles, themeStyles)
    return mergedStyles
}
