import { useShowNewArticleList, useDisplayListV2 } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { em } from "csx"
import { useDispatch, useSelector } from "react-redux"
import Morpheus, { useActions } from "@tm/morpheus"
import { useEffect } from "react"
import { Box, styled } from "@tm/components"
import { getBundleParams } from "@bundles/wheels/utils"
import { WheelSelectionSteps } from "../../data/enums"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import ApplyButton from "../_shared/applyBtn"
import { Actions } from "./business"
import SensorFilters from "./components/sensorFilters"
import SensorListTable from "./components/sensorList"
import { SensorsListV2 } from "./components/SensorsListV2"
import SensorFiltersV2 from "./components/SensorFiltersV2"

type Props = {
    articleAlternativesRoute: string
    repairTimesRoute: string
    detailsModalRoute?: string
}

export default function RdksList({ articleAlternativesRoute, repairTimesRoute, detailsModalRoute }: Props) {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()

    const { selectedArticles, request } = useSelector((s: MainState) => ({
        selectedArticles: s.rdksList.selectedArticles,
        request: s.rdksList.request,
    }))
    const actions = useActions(Actions, "loadSensorsList")
    const showNewArticleList = useShowNewArticleList()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const isLKQ = getBundleParams()?.version === "LKQ"
    const displayListV2 = useDisplayListV2()

    useEffect(() => {
        const unsubscribeErpModifiers = channel("GLOBAL").subscribe("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
            dispatch(Actions.setErpInformations(erpInfo))
        })
        actions.loadSensorsList()

        return () => {
            unsubscribeErpModifiers?.()
        }
    }, [])

    const handleApplyButton = () => {
        dispatch(Actions.sendArticleToOverview())
        dispatch(MainActions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, true))
        selectedArticles && dispatch(MainActions.saveTpmsTab(selectedArticles[0]))
    }

    const handleSkipTab = () => {
        dispatch(MainActions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, false))
    }

    const renderFilters = () => {
        if (displayListV2 && !isWm) {
            return !request ? null : <SensorFiltersV2 />
        }

        return <FixedSizeSensorFilters />
    }

    const renderList = () => {
        if (showNewArticleList && !isWm) {
            return <SensorsListV2 />
        }

        return (
            <StyledSensorListTable
                repairTimesRoute={repairTimesRoute}
                articleAlternativesRoute={articleAlternativesRoute}
                detailsModalRoute={detailsModalRoute}
            />
        )
    }

    return (
        <StyledWrapperBox className="rdks-list" isLKQ={isLKQ}>
            <ApplyButton
                showApplyBtn
                disabledApplyBtn={selectedArticles?.length !== 1}
                onApply={handleApplyButton}
                showSkipBtn
                skipBtnText={translateText(1117)}
                onSkip={handleSkipTab}
            />
            {renderFilters()}
            {renderList()}
        </StyledWrapperBox>
    )
}

const StyledWrapperBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isLKQ",
})<{ isLKQ: boolean }>(({ isLKQ }) => ({
    width: "100%",
    display: "flex",
    flexDirection: isLKQ ? "row-reverse" : "row",
}))

const StyledSensorListTable = styled(SensorListTable)({
    flex: 1,
})

const FixedSizeSensorFilters = styled(SensorFilters)({
    flexBasis: em(22),
})
