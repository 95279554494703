import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, Icon, Loader, PanelSection, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { createQueryString, initSelector, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { em } from "csx"
import { useSelector } from "react-redux"
import { FC, useState } from "react"
import { getBundleParams } from "../../../data/params"
import { statusDataSelector } from "../../main/business"
import { Cell, HeadlineBox } from "../../shared"

type Props = RouteComponentProps<any> & {}

const selector = initSelector(statusDataSelector)

const NextMaintenanceComponent: FC<Props> = ({ history, match }) => {
    const { translate, date, translateText } = useLocalization()
    const { attachToWorkTask, workTask: { vehicle = undefined } = {} } = useWorkTask() || {}

    const [statusData] = useSelector(selector)
    const [fcLoading, setFcLoading] = useState(false)

    if (!statusData) {
        return null
    }

    const { fastCalculatorRoute } = getBundleParams()
    const { serviceRequiredDistance, serviceRequiredTime, odometerUnit } = statusData

    const handleFCClick = () => {
        if (!fastCalculatorRoute) {
            return
        }

        setFcLoading(true)

        const openFC = (reset?: boolean) => {
            let fastCalculatorUrl = renderRoute(fastCalculatorRoute!, { ...match.params })

            if (reset) {
                fastCalculatorUrl += createQueryString({ forceReset: true })
            }

            history.push(fastCalculatorUrl)

            setFcLoading(false)
        }

        const { useAttachToWorktask } = getBundleParams()

        if (useAttachToWorktask && vehicle && vehicle.mileAge != statusData.odometer) {
            attachToWorkTask?.({ vehicle: { ...vehicle, mileAge: statusData.odometer } }).then(() => {
                openFC(true)
            })
        } else {
            openFC()
        }
    }

    return (
        <PanelSection>
            <HeadlineBox textId={574} />

            <div className={style.inspection}>
                <div className={style.list}>
                    {serviceRequiredTime && (
                        <Cell>
                            <Icon name="inspection-date" size="xl" />
                            <Text modifiers="highlight">{date(new Date(serviceRequiredTime), "d")}</Text>
                            <Text size="s">{translateText(12463)}</Text>
                        </Cell>
                    )}

                    {serviceRequiredDistance && (
                        <Cell>
                            <Icon name="inspection-distance" size="xl" />
                            <Text modifiers="highlight">
                                {serviceRequiredDistance} {odometerUnit}
                            </Text>
                            <Text size="s">{translateText(12488)}</Text>
                        </Cell>
                    )}
                </div>

                {!!fastCalculatorRoute && (
                    <div className={style.options}>
                        <Button icon="fastclick" layout={["holo"]} onClick={handleFCClick} disabled={fcLoading}>
                            {translate(598)}
                        </Button>
                        {fcLoading && <Loader className={style.loader} />}
                    </div>
                )}
            </div>
        </PanelSection>
    )
}

const style = useStyle({
    inspection: {
        marginBottom: 0,
        textAlign: "center",
    },
    list: {
        display: "flex",
        marginBottom: em(0.5),
        textAlign: "center",
    },
    options: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    loader: {
        marginLeft: em(0.5),
    },
})(NextMaintenanceComponent)

export default withRouter(NextMaintenanceComponent)
