import { useWorkTask } from "@tm/context-distribution"
import { RegistrationNoType, VehicleType } from "@tm/models"
import { Suspense, useEffect, useState } from "react"
import { useRouteMatch } from "react-router"
import { useRecoilState } from "recoil"
import { Alert, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { AlertTitle, Box } from "@mui/material"
import { showVehicles } from "../../data/repositories/vrm-lookup"
import { useFilters, useImportedVehicleData, useManufacturersByQuery } from "../../data/hooks"
import { ExternalProps, RouteParams } from "./ComponentSwitch"
import ResultLists from "./components/ResultLists"
import { useSelectedSimpleLookupConfig } from "../../data/hooks/useSelectedSimpleLookupConfig"
import { vehicleSearchResults } from "../../data/hooks/vehicleSearchResult"

export const VehicleParams = {
    SearchQuery: "query",
    RegNo: "regNo",
    RegNoType: "regNoType",
    CheckAndMergeRegNoDetails: "checkAndMergeRegNoDetails",
    ForceUpdate: "forceUpdate",
    AutoApplySingleVehicle: "autoApplySingleVehicle",
    PlateId: "plateId",
    Vin: "vin",
    InitialRegistration: "initialRegistration",
}

export function VehicleSearchResultList(props: ExternalProps) {
    const match = useRouteMatch<RouteParams>()
    const query = decodeURIComponent(match.params.query)
    const vehicleType: VehicleType = parseInt(match.params.vehicleType)
    const { translateText } = useLocalization()
    const { selectedConfig } = useSelectedSimpleLookupConfig()

    const { workTaskId } = useWorkTask() ?? {}
    const { importedData, importedDataLoading } = useImportedVehicleData(workTaskId)

    const [filters, setFilters] = useFilters(vehicleType)
    const [manufacturerId, setManufacturerId] = useState<number>()

    const [result, setResults] = useRecoilState(vehicleSearchResults)
    const [loading, setLoading] = useState(true)

    // Brickt wenn man es einkommentiert, endless loop
    // const manufacturerResponse = useManufacturersByQuery({ vehicleType, query, selectedFilters: {} })

    // "sorting" is not used for query search, so we will remove it here
    // so a change of this property doesn't trigger a new service call
    const selectedFilters = { ...filters }
    delete selectedFilters.sorting

    useEffect(() => {
        setManufacturerId(undefined)
    }, [query])

    // useEffect(() => {
    //     // When the url query contains an engine code set it as active filter (only once)
    //     if (searchEngineCode) {
    //         setFilters((prev) => ({ ...prev, engineCode: searchEngineCode }))
    //     }
    //     // When the imported data contains an engine code set it as active filter (only once)
    //     if (importedData?.engineCode) {
    //         setFilters((prev) => ({ ...prev, engineCode: importedData?.engineCode }))
    //     }
    // }, [searchEngineCode, importedData?.engineCode, setFilters])

    useEffect(() => {
        const searchForVehicles = async (query: string, lookupId?: RegistrationNoType) => {
            const vehicles = await showVehicles({
                lookupTypeId: lookupId,
                query,
                forceUpdateRegistrationNoDetails: false,
                vehicleType: VehicleType.PassengerCar,
            })
            setResults(vehicles)
            setLoading(false)
        }

        if (query && !result) {
            setLoading(true)
            searchForVehicles(query, selectedConfig?.lookupId)
        } else {
            setLoading(false)
        }
    }, [query, result, selectedConfig, setLoading, setResults])

    if (loading) {
        return (
            <Box flex={1} alignContent="center" justifyItems="center">
                <Loader />
            </Box>
        )
    }

    return (
        <div className="tk-vehicle vehicle-search">
            {result?.carModels.length || result?.customerVehicles.length ? (
                <Suspense fallback={<Loader />}>
                    <ResultLists
                        results={result}
                        manufacturerId={manufacturerId}
                        setManufacturerId={setManufacturerId}
                        query={query}
                        vehicleType={vehicleType}
                        importedData={importedData}
                        importedDataLoading={importedDataLoading}
                    />
                </Suspense>
            ) : (
                <Alert severity="info">
                    <AlertTitle>{translateText(12775)}</AlertTitle>
                    {translateText(12776)}
                </Alert>
            )}
        </div>
    )
}
