import { Box, Loader } from "@tm/components"
import { FC, SyntheticEvent, useEffect, useRef, useState, useCallback } from "react"
import { ImageMapAreaV2Dto } from "../../../../models"
import { GraphicImageMapProps, ImageModel, PositionModel, RectType } from "./models"
import { Container, StyledImage, StyledText } from "./styledComponents"

const GraphicImageMap: FC<GraphicImageMapProps> = ({ src, areas, selectedArea, onAreaClick, onAreaHover }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isPanning, setIsPanning] = useState(false)

    const [image, setImage] = useState<ImageModel>()
    const [position, setPosition] = useState<PositionModel>({ x: 0, y: 0, z: 1, oldX: 0, oldY: 0 })
    const [rect, setRect] = useState<RectType>({ width: 0, height: 0 })

    const containerRef = useRef<HTMLDivElement | null>(null)
    const imgContainerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        setIsLoading(true)
    }, [src, areas])

    function onLoad(event: SyntheticEvent<HTMLImageElement, Event>) {
        if (!containerRef.current) {
            return
        }

        const localRect = containerRef.current.getBoundingClientRect()
        setRect({ width: localRect.width, height: localRect.height })

        const scaleFactor = Math.min(localRect.width / event.currentTarget.width, localRect.height / event.currentTarget.height)

        setImage({
            width: event.currentTarget.width * scaleFactor,
            height: event.currentTarget.height * scaleFactor,
            offsetWidth: event.currentTarget.offsetWidth,
            offsetHeight: event.currentTarget.offsetHeight,
            scaleX: event.currentTarget.offsetWidth / event.currentTarget.naturalWidth,
            scaleY: event.currentTarget.offsetHeight / event.currentTarget.naturalHeight,
        })

        if (event.currentTarget.offsetHeight > localRect.height) {
            setPosition({ ...position, z: scaleFactor })
        }

        if (event.currentTarget.offsetWidth > localRect.width) {
            setPosition({ ...position, z: scaleFactor })
        }

        setIsLoading(false)
    }

    function onMouseDown(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault()

        setIsPanning(true)

        if (imgContainerRef?.current) {
            imgContainerRef.current.style.transition = "none"
        }

        setPosition({
            ...position,
            oldX: event.clientX,
            oldY: event.clientY,
        })
    }

    function onWheel(event: React.WheelEvent<HTMLDivElement>) {
        if (!event.deltaY || !containerRef.current) {
            return
        }

        const sign = Math.sign(event.deltaY) / 10
        const scale = 1 - sign
        const localRect = containerRef.current.getBoundingClientRect()

        // if (position.z * scale < 1) {
        //     return
        // }

        if (!image?.height || !image?.width) {
            return
        }

        setPosition({
            ...position,
            x: position.x * scale - (localRect.width / 2 - event.clientX + localRect.x) * sign,
            y: position.y * scale - ((image.height * localRect.width) / image.width / 2 - event.clientY + localRect.y) * sign,
            z: position.z * scale,
        })
    }

    const mouseup = () => {
        setIsPanning(false)
        // let trigger = false

        // const goodScale = position.z

        // if (position.x - containerRef.current.clientWidth / 2 > (containerRef.current.clientWidth / 2) * goodScale - 100) {
        //     trigger = true
        // }

        // if (position.x + containerRef.current.clientWidth / 2 < -((containerRef.current.clientWidth / 2) * goodScale - 100)) {
        //     trigger = true
        // }

        // if (position.y - containerRef.current.clientHeight / 2 > (containerRef.current.clientHeight / 2) * goodScale - 100) {
        //     trigger = true
        // }

        // if (position.y + containerRef.current.clientHeight / 2 < -((containerRef.current.clientHeight / 2) * goodScale - 100)) {
        //     trigger = true
        // }

        // imgContainerRef.current.style.transition = "all .3s ease-in"

        // if (trigger == true) {
        //     setPosition({
        //         ...position,
        //         x: 0,
        //         y: 0,
        //     })
        // }
    }

    const mousemove = (event: MouseEvent) => {
        if (!isPanning) {
            return
        }

        const newXPosition = position.x + event.clientX - position.oldX
        const newYPosition = position.y + event.clientY - position.oldY

        setPosition({
            ...position,
            x: newXPosition,
            y: newYPosition,
            oldX: event.clientX,
            oldY: event.clientY,
        })
    }

    const handleAreaClick = useCallback(
        (area: ImageMapAreaV2Dto) => () => {
            onAreaClick?.(area)
        },
        [onAreaClick]
    )

    const handleAreaHover = useCallback(
        (area?: ImageMapAreaV2Dto) => () => {
            onAreaHover?.(area)
        },
        [onAreaHover]
    )

    useEffect(() => {
        window.addEventListener("mouseup", mouseup)
        window.addEventListener("mousemove", mousemove)

        return () => {
            window.removeEventListener("mouseup", mouseup)
            window.removeEventListener("mousemove", mousemove)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPanning])

    return (
        <>
            {isLoading && (
                <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Loader sx={{ zIndex: "1" }} />
                </Box>
            )}

            <Container
                onContextMenu={(e) => e.preventDefault()}
                style={{ height: "100%", ...(isLoading && { visibility: "hidden" }) }}
                className="PanAndZoomContainer"
                ref={containerRef}
                onMouseDown={onMouseDown}
                onWheel={onWheel}
            >
                <div
                    ref={imgContainerRef}
                    style={{
                        height: `${rect.height}`,
                        position: "relative",
                        transform: `translate(${position.x}px, ${position.y}px) scale(${position.z})`,
                    }}
                >
                    {areas.map((area) => (
                        <StyledText
                            areaCode={area.areaCode}
                            selectedAreaCode={selectedArea?.areaCode}
                            onClick={handleAreaClick(area)}
                            onMouseOver={handleAreaHover(area)}
                            onMouseLeave={handleAreaHover(undefined)}
                            key={`${area.areaCode + area.x1 + area.x2}`}
                            style={{
                                top: `${area.y1 * (image?.scaleY || 1)}px`,
                                left: `${area.x1 * (image?.scaleX || 1)}px`,
                                width: `${area.x2 * (image?.scaleY || 1) - area.x1 * (image?.scaleX || 1)}px`,
                                height: `${area.y2 * (image?.scaleY || 1) - area.y1 * (image?.scaleY || 1)}px`,
                            }}
                        >
                            {area.areaCode}
                        </StyledText>
                    ))}
                    <StyledImage src={src} onLoad={onLoad} />
                </div>
            </Container>
        </>
    )
}

export default GraphicImageMap
