import { useStyle } from "@tm/context-distribution"
import { Text, Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModulePropsMultiWidget, useMultiWidgetState } from "@tm/utils"

import PartsIndicator from "../_shared/parts-indicator"

type Props = {
    className?: string
    showComponentKey?: string
    calculatorRoute: string
    moduleProps?: ModulePropsMultiWidget
}

function PartsIndicatorWidget(props: Props) {
    const { showComponentKey } = props
    const { translate, translateText } = useLocalization()
    const [selectedMultiWidgetTab] = useMultiWidgetState(props.moduleProps)

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    const className = `tk-parts parts-indicator-widget ${props.className || ""}`

    return (
        <Widget id="parts__parts-indicator-widget" className={className} iconName="catalog" title={translateText(993)} size="6x3" active>
            <Text className="label" style={{ marginBottom: "1.5em" }}>
                {translate(1630)}
            </Text>
            <PartsIndicator calculatorRoute={props.calculatorRoute} className={style.partsIndicator} />
        </Widget>
    )
}

const style = useStyle({
    partsIndicator: {
        marginTop: "-1.5em",
    },
})(PartsIndicatorWidget)

export default PartsIndicatorWidget
