import { Dialog } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { FC, useEffect, useRef } from "react"
import { RimItem } from "../../../data/model"
import StandardConfigurator from "../../2d-configurator/component"
import PreyssConfigurator from "../../3d-configurator/component"
import { MainState } from "../../main"
import { Actions } from "../business"

type Props = {
    selectedRimItem?: RimItem
    has2DConfig: boolean
    has3DConfig: boolean
    show3D: boolean
    renderHidden: boolean
    selectedRimLoading?: boolean
    selectedSize?: string
}

const selector = createSelector(
    (s: MainState) => ({
        _2D: s.wheelsList.configurator._2D,
        showDialog: s.wheelsList.configurator.showDialog,
    }),
    (x) => x
)

const ConfiguratorDialog: FC<Props> = ({ selectedRimItem, has2DConfig, has3DConfig, show3D, renderHidden, selectedRimLoading, selectedSize }) => {
    const dialogRef = useRef<Dialog>(null)
    const {
        showDialog,
        _2D: { images, loading, rimPositions, rimSizes },
    } = useSelector(selector)

    const actions = useActions(Actions, "changeConfiguratorDisplay", "changeDialogDisplay")

    const handleCloseDialog = () => {
        actions.changeConfiguratorDisplay()
        actions.changeDialogDisplay(false)
    }

    useEffect(() => {
        if (showDialog) {
            dialogRef?.current?.show()
        } else {
            dialogRef?.current?.hide()
        }
    }, [showDialog])

    return (
        <Dialog className="configurator-dialog" layout="stretch" ref={dialogRef} onClose={handleCloseDialog}>
            {has2DConfig && !show3D && (
                <StandardConfigurator
                    insideDialog
                    images={images}
                    selectedRimItem={selectedRimItem}
                    loading={loading}
                    selectedSize={selectedSize}
                    selectedRimLoading={selectedRimLoading}
                    rimSizes={rimSizes}
                    rimPositions={rimPositions}
                    renderHidden={renderHidden}
                />
            )}

            {has3DConfig && show3D && <PreyssConfigurator insideDialog />}
        </Dialog>
    )
}

export default ConfiguratorDialog
