import { ErpInformationRequestItem } from "@tm/models"
import * as htmlToImage from "html-to-image"
import { getBundleParams } from "../../../utils"
import { FilterCriterias } from "../../../data/enums"
import { ImagePathsRequest } from "../../../data/repositories/wheels-load2DImages/model"
import { _2DRimSizesRequest } from "../../../data/repositories/wheels-load2DRimSizes/model"
import { RimFiltersRequest } from "../../../data/repositories/wheels-loadRimFIlters/model"
import { RimItemsRequest } from "../../../data/repositories/wheels-loadRimItems/model"
import { FiltersBySelectionOrder, SelectedFilters } from "./model"
import { RimErpInfo } from "../../../data/model"

export function createRimSizesRequest(carparkId: number, isNewImage?: boolean, size?: string): _2DRimSizesRequest {
    return {
        carparkId,
        diameterTyre: size || "",
        isNewImage: isNewImage || false,
    }
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function createRimArticlesRequest(
    carparkId: number,
    selectedFilters: SelectedFilters,
    isAdditionalManufacturerSelected: boolean,
    pageIndex?: number
): RimItemsRequest {
    return {
        carparkId,
        rimFilter: mapSelectedFiltersForRequest(selectedFilters),
        pageIndex: pageIndex ?? 1,
        pageSize: getBundleParams().wheelsPagingSize,
        isAdditionalManufacturerSelected,
    }
}

// TODO too much hardcoded stuff
export function mapSelectedFiltersForRequest(selectedFilters: Partial<SelectedFilters>) {
    let request = ""

    if (selectedFilters[FilterCriterias.rimSupplier]?.length) {
        selectedFilters[FilterCriterias.rimSupplier]?.split(";").forEach((item) => {
            request += `${FilterCriterias.rimSupplier},${item};`
        })
    }

    if (selectedFilters[FilterCriterias.rimWidth]) {
        request += `${FilterCriterias.rimWidth},${selectedFilters[FilterCriterias.rimWidth]};`
    }

    if (selectedFilters[FilterCriterias.rimOffset]) {
        request += `${FilterCriterias.rimOffset},${selectedFilters[FilterCriterias.rimOffset]};`
    }

    if (selectedFilters[FilterCriterias.rimDiameter]) {
        request += `${FilterCriterias.rimDiameter},${selectedFilters[FilterCriterias.rimDiameter]};`
    }

    if (selectedFilters[FilterCriterias.rimColor]) {
        selectedFilters[FilterCriterias.rimColor]?.split(";").forEach((item) => {
            request += `${FilterCriterias.rimColor},${item};`
        })
    }

    if (selectedFilters[FilterCriterias.rimSort]) {
        selectedFilters[FilterCriterias.rimSort]?.split(";").forEach((item) => {
            request += `${FilterCriterias.rimSort},${item};`
        })
    }

    if (selectedFilters[FilterCriterias.rimAttribute1]) {
        request += `${FilterCriterias.rimAttribute1},${selectedFilters[FilterCriterias.rimAttribute1]};`
    }

    if (selectedFilters[FilterCriterias.rimType]) {
        request += `${FilterCriterias.rimType},${selectedFilters[FilterCriterias.rimType]};`
    }

    if (selectedFilters[FilterCriterias.rimAttribute2]) {
        request += `${FilterCriterias.rimAttribute2},${selectedFilters[FilterCriterias.rimAttribute2]};`
    }

    if (selectedFilters[FilterCriterias.rimAttribute3]) {
        request += `${FilterCriterias.rimAttribute3},${selectedFilters[FilterCriterias.rimAttribute3]};`
    }

    if (selectedFilters[FilterCriterias.rimAttribute4]) {
        request += `${FilterCriterias.rimAttribute4},${selectedFilters[FilterCriterias.rimAttribute4]};`
    }

    if (selectedFilters[FilterCriterias.OE]) {
        request += `${FilterCriterias.OE},${selectedFilters[FilterCriterias.OE]};`
    }

    if (selectedFilters[FilterCriterias.VSA]) {
        request += `${FilterCriterias.VSA},${selectedFilters[FilterCriterias.VSA]};`
    }

    return request
}

export function mapRimListFilters(filtersBySelectionOrder: FiltersBySelectionOrder[]) {
    let request = ""

    request = filtersBySelectionOrder.map((item) => `${item.attributeKey},${item.attributeValue}`).join(";")

    return request
}

export function addOrRemoveFilter(value: string, selectedFilter?: string): string {
    // Add value to string if does'n exist or remove it if exist
    if (!value) {
        return ""
    }

    let items = selectedFilter ? selectedFilter.split(";") : []

    if (selectedFilter && +value !== +selectedFilter) {
        if (items.length && !containsExactString(selectedFilter, value)) {
            items.push(value)
        }

        if (containsExactString(selectedFilter, value)) {
            items = items.filter((item) => item !== value)
        }
    } else if (selectedFilter && selectedFilter.includes(value)) {
        items = items.filter((item) => item !== value)
    } else {
        items.push(value)
    }

    return items.join(";")
}

export function addOrRemoveOneFilter(value: string, selectedFilter?: string): string {
    if (!value) {
        return ""
    }

    let items = selectedFilter ? selectedFilter.split(";") : []

    if (selectedFilter && selectedFilter.includes(value)) {
        items = items.filter((item) => item !== value)
    } else {
        items = [value]
    }

    return items.join(";")
}

export function createImagePathsRequest(colorCode: string, imageId: string): ImagePathsRequest {
    return {
        colorCode,
        idImage: parseInt(imageId) || 0,
        degree: 90,
    }
}

export function createRimFiltersRequest(
    carparkId: number,
    filtersBySelectionOrder: FiltersBySelectionOrder[],
    isAdditionalManufacturerSelected: boolean
): RimFiltersRequest {
    return {
        carparkId,
        rimFilter: mapRimListFilters(filtersBySelectionOrder),
        isAdditionalManufacturerSelected,
    }
}

export function generateConfiguratorImage(has2DConfig: boolean, has3DConfig: boolean, show3d: boolean, rimPositions?: any) {
    return new Promise<string | undefined>((resolve, reject) => {
        if ((document as any).documentMode) {
            // IF IE
            resolve(undefined)
        }

        if (has2DConfig && !show3d) {
            // let element = document.getElementById('2D-configurator') as HTMLDivElement
            const element = document.getElementById("2d-configurator-container") as HTMLDivElement

            if (element) {
                const invScale = (rimPositions.naturalHeight / element.clientHeight) * 1.74
                const options = {
                    width: element.clientWidth * invScale,
                    height: element.clientHeight * invScale,
                    pixelRatio: window.devicePixelRatio,
                }

                htmlToImage
                    .toCanvas(element, options)
                    .then((canvas: HTMLCanvasElement) => {
                        canvas = trimCanvas(canvas)
                        canvas.toBlob((blob: Blob | null) => {
                            if (blob) {
                                const reader = new FileReader()
                                reader.readAsDataURL(blob)
                                reader.onloadend = () => resolve(reader.result as string)
                                reader.onerror = () => reject()
                            } else {
                                reject()
                            }
                        })
                    })
                    .catch(() => reject())
            }
        } else if (has3DConfig && show3d) {
            const wrapper = document.getElementById("viewer") as HTMLDivElement
            let element = document.getElementById("ConfigurationRendererViewer-viewer") as HTMLCanvasElement
            wrapper.setAttribute("angle", "0")
            // PreyssConfigurator.changeRotation(0)
            sleep(300).then(() => {
                if (element) {
                    try {
                        element = trimCanvas(element)
                        element.toBlob((blob: Blob | null) => {
                            const context = element.getContext("2d")
                            context && context.clearRect(0, 0, element.width, element.height)
                            if (blob) {
                                const reader = new FileReader()
                                reader.readAsDataURL(blob)
                                reader.onloadend = () => resolve(reader.result as string)
                                reader.onerror = () => reject()
                            } else {
                                reject()
                            }
                        })
                    } catch (e) {
                        reject()
                    }
                }
            })
        } else {
            resolve(undefined)
        }
    })
}

export function trimCanvas(c: HTMLCanvasElement) {
    const ctx = c.getContext("2d")
    const copy = document.createElement("canvas").getContext("2d")
    if (!ctx || !copy) {
        return c
    }

    const pixels = ctx.getImageData(0, 0, c.width, c.height)
    const l = pixels.data.length
    let i
    const bound: any = {
        top: null,
        left: null,
        right: null,
        bottom: null,
    }
    let x
    let y

    for (i = 0; i < l; i += 4) {
        if (pixels.data[i + 3] !== 0) {
            x = (i / 4) % c.width
            y = ~~(i / 4 / c.width)

            if (bound.top === null) {
                bound.top = y
            }

            if (bound.left === null) {
                bound.left = x
            } else if (x < bound.left) {
                bound.left = x
            }

            if (bound.right === null) {
                bound.right = x
            } else if (bound.right < x) {
                bound.right = x
            }

            if (bound.bottom === null) {
                bound.bottom = y
            } else if (bound.bottom < y) {
                bound.bottom = y
            }
        }
    }
    const trimHeight = bound.bottom - bound.top
    const trimWidth = bound.right - bound.left
    const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

    copy.canvas.width = trimWidth
    copy.canvas.height = trimHeight
    copy.putImageData(trimmed, 0, 0)

    // Return trimmed canvas
    return copy.canvas
}

export function createErpInformationRequestItem(info: RimErpInfo, showIndustrialAvailability?: boolean): ErpInformationRequestItem {
    return {
        itemId: info.itemId,
        articleDescription: info.description,
        wholesalerArticleNumber: info.wholesalerArticleNumber,
        dataSupplierArticleNumber: info?.dataSupplierArticleNumber ?? "",
        quantityValue: 4,
        ...(info.stock > -1 &&
            showIndustrialAvailability && {
                productGroupId: info.productGroupId,
                dataSupplierId: info.supplierId,
                dataSupplierName: info.supplierName,
            }),
    }
}

export function containsExactString(wholeString: string, oneString: string) {
    const pattern = new RegExp(`\\b${oneString}\\b`)
    return pattern.test(wholeString)
}
