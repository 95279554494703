import { useCallback, useMemo } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { AddCatalogPartListRequest, Article, CatalogPart, ErpInformation } from "@tm/models"
import { isSameArticle, mapArticleToCatalogPart, useErpConfig } from "@tm/utils"
import { useBasketMemo, useWarehouse, useBasketParts } from "@bundles/basket"
import { useSetRecoilState } from "recoil"
import { useLocation } from "react-router"
import { SearchType } from "../../../../business"
import { useVehicle } from "../useVehicle"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ArticleErpInfoFactory } from "../useErpInfos"
import { RecommendedArticlesChainState } from "../../states"

export function useHandleAddToBasket(article: Article | Article[]) {
    const { workTaskId, workTask } = useWorkTask() ?? {}
    const { customer } = workTask ?? {}
    const vehicle = useVehicle()
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const defaultErpSystem = useDefaultErpSystem()

    const inputArticle = useMemo(() => {
        return Array.isArray(article) ? article : [article]
    }, [article])

    // TODO: Searchtyp anpassen,
    const basketMemo = useBasketMemo(workTask, SearchType.PARTSLIST)
    const warehouseData = useWarehouse(inputArticle[0]?.id, defaultErpSystem?.id)
    const { addCatalogPartList } = useBasketParts()
    const setArticlesChain = useSetRecoilState(RecommendedArticlesChainState(workTaskId))
    const location = useLocation()

    const handleAddToBasket = useCallback(
        (quantity: number, erpInfos?: ErpInformation[] | ErpInformation | null, erpSystemId?: number, enableCostEstimation?: boolean) => {
            if (!workTaskId) {
                return Promise.reject()
            }
            const customerAlsoBought = new URLSearchParams(location.search).get("customerAlsoBought")

            if (setArticlesChain && customerAlsoBought) {
                setArticlesChain((prev) => {
                    const prevChain = prev ?? []

                    const newArticles = Array.isArray(article) ? article : [article]

                    const filteredArticles = newArticles.filter(
                        (newItem) => !prevChain.some((articleGroup) => articleGroup.some((existingItem) => isSameArticle(existingItem, newItem)))
                    )

                    if (filteredArticles.length === 0) {
                        return prevChain
                    }

                    const newArticlesChain = [...prevChain, filteredArticles]

                    localStorage.setItem(`recommendedArticles/${workTaskId}`, JSON.stringify(newArticlesChain))

                    return newArticlesChain
                })
            }

            // send erpSystem only when useOrderByDistributor is true
            let erpSystem = defaultErpSystem
            if (useOrderByDistributor) {
                erpSystem = erpSystemConfigs?.find((x) => x.id === erpSystemId) ?? erpSystem
            }

            const addToBasketArticles = inputArticle.length === 1 ? [{ ...inputArticle[0], quantity }] : inputArticle
            const request: AddCatalogPartListRequest = {
                catalogParts: addToBasketArticles.map<CatalogPart>((basketArticle) => {
                    let erpInfo: ErpInformation | undefined
                    if (erpSystem) {
                        const erpInfoKey = ArticleErpInfoFactory.createKeyFromArticle(basketArticle, erpSystem.id)
                        erpInfo = Array.isArray(erpInfos) ? erpInfos?.find((erp) => erp.itemId === erpInfoKey.id) : (erpInfos ?? undefined)
                    }
                    return mapArticleToCatalogPart(
                        basketArticle,
                        erpInfo,
                        useOrderByDistributor ? erpSystem : undefined,
                        basketMemo.position,
                        warehouseData.warehouse
                    )
                }),
                workTaskId,
                vehicleId: vehicle?.id,
                customerId: customer?.id,
                usePercentageValues: true,
                // TODO: check which module shoudl be  logged
                // log: origin
                //     ? TmaHelper.AddCatalogPartToBasket.GetUserInteractionLog(
                //           addToBasketArticles.first()?.articleListPosition,
                //           TmaEModule.ARTICLE_LIST
                //       )
                //     : undefined,
            }

            return addCatalogPartList(request, enableCostEstimation)
        },
        [
            workTaskId,
            useOrderByDistributor,
            erpSystemConfigs,
            defaultErpSystem,
            inputArticle,
            vehicle?.id,
            customer?.id,
            basketMemo.position,
            warehouseData.warehouse,
        ]
    )
    return handleAddToBasket
}
