import { Alert, Box, Skeleton, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Dictionary, useSelection } from "@tm/utils"
import { useCallback, useEffect, useState } from "react"
import { Compilation } from "../../../../data"
import { isWM } from "../../../../utils"
import CompilationCreator from "./components/creator"
import { CompilationsList } from "./components/CompilationsList"
import { WmOrExpandedCompilationsList } from "./components/WmOrExpandedCompilationsList"
import CompilationsSelection from "./components/selection"

type Props = {
    compilations: Compilation[] | undefined
    loading: boolean
    error: string | undefined
    showCreator: boolean
    compact: boolean
    onHideCreator(): void
    openCompilationDetails(id: string): void
    openCompilationList(): void
    query?: string
}

export default function CompilationsListComponent(props: Props) {
    const { compact, compilations, loading, error, showCreator, onHideCreator, openCompilationDetails, openCompilationList, query } = props
    const [editingCompilations, setEditingCompilations] = useState<Dictionary<Compilation>>({})
    const { translateText } = useLocalization()

    const getAllIds = useCallback(() => compilations?.filter((x) => x.isOwn).map((x) => x.id) || [], [compilations])
    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllIds)

    useEffect(() => {
        unselectAll()
    }, [compilations, unselectAll])

    function renderCompilationsSelection() {
        if (!compact && compilations?.length) {
            if (selectedIds.length > 0) {
                const ownCompilations = compilations.filter((compilation) => compilation.isOwn)
                const allSelected = ownCompilations.length === selectedIds.length

                return (
                    <CompilationsSelection
                        allSelected={allSelected}
                        selectedCompilationIds={selectedIds}
                        onSelectAll={selectAll}
                        onUnselectAll={unselectAll}
                    />
                )
            }
        }
    }

    function renderEmpty() {
        return (
            <Box alignSelf="center" alignItems="center" display="flex">
                <Typography>{translateText(1284)}</Typography>
            </Box>
        )
    }

    function renderList(items: Compilation[]) {
        if (isWM() || !compact) {
            return (
                <WmOrExpandedCompilationsList
                    compilations={items}
                    onOpenCompilationDetails={openCompilationDetails}
                    onSelectCompilation={toggleSelected}
                    selectedCompilationIds={selectedIds}
                    compact={compact}
                    showCreator={showCreator}
                    onShowCreator={onHideCreator}
                    editingCompilations={editingCompilations}
                    setEditingCompilations={setEditingCompilations}
                />
            )
        }

        return (
            <CompilationsList
                compilations={items}
                onOpenCompilationDetails={openCompilationDetails}
                openCompilationList={openCompilationList}
                showCreator={showCreator}
                onShowCreator={onHideCreator}
                query={query}
            />
        )
    }

    if (props.error) {
        return <Alert severity="error">{error}</Alert>
    }

    if (isWM() && compact) {
        return null
    }

    if (loading) {
        return (
            <Stack flex={compact ? undefined : 1} minWidth="25rem" style={{ paddingTop: "1rem" }}>
                <Skeleton variant="text" height="30px" />
                <Skeleton variant="text" height="63px" />
                <Skeleton variant="text" height="63px" />
            </Stack>
        )
    }

    return (
        <Stack flex={compact ? undefined : 1} minWidth="25rem">
            <Box position="relative">{renderCompilationsSelection()}</Box>
            {showCreator && !compact && <CompilationCreator onClose={onHideCreator} />}
            {!compilations || compilations.length === 0 ? renderEmpty() : renderList(compilations)}
        </Stack>
    )
}
