import { Box, styled, VehicleImage } from "@tm/components"
import { VehicleType } from "@tm/models"
import { useSelector, useDispatch } from "react-redux"
import { TmaHelper } from "@tm/utils"
import { useTyresMatchCodeSearchType } from "@tm/context-distribution"
import { MatchCodeSearch } from "../../_shared/matchCodeSearch"
import { MainActions } from "../../main/business"
import { MainState } from "../../main"

export function SummaryHeader() {
    const dispatch = useDispatch()

    const searchValue = useSelector((state: MainState) => state.list.searchValue)
    const vehicle = useSelector((state: MainState) => state.manager.vehicle)
    const showMatchCodeSearch = useTyresMatchCodeSearchType()

    const handleMatchCodeSearch = (size: string) => {
        dispatch(MainActions.updateSearchValue(size))

        const params = {
            size,
            season: undefined,
            carType: undefined,
        }

        TmaHelper.UniParts.Search.TyresSelection(size)
        dispatch(MainActions.loadTyresList(true))
        dispatch(MainActions.changeStep("list", params))
    }

    const isIcon = vehicle?.vehicleType === VehicleType.CommercialVehicle || vehicle?.vehicleType === VehicleType.Motorcycle

    return (
        <StyledHeaderBox>
            {showMatchCodeSearch && <MatchCodeSearch withTitle value={searchValue} onButtonClick={handleMatchCodeSearch} />}
            {vehicle?.modelThumbnail && (
                <StyledVehicleImage
                    modelImage={vehicle.modelThumbnail}
                    modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                    vehicleType={vehicle.vehicleType}
                    isIcon={isIcon}
                />
            )}
        </StyledHeaderBox>
    )
}

const StyledHeaderBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.margin?.m,
}))

const StyledVehicleImage = styled(VehicleImage)<{ isIcon?: boolean }>(({ isIcon }) => ({
    width: "auto",
    height: "3em",
    marginLeft: "7em",
    transform: isIcon ? "" : "rotateY(180deg)",
}))
