import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import merge from "lodash/merge"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()

    const themeStyles: NestedCSSProperties = {
        color: color(colors.dark).setAlpha(opacity.secondary).toRgbString(),
        $nest: {
            ".icon": {
                fill: color(colors.dark).setAlpha(opacity.secondary).toRgbString(),
            },
            ":hover .icon": {
                fill: colors.dark,
            },
            "&--selected": {
                backgroundColor: "#fafcff",
            },
            "&:not(&--selected)": {
                borderLeftColor: color(colors.primary).setAlpha(opacity.secondary).toRgbString(),
            },
            "&:hover:not(&--selected)": {
                borderLeftColor: color(colors.primary).setAlpha(opacity.primary).toRgbString(),
                backgroundColor: "transparent",
                color: colors.dark,
                $nest: {
                    ".icon": {
                        fill: colors.dark,
                    },
                },
            },
        },
    }
    const mergedStyles = merge(baseStyles, themeStyles)
    return mergedStyles
}
