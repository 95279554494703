import { getInitialState } from "."
import {
    endPageActions,
    imagesActions,
    inspectionWorkActions,
    maintenancePlanActions,
    maintenanceReviewActions,
    navigationActions,
    printOptionsActions,
    stepNavigationActions,
    technicianActions,
    testDriveActions,
    testerActions,
    tyresWheelsActions,
    fastServiceStore,
    worksActions,
    FastServiceStore,
} from "../.."
import { CalculationHistoryStatus } from "../../models"

export function setStateFromHistory(historyState: FastServiceStore) {
    const { version } = fastServiceStore.getState()
    if (historyState.version === version) {
        fastServiceStore.setState({ ...historyState }, false, "Set state from history")
    }
}

export function resetStore() {
    endPageActions.resetEndPage()
    imagesActions.resetImages()
    inspectionWorkActions.resetInspectionWork()
    maintenancePlanActions.resetMaintenancePlan()
    maintenanceReviewActions.resetMaintenanceReview()
    resetManager()
    navigationActions.resetNavigation()
    printOptionsActions.resetPrintOptions()
    stepNavigationActions.resetStepsNavigation()
    technicianActions.resetTechnician()
    testDriveActions.resetTestDrive()
    testerActions.resetTester()
    tyresWheelsActions.resetTyresWheels()
    worksActions.resetWorks()
}

export function resetManager() {
    fastServiceStore.setState(getInitialState(), false, "Reset manager slice")
}

export function hydrateWorktaskSwitch(hydrate: boolean) {
    fastServiceStore.setState({ hydrateWorktaskSwitch: hydrate }, false, "Hydrate worktask switch")
}

export function setHistoryLoading(loading: boolean) {
    fastServiceStore.setState({ historyLoading: loading }, false, "History loading")
}

export function setCalculationHistoryId(calculationId: string) {
    fastServiceStore.setState({ calculationHistoryId: calculationId }, false, "Set calculation history id")
}

export function setCalculationStatus(calculationStatus: CalculationHistoryStatus) {
    fastServiceStore.setState({ calculationStatus }, false, "Set calculation status")
}

export function reInitializeStore() {
    const {
        maintenancePlan: { stateVehicle, modelDetails },
    } = fastServiceStore.getState()
    fastServiceStore.persist.clearStorage()
    resetStore()

    if (stateVehicle && modelDetails) {
        maintenancePlanActions.setVehicle(stateVehicle, modelDetails)
    }
}

export function setFullScreen(isFullScreen: boolean) {
    fastServiceStore.setState(
        {
            isFullScreen,
        },
        false,
        "Set full screen"
    )
}

export function setWorktaskId(worktaskId: string) {
    fastServiceStore.setState(
        {
            worktaskId,
        },
        false,
        "Set worktask id"
    )
}
