import { Box, Checkbox, styled, Typography } from "@tm/components"
import { classes } from "@tm/utils"

type Props = {
    label: string
    info?: string
    articleCount?: number
    selected?: boolean
    onChange?: () => void
}

export function SelectionCheckbox({ label, info, onChange, selected, articleCount }: Props) {
    return (
        <Box display="flex" className={classes("is-clickable selection-list__item--high-priority")}>
            <Checkbox checked={selected} size="small" onChange={onChange} />
            <Box className="selection-list__item" onClick={onChange}>
                <StyledTypography>{label}</StyledTypography>
                {info && <Typography fontSize="11.25px">&nbsp;{info}</Typography>}
                {articleCount ? <Typography fontSize="11.25px">&nbsp;({articleCount})</Typography> : ""}
            </Box>
        </Box>
    )
}

const StyledTypography = styled(Typography)({
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "1.2",
    display: "inline",
    opacity: ".87",
    color: "#202020",
    fontFamily: "Open Sans, Arial, Helvetica, sans- serif",
})
