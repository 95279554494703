import { Articles } from "@tm/data"
import { Article, Vehicle } from "@tm/models"
import { uniqBy } from "lodash"
import { PartItem } from "../../../../../../../basket/src/data/model"

export function createRecommendedArticlesRequest(
    parts: PartItem[] | undefined,
    article: Article,
    vehicle?: Vehicle
): Articles.GetRecommendedArticlesRequest | undefined {
    const basketArticles = parts?.filter((item) => item.vehicleId === vehicle?.id) ?? []

    let recommendedArticlesIn: Articles.RecommendedArticleIn[] = []

    basketArticles.forEach((basketArticle) => {
        if (basketArticle.articleInformation.articleNumber && basketArticle.articleInformation.supplierId) {
            recommendedArticlesIn.push({
                supplierArticleNo: basketArticle.articleInformation.articleNumber,
                supplierId: basketArticle.articleInformation.supplierId,
            })
        }
    })

    recommendedArticlesIn.push({
        supplierArticleNo: article.supplierArticleNo,
        supplierId: article.supplier?.id,
    })

    recommendedArticlesIn = uniqBy(recommendedArticlesIn, (item) => `${item.supplierArticleNo}_${item.supplierId}`)

    if (!recommendedArticlesIn.length) {
        return undefined
    }

    return {
        modelId: vehicle?.tecDocTypeId ?? undefined,
        maxCount: 1,
        articles: recommendedArticlesIn,
    }
}
