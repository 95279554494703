import { Switch } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { useHistory, useParams, useRouteMatch } from "react-router"
import { VoucherRouteParams } from "../../business"
import { useShowOnlyUserVouchers } from "../../data/hooks/useShowOnlyUserVouchers"

type Props = {
    showLabelStart?: boolean
}

export function ShowOnlyUserVouchersSwitch({ showLabelStart }: Props) {
    const { translateText } = useLocalization()
    const history = useHistory()
    const params = useParams<VoucherRouteParams>()
    const { path } = useRouteMatch()

    const { showOnlyUserVouchers, setShowOnlyUserVouchers, showOnlyUserVouchersLoaded } = useShowOnlyUserVouchers()

    function handleShowOnlyUserVoucherChange() {
        if (params.id) {
            const newUrl = renderRoute(path, { ...params, id: undefined })
            history.push(newUrl)
        }
        setShowOnlyUserVouchers?.(!showOnlyUserVouchers)
    }

    return (
        <Switch
            checked={showOnlyUserVouchers}
            size="small"
            label={showLabelStart ? translateText(1131) : undefined}
            labelPlacement="start"
            onChange={handleShowOnlyUserVoucherChange}
            disabled={!showOnlyUserVouchersLoaded}
        />
    )
}
