import { css, getStyleTheme, withStyle, StyleProps } from "@tm/context-distribution"
import { bindSpecialReactMethods, classes, equals } from "@tm/utils"
import { scale } from "csx"
import * as Dragscroll from "dragscroll"
import { Component } from "react"
import { GraphicActions } from "."
import { bem } from "../../data/helpers"
import CustomTooltip, { CustomTooltipPosition } from "./customTooltip"

type SVGItem = {
    id: string
    tooltip?: string
    isVisible?: boolean
    color?: string
}

type Props = StyleProps<typeof stylesheet> & {
    svgImage: string
    zoomEnabled?: boolean
    withoutHover?: boolean
    withoutTooltip?: boolean
    items?: SVGItem[]
    selected?: string | string[]
    className?: string
    disabled?: boolean
    withColors?: boolean
    renderClickTooltip?: JSX.Element
    // in case on multiple items on screen
    index?: number
    // multipeItemsOnView?: boolean
    isVknImage?: boolean
    loading?: boolean
    onClick?(value: string): void
}

type State = {
    zoomLevel: number
}

class GraphicViewer extends Component<Props, State> {
    baseTooltipRef: CustomTooltip | null

    clickTooltipRef: CustomTooltip | null

    graphicRef: HTMLDivElement | null

    mouseX: number

    mouseY: number

    elements: Record<string, SVGGElement>

    listenersAdded?: boolean

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        this.elements = {}

        this.state = {
            zoomLevel: 1,
        }
    }

    updateSvgSize() {
        const { isVknImage } = this.props

        const svgRef = this.graphicRef?.firstElementChild as SVGSVGElement
        const gRef = ((isVknImage && svgRef?.children?.[0]) || svgRef?.children?.[1]) as SVGGElement | undefined
        if (svgRef && gRef && gRef.getBBox?.()) {
            const clientrect = gRef.getBBox()
            svgRef.setAttribute("viewBox", `${clientrect.x} ${clientrect.y} ${clientrect.width} ${clientrect.height}`)
        }
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (props.loading != this.props.loading) {
            setTimeout(() => {
                this.updateSvgSize()
            }, 50)
        }
    }

    componentDidMount() {
        this.updateSvgSize()
        if (!this.listenersAdded) {
            ;(this.props.isVknImage && this.updateVknPositions()) || this.updatePositions()
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if ((prevState.zoomLevel == 1 && this.state.zoomLevel != 1) || (this.state.zoomLevel == 1 && prevState.zoomLevel != -1)) {
            Dragscroll.reset()
        }
        if (!equals(prevProps.items, this.props.items) || !equals(prevProps.selected, this.props.selected)) {
            ;(this.props.isVknImage && this.updateVknPositions()) || this.updatePositions()
        }

        if (this.graphicRef?.firstElementChild && prevState.zoomLevel != this.state.zoomLevel) {
            const divRect = this.graphicRef.getBoundingClientRect()
            if (divRect) {
                if (divRect) {
                    const svgRef = this.graphicRef?.firstElementChild as SVGSVGElement
                    const zoomLevel = this.state.zoomLevel * 0.5 + 0.5
                    svgRef.style.cursor = zoomLevel > 1 ? "all-scroll" : "inherit"
                    svgRef.style.transform = scale(zoomLevel)

                    // scroll by mause position
                    // this.graphicRef!.scrollLeft = (this.mouseX - divRect.left) * (zoomLevel - 1)
                    // this.graphicRef!.scrollTop = (this.mouseY - divRect.top) * (zoomLevel - 1)

                    // scroll in the middle
                    this.graphicRef!.scrollLeft = (divRect.width / 2) * (zoomLevel - 1)
                    this.graphicRef!.scrollTop = (divRect.height / 2) * (zoomLevel - 1)
                }
            }
        }
    }

    componentWillUnmount() {
        Object.values(this.elements).forEach((element) => {
            element.removeEventListener("click", () => {})
            element.removeEventListener("mousemove", () => {})
            element.removeEventListener("mouseleave", () => {})
            element.removeEventListener("mousedown", () => {})
        })
    }

    handleClick(id: string, ev: MouseEvent) {
        const { renderClickTooltip } = this.props

        ev.preventDefault()
        ev.stopImmediatePropagation()

        if (Math.abs(this.mouseX - ev.pageX) > 5 || Math.abs(this.mouseY - ev.pageY) > 5) {
            return
        }

        this.props.onClick?.(id)

        if (renderClickTooltip) {
            const position = this.getTooltipPosition(ev)
            const maxHeight = this.getMaxHeight(ev, position)
            this.clickTooltipRef?.show(ev, position, undefined, maxHeight)
        }
    }

    getMaxHeight(event: MouseEvent, position: CustomTooltipPosition) {
        const divRect = this.graphicRef?.getBoundingClientRect()
        const { clientY } = event
        if (divRect) {
            const yInRect = clientY - divRect.top
            const remaining = divRect.height - yInRect

            switch (position) {
                case "bottom-left":
                case "bottom-right":
                    return remaining
                case "middle-left":
                case "middle-right":
                    return remaining + yInRect
                case "top-left":
                case "top-right":
                    return yInRect
                default:
                    return (remaining + yInRect) / 2
            }
        }
        return 0
    }

    getTooltipPosition(event: MouseEvent): CustomTooltipPosition {
        const divRect = this.graphicRef?.getBoundingClientRect()
        const { clientX, clientY } = event
        if (divRect) {
            const xInRect = clientX - divRect.left
            const yInRect = clientY - divRect.top

            if (xInRect < divRect.width / 2) {
                if (yInRect < divRect.height / 3) {
                    return "bottom-right"
                }
                if (yInRect > divRect.height / 3 && yInRect < divRect.height * (2 / 3)) {
                    return "middle-right"
                }
                return "top-right"
            }

            if (yInRect < divRect.height / 3) {
                return "bottom-left"
            }
            if (yInRect > divRect.height / 3 && yInRect < divRect.height * (2 / 3)) {
                return "middle-left"
            }
            return "top-left"
        }
        return "bottom-right"
    }

    handleMouseMove(element: SVGGElement, item: SVGItem, ev: MouseEvent) {
        const { withoutHover, withoutTooltip } = this.props

        if (!withoutHover) {
            element.setAttribute("hovered", "true")
        }

        if (!withoutTooltip) {
            !withoutTooltip && this.baseTooltipRef?.show(ev, undefined, item?.tooltip)
        }
    }

    handleMouseLeave(element: SVGGElement) {
        const { withoutHover, withoutTooltip } = this.props

        !withoutHover && element.removeAttribute("hovered")
        !withoutTooltip && this.baseTooltipRef?.hide()
    }

    handleMouseDown(ev: MouseEvent) {
        this.mouseX = ev.pageX
        this.mouseY = ev.pageY
    }

    updatePositions() {
        const { items, selected, index } = this.props
        items?.forEach((item) => {
            const nodeElements = document.querySelectorAll(`[id='${item.id}']`)
            let elements = [].slice.call(nodeElements)
            const selectedItems = (Array.isArray(selected) && selected) || [selected]
            if (elements) {
                if (index != undefined && elements.length > 1) {
                    elements = elements.filter((_, idx) => idx == index)
                }

                elements.forEach((gElement: SVGGElement, idx) => {
                    // if (idx != elements.length - 1 && !this.props.multipeItemsOnView)
                    //     return

                    this.elements[item.id] = gElement

                    if (!item.isVisible) {
                        gElement.setAttribute("hidden", "true")
                        return
                    }
                    gElement.removeAttribute("hidden")

                    if (selectedItems.includes(gElement.id)) {
                        gElement.setAttribute("itemselected", "true")
                    } else {
                        gElement.removeAttribute("itemselected")
                    }

                    if (this.props.withColors && item.color) {
                        const polygons = gElement.getElementsByTagName("polygon")
                        if (polygons?.length > 0) {
                            const polygonElements = [].slice.call(polygons) as SVGPolygonElement[]

                            for (const polygon of polygonElements) {
                                polygon.style.fill = item.color
                            }
                        }

                        const paths = gElement.getElementsByTagName("path")
                        if (paths?.length > 0) {
                            const pathElements = [].slice.call(paths) as SVGPathElement[]

                            for (const path of pathElements) {
                                path.style.fill = item.color
                            }
                        }
                    }
                    gElement.addEventListener("mousemove", this.handleMouseMove.bind(this, gElement, item))
                    gElement.addEventListener("mouseleave", this.handleMouseLeave.bind(this, gElement, item))
                    gElement.addEventListener("mousedown", this.handleMouseDown.bind(this))
                    gElement.addEventListener("click", this.handleClick.bind(this, gElement.id))
                })
            }
        })
    }

    updateVknPositions() {
        const { items, selected } = this.props
        const selector = "[objecttype='PS']"
        const nodeElements = document.querySelectorAll(selector)
        const elements = [].slice.call(nodeElements) as SVGGElement[]
        // const vknImageName = elements[0].parentElement?.id ?? ""

        // let ar: SVGGElement[] = []
        // for (let i = 0; i < elements.length; i++) {
        //     if (elements[i].parentElement?.id == vknImageName && !ar.find(x => x.id == elements[i].id))
        //         ar.push(elements[i])
        //     else
        //         break
        // }

        // elements = ar
        const selectedItems = (Array.isArray(selected) && selected) || [selected]
        if (elements) {
            elements.forEach((gElement) => {
                const item = items?.find((x) => x.id == gElement.id)

                if (!item || (item && !item.isVisible)) {
                    gElement.setAttribute("hidden", "true")
                    return
                }
                gElement.removeAttribute("hidden")

                this.elements[item?.id] = gElement

                if (selectedItems.includes(gElement.id)) {
                    gElement.setAttribute("itemselected", "true")
                } else {
                    gElement.removeAttribute("itemselected")
                }

                if (this.props.withColors && item.color) {
                    const polygons = gElement.getElementsByTagName("polygon")
                    if (polygons?.length > 0) {
                        const polygonElements = [].slice.call(polygons) as SVGPolygonElement[]

                        for (const polygon of polygonElements) {
                            polygon.style.fill = item.color
                        }
                    }

                    const paths = gElement.getElementsByTagName("path")
                    if (paths?.length > 0) {
                        const pathElements = [].slice.call(paths) as SVGPathElement[]

                        for (const path of pathElements) {
                            path.style.fill = item.color
                        }
                    }
                }
                item && gElement.addEventListener("mousemove", this.handleMouseMove.bind(this, gElement, item))
                item && gElement.addEventListener("mouseleave", this.handleMouseLeave.bind(this, gElement, item))
                item && gElement.addEventListener("mousedown", this.handleMouseDown.bind(this))
                item && gElement.addEventListener("click", this.handleClick.bind(this, gElement.id))
            })
        }
    }

    zoomInEnabled() {
        return this.state.zoomLevel <= 10
    }

    zoomOutEnabled() {
        return this.state.zoomLevel >= 1.5
    }

    handleZoomIn() {
        this.zoomInEnabled() && this.setState((prevState) => ({ zoomLevel: prevState.zoomLevel + 0.5 }))
    }

    handleZoomOut() {
        this.zoomOutEnabled() && this.setState((prevState) => ({ zoomLevel: prevState.zoomLevel - 0.5 }))
    }

    handleReset() {
        this.setState({ zoomLevel: 1 })
    }

    handleZoom(e: React.WheelEvent<HTMLDivElement>) {
        this.mouseX = e.pageX
        this.mouseY = e.pageY

        if (e.deltaY < 0) {
            this.handleZoomIn()
        } else {
            this.handleZoomOut()
        }
    }

    handleArrowClick(direction: "left" | "up" | "right" | "down") {
        const { graphicRef } = this
        const movement = 50
        if (!graphicRef) {
            return
        }

        switch (direction) {
            case "left":
                graphicRef.scrollLeft += movement
                break
            case "up":
                graphicRef.scrollTop += movement
                break
            case "right":
                graphicRef.scrollLeft -= movement
                break
            case "down":
                graphicRef.scrollTop -= movement
                break
        }
    }

    render() {
        const { className, zoomEnabled, svgImage, renderClickTooltip, style, disabled } = this.props
        const baseClaseName = bem(style.graphic, disabled && "disabled")
        return (
            <div onWheel={(zoomEnabled && this.handleZoom) || undefined} className={classes(className, baseClaseName)}>
                {zoomEnabled && (
                    <GraphicActions
                        className={style.actions}
                        zoomOutEnabled={this.zoomOutEnabled()}
                        zoomInEnabled={this.zoomInEnabled()}
                        onZoomOut={this.handleZoomOut}
                        onArrow={this.handleArrowClick}
                        zoomLevel={this.state.zoomLevel}
                        onReset={this.handleReset}
                        onZoomIn={this.handleZoomIn}
                    />
                )}

                <div
                    id="svg-wrapper"
                    className={classes(style.svgWrapper, "dragscroll")}
                    ref={(ref) => (this.graphicRef = ref)}
                    dangerouslySetInnerHTML={{ __html: svgImage }}
                />

                <CustomTooltip ref={(ref) => (this.baseTooltipRef = ref)} />
                {renderClickTooltip && <CustomTooltip ref={(ref) => (this.clickTooltipRef = ref)} renderContent={renderClickTooltip} />}
            </div>
        )
    }
}

function stylesheet() {
    const theme = getStyleTheme()
    return css({
        graphic: {
            position: "relative",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            $nest: {
                "&--disabled": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                },
            },
        },
        actions: {
            zIndex: 1,
            position: "absolute",
            top: 0,
            left: 0,
        },
        svgWrapper: {
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            $nest: {
                svg: {
                    flex: "1 1 0",
                    // pointerEvents: (props.withoutHover || props.withoutTooltip) && "none" || undefined,
                    transformOrigin: "top left",
                },

                "g[hovered]": {
                    $nest: {
                        path: {
                            fill: `${theme.colors.primary} !important`,
                            cursor: "pointer",
                        },
                        polygon: {
                            fill: `${theme.colors.primary} !important`,
                            cursor: "pointer",
                        },
                    },
                },
                "g[itemselected]": {
                    $nest: {
                        path: {
                            fill: theme.colors.primary,
                            cursor: "pointer",
                        },
                        polygon: {
                            fill: theme.colors.primary,
                            cursor: "pointer",
                        },
                    },
                },
                "g[hidden]": {
                    display: "none",
                },
            },
        },
    })
}

export default withStyle(stylesheet, GraphicViewer)
