import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { useArticlesByArticleNumbersWithOptionalVehicle } from "@tm/utils"
import { Article } from "@tm/models"
import { ReturnItem } from "../model"
import { mapReturnItemsToArticleIdentifiers, mapUpdatedReturnItem } from "../mapper"

/*
@description returns an updated returnItems array, while fetching articles the old returnItems are returned
 */
export function useUpdatedReturnItems(returnItems: ReturnItem[], enabled: boolean): ReturnItem[] {
    const { articles, isLoading } = useCatalogArticles(returnItems, enabled)

    if (isLoading) {
        return returnItems
    }

    const updatedReturnItems = returnItems.map((returnItem) => {
        const article = getMatchingCatalogArticle(returnItem, articles ?? [])
        if (!article) {
            return returnItem
        }
        return mapUpdatedReturnItem(returnItem, article)
    })

    return updatedReturnItems
}

function useCatalogArticles(returnItems: ReturnItem[], enabled: boolean) {
    const { language } = useLocalization()
    const request = useMemo(() => {
        return mapReturnItemsToArticleIdentifiers(returnItems)
    }, [returnItems])

    const { articles, isLoading } = useArticlesByArticleNumbersWithOptionalVehicle(enabled ? request : undefined, language)

    return { articles, isLoading }
}

function getMatchingCatalogArticle(returnItem: ReturnItem, articles: Article[]) {
    return articles.find(
        (article) =>
            !!returnItem.dataSupplierArticleNumber &&
            !!returnItem.dataSupplierId &&
            !!returnItem.productGroupId &&
            returnItem.dataSupplierId === article.supplier.id &&
            returnItem.dataSupplierArticleNumber === article.supplierArticleNo &&
            returnItem.productGroupId === article.productGroup.id
    )
}
