import { CountrySelection, SxProps, Theme } from "@tm/components"
import { Vehicle, VehicleLookupConfig, VehicleType } from "@tm/models"
import { useSelectedVehicleLookup } from "../../data/hooks/useSelectedLookupConfig"

export type VehicleLookupSelectionProps = {
    vehicleType: VehicleType
    selectedSearchConfig?: VehicleLookupConfig
    onSelectSearchOption: (config: VehicleLookupConfig) => void
    onOpen?: () => void
    sx?: SxProps<Theme>
    variant?: "outlined" | "standard" | "filled"
    tooltipTitle?: string
    className?: string
    selectedVehicle?: Vehicle
}

export function VehicleLookupSelection(props: VehicleLookupSelectionProps) {
    return <CountrySelection {...props} useSelectedVehicleLookup={useSelectedVehicleLookup} flagPath="/styles/base/images/flags/" />
}
