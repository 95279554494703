import {
    ETyresCarType,
    TyresSeason,
    RepairTimeProvider,
    Vehicle,
    FilterNames,
    TyreFilter,
    GetArticleListByMatchCodeRequest,
    TyreArticle,
} from "@tm/models"
import { FilterType } from "../../../data/enums"

export type ListState = {
    initialized?: boolean
    seasonInitialized?: boolean
    searchValue: string
    clippedFilters: FilterType
    clipedFiltersLoaded?: boolean

    articles: {
        loading?: boolean
        loadingNextItems?: boolean
        loadingAutoItems?: boolean
        nextArticlesError?: boolean
        data: TyreArticle[]
        error?: boolean
        count: number
        pageIndex: number
        autoNextCount: number
        defaultQuantity: number
    }
    lastSearch: {
        carType?: ETyresCarType[]
        seasons?: TyresSeason[] | undefined
        size?: string
        byMatchcode?: boolean
    }
    leftFiltersLoading?: boolean
    filters: { [key in IListFilters]: TyreFilter[] } & {
        leftLoading?: boolean
        topError?: boolean
    }

    selectedFilters: { [key in IListSingleFilters]?: TyreFilter } & { [key in IListMultiFilters]?: TyreFilter[] } & {
        availability: AvailabilityFilterType
    }

    vehicle?: Vehicle
    // erpInformations: ErpInformation[]
    repairTimeAvailabilities: { [id: number]: Array<RepairTimeProvider> }

    request?: GetArticleListByMatchCodeRequest
}

export type IListFilters = IListSingleFilters | IListMultiFilters

export type IListSingleFilters = Extract<FilterNames, "fuelEfficiency" | "externalRolling" | "wetGripClass">

export type IListMultiFilters = Extract<
    FilterNames,
    "oeIdentifier" | "loadIndex" | "speedIndex" | "weight" | "manufacturer" | "extras" | "season" | "studed" | "studdable" | "silent"
>

export enum AvailabilityFilterType {
    None = 0,
    Primary = 1,
    Secondary = 2,
}
