import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const costEstimation: BundleComponent<unknown, typeof component> = {
    name: "cost-estimation",
    reduce,
    component,
}

export default costEstimation
