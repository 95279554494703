import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, ButtonKeyDefinition } from "@tm/utils"
import { Widget, InputGroup } from "@tm/controls"
import { Box, Typography, TextField, LinkButton, Icon, Stack } from "@tm/components"
import { useParams } from "react-router"
import { useBackordersCounts } from "../../data/hooks/useBackordersCount"
// import { useBackordersRecent } from "../../data/hooks/useBackordersRecent"
import BackordersTableComponent from "../_shared/tables/BackordersTable"
import { useBackorders } from "../../data/hooks/useBackorders"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"

type Props = {
    backordersRoute: string
}

type RouteProps = {
    subpage?: string
    voucherTypeId?: string
    id?: string
}

export default function WidgetBackordersComponent({ backordersRoute }: Props) {
    const { translateText, translate } = useLocalization()
    const matchParams = useParams<RouteProps>()
    const { backordersCount, backordersCountLoading } = useBackordersCounts()
    const { backorders, backordersLoading } = useBackorders(false, undefined)

    /**
     *  This commented code shall not be deleted.
     *  The first version of the PV service for backorders doesn't support filtering.
     *  A future Version might support it, so just in that case these lines must remain.
     *  * */
    // const { backordersLoading, backorders } = useBackordersRecent()
    // const [searchText, setSearhText] = useState<string>()

    const url = renderRoute(backordersRoute, { ...matchParams, subpage: "backorders" })

    const backorderCountText = useMemo(() => {
        if (backordersCount) {
            return ` (${backordersCount} ${translateText(90)}) `
        }
    }, [backordersCount, translateText])

    /**
     *  This commented code shall not be deleted.
     *  The first version of the PV service for backorders doesn't support filtering.
     *  A future Version might support it, so just in that case these lines must remain.
     *  * */

    // function handleSearchTextChange({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) {
    //     if (value && value.length <= 40) {
    //         setSearhText(value)
    //     }
    // }

    // function handleSearchKeyDown(e: KeyboardEvent) {
    //     if (e.key === ButtonKeyDefinition.Enter && searchText) {
    //         url += `?query=${encodeURIComponent(searchText)}`
    //         history.push(url)
    //     }
    // }

    function renderBackordersTable() {
        return (
            <Box alignContent="center">
                {/**
                 *  This commented code shall not be deleted.
                 *  The first version of the PV service for backorders doesn't support filtering.
                 *  A future Version might support it, so just in that case these lines must remain.
                 *  * */}
                {/* <InputGroup>
                    <TextField
                        value={searchText}
                        onKeyDown={handleSearchKeyDown}
                        onChange={handleSearchTextChange}
                        placeholder={translateText(13178)}
                        size="small"
                        fullWidth
                        sx={{ ".MuiFilledInput-root": { paddingRight: 0 }, marginRight: "0.8em" }}
                        InputProps={{
                            endAdornment: (
                                <LinkButton to={url + (searchText ? `?query=${encodeURIComponent(searchText)}` : "")} sx={{ minWidth: "15px" }}>
                                    <Icon name="search" />
                                </LinkButton>
                            ),
                        }}
                    />
                </InputGroup> */}
                <Box height="187px" maxHeight="100%">
                    {/* height: legacy code, thats a no go */}
                    <BackordersTableComponent variant="small" backorders={backorders} backordersLoading={backordersLoading} />
                </Box>
            </Box>
        )
    }

    return (
        <Widget
            size="4x2"
            iconName="voucher"
            title={translate(13173)}
            header={
                <WidgetHeaderTitleComponent
                    iconName="voucher"
                    titleTextId={13173}
                    additionalInfoLoading={backordersCountLoading}
                    additionalInfo={backorderCountText}
                />
            }
            active
        >
            <Stack spacing={0.5}>
                {renderBackordersTable()}
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="label">{`*${translate(694)}`}</Typography>
                    <LinkButton to={url} disabled={backordersLoading}>
                        {translate(43)}
                    </LinkButton>
                </Box>
            </Stack>
        </Widget>
    )
}
