import { useUser } from "@tm/context-distribution"
import { Text, Widget } from "@tm/controls"
import { EFilterNames, ETyresSeason, SystemType, TyresSeason } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { batch, useDispatch, useSelector } from "react-redux"
import { Box, Loader, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { createSpecificQuery } from "@bundles/tyres/data/helpers/createSpecificQuery"
import { Statics } from "@bundles/tyres/data/statics"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { DriveRightTyresTable } from "../../_shared/driveRTable"
import { mapTyres } from "../business"
import { getBundleParams } from "../../../utils"
import { Tyre } from "../../../data/models"

type Props = {
    onVehicleRedirect(): void
    className?: string
}

export function Suggestions({ onVehicleRedirect, className }: Props) {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { isFiltersOnRightSide } = getBundleParams()

    const driveRightTyres = useSelector((state: MainState) => state.summary.driveRightTyres)
    const vehicle = useSelector((state: MainState) => state.manager.vehicle)
    const selectedFilters = useSelector((state: MainState) => state.summary.selectedFilters)

    const { frontTyres, rearTyres, error, loading } = driveRightTyres
    const tyres = mapTyres(frontTyres, rearTyres)
    const { carType } = selectedFilters

    const handleDriveTyreSelect = (item: Tyre, isFrontTyres: boolean, season?: TyresSeason) => {
        const query = `${item[isFrontTyres ? "sizeFront" : "sizeRear"]} ${createSpecificQuery(
            item[isFrontTyres ? "loadIndexFront" : "loadIndexRear"],
            item[isFrontTyres ? "speedIndexFront" : "speedIndexRear"]
        )}`

        if (!season) {
            season = selectedFilters.season?.[0]?.value as TyresSeason | undefined

            if (!item[isFrontTyres ? "seasonSummerFront" : "seasonSummerRear"] || !item[isFrontTyres ? "seasonWinterFront" : "seasonWinterRear"]) {
                season = item[isFrontTyres ? "seasonSummerFront" : "seasonSummerRear"] ? ETyresSeason.summer : ETyresSeason.winter
            }
        }

        const params = { size: query.trim(), season: season ? [season] : [], carType: carType?.map((x: any) => x.value) }
        const seasonFilter = Statics.seasons.find((x) => x.query === season)

        let tyreItemSize = `${item[isFrontTyres ? "sizeFront" : "sizeRear"]} `
        if (item[isFrontTyres ? "loadIndexFront" : "loadIndexRear"]) {
            tyreItemSize += item[isFrontTyres ? "loadIndexFront" : "loadIndexRear"]
        }
        if (item[isFrontTyres ? "speedIndexFront" : "speedIndexRear"]) {
            tyreItemSize += item[isFrontTyres ? "speedIndexFront" : "speedIndexRear"]
        }

        TmaHelper.UniParts.Search.TyresSelection(query)

        batch(() => {
            if (seasonFilter) {
                dispatch(MainActions.resetSummaryFilter(EFilterNames.season, true))
                dispatch(MainActions.updateSummaryFilters(EFilterNames.season, seasonFilter))
            }
            dispatch(MainActions.updateSearchValue(tyreItemSize))
            dispatch(MainActions.loadTyresList(false, false, true, true))
            dispatch(MainActions.changeStep("list", params))
        })
    }

    if (userContext?.system.systemType !== SystemType.Next && !vehicle) {
        return (
            <StyledWrapperBox>
                <StyledStatusBox className="article-list__panel">
                    <Typography>{translateText(100)}</Typography>
                </StyledStatusBox>
            </StyledWrapperBox>
        )
    }

    if (!vehicle) {
        return (
            <StyledWrapperBox>
                <StyledStatusBox className="article-list__panel">
                    <Widget
                        iconName="vehiclesearch"
                        title={translateText(12720)}
                        highlight
                        indicator
                        className="vehicle-widget"
                        onClick={onVehicleRedirect}
                    />
                </StyledStatusBox>
            </StyledWrapperBox>
        )
    }

    if (loading) {
        return (
            <StyledWrapperBox>
                <StyledStatusBox className="article-list__panel">
                    <Loader />
                </StyledStatusBox>
            </StyledWrapperBox>
        )
    }

    if (!frontTyres.length || !rearTyres.length || error) {
        return (
            <StyledWrapperBox>
                <StyledStatusBox className="article-list__panel">
                    <Text modifiers={["block"]}>{translateText(163)}</Text>
                </StyledStatusBox>
            </StyledWrapperBox>
        )
    }

    return (
        <StyledWrapperBox isFiltersOnRightSide={isFiltersOnRightSide} className={className}>
            <DriveRightTyresTable onTyreSelect={handleDriveTyreSelect} items={tyres} />
        </StyledWrapperBox>
    )
}

const StyledWrapperBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isFiltersOnRightSide",
})<{ isFiltersOnRightSide?: boolean }>(({ theme, isFiltersOnRightSide }) => ({
    display: "flex",
    flex: "1",
    marginLeft: isFiltersOnRightSide ? 0 : theme.margin?.m,
    marginRight: isFiltersOnRightSide ? theme.margin?.m : 0,
}))

const StyledStatusBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0",
    padding: "0",
    flex: "1",
})
