import { NestedCSSProperties } from "typestyle/lib/types"
import merge from "lodash/merge"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&--selected, &:active": {
                backgroundColor: "white",
            },
        },
    }
    const mergedStyles = merge(baseStyles, themeStyles)
    return mergedStyles
}
