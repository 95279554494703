import { useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { Box, TextField, Typography, styled } from "@tm/components"
import { CustomArticle } from "@tm/models"
import { ReplaceButton } from "@tm/controls"
import { SpecificationsGenart } from "../../../data/models"
import ReplaceConsumable from "../modal/ReplaceConsumable"
import { useFastServiceStore } from "@bundles/fast-service/data"

type Props = {
    item: SpecificationsGenart
    onConsumableReplace: (consumableId: string, newConsumableArticle: CustomArticle) => void
}

export default function ConsumableArticleComponent({ item, onConsumableReplace }: Props) {
    const { article, label, quantity, genArtNr, id } = item
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const inputsLocked = useFastServiceStore((state) => state.inputsLocked)
    const [open, setOpen] = useState(false)
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const articleDetails = article ? `${article?.supplierArtNr} ${article?.genArtLabel} ${article?.price?.toFixed(2)} ${currencyCode}` : ""

    function replaceConsumable(consumable: CustomArticle) {
        onConsumableReplace(id, consumable)
    }

    return (
        <>
            <Typography variant="h2" gutterBottom>
                {translateText(12730)}
            </Typography>
            <StyledBox>
                <TextField label={label} value={articleDetails} size="medium" disabled={inputsLocked} />
                <TextField label={currencyCode} onChange={() => {}} value={article?.price?.toFixed(2) || 0} size="medium" disabled={inputsLocked} />
                <TextField label={translateText(89)} onChange={() => {}} value={quantity?.toFixed(2) || 0} size="medium" disabled={inputsLocked} />
                <ReplaceButton className="price" disableMargin onClick={() => setOpen(true)} disabled={inputsLocked} />
            </StyledBox>
            {open && (
                <ReplaceConsumable genArtLabel={label} open={open} onClose={setOpen} genArtNr={genArtNr} replaceConsumable={replaceConsumable} />
            )}
        </>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: ".5em",
    borderRadius: "3px",
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxShadow: theme.shadows[1],
    "& .MuiInputBase-input": {
        paddingTop: "1em",
    },
    "& .MuiFormControl-root:first-child": {
        width: "20em",
    },
}))
