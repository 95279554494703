import { useState, useEffect, useCallback, Suspense, useMemo } from "react"
import { Badge, NumberSelect, Button, Icon, Box, Loader, Stack, styled } from "@tm/components"
import { WorkTaskInfo, useTelesalesCustomerNumber, useWorkTask, useAddToBasketModuleParameters } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Article, ArticleInfoType, ErpContainer, ErpInformation, IMicros, LinkedItemsRole, RegisteredModels } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import {
    concat,
    createErpInformationRequestItem,
    showWarehouseDataMissingError,
    useDefaultErpSystem,
    useDefaultOrderWarehouse,
    useExternalCatalogUrl,
} from "@tm/utils"

import { useAddArticleExternalUrl } from "../../helpers"
import { useBasketMemo } from "../../hooks/useBasketMemo"
import { getBundleParams } from "../../utils"
import { createAddWholesalerPartListRequest } from "./helpers/createAddWholesalerPartListRequest"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

type Props = IMicros["basket"]["add-to-basket-wholesaler-part"]

const NumberSelectWrapper = styled(Box)(() => ({
    marginRight: ".25em",
}))

function AddToBasketWholesalerPartComponent(props: Props & { workTaskId: string; workTask?: WorkTaskInfo; addToNextBasket: boolean }) {
    const {
        part,
        disabled,
        generatedWorktaskId,
        erpType,
        generatedArticleId,
        onChangeQuantity,
        workTaskId,
        workTask,
        hideQuantityField,
        buttonText,
        addToNextBasket,
    } = props
    const Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)

    const { translateText, languageId } = useLocalization()
    const externalSystemId = getBundleParams().addToBasketExternalSystemId
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { basket } = useWorkTaskBasketState(workTaskId)

    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const basketMemo = useBasketMemo(workTask)

    const [_quantity, setQuantity] = useState(part.quantityValue > 0 ? part.quantityValue : 1)
    const [articleGetsAddedToBasket, setArticleGetsAddedToBasket] = useState(false)
    const { erpSystemConfig } = useDefaultErpSystem(props.erpSystemConfig)
    const url = useAddArticleExternalUrl(externalCatalogUrl, languageId, _quantity, part.wholesalerArticleNumber, undefined, undefined, undefined)

    const { warehouseData, refetchWarehouseData } = useDefaultOrderWarehouse(
        { telesalesCustomerNo, distributorId: erpSystemConfig?.id },
        enableServiceCalls
    )
    const [erpInfo, setErpInfo] = useState<ErpInformation>()

    const { addWholesalerParts, removeParts } = basket.actions
    const { basketOrderGroups, basketLoading } = basket.state

    useEffect(() => {
        if (disabled) {
            return
        }

        Erp.action(erpType === "details" ? "getErpInfoDetails" : "getErpInfo")({
            item: createErpInformationRequestItem(
                {
                    traderArticleNo: part.wholesalerArticleNumber,
                    supplier: {},
                    productGroup: {},
                    id: generatedArticleId,
                    requestErpInfo: true,
                } as Article,
                workTask?.vehicle?.id
            ),
            distributorId: erpSystemConfig?.id,
            telesalesCustomerNo,
        }).then((response) => {
            if (!erpInfo) {
                setErpInfo(response)

                if (response.quantity?.division && response.quantity.division > 1) {
                    setQuantity(response.quantity?.division)
                }
            }
        })
    }, [telesalesCustomerNo, part.wholesalerArticleNumber, generatedArticleId, disabled, erpType, workTask?.vehicle?.id, erpSystemConfig?.id])

    const handleAddArticleClick = () => {
        if (disabled || !part || (!workTask && !generatedWorktaskId)) {
            return
        }

        if (!warehouseData || warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            refetchWarehouseData()
            return
        }

        if (addToNextBasket !== false) {
            if (url) {
                Morpheus.showView("1", url)
            } else {
                const request = createAddWholesalerPartListRequest(
                    part,
                    _quantity,
                    workTask,
                    basketMemo.position,
                    warehouseData.defaultWarehouse,
                    generatedWorktaskId,
                    erpInfo,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description
                )

                if (request) {
                    setArticleGetsAddedToBasket(true)

                    addWholesalerParts(request).finally(() => {
                        setArticleGetsAddedToBasket(false)
                        props.onAddWholesalerPartToBasketFinished?.(part)
                    })
                }
            }
        }
    }

    const handleChangeQuantity = useCallback(
        (amount: number) => {
            setQuantity(amount)
            onChangeQuantity?.(amount)
        },
        [onChangeQuantity]
    )

    function handleRemovePart(partIds: Array<string>) {
        removeParts(partIds, true)
    }

    const [quantity, ids] = useMemo(() => {
        // basket button for a specific erp system / warehouse. We do not want the default erp system or warehouse here.
        const distributorId = props.erpSystemConfig?.id
        let quantityValue = 0
        const partIds: string[] = []
        basketOrderGroups
            ?.filter((group) => !distributorId || group.orderGroup.distributorId === distributorId)
            .forEach((group) => {
                group.basketParts.forEach((basketPart) => {
                    const { wholesalerArticleNumber, articleInfoType } = basketPart.partItem.articleInformation
                    if (wholesalerArticleNumber === part.wholesalerArticleNumber && articleInfoType === ArticleInfoType.WholesalerArticle) {
                        quantityValue += basketPart.partItem.quantity.value
                        partIds.push(basketPart.partItem.id)
                    }
                })
            })
        return [quantityValue, partIds]
    }, [basketOrderGroups, part.wholesalerArticleNumber, props.erpSystemConfig?.id])

    function renderQuantity() {
        if (hideQuantityField) {
            return null
        }

        return (
            <NumberSelectWrapper>
                <NumberSelect
                    classNameWrapper="amount-field"
                    value={_quantity}
                    steps={erpInfo?.quantity?.division || undefined}
                    onValueChange={handleChangeQuantity}
                />
            </NumberSelectWrapper>
        )
    }

    function renderButton() {
        let badge

        if (articleGetsAddedToBasket) {
            badge = (
                <Badge
                    title={translateText(1299)}
                    badgeContent={<Loader size="extrasmall" />}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: "initial" }}
                />
            )
        } else if (quantity && ids.length) {
            badge = (
                <Badge
                    size="small"
                    title={translateText(1299)}
                    badgeContent={
                        <Stack direction="row">
                            {quantity}
                            <Icon name="close" width="12px" height="12px" sx={{ marginLeft: "2px" }} />
                        </Stack>
                    }
                    onClick={() => !basketLoading && handleRemovePart(ids)}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: "initial" }}
                />
            )
        }

        return (
            <>
                {badge}
                <Box minWidth="6em">
                    <Button
                        title={translateText(937)}
                        className="addToBasketButton"
                        variant={!disabled ? "bordered" : undefined}
                        onClick={handleAddArticleClick}
                        startIcon={<Icon name="cart" />}
                        color={!disabled ? "highlight" : undefined}
                        fullWidth
                        disabled={disabled || part.itemRole === LinkedItemsRole.PawnItems}
                    >
                        {buttonText ? <>{buttonText}</> : ""}
                    </Button>
                </Box>
            </>
        )
    }

    return (
        <div className="tk-basket" title={disabled ? translateText(938) : undefined}>
            <div className={concat(" ", "add-to-basket", disabled && "add-to-basket--disabled")}>
                {renderQuantity()}
                {renderButton()}
            </div>
        </div>
    )
}

export default function Wrapper(props: Props) {
    const { workTaskId, workTask } = useWorkTask() ?? {}
    const { showBasketButton, addToNextBasket } = useAddToBasketModuleParameters()

    const id = workTaskId ?? props.generatedWorktaskId
    // if the MDM config at the BasketModule has no Basketbutton, don't render it
    if (!id || showBasketButton === false) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToBasketWholesalerPartComponent {...props} workTaskId={id} workTask={workTask} addToNextBasket={addToNextBasket} />
        </Suspense>
    )
}
