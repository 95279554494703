import { Image, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article } from "@tm/models"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { FC } from "react"
import { MainState } from "../../../main"
import { ArticleCell } from "../../../_shared"

const selector = createSelector(
    (s: MainState) => ({ sensorListResult: s.wheelsList.base.sensorListResult }),
    (x) => x
)

const RimSensors: FC = () => {
    const { translateText } = useLocalization()
    const {
        sensorListResult: { data },
    } = useSelector(selector)

    const renderItem = (item: Article, idx: number) => {
        return (
            <div key={idx} className="article-list__panel article-list__item">
                <ArticleCell bemModifier="thumbnail">
                    <Image className="no-image" url={item.thumbnail} type="article" />
                </ArticleCell>

                <ArticleCell bemModifier="supplier">
                    <Text className="supplier__name">{item.supplier.name}</Text>
                </ArticleCell>

                <ArticleCell bemModifier="description">
                    <Text className="article__description">{item.description}</Text>
                </ArticleCell>
                <ArticleCell bemModifier="numbers">
                    <Text className="article__description">{item.status?.[0]?.description}</Text>
                </ArticleCell>
            </div>
        )
    }

    if (!data.length) {
        return (
            <div className="article-list__panel article-list__status">
                <Text size="m"> {translateText(163)}</Text>
            </div>
        )
    }

    return (
        <div className="sensors-content article-list">
            <Scrollbar>{data.map(renderItem)}</Scrollbar>
        </div>
    )
}

export default RimSensors
