import { MainState } from "../../components/main"

export const vehicleSelector = (s: MainState) => s.manager.vehicle

export const selectedFiltersSelector = (s: MainState) => s.rdksList.selectedFilters
export const filtersSelector = (s: MainState) => s.rdksList.filters
export const displayStateResetRDKSSelector = (s: MainState) => s.rdksList.displayStateReset

export const selectedTyresFiltersSelector = (s: MainState) => s.tyresList.selectedFilters
export const tyresFiltersSelector = (s: MainState) => s.tyresList.filters

export const registrationNosSelector = (s: MainState) => s.vehicleSelection
