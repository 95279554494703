import { useHidePriceTypes, useTelesalesCustomerNumber } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { useExternalCatalogUrl } from "@tm/utils"
import { useMemo } from "react"
import { ListOptions } from "@tm/models"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { usePartsViewOptions } from "../../hooks/usePartsViewOptions"

/** @todo Merge somehow with `ArticleListConfiguration` */
export function useListOptions(): ListOptions {
    const { addToBasketExternalSystemId } = Morpheus.getParams("basket")
    const { isPriceTypeHidden } = useHidePriceTypes()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })

    const {
        showDataSupplierLogos,
        hasWatchList,
        hideCostEstimationButton,
        hideAddToBasketButton,
        highlightWatchlistButton,
        hasNarrowRightActionsContainer,
        erpDataCustomization,
    } = useArticleListConfiguration()

    const {
        showPurchasePrice,
        partsViewSettings: { showArticleImages, compactView },
    } = usePartsViewOptions()

    return useMemo(
        () => ({
            showDocumentsInline: false,
            showDocumentsInCompact: false,
            openDocumentsAsModal: true,
            showReferenceLinksInCompact: false,
            showDataSupplierLogos,
            externalCatalogUrl,
            hasWatchList,
            hideCostEstimationButton,
            hideAddToBasketButton,
            highlightWatchlistButton,
            showPurchasePrice,
            showArticleImages,
            isPriceTypeHidden,
            hasNarrowRightActionsContainer,
            compactView,
            erpDataCustomization,
        }),
        [
            showDataSupplierLogos,
            externalCatalogUrl,
            hasWatchList,
            hideCostEstimationButton,
            hideAddToBasketButton,
            highlightWatchlistButton,
            showPurchasePrice,
            showArticleImages,
            isPriceTypeHidden,
            hasNarrowRightActionsContainer,
            compactView,
            erpDataCustomization,
        ]
    )
}
