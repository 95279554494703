import { Loader, styled } from "@tm/components"
import { PanelSection } from "@tm/controls"
import { Suspense, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SummaryProps } from "../component"
import { VehicleDetails } from "./VehicleDetails"
import { SuggestionsV2 } from "./SuggestionsV2"
import { SummaryHeaderV2 } from "./SummaryHeaderV2"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { filterTyres, mapTyres, vehicleRedirect } from "../business"
import { SearchForTyres } from "./SearchForTyres"

const StyledPanelSection = styled(PanelSection)({
    margin: "1em 18em 1em 0",
    "& .panel__content": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
})

export function SummaryV2(props: SummaryProps) {
    const dispatch = useDispatch()
    const driveRightTyres = useSelector((state: MainState) => state.summary.driveRightTyres)
    const vehicle = useSelector((state: MainState) => state.manager.vehicle)
    const summaryWithVehicle = useSelector((state: MainState) => state.summary.summaryWithVehicle)
    const { frontTyres, rearTyres, error, loading } = driveRightTyres
    const [sameSizeTyres, mixSizeTyres] = filterTyres(mapTyres(frontTyres, rearTyres))

    useEffect(() => {
        dispatch(MainActions.setSummaryWithVehicle(!!vehicle))
    }, [vehicle])

    const handleCheck = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch(MainActions.setSummaryWithVehicle(checked))
    }

    const handleVehicleRedirect = useCallback(() => {
        vehicleRedirect(props)
    }, [props])

    return (
        <>
            <Suspense fallback={<Loader minWidth="18em" />}>
                <VehicleDetails handleVehicleRedirect={handleVehicleRedirect} />
            </Suspense>
            <StyledPanelSection>
                <SummaryHeaderV2 checked={summaryWithVehicle} handleChange={handleCheck} />
                {summaryWithVehicle ? (
                    <SuggestionsV2
                        handleVehicleRedirect={handleVehicleRedirect}
                        sameSizeTyres={sameSizeTyres}
                        mixSizeTyres={mixSizeTyres}
                        loading={loading}
                        error={!frontTyres.length || !rearTyres.length || error}
                    />
                ) : (
                    <SearchForTyres />
                )}
            </StyledPanelSection>
        </>
    )
}
