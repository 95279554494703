import { AddRepairTimeListRequest, FittingGroupRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import { useAddToBasketModuleParameters } from "@tm/context-distribution"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"
import { useHandlePostSendOrderAction } from "../../../../hooks/useHandlePostSendOrderAction"
import { useCreateBasketRepairTimesPostMessage } from "../../../../hooks/useCreateRepairTimesPostMessage"

export function useAddRepairTimeList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()
    const { getMessageForRepairTimes } = useCreateBasketRepairTimesPostMessage()
    const { handlePostSendOrderAction } = useHandlePostSendOrderAction()
    const { sendRepairTimesPostMessage } = useAddToBasketModuleParameters()

    const { mutateAsync: addRepairTimeList } = useMutation((request: AddRepairTimeListRequest) => Data.addRepairTimeList(request), {
        onSuccess: async (response, request) => {
            if (response) {
                await enableCostEstimation(request.workTaskId)
                handleBasketUpdateWorkflow(request.workTaskId, response)

                // Save work task in the DB
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()

                const container = Container.getInstance(RegisteredModels.Basket_HasRepairTimes)
                const containerPG = Container.getInstance(RegisteredModels.Basket_HasRepairTimesForProductGroup)

                request.repairTimes
                    .filter((time) => time.providerWorkId)
                    .forEach((time) => {
                        container
                            .subscribe({
                                workTaskId: request.workTaskId,
                                repairTimesProvider: request.provider,
                                repairTimeProviderWorkId: time.providerWorkId,
                            })
                            .loadIfRequired()

                        time.productGroupIds?.forEach((productGroupId) => {
                            const fittingGroupRequest: FittingGroupRequest = {
                                fittingSide: time.fittingSide,
                                productGroupId,
                            }

                            containerPG
                                .subscribe({
                                    workTaskId: request.workTaskId,
                                    request: fittingGroupRequest,
                                })
                                .loadIfRequired()
                        })
                    })
            }
        },
    })

    const addRepairTimes = useCallback(
        (request: AddRepairTimeListRequest) => {
            if (sendRepairTimesPostMessage) {
                const message = getMessageForRepairTimes(request)
                handlePostSendOrderAction(message)
            }

            return addRepairTimeList(request)
        },
        [addRepairTimeList, getMessageForRepairTimes, handlePostSendOrderAction, sendRepairTimesPostMessage]
    )

    return addRepairTimes
}
