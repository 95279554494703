import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ErpPrice } from "@tm/models"

type Props = {
    hidePurchasePrice?: boolean
    purchasePrice?: ErpPrice
    retailPrice?: ErpPrice
}

export default function EditedPartErpPrices({ hidePurchasePrice, purchasePrice, retailPrice }: Props) {
    const { translateText, currency } = useLocalization()

    return (
        <Stack>
            {!hidePurchasePrice && (
                <Typography variant="body2">
                    {`${translateText(55)}: ${purchasePrice ? currency(purchasePrice.value, purchasePrice.currencySymbol ?? purchasePrice.currencyCode_Iso_4217) : "-"}`}
                </Typography>
            )}
            <Typography variant="body2">
                {`${translateText(1620)}: ${retailPrice ? currency(retailPrice.value, retailPrice.currencySymbol ?? retailPrice.currencyCode_Iso_4217) : "-"}`}
            </Typography>
        </Stack>
    )
}
