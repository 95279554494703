import { useUser, useWorkTask } from "@tm/context-distribution"
import { channel, getCurrentWorkTaskId, RegistrationNoType, Vehicle } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { decodeUniqueId } from "@tm/utils"
import { useSelector } from "react-redux"
import { FC, useEffect } from "react"
import { BundleActions, BundleActionTypes } from "../../business"
import { WheelSelectionSteps } from "../../data/enums"
import { isVehicleChanged } from "../../data/helpers"
import { HistoryData } from "../../data/models"
import { MainState } from "../main"

const ManagerComponent: FC<{ matchParamsWorkTask: string }> = ({ matchParamsWorkTask }) => {
    const user = useUser()
    const vehicle = useSelector((x: MainState) => x.manager.vehicle)
    const workTaskVehicle = useWorkTask()?.workTask?.vehicle
    const actions = useActions(BundleActions, "loadData", "resetStore", "setVehicle", "changeQuantity", "loadData")

    const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(matchParamsWorkTask ?? "") ?? "FAKE_WORKTASK"

    useEffect(() => {
        if (workTaskVehicle && vehicle && workTaskVehicle?.id !== vehicle?.id) {
            actions.resetStore()
        }
    }, [workTaskVehicle, vehicle])

    useEffect(() => {
        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    actions.setVehicle(newVeh)
                }
            },
            true
        )
        return () => {
            unsubVehicle?.()
        }
    }, [user?.userContext?.system.systemType])

    useEffect(() => {
        const unsub = channel("WORKTASK").subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", ({ quantity, article }) =>
            actions.changeQuantity(article!, quantity)
        )
        const unsubModuleClosed = channel("WORKTASK", getCurrentWorkTaskId()).subscribe("MODULE/CLOSED", (module) => {
            module.includes("wheels") && actions.resetStore()
        })
        return () => {
            unsub()
            unsubModuleClosed()
        }
    }, [])

    return null
}

export default ManagerComponent

export type ManagerState = {
    vehicle?: Vehicle
    registrationNos?: string[]
    selectedRegistrationNo?: string
    keyValueData?: HistoryData
    keyValueDataLoaded?: HistoryData
    keyValueDataLoading: boolean
    savedWorktaskId?: string
    isHostettler?: boolean
    linearProgress?: {
        currentStep: number
        totalSteps: number
    }
    historyInit: boolean
}

export const DEFAULT_STATE: ManagerState = {
    keyValueDataLoading: false,
    historyInit: false,
}

export const reduce = (state = DEFAULT_STATE, action: BundleActionTypes): ManagerState => {
    switch (action.type) {
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "KEY_VALUES_LOADING": {
            return {
                ...state,
                keyValueDataLoading: true,
                savedWorktaskId: action.payload,
            }
        }
        case "KEY_VALUES_LOADING_STEPS": {
            return {
                ...state,
                linearProgress: action.payload,
            }
        }
        case "KEY_VALUES_SAVING": {
            return {
                ...state,
                keyValueData: action.payload,
            }
        }
        case "KEY_VALUES_FAILED": {
            return {
                ...state,
                keyValueDataLoaded: {
                    ...state.keyValueData,
                    activeStep: WheelSelectionSteps.NONE,
                },
                keyValueDataLoading: false,
                historyInit: true,
            }
        }
        case "INIT_KEY_VALUES": {
            return {
                ...state,
                keyValueDataLoading: false,
            }
        }
        default: {
            return state
        }
    }
}
