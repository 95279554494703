import { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { ArticleErpInfo, UserModuleType } from "@tm/models"
import { getParameterFromUserContextModule, useUser } from "@tm/context-distribution"
import { TyresListData, useTyresList } from "./useTyresList"
import { ArticlesContext, useArticlesContext } from "../../../ListV2/ContextProvider/Articles/ArticlesContext"
import { useAfterPaintEffect } from "../../../ListV2/helpers"

export type TyresListProviderProps = PropsWithChildren<{
    setHasRendered: Dispatch<SetStateAction<boolean>>
    erpInfos: ArticleErpInfo[]
}>

export function TyresListProvider({ children, setHasRendered, erpInfos }: TyresListProviderProps) {
    const { userContext } = useUser()
    const defaultQuantity = getParameterFromUserContextModule(userContext, UserModuleType.TMTires, "DefaultAmount", "number")
    const tyresListData = useTyresList(erpInfos, defaultQuantity)

    useAfterPaintEffect(() => {
        if (tyresListData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [tyresListData.requestStatus])

    return <ArticlesContext.Provider value={tyresListData}>{children}</ArticlesContext.Provider>
}

export function useTyresListContext<TContext extends TyresListData>(): TContext
export function useTyresListContext<TContext extends TyresListData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useTyresListContext<TContext extends TyresListData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useArticlesContext(selector as never)
}
