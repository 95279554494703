import {
    OePart,
    RepairTimeProvider,
    OE,
    ImportRepairEstimationRequest,
    CustomPart,
    RepairEstimationMainWork,
    RepairEstimationIncludedWork,
    RequestArticleListPayload,
    EReplaceButtonBundle,
    AddCatalogPartListRequest,
    TmaEModule,
} from "@tm/models"
import { mapArticleToAddCatalogPartListRequest, mapOePartToOeOePart } from "@tm/utils"
import { WorkTask } from "@tm/context-distribution"
import { getBundleParams } from "../../../utils"
import { GetCalculationDataRequest } from "../../../data/repositories/getCalculationData/model"
import { MainState } from "../../main"
import { ICalculationItem, IncludedWork } from "../../../data/models"

export const createEurotaxGetCalculationDataRequest = (historyId: number, state: MainState, sendEreSession?: boolean): GetCalculationDataRequest => {
    const { vehicle } = state.manager
    return {
        contReturnUrl: getBundleParams().redirectURL,
        ...(sendEreSession && {
            ereSession: state.extern.externalSessionId,
        }),
        historyId,
        vehicleManufacturerId: vehicle?.tecDocManufacturerId,
        vehicleManufacturerName: vehicle?.manufacturer,
    }
}

function mapCustomPart(oePart: OePart): CustomPart {
    return {
        articleNumber: oePart.oeArticleNumber,
        description: oePart.description,
        quantityValue: oePart.quantityValue,
        garagePrice: oePart.oePriceValue ?? undefined,
    }
}

function mapIncludedWork(includedWork: IncludedWork): RepairEstimationIncludedWork {
    return {
        categoryOfWork: includedWork.categoryOfWork,
        description: includedWork.description,
        providerWorkId: includedWork.providerWorkId,
        time: includedWork.time,
        sortNo: includedWork.sortNo,
    }
}

export const createCostEstimationRequest = (
    state: MainState,
    repairTimeDivision: number,
    fullWorkTask: WorkTask,
    memo?: string,
): ImportRepairEstimationRequest | undefined => {
    const { workTask, workTaskId } = fullWorkTask
    if (!workTaskId) {
        return
    }

    const { items, works } = state.calculation
    const selectedItems = items?.filter((x) => x.isSelected)
    const selectedWorks = works?.filter((x) => x.isSelected)

    const parts: AddCatalogPartListRequest[] = []
    const oeParts: OePart[] = []
    const customParts: CustomPart[] = []
    const mainWorks: RepairEstimationMainWork[] = []

    selectedItems?.forEach((item) => {
        if (item.selectedPart) {
            parts.push(
                mapArticleToAddCatalogPartListRequest(
                    [item.selectedPart],
                    workTaskId!,
                    workTask!.vehicle?.id,
                    workTask!.customer?.id,
                    undefined,
                    memo,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    TmaEModule.REPESTIMATE_EUROTAX,
                ),
            )
        } else if (!item.oeArticle.oeArticleNumber || !item.oeArticle.oePriceValue) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeParts.push({
                ...item.oeArticle,
                memo,
                oePriceValue: item.oeArticle.oePriceValue || undefined,
                oeArticleOrigin: {
                    module: OE.OeArticleModule.RepairEstimation,
                    provider: OE.OeArticleProvider.Eurotax,
                },
            })
        }
    })

    selectedWorks?.forEach((work) => {
        mainWorks.push({
            providerWorkId: work.providerWorkId,
            hourlyRate: work.hourlyRate,
            categoryOfWork: work.categoryOfWork,
            description: work.description,
            time: work.time,
            sortNo: work.sortNo,
            includedWorks: work.includedWorks?.map(mapIncludedWork),
        })
    })

    const allCatalogParts = parts.flatMap((part) => part.catalogParts)

    return {
        workTaskId: workTaskId!,
        customParts,
        vehicleId: state.manager.vehicle?.id ?? "",
        oeParts,
        works: mainWorks,
        parts: allCatalogParts,
        repairTimesProvider: RepairTimeProvider.Eurotax,
        repairTimeDivision,
    }
}

const getReplacements = (replacements?: string[]) => {
    const result: OE.OeNumber[] = []

    if (replacements?.length) {
        replacements.forEach((x) => result.push({ number: x }))
    }

    return result
}

export const createRequestArticleListPayload = (item: ICalculationItem): RequestArticleListPayload => {
    return {
        oePositions: [
            {
                number: item.oeArticle.oeArticleNumber,
                description: item.oeArticle.description,
                additionalDescription: item.oeArticle.additionalDescription,
                isSelected: item.isSelected,
                replacements: getReplacements(item.oeArticle.replacements),
                ...(!!item.selectedPart && {
                    parts: [mapOePartToOeOePart(item.oeArticle)],
                }),
            },
        ],
        replaceButtonMicro: EReplaceButtonBundle.DMGEurotax,
        forceReload: true,
        inModal: true,
        extendedAssortment: true,
        disableExtendedAssortment: true,
    }
}
