import { margin } from "csx"
import { ReactNode, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Loader, PanelSection } from "@tm/controls"
import { classes } from "@tm/utils"
import { bem } from "../../data/helpers"

type Props = {
    title?: string
    className?: string
    children?: ReactNode
    loading?: boolean
}

export default function EurotaxPanel({ children, title, className, loading }: Props) {
    const style = useMemo(() => getStyle(), [])

    return (
        <PanelSection className={classes(className, bem(style.panel, loading && "loading"))} title={title} size="xs">
            {loading ? <Loader /> : children}
        </PanelSection>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        panel: {
            paddingTop: 0,
            display: "flex",
            flexDirection: "column",
            margin: margin(theme.margin.s, 0),
            $nest: {
                ".panel__content": {
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    marginTop: theme.margin.m,
                },
                "&--loading .panel__content": {
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                },
            },
        },
    })(EurotaxPanel)
}
