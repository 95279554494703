import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetCatMemoVKNRequest, GetCatMemoVKNResponse } from "@tm/models"
import { getServiceUrl } from "../params"

export async function getKTypeMemoInfo(body: GetCatMemoVKNRequest): Promise<GetCatMemoVKNResponse | undefined> {
    const url = `${getServiceUrl()}/GetCatMemoVKN`
    const authorization = getStoredAuthorization()

    return ajax<GetCatMemoVKNResponse>({ url, body, authorization, method: "GET" }).then((response) => response)
}
