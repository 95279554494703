import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, ButtonKeyDefinition, RouteComponentProps } from "@tm/utils"
import { Widget, InputGroup } from "@tm/controls"
import { CisVoucherType } from "@tm/models"
import { useShowCisOptions } from "@tm/context-distribution"
import { Box, TextField, Typography, Icon, LinkButton, Loader } from "@tm/components"
import { ChangeEvent, useMemo, useState, KeyboardEvent } from "react"
import PvOrdersTable from "../_shared/tables/PvOrdersTable"
import { useAllOrdersRecent } from "../../data/hooks/useAllOrdersRecent"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"

type Props = RouteComponentProps<RouteProps> & {
    subpageRoute: string
    titleTextId: string
}

type RouteProps = {
    subpage?: string
    voucherTypeId?: string
    id?: string
}

function WidgetPvOrdersComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const [searchText, setSearchText] = useState<string>()
    const { cisOptions } = useShowCisOptions()

    const allOrdersVoucherType = useMemo(() => {
        return cisOptions?.voucherTypes?.find((voucherType) => voucherType.typeId === CisVoucherType.AllOrders)
    }, [cisOptions])

    const { allOrdersLoading, allOrders } = useAllOrdersRecent()

    const { subpageRoute, titleTextId, history, match } = props

    function handleSearchTextChange({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) {
        if (value && value.length >= 40) {
            return
        }
        setSearchText(value)
    }

    function handleSearchKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter && searchText) {
            let url = renderRoute(subpageRoute, { ...match.params, subpage: "vouchers", voucherTypeId: CisVoucherType.AllOrders })
            url += `?query=${encodeURIComponent(searchText)}`
            history.push(url)
        }
    }

    function renderOrdersTable(url: string) {
        return (
            <Box margin-right="0.2em" width="100%" alignContent="center">
                <InputGroup className="orders-widget-search-wrapper">
                    <TextField
                        className="orders-widget-search-input"
                        value={searchText}
                        onKeyDown={handleSearchKeyDown}
                        onChange={handleSearchTextChange}
                        placeholder={translateText(1859)}
                        size="large"
                        fullWidth
                        sx={{ ".MuiFilledInput-root": { paddingRight: 0 }, marginRight: "0.8em" }}
                        InputProps={{
                            endAdornment: (
                                <LinkButton
                                    className="orders-widget-search-button"
                                    to={url + (searchText ? `?query=${encodeURIComponent(searchText)}` : "")}
                                    sx={{ minWidth: "15px" }}
                                >
                                    <Icon name="search" height={21} width={21} />
                                </LinkButton>
                            ),
                        }}
                    />
                </InputGroup>
                <Box height="32em" display="flex" alignContent="center" className="orders-table-wrapper">
                    {/* height: legacy code, thats a no go */}
                    {allOrdersLoading && <Loader />}
                    {allOrders && <PvOrdersTable variant="small" orders={allOrders} singleSearch />}
                </Box>
            </Box>
        )
    }
    function renderOrders() {
        if (allOrdersVoucherType) {
            const url = renderRoute(subpageRoute, { ...match.params, subpage: "vouchers", voucherTypeId: allOrdersVoucherType.typeId })

            return (
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flex={1}>
                        {renderOrdersTable(url)}
                    </Box>
                    <Box display="flex" justifyContent="space-between" sx={{ transform: "translateY(.5rem)" }} className="orders-widget-table-footer">
                        <Typography variant="label">{`*${translate(694)}`}</Typography>
                        <LinkButton to={url} disabled={allOrdersLoading}>
                            {translate(43)}
                        </LinkButton>
                    </Box>
                </Box>
            )
        }

        return <Typography>COMING SOON</Typography>
    }

    return (
        <Widget
            size="4x4"
            iconName="voucher"
            title={translate(titleTextId)}
            header={<WidgetHeaderTitleComponent iconName="voucher" titleTextId={titleTextId} />}
            active
        >
            {cisOptions && renderOrders()}
        </Widget>
    )
}

export default withRouter(WidgetPvOrdersComponent)
