import { DRTyre, Tyre } from "../../../../data/models"

export function mapTyres(frontTyres: DRTyre[], rearTyres: DRTyre[]): Tyre[] {
    const tyres = frontTyres.map((frontTyre: DRTyre, index: number) => {
        return {
            loadIndexFront: frontTyre.loadIndex,
            rimSizeFront: frontTyre.rimSize,
            speedIndexFront: frontTyre.speedindex,
            sizeFront: frontTyre.size,
            rimOffsetFront: frontTyre.offsetValue,
            seasonSummerFront: frontTyre.isSummer,
            seasonWinterFront: frontTyre.isWinter,

            loadIndexRear: rearTyres[index].loadIndex,
            rimSizeRear: rearTyres[index].rimSize,
            speedIndexRear: rearTyres[index].speedindex,
            sizeRear: rearTyres[index].size,
            rimOffsetRear: rearTyres[index].offsetValue,
            seasonSummerRear: rearTyres[index].isSummer,
            seasonWinterRear: rearTyres[index].isWinter,
        }
    })
    return tyres
}
