import { useCallback } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { useLocalization } from "@tm/localization"
import { Button, Divider, Loader, ScrollContainer, SimpleCard, Stack, styled, Typography, Image, Icon, Box } from "@tm/components"
import { RimDetailsArticle, SizeInfo } from "@bundles/wheels/data/model"
import { getBundleParams } from "@bundles/wheels/utils"
import { MainState } from "../../main"
import { Actions } from "../business"
import RimArticle from "./rimArticle"

const StyledSimpleCard = styled(SimpleCard)(({ theme }) => ({
    marginBottom: theme.margin?.m,
    ".error-image": {
        height: "unset",
    },
}))

const StyledMainImage = styled(Image)({
    width: "15em",
    height: "15em",
    margin: "1em 1em 0 1em",
})

const StyledLabel = styled(Typography)({
    textTransform: "uppercase",
})

const StyledButton = styled(Button)({
    "&.MuiButton-sizeLarge": { padding: ".4em" },
    maxWidth: "fit-content",
})

type WheelsDetailsComponentProps = {
    repairTimesRoute: string
}

export function RimDetailsHeaderV2({ repairTimesRoute }: WheelsDetailsComponentProps) {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const isLKQ = getBundleParams()?.version === "LKQ"

    const { rimItem, rimInfoTireSizesResponse, isConti, selectedSize, loading, data, error, selectedRimDetailsArticle, registrationNos } =
        useSelector((s: MainState) => ({
            rimItem: s.wheelsDetails.rimItem,
            selectedSize: s.wheelsDetails.selectedSize,
            rimInfoTireSizesResponse: s.wheelsDetails.rimInfoTireSizesResponse,
            isConti: s.wheelsDetails.rimItem?.isConti,
            loading: s.wheelsDetails.rimDetailsArticlesResponse.loading,
            data: s.wheelsDetails.rimDetailsArticlesResponse.data,
            error: s.wheelsDetails.rimDetailsArticlesResponse.error,
            selectedRimDetailsArticle: s.wheelsDetails.selectedRimDetailsArticle,
            registrationNos: s.vehicleSelection,
        }))

    const renderRimSize = (item: SizeInfo, idx: number) => {
        const handleRimSizeSelect = () => {
            dispatch(Actions.selectRimDetailsSize(item.size))
        }

        return (
            <Button
                size="large"
                key={idx}
                color={selectedSize === item.size ? "highlight" : "secondary"}
                sx={{ marginRight: ".2em" }}
                onClick={handleRimSizeSelect}
            >
                {item.size}
            </Button>
        )
    }

    const handleCertificateUrl = useCallback(() => {
        const certificateUrl = rimInfoTireSizesResponse?.data?.[0]?.pathToCertificate
        certificateUrl && window.open(certificateUrl, "_blank")
    }, [rimInfoTireSizesResponse?.data])

    const renderCertificateReport = useCallback(() => {
        return (
            <StyledButton
                disabled={!!rimInfoTireSizesResponse.loading || !rimInfoTireSizesResponse?.data?.[0]?.pathToCertificate}
                variant="outlined"
                startIcon={<Icon name="file-pdf" />}
                size="large"
                onClick={handleCertificateUrl}
            >
                {translateText(1022)}
            </StyledButton>
        )
    }, [handleCertificateUrl, rimInfoTireSizesResponse?.data, rimInfoTireSizesResponse.loading])

    const handleSelectItem = useCallback((rim: RimDetailsArticle) => {
        batch(() => {
            dispatch(Actions.selectRimDetailsArticle(rim))
            dispatch(Actions.loadRimDetailsAndRestrictions())
            dispatch(Actions.selectTireSize(undefined))
        })
    }, [])

    const handleRimQuantityChange = useCallback(
        (article: RimDetailsArticle, value: number) => {
            dispatch(Actions.changeRimDetailsArticleQuantity(article, value))
        },
        [rimItem?.idRimDesign]
    )

    const renderAxleCombinationArticles = () => {
        if (loading) {
            return (
                <div className="rim-details__items">
                    <div className="article-list__panel article-list__status">
                        <Loader />
                    </div>
                </div>
            )
        }

        if (!Object.keys(data).length || error) {
            return (
                <div className="rim-details__items">
                    <div className="article-list__panel article-list__status">
                        <Typography>{translateText(323)}</Typography>
                    </div>
                </div>
            )
        }

        const renderAxleCombinationArticle = ([group, articles]: [string, RimDetailsArticle[]], idx: number) => {
            const uniqueRimSizes = articles.map((x) => x.rimSize).distinct()
            return (
                <div className="article-list" key={idx}>
                    <Stack direction="row" gap={0.5} mb={0.5}>
                        {uniqueRimSizes.map((x) => (
                            <Button size="large" key={group + x} color="secondary" className="axle-group">
                                {x}
                            </Button>
                        ))}
                    </Stack>
                    {articles.map((x, artIDx) => (
                        <RimArticle
                            key={artIDx + x.applicationId || x.eanCode}
                            item={x}
                            repairTimesRoute={repairTimesRoute}
                            onSelectItem={handleSelectItem}
                            onRimQuantityChange={handleRimQuantityChange}
                            largeCheckbox={isLKQ}
                            isSelected={
                                selectedRimDetailsArticle?.artNr === x.artNr &&
                                selectedRimDetailsArticle?.appType === x.appType &&
                                selectedRimDetailsArticle?.idRim === x.idRim &&
                                selectedRimDetailsArticle?.applicationId === x.applicationId
                            }
                        />
                    ))}
                </div>
            )
        }

        return <ScrollContainer sx={{ maxHeight: "22.5em" }}>{Object.entries(data).map(renderAxleCombinationArticle)}</ScrollContainer>
    }

    return (
        <StyledSimpleCard sxContent={{ padding: "0 1em" }} sx={{ padding: "0 0.75em" }}>
            <Stack direction="row" gap={3}>
                <Stack direction="row" gap={2} divider={<Divider orientation="vertical" sx={{ maxHeight: "21em" }} />}>
                    <Stack direction="column">
                        {!(registrationNos && isConti) && renderCertificateReport()}
                        {rimItem?.perspectivePicture && <StyledMainImage src={rimItem?.perspectivePicture} />}
                        {rimItem?.isWinterproofed && isLKQ && <Icon name="snow" size="2em" sx={{ alignSelf: "end" }} color="black" />}
                    </Stack>
                    <Stack gap={2} sx={{ margin: "1em" }}>
                        <Stack>
                            <StyledLabel variant="body2">{translateText(13467)}</StyledLabel>
                            <StyledLabel variant="h2">{rimItem?.modelCode}</StyledLabel>
                        </Stack>
                        <Stack>
                            <StyledLabel variant="body2">{translateText(1032)}</StyledLabel>
                            <StyledLabel variant="h2">{rimItem?.suplierName}</StyledLabel>
                        </Stack>
                        <Stack>
                            <StyledLabel variant="body2">{translateText(944)}</StyledLabel>
                            <Typography variant="h2">{rimItem?.color}</Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack direction="column" gap={1} sx={{ width: "100%" }}>
                    <Stack direction="row">{rimItem?.sizeInfo.map(renderRimSize)}</Stack>
                    {renderAxleCombinationArticles()}
                </Stack>
            </Stack>
        </StyledSimpleCard>
    )
}
