import { Box, Button, Divider, Stack, styled, Typography, useMediaQuery, useTheme, TypographyProps } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { EFilterNames, ETyreCountType, TyreFilter } from "@tm/models"
import { Statics } from "@bundles/tyres/data/statics"
import { batch, useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo } from "react"
import { CombinedTyresPosition, SpecialCarTypes } from "@bundles/tyres/data/enums"
import { margin } from "csx"
import { TmaHelper } from "@tm/utils"
import { getIconsForCombinedTyresSearch, getTyreCountFilters } from "@bundles/tyres/data/helpers"
import { useGetStandardTyreSizes } from "@bundles/tyres/hooks/useGetStandardTyreSizes"
import { CarTypeSeasonFilters } from "../../_shared/carTypeSeasonFilters"
import { StandardTyreSizes } from "../../_shared/StandardTyreSizes"
import { MainState } from "../../main"
import { createSizeQuery, ISummaryFilters } from "../business"
import { MainActions } from "../../main/business"
import { DiameterFilters } from "../../_shared/DiameterFilters"
import { CustomDropdown } from "../../_shared/customDropdown"

const StyledLabel = styled((props: TypographyProps) => <Typography variant="body3" {...props} />)(({ theme }) => ({
    textTransform: "uppercase",
    marginRight: "1em",
    color: theme.palette.text.secondary,
    textAlign: "end",
    minWidth: "8em",
}))

const StyledSeasonLabel = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    marginRight: "1em",
    color: theme.palette.text.secondary,
}))

const StyledDiameterFilters = styled(DiameterFilters, { shouldForwardProp: (prop) => prop !== "multiTireSizes" })<{ multiTireSizes?: boolean }>(
    ({ multiTireSizes }) => ({
        flex: 1,
        visibility: multiTireSizes === undefined || multiTireSizes === true ? "visible" : "hidden",
        maxWidth: "fit-content",
    })
)

const StyledDropdown = styled(CustomDropdown)(({ theme }) => ({
    flex: "1",
    margin: margin(0, theme.margin?.s || 0),
    "& .btn": {
        padding: theme.margin?.m,
        lineHeight: "1.3",
    },
    "&--small": {
        width: "5.5em",
    },
}))

const StyledBox = styled(Box)({
    display: "grid",
    gridTemplateColumns: "1fr 8fr",
    alignItems: "center",
})

const StyledFilters = styled(CarTypeSeasonFilters, { shouldForwardProp: (prop) => prop !== "isBigScreen" })<{ isBigScreen?: boolean }>(
    ({ isBigScreen }) => ({
        ...(isBigScreen && {
            ".MuiButton-sizeLarge": {
                height: "3em",
                width: "3em",
            },
        }),
    })
)

export function SearchForTyres() {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const { selectedFilters, filters } = useSelector((s: MainState) => s.summary)
    const displayTyreCountFilter = false // getTyreCountFilters(selectedFilters.carType.first()?.query).length > 0
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))
    const { standardTyreSizes } = useGetStandardTyreSizes()

    const vehicleStandardTyreSizes = useMemo(() => {
        return standardTyreSizes?.filter((sizes) => sizes.carType.toString() === selectedFilters.carType?.[0].query)
    }, [selectedFilters.carType, standardTyreSizes])

    const handleFiltersChange = (path: ISummaryFilters, filter: TyreFilter | undefined) => {
        batch(() => {
            dispatch(MainActions.resetSummaryFilter(path, true))
            if (filter) {
                dispatch(MainActions.updateSummaryFilters(path, filter))
            }
            dispatch(MainActions.loadSummaryFilters())
        })
    }

    useEffect(() => {
        if (filters.height?.length === 1 && selectedFilters.width && !selectedFilters.height) {
            handleFiltersChange(EFilterNames.height, filters.height.first())
        } else if (filters.inch?.length === 1 && selectedFilters.height && !selectedFilters.inch) {
            handleFiltersChange(EFilterNames.inch, filters.inch.first())
        }
    }, [filters.height, filters.inch])

    const handleNormalSearchBtn = () => {
        const { carType, season } = selectedFilters

        const size = createSizeQuery(selectedFilters)

        const params = { size, season: season?.map((s) => s.value), carType: carType?.map((x) => x.query) }

        TmaHelper.UniParts.Search.TyresSelection(size)

        batch(() => {
            dispatch(MainActions.updateSearchValue(size))
            dispatch(MainActions.loadTyresList(false, false, true))
            dispatch(MainActions.changeStep("list", params))
            dispatch(MainActions.toggleTyresAccordion(false))
        })
    }

    const handleStandardTyreSizesSearch = (size: string) => {
        const params = { size, season: undefined, carType: undefined }

        TmaHelper.UniParts.Search.TyresSelection(size)

        batch(() => {
            dispatch(MainActions.updateSearchValue(size))
            dispatch(MainActions.loadTyresList(true))
            dispatch(MainActions.changeStep("list", params))
            dispatch(MainActions.toggleTyresAccordion(false))
        })
    }

    const renderTyreCountAndSeasonFilters = () => {
        if (displayTyreCountFilter) {
            return (
                <StyledBox>
                    <StyledLabel>{translateText(13917)}</StyledLabel>
                    <Stack direction="row" alignContent="center" gap={2}>
                        <Box maxWidth="8em">
                            <StyledFilters
                                filterName={EFilterNames.tyreCount}
                                disabled={filters.loading}
                                items={getTyreCountFilters(selectedFilters.carType.first()?.query)}
                                selectedItemValue={selectedFilters?.tyreCount}
                                onChange={handleFiltersChange}
                                isBigScreen={isBigScreen}
                                buttonSize="xl"
                            />
                        </Box>
                        <Stack direction="row" alignItems="center">
                            <StyledSeasonLabel>{translateText(1235)}</StyledSeasonLabel>
                            <StyledFilters
                                filterName={EFilterNames.season}
                                disabled={filters.loading || selectedFilters.carType.every((x) => SpecialCarTypes.includes(+x.value))}
                                items={Statics.seasons}
                                selectedItemValue={selectedFilters?.season}
                                onChange={handleFiltersChange}
                                isBigScreen={isBigScreen}
                                buttonSize="xl"
                            />
                        </Stack>
                    </Stack>
                </StyledBox>
            )
        }

        return (
            <StyledBox>
                <StyledLabel>{translateText(1235)}</StyledLabel>
                <StyledFilters
                    filterName={EFilterNames.season}
                    disabled={filters.loading || selectedFilters.carType.every((x) => SpecialCarTypes.includes(+x.value))}
                    items={Statics.seasons}
                    selectedItemValue={selectedFilters?.season}
                    onChange={handleFiltersChange}
                    isBigScreen={isBigScreen}
                    buttonSize="xl"
                />
            </StyledBox>
        )
    }

    return (
        <Stack direction="column" gap={isBigScreen ? 3 : 2} divider={<Divider orientation="horizontal" flexItem />} margin="0 8em">
            {!!vehicleStandardTyreSizes?.length && (
                <StyledBox>
                    <StyledLabel>{translateText(13650)}</StyledLabel>
                    <StandardTyreSizes tyreSizes={vehicleStandardTyreSizes} onSizeClick={handleStandardTyreSizesSearch} />
                </StyledBox>
            )}
            <Stack gap={isBigScreen ? 2 : 1}>
                <StyledBox>
                    <StyledLabel>{translateText(12838)}</StyledLabel>
                    <StyledFilters
                        filterName={EFilterNames.carType}
                        disabled={filters.loading}
                        items={Statics.getCarTypes()}
                        selectedItemValue={selectedFilters?.carType}
                        onChange={handleFiltersChange}
                        isBigScreen={isBigScreen}
                        buttonSize="xl"
                    />
                </StyledBox>
                {renderTyreCountAndSeasonFilters()}
                <Stack direction="column">
                    <StyledBox>
                        <StyledLabel>{translateText(712)}</StyledLabel>
                        <StyledDiameterFilters
                            widths={filters.width}
                            heights={filters.height}
                            inches={filters.inch}
                            selectedHeight={selectedFilters.height}
                            selectedInch={selectedFilters.inch}
                            selectedWidth={selectedFilters.width}
                            onChange={handleFiltersChange}
                            loading={filters.loading}
                            maxItemsToShow={3}
                            layout={["iconRight"]}
                            customCoverView
                            iconName={getIconsForCombinedTyresSearch(selectedFilters.tyreCount?.query, CombinedTyresPosition.frontTyres)}
                        />
                    </StyledBox>
                    {displayTyreCountFilter && (
                        <StyledBox>
                            <StyledLabel />
                            <StyledDiameterFilters
                                widths={filters.width}
                                heights={filters.height}
                                inches={filters.inch}
                                selectedHeight={selectedFilters.height}
                                selectedInch={selectedFilters.inch}
                                selectedWidth={selectedFilters.width}
                                onChange={handleFiltersChange}
                                loading={filters.loading}
                                maxItemsToShow={3}
                                layout={["iconRight"]}
                                customCoverView
                                multiTireSizes={selectedFilters.tyreCount?.query !== ETyreCountType.singleTire}
                                disabled
                                iconName={getIconsForCombinedTyresSearch(selectedFilters.tyreCount?.query, CombinedTyresPosition.rearTyres)}
                            />
                        </StyledBox>
                    )}
                </Stack>
                <StyledBox>
                    <StyledLabel />
                    <Stack direction="row">
                        <StyledDropdown
                            filterName={EFilterNames.loadIndex}
                            submitOnTab
                            defaultItem={translateText(12403)}
                            items={filters.loadIndex}
                            onChange={handleFiltersChange}
                            selectedValue={selectedFilters.loadIndex}
                            disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
                            isActive={false}
                            maxItemsToShow={3}
                            customCoverView
                            layout={["iconRight"]}
                        />
                        <StyledDropdown
                            filterName={EFilterNames.speedIndex}
                            submitOnTab
                            defaultItem={translateText(719)}
                            items={filters.speedIndex}
                            onChange={handleFiltersChange}
                            selectedValue={selectedFilters.speedIndex}
                            disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
                            isActive={false}
                            maxItemsToShow={3}
                            customCoverView
                            layout={["iconRight"]}
                        />
                        <StyledDropdown
                            filterName={EFilterNames.oeIdentifier}
                            submitOnTab
                            defaultItem={translateText(720)}
                            items={filters.oeIdentifier}
                            onChange={handleFiltersChange}
                            selectedValue={selectedFilters.oeIdentifier}
                            disableAutoSelect
                            disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
                            isActive={false}
                            maxItemsToShow={3}
                            customCoverView
                            layout={["iconRight"]}
                        />
                    </Stack>
                </StyledBox>
                <StyledBox>
                    <StyledLabel />
                    <Button
                        size="extralarge"
                        color="primary"
                        sx={{ alignSelf: "start", width: "fit-content", height: "3em" }}
                        onClick={handleNormalSearchBtn}
                        disabled={!selectedFilters.height && !selectedFilters.inch && !selectedFilters.width}
                    >
                        {translateText(135)}
                    </Button>
                </StyledBox>
            </Stack>
        </Stack>
    )
}
