import { List, Stack, ListItemPaper, styled, Typography } from "@tm/components"
import { Fixture } from "@bundles/wheels/data/repositories/wheels-loadRimFixtures/model"
import { useSelector } from "react-redux"
import { MainState } from "@bundles/wheels/components/main"
import { useLocalization } from "@tm/localization"
import { fixtureItems } from "../../business/helper"
import { ValueItemsDefinition } from "../../business"

const StyledList = styled(List)({
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "50%",
})

export function Accessories() {
    const { translateText } = useLocalization()
    const { selectedRimDetailsArticle } = useSelector((s: MainState) => ({
        selectedRimDetailsArticle: s.wheelsDetails.selectedRimDetailsArticle,
    }))

    const renderItem = (item: ValueItemsDefinition<keyof Fixture>) => {
        return (
            <ListItemPaper sx={{ display: "flex" }}>
                <Typography sx={{ flexBasis: "50%" }}>
                    {typeof item.description === "number" ? translateText(item.description) : item.description}
                </Typography>
                {(selectedRimDetailsArticle?.fixture && <Typography>{selectedRimDetailsArticle?.fixture[item?.value] || "-"}</Typography>) || "-"}
            </ListItemPaper>
        )
    }

    return (
        <Stack direction="row">
            <StyledList>{fixtureItems.map(renderItem)}</StyledList>
        </Stack>
    )
}
