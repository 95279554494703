import { useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { useMicro } from "@tm/morpheus"
import { FittingPosition, AttributeFilterModel, ProductGroupFiltersModel, IMicros, ECounterType } from "@tm/models"
import { Box, Icon, Typography, styled, buttonClasses, Button } from "@tm/components"
import { TmaHelper, classes } from "@tm/utils"
import XorSwitch from "../../../_shared/Switch"
import { hasVehicleRecordDatVinOnly, showDefaultAttributeSelect } from "../../../../helper"
import { useComponentBehavior } from "./useComponentBehavior"
import ArticleGroupFilters from "./ArticleGroupFilters"

const FiltersArea = styled(Box, { shouldForwardProp: (propName) => propName !== "showXorSwitch" })<{ showXorSwitch: boolean }>(({
    theme,
    showXorSwitch,
}) => {
    const groupHeader = theme.overwrites?.components?.partlist?.groupHeader
    const backgroundColor = groupHeader?.monochrom ? theme.palette.fallback.highlightLight : theme.palette.highlight.light

    return {
        display: "flex",
        alignItems: showXorSwitch ? "flex-end" : "center",
        alignSelf: "stretch",
        marginBottom: theme.spacing(),
        padding: theme.spacing(),
        border: `1px solid ${groupHeader?.backgroundColor || backgroundColor}`,
        borderWidth: "0px 1px 1px 1px",
        backgroundColor: theme.palette.background.default,
    }
})

const AreaLabel = styled(Typography)({
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
})

const XorSwitchBox = styled(Box)(({ theme }) => ({
    fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
    height: 38,
    display: "flex",
    alignItems: "center",
    ".xor--sub": {
        opacity: 0.53,
    },
}))

const DatVinRequestWrapper = styled(Box)({
    marginLeft: "auto",
    ".dat-details": {
        padding: "0 0 0 1em",
    },
    [`.${buttonClasses.sizeMedium}`]: {
        height: 38,
    },
})

export type ArticleGroupFilterAreaProps = {
    filterOptions?: ProductGroupFiltersModel
    usedAttributeFilters?: string[]
    prefilledAttributeFilters?: string[]
    modelId: number
    onSelectProductGroupFilter?: (option: AttributeFilterModel, previousProductGroupFilter?: AttributeFilterModel) => void
    onDeselectProductGroupFilter?: (option: AttributeFilterModel) => void
    onDeselectAllProductGroupFilter?: (options: Array<AttributeFilterModel>) => void
}

export default function ArticleGroupFilterArea(props: ArticleGroupFilterAreaProps): JSX.Element {
    const { renderMicro } = useMicro<IMicros>()
    const { translate, translateText } = useLocalization()
    const { filterOptions, usedAttributeFilters, prefilledAttributeFilters } = props

    const {
        selectedFittingSide,
        handleSetFrontAxle,
        handleSetRearAxle,
        handleFittingSideChange,
        handleDeselectFilter,
        handleDeselectAllFilters,
        handleSelectionClick,
    } = useComponentBehavior(props)

    const showXorSwitch = !!filterOptions && showDefaultAttributeSelect(filterOptions.attributeFilters)
    const xorState = selectedFittingSide & FittingPosition.FrontAxle ? "left" : "right"

    useEffect(() => {
        if (!usedAttributeFilters?.length) {
            handleDeselectAllFilters()
        }
    }, [usedAttributeFilters, handleDeselectAllFilters])

    const handleDeselectAllFiltersEvent = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListDeleteCriteriaFilter)
        handleDeselectAllFilters()
    }

    return (
        <FiltersArea className="ArticleGroupFilterArea" showXorSwitch={showXorSwitch}>
            <Box marginRight={2}>
                <AreaLabel variant="label">
                    <Icon name="vehicle-file" sx={{ marginRight: 0.5 }} /> {translate(13289)}
                </AreaLabel>
                {showXorSwitch && (
                    <XorSwitchBox>
                        <Box onClick={handleSetFrontAxle} className={classes(!(selectedFittingSide & FittingPosition.FrontAxle) && "xor--sub")}>
                            {translateText(353)}
                        </Box>
                        <XorSwitch status={xorState} onChange={handleFittingSideChange} />
                        <Box onClick={handleSetRearAxle} className={classes(!(selectedFittingSide & FittingPosition.RearAxle) && "xor--sub")}>
                            {translate(354)}
                        </Box>
                    </XorSwitchBox>
                )}
            </Box>

            <Box display="flex">
                <ArticleGroupFilters
                    {...props}
                    handleDeselectFilter={handleDeselectFilter}
                    handleSelectionClick={handleSelectionClick}
                    selectedFittingSide={showXorSwitch ? selectedFittingSide : FittingPosition.None}
                />

                <Button
                    startIcon={<Icon name="remove-filter" />}
                    variant="outlined"
                    onClick={handleDeselectAllFiltersEvent}
                    title={translateText(1664)}
                />
            </Box>

            {hasVehicleRecordDatVinOnly() && (
                <DatVinRequestWrapper>{renderMicro("vehicle", "dat-vin-request", { className: "dat-details" })}</DatVinRequestWrapper>
            )}
        </FiltersArea>
    )
}
