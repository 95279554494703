import { PropsWithChildren } from "react"
import { createContext, useContextSelector } from "use-context-selector"
import { useDirectSearchListParamsContext } from ".."
import { ensureContextAvailability } from "../../../helpers"
import { OeReplacementData } from "../../../models"
import { useOeReplacement } from "./useOeReplacement"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"

export const OeReplacementContext = createContext<OeReplacementData | undefined>(undefined)

export function OeReplacementProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const params = useDirectSearchListParamsContext()
    const {
        enableOEReplacements,
        oeInformation: { showReplacementChain },
    } = useArticleListConfiguration()

    // Only load data when it's enabled in the UI Config and when the chain is not being shown in the OE Information component
    const oeReplacement = useOeReplacement(
        params,
        props.isEnabled && !!enableOEReplacements && !showReplacementChain && !params.startParams.forcePartsAlternatives
    )

    return <OeReplacementContext.Provider value={oeReplacement}>{props.children}</OeReplacementContext.Provider>
}

export function useOeReplacementContext<TContext extends OeReplacementData>(): TContext
export function useOeReplacementContext<TContext extends OeReplacementData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useOeReplacementContext<TContext extends OeReplacementData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(OeReplacementContext, (context) => {
        const validatedContext = ensureContextAvailability("OeReplacementContext", context)
        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
