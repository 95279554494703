import { em, important, rem } from "csx"
import { ReactElement, useEffect, useMemo, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { getStyleTheme, useStyle, useUser } from "@tm/context-distribution"
import { AmountField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, EFilterNames, IMicros, LogEntry, RegisteredModels, RepairTimeProvider, RequestArticleDetailsPayload, Vehicle } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import {
    Filter,
    RouteComponentProps,
    bem,
    encodeUniqueId,
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    renderRoute,
    uniqueId,
    withRouter,
} from "@tm/utils"
import { MainState } from "../main"
import { Button, Icon } from "@tm/components"
import { Actions } from "../rdks-list/business"

type Props = RouteComponentProps & {
    item: Article
    repairTimesRoute: string
    vehicleId?: string
    tecDocTypeId?: number
    vehicle?: Vehicle
    onArticleDetailsClick: (item: Article, request: RequestArticleDetailsPayload) => void
    onQuantityChange: (item: Article, value: number) => void
    onSelect: (item: Article) => void
    onAddToBasket?: (item: Article) => void
    isSelected?: boolean
    articleAlternativesRoute: string
    isDisabled?: boolean
}

function SensorArticle({
    articleAlternativesRoute,
    onAddToBasket,
    onQuantityChange,
    vehicleId,
    onArticleDetailsClick,
    onSelect,
    isSelected,
    item,
    match,
    repairTimesRoute,
    isDisabled,
}: Props): ReactElement {
    const { translateText } = useLocalization()
    const user = useUser()
    const { renderMicro } = useMicro<IMicros>()

    const style = useMemo(() => getStyle(), [])

    const showArticleImages = user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages
    const isCompact = user?.userSettings?.articleListSettings?.viewOptions?.compactView

    const [opened, setOpened] = useState(isCompact)

    const dispatch = useDispatch()
    const { filters, selectedFilters } = useSelector((s: MainState) => ({ filters: s.rdksList.filters, selectedFilters: s.rdksList.selectedFilters }))

    const { repairTimeProviders } = getRepairTimeProviders()

    useEffect(() => {
        if (opened != isCompact) {
            setOpened(isCompact)
        }
    }, [isCompact])

    const getRepairTimesUrl = (article: any, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
        if (article.productGroup && repairTimeProviders.length && user?.userSettings) {
            let provider
            if (Array.isArray(rtProviders)) {
                const activeRTProvider = user?.userSettings.activeVehicleDataProviders.repairTimes

                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider) {
                return
            }

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    workTaskId: encodeUniqueId(uniqueId()),
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide,
                })
            )
        }
    }

    const handleToggleOpened = () => {
        setOpened((prevState) => !prevState)
    }

    const renderActions = (_: Article, showButtonText?: boolean) => {
        return (
            <div className="tk-basket">
                <div className="add-to-basket">
                    <AmountField value={item.quantity} onChange={(x) => onQuantityChange(item, x.value)} />
                    <Button
                        sx={{ minWidth: showButtonText ? "6.25em" : "" }}
                        className="addToBasketButton"
                        variant="bordered"
                        color="highlight"
                        startIcon={<Icon name="cart" />}
                        onClick={() => onAddToBasket?.(item)}
                    >
                        {showButtonText ? translateText(13110) : ""}
                    </Button>
                </div>
            </div>
        )
    }

    const handleRequestArticleAlternatives = (article: Article) => {
        const { supplierArticleNo, productGroup } = article
        if (!articleAlternativesRoute) {
            return
        }

        const queryParams = new URLSearchParams()
        queryParams.set("query", supplierArticleNo)
        queryParams.set("productGroupIds", productGroup.id.toString())

        const url = `${renderRoute(articleAlternativesRoute, { ...match.params })}?${queryParams.toString()}`

        Morpheus.showView("1", url)

        try {
            const logEntry: LogEntry = {
                type: "Alternative article search started",
                searchType: 0,
                article: {
                    internalId: article.internalId,
                    supplierArticleNo: article.supplierArticleNo,
                    traderArticleNo: article.traderArticleNo,
                    matchingArticleNo: article.matchingArticleNo,
                    supplier: {
                        id: article.supplier.id,
                        name: article.supplier.name,
                    },
                    productGroup: {
                        id: article.productGroup.id,
                        name: article.productGroup.name,
                    },
                },
            }

            Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", logEntry)
        } catch {}
    }

    const providers = useSelector((s: MainState) => s.rdksList.repairTimeAvailabilities?.[item.productGroup.id])

    const manufacturerIndex = filters.manufacturer.findIndex((x) => x.name === item.supplier.name)

    const handleManufacturerClick = (value: Filter) => {
        batch(() => {
            dispatch(Actions.updateFilter(EFilterNames.manufacturer, value))
            dispatch(Actions.loadSensorsList())
        })
    }

    return (
        <>
            {renderMicro("parts", "part-item", {
                className: bem(style.articleItem, Morpheus.getParams("parts")?.templates?.articleItem?.bundle),
                part: item,
                vehicleId,
                isCompact: opened,
                onToggleCollapse: handleToggleOpened,
                rtProviders: providers,
                showArticleImage: showArticleImages,
                onRequestArticleDetails: (request) => onArticleDetailsClick(item, request),
                onRequestArticleAlternatives: handleRequestArticleAlternatives,
                onArticleSelect: () => onSelect(item),
                isSelected,
                renderBuyActions: renderActions,
                getRepairTimesUrl,
                canFilterArticleAttributes: true,
                showActions: true,
                isDisabled,
                isDataSupplierFilterActive: !!selectedFilters?.manufacturer?.length,
                onDataSupplierClick: () => handleManufacturerClick(filters.manufacturer[manufacturerIndex]),
            })}
        </>
    )
}

export default withRouter(SensorArticle)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        articleItem: {
            $nest: {
                "&--wm": {
                    $nest: {
                        ".add-to-compilation": {
                            marginRight: important(0),
                        },
                        ".add-to-cost-estimate": {
                            marginLeft: theme.margin.m,
                        },
                        ".basket-button": {
                            width: "auto",
                            minWidth: em(13),
                            marginLeft: "auto",
                        },
                    },
                },
                ".article__supplier-logo": {
                    maxHeight: rem(2),
                    maxWidth: rem(5),
                },
            },
        },
    })(SensorArticle)
}
