import { MainState } from "@bundles/wheels/components/main"
import { Icon, List, Typography, ListItemPaper, styled, Stack, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useSelector } from "react-redux"
import { RimDetailsArticle } from "@bundles/wheels/data/model"
import { PanelSection } from "@tm/controls"
import { ValueItemsDefinition } from "../../business"
import { technicalDataFields } from "../../business/helper"
import { TireSizes } from ".."

const StyledList = styled(List)({
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "50%",
})

const StyledPanelSection = styled(PanelSection)({
    width: "calc(100% - 60em) !important",
    height: "18em",
    flexBasis: "50%",
})

export function TechnicalData() {
    const { translateText } = useLocalization()
    const { selectedRimDetailsArticle, loading, isTyresAvailable } = useSelector((s: MainState) => ({
        selectedRimDetailsArticle: s.wheelsDetails.selectedRimDetailsArticle,
        loading: s.wheelsDetails.rimDetailsArticlesResponse.loading,
        isTyresAvailable: s.navigation.isTyresAvailable,
    }))

    const renderItem = (item: ValueItemsDefinition<keyof RimDetailsArticle>) => {
        return (
            <ListItemPaper sx={{ display: "flex" }}>
                <Typography sx={{ flexBasis: "50%" }}>{translateText(item.description)}</Typography>
                {item.id !== 8 && ((selectedRimDetailsArticle && <Typography>{selectedRimDetailsArticle[item.value]?.toString()}</Typography>) || "")}
                {item.id === 8 && <Icon name={selectedRimDetailsArticle?.[item.value] ? "snowflake" : "close"} />}
            </ListItemPaper>
        )
    }

    const firstColumn = technicalDataFields.filter((item) => item.id <= 4)
    const secondColumn = technicalDataFields.filter((item) => item.id > 4)

    if (loading) {
        return <Loader />
    }

    return (
        <Stack direction="row" gap={3}>
            <Stack direction="column" gap={1} sx={{ flexBasis: "50%" }}>
                <Typography variant="h2">{translateText(13940)}</Typography>
                <Stack direction="row" gap={1}>
                    <StyledList>{firstColumn.map(renderItem)}</StyledList>
                    <StyledList>{secondColumn.map(renderItem)}</StyledList>
                </Stack>
            </Stack>
            <StyledPanelSection className="rim-information">
                <Typography variant="h2">{translateText(13941)}</Typography>
                {isTyresAvailable && <Typography variant="body1">{translateText(13942)}</Typography>}
                <TireSizes largeCheckbox colorCheckIcon tireSizeSelectionEnabled={isTyresAvailable} />
            </StyledPanelSection>
        </Stack>
    )
}
