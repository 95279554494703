import { Box, Stack, Typography } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { useState } from "react"
import { SortSelection } from "@bundles/parts"

type Props = {
    defaultSortingMode?: ArticleListSortingMode
    availableSortingModes: Partial<Record<ArticleListSortingMode, string | number>>
}

export function ArticleListSettings(props: Props) {
    const { defaultSortingMode, availableSortingModes } = props
    const { translateText } = useLocalization()
    const { setUserSetting } = useUser()

    const [value, setValue] = useState<ArticleListSortingMode | undefined>(defaultSortingMode)

    function handleChange(changedValue: ArticleListSortingMode) {
        setValue(changedValue)
        setUserSetting("ARTICLE_LIST_DEFAULT_SORTING", changedValue)
    }

    return (
        <Box>
            <Typography variant="body2">{translateText(13575)}</Typography>
            <Stack mt={0.5} spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                <SortSelection sortingModes={availableSortingModes} value={value ?? "default"} onChange={handleChange} displayDefaultText />
            </Stack>
        </Box>
    )
}
