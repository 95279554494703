import { useLocalization } from "@tm/localization"
import { useMemo, useRef, useState } from "react"
import { Box, Icon, Button, styled } from "@tm/components"
import EnlargeImage from "../../modal/enlargeImage"
import { ERIKImage } from "../../ERIKImage"

type Props = {
    image: string
    removeImage(id: string): void
    updateImage(id: string, image: string): void
    imageId: string
    disabled?: boolean
}

export default function ShowPicture({ image, removeImage, updateImage, imageId, disabled }: Props) {
    const { translateText } = useLocalization()
    const imageRef = useRef<HTMLImageElement>(null)
    const [show, setShow] = useState(false)

    const rotateImage = () => {
        if (imageRef.current) {
            const c = document.createElement("canvas")
            c.width = imageRef.current.naturalHeight
            c.height = imageRef.current.naturalWidth

            const context = c.getContext("2d")
            if (context) {
                const x = c.width / 2
                const y = c.height / 2
                const width = imageRef.current.naturalWidth
                const height = imageRef.current.naturalHeight

                const angleInDegrees = 90
                const angleInRadians = (angleInDegrees * Math.PI) / 180

                context.translate(x, y)
                context.rotate(angleInRadians)
                context.drawImage(imageRef.current, -width / 2, -height / 2, width, height)
                const data = c.toDataURL("image/jpg")
                updateImage(imageId, data)
            }
        }
    }

    const handleResetImage = () => {
        removeImage(imageId)
    }

    const showEnlargedImage = () => {
        setShow((prev) => !prev)
    }

    const renderImage = useMemo(
        () => <ERIKImage flex="1" width="unset" height="unset" imageId={imageId} imageUrl={image} imageRef={imageRef} />,
        [imageId, image, imageRef]
    )

    return (
        <Box flex="1" display="flex" gap="0.5em" flexDirection="column">
            <Box display="flex" gap="0.5em">
                <Button startIcon={<Icon name="rotate" />} onClick={() => rotateImage()} disabled={disabled}>
                    {translateText(12687)}
                </Button>
                <Button startIcon={<Icon name="delete" />} onClick={() => handleResetImage()} disabled={disabled}>
                    {translateText(69)}
                </Button>
            </Box>

            <Box display="flex" flex="1" position="relative" onClick={showEnlargedImage}>
                {renderImage}
                <PreviewWrapper>
                    <Icon name="search" sx={{ fill: "#fff" }} />
                </PreviewWrapper>
            </Box>
            <EnlargeImage open={show} onHide={setShow} content={renderImage} />
        </Box>
    )
}

const PreviewWrapper = styled(Box)({
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    backgroundColor: "rgba(0,0,0,0.6)",
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
    height: "100%",
    width: "100%",
    "&:hover": {
        opacity: 1,
    },
})
