import { ArticleListSettings, withChangeUserSetting, WithChangeUserSettingProps } from "@tm/context-distribution"
import { Switch, Toolbar } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { bindSpecialReactMethods, TmaHelper } from "@tm/utils"
import { Component } from "react"

import { Box, Button, Icon, styled, Tooltip } from "@tm/components"
import { ECounterType } from "@tm/models"
import { getBundleParams } from "../../utils"
import { Actions, IActions, OptionsState } from "./business"

type Props = LocalizationProps &
    WithChangeUserSettingProps & {
        state: OptionsState
        actions: IActions
        defaultStatus?: {
            showCriteriaFilters?: boolean
            filterCompact?: boolean
        }
        hiddenOptions?: {
            showCriteriaFilters?: boolean
            showFilterCompact?: boolean
        }
    }

type State = {
    isTooltipOpen: boolean
}

class ListOptionsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isTooltipOpen: false,
        }
        bindSpecialReactMethods(this)
    }

    UNSAFE_componentWillMount() {
        this.initialize(this.props)
        this.initializeFiltersCompact(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            (!this.props.userSettings && nextProps.userSettings) ||
            (this.props.userSettings &&
                !this.props.userSettings.articleListSettings &&
                nextProps.userSettings &&
                nextProps.userSettings.articleListSettings) ||
            (this.props.userSettings?.articleListSettings?.viewOptions &&
                nextProps.userSettings?.articleListSettings?.viewOptions &&
                this.props.userSettings.articleListSettings.viewOptions.compactView !=
                    nextProps.userSettings.articleListSettings.viewOptions.compactView)
        ) {
            this.initialize(nextProps)
        }
        if (
            (!this.props.userSettings && nextProps.userSettings) ||
            (this.props.userSettings &&
                !this.props.userSettings.articleListSettings &&
                nextProps.userSettings &&
                nextProps.userSettings.articleListSettings) ||
            (this.props.userSettings?.articleListSettings?.viewOptions &&
                nextProps.userSettings?.articleListSettings?.viewOptions &&
                this.props.userSettings.articleListSettings.viewOptions.filtersCompact !=
                    nextProps.userSettings.articleListSettings.viewOptions.filtersCompact)
        ) {
            this.initializeFiltersCompact(nextProps)
        }
    }

    initializeFiltersCompact(props: Props) {
        if (getBundleParams().filtersAlwaysOpen) {
            this.props.actions.toggleOpenedFilters(false)
        } else {
            this.props.actions.toggleOpenedFilters(
                props.userSettings?.articleListSettings?.viewOptions?.filtersCompact ?? props.defaultStatus?.filterCompact ?? false
            )
        }
    }

    initialize(props: Props) {
        const { userSettings, setUserSetting, actions, state, defaultStatus } = props

        if (userSettings) {
            if (userSettings.articleListSettings?.viewOptions) {
                if (userSettings.articleListSettings?.viewOptions?.showArticleImages !== undefined) {
                    actions.setArticleListShowArticleImages(userSettings.articleListSettings.viewOptions.showArticleImages)
                }
            } else {
                setUserSetting("ARTICLE_LIST_SETTINGS", {
                    ...userSettings.articleListSettings,
                    viewOptions: {
                        showArticleImages: state.showArticleImages,
                    },
                })
            }
        }
    }

    handleChangeShowArticleImages(showArticleImages: boolean) {
        const { actions, setUserSetting, userSettings } = this.props
        actions.setArticleListShowArticleImages(showArticleImages)
        this.handleSelectTmaEvent(showArticleImages, ECounterType.ArticleListShowImages, ECounterType.ArticleListHideImages)

        if (userSettings?.articleListSettings) {
            setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...userSettings.articleListSettings,
                viewOptions: {
                    ...userSettings.articleListSettings.viewOptions,
                    showArticleImages,
                },
            })
        }
    }

    handleChangeShowRecordedCriterias(showVehicleRecordsFilters: boolean) {
        const { userSettings } = this.props

        if (userSettings?.articleListSettings) {
            this.props.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...userSettings.articleListSettings,
                viewOptions: {
                    ...userSettings.articleListSettings.viewOptions,
                    showVehicleRecordsFilters,
                },
            })
        }
    }

    handleSelectTmaEvent = (value: boolean, on: ECounterType, off: ECounterType) => {
        if (value) {
            TmaHelper.GeneralCountEvent.Call(on)
        } else {
            TmaHelper.GeneralCountEvent.Call(off)
        }
    }

    handleChangeToggleFiltersCompact(filtersCompact: boolean) {
        const { userSettings } = this.props

        if (userSettings?.articleListSettings) {
            this.props.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...userSettings.articleListSettings,
                viewOptions: {
                    ...userSettings.articleListSettings.viewOptions,
                    filtersCompact,
                },
            })
        }
    }

    renderTooltipContent() {
        const {
            state,
            userSettings,
            hiddenOptions,
            localization: { translateText },
        } = this.props

        return (
            <OptionsBox>
                <Tooltip className="tk-parts list-options__tooltip" title={translateText(1805)} placement="right-start">
                    <div className="list-options__switch-wrapper">
                        <Switch
                            status={state.showArticleImages}
                            onChange={this.handleChangeShowArticleImages}
                            label={translateText(795)}
                            alignLabel="left"
                        />
                    </div>
                </Tooltip>
                {hiddenOptions?.showCriteriaFilters !== true && getBundleParams().vehicleRecordsEnabled && (
                    <Tooltip className="tk-parts list-options__tooltip" title={translateText(1807)} placement="right-start">
                        <div className="list-options__switch-wrapper">
                            <Switch
                                status={userSettings?.articleListSettings?.viewOptions?.showVehicleRecordsFilters ?? true}
                                onChange={this.handleChangeShowRecordedCriterias}
                                label={translateText(1186)}
                                alignLabel="left"
                            />
                        </div>
                    </Tooltip>
                )}
                {hiddenOptions?.showFilterCompact !== true && (
                    <Tooltip className="tk-parts list-options__tooltip" title={translateText(12778)} placement="right-start">
                        <div className="list-options__switch-wrapper">
                            <Switch
                                status={
                                    userSettings?.articleListSettings?.viewOptions?.filtersCompact ?? this.props.defaultStatus?.filterCompact ?? false
                                }
                                onChange={this.handleChangeToggleFiltersCompact}
                                label={translateText(12779)}
                                alignLabel="left"
                            />
                        </div>
                    </Tooltip>
                )}
            </OptionsBox>
        )
    }

    hideTooltip = () => {
        this.setState({ isTooltipOpen: false })
    }

    toggleTooltip = () => {
        this.setState((prev) => ({ isTooltipOpen: !prev.isTooltipOpen }))
    }

    render() {
        const { translateText } = this.props.localization

        return (
            <Toolbar className="tk-parts" title={translateText(361)}>
                <div className="list-options">
                    <Tooltip
                        open={this.state.isTooltipOpen}
                        title={this.renderTooltipContent()}
                        variant="light"
                        color="primary"
                        onClickAway={this.hideTooltip}
                        disableFocusListener
                        disableHoverListener
                    >
                        <Button
                            onClick={() => {
                                this.toggleTooltip()
                                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListViewOptions)
                            }}
                            variant="outlined"
                            startIcon={<Icon name="settings" />}
                        />
                    </Tooltip>
                </div>
            </Toolbar>
        )
    }
}

export default connectComponent(Actions, withLocalization(withChangeUserSetting(ListOptionsComponent)))

const OptionsBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 3,
})
