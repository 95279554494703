import { Color } from "../model"

export const sortColors = (rawColors: any): Color[] => {
    const clusters = [
        { name: "black", leadColor: [0, 0, 0], colors: [] as Color[] },
        { name: "dark", leadColor: [66, 68, 69], colors: [] as Color[] },
        { name: "platinumgrey", leadColor: [136, 136, 137], colors: [] as Color[] },
        { name: "darkblue", leadColor: [13, 51, 133], colors: [] as Color[] },
        { name: "blue", leadColor: [0, 0, 255], colors: [] as Color[] },
        { name: "cyan", leadColor: [0, 255, 255], colors: [] as Color[] },
        { name: "azure", leadColor: [0, 127, 255], colors: [] as Color[] },
        { name: "lightblue", leadColor: [229, 243, 255], colors: [] as Color[] },
        { name: "violet", leadColor: [127, 0, 255], colors: [] as Color[] },
        { name: "darkgreen", leadColor: [89, 120, 120], colors: [] as Color[] },
        { name: "green", leadColor: [0, 255, 0], colors: [] as Color[] },
        { name: "spring green", leadColor: [0, 255, 128], colors: [] as Color[] },
        { name: "chartreuse", leadColor: [128, 255, 0], colors: [] as Color[] },
        { name: "brown", leadColor: [165, 42, 42], colors: [] as Color[] },
        { name: "violet", leadColor: [128, 0, 128], colors: [] as Color[] },
        { name: "magenta", leadColor: [139, 0, 139], colors: [] as Color[] },
        { name: "rose", leadColor: [255, 0, 128], colors: [] as Color[] },
        { name: "red", leadColor: [255, 0, 0], colors: [] as Color[] },
        { name: "orange", leadColor: [255, 140, 0], colors: [] as Color[] },
        { name: "yellow", leadColor: [255, 255, 0], colors: [] as Color[] },
        { name: "grey", leadColor: [235, 235, 235], colors: [] as Color[] },
        { name: "white", leadColor: [255, 255, 255], colors: [] as Color[] },
    ]

    const colorDistance = (color1: any, color2: number[]) => {
        const x = (color1[0] - color2[0]) ** 2 + (color1[1] - color2[1]) ** 2 + (color1[2] - color2[2]) ** 2
        return Math.sqrt(x)
    }

    const rgbToHSL = (rgb: any) => {
        let r = rgb[0]
        let g = rgb[1]
        let b = rgb[2]

        ;(r /= 255), (g /= 255), (b /= 255)
        const max = Math.max(r, g, b)
        const min = Math.min(r, g, b)
        let h: any
        let s: any
        let l: any = (max + min) / 2

        if (max == min) {
            h = s = 0
        } else {
            const d = max - min
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0)
                    break
                case g:
                    h = (b - r) / d + 2
                    break
                case b:
                    h = (r - g) / d + 4
                    break
            }

            h /= 6
        }

        s *= 100
        s = Math.round(s)
        l *= 100
        l = Math.round(l)
        h = Math.round(360 * h)

        return { h, s, l }
    }

    const oneDimensionSorting = (colors: any, dim: any) => {
        return colors.sort((colorA: any, colorB: any) => {
            if (colorA.hsl[dim] < colorB.hsl[dim]) {
                return -1
            }
            if (colorA.hsl[dim] > colorB.hsl[dim]) {
                return 1
            }
            return 0
        })
    }

    const sortWithClusters = (colorsToSort: Color[]): Color[] => {
        const mappedColors = colorsToSort.map((color) => {
            const hex = color.code
            const r = parseInt(hex.substring(0, 2), 16)
            const g = parseInt(hex.substring(2, 4), 16)
            const b = parseInt(hex.substring(4, 6), 16)

            color.rgb = [r, g, b]

            return color
        })

        mappedColors.forEach((color) => {
            let minDistance: any
            let minDistanceClusterIndex: any
            clusters.forEach((cluster, clusterIndex) => {
                const distance = colorDistance(color?.rgb, cluster.leadColor)
                if (typeof minDistance === "undefined" || minDistance > distance) {
                    minDistance = distance
                    minDistanceClusterIndex = clusterIndex
                }
            })
            clusters[minDistanceClusterIndex].colors.push({ code: color.code, name: color.name, hsl: rgbToHSL(color?.rgb) })
        })

        const colors: Color[] = []
        clusters.forEach((cluster) => {
            const dim = ["white", "grey", "black"].includes(cluster.name) ? "l" : "s"

            if (cluster?.colors && cluster?.colors.length > 0) {
                cluster.colors = oneDimensionSorting(cluster.colors, dim)
                cluster?.colors.forEach((color: any) => {
                    colors.push(color)
                })
            }
        })
        return colors
    }

    return sortWithClusters(rawColors)
}
