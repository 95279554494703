import { useStyle } from "@tm/context-distribution"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { em, margin, padding, percent, px, rem } from "csx"
import { FC, PropsWithChildren } from "react"

type Props = PropsWithChildren<{
    title: string
    ok: boolean
    description?: string
}>

export const MaintenanceItem: FC<Props> = ({ title, ok, description, children }) => {
    const { translateText } = useLocalization()

    return (
        <div className={style.maintenanceItem}>
            <div className={style.before} />
            <div className={style.title}>{title}</div>
            <div className={style.status}>
                {ok ? (
                    <>
                        <Text modifiers={["highlight", "strong"]}>{translateText(316)}</Text>
                        <Icon name="check-filled" size="xl" skin="success" />
                    </>
                ) : (
                    <>
                        <Text modifiers={["highlight", "strong"]}>{translateText(12498)}</Text>
                        <Icon name="error-filled" size="xl" skin="danger" />
                    </>
                )}
            </div>
            {children && <div className={style.description}>{description}</div>}
            {children && <div className={style.value}>{children}</div>}
        </div>
    )
}

const style = useStyle({
    maintenanceItem: {
        display: "flex",
        flexDirection: "column",
        flexBasis: percent(20),
        position: "relative",
        margin: margin(0, em(1)),
        padding: padding(em(1), 0),
    },
    title: {
        fontSize: em(1.2),
        fontWeight: 700,
        marginBottom: em(1),
        opacity: 0.87,
    },
    status: {
        display: "inline-flex",
        flex: 1,
        marginBottom: rem(1),
        alignItems: "center",
        $nest: {
            svg: {
                marginLeft: em(1),
            },
            ".text": {
                fontSize: rem(2),
            },
        },
    },
    description: {},
    value: {},
    before: {
        content: "",
        position: "absolute",
        top: 0,
        left: 0,
        height: px(5),
        width: rem(4),
        background: "#c3c3c3",
    },
})(MaintenanceItem)
