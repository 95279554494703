import { border, color, rem } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { CarModelDetails } from "@tm/models"
import { classes } from "@tm/utils"
import { FastCalculator } from "@tm/data"
import { bem } from "../../data/helpers"
import CalcInputButton from "./calcInputs/calcInputButton"
import CalcInputCheck from "./calcInputs/calcInputCheck"
import CalcInputConsumableLabel from "./calcInputs/calcInputConsumableLabel"
import CalcInputDate from "./calcInputs/calcInputDate"
import CalcInputDropdown from "./calcInputs/calcInputDropdown"
import CalcInputNumber from "./calcInputs/calcInputNumber"
import CalcInputText from "./calcInputs/calcInputText"

type Props = {
    item: FastCalculator.CalcInput
    showBorder?: boolean
    className?: string
    selected?: boolean
    modelDetails?: CarModelDetails
    onChangeCallBack?: (data: string | Date | number | boolean) => void
    changeFCRoute?: () => void
    changeDropDownPosition?: boolean
    fromWidget?: boolean
}

export default function CalcInputComponent(props: Props) {
    const { onChangeCallBack, item, showBorder, className, selected, modelDetails, fromWidget } = props
    const style = useMemo(() => getStyle(), [])

    if (!item) {
        return null
    }

    const ownClassName = bem(
        style.calcInputContent,
        showBorder && item.value && "borderedSucces",
        showBorder && !item.value && "borderedWarning",
        item.isDisabled && "disabled",
        selected && "selected"
    )

    let content
    switch (item.style) {
        case FastCalculator.ECalcInputStyle.Text:
            content = <CalcInputText item={item} onChangeCallBack={onChangeCallBack} modelDetails={modelDetails} fromWidget={fromWidget} />
            break
        case FastCalculator.ECalcInputStyle.Slider:
            content = <CalcInputCheck item={item} onChangeCallBack={onChangeCallBack} fromWidget={fromWidget} />
            break
        case FastCalculator.ECalcInputStyle.Date:
            content = <CalcInputDate item={item} onChangeCallBack={onChangeCallBack} modelDetails={modelDetails} fromWidget={fromWidget} />
            break
        case FastCalculator.ECalcInputStyle.Number:
            content = <CalcInputNumber item={item} onChangeCallBack={onChangeCallBack} fromWidget={fromWidget} />
            break
        case FastCalculator.ECalcInputStyle.Button:
            content = <CalcInputButton item={item} />
            break
        case FastCalculator.ECalcInputStyle.DropDown:
            content = <CalcInputDropdown calcInput={item} changeFCRoute={props.changeFCRoute} changeDropDownPosition={props.changeDropDownPosition} />
            break
        default:
            break
    }

    switch (item.type) {
        case FastCalculator.ECalcInputType.ConsumableLabel:
            if (item.dropDownItems) {
                content = <CalcInputConsumableLabel item={item} />
            }
            break
        default:
            break
    }

    return <div className={classes(ownClassName, className)}>{content}</div>
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        calcInputContent: {
            $nest: {
                "&--borderedSucces": {
                    borderLeft: border({ style: "solid", color: theme.colors.success }),
                },
                "&--borderedWarning": {
                    borderLeft: border({ style: "solid", color: color(theme.colors.warning).mix(theme.colors.danger).toString() }),
                },
                "&--disabled": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                },
                "&--selected": {
                    color: theme.colors.primary,
                    opacity: 1,
                },
                ".react-datepicker__input-container": {
                    minWidth: "auto",
                },
                ".input__field--date": {
                    fontSize: rem(1),
                },
            },
        },
    })(CalcInputComponent)
}
