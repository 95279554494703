import { useCallback, useMemo } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { Article } from "@tm/models"
import { PureList } from "../../../../../parts/src"
import { ArticleListFeaturesDisableProvider } from "../../../../../parts/src/components/ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import {
    WheelsOverviewType,
    ParticularStateProvider,
} from "../../../../../parts/src/components/PureList/ContextProviders/Particular/ParticularContext"
import { MainState } from "../../main"
import { WheelSelectionSteps } from "../../../data/enums"
import { MainActions } from "../../main/business"
import { Actions } from "../business"
import { getBundleParams } from "../../../utils"

export function OverviewListV2() {
    const dispatch = useDispatch()
    const isLKQ = getBundleParams()?.version === "LKQ"

    const { rimItem, tireItem, sensorItem } = useSelector((s: MainState) => ({
        rimItem: s.overview.rimItem,
        tireItem: s.overview.tireItem,
        sensorItem: s.overview.sensorItem,
    }))
    const articles = useMemo(() => [rimItem, sensorItem, tireItem].filter((x) => !!x) as Article[], [rimItem, sensorItem, tireItem])

    const handleOnDelete = useCallback((item: Article) => {
        if (rimItem && (rimItem.id === item.id || item.internalId == rimItem.internalId)) {
            batch(() => {
                // TODO: RESET HISTORY NAV
                dispatch(Actions.updateRimsItems(undefined))
                dispatch(MainActions.changeStep({ step: WheelSelectionSteps.WHEELSLIST, disableNextSteps: true }, false))
            })
            return
        }

        if (tireItem && tireItem.internalId === item.internalId) {
            dispatch(Actions.updateTiresItems(undefined))
            return
        }

        if (sensorItem && sensorItem.internalId == item.internalId) {
            dispatch(Actions.updateSensorItems(undefined))
        }
    }, [])

    const handleSelectedArticle = useCallback(
        (item: Article) => {
            if (rimItem && (rimItem.id === item.id || item.internalId == rimItem.internalId)) {
                dispatch(Actions.updateRimsItems({ ...rimItem, isSelected: !rimItem.isSelected }))
                return
            }

            if (tireItem && tireItem.internalId === item.internalId) {
                dispatch(Actions.updateTiresItems({ ...tireItem, isSelected: !tireItem.isSelected }))
                return
            }

            if (sensorItem && sensorItem.internalId == item.internalId) {
                dispatch(Actions.updateSensorItems({ ...sensorItem, isSelected: !sensorItem.isSelected }))
            }
        },
        [dispatch, rimItem, sensorItem, tireItem]
    )

    const handleQuantityChange = useCallback((item: Article, quantity: number) => {
        if (rimItem && (rimItem.id === item.id || item.internalId == rimItem.internalId)) {
            dispatch(Actions.updateRimsItems({ ...rimItem, quantity }))
            return
        }

        if (tireItem && tireItem.internalId === item.internalId) {
            dispatch(Actions.updateTiresItems({ ...tireItem, quantity }))
            return
        }

        if (sensorItem && sensorItem.internalId == item.internalId) {
            dispatch(Actions.updateSensorItems({ ...sensorItem, quantity }))
        }
    }, [])

    const selectedArticles = useMemo(() => articles?.filter((x) => x.isSelected), [articles])

    const isArticleSelected = useCallback(
        (article: Article) => {
            const { id } = article
            return !!selectedArticles?.find((x) => x.id === id)
        },
        [selectedArticles]
    )

    const context: WheelsOverviewType = useMemo(() => {
        return {
            type: "wheelsOverview",
            articles,
            request: articles
                .map((x) => `${x.id ?? ""}_${x.internalId ?? 0}_${x.traderArticleNo ?? ""}_${x.supplierArticleNo ?? ""}_${x.quantity}`)
                .join(","),
            onDelete: handleOnDelete,
            onChangeQuantity: handleQuantityChange,
            toggleSelectedArticle: handleSelectedArticle,
            selectedArticles,
            isArticleSelected,
            largeCheckbox: isLKQ,
        }
    }, [articles, handleOnDelete, handleQuantityChange, handleSelectedArticle, selectedArticles, isArticleSelected, isLKQ])

    return (
        <ArticleListFeaturesDisableProvider
            disableArticleGrouping
            disableDeleteButton={false}
            disableAlternatives
            disableDescriptionCompactView={false}
            disableCostEstimationButton={isLKQ}
            disableBasketButton={isLKQ}
            displayOnlyQuantity={isLKQ}
        >
            <ParticularStateProvider value={context}>
                <PureList listId="wheelsOverview" listType="wheelsOverview" />
            </ParticularStateProvider>
        </ArticleListFeaturesDisableProvider>
    )
}
