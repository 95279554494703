import {
    ArticleActionIcon,
    ArticleActions,
    ArticleErpIcon,
    ArticleErpInfo,
    ErpIcon,
    ErpIconActionType,
    ErpIconDisplayLocation,
    ErpIconType,
} from "@tm/models"
import { useMemo } from "react"
import { useHistory } from "react-router"
import { useHandleClickDetails } from "../useHandleClickDetails"

type UseSpecialIconsProps = {
    articleErpInfo?: ArticleErpInfo
    onClickDetails: ReturnType<typeof useHandleClickDetails>
}

export function useSpecialIcons({ onClickDetails, articleErpInfo }: UseSpecialIconsProps): ArticleActions {
    const history = useHistory()
    return useMemo(() => {
        const articleActions: ArticleActions = {
            erpArea: [],
            imageArea: [],
            informationArea: [],
            menuArea: [],
        }

        if (articleErpInfo?.state !== "success" || !articleErpInfo.response.specialIcons.length) {
            return articleActions
        }

        function getActionHandler(erpIcon: ArticleErpIcon) {
            switch (erpIcon.actionType) {
                case ErpIconActionType.ReplacementItemsDetailPage: {
                    return () => onClickDetails("replacement-articles")
                }
                case ErpIconActionType.AlternativeItemsDetailPage: {
                    return () => onClickDetails("alternative-articles")
                }
                case ErpIconActionType.PawnItemsDetailPage: {
                    return () => onClickDetails("pawn-articles")
                }
                case ErpIconActionType.SafetyDataSheetsDetailPage: {
                    return () => onClickDetails("safety-data-sheet")
                }
                case ErpIconActionType.WholesalerInformationDetailPage: {
                    return () => onClickDetails("stocks")
                }
                case ErpIconActionType.OpenInTab: {
                    return () => console.log("Handler for ErpIconActionType.OpenInTab not defined")
                }
                case ErpIconActionType.OpenInWindow: {
                    if (erpIcon.linkUrl) {
                        return () => history.push(`/external01?url=${encodeURIComponent(erpIcon.linkUrl!)}`)
                    }
                    return () => console.log("Handler for ErpIconActionType.OpenInWindow: Missing linkUrl!")
                }
                case ErpIconActionType.ImagesAndDocumentsOnDetailPage: {
                    return () => onClickDetails("stocks")
                }
                default:
            }
            switch (erpIcon.type) {
                case ErpIconType.AlternativeItemAvailable: {
                    return () => onClickDetails("alternative-articles")
                }
                default:
            }
        }

        articleErpInfo.response.specialIcons.forEach((specialIcon, index) => {
            const mappedIcon: ArticleActionIcon = {
                iconType: specialIcon.type,
                sort: 1000 + index,
                displayType: "ICON",
                icon: specialIcon.url,
                tooltip: specialIcon.description,
                useIconFromUrl: true,
                handler: getActionHandler(specialIcon),
            }

            if (specialIcon.displayLocations?.includes(ErpIconDisplayLocation.ArticleImageContainer)) {
                articleActions.imageArea = [...articleActions.imageArea, mappedIcon]
            }
            if (specialIcon.displayLocations?.includes(ErpIconDisplayLocation.ArticleInformationContainer)) {
                articleActions.informationArea = [...articleActions.informationArea, mappedIcon]
            }
            if (specialIcon.displayLocations?.includes(ErpIconDisplayLocation.ErpContainer)) {
                articleActions.erpArea = [...articleActions.erpArea, mappedIcon]
            }
        })

        return articleActions
    }, [articleErpInfo])
}
