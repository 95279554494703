import { EFilterNames } from "@tm/models"
import { IListMultiFilters } from "../models"

export enum FilterType {
    None = 0,
    Season = 1 << 0,
    Manufacturer = 1 << 1,
    LoadIndex = 1 << 2,
    SpeedIndex = 1 << 3,
    OeIdentifier = 1 << 4,
    Extras = 1 << 5,
    Availability = 1 << 6,
    RangeSliders = 1 << 8,
    TireSizes = 1 << 9,
}

// TODO get rid of FilterType and use EFilterNames
export const getEnumByPath = (path: IListMultiFilters) => {
    switch (path) {
        // case EFilterNames.season: return FilterType.Season
        case EFilterNames.loadIndex:
            return FilterType.LoadIndex
        case EFilterNames.manufacturer:
            return FilterType.Manufacturer
        case EFilterNames.oeIdentifier:
            return FilterType.OeIdentifier
        case EFilterNames.extras:
            return FilterType.Extras
        case EFilterNames.speedIndex:
            return FilterType.SpeedIndex
        default:
            return FilterType.None
    }
}
