import { channel } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSearchTyre(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { searchTyre, sourceId } = data

    if (!searchTyre?.size) {
        return
    }

    const origin = TmaHelper.PostMessage.SearchTyre.GetOrigin(sourceId)
    channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { ...searchTyre, origin })
}
