import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import color from "tinycolor2"
import merge from "lodash/merge"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()
    const component = {
        active: {
            back: colors.highlight,
            fore: color(colors.light).setAlpha(opacity.primary).toRgbString(),
        },
        border: {
            radius: "3px",
        },
    }

    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&--selected": {
                transform: "unset",
                $nest: {
                    "&:hover .icon, &:hover .icon:hover": {
                        fill: `unset`,
                    },
                },
            },
            "&:hover:not(&--selected)": {
                $nest: {
                    ".icon": {
                        fill: "unset",
                    },
                },
            },
            "&--selected, &:active": {
                transform: "unset",
                background: component.active.back,
                backgroundColor: component.active.back,
                color: component.active.fore,
                borderRadius: component.border.radius,
                borderLeftColor: "transparent",
            },
            "&--selected .icon, &:active .icon": {
                // fill: "unset",
            },
            "&__close": {
                $nest: {
                    ".icon": {
                        fill: `${color(component.active.fore).setAlpha(0.87).toRgbString()} !important`,
                    },
                    "&:hover, &:active": {
                        background: "none",
                    },
                    "&:hover .icon": {
                        fill: `${colors.light} !important`,
                    },
                },
            },
        },
    }

    const mergedStyles = merge(baseStyles, themeStyles)
    return mergedStyles
}
