import { useCallback, useEffect, useMemo, memo, useRef } from "react"
import { useShowCisOptions, useUser } from "@tm/context-distribution"
import { CisVoucherType, UserModuleType } from "@tm/models"
import { parseQueryString, renderRoute, barcodeToastState } from "@tm/utils"
import { matchPath, useHistory, useLocation, useParams } from "react-router"
import { useSetRecoilState } from "recoil"

import { Box } from "@tm/components"
import { getBundleParams } from "../../utils"
import { getInitializedCisFilters, getInitializedCostOverviewFilters } from "../../business/helper"
import { CisFilters, OrderItemSearchTypeId } from "../../business/model"
import InvoicesFilterComponent from "./components/invoices-filter"
import OrderItemSearchComponent from "./components/order-item-search"
import PvOrdersFilterComponent from "./components/pv-orders-filter"
import StgOrdersFilterComponent from "./components/stg-orders-filter"
import { VouchersFilterComponent } from "./components/vouchers-filter"
import { useFilterStore } from "../../business/state"
import CostOverviewFilter from "./components/cost-overview-filter"

export type DropdownInputViewProps = {
    id?: number
    description: string
    showSingleDateFilter?: boolean
}

export const DropdownInputView = memo<DropdownInputViewProps>((props) => <>{props.description}</>)

type KeyboardShortcut = {
    key: string
    ctrl: boolean
    voucherTypeId: number
    queryTypeToSelect: number
}

export type PropsRef = {
    focus(): void
}

type Props = {
    // every customer might use different ids
    articleNumberSearchTypeId?: number
    manufacturerNumberSearchTypeId?: number
    europeanArticleNumberSearchTypeId?: number
    keyboardShortcuts?: KeyboardShortcut[]
    // MDM don't support different date presets for different voucher types. WM requires this.
    initialVoucherFilters?: Record<
        string,
        {
            dateToDaysPreset?: number
            dateFromDaysPreset?: number // Number of days calculated from date to preset when given
        }
    >
}

type FilterComponentRouteProps = {
    subpage?: "invoices" | "vouchers" | "cost-overview"
    /**
     *  This commented code shall not be deleted.
     *  The first version of the PV service for backorders doesn't support filtering.
     *  A future Version might support it, so just in that case these lines must remain.
     *  * */
    //    | "backorders"
    voucherTypeId?: string
}

export default function FilterComponent(props: Props) {
    const { cisOptions } = useShowCisOptions()
    const { manufacturerNumberSearchTypeId, articleNumberSearchTypeId, europeanArticleNumberSearchTypeId, initialVoucherFilters, keyboardShortcuts } =
        props
    const matchParams = useParams<FilterComponentRouteProps>()
    const { subpage, voucherTypeId } = matchParams
    const location = useLocation()
    const history = useHistory()
    const { userContext } = useUser()
    const cisModule = userContext.modules?.find((x) => x.type === UserModuleType.CustomerInformation)
    const hasModuleCosts = cisModule?.moduleCosts

    const setBarcodeToastState = useSetRecoilState(barcodeToastState)
    const filterRef = useRef<PropsRef>(null)

    const { cisVoucherUrl } = getBundleParams()

    const [filters, setFilters] = useFilterStore((store) => [store.filters, store.setFilters])

    const searchQuery = useMemo(() => {
        const searchQueryString = parseQueryString(location.search)?.query
        return searchQueryString ? decodeURIComponent(searchQueryString?.toString() ?? "") : undefined
    }, [location.search])

    const voucherTypeFilters = filters?.[voucherTypeId ?? ""]

    const dateFromDaysPreset = initialVoucherFilters?.[voucherTypeId ?? ""]?.dateFromDaysPreset
    const dateToDaysPreset = initialVoucherFilters?.[voucherTypeId ?? ""]?.dateToDaysPreset
    const mdmPreset = cisOptions?.dateFilterDayPreset

    const initializedCisFilters = useMemo(() => {
        if (subpage === "cost-overview") {
            return getInitializedCostOverviewFilters()
        }
        if (subpage === "invoices") {
            return getInitializedCisFilters(undefined, undefined, mdmPreset)
        }
        if (voucherTypeId) {
            return getInitializedCisFilters(cisOptions?.queryTypes?.[0]?.id, dateToDaysPreset, dateFromDaysPreset ?? mdmPreset)
        }

        getInitializedCisFilters(undefined, undefined, mdmPreset)
    }, [subpage, voucherTypeId, cisOptions?.queryTypes, dateToDaysPreset, dateFromDaysPreset, mdmPreset])

    const initializeFilters = useCallback(() => {
        if (cisOptions) {
            if (voucherTypeId) {
                // TODO: voucherTypeId should get to this point as number, in NavigationComponent in Render is set to string
                const typeId = +voucherTypeId
                if (initializedCisFilters && !voucherTypeFilters) {
                    setFilters(typeId.toString(), initializedCisFilters)
                }
            }
            // For invoices and backorders
            // TODO: when cis has only one single morphues component, setFilters for invoices should only be perforemed when hasInvoices is true
            else if (initializedCisFilters && !filters?.invoices) {
                setFilters("invoices", initializedCisFilters)
            } else if (initializedCisFilters && !filters?.costOverview && hasModuleCosts) {
                setFilters("costOverview", initializedCisFilters)
            }
        }
    }, [cisOptions, voucherTypeId, initializedCisFilters, filters?.invoices, filters?.costOverview, hasModuleCosts, voucherTypeFilters, setFilters])

    useEffect(() => {
        if (!searchQuery) {
            initializeFilters()
        }
    }, [initializeFilters, searchQuery])

    useEffect(() => {
        if (!keyboardShortcuts?.length) {
            return
        }
        
        document.addEventListener("keydown", handleOnKeyDown)
        return () => {
            document.removeEventListener("keydown", handleOnKeyDown)
            setBarcodeToastState({ show: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleOnKeyDown(ev: KeyboardEvent) {
        keyboardShortcuts?.forEach((keyboardShortcut) => {
            if (ev.key === keyboardShortcut.key && keyboardShortcut.ctrl === ev.ctrlKey) {
                ev.preventDefault()
                handleNavigationAndSetFilter(keyboardShortcut.voucherTypeId, keyboardShortcut.queryTypeToSelect)
                if (filterRef.current) {
                    filterRef.current.focus()
                }
            }
        })
    }

    useEffect(() => {
        const voucherType = !!voucherTypeId && parseInt(voucherTypeId)
        if (searchQuery && voucherTypeFilters?.itemId !== searchQuery && voucherTypeFilters?.query !== searchQuery) {
            if (voucherType === CisVoucherType.ArticleSearchInOrders) {
                setFilters(voucherType.toString(), {
                    ...voucherTypeFilters,
                    itemId: searchQuery,
                    queryTypeId: OrderItemSearchTypeId.Manufacturer,
                })
            } else if (voucherType === CisVoucherType.AllOrders) {
                setFilters(voucherType.toString(), { ...voucherTypeFilters, query: searchQuery })
            }
        }
    }, [searchQuery, voucherTypeFilters, voucherTypeId, subpage])

    function handleResetFilters() {
        if (voucherTypeId && initializedCisFilters) {
            setFilters(voucherTypeId, initializedCisFilters)
        } else if (initializedCisFilters && filters?.invoices) {
            setFilters("invoices", initializedCisFilters)
        }

        if (subpage === "cost-overview" && initializedCisFilters && filters?.costOverview) {
            setFilters("costOverview", initializedCisFilters)
        }
    }

    function handleNavigationAndSetFilter(voucherTypeIdToSelect: CisVoucherType, queryTypeToSelect: OrderItemSearchTypeId) {
        const url = renderRoute(cisVoucherUrl, { ...matchParams, voucherTypeId: voucherTypeIdToSelect, id: undefined, subId: undefined })
        history.push(url)
        setFilters(voucherTypeIdToSelect.toString(), {
            queryTypeId: queryTypeToSelect,
        })
    }

    function handleSetVoucherFilter(newFilters: CisFilters, manualChange: boolean) {
        if (voucherTypeId) {
            setFilters(voucherTypeId, newFilters)
        }
        const voucherDetailsMatch = manualChange && matchPath<{ id?: string; subId?: string }>(location.pathname, { path: cisVoucherUrl })
        if (voucherDetailsMatch && (voucherDetailsMatch.params.id || voucherDetailsMatch.params.subId)) {
            const url = renderRoute(cisVoucherUrl, { ...matchParams, id: undefined, subId: undefined })
            history.push(url)
        }
    }

    function handleSetInvoiceFilter(newFilters: CisFilters) {
        setFilters("invoices", newFilters)
    }

    function handleSetCostOverviewFilter(newFilters: CisFilters) {
        setFilters("costOverview", newFilters)
    }

    let content
    if (subpage) {
        switch (subpage) {
            case "invoices": {
                content = (
                    <InvoicesFilterComponent
                        filters={filters?.invoices || {}}
                        setFilters={handleSetInvoiceFilter}
                        resetFilters={handleResetFilters}
                    />
                )
                break
            }
            case "cost-overview": {
                content = (
                    <CostOverviewFilter
                        filters={filters?.costOverview || {}}
                        setFilters={handleSetCostOverviewFilter}
                        resetFilters={handleResetFilters}
                    />
                )
                break
            }
            /**
             *
             *  This commented code shall not be deleted.
             *  The first version of the PV service for backorders doesn't support filtering.
             *  A future Version might support it, so just in that case these lines must remain.
             *  * */
            // case "backorders": {
            //     content = (
            //         <PvOrdersFilterComponent
            //             filters={voucherTypeFilters || {}}
            //             setFilters={(filters) => handleSetVoucherFilter(filters, true)}
            //             searchFieldLabelId={13178}
            //             queryTypes={cisOptions?.queryTypes}
            //             onSearch={handleSearchVouchers}
            //         />
            //     )
            //     break
            // }
            case "vouchers": {
                if (voucherTypeId) {
                    // TODO: voucherTypeId should get to this point as number, in NavigationComponent in Render is set to string
                    const typeId = +voucherTypeId
                    switch (typeId) {
                        case CisVoucherType.AllOrders:
                            content = (
                                <PvOrdersFilterComponent
                                    filters={voucherTypeFilters || {}}
                                    setFilters={(newFilters) => handleSetVoucherFilter(newFilters, true)}
                                    searchFieldLabelId={1859}
                                    queryTypes={cisOptions?.queryTypes}
                                />
                            )
                            break
                        case CisVoucherType.OpenOrders:
                        case CisVoucherType.ClosedOrders: {
                            content = (
                                <StgOrdersFilterComponent
                                    filters={voucherTypeFilters || {}}
                                    setFilters={(newFilters) => handleSetVoucherFilter(newFilters, true)}
                                    voucherTypeId={typeId}
                                    queryTypes={cisOptions?.queryTypes}
                                />
                            )
                            break
                        }
                        case CisVoucherType.ArticleSearchInOrders: {
                            content = (
                                <OrderItemSearchComponent
                                    filters={voucherTypeFilters || {}}
                                    setFilters={(newFilters) => handleSetVoucherFilter(newFilters, true)}
                                />
                            )
                            break
                        }
                        case CisVoucherType.Complaints:
                        case CisVoucherType.PickupInvoices: {
                            break
                        }
                        default: {
                            content = (
                                <VouchersFilterComponent
                                    voucherTypeId={+voucherTypeId}
                                    filters={voucherTypeFilters}
                                    queryTypes={cisOptions?.queryTypes}
                                    articleNumberSearchTypeId={articleNumberSearchTypeId}
                                    manufacturerNumberSearchTypeId={manufacturerNumberSearchTypeId}
                                    europeanArticleNumberSearchTypeId={europeanArticleNumberSearchTypeId}
                                    initialVoucherFilters={initialVoucherFilters}
                                    setFilters={(newFilters) => handleSetVoucherFilter(newFilters, true)}
                                    resetFilters={handleResetFilters}
                                    ref={filterRef}
                                />
                            )
                            break
                        }
                    }
                }
                break
            }
            default:
                break
        }
    }

    return content ? (
        <Box sx={{ margin: "0.75em", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>{content}</Box>
        </Box>
    ) : (
        <></>
    )
}
