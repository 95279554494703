import { Article, ArticleSelectionParams } from "@tm/models"
import { useCallback, useMemo } from "react"
import { emptyFunc } from "../../ListV2/helpers"

export function useTyreArticleSelection(
    selectedArticles: Article[],
    toggleSelectedArticle?: (article: Article) => void
): ArticleSelectionParams<Article> {
    const isArticleSelected = useCallback(
        (article: Article) => selectedArticles.findIndex((x) => x?.internalId === article?.internalId) > -1,
        [selectedArticles]
    )

    const deselectAll = useCallback(() => {
        if (!toggleSelectedArticle) {
            return
        }
        selectedArticles.forEach((article) => toggleSelectedArticle(article))
    }, [toggleSelectedArticle, selectedArticles])

    return useMemo(
        () => ({
            selectedArticles,
            toggleSelectedArticle: toggleSelectedArticle ?? emptyFunc,
            deselectArticle: emptyFunc,
            deselectAll,
            isArticleSelected,
        }),
        [isArticleSelected, selectedArticles, toggleSelectedArticle, deselectAll]
    )
}
