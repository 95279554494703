import { DialogContent, CircularProgress, styled, MuiTableCell } from "@tm/components"

export function ModalLoader() {
    return (
        <DialogContent>
            <CircularProgress />
        </DialogContent>
    )
}

export const StyledCell = styled(MuiTableCell)(() => ({
    fontSize: "1rem",
}))
