import { ETyreCountType, ETyresCarType, TyreFilter } from "@tm/models"
import { Statics } from "../statics"

export const getTyreCountFilters = (carType: string | undefined): TyreFilter[] => {
    let multiTiresFilter: TyreFilter | undefined

    if (carType === ETyresCarType.PKW.toString()) {
        multiTiresFilter = Statics.tyreCountFilters.find((x) => x.query === ETyreCountType.carTires)
    }
    if (carType === ETyresCarType.Motorrad.toString()) {
        multiTiresFilter = Statics.tyreCountFilters.find((x) => x.query === ETyreCountType.motorcycleTires)
    }
    if (multiTiresFilter) {
        return [Statics.getDefaultTyreCountFilter(), multiTiresFilter]
    }

    return []
}
