import { Component } from "react"
import { withUserContext, WithUserContextProps } from "@tm/context-distribution"
import { Text, Button, MoreMenu, Checkbox } from "@tm/controls"
import { withLocalization, LocalizationProps } from "@tm/localization"
import { WholesalerArticle, AvailabilityStatus, IWholesalerItemTemplate, Article, WholesalerPart, RepairTimeProvider, IMicros } from "@tm/models"
import Morpheus, { importMicro, TemplateRenderer, WithMicroProps } from "@tm/morpheus"
import { bindSpecialReactMethods, getRepairTimeProviders } from "@tm/utils"
import ArticleCell from "../../_shared/article-cell"
import { getBundleParams } from "../../../utils"
import { SearchType } from "../../../business"
import ArticleNumbers, { StyledStack } from "../../_shared/ArticleNumbers"

type Props = LocalizationProps &
    WithMicroProps<IMicros> &
    WithUserContextProps & {
        part: WholesalerArticle
        hideThumbnail?: boolean
        hideRetailPrice?: boolean
        vehicleId?: string
        customerId?: string
        hideCostEstimationButton?: boolean | undefined
        searchType: SearchType
        advertisementCategoryId?: string
        getRepairTimesUrl(article: Article, rtProviders: Array<RepairTimeProvider> | RepairTimeProvider): string | undefined
    }

type State = {
    quantity?: number
}

@importMicro
class WholesalerItem extends Component<Props, State> implements IWholesalerItemTemplate, TemplateRenderer {
    rendererIdentifier = {
        bundle: "parts",
        component: "wholesaler-item",
    }

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        this.state = {}
    }

    getQualifiedClassName(withPanel = true): string {
        let className = "wholesaler-item article-list__item"
        if (withPanel) {
            className += " article-list__panel"
        }

        return className
    }

    /** Map only the properties required for getting the erp info */
    mapWholesalerPartToArticle(part: WholesalerArticle): Partial<Article> {
        const quantity = this.state.quantity || part.quantity?.value || part.quantity?.confirmedValue
        return {
            id: part.itemId,
            supplier: {} as any,
            productGroup: {} as any,
            supplierArticleNo: part.dataSupplierArticleNumber,
            traderArticleNo: part.wholesalerArticleNumber,
            description: part.additionalInformationExtended,
            quantity,
            requestErpInfo: true,
        }
    }

    mapWholesalerArticleToWholesalerPart(part: WholesalerArticle, advertisementCategoryId?: string): Partial<WholesalerPart> {
        const quantity = this.state.quantity || part.quantity?.value || part.quantity?.confirmedValue
        return {
            wholesalerArticleNumber: part.wholesalerArticleNumber,
            description: part.additionalInformationExtended,
            quantityValue: quantity,
            availability: {
                description: part.availability?.description || "",
                status: part.availability?.type || AvailabilityStatus.Unknown,
            },
            advertisementCategoryId,
            itemRole: part.itemRole,
            itemId: part.itemId,
        }
    }

    renderActions() {
        return (
            <ArticleCell bemModifier="actions">
                {this.renderBuyActions()}
                {this.renderAdditionalActions()}
            </ArticleCell>
        )
    }

    renderBuyActions(showButtonText?: boolean) {
        const {
            part,
            localization: { translateText },
            renderMicro,
            advertisementCategoryId,
        } = this.props
        return renderMicro!("basket", "add-to-basket-wholesaler-part", {
            part: this.mapWholesalerArticleToWholesalerPart(part, advertisementCategoryId) as WholesalerPart,
            buttonText: showButtonText ? translateText(133) : undefined,
            onChangeQuantity: this.handleChangeQuantity,
            generatedArticleId: part.itemId,
        })
    }

    handleChangeQuantity(quantity: number) {
        this.setState({ quantity })
    }

    renderAdditionalActions() {
        return (
            <>
                {this.renderCostEstimationButton()}
                <Button disabled>{this.props.localization.translate(43)}</Button>
                <MoreMenu
                    disabled
                    items={[]}
                    onMenuItemSelect={() => {
                        return null
                    }}
                />
                {this.renderSelector()}
            </>
        )
    }

    renderCostEstimationButton() {
        if (this.props.hideCostEstimationButton) {
            return
        }

        const { part, renderMicro, vehicleId, customerId, getRepairTimesUrl } = this.props
        const article = this.mapWholesalerPartToArticle(part)
        const { repairTimeProviders } = getRepairTimeProviders()

        // micro add-to-cost-estimate
        return renderMicro!("basket", "add-to-cost-estimate", {
            data: [article as Article],
            vehicleId,
            customerId,
            repairTimeProviders,
            getRepairTimesUrl,
            erpType: "list",
        })
    }

    renderAddToCompilations() {
        const { part, renderMicro } = this.props
        const article = this.mapWholesalerArticleToWholesalerPart(part)

        const compilationMicro = renderMicro!("compilations", "add-to-compilation", {
            wholesalerPart: article as WholesalerPart,
        })

        if (compilationMicro) {
            return <div className="btn-compilations">{compilationMicro}</div>
        }

        return null
    }

    renderDescription() {
        const { part, renderMicro } = this.props
        return (
            <ArticleCell bemModifier="description">
                <Text className="article__description">{part.additionalInformationExtended}</Text>
                {!part.additionalInformationExtended &&
                    part.wholesalerArticleNumber &&
                    renderMicro!("erp", "erp-info-additional-information", {
                        data: this.mapWholesalerPartToArticle(part) as Article,
                    })}
            </ArticleCell>
        )
    }

    renderErpInformation() {
        return (
            <ArticleCell bemModifier="erp-information">
                {this.renderPrices()}
                <div className={this.props.searchType === SearchType.OFFERS ? "alternatives-offers" : "alternatives"}>
                    {this.renderAlternativeArticles()}
                </div>
                {this.renderAvailability()}
            </ArticleCell>
        )
    }

    renderNumbers() {
        const { part, userContext } = this.props
        return (
            <ArticleCell bemModifier="numbers">
                <StyledStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
                    {!userContext?.parameter.hideDealerPartNumber && <ArticleNumbers wholesalerArticleNumber={part.wholesalerArticleNumber} />}
                    {part.dataSupplierArticleNumber && <ArticleNumbers supplierArticleNumber={part.dataSupplierArticleNumber} />}
                </StyledStack>
            </ArticleCell>
        )
    }

    renderPrices(priceNamesInTooltip?: boolean) {
        const { part, hideRetailPrice, renderMicro } = this.props
        if (part.prices?.length) {
            return renderMicro!("erp", "display-only-prices", {
                data: this.mapWholesalerPartToArticle(part) as Article,
                prices: part.prices,
                priceNamesInTooltip,
            })
        }
        if (part.wholesalerArticleNumber) {
            return renderMicro!("erp", "erp-info-prices", {
                data: this.mapWholesalerPartToArticle(part) as Article,
                hideRetailPrice,
                priceNamesInTooltip,
            })
        }
    }

    renderSale() {
        const { part, renderMicro } = this.props
        return renderMicro!("erp", "erp-info-sale", {
            data: this.mapWholesalerPartToArticle(part) as Article,
        })
    }

    renderQuantityUnit() {
        const { part, renderMicro } = this.props
        return renderMicro!("erp", "erp-info-quantity-unit", {
            data: this.mapWholesalerPartToArticle(part) as Article,
        })
    }

    renderAlternativeArticles(showText?: boolean) {
        const { part, renderMicro } = this.props
        return renderMicro!("erp", "erp-info-alternatives", {
            data: this.mapWholesalerPartToArticle(part) as Article,
            isWholesalerPart: true,
            showOnlyIcon: !showText,
        })
    }

    renderAdditionalActionsAsRowWithoutMore() {
        return (
            <ArticleCell bemModifier="additional-actions">
                <Button disabled>{this.props.localization.translate(43)}</Button>
                {this.renderAlternativeArticles(true)}
            </ArticleCell>
        )
    }

    renderAvailability() {
        const { part, renderMicro } = this.props
        const { availability } = part
        if (
            availability &&
            availability.type != AvailabilityStatus.Undefined &&
            availability.type != AvailabilityStatus.Unknown &&
            (!availability.icon || (availability.icon.useIconFromUrl && availability.icon.url))
        ) {
            return renderMicro!("erp", "display-only-availability", {
                availability,
                tour: part.tour,
                warehouses: part.warehouses,
            })
        }
        if (part.wholesalerArticleNumber) {
            return renderMicro!("erp", "erp-info-availability", {
                data: this.mapWholesalerPartToArticle(part) as Article,
            })
        }
    }

    renderSelector() {
        return <Checkbox disabled />
    }

    renderThumbnail() {
        return (
            <ArticleCell bemModifier="thumbnail" style={{ display: this.props.hideThumbnail ? "none" : undefined }}>
                <Button
                    icon="supplier"
                    title={this.props.localization.translateText(1385)}
                    className="image article__thumbnail article__thumbnail--clickable"
                    fakeButton
                />
            </ArticleCell>
        )
    }

    renderPrefix() {
        return (
            <ArticleCell bemModifier="prefix">
                <div>
                    <Button icon="up" layout={["ghost"]} disabled />
                </div>
            </ArticleCell>
        )
    }

    renderSupplier() {
        return <ArticleCell bemModifier="supplier supplier__name" />
    }

    renderDefault() {
        return (
            <div className={this.getQualifiedClassName()}>
                {this.renderPrefix()}
                {this.renderThumbnail()}
                {this.renderSupplier()}
                {this.renderNumbers()}
                {this.renderDescription()}
                {this.renderErpInformation()}
                {this.renderActions()}
            </div>
        )
    }

    render() {
        const template = getBundleParams().templates?.wholesalerItem
        let content = null

        if (template) {
            content = Morpheus.renderTemplate(template.bundle, template.template, this)
        }

        if (!content) {
            content = this.renderDefault()
        }

        return content
    }
}

export default withLocalization(withUserContext(WholesalerItem))
