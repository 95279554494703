import { useLocalization } from "@tm/localization"
import { Availability, ItemTour, SpecialProcurementErpInformation, Warehouse } from "@tm/models"
import { useRecoilValue } from "recoil"
import { selectedOrderOptionState } from "@tm/utils"
import { Icon, IconButton, Tooltip } from "@tm/components"
import { ReactElement, ReactNode } from "react"
import AvailabilityWrapperComponent from "./availability-wrapper"
import { getBundleParams } from "../utils"

type AvailabilityProps = {
    availability?: Availability
    tour?: ItemTour
    warehouses?: Array<Warehouse>
    hasAlternatives: boolean
    hasReplacementArticles: boolean
    error?: string
    classPrefix: string
    hasReplacedArticles: boolean
    customComponents?: ReactNode | Array<ReactNode>
    partnerSystem?: ReactElement | undefined
    requestedQuantity: number | undefined
    hideAlternativesIcon?: boolean
    onClick?(useSpecialProcurement?: boolean): void
    onAlternativesClick?(): void
    onReplacementArticlesClick?(): void
    useSpecialProcurement?: boolean
    specialProcurementInformation?: SpecialProcurementErpInformation
}

export default function AvailabilityComponent(props: AvailabilityProps) {
    const { hasAlternatives, onAlternativesClick, hasReplacementArticles, onReplacementArticlesClick, classPrefix, hideAlternativesIcon } = props
    const selectedOrderOption = useRecoilValue(selectedOrderOptionState)
    const { hideDefaultReplacedArticlesIcon } = getBundleParams()
    const { translateText } = useLocalization()

    let alternativesClassName = `${classPrefix}__alternatives__clickable`
    let availabilityClassName = `${props.classPrefix}__availability`
    if ((!hasAlternatives || !onAlternativesClick || hideAlternativesIcon) && (hideDefaultReplacedArticlesIcon || !hasReplacementArticles)) {
        alternativesClassName = `${classPrefix}__alternatives__disabled`
        availabilityClassName += ` ${props.classPrefix}__availability--no-alternatives`
    }

    let alternativesAction
    let alternativesTooltip
    if (hasAlternatives) {
        alternativesAction = onAlternativesClick
        alternativesTooltip = translateText(710)
    } else if (hasReplacementArticles) {
        alternativesAction = onReplacementArticlesClick
        alternativesTooltip = translateText(13140)
    }

    return (
        <div className={availabilityClassName}>
            <div className={`${classPrefix}__alternatives ${alternativesClassName}`}>
                <Tooltip title={alternativesTooltip}>
                    <IconButton onClick={alternativesAction} className={alternativesClassName} disabled={!hasAlternatives && !hasReplacementArticles}>
                        <Icon name="alternative-article" />
                    </IconButton>
                </Tooltip>
            </div>
            {props.partnerSystem}
            <AvailabilityWrapperComponent
                onClick={props.onClick}
                hasReplacedArticles={props.hasReplacedArticles}
                availability={props.availability}
                tour={props.tour}
                warehouses={props.warehouses}
                error={props.error}
                requestedQuantity={props.requestedQuantity}
                specialProcurementInformation={props.specialProcurementInformation}
                useSpecialProcurement={props.useSpecialProcurement}
                selectedShipmentMode={selectedOrderOption.orderOption}
            />
            {props.customComponents}
        </div>
    )
}
