import { memo, useCallback, useRef, useState } from "react"
import { Container } from "@tm/nexus"
import { Image } from "@tm/controls"
import { Article, ArticleImage, RegisteredModels } from "@tm/models"
import { Skeleton, styled, ImageViewer, ResizablePopover, PopoverActions, ImageViewerRefActions } from "@tm/components"

type Props = {
    articleInfo?: Article
}

const ClickableImage = styled(Image)(() => ({
    cursor: "pointer",
}))

export const PartListItemImage = memo<Props>(({ articleInfo }) => {
    const [imagesLoading, setImagesLoading] = useState(false)
    const [articleImages, setArticleImages] = useState<ArticleImage[]>([])
    const [open, setOpen] = useState<boolean>(false)

    const handleLoadImages = useCallback(() => {
        setImagesLoading(true)

        Container.getInstance<ArticleImage[]>(RegisteredModels.Articles_GetArticleImageByInternalArticleId)
            .subscribe(articleInfo!.internalId)
            .load()
            .then(
                (response) => {
                    setArticleImages(response)
                    setImagesLoading(false)
                },
                () => setImagesLoading(false)
            )
            .finally(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"))
                }, 150)
            })
    }, [articleInfo?.internalId])

    const handleTooltipOpen = useCallback(() => {
        handleLoadImages()
        setOpen(true)
    }, [handleLoadImages])

    const handleTooltipClose = useCallback(() => {
        setOpen(false)
    }, [])

    const imageViewerActionsRef = useRef<ImageViewerRefActions>(null)
    const anchorEl = useRef<HTMLImageElement>(null)

    if (!articleInfo) {
        return (
            <div className="thumbnail no-separator">
                <Skeleton variant="text" height="63px" width="63px" />
            </div>
        )
    }

    const onResize = () => {
        imageViewerActionsRef?.current?.fitImageToContent()
    }

    const popoverTitle = `${articleInfo.supplier.name}${articleInfo.supplierArticleNo ? ` | ${articleInfo.supplierArticleNo}` : ""}`

    return (
        <div className="thumbnail no-separator">
            <ClickableImage url={articleInfo.thumbnail || ""} type="article" ref={anchorEl} onClick={handleTooltipOpen} />

            <ResizablePopover
                open={open}
                onClose={handleTooltipClose}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{ ml: 1 }}
                onResize={onResize}
                title={popoverTitle}
            >
                <ImageViewer images={articleImages} isLoading={imagesLoading} />
            </ResizablePopover>
        </div>
    )
})
