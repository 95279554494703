import { ReactNode } from "react"
import { Box, MuiTableCell, styled, Tooltip } from "@tm/components"

const OverflowAtPx = 310

const StyledTableCell = styled(MuiTableCell)({
    ".MuiTableCell-head": {
        fontSize: 11,
        color: "black",
        padding: "5px 4px 5px 6px",
        border: "none",
        whiteSpace: "nowrap",
        fontFamily: "'Open Sans'",
    },
    ".MuiTableCell-body": {
        color: "black",
        whiteSpace: "nowrap",
        padding: "8px 4px 8px 6px",
        fontFamily: "'Open Sans'",
        fontSize: 12,
    },
})

export function OverflowTooltipTableCell(props: { children: ReactNode; tooltipContent?: string }) {
    return (
        <StyledTableCell>
            <Tooltip title={props.tooltipContent} variant="light">
                <Box textOverflow="ellipsis" overflow="hidden" maxWidth={`${OverflowAtPx}px`}>
                    {props.children}
                </Box>
            </Tooltip>
        </StyledTableCell>
    )
}

export function StyledCell(props: { children: ReactNode }) {
    return <StyledTableCell>{props.children}</StyledTableCell>
}
