import { useUser } from "@tm/context-distribution"
import { Button, Checkbox, Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { em, percent, rem } from "csx"
import { useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { Typography, styled } from "@tm/components"
import { getCurrencyFromUserContext } from "../../../../data/helpers"
import { Work } from "../../../../data/models"
import { calculationSelector, MainActions } from "../../../main/business"
import IncludedWorks from "./incudedWorks"

export default function WorkList() {
    const user = useUser()
    const { translateText, currency, number } = useLocalization()
    const { works } = useSelector(calculationSelector)
    const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({})
    const currencyString = getCurrencyFromUserContext(user?.userContext)
    const actions = useActions(MainActions, "selectWork")

    const handleCollapse = useCallback(
        (work: Work) => {
            const key = work.providerWorkId + work.description
            setIsExpanded((state) => ({ ...state, [key]: !state[key] }))
        },
        [setIsExpanded]
    )

    const renderIncludes = (work: Work) => (
        <Table.Cell>
            <Button
                icon={isExpanded[work.providerWorkId + work.description] ? "up" : "down"}
                size="s"
                disabled={!work.includedWorks?.length}
                layout={["ghost"]}
                onClick={() => handleCollapse(work)}
            />
        </Table.Cell>
    )

    const renderProviderId = (work: Work) => (
        <Table.Cell>
            {work.providerWorkId
                ?.split(/(?=[+])/)
                .map((providerWorkId: string, index: number) => <Typography key={index}>{providerWorkId}</Typography>)}
        </Table.Cell>
    )

    const renderDescription = (work: Work) => (
        <Table.Cell>
            <Typography>{work.description} </Typography>
        </Table.Cell>
    )

    const renderHourlyRate = (work: Work) => (
        <Table.Cell>
            <Typography>{currency(work.hourlyRate, currencyString)} </Typography>
        </Table.Cell>
    )

    const renderRtUnitValue = (work: Work) => (
        <Table.Cell>
            <Typography>{number(work.rtUnit, 2)}</Typography>
        </Table.Cell>
    )

    const renderRtValue = (work: Work) => (
        <Table.Cell>
            <Typography>{number(work.time, 2)}</Typography>
        </Table.Cell>
    )

    const renderTotal = (work: Work) => (
        <Table.Cell>
            <Typography>{currency(work.total, currencyString)}</Typography>
        </Table.Cell>
    )

    const renderCheckbox = (work: Work) => (
        <Table.Cell>
            <Checkbox checked={work.isSelected} onToggle={() => actions.selectWork(work)} />
        </Table.Cell>
    )

    const renderIncludedWorks = (work: Work) => (
        <Table.Cell>
            {work.includedWorks && (
                <IncludedWorks
                    includedWorks={work.includedWorks}
                    showSupplierArticleNumbers // TODO: this.props.showSupplierArticleNumbers
                />
            )}
        </Table.Cell>
    )

    const rtValueTitle = user?.userSettings?.repairTimeOptions?.division === 1 ? translateText(84) : translateText(621)

    const columns = [
        <Table.Column key="includes" className="includes" renderItemContent={renderIncludes} />,
        <Table.Column key="number" className="number" renderItemContent={renderProviderId}>
            {translateText(58)}
        </Table.Column>,
        <Table.Column key="description" className="description" renderItemContent={renderDescription}>
            {translateText(617)}
        </Table.Column>,
        <Table.Column key="hourly-rate price" className="hourly-rate price" renderItemContent={renderHourlyRate}>
            {translateText(622)}
        </Table.Column>,
        user?.userSettings?.repairTimeOptions?.division !== 1 && (
            <Table.Column className="rt-value time" renderItemContent={renderRtUnitValue}>
                {translateText(1723)}
            </Table.Column>
        ),
        <Table.Column key="rt-value time" className="rt-value time" renderItemContent={renderRtValue}>
            {rtValueTitle}
        </Table.Column>,
        <Table.Column key="price sum" className="price sum" renderItemContent={renderTotal}>
            {translateText(57)}
        </Table.Column>,
        <Table.Column key="actions" className="actions" renderItemContent={renderCheckbox} />,
        <Table.Column key="expandableRow" className="expandableRow" renderItemContent={renderIncludedWorks} />,
    ]

    return (
        <StyledTable
            data={works}
            columns={columns.filter(Boolean) as any}
            getRowClassName={(work: Work) => (isExpanded[work.providerWorkId + work.description] ? "is-expanded" : "")}
        />
    )
}

const StyledTable = styled(Table)({
    ".is-expanded": {
        flexWrap: "wrap",
        $nest: {
            ".expandableRow": {
                display: "block",
            },
        },
    },
    ".expandableRow": {
        flex: percent(100),
        display: "none",
    },
    ".includes": {
        flex: 0,
        flexBasis: rem(1.6),
    },
    ".provider, .category": {
        flex: 0,
        flexBasis: rem(5.2),
        justifyContent: "flex-start",
    },
    ".price": {
        flex: 0,
        flexBasis: rem(5.7),
        textAlign: "right",
        $nest: {
            "&.sum": {
                display: "flex",
                flex: 0,
                flexBasis: rem(7.5),
                paddingLeft: em(1.5),
                justifyContent: "flex-end",
            },
        },
    },
    ".time": {
        flex: 0,
        flexBasis: rem(5.3),
        textAlign: "right",
        whiteSpace: "normal",
    },
    ".actions": {
        flex: 0,
        flexBasis: rem(1.5),
    },
    ".type, .note": {
        flex: 0,
        flexBasis: rem(1.2),
    },
    ".number": {
        flex: 0,
        flexBasis: rem(9),
        display: "flex",
        flexDirection: "column",
    },
    ".description": {
        flex: 1,
    },
    ".fancy-list__head": {
        fontWeight: "500",
    },
})
