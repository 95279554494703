import { Box, styled } from "@tm/components"
import { FC, memo, useEffect, useMemo, useRef, useState } from "react"
import { FreeMode, Mousewheel } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { Swiper as SwiperType } from "swiper/types"
import SlideComponent from "./slideComponent"
import { changeStepWithHistory, useFastServiceStore } from "../../data"

type StepperNavigationProps = {
    stepsWrapperHeight: number
}

const StepsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flexWrap: "wrap",
    touchAction: "none",
    ".swiperjs": {
        width: "12.5em",
    },
    ">*": {
        userSelect: "none",
    },
})

const StepperNavigation: FC<StepperNavigationProps> = memo(({ stepsWrapperHeight }) => {
    const { navigationSteps, activeStep, inputsLocked } = useFastServiceStore((state) => ({
        navigationSteps: state.stepNavigationState.steps,
        activeStep: state.navigation.active,
        inputsLocked: state.inputsLocked,
    }))

    const [insideActiveIndex, setInsideActiveIndex] = useState(0)
    const [controlledSwiper, setControlledSwiper] = useState<SwiperType>()
    const realActiveIndex = useMemo<number>(() => getStep(activeStep || "start"), [activeStep])
    const scrollTimeout = useRef<NodeJS.Timeout | null>(null)
    const isNotDev = localStorage.getItem("ENV") !== "development"

    function getStep(step: string): number
    function getStep(step: number): string
    function getStep(step: string | number): number | string {
        if (typeof step === "string") {
            return navigationSteps.findIndex((x) => x.stepName === step)
        }

        return navigationSteps[step]?.stepName
    }

    useEffect(() => {
        if (realActiveIndex !== insideActiveIndex && controlledSwiper) {
            setInsideActiveIndex(realActiveIndex)
            controlledSwiper?.slideTo(realActiveIndex)
        }
    }, [realActiveIndex, controlledSwiper])

    const handleScrollEnd = (swiper: SwiperType | undefined) => {
        const newIndex = swiper?.activeIndex

        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current)
        }

        if (newIndex !== undefined && newIndex !== insideActiveIndex) {
            setInsideActiveIndex(newIndex)
        }

        scrollTimeout.current = setTimeout(() => {
            swiper?.slideToClosest()

            if (newIndex !== undefined && navigationSteps[newIndex]?.available && isNotDev) {
                changeStepWithHistory(getStep(+newIndex) ?? "start")
            }
        }, 300)
    }

    const onClick = (e: React.MouseEvent<HTMLElement>, idx: number) => {
        e.preventDefault()
        e.stopPropagation()

        if (!navigationSteps[idx]?.available) {
            controlledSwiper?.slideTo(realActiveIndex)
            return null
        }

        controlledSwiper?.slideToLoop(idx)
        changeStepWithHistory(getStep(idx) ?? "start")
    }

    const renderArray = () => {
        return navigationSteps.map((slide, idx) => {
            return (
                <SwiperSlide
                    style={{
                        width: "100%",
                        paddingLeft: "0.5em",
                        display: "flex",
                    }}
                    onClick={(e) => onClick(e, idx)}
                    key={slide.stepName + idx}
                >
                    <SlideComponent idx={idx} slideObject={slide} activeIndex={insideActiveIndex} />
                </SwiperSlide>
            )
        })
    }

    return (
        <StepsWrapper>
            {stepsWrapperHeight > 0 && (
                <Swiper
                    key={inputsLocked ? "unlocked" : "locked"}
                    style={{ height: stepsWrapperHeight }}
                    className="swiperjs"
                    direction="vertical"
                    slidesPerView={9}
                    spaceBetween={20}
                    breakpoints={{
                        850: {
                            slidesPerView: 7,
                            spaceBetween: 20,
                        },
                        1500: {
                            slidesPerView: 9,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Mousewheel, FreeMode]}
                    freeMode={{ enabled: inputsLocked, momentum: false, sticky: true }}
                    allowTouchMove={inputsLocked}
                    watchSlidesProgress
                    speed={200}
                    preventInteractionOnTransition
                    loopPreventsSliding
                    centeredSlides
                    mousewheel={{ enabled: inputsLocked, forceToAxis: true, sensitivity: 0.5, releaseOnEdges: true }}
                    onSwiper={(s: SwiperType) => setControlledSwiper(s)}
                    onTransitionEnd={() => handleScrollEnd(controlledSwiper)}
                    onScroll={() => (inputsLocked ? handleScrollEnd(controlledSwiper) : null)}
                    onTouchMove={(s: SwiperType) => setInsideActiveIndex(s.activeIndex)}
                >
                    {renderArray()}
                </Swiper>
            )}
        </StepsWrapper>
    )
})

export default StepperNavigation
