import { Grid, styled } from "@tm/components"
import { ArticleErpInfo } from "@tm/models"
import { useEffect, useMemo, useRef, useState } from "react"
import { Route } from "react-router"
import { SearchTreeRefType } from "../../searchtreeV2/component"
import { ArticleListWrapper, ArticleListWrapperRefType } from "../components/ArticleListWrapper"
import { ModuleHeader } from "../components/ModuleHeader"
import { SearchTree } from "../components/Searchtree"
import { ActiveVehicleDataProvidersProvider } from "../ContextProvider/ActiveVehicleDataProviders"
import { BasketQuantitiesProvider } from "../ContextProvider/BasketQuantities"
import { ComposerComponent, ContextComposer } from "../ContextProvider/ContextComposer"
import { ErpInfosProvider } from "../ContextProvider/ErpInfos"
import { ListOptionsProvider } from "../ContextProvider/ListOptions"
import { NotesProvider } from "../ContextProvider/Notes"
import { PreviouslyOrderedArticlesProvider } from "../ContextProvider/PreviouslyOrderedArticles"
import { ProductGroupRepairTimesProvider } from "../ContextProvider/ProductGroupRepairTimes"
import { ProductGroupTopicIdsProvider } from "../ContextProvider/ProductGroupTopicIds"
import { SupplierLogosProvider } from "../ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ContextProvider/TradeReferences"
import { WatchListProvider } from "../ContextProvider/WatchList"
import { VehiclePartsArticleListActionsProvider } from "./ContextProvider/ArticleListActions"
import { VehiclePartsArticlesProvider } from "./ContextProvider/Articles"
import { VehiclePartsFiltersProvider } from "./ContextProvider/Filters"
import { useVehiclePartsListParamsContext, VehiclePartsListParamsProvider } from "./ContextProvider/ListParams"
import { VehicleRecordsProvider } from "./ContextProvider/VehicleRecords"
import { VehiclePartsList } from "./VehiclePartsList"

export const ArticleListGrid = styled(Grid, {
    name: "ArticleListContainer",
    slot: "root",
    overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
    display: "grid",
    gridTemplateRows: "auto 1fr",
    overflow: "hidden",
    flex: 1,
}))

function VehiclePartsComponent() {
    const { startParams, showAvailable, productGroups, suppliers, attributes, fittingPosition } = useVehiclePartsListParamsContext()

    const searchTreeRef = useRef<SearchTreeRefType>(null)
    const articleListWrapperRef = useRef<ArticleListWrapperRefType>(null)

    const hasMissingParams = useMemo(() => {
        switch (startParams.type) {
            case "node":
                return !startParams.treeId || !startParams.nodeId
            case "productgroups":
                return !startParams.productGroupIds.length
            case "synonym":
                return !startParams.query
            default:
                return true
        }
    }, [startParams])

    useEffect(
        function showSearchTreeWhenSearchCannotStart() {
            if (hasMissingParams) {
                searchTreeRef.current?.open()
            }
        },
        [hasMissingParams]
    )

    useEffect(
        function scrollToTopOnListChanged() {
            articleListWrapperRef.current?.scrollToTop()
        },
        [startParams, showAvailable, productGroups, suppliers, attributes, fittingPosition]
    )

    return (
        <>
            <Route path="/:workTaskId/parts/:section/:view/:searchType?" component={ModuleHeader} />
            <ArticleListGrid>
                <SearchTree treeType="vehicleParts" ref={searchTreeRef} />
                {!hasMissingParams && (
                    <ArticleListWrapper ref={articleListWrapperRef}>
                        <VehiclePartsList />
                    </ArticleListWrapper>
                )}
            </ArticleListGrid>
        </>
    )
}

export function VehicleParts() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [VehiclePartsListParamsProvider],
        [VehiclePartsFiltersProvider],
        [VehiclePartsArticlesProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [ProductGroupTopicIdsProvider, { isEnabled: hasListRendered }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [VehicleRecordsProvider, { isEnabled: hasListRendered }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: true }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [VehiclePartsArticleListActionsProvider],
    ]

    return (
        <ContextComposer components={components}>
            <VehiclePartsComponent />
        </ContextComposer>
    )
}
