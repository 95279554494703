import { IBundle } from "@tm/morpheus"

import ExportButtonComp from "./components/export-button"
import Gateway from "./components/gateway"
import Overview from "./components/overview"
import StatusButton from "./components/status-button"
import ArticleInfo from "./micros/article-info"
import { initDmsBundle, version } from "./utils"
import { useDmsArticleInfo } from "./hooks/useDmsArticleInfo"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [ExportButtonComp, Gateway, Overview, StatusButton],
    micros: [ArticleInfo],
    init: initDmsBundle,
}

export default bundle

const ExportButton = ExportButtonComp.component

export { ExportButton, useDmsArticleInfo }
