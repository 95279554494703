import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { Article, channel, CrossSelling, UserModuleType } from "@tm/models"
import { useCallback, useMemo, useState } from "react"
import { getParameterFromUserContextModule, usePureArticleLists, useUser, useWorkTaskId } from "@tm/context-distribution"
import { Articles } from "@tm/data"
import { useQueryClient } from "react-query"
import { getCachedWorkTaskBasketData } from "../../../../../../../basket/src/data/hooks/workTaskBasket/queries/useWorkTaskBasketData"
import { CrossSellingArticleState, PartsViewSettingsState, RecommendedArticlesChainState } from "../../../states"
import { PureList } from "../../../../PureList/component"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { useVehicle } from "../.."
import { useListParamsContext } from "../../../ContextProvider"
import { createRecommendedArticlesRequest, createUniversalSearchRequestPayload, createVehiclePartsRequestPayload } from "."
import { RecommendedArticlesChain } from "../../../components/RecommendedArticles"
import { usePartsAlternativesModuleState } from "../../../PartsAlternatives/PartsAlternativesModuleState"
import { CrossSellingContext } from "../../../models"

export function useCrossSelling(article: Article): CrossSelling {
    const listId = "recommended-articles"
    const queryClient = useQueryClient()

    const vehicle = useVehicle()
    const workTaskId = useWorkTaskId()!
    const { userContext } = useUser()

    const { enableCrossSelling } = useArticleListConfiguration()
    const { listType } = useListParamsContext()
    const displayCrossSelling = listType === "vehicle" || listType === "universal"
    const partsViewSettings = useRecoilValue(PartsViewSettingsState)
    const quantitySuggestionEnabled = useMemo(() => partsViewSettings.quantitySuggestionEnabled, [partsViewSettings?.quantitySuggestionEnabled])

    const { reset, setRequest } = usePureArticleLists()
    const [referenceArticle, setReferenceArticle] = useRecoilState(CrossSellingArticleState)
    const setArticlesChain = useSetRecoilState(RecommendedArticlesChainState(workTaskId))

    const [isLoading, setIsLoading] = useState(false)
    const context = getParameterFromUserContextModule(userContext, UserModuleType.CrossSelling, "Context")
    const [displayButton, setDisplayButton] = useState(false)
    const isAlternativeSearch = usePartsAlternativesModuleState((x) => !!x)

    const isEnabled = useMemo(
        () => enableCrossSelling && displayCrossSelling && referenceArticle === article,
        [enableCrossSelling, referenceArticle, article.id]
    )

    const loadArticles = useCallback(
        async (newArticle: Article) => {
            // get the data directly async without mutating the state and do rerender hell
            const workTaskBasket = await getCachedWorkTaskBasketData(queryClient, workTaskId, true)

            const request = createRecommendedArticlesRequest(workTaskBasket?.parts, newArticle, vehicle)

            if (!request) {
                return undefined
            }

            const recommendedArticlesOut = await queryClient.fetchQuery({
                queryKey: [listId, request],
                queryFn: () => Articles.getRecommendedArticles(request),
            })

            if (!quantitySuggestionEnabled || !recommendedArticlesOut?.length) {
                return recommendedArticlesOut
            }

            return recommendedArticlesOut.map((recommendedArticle) => {
                if (!recommendedArticle.suggestedQuantity) {
                    return recommendedArticle
                }

                return {
                    ...recommendedArticle,
                    quantity: recommendedArticle.suggestedQuantity,
                    initialQuantity: recommendedArticle.suggestedQuantity,
                }
            })
        },
        [queryClient, vehicle, workTaskId, quantitySuggestionEnabled]
    )

    const loadRecommendedArticles = useCallback(async () => {
        if (!enableCrossSelling || isAlternativeSearch) {
            return
        }

        setReferenceArticle(undefined)

        const recommendedArticlesOut = await loadArticles(article)

        if (!recommendedArticlesOut?.length) {
            setArticlesChain(() => {
                localStorage.removeItem(`recommendedArticles/${workTaskId}`)
                return []
            })
        }

        switch (context) {
            case CrossSellingContext.AlsoBoughtArticle:
                if (recommendedArticlesOut && recommendedArticlesOut.length > 0) {
                    setDisplayButton(false)
                    setRequest(listId, { articles: recommendedArticlesOut })
                } else {
                    setDisplayButton(true)
                }
                break
            case CrossSellingContext.AlsoBoughtProductGroup:
                setDisplayButton(true)
                break
            case CrossSellingContext.Recommended:
                // TODO: Implement logic for recommended product groups when it will be clarified
                break
            default:
                break
        }

        setReferenceArticle(article)
    }, [enableCrossSelling, displayCrossSelling, vehicle, setReferenceArticle, loadArticles, setRequest, isAlternativeSearch])

    const loadNextRecommendedArticle = useCallback(
        async (newArticle: Article) => {
            if (!enableCrossSelling || isAlternativeSearch) {
                return
            }

            setIsLoading(true)

            const recommendedArticlesOut = await loadArticles(newArticle)

            if (!recommendedArticlesOut?.length) {
                setArticlesChain(() => {
                    localStorage.removeItem(`recommendedArticles/${workTaskId}`)
                    return []
                })
            }

            switch (context) {
                case CrossSellingContext.AlsoBoughtArticle:
                    if (recommendedArticlesOut && recommendedArticlesOut.length > 0) {
                        setDisplayButton(false)
                        setRequest(listId, { articles: recommendedArticlesOut })
                    } else {
                        setDisplayButton(true)
                    }
                    break
                case CrossSellingContext.AlsoBoughtProductGroup:
                    setDisplayButton(true)
                    break
                case CrossSellingContext.Recommended:
                    // TODO: Implement logic for recommended product groups when it will be clarified
                    break
                default:
                    break
            }

            setIsLoading(false)
        },
        [enableCrossSelling, displayCrossSelling, loadArticles, setRequest, vehicle, setIsLoading, isAlternativeSearch]
    )

    const onClose = useCallback(() => {
        setReferenceArticle(undefined)
        reset(listId)
    }, [reset, setReferenceArticle])

    const onProductGroupClick = useCallback(() => {
        if (listType === "vehicle") {
            return channel("WORKTASK").publish(
                "PARTS/REQUEST_LIST",
                createVehiclePartsRequestPayload(
                    vehicle?.tecDocTypeId!,
                    [referenceArticle?.productGroup.id!],
                    [referenceArticle?.supplier.manufacturerId!]
                )
            )
        }
        return channel("WORKTASK").publish(
            "PARTS/REQUEST_LIST",
            createUniversalSearchRequestPayload(referenceArticle?.productGroup.name!, [referenceArticle?.productGroup.id!])
        )
    }, [referenceArticle, listType, vehicle])

    return useMemo(
        () => ({
            isEnabled,
            isLoading,
            loadRecommendedArticles,
            loadNextRecommendedArticle,
            onClose,
            ListComponent: PureList,
            displayButton,
            productGroup: referenceArticle?.productGroup.name,
            onProductGroupClick,
            RecommendedArticlesChain,
        }),
        [
            isEnabled,
            isLoading,
            loadRecommendedArticles,
            loadNextRecommendedArticle,
            onClose,
            displayButton,
            onProductGroupClick,
            referenceArticle?.productGroup.name,
        ]
    )
}
