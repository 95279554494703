import { Icon, Badge, Box } from "@tm/components"
import { useState } from "react"
import { useQueryClient } from "react-query"
import NotificationsList from "./NotificationsList"
import { POLLING_QUERY_KEY, useNotificationPolling } from "../../data/hooks/useNotificationPolling"
import { setNotificationMessagesRead } from "../../data/setNotificationMessagesRead"

export default function NotificationPanel() {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const queryClient = useQueryClient()
    const { polledNotificationMessages, numberOfUnreadMessages } = useNotificationPolling()

    const openPopup = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopup = () => {
        setAnchorEl(null)
    }

    async function setNotificationRead(notificationMessageId: string) {
        await setNotificationMessagesRead([notificationMessageId])
        queryClient.invalidateQueries([POLLING_QUERY_KEY])
    }

    const unreadNotificationMessages = polledNotificationMessages !== undefined ? polledNotificationMessages.filter((item) => !item.isRead) : []

    return (
        <Box>
            <Box id="notification-center-tab" className="tab tab--worktask" onClick={openPopup}>
                <Badge badgeContent={numberOfUnreadMessages} color="error">
                    <Icon name="bell" />
                </Badge>
            </Box>
            <NotificationsList
                unreadNotificationMessages={unreadNotificationMessages}
                setNotificationMessageReadAndRemove={setNotificationRead}
                closePopup={closePopup}
                anchorEl={anchorEl}
            />
        </Box>
    )
}
