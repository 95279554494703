import { useEffect, useMemo, useRef } from "react"
import { Badge, Demo } from "@tm/controls"
import { ActivateModuleRef, ModuleGroupId, ModuleId, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, getDemoModuleByVehicleType, renderRoute } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Box, Button, Icon, styled } from "@tm/components"
import { Authority } from "@tm/data"
import { useHideDemoBanner } from "../../../hooks"
import { getMessage, getModuleOptionsAndPackages, getModuleTitle } from "../helpers"
import { ActivateModule } from "../../../micros/activate-module"

type Props = {
    activationRoute?: string
    moduleGroupId: ModuleGroupId
    moduleId: ModuleId
    moduleName: string
    vehicleType: VehicleType
}

export function DemoBanner({ moduleGroupId, moduleId, moduleName, vehicleType, activationRoute }: Props) {
    const { workTaskId } = useWorkTask() || {}
    const { translateText, languageId } = useLocalization()

    const { userContext } = useUser()
    const activatableModules = Authority.useGetActivatableModules(userContext)
    const { activatingModule } = Authority.useActivateModule()

    const activateRef = useRef<ActivateModuleRef>(null)
    const { handleHideBanner, hideBanner } = useHideDemoBanner(moduleGroupId, moduleId, vehicleType)

    const moduleInfo = useMemo(() => {
        const mainModule = getDemoModuleByVehicleType(activatableModules.response, moduleGroupId, moduleId, vehicleType)

        if (!mainModule) {
            return {}
        }

        const {
            defaultFullOptionModule,
            defaultDemoOptionModule,
            activeFullModulePackage,
            activeDemoModulePackage,
            activatableFullModulePackage,
            activatableDemoModulePackage,
            fullOptionModule,
            demoOptionModule,
            isFullPackageActive,
        } = getModuleOptionsAndPackages(mainModule)

        const shouldOpenComparePage =
            activationRoute &&
            ["dat", "eurotax", "tecrmi", "haynespro"].includes(moduleId) &&
            !defaultFullOptionModule?.active &&
            !defaultDemoOptionModule?.active

        return {
            mainModule,
            defaultFullOptionModule,
            defaultDemoOptionModule,
            activeFullModulePackage,
            activeDemoModulePackage,
            activatableFullModulePackage,
            activatableDemoModulePackage,
            fullOptionModule,
            demoOptionModule,
            isFullPackageActive,
            shouldOpenComparePage,
        }
    }, [activatableModules.response, activationRoute, moduleGroupId, moduleId, vehicleType])

    const { mainModule, isFullPackageActive, shouldOpenComparePage, demoOptionModule } = moduleInfo

    useEffect(() => {
        if (!mainModule || !shouldOpenComparePage) {
            return
        }

        Morpheus.showView("1", renderRoute(activationRoute!, {}), `/${workTaskId ? encodeUniqueId(workTaskId) : ""}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldOpenComparePage, mainModule])

    if (!mainModule || isFullPackageActive || shouldOpenComparePage || (!demoOptionModule?.active && hideBanner)) {
        return null
    }

    const {
        defaultFullOptionModule,
        defaultDemoOptionModule,
        activeFullModulePackage,
        activeDemoModulePackage,
        activatableFullModulePackage,
        activatableDemoModulePackage,
        fullOptionModule,
    } = moduleInfo

    const moduleTitle = getModuleTitle(mainModule, moduleId, moduleName, moduleGroupId, vehicleType, translateText)

    const message = getMessage(
        moduleTitle,
        mainModule?.name,
        defaultFullOptionModule,
        defaultDemoOptionModule,
        activeFullModulePackage,
        activeDemoModulePackage,
        activatableFullModulePackage,
        activatableDemoModulePackage,
        translateText
    )

    return (
        <>
            <StyledDemoAlert>
                <Icon color="primary" height="1.5em" name="information-light" sx={{ marginRight: "0.5em" }} />

                <Box flexGrow="1">{message}</Box>

                <Box display="flex" flex="0">
                    <StyledDemoButton
                        disabled={activatingModule || (demoOptionModule?.activatable ? demoOptionModule?.active : true)}
                        onClick={() =>
                            activateRef.current?.handleActivateModule(moduleGroupId, mainModule, demoOptionModule, activatableDemoModulePackage)
                        }
                    >
                        {translateText(12567)}
                        <Demo displayMode="edge" />
                    </StyledDemoButton>
                    <StyledDemoButton
                        color="highlight"
                        variant="contained"
                        disabled={activatingModule || (fullOptionModule?.activatable ? fullOptionModule?.active : true)}
                        onClick={() =>
                            activateRef.current?.handleActivateModule(moduleGroupId, mainModule, fullOptionModule, activatableFullModulePackage)
                        }
                    >
                        {translateText(12568)}
                    </StyledDemoButton>
                    <StyledMoreInfoBtn
                        target="_blank"
                        disabled={!mainModule.longDescriptionUrl}
                        href={mainModule.longDescriptionUrl?.replace("{0}", languageId)}
                    >
                        {translateText(12597)}
                    </StyledMoreInfoBtn>
                    {mainModule.explanationVideoUrl && (
                        <StyledMoreInfoBtn href={mainModule.explanationVideoUrl} target="_blank" startIcon={<Icon name="video" />}>
                            {translateText(12841)}
                        </StyledMoreInfoBtn>
                    )}
                </Box>

                {!demoOptionModule?.active && (
                    <Button
                        sx={{ alignSelf: "center", color: "#202020" }}
                        variant="text"
                        startIcon={<Icon name="close" />}
                        onClick={handleHideBanner}
                    />
                )}
            </StyledDemoAlert>
            <Badge />
            <ActivateModule ref={activateRef} />
        </>
    )
}

const StyledDemoAlert = styled(Box)(({ theme }) => ({
    width: "100%",
    flex: "0 0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "flex-start",
    background: theme.colors?.light ?? "#fff",
    border: `1px solid ${theme.colors?.primary ?? ""}`,
    marginBottom: 0,
    padding: "0.25em 0.8em 0.25em 0.9em",
    gap: "0.5em",
}))

const StyledDemoButton = styled(Button)({
    margin: "0 .25rem",
    "& svg": {
        position: "absolute",
        top: "0",
        right: "0",
        opacity: 1,
        margin: 0,
    },
})

const StyledMoreInfoBtn = styled(Button)({
    margin: "0 .25rem",
    display: "flex",
    justifyContent: "center",
})
