import { UseQueryOptions, useQueries, useQueryClient } from "react-query"
import { Item, TeccomErpInformation } from "@tm/models"
import { useCallback, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Mutex } from "async-mutex"
import { mergeWarehouses } from "@bundles/erp"
import { useErpConfigLoadable } from "@tm/utils"
import * as Data from "../../.."
import { BasketErpInformationResponseItem, GetErpInformationBasketRequest, GetErpInformationBasketResponse } from "../../../model"
import { BasketErpInfo, GetErpInformationBasketRequestPage } from "../../../../models"
import { useBasketErpMutations } from "../mutations/useBasketErpMutations"
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"

const erpMutex = new Mutex()
export const BASKET_ERP_KEY = "basket_useErpInfos"
export const BASKET_ERP_CACHING_TIME = 2 * 60 * 1000 // 2 Minutes

function loadErpInformation(request: GetErpInformationBasketRequestPage) {
    return erpMutex.runExclusive(() => {
        return Data.getBasketErpInformation(request)
    })
}

export function useErpInfosBasketData(workTaskId: string, requests: GetErpInformationBasketRequest[]) {
    const queryClient = useQueryClient()
    const { translateText } = useLocalization()
    const basketErpInfoIndex = useWorkTaskBasketStore((store) => store.getBasketErpInfoIndex(workTaskId))

    const { getErpInformationForPart } = useBasketErpMutations(workTaskId)
    const { erpSystemConfigs } = useErpConfigLoadable() ?? {}
    const [erpInfoBeingLoadedPartIds, setErpInfoBeingLoadedPartIds] = useState<string[]>([])

    function getQueryOptions(request: GetErpInformationBasketRequestPage): UseQueryOptions<GetErpInformationBasketResponse | undefined> {
        return {
            queryKey: [BASKET_ERP_KEY, workTaskId, request.distributorId, request.pageIndex, basketErpInfoIndex],
            queryFn: () => loadErpInformation(request),
            enabled: !!request.items.length, // The dependency on isErpInfoEnabled has been removed because for Bosch(WomPlus) the erp infos should be requested for External parts eventhough there is no ERP system configured in MDM
            staleTime: BASKET_ERP_CACHING_TIME,
        }
    }

    const pagedRequests = useMemo(() => {
        const requestPages: GetErpInformationBasketRequestPage[] = []

        requests.forEach((request) => {
            const erpSystemConfig = request.distributorId ? erpSystemConfigs?.find((x) => x.id === request.distributorId) : erpSystemConfigs?.first()
            const pageSize = erpSystemConfig?.erpRequestArticleCount

            if (pageSize) {
                let pageIndex = 0
                for (let i = 0; i <= request.items.length; i += pageSize) {
                    const pagedItems = request.items.slice(i, i + pageSize)

                    if (pagedItems.length) {
                        requestPages.push({
                            ...request,
                            items: pagedItems,
                            pageIndex,
                        })
                        pageIndex++
                    }
                }
            } else {
                requestPages.push(request)
            }
        })

        return requestPages
    }, [requests, erpSystemConfigs])

    const updateShipmentModeForPart = useCallback(
        (partId: string, selectedOptionsItem: Item, shipmentModeId: string) => {
            const request = pagedRequests.find((req) => req.items.some((item) => item.itemId === partId))
            if (!request) {
                return
            }

            queryClient.setQueryData<GetErpInformationBasketResponse | undefined>(
                [BASKET_ERP_KEY, workTaskId, request.distributorId, request.pageIndex, basketErpInfoIndex],
                (prev) => {
                    if (!prev) {
                        return
                    }

                    return {
                        ...prev,
                        items: prev.items?.map<BasketErpInformationResponseItem>((item) => {
                            if (item.itemId !== partId || !item.orderOptions?.selectedOptionsItem || !item.orderOptions.shipmentModes) {
                                return item
                            }

                            return {
                                ...item,
                                orderOptions: {
                                    ...item.orderOptions,
                                    selectedOptionsItem,
                                    shipmentModes: {
                                        ...item.orderOptions.shipmentModes,
                                        shipmentModes: item.orderOptions.shipmentModes.shipmentModes.map((mode) => {
                                            return {
                                                ...mode,
                                                isSelected: mode.id === shipmentModeId,
                                            }
                                        }),
                                    },
                                },
                            }
                        }),
                    }
                }
            )
        },
        [pagedRequests, queryClient, workTaskId, basketErpInfoIndex]
    )

    const updatePartErpInfo = useCallback(
        async (partId: string, quantityValue?: number) => {
            const matchedRequest = pagedRequests.find((req) => req.items.some((item) => item.itemId === partId))
            if (!matchedRequest) {
                return
            }
            const request = {
                ...matchedRequest,
                items: matchedRequest.items
                    .filter((item) => item.itemId === partId)
                    .map((item) => {
                        if (quantityValue) {
                            return { ...item, quantityValue }
                        }
                        return item
                    }),
            }

            setErpInfoBeingLoadedPartIds((prev) => [...prev, partId])
            await getErpInformationForPart({ partId, request }).finally(() =>
                setErpInfoBeingLoadedPartIds((prev) => prev.filter((id) => id !== partId))
            )
        },
        [pagedRequests, getErpInformationForPart]
    )

    const mergeTecComInfo = useCallback(
        (partId: string, teccom: TeccomErpInformation) => {
            const request = pagedRequests.find((req) => req.items.some((item) => item.itemId === partId))
            if (!request) {
                return
            }

            queryClient.setQueryData<GetErpInformationBasketResponse | undefined>(
                [BASKET_ERP_KEY, workTaskId, request.distributorId, request.pageIndex, basketErpInfoIndex],
                (prev) => {
                    if (!prev) {
                        return
                    }

                    return {
                        ...prev,
                        items: prev.items?.map<BasketErpInformationResponseItem>((item) => {
                            if (item.itemId !== partId) {
                                return item
                            }
                            return {
                                ...item,
                                warehouses: mergeWarehouses(translateText, item.warehouses, teccom),
                                availability: teccom.specialProcurementErpInformation?.availability || item.availability,
                                orderOptions: teccom.specialProcurementErpInformation?.orderOptions || item.orderOptions,
                            }
                        }),
                    }
                }
            )
        },
        [pagedRequests, queryClient, workTaskId, basketErpInfoIndex, translateText]
    )

    const results = useQueries(pagedRequests.map((request) => getQueryOptions(request)))

    return {
        basketErpInformation: results.map<BasketErpInfo>((result, index) => ({
            erpInfos: result.data,
            dataUpdatedAt: result.dataUpdatedAt,
            erpInfosLoading: result.isLoading,
            erpInfosReloading: result.isRefetching,
            request: pagedRequests[index],
            erpInfosWithError: result.isError || !!result.data?.hasErrors,
        })),
        erpInfoBeingLoadedPartIds,
        mergeTecComInfo,
        updateShipmentModeForPart,
        updatePartErpInfo,
    }
}
