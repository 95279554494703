import { RimItem } from "../../model"

export function mapRimItems(items?: any[]): RimItem[] {
    if (!items) {
        return []
    }

    return items.map((rimItem: RimItem) => {
        const sizeInfo = rimItem.sizeInfo.last()
        return {
            ...rimItem,
            selectedSize: sizeInfo?.size,
            rimFrameId: sizeInfo?.rimFrameId,
        }
    })
}
