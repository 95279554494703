import { BundleActions, BundleActionTypes } from "../../../data/business"
import { DecodedMatchParams, NavigationSteps } from "../../../data/models"
import { MainActionsType } from "../../main/business"
import { NavigationState } from "./model"

export * from "./model"

export type ComponentActionType = BundleActionTypes | { type: "INIT"; payload: { matchParams: DecodedMatchParams; tyresV2?: boolean } }

const DEFAULT_STATE: NavigationState = {
    params: {},
}

export function reduce(state = DEFAULT_STATE, action: MainActionsType): NavigationState {
    switch (action.type) {
        case "CHANGE_STEP": {
            const { params, selectedStep } = action.payload
            return {
                ...state,
                active: selectedStep,

                params: {
                    ...state.params,
                    ...params,
                },
            }
        }
        case "INIT": {
            const { view, ...rest } = action.payload.matchParams
            return {
                ...state,
                initialized: true,
                active: view,
                params: {
                    ...DEFAULT_STATE.params,
                    ...rest,
                },
            }
        }
        default:
            return state
    }
}

const initNav = (matchParams: DecodedMatchParams, tyresV2?: boolean): ComponentActionType => ({ type: "INIT", payload: { matchParams, tyresV2 } })

const changeStep = (selectedStep: NavigationSteps, params?: DecodedMatchParams): ComponentActionType => ({
    type: "CHANGE_STEP",
    payload: { selectedStep, params },
})

export const Actions = {
    ...BundleActions,
    initNav,
    changeStep,
}
