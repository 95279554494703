import { Button, Box } from "@tm/components"
import { Icon } from "@tm/controls"
import { Memo_V1 } from "@tm/models"

type Props = {
    item: Memo_V1
}

function PDFItem(props: Props) {
    const { item } = props

    return (
        <Box sx={{ display: "flex", flex: 1, alignItems: "center", margin: (theme) => theme.spacing(1, 0) }} key={item.id}>
            <Button startIcon={<Icon name="file-pdf" />} href={`http:${item.code_Path}`} target="_blank">
                {item.txt}
            </Button>
        </Box>
    )
}

export default PDFItem
