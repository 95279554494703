import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { Scrollbar, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box, Button } from "@tm/components"
import NextStep from "../_shared/nextStep"
import { CustomModal, VehicleAndCustomer } from "../_shared"
import { getComponentStyles } from "../_shared/styles"
import { stepNavigationActions, testerActions, useFastServiceStore } from "../../data"
import { StepNames } from "../../data/models"

const currentStepName = StepNames.TestComplete

const TestComplete: FC = () => {
    const { translateText } = useLocalization()
    const [showModal, setShowModal] = useState(false)

    const { firstName, lastName, testerSignature, comment, inputsLocked } = useFastServiceStore((state) => ({
        firstName: state.tester.firstName,
        lastName: state.tester.lastName,
        testerSignature: state.tester.signature,
        comment: state.tester.comment,
        inputsLocked: state.inputsLocked
    }))

    const classNames = getComponentStyles()
    const authenthicationComplete = useMemo(() => !!testerSignature, [testerSignature])

    useEffect(() => {
        if (authenthicationComplete) {
            stepNavigationActions.updateNextStep(currentStepName)
            stepNavigationActions.completeStep(currentStepName)
        }
    }, [authenthicationComplete])

    const handleSignClick = () => {
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const saveTesterSignature = useCallback((signature: string) => {
        testerActions.setSignature(signature)
        setShowModal(false)
    }, [])

    const saveComment = useCallback((newComment: string) => {
        testerActions.setComment(newComment)
    }, [])

    return (
        <>
            <Scrollbar>
                <div className={classes(style.wrapper, classNames.wrapper, classNames.wrapperMinHeight)}>
                    <VehicleAndCustomer headerText={translateText(12537)} showServiceText />
                    <TextField size="xl" className={style.service} placeholder={translateText(1700)} value={comment} onChangeConfirm={saveComment} disabled={inputsLocked} />

                    <Box sx={{ padding: "5% 20% 0 20%", display: "flex", justifyContent: "center" }}>
                        <Text size="m" modifiers="strong">
                            {translateText(12973)}
                        </Text>
                        <Text size="m" modifiers="sub">
                            {translateText(12969)}
                        </Text>
                    </Box>
                    <Box sx={{ marginTop: "2em", display: "flex", justifyContent: "center" }}>
                        <Button size="extralarge" color="highlight" className={classNames.signButton} onClick={handleSignClick}>
                            {translateText(12967)}
                        </Button>
                    </Box>
                </div>

                <NextStep currentStepName={currentStepName} buttonTextId={12942} icon="arrows_down" active={authenthicationComplete} />
            </Scrollbar>
            <CustomModal
                open={showModal}
                headLineText={translateText(12974)}
                headerText={`${firstName} ${lastName}`}
                onClose={handleCloseModal}
                onSave={saveTesterSignature}
                savedSignature={testerSignature}
            />
        </>
    )
}

export default TestComplete

const style = useStyle({
    wrapper: {
        padding: "0 1em 1em",
    },
    service: {
        marginTop: "0.5em",
    },
})(TestComplete)
