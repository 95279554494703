import { ajax, getStoredAuthorization } from "@tm/utils"
import { ImagePositionsRequest, ImagePositionsResponse } from "."
import { getBundleParams } from "../../../utils"
import { mapImagePositionResponse } from "./mapper"

export * from "./model"

export function getImagePositions(request?: ImagePositionsRequest) {
    const url = `${getServiceUrl()}/GetImagePositions`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<ImagePositionsResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => {
            if (response?.vknImgPositions?.positions?.length) {
                resolve(mapImagePositionResponse(response.vknImgPositions))
            } else {
                reject()
            }
        }, reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.eurotaxImagesUrl
}
