import { Button } from "@tm/controls"
import { useMemo } from "react"
import { createTheme, styled } from "@tm/components"
import type { ActiveButtons } from "../../../data/models"

type Props = {
    isActive: boolean
    onClick(e: React.MouseEvent<HTMLDivElement>, buttonType: ActiveButtons): void
    readOnly?: boolean
    buttonType: ActiveButtons
}

const BigButton = styled(Button)(({ theme }) => ({
    padding: ".2em",
    marginLeft: ".5em",
    "&& .icon.btn__icon": {
        width: "2.5rem",
        height: "2.5rem",
    },
    "&.is-active svg": {
        fill: `${theme.colors?.primary}!important`,
    },
}))

function DefectButton({ isActive, onClick, readOnly, buttonType }: Props) {
    const theme = createTheme()
    const icon = useMemo(() => getIconName(isActive, buttonType), [isActive, buttonType])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (readOnly) {
            return
        }
        onClick(e, buttonType)
    }

    return <BigButton onClick={handleClick} isActive={isActive} size="xl" layout={["holo"]} icon={icon} />
}

export default DefectButton

const getIconName = (isActive: boolean, iconType: ActiveButtons): string => {
    switch (iconType) {
        case "defectButton":
            return isActive ? "defect-filled" : "defect-bordered"
        case "repairedButton":
            return isActive ? "repaired_filled" : "repaired-bordered"
        case "checkButton":
            return isActive ? "checked-filled" : "check-bordered"
        default:
            return ""
    }
}
