import { Widget } from "@tm/controls"
import { styled, Button, Icon, Box } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { EFilterNames, channel } from "@tm/models"
import { useMultiWidgetState, ModulePropsMultiWidget } from "@tm/utils"
import { useTyresMatchCodeSearchType } from "@tm/context-distribution"
import { Statics } from "../../data/statics"
import { SpecialCarTypes } from "../../data/enums"
import { useWidgetFilters } from "../../hooks/widgetFilters"
import { MatchCodeSearch } from "../_shared/matchCodeSearch"
import { CarTypeSeasonFilters } from "../_shared/carTypeSeasonFilters"
import { DiameterFilters } from "../_shared/DiameterFilters"

type Props = {
    showComponentKey?: string
    moduleProps?: ModulePropsMultiWidget
}

function TyresSearchWidget() {
    const { translateText } = useLocalization()

    const {
        state: { loading, filters, selectedFilters, selectedFiltersDefaultVehicle },
        handlers: { handleFilterChange, handleResetAll, handleNormalSearch },
    } = useWidgetFilters()

    const showMatchCodeSearch = useTyresMatchCodeSearchType(true)

    function handleMatchCodeSearch(size: string) {
        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { size })
    }

    return (
        <Widget size="6x3" iconName="tire" title={translateText(240)} active className="tyres-search-widget">
            <Content>
                {showMatchCodeSearch && (
                    <>
                        <MatchCodeSearch value="" withTitle onButtonClick={handleMatchCodeSearch} />
                        <Separator />
                    </>
                )}
                <Box className="subtitle">{translateText(1562)}</Box>
                <Separator />
                <CarTypeSeasonFilters
                    filterName="carType"
                    disabled={loading}
                    items={Statics.getCarTypes()}
                    selectedItemValue={selectedFiltersDefaultVehicle ?? selectedFilters?.carType}
                    onChange={handleFilterChange}
                />
                <Separator />
                <CarTypeSeasonFilters
                    filterName={EFilterNames.season}
                    disabled={loading || selectedFilters.carType.every((x) => SpecialCarTypes.includes(+x.value))}
                    items={Statics.seasons}
                    selectedItemValue={selectedFilters.season}
                    onChange={handleFilterChange}
                />
                <Separator />
                <MainFilters loading={loading}>
                    <DiameterFilters
                        selectedHeight={selectedFilters.height}
                        selectedWidth={selectedFilters.width}
                        selectedInch={selectedFilters.inch}
                        widths={filters.width}
                        heights={filters.height}
                        inches={filters.inch}
                        onChange={handleFilterChange}
                        onResetAll={handleResetAll}
                        loading={loading}
                        maxItemsToShow={6}
                    />
                    <Separator />
                    <SearchButton
                        color="highlight"
                        startIcon={<Icon name="search" />}
                        onClick={handleNormalSearch}
                        disabled={!selectedFilters.width || !selectedFilters.inch}
                    >
                        {translateText(135)}
                    </SearchButton>
                </MainFilters>
            </Content>
        </Widget>
    )
}

const Content = styled(Box)({
    paddingLeft: "12em",
    backgroundImage: "url('/styles/base/images/bitmap-copy.png')",
    backgroundPositionY: "2em",
    backgroundRepeat: "no-repeat",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
})

const MainFilters = styled(Box, {
    shouldForwardProp: (prop) => prop !== "loading",
})<{ loading?: boolean }>(({ theme, loading }) => ({
    display: "flex",
    flexDirection: "column",
    ...(loading && {
        position: "relative",
        opacity: theme.opacity?.disabled,
        pointerEvents: "none",
        ".popover": {
            position: "fixed",
        },
    }),
}))

const Separator = styled("hr")({
    border: 0,
    height: 0,
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
    marginLeft: 0,
    marginRight: 0,
})

const SearchButton = styled(Button)({
    margin: "0 0 0 auto",
    display: "flex",
    justifyContent: "flex-end",
})

export default function TyresSearchWidgetWrapper({ showComponentKey, moduleProps }: Props) {
    const [selectedMultiWidgetTab] = useMultiWidgetState(moduleProps)

    if (!showComponentKey || selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    return <TyresSearchWidget />
}
