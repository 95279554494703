import { Box, styled } from "@tm/components"
import { RepairShop } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { concat } from "@tm/utils"
import { rem } from "csx"
import { FC } from "react"
import { DictionaryItem } from "../../../../model"
import Dictionary from "../../dictionary"

type Props = {
    repairShop: RepairShop
}

const VehicleDetails: FC<Props> = ({ repairShop }) => {
    const { translateText } = useLocalization()

    const addressArray = (repairShop.postalAddress && Object.values(repairShop.postalAddress)) || []
    const contactPersonName = (repairShop.contactPerson && Object.values(repairShop.contactPerson)) || []

    const items: Array<DictionaryItem> = [
        { key: translateText(762), value: concat(", ", ...addressArray) || translateText(1779) },
        { key: translateText(110), value: repairShop.contactInfo?.phone || translateText(1779) },
        { key: translateText(109), value: repairShop.contactInfo?.email || translateText(1779) },
        { key: translateText(950), value: concat(" ", ...contactPersonName) || translateText(1779) },
    ]

    return (
        <StyledWrapper>
            <PanelSection>
                <Dictionary items={items} />
            </PanelSection>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(0, rem(0.5)),
}))

export default VehicleDetails
