import { useStyle } from "@tm/context-distribution"
import { Button, Icon, Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { em } from "csx"
import { useSelector } from "react-redux"
import { FC } from "react"
import { MainState } from "../../main"

type Props = {
    onButtonClick(): void
}

const FirstPart: FC<Props> = ({ onButtonClick }) => {
    const { translateText } = useLocalization()
    const { statusDataLoading } = useSelector((s: MainState) => s.manager)

    const handleOnclick = () => {
        onButtonClick()
    }

    return (
        <div className={style.firstView}>
            <div className={style.loadWrapper}>
                <Icon name="car" />
                <Loader className={style.marginIcon} visible={!!statusDataLoading} type="connection" />
                <Icon name="telematics" />
            </div>
            {statusDataLoading ? (
                <div className={style.textPadding}>{translateText(977)}</div>
            ) : (
                <div className={style.textPadding}>{translateText(978)}</div>
            )}
            <Button disabled={statusDataLoading} className={style.buttonPadding} skin="primary" onClick={handleOnclick}>
                {translateText(979)}
            </Button>
            <div className={style.textPadding}>
                {translateText(489).replace("%0", "1 EUR")}
                <Icon className={style.marginIcon} name="info" size="xs" />
            </div>
        </div>
    )
}

export default FirstPart

const style = useStyle({
    loadWrapper: {
        display: "flex",
        alignItems: "center",
        margin: "0.5em 0 1.5em 0",
    },
    firstView: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
    },
    textPadding: {
        padding: em(0.5),
    },
    marginIcon: {
        marginLeft: em(0.3),
    },
    buttonPadding: {
        padding: em(1),
    },
})(FirstPart)
