import { Box, Button, Icon, IconButton, Popover, SxProps, Theme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { debounce, equals } from "@tm/utils"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useAddArticle, useCompilations, useCompilationsContains, useCreateCompilation, useDeleteArticles } from "../../data/hooks/useCompilations"
import { ArticleInterface } from "../../data/interfaces/compilation-article-interface"
import { getBundleParams, isWM } from "../../utils"
import CreateCompilation from "./components/create-compilation"
import { CompilationItemDefault } from "./components/default/compilation-item"
import CompilationItemWm from "./components/wm/compilation-item-wm"

export type AddingState = "ADDING" | "ERROR" | "ADDED"

type Props = {
    articles: ArticleInterface[]
    disabled?: boolean
    highlightListsIfItContainsArticle?: boolean
    enableRemoveFromList?: boolean
    variant?: "small" | "normal"
    createOrAddSingleWatchlist?(): void
}

const highlightedIconStyle: SxProps<Theme> = (theme) => ({
    width: "111px",
    height: "32px",
    borderRadius: "3px",
    color: theme.colors?.light,
    backgroundColor: theme.colors?.highlight,
    "&:hover": {
        backgroundColor: `${theme.colors?.["highlight-hover"]} !important`,
    },
})

export const AddToCompilation = memo<Props>(
    ({ articles, disabled, highlightListsIfItContainsArticle, enableRemoveFromList, variant, createOrAddSingleWatchlist }) => {
        const { containedArticles, loading: containedArticlesLoading } = useCompilationsContains(articles)
        const { translateText } = useLocalization()
        const { compilations } = useCompilations()
        const { createCompilation } = useCreateCompilation()
        const { compilationsIcon, disableWidgetButtonActiveState } = getBundleParams()
        const { addArticle, isLoading: addArticleLoading } = useAddArticle()
        const { deleteArticles, isLoading: deleteArticleLoading } = useDeleteArticles()

        const [addingState, setAddingState] = useState<Record<string, AddingState>>({})

        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

        const open = Boolean(anchorEl)

        const activeCompilations = useMemo((): string[] => {
            return !containedArticlesLoading && highlightListsIfItContainsArticle && containedArticles
                ? containedArticles.map((article) => article.compilationId)
                : []
        }, [highlightListsIfItContainsArticle, containedArticles, containedArticlesLoading])

        // delete items from state if we handle it over our api. needed change for WM -> we should split WM and our implementation
        useEffect(() => {
            if (!highlightListsIfItContainsArticle) {
                return
            }

            const newState = { ...addingState }
            containedArticles?.forEach((article) => {
                if (Object.keys(addingState).includes(article.compilationId)) {
                    delete newState[article.compilationId]
                }
            })

            if (!equals(addingState, newState)) {
                setAddingState(newState)
            }
        }, [activeCompilations, addingState, highlightListsIfItContainsArticle])

        const includedInCompilationList = useMemo((): boolean => {
            return !!(compilations && compilations.find(({ id }) => addingState[id])) || (containedArticles && containedArticles.length > 0) || false
        }, [compilations, addingState, containedArticles, highlightListsIfItContainsArticle])

        const handleCreateCompilation = (name: string) => {
            createCompilation({ name, description: "", isPublic: true }).then((compilationId) => {
                handleClickItem(compilationId)
            })
        }

        const handleClickItem = (compilationId: string, articleId?: string) => {
            if (addingState[compilationId] === "ADDED" && !enableRemoveFromList) {
                return
            }
            if (!articles || addArticleLoading || deleteArticleLoading || containedArticlesLoading) {
                return
            }

            if (includedInCompilationList && articleId && enableRemoveFromList) {
                setAddingState((prev) => ({ ...prev, [compilationId]: "ADDING" }))
                deleteArticles({ compilationId, articleIds: [articleId] }).then(() => {
                    const newState = { ...addingState }
                    delete newState[compilationId]
                    setAddingState(newState)
                })
            } else {
                setAddingState((prev) => ({ ...prev, [compilationId]: "ADDING" }))
                addArticle({
                    compilationId,
                    articles,
                }).then(
                    () => setAddingState((prev) => ({ ...prev, [compilationId]: "ADDED" })),
                    () => setAddingState((prev) => ({ ...prev, [compilationId]: "ERROR" }))
                )
            }
        }

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(anchorEl ? null : event.currentTarget)
        }

        const debouncedHandleClick = useCallback(debounce(handleClickItem, 100), [handleClickItem])

        const renderCompilationList = () => {
            if (articles && open && compilations) {
                return compilations.map((compilation) => {
                    return isWM() ? (
                        <CompilationItemWm
                            key={compilation.id}
                            compilation={compilation}
                            state={addingState[compilation.id]}
                            onClick={debouncedHandleClick}
                        />
                    ) : (
                        <CompilationItemDefault
                            key={compilation.id}
                            compilation={compilation}
                            state={addingState[compilation.id]}
                            articleId={containedArticles?.find((list) => list.compilationId === compilation.id)?.id}
                            containedInList={
                                highlightListsIfItContainsArticle
                                    ? containedArticles?.findIndex((list) => list.compilationId === compilation.id) !== -1
                                    : false
                            }
                            onClick={debouncedHandleClick}
                        />
                    )
                })
            }

            return null
        }

        const isActive = includedInCompilationList && highlightListsIfItContainsArticle && !disableWidgetButtonActiveState
        const iconButton = useMemo((): string => {
            if (compilationsIcon === "wm") {
                return "wishlist"
            }

            return isActive ? "filled-star" : "vote-star"
        }, [compilationsIcon, isActive])

        const width = useMemo(() => {
            if (variant !== "small" && compilationsIcon === "wm") {
                return "6.25em"
            }
        }, [variant, compilationsIcon])

        return (
            <div className="bdl-compilations add-to-compilation">
                <Box width={width}>
                    {createOrAddSingleWatchlist ? (
                        <IconButton onClick={createOrAddSingleWatchlist} sx={highlightedIconStyle}>
                            <Icon name={iconButton} />
                        </IconButton>
                    ) : (
                        <Button
                            disabled={disabled}
                            fullWidth
                            startIcon={<Icon name={iconButton} />}
                            title={translateText(1330)}
                            variant={compilationsIcon === "wm" ? undefined : "text"}
                            onClick={handleClick}
                        />
                    )}
                    <Popover
                        sx={(theme) => ({
                            maxHeight: "600px",
                            "& .MuiPaper-root": {
                                border: `1px solid ${theme.palette.primary.main}` || "#000",
                            },
                        })}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                    >
                        <div>
                            <div className="compilations__list">{renderCompilationList()}</div>
                            <CreateCompilation onSave={handleCreateCompilation} />
                        </div>
                    </Popover>
                </Box>
            </div>
        )
    }
)
