import { Dialog, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useRef, useEffect } from "react"

type Props = {
    onClose(noResult?: boolean): void
}

export default function NoResultDialog(props: Props) {
    const { translateText } = useLocalization()
    const dialogRef = useRef<Dialog>(null)

    useEffect(() => {
        dialogRef.current?.show()
    }, [])

    const { onClose } = props
    return (
        <Dialog ref={dialogRef} onClose={() => onClose(true)} text={translateText(325)} skin="danger" iconName="not">
            <Text modifiers="block">{translateText(1405)}</Text>
            <Text modifiers="block">{translateText(12632)}</Text>
        </Dialog>
    )
}
