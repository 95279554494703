import { Box, Typography } from "@tm/components"
import { FC } from "react"
import { AttrNodeV2 } from "../../models"
import OverflowTypography from "./OverflowTypography"

type Props = {
    code: string
    attributes: AttrNodeV2[]
}

const UnitDetails: FC<Props> = (props) => {
    const { code, attributes } = props

    const notes = attributes
        ?.find((attribute) => attribute.code === "note")
        ?.values.reduce((acc, attribute) => {
            return acc + attribute
        }, "")
        .replace(";", "; ")

    return (
        <Box>
            <Typography fontWeight="bold">{code}</Typography>
            <OverflowTypography align="left" title={notes || ""} content={notes || ""} fontSize="12px" maxLines={3} />
        </Box>
    )
}

export default UnitDetails
