import { HourlyRate, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CarModelDetailsResponse, channel, RegisteredModels, Vehicle, VehicleType } from "@tm/models"
import { BundleComponent, PayloadAction, useActions } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { getLanguageIdFromLocalStorage, getStoredAuthorization, parseQueryString, uniqueId } from "@tm/utils"
import { Component, ComponentType, createElement, FC, useEffect } from "react"
import { Provider } from "react-redux"
import { Store } from "redux"

function transmit(action: PayloadAction<any>) {
    switch (action.type) {
        case "VEHICLE_SET": {
            return action
        }
    }
}

function setVehicle(vehicle: Vehicle) {
    return { type: "VEHICLE_SET", payload: vehicle, broadcast: true }
}

const Actions = { setVehicle }

const getQueryparams = (search: string) => {
    const { ktype, languageId, hourlyRate, currencyCode } = parseQueryString(search) as Record<string, string>
    return { ktype: +ktype, languageId, currencyCode, hourlyRate: +hourlyRate }
}

const StandaloneStatupComponent: FC = () => {
    const user = useUser()
    const { changeLocale } = useLocalization()
    const actions = useActions(Actions)

    useEffect(() => {
        const { search } = window.location
        const { ktype, languageId, hourlyRate, currencyCode } = getQueryparams(search)
        const activeLanguage = getLanguageIdFromLocalStorage()

        if (hourlyRate && currencyCode && hourlyRate != user?.userSettings?.hourlyRates?.find((x) => !x.category)?.hourlyRate) {
            user?.changeHourlyRates([{ hourlyRate } as HourlyRate], currencyCode)
        }

        channel("GLOBAL").publish("WORKTASK/ID_CHANGED", { id: "FAKE_WORKTASK" })

        if (languageId && languageId != activeLanguage) {
            changeLocale(languageId)
        }

        if (getStoredAuthorization() && ktype) {
            Container.getInstance(RegisteredModels.Vehicle_ModelDetails)
                .subscribe({ modelId: ktype })
                .load()
                .then((response) => {
                    const vehicle = mapVehicleModelDetails(response as CarModelDetailsResponse)
                    actions.setVehicle(vehicle)
                    channel("WORKTASK", "FAKE_WORKTASK").publish("VEHICLE/LOADED", vehicle)
                })
        }
    }, [])

    return null
}

const StandaloneStatup: BundleComponent = {
    name: "standalone-startup",
    transmit,
    reduce: (_ = {}) => _,
    component: withStoreProvider(StandaloneStatupComponent),
}

export function mapVehicleModelDetails(vehicleModel: CarModelDetailsResponse): Vehicle {
    const { modelDetails, modelSeries, manufacturer } = vehicleModel

    const vehicle: Vehicle = {
        id: uniqueId(),
        tecDocTypeId: (modelDetails && modelDetails.id) || 0,
        tecDocManufacturerId: (modelDetails && modelDetails.manufacturerId) || 0,
        tecDocModelId: (modelDetails && modelDetails.modelSeriesId) || 0,
        manufacturer: (manufacturer && manufacturer.description) || "",
        model: (modelDetails && modelDetails.description) || "",
        modelSeries: (modelSeries && modelSeries.description) || "",
        manufacturerThumbnail: (modelDetails && modelDetails.manufacturerThumbnail) || "",
        modelSeriesThumbnail: (modelDetails && modelDetails.modelSeriesThumbnail) || "",
        modelThumbnail: (modelDetails && modelDetails.thumbnail) || "",
        vehicleType: VehicleType.PassengerCar,
    }

    const { registrationNoDetails } = modelDetails

    if (registrationNoDetails) {
        if (registrationNoDetails.engineCodes && registrationNoDetails.engineCodes.length == 1) {
            vehicle.engineCode = registrationNoDetails.engineCodes.first()
        }

        vehicle.initialRegistration = registrationNoDetails.initialRegistration
        vehicle.plateId = registrationNoDetails.plateId
        vehicle.registrationNo = registrationNoDetails.registrationNo
        vehicle.vin = registrationNoDetails.vin
    }

    if (!vehicle.engineCode && modelDetails && modelDetails.engineCodes && modelDetails.engineCodes.length == 1) {
        vehicle.engineCode = modelDetails.engineCodes.first()
    }

    return vehicle
}

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(component: ComponentType<Omit<P, keyof StoreModel>>): ComponentType<P> {
    return class extends Component<P> {
        render() {
            const { store } = this.props
            return createElement(Provider, { store }, createElement(component, this.props))
        }
    }
}

export default StandaloneStatup
