import { FC, memo, useEffect, useState } from "react"
import { useParams } from "react-router"
import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import {
    Article,
    ArticleAttribute,
    EFilterNames,
    IMicros,
    RepairTimeProvider,
    SystemType,
    TmaEModule,
    TyreArticle,
    TyreArticleAttribute,
    TyreFilter,
    Vehicle,
} from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import {
    bem,
    createQueryString,
    encodeUniqueId,
    getIconByGroup,
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    getTitleByGroup,
    renderRoute,
    uniqueId,
} from "@tm/utils"
import { batch, useDispatch, useSelector } from "react-redux"
import { Box, Icon, NumberSelect, Tooltip } from "@tm/components"
import { MatchParams } from "../../data/models"
import { TyresIcons } from "./TyresIcons"
import { MainActions } from "../main/business"
import { MainState } from "../main"

type Props = {
    item: TyreArticle
    repairTimesRoute: string
    vehicleId?: string
    vehicle?: Vehicle
    hidden?: boolean
    selectedArticleAttributes?: string[]
    onArticleDetailsClick: (item: TyreArticle, path?: string) => void
    onArticleAttributeSelect(article: TyreArticle, attribute: ArticleAttribute): void
    onChangeStandaloneQuantity: (item: TyreArticle, value: number) => void
}

export const ArticleItem: FC<Props> = memo(
    ({
        repairTimesRoute,
        hidden,
        onArticleAttributeSelect,
        item,
        onArticleDetailsClick,
        onChangeStandaloneQuantity,
        selectedArticleAttributes,
        vehicle,
        vehicleId,
    }) => {
        const user = useUser()
        const { renderMicro } = useMicro<IMicros>()
        const matchParams = useParams<MatchParams>()
        const systemType = user?.userContext?.system.systemType
        const { translateText } = useLocalization()
        const providers = useSelector((s: MainState) => s.list.repairTimeAvailabilities?.[item.productGroup.id])

        const showArticleImages = user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages
        const isCompact = user?.userSettings?.articleListSettings?.viewOptions?.compactView
        const [opened, setOpened] = useState(isCompact)
        const isWM = (Morpheus.getParams("parts")?.templates?.articleItem?.bundle as string) === "wm"
        const showAdditionalPrices = Morpheus.getParams("parts")?.showAdditionalPrices

        const { repairTimeProviders } = getRepairTimeProviders()

        const dispatch = useDispatch()
        const { filters, selectedFilters } = useSelector((s: MainState) => ({ filters: s.list.filters, selectedFilters: s.list.selectedFilters }))

        useEffect(() => {
            if (opened !== isCompact) {
                setOpened(isCompact)
            }
        }, [isCompact])

        const getRepairTimesUrl = (article: Article, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
            if (article.productGroup && repairTimeProviders.length && user?.userSettings) {
                let provider
                if (Array.isArray(rtProviders)) {
                    const activeRTProvider = user?.userSettings.activeVehicleDataProviders.repairTimes
                    provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
                } else {
                    provider = getRepairTimesProviderStringByEnum(rtProviders)
                }

                if (!provider) {
                    return
                }

                return decodeURIComponent(
                    renderRoute(repairTimesRoute, {
                        ...matchParams,
                        workTaskId: encodeUniqueId(uniqueId()),
                        provider,
                        productGroupId: article.productGroup.id,
                        supplierId: article.supplier.id,
                        supplierArticleNo: article.supplierArticleNo,
                        position: article.fittingSide,
                    })
                )
            }
        }

        const handleToggleOpened = () => setOpened(!opened)

        const isSelected = (attribute: ArticleAttribute | undefined) => {
            return selectedArticleAttributes?.some((selAtr) => {
                const [id, key] = selAtr.split("|")
                return attribute?.id.toString() === id && attribute.value === key
            })
        }

        const renderIcons = () => {
            const allAttr = [...(item.attributes?.[0]?.topAttributes ?? []), ...(item.attributes?.[0]?.articleAttributes ?? [])]
            const fuelEfficiency = allAttr.find((x) => x.group === EFilterNames.fuelEfficiency)
            const wetGripClass = allAttr.find((x) => x.group === EFilterNames.wetGripClass)
            const externalRolling = allAttr.find((x) => x.group === EFilterNames.externalRolling)

            const items: (TyreArticleAttribute & { selected?: boolean })[] = []
            if (fuelEfficiency) {
                items.push({ ...fuelEfficiency, selected: isSelected(fuelEfficiency) })
            }
            if (wetGripClass) {
                items.push({ ...wetGripClass, selected: isSelected(wetGripClass) })
            }
            if (externalRolling) {
                items.push({ ...externalRolling, selected: isSelected(externalRolling) })
            }

            return <TyresIcons onSelect={(attr) => onArticleAttributeSelect(item, attr)} items={items} />
        }

        const renderSeasonIcon = (className: string) => {
            return (
                <Tooltip title={translateText(getTitleByGroup(item.productGroup.season))}>
                    <Icon name={getIconByGroup(item.productGroup.season)} size="1.5em" className={className} />
                </Tooltip>
            )
        }

        const renderSupplierWithSeason = () => {
            if (isWM) {
                return (
                    <Box display="flex" justifyContent="space-between" marginBottom="1em">
                        {renderIcons()}
                        {renderSeasonIcon(style.iconPadding)}
                    </Box>
                )
            }

            return <>{renderSeasonIcon(style.iconDefaultMargin)}</>
        }

        const renderStandAloneButtons = (tyreArticle: TyreArticle, showButtonText?: boolean) => {
            return (
                <Box className="standalone-basket-elements">
                    <NumberSelect
                        className="add-to-basket__quantity"
                        value={tyreArticle.quantity}
                        onChange={(event) => onChangeStandaloneQuantity(tyreArticle, parseInt((event.target as HTMLInputElement).value))}
                    />

                    {systemType === SystemType.Redesign &&
                        renderMicro!("standalone", "rd-add-articles-to-basket", {
                            items: [tyreArticle],
                            buttonText: showButtonText ? translateText(133) : undefined,
                            sourceId: "TM_TYRES",
                            tmaEventId: TmaEModule.TM_TYRE_SEARCH,
                        })}

                    {vehicle &&
                        renderMicro!("standalone", "open-rt-modal", {
                            repairTimesRoute,
                            items: [tyreArticle],
                            repairTimeProviders,
                            sourceId: "TM_TYRES",
                        })}
                </Box>
            )
        }

        const handleShowArticleFeedback = (article: TyreArticle) => {
            const articleFeedbackPath = Morpheus.getParams("parts")?.articleFeedbackPath
            if (articleFeedbackPath) {
                const url =
                    renderRoute(articleFeedbackPath, {
                        ...matchParams,
                        productGroupId: article.productGroup.id,
                        supplierId: article.supplier.id,
                        supplierName: article.supplier.name,
                        supplierArticleNo: article.supplierArticleNo,
                        itemId: article.id,
                        quantityValue: article.quantity || 1,
                        productGroupName: article.productGroup.name,
                        traderArticleNo: article.traderArticleNo || undefined,
                    }) + createQueryString({ articleDescription: article.description })
                Morpheus.showView("1", url)
            }
        }
        const handleDetailsButtonClick = () => {
            onArticleDetailsClick(item, "stocks")
        }

        const renderCustomErp = () => {
            return (
                <>
                    {showAdditionalPrices && renderMicro!("erp", "erp-additional-prices", { data: item })}

                    {renderMicro("erp", "erp-info", {
                        data: item,
                        onClick: handleDetailsButtonClick,
                    })}
                </>
            )
        }

        const manufacturerIndex = filters.manufacturer.findIndex((x) => x.value === item.supplier.name)

        const handleManufacturerClick = (value: TyreFilter) => {
            batch(() => {
                dispatch(MainActions.updateAttributeFilter(EFilterNames.manufacturer, value))
                dispatch(MainActions.loadTyresList(undefined, true))
            })
        }

        const workTaskId = useWorkTask()?.workTaskId
        return (
            <>
                {renderMicro("parts", "part-item", {
                    className: bem(style.articleItem, hidden && "hidden", isWM && "wm"),
                    part: item,
                    vehicleId,
                    isCompact: opened,
                    renderCustomIcons: renderIcons,
                    renderCustomSeason: renderSupplierWithSeason,
                    renderCustomErpInformation: renderCustomErp,
                    onToggleCollapse: handleToggleOpened,
                    rtProviders: vehicle && providers,
                    showActions: true,
                    showArticleImage: showArticleImages,
                    canFilterArticleAttributes: true,
                    selectedArticleAttributes,
                    onRequestArticleDetails: (r) => onArticleDetailsClick(item, r?.subPage),
                    onArticleAttributeSelect,
                    renderBuyActions: (systemType !== SystemType.Next && renderStandAloneButtons) || undefined,
                    getRepairTimesUrl,
                    workTaskId,
                    onShowArticleFeedback: handleShowArticleFeedback,
                    ignoreAttributeKey: true,
                    isDataSupplierFilterActive: !!selectedFilters?.manufacturer?.length,
                    onDataSupplierClick: () => handleManufacturerClick(filters.manufacturer[manufacturerIndex]),
                })}
            </>
        )
    }
)

const style = useStyle({
    articleItem: {
        $nest: {
            ".article__cell--supplier .supplier__name ": {
                width: "6em",
            },
            ".article__cell--supplier .article__supplier-logo": {
                maxHeight: "2rem",
                maxWidth: "5rem",
            },
            "&--wm .article__cell--supplier ": {
                flexDirection: "column-reverse",
            },
            ".article__cell--numbers": {
                width: "10em",
            },
            "&--wm .article__cell--numbers .article__numbers": {
                width: "auto",
            },
            "&--hidden": {
                display: "none",
            },
        },
    },
    iconPadding: {
        paddingLeft: "0.2em",
    },
    iconDefaultMargin: {
        marginRight: "1em",
    },
})(ArticleItem)
