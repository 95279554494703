import { Card, Image, SubTitle } from "@tm/controls"
import { channel, OE, Vehicle } from "@tm/models"
// eslint-disable-next-line import/no-unresolved
import tinycolor from "tinycolor2"
import { useLocalization } from "@tm/localization"
import { Box, Divider, styled, Switch, SxProps, Typography } from "@tm/components"
import { FC, useState, useCallback, useEffect, useMemo } from "react"
import { Models } from "../../../data"
import OePositions from "./oe/oe-positions"
import { compareReplacementNumbers } from "./utils"
import OeInformationItem from "../../_shared/oe/oe-article/OeInformationItem"
import {
    createManufacturerDependentSearchRequest,
    createOePositions,
    createVehicleDependentSearchRequest,
} from "../../ListV2/components/OeInformation/helpers"
import { OeInformationConfiguration } from "../../ListV2/ArticleListConfiguration/useOeInformationConfiguration"

type StyledProps = {
    isImage?: boolean
}

const StyledWrapperBox = styled(Box)(({ theme }) => {
    switch (theme.name) {
        case "lkq":
        case "stg":
        case "pv":
        case "neimcke": {
            return {
                padding: theme?.margin?.s,
                backgroundColor: theme.name === "stg" ? "#f6f6f6" : undefined,
                display: "flex",
            }
        }
        case "default": {
            return {
                backgroundColor: tinycolor(theme?.colors?.highlight).lighten(30).toHexString(),
                display: "flex",
            }
        }
        default: {
            return {
                display: "flex",
            }
        }
    }
})

const StyledCard = styled(Card)<StyledProps>((props) => {
    const { isImage, theme } = props

    const style: SxProps = {
        display: "inline-block",
        margin: "0 3px 0 0",
        padding: "0",
        cursor: "pointer",
        textAlign: "center",
        "& :hover": {
            borderColor: "#9a9a9a",
        },
    }

    const cardInner: SxProps = {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "0.25em 0.4em",
        border: "1px solid #c3c3c3",
        color: `${theme?.colors?.dark ?? "#202020"}`,
    }

    if (isImage) {
        return {
            ...style,
            width: "3.5em",
            "& .card__inner": {
                ...cardInner,
                background: "#fff",
            },
        }
    }

    return {
        ...style,
        minWidth: "3em",

        "& .card__inner": {
            ...cardInner,
            background: "transparent",
        },
        "&.card--highlight": {
            "& .card__inner": {
                backgroundColor: `${theme?.colors?.primary}`,
            },
            "& p": {
                color: `${theme?.colors?.light ?? "#fff"}`,
            },
        },
        "&.card--decent": {
            opacity: "1",
        },
    }
})

const StyledImage = styled(Image)({
    display: "block",
    width: "auto",
    maxWidth: "100%",
    "&.error-image": {
        margin: "-0.25em -0.4em",
    },
    "& svg": {
        width: "1.55em",
    },
})

const StyledOePositions = styled(OePositions)({
    margin: "0.1em 0.5em 0.5em 0.5em !important", // TODO fix
})

const StyledDivider = styled(Divider)({
    borderWidth: "1px",
})

const StyledSubTitle = styled(SubTitle)({
    marginBottom: "0.5em",
})

export type Props = {
    oeInformationList: Array<Models.OeInformation>
    query?: string
    onReplacementSelect(replacement: string): void
    vehicle: Vehicle | undefined
    hideOePrice?: boolean
    oeManufacturerIds?: Array<number> | undefined
    oeInformationConfiguration: OeInformationConfiguration
}

export const OeInformation: FC<Props> = (props) => {
    const { translate } = useLocalization()
    const { oeInformationList, onReplacementSelect, query, vehicle, hideOePrice, oeManufacturerIds } = props

    const [checkboxes, setCheckboxes] = useState({ manufacturer: false, vehicle: false })

    const [selectedManufacturerId, setSelectedManufacturerId] = useState<number>()
    const [selectedOeNumber, setSelectedOeNumber] = useState<string>()

    const { hideManufacturerLogo, showReplacementChain, isVehicleDependentSearch, showOePrice, displayVehicleOeCriteria, transferToShoppingCart } =
        props.oeInformationConfiguration

    const selectReplacement = useCallback(
        (position: OE.OePosition, replacement?: OE.OeNumber) => {
            if (!replacement) {
                return
            }

            setSelectedOeNumber(replacement.number)
            onReplacementSelect(replacement.number)
        },
        [onReplacementSelect]
    )

    const handleManufacturerChange = useCallback(
        (manufacturerId: number) => () => {
            setSelectedManufacturerId(manufacturerId)
        },
        [setSelectedManufacturerId]
    )

    useEffect(() => {
        if (!query) {
            return
        }

        const oeInformation =
            oeInformationList?.find((x) => x.manufacturer?.id === selectedManufacturerId) ??
            oeInformationList.find((x) => x.manufacturer?.id === vehicle?.tecDocManufacturerId) ??
            oeInformationList.find((x) => oeManufacturerIds?.includes(x.manufacturer?.id)) ??
            oeInformationList?.first()

        const oeArticle =
            oeInformation?.oeArticles?.find((x) => compareReplacementNumbers(x.number, query)) ??
            oeInformation?.oeArticles?.find((x) => x.number === selectedOeNumber) ??
            oeInformation?.oeArticles?.first()

        setSelectedManufacturerId(oeInformation?.manufacturer?.id)
        setSelectedOeNumber(oeArticle?.number)
    }, [query, oeInformationList, selectedManufacturerId, vehicle?.tecDocManufacturerId])

    const selectedManufacturer: Models.OeInformation | undefined = useMemo(() => {
        return oeInformationList?.find((x) => x.manufacturer.id === selectedManufacturerId)
    }, [oeInformationList, selectedManufacturerId])

    const selectedOeArticle: Models.OeInformationNumber | undefined = useMemo(() => {
        return selectedManufacturer?.oeArticles?.find((x) => x.number === selectedOeNumber)
    }, [selectedOeNumber, selectedManufacturer])

    const positions: OE.OePosition[] = useMemo(
        () => createOePositions(selectedOeNumber, selectedManufacturer, selectedOeArticle),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedManufacturer?.oeArticles, selectedOeArticle, selectedOeNumber]
    )

    function handleModalClose() {
        setCheckboxes({ manufacturer: false, vehicle: false })
    }

    useEffect(() => {
        if (Object.values(checkboxes).every((x) => !x)) {
            return
        }

        const unsubModalClose = channel("GLOBAL").subscribe("MODAL/CLOSED", handleModalClose)

        return unsubModalClose
    }, [checkboxes])

    const handleManufacturerDependentSearch = useCallback(() => {
        if (!selectedManufacturer || !positions || !selectedOeNumber) {
            return
        }

        setCheckboxes({ manufacturer: true, vehicle: true })

        const request = createManufacturerDependentSearchRequest(
            selectedOeNumber,
            positions,
            selectedManufacturer.manufacturer.id,
            hideOePrice,
            selectedManufacturer?.oeArticles
        )

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }, [selectedManufacturer, positions, selectedOeNumber, hideOePrice])

    const handleVehicleDependentSearch = useCallback(() => {
        if (!selectedManufacturer || !positions || !selectedOeNumber || !vehicle) {
            return
        }

        setCheckboxes({ manufacturer: false, vehicle: true })

        const request = createVehicleDependentSearchRequest(positions, hideOePrice)

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedManufacturer, positions, selectedOeNumber, hideOePrice, vehicle?.id])

    if (!positions || !selectedManufacturer || !selectedOeArticle) {
        return null
    }

    const renderVehicleDependentSearch = () => {
        if (!isVehicleDependentSearch) {
            return null
        }

        return (
            <Box display="flex" width="20em">
                <StyledDivider orientation="vertical" />
                <Box display="flex" flexDirection="column">
                    <Typography variant="h2" m={1}>
                        {translate(389)}
                    </Typography>
                    <Box ml=".5em" display="flex">
                        <Switch
                            checked={checkboxes.vehicle}
                            disabled={!vehicle}
                            label={translate(13228)}
                            size="small"
                            sx={{ marginLeft: 0, marginRight: 0 }}
                            onChange={handleVehicleDependentSearch}
                        />
                    </Box>
                    {/* This toggle is hidden for now, until is requested by Dierk as a feature
                    <Box ml=".5em" display="flex">
                        <Switch
                            checked={checkboxes.manufacturer}
                            label={translate(13229)}
                            size="small"
                            sx={{ marginLeft: 0, marginRight: 0 }}
                            onChange={handleManufacturerDependentSearch}
                        />
                    </Box> */}
                </Box>
            </Box>
        )
    }

    return (
        <StyledWrapperBox>
            <Box>
                <Box display="flex">
                    <Typography variant="h2" m={1}>
                        {translate(13168)}
                    </Typography>

                    <StyledDivider />

                    <Box m={1}>
                        <StyledSubTitle size="s">{translate(71)}</StyledSubTitle>

                        {oeInformationList?.map((x) => (
                            <StyledCard
                                onClick={handleManufacturerChange(x.manufacturer.id)}
                                key={x.manufacturer.id}
                                skin={x.manufacturer.id === selectedManufacturerId ? "highlight" : "decent"}
                                isImage={!hideManufacturerLogo}
                            >
                                {hideManufacturerLogo ? (
                                    <Typography> {x.manufacturer.description || "OE"} </Typography>
                                ) : (
                                    <StyledImage url={x.manufacturer.thumbnail ?? ""} type="manufacturer" title={x.manufacturer.description} />
                                )}
                            </StyledCard>
                        ))}
                    </Box>

                    <StyledDivider />

                    {positions && (
                        <StyledOePositions
                            positions={positions}
                            onPositionSelect={selectReplacement}
                            hideDescription
                            hideSelectedPosition
                            hideReplacements={!showReplacementChain}
                        />
                    )}
                </Box>

                <div className="article-list">
                    <OeInformationItem
                        parts={selectedOeArticle.parts}
                        selectedManufacturer={selectedManufacturer.manufacturer}
                        forceHideOePrice={hideOePrice}
                        showOePrice={showOePrice}
                        displayVehicleOeCriteria={displayVehicleOeCriteria}
                        transferToShoppingCart={transferToShoppingCart}
                    />
                </div>
            </Box>
            {renderVehicleDependentSearch()}
        </StyledWrapperBox>
    )
}
