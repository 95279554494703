import { Typography } from "@tm/components"
import { Tax } from "../../../data/model"

type Props = {
    taxes?: Array<Tax>
}

export default function TaxesCellComponent({ taxes }: Props) {
    return <>{taxes?.map((tax, idx) => <Typography key={idx}>{tax.description || `${tax.value}%`}</Typography>)}</>
}
