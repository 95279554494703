import { Stack, styled, SxProps, Theme } from "@tm/components"
import { Widget as WidgetControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useHeighAdjustment } from "@tm/utils"
import { ReactNode, useEffect, useRef } from "react"
import { AiSearch } from "../_shared/ai-search/AiSearch"

const SxWidgetControl = styled(WidgetControl)({})

export type Props = {
    height?: number
    alignHeightToWidgets?: string[]
    sx?: SxProps<Theme>
    aiSwitch?: ReactNode
    isOnIndustryDashboard?: boolean
    className?: string
}

export function AiWidget(props: Props) {
    const { sx, alignHeightToWidgets, height, aiSwitch, isOnIndustryDashboard } = props
    const { translate } = useLocalization()

    const removeHeightAdjustment = useRef<() => void>()

    useEffect(() => {
        return () => {
            removeHeightAdjustment.current?.()
        }
    }, [])

    const handleRef = (el: HTMLDivElement | null) => {
        if (el && alignHeightToWidgets) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            removeHeightAdjustment.current = useHeighAdjustment().setHeightAdjustment(el, alignHeightToWidgets)
        }
    }

    return (
        <SxWidgetControl
            sx={sx}
            id="parts__widget"
            className={`tk-parts parts-widget aiSearch ${props.className || ""}`}
            size="4x4"
            height={height}
            collapsible={false}
            iconName="catalog"
            title={translate(993)}
            active
            forwardedRef={handleRef}
        >
            <Stack alignItems={!isOnIndustryDashboard ? "flex-start" : "center"} spacing={1} overflow="hidden">
                <AiSearch
                    searchFieldWidth="500px"
                    aiSwitch={aiSwitch}
                    isOnIndustryDashboard={isOnIndustryDashboard}
                    lowSearchField={!isOnIndustryDashboard}
                />
            </Stack>
        </SxWidgetControl>
    )
}
