import { RimFilters } from "../../../components/wheels-list/business/model"
import { FilterCriterias } from "../../enums"

export function mapRimFiltersResponse(response: any): RimFilters {
    const { rimFilter } = response
    if (!rimFilter) {
        return {
            colors: [],
            winterprofed: [],
            widths: [],
            snowChainsCompatible: [],
            offsets: [],
            noModification: [],
            manufacturers: [],
            inches: [],
            ece: [],
            designs: [],
            type: [],
        }
    }

    return {
        manufacturers: rimFilter[FilterCriterias.rimSupplier] || [],
        colors:
            rimFilter[FilterCriterias.rimColor]?.map((x: any) => {
                return { ...x, info: x.attributeValue, attributeKey: x.attributeKey }
            }) || [],
        designs:
            rimFilter[FilterCriterias.rimSort]?.map((x: any) => {
                return { ...x, info: x.attributeValue }
            }) || [],
        inches: rimFilter[FilterCriterias.rimDiameter] || [],
        offsets: rimFilter[FilterCriterias.rimOffset] || [],
        widths: rimFilter[FilterCriterias.rimWidth] || [],
        winterprofed: rimFilter[FilterCriterias.rimAttribute1] || [],
        snowChainsCompatible: rimFilter[FilterCriterias.rimAttribute2] || [],
        noModification: rimFilter[FilterCriterias.rimAttribute3] || [],
        ece: rimFilter[FilterCriterias.rimAttribute4] || [],
        type:
            rimFilter[FilterCriterias.rimType]?.map((x: any) => {
                return { ...x, info: x.attributeValue }
            }) || [],
    }
}
