import { EFilterNames, FilterNames, TyreFilter } from "@tm/models"
import { DRTyre } from "../../../data/models"

export type SummaryState = {
    driveRightTyres: {
        frontTyres: DRTyre[]
        rearTyres: DRTyre[]
        loading?: boolean
        error?: boolean
        lastTecDocSearched?: number
    }
    ignoreVehRecors?: boolean
    filters: Filters
    lastFiltersSearch?: string
    selectedFilters: SelectedFilters
    toggleTyresAccordion: boolean
    summaryWithVehicle: boolean
}

export type ISummarySingleFilters = Extract<FilterNames, "width" | "height" | "inch" | "loadIndex" | "oeIdentifier" | "speedIndex" | "tyreCount">

export type ISummaryMultiFilters = Extract<FilterNames, "carType" | "season">

export const SummaryMultiFilters: Array<FilterNames> = [EFilterNames.carType, EFilterNames.season]

export type ISummaryFilters = ISummarySingleFilters | ISummaryMultiFilters

export type IVehicleRecordFilter = Extract<ISummaryFilters, "width" | "height" | "inch">

export type Filters = { [key in ISummaryFilters]: TyreFilter[] } & { loading?: boolean; initialized: boolean; carTypeAndSeason?: TyreFilter[] }

export type SelectedFilters = { [key in ISummarySingleFilters]?: TyreFilter } & { [key in ISummaryMultiFilters]: TyreFilter[] } & {
    untouched?: boolean
}
