import { Article, ArticleErpInfo, ArticleListSortingMode, AvailabilityStatus, ErpPrice, PriceType, TyreArticle } from "@tm/models"
import { useCallback, useMemo, useState } from "react"
import { useArticleListSortingConfiguration } from "../ArticleListConfiguration"
import { ArticleErpInfoFactory, useDefaultErpSystem } from "."

export type ArticleSorting = {
    sortedArticleList: TyreArticle[] | Article[]
    selectedSorting?: ArticleListSortingMode
    handleSorting?: (sorting: ArticleListSortingMode | undefined) => void
}

export function useSortArticles(articles: TyreArticle[] | Article[], erpInfos: ArticleErpInfo[]): ArticleSorting {
    const { availabilitySortingOrder, sortingPriceType, defaultMode } = useArticleListSortingConfiguration()
    const defaultErpSystemId = useDefaultErpSystem()?.id
    const [selectedSorting, setSelectedSorting] = useState<ArticleListSortingMode | undefined>(defaultMode)

    const handleSorting = useCallback((sorting: ArticleListSortingMode | undefined) => {
        setSelectedSorting(sorting || "default")
        const scrollableElement = document.querySelector(".scrollbar__container__tyres") as HTMLElement
        if (scrollableElement) {
            scrollableElement.scrollTop = 0
        }
    }, [])

    const sortedArticleList = useMemo(() => {
        if (!erpInfos?.length || !defaultErpSystemId) {
            return articles
        }
        switch (selectedSorting) {
            case "price":
            case "priceDescending":
                return sortByPrice(articles, erpInfos, defaultErpSystemId, selectedSorting, sortingPriceType)
            case "availability":
                return sortByAvailability(articles, erpInfos, defaultErpSystemId, availabilitySortingOrder)
            default:
                return articles
        }
    }, [articles, availabilitySortingOrder, defaultErpSystemId, erpInfos, selectedSorting, sortingPriceType])
    return { sortedArticleList, selectedSorting, handleSorting }
}

function sortByPrice(
    articles: Article[],
    erpData: ArticleErpInfo[],
    erpSystemId: number,
    sorting: ArticleListSortingMode,
    sortingPriceType: PriceType
) {
    const sortedArticles = [...articles]

    sortedArticles.sort((a, b) => {
        const aErpInfo = getArticleErpInfo(a, erpData, erpSystemId)
        const bErpInfo = getArticleErpInfo(b, erpData, erpSystemId)

        const aErpInfoResponse = aErpInfo?.state === "success" ? aErpInfo.response : undefined
        const bErpInfoResponse = bErpInfo?.state === "success" ? bErpInfo.response : undefined

        if (aErpInfoResponse && !bErpInfoResponse) {
            return -1
        }

        if (!aErpInfoResponse && bErpInfoResponse) {
            return 1
        }

        if (aErpInfoResponse && bErpInfoResponse) {
            const aPrice = getPrice(aErpInfoResponse.prices, sortingPriceType)
            const bPrice = getPrice(bErpInfoResponse.prices, sortingPriceType)

            if (aPrice && !bPrice) {
                return -1
            }

            if (!aPrice && bPrice) {
                return 1
            }

            if (aPrice && bPrice) {
                return sorting === "price" ? aPrice.value - bPrice.value : bPrice.value - aPrice.value
            }
        }

        return 0
    })

    return sortedArticles
}

function sortByAvailability(articles: Article[], erpData: ArticleErpInfo[], erpSystemId: number, availabilitySortingOrder: AvailabilityStatus[]) {
    const sortedArticles = [...articles]

    sortedArticles.sort((a, b) => {
        const aErpInfo = getArticleErpInfo(a, erpData, erpSystemId)
        const bErpInfo = getArticleErpInfo(b, erpData, erpSystemId)

        const aErpInfoResponse = aErpInfo?.state === "success" ? aErpInfo.response : undefined
        const bErpInfoResponse = bErpInfo?.state === "success" ? bErpInfo.response : undefined

        if (aErpInfoResponse && !bErpInfoResponse) {
            return -1
        }

        if (!aErpInfoResponse && bErpInfoResponse) {
            return 1
        }

        if (aErpInfoResponse && bErpInfoResponse) {
            const aAvailability = aErpInfoResponse.availability.type
            const bAvailability = bErpInfoResponse.availability.type

            if (aAvailability && !bAvailability) {
                return -1
            }

            if (!aAvailability && bAvailability) {
                return 1
            }

            if (aAvailability && bAvailability) {
                const aSort = availabilitySortingOrder.indexOf(aAvailability)
                const bSort = availabilitySortingOrder.indexOf(bAvailability)

                return aSort - bSort
            }
        }

        return 0
    })

    return sortedArticles
}

function getArticleErpInfo(article: TyreArticle | Article, erpData: ArticleErpInfo[], erpSystemId: number): ArticleErpInfo | undefined {
    return erpData.find((info) => info.key.id === ArticleErpInfoFactory.createKeyFromArticle(article, erpSystemId).id)
}

function getPrice(prices: ErpPrice[] | undefined, priceType: PriceType): ErpPrice | undefined {
    return prices?.find((price) => price.type === priceType)
}
