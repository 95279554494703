import { Suspense, useState, useCallback, useMemo } from "react"
import { WorkTaskInfo, useTelesalesCustomerNumber, useWorkTask, useAddToBasketModuleParameters } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ArticleInfoType, IMicros } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Box, NumberSelect, styled, Badge, Button, Loader, Stack, Icon } from "@tm/components"
import { concat, useExternalCatalogUrl } from "@tm/utils"
import { useAddArticleExternalUrl, createAddOePartListRequest } from "../../helpers"
import { useBasketMemo } from "../../hooks/useBasketMemo"
import { getBundleParams } from "../../utils"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

type Props = IMicros["basket"]["add-to-basket-oe-part"]

const NumberSelectWrapper = styled(Box)(() => ({
    marginRight: ".25em",
}))

function AddToBasketOePartComponent(props: Props & { workTask: WorkTaskInfo; addToNextBasket: boolean }) {
    const { part, buttonText, disabled, layout, onChangeQuantity, workTask, addToNextBasket } = props
    const { translateText, languageId } = useLocalization()
    const externalSystemId = getBundleParams().addToBasketExternalSystemId
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { basket } = useWorkTaskBasketState(workTask.id)
    const { loadingExternalCatalogUrl, externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const basketMemo = useBasketMemo(workTask)
    const { addOeParts, removeParts } = basket.actions
    const { basketOrderGroups, basketLoading } = basket.state

    const [_quantity, setQuantity] = useState(part.quantityValue ?? 1)
    const quantity = props.quantity ?? _quantity
    const [articleGetsAddedToBasket, setArticleGetsAddedToBasket] = useState(false)
    const url = useAddArticleExternalUrl(
        externalCatalogUrl,
        languageId,
        quantity,
        part.wholesalerArticleNumber,
        part.oeArticleNumber,
        undefined,
        part.productGroupId
    )

    const handleChangeQuantity = useCallback(
        (amount: number) => {
            setQuantity(amount)
            onChangeQuantity?.(amount)
        },
        [onChangeQuantity]
    )

    const handleAddArticleClick = useCallback(() => {
        if (disabled || !part) {
            return
        }

        if (addToNextBasket !== false) {
            if (url) {
                Morpheus.showView("1", url)
            } else {
                const request = createAddOePartListRequest(part, quantity, workTask, basketMemo.position)

                setArticleGetsAddedToBasket(true)
                addOeParts(request).finally(() => {
                    setArticleGetsAddedToBasket(false)
                })
            }
        }
    }, [disabled, part, workTask, url, quantity, basketMemo.position, addToNextBasket])

    const [quantityInBasket, ids] = useMemo(() => {
        let quantityValue = 0
        const partIds: string[] = []
        basketOrderGroups
            ?.filter((group) => !externalSystemId || group.orderGroup.distributorId === externalSystemId)
            .forEach((group) => {
                group.basketParts.forEach((basketPart) => {
                    const { articleNumber, productGroupId, articleInfoType, supplierId } = basketPart.partItem.articleInformation
                    if (
                        articleNumber === part.oeArticleNumber &&
                        productGroupId === part.productGroupId &&
                        supplierId === part.vehicleManufacturerId &&
                        articleInfoType === ArticleInfoType.OeArticle
                    ) {
                        quantityValue += basketPart.partItem.quantity.value
                        partIds.push(basketPart.partItem.id)
                    }
                })
            })
        return [quantityValue, partIds]
    }, [basketOrderGroups, externalSystemId, part.oeArticleNumber, part.productGroupId])

    if (!props.part) {
        console.warn("AddOeArticleToBasketComponent used but property not supplied: part")
        return null
    }

    function renderQuantity() {
        if (props.hideQuantityField || layout === "cost-estimation") {
            return null
        }

        return (
            <NumberSelectWrapper>
                <NumberSelect value={quantity} onValueChange={handleChangeQuantity} />
            </NumberSelectWrapper>
        )
    }

    function renderButton() {
        const handleRemovePart = (partIds: Array<string>) => {
            removeParts(partIds, true)
        }

        let badge
        if (articleGetsAddedToBasket) {
            badge = (
                <Badge
                    title={translateText(1299)}
                    badgeContent={<Loader size="extrasmall" />}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: "initial" }}
                />
            )
        } else if (quantityInBasket && ids.length) {
            badge = (
                <Badge
                    size="small"
                    title={translateText(1299)}
                    badgeContent={
                        <Stack direction="row">
                            {quantityInBasket}
                            <Icon name="close" width="12px" height="12px" sx={{ marginLeft: "2px" }} />
                        </Stack>
                    }
                    onClick={() => !basketLoading && handleRemovePart(ids)}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: "initial" }}
                />
            )
        }

        // The add-to-cost-estimation micro requires too much logic only to add the oe part in the basket igonring the RT.
        // The best solution is to add the cost-estimation layout to the add t basket Button
        let icon = "cart"
        if (layout === "cost-estimation") {
            icon = languageId === "1" ? "voucher-kva" : "voucher-kva-international"
        }

        return (
            <>
                {layout !== "cost-estimation" && badge}
                <Box minWidth={buttonText ? "13em" : "6em"}>
                    <Button
                        title={translateText(937)}
                        color={!disabled ? "highlight" : undefined}
                        className="addToBasketButton"
                        variant={!disabled ? "bordered" : undefined}
                        onClick={handleAddArticleClick}
                        startIcon={<Icon name={icon} />}
                        disabled={disabled || loadingExternalCatalogUrl}
                        fullWidth
                    >
                        {buttonText ? <>{buttonText}</> : ""}
                    </Button>
                </Box>
            </>
        )
    }

    return (
        <div className="tk-basket" title={disabled ? translateText(938) : undefined}>
            <div className={concat(" ", "add-to-basket", disabled && "add-to-basket--disabled")}>
                {renderQuantity()}
                {renderButton()}
            </div>
        </div>
    )
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}
    const { showBasketButton, addToNextBasket } = useAddToBasketModuleParameters()

    // if the MDM config at the BasketModule has no Basketbutton, don't render it
    if (!workTask || showBasketButton === false) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToBasketOePartComponent {...props} workTask={workTask} addToNextBasket={addToNextBasket} />
        </Suspense>
    )
}
