export function createSpecificQuery(loadIndex?: string, speedIndex?: string) {
    let size = ""
    if (loadIndex) {
        size += loadIndex
    }
    if (speedIndex) {
        size += speedIndex
    }
    return size
}
