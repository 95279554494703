import { useState, useEffect } from "react"
import { Popover, Toolbar, DateField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, uniqueId, useKeyValue } from "@tm/utils"
import { CisVoucherType } from "@tm/models"
import { Typography, Button, Switch, Icon, Stack, Box, SearchButton } from "@tm/components"
import LastOrders from "./LastOrders"
import { useCentralOrderWorkTasks } from "../../../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import { useGlobalOrderOptionsState } from "../../../../hooks/useGlobalOrderOptionsState"
import { BoxWithAlignContent, StackRow } from "../../../StyledComponents"
import { useIsCentralOrderBasketsLoading } from "../../../../hooks/basketState/useIsCentralOrderBasketsLoading"
import CentralOrderButton from "../../../_shared/order-button/central"

export type CentralOrderNavigationConfigProps = {
    lastOrdersTextId: number
    ordersUrl?: string // Could be cis or vouchers-orders.
    voucherTypeId?: CisVoucherType
    userWorkTasksEnabled?: boolean
    centralOrderButtonTextId: number
}

const generatedWorktaskId = encodeUniqueId(uniqueId())
const today = new Date()
const oneWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

export default function CentralOrderNavigation(props: CentralOrderNavigationConfigProps) {
    const { centralOrderButtonTextId, lastOrdersTextId, ordersUrl, userWorkTasksEnabled, voucherTypeId } = props
    const { translateText } = useLocalization()

    const [dateTo, setDateTo] = useState<Date | undefined>(today)
    const [dateFrom, setDateFrom] = useState<Date | undefined>(oneWeekAgo)
    const [viewOptionsExpanded, setViewOptionsExpanded] = useState(false)
    const { globalOrderOptionsEnabled, setGlobalOrderOptionsEnabled, centralOrderParameters } = useGlobalOrderOptionsState()

    const { search, reset } = useCentralOrderWorkTasks()
    const isBasketLoading = useIsCentralOrderBasketsLoading()

    const [centralOrderingShowOnlyUserWorkTasks, setCentralOrderingShowOnlyUserWorkTasks, centralOrderingShowOnlyUserWorkTasksState] = useKeyValue({
        key: "CENTRAL_ORDERING_SHOW_ONLY_USER_WORKTASKS",
        ownedByRepairShop: false,
    })

    const disabled = isBasketLoading || centralOrderingShowOnlyUserWorkTasksState === "loading"

    const showOnlyUserWorktasks =
        centralOrderingShowOnlyUserWorkTasksState === "hasValue" ? centralOrderingShowOnlyUserWorkTasks === "true" : userWorkTasksEnabled

    useEffect(() => {
        search(dateTo, dateFrom, showOnlyUserWorktasks)
        return reset
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnlyUserWorktasks]) // when dateTo and/or dateFrom change, no automatic search must be performed.

    function handleResetButtonClick() {
        setDateFrom(undefined)
        setDateTo(undefined)
    }

    if (!ordersUrl) {
        return null
    }

    return (
        <StackRow justifyContent="space-between">
            <BoxWithAlignContent>
                <Typography variant="h1" mr={2} alignSelf="center">
                    {translateText(1991)}
                </Typography>
                <Toolbar title={translateText(98)}>
                    <DateField floatingLabel label={translateText(344)} maxDate={dateTo || today} useUtc value={dateFrom} onChange={setDateFrom} />
                    <DateField
                        floatingLabel
                        label={translateText(95)}
                        minDate={dateFrom}
                        maxDate={today}
                        useUtc
                        value={dateTo}
                        onChange={setDateTo}
                    />
                    <SearchButton disabled={disabled} onClick={() => search(dateTo, dateFrom, showOnlyUserWorktasks)} />
                    <Button startIcon={<Icon name="refresh" />} title={translateText(3133)} onClick={handleResetButtonClick} />
                </Toolbar>
                <Toolbar title={translateText(177)}>
                    <LastOrders
                        textId={lastOrdersTextId}
                        generatedWorktaskId={generatedWorktaskId}
                        ordersUrl={ordersUrl}
                        voucherTypeId={voucherTypeId}
                    />
                </Toolbar>
                <Toolbar title={translateText(222)}>
                    <div style={{ position: "relative" }}>
                        <Button onClick={() => setViewOptionsExpanded(!viewOptionsExpanded)}>{translateText(177)}</Button>
                        <Popover active={viewOptionsExpanded} className="view-options" onOutsideInteraction={() => setViewOptionsExpanded(false)}>
                            <Stack alignItems="flex-end">
                                {centralOrderParameters.showGlobalOrderOptionsSwitch && (
                                    <Switch
                                        label={translateText(13129)}
                                        checked={globalOrderOptionsEnabled}
                                        onChange={() => setGlobalOrderOptionsEnabled(!globalOrderOptionsEnabled)}
                                        disabled={disabled}
                                        labelPlacement="start"
                                        size="small"
                                    />
                                )}
                                <Box>
                                    <Switch
                                        label={translateText(13125)}
                                        checked={showOnlyUserWorktasks}
                                        onChange={() => setCentralOrderingShowOnlyUserWorkTasks?.((!showOnlyUserWorktasks).toString())}
                                        disabled={disabled}
                                        labelPlacement="start"
                                        size="small"
                                    />
                                </Box>
                            </Stack>
                        </Popover>
                    </div>
                </Toolbar>
            </BoxWithAlignContent>
            <CentralOrderButton textId={centralOrderButtonTextId} />
        </StackRow>
    )
}
