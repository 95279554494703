import { ShowCostEstimationDetailsResponse } from "@tm/models"
import { OfferState } from "./model"

export * from "./model"

export type ComponentActionType =
    | { type: "COST_ESTIMATION_DETAILS_LOADED"; payload: ShowCostEstimationDetailsResponse }
    | { type: "COST_ESTIMATION_NOTE_MODIFIED"; payload: string }
    | { type: "EXPAND_WORKS"; payload: { workIds: string[] } }
    | { type: "OFFER_DETAILS_CLEARED" }
    | { type: "SHOW_HIDE_WORK_INCLUDES"; payload: { workId: string } }

const DEFAULT_STATE: OfferState = {
    costEstimationDetailsLoading: false,
    offerDetails: {
        mechanic: "",
        clientAdvisor: "",
        referenceNumber: "",
    },
    note: "",
}

// TODO: state is mutated in this reducer! A big refactoring should be done.
export function reduce(state = DEFAULT_STATE, action: ComponentActionType): OfferState {
    switch (action.type) {
        case "COST_ESTIMATION_DETAILS_LOADED": {
            const { clientAdvisorName, mechanicName, dueDate, endDate, startDate, memo, referenceNumber, version } = action.payload
            return {
                ...state,
                costEstimationDetailsLoading: false,
                offerDetails: {
                    clientAdvisor: clientAdvisorName,
                    dueDate,
                    endDate,
                    mechanic: mechanicName,
                    referenceNumber,
                    startDate,
                    version,
                },
                note: memo,
            }
        }
        case "COST_ESTIMATION_NOTE_MODIFIED":
            return {
                ...state,
                note: action.payload,
            }
        case "OFFER_DETAILS_CLEARED": {
            return {
                ...state,
                offerDetails: {
                    mechanic: "",
                    clientAdvisor: "",
                    referenceNumber: "",
                    startDate: undefined,
                    endDate: undefined,
                    dueDate: undefined,
                },
            }
        }
        default:
            break
    }

    return state
}
