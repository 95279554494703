import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { TradeReferencesData, useTradeReferences } from "./useTradeReferences"
import { useArticlesContext } from "../Articles"
import { ensureContextAvailability } from "../../helpers"

export const TradeReferenceContext = createContext<TradeReferencesData | undefined>(undefined)

export function TradeReferenceProvider({ isEnabled, children }: PropsWithChildren<{ isEnabled: boolean }>) {
    const articlesData = useArticlesContext()
    const tradeReferences = useTradeReferences(articlesData.articles, isEnabled)
    return <TradeReferenceContext.Provider value={tradeReferences}>{children}</TradeReferenceContext.Provider>
}

export function useTradeReferenceContext(): TradeReferencesData {
    const context = useContext(TradeReferenceContext)
    return ensureContextAvailability("TradeReferenceContext", context)
}
