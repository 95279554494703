import { useCallback, useMemo, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, createQueryString, useSelection, mapVoucherTypeForUrl } from "@tm/utils"
import { Headline, Button, Text, Loader, Badge } from "@tm/controls"
import { Item, ReturnStatus, VoucherType } from "@tm/models"
import { useCisReturnItemsTotalNumbers, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { useHistory, useParams } from "react-router"
import PartsList from "./components/parts-list"
import OrderCompleted from "./components/order-completed"
import PartsSelection from "./components/parts-selection"
import { ReturnItem } from "../../data/model"
import { CisDisplayMode } from "../../business/model"
import {
    useRemoveReturnItems,
    useShowReturnItems,
    useSubmitReturnOrder,
    useIncludeItemsInReturn,
    useSubmitReturnOrderMail,
} from "../../data/hooks/useReturnItems"
import { useUpdatedReturnItems } from "../../data/hooks/useUpdatedReturnItems"
import { printReturnItemsList } from "./business/helper"
import { getBundleParams } from "../../utils"

type Props = {
    cisDisplayMode?: CisDisplayMode
    fromVouchersModule?: boolean
    onOpenPartDetails?(): void
}

export default function ReturnsComponent({ cisDisplayMode, fromVouchersModule, onOpenPartDetails }: Props) {
    const { translateText, language } = useLocalization()
    const { returnItems, returnItemsLoading } = useShowReturnItems()
    const updatedReturnItems = useUpdatedReturnItems(returnItems ?? [], !returnItemsLoading)

    const getReturnItemsIds = useCallback(() => {
        return updatedReturnItems?.map((item) => item.id) || []
    }, [updatedReturnItems])

    const history = useHistory()
    const matchParams = useParams<{ workTaskId?: string }>()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { selectedIds, unselectAll, selectAll, toggleSelected } = useSelection(getReturnItemsIds)
    const { includeInReturn, isIncludingItemsInReturn } = useIncludeItemsInReturn()
    const { totalNumbers, totalNumbersLoading } = useCisReturnItemsTotalNumbers()
    const { returnOrderResponse, returnOrderSending, submitReturnOrder, resetSubmitOrder } = useSubmitReturnOrder()
    const { returnOrderEmailResponse, returnOrderEmailSending, submitReturnOrderEmail, resetSubmitOrderEmail } = useSubmitReturnOrderMail()
    const { removeReturnItems, isRemovingReturnItems } = useRemoveReturnItems()

    const disabled = isIncludingItemsInReturn || isRemovingReturnItems || returnOrderSending
    const [isPrintLoading, setPrintLoading] = useState(false)

    const response = useMemo(() => {
        if (fromVouchersModule) {
            return returnOrderEmailResponse
        }

        return returnOrderResponse
    }, [returnOrderResponse, returnOrderEmailResponse, fromVouchersModule])

    useEffect(() => {
        if (fromVouchersModule) {
            resetSubmitOrderEmail()
        } else {
            resetSubmitOrder()
        }
        // only execute on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromVouchersModule])

    const selectedReturnItems = useMemo(() => {
        return updatedReturnItems?.filter((item) => selectedIds.includes(item.id))
    }, [selectedIds, updatedReturnItems])

    const { cisVoucherUrl, orderVoucherUrl, printing } = getBundleParams()

    function openVoucherUrl(url: string) {
        history.push(url)
    }

    const redirectToOrdersUrl = useCallback(
        (voucherTypeId: number, orderId: string, voucherId?: string, subId?: string) => {
            if (orderVoucherUrl) {
                const openUrlParams = {
                    workTaskId: matchParams.workTaskId,
                    type: mapVoucherTypeForUrl(VoucherType.Order),
                    id: voucherId,
                }

                const url = renderRoute(orderVoucherUrl, openUrlParams)
                history.push(url)
            } else if (cisVoucherUrl) {
                const ordersUrlParams = {
                    workTaskId: matchParams.workTaskId,
                    voucherTypeId,
                    id: orderId,
                    subId,
                }

                let url = renderRoute(cisVoucherUrl, ordersUrlParams)
                url += createQueryString({ singleSearch: true })

                history.push(url)
            }
        },
        [cisVoucherUrl, orderVoucherUrl, history, matchParams]
    )

    function handleReturnOrderButtonClick() {
        if (fromVouchersModule) {
            submitReturnOrderEmail({})
        } else {
            submitReturnOrder({ customerReturnOrderNumber: undefined })
        }
    }

    function handleRemoveReturnItems(returnItems: Array<ReturnItem>) {
        removeReturnItems(returnItems).then(() => {
            resetSubmitOrder()
        })
    }

    function handleIncludeInReturn(items: Array<Item>, include: boolean) {
        includeInReturn({ include, items }).then(() => {
            resetSubmitOrder()
        })
    }

    function handleInclude(include: boolean) {
        const items = selectedReturnItems
            ?.filter((item) =>
                include ? item.returnStatus === ReturnStatus.ExcludedFromReturn : item.returnStatus === ReturnStatus.IncludedInReturn
            )
            .map<Item>((part) => {
                return { id: part.id, version: part.version }
            })
        if (items) {
            includeInReturn({ include, items }).then(() => {
                resetSubmitOrder()
            })
        }
    }

    function handleResetSubmitOrder() {
        resetSubmitOrder()
    }

    function handlePrintLoading(loading: boolean) {
        setPrintLoading(loading)
    }

    function handlePrintButtonClick() {
        if (updatedReturnItems?.length === 0) {
            return
        }

        printReturnItemsList(printing, language, translateText, handlePrintLoading, telesalesCustomerNo)
    }

    function areSelectedPartsEqual(selectedParts: Array<ReturnItem>): boolean {
        if (
            selectedParts.some((part) => part.returnStatus === ReturnStatus.IncludedInReturn) &&
            selectedParts.some((part) => part.returnStatus !== ReturnStatus.IncludedInReturn)
        ) {
            return false
        }
        return true
    }

    function renderItemsSelection() {
        if (updatedReturnItems && updatedReturnItems?.length > 0) {
            const selectedParts = updatedReturnItems?.filter((part) => selectedIds.includes(part.id))
            if (selectedParts.length > 0) {
                const allSelected = updatedReturnItems.length === selectedParts.length
                return (
                    <PartsSelection
                        items={selectedParts.length}
                        articles={selectedParts.map((part) => part.returnQuantity).reduce((sum, current) => sum + current)}
                        allSelected={allSelected}
                        equalReturnStatus={areSelectedPartsEqual(selectedParts)}
                        selectedParts={selectedParts}
                        selectedPartsIncludedInOrder={!!selectedParts.some((part) => part.returnStatus === ReturnStatus.IncludedInReturn)}
                        selectedPartsBeingUpdated={false}
                        onSelectAll={selectAll}
                        onUnselectAll={unselectAll}
                        onRemoveReturnItems={handleRemoveReturnItems}
                        onUpdateItemsReturnStatus={handleInclude}
                    />
                )
            }
        }
    }

    function renderHeader() {
        const disableReturnOrder =
            returnItemsLoading || returnOrderEmailSending || !totalNumbers?.numberOfPositionsInReturn || totalNumbers.numberOfPositionsInReturn === 0
        return (
            <>
                <Headline>{translateText(1515)}</Headline>
                <div>
                    <div className="return-summary">
                        {(returnOrderSending || returnOrderEmailSending) && <Loader />}
                        {!returnOrderSending && !returnOrderEmailSending && (
                            <div className="button-container">
                                <Button icon="orders" disabled={disableReturnOrder} onClick={handleReturnOrderButtonClick} skin="highlight">
                                    {translateText(1621)}
                                </Button>
                                <div className="print-button-wrapper">
                                    {isPrintLoading && <Badge title="loading" skin="dark" loading />}
                                    <Button
                                        className="print-return-items-button"
                                        icon="print"
                                        disabled={!updatedReturnItems || updatedReturnItems?.length === 0 || isPrintLoading}
                                        skin="primary"
                                        onClick={handlePrintButtonClick}
                                        title={translateText(13164)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="totals">
                            {totalNumbersLoading && <Loader />}
                            {!totalNumbersLoading && totalNumbers && (
                                <>
                                    <Text>
                                        {`${translateText(479)} ${
                                            totalNumbers.numberOfPositionsInReturn ? totalNumbers.numberOfPositionsInReturn : 0
                                        }`}
                                        ,{" "}
                                    </Text>
                                    <Text>{`${translateText(90)} ${
                                        totalNumbers.numberOfPartsInReturn ? totalNumbers.numberOfPartsInReturn : 0
                                    }`}</Text>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="parts-selection">{renderItemsSelection()}</div>
                </div>
            </>
        )
    }

    function renderPartList() {
        return (
            <>
                <Headline size="xs">{translateText(152)}</Headline>
                <PartsList
                    loading={returnItemsLoading}
                    ordersUrl={cisVoucherUrl}
                    returnItems={updatedReturnItems ?? []}
                    selectedIds={selectedIds}
                    cisDisplayMode={cisDisplayMode}
                    isUpdating={disabled}
                    fromVouchersModule={fromVouchersModule}
                    onChangeReturnStatus={handleIncludeInReturn}
                    onOpenDetails={onOpenPartDetails}
                    onRemoveItems={handleRemoveReturnItems}
                    onSelectItem={toggleSelected}
                    onShowOrder={redirectToOrdersUrl}
                    onResetSubmitOrder={handleResetSubmitOrder}
                />
            </>
        )
    }

    function renderOrderCompleted() {
        if (!response) {
            return null
        }

        return (
            <OrderCompleted
                response={response}
                fromVouchersModule={fromVouchersModule}
                onClearOrderSent={fromVouchersModule ? resetSubmitOrderEmail : resetSubmitOrder}
                onOpenVoucherUrl={openVoucherUrl}
            />
        )
    }

    return (
        <div className="tk-cis returns">
            <div className="header">{renderHeader()}</div>
            {renderOrderCompleted()}
            <div className="parts">{renderPartList()}</div>
        </div>
    )
}
