import { Channel, MessageBus } from "@tm/hermes"
import { getCurrentWorkTaskId } from "@tm/models"
import { FilterType } from "./enums/filterType"

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "TYRES"> {
    if (!mb) {
        mb = (window as any).__NEXT_MESSAGEBUS__
    }

    return mb.channel("TYRES", getCurrentWorkTaskId())
}

export interface BundleChannels {
    TYRES: BundleChannelType
}

export type BundleChannelType = { LOAD_LIST: { matchCode?: string; byMatchCode?: boolean; carType?: string; season?: string[] } } & {
    LOAD_TYRES_CLIPPED_FILTERS: { source: "filter" | "manager"; value: FilterType }
}
