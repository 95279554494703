import { useState } from "react"
import { ArticleErpInfo } from "@tm/models"
import { ComposerComponent, ContextComposer } from "../ContextProvider"
import { ActiveVehicleDataProvidersProvider } from "../ContextProvider/ActiveVehicleDataProviders"
import { BasketQuantitiesProvider, WholesalerBasketQuantitiesProvider } from "../ContextProvider/BasketQuantities"
import { ListOptionsProvider } from "../ContextProvider/ListOptions"
import { NotesProvider } from "../ContextProvider/Notes"
import { ProductGroupRepairTimesProvider } from "../ContextProvider/ProductGroupRepairTimes"
import { ProductGroupTopicIdsProvider } from "../ContextProvider/ProductGroupTopicIds"
import { SupplierLogosProvider } from "../ContextProvider/SupplierLogos"
import { WatchListProvider } from "../ContextProvider/WatchList"
import { OffersListParamsProvider } from "./ContextProvider/ListParams"
import { OffersList } from "./OffersList"
import { ArticleListActionsProvider } from "../ContextProvider/ArticleListActions"
import { OffersArticlesProvider } from "./ContextProvider/Articles"
import { EmptyFiltersProvider } from "../ContextProvider/Filters"
import { MultiErpInfosProvider } from "../ContextProvider/ErpInfos/MultiErpInfosProvider"
import { TradeReferenceProvider } from "../ContextProvider/TradeReferences"

export function Offers() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [OffersListParamsProvider],
        [EmptyFiltersProvider],
        [OffersArticlesProvider, { setHasRendered: setHasListRendered }],
        [ProductGroupTopicIdsProvider, { isEnabled: hasListRendered }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [MultiErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [WholesalerBasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [ArticleListActionsProvider],
    ]
    return (
        <ContextComposer components={components}>
            <OffersList />
        </ContextComposer>
    )
}
