import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import WorktaskManager from "./components/workTaskManager"
import SearchMicro from "./micros/partsSearchWidget"
import { initEurotaxBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    // debug: true,
    components: [Main, WorktaskManager],
    micros: [SearchMicro],
    init: initEurotaxBundle,
}

export default bundle
