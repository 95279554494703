import { ShowCostEstimationDetailsResponse } from "@tm/models"
import { mapCostEstimationDetail } from "../../../data/mapper"
import { ComponentActionType } from "."

function clearOfferDetails(): ComponentActionType {
    return { type: "OFFER_DETAILS_CLEARED" }
}

function setCostEstimationDetails(response: ShowCostEstimationDetailsResponse): ComponentActionType {
    return { type: "COST_ESTIMATION_DETAILS_LOADED", payload: mapCostEstimationDetail(response) }
}

function setCostEstimationNote(note: string): ComponentActionType {
    return { type: "COST_ESTIMATION_NOTE_MODIFIED", payload: note }
}

export type IActions = typeof Actions

// TODO Basket V2: Check which actions can be removed
export const Actions = {
    clearOfferDetails,
    setCostEstimationDetails,
    setCostEstimationNote,
}
