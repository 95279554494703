import { Box } from "@tm/components"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CategoryType } from "@tm/models"
import { FC, ReactNode } from "react"
import { SearchType } from "../../../business"
import { FiltersAnchor } from "./FilterAnchor"

type Props = {
    articleFiltersInLeft: boolean
    searchType?: SearchType
    categoryType?: CategoryType
    clippedFiltersClassName?: string
}

export const NoSearch: FC<Props> = (props) => {
    const { translate } = useLocalization()
    const { articleFiltersInLeft, categoryType, searchType, clippedFiltersClassName } = props

    let text: ReactNode

    switch (categoryType) {
        case "vehicleParts":
        case "universalParts": {
            text = translate(1012)
            break
        }
        case "directSearch": {
            text = translate(1013)
            break
        }
        default:
    }

    return (
        <Box className="searchtreeV2 tk-parts">
            {articleFiltersInLeft && <FiltersAnchor searchType={searchType} className={clippedFiltersClassName} />}
            <Box className="list">
                <Box className="article-list__panel article-list__status" mt="50px">
                    <Box className="article-list__no-search">
                        <Icon name="bulb" />
                        <Text size="l">{text}</Text>
                    </Box>
                </Box>
            </Box>
            {!articleFiltersInLeft && <FiltersAnchor searchType={searchType} className={clippedFiltersClassName} />}
        </Box>
    )
}
