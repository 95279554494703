import { AddOePartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import { useAddToBasketModuleParameters } from "@tm/context-distribution"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useCreateBasketPartPostMessage } from "../../../../hooks/useCreatePartPostMessage"
import { useHandlePostSendOrderAction } from "../../../../hooks/useHandlePostSendOrderAction"

export function useAddOePartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { sendArticlesPostMessage, addToNextBasket } = useAddToBasketModuleParameters()
    const { getMessageByOePart } = useCreateBasketPartPostMessage()
    const { handlePostSendOrderAction } = useHandlePostSendOrderAction()
    const { mutateAsync: addOePartList } = useMutation((addWOePartListRequest: AddOePartListRequest) => Data.addOePartList(addWOePartListRequest), {
        onSuccess: (response, request) => {
            if (response) {
                handleBasketUpdateWorkflow(request.workTaskId, response)
            }

            // Save work task in the DB
            Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
        },
    })
    const addOeParts = useCallback(
        (request: AddOePartListRequest) => {
            if (sendArticlesPostMessage) {
                const message = getMessageByOePart(request.oeParts, request.workTaskId)
                handlePostSendOrderAction(message)
            }
            if (addToNextBasket !== false) {
                return addOePartList(request)
            }
            return Promise.reject(new Error("no function"))
        },
        [addToNextBasket, sendArticlesPostMessage, getMessageByOePart]
    )

    return addOeParts
}
