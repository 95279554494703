import { em, important, rem } from "csx"
import { ReactElement, memo, useEffect, useMemo, useState } from "react"
import { getStyleTheme, useStyle, useUser } from "@tm/context-distribution"
import { AmountField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    Article,
    ArticleAttribute,
    EFilterNames,
    ErpPartsModifiers,
    IMicros,
    RepairTimeProvider,
    RequestArticleDetailsPayload,
    TyreArticle,
    TyreArticleAttribute,
    TyreFilter,
    Vehicle,
} from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import {
    RouteComponentProps,
    bem,
    encodeUniqueId,
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    renderRoute,
    uniqueId,
    withRouter,
} from "@tm/utils"
import { batch, useDispatch, useSelector } from "react-redux"
import { Button, Icon, Tooltip, styled } from "@tm/components"
import { getAttributeString } from "../tyres-list/business/helpers"
import TiresIcons from "./tiresIcons"
import { MainState } from "../main"
import { getIconByGroup, getTitleByGroup } from "../../data/helpers"
import { MainActions } from "../main/business"

type Props = RouteComponentProps & {
    item: TyreArticle
    repairTimesRoute: string
    vehicleId?: string
    tecDocTypeId?: number
    vehicle?: Vehicle
    hidden?: boolean
    quantityModifiers?: ErpPartsModifiers
    selectedArticleAttributes?: string[]
    onArticleDetailsClick: (item: TyreArticle, request: RequestArticleDetailsPayload) => void
    onArticleAttributeSelect(article: TyreArticle, attribute: ArticleAttribute): void
    onQuantityChange: (item: TyreArticle, value: number) => void
    onSelect: (item: TyreArticle) => void
    onAddToBasket?: (item: TyreArticle, isSelected: boolean) => void
    isSelected?: boolean
    isDisabled?: boolean
}

function TireArticleItem({
    repairTimesRoute,
    hidden,
    onArticleAttributeSelect,
    item,
    isSelected,
    match,
    onArticleDetailsClick,
    onAddToBasket,
    onQuantityChange,
    quantityModifiers,
    selectedArticleAttributes,
    vehicleId,
    onSelect,
    isDisabled,
}: Props): ReactElement {
    const { translateText } = useLocalization()
    const user = useUser()
    // const params = useParams() TODO ?? FIND WHY functions is undefined
    const { renderMicro } = useMicro<IMicros>()
    const style = useMemo(() => getStyle(), [])

    const providers = useSelector((s: MainState) => s.tyresList.repairTimeAvailabilities?.[item.productGroup.id])
    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle == "wm"
    const showArticleImages = user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages
    const isCompact = user?.userSettings?.articleListSettings?.viewOptions?.compactView

    const [opened, setOpened] = useState(isCompact)

    const dispatch = useDispatch()
    const { filters, selectedFilters } = useSelector((s: MainState) => ({
        filters: s.tyresList.filters,
        selectedFilters: s.tyresList.selectedFilters,
    }))

    const { repairTimeProviders } = getRepairTimeProviders()

    useEffect(() => {
        if (opened != isCompact) {
            setOpened(isCompact)
        }
    }, [isCompact])

    const getRepairTimesUrl = (article: any, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
        if (article.productGroup && repairTimeProviders.length && user?.userSettings) {
            let provider
            if (Array.isArray(rtProviders)) {
                const activeRTProvider = user?.userSettings.activeVehicleDataProviders.repairTimes
                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider) {
                return
            }

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    workTaskId: encodeUniqueId(uniqueId()),
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide,
                })
            )
        }
    }

    const handleToggleOpened = () => setOpened((prevState) => !prevState)

    const isAttrSelected = (attribute: any) => {
        return selectedArticleAttributes?.some((selAtr) => selAtr == getAttributeString(attribute))
    }

    const renderIcons = () => {
        const allAttr = [...item.attributes?.[0]?.topAttributes, ...item.attributes?.[0]?.articleAttributes]

        const fuelEfficiency = allAttr.find((x) => x.group == EFilterNames.fuelEfficiency)
        const wetGripClass = allAttr.find((x) => x.group == EFilterNames.wetGripClass)
        const externalRolling = allAttr.find((x) => x.group == EFilterNames.externalRolling)

        const items: (TyreArticleAttribute & { selected?: boolean })[] = []
        if (fuelEfficiency) {
            items.push({ ...fuelEfficiency, selected: isAttrSelected(fuelEfficiency) })
        }
        if (wetGripClass) {
            items.push({ ...wetGripClass, selected: isAttrSelected(wetGripClass) })
        }
        if (externalRolling) {
            items.push({ ...externalRolling, selected: isAttrSelected(externalRolling) })
        }

        return <TiresIcons onSelect={(attr) => onArticleAttributeSelect(item, attr)} items={items} />
    }

    const renderActions = (_: Article, showButtonText?: boolean) => {
        return (
            <div className="tk-basket">
                <div className="add-to-basket">
                    <AmountField value={item.quantity} onChange={(x) => onQuantityChange(item, x.value)} />
                    <Button
                        sx={{ minWidth: showButtonText ? "6.25em" : "" }}
                        className="addToBasketButton"
                        variant="bordered"
                        color="highlight"
                        startIcon={<StyledIcon name="cart" />}
                        onClick={() => onAddToBasket?.(item, !!isSelected)}
                    >
                        {showButtonText ? translateText(13110) : ""}
                    </Button>
                </div>
            </div>
        )
    }
    const renderSeasonIcon = (className?: string) => {
        return (
            <Tooltip title={translateText(getTitleByGroup(item.productGroup.season))}>
                <StyledIcon name={getIconByGroup(item.productGroup.season)} className={className} />
            </Tooltip>
        )
    }

    const renderSupplierWithSeason = () => {
        if (isWM) {
            return (
                <div className={style.sup_wrapper}>
                    {renderIcons()}
                    {renderSeasonIcon("wrapped-icon")}
                </div>
            )
        }
        return <>{renderSeasonIcon(style.icon)}</>
    }

    const manufacturerIndex = filters.manufacturer.findIndex((x) => x.value === item.supplier.name)

    const handleManufacturerClick = (value: TyreFilter) => {
        batch(() => {
            dispatch(MainActions.updateFilter(EFilterNames.manufacturer, value))
            dispatch(MainActions.loadTiresList(true, undefined))
        })
    }

    return (
        <>
            {renderMicro!("parts", "part-item", {
                className: bem(style.articleItem, hidden && "hidden", Morpheus.getParams("parts")?.templates?.articleItem?.bundle),
                part: item,
                vehicleId,
                isCompact: opened,
                renderCustomIcons: renderIcons,
                renderCustomSeason: renderSupplierWithSeason,
                onToggleCollapse: handleToggleOpened,
                rtProviders: providers,
                canFilterArticleAttributes: true,
                quantityModifiers,
                selectedArticleAttributes,
                onRequestArticleDetails: (request) => onArticleDetailsClick(item, request),
                onArticleAttributeSelect,
                onArticleSelect: () => onSelect(item),
                isSelected,
                showArticleImage: showArticleImages,
                renderBuyActions: renderActions,
                getRepairTimesUrl,
                showActions: true,
                ignoreAttributeKey: true,
                hideAlternativesIcon: true,
                isDisabled,
                isDataSupplierFilterActive: !!selectedFilters?.manufacturer?.length,
                onDataSupplierClick: () => handleManufacturerClick(filters.manufacturer[manufacturerIndex]),
            })}
        </>
    )
}

export default memo(withRouter(TireArticleItem))

const StyledIcon = styled(Icon)({
    width: "1.5em",
    height: "1.5em",
})

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        articleItem: {
            $nest: {
                ".article__cell--supplier .supplier__name ": {
                    width: em(6),
                },
                ".article__cell--supplier .image": {
                    maxHeight: rem(2),
                    maxWidth: rem(5),
                },
                "&--wm .article__cell--supplier ": {
                    flexDirection: "column-reverse",
                },
                ".article__cell--numbers": {
                    width: em(10),
                },
                ".article__cell.article__cell--erp-information": {
                    minWidth: em(20.5),
                    $nest: {
                        ".tk-erp": {
                            width: "100%",
                            $nest: {
                                ".erp-info__availability:nth-child(1)": {
                                    marginLeft: "auto",
                                },
                                ".erp-info": {
                                    justifyContent: "flex-end",
                                },
                            },
                        },
                    },
                },
                "&--hidden": {
                    display: "none",
                },
                "&--wm": {
                    $nest: {
                        ".add-to-compilation": {
                            marginRight: important(0),
                        },
                        ".add-to-cost-estimate": {
                            marginLeft: theme.margin.m,
                        },
                        ".basket-button": {
                            marginLeft: "auto",
                        },
                    },
                },
            },
        },
        icon: {
            marginRight: em(1),
        },
        sup_wrapper: {
            display: "flex",
            justifyContent: "space-between",
            $nest: {
                ".wrapped-icon": {
                    paddingLeft: em(0.2),
                },
            },
        },
    })(TireArticleItem)
}
