import { Fixture } from "@bundles/wheels/data/repositories/wheels-loadRimFixtures/model"
import { FilterCriterias } from "../../../data/enums"
import { RimDetailsArticle, RimErpInfo } from "../../../data/model"
import { RimsDetailsArticlesRequest } from "../../../data/repositories/wheels-loadRimDetailsArticles/model"
import { RimRestrictionRequest } from "../../../data/repositories/wheels-loadRimRestrictions/model"
import { TiresRestrictionsRequest } from "../../../data/repositories/wheels-loadTireRestrictions/model"
import { TireSizesRequest } from "../../../data/repositories/wheels-loadTireSizes/model"
import { mapSelectedFiltersForRequest } from "../../wheels-list/business/helper"
import { SelectedFilters } from "../../wheels-list/business/model"
import { ValueItemsDefinition, WheelsDetailsState } from "./model"

export function createRimDetArticlesRequest(
    carparkId: number,
    diameter: string,
    idRimDesign: number,
    selectedFilters: Partial<SelectedFilters>,
    articlesId?: string,
    registrationNo?: string,
    isSSW?: boolean,
    isConti?: boolean,
    rimErpInformations?: RimErpInfo[],
    isAdditionalManufacturerSelected?: boolean
): RimsDetailsArticlesRequest {
    const rimFilter = mapSelectedFiltersForRequest(selectedFilters)
    let rimInfo: string | undefined = ""

    if (rimErpInformations) {
        rimInfo = mapRimInfo(rimErpInformations, diameter)
    }

    return {
        idRim: 0,
        diameter,
        idRimDesign,
        rimFilter,
        articlesId: articlesId || undefined,
        registrationNo,
        isSSW,
        isConti,
        carparkId,
        rimInfo,
        isAdditionalManufacturerSelected,
    }
}

function mapRimInfo(rimErpInformations: RimErpInfo[], diameter: string) {
    const relevantInformations = rimErpInformations.filter(
        (rimInfo) => rimInfo.diameterSize === diameter.toString() && rimInfo.wholesalerArticleNumber?.length > 0
    )

    if (!relevantInformations.length) {
        return
    }

    let rimInfo: string = ""
    relevantInformations.forEach((info) => {
        rimInfo += `${info.dataSupplierArticleNumber},${info.wholesalerArticleNumber};`
    })
    return rimInfo
}

export function createRimRestrictionRequest(carparkId: number, selectedRimDetailsArticle?: RimDetailsArticle): RimRestrictionRequest {
    return {
        carparkId,
        idRim: selectedRimDetailsArticle?.idRim ?? 0,
        appId: selectedRimDetailsArticle?.applicationId ?? 0,
    }
}

export function createTireRestrictionRequest(carparkId: number, state: WheelsDetailsState): TiresRestrictionsRequest | undefined {
    const { selectedRimDetailsArticle, selectedTireSize } = state

    if (!selectedTireSize) {
        return
    }
    return {
        carparkId,
        idrim: (selectedRimDetailsArticle && selectedRimDetailsArticle.idRim) || 0,
        idtyresize: selectedTireSize?.idTyreSize ?? 0,
        appId: selectedRimDetailsArticle?.applicationId ?? 0,
    }
}

export function createTireSizesRequest(carparkId: number, state: WheelsDetailsState): TireSizesRequest {
    const { selectedRimDetailsArticle, selectedFilters } = state

    let rimFilter = mapSelectedFiltersForRequest(selectedFilters)
    if (selectedRimDetailsArticle?.applicationId) {
        rimFilter += `${FilterCriterias.applicationId},${selectedRimDetailsArticle.applicationId};`
    }
    return {
        carparkId,
        idRim: selectedRimDetailsArticle?.idRim ?? 0,
        rimFilter,
    }
}

export const technicalDataFields: Array<ValueItemsDefinition<keyof RimDetailsArticle>> = [
    { id: 0, description: 713, value: "aspectRatio" },
    { id: 1, description: 715, value: "wheelSize" },
    { id: 2, description: 1133, value: "boltCircle" },
    { id: 3, description: 1135, value: "offset" },
    { id: 4, description: 1820, value: "hubbore" },
    { id: 5, description: 71, value: "supplierName" },
    { id: 6, description: 387, value: "eanCode" },
    { id: 7, description: 1823, value: "artNr" },
    { id: 8, description: 1136, value: "winterFlag" },
]

export const fixtureItems: Array<ValueItemsDefinition<keyof Fixture>> = [
    { id: 9, description: 13796, value: "collar" },
    { id: 10, description: 13829, value: "matchCode" },
    { id: 11, description: 13797, value: "nonDiameter" },
    { id: 12, description: 13798, value: "pitch" },
    { id: 13, description: 13800, value: "shaftlen" },
    { id: 14, description: 13801, value: "torque" },
]
