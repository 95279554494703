import { FC, useCallback, useEffect, useRef, useState } from "react"
import { StatusCodes } from "http-status-codes"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { uniqueId } from "@tm/utils"
import { getStyleTheme } from "@tm/context-distribution"
import { Dropdown, Text, Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"

import { DMS } from "@tm/models"
import { connectionChannel, messageChannel } from "../../../../business/messaging"

function getComponentStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()

    return {
        $nest: {
            "> .btn": {
                $nest: {
                    ".btn__content": {
                        minWidth: "7.5em",
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    },
                    ".btn__icon": {
                        height: "1.5em",
                        width: "1.5em",
                        margin: "-0.5em 0",
                    },
                },
            },
            "&__btn--clear": {
                marginLeft: margin.s,
                padding: 0,
                $nest: {
                    ".btn__icon": {
                        height: "1.25em !important",
                        width: "1.25em !important",
                    },
                },
            },
        },
    }
}

type Props = {
    selected: DMS.VoucherType | undefined
    onChange(voucherType: DMS.VoucherType | undefined): void
}

export const VoucherTypeFilter: FC<Props> = ({ onChange, selected }) => {
    const className = style(getComponentStyles())
    const { translate } = useLocalization()

    const [isConnected, setIsConnected] = useState(() => connectionChannel().last(1, "CONNECTION_STATUS_RECEIVED")[0]?.content?.isConnected ?? false)
    const [voucherTypes, setVoucherTypes] = useState<Array<DMS.VoucherType>>([])

    const dropdownRef = useRef<Dropdown<DMS.VoucherType>>(null)

    useEffect(() => {
        return connectionChannel().subscribe(
            "CONNECTION_STATUS_RECEIVED",
            (response) => {
                setIsConnected(!!response.isConnected)
            },
            true
        )
    }, [])

    useEffect(() => {
        if (!isConnected) {
            return
        }

        const processId = uniqueId()

        const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
            if (e.type === "findVoucherTypes" && e.processId === processId) {
                unsub()

                if (e.status === StatusCodes.OK && e.response) {
                    setVoucherTypes(e.response.voucherTypes)
                }
            }
        })

        messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "findVoucherTypes", request: undefined })
    }, [isConnected])

    const itemView = useCallback((voucherType: DMS.VoucherType) => {
        return <Text>{voucherType.description}</Text>
    }, [])

    const handleButtonClick = useCallback(() => {
        onChange(undefined)
        dropdownRef.current?.toggleDropdownMenu()
    }, [onChange])

    const displayView = useCallback(
        (voucherType: DMS.VoucherType) => {
            return (
                <>
                    {itemView(voucherType)}
                    <Button fakeButton className={`${className}__btn--clear`} icon="close" layout={["ghost"]} onClick={handleButtonClick} />
                </>
            )
        },
        [className, handleButtonClick, itemView]
    )

    const coverView = useCallback(() => <Text>{translate(939)}</Text>, [translate])

    return (
        <Dropdown<DMS.VoucherType>
            className={className}
            layout={["iconRight"]}
            disabled={!voucherTypes.length}
            items={voucherTypes}
            displayView={displayView}
            itemView={itemView}
            coverView={coverView}
            value={selected}
            onChange={onChange}
            ref={dropdownRef}
        />
    )
}
