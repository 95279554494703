import { useCallback, useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { MoreMenu } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, IMicros } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { useOpenArticleDetails } from "@tm/utils"
import { Card, CardActions, CardContent, ArticleNumber, Box, Stack, Typography, Image } from "@tm/components"
import { getBundleParams } from "../../../utils"

type Props = {
    workTaskId: string
    part: Article
    advertisementCategoryId: string
    onRequestPartList(showAddToBasketMessage?: boolean): void
}

export function OfferItemCatalogPart({ workTaskId, part, advertisementCategoryId, onRequestPartList }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()
    const { partsDetailsModalUrl } = getBundleParams()

    const basketParts = useMemo(() => (part ? [part] : []), [part])

    const requestPartList = useCallback((showAddToBasketMessage?: boolean) => onRequestPartList(showAddToBasketMessage), [onRequestPartList])

    const handleOpenArticleDetails = useOpenArticleDetails({
        workTaskId,
        productGroupId: part.productGroup.id,
        supplierId: part.supplier.id,
        supplierArticleNumber: part.supplierArticleNo,
        partsDetailsUrl: partsDetailsModalUrl,
        openModal: Morpheus.showView,
    })

    function openDetails(subPage?: string, scrollTo?: string) {
        setTimeout(() => {
            handleOpenArticleDetails(subPage, scrollTo)
        }, 5000)

        requestPartList(false)
    }

    return (
        <Card key={part.id} sx={{ display: "flex", flexDirection: "column", height: "100%", width: "33.3%" }}>
            <CardContent sx={{ flex: 1 }}>
                <Box mt={0.5} height="4rem" display="flex" alignItems="center" justifyContent="center">
                    <Image src={part.thumbnail} />
                </Box>
                <Box pt={1.5} position="relative" display="flex" justifyContent="space-between" height="5em">
                    {renderMicro("erp", "erp-info-prices", {
                        data: part,
                        hideRetailPrice: true,
                    })}
                    {renderMicro("erp", "erp-info-availability", {
                        data: part,
                        onClick: (useSpecialProcurement?: boolean) => {
                            if (useSpecialProcurement) {
                                openDetails("special-procurement", "#load-teccom")
                            } else {
                                openDetails("stocks")
                            }
                        },
                        onTeccomClick: (useSpecialProcurement: boolean) => {
                            if (useSpecialProcurement) {
                                openDetails("special-procurement", "#load-teccom")
                            } else {
                                openDetails("stocks", "teccom")
                            }
                        },
                    })}
                </Box>
                <Stack
                    alignItems={userContext?.parameter.positionChangeEArtNrHArtNr ? "flex-end" : "flex-start"}
                    direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}
                    height="3em"
                >
                    {part.traderArticleNo && !userContext?.parameter.hideDealerPartNumber && (
                        <ArticleNumber
                            articleNumber={part.traderArticleNo}
                            articleNumberType="wholesaler"
                            omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                            onClick={openDetails}
                        />
                    )}
                    {part.supplierArticleNo && (
                        <ArticleNumber
                            articleNumber={part.supplierArticleNo}
                            articleNumberType="supplier"
                            omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                            onClick={openDetails}
                        />
                    )}
                </Stack>
                <Box height="3.5em">
                    <Typography variant="body2" fontWeight="bold" maxLines={3}>
                        {part.productGroup.name}
                    </Typography>
                </Box>
                <Box height="2em">
                    <Typography variant="body2" maxLines={2}>
                        {part.supplier.name}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body2" maxLines={3}>
                        {part.description}
                    </Typography>
                </Box>
                <Box>
                    {renderMicro("erp", "erp-info-additional-information", {
                        data: part,
                        maxLines: 1,
                        variant: "body2",
                    })}
                </Box>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
                {renderMicro("basket", "add-to-basket", {
                    data: basketParts,
                    hideQuantityField: true,
                    generatedWorktaskId: workTaskId,
                    advertisementCategoryId,
                    onAddCatalogArticleToBasketFinished: () => requestPartList(true),
                })}
                <MoreMenu
                    items={[
                        {
                            icon: "search",
                            text: translateText(43),
                        },
                    ]}
                    onMenuItemSelect={() => {
                        openDetails()
                    }}
                />
            </CardActions>
        </Card>
    )
}
