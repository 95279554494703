import { ConceptPageCredentials, KeyValueStateContainer, RegisteredModels, UrlResolverContainer, UserContext, channel } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import * as Data from "../../../data"
import { GetStreetsRequest, Permission, SetDutchOrdersMDMParamsRequest, ShowSubUsersResponse, Street, PermissionContainer } from "../../../data/model"
import { UserSettingsState } from "./model"

export * from "./model"

export type ComponentActionType =
    | { type: "ADDRESS_SUGGESTIONS_LOADING" }
    | { type: "ADDRESS_SUGGESTIONS_LOADED"; payload: Array<Street> }
    | { type: "RESET_ADDRESS_SUGGESTIONS" }
    | { type: "SHOW_SUB_USERS_LOADING" }
    | { type: "SHOW_SUB_USERS_LOADED"; payload: ShowSubUsersResponse | undefined }
    | { type: "CONCEPT_PAGE_LOADING" }
    | { type: "CONCEPT_PAGE_UPDATED" }
    | { type: "SHOW_CONCEPT_PAGE_SETTINGS"; payload: boolean }
    | { type: "CONCEPT_PAGE_CREDENTIALS_FOUND"; payload: boolean }
    | { type: "DUTCH_ORDER_MDM_LOADED" }
    | { type: "DUTCH_ORDER_MDM_ERROR" }
    | { type: "DUTCH_ORDER_MDM_LOADING" }

const DEFAULT_STATE: UserSettingsState = {
    addressSuggestionsLoading: false,
    subUsersLoading: false,
    userAdminConfig: {
        adminCanEditUserPassword: false,
    },
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): UserSettingsState {
    switch (action.type) {
        case "ADDRESS_SUGGESTIONS_LOADING": {
            return {
                ...state,
                addressSuggestionsLoading: true,
            }
        }
        case "ADDRESS_SUGGESTIONS_LOADED": {
            return {
                ...state,
                addressSuggestionsLoading: false,
                addressSuggestions: action.payload,
            }
        }
        case "RESET_ADDRESS_SUGGESTIONS": {
            return {
                ...state,
                addressSuggestions: DEFAULT_STATE.addressSuggestions,
            }
        }
        case "SHOW_SUB_USERS_LOADING": {
            return {
                ...state,
                subUsersLoading: true,
            }
        }
        case "SHOW_SUB_USERS_LOADED": {
            return {
                ...state,
                subUsersLoading: false,
                subUsers: action.payload?.subUsers,
                userAdminConfig: action.payload?.userAdminConfig || state.userAdminConfig,
                subUsersPermissionContainer: action.payload?.permissions,
            }
        }
        case "SHOW_CONCEPT_PAGE_SETTINGS": {
            return {
                ...state,
                conceptPage: action.payload,
            }
        }
        case "CONCEPT_PAGE_LOADING": {
            return {
                ...state,
                conceptPage: false,
                conceptPageLoading: true,
            }
        }
        case "CONCEPT_PAGE_UPDATED": {
            return {
                ...state,
                conceptPageLoading: false,
                conceptPageUpdated: true,
            }
        }
        case "CONCEPT_PAGE_CREDENTIALS_FOUND": {
            return {
                ...state,
                conceptPageHasCredentials: action.payload,
                conceptPageLoading: false,
            }
        }
        case "DUTCH_ORDER_MDM_LOADED": {
            return {
                ...state,
                rdwCompanyInfoLoading: false,
                rdwCompanyInfoLoaded: true,
            }
        }
        case "DUTCH_ORDER_MDM_ERROR": {
            return {
                ...state,
                rdwCompanyInfoLoading: false,
                rdwCompanyInfoError: true,
            }
        }
        case "DUTCH_ORDER_MDM_LOADING": {
            return {
                ...state,
                rdwCompanyInfoLoading: true,
            }
        }
        default:
            break
    }

    return state
}

function loadAddressSuggestions(street?: string, zip?: string, city?: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        const request: GetStreetsRequest = {
            countryCode: "DE",
            latitude: 53.720903,
            longitude: 10.281732,
            postcode: zip,
            region: city,
            street,
        }

        dispatch({ type: "ADDRESS_SUGGESTIONS_LOADING" })

        Data.getStreets(request).then((list) =>
            dispatch({
                type: "ADDRESS_SUGGESTIONS_LOADED",
                payload: list,
            })
        )
    }
}

function resetAddressSuggestions(): ComponentActionType {
    return { type: "RESET_ADDRESS_SUGGESTIONS" }
}

function showSubUsers(languageId: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "SHOW_SUB_USERS_LOADING" })

        Data.showSubUsers(languageId).then((response) => dispatch({ type: "SHOW_SUB_USERS_LOADED", payload: response }))
    }
}

function saveConceptPageCredentials(credentials: ConceptPageCredentials): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "CONCEPT_PAGE_LOADING" })
        const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
        const jsonCreds = JSON.stringify(credentials)
        container
            .action("saveKeyValue")("CONCEPT_PAGE_CREDENTIALS", jsonCreds, true)
            .then(() => {
                dispatch({ type: "CONCEPT_PAGE_UPDATED" })
                channel("GLOBAL").publish("CONCEPT_PAGE/CREDENTIALS_CHANGED", credentials)
            })
    }
}

function checkConceptPage(userContext?: UserContext): AsyncAction<ComponentActionType, UserSettingsState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.conceptPage != null || state.conceptPageLoading) {
            return
        }
        if (
            userContext &&
            userContext.externalModules &&
            userContext.externalModules.some(
                (extModule) =>
                    extModule.parameter && extModule.parameter?.some((param) => param.key === "DocumentTypeID" && param.value === "ConceptPage")
            )
        ) {
            dispatch({ type: "CONCEPT_PAGE_LOADING" })
            const container = Container.getInstance(RegisteredModels.UrlResolver) as UrlResolverContainer
            container
                .action("getConceptPageUrl")()
                .then((result) => {
                    dispatch({ type: "SHOW_CONCEPT_PAGE_SETTINGS", payload: !!result.canChangeCredentials })
                    dispatch({ type: "CONCEPT_PAGE_CREDENTIALS_FOUND", payload: !!result.hasCredentials })
                })
        }
    }
}

function changeAnyway(): ComponentActionType {
    return {
        type: "CONCEPT_PAGE_CREDENTIALS_FOUND",
        payload: false,
    }
}

function setDutchOrdersMDMParams(request: SetDutchOrdersMDMParamsRequest): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "DUTCH_ORDER_MDM_LOADING" })
        Data.setDutchOrdersMDMParams(request).then(
            () => dispatch({ type: "DUTCH_ORDER_MDM_LOADED" }),
            () => dispatch({ type: "DUTCH_ORDER_MDM_ERROR" })
        )
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadAddressSuggestions,
    resetAddressSuggestions,
    showSubUsers,
    saveConceptPageCredentials,
    checkConceptPage,
    changeAnyway,
    setDutchOrdersMDMParams,
}
