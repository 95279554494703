import { IAllFilters } from "@bundles/tyres/components/_shared/DiameterFilters"
import { EFilterNames } from "@tm/models"

export const getTextIdByFilterName = (filterName: IAllFilters) => {
    switch (filterName) {
        case EFilterNames.width:
            return 713
        case EFilterNames.height:
            return 714
        case EFilterNames.inch:
            return 715
        case EFilterNames.loadIndex:
            return 12403
        case EFilterNames.speedIndex:
            return 719
        case EFilterNames.oeIdentifier:
            return 720

        default:
            return 209
    }
}
