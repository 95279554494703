import { connectComponent } from "@tm/morpheus"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { Link } from "react-router-dom"
import { Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { ModuleNavigationState, ModuleTab, IActions, Actions, StylingFromConfig } from "./business"

type Props = RouteComponentProps<{ workTaskId: string }> &
    StylingFromConfig & {
        state: ModuleNavigationState
        actions: IActions
        closeRoute?: string
    }

const translationRegex = /\{\{(.*?)\}\}/

function ModuleNavigationComponent({ state, actions, closeRoute, history, match, location, style }: Props) {
    const { translateText } = useLocalization()
    const [currentUrl, setCurrentUrl] = useState<string>(location.pathname)

    useEffect(() => {
        actions.start()
    }, [])

    useEffect(() => {
        return history.listen((location) => {
            setCurrentUrl(location.pathname)
        })
    }, [])

    function getStyleFromConfig(prefix: string, defaultStyle?: { layout?: string; skin?: string }) {
        let typeVariations = defaultStyle && defaultStyle.layout ? ` ${prefix}--${defaultStyle.layout}` : ""
        let skin = defaultStyle && defaultStyle.skin ? ` ${prefix}--${defaultStyle.skin}` : ""

        if (style) {
            if (typeof style.layout === "object") {
                typeVariations = " "

                style.layout.forEach((variation) => {
                    typeVariations += ` ${prefix}--${variation}`
                })
            } else if (style.layout) {
                typeVariations = style.layout
            }

            if (style && style.skin) {
                skin = ` ${prefix}--${style.skin}`
            }
        }

        return typeVariations + skin
    }

    function getComponentUrl() {
        let url = renderRoute(closeRoute || match.path, match.params)
        url = url.replace(/\/$/, "") // TODO: Fix hotfix, this.props.match.path has an ending / which causes "ModuleComponent.prototype.handleClearStoresOnRouteChange" to clear the store (url is not the same anymore)
        return url
    }

    function handleCloseTab(tab: ModuleTab, ev: React.MouseEvent<HTMLButtonElement>) {
        ev.stopPropagation()
        ev.preventDefault()
        const componentUrl = getComponentUrl()
        if (tab.url.indexOf(currentUrl) !== -1) {
            history.push(componentUrl)
        }
        actions.closeTab(tab)
    }

    function renderItem(item: ModuleTab) {
        const isSelected = decodeURIComponent(item.url).replace(/\/$/, "") == currentUrl.replace(/\/$/, "")
        const className = `tab navigation__item ${isSelected ? "is-selected" : ""}`

        const tabClassNameExtensions = getStyleFromConfig("tab", { layout: "module" })

        const title = (item.title || "").replace(translationRegex, (s, num) => {
            return translateText(num)
        })

        return (
            <Link className={className + tabClassNameExtensions} to={item.url} key={item.url}>
                {item.icon && <Icon name={item.icon} className="tab__icon" />}
                <div className="tab__content">
                    <div className="tab__title">{title}</div>
                </div>
                <button type="button" className="tab__close" onClick={(ev) => handleCloseTab(item, ev)}>
                    <Icon name="close" />
                </button>
            </Link>
        )
    }

    return (
        <div className="worktask-modules__wrapper">
            <div className="worktask-modules tab-control">
                <div className="worktask-modules__inner">{state.tabs.map(renderItem)}</div>
            </div>
        </div>
    )
}

export default connectComponent(Actions, withRouter(ModuleNavigationComponent))
