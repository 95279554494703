import { Stack } from "@tm/components"
import { CisVoucherType } from "@tm/models"
import { useParams } from "react-router"
import Totals from "@bundles/cis/components/_shared/Totals"
import { Voucher } from "../../../../data/model"
import { RowStackWithPadding, VerticalDivider } from "../../../_shared/StyledComponents"
import { COMPACT_WIDTH, FULL_WIDTH, VoucherRouteProps } from "../../component"
import StandardVouchersTable from "./voucher-tables/StandardVouchersTable"
import StandardVoucherDetails from "./StandardVoucherDetails"
import { CisDisplayMode } from "../../../../business/model"
import PickupInvoicesTable from "./voucher-tables/PickupInvoicesTable"

type Props = {
    cisDisplayMode?: CisDisplayMode
    endOfList: boolean
    openedConnectedVouchers: string[]
    voucherTypeId: number
    vouchers?: Voucher[]
    currencyCode?: string
    totalGross?: number
    totalNet?: number
    loadNextPage(): void
    onExpandRowClick?(voucherId: string): void
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function StandardVouchersComponent(props: Props) {
    const { voucherTypeId, vouchers, cisDisplayMode, openedConnectedVouchers, endOfList, currencyCode, totalGross, totalNet } = props
    const matchParams = useParams<VoucherRouteProps>()
    const selectedVoucherId = matchParams.id ? decodeURIComponent(matchParams.id) : undefined
    let table = null
    switch (voucherTypeId) {
        case CisVoucherType.PickupInvoices:
            table = (
                <PickupInvoicesTable
                    compact={!!matchParams.id}
                    vouchers={vouchers as Voucher[]}
                    voucherTypeId={voucherTypeId}
                    selectedVoucherId={selectedVoucherId}
                    variant={matchParams.id ? "small" : "normal"}
                    loadNextPage={props.loadNextPage}
                    onOpenNewVoucher={props.onOpenNewVoucher}
                />
            )
            break
        default:
            table = (
                <StandardVouchersTable
                    compact={!!matchParams.id}
                    openedConnectedVouchers={openedConnectedVouchers}
                    selectedVoucherId={selectedVoucherId}
                    variant={matchParams.id ? "small" : "normal"}
                    voucherTypeId={voucherTypeId}
                    vouchers={vouchers as Voucher[]}
                    onExpandRowClick={props.onExpandRowClick}
                    loadNextPage={props.loadNextPage}
                    onOpenNewVoucher={props.onOpenNewVoucher}
                />
            )
            break
    }

    return (
        <RowStackWithPadding divider={<VerticalDivider />}>
            <Stack width={matchParams.id ? COMPACT_WIDTH : FULL_WIDTH}>
                {table}
                {!matchParams.id && <Totals currencyCode={currencyCode} totalGross={totalGross} totalNet={totalNet} />}
            </Stack>
            {!!matchParams.id && (
                <StandardVoucherDetails
                    endOfList={endOfList}
                    selectedVoucherId={decodeURIComponent(matchParams.id)}
                    voucherTypeId={voucherTypeId}
                    vouchers={vouchers as Voucher[]}
                    cisDisplayMode={cisDisplayMode}
                    onOpenNewVoucher={props.onOpenNewVoucher}
                />
            )}
        </RowStackWithPadding>
    )
}
