import {
    AdvertisementCategory,
    Article,
    ArticleAttribute,
    ArticleErpInfo,
    ArticleListType,
    AttributeComparisonModel,
    AttributeFilterModel,
    Compilation,
    ErpInformationRequestItem,
    FittingPosition,
    GetErpConfigResponse,
    HasRepairTimesResponse,
    ListFilter,
    ModificationState,
    OE,
    ProductGroup,
    ProductGroupFiltersModel,
    ReplaceButtonBundle,
    WholesalerArticle,
} from "@tm/models"
import { Dispatch, SetStateAction } from "react"
import { QueryStatus } from "react-query"
import {
    ExtendedAssortment,
    OeInformation,
    OeReplacementManufacturer,
    PartsListImage,
    PartsListImageCoordinate,
    SearchFilter,
} from "../../data/model"
import { ArticleListSortingMeta } from "./hooks/useArticleListSorting"

/** Contains all the data specific to this article group. */
export type ArticleGroupParams = {
    type: "default"

    /** The product group for this article group. */
    productGroup: ProductGroup

    /** The articles of this group. */
    articles: Article[]

    /** Indicates that not all articles of this group where loaded yet due to the paging. */
    incomplete: boolean
}

type BaseStartParams = {
    replaceButtonBundle?: ReplaceButtonBundle | null
    extendedAssortment?: string
    disableExtendedAssortment?: string
}

export type StartParams =
    | VehiclePartsStartParams
    | DirectSearchStartParams
    | UniversalPartsStartParams
    | OePartsStartParams
    | ProductGroupsStartParams
    | WholesalerArticleNumbersStartParams
    | OffersStartParams

/** Contains information about the current article list parameters, status and filter selection and some helper methods to change them. */
export type ListParams<TStartParams = StartParams> = {
    listType: ArticleListType
    pageIndex: number
    startParams: TStartParams
    productGroups: ListFilter[]
    suppliers: ListFilter[]
    attributes: ListFilter[]
    fittingPosition?: FittingPosition
    extendedAssortment: boolean
    showAvailable: boolean
    showAvailableSecondary: boolean
    noResult: boolean
    isFiltersLoading: boolean
    setProductGroups(set: SetStateAction<ListFilter[]>): void
    setSuppliers(filters: ListFilter[]): void
    setAttributes(attributes: ListFilter[]): void
    toggleProductGroup(filter: ListFilter): void
    toggleSupplier(filter: ListFilter): void
    toggleAttribute(attribute: ListFilter): void
    setAvailability(checked: boolean): void
    setAvailabilitySecondary(checked: boolean): void
    setExtendedAssortment(checked: boolean): void
    setNoResult(state: boolean): void
    setIsFiltersLoading(state: boolean): void
    setFittingPosition?(position: FittingPosition): void
    nextPage(): void
    sorting: ArticleListSortingMeta
}

export type VehiclePartsListParams = ListParams<VehiclePartsStartParams> & { listType: "vehicle" }

export type DirectSearchListParams = ListParams<DirectSearchStartParams> & {
    listType: "direct"
    oePositions?: OE.OePosition[]
    selectedOePart?: OE.OePart
    selectedOeNumber?: OE.OeNumber
    changeOePosition(position: OE.OePosition, replacement?: OE.OeNumber): void
}

export type UniversalPartsListParams = ListParams<UniversalPartsStartParams> & { listType: "universal" }

export type ProductGroupsListParams = ListParams<ProductGroupsStartParams> & { listType: "default" }

export type WholesalerArticleNumberListParams = ListParams<WholesalerArticleNumbersStartParams> & { listType: "default" }
export type OffersListParams = ListParams<OffersStartParams> & { listType: "default" }

export type OePartsListParams = ListParams<OePartsStartParams> & { listType: "oe" } & {
    oePositions: OE.OePosition[]
    selectedOePart?: OE.OePart
    selectedOeNumber?: OE.OeNumber
    extendedAssortment?: boolean
    disableExtendedAssortment?: boolean
    query?: string
    oeInformationWithoutCar?: boolean
    changeOePosition(position: OE.OePosition, replacement?: OE.OeNumber): void
}

/** Contains the different modes the vehicle parts article list can be in and their necessary initial data. */
export type VehiclePartsStartParams = BaseStartParams &
    (
        | {
              type: "node"
              treeId: number
              nodeId: number
          }
        | {
              type: "synonym"
              query: string
          }
        | {
              type: "productgroups"
              treeId?: number
              nodeId?: number
              productGroupIds: number[]
          }
    )

export type ProductGroupsStartParams = BaseStartParams & {
    type: "default"
    productGroupIds: number[]
    fittingSideFilter: number
}

export type WholesalerArticleNumbersStartParams = BaseStartParams & {
    type: "default"
    productGroupIds: number[]
    wholesalerArticleNumbers: string[]
}

export type OffersStartParams = BaseStartParams & {
    type: "default"
    advertisementCategory?: AdvertisementCategory
    tabTitle?: string
    title?: string
}

/** Contains the different modes the vehicle parts article list can be in and their necessary initial data. */
export type OePartsStartParams = BaseStartParams &
    (
        | {
              type: "oe"
              oePositionsCacheId: string
          }
        | {
              type: "default"
          }
    )

/** Contains the different modes the direct search article list can be in and their necessary initial data. */
export type DirectSearchStartParams = BaseStartParams & {
    type: "direct"
    query: string
    oePositionsCacheId?: string
    searchFilter: number
    fittingSideFilter: number
    isOeReplacement?: boolean
    oeManufacturerIds: number[]
    oeInformationWithoutCar?: boolean
    forcePartsAlternatives?: string
    customerAlsoBought?: string
}

/** Contains the different modes the universal parts article list can be in and their necessary initial data. */
export type UniversalPartsStartParams = BaseStartParams &
    (
        | {
              type: "uninode"
              treeId: number
              nodeId: number
              criterias?: string[]
          }
        | {
              type: "uniproductgroups"
              productGroupIds: number[]
          }
        | {
              type: "unisearch"
              query: string
          }
    )

export type ListFilterGroup = {
    key: number
    description: string
    priority: number
    filters: [ListFilter, boolean][]
}

/** Contains the current filter data and selection of the parts module. */
export type FiltersData = {
    isLoading: boolean
    isRefetching: boolean
    productGroupFilters: [ListFilter, boolean][]
    dataSupplierFilters: [ListFilter, boolean][]
    attributeFilters: [ListFilter, boolean][]
    groupedAttributeFilters: ListFilterGroup[]
    fittingPosition?: FittingPosition
    extendedAssortment: ExtendedAssortment
    showExtendedAssortmentFilter: boolean
    showOnlyAvailable: boolean
    showOnlyAvailableSecondary: boolean
    showFittingPositionsFilter?: boolean
}

/** Contains helper methods to change the filter selection. */
export type FilterActions = {
    toggleAttribute?(attribute: ListFilter, exclusive?: boolean): void
    toggleProductGroup(id: number, exclusive?: boolean): void
    toggleSupplier(id: number, exclusive?: boolean): void
    toggleExtendedAssortment(): void
    toggleAvailability(): void
    toggleAvailabilitySecondary(): void
    setFittingPosition?(position: FittingPosition): void
    resetProductGroups(): void
    resetSuppliers(): void
    resetAttributes?(): void
    resetAvailability?(): void
    resetAll(props?: VehicleListResetParams): void
}

/** Contains helper methods to change the filter selection but only the ones required for an article item. */
export type ArticleListActions = {
    toggleSupplier?(id: number, exclusive?: boolean): void
    toggleInlineFilter?(attribute: ArticleAttribute, article?: Article): void
    toggleQuickFilter?(attribute: AttributeFilterModel): void
    updateBasketQuantities?(articles: Article[]): void
    updateWholesalerBasketQuantities?(articles: WholesalerArticle[]): void
    showArticleDetails?(productGroupId: number, supplierId: number, supplierArticleNo: string, quantity: number): void
}

export type VehicleListResetParams = {
    useInitialValues?: boolean
}

export type VehicleRecordsData = {
    isLoading: boolean
    productGroupFilters: ProductGroupFiltersModel[]
    attributes: AttributeComparisonModel[]
}

export type VehicleRecordsActions = {
    refresh: () => void
    handleArticleAddedToBasket: (article: Article) => Promise<unknown>
    handleSelectInlineFilter: (attribute: ArticleAttribute, article: Article, fittingPosition?: FittingPosition) => Promise<unknown>
    handleSelectQuickFilter: (filter: AttributeFilterModel) => Promise<unknown>
}

export type SupplierLogosData = {
    isLoading: boolean
    supplierLogos: Record<number, string>
}

export type ProductGroupRepairTimesData = {
    isLoading: boolean
    productGroupRepairTimes?: HasRepairTimesResponse
}

export type WatchListData = {
    isLoading: boolean
    error?: unknown
    compilations: [Compilation, Article[]][]
}

export type SearchData = {
    isLoading: boolean
    searchFilter?: SearchFilter[]
}

export type OeInformationData = {
    isLoading?: boolean
    oeInformation?: OeInformation[]
}

export type OeReplacementData = {
    isLoading: boolean
    replacementData?: OeReplacementManufacturer[]
}

export type OensArticleData = {
    isLoading: boolean
    oeParts?: OE.OePart[]
}

export type WholesalerArticleGroup = {
    type: "WholesalerArticle"
    articles: WholesalerArticle[]
}

export type PureListArticlesResponse = {
    articles: Article[]
} & (
    | { type: null }
    | {
          type: "alternative"
          wholesalerParts?: WholesalerArticle[]
      }
    | {
          type: "partslist"
          imageCoordinates?: PartsListImageCoordinate[]
          partsListImage?: PartsListImage
      }
    | {
          type: "accessoryList"
      }
    | {
          type: "articlesAlreadyOrderedList"
      }
    | {
          type: "mainAccessoryList"
      }
    | {
          type: "recommended-articles"
      }
    | {
          type: "wheelsOverview"
      }
    | {
          type: "damageModulesList"
          oeArticles: { article: Article | null; oeArticle: OE.OePart | null }[]
      }
)

/** Contains the current article data of the parts module. */
export type BaseArticleData<A = Article> = {
    requestStatus: QueryStatus
    isEnabled: boolean
    isLoading: boolean
    isFetchingNextPage: boolean
    isLoaded: boolean
    isSuccess: boolean
    isFailed: boolean
    failMessage?: string
    pageCount: number
    articles: A[]
    articleCount: number
    supplierIds: number[]
    productGroupIds: number[]
    loadNextPage(): void
    hasNextPage: boolean
    /** Indicates that the articles should not be shown yet, but the loader. */
    isStalling?: boolean
    /** Indicates that the articles do not contain all items received from the service, but are filtered. */
    isFrontendFiltered?: boolean
    displayArticles?: A[]
    showInitialLoading?: boolean
    wholesalerArticles?: WholesalerArticle[]
}

export type ArticleData = BaseArticleData & {
    articleGroups: ArticleGroupParams[]
}

export type PureListArticleData = BaseArticleData & {
    articleGroups: (ArticleGroupParams | WholesalerArticleGroup)[]
    response?: PureListArticlesResponse
}

export type VehiclePartsArticleData = ArticleData

export type OePartsArticleData = ArticleData

/** Contains the current article data of the direct search module. */
export type DirectSearchArticleData = ArticleData & {
    exactMatchArticleGroups: ArticleGroupParams[]
}

/** Contains the current article data of the product groups module. */
export type ProductGroupsArticleData = ArticleData & {
    exactMatchArticleGroups: ArticleGroupParams[]
}

/** Contains the current article data of the wholesaler. */
export type WholesalerArticleData = BaseArticleData & {
    articleGroups: (ArticleGroupParams | WholesalerArticleGroup)[]
}

export type OffersArticleData = BaseArticleData & {
    articleGroups: (ArticleGroupParams | WholesalerArticleGroup)[]
}

/** Contains the current article data of the wholesaler. */
export type WholesalerArticleNumberArticleData = BaseArticleData & {
    articleGroups: ArticleGroupParams[]
}

/** Contains the current article data of the universal parts module. */
export type UniversalPartsArticleData = ArticleData

export type ErpInfosData = {
    isReady: boolean
    erpInfos: ArticleErpInfo[]
    setErpInfos: Dispatch<SetStateAction<ArticleErpInfo[]>>
    erpConfig?: GetErpConfigResponse
}

/** Contains the currently enabled article list view settings. */
export type PartsViewSettings = {
    compactView: boolean
    showArticleImages: boolean
    showVehicleRecordsFilters: boolean
    quantitySuggestionEnabled: boolean
}

export type ErpSystemLoadingGroup = {
    erpSystemId: number
    chunkSize: number
    items: ErpInformationRequestItem[]
}

export type VehicleDescriptionsButtonParms = {
    hideVehicleDescriptions?: boolean
    vehicleDescriptions?: string[]
}

export type DisplayArticleAttribute = {
    id: number
    isSelected?: boolean
    isHighlighted?: boolean
    label?: string
    value?: string
    displayValue?: string
    modificationState: ModificationState // For IMS
    isOptimized: boolean // For DOM
    alternatives: Array<string> // For DOM
}

// TODO: mehr als ein Attribut erlauben / Attribute zusammenfassen
export type ArticleAttributeParms = {
    id: number
    key: string
    label?: string
    values?: Array<DisplayArticleAttribute>
    isRecommended?: boolean
    onClickAttribute?(parms: ArticleAttributeParms): void
    // Wenn mehr als eine Attribut, dann kann die Option genutzt werden
    // um diese filter als Oder verknüpft zu betrachten, standard "OR"
    filterOption?: "AND" | "OR"
}

export type WatchListItem = { id: string; name: string; selected: boolean }

export type WatchList = {
    watchLists: WatchListItem[] | undefined
    isInWatchList?: boolean
    createWatchList: (name: string) => void
    toggleWatchList: (listId: string) => void
    isLoading: boolean
    isToggling: boolean
    isCreating: boolean
}

export enum ShowFeedbackForArticleType {
    AllArticles = 0,
    OnlyTraderArticles = 1,
    ArticlesWithoutTraderReference = 2,
}

export enum CrossSellingContext {
    AlsoBoughtArticle = "Article",
    AlsoBoughtProductGroup = "ProductGroup",
    Recommended = "Recommended",
}
