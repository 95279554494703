import { useDisplayListV2, useUser } from "@tm/context-distribution"
import { Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, channel, EReplaceButtonBundle, OE } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { clone, closeModalOrDrawer, getCurrencyFromUserContext, useUnsubscribe } from "@tm/utils"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useCallback } from "react"
import { Box, styled } from "@tm/components"
import { eOEArtSubType, eOEArtType } from "../../data/enums"
import { ICalculationItem } from "../../data/models"
import { MainState } from "../main"
import { erpArticlesSelector, itemsSelector, MainActions, totalsSelector, worksSelector } from "../main/business"
import { DcCollapsible } from "../_shared"
import { ArticleListV2, CalculationItem, Header, Totals, WorkList } from "./components"
import { createRequestArticleListPayload, mapPart } from "./business/helpers"

const selector = createSelector(erpArticlesSelector, itemsSelector, totalsSelector, worksSelector, (...x) => x)

export default function Calculation() {
    const { translateText, currency } = useLocalization()

    const user = useUser()
    const currencyString = getCurrencyFromUserContext(user?.userContext)
    const subscriptionReplacePart = useUnsubscribe()

    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const displayListV2 = useDisplayListV2()
    const isV2 = displayListV2 && !isWm

    const actions = useActions(
        MainActions,
        "setErpArticles",
        "changeOeArticleQuantity",
        "selectArticle",
        "replaceArticle",
        "replaceOeArticle",
        "selectWork",
        "updateTotals"
    )
    const [erpArticles, items, totals, works] = useSelector(selector)
    const { calculationError, calculationLoading } = useSelector((x: MainState) => ({
        calculationError: x.calculation.calculationError,
        calculationLoading: x.calculation.calculationLoading,
    }))

    const materialPositions = items?.filter((x) => x.oeArticle.type === eOEArtType.MaterialPosition)
    const smallPartsAndConsumables = items?.filter((x) => x.oeArticle.type === eOEArtType.SmallPartsAndConsumables)
    const parts = materialPositions.concat(smallPartsAndConsumables)
    const additionalCostsPositions = items?.filter((x) => x.oeArticle.type === eOEArtType.AdditionalCostsPosition)

    // TODO remove above selector/ use reselect

    const handleChange = (oeArticle: ICalculationItem, part: OE.OePart | Article) => {
        if ("number" in part) {
            actions.replaceOeArticle(oeArticle)
            let filteredErpArticles = clone(erpArticles)
            if (oeArticle.selectedPart) {
                filteredErpArticles = filteredErpArticles
                    .filter((x) => x.dataSupplierArticleNumber !== oeArticle.selectedPart?.dataSupplierArticleNumber)
                    .distinct()
            }
            actions.updateTotals(filteredErpArticles)
        } else {
            if (isV2) {
                // eslint-disable-next-line no-param-reassign
                part.quantity = oeArticle.oeArticle.quantityValue
            }
            actions.replaceArticle(oeArticle, mapPart(part))
            channel("GLOBAL").subscribeOnce("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
                const newErpArticle = erpInfo.filter((x) => x.dataSupplierArticleNumber === (part as Article).supplierArticleNo)

                if (oeArticle.isSelected) {
                    actions.updateTotals([...erpArticles, ...newErpArticle].distinct())
                } else {
                    actions.setErpArticles([...erpArticles, ...newErpArticle])
                }
            })
        }
    }

    const handleAlternativeClick = useCallback(
        (item: ICalculationItem) => {
            subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce("PARTS/REPLACE_PART", ({ part, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.DMGDatGlass) {
                    return false
                }
                if (part) {
                    closeModalOrDrawer(isNewList)
                    handleChange(item, part)
                }
            })
            channel("WORKTASK").publish("PARTS/REQUEST_LIST", createRequestArticleListPayload(item, user.userContext))
        },
        [user.userContext, actions, erpArticles]
    )

    const renderCalculationItem = (item: ICalculationItem, idx: number) => {
        return (
            <CalculationItem
                key={idx}
                item={item}
                onOeArticleSelect={() => actions.selectArticle(item)}
                onOeArticleQuantity={(quantity) => actions.changeOeArticleQuantity(item, quantity)}
                onAlternativeClick={() => handleAlternativeClick(item)}
            />
        )
    }

    if (calculationLoading) {
        return (
            <Box className="calculation-content content-loader">
                <Loader />
            </Box>
        )
    }

    if (calculationError) {
        return (
            <Box className="calculation-content article-list__panel article-list__status">
                <Text>{translateText(1569)}</Text>
            </Box>
        )
    }

    const regularWorks = works?.filter((x) => x.providerWorkId !== eOEArtSubType.RemoveGlassSplitter.toString())
    const additionalWorks = works?.filter((x) => x.providerWorkId === eOEArtSubType.RemoveGlassSplitter.toString())

    return (
        <StyledCalculationContent>
            <Header />
            <Totals />

            <DcCollapsible
                title={translateText(90)}
                itemsCount={parts?.length ?? 0}
                itemsPrice={currency(totals.totalSparePartsPrice - totals.additionalCosts, currencyString)}
            >
                {!!parts?.length && !isV2 && (
                    <Box className="tk-parts">
                        <Box className="list">
                            <Box className="article-list calculation-items">{parts.map(renderCalculationItem)}</Box>
                        </Box>
                    </Box>
                )}
                {!!parts?.length && isV2 && <ArticleListV2 items={parts} handleAlternativeClick={handleAlternativeClick} />}
            </DcCollapsible>

            <DcCollapsible
                title={translateText(12849)}
                itemsCount={additionalCostsPositions?.length ?? 0}
                itemsPrice={currency(totals.additionalCosts, currencyString)}
            >
                {!!additionalCostsPositions?.length && !isV2 && (
                    <Box className="tk-parts">
                        <Box className="list">
                            <Box className="article-list calculation-items">{additionalCostsPositions.map(renderCalculationItem)}</Box>
                        </Box>
                    </Box>
                )}
                {!!additionalCostsPositions?.length && isV2 && <ArticleListV2 items={additionalCostsPositions} />}
            </DcCollapsible>

            <DcCollapsible
                title={translateText(83)}
                itemsCount={regularWorks?.length ?? 0}
                itemsPrice={currency(totals.totalWorksPrice, currencyString)}
            >
                {!!regularWorks?.length && <WorkList works={regularWorks} />}

                {!!additionalWorks?.length && (
                    <DcCollapsible
                        title={translateText(12849)}
                        itemsCount={additionalWorks?.length ?? 0}
                        itemsPrice={currency(totals.totalAdditionalWorksCostsPrice, currencyString)}
                    >
                        <WorkList works={additionalWorks} />
                    </DcCollapsible>
                )}
            </DcCollapsible>
        </StyledCalculationContent>
    )
}

const StyledCalculationContent = styled(Box)(({ theme }) => ({
    padding: "1rem",
    flex: 1,

    ".panel": {
        "&.total": {
            ".value": {
                color: theme.colors?.highlight,
            },
        },
    },

    ".calculation-items": {
        ".calculation-item": {
            marginBottom: "1rem",

            ".alternative-part__list": {
                padding: 0,

                ".article-list__item": {
                    margin: "0 0 0.5rem 3rem",
                },
            },

            ".oe-article-image": {
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right center",
                backgroundSize: "100%",
                backgroundColor: "white",
            },

            ".oeArticle-attribute": {
                ".tag__content": {
                    borderColor: "orange",
                },
            },

            ".article__cell--actions": {
                flexWrap: "wrap",
                maxWidth: "220px",

                ".tk-basket": {
                    margin: ".1725em",
                    marginLeft: 0,
                    marginRight: ".25em",
                },
            },
        },
    },

    ".works-list": {
        ".fancy-list": {
            "&__head, &__item": {
                "&.is-expanded": {
                    flexWrap: "wrap",

                    ".expandableRow": {
                        display: "block",
                    },
                },

                ".expandableRow": {
                    display: "none",
                },

                ".includes": {
                    flex: "0 0 1.6rem",
                },

                ".provider, .category": {
                    flex: "0 0 5.2rem",
                    justifyContent: "flex-start",
                },

                ".price": {
                    flex: "0 0 5.7rem",
                    textAlign: "right",

                    " &.sum": {
                        display: "flex",
                        flex: "0 0 7.5rem",
                        paddingLeft: "1.5em",
                        justifyContent: "flex-end",
                    },
                },

                ".time": {
                    flex: "0 0 5.3rem",
                    textAlign: "right",
                    whiteSpace: "normal",
                },

                ".actions": {
                    flex: "0 0 1.5rem",
                },

                ".type, .note": {
                    flex: "0 0 1.2rem",
                },

                ".number": {
                    flex: "0 0 9rem",
                    display: "flex",
                    flexDirection: "column",
                },

                ".description": {
                    flex: 1,
                },
            },
        },
    },
}))
