import { useUser } from "@tm/context-distribution"
import { OrderOptions, UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { atom, useRecoilState } from "recoil"
import { useGlobalOrderOptions } from "../data/hooks/centralOrder/useGlobalOrderOptions"
import { getBundleParams } from "../utils"

type State = {
    globalOrderOptionsEnabled: boolean
    globalOrderOptions?: OrderOptions
}

const globalOrderOptionsStateAtom = atom<State>({
    key: "basket__globalOrderOptionsState",
    default: {
        globalOrderOptionsEnabled: false,
    },
})

enum GlobalOrderOptionsMode {
    Optional = "1", // use global or work task order options only, display switch
    Exclusive = "2", // only use global, no work task order options, no switch
    Disabled = "3", // not use global, only work task order options, no switch. (default)
}

export function useGlobalOrderOptionsState() {
    const [value, setValue] = useRecoilState(globalOrderOptionsStateAtom)
    const { userContext } = useUser()
    const updateOrderOptionsOnChange = value.globalOrderOptions?.shipmentModes?.updateOrderOptionsOnChange
    const enableDefaultGlobalOrderOptions = getBundleParams()?.globalOrderOptionsEnabled

    useMemo(() => {
        enableDefaultGlobalOrderOptions && setValue((prev) => ({ ...prev, globalOrderOptionsEnabled: true }))
    }, [enableDefaultGlobalOrderOptions, setValue])

    const selectedShipmentMode = useMemo(() => {
        return value.globalOrderOptions?.shipmentModes?.shipmentModes?.find((shipmentMode) => shipmentMode.isSelected)
    }, [value.globalOrderOptions?.shipmentModes])

    const centralOrderParameters = useMemo(() => {
        const centralOrderModule = userContext.modules?.find((module) => module.type === UserModuleType.CentralOrder)
        const orderPagingSize = parseInt(centralOrderModule?.parameters?.find((param) => param.key === "OrderPagingSize")?.value || "10")
        const useWorkTaskOrder = centralOrderModule?.parameters?.some((param) => param.key === "UseWorkTaskOrder")
        const useOrderCollection = centralOrderModule?.parameters?.some((param) => param.key === "UseOrderCollection")
        const showGlobalOrderOptionsSwitch = centralOrderModule?.parameters?.some(
            (param) => param.key === "GlobalOrderOptionsMode" && param.value === GlobalOrderOptionsMode.Optional
        )
        const globalOrderOptionsEnabled = centralOrderModule?.parameters?.some(
            (param) => param.key === "GlobalOrderOptionsMode" && param.value === GlobalOrderOptionsMode.Exclusive
        )
        return {
            globalOrderOptionsEnabled,
            showGlobalOrderOptionsSwitch,
            orderPagingSize,
            useWorkTaskOrder,
            useOrderCollection,
        }
    }, [userContext.modules])

    const globalOrderOptionsEnabled = value.globalOrderOptionsEnabled || centralOrderParameters.globalOrderOptionsEnabled
    const { globalOrderOptionsResponse, globalOrderOptionsLoading } = useGlobalOrderOptions(
        globalOrderOptionsEnabled ?? false,
        updateOrderOptionsOnChange,
        selectedShipmentMode?.id
    )

    const setGlobalOrderOptionsEnabled = (enabled: boolean) => {
        setValue((prev) => ({ ...prev, globalOrderOptionsEnabled: enabled }))
    }

    const setGlobalOrderOptionsValue = (orderOptions: OrderOptions) => {
        setValue((prev) => ({ ...prev, globalOrderOptions: orderOptions }))
    }

    return {
        ...value,
        globalOrderOptionsEnabled,
        globalOrderOptions: value.globalOrderOptions || globalOrderOptionsResponse?.options,
        errorText: globalOrderOptionsResponse?.errorText,
        hasErrors: globalOrderOptionsResponse?.hasErrors,
        centralOrderParameters,
        globalOrderOptionsLoading,
        setGlobalOrderOptionsEnabled,
        setGlobalOrderOptionsValue,
    }
}
