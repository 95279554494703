import { useCompilation } from "../../../../../data/hooks/useCompilations"
import { CompilationVisibilityIndicatorNavigation } from "../indicator/indicator"
import { CompilationDeleteNavigation } from "../delete/delete"
import { CompilationAddNavigation, IProps as IAddProps } from "../add/add"

interface Props extends IAddProps {
    compilationId?: string
    openCompilations(): void
}

export default function CompilationWMNavigation(props: Props) {
    const { compilation } = useCompilation(props.compilationId || "")

    if (!props.compilationId) {
        return <CompilationAddNavigation showCreator={props.showCreator} onShowCreator={props.onShowCreator} />
    }
    if (!compilation) {
        return null
    }

    return (
        <>
            <CompilationDeleteNavigation compilation={compilation} openCompilations={props.openCompilations} />
            <CompilationVisibilityIndicatorNavigation compilation={compilation} />
        </>
    )
}
