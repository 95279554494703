import { useDisplayListV2, useStyle, useUser } from "@tm/context-distribution"
import { Toolbar, Switch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Switch as SwitchMui } from "@tm/components"
import { useMemo } from "react"
import Morpheus from "@tm/morpheus"

function PurchasePriceSwitch() {
    const user = useUser()
    const { userSettingsUpdating } = user
    const { translateText } = useLocalization()
    const displayListV2 = useDisplayListV2()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    const style = useMemo(() => getStyle(), [])

    if (user?.userContext?.parameter.purchasePricesDisabled) {
        return null
    }

    const canShowSwitch = (): boolean => {
        const externalModules = user?.userContext?.externalModules

        let isInfoEnabled = false
        if (externalModules) {
            externalModules.forEach((externalModule) => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                }
            })
        }
        return isInfoEnabled
    }

    if (!canShowSwitch()) {
        return null
    }

    const handleChange = (showPurchasePrice: boolean) => {
        if (user?.userSettings?.articleListSettings) {
            user.setUserSetting("SHOW_PURCHASE_PRICE", showPurchasePrice)
        }
    }

    if (displayListV2 && !isWm) {
        return (
            <Toolbar className={style.compactViewToolbar} title={translateText(1132)}>
                <SwitchMui
                    checked={user?.userSettings?.showPurchasePrice}
                    onChange={(event, checked) => handleChange(checked)}
                    disabled={userSettingsUpdating}
                />
            </Toolbar>
        )
    }

    return (
        <Toolbar className="tk-parts switch" title={translateText(1132)}>
            <Switch status={user?.userSettings?.showPurchasePrice} onChange={handleChange} alignLabel="left" disabled={userSettingsUpdating} />
        </Toolbar>
    )
}

export default PurchasePriceSwitch

function getStyle() {
    return useStyle({
        compactViewToolbar: {
            $nest: {
                "&:before": {
                    borderLeft: "transparent !important",
                },
            },
        },
    })(PurchasePriceSwitch)
}
