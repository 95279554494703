import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Box, ToggleSwitch, ToggleSwitchItem, Typography, Switch, SelectedTypography, styled } from "@tm/components"
import { useEffect, useMemo, useState } from "react"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { WheelSelectionSteps } from "@bundles/wheels/data/enums"
import { Actions } from "../wheels-list/business"
import { MainState } from "../main"

const StyledOptionsBox = styled(Box)({
    padding: "0.25em 0.25em 0 0.25em",
    display: "flex",
    flexDirection: "column",
    gap: "0.5em",
})

const StyledSectionBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
})

const StyledSwitch = styled(Switch)({
    marginLeft: "-6px",
})

const StyledSectionHeader = styled(Typography)({
    textTransform: "uppercase",
})

const selector = createSelector(
    (s: MainState) => ({
        activeStep: s.navigation.activeStep,
        display: s.wheelsList.configurator.display,
        has2DConfig: s.wheelsList.configurator.has2DConfig,
        has3DConfig: s.wheelsList.configurator.has3DConfig,
        show3D: s.wheelsList.configurator.show3D,
        selectedRimItem: s.wheelsList.base.selectedRimItem,
    }),
    (x) => x
)

function OptionsSwitch() {
    const user = useUser()
    const { translateText } = useLocalization()
    const { changeConfiguratorDisplay } = useActions(Actions, "changeConfiguratorDisplay")
    const { activeStep, display, has2DConfig, has3DConfig, show3D, selectedRimItem } = useSelector(selector)
    const externalModules = user?.userContext?.externalModules

    const [switchStates, setSwitchStates] = useState<{
        showPurchasePrice: boolean | undefined
        hideWheelsAvailability: boolean | undefined
    }>({
        showPurchasePrice: user?.userSettings?.showPurchasePrice,
        hideWheelsAvailability: user?.userSettings?.hideWheelsAvailability,
    })

    const displayVehicleImage = useMemo(
        () => activeStep === WheelSelectionSteps.WHEELSLIST && (has2DConfig || has3DConfig),
        [activeStep, has2DConfig, has3DConfig]
    )

    const disabledVehicleImage = useMemo(
        () => (!has2DConfig && !has3DConfig) || (!has2DConfig && has3DConfig && !show3D) || !selectedRimItem,
        [has2DConfig, has3DConfig, show3D, selectedRimItem]
    )

    useEffect(() => {
        if (switchStates.hideWheelsAvailability) {
            user.setUserSetting("SHOW_PURCHASE_PRICE", false)
            setSwitchStates((prevState) => ({
                ...prevState,
                showPurchasePrice: false,
            }))
        }
    }, [switchStates.showPurchasePrice, switchStates.hideWheelsAvailability])

    useEffect(() => {
        setSwitchStates((prevState) => ({
            ...prevState,
            showPurchasePrice: user?.userSettings?.showPurchasePrice,
            hideWheelsAvailability: user?.userSettings?.hideWheelsAvailability,
        }))
    }, [user?.userSettings?.showPurchasePrice, user?.userSettings?.hideWheelsAvailability])

    const handleChangeShowPurchasePrice = (item?: ToggleSwitchItem<boolean>) => {
        if (user?.userSettings) {
            user.setUserSetting("SHOW_PURCHASE_PRICE", !!item?.key)
            setSwitchStates((prevState) => ({
                ...prevState,
                showPurchasePrice: !!item?.key,
            }))
        }
    }

    const handleChangeShowAvailability = (availability: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("HIDE_WHEELS_AVAILABILITY", !availability)
            setSwitchStates((prevState) => ({
                ...prevState,
                hideWheelsAvailability: !availability,
            }))
        }
    }

    const renderSwitchLabel = (item: ToggleSwitchItem<boolean>) => {
        return (
            <SelectedTypography variant="body3" selected={switchStates.showPurchasePrice === item.key}>
                {item.value}
            </SelectedTypography>
        )
    }

    if (!externalModules || !externalModules.some((externalModule) => externalModule.isInfoEnabled)) {
        return null
    }

    return (
        <StyledOptionsBox>
            <StyledSectionBox>
                <StyledSectionHeader variant="body2">{translateText(222)}</StyledSectionHeader>
                {displayVehicleImage && (
                    <StyledSwitch
                        label={<Typography variant="body3">{translateText(1633)}</Typography>}
                        checked={display}
                        disabled={disabledVehicleImage}
                        onChange={changeConfiguratorDisplay}
                        formControlLabelSx={{ margin: "0" }}
                    />
                )}
                <StyledSwitch
                    label={<Typography variant="body3">{translateText(13669)}</Typography>}
                    checked={!switchStates.hideWheelsAvailability}
                    onChange={(event, checked) => handleChangeShowAvailability(checked)}
                    formControlLabelSx={{ margin: "0" }}
                />
            </StyledSectionBox>
            <StyledSectionBox>
                <StyledSectionHeader variant="body2">{translateText(13958)}</StyledSectionHeader>
                <ToggleSwitch
                    left={{ value: "EK", key: true }}
                    right={{ value: "VK", key: false }}
                    disabled={user?.userContext?.parameter.purchasePricesDisabled}
                    selected={switchStates.showPurchasePrice}
                    onChange={handleChangeShowPurchasePrice}
                    renderLabel={renderSwitchLabel}
                />
            </StyledSectionBox>
        </StyledOptionsBox>
    )
}

export default OptionsSwitch
