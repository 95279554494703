import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { Repositories } from "../../data"
import { Statics } from "../../data/statics"
import { createTyresCritsRequest } from "./helper"
import { Filters, SelectedFilters } from "./model"

const KEY = "tyres_useFetchGetTyresCrits"

const cacheTime = {
    staleTime: 30 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    retry: 2,
}

export function useGetTyresCrits(selectedFilters: SelectedFilters): { filters: Filters; loading: boolean; error: unknown } {
    const request = createTyresCritsRequest(selectedFilters)

    const defaultState = useMemo(
        () =>
            Object.freeze({
                width: [],
                height: [],
                inch: [],
                carType: Statics.getCarTypes(),
                tyreCount: Statics.tyreCountFilters,
                season: Statics.seasons,
            }),
        []
    )

    const [filters, setFilters] = useState<Filters>({ ...defaultState })

    const { data, isLoading, error } = useQuery(
        [KEY, request],
        () =>
            Repositories.loadTyresCrits(request).then(
                (res) =>
                    ({
                        ...defaultState,
                        width: res.width,
                        height: res.height,
                        inch: res.inch,
                    }) as Filters
            ),
        { ...cacheTime, enabled: !selectedFilters.untouched }
    )

    useEffect(() => {
        if (!isLoading) {
            setFilters(data ?? { ...defaultState })
        }
    }, [data, isLoading])

    return { filters, loading: isLoading, error }
}
