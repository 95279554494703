import { useWorkTask } from "@tm/context-distribution"
import { ShowConfigResponse, VehicleType } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { decodeUniqueId, RouteComponentProps, withRouter, useAvailableVehicleSearchOptions } from "@tm/utils"
import { NavigationItem } from "./business"
import { Actions, IActions } from "./business/actions"
import { DetailsByTecdocId } from "./DetailsByTecdocId"
import { DetailsByVehicle } from "./DetailsByVehicle"
import { DetailsByVehicleId } from "./DetailsByVehicleId"

export type DetailsProps = RouteComponentProps<RouteParams> & {
    actions: IActions
    tabs: { [key: string]: NavigationItem | false }
    vrmRefreshContingentEmail?: string
    showServiceBook?: boolean
    availableVehicleSearches?: ShowConfigResponse
}

type RouteParams = {
    vehicleType: string
    workTaskId: string
    vehicleId: string
    regNoType?: string
    vehicleDetailsSubPage?: string
}

const TECDOC_ID_PATTERN = /^\d{1,19}$/
const VEHICLE_ID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-d][0-9a-f]{3}-[0-9a-f]{12}$/i

export function Wrapper(props: DetailsProps) {
    const vehicleType: VehicleType = parseInt(props.match.params.vehicleType)
    const id = props.match.params.vehicleId

    const { workTask } = useWorkTask() ?? {}

    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()

    // Check if id is a tecdoc id (only digits)
    const match = TECDOC_ID_PATTERN.exec(id)

    if (match) {
        return (
            <DetailsByTecdocId
                {...props}
                vehicleType={vehicleType}
                modelId={parseInt(match[0])}
                workTaskId={decodeUniqueId(props.match.params.workTaskId)}
                availableVehicleSearches={availableVehicleSearches}
            />
        )
    }
    // Check if vehicle id is valid
    const vehicleId = VEHICLE_ID_PATTERN.test(id) ? id : decodeUniqueId(id)

    if (!vehicleId) {
        return null
    }

    // Check if vehicle id matches the current vehicle of the work task
    if (workTask?.vehicle && workTask.vehicle.id === vehicleId) {
        return (
            <DetailsByVehicle
                {...props}
                vehicleType={vehicleType}
                vehicle={workTask.vehicle}
                isWorkTaskVehicle
                availableVehicleSearches={availableVehicleSearches}
            />
        )
    }
    return <DetailsByVehicleId {...props} vehicleType={vehicleType} vehicleId={vehicleId} availableVehicleSearches={availableVehicleSearches} />
}

export default connectComponent(Actions, withRouter(Wrapper))
