import { getParameterFromUserContextModule, useUser } from "@tm/context-distribution"
import { Wheels } from "@tm/data"
import { UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { useQuery } from "react-query"

const KEY = "tyres_useGetStandardTyreSizes"

const cacheTime = {
    staleTime: 30 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    retry: 2,
}

export function useGetStandardTyreSizes() {
    const userContext = useUser()?.userContext
    const tyreSystemEnabled = useMemo(() => {
        return !!getParameterFromUserContextModule(userContext, UserModuleType.TMTires, "TyreSystem")
    }, [userContext])

    const { data, isLoading } = useQuery(
        [KEY],
        () =>
            Wheels.getStandardTyreSizes().then((response) => {
                return response
            }),

        { ...cacheTime, enabled: tyreSystemEnabled }
    )
    return { standardTyreSizes: data, loadingStandardTyres: isLoading }
}
