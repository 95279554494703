import { useRef, useCallback, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Text, Icon } from "@tm/controls"
import { mapStyle } from "./map-style"
import { TelematicsDataResponse } from "../../../../data"

type Props = {
    data?: TelematicsDataResponse
}

declare global {
    interface Window {
        initMap: () => void
    }
}

export default function GpsComponent(props: Props) {
    const { translateText, language } = useLocalization()
    const containerRef = useRef<HTMLDivElement>(null)

    const latitude = props.data?.gpsPosition?.latitude
    const longitude = props.data?.gpsPosition?.longitude
    const altitude = props.data?.gpsPosition?.altitude
    const headingOrientation = props.data?.gpsPosition?.headingOrientation

    const initMap = useCallback(() => {
        if (!containerRef.current) {
            return
        }

        const { HYBRID, ROADMAP, SATELLITE, TERRAIN } = google.maps.MapTypeId
        const MAP_TYPE_ID = "MAP_STYLE"

        let carPosition

        if (latitude && longitude) {
            carPosition = new google.maps.LatLng(latitude.value, longitude.value)
        } else {
            // Carl-Benz-Weg 1, 22941 Bargteheide as fallback
            carPosition = new google.maps.LatLng(53.72102961, 10.28169505)
        }

        const map = new google.maps.Map(containerRef.current, {
            center: carPosition,
            zoom: 12,
            mapTypeId: MAP_TYPE_ID,
            mapTypeControlOptions: {
                mapTypeIds: [MAP_TYPE_ID, ROADMAP, TERRAIN, SATELLITE, HYBRID],
            },
        })

        map.mapTypes.set(
            MAP_TYPE_ID,
            new google.maps.StyledMapType(mapStyle, {
                name: "TOPMOTIVE",
                alt: "Topmotive-Stil anzeigen",
            })
        )

        if (latitude && longitude) {
            // eslint-disable-next-line no-new
            new google.maps.Marker({
                position: carPosition,
                map,
                icon: {
                    fillColor: "#2196F3",
                    fillOpacity: 1,
                    path: "m 25.998,21.996 h 4 v -2 h -4 z m -8,0 h 4 v -2 h -4 z m 2,-8 h 8 l 2,4 h -12 z m 9.998,-2 H 17.998 l -4,8 v 4 h 2 c 0,1.106 0.894,2 2,2 1.106,0 2,-0.894 2,-2 h 8 c 0,1.106 0.894,2 2,2 1.106,0 2,-0.894 2,-2 h 2 v -4 z m -5.998,27.307 c 4.26,-3.994 12,-12.63 12,-19.306 0,-6.62 -5.382,-12 -12,-12 -6.618,0 -12,5.38 -12,12 0,6.676 7.74,15.312 12,19.306 z m 0,-35.306 c 8.822,0 16,7.178 16,16 0,8.824 -9.292,18.882 -16,24.634 -6.712,-5.756 -16,-15.814 -16,-24.634 0,-8.822 7.178,-16 16,-16 z",
                    strokeWeight: 0,
                    anchor: new google.maps.Point(21, 41),
                },
                title: "Fahrzeugposition",
            })
        }

        // Fix map loading and centering after page change
        setTimeout(() => {
            if (!map) {
                return
            }
            const center = map.getCenter()
            google.maps.event.trigger(map, "resize")
            if (center) {
                map.setCenter(center)
            }
        }, 100)
    }, [latitude, longitude])

    useEffect(() => {
        window.initMap = initMap
        initMap()
    }, [initMap])

    let mapClassName = "box__content map"
    mapClassName += !longitude || !latitude ? " map--disabled" : ""

    return (
        <div className="gps">
            <div className="box">
                <div className={mapClassName} ref={containerRef} />
                <div className="box__headline">GPS Position</div>
                <div className="box__content">
                    <div className="list">
                        {(!longitude || !latitude) && (
                            <div className="list__cell">
                                <Text modifiers="highlight">{translateText(583)}</Text>
                            </div>
                        )}
                        {latitude && latitude.value && (
                            <div className="list__cell">
                                <Text modifiers="highlight">{latitude.value.format(5)}</Text>
                                <Text size="s">{latitude.labelTextMap && latitude.labelTextMap[language.toLowerCase()]}</Text>
                            </div>
                        )}
                        {longitude && longitude.value && (
                            <div className="list__cell">
                                <Text modifiers="highlight">{longitude.value.format(5)}</Text>
                                <Text size="s">{longitude.labelTextMap && longitude.labelTextMap[language.toLowerCase()]}</Text>
                            </div>
                        )}
                        {altitude && altitude.value && (
                            <div className="list__cell">
                                <Text modifiers="highlight">{altitude.value.format(0)}</Text>
                                <Text size="s">{altitude.labelTextMap && altitude.labelTextMap[language.toLowerCase()]}</Text>
                            </div>
                        )}
                        {headingOrientation && headingOrientation.value && (
                            <div className="list__cell">
                                <Text modifiers="highlight">
                                    <div className="heading-orientation" style={{ transform: `rotateZ(${headingOrientation.value}deg)` }}>
                                        <Icon className="list__cell__icon icon--primary" name="up" size="xs" />
                                    </div>
                                </Text>
                                <Text size="s">{headingOrientation.labelTextMap && headingOrientation.labelTextMap[language.toLowerCase()]}</Text>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
