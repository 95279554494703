import { Stack, styled, VehicleImage } from "@tm/components"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, RepairTimeProvider, RequestArticleListPayload, SystemType, UserModuleType, VehicleType } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useCallback, useEffect, useMemo } from "react"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { getModuleFromUserContext, getParameterFromModule, useUser, useWorkTask } from "@tm/context-distribution"
import { MainState } from "../../main"
import { Actions } from "../business"
import { getBundleParams } from "../../../utils"
import { createSensorArticlesRequest } from "../../rdks-list/business/helper"

const StyledStack = styled(Stack)({
    height: "3em",
    flexDirection: "row",
    alignItems: "center",
    svg: {
        marginRight: "0.5em",
    },
})

const selector = createSelector(
    (s: MainState) => ({
        vehicle: s.manager.vehicle,
        articles: s.wheelsList.base.articles.data,
        showRimInfo: s.wheelsList.base.showRimInfo,
        display: s.wheelsList.configurator.display,
        productGroupTopicIds: s.wheelsList.base.productGroupTopicIds,
        isRDKSAvailable: s.navigation.isRDKSAvailable,
        rdksList: s.rdksList,
    }),
    (x) => x
)

export default function TopActions() {
    const actions = useActions(Actions, "changeConfiguratorDisplay", "changeRimInfoAndSettingVisibility", "getProductGroupTopicIds")
    const { vehicle, articles, showRimInfo, productGroupTopicIds, rdksList, isRDKSAvailable } = useSelector(selector)

    const { translateText } = useLocalization()
    const workTask = useWorkTask()
    const user = useUser()
    const { haynesProTdGenartsRoute, haynesProRouteWithCredentials, version } = getBundleParams()
    const hasHaynesPro = useMemo(
        () => !!workTask?.workTask?.vehicle?.dataAvailabilities?.technicalData?.haynesPro,
        [workTask?.workTask?.vehicle?.dataAvailabilities]
    )

    const { tmWheelsModule, hasHaynesProCredentials } = useMemo(() => {
        const wheelsModule = getModuleFromUserContext(user?.userContext, UserModuleType.TMWheels)

        return {
            tmWheelsModule: wheelsModule,
            hasHaynesProCredentials: wheelsModule ? getParameterFromModule(wheelsModule, "HasHaynesProCredentials", "boolean") : false,
        }
    }, [user?.userContext])

    const isNotStandalone = user.userContext.system.systemType === SystemType.Next
    const workTaskId = encodeUniqueId(workTask?.workTaskId ?? "")
    const productGroupId = articles?.first()?.rimErpInformations.first()?.productGroupId || 0
    const isLKQ = version === "LKQ"

    const isHaynesProTdButtonAvailable =
        isLKQ && isNotStandalone && (!!haynesProTdGenartsRoute || (hasHaynesProCredentials && !!haynesProRouteWithCredentials))

    useEffect(() => {
        Object.keys(productGroupTopicIds)?.length === 0 && vehicle && productGroupId && actions.getProductGroupTopicIds(vehicle, [productGroupId])
    }, [productGroupId])

    const handleHaynesProTechnicalDataClick = () => {
        if (productGroupId) {
            if (!hasHaynesPro && haynesProRouteWithCredentials) {
                Morpheus.showView(
                    "1",
                    renderRoute(haynesProRouteWithCredentials, {
                        workTaskId,
                        extModule: tmWheelsModule?.moduleSubId,
                    })
                )

                return
            }

            if (!haynesProTdGenartsRoute) {
                return
            }

            Morpheus.showView(
                "1",
                renderRoute(haynesProTdGenartsRoute, {
                    workTaskId,
                    topicId: productGroupTopicIds?.[productGroupId]?.[RepairTimeProvider.HaynesProCar],
                })
            )
        }
    }

    const openModal = useCallback(() => {
        const { selectedFilters, filters } = rdksList

        if (!vehicle) {
            return
        }

        const isExtendedAssortment = selectedFilters.manufacturer?.some((x) => !x.isTopPriority)

        const request = createSensorArticlesRequest(filters, selectedFilters, vehicle, isExtendedAssortment)

        if (!request || !request.productGroupIds) {
            return
        }

        const partsListRequest: RequestArticleListPayload = {
            productGroups: {
                ids: request.productGroupIds,
                supplierIds: request.supplierIds,
            },
            extendedAssortment: request.extendedAssortment,
            fittingPosition: request.fittingSideFilter,
            inModal: true,
            useNewModal: true,
        }

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", partsListRequest)
    }, [vehicle, rdksList])

    return (
        <div className="content_wheels-information">
            <Button isActive={showRimInfo} onClick={() => actions.changeRimInfoAndSettingVisibility(!showRimInfo, false)} icon="info">
                {translateText(931)}
            </Button>

            {isLKQ && (
                <Button disabled={!isRDKSAvailable} onClick={() => openModal()} icon="rdks">
                    {translateText(932)}
                </Button>
            )}

            {isHaynesProTdButtonAvailable && (
                <Button onClick={handleHaynesProTechnicalDataClick} icon="tech-data">
                    {translateText(13938)}
                </Button>
            )}
        </div>
    )
}
