import { batch, useDispatch, useSelector } from "react-redux"
import { RimDetailsArticle, TireSize } from "@bundles/wheels/data/model"
import { useWorkTask } from "@tm/context-distribution"
import { WheelSelectionSteps } from "@bundles/wheels/data/enums"
import { useMemo } from "react"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { Actions } from "../business"
import { ApplyBtn } from "../../_shared"

export function ContinueButton() {
    const dispatch = useDispatch()
    const selectedTireSize = useSelector<MainState, TireSize | undefined>((s) => s.wheelsDetails.selectedTireSize)
    const selectedRimDetailsArticle = useSelector<MainState, RimDetailsArticle | undefined>((s) => s.wheelsDetails.selectedRimDetailsArticle)
    const isTyresAvailable = useSelector<MainState, boolean | undefined>((s) => s.navigation.isTyresAvailable)
    const workTaskId = useWorkTask()?.workTaskId
    const disabledApplyBtn = useMemo(
        () => (isTyresAvailable ? !selectedRimDetailsArticle || !selectedTireSize : !selectedRimDetailsArticle),
        [isTyresAvailable, selectedRimDetailsArticle, selectedTireSize]
    )

    const handleApplyBtnClick = () => {
        batch(() => {
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.RDKSLIST }, true))
            dispatch(Actions.sendRimArticlesToOverview(workTaskId))
        })
    }
    return <ApplyBtn disabledApplyBtn={disabledApplyBtn} showApplyBtn onApply={handleApplyBtnClick} />
}
