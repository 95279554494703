import { WheelSelectionSteps } from "@bundles/wheels/data/enums"

export function getMatchParamFromActiveStep(activeStep: number) {
    switch (activeStep) {
        case WheelSelectionSteps.CARSELECTION:
            return "vehicle-selection"
        case WheelSelectionSteps.WHEELSLIST:
            return "wheels-list"
        case WheelSelectionSteps.WHEELDETAILS:
            return "wheels-details"
        case WheelSelectionSteps.RDKSLIST:
            return "rdks-list"
        case WheelSelectionSteps.RDKSDETAILS:
            return "rdks-details"
        case WheelSelectionSteps.TIRESLIST:
            return "tyres-list"
        case WheelSelectionSteps.TIREDETAILS:
            return "tyres-details"
        case WheelSelectionSteps.OVERVIEW:
            return "overview"
        default:
            return undefined
    }
}

export function getActiveStepFromMatchParam(selectedValue: string): WheelSelectionSteps {
    switch (selectedValue) {
        case "vehicle-selection":
            return WheelSelectionSteps.CARSELECTION
        case "wheels-list":
            return WheelSelectionSteps.WHEELSLIST
        case "wheels-details":
            return WheelSelectionSteps.WHEELDETAILS
        case "rdks-list":
            return WheelSelectionSteps.RDKSLIST
        case "rdks-details":
            return WheelSelectionSteps.RDKSDETAILS
        case "tyres-list":
            return WheelSelectionSteps.TIRESLIST
        case "tyres-details":
            return WheelSelectionSteps.TIREDETAILS
        case "overview":
            return WheelSelectionSteps.OVERVIEW
        default:
            return WheelSelectionSteps.NONE
    }
}
