import { useMemo, useState } from "react"
import { Alert, Box, Button, Icon, ListItemIcon, styled } from "@tm/components"
import { CreateCustomArticleRequest, CustomArticle, Note } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { NumberField, TextField } from "@tm/controls"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { StyledListItem } from "./shared"

const StyledNumberField = styled(NumberField)({
    margin: "0",
    marginRight: "0.5em",
})

const StyledTextField = styled(TextField)({
    margin: "0",
    marginRight: ".25em",
})

const StyledNoteField = styled(TextField)({
    margin: "0",
    width: "50%",
    alignSelf: "flex-start",
})

const StyledBox = styled(Box)({
    alignItems: "center",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr 1fr",
})

const ListItemWrapper = styled(StyledListItem)({
    display: "flex",
    flexDirection: "column",
    gap: ".5em",
    padding: "0.5em 1em 0.5em 0.5em",
    "&:hover": {
        border: "none",
    },
})

const ButtonsGroup = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    gap: ".25em",
    alignItems: "center",
})

type Props = {
    note?: Note
    saveDisabled?: boolean
    favoriteItem?: CustomArticle
    consumableItem?: CustomArticle
    handleConsumableSave: (selected: CreateCustomArticleRequest) => void
    handleSaveDefaultForCategory: (consumable: CustomArticle) => void
    handleConsumableDiscard: () => void
}

export default function ConsumableItemEdit({
    note,
    saveDisabled,
    favoriteItem,
    consumableItem,
    handleConsumableSave,
    handleConsumableDiscard,
    handleSaveDefaultForCategory,
}: Props) {
    const { translateText } = useLocalization()
    const user = useUser()
    const currencyString = getCurrencyFromUserContext(user?.userContext)
    const [description, setDescription] = useState<string | undefined>(consumableItem?.description ?? "")
    const [articleNumber, setArticleNumber] = useState<string | undefined>(consumableItem?.articleNumber ?? "")
    const [retailPrice, setRetailPricePrice] = useState<number | undefined>(consumableItem?.retailPrice ?? 0)
    const [purchasePrice, setPurchasePrice] = useState<number | undefined>(consumableItem?.purchasePrice ?? 0)
    const [consumableItemNote, setNote] = useState<string | undefined>(note?.text ?? "")
    const [isDefaultForCategory, setIsDefaultForCategory] = useState<boolean>(consumableItem?.isDefaultForCategory ?? false)

    const articleSaveDisabled = !articleNumber || !description || !purchasePrice || !retailPrice || saveDisabled

    const showAlert = useMemo(() => {
        return favoriteItem && favoriteItem?.id !== consumableItem?.id && isDefaultForCategory
    }, [favoriteItem, consumableItem, isDefaultForCategory])

    function handleArticleNumberChange(newArticleNumber: string | undefined) {
        setArticleNumber(newArticleNumber || "")
    }

    function handleDescriptionChange(newDescription: string | undefined) {
        setDescription(newDescription || "")
    }

    function handlePurchasePriceChange(value: number | undefined) {
        setPurchasePrice(value || 0)
    }

    function handleRetailPricePriceChange(value: number | undefined) {
        setRetailPricePrice(value || 0)
    }

    function handleToggleDefaultForCategory() {
        setIsDefaultForCategory((prev) => !prev)
    }

    function handleNoteChange(newNote: string | undefined) {
        setNote(newNote || "")
    }

    function handleSave() {
        if (!description) {
            return
        }

        const customArticle: CreateCustomArticleRequest = {
            articleNumber,
            description,
            purchasePrice,
            retailPrice,
            memo: consumableItemNote,
            isDefaultForCategory,
        }

        handleConsumableSave(customArticle)

        if (consumableItem?.id && !consumableItem.isDefaultForCategory && isDefaultForCategory) {
            const article: CustomArticle = {
                id: consumableItem?.id,
                version: consumableItem?.version,
                articleNumber,
                description,
                purchasePrice,
                retailPrice,
                memo: consumableItemNote,
                isDefaultForCategory,
            }
            handleSaveDefaultForCategory(article)
        }
    }

    return (
        <>
            <ListItemWrapper disablePadding>
                <StyledBox>
                    <StyledTextField
                        size="s"
                        value={articleNumber}
                        onChange={handleArticleNumberChange}
                        floatingLabel
                        label={translateText(87)}
                        maxLength={50}
                    />
                    <StyledTextField size="s" value={description} onChange={handleDescriptionChange} floatingLabel label={translateText(25)} />
                    <StyledNumberField
                        value={purchasePrice}
                        onChangeConfirm={handlePurchasePriceChange}
                        onChange={handlePurchasePriceChange}
                        minimum={0.0}
                        maximum={9999999}
                        nullable
                        stepSize={0.01}
                        floatingLabel
                        label={`${translateText(1132)} ${currencyString}`}
                    />
                    <StyledNumberField
                        value={retailPrice}
                        onChangeConfirm={handleRetailPricePriceChange}
                        onChange={handleRetailPricePriceChange}
                        minimum={0.0}
                        maximum={9999999}
                        nullable
                        stepSize={0.01}
                        floatingLabel
                        label={`${translateText(1257)} ${currencyString}`}
                    />
                    <ListItemIcon>
                        <Icon
                            name={isDefaultForCategory ? "filled-star" : "vote-star"}
                            width="1.5em"
                            height="1.5em"
                            onClick={() => handleToggleDefaultForCategory()}
                        />
                    </ListItemIcon>
                    <ListItemIcon />
                    <ButtonsGroup>
                        <Button size="medium" startIcon={<Icon name="close" />} onClick={() => handleConsumableDiscard()} />
                        <Button
                            size="medium"
                            startIcon={<Icon name="check" />}
                            onClick={() => handleSave()}
                            disabled={articleSaveDisabled}
                            color="success"
                        />
                    </ButtonsGroup>
                </StyledBox>
                <StyledNoteField size="s" value={consumableItemNote} onChange={handleNoteChange} floatingLabel label={translateText(12874)} />
            </ListItemWrapper>
            {showAlert && <Alert size="small" severity="error" title={translateText(13730)} variant="outlined" style={{ marginBottom: ".5em" }} />}
        </>
    )
}
