import { Article, ArticleIdentifier, CisCustomerResponse } from "@tm/models"
import { ReturnItem } from "./model"

export function mapCustomer(data: any): CisCustomerResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        companyName: data.companyName,
        customerName: data.companyNameAddition1,
        account: data.account,
        addresses: data.addresses || [],
        shippingTypes: data.shippingTypes || [],
        notes: data.notes || [],
        salesOutlets: data.salesOutlets || [],
    }
}

export function mapUpdatedReturnItem(returnItem: ReturnItem, article: Article) {
    return {
        ...returnItem,
        thumbnailUrl: article.thumbnail ?? returnItem.thumbnailUrl,
        productGroupName: article?.productGroup.name ?? returnItem.productGroupName,
        productGroupId: article?.productGroup.id ?? returnItem.productGroupId,
        description: article?.description ?? returnItem.description,
    }
}

export function mapReturnItemsToArticleIdentifiers(returnItems: ReturnItem[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    returnItems.forEach((returnItem) => {
        const part = mapPartItemToArticleIdentifier(returnItem)
        if (part) {
            parts.push(part)
        }
    })
    return parts
}

function mapPartItemToArticleIdentifier(returnItem: ReturnItem): ArticleIdentifier | undefined {
    const { dataSupplierId, dataSupplierArticleNumber, productGroupId } = returnItem
    if (!!dataSupplierId && !!productGroupId && dataSupplierArticleNumber) {
        return {
            supplierId: dataSupplierId,
            supplierArticleNo: dataSupplierArticleNumber,
            productGroupId,
        }
    }
}
