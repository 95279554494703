import { useCallback, useEffect, useMemo, useState } from "react"
import { batch, useSelector } from "react-redux"
import { Box, Collapse, Divider, Icon, IconButton, Stack, styled, Tooltip, Typography } from "@tm/components"
import { Checkbox, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { Filter } from "@tm/utils"
import { useAvailabilityStatus } from "@tm/context-distribution"
import { getBundleParams } from "@bundles/wheels/utils"
import { AvailabilityFilterType } from "../../../business"
import { filtersSelector, selectedFiltersSelector } from "../../../data/helpers"
import { FiltersSkeleton } from "../../../../../parts/src/components/ListV2/components/Filters/FiltersSkeleton"
import { Actions, ISensorFilters } from "../business"
import { AvailabilityFilter, SensorsFiltersArea } from "./SensorsFiltersArea"
import { SensorFilterItem } from "./SensorFilterItem"

type AccordionStates = {
    availability?: boolean
    manufacturer?: boolean
}

export default function SensorFilters() {
    const { translateText } = useLocalization()

    const actions = useActions(
        Actions,
        "loadSensorsList",
        "updateFilter",
        "resetFilter",
        "changeAvailabilityFilter",
        "toggleExtendedAssortmentFilter"
    )
    const selectedFilters = useSelector(selectedFiltersSelector)
    const filters = useSelector(filtersSelector)
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()

    const [visibilityButtonMinimized, setVisibilityButtonMinimized] = useState(false)
    const [filtersVisible, setFiltersVisible] = useState(true)
    const isLKQ = getBundleParams()?.version === "LKQ"

    const availabilityArray: AvailabilityFilter[] = []

    if (availabilityStatusIdsToShow?.length) {
        availabilityArray.push({
            name: translateText(1623),
            value: AvailabilityFilterType.Primary,
        })
    }

    if (availabilityStatusIdsToShowSecondary?.length) {
        availabilityArray.push({
            name: translateText(12860),
            value: AvailabilityFilterType.Secondary,
        })
    }

    const [accordionsState, setAccordionsState] = useState<AccordionStates>({
        ...(availabilityArray.length > 0 && { availability: true }),
        ...(filters.manufacturer.length > 0 && { manufacturer: true }),
    })

    const isFilterCollapsed = useMemo(() => !Object.values(accordionsState).some((value) => value === true), [accordionsState])

    useEffect(() => {
        setAccordionsState({
            ...(availabilityArray.length > 0 && { availability: true }),
            ...(filters.manufacturer.length > 0 && { manufacturer: true }),
        })
    }, [filters.manufacturer])

    const updateAccordionState = (key: string, value: boolean) => {
        setAccordionsState((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    const toggleAccordionsState = () => {
        const isAnyFilterOpen = Object.values(accordionsState).some((value) => value === true)

        setAccordionsState((prevState) => {
            const newState = Object.keys(prevState).reduce((acc: AccordionStates, key) => {
                acc[key] = !isAnyFilterOpen
                return acc
            }, {})

            return newState
        })
    }

    const handleFilterChange = (filter: Filter | AvailabilityFilter | null, path?: ISensorFilters) => {
        batch(() => {
            if (path) {
                actions.updateFilter(path, filter as Filter)
            } else {
                actions.changeAvailabilityFilter(
                    selectedFilters.availability === (filter as AvailabilityFilter).value
                        ? AvailabilityFilterType.None
                        : (filter as AvailabilityFilter).value
                )
            }
            actions.loadSensorsList()
        })
    }

    const handleResetAllFilters = () => {
        batch(() => {
            actions.resetFilter("manufacturer")
            actions.changeAvailabilityFilter(AvailabilityFilterType.None)
            actions.toggleExtendedAssortmentFilter(false)
            actions.loadSensorsList()
        })
    }

    const handleResetAvailability = () => {
        batch(() => {
            actions.changeAvailabilityFilter(AvailabilityFilterType.None)
            actions.loadSensorsList()
        })
    }

    const handleFilterReset = (path: ISensorFilters | undefined) => {
        batch(() => {
            path && actions.resetFilter(path)
            actions.loadSensorsList()
        })
    }

    const handleToggleFilterVisibility = () => {
        setFiltersVisible((prev) => !prev)
        setVisibilityButtonMinimized((prev) => !prev)
    }
    function getCollapseIcon(): string {
        if (isLKQ) {
            return filtersVisible ? "arrow-right" : "arrow-left"
        }
        return filtersVisible ? "arrow-left" : "arrow-right"
    }

    const handleCollapseExit = useCallback(() => {
        if (filtersVisible) {
            setTimeout(() => setVisibilityButtonMinimized(false), 500)
        }
    }, [filtersVisible])

    // #region ExtendedAssortment
    const renderExtendedAssortment = () => {
        const selected = selectedFilters.extendedAssortment

        const handleOnToggle = () => {
            if (selectedFilters.extendedAssortment && selectedFilters.manufacturer?.some((x) => !x.isTopPriority)) {
                return
            }
            actions.toggleExtendedAssortmentFilter(!selected)
        }

        return (
            <Box key="collapsible-filter--clipped-extended-assortment" className="collapsible filter filter--clipped filter--extended-assortment">
                <Box className="collapsible__header">
                    <Box className="collapsible__caption">
                        <Checkbox size="s" label={translateText(227)} checked={selected} onToggle={handleOnToggle} />
                    </Box>
                </Box>
            </Box>
        )
    }
    // #endregion ExtendedAssortment

    // #region Availability
    const renderAvailability = () => {
        if (!availabilityStatusIdsToShow?.length && !availabilityStatusIdsToShowSecondary?.length && !availabilityArray) {
            return null
        }

        return (
            <SensorsFiltersArea
                headline={translateText(746)}
                onChange={handleFilterChange}
                hideFilterSearch
                onResetFilters={handleResetAvailability}
                isExpanded={!!accordionsState.availability}
                onAccordionClick={updateAccordionState}
                dropdownPath="availability"
            >
                {availabilityArray?.map((filter, index) => (
                    <SensorFilterItem
                        key={index}
                        filter={filter}
                        checked={filter.value === selectedFilters.availability}
                        onChange={handleFilterChange}
                    />
                ))}
            </SensorsFiltersArea>
        )
    }
    // #endregion Availability

    // #region Manufacturer
    const renderManufacturer = () => {
        if (!filters.manufacturer.length) {
            return
        }

        return (
            <SensorsFiltersArea
                path="manufacturer"
                data={filters.manufacturer}
                headline={translateText(71)}
                onChange={handleFilterChange}
                selectedSensorsFilters={selectedFilters.manufacturer}
                onResetFilters={handleFilterReset}
                isExpanded={!!accordionsState.manufacturer}
                onAccordionClick={updateAccordionState}
                extendedAssortment={selectedFilters.extendedAssortment}
            />
        )
    }
    // #endregion Manufacturer

    // #region Header
    const renderHeader = () => {
        return (
            <StyledStack direction="row">
                <Tooltip title={translateText(visibilityButtonMinimized ? 13483 : 13484)} enterDelay={1000}>
                    <IconButton onClick={handleToggleFilterVisibility}>
                        <Icon size="16px" name={getCollapseIcon()} />
                    </IconButton>
                </Tooltip>
                <Box display="flex" justifyContent="space-between" width="100%">
                    {!visibilityButtonMinimized && <Typography>{translateText(209)}</Typography>}
                    {!visibilityButtonMinimized && (
                        <Box>
                            <Tooltip title={isFilterCollapsed ? translateText(13866) : translateText(13865)} enterDelay={1000}>
                                <Icon
                                    sx={{ mr: "1.2em", cursor: "pointer" }}
                                    name={isFilterCollapsed ? "filter-expand" : "filter-collaps"}
                                    onClick={toggleAccordionsState}
                                />
                            </Tooltip>
                            <Tooltip title={translateText(13857)} enterDelay={1000}>
                                <Icon sx={{ mr: "1.2em", cursor: "pointer" }} name="remove-filter" onClick={handleResetAllFilters} />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            </StyledStack>
        )
    }
    // #endregion Header

    return (
        <Stack marginX="1em" maxWidth="19em">
            {renderHeader()}
            <Divider sx={{ borderColor: "#ccc" }} />
            <Collapse in={filtersVisible} orientation="horizontal" sx={{ overflow: "hidden", flex: 1 }} addEndListener={handleCollapseExit}>
                {filters.loading && filtersVisible ? (
                    <Box width={290} key="loading">
                        <FiltersSkeleton />
                    </Box>
                ) : (
                    <StyledBox>
                        <Scrollbar>
                            <Box display="flex" flexDirection="column" className="panel__content">
                                {renderExtendedAssortment()}
                                {renderAvailability()}
                                {renderManufacturer()}
                            </Box>
                        </Scrollbar>
                    </StyledBox>
                )}
            </Collapse>
        </Stack>
    )
}

const StyledBox = styled(Box)({
    width: "290px",
    height: "100%",
    maxWidth: "19em",
    overflowY: "auto",
})

const StyledStack = styled(Stack)({
    flexGrow: "0",
    flexShrink: "0",
    flexBasis: "auto",
    alignItems: "center",
    alignSelf: "flex-start",
    width: "100%",
})
