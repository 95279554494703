import { useCallback } from "react"
import { useMutation } from "react-query"
import { VeFiles } from "@tm/data"

export function useGetUploadKey() {
    const { isLoading, mutateAsync } = useMutation(VeFiles.getUploadKey)

    const getUploadKey = useCallback(
        (maxFileCount: number, vehicleId: string, category: string, mileage: string, date: Date, invoiceTotal?: string) => {
            const request: VeFiles.GetUploadKeyRequest = {
                maxFileCount,
                application: "VeFiles",
                vehicleId,
                category,
                mileage,
                date,
                invoiceTotal,
            }

            return mutateAsync(request)
        },
        [mutateAsync]
    )

    return { isLoading, getUploadKey }
}
