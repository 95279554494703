import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Text } from "@tm/controls"
import { ExternalDocument } from "../../../../data/model"

type Props = {
    item: ExternalDocument
    onClick: (item: ExternalDocument) => void
}

export default function DocumentItemBtn({ item, onClick }: Props) {
    const style = useMemo(() => getStyle(), [])

    return (
        <div className={`${style.item} details-formulars-buttons`}>
            <Button className={style.button} onClick={() => onClick(item)} size="s">
                {item.name}
            </Button>

            <Text className={style.text} size="s">
                {item.description}
            </Text>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        item: {
            margin: theme.margin.m,
            display: "flex",
            borderTop: "1px solid #e2e2e2",
            paddingTop: theme.margin.m,
            alignItems: "center",
            $nest: {
                "&:first-child": {
                    borderTop: "none",
                },
            },
        },
        button: {
            $nest: {
                "&&": {
                    padding: theme.margin.m,
                    minWidth: "22em",
                    whiteSpace: "normal",
                },
            },
        },
        text: {
            flex: 1,
            marginLeft: theme.margin.m,
        },
    })(DocumentItemBtn)
}
