import { useCallback, useMemo } from "react"
import { ArticleAttribute } from "@tm/models"
import { useShowArticleDetails } from "../../hooks"
import { useFiltersContext } from "../Filters"
import { ArticleListActions } from "../../models"
import { useListParamsContext } from "../ListParams"
import { useBasketQuantitiesContext, useWholesalerBasketQuantitiesContext } from "../BasketQuantities"

export function useArticleListActions(): ArticleListActions {
    const showArticleDetails = useShowArticleDetails()
    const { toggleAttribute } = useListParamsContext()
    const toggleSupplier = useFiltersContext((x) => x.toggleSupplier)
    const { updateBasketQuantities } = useBasketQuantitiesContext()
    const { updateBasketQuantities: updateWholesalerBasketQuantities } = useWholesalerBasketQuantitiesContext() ?? {}

    const toggleInlineFilter = useCallback((attribute: ArticleAttribute) => {
        toggleAttribute(attribute)
    }, [])

    return useMemo<ArticleListActions>(
        () => ({
            showArticleDetails,
            toggleSupplier,
            toggleInlineFilter,
            updateBasketQuantities,
            updateWholesalerBasketQuantities,
        }),
        [showArticleDetails, toggleSupplier, toggleInlineFilter, updateBasketQuantities, updateWholesalerBasketQuantities]
    )
}
