import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import merge from "lodash/merge"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()
    const component = {
        fore: color(colors.light).setAlpha(opacity.primary).toRgbString(),
        hover: {
            fore: colors.light,
        },
    }

    const themeStyles: NestedCSSProperties = {
        color: component.fore,
        $nest: {
            "&__close": {
                $nest: {
                    "&:hover .icon": {
                        fill: component.hover.fore,
                    },
                    ".icon": {
                        fill: component.fore,
                    },
                },
            },
            "&:hover:not(&--selected)": {
                color: component.hover.fore,
                $nest: {
                    ".icon": {
                        fill: component.hover.fore,
                    },
                },
            },
        },
    }
    const mergedStyles = merge(baseStyles, themeStyles)
    return mergedStyles
}
