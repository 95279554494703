import { ajaxAuth, createBufferedRequestFunction, notUndefinedOrNull } from "@tm/utils"
import { Compilation, CompilationsContainsResponse, CompilationsFindResponse } from "@tm/models"
import type {
    ArticlesDeleteRequest,
    ArticleUpdateRequest,
    CompilationCreateRequest,
    CompilationShowAmountsResponse,
    CompilationShowResponse,
    CompilationUpdateRequest,
    CompilationsFindResponseEmbedded,
    ArticleMoveRequest,
    CompilationsContainsRequest,
    CompilationImportArticlesRequest,
    CompilationUpdateSortingRequest,
} from "../model"
import { mapArticleToAddRequest } from "../mapper/embedded"
import { CompilationArticleInterface } from "../interfaces/compilation-article-interface"
import { getCompilationsServiceUrl } from "../../utils"

export function addArticleEmbedded(articles: CompilationArticleInterface): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticlesAdd`
    const body = {
        compilationId: articles.compilationId,
        articles: mapArticleToAddRequest(articles.articles),
    }

    return ajaxAuth({ url, body, method: "POST" })
}

export function findCompilationsEmbedded(query?: string, pageIndex?: number, pageSize?: number): Promise<CompilationsFindResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationsFind`
    const body = { query, pageIndex, pageSize }

    return ajaxAuth<CompilationsFindResponseEmbedded>({ url, body }).then(
        (response) =>
            response && {
                ...response,
                compilations: response.compilations.map((compilation): Compilation => {
                    return {
                        ...compilation,
                        isOwn: true,
                        isPublic: true,
                        createDate: compilation.createDate,
                        updateDate: compilation.updateDate,
                    }
                }),
            }
    )
}

export function createCompilationEmbedded(body: CompilationCreateRequest): Promise<string> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationCreate`

    return ajaxAuth({ url, body, method: "POST" }).then((response) => response.compilationId)
}

export async function showCompilationEmbedded(compilationId: string) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationShow`
    const body = { compilationId }

    return ajaxAuth<CompilationShowResponse>({ url, body })
}

export async function updateArticleEmbedded(body: ArticleUpdateRequest) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticleUpdate`

    return ajaxAuth({ url, body, method: "POST" })
}

export async function deleteArticlesEmbedded(body: ArticlesDeleteRequest) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticlesDelete`

    return ajaxAuth({ url, body, method: "POST" })
}

export async function updateCompilationEmbedded(body: CompilationUpdateRequest) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationUpdate`

    return ajaxAuth({ url, body, method: "POST" })
}

export async function updateCompilationSortingEmbedded(body: CompilationUpdateSortingRequest) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationUpdateSorting`

    return ajaxAuth({ url, body, method: "POST" })
}

export async function deleteCompilationsEmbedded(compilationIds: string[]): Promise<any> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationsDelete`
    const body = { compilationIds }

    return ajaxAuth({ url, body, method: "POST" })
}

export async function importArticlesEmbedded(body: CompilationImportArticlesRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/tbd`

    return ajaxAuth({ url, body, method: "POST" })
}

export async function showAmountsEmbedded(compilationId?: string): Promise<CompilationShowAmountsResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationShowAmounts`
    const body = { compilationId }

    return ajaxAuth<CompilationShowAmountsResponse>({ url, body })
}

export async function moveArticleEmbedded(body: ArticleMoveRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticleMove`

    return ajaxAuth({ url, body, method: "POST" })
}

export const getContainingArticlesBuffered = createBufferedRequestFunction<
    CompilationsContainsRequest[],
    CompilationsContainsResponse[],
    CompilationsContainsResponse[]
>({
    callService: (requests) => showContainingArticles(requests.reduce((prev, cur) => [...prev, ...cur], [])),
    mapServiceResponseToResponse: (serviceResponse, request) => {
        const includedInLists = serviceResponse.filter((compilationItem) =>
            request.some(
                (y) =>
                    compilationItem.supplierArticleNumber === y.supplierArticleNumber &&
                    compilationItem.supplierId === y.supplierId &&
                    compilationItem.productGroupId === y.productGroupId
            )
        )

        return includedInLists.length ? includedInLists : []
    },
})

export function showContainingArticles(articles: CompilationsContainsRequest[]): Promise<CompilationsContainsResponse[]> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationsContain`

    return ajaxAuth<{ compilationItemIds: CompilationsContainsResponse[] }>({
        url,
        body: { articleNumbers: articles },
        method: "POST",
    }).then((data) => data?.compilationItemIds?.filter(notUndefinedOrNull) || [])
}

export function showCompilationEmbeddedPdf(compilationId: string) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationShowPdf`
    const body = { compilationId }

    return ajaxAuth<number[]>({ url, body, responseType: "blob" })
}
