import { PropsWithChildren, Component, ReactNode } from "react"

type Props = PropsWithChildren<{
    content?: () => ReactNode
}>

type State = {
    hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    static getDerivedStateFromError(): Partial<State> {
        return {
            hasError: true,
        }
    }

    public reset() {
        this.setState({ hasError: false })
    }

    render() {
        if (this.state.hasError) {
            return this.props.content?.()
        }

        return this.props.children
    }
}
