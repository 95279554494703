import { Box, Button, filledInputClasses, FormGroup, Icon, Loader, Stack, styled, TextField, Typography, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useState } from "react"

const InlineSearchButton = styled(Button, { shouldForwardProp: (prop) => prop !== "lowSearchField" })<{ lowSearchField?: boolean }>(({
    theme,
    lowSearchField,
}) => {
    // for the first showcase hardcoded, otherwise, theme.palette.highlight.main
    return {
        "&.MuiButton-sizeMedium": {
            backgroundColor: "#de9c35",
            borderRadius: `${theme.radius?.default || "2px"} !important`,
            borderTopLeftRadius: "0 !important",
            borderBottomLeftRadius: "0 !important",
            width: !lowSearchField ? "77px" : "50px",
            ...(lowSearchField && { padding: 0 }),
        },
        "&.MuiButtonBase-root:hover": {
            backgroundColor: "#f6c051",
        },
    }
})

const StyledTextField = styled(TextField, { shouldForwardProp: (prop) => prop !== "lowSearchField" })<{ lowSearchField?: boolean }>(({
    theme,
    lowSearchField,
}) => {
    // for the first showcase hardcoded, otherwise, theme.palette.highlight.main
    const borderColor = "#de9c35"
    return {
        [`.${filledInputClasses.root}`]: {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor,
            border: `1px solid ${borderColor}`,
            borderRadius: theme.radius?.default || "3px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            fontSize: "18px",
            "&.Mui-focused": {
                boxShadow: "none",
            },
        },

        // Input
        [`.${filledInputClasses.input}`]: {
            background: "none",
            padding: !lowSearchField ? "18px 11px 18px" : "7.5px 11px 7.5px",
        },
    }
})

const StyledIcon = styled(Icon, { shouldForwardProp: (prop) => prop !== "lowSearchField" })<{ lowSearchField?: boolean }>(({ lowSearchField }) => ({
    width: !lowSearchField ? "40px !important" : "30px !important",
    height: !lowSearchField ? "40px !important" : "30px !important",
    color: "white",
}))

type Props = {
    onStartSearch: (query: string) => void
    loading: boolean
    searchFieldWidth?: string
    lowSearchField?: boolean
}

export function AISearchField(props: Props) {
    const { onStartSearch, loading, searchFieldWidth, lowSearchField } = props
    const { translateText } = useLocalization()
    const [query, setQuery] = useState("")

    const handleStartSearch = () => {
        onStartSearch?.(query)
    }

    const theme = useTheme()
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    const InputProps = {
        endAdornment: (
            <Stack direction="row" spacing="4px" mr={1}>
                {loading && <Loader size="extrasmall" />}
            </Stack>
        ),
    }

    return (
        <Stack>
            <FormGroup
                row
                sx={{
                    flexWrap: "noWrap",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        boxShadow: isFocused ? "0 0 0 2px rgba(100, 101, 103, 0.25)" : "none",
                        borderRadius: theme.radius?.default || "2px",
                    }}
                >
                    <StyledTextField
                        disabled={loading}
                        InputProps={InputProps}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleStartSearch()
                            }
                        }}
                        sx={{ width: searchFieldWidth }}
                        value={query}
                        size="extralarge"
                        placeholder={translateText(13833)}
                        onChange={(e) => setQuery(e.target.value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        lowSearchField={lowSearchField}
                    />

                    <InlineSearchButton
                        disabled={loading}
                        onClick={handleStartSearch}
                        startIcon={<StyledIcon name="ki-search" lowSearchField={lowSearchField} />}
                        lowSearchField={lowSearchField}
                    />
                </Box>
            </FormGroup>
            <Box pt={1} display="flex" sx={{ alignContent: "center" }} flexWrap="wrap" mb={lowSearchField ? "-14px" : 0}>
                <Icon name="bulb" sx={{ marginRight: 1 }} height="20px" width="20px" />
                <Typography variant="body2" fontFamily="Roboto Condensed">
                    {translateText(13832)}
                </Typography>
            </Box>
        </Stack>
    )
}
