import { ErpInformation, ErpPartsModifiers } from "@tm/models"

export function getErpQuantityModifiers(response: ErpInformation): ErpPartsModifiers {
    // this is needed for tires, because default is 4
    const minQuantity = response.quantity?.minQuantity && response.quantity.minQuantity > 0 ? response.quantity.minQuantity : undefined
    const maxQuantity = response.quantity?.maxQuantity && response.quantity.maxQuantity > 0 ? response.quantity.maxQuantity : undefined
    const division = response.quantity?.division && response.quantity.division > 1 ? response.quantity.division : undefined

    return { minQuantity, maxQuantity, division, availability: response.availability.type }
}
