import { Button, Loader, PanelSection, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RouteComponentProps, withRouter, bem } from "@tm/utils"
import { batch, useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { FC, useCallback } from "react"
import { RimArticle, RimDetailsHeader } from "."
import { RimDetailsArticle } from "../../../data/model"
import { MainState } from "../../main"
import { Actions } from "../business"

type Props = RouteComponentProps & {
    repairTimesRoute: string
}

const selector = createSelector(
    (s: MainState) => ({
        rimItem: s.wheelsDetails.rimItem,
        selectedRimDetailsArticle: s.wheelsDetails.selectedRimDetailsArticle,
        loading: s.wheelsDetails.rimDetailsArticlesResponse.loading,
        data: s.wheelsDetails.rimDetailsArticlesResponse.data,
        error: s.wheelsDetails.rimDetailsArticlesResponse.error,
    }),
    (x) => x
)

const RimDetails: FC<Props> = ({ repairTimesRoute }) => {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const { rimItem, selectedRimDetailsArticle, loading, data, error } = useSelector(selector)

    const handleSelectItem = useCallback((rim: RimDetailsArticle) => {
        batch(() => {
            dispatch(Actions.selectRimDetailsArticle(rim))
            dispatch(Actions.loadRimDetailsAndRestrictions())
            dispatch(Actions.loadTiresRestrictions())
        })
    }, [])

    const handleRimQuantityChange = useCallback(
        (article: RimDetailsArticle, value: number) => {
            dispatch(Actions.changeRimDetailsArticleQuantity(article, value))
        },
        [rimItem?.idRimDesign]
    )

    const renderAxleCombinationArticles = () => {
        if (loading) {
            return (
                <div className="rim-details__items">
                    <div className="article-list__panel article-list__status">
                        <Loader />
                    </div>
                </div>
            )
        }

        if (!Object.keys(data).length || error) {
            return (
                <div className="rim-details__items">
                    <div className="article-list__panel article-list__status">
                        <Text>{translateText(323)}</Text>
                    </div>
                </div>
            )
        }

        return (
            <div className="rim-details__items">
                <Scrollbar>{Object.entries(data).map(renderAxleCombinationArticle)}</Scrollbar>
            </div>
        )
    }

    const renderAxleCombinationArticle = ([group, articles]: [string, RimDetailsArticle[]], idx: number) => {
        const uniqueRimSizes = articles.map((x) => x.rimSize).distinct()
        return (
            <div className={bem("article-list")} key={idx}>
                {uniqueRimSizes.map((x) => (
                    <Button size="l" key={group + x} isActive className={bem("axle-group")}>
                        {x}
                    </Button>
                ))}
                {articles.map((x, artIDx) => (
                    <RimArticle
                        key={artIDx + x.applicationId || x.eanCode}
                        item={x}
                        repairTimesRoute={repairTimesRoute}
                        onSelectItem={handleSelectItem}
                        onRimQuantityChange={handleRimQuantityChange}
                        isSelected={
                            selectedRimDetailsArticle?.artNr == x.artNr &&
                            selectedRimDetailsArticle?.appType == x.appType &&
                            selectedRimDetailsArticle?.idRim == x.idRim &&
                            selectedRimDetailsArticle?.applicationId == x.applicationId
                        }
                    />
                ))}
            </div>
        )
    }

    if (!rimItem) {
        return null
    }

    return (
        <PanelSection className="rim-details ">
            <RimDetailsHeader />
            {renderAxleCombinationArticles()}
        </PanelSection>
    )
}

export default withRouter(RimDetails)
