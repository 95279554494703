import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { DegreesColorsRequest, DegreesColorsResponse } from "./model"
import { mapResponseToDegreeColorsResponse } from "./mapper"

export function loadColors(request: DegreesColorsRequest) {
    const url = `${getServiceUrl()}/GetDegreesColors`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<DegreesColorsResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then(
            (response) => {
                const mappedResponse = mapResponseToDegreeColorsResponse(response)
                if (mappedResponse?.colors?.length) {
                    resolve(mappedResponse)
                } else {
                    reject()
                }
            },
            (reject) => {
                reject()
            }
        )
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.vehicleServiceUrl
}
