import { useEffect, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { EFilterNames, TyreFilter } from "@tm/models"
import { Box, Button, Icon, styled, useTheme } from "@tm/components"
import { IWidgetFilters } from "@bundles/tyres/hooks/widgetFilters/model"
import { ButtonLayout } from "@tm/controls"
import { ISummaryFilters } from "../summary/business/model"
import { CustomDropdown } from "./customDropdown"

type Props = {
    selectedWidth?: TyreFilter
    selectedHeight?: TyreFilter
    selectedInch?: TyreFilter
    widths: TyreFilter[]
    heights: TyreFilter[]
    inches: TyreFilter[]
    loading?: boolean
    className?: string
    maxItemsToShow?: number
    layout?: Array<ButtonLayout>
    customCoverView?: boolean
    disabled?: boolean
    iconName?: string
    onResetAll?: () => void
    onSearch?: () => void
    onChange(filterName: IAllFilters, value: TyreFilter): void
    onFocus?: () => void
}

type FocusedIndex = 0 | 1 | 2 | 3

export type IAllFilters = Partial<ISummaryFilters | IWidgetFilters>

export function DiameterFilters({
    heights,
    inches,
    onChange,
    onFocus,
    widths,
    className,
    onSearch,
    onResetAll,
    loading,
    maxItemsToShow,
    selectedHeight,
    selectedInch,
    selectedWidth,
    layout,
    customCoverView,
    disabled,
    iconName,
}: Props) {
    const { translateText } = useLocalization()
    const [focusedDropdown, setFocusedDropdown] = useState<FocusedIndex>(0)
    const submitButtonRef = useRef<HTMLButtonElement | null>(null)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const theme = useTheme()

    useEffect(() => {
        if (focusedDropdown === 2 && heights.length === 1) {
            setFocusedDropdown(3)
        }

        if (focusedDropdown === 3 && inches.length === 1) {
            setFocusedDropdown(0)
            timeoutRef.current && clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                if (onFocus) {
                    onFocus?.()
                } else {
                    submitButtonRef?.current?.focus()
                }
            }, 300)
        }
    }, [heights, inches])

    const focusDropdown = (i: FocusedIndex) => setFocusedDropdown(i)

    const handleChange = (filterName: IAllFilters, filter: TyreFilter) => {
        const value = filter?.query

        switch (filterName) {
            case EFilterNames.width:
                if (value !== selectedWidth?.value) {
                    onChange(filterName, filter)
                }
                focusDropdown(2)
                break
            case EFilterNames.height:
                if (value !== selectedHeight?.value) {
                    onChange(filterName, filter)
                }
                focusDropdown(3)
                break
            case EFilterNames.inch:
                if (value !== selectedInch?.value) {
                    onChange(filterName, filter)
                }
                focusDropdown(0)
                timeoutRef.current && clearTimeout(timeoutRef.current)
                timeoutRef.current = setTimeout(() => {
                    if (onFocus) {
                        onFocus?.()
                    } else {
                        submitButtonRef?.current?.focus()
                    }
                }, 300)
                break
            default:
                onChange(filterName, filter)
                break
        }
    }

    const handleOnResetAll = () => {
        onResetAll?.()
        focusDropdown(0)
    }

    return (
        <Box marginBottom={theme.margin?.m} display="flex" className={className} alignItems="center">
            {iconName && <Icon name={iconName} size="2em" sx={{ marginRight: ".5em" }} />}
            <StyledCustomDropdown
                filterName={EFilterNames.width}
                isActive={focusedDropdown === 1}
                loading={loading}
                onDropdownClick={() => {
                    focusDropdown(1)
                }}
                defaultItem={translateText(713)}
                items={widths}
                onChange={handleChange}
                selectedValue={selectedWidth}
                withResetButton={false}
                hasInput
                label={translateText(209)}
                maxItemsToShow={maxItemsToShow}
                submitOnTab
                layout={layout}
                disabled={disabled}
                customCoverView={customCoverView}
            />

            <StyledCustomDropdown
                filterName={EFilterNames.height}
                isActive={focusedDropdown === 2}
                onDropdownClick={() => {
                    focusDropdown(2)
                }}
                loading={loading}
                disabled={disabled ?? !selectedWidth}
                defaultItem={translateText(714)}
                items={heights}
                onChange={handleChange}
                selectedValue={selectedHeight}
                withResetButton={false}
                hasInput
                label={translateText(209)}
                maxItemsToShow={maxItemsToShow}
                submitOnTab
                layout={layout}
                customCoverView={customCoverView}
            />

            <StyledCustomDropdown
                filterName={EFilterNames.inch}
                isActive={focusedDropdown === 3}
                onDropdownClick={() => {
                    focusDropdown(3)
                }}
                loading={loading}
                disabled={disabled ?? !selectedWidth}
                defaultItem={translateText(715)}
                items={inches}
                onChange={handleChange}
                selectedValue={selectedInch}
                withResetButton={false}
                hasInput
                label={translateText(209)}
                maxItemsToShow={maxItemsToShow}
                submitOnTab
                layout={layout}
                customCoverView={customCoverView}
            />
            {onResetAll && (
                <Button
                    variant="text"
                    disabled={!selectedHeight && !selectedInch && !selectedWidth}
                    onClick={handleOnResetAll}
                    size="small"
                    startIcon={<Icon name="synchronize" />}
                />
            )}
            {onSearch && (
                <StyledButton
                    ref={submitButtonRef}
                    color="highlight"
                    startIcon={<Icon name="search" />}
                    onClick={onSearch}
                    disabled={!selectedHeight && !selectedInch && !selectedWidth}
                >
                    {translateText(135)}
                </StyledButton>
            )}
        </Box>
    )
}

const StyledCustomDropdown = styled(CustomDropdown, {
    shouldForwardProp: (prop) => prop !== "diffMargin",
})(({ theme }) => ({
    flex: 1,
    borderRadius: theme.box?.radius,
    margin: `0 ${theme?.margin?.xs || 0}`,
    "& .btn": {
        padding: theme.margin?.m,
    },
}))

const StyledButton = styled(Button)(({ theme }) => ({
    flex: 1,
    marginLeft: "0.5em",
    "& :focus": {
        border: `1px solid ${theme.colors?.["primary-active"]}`,
    },
}))
