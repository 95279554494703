import { OrderVoucherSupplierItem } from "@tm/models"
import { useQuery } from "react-query"
import { showSupplierArticleByVehicle } from "@tm/data/vouchers/orderVoucher"
import { useVehicle } from "../../hooks/useVehicle"
import { useArticlesContext } from "../Articles"

const SHOW_SUPPLIER_ARTICLE_BY_VEHICLE = "SHOW_SUPPLIER_ARTICLE_BY_VEHICLE"

export function usePreviouslyOrderedArticles(): OrderVoucherSupplierItem[] | undefined {
    const vehicle = useVehicle()
    const { productGroupIds } = useArticlesContext()

    const { data } = useQuery({
        queryKey: [SHOW_SUPPLIER_ARTICLE_BY_VEHICLE, vehicle?.id, productGroupIds],
        queryFn: () => showSupplierArticleByVehicle({ vehicleId: vehicle?.id || "", productGroupFilterIds: productGroupIds.join(",") }),
        enabled: !!productGroupIds.length && !!vehicle?.id,
    })

    return data?.orderedSupplierArticles
}
