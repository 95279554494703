import { useState } from "react"
import {
    DialogContent,
    DialogTitle,
    CloseOutlined as CloseIcon,
    styled,
    dialogTitleClasses,
    Typography,
    LinearProgress,
    FormHelperText,
    IconButton,
    Button,
    Box,
    VinField,
    DefaultDialog,
    Icon,
} from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { CarModel, channel, LookupConflictReason, RegisteredModels, RegistrationNoType, Vehicle, VehicleContainer, VehicleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, TmaHelper } from "@tm/utils"
import { getVinSuggestions } from "../../data/repositories/vin-picker"
import { searchVehiclesByVin, createVehicleFromModel } from "../../business"
import { getBundleParams } from "../../utils"
import { ConflictDialog } from "../../components/details/components/ConflictDialog"
import { handleDATRegistration, VrmLookupErrorTextIds } from "../../helpers"

const StyledDialog = styled(DefaultDialog)(({ theme }) => {
    return {
        lineHeight: "1.3",
        ".icon": {
            verticalAlign: "text-bottom",
            "&.info": {
                fill: theme.colors?.primary,
            },
        },
        [`.${dialogTitleClasses.root}`]: {
            ".icon": {
                marginRight: ".25em",
            },
        },
        ".close": {
            transform: "translate(0.5em,-0.25em)",
        },
    }
})

const StyledList = styled("ul")({
    listStyleType: "disc",
    padding: "0 0 0 1.5em",
})

type RequestDialogProps = {
    open: boolean
    className?: string
    onClose(): void
}

function RequestDialogComponent(props: RequestDialogProps) {
    const { open, className, onClose } = props
    const { workTask, reloadWorkTask, attachToWorkTask } = useWorkTask()!
    const { id: workTaskId, vehicle } = workTask!
    const { translate, translateText } = useLocalization()
    const [vin, setVin] = useState(vehicle?.vin || "")
    const [error, setError] = useState<string | null>(null)
    const { datPricePerRequest } = getBundleParams()
    const [alternativeVehicles, setAlternativeVehicles] = useState<CarModel[]>()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(true)

    async function loadSuggestions() {
        if (vehicle?.tecDocTypeId) {
            return getVinSuggestions("", vehicle.tecDocTypeId)
        }

        return Promise.resolve([])
    }

    async function handleStartRequest() {
        if (!vehicle) {
            return
        }

        setError(null)

        try {
            setLoading(true)

            const response = await searchVehiclesByVin(vin, RegistrationNoType.DatVin, true)
            const model = response?.models.find((x) => x.id === vehicle.tecDocTypeId)

            if (model) {
                if (vehicle.vin !== vin || vehicle.registrationTypeId !== RegistrationNoType.DatVin) {
                    const saveVehicle: Vehicle = {
                        ...vehicle,
                        vin,
                        registrationTypeId: RegistrationNoType.DatVin,
                    }

                    const container = Container.getInstance(RegisteredModels.Vehicle) as VehicleContainer
                    await container.action("saveVehicle")(saveVehicle)
                }

                reloadWorkTask()
                onClose()
            } else if (response?.models.length) {
                setAlternativeVehicles(response.models as CarModel[])
            } else {
                setError(translateText(13102))
            }
        } catch (errorTextId: unknown) {
            if (typeof errorTextId === "number") {
                const { datRegistrationPath } = getBundleParams()

                if (errorTextId === VrmLookupErrorTextIds.UserNotRegistered && datRegistrationPath) {
                    setVisible(false) // hide dialog because it would be in front of Morpheus modal
                    channel("GLOBAL").subscribeOnce("MODAL/CLOSED", () => setVisible(true)) // when Morpheus modal is closed show dialog again

                    // User is not already registered at DAT
                    const datRegistrationRoute = renderRoute(datRegistrationPath, { workTaskId: encodeUniqueId(workTaskId) })

                    handleDATRegistration(datRegistrationRoute).then(
                        () => handleStartRequest(),
                        () => setError(`${translateText(1476)} ${translateText(1477)} ${translateText(1478)}`)
                    )
                } else {
                    setError(translateText(errorTextId))
                }
            } else {
                setError(translateText(13102))
            }
        } finally {
            setLoading(false)
        }
    }

    async function handleChangeVehicle(model: CarModel, reuseVehicleData: boolean) {
        if (vehicle) {
            const createdVehicle = await createVehicleFromModel({
                model,
                registrationNoTypeId: RegistrationNoType.DatVin,
                vehicleType: vehicle.vehicleType,
                requestOnlyRegNo: vin,
                vin,
                ...(reuseVehicleData
                    ? {
                          plateId: vehicle.plateId,
                          initialRegistration: vehicle.initialRegistration,
                          engineCode: vehicle.engineCode,
                          mileAge: vehicle.mileAge,
                          mileageType: vehicle.mileType,
                          lastGeneralInspection: vehicle.lastGeneralInspection,
                          nextGeneralInspection: vehicle.nextGeneralInspection,
                          nextServiceDate: vehicle.nextServiceDate,
                          longlife: vehicle.longlife,
                      }
                    : {}),
            })

            attachToWorkTask?.({ vehicle: createdVehicle })

            TmaHelper.VehicleSelection.List.Search({
                dataSourceId: RegistrationNoType.DatVin,
                query: vin,
            })
        }
    }

    function handleCloseConflictDialog() {
        setAlternativeVehicles(undefined)
        onClose()
    }

    if (alternativeVehicles) {
        return (
            <ConflictDialog
                reason={LookupConflictReason.TecdocTypeNotMatch}
                vehicleType={VehicleType.PassengerCar}
                alternativeVehicles={alternativeVehicles}
                currentVehicle={vehicle}
                onSelectAlternativeVehicle={handleChangeVehicle}
                onClose={handleCloseConflictDialog}
            />
        )
    }

    return (
        <StyledDialog open={open && visible} className={className}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flex={1} display="flex" alignItems="center" gap={0.5}>
                        <Icon name="dat-vin-data" />
                        <Typography variant="body1">{translate(13099)}</Typography>
                    </Box>
                    <IconButton onClick={onClose} className="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box marginBottom={2}>{translate(13096)}</Box>
                <Box marginBottom={3} position="relative">
                    <Box display="flex" alignItems="flex-start" flexWrap="wrap" gap={1}>
                        <Box flex="0 0 22em">
                            <VinField vin={vin} onChange={setVin} loadSuggests={loadSuggestions} error={!!error} />
                            {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
                        </Box>
                        <Button
                            disabled={vin.length < 17 || loading}
                            size="extralarge"
                            onClick={handleStartRequest}
                            variant="contained"
                            color="primary"
                        >
                            {translate(1265)}
                        </Button>
                    </Box>
                    {loading && <LinearProgress sx={{ position: "absolute", width: "100%" }} />}
                </Box>
                <Box display="flex">
                    <Box marginRight={1}>
                        <Icon name="info" color="primary" size="24px" />
                    </Box>
                    <Box fontSize=".9em">
                        <Typography marginBottom={1} fontWeight="bold">
                            {translate(12423)}:
                        </Typography>
                        <StyledList>
                            <li>{translateText(1260).replace("{0}", datPricePerRequest || "2€")}</li>
                            <li>{translate(13097)}</li>
                            <li>{translate(13098)}</li>
                        </StyledList>
                    </Box>
                </Box>
            </DialogContent>
        </StyledDialog>
    )
}

export default RequestDialogComponent
