import { VoucherRouteParams } from "@bundles/vouchers/business"
import { voucherListFilterAtom } from "@bundles/vouchers/data/states/listFilters"
import { Box, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useParams } from "react-router"
import { useRecoilState } from "recoil"

export function NoResultHint() {
    const { translateText, date } = useLocalization()
    const params = useParams<VoucherRouteParams>()
    const [listFilters] = useRecoilState(voucherListFilterAtom(params.workTaskId))

    return (
        <Box mt={2} display="flex" justifyContent="center">
            <Typography>
                {translateText(279)
                    .replace("{0}", listFilters.startDate ? date(listFilters.startDate, "d") : "-")
                    .replace("{1}", listFilters.endDate ? date(listFilters.endDate, "d") : "-")}
            </Typography>
        </Box>
    )
}
