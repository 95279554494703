import { BundleTemplate, TemplateRenderer } from "@tm/morpheus"
import { PanelSection } from "@tm/controls"
import { IArticleDetailsHeadTemplate } from "@tm/models"
import { Box, Stack } from "@tm/components"
import { CSSProperties } from "react"

const Style: { [key: string]: CSSProperties } = {
    ArticleDetailsActions: {
        flex: "0 0 27%",
        paddingRight: "0.5rem",
        paddingBottom: "0.5rem",
    },
    ArticleErpInformation: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: "4em",
    },
    ArticleErpInformationLeft: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "0.25em",
    },
    ArticleErpInformationRight: {
        flex: "1",
        textAlign: "right",
    },
}

export const ArticleDetailsHeadTemplate: BundleTemplate<IArticleDetailsHeadTemplate & TemplateRenderer> = {
    name: "details-head",
    targetComponent: {
        bundle: "parts",
        component: "details-head",
    },
    render(r: IArticleDetailsHeadTemplate) {
        return (
            <PanelSection className="article-details__head">
                {r.renderImage()}
                {r.renderInfo()}
                <div style={Style.ArticleDetailsActions} className="article-details__actions">
                    <div style={Style.ArticleErpInformation} className="article-details__actions__erp">
                        <div style={Style.ArticleErpInformationLeft}>
                            {r.renderAvailability()}
                            {r.renderSpecialIcons()}
                            {r.renderGraduatedPriceInfo()}
                        </div>
                        <div style={Style.ArticleErpInformationRight}>
                            {r.renderPrices(false)}
                            {r.renderQuantityUnit()}
                        </div>
                    </div>
                    <Stack spacing={0.5} alignSelf="flex-end">
                        <Box>{r.renderAddToBasket(true)}</Box>
                        <Stack direction="row" spacing={0.75} justifyContent="flex-end">
                            {r.renderAddToCompilations()}
                            {r.renderCostEstimationButton(true)}
                            {r.renderPrintArticleDetails?.(true)}
                        </Stack>
                    </Stack>
                </div>
            </PanelSection>
        )
    },
}
