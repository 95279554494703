import { useUser, useWorkTask, useAddToBasketModuleParameters } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { IMicros, ArticleListRouteKey } from "@tm/models"
import { WithMicroProps, connectComponent } from "@tm/morpheus"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { Suspense, useMemo } from "react"
import { useRecoilValue } from "recoil"
import { ListState } from "./business"
import { Actions, IActions } from "./business/actions"
import ListComponent from "./component"
import { SelectedCriteriasState } from "../_shared/uni-search-top-products/state/SelectedCriteriasState"

export type ConfigProps = {
    /* eslint-disable react/no-unused-prop-types */
    groupParts?: boolean // optional - default value: true
    standaloneMode?: boolean // optional - default value: true
    scrollable?: boolean // optional - default value: true
    panelTitle?: string // optional - default value: undefined
    compactViewAsDefault: boolean
    scrollToRoute?: string
    alwaysExpandedArticleItems?: boolean
    articleAlternativesRoute?: string
    clippedFiltersClassName?: string
    showArticleComparision?: boolean
    showDocumentsInline?: boolean
    showDocumentsInCompact?: boolean
    showReferenceLinksInCompact?: boolean
    openDocumentsAsModal?: boolean
    isInTab?: boolean
    // The are keys from uiconfig to determine where the list is
    routeKey?: ArticleListRouteKey
}

export type RouteParams = {
    workTaskId?: string
    searchType?: string
    scrollTo?: string
}

export type WrapperTypes = WithMicroProps<IMicros> &
    RouteComponentProps<RouteParams> &
    ConfigProps & {
        state: ListState
        actions: IActions
        moduleProps?: {
            publishModuleInfo?: boolean
        }
        showAdditionalPrices?: boolean
        showErpPawnItems?: boolean

        calculatorRoute?: string
    }
/**
 * use the partlistdata context to fill up the list informations
 * @param props
 * @returns
 */
function ListComponentWrapper(props: WrapperTypes) {
    const { userContext, userSettings, setUserSetting } = useUser()
    const selectedCriteria = useRecoilValue(SelectedCriteriasState)
    const { workTask } = useWorkTask() ?? {}
    const localizationHook = useLocalization()
    const { showCostEstimationButton } = useAddToBasketModuleParameters()

    // get the selected criterias from different pages, like Dashboard bestseller page
    // parse it as Prop to the class component
    const initialCriterias = useMemo(() => {
        let criterias: string[] = []
        if (selectedCriteria?.length) {
            criterias = selectedCriteria.map((v) => v.queryStr)
        }
        return criterias
    }, [])

    return (
        <ListComponent
            {...props}
            userSettings={userSettings}
            userContext={userContext}
            workTask={workTask}
            setUserSetting={setUserSetting}
            localization={localizationHook}
            hideCostEstimationButton={!showCostEstimationButton}
            initialUniSelectedCriterias={initialCriterias}
        />
    )
}

/**
 * Initial the Context before use it
 * @param props
 */
function ListContextWrapper(props: WrapperTypes) {
    return (
        <Suspense fallback={null}>
            <ListComponentWrapper {...props} />
        </Suspense>
    )
}

export default connectComponent(Actions, withRouter(ListContextWrapper))
