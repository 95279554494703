import { TabControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import Restrictions from "./restrictions"
import { mountingPadsTabs } from "../../../../data/helpers"
import { MainState } from "../../../main"
import { Actions } from "../../business"

function Mountingpads() {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()

    const [selectedTab, setSelectedTab] = useState("TiresRestrictions")

    const { rimInfoTireRestrictionsResponse, rimInfoRimRestrictionsResponse } = useSelector((s: MainState) => ({
        rimInfoTireRestrictionsResponse: s.wheelsDetails.rimInfoTireRestrictionsResponse,
        rimInfoRimRestrictionsResponse: s.wheelsDetails.rimInfoRimRestrictionsResponse,
    }))

    useEffect(() => {
        !rimInfoTireRestrictionsResponse?.data?.length && dispatch(Actions.loadTiresRestrictions())
    }, [])

    return (
        <div className="mounting-pads">
            <TabControl className="mounting-pads-tabs" selectedTabIdentifier={selectedTab} onTabSelect={setSelectedTab}>
                {mountingPadsTabs.map((item, idx) => (
                    <TabControl.Tab key={idx} identifier={item.path}>
                        {translateText(item.translationId)}
                    </TabControl.Tab>
                ))}
            </TabControl>
            {selectedTab == "TiresRestrictions" && (
                <Restrictions data={rimInfoTireRestrictionsResponse.data} loading={rimInfoTireRestrictionsResponse.loading} />
            )}
            {selectedTab == "RimsRestrictions" && (
                <Restrictions data={rimInfoRimRestrictionsResponse.data} loading={rimInfoRimRestrictionsResponse.loading} />
            )}
        </div>
    )
}

export default Mountingpads
