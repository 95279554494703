import {
    Article,
    ArticleAttribute,
    ArticleAttributes,
    ArticlePrice,
    ArticleReference,
    CatalogPart,
    CustomPart,
    CustomWork,
    ErpPrice,
    FastCalculation,
    ModificationState,
    WorkCategory,
} from "@tm/models"
import { uniqueId } from "@tm/utils"
import { WorkTaskInfo } from "@tm/context-distribution"
import { AlternativeAttribute, ArticleForOe, Attribute, DrivemotiveArticle, ItemPrice, Reference, VknAttribute } from "../models/calculation"
import { LabourValues, SelectedWork } from "../models"

const mapReferences = (references?: Reference[]): ArticleReference[] => {
    let refs: ArticleReference[] = []

    if (references && references.length > 0) {
        refs = references.map((ref) => {
            return {
                information: ref.refTypeBez,
                referenceNo: ref.refNr,
                referenceType: ref.eRefType,
                description: ref.refTypeBez,
                value: ref.refNr,
                modificationState: ModificationState.NotModified,
            }
        })
    }

    return refs
}

const mapAttributeAlternatives = (alternatives?: AlternativeAttribute[]): ArticleAttribute[] => {
    let attributeAlternatives: ArticleAttribute[] = []

    if (alternatives && alternatives.length > 0) {
        attributeAlternatives = alternatives.map((attribute) => {
            return {
                id: parseInt(attribute.nr),
                description: attribute.description,
                abbreviation: attribute.shortDescription,
                text: attribute.value,
                key: attribute.nr,
                value: attribute.value,
                unit: attribute.unit,
                modificationState: ModificationState.NotModified,
                highlight: false,
                sortNo: parseInt(attribute.nr),
                isBlockSeperator: true,
                isOptimized: false,
                isDuplicatedAttribute: false, // Added to hide labels of duplicated attributes, e.g. multiple engine codes
                // productGroupId: attribute.;
            }
        })
    }

    return attributeAlternatives
}

const mapArticleAttributes = (attributes?: Attribute[], vknAttributes?: VknAttribute[]): ArticleAttributes[] => {
    let topAttributes: ArticleAttribute[] = []

    if (attributes && attributes?.length > 0) {
        topAttributes = attributes.map((attribute) => {
            return {
                id: parseInt(attribute.nr),
                description: attribute.description,
                abbreviation: attribute.shortDescription,
                text: attribute.value,
                key: attribute.nr,
                value: attribute.value,
                unit: attribute.unit,
                modificationState: ModificationState.NotModified,
                highlight: false,
                sortNo: parseInt(attribute.nr),
                isBlockSeperator: true,
                alternatives: mapAttributeAlternatives(attribute.alternatives),
                isOptimized: false,
                isDuplicatedAttribute: false, // Added to hide labels of duplicated attributes, e.g. multiple engine codes
                // productGroupId: attribute.;
            }
        })
    }
    let vehicleAttributes: ArticleAttribute[] = []

    if (vknAttributes && vknAttributes?.length > 0) {
        vehicleAttributes = vknAttributes.map((attribute) => {
            return {
                id: parseInt(attribute.nr),
                description: attribute.description,
                abbreviation: attribute.shortDescription,
                text: attribute.value,
                key: attribute.nr,
                value: attribute.value,
                unit: attribute.unit,
                modificationState: ModificationState.NotModified,
                highlight: false,
                sortNo: parseInt(attribute.nr),
                isBlockSeperator: true,
                // alternatives: attribute.alternatives ?? [],
                isOptimized: false,
                isDuplicatedAttribute: false, // Added to hide labels of duplicated attributes, e.g. multiple engine codes
                // productGroupId: attribute.;
            }
        })
    }

    const articleAttributes: ArticleAttributes[] = [{ topAttributes, articleAttributes: [], vehicleAttributes, partsListAttributes: [] }]
    return articleAttributes
}

const mapPrices = (prices?: ItemPrice[]): ArticlePrice[] => {
    let articlePrices: ArticlePrice[] = []

    if (prices && prices.length > 0) {
        articlePrices = prices.map((price) => {
            return {
                description: price.preisArtBez,
                priceType: price.preisArt,
                // traderDescription?: string
                // traderPriceType?: string
                value: price.preis,
                currencyCode: price.wkz,
                // currencySymbol: price.
                countryCode: price.lkz,
                quantityUnitId: price.peNr,
                quantityUnit: price.peBez,
                scaleQuantity: price.peNr,
                priceUnitId: price.peNr,
                priceUnit: price.wkz,
                validFrom: new Date(price.datVon),
                validTo: new Date(price.datBis),
                discount: price.rabattGrpString.length > 0 ? parseInt(price.rabattGrpString) : 0,
                discountGroupId: 0,
                deliveryStatusId: 0,
                deliveryStatus: 0,
                isTraderPrice: 0,
            }
        })
    }
    return articlePrices
}

export const mapDrivemotiveArticleToArticle = (item: ArticleForOe): Partial<Article> => {
    return {
        id: uniqueId(),
        supplier: {} as any,
        productGroup: {} as any,
        supplierArticleNo: item.article?.eArtNr,
        traderArticleNo: item.article?.hArtNr,
        description: item.article?.genBez,
        quantity: item.quantity,
        requestErpInfo: false,
        thumbnail: item.article?.thumb,
        attributes: mapArticleAttributes(item.article.attributes, item.article.vknAttributes),
        references: mapReferences(item.article.references),
        information: item.article.articleInfo,
        vehicleLinkageId: item.article.vknId ? parseInt(item.article.vknId) : 0,
        vehicleInformation: item.article.vknInfo,
        prices: mapPrices(item.article.artPreise),
    }
}

export const mapErpPrices = (article: DrivemotiveArticle) => {
    let erpPrices: ErpPrice[] = []
    if (article.artPreise && article.artPreise?.length > 0) {
        erpPrices = article.artPreise.map((price) => {
            return {
                type: price.preisArt,
                description: price.preisArtBez,
                shortDescription: price.meBez,
                value: price.preis,
                currencySymbol: price.wkz,
                currencyCode_Iso_4217: price.wkz,
                vat: 0,
            }
        })
    }
    return erpPrices
}

export function mapCustomWork(work: SelectedWork, hourlyRate: number): CustomWork {
    return {
        customWorkNumber: work.workId?.toString() || "",
        description: work.mainWork,
        time: work.hours || 0,
        hourlyRate,
        fixedPriceValue: work.hours * hourlyRate,
        categoryOfWork: WorkCategory.NotCategorized,
    }
}

export function mapCustomFixedPrice(fixedPrice: LabourValues): CustomWork {
    return {
        customWorkNumber: "",
        description: fixedPrice.title,
        fixedPriceValue: fixedPrice.price,
        categoryOfWork: WorkCategory.NotCategorized,
    }
}

export function mapCustomParts(item: ArticleForOe, label: string): CustomPart {
    return {
        articleNumber: item.oeNumber || "",
        description: label,
        quantityValue: item.quantity || 0,
        garagePrice: item.price || 0,
    }
}

export function mapDrivemotiveArticleToCatalogPart(item: ArticleForOe): CatalogPart {
    return {
        dataSupplierArticleNumber: item.article?.eArtNr || "",
        dataSupplierId: item.article?.einspNr || 0,
        dataSupplierName: item.article?.einspBez || "",
        productGroupId: item.article?.genArtNr || 0,
        productGroupName: item.article?.genBez || "",
        quantityValue: item.quantity,
        additionalDescription: "",
        description: "",
        fittingSide: item.article?.binKrit100,
        memo: "",
        thumbnailUrl: item.article?.thumb,
        wholesalerArticleNumber: item.article?.hArtNr,
    }
}

export function createImportCalculationRequest(
    worktask: WorkTaskInfo,
    articles: ArticleForOe[],
    vehicleId?: string,
    works?: SelectedWork[],
    customWorks?: LabourValues[],
    hourlyRate?: number,
    customParts?: CustomPart[]
): FastCalculation.ImportFastCalculationRequest | undefined {
    const allCustomWorks: CustomWork[] = []
    if (works && hourlyRate) {
        works.forEach((work) => {
            allCustomWorks.push(mapCustomWork(work, hourlyRate))
        })
    }
    if (customWorks?.length) {
        customWorks.forEach((fixedPrice) => {
            allCustomWorks.push(mapCustomFixedPrice(fixedPrice))
        })
    }

    return {
        workTaskId: worktask.id,
        vehicleId: uniqueId(),
        parts: articles.map((article) => mapDrivemotiveArticleToCatalogPart(article)),
        customWorks: allCustomWorks,
        customParts,
    }
}
