import { useMemo } from "react"
import { AdditionalModelIdentifier } from "@tm/models"
import { VehicleParams } from "../../search/OldSearchResultList"

export function useAdditionalModelIdentifier(query: string): AdditionalModelIdentifier {
    return useMemo(() => {
        const searchParams = new URLSearchParams(query)
        const plateId = searchParams.get(VehicleParams.PlateId) || undefined
        const vin = searchParams.get(VehicleParams.Vin) || undefined

        let initialRegistration: Date | undefined
        const initialRegistrationString = searchParams.get(VehicleParams.InitialRegistration)

        if (initialRegistrationString) {
            initialRegistration = new Date(initialRegistrationString)

            if (Number.isNaN(initialRegistration.getTime())) {
                initialRegistration = undefined
            }
        }

        return { plateId, vin, initialRegistration }
    }, [query])
}
