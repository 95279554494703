import { useCallback, useState } from "react"
import { useLocalization } from "@tm/localization"
import { ECounterType } from "@tm/models"
import { Icon, Tooltip, Button, Stack, LinkButton } from "@tm/components"
import { TmaHelper } from "@tm/utils"

type Props = {
    repairTimesUrl: string | undefined
    onCreateCustomWorkButtonClick(): void
}

export default function AddWork({ repairTimesUrl, onCreateCustomWorkButtonClick }: Props) {
    const { translateText } = useLocalization()
    const [openTooltip, setOpenTooltip] = useState<boolean>(false)

    const handleOpenTooltip = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateAddLabourTime)
        setOpenTooltip(!openTooltip)
    }, [openTooltip])

    const handleCreateButtonClick = useCallback(() => {
        onCreateCustomWorkButtonClick()
        setOpenTooltip(false)
    }, [])

    return (
        <Tooltip
            title={
                <Stack spacing={0.5}>
                    <Button startIcon={<Icon name="individual-repairtimes" />} variant="outlined" onClick={handleCreateButtonClick}>
                        {translateText(920)}
                    </Button>
                    {repairTimesUrl && (
                        <LinkButton startIcon={<Icon name="repairtimes" />} variant="outlined" to={repairTimesUrl} disabled={!repairTimesUrl}>
                            {translateText(83)}
                        </LinkButton>
                    )}
                </Stack>
            }
            variant="light"
            disableHoverListener
            open={openTooltip}
            onClickAway={() => setOpenTooltip(false)}
        >
            <Button
                // remove title from button if used inside from Tooltip to prevent double title use MUI errors
                // correct way is to use a Popper and a Tooltiop outside around the Button
                aria-label={translateText(1507)}
                onClick={handleOpenTooltip}
                startIcon={<Icon name="plus" />}
                color="highlight"
                variant="contained"
            />
        </Tooltip>
    )
}
