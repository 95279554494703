import { Box, styled } from "@tm/components"
import { Children, FC, PropsWithChildren, ReactNode } from "react"

type Props = PropsWithChildren<{
    icon: ReactNode
    expandIcon: ReactNode
    expanded: boolean
    fullHeight?: boolean
    keepContent?: boolean
    onClick?: (expanded: boolean) => void
}>

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "fullHeight",
})<{ fullHeight?: boolean }>(({ fullHeight }) => ({
    ...(fullHeight && {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    }),
}))

const LineBox = styled(Box)({
    borderBottom: "1px solid",
    borderBottomColor: "#9a9a9a",
    paddingBottom: "0.5em",
})

const Accordion: FC<Props> = (props) => {
    const { children, icon, expandIcon, expanded, onClick, fullHeight, keepContent } = props

    const handleClick = () => {
        onClick?.(!expanded)
    }

    const result = Children.toArray(children)
    return (
        <StyledBox fullHeight={fullHeight}>
            <LineBox sx={{ display: "flex", gap: "1em", alignItems: "center" }} onClick={handleClick}>
                <Box sx={{ display: "flex" }}>{expanded ? expandIcon : icon}</Box>
                {result[0]}
            </LineBox>
            {keepContent ? (
                <Box sx={{ padding: "8px 16px 16px", height: "100%", ...(!expanded && { display: "none" }) }}>{result[1]}</Box>
            ) : (
                <>{expanded && <Box sx={{ padding: "8px 16px 16px", height: "100%" }}>{result[1]}</Box>}</>
            )}
        </StyledBox>
    )
}

export default Accordion
