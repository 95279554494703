import { useState } from "react"
import { ArticlesContext, ArticlesProviderProps, useArticlesContext } from "../../../ContextProvider/Articles"
import { useAfterPaintEffect } from "../../../helpers"
import { UniversalPartsArticleData } from "../../../models"
import { useArticles } from "./useArticles"
import { usePartsAlternativesModuleState } from "../../../PartsAlternatives/PartsAlternativesModuleState"

export function UniversalPartsArticlesProvider({ setHasRendered, erpInfos, children }: ArticlesProviderProps) {
    const [startRender, setStartRender] = useState(false)
    const articlesData = useArticles(erpInfos, startRender)
    const isAlternativeSearch = usePartsAlternativesModuleState((x) => !!x)
    const hasPartToReplace = usePartsAlternativesModuleState((x) => x?.partToReplaceId)
    const isPartToReplaceDataLoaded = usePartsAlternativesModuleState((x) => x?.isLoaded)

    useAfterPaintEffect(() => {
        if (isAlternativeSearch && !isPartToReplaceDataLoaded && hasPartToReplace) {
            return
        }
        setStartRender(true)
    }, [isPartToReplaceDataLoaded])

    useAfterPaintEffect(() => {
        if (articlesData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articlesData.requestStatus])

    return <ArticlesContext.Provider value={articlesData}>{children}</ArticlesContext.Provider>
}

export function useUniversalPartsArticlesContext<TContext extends UniversalPartsArticleData>(): TContext
export function useUniversalPartsArticlesContext<TContext extends UniversalPartsArticleData, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useUniversalPartsArticlesContext<TContext extends UniversalPartsArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useArticlesContext(selector as never)
}
