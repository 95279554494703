import { batch } from "react-redux"
import {
    Article,
    GetProductGroupTopicIdsRequest,
    GetProductGroupTopicIdsResponse,
    HasRepairTimesRequest,
    HasRepairTimesResponse,
    RegisteredModels,
    RepairTimeProvider,
    SensorItemsRequest,
    SensorItemsResponse,
    Vehicle,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import {
    Filter,
    equals,
    getProductGroupsIdsFromArticles,
    getRepairTimeProviders,
    getRepairTimeProvidersByNames,
    getValue,
    isSameArticle,
} from "@tm/utils"
import { Wheels } from "@tm/data"
import { AvailabilityFilterType, BundleActionTypes, BundleActions } from "../../../business"
import { WheelSelectionSteps } from "../../../data/enums"
import { addOrRemoveItem } from "../../../data/helpers"
import { getBundleParams } from "../../../utils"
import { MainState } from "../../main"
import { createNextSensorArticlesRequest, createSensorArticlesRequest } from "./helper"
import { ISensorFilters, RDKSListState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "RDKS_ARTICLES_LOADING" }
    | { type: "RDKS_NEXT_ARTICLES_LOADING"; payload: boolean }
    | { type: "RDKS_ARTICLES_ERROR" }
    | { type: "RDKS_ARTICLES_LOADED"; payload: SensorItemsResponse }
    | { type: "RDKS_NEXT_ARTICLES_LOADED"; payload: { articleList: SensorItemsResponse; auto?: boolean } }
    | { type: "RDKS_NEXT_ARTICLES_ERROR" }
    | { type: "RDKS_ARTICLES_SELECT_ITEM"; payload: { article: Article; isMultipleSelect?: boolean } }
    | { type: "UPDATE_FILTER"; payload: { path: ISensorFilters; value: Filter } }
    | { type: "CHANGE_AVAILABILITY"; payload: AvailabilityFilterType }
    | { type: "RESET_FILTER"; payload: { path: ISensorFilters } }
    | { type: "SET_PRODUCTGROUP_TOPICIDS"; payload: GetProductGroupTopicIdsResponse }
    | { type: "CHANGE_EXTENDEDASSORTMENT"; payload: boolean }
    | { type: "SET_PRODUCTGROUP_REPAIRTIMES"; payload: { [key: number]: Array<RepairTimeProvider> } }
    | { type: "SET_REQUEST"; payload: { request: SensorItemsRequest } }

export const RDKS_LIST_DEFAULT_STATE: RDKSListState = {
    filters: { manufacturer: [] },
    articles: { autoNextCount: 0, data: [], pageIndex: 1, noMoreRdksArticles: false },
    selectedFilters: {
        availability: AvailabilityFilterType.None,
        extendedAssortment: false,
    },
    erpInformations: [],
    productGroupTopicIds: {},
    repairTimeAvailabilities: [],
    selectedArticles: [],
}

export function reduce(state = { ...RDKS_LIST_DEFAULT_STATE }, action: ComponentActionType): RDKSListState {
    switch (action.type) {
        case "SEND_SENSOR_FILTERS_RESPONSE": {
            const { manufacturer, productGroupId } = action.payload
            return {
                ...state,
                filters: { manufacturer, productGroupId },
            }
        }
        case "SEND_SENSOR_ITEMS_RESPONSE": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: action.payload,
                    error: false,
                    loading: false,
                    autoNextCount: 0,
                    pageIndex: 1,
                    noMoreRdksArticles: false,
                },
            }
        }
        case "SET_PRODUCTGROUP_TOPICIDS": {
            return {
                ...state,
                productGroupTopicIds: {
                    ...state.productGroupTopicIds,
                    ...action.payload,
                },
            }
        }
        case "RDKS_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: [],
                    error: false,
                    loading: true,
                    autoNextCount: 0,
                    pageIndex: 1,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    noMoreRdksArticles: false,
                },
            }
        }
        case "RDKS_ARTICLES_LOADED": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: action.payload.articles,
                    error: false,
                    loading: false,
                    pageIndex: 1,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    noMoreRdksArticles: !action.payload.hasNextPage,
                },
            }
        }
        case "RDKS_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    error: true,
                    loading: false,
                    pageIndex: 1,
                    loadingNextItems: false,
                },
            }
        }
        case "RDKS_ARTICLES_SELECT_ITEM": {
            if (action.payload?.isMultipleSelect) {
                const newArticle = action.payload.article

                const isAlreadySelected = state.selectedArticles?.some((article) => isSameArticle(article, newArticle))

                return {
                    ...state,
                    selectedArticles: isAlreadySelected
                        ? state.selectedArticles?.filter((article) => !isSameArticle(article, newArticle))
                        : [...(state.selectedArticles || []), newArticle],
                }
            }

            return {
                ...state,
                selectedArticles: (!equals(state.selectedArticles?.[0], action.payload.article) && [action.payload.article]) || undefined,
            }
        }

        case "SET_ERP_INFORMATIONS": {
            const erpInformations = [...state.erpInformations]

            action.payload.forEach((erp) => {
                const index = erpInformations.findIndex((x) => x.itemId === erp.itemId)
                if (index >= 0) {
                    erpInformations[index] = erp
                } else {
                    erpInformations.push(erp)
                }
            })

            return {
                ...state,
                erpInformations,
                articles: {
                    ...state.articles,
                    loading: false,
                    loadingAutoItems: false,
                },
            }
        }
        case "CHANGE_AVAILABILITY": {
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    availability: action.payload,
                },
            }
        }
        case "CHANGE_EXTENDEDASSORTMENT": {
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    extendedAssortment: action.payload,
                },
            }
        }
        case "UPDATE_FILTER": {
            const { path, value } = action.payload
            const oldStoredValues = getValue(state.selectedFilters, [path])
            const newValues = addOrRemoveItem(oldStoredValues, value)
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: newValues,
                },
            }
        }
        case "RESET_FILTER": {
            const { path } = action.payload
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: undefined,
                },
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            const { article, quantity } = action.payload
            const sensors = state.articles.data.map((sensor) => ({
                ...sensor,
                ...((sensor.id == article.id || sensor.id == article.internalId) && { quantity: quantity || article.quantity }),
            }))

            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: sensors,
                },
            }
        }
        case "RDKS_NEXT_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    noMoreRdksArticles: true,
                },
            }
        }
        case "RDKS_NEXT_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    ...((action.payload && {
                        loadingAutoItems: true,
                    }) || {
                        loadingNextItems: true,
                    }),
                    autoNextCount: (action.payload && state.articles.autoNextCount + 1) || 0,
                },
            }
        }
        case "RDKS_NEXT_ARTICLES_LOADED": {
            const { articleList, auto } = action.payload
            const newData = [...state.articles.data, ...articleList.articles]
            return {
                ...state,
                articles: {
                    ...state.articles,
                    ...(auto && {
                        loadingAutoItems: false,
                    }),
                    data: newData,

                    loading: false,
                    loadingNextItems: false,
                    pageIndex: state.articles.pageIndex + 1,
                    noMoreRdksArticles: !articleList.hasNextPage,
                },
            }
        }

        case "SET_PRODUCTGROUP_REPAIRTIMES": {
            return {
                ...state,
                repairTimeAvailabilities: {
                    ...state.repairTimeAvailabilities,
                    ...action.payload,
                },
            }
        }

        case "SET_REQUEST": {
            const { request } = action.payload

            return {
                ...state,
                request,
            }
        }
        default:
            return state
    }
}

function loadSensorsList(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { vehicle } = getState().manager
        const { selectedFilters, filters } = getState().rdksList

        if (!vehicle) {
            return
        }

        const isExtendedAssortment = selectedFilters.manufacturer?.some((x) => !x.isTopPriority)

        const request = createSensorArticlesRequest(filters, selectedFilters, vehicle, isExtendedAssortment)

        if (!request) {
            return
        }

        dispatch({ type: "SET_REQUEST", payload: { request } })

        batch(() => {
            dispatch({ type: "RDKS_ARTICLES_LOADING" })
            isExtendedAssortment && dispatch({ type: "CHANGE_EXTENDEDASSORTMENT", payload: isExtendedAssortment })
        })

        Wheels.loadSensorItems(request).then(
            (response) => {
                dispatch({ type: "RDKS_ARTICLES_LOADED", payload: response })
                dispatch(loadProductGroupRepairTimes(response.articles))
            },
            () => dispatch({ type: "RDKS_ARTICLES_ERROR" })
        )
    }
}

function loadProductGroupRepairTimes(articles: Array<Article>): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const {
            manager: { vehicle },
        } = getState()

        const productGroupIds = getProductGroupsIdsFromArticles(articles)
        const { repairTimeProviderIds } = getRepairTimeProviders()

        const providers = getRepairTimeProvidersByNames(repairTimeProviderIds)

        if (!vehicle || !providers.length || !productGroupIds.length) {
            return
        }

        const request: HasRepairTimesRequest = {
            repairTimeProvider: providers,
            modelId: vehicle.tecDocTypeId,
            productGroupIds,
            vehicleType: vehicle.vehicleType,
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then((response) => {
                if (response) {
                    dispatch({ type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: response })
                }
            })
    }
}

function toggleExtendedAssortmentFilter(extendedAssortment: boolean): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { vehicle } = getState().manager
        const { selectedFilters, filters } = getState().rdksList

        if (!vehicle) {
            return
        }

        const request = createSensorArticlesRequest(filters, selectedFilters, vehicle, extendedAssortment)
        if (!request) {
            return
        }

        dispatch({ type: "SET_REQUEST", payload: { request } })

        Wheels.loadSensorItems(request).then(
            (response) => {
                batch(() => {
                    dispatch({ type: "RDKS_ARTICLES_LOADED", payload: response })
                    dispatch({ type: "CHANGE_EXTENDEDASSORTMENT", payload: extendedAssortment })
                })
            },
            () => {
                batch(() => {
                    dispatch({ type: "RDKS_ARTICLES_ERROR" })
                    dispatch({ type: "CHANGE_EXTENDEDASSORTMENT", payload: extendedAssortment })
                })
            }
        )
    }
}

function loadNextSensorsList(auto?: boolean): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const state = getState()
        const { vehicle } = getState().manager

        if (!vehicle) {
            return
        }

        const isExtendedAssortment = state.rdksList.selectedFilters.manufacturer?.some((x) => !x.isTopPriority)

        const request = createNextSensorArticlesRequest(state.rdksList, vehicle, isExtendedAssortment)

        if (!request || state.rdksList.articles.loadingNextItems || state.rdksList.articles.loadingAutoItems) {
            return
        }

        dispatch({ type: "SET_REQUEST", payload: { request } })

        dispatch({ type: "RDKS_NEXT_ARTICLES_LOADING", payload: !!auto })

        Wheels.loadSensorItems(request).then(
            (response) => dispatch({ type: "RDKS_NEXT_ARTICLES_LOADED", payload: { articleList: response, auto } }),
            () => dispatch({ type: "RDKS_NEXT_ARTICLES_ERROR" })
        )
    }
}

function getProductGroupTopicIds(vehicle: Vehicle, productGroupIds: Array<number>): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        if (getBundleParams().haynesProTdGenartsRoute) {
            const state = getState()
            productGroupIds = productGroupIds.filter((id) => !state.rdksList.productGroupTopicIds.hasOwnProperty(id))

            if (!productGroupIds.length) {
                return
            }

            const request: GetProductGroupTopicIdsRequest = {
                modelId: vehicle.tecDocTypeId,
                vehicleType: vehicle.vehicleType,
                repairTimeProvider: [RepairTimeProvider.HaynesProCar],
                productGroupIds,
            }

            Container.getInstance<GetProductGroupTopicIdsResponse>(RegisteredModels.RepairTimes_TechnicalData_GetProductGroupTopicIds)
                .subscribe(request)
                .load()
                .then((response) => {
                    dispatch({ type: "SET_PRODUCTGROUP_TOPICIDS", payload: response })
                })
        }
    }
}

function sendArticleToOverview(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedArticles } = getState().rdksList
        if (!selectedArticles) {
            return
        }

        dispatch({ type: "SEND_SENSOR_TO_OVERVIEW", payload: selectedArticles[0] })
    }
}

function saveTpmsTab(selectedArticle: Article): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch, getState) => {
        const {
            rdksList: {
                selectedFilters,
                articles: { pageIndex },
            },
            navigation: { isTyresAvailable },
        } = getState()

        dispatch(
            BundleActions.saveData({
                tpmsTab: {
                    article: {
                        internalId: selectedArticle.internalId,
                        traderArticleNo: selectedArticle.traderArticleNo,
                        quantity: selectedArticle.quantity,
                    },
                    pageIndex,
                    selectedFilters,
                },
                activeStep: isTyresAvailable ? WheelSelectionSteps.TIRESLIST : WheelSelectionSteps.OVERVIEW,
                highestStepReached: WheelSelectionSteps.TIRESLIST,
            })
        )
    }
}

function selectRDKSArticle(article: Article, isMultipleSelect?: boolean): ComponentActionType {
    return { type: "RDKS_ARTICLES_SELECT_ITEM", payload: { article, isMultipleSelect } }
}

function updateFilter(path: ISensorFilters, value: Filter): ComponentActionType {
    return { type: "UPDATE_FILTER", payload: { path, value } }
}

function resetFilter(path: ISensorFilters): ComponentActionType {
    return { type: "RESET_FILTER", payload: { path } }
}

function changeAvailabilityFilter(value: AvailabilityFilterType): ComponentActionType {
    return { type: "CHANGE_AVAILABILITY", payload: value }
}

function changeExtendedAssortmentFilter(value: boolean): ComponentActionType {
    return { type: "CHANGE_EXTENDEDASSORTMENT", payload: value }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectRDKSArticle,
    sendArticleToOverview,
    changeAvailabilityFilter,
    updateFilter,
    resetFilter,
    loadSensorsList,
    getProductGroupTopicIds,
    loadNextSensorsList,
    changeExtendedAssortmentFilter,
    toggleExtendedAssortmentFilter,
    saveTpmsTab,
}
