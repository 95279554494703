import { CompilationsBundleParams } from "@tm/models"

export const version = {
    name: "compilations",
    version: "1.0",
}

let bundleParams: CompilationsBundleParams | undefined

export function initCompilationsBundle(params: CompilationsBundleParams) {
    bundleParams = params
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function isWM() {
    return bundleParams?.mode === "wm"
}

export function getCompilationsServiceUrl(): string {
    return getBundleParams().compilationsServiceUrl
}
