import { useWorkTaskBasketState } from "@bundles/basket"

export function useBasketHasItems(workTaskId: string) {
    const basketState = useWorkTaskBasketState(workTaskId)
    const {
        basket: {
            state: { hasItems },
        },
    } = basketState
    return !!hasItems
}
