import { QRCodeSVG } from "qrcode.react"
import { Box, LoadingContainer, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { useWorktaskShare } from "../hooks"

const qrCodeSize = 200

export function ShareContent() {
    const { translateText } = useLocalization()
    const { isLoading, shareLink, isError } = useWorktaskShare()

    const qrCode = useMemo(() => {
        if (isLoading) {
            return <div />
        }

        if (!shareLink || isError) {
            return <Typography>{translateText(13301)}</Typography>
        }

        return <QRCodeSVG value={shareLink} size={qrCodeSize} />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareLink, isLoading, isError])

    return (
        <Box display="flex" flexDirection="column" gap="1em" margin="1em">
            <Typography variant="h2">{translateText(13717)}</Typography>
            <Box display="flex" gap="1em" alignItems="center">
                <LoadingContainer height="auto" width="auto" isLoading={isLoading}>
                    <Box minWidth="150px" minHeight="150px" display="flex" alignItems="center" justifyContent="center" position="relative">
                        {qrCode}
                    </Box>
                </LoadingContainer>

                <Box>
                    <Typography>{translateText(13718)}</Typography>
                    <Typography>{translateText(13719)}</Typography>
                </Box>
            </Box>
        </Box>
    )
}
