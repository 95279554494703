import { batch, useSelector } from "react-redux"
import { isArray } from "lodash"
import { Box } from "@tm/components"
import { Button, Scrollbar } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { useCallback, useMemo, useState } from "react"
import { Article, GetArticleListByMatchCodeRequest, ListFilter, TyreArticleAttribute, TyreFilter } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { UniArticles } from "@tm/data"
import { ArticleType, RequestType } from "@bundles/parts/components/WheelsList/ContextProvider/WheelsList/models"
import { WheelsList } from "@bundles/parts"
import { ParticularStateProvider, WheelsListType } from "@bundles/parts/components/PureList/ContextProviders/Particular/ParticularContext"
import { IListFilters } from "@bundles/wheels/data/models"
import { getBundleParams } from "@bundles/wheels/utils"
import { mapTyreAttributeToTyreFilter } from "@bundles/tyres/data/helpers/mapTyreAttributeToTyreFilter"
import { ArticleListFeaturesDisableProvider } from "@bundles/parts/components/ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { MainState } from "../../main"
import { NextPageLoader } from "../../../../../parts/src/components/ListV2/components/NextPageLoader"
import { Actions } from "../business"
import { WheelSelectionSteps } from "../../../data/enums"
import { mapTyreFilterToListFilter } from "../../../../../tyres/src/data/helpers/mapTyreFilterToListFilter"

export function TyresArticleListV2() {
    const { translateText } = useLocalization()
    const { request, selectedArticles, selectedAvailability, articles, selectedFilters } = useSelector((s: MainState) => ({
        request: s.tyresList?.request!,
        selectedArticles: s.tyresList?.selectedItems,
        selectedAvailability: s.tyresList?.selectedFilters.availability,
        articles: s.tyresList?.articles,
        selectedFilters: s.tyresList?.selectedFilters,
    }))

    const [count, setCount] = useState(0)
    const { maxAutoRequestV2 } = getBundleParams()
    const isLKQ = getBundleParams()?.version === "LKQ"

    const isListFiltered =
        articles.autoNextCount === maxAutoRequestV2 - 1 && !!selectedAvailability && !articles.loadingAutoItems && count < articles.count

    const actions = useActions(
        Actions,
        "saveTyresListTab",
        "changeStep",
        "loadNextTiresList",
        "changeQuantity",
        "selectItem",
        "sendTireToOverview",
        "loadNextTiresList",
        "loadNextTiresList",
        "loadTiresList",
        "updateFilter"
    )

    const initializeScrollContainerSelector = "scrollbar__container__wheels__tyres"

    const onAddToBasket = useCallback(
        (article: Article, quantity: number) => {
            batch(() => {
                const newArticle = !quantity ? article : { ...article, quantity }
                actions.sendTireToOverview(newArticle)
                actions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, true)
                actions.saveTyresListTab(newArticle)
            })
        },
        [actions]
    )

    const toggleSelectedArticle = useCallback(
        (article: Article) => {
            actions.selectItem(article, isLKQ)
        },
        [actions]
    )

    const onChangeQuantity = useCallback(
        (article: Article, quantity: number) => {
            const existingArticle = selectedArticles?.find((item) => item.internalId === article.internalId)
            if (!existingArticle) {
                return
            }

            const newArticle = !quantity ? existingArticle : { ...existingArticle, quantity }
            actions.selectItem(newArticle, isLKQ)
        },
        [actions, selectedArticles]
    )

    const handleAutoNextPage = () => {
        if (!request) {
            return
        }

        if (articles.autoNextCount < maxAutoRequestV2 - 1) {
            actions.loadNextTiresList(true)
        }
    }

    const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
        const el = e.target as HTMLElement
        if (el.scrollHeight - el.scrollTop <= el.clientHeight + 175 && !isListFiltered) {
            actions.loadNextTiresList()
        }
    }

    const fetchArticles = useCallback(async (request: RequestType): Promise<ArticleType> => {
        try {
            const response = await UniArticles.getArticleListByMatchCode(request as GetArticleListByMatchCodeRequest)
            return response.uniArticles
        } catch (error: any) {
            if (error.message.includes("404") || error.response?.status === 404) {
                return []
            }
            throw error
        }
    }, [])

    const loadNextPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        actions.loadNextTiresList()
    }

    const selectedFiltersMapped: ListFilter[] = useMemo(
        () =>
            Object.keys(selectedFilters)
                .filter((x) => x !== "availability")
                .map((filterKey) => {
                    if (isArray(selectedFilters[filterKey])) {
                        const selectedFiltersGroup = selectedFilters[filterKey] as TyreFilter[]
                        return selectedFiltersGroup.map((filter) => mapTyreFilterToListFilter(filter))
                    }

                    const filter = selectedFilters[filterKey] as TyreFilter

                    if (!filter) {
                        return undefined
                    }

                    return [mapTyreFilterToListFilter(filter)]
                })
                .flatMap((x) => x)
                .filter((x) => x !== undefined) as ListFilter[],
        [selectedFilters]
    )

    const handleClickAttribute = useCallback(
        (articleAttribute: TyreArticleAttribute) => {
            const filter = mapTyreAttributeToTyreFilter(articleAttribute)

            if (!filter) {
                return
            }

            batch(() => {
                const path = filter.group as IListFilters

                actions.updateFilter(path, filter)
                actions.loadTiresList(true, path)
            })
        },
        [actions]
    )

    const context: WheelsListType = {
        type: "wheelsTyresList",
        request,
        selectedArticles,
        onAddToBasket,
        onChangeQuantity,
        toggleSelectedArticle,
        selectedAvailability,
        handleAutoNextPage,
        fetchArticles,
        initializeScrollContainerSelector,
        articleItemType: "Tyres",
        headerTitle: translateText(240),
        setArticleCount: setCount,
        selectedFilters: selectedFiltersMapped,
        handleClickAttribute,
        largeCheckbox: isLKQ,
    }

    return (
        <Scrollbar onScroll={handleScroll} className={initializeScrollContainerSelector}>
            <Box display="flex" flexDirection="column">
                <ArticleListFeaturesDisableProvider disableCostEstimationButton={isLKQ} disableBasketButton={isLKQ} displayOnlyQuantity={isLKQ}>
                    <ParticularStateProvider value={context}>
                        <WheelsList />
                    </ParticularStateProvider>
                </ArticleListFeaturesDisableProvider>
                {(articles.loadingNextItems || articles.loadingAutoItems) && <NextPageLoader canLoadNextArticles skeletonArticleCount={1} />}
                {isListFiltered && (
                    <Box display="flex" justifyContent="center">
                        <Button skin="highlight" onClick={loadNextPage}>
                            {translateText(12430)}
                        </Button>
                    </Box>
                )}
            </Box>
        </Scrollbar>
    )
}
