import { EreLinkImagesResponse, EreLinkImagesRawResponse } from "."

export function mapEreLinkImagesResponse(response: EreLinkImagesRawResponse): EreLinkImagesResponse {
    const { imageGroups } = response.vknImages
    // const imagesGroups = Object.entries(imageGroups).map(([key, value]) => ({ ...value, imageName: key }))
    return {
        ...response.vknImages,
        imageGroups: response.vknImages?.imageGroups?.filter((x) => x.imageName) ?? [],
    }
}
