import { GetCustomArticlesCategoriesResponse } from "@tm/models"
import { ajaxAuth } from "@tm/utils"
import { useQuery } from "react-query"
import { useMemo } from "react"
import { getBundleParams } from "../../utils"

function getReplaceArticlesCategory() {
    const url = getBundleParams().customArticlesServiceUrl
    return ajaxAuth<GetCustomArticlesCategoriesResponse>({ url: `${url}/GetCategories` }).then((data) => data?.categories)
}

export function useGetCustomArticlesCategory(genartNr: string | undefined, enabled: boolean) {
    const query = useQuery(["MVCCustomArticlesGetCategories", enabled], () => getReplaceArticlesCategory(), {
        enabled,
    })

    const categoryTextId = useMemo(() => {
        if (query.data && genartNr) {
            return query.data.find((x) => x.genArt.includes(+genartNr))?.textId
        }

        return undefined
    }, [query.data, genartNr])

    return { categoryTextId, ...query }
}
