import { MouseEvent, useState, useRef, useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, uniqueId, getStorage, useKeyValue, TmaHelper } from "@tm/utils"
import {
    Typography,
    Icon,
    Switch,
    SwitchProps,
    Button,
    Stack,
    SearchField,
    ModuleHeaderSlot,
    ScrollContainer,
    Box,
    Tooltip,
    tooltipClasses,
} from "@tm/components"
import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { ECounterType } from "@tm/models"
import { useHistory } from "react-router"
import { Actions, IActions } from "./business"
import { SearchState, WorkTaskListItem } from "./business/model"
import { getBundleParams } from "../../utils"
import OpenWorkTaskHistoryButton from "./components/OpenWorkTaskHistoryButton"
import WorkTasksList from "./components/WorkTasksList"
import { WorkTaskHistoryContent, WorkTaskHistoryContainer } from "./components/StyledComponents"

type Props = {
    state: SearchState
    actions: IActions
    icon?: string
    title?: string
    searchOnType?: boolean
    hideTimeColumn?: boolean
    showOnlyUserWorkTasksByDefault?: boolean
}

export const LOCAL_STORAGE_KEY = "WORKTASK_DELETE_DIALOG_DEACTIVATED"
const MAX_LOCAL_STORAGE_ENTRY_AGE = 60 * 60 * 24 * 1000

function SearchComponent(props: Props) {
    const localization = useLocalization()
    const { translateText, translate, date } = localization
    const history = useHistory()
    const { createWorkTask } = useWorkTask() || {}
    const { state, actions, icon, title, searchOnType, hideTimeColumn, showOnlyUserWorkTasksByDefault } = props
    const { loading, workTasks } = state
    const [deletionPromptOpen, setDeletionPromptOpen] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [query, setQuery] = useState<string>("")
    const [showWithOrderOnly, setShowWithOrderOnly] = useKeyValue({ key: "WORKTASK_SHOW_WITH_ORDER_ONLY", ownedByRepairShop: false })
    const onlyWithOrder = showWithOrderOnly ? showWithOrderOnly === "true" : false
    const [showOnlyUserWorkTasks, setShowOnlyUserWorkTasks] = useKeyValue({ key: "WORKTASK_SHOW_ONLY_USER_WORKTASKS", ownedByRepairShop: false })
    const onlyUserWorkTasks = showOnlyUserWorkTasks ? showOnlyUserWorkTasks === "true" : showOnlyUserWorkTasksByDefault
    const viewBoxRef = useRef<HTMLDivElement>(null)
    const searchTimeout = useRef<number>()
    const [viewOptionsExpanded, setViewOptionsExpanded] = useState(false)
    const { plateCode: defaultPlateCode } = useCountryCodeToLicensePlate()

    useEffect(() => {
        const localStorageEntry = getStorage("localStorage").getItem(LOCAL_STORAGE_KEY)
        /* delete localstorage entry after 24h if the user reload the page -> NEXT-15870 */
        if (localStorageEntry && +localStorageEntry < Date.now() - MAX_LOCAL_STORAGE_ENTRY_AGE) {
            getStorage("localStorage").removeItem(LOCAL_STORAGE_KEY)
        }

        document.addEventListener("keydown", (event) => {
            event.altKey && !event.key.includes("Alt") && setOpen(false)
        })
    }, [])

    function handleToggleHistory() {
        if (!open) {
            setQuery("")

            TmaHelper.GeneralCountEvent.Call(ECounterType.LastActivities)
            setTimeout(() => {
                actions.findWorktasks("", onlyWithOrder, onlyUserWorkTasks)
            }, 100)
        }
        setOpen(!open)
    }

    function handleLoadWorkTask(workTaskId: string) {
        setOpen(false)
        history.push(`/${encodeUniqueId(workTaskId)}`)
    }

    function handleSearchChange(searchTerm: string) {
        clearTimeout(searchTimeout.current)
        setQuery(searchTerm)

        if (!searchTerm || (searchOnType && searchTerm.length > 1)) {
            searchTimeout.current = window.setTimeout(() => {
                actions.findWorktasks(searchTerm, onlyWithOrder, onlyUserWorkTasks)
            }, 500)
        }
    }

    function handleSearch() {
        if (!searchOnType && query && query.length > 1) {
            clearTimeout(searchTimeout.current)
            actions.findWorktasks(query, onlyWithOrder, onlyUserWorkTasks)
        }
    }

    function handleShowWithOrderOnlyChange() {
        clearTimeout(searchTimeout.current)

        searchTimeout.current = window.setTimeout(() => {
            actions.findWorktasks(query, !onlyWithOrder, onlyUserWorkTasks)
        }, 500)

        setShowWithOrderOnly?.((!onlyWithOrder).toString())
    }

    function handleShowMyOrdersChange() {
        clearTimeout(searchTimeout.current)

        searchTimeout.current = window.setTimeout(() => {
            actions.findWorktasks(query, onlyWithOrder, !onlyUserWorkTasks)
        }, 500)

        setShowOnlyUserWorkTasks?.((!onlyUserWorkTasks).toString())
    }

    function handleWorktaskDelete() {
        actions.findWorktasks(query, onlyWithOrder, onlyUserWorkTasks)
    }

    async function handleClickCreateNewWorktask(workTask: WorkTaskListItem, e: MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        const workTaskId = uniqueId()
        await createWorkTask?.({
            workTaskId,
            customer: workTask.customer?.customerId,
            vehicle: workTask.vehicle?.vehicleId,
        })
        setOpen(false)
        history.push(`/${encodeUniqueId(workTaskId)}`)
    }

    function handleScrollBottom() {
        if (open) {
            actions.findWorktasksNextPage(query, onlyWithOrder, onlyUserWorkTasks)
        }
    }

    function renderWorkTaskLists() {
        const groupedWorkTasks = workTasks.groupBy((workTask) => (workTask.modifiedDate ? date(workTask.modifiedDate, "d") : "-"))

        return (
            <ScrollContainer
                onScrollBottom={handleScrollBottom}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginTop: "1rem",
                    marginRight: "1.25rem",
                    wordBreak: "break-word",
                }}
            >
                {Object.entries(groupedWorkTasks).map(([key, value], _index) => (
                    <Stack key={key} gap="5px">
                        <Typography marginLeft={1}>{key}</Typography>
                        <WorkTasksList
                            workTasks={value}
                            hideTimeColumn={hideTimeColumn}
                            defaultPlateCode={defaultPlateCode}
                            deletionPromptOpen={deletionPromptOpen}
                            onCreateNewWorkTask={handleClickCreateNewWorktask}
                            onPromptToggle={setDeletionPromptOpen}
                            onWorkTaskCompleted={handleWorktaskDelete}
                            onLoadWorkTask={handleLoadWorkTask}
                        />
                    </Stack>
                ))}
            </ScrollContainer>
        )
    }

    function handleOutsideClick(event: MouseEvent) {
        if (viewBoxRef.current && !viewBoxRef.current.contains(event.target as Node) && open && !deletionPromptOpen && !viewOptionsExpanded) {
            setOpen(false)
        }
    }

    function handleViewOptionsClick() {
        setViewOptionsExpanded((prev) => !prev)
    }

    function renderViewOptions() {
        return (
            <>
                <ViewOption label={translateText(143)} onChange={handleShowWithOrderOnlyChange} checked={onlyWithOrder} />
                <ViewOption label={translateText(13125)} onChange={handleShowMyOrdersChange} checked={onlyUserWorkTasks} />
            </>
        )
    }

    function renderViewBox() {
        if (!open) {
            return null
        }

        return (
            <WorkTaskHistoryContainer onClick={handleOutsideClick}>
                <Box maxWidth="50rem" width="100%" height="100%">
                    <WorkTaskHistoryContent ref={viewBoxRef}>
                        <Box display="flex" position="relative">
                            <Typography variant="h2" mt={2} mr={2}>
                                {translate(369)}
                            </Typography>
                            <ModuleHeaderSlot title={translateText(135)} isTabsView>
                                <SearchField
                                    size="large"
                                    placeholder={translateText(337)}
                                    value={query}
                                    onChange={handleSearchChange}
                                    onStartSearch={handleSearch}
                                    buttonDisabled={!query || query.length < 2}
                                    isLoading={loading}
                                    sx={{ width: "25rem" }}
                                />
                            </ModuleHeaderSlot>
                            <ModuleHeaderSlot title={translateText(222)} isTabsView>
                                <div style={{ position: "relative" }}>
                                    <Tooltip
                                        variant="light"
                                        color="primary"
                                        title={renderViewOptions()}
                                        open={viewOptionsExpanded}
                                        onClickAway={() => setViewOptionsExpanded(false)}
                                        placement="bottom-start"
                                        sx={(theme) => ({ [`.${tooltipClasses.tooltip}`]: { paddingLeft: theme.spacing(2) } })}
                                    >
                                        <Button onClick={handleViewOptionsClick}>{translateText(177)}</Button>
                                    </Tooltip>
                                </div>
                            </ModuleHeaderSlot>
                        </Box>
                        <Button
                            size="large"
                            startIcon={<Icon name="close" />}
                            onClick={handleToggleHistory}
                            sx={{
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translateX(1.3rem)",
                            }}
                        />
                        {renderWorkTaskLists()}
                    </WorkTaskHistoryContent>
                </Box>
            </WorkTaskHistoryContainer>
        )
    }

    function renderButtonText() {
        if (!title) {
            return null
        }

        // Checks if the consists only of digits
        return /^\d+/.test(title) ? translateText(title) : title
    }

    const { nextLight, hideWorktaskSearchButton } = getBundleParams()
    if (nextLight || hideWorktaskSearchButton) {
        return null
    }

    return (
        <>
            <OpenWorkTaskHistoryButton startIcon={<Icon name={icon || "menu"} />} title={translateText(369)} onClick={handleToggleHistory}>
                {renderButtonText()}
            </OpenWorkTaskHistoryButton>
            {renderViewBox()}
        </>
    )
}

export default connectComponent(Actions, SearchComponent)

function ViewOption(props: SwitchProps) {
    return (
        <Switch
            formControlLabelSx={(theme) => ({ display: "flex", paddingRight: theme.spacing(2) })}
            labelPlacement="start"
            size="medium"
            typographySx={{ marginRight: "auto" }}
            {...props}
        />
    )
}
