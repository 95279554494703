import { Headline, Icon } from "@tm/controls"
import { Paper, Box } from "@tm/components"
import { FC, PropsWithChildren } from "react"
import { getComponentStyles } from "./styles"

type Props = PropsWithChildren<{
    icon: string
    headline: string
}>

export const UploadPanel: FC<Props> = ({ icon, headline, children }) => {
    const classNames = getComponentStyles()

    return (
        <Paper sx={{ p: 3 }} elevation={2} className={classNames.panel}>
            <Box className={classNames.panelHeader}>
                <Icon name={icon} className={classNames.panelHeaderIcon} size="l" />
                <Headline size="xs">{headline}</Headline>
            </Box>
            <Box className={classNames.panelContent}>{children}</Box>
        </Paper>
    )
}
