import {
    channel,
    EFilterNames,
    getCurrentWorkTaskId,
    KeyValueStateContainer,
    RegisteredModels,
    ShowVehicleRecordsResponse,
    VehicleRecordsContainer,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { decodeUniqueId } from "@tm/utils"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { useParams } from "react-router"
import { MatchParams } from "@bundles/tyres/data/models"
import { getBundleParams } from "../../utils"
import { bundleChannel } from "../../data/channels"
import { mapVehicleRecordsResponse } from "../../data/mapper"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { createConfirmVehicleRecordsRequest, createShowVehicleRecordsRequest, isVehicleChanged } from "./business/helpers"
import { IVehicleRecordFilter } from "../summary/business"
import { VehRecords } from "./business"
import { getProductGroupFromCarTypeAndSeason, getProductGroupsFromCarTypeAndSeason } from "../../data/helpers"

const vehicleRecordFilterKeys: IVehicleRecordFilter[] = [EFilterNames.width, EFilterNames.height, EFilterNames.inch]

export default function ModuleManager() {
    const dispatch = useDispatch()
    const matchParams = useParams<MatchParams>()
    const { vehicle, vehRecords } = useSelector((s: MainState) => s.manager)
    const { selectedFilters, clippedFilters, clipedFiltersLoaded, filters } = useSelector((s: MainState) => ({
        selectedFilters: s.summary.selectedFilters,
        clippedFilters: s.list.clippedFilters,
        clipedFiltersLoaded: s.list.clipedFiltersLoaded,
        filters: s.summary.filters,
    }))

    useEffect(() => {
        const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(matchParams.workTaskId ?? "")
        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    dispatch(MainActions.setVehicle(newVeh))
                }
            },
            true
        )

        return unsubVehicle
    }, [])

    useEffect(() => {
        const unsub = channel("WORKTASK").subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", ({ quantity, article }) => {
            if (!article) {
                return
            }
            dispatch(MainActions.changeQuantity(article, quantity))
        })
        return unsub
    }, [])

    useEffect(() => {
        if (!vehicle?.id || !getBundleParams().vehicleRecordsEnabled) {
            dispatch(MainActions.setTyreSizes())
            dispatch(MainActions.loadSummaryFilters())
        }

        if (vehicle) {
            dispatch(MainActions.loadDriveRightTyres())
        }

        if (getBundleParams().vehicleRecordsEnabled && vehicle?.id && filters.initialized && filters.carTypeAndSeason?.length) {
            const productGroupIds = getProductGroupsFromCarTypeAndSeason(vehicle.vehicleType, filters.carTypeAndSeason)
            const req = createShowVehicleRecordsRequest(vehicle.id, productGroupIds)

            Container.getInstance<ShowVehicleRecordsResponse>(RegisteredModels.VehicleRecords)
                .subscribe(req)
                .load()
                .then((vehicleRecordsResponse) => {
                    const attributeFilters = vehicleRecordsResponse.productGroupFilters?.first()?.attributeFilters
                    if (attributeFilters) {
                        const records = mapVehicleRecordsResponse(attributeFilters, filters)
                        if (
                            (records.width && records.width.query !== selectedFilters.width?.query) ||
                            (records.height && records.height.query !== selectedFilters.height?.query) ||
                            (records.inch && records.inch.query !== selectedFilters.inch?.query)
                        ) {
                            dispatch(MainActions.saveVehicleRecords(records))
                            dispatch(MainActions.loadSummaryFilters())
                        }

                        dispatch(MainActions.setTyreSizes())
                    }
                })
        }
    }, [vehicle?.id, filters.initialized])

    useEffect(() => {
        const unsub = channel("WORKTASK", getCurrentWorkTaskId()).subscribe("MODULE/CLOSED", (x) => {
            if (x.includes("tyres/summary") || x.includes("tyres/list") || x.includes("tyres/details")) {
                dispatch(MainActions.resetStore())
            }
        })
        return unsub
    }, [])

    useEffect(() => {
        const unsub = channel("GLOBAL").subscribe("ERP/ERP_INFORMATION_LOADED", (erpInformation) => {
            dispatch(MainActions.setErpInformations(erpInformation))
        })

        const unsubClipped = bundleChannel().subscribe(
            "LOAD_TYRES_CLIPPED_FILTERS",
            ({ source, value }) => source !== "filter" && dispatch(MainActions.setFiltersClip(value)),
            true
        )

        return () => {
            unsubClipped?.()
            unsub?.()
        }
    }, [])

    useEffect(() => {
        const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
        const jsonCreds = JSON.stringify(clippedFilters)

        const savedClippedFilters = bundleChannel().last(1, "LOAD_TYRES_CLIPPED_FILTERS")?.last()?.content.value

        if (clippedFilters !== savedClippedFilters && clipedFiltersLoaded) {
            container
                .action("saveKeyValue")("TYRES_CLIPPED_FILTERS", jsonCreds)
                .then(() => {
                    bundleChannel().clear("LOAD_TYRES_CLIPPED_FILTERS")
                    bundleChannel().publish("LOAD_TYRES_CLIPPED_FILTERS", { source: "filter", value: clippedFilters })
                })
        }
    }, [clippedFilters])

    useEffect(() => {
        if (!getBundleParams().vehicleRecordsEnabled || !filters.initialized || !vehicle?.id || !filters?.carTypeAndSeason?.length) {
            return
        }

        const records: VehRecords = {}

        const productGroupId = getProductGroupFromCarTypeAndSeason(vehicle.vehicleType, filters.carTypeAndSeason, selectedFilters)

        vehicleRecordFilterKeys.forEach((key) => {
            const lastUpdate = vehRecords[key]
            const filter = selectedFilters[key]

            if (filter?.query && lastUpdate?.query !== filter?.query) {
                const request = createConfirmVehicleRecordsRequest(vehicle.id, filter, productGroupId, filters)
                if (request) {
                    Container.getInstance<VehicleRecordsContainer>(RegisteredModels.VehicleRecords).action("tyreFilterApplied")(request)

                    records[key] = filter
                }
            }
        })

        if (Object.keys(records).length) {
            dispatch(MainActions.saveVehicleRecords(records))
        }
    }, [selectedFilters.width, selectedFilters.height, selectedFilters.inch, filters.initialized])

    return null
}
