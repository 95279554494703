import { SubTitle } from "@tm/controls"
import { OE } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { FC } from "react"
import OeNumber from "./components/oe-number"

type Props = {
    positions?: Array<OE.OePosition>
    onPositionSelect(position: OE.OePosition, replacement?: OE.OeNumber): void
    hideSelectedPosition?: boolean
    hideDescription?: boolean
    hideReplacements?: boolean
    className?: string
}

const OePositions: FC<Props> = (props) => {
    const { translate } = useLocalization()
    const { positions, onPositionSelect, hideSelectedPosition, className, hideDescription, hideReplacements } = props

    if (!positions?.length) {
        return null
    }

    const selectedPosition = positions?.find((x) => x.isSelected)

    const handleReplacementSelect = (replacement: OE.OeNumber) => selectedPosition && onPositionSelect(selectedPosition, replacement)

    return (
        <div className={classes("oe-positions", className)}>
            {!hideSelectedPosition && (
                <div className="oe-positions__filters">
                    <SubTitle className="oe-positions__headline" size="s">
                        {translate(1814)}
                    </SubTitle>
                    <div className="oe-positions__number-wrapper">
                        {positions.map((position, key) => (
                            <OeNumber
                                key={key}
                                oeNumber={position}
                                onSelect={onPositionSelect}
                                hasSelectedReplacement={position.replacements?.some((x) => x.isSelected)}
                                hideDescription={hideDescription}
                            />
                        ))}
                    </div>
                </div>
            )}
            {
                // !! needed as otherwise a length of zero can result in a "0" being rendered
                !hideReplacements && !!selectedPosition?.replacements?.length && (
                    <div className="oe-positions__replacements">
                        <SubTitle className="oe-positions__headline" size="s">
                            {translate(1819)}
                        </SubTitle>
                        <div className="oe-positions__number-wrapper">
                            {selectedPosition.replacements.map((replacement, key) => (
                                <div key={key} className="oe-positions__replacement">
                                    <OeNumber oeNumber={replacement} onSelect={handleReplacementSelect} hideDescription={hideDescription} />
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default OePositions
