import { Backdrop, Box, Loader, OAuthButton } from "@tm/components"
import { useCostEstimationModuleParameters, useUser, useWorkTask, WorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ExternalModule, WorkTaskStatus } from "@tm/models"
import { encodeUniqueId } from "@tm/utils"
import { useCallback, useState } from "react"
import { useHistory } from "react-router"
import { ExternalBasketDialog } from "../../../../../basket/src/components/cost-estimation/components/navigation/ExternalBasketDialog"
import { useWorkTaskBasketState } from "../../../../../basket/src/hooks/basketState/useWorkTaskBasketState"
import WidgetItem, { WidgetItemComponentProps } from "./WidgetItem"

type Props = {
    config: ExternalModule
    widgetItemProps: Omit<WidgetItemComponentProps, "item" | "onClick" | "loading">
}

export function BoschWomWidget(props: Props) {
    const { workTaskId, workTask, createWorkTask } = useWorkTask() ?? {}

    if (!workTaskId) {
        return null
    }

    return (
        <BoschWomWidgetInternal
            {...props}
            workTaskId={workTaskId}
            workTaskNotYetPersistedInDatabase={workTask?.statusValue === WorkTaskStatus.Undefined}
            createWorkTask={createWorkTask}
        />
    )
}

function BoschWomWidgetInternal({
    config,
    widgetItemProps,
    workTaskId,
    workTaskNotYetPersistedInDatabase,
    createWorkTask,
}: Props & { workTaskId: string; workTaskNotYetPersistedInDatabase: boolean; createWorkTask: WorkTask["createWorkTask"] | undefined }) {
    const { translate } = useLocalization()
    const { userContext, updateExternalAuthenticationTokens } = useUser()
    const history = useHistory()

    const { requiredAuthModeForExternalBasket } = useCostEstimationModuleParameters()
    const requiresAuthorization =
        requiredAuthModeForExternalBasket &&
        !Object.keys(userContext.externalAuthenticationTokens).some((tokenId) => tokenId.includes(requiredAuthModeForExternalBasket))

    const { costEstimation } = useWorkTaskBasketState(workTaskId)
    const { externalModalUrl } = costEstimation.state
    const { importFromExternalBasket, exportToExternalBasket, resetExternalModalUrl } = costEstimation.actions

    const [loading, setLoading] = useState(false)

    function handleAuthFinished(status: "success" | "error" | "abort") {
        // Also reload tokens on "abort" because sometimes the feedback from the OAuth popup isn't working correctly
        if (status === "success" || status === "abort") {
            updateExternalAuthenticationTokens()
        }
    }

    const handleWidgetClick = useCallback(async () => {
        if (loading || requiresAuthorization) {
            return
        }

        setLoading(true)

        if (workTaskNotYetPersistedInDatabase) {
            await createWorkTask?.({
                workTaskId,
                skipRedirect: true,
            })
        }

        try {
            await exportToExternalBasket(true, true)
        } catch {
            setLoading(false)
        }
    }, [loading, requiresAuthorization, workTaskNotYetPersistedInDatabase, workTaskId, createWorkTask, exportToExternalBasket])

    const handleDialogClose = useCallback(async () => {
        resetExternalModalUrl()

        try {
            await importFromExternalBasket()
            history.push(`${encodeUniqueId(workTaskId)}/cost-estimation`)
        } finally {
            setLoading(false)
        }
    }, [importFromExternalBasket, resetExternalModalUrl, history, workTaskId])

    function renderOverlay() {
        if (requiresAuthorization) {
            return (
                <Box sx={{ position: "absolute", inset: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <OAuthButton
                        tokenId={requiredAuthModeForExternalBasket}
                        onAuthorizationFinished={handleAuthFinished}
                        sx={{ whiteSpace: "normal", maxWidth: "80%" }}
                    >
                        {translate(13870)}
                    </OAuthButton>
                </Box>
            )
        }

        if (loading) {
            return (
                <Backdrop sx={(theme) => ({ position: "absolute", inset: 0, zIndex: theme.zIndex.drawer + 1 })} open>
                    <Loader />
                </Backdrop>
            )
        }
    }

    return (
        <>
            <WidgetItem {...widgetItemProps} hideCover size="3x1" item={config} onClick={handleWidgetClick} Overlay={renderOverlay()} />
            <ExternalBasketDialog open={!!externalModalUrl} url={externalModalUrl} onClose={handleDialogClose} />
        </>
    )
}
