import { useLocalization } from "@tm/localization"
import { ElementRef, useRef } from "react"
import { Box } from "@tm/components"

import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { EngineCapacityFilter } from "../../../data/model/filters"
import Filter from "./_filter"

type Props = {
    items: Array<EngineCapacityFilter>
    clipped: boolean
    opened: boolean
    onToggleOpen(): void
    onToggleClip(): void
    onChangeFilter(item?: number): void
}

function EngineCapacityFilterComponent(props: Props) {
    const { translateText } = useLocalization()
    const filterRef = useRef<ElementRef<typeof Filter>>(null)

    const handleClick = (item: EngineCapacityFilter) => {
        props.onChangeFilter(item.isSelected ? undefined : item.engineCapacityCcm)
        !props.clipped && filterRef.current?.collapse()
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionFilterccm, !item.isSelected)
    }

    const renderItem = (item: EngineCapacityFilter) => {
        const className = `filter__item ${item.isSelected ? "filter__item--selected" : ""}`
        return (
            <Box className={className} textAlign="center" flex="0 0 4em" key={item.engineCapacityCcm} onClick={() => handleClick(item)}>
                {item.engineCapacityCcm}
            </Box>
        )
    }

    const { items, opened } = props
    const selectedItem = items.find((x) => !!x.isSelected)

    return (
        <Filter
            {...props}
            name={`${translateText(128)} (${translateText(331)})`}
            ref={filterRef}
            className="filters__filter"
            disabled={!(items && items.length)}
            value={!opened && selectedItem ? selectedItem.engineCapacityCcm.toString() : undefined}
        >
            <Box className="filter" display="flex" flexWrap="wrap">
                {items.map(renderItem)}
            </Box>
        </Filter>
    )
}

export default EngineCapacityFilterComponent
