import { useState, useEffect } from "react"
import { treeNavigationActions, useEdsStore } from "../../../data/state"
import { YqNode } from "../../../models"
import TreeNavigation from "../../_shared/TreeNavigation"
import { getTreeNavigation } from "../business"

function SelectionTree() {
    const selectedNode = useEdsStore((state) => state.treeNavigation.selectedNode)
    const vehicle = useEdsStore((state) => state.vehicle.data)
    const treeOption = useEdsStore((state) => state.main.treeOption)
    const treeNavigation = useEdsStore((state) => state.treeNavigation.data)
    const isTreeNavigationLoading = useEdsStore((state) => state.treeNavigation.isLoading)
    const vin = useEdsStore((state) => state.vehicle.data.vin)

    const [highlightOnStart, setHighlightOnStart] = useState(false)

    useEffect(() => {
        const yqVehicle = vehicle?.yqVehicle
        if (yqVehicle && !treeNavigation) {
            treeNavigationActions.setLoading(true)
            getTreeNavigation(treeOption, yqVehicle, vin)
        }
    }, [vehicle?.yqVehicle, treeOption, treeNavigation])

    useEffect(() => {
        if (!selectedNode) {
            return
        }

        setHighlightOnStart(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onNodeSelect(node: YqNode) {
        treeNavigationActions.setSelectedNode(node)
    }

    return (
        <TreeNavigation
            highlightOnStart={highlightOnStart}
            nodes={treeNavigation?.children}
            loading={isTreeNavigationLoading}
            onNodeSelect={onNodeSelect}
            selectedNode={selectedNode}
        />
    )
}

export default SelectionTree
