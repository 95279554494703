import { deg, rotate } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { classes } from "@tm/utils"
import { bem } from "../../data/helpers"

type Props = {
    className?: string
    zoomInEnabled?: boolean
    zoomOutEnabled?: boolean
    zoomLevel: number

    showArrows?: boolean
    showResetBtn?: boolean

    onZoomIn(): void
    onZoomOut(): void
    onReset(): void
    onArrow(direction: "left" | "up" | "right" | "down"): void
}

export default function GraphicActions({
    className,
    zoomInEnabled,
    zoomOutEnabled,
    zoomLevel,
    onArrow,
    onZoomIn,
    onReset,
    onZoomOut,
    showArrows,
    showResetBtn,
}: Props) {
    const style = useMemo(() => getStyle(), [])

    function renderArrowButton(direction: "left" | "up" | "right" | "down") {
        return <Button className={bem(style.button, direction)} disabled={zoomLevel == 1} onClick={() => onArrow(direction)} icon="up" />
    }

    return (
        <div className={classes(className, style.actions)}>
            <Button onClick={onZoomIn} disabled={!zoomInEnabled} className={style.button} layout={["rounded"]} icon="plus" />
            <Button onClick={onZoomOut} disabled={!zoomOutEnabled} className={style.button} layout={["rounded"]} icon="minus" />
            {showResetBtn && <Button onClick={onReset} disabled={zoomLevel == 1} className={style.button} layout={["rounded"]} icon="synchronize" />}

            {showArrows && (
                <div className={style.arrows}>
                    {renderArrowButton("left")}
                    <div className={style.verticalArrows}>
                        {renderArrowButton("up")}
                        {renderArrowButton("down")}
                    </div>
                    {renderArrowButton("right")}
                </div>
            )}
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        actions: {
            opacity: theme.opacity.primary,
        },
        arrows: {
            alignItems: "center",
            display: "flex",
        },
        verticalArrows: {
            display: "flex",
            flexDirection: "column",
        },
        button: {
            margin: `${theme.margin.xs} !important`,
            $nest: {
                "&--right": {
                    transform: rotate(deg(90)),
                },
                "&--down": {
                    transform: rotate(deg(180)),
                },
                "&--left": {
                    transform: rotate(deg(270)),
                },
                "&--up": {
                    transform: rotate(deg(0)),
                },
            },
        },
    })(GraphicActions)
}
