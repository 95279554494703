import { Customer, KeyValueStateContainer, RegisteredModels, Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { clone } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { BundleActionTypes } from "../../../business"
import { generateKey, mapStateForSaving, recreateStore } from "../../../data/helpers"
import { DeepPartial, ErePosition, MmtGroup } from "../../../data/models"
import { MainActions, MainActionsType, MainState } from "../../main"

export type ModuleManagerState = {
    vehicle?: Vehicle
    customer?: Customer
    loading?: boolean
    saving?: boolean

    progress: number
}

export type ComponentActionType =
    | BundleActionTypes
    | { type: "STATE_LOADED"; payload?: DeepPartial<MainState> | undefined }
    | { type: "STATE_LOADING" }
    | { type: "STATE_PROGRESS_UPDATE" }
    | { type: "INIT_WIDGET_SEARCH"; payload: { erePosition: ErePosition; selectedMmtGroup: MmtGroup; groups: MmtGroup[] } }

const DEFAULT_STATE: ModuleManagerState = {
    loading: true,
    progress: 0,
}

export const reduce = (state = clone(DEFAULT_STATE), action: MainActionsType): ModuleManagerState => {
    switch (action.type) {
        case "VEHICLE_SET": {
            const { vehicle } = action.payload
            return {
                ...state,
                vehicle,
            }
        }
        case "STATE_PROGRESS_UPDATE": {
            return {
                ...state,
                progress: state.progress + 1,
            }
        }
        case "STATE_LOADING": {
            return {
                ...state,
                progress: 0,
            }
        }
        case "STATE_LOADED": {
            return {
                ...state,
                progress: 0,
            }
        }
    }
    return state
}

let lastSaved = "" // TODO

const setVehicle = (vehicle: Vehicle, isFirstUpdate?: boolean, isWidgetSearch?: boolean): ComponentActionType => ({
    type: "VEHICLE_SET",
    payload: { vehicle, isFirstUpdate, isWidgetSearch },
})

const resetStore = (): AsyncAction<MainActionsType> => (dispatch, getState) => {
    dispatch({ type: "@@redux/INIT" })
}

const resetLocalStore =
    (vehicle?: Vehicle): AsyncAction<MainActionsType> =>
    (dispatch, getState) => {
        dispatch({ type: "RESET_LOCAL_STORE", payload: { vehicle } })
    }

const initWidgetSearch =
    (payload: { erePosition: ErePosition; selectedMmtGroup: MmtGroup; groups: MmtGroup[] }): AsyncAction<ComponentActionType> =>
    (dispatch, getState) => {
        dispatch({ type: "INIT_WIDGET_SEARCH", payload })
        dispatch({ type: "STATE_LOADED" })
    }

const deleteData =
    (workTaskId?: string): AsyncAction<ComponentActionType, MainState> =>
    (dispatch, getState) => {
        if (getBundleParams().disableStateSave) {
            return
        }

        const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
        container.action("deleteKeyValue")(generateKey(getState().manager.vehicle?.tecDocTypeId, workTaskId))
    }

const saveData =
    (workTaskId?: string): AsyncAction<ComponentActionType, MainState> =>
    (dispatch, getState) => {
        if (getBundleParams().disableStateSave) {
            return
        }

        const saveKey = generateKey(getState().manager.vehicle?.tecDocTypeId, workTaskId)
        const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
        const data = mapStateForSaving(getState())
        const serializedData = JSON.stringify(data)

        if (serializedData == lastSaved) {
            return
        }

        container
            .action("saveKeyValue")(saveKey, JSON.stringify(data))
            .then((_) => (lastSaved = serializedData))
    }

const loadSavedData =
    (workTaskId?: string, vehicle?: Vehicle): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        // if (getState().loading)
        //     return

        const {
            summary: {
                cars: { groups },
            },
        } = getState()

        if (getBundleParams().disableStateSave) {
            dispatch({ type: "STATE_LOADED" })

            return
        }

        dispatch({ type: "STATE_LOADING" })
        const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
        container
            .action("loadKeyValue")(generateKey(vehicle?.tecDocTypeId, workTaskId))
            .then(
                async (value) => {
                    const data = await recreateStore(() => dispatch({ type: "STATE_PROGRESS_UPDATE" }), vehicle, value && JSON.parse(value))
                    dispatch({ type: "STATE_LOADED", payload: data })
                    if (!data?.summary?.cars?.groups?.length) {
                        // todo batch actions
                        dispatch(resetLocalStore(vehicle))
                        dispatch(MainActions.loadEurotaxCars())
                    }
                },
                (_) => dispatch({ type: "STATE_LOADED" })
            )
    }

export type IActions = typeof Actions

export const Actions = { setVehicle, resetStore, saveData, loadSavedData, deleteData, initWidgetSearch }
