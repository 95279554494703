import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { ERIKContentService } from "@tm/data"
import { useEmailShare } from "./useEmailShare"
import { ShareEmailTemplateProps, TemplateTitle } from "../../models"

const EMAIL_BODY_LIMIT = 30000

export function useVeFilesEmailShare(onShareCompleted?: (success: boolean) => void) {
    const { translateText } = useLocalization()
    const customer = useWorkTask()?.workTask?.customer
    const { userSettings } = useUser()
    const { sendEmail } = useEmailShare(onShareCompleted)
    const { isLoading: emailTemplateLoading, getEmailTemplate } = ERIKContentService.useGetEmailTemplate()

    const fetchTemplate = async (fileUrl: string, validUntil: string, ignoreImage?: boolean, callCounter = 1): Promise<string> => {
        const templateContent: ShareEmailTemplateProps = {
            logoUrl: ignoreImage ? "" : (userSettings?.repairShop?.logo ?? ""),
            textIntroduction: translateText(13818).replace("{{0}}", `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`),
            textFileDescription: translateText(13820).replace("{{1}}", validUntil),
            documentLinkText: translateText(13819),
            textEnding: translateText(13821).replace("{{3}}", userSettings?.repairShop?.companyName || ""),
            textDisclaimer: translateText(13822),
            documentUrl: fileUrl,
        }

        const template = await getEmailTemplate(templateContent, TemplateTitle.DocumentSharingEmail)

        if (!template?.url) {
            return ""
        }

        const response = await fetch(template.url)
        if (response.ok) {
            const htmlTemplate = await response.text()

            if (htmlTemplate.length <= EMAIL_BODY_LIMIT) {
                return htmlTemplate
            }

            if (callCounter <= 3) {
                return fetchTemplate(fileUrl, validUntil, true, ++callCounter)
            }
        }

        return ""
    }

    const handleSendEmail = async (emails: string[], fileUrl: string, validUntil: string, bcc?: string[]) => {
        const htmlTemplate = await fetchTemplate(fileUrl, validUntil)

        if (htmlTemplate) {
            sendEmail(emails, htmlTemplate, bcc)
        }
    }

    return {
        isLoading: emailTemplateLoading,
        sendEmail: handleSendEmail,
    }
}
