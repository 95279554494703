import { VehicleImage } from "@tm/components"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Dialog, Image, Radio, RealTable, Switch, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CarModel, LookupConflictReason, Vehicle, VehicleType } from "@tm/models"
import { classes, concat, plateCountryCodeMapper } from "@tm/utils"

import { FC, Key, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { getImageTypeByVehicleType } from "../../../../business"
import { renderDateRange } from "../../../../helpers/construction-year"
import { getBundleParams } from "../../../../utils"

import {
    AlternativeVehiclesStep,
    AlternativeVehiclesStepTableWrapper,
    AlternativeVehiclesStepThumbnail,
    ArrowIcon,
    StepDescription,
    StyledDialog,
    StyledLicensePlateField,
    SwitchWrapperStep,
    VehicleCard,
    VehicleCardDetail,
    VehicleCardDetails,
    VehicleCardHeader,
    VehicleCardHeadline,
    VehicleCardThumbnails,
    VehicleCardsStep,
} from "./styled-components"
import { ConflictFlow, ConflictStep, ConflictStepStatus, VehicleSimple } from "./models"
import { mapCarModelToVehicleSimple, mapVehicleToVehicleSimple } from "./mapper"

type Props = {
    reason: LookupConflictReason
    alternativeVehicles?: Array<CarModel>
    currentVehicle?: Vehicle
    vehicleType: VehicleType
    onClose(): void
    onSelectAlternativeVehicle(vehicle: CarModel, reuseVehicleData: boolean): void
}

export const ConflictDialog: FC<Props> = (props) => {
    const { translate, translateText, date } = useLocalization()

    const [changeVehicle, setChangeVehicle] = useState<boolean | undefined>(false)
    const [selectedAlternative, setSelectedAlternative] = useState<CarModel | undefined>(
        props.alternativeVehicles?.length === 1 ? props.alternativeVehicles.first() : undefined
    )
    const [reuseVehicleData, setReuseVehicleData] = useState<boolean | undefined>(false)

    const dialogRef = useRef<Dialog>(null)
    const { plateCode: defaultPlateCode } = useCountryCodeToLicensePlate()
    useEffect(() => {
        if (props.reason !== LookupConflictReason.NoConflict) {
            dialogRef.current?.show()
        } else {
            dialogRef.current?.hide()
        }
    }, [props.reason])

    const renderSelector = useCallback(
        (vehicle: CarModel) => {
            return (
                <RealTable.Cell>
                    <Radio size="s" checked={vehicle === selectedAlternative} onCheck={() => {}} />
                </RealTable.Cell>
            )
        },
        [selectedAlternative]
    )

    const renderVehicleType = useCallback(
        (vehicle: CarModel) => {
            return (
                <RealTable.Cell>
                    <Text modifiers="strong">{vehicle.fullDescription || vehicle.description}</Text>
                    <AlternativeVehiclesStepThumbnail>
                        {!getBundleParams().hideManufacturerLogos && <Image url={fixUrlSchema(vehicle.manufacturerThumbnail)} type="manufacturer" />}
                        <VehicleImage
                            modelImage={fixUrlSchema(vehicle.thumbnail)}
                            modelSeriesImage={fixUrlSchema(vehicle.modelSeriesThumbnail)}
                            vehicleType={props.vehicleType}
                        />
                    </AlternativeVehiclesStepThumbnail>
                </RealTable.Cell>
            )
        },
        [props.vehicleType]
    )

    const renderEngineCodes = useCallback((vehicle: CarModel) => {
        return <RealTable.Cell>{concat(", ", ...(vehicle.registrationNoDetails?.engineCodes ?? vehicle.engineCodes ?? [])) ?? "-"}</RealTable.Cell>
    }, [])

    const renderConstructionYears = useCallback(
        (vehicle: CarModel) => {
            return (
                <RealTable.Cell>
                    {renderDateRange(
                        {
                            constructionYearFrom: vehicle.registrationNoDetails?.constructionYearFrom ?? vehicle.constructionYearFrom,
                            constructionYearTo: vehicle.registrationNoDetails?.constructionYearTo ?? vehicle.constructionYearTo,
                        },
                        date
                    )}
                </RealTable.Cell>
            )
        },
        [date]
    )

    const renderInitialRegistration = useCallback(
        (vehicle: CarModel) => {
            return (
                <RealTable.Cell>
                    {vehicle.registrationNoDetails?.initialRegistration ? date(vehicle.registrationNoDetails?.initialRegistration, "d") : "-"}
                </RealTable.Cell>
            )
        },
        [date]
    )

    const renderLicensePlate = useCallback((vehicle: CarModel) => {
        return (
            <RealTable.Cell>
                {vehicle.registrationNoDetails?.plateId && (
                    <StyledLicensePlateField
                        readonly
                        shortCountryCode={plateCountryCodeMapper(vehicle.countryCode || defaultPlateCode)}
                        value={vehicle.registrationNoDetails.plateId}
                    />
                )}
            </RealTable.Cell>
        )
    }, [])

    const renderVin = useCallback((vehicle: CarModel) => {
        return <RealTable.Cell>{vehicle.registrationNoDetails?.vin ?? "-"}</RealTable.Cell>
    }, [])

    const renderRegistrationNo = useCallback((vehicle: CarModel) => {
        return (
            <RealTable.Cell>
                {/** @todo Remove use of typScheinNr and additionalDetails as soon as the service maps this property to the registrationNo property */}
                {vehicle.registrationNoDetails?.registrationNo ??
                    vehicle.registrationNoDetails?.typScheinNr ??
                    vehicle.registrationNoDetails?.additionalDetails.find((x) => x.type === 23)?.value ??
                    "-"}
            </RealTable.Cell>
        )
    }, [])

    function renderVehicleCard(headline: string, vehicle: VehicleSimple | undefined) {
        function renderDetail(description: ReactNode, value: ReactNode) {
            return (
                <VehicleCardDetail>
                    <div>
                        <Text>{description}:</Text>
                    </div>
                    <div>{typeof value === "string" ? <Text>{value}</Text> : value}</div>
                </VehicleCardDetail>
            )
        }

        return (
            <VehicleCard>
                <VehicleCardHeader>
                    <VehicleCardHeadline size="xs">{headline}</VehicleCardHeadline>
                    <Text modifiers={["strong", "block"]}>{!vehicle ? `${translateText(1608)}` : vehicle.description}</Text>
                    <VehicleCardThumbnails>
                        {vehicle?.manufacturerThumbnail && !getBundleParams().hideManufacturerLogos && (
                            <Image url={fixUrlSchema(vehicle.manufacturerThumbnail)} type="manufacturer" />
                        )}
                        {vehicle?.modelSeriesThumbnail && (
                            <Image url={fixUrlSchema(vehicle.modelSeriesThumbnail)} type={getImageTypeByVehicleType(props.vehicleType)} />
                        )}
                    </VehicleCardThumbnails>
                </VehicleCardHeader>
                {vehicle && (
                    <VehicleCardDetails>
                        {renderDetail(
                            translate(21),
                            vehicle.plateId && (
                                <StyledLicensePlateField
                                    readonly
                                    shortCountryCode={plateCountryCodeMapper(vehicle.countryCode || defaultPlateCode)}
                                    value={vehicle.plateId}
                                />
                            )
                        )}
                        {renderDetail(translate(101), vehicle.vin)}
                        {renderDetail(translate(1490), vehicle.registrationNo)}
                        {renderDetail(translate(1367), vehicle.initialRegistration && date(vehicle.initialRegistration, "d"))}
                        {renderDetail(translate(105), vehicle.engineCode)}
                        {renderDetail(translate(125), vehicle.mileAge)}
                        {renderDetail(translate(896), vehicle.lastGeneralInspection && date(vehicle.lastGeneralInspection, "d"))}
                        {renderDetail(translate(897), vehicle.nextGeneralInspection && date(vehicle.nextGeneralInspection, "d"))}
                        {renderDetail(translate(574), vehicle.nextServiceDate && date(vehicle.nextServiceDate, "d"))}
                        {renderDetail(translate(12514), vehicle.longlife !== undefined && <Switch status={vehicle.longlife} />)}
                    </VehicleCardDetails>
                )}
            </VehicleCard>
        )
    }

    function getConflictFlow(): ConflictFlow | undefined {
        function renderAlternativeVehicles(key: Key, status: ConflictStepStatus) {
            return (
                <AlternativeVehiclesStep key={key} disabled={status === ConflictStepStatus.Disabled}>
                    <StepDescription>
                        {props.alternativeVehicles?.length === 1 ? `${translateText(12520)}:` : `${translateText(12511)}:`}
                    </StepDescription>

                    <AlternativeVehiclesStepTableWrapper>
                        <RealTable
                            data={props.alternativeVehicles}
                            onClickRow={(vehicle) => setSelectedAlternative(vehicle)}
                            getRowClassName={(vehicle) => classes("is-clickable", vehicle === selectedAlternative && "is-selected")}
                            columns={[
                                <RealTable.Column key="selector" renderItemContent={renderSelector} />,
                                <RealTable.Column key="vehicle-type" renderItemContent={renderVehicleType}>
                                    {translate(74)}
                                </RealTable.Column>,
                                <RealTable.Column key="construction-years" renderItemContent={renderConstructionYears}>
                                    {translate(127)}
                                </RealTable.Column>,
                                <RealTable.Column key="initial-registration" renderItemContent={renderInitialRegistration}>
                                    {translate(1367)}
                                </RealTable.Column>,
                                <RealTable.Column key="vin" renderItemContent={renderVin}>
                                    {translate(101)}
                                </RealTable.Column>,
                                <RealTable.Column key="license-plate" renderItemContent={renderLicensePlate}>
                                    {translate(21)}
                                </RealTable.Column>,
                                <RealTable.Column key="registration-no" renderItemContent={renderRegistrationNo}>
                                    {translate(1490)}
                                </RealTable.Column>,
                                <RealTable.Column key="engine-codes" renderItemContent={renderEngineCodes}>
                                    {translate(105)}
                                </RealTable.Column>,
                            ]}
                        />
                    </AlternativeVehiclesStepTableWrapper>
                </AlternativeVehiclesStep>
            )
        }

        function getSwitchVehicleSteps(): Array<ConflictStep> {
            return [
                {
                    status: getConflictStepStatus(changeVehicle !== true, !!selectedAlternative),
                    render: renderAlternativeVehicles,
                },
                {
                    status: getConflictStepStatus(!selectedAlternative, reuseVehicleData !== undefined),
                    render: (key, status) => (
                        <SwitchWrapperStep key={key} disabled={status === ConflictStepStatus.Disabled}>
                            <Switch status={reuseVehicleData} label={translateText(12508)} onChange={(checked) => setReuseVehicleData(checked)} />
                            <Text size="s">{translate(reuseVehicleData ? 12509 : 12510)}</Text>
                        </SwitchWrapperStep>
                    ),
                },
            ]
        }

        switch (props.reason) {
            case LookupConflictReason.TecdocTypeNotMatch:
                return {
                    reason: translateText(12512),
                    steps: [
                        {
                            status: getConflictStepStatus(false, changeVehicle === true),
                            render: (key, status) => (
                                <SwitchWrapperStep key={key} disabled={status === ConflictStepStatus.Disabled}>
                                    <Switch status={changeVehicle} label={translateText(12517)} onChange={(checked) => setChangeVehicle(checked)} />
                                    <Text size="s">{translate(changeVehicle ? 12518 : 12519)}</Text>
                                </SwitchWrapperStep>
                            ),
                        },
                        ...getSwitchVehicleSteps(),
                        {
                            status: getConflictStepStatus(false, true),
                            render: (key, status) => {
                                const currentVehicle = mapVehicleToVehicleSimple(props.currentVehicle)
                                const alternativeVehicle = mapCarModelToVehicleSimple(selectedAlternative)

                                if (!currentVehicle) {
                                    return
                                }

                                let combinedVehicle: VehicleSimple | undefined

                                if (changeVehicle && alternativeVehicle) {
                                    combinedVehicle = { ...alternativeVehicle }

                                    if (reuseVehicleData) {
                                        if (currentVehicle.plateId) {
                                            combinedVehicle.plateId = currentVehicle.plateId
                                        }
                                        if (currentVehicle.vin) {
                                            combinedVehicle.vin = currentVehicle.vin
                                        }
                                        if (currentVehicle.initialRegistration) {
                                            combinedVehicle.initialRegistration = currentVehicle.initialRegistration
                                        }
                                        if (currentVehicle.mileAge) {
                                            combinedVehicle.mileAge = currentVehicle.mileAge
                                        }
                                        if (currentVehicle.lastGeneralInspection) {
                                            combinedVehicle.lastGeneralInspection = currentVehicle.lastGeneralInspection
                                        }
                                        if (currentVehicle.nextGeneralInspection) {
                                            combinedVehicle.nextGeneralInspection = currentVehicle.nextGeneralInspection
                                        }
                                        if (currentVehicle.nextServiceDate) {
                                            combinedVehicle.nextServiceDate = currentVehicle.nextServiceDate
                                        }
                                        if (currentVehicle.longlife !== undefined) {
                                            combinedVehicle.longlife = currentVehicle.longlife
                                        }
                                    }
                                }

                                return (
                                    <VehicleCardsStep key={key} disabled={status === ConflictStepStatus.Disabled}>
                                        {renderVehicleCard(`${translateText(12515)}:`, currentVehicle)}
                                        <ArrowIcon size="xl" name="pv-arrow" />
                                        {renderVehicleCard(`${translateText(12516)}:`, combinedVehicle)}
                                    </VehicleCardsStep>
                                )
                            },
                        },
                    ],
                }
            default:
                break
        }
    }

    const flow = getConflictFlow()

    if (!flow) {
        return null
    }

    function handleConfirm() {
        dialogRef.current?.hide()

        if (changeVehicle === undefined) {
            return
        }

        // if (keepVehicle) {
        //     const alternative = selectedAlternative ?? props.alternativeVehicles?.first()

        //     if (alternative && copyRegNoDetails !== undefined) {
        //         props.onKeepVehicle(copyRegNoDetails, alternative)
        //     }
        // }
        // else {
        if (changeVehicle && selectedAlternative && reuseVehicleData !== undefined) {
            props.onSelectAlternativeVehicle(selectedAlternative, reuseVehicleData)
        }
        // }
    }

    return (
        <StyledDialog
            onClose={props.onClose}
            onCancel={props.onClose}
            cancelButtonText={translateText(317).toLowerCase()}
            onConfirm={handleConfirm}
            confirmButtonText={translateText(1041).toLowerCase()}
            confirmButtonDisabled={!flow.steps.every((x) => x.status === ConflictStepStatus.Disabled || x.status === ConflictStepStatus.Done)}
            ref={dialogRef}
            text={flow.reason}
            layout="stretch"
            iconName="not"
            skin="danger"
            closeOnlyWithDialogInteraction
        >
            {flow.steps.map((step, stepIndex) => step.render(stepIndex, step.status))}
        </StyledDialog>
    )
}

/** @todo this should be done by service and removed here */
function fixUrlSchema(url: string | undefined): string {
    return url?.replace(/https?:/, "") ?? ""
}

function getConflictStepStatus(isDisabled: boolean, isDone: boolean): ConflictStepStatus {
    if (isDisabled) {
        return ConflictStepStatus.Disabled
    }

    if (isDone) {
        return ConflictStepStatus.Done
    }

    return ConflictStepStatus.Current
}
