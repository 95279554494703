import { PureArticleListType } from "@tm/models"
import { useContextSelector } from "use-context-selector"
import { ArticlesContext, ArticlesProviderProps } from "../../../ListV2/ContextProvider/Articles"
import { ensureContextAvailability, useAfterPaintEffect } from "../../../ListV2/helpers"
import { PureListArticleData } from "../../../ListV2/models"
import { useArticles } from "./useArticles"

type PureListArticlesProviderProps = ArticlesProviderProps & {
    listId: string
    listType: PureArticleListType
}

export function PureListArticlesProvider({ setHasRendered, children, listId, listType }: PureListArticlesProviderProps) {
    const articlesData = useArticles(listId, listType)

    useAfterPaintEffect(() => {
        if (articlesData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articlesData.requestStatus])

    return <ArticlesContext.Provider value={articlesData}>{children}</ArticlesContext.Provider>
}

export function usePureListArticlesContext<TContext extends PureListArticleData>(): TContext
export function usePureListArticlesContext<TContext extends PureListArticleData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function usePureListArticlesContext<TContext extends PureListArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticlesContext, (context) => {
        const validatedContext = ensureContextAvailability("PureListArticlesContext", context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
