import { Suspense, useMemo } from "react"
import { WorkTaskInfo, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, FittingPosition, RepairTime, RepairTimeProvider, SearchTreeProductGroup, WorkCategory, TmaEModule } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { getCategoryOfWorkDescriptionTextId, mapMainServicesToAddRepairTimeListRequest, QuerySearchType, renderRoute, TmaHelper } from "@tm/utils"

import { useParams } from "react-router"
import { useWorkTaskBasketState } from "@bundles/basket"
import { Models } from "../../../data"
import { getMainServices, getProductGroups } from "../../../data/repositories"
import { usePredictiveNodes, useTopNodes } from "./business"
import { Component } from "./component"

type Props = {
    calculatorRoute?: string
    className?: string
}

function PartsIndicator(props: Props & { workTask: WorkTaskInfo }) {
    const { calculatorRoute, className, workTask } = props
    const { vehicle, id } = workTask
    const { translateText, translate } = useLocalization()
    const mathcParmas = useParams()
    const { userSettings } = useUser() ?? {}
    const { repairTimeOptions /* activeVehicleDataProviders */ } = userSettings ?? {}
    const { costEstimation } = useWorkTaskBasketState(id)

    // FastCalculator is only available if enabled in the config and the vehicle has data
    const onRequestCalculation = calculatorRoute ? handleRequestCalculation : undefined
    const canRequestCalculation = !!vehicle?.dataAvailabilities?.fastCalculator.hasAny

    // const [state, dispatch] =  useReducer(reduce, initialState)

    const repairTimeProviderId = useMemo(
        () => {
            /** @todo for now only use AWDOC */
            return RepairTimeProvider.AwDocCar
            // return getActiveRepairTimeProvider(activeVehicleDataProviders?.repairTimes, vehicle?.vehicleType)
        },
        [
            /** activeVehicleDataProviders?.repairTimes, vehicle?.vehicleType */
        ]
    )

    const { loading: topNodesLoading, response: topNodesResponse } = useTopNodes(vehicle, repairTimeProviderId, repairTimeOptions)
    const { loading: predictiveNodesLoading, response: predictiveNodesResponse } = usePredictiveNodes(
        vehicle,
        repairTimeProviderId,
        repairTimeOptions
    )

    function handleRequestCalculation(productGroups: Array<SearchTreeProductGroup>) {
        if (!productGroups.length || !calculatorRoute) {
            return
        }
        Morpheus.showView(
            "1",
            renderRoute(calculatorRoute, {
                ...mathcParmas,
                productGroupIds: productGroups.map((x) => x.id).join(","),
            })
        )
    }

    function handleApplyProductGroups(productGroups: Array<SearchTreeProductGroup>) {
        const productGroupIds = productGroups?.map((x) => x.id).orderBy((x) => x)

        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchContextV2(TmaEModule.PARTS_INDICATOR, "", QuerySearchType.ProductGroup)

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            productGroups: { ids: productGroupIds },
        })
    }

    async function handleAddRepairTimeToCostEstimation(predictiveRepairTime: Models.Predictive.PredictiveRepairTime) {
        if (!workTask?.vehicle) {
            return
        }

        let repairTimeDivision = userSettings?.repairTimeOptions?.division ?? 1
        const useManufacturerRepairTimeDivision = userSettings?.repairTimeOptions?.useManufacturerRepairTimeDivision ?? false

        const repairTime: RepairTime = {
            description: predictiveRepairTime.description,
            providerWorkId: predictiveRepairTime.mainWorkId,
            time: predictiveRepairTime.time,
            categoryOfWork: WorkCategory.NotCategorized,
            includedWorks: [],
            isMaintenanceWork: false,
            sortNo: 0,
        }

        try {
            const response = await getMainServices({
                modelId: workTask.vehicle.tecDocTypeId,
                vehicleType: workTask.vehicle.vehicleType,
                fittingSideFilter: FittingPosition.None,
                serviceIds: predictiveRepairTime.mainWorkId,
                repairTimeProvider: predictiveRepairTime.providerId,
                manufacturerId: workTask.vehicle.tecDocManufacturerId,
                defaultRepairTimeDivision: repairTimeDivision,
                useManufacturerRepairTimeDivision,
            })

            const mainService = response?.mainServices?.find((x) => x.repairTimeNo == predictiveRepairTime.mainWorkId)

            if (mainService) {
                if (!mainService.productGroupIds?.length) {
                    try {
                        mainService.productGroupIds = (
                            await getProductGroups({
                                repairTimeProvider: predictiveRepairTime.providerId,
                                serviceId: mainService.repairTimeNo,
                                modelId: workTask.vehicle.tecDocTypeId,
                                vehicleType: workTask.vehicle.vehicleType,
                            })
                        ).map((x) => x.id)
                    } catch {}
                }

                if (response?.repairTimeDivision) {
                    repairTimeDivision = response?.repairTimeDivision
                }

                // Set description based on category of work
                if (!mainService.description) {
                    mainService.description = translateText(getCategoryOfWorkDescriptionTextId(mainService.serviceType))
                }
                mainService.calculatedServices?.forEach((x) => {
                    if (!x.description) {
                        x.description = translateText(getCategoryOfWorkDescriptionTextId(x.serviceType))
                    }
                })
                mainService.preparationWorks?.forEach((x) => {
                    if (!x.description) {
                        x.description = translateText(getCategoryOfWorkDescriptionTextId(x.serviceType))
                    }
                })

                costEstimation.actions.addRepairTimes(
                    mapMainServicesToAddRepairTimeListRequest(
                        workTask.id,
                        predictiveRepairTime.providerId,
                        [mainService],
                        workTask.vehicle.id,
                        repairTimeDivision
                    )
                )
                return
            }
        } catch {}

        costEstimation.actions.addRepairTimes({
            provider: predictiveRepairTime.providerId,
            repairTimeDivision,
            repairTimes: [repairTime],
            vehicleId: workTask.vehicle.id,
            workTaskId: workTask.id,
        })
    }

    function handleRemoveRepairTimeFromCostEstimation(workId: string) {
        if (!workTask) {
            return
        }

        costEstimation.actions.removeWorks([workId], true)
    }

    function getDescription() {
        if (!vehicle) {
            return translate(12648)
        }

        if (!vehicle.initialRegistration || !vehicle.mileAge) {
            return translate(1631)
        }
    }

    return (
        <Component
            canRequestCalculation={canRequestCalculation}
            className={className}
            description={getDescription()}
            loading={topNodesLoading || predictiveNodesLoading}
            predictiveNodes={predictiveNodesResponse}
            topNodes={topNodesResponse}
            onAddToRepairTimeToCostEstimation={handleAddRepairTimeToCostEstimation}
            onApplyProductGroups={handleApplyProductGroups}
            onRemoveRepairTimeFromCostEstimation={handleRemoveRepairTimeFromCostEstimation}
            onRequestCalculation={onRequestCalculation}
        />
    )
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <PartsIndicator {...props} workTask={workTask} />
        </Suspense>
    )
}
