import { DraggableList, Stack, Typography } from "@tm/components"
import { Compilation } from "@tm/models"
import { formatDate } from "@tm/utils"

type CompilationListItemProps = {
    compilation: Compilation
    isActive: boolean
    onOpenCompilationDetails(id: string): void
}

export function CompilationListItem(props: CompilationListItemProps) {
    const { compilation, isActive, onOpenCompilationDetails } = props

    function handleClick() {
        onOpenCompilationDetails(compilation.id)
    }

    function renderDate(dateString: string | undefined) {
        if (!dateString) {
            return
        }

        return formatDate(new Date(dateString), "d")
    }

    return (
        <DraggableList.Item item={compilation.id} isSelected={isActive} onClick={handleClick}>
            <Stack style={{ marginRight: "auto" }}>
                <Typography variant="body3" gutterBottom>
                    {compilation.name}
                </Typography>
                <Typography variant="body3">{renderDate(compilation.updateDate ?? compilation.createDate)}</Typography>
            </Stack>
        </DraggableList.Item>
    )
}
