import { useMemo, useState } from "react"
import { FittingPosition } from "@tm/models"
import { ListParams } from "../../models"
import { useArticleListSorting } from "../../hooks"

/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useEmptyListParams(): ListParams {
    const sorting = useArticleListSorting()
    const [noResult, setNoResult] = useState(false)

    return useMemo(
        () => ({
            listType: "direct",
            startParams: {
                fittingSideFilter: FittingPosition.None,
                productGroupIds: [],
                type: "default",
            },
            pageIndex: 0,
            productGroups: [],
            suppliers: [],
            attributes: [],
            extendedAssortment: false,
            showAvailable: false,
            showAvailableSecondary: false,
            noResult,
            isFiltersLoading: false,
            fittingPosition: FittingPosition.None,
            setProductGroups: () => {},
            setSuppliers: () => {},
            setAttributes: () => {},
            toggleProductGroup: () => {},
            toggleSupplier: () => {},
            toggleAttribute: () => {},
            setExtendedAssortment: () => {},
            setAvailability: () => {},
            setAvailabilitySecondary: () => {},
            setNoResult,
            setIsFiltersLoading: () => {},
            setFittingPosition: () => {},
            nextPage: () => {},
            sorting,
        }),
        []
    )
}
