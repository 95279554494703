import { Article, RepairTimeProvider } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { atomFamily, useRecoilState } from "recoil"
import { loadExpandedWorkIds, saveExpandedWorkIds } from "../../data"
import { getMatchingCatalogArticle, mapBasketPartItem, mapBasketWorkItem } from "../../data/mapper"
import { CalculateWorkTaskBasketResponse, CostEstimation, ShowWorkEstimationResponse, ShowWorkTaskBasketResponse } from "../../data/model"
import { createArrayToggle } from "../../helpers"
import { BasketErpInfo, BasketErpInfoGroup } from "../../models"

const showSupplierArticleNumbersState = atomFamily<boolean, string>({ key: "costEstimation_showSupplierArticleNumbers", default: true })
const showManufacturersState = atomFamily<boolean, string>({ key: "costEstimation_showManufacturers", default: true })
const showWholesalerArticleNumbersState = atomFamily<boolean, string>({ key: "costEstimation_showWholesalerArticleNumbers", default: true })
const showVehicleImageState = atomFamily<boolean, string>({ key: "costEstimation_showVehicleImage", default: true })

export function useCostEstimation(
    workTaskId: string,
    articles: Article[],
    beingUpdatedWorkIds: string[],
    beingUpdatedPartIds?: string[],
    workTaskBasket?: ShowWorkTaskBasketResponse,
    erpInfoGroups?: BasketErpInfoGroup[],
    erpInformation?: BasketErpInfo[],
    workTaskBasketCalculation?: CalculateWorkTaskBasketResponse,
    workEstimation?: ShowWorkEstimationResponse,
    availableRepairTimeProviders?: Record<number, RepairTimeProvider[]>,
    erpInfoBeingLoadedPartIds?: string[],
    workEstimationWithError?: boolean,
    useNoteText?: boolean,
    noteText?: string
) {
    const [selectedCostEstimationPartIds, setSelectedCostEstimationPartIds] = useState<string[]>([])
    const [selectedWorkIds, setSelectedWorkIds] = useState<string[]>([])
    const [expandedWorkIds, setExpandedWorkIds] = useState<string[]>([])
    const [showSupplierArticleNumbers, toggleShowSupplierArticleNumbers] = useRecoilState(showSupplierArticleNumbersState(workTaskId))
    const [showManufacturers, toggleShowManufacturers] = useRecoilState(showManufacturersState(workTaskId))
    const [showWholesalerArticleNumbers, toggleShowWholesalerArticleNumbers] = useRecoilState(showWholesalerArticleNumbersState(workTaskId))
    const [showVehicleImage, toggleShowVehicleImage] = useRecoilState(showVehicleImageState(workTaskId))

    /** Intercepting the state update is required to also persist the new value in the state management. */
    const customSetExpandedWorkIds = useCallback(
        (getNextIds: (prevState: string[]) => string[]) => {
            setExpandedWorkIds((prev) => {
                const next = getNextIds(prev)
                saveExpandedWorkIds(workTaskId, next)
                return next
            })
        },
        [workTaskId]
    )

    const toggleCostEstimationPartSelect = useMemo(() => createArrayToggle(setSelectedCostEstimationPartIds), [])
    const toggleWorkSelect = useMemo(() => createArrayToggle(setSelectedWorkIds), [])
    const toggleWorkExpand = useMemo(() => createArrayToggle(customSetExpandedWorkIds), [customSetExpandedWorkIds])

    useEffect(() => {
        loadExpandedWorkIds(workTaskId).then(setExpandedWorkIds)
    }, [workTaskId])

    const hasItems = useMemo(() => {
        return !!workTaskBasket?.costEstimation?.partIds?.length || !!workTaskBasket?.costEstimation?.workIds?.length
    }, [workTaskBasket?.costEstimation])

    const costEstimationParts = useMemo(() => {
        // For the cost estimation, only the parts that are in costEstimation are relevant
        const parts = workTaskBasket?.costEstimation?.partIds
            ?.map((id) => {
                return workTaskBasket?.parts?.find((part) => part.id === id)
            })
            .filter(notUndefinedOrNull)

        return parts?.map((partItem) => {
            const article = getMatchingCatalogArticle(partItem, articles)

            return mapBasketPartItem(
                workTaskId,
                partItem,
                selectedCostEstimationPartIds,
                beingUpdatedPartIds,
                undefined,
                undefined,
                undefined,
                erpInfoBeingLoadedPartIds,
                article,
                erpInfoGroups,
                erpInformation,
                undefined,
                workTaskBasketCalculation?.calculatedCostEstimation?.calculatedParts,
                availableRepairTimeProviders
            )
        })
    }, [
        workTaskBasket?.costEstimation?.partIds,
        workTaskBasket?.parts,
        articles,
        workTaskId,
        selectedCostEstimationPartIds,
        beingUpdatedPartIds,
        erpInfoBeingLoadedPartIds,
        erpInfoGroups,
        erpInformation,
        workTaskBasketCalculation?.calculatedCostEstimation?.calculatedParts,
        availableRepairTimeProviders,
    ])

    const works = useMemo(() => {
        return workTaskBasket?.works?.map((workItem) => {
            return mapBasketWorkItem(
                workItem,
                selectedWorkIds,
                beingUpdatedWorkIds,
                expandedWorkIds,
                workEstimation?.works,
                workTaskBasketCalculation?.calculatedCostEstimation?.calculatedWorks,
                workEstimationWithError
            )
        })
    }, [
        workTaskBasket?.works,
        selectedWorkIds,
        beingUpdatedWorkIds,
        expandedWorkIds,
        workEstimation?.works,
        workTaskBasketCalculation?.calculatedCostEstimation?.calculatedWorks,
        workEstimationWithError,
    ])

    const selectAllCostEstimationParts = useCallback(() => {
        const partIds: string[] = []
        costEstimationParts?.forEach((part) => {
            if (part !== undefined) {
                partIds.push(part.partItem.id)
                part.linkedItems?.forEach((linkedPart) => partIds.push(linkedPart.partItem.id))
            }
        })
        if (partIds) {
            setSelectedCostEstimationPartIds(partIds)
        }
    }, [costEstimationParts])

    function unselectAllCostEstimationParts() {
        setSelectedCostEstimationPartIds([])
    }

    const selectAllWorks = useCallback(() => {
        const workIds = works?.map((work) => work.workItem.id)
        if (workIds) {
            setSelectedWorkIds(workIds)
        }
    }, [works])

    function unselectAllWorks() {
        setSelectedWorkIds([])
    }

    let costEstimation: CostEstimation | undefined

    if (workTaskBasket?.costEstimation) {
        costEstimation = {
            ...workTaskBasket?.costEstimation,
            useNoteText,
            noteText,
        }
    }

    return {
        costEstimation: {
            state: {
                costEstimation,
                costEstimationParts,
                hasItems,
                works,
                repairTimeDivision: workEstimation?.repairTimeDivision ?? workTaskBasket?.costEstimation?.defaultRepairTimeDivision ?? 1,
                currencyCode:
                    workTaskBasketCalculation?.calculatedCostEstimation?.currencyCode ?? workTaskBasket?.costEstimation?.defaultCurrencyCode ?? "-",
                currencySymbol:
                    workTaskBasketCalculation?.calculatedCostEstimation?.currencySymbol ??
                    workTaskBasket?.costEstimation?.defaultCurrencySymbol ??
                    "-",
                showRepairTimesInHours:
                    workTaskBasketCalculation?.calculatedCostEstimation?.showRepairTimesInHours ?? workEstimation?.showRepairTimesInHours ?? false,
                showSupplierArticleNumbers,
                showManufacturers,
                showWholesalerArticleNumbers,
                showVehicleImage,
            },
            actions: {
                selectAllCostEstimationParts,
                selectAllWorks,
                toggleCostEstimationPartSelect,
                toggleShowVehicleImage,
                toggleShowManufacturers,
                toggleShowSupplierArticleNumbers,
                toggleShowWholesalerArticleNumbers,
                toggleWorkExpand,
                toggleWorkSelect,
                unselectAllCostEstimationParts,
                unselectAllWorks,
            },
        },
    }
}
