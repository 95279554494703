import { CisCustomerVoucherType, CisVoucherType, Paging, PdfOrderDetailOptions } from "@tm/models"
import { SearchOptions, FilterOptions, VoucherItem } from "./index"

export type FindVoucherRequest = SearchOptions &
    FilterOptions & {
        voucherTypeId: number
        query?: string
        customerNo?: string
    }

export type FindConnectedVouchersRequest = {
    parentVoucherId: string
    vouchersParentTypeId: number
    query?: string
    pageIndex?: number
    pageSize?: number
    customerNo: string | undefined
}

export type FindVouchersResponse = Paging & {
    vouchers: Array<Voucher>
    messageId: string
    messageDescription: string
    totalGross?: number
    totalNet?: number
    currencyCode?: string
}

export type Voucher = {
    voucherId: string
    voucherType: CisCustomerVoucherType
    voucherDate: Date
    information: string
    deliveryType: string
    paymentInformation: string
    itemsCount: number
    totalGross: number
    totalNet: number
    currencyCode: string
    hasConnectedVouchers: boolean
    warehouseId: string
    salesPoint: string
    voucherDescription: string
    tour: string
    orderNumber: string
    orderType: string
    orderStatus: string
    orderDate?: Date
    confirmationOptions?: ConfirmationOptions
    confirmation?: Confirmation
}

export type ShowOpenOrdersRequest = Paging & {
    dateFrom?: Date
    dateTo?: Date
    orderNumber?: string
    orderType?: string
    customerNo?: string
}

export type ShowOpenOrdersResponse = Paging & {
    openOrders: Array<OpenOrderListItem>
    messageId: string
    messageDescription: string
}

export type OpenOrderListItem = {
    voucherId: string
    voucherType: CisCustomerVoucherType
    orderNumber: string
    orderSubNumber: string
    orderDate?: Date
    orderType?: string
    orderTypeDescription: string
    totalNet: number
    totalGross: number
    currencyCode: string
    warehouseId: string
    deliveryType: string
    paymentInformation: string
    customerOrderNumber: string
    tour: string
}

export type ShowCompletedOrdersRequest = Paging & {
    dateFrom?: Date
    dateTo?: Date
    orderNumber?: string
    orderType?: string
    deliveryNote?: string
    deliveryNoteDate?: Date
    invoice?: string
    invoiceDate?: Date
    documentType?: string
    customerNo?: string
}

export type ShowCompletedOrdersResponse = Paging & {
    completedOrders: Array<CompletedOrderListItem>
    messageId: string
    messageDescription: string
}

export type CompletedOrderListItem = {
    voucherId: string
    voucherType: CisCustomerVoucherType
    orderNumber: string
    orderSubNumber: string
    orderDate?: Date
    orderType: string
    orderTypeDescription: string
    deliveryNote: string
    deliveryNoteDate?: Date
    invoice: string
    invoiceDate?: Date
    documentType: string
    hasConnectedVouchers: boolean
    itemsCount: number
    totalNet: number
    totalGross: number
    currencyCode: string
    warehouseId: string
    deliveryType: string
    paymentInformation: string
    customerOrderNumber: string
    tour: string
}

export type ShowAllOrdersRequest = Paging & {
    dateFrom?: Date
    dateTo?: Date
    query?: string
    orderNumber?: string
    continuationPoint?: string
    customerNo?: string
}

export type ShowAllOrdersResponse = Paging & {
    orders: Array<AllOrderListItem>
    continuationPoint?: string
}

export type AllOrderListItem = {
    voucherId: string // Added to fit within the other order types
    orderId: string
    retailTotalPrice: number
    purchaseTotalPrice: number
    currencyCode: string
    currencySymbol: string
    paymentType: string
    deliveryType: string
    deliveryOption: string
    orderDate?: Date
    customerOrderId: string
    orderComment: string
    userInformation?: UserInformation
    articleCount?: number
    articlePositionCount?: number
    orderType: string
    workTaskId?: string
    voucherType: CisCustomerVoucherType
}

type UserInformation = {
    userName: string
    lastName: string
    firstName: string
}

export type ShowComplaintsRequest = Paging & {
    customerNo?: string
}

export type ShowComplaintsResponse = Paging & {
    complaints: Array<ComplaintListItem>
    messageId: string
    messageDescription: string
}

export type ComplaintListItem = {
    voucherId: string
    voucherType: CisCustomerVoucherType
    orderNumber: string
    wholesalerArticleNumber: string
    itemDescription: string
    itemsCount: number
    positionStatus: string
}

export type ShowOrderDetailsRequest = {
    voucherId?: string
    voucherTypeId?: number
    warehouseId?: string
    orderNumber?: string
    orderSubNumber?: string
    deliveryNote?: string
    invoice?: string
    customerNo?: string
}

export type ShowOrderDetailsResponse = {
    orderDetails: OrderDetails
    messageId: string
    messageDescription: string
}

export type OrderDetails = {
    voucherId: string // Property declared only because ShowOrderDetailsPdfRequest requires it, but it is not used.
    orderNumber: string
    orderSubNumber: string
    orderDate?: Date
    orderTime?: Date
    deliveryNote: string
    deliveryNoteDate?: Date
    invoice: string
    invoiceDate?: Date
    orderType: string
    orderTypeDescription: string
    creditNote: string
    creditNoteDate?: string
    deliveryType: string
    paymentMode: string
    customerOrderNumber: string
    orderTexts: Array<string>
    warehouseId: string
    warehouseName1: string
    warehouseName2: string
    warehouseName3: string
    warehouseStreet: string
    warehousePostalCode: string
    warehouseCity: string
    warehouseCountry: string
    voucherItems: Array<VoucherItem>
    orderValueNet: number
    vat1Percent: number
    vat1Value: number
    vat2Percent: number
    vat2Value: number
    vatOldPartValue: number
    orderValueTotal: number
    currencyCode: string
    deliveryOption: string
    userInformation?: UserInformation
    articleCount?: number
    articlePositionCount?: number
    workTaskId?: string
    bonusInformation?: BonusInformation
    voucherDocuments?: Array<VoucherDocument>
    confirmationOptions?: ConfirmationOptions
    confirmation?: Confirmation
}

type ConfirmationOptions = {
    canBeConfirmed: boolean
    display: ConfirmationDisplay
    tooltip: string
}

export enum ConfirmationDisplay {
    Unknown = 0,
    ShowOnList = 1, // The button is only shown on the voucher list
    ShowOnDetails = 2, // The button is only shown in the voucher's detail page
    ShowOnListAndDetails = 3, // The button is shown on the voucher list and in the voucher's detail page
}

type Confirmation = {
    confirmationState: ConfirmationState
    description: string
}

export enum ConfirmationState {
    Unknown = 0,
    Unconfirmed = 1,
    Confirmed = 2,
    ConfirmedWithChanges = 3,
    Revoked = 4,
    Rejected = 5,
}

type BonusInformation = {
    online: number
    sales: number
    concept: number
    delivery: number
    actions: number
    bonusTotal: number
}

export type VoucherDocument = {
    documentURL: string
    iconURL?: string
    description: string
    documentType: CisDocumentType
    actionType: DocumentAction
}

export type PrintVoucherDetailsRequest = {
    voucher: Voucher
    printOptions: PdfOrderDetailOptions
    customerNo?: string
}

export type PrintVoucherListRequest = FindVoucherRequest & {
    printOptions: PdfOrderDetailOptions
}

export type ShowOrderDetailsPdfRequest = {
    voucherId?: string
    voucherTypeId?: CisVoucherType
    warehouseId?: string
    orderNumber?: string
    orderSubNumber?: string
    printOptions: PdfOrderDetailOptions
    customerNo?: string
    showPurchasePrice?: boolean
}

declare enum CisDocumentType {
    Undefined = 0,
    Pdf = 1,
    Html = 2,
    Excel = 3,
    Doc = 4,
}

export enum DocumentAction {
    Undefined = 0,
    Dialog = 1, // The Document will be opened in a dialog
    NewTab = 2, // the Document will be opened in a new tab
}

export type SendVoucherConfirmationRequest = {
    voucher: Voucher
    voucherItems?: Array<VoucherItem>
}

export type SendVoucherConfirmationResponse = {
    voucherId?: string
    voucherTypeId?: CisVoucherType
    confirmation?: Confirmation
}

export type ShowOrderVoucherTypeIdRequest = {
    orderNumber: string
    customerNo?: string
}

export type ShowOrderVoucherTypeIdResponse = {
    orderDate: Date
    orderNumber: string
    orderSubNumber?: string
    voucherType: CisCustomerVoucherType
}
