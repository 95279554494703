import { useEffect, useRef, useState } from "react"
import { Box, Button, Loader, Stack, styled } from "@tm/components"
import { ArticleErpInfo, TyreArticle, TyresBundleParams, ArticleListV2DisplayBehavior } from "@tm/models"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { useUser } from "@tm/context-distribution"
import { Scrollbar } from "@tm/controls"
import VendorScrollbar from "react-custom-scrollbars-2"
import { AvailabilityFilterType } from "@bundles/parts/business"
import { TyresListProvider, useTyresListContext } from "./ContextProvider/TyresList"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { NextPageLoader } from "../ListV2/components/NextPageLoader"
import { PureArticleSelection } from "../PureList/PureArticleSelection"
import { TyresArticleItem } from "./TyresArticleItem"
import { TyresError } from "../../../../tyres/src/components/list/components"
import { ComposerComponent, ContextComposer } from "../ListV2/ContextProvider"
import { TyresListType, useParticularContext } from "../PureList/ContextProviders/Particular/ParticularContext"
import { ArticleListFeaturesDisableProvider } from "../ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { ArticleListConfigurationProvider } from "../ListV2/ArticleListConfiguration"
import { ListOptionsProvider } from "../ListV2/ContextProvider/ListOptions"
import { EmptyListParamsProvider } from "../ListV2/ContextProvider/ListParams"
import { EmptyFiltersProvider } from "../ListV2/ContextProvider/Filters"
import { TradeReferenceProvider } from "../ListV2/ContextProvider/TradeReferences"
import { ErpInfosProvider } from "../ListV2/ContextProvider/ErpInfos"
import { BasketQuantitiesProvider } from "../ListV2/ContextProvider/BasketQuantities"
import { ActiveVehicleDataProvidersProvider } from "../ListV2/ContextProvider/ActiveVehicleDataProviders"
import { ProductGroupRepairTimesProvider } from "../ListV2/ContextProvider/ProductGroupRepairTimes"
import { PreviouslyOrderedArticlesProvider } from "../ListV2/ContextProvider/PreviouslyOrderedArticles"
import { NotesProvider } from "../ListV2/ContextProvider/Notes"
import { ArticleListActionsProvider } from "../ListV2/ContextProvider/ArticleListActions"

export function TyresList() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [ArticleListFeaturesDisableProvider, { disableAlternatives: true }],
        [ArticleListConfigurationProvider],
        [ListOptionsProvider, { isEnabled: true }],
        [EmptyListParamsProvider],
        [EmptyFiltersProvider],
        [TyresListProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [ArticleListActionsProvider],
    ]

    return (
        <ContextComposer components={components}>
            <TyresListComponent />
        </ContextComposer>
    )
}

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== "isTile" })<{ isTile: boolean }>(({ isTile }) => ({
    display: isTile ? "grid" : "flex",
    flexDirection: isTile ? "unset" : "column",
    gridTemplateColumns: isTile ? "repeat(auto-fill, minmax(240px, 1fr))" : "unset",
    gap: isTile ? "1rem" : "0",
    padding: isTile ? "0.5rem" : "0",
    alignItems: isTile ? "stretch" : "unset",
    justifyContent: isTile ? "center" : "unset",
}))

function TyresListComponent() {
    const { translateText } = useLocalization()
    const { userSettings } = useUser()
    const scrollbarRef = useRef<VendorScrollbar | null>(null)
    const tyresListRef = useRef<HTMLDivElement | null>(null)
    const [scrollbarProperties, setScrollbarProperties] = useState({ clientHeight: 0, scrollHeight: 0 })

    const contextProvider = useParticularContext<TyresListType>()
    const { selectedAvailability, request, loadNextItems } = contextProvider ?? {}

    const tyreArticles = useTyresListContext((context) => context.displayArticles)
    const isFailed = useTyresListContext((tyresListContext) => tyresListContext.isFailed)
    const failMessage = useTyresListContext((tyresListContext) => tyresListContext.failMessage)
    const isLoading = useTyresListContext((tyresListContext) => tyresListContext.isLoading)
    const isSuccess = useTyresListContext((tyresListContext) => tyresListContext.isSuccess)
    const sorting = useTyresListContext((context) => context.sorting)
    const totalArticlesNumber = useTyresListContext((context) => context.totalArticlesNumber)
    const fetchNextPage = useTyresListContext((context) => context.loadNextPage)
    const hasNextPage = useTyresListContext((context) => context.hasNextPage)
    const isFetchingNextPage = useTyresListContext((context) => context.isFetchingNextPage)

    const { maximumSortableArticleCount } = Morpheus.getParams<TyresBundleParams>("tyres")
    const isTile = userSettings?.articleListSettings?.viewOptions?.displayBehavior === ArticleListV2DisplayBehavior.Tile
    const displayNextPageButton =
        !!selectedAvailability && !!totalArticlesNumber && tyreArticles && tyreArticles.length < totalArticlesNumber && !isFetchingNextPage

    useEffect(() => {
        if (scrollbarRef.current && tyresListRef.current) {
            setTimeout(() => {
                setScrollbarProperties({
                    clientHeight: scrollbarRef.current!.getClientHeight(),
                    scrollHeight: scrollbarRef.current!.getScrollHeight(),
                })
            }, 200)
        }
    }, [tyresListRef.current?.childElementCount, isTile])

    useEffect(() => {
        if (
            scrollbarProperties.clientHeight &&
            scrollbarProperties.scrollHeight &&
            scrollbarProperties.clientHeight >= scrollbarProperties.scrollHeight &&
            tyresListRef.current?.childElementCount &&
            selectedAvailability === AvailabilityFilterType.None &&
            !isLoading &&
            !loadNextItems
        ) {
            fetchNextPage()
        }
    }, [scrollbarProperties, tyresListRef.current?.childElementCount])

    if (!request) {
        return null
    }

    if (!userSettings?.articleListSettings?.viewOptions?.displayBehavior) {
        return (
            <Box mt="35vh">
                <Loader />
            </Box>
        )
    }

    if (isLoading && !isFetchingNextPage) {
        return <NextPageLoader skeletonCategory skeletonArticleCount={isTile ? 20 : 5} isTile={isTile} />
    }
    if ((isSuccess && !tyreArticles?.length && !isLoading && !displayNextPageButton) || (isFailed && !failMessage)) {
        return <TyresError />
    }

    if ((isSuccess && !tyreArticles?.length && !isLoading && !displayNextPageButton) || (isFailed && !!failMessage)) {
        return <TyresError message={failMessage} />
    }

    const handleScroll = () => {
        if (!scrollbarRef.current || selectedAvailability !== AvailabilityFilterType.None) {
            return
        }
        if (
            scrollbarRef.current.getScrollHeight() - scrollbarRef.current.getScrollTop() <= scrollbarRef.current.getClientHeight() + 25 &&
            !isLoading
        ) {
            fetchNextPage()
        }
    }

    return (
        <Stack flex={1} position="relative">
            <ArticleGroupHeader
                title={translateText(240)}
                groupArticlesCount={tyreArticles?.length}
                currentSorting={sorting?.selectedSorting}
                onChangeSorting={maximumSortableArticleCount ? sorting?.handleSorting : undefined}
                maximumSortableArticleCount={maximumSortableArticleCount}
            >
                <PureArticleSelection showArticleComparision />
            </ArticleGroupHeader>
            <Scrollbar
                onScroll={handleScroll}
                onRef={(el) => {
                    scrollbarRef.current = el
                }}
                className="scrollbar__container__tyres"
            >
                <StyledBox isTile={isTile} ref={tyresListRef}>
                    {tyreArticles?.map((article) => <TyresArticleItem key={article.internalId} article={article as TyreArticle} />)}
                    {isFetchingNextPage && <NextPageLoader canLoadNextArticles skeletonArticleCount={1} isTile={isTile} />}
                </StyledBox>
                {!!displayNextPageButton && hasNextPage && (
                    <Box display="flex" justifyContent="center">
                        <Button color="highlight" onClick={() => fetchNextPage()}>
                            {translateText(12430)}
                        </Button>
                    </Box>
                )}
            </Scrollbar>
        </Stack>
    )
}
