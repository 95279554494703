import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { uniqueId } from "@tm/utils"
import { useWorkTask, useWorkTaskId } from "@tm/context-distribution"
import FileSaver from "file-saver"
import { Toolbar } from "@tm/controls"
import { getElekatString } from "../../data"
import { useBasketHasItems } from "../../hooks/useBasketHasItems"
import { Box, Button, Icon } from "@tm/components"

// Has to stay in this order
require("../../text-encoding/encoding-indexes")
const TextEncoder = require("../../text-encoding/index").TextEncoder as TextEncoding.TextEncoderStatic

function ElekatButtonComponent({ workTaskId }: { workTaskId: string }) {
    const { translateText } = useLocalization()
    const basketHasItems = useBasketHasItems(workTaskId)
    const [downloadingFile, setDownloadingFile] = useState<boolean>(false)

    function handleElekatButtonClick() {
        setDownloadingFile(true)
        getElekatString(workTaskId)
            .then((file) => {
                const uint8array = new TextEncoder("windows-1252", { NONSTANDARD_allowLegacyEncoding: true }).encode(file)
                const blob = new Blob([uint8array], { type: "text/plain;charset=windows-1252" })
                FileSaver.saveAs(blob, `ts${uniqueId()}.wdb`)
            })
            .finally(() => {
                setDownloadingFile(false)
            })
    }

    return (
        <Box display="flex" flex="0 0 auto">
            <Toolbar title={translateText(631)}>
                <Button
                    variant="contained"
                    startIcon={<Icon name="download" />}
                    onClick={handleElekatButtonClick}
                    disabled={downloadingFile || !basketHasItems}
                >
                    {translateText(1358)}
                </Button>
            </Toolbar>
        </Box>
    )
}

export default function ElekatButton() {
    const workTaskId = useWorkTaskId()

    if (!workTaskId) {
        return null
    }

    return <ElekatButtonComponent workTaskId={workTaskId} />
}
