import { connectComponent } from "@tm/morpheus"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { DetailsFrameState, Actions } from "./business"

type Props = {
    state: DetailsFrameState
    url: string
    id: string
    title?: string | number
}

function DetailsFrameComponent(props: Props) {
    const { detailsResponse } = props.state
    const { translateText, language } = useLocalization()

    if (!detailsResponse || !detailsResponse.article) {
        return null
    }

    const { traderArticleNo } = detailsResponse.article

    let { url } = props
    url = url.replace("{#traderArticleNo}", traderArticleNo || "")
    url = url.replace("{#traderArticleNo:noWhitespace}", traderArticleNo ? traderArticleNo.replace(/ /g, "") : "")
    url = url.replace("{#language}", language)

    return (
        <PanelSection className="article-details__frame" title={props.title && translateText(props.title)}>
            <iframe className="article-details__frame__control" src={url} id={`article-details__frame__${props.id}`} />
        </PanelSection>
    )
}

export default connectComponent(Actions, DetailsFrameComponent)
