import { ajaxAuth } from "@tm/utils"
import { getDmsServiceUrl } from "../.."

type SendOfferRequest = {
    voucherId: string
    workTaskId: string
}

type SendOfferResponse = {
    offerId: string
    webPin?: string
}

export async function sendCostEstimation(request: SendOfferRequest): Promise<string | undefined> {
    const baseUrl = `${getDmsServiceUrl()}/IQT`

    const response = await ajaxAuth<SendOfferResponse>({
        url: `${baseUrl}/SendOffer`,
        body: request,
        method: "POST",
    })

    if (response?.offerId) {
        return `${window.location.origin}${baseUrl}/DownloadOfferPdf?offerId=${response.offerId}`
    }
}
