import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { SensorDetailsRequest, SensorDetailsResponse } from "./model"

export function loadSensorDetails(request?: SensorDetailsRequest) {
    const url = `${getServiceUrl()}/Details`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<SensorDetailsResponse>((resolve, reject) =>
        ajax({ url, body, authorization, method: "GET" }).then((response) => resolve(response), reject)
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.articlesServiceUrl
}
