import { Button, Icon, Stack, styled, Typography } from "@tm/components"

export type Filter = {
    id: number
    value: string
    abbreviation?: string
    unit?: string
}

type Props<TFilter> = {
    onRemoveFilter(filter: TFilter): void
    filters: TFilter[]
}

const ColoredFlexTypography = styled(Typography)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    color: theme.palette.getContrastText(theme.palette.highlight.main),
}))

export function FilterButtons<TFilter extends Filter>(props: Props<TFilter>) {
    const { onRemoveFilter, filters } = props

    return (
        <Stack sx={{ m: "0 0 40px 0", flexWrap: "wrap", flexDirection: "row" }}>
            {filters.map((filter) => (
                <Button
                    color="highlight"
                    key={filter.id}
                    sx={{
                        m: "10px",
                        minWidth: "100px",
                        flexDirection: "row",
                        display: "flex",
                    }}
                    style={{ padding: 0 }}
                    onClick={() => onRemoveFilter(filter)}
                >
                    {filter.abbreviation ? (
                        <Stack flexDirection="column">
                            <ColoredFlexTypography variant="body2" sx={{ padding: "7px 10px 0", textAlign: "left" }}>
                                {filter.abbreviation}
                            </ColoredFlexTypography>
                            <ColoredFlexTypography variant="body2" sx={{ padding: "0 10px 7px", textAlign: "left" }}>
                                {filter.value} {filter.unit && ` ${filter.unit}`}
                            </ColoredFlexTypography>
                        </Stack>
                    ) : (
                        <ColoredFlexTypography variant="body2" sx={{ minWidth: "70px", overflow: "hidden", padding: "14px 10px" }}>
                            {filter.value}
                        </ColoredFlexTypography>
                    )}
                    <Icon name="close" sx={{ height: "40px", padding: "5px", width: "40px", borderLeft: "1px solid #666" }} />
                </Button>
            ))}
        </Stack>
    )
}
