import { handleInputSubmit } from "@bundles/fast-calculator/business"
import { StoreInstance } from "@bundles/fast-calculator/state"
import { FastCalculator } from "@tm/data"
import { Vehicle } from "@tm/models"

export function submitVehicleFieldInputs(inputs: FastCalculator.CalcInput[], worktaskVehicle: Vehicle, instance: StoreInstance, vehicle?: Vehicle) {
    if (!worktaskVehicle) {
        return
    }

    const isVehicleRegDate =
        (vehicle?.initialRegistration && !worktaskVehicle.initialRegistration?.isSame(vehicle.initialRegistration)) ||
        (worktaskVehicle?.initialRegistration && !vehicle?.initialRegistration)

    inputs.forEach((input) => {
        if (
            input.style === FastCalculator.ECalcInputStyle.Text &&
            input.type === FastCalculator.ECalcInputType.EngineCode &&
            worktaskVehicle.engineCode !== vehicle?.engineCode
        ) {
            handleInputSubmit(instance, { ...input, value: worktaskVehicle.engineCode })
        }

        if (input.style === FastCalculator.ECalcInputStyle.Date && input.type === FastCalculator.ECalcInputType.RegDate && isVehicleRegDate) {
            handleInputSubmit(instance, { ...input, value: worktaskVehicle.initialRegistration })
        }

        if (
            input.style === FastCalculator.ECalcInputStyle.Number &&
            input.type === FastCalculator.ECalcInputType.Mileage &&
            worktaskVehicle.mileAge !== vehicle?.mileAge
        ) {
            handleInputSubmit(instance, { ...input, value: worktaskVehicle.mileAge })
        }

        if (
            input.style === FastCalculator.ECalcInputStyle.Slider &&
            input.type === FastCalculator.ECalcInputType.Longlife &&
            worktaskVehicle.longlife !== vehicle?.longlife
        ) {
            handleInputSubmit(instance, { ...input, value: worktaskVehicle.longlife })
        }
    })
}
