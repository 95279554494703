import { IBundle } from "@tm/morpheus"

import Navigation from "./components/navigation"
import Prices from "./components/prices"
import StockInfo from "./components/stock-info"
import Tours from "./components/tours"
import SpecialProcurement from "./components/SpecialProcurement"
import MissingArticlesRequest from "./components/missing-articles-request"
import DetailsPartnersystems from "./components/details-partnersystems"
import BasketPartnerChange from "./components/basket-partner-change"

import ArticleWarehouseSelector from "./micros/article-warehouse-selector"
import CompilationAvailability from "./micros/pieces/compilation-availability"
import CompilationPrices from "./micros/pieces/compilation-prices"
import DetailsErpInfoAvailability from "./micros/pieces/details-erp-info-availability"
import DetailsErpInfoGraduated from "./micros/pieces/details-erp-info-graduated"
import DetailsErpInfoPrices from "./micros/pieces/details-erp-info-prices"
import DetailsErpInfoQuantityUnit from "./micros/pieces/details-erp-info-quantity-unit"
import DetailsErpInfoSpecialIcons from "./micros/pieces/details-erp-info-special-icons"
import DisplayOnlyAvailability from "./micros/pieces/display-only-availability"
import DisplayOnlyPrices from "./micros/pieces/display-only-prices"
import ErpAdditionalPrices from "./micros/pieces/erp-additional-prices"
import ErpInfo from "./micros/combinations/erp-info"
import ErpInfoAdditionalInformation from "./micros/pieces/erp-info-additional-info"
import ErpInfoAlternatives from "./micros/pieces/erp-info-alternatives"
import ErpInfoAvailability from "./micros/pieces/erp-info-availability"
import ErpInfoAvailabilityOverview from "./micros/pieces/erp-info-availability-overview"
import ErpInfoBasket from "./micros/combinations/erp-info-basket"
import ErpInfoDetails from "./micros/combinations/erp-info-details"
import ErpInfoGraduated from "./micros/pieces/erp-info-graduated"
import ErpInfoPawn from "./micros/pieces/erp-info-pawn"
import ErpInfoMemo from "./micros/pieces/erp-info-memo"
import ErpInfoPrices from "./micros/pieces/erp-info-prices"
import ErpInfoQuantityUnit from "./micros/pieces/erp-info-quantity-unit"
import ErpInfoSale from "./micros/pieces/erp-info-sale"
import ErpInfoSpecialIcons from "./micros/pieces/erp-info-special-icons"
import ErpInfoTeccom from "./micros/pieces/erp-info-teccom"
import TeccomBasketListener from "./micros/pieces/teccom-basket-listener"
import AvailabilityWrapper from "./_shared/availability-wrapper"
import { useErpInfo, mergeWarehouses, mergeTeccomPrices } from "./micros/_helpers/useErpInfo"

import { initErpBundle, version } from "./utils"
import { useArticleWithChangedTradeReference, useIsArticleAvailableForMultipleDistributors } from "./data/hooks/useArticleWithChangedTradeReference"
import { useTeccom } from "./micros/_helpers/useTeccom"
import { getErpInfos } from "./data"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [Navigation, Prices, StockInfo, Tours, MissingArticlesRequest, DetailsPartnersystems, BasketPartnerChange, SpecialProcurement],
    micros: [
        CompilationAvailability,
        CompilationPrices,
        DetailsErpInfoAvailability,
        DetailsErpInfoGraduated,
        DetailsErpInfoPrices,
        DetailsErpInfoQuantityUnit,
        DetailsErpInfoSpecialIcons,
        ErpInfo,
        ErpInfoAdditionalInformation,
        ErpInfoAlternatives,
        ErpInfoAvailability,
        ErpInfoAvailabilityOverview,
        ErpInfoBasket,
        ErpInfoDetails,
        ErpInfoGraduated,
        ErpInfoPawn,
        ErpInfoPrices,
        ErpAdditionalPrices,
        ErpInfoQuantityUnit,
        ErpInfoSale,
        ErpInfoSpecialIcons,
        ErpInfoTeccom,
        DisplayOnlyAvailability,
        DisplayOnlyPrices,
        ArticleWarehouseSelector,
        ErpInfoMemo,
        TeccomBasketListener,
    ],
    init: initErpBundle,
}

export default bundle

export {
    AvailabilityWrapper,
    useErpInfo,
    mergeWarehouses,
    useArticleWithChangedTradeReference,
    useIsArticleAvailableForMultipleDistributors,
    mergeTeccomPrices,
    useTeccom,
    getErpInfos,
}
