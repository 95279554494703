import { Article, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useErpConfig } from "@tm/utils"

type Props = {
    article: Article
    foundBySearchTerm?: string
    foundByVehicleId?: string
    showAdditionalPrices?: boolean
    handleAlternativesClick?(): void
    handleReplacementArticlesClick?(): void
    handleGraduatedPricesClick?(): void
}

export default function ErpInformation(props: Props) {
    const {
        article,
        foundBySearchTerm,
        foundByVehicleId,
        showAdditionalPrices,
        handleAlternativesClick,
        handleReplacementArticlesClick,
        handleGraduatedPricesClick,
    } = props

    const { erpSystemConfigs } = useErpConfig()
    const { renderMicro } = useMicro<IMicros>()

    if (erpSystemConfigs) {
        const compilationMicro = renderMicro("erp", "erp-info-details", {
            data: article,
            foundBySearchTerm,
            foundByVehicleId,
            showAdditionalPrices,
            onAlternativesClick: handleAlternativesClick,
            onReplacementArticlesClick: handleReplacementArticlesClick,
            onGraduatedPricesClick: handleGraduatedPricesClick,
        })

        if (compilationMicro) {
            return <div className="btn-compilations">{compilationMicro}</div>
        }
    }
    return null
}
