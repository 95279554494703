import { useState, useCallback, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Skeleton, MenuItem, Stack, Icon, Typography, Switch, TextField, SelectTextField } from "@tm/components"
import { CostEstimationOptions, SelectedPriceTypeMode, VatRate } from "@tm/models"
import DialogHeaderButtons from "@bundles/basket/components/_shared/DialogHeaderButtons"
import { useCustomArticlesCategories } from "../../../../../data/hooks/useCustomArticlesCategories"
import { VatRateSelectionItem } from "../../../../../data/model"
import { StackRow } from "../../../../StyledComponents"
import { VatRateSelector } from "../../../../VatRateSelector"
import { BasicBasketPartInfo, BasketPart } from "../../../../../models"
import PartPriceCalculation from "./PartPriceCalculation"

type Props = {
    costEstimationOptions?: CostEstimationOptions
    customPartVatRates?: VatRateSelectionItem[]
    defaultCurrencyCode: string
    part?: BasketPart
    rebate?: number
    surcharge?: number
    titleText: string
    hidePurchasePrice?: boolean
    onAddCustomPart?(saveAsCustomArticle: boolean, basicBasketPartInfo: BasicBasketPartInfo, quantityValue: number, category?: number): void
    onClose(): void
    onEditPart?(saveAsCustomArticle: boolean, basicBasketPartInfo: BasicBasketPartInfo, category?: number): void
    onSaveAsCustomArticle?(): void
}

const DEFAULT_CATEGORY = 3060
export default function CustomPartDialogFields(props: Props) {
    const { part, defaultCurrencyCode, surcharge, rebate, customPartVatRates, titleText, hidePurchasePrice, costEstimationOptions } = props
    const { partItem, calculatedPart } = part || {}
    const { articleInformation, costEstimationItem } = partItem || {}
    const { description, articleNumber } = articleInformation || {}
    const { translateText } = useLocalization()
    const { categories } = useCustomArticlesCategories()

    const originalRebate = calculatedPart?.rebate ?? rebate ?? 0
    const originalSurcharge = calculatedPart?.surcharge ?? surcharge ?? 0
    const originalRegularPrice = calculatedPart?.regularPrice?.value ?? 0

    const [actualDescription, setDescription] = useState(description)
    const [actualArticleNumber, setArticleNumber] = useState(articleNumber)
    const [actualRegularPrice, setRegularPrice] = useState(originalRegularPrice)
    const [actualRebate, setRebate] = useState(originalRebate)
    const [actualSurcharge, setSurcharge] = useState(originalSurcharge)
    const [actualQuantity, setQuantity] = useState(1)
    const [actualCategory, setActualCategory] = useState<number>(DEFAULT_CATEGORY)
    const vatRates = useMemo(() => {
        return costEstimationItem?.vatRates ?? customPartVatRates
    }, [costEstimationItem?.vatRates, customPartVatRates])
    const [selectedVatRate, setSelectedVatRate] = useState<VatRateSelectionItem | undefined>(vatRates?.find((v) => v.isSelected))

    const [saveAsCustomArticle, setSaveAsCustomArticle] = useState(false)
    const [resetValues, setResetValues] = useState(false)
    const [fieldsChanged, setFieldsChanged] = useState(false)
    const [confirmButtonClicked, setConfirmButtonClicked] = useState(false)

    const currencyCode = costEstimationItem?.currencyCode ?? defaultCurrencyCode

    const resetFields = useCallback(() => {
        if (fieldsChanged) {
            setDescription(description)
            setArticleNumber(articleNumber)
            setSaveAsCustomArticle(false)
            setActualCategory(DEFAULT_CATEGORY)
            setResetValues(true)
            setSelectedVatRate(vatRates?.find((val) => val.isSelected))
            setFieldsChanged(false)
            setQuantity(1)
        }
    }, [articleNumber, description, vatRates, fieldsChanged])

    const handleSaveAsCustomArticle = useCallback(() => {
        props.onSaveAsCustomArticle?.()
        setSaveAsCustomArticle((prev) => !prev)
        setFieldsChanged(true)
    }, [props.onSaveAsCustomArticle])

    const mappedVatRate: VatRate | undefined = useMemo(() => {
        return selectedVatRate
            ? {
                  vatRate: selectedVatRate?.vatValue,
                  vatType: selectedVatRate?.vatType,
              }
            : undefined
    }, [selectedVatRate])

    function handleArticleNumberChange(newArticleNumber: string) {
        setArticleNumber(newArticleNumber)
        if (newArticleNumber !== articleNumber) {
            setFieldsChanged(true)
            if (resetValues) {
                setResetValues(false)
            }
        }
    }

    function handleArticleDescriptionChange(newDescription: string) {
        setDescription(newDescription)
        if (newDescription !== description) {
            setFieldsChanged(true)
            if (resetValues) {
                setResetValues(false)
            }
        }
    }

    function handlePartPriceChange(newRebate: number, newSurcharge: number, newRegularPrice: number) {
        setSurcharge(newSurcharge ?? 0)
        setRebate(newRebate ?? 0)
        setRegularPrice(newRegularPrice ?? 0)
        if (resetValues) {
            setResetValues(false)
        }
    }

    function handleVatRateSelection(newVatRate: VatRateSelectionItem) {
        setSelectedVatRate(newVatRate)
        setFieldsChanged(true)
        if (resetValues) {
            setResetValues(false)
        }
    }

    function handleQuantityChange(newQuanity: number) {
        setQuantity(newQuanity)
        setFieldsChanged(true)
        if (resetValues) {
            setResetValues(false)
        }
    }

    function handleConfirmButtonClick() {
        if (!actualDescription) {
            setConfirmButtonClicked(true)
        } else {
            setConfirmButtonClicked(false)

            if (!part && props.onAddCustomPart) {
                props.onAddCustomPart(
                    saveAsCustomArticle,
                    {
                        description: actualDescription,
                        partNumber: actualArticleNumber,
                        retailPrice: !costEstimationOptions?.priceTypeMode ? actualRegularPrice : undefined,
                        purchasePrice: costEstimationOptions?.priceTypeMode === SelectedPriceTypeMode.Purchase ? actualRegularPrice : undefined,
                        rebate: actualRebate,
                        surcharge: actualSurcharge,
                        vatRate: mappedVatRate,
                    },
                    actualQuantity,
                    actualCategory
                )
            } else if (part && props.onEditPart) {
                props.onEditPart(
                    saveAsCustomArticle,
                    {
                        description: actualDescription,
                        partNumber: actualArticleNumber,
                        regularPrice: actualRegularPrice,
                        rebate: actualRebate,
                        surcharge: actualSurcharge,
                        vatRate: mappedVatRate,
                    },
                    actualCategory
                )
            }
            setSaveAsCustomArticle(false)
            props.onClose()
        }
    }

    function renderHeader() {
        return (
            <StackRow justifyContent="space-between">
                <StackRow alignContent="center" gap={1}>
                    <Icon name="individual-article" />
                    <Typography variant="h2">{titleText}</Typography>
                </StackRow>
                <StackRow gap={2}>
                    <StackRow gap={1}>
                        <Switch
                            label={translateText(9)}
                            checked={saveAsCustomArticle}
                            labelPlacement="start"
                            size="small"
                            onChange={handleSaveAsCustomArticle}
                        />
                        {categories ? (
                            <SelectTextField
                                disabled={!saveAsCustomArticle}
                                onChange={(e) => setActualCategory(parseInt(e.target.value))}
                                value={actualCategory ?? categories[0].textId}
                                size="large"
                            >
                                {categories?.map((row, index) => (
                                    <MenuItem key={index} value={row.textId}>
                                        {translateText(row.textId)}
                                    </MenuItem>
                                )) || []}
                            </SelectTextField>
                        ) : (
                            <Skeleton height={48} width={128} />
                        )}
                    </StackRow>
                    <DialogHeaderButtons
                        disableConfirmButton={!fieldsChanged || (confirmButtonClicked && !actualDescription)}
                        disableResetButton={!fieldsChanged}
                        onCloseButtonClick={props.onClose}
                        onConfirmButtonClick={handleConfirmButtonClick}
                        onResetButtonClick={resetFields}
                    />
                </StackRow>
            </StackRow>
        )
    }

    function renderBody() {
        return (
            <>
                <StackRow gap={1} flex={1}>
                    <TextField
                        inputProps={{ maxLength: 50 }}
                        label={translateText(87)}
                        size="extralarge"
                        value={actualArticleNumber}
                        onChange={(e) => handleArticleNumberChange(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        inputProps={{ maxLength: 200 }}
                        label={translateText(25)}
                        required
                        validateNow={confirmButtonClicked}
                        size="extralarge"
                        value={actualDescription}
                        onChange={(e) => handleArticleDescriptionChange(e.target.value)}
                    />
                </StackRow>
                <StackRow gap={1}>
                    <PartPriceCalculation
                        defaultCurrencyCode={currencyCode}
                        part={part}
                        hidePurchasePrice={hidePurchasePrice}
                        resetValues={resetValues}
                        quantity={actualQuantity}
                        onChangePartPrice={handlePartPriceChange}
                        onChangeFields={() => setFieldsChanged(true)}
                        onChangeQuantity={handleQuantityChange}
                    />
                    {vatRates && <VatRateSelector vatRates={vatRates} onChange={handleVatRateSelection} />}
                </StackRow>
            </>
        )
    }

    return (
        <Stack spacing={2}>
            {renderHeader()}
            {renderBody()}
        </Stack>
    )
}
