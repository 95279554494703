import { Article } from "@tm/models"
import { useQuery } from "react-query"
import { useMemo, useState } from "react"
import { Repositories } from "../../../data"

const ARTICLE_IMAGES_QUERY = "ARTICLE_IMAGES_QUERY"

export function useArticleImages(article: Article) {
    const [enabled, setEnabled] = useState(false)

    const { data, isLoading, status } = useQuery({
        queryKey: [ARTICLE_IMAGES_QUERY, article.internalId],
        queryFn: () => {
            return Repositories.getArticleImages(article.internalId, article.vehicleLinkageId)
        },
        enabled: !!article && enabled,
    })

    return useMemo(
        () => ({
            data,
            isLoading,
            setEnabled,
            status,
        }),
        [data, isLoading, setEnabled, status]
    )
}
