import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Modal } from "@tm/controls"
import { Article, CisQueryType, CisVoucherType, GetArticlesRequest, RegisteredModels, SearchFilters } from "@tm/models"
import { Container } from "@tm/nexus"
import { ButtonKeyDefinition, isValidDate } from "@tm/utils"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Button, DatePicker, Icon, MenuItemWidget, SearchButton, Select, Stack, TextField, Typography } from "@tm/components"
import { useParams } from "react-router"
import { today, oneMonthAgo } from "../../../business/helper"
import SelectArticlesComponent from "./select-articles"
import { CisFilters } from "../../../business/model"
import { DropdownInputViewProps, PropsRef } from "../component"
import { printVoucherList } from "../../vouchers/business/helpers"
import { useFilterStore } from "../../../business/state"
import { getBundleParams } from "../../../utils"

type RouteProps = {
    id?: string
}

type Props = {
    voucherTypeId: number
    filters?: CisFilters
    queryTypes?: Array<CisQueryType>
    manufacturerNumberSearchTypeId?: number
    articleNumberSearchTypeId?: number
    europeanArticleNumberSearchTypeId?: number
    // Currently only WM has this setting in the ui-configs.
    initialVoucherFilters?: Record<
        string,
        {
            dateToDaysPreset?: number
            dateFromDaysPreset?: number // Number of days calculated from date to preset when given
        }
    >
    setFilters(filters: CisFilters): void
    resetFilters(): void
}

export const VouchersFilterComponent = forwardRef<PropsRef, Props>((props, ref) => {
    const {
        queryTypes,
        voucherTypeId,
        filters,
        manufacturerNumberSearchTypeId,
        articleNumberSearchTypeId,
        europeanArticleNumberSearchTypeId,
        initialVoucherFilters,
        setFilters,
        resetFilters,
    } = props
    const { translateText, language } = useLocalization()
    const matchParams = useParams<RouteProps>()
    const [articlesToSelect, setArticlesToSelect] = useState<Array<Article>>()
    const [searchFilter, setSearchFilter] = useState<SearchFilters>()
    const [findVouchersRequest, loading, hasData] = useFilterStore((state) => [state.findVouchersRequest, state.loading, state.hasData])
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const [inputQuery, setInputQuery] = useState(filters?.query)
    const textFieldRef = useRef<HTMLInputElement | null>(null)

    useImperativeHandle(
        ref,
        () => ({
            focus: () => {
                textFieldRef.current?.focus()
            },
        }),
        []
    )

    useEffect(() => {
        if (filters?.queryTypeId === manufacturerNumberSearchTypeId) {
            setSearchFilter(SearchFilters.All)
        } else if (filters?.queryTypeId === europeanArticleNumberSearchTypeId) {
            setSearchFilter(SearchFilters.EuropeanArticleNumber)
        } else {
            setSearchFilter(undefined)
        }
    }, [filters?.queryTypeId, manufacturerNumberSearchTypeId, europeanArticleNumberSearchTypeId])

    function handlePrintButtonClick() {
        if (findVouchersRequest) {
            printVoucherList(findVouchersRequest, translateText, telesalesCustomerNo)
        }
    }

    function handleChangeDateFrom(dateFrom: Date | null) {
        // Currently only WM has this setting in the ui-configs.
        // If a customer has different date presets for the voucher Types (which is not supported by the MDM setting) this must be refacoterd
        if (dateFrom && initialVoucherFilters) {
            let dateTo = filters?.dateTo

            switch (voucherTypeId) {
                case CisVoucherType.Order:
                case CisVoucherType.Backorder:
                case CisVoucherType.DeliveryNote:
                case CisVoucherType.Estimation:
                case CisVoucherType.OldParts: {
                    if (dateFrom < oneMonthAgo) {
                        dateTo = new Date(dateFrom)
                        dateTo.setMonth(dateTo.getMonth() + 1)
                    } else {
                        dateTo = today
                    }
                    break
                }
                default:
                    break
            }

            setFilters({ ...filters, dateFrom, dateTo })
        } else if (!initialVoucherFilters) {
            setFilters({ ...filters, dateFrom: dateFrom || undefined })
        }
    }

    function handleChangeDateTo(dateTo: Date | null) {
        // Currently only WM has this setting in the ui-configs.
        // If a customer has different date presets for the voucher Types (which is not supported by the MDM setting) this must be refacoterd
        if (dateTo && initialVoucherFilters) {
            let dateFrom = filters?.dateFrom

            switch (voucherTypeId) {
                case CisVoucherType.Order:
                case CisVoucherType.Backorder:
                case CisVoucherType.DeliveryNote:
                case CisVoucherType.Estimation:
                case CisVoucherType.OldParts: {
                    dateFrom = new Date(dateTo)
                    dateFrom.setMonth(dateFrom.getMonth() - 1)
                    break
                }
                default:
                    break
            }
            setFilters({ ...filters, dateFrom, dateTo })
        } else if (!initialVoucherFilters) {
            setFilters({ ...filters, dateTo: dateTo || undefined })
        }
    }

    function handleQueryTypeStateChange(queryType: DropdownInputViewProps) {
        setFilters({
            ...filters,
            queryTypeId: queryType.id,
        })
    }

    function searchArticle(request: GetArticlesRequest) {
        Container.getInstance<Array<Article>>(RegisteredModels.Articles)
            .subscribe(request)
            .load()
            .then((articles) => {
                const filteredArticles = articles.filter((article) => !!article.traderArticleNo)
                if (filteredArticles.length === 1) {
                    setInputQuery(filteredArticles[0].traderArticleNo)
                    setFilters({
                        ...filters,
                        query: inputQuery,
                        queryTypeId: articleNumberSearchTypeId,
                    })
                } else if (filteredArticles.length > 1) {
                    setArticlesToSelect(filteredArticles)
                }
            })
    }

    function searchQuery(query: string) {
        let change = false

        if (query !== filters?.query) {
            change = true

            if (filters?.query === undefined && query.trim() === "") {
                change = false
            }
        }

        if (change) {
            if (searchFilter && query) {
                searchArticle({ query, searchFilter })
            } else if (!searchFilter) {
                setFilters({ ...filters, query })
            }
        }
    }

    function handleSearchKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === ButtonKeyDefinition.Enter) {
            const query = (e.target as HTMLInputElement).value
            searchQuery(query)
        }
    }

    function handleButtonClick() {
        if (searchFilter && inputQuery) {
            searchArticle({ query: inputQuery, searchFilter })
        } else if (!searchFilter) {
            setFilters({ ...filters, query: inputQuery })
        }
    }

    function handleModalClose() {
        setArticlesToSelect(undefined)
    }

    function handleSelectArticle(article: Article) {
        setInputQuery(article.traderArticleNo)
        setArticlesToSelect(undefined)
        setFilters({
            ...filters,
            query: article.traderArticleNo,
            queryTypeId: articleNumberSearchTypeId,
        })
    }

    function renderArticleSelection() {
        if (articlesToSelect) {
            return (
                <Modal className="direct-buy__article-details" onClose={handleModalClose}>
                    <SelectArticlesComponent articles={articlesToSelect} selectArticle={handleSelectArticle} />
                </Modal>
            )
        }
    }

    let queryTypesItems: Array<DropdownInputViewProps> = []
    if (queryTypes) {
        queryTypesItems = queryTypes.map((queryType) => ({
            id: queryType.id,
            description: queryType.description,
        }))
    }

    const selectedQueryType = queryTypesItems.find((queryType) => queryType.id === filters?.queryTypeId) || queryTypesItems[0]
    return (
        <>
            {renderArticleSelection()}
            <Stack direction="row" spacing={1}>
                {voucherTypeId !== CisVoucherType.Returns && queryTypes && !!queryTypes.length && (
                    <Stack direction="column">
                        <Typography variant="label">{translateText(33)}</Typography>
                        <Select
                            color="primary"
                            value={selectedQueryType}
                            variant="filled"
                            onChange={(e) => handleQueryTypeStateChange(e.target.value as DropdownInputViewProps)}
                            sx={{ minWidth: "8.5rem" }}
                            disableUnderline
                        >
                            {queryTypesItems.map((item) => {
                                return (
                                    <MenuItemWidget key={item.description} value={item}>
                                        {item.description}
                                    </MenuItemWidget>
                                )
                            })}
                        </Select>
                    </Stack>
                )}

                <Stack direction="column">
                    <Typography variant="label">&nbsp;</Typography>
                    <TextField
                        value={inputQuery}
                        label={translateText(135)}
                        onKeyDown={handleSearchKeyDown}
                        onBlur={(e) => searchQuery(e.target.value)}
                        onChange={(e) => setInputQuery(e.target.value)}
                        inputProps={{ maxLength: 500 }}
                        size="large"
                        inputRef={(input) => {
                            if (input) {
                                textFieldRef.current = input
                            }
                        }}
                    />
                </Stack>

                <Stack direction="row" alignItems="flex-end">
                    <Stack direction="column">
                        <Typography variant="label">{translateText(98)}</Typography>
                        <DatePicker
                            localisation={{ locale: language, region: null }}
                            onChange={handleChangeDateFrom}
                            value={filters?.dateFrom || null}
                            maxDate={isValidDate(filters?.dateTo) ? filters?.dateTo : today}
                            size="large"
                            label={translateText(344)}
                        />
                    </Stack>

                    <Stack direction="row">
                        <DatePicker
                            localisation={{ locale: language, region: null }}
                            onChange={handleChangeDateTo}
                            value={filters?.dateTo || null}
                            minDate={filters?.dateFrom || undefined}
                            maxDate={today}
                            size="large"
                            label={translateText(95)}
                        />
                        <SearchButton disabled={loading} onClick={handleButtonClick} sx={{ marginRight: "4px" }} />
                        <SearchButton startIcon={<Icon name="refresh" />} onClick={resetFilters} />
                    </Stack>
                </Stack>

                {getBundleParams().displayVoucherListPrint && (
                    <Stack direction="column">
                        <Typography variant="label">&nbsp;</Typography>

                        {!matchParams.id && (
                            <Button
                                color="primary"
                                startIcon={<Icon name="print" />}
                                disabled={!hasData}
                                onClick={handlePrintButtonClick}
                                title={translateText(13165)}
                                sx={{ flex: 1 }}
                            />
                        )}
                    </Stack>
                )}
            </Stack>
        </>
    )
})
