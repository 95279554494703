import { useWorkTask } from "@tm/context-distribution"
import {
    RegisteredModels,
    RegistrationNoType,
    ShowCarModelDetailsRequest,
    ShowCarModelDetailsResponse,
    ThirdPartyIdType,
    Vehicle,
    VehicleType,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { useCallback, useEffect, useState } from "react"

import { isLicensePlateType, isVinType } from "@tm/utils"
import { deleteImportedVehicleDetails } from "../../business"
import { getDATErrorMessageTextId, isSwissMasterNumber, UnknownVinTextId } from "../../helpers"
import { enhanceVehicle } from "../mapper"
import { useSelectedVehicleLookup } from "./useSelectedLookupConfig"

export type DetailsError = {
    text: string | number
    type: "general" | "registrationNoDetails"
}

export function useModelDetailsWithoutCache(vehicle: Vehicle | undefined, vehicleType: VehicleType, regNoType?: RegistrationNoType, regNo?: string) {
    const { attachToWorkTask } = useWorkTask() ?? {}
    const { selectedVehicleLookup, setSelectedVehicleLookup } = useSelectedVehicleLookup(vehicle?.vehicleType ?? vehicleType)
    const [modelDetails, setModelDetails] = useState<ShowCarModelDetailsResponse>()
    const [error, setError] = useState<DetailsError>()

    if (!vehicleType && vehicle) {
        vehicleType = vehicle.vehicleType
    }

    const { registrationTypeId, countryCode } = vehicle || {}
    // a countrycode was initially set by a customer, this is needed for the case if the found the vehicle by a regnumber.
    useEffect(() => {
        if (
            (selectedVehicleLookup.countryCode !== countryCode || selectedVehicleLookup.lookupTypeId !== registrationTypeId) &&
            registrationTypeId !== 9994
        ) {
            setSelectedVehicleLookup({
                countryCode,
                lookupTypeId: registrationTypeId,
            })
        }
    }, [countryCode, registrationTypeId])

    const loadModelDetails = useCallback(
        (force = false, overwriteRegNo?: string, overwriteRegNoType?: RegistrationNoType) => {
            if (!vehicle) {
                return
            }

            const request: ShowCarModelDetailsRequest = {
                modelId: vehicle.tecDocTypeId,
                registrationNo: overwriteRegNo || regNo,
                registrationNoTypeId: overwriteRegNoType || regNoType,
                modelIdentifier: {
                    plateId: vehicle.plateId,
                    vin: vehicle.vin,
                    initialRegistration: vehicle.initialRegistration,
                },
                forceUpdateRegistrationNoDetails: force,
                selectedLookupType: selectedVehicleLookup.lookupTypeId,
            }

            if (vehicle.registrationTypeId) {
                request.registrationNoTypeId = request.registrationNoTypeId || vehicle.registrationTypeId

                // If there is the swiss master number in the thirdPartyIds in the vehicle
                // just use this number for the registrationNo search to identify it by the swiss master number instead the typscheinNr
                if (isSwissMasterNumber(request.registrationNoTypeId)) {
                    request.registrationNo = vehicle.thirdPartyIds?.[ThirdPartyIdType.SwissMasterNumber]
                }

                if (isVinType(vehicle.registrationTypeId, request.registrationNo)) {
                    request.registrationNo = request.registrationNo || vehicle.vin
                } else if (isLicensePlateType(vehicle.registrationTypeId, request.registrationNo)) {
                    request.registrationNo = request.registrationNo || vehicle.plateId
                } else {
                    request.registrationNo = request.registrationNo || vehicle.registrationNo
                }
            }

            Container.getInstance<ShowCarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
                .subscribe(request, vehicleType)
                .load()
                .then((response) => {
                    if (!response?.modelDetails) {
                        setError({ text: response.error?.errorMessage ?? 567, type: "general" })
                        return
                    }

                    if (response.error) {
                        setError({ text: getDATErrorMessageTextId(response.error), type: "registrationNoDetails" })
                    } else if (!response.modelDetails.registrationNoDetails && force) {
                        setError({ text: UnknownVinTextId, type: "registrationNoDetails" })
                    }

                    setModelDetails(response)

                    if (force) {
                        if (!response.modelDetails?.registrationNoDetails) {
                            return
                        }

                        // If regNoDetails were loaded update the vehicle data and save the vehicle
                        const enhancedVehicle = enhanceVehicle(vehicle, response.modelDetails.registrationNoDetails, request.registrationNoTypeId)

                        if (enhancedVehicle && response.modelDetails.registrationNoDetails.datECode) {
                            localStorage.setItem(`${enhancedVehicle.id}_datECode`, response.modelDetails.registrationNoDetails.datECode)
                        }

                        attachToWorkTask?.({ vehicle: enhancedVehicle })
                        deleteImportedVehicleDetails()
                        // props.history.push(renderRoute("/:workTaskId", props.match.params))
                    } else if (response.modelDetails.registrationNoDetails?.datECode) {
                        // Store the DAT Ecode (if available) for the vehicle in the localStorage to read it for the DAT GPI mvc frame
                        // This is very hacky and should be removed in future by extending the worktask vehicle with the DAT ECode
                        localStorage.setItem(`${vehicle.id}_datECode`, response.modelDetails.registrationNoDetails.datECode)
                    }
                })
        },
        [vehicle, regNoType, regNo, vehicleType, selectedVehicleLookup]
    )

    useEffect(() => {
        loadModelDetails()
    }, [loadModelDetails])

    function clearConflict(): void {
        setModelDetails((prev) => ({ ...prev, conflictReason: undefined, alternativeModels: undefined, error: undefined }))
        setError(undefined)
    }

    return { modelDetails, error, loadModelDetails, clearConflict }
}
