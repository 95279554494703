import { Button, Divider, Icon, Loader, Stack, TextField, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ButtonKeyDefinition } from "@tm/utils"
import { useEffect, useRef, useState } from "react"
import { Compilation } from "../../../../../data"
import { useUpdateCompilation } from "../../../../../data/hooks/useCompilations"
import { CompilationDeleteNavigation } from "../../navigation/delete/delete"

type Props = {
    compilation: Compilation
    openCompilationList(): void
}

export default function CompilationInfo(props: Props) {
    const { translateText } = useLocalization()
    const descriptionRef = useRef<HTMLInputElement>(null)
    const confirmationRef = useRef<HTMLButtonElement>(null)

    const { updateCompilation, updatingCompilation } = useUpdateCompilation()

    const { compilation, openCompilationList } = props

    const [name, setName] = useState(compilation.name)
    const [description, setDescription] = useState(compilation.description)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (compilation.name !== name || compilation.description !== description) {
            setDescription(compilation.description)
            setName(compilation.name)
        }
    }, [compilation])

    const handleEditModeToggle = () => {
        setIsEditing((prev) => !prev)
    }

    const handleNameChange = (name: string | null) => {
        setName(name || "")
    }

    const handleCompilationNameKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            descriptionRef.current?.focus()
        }
    }

    const handleDescriptionChange = (description: string | null) => {
        setDescription(description || "")
    }

    const handleDescriptionKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            confirmationRef.current?.focus()
        }
    }

    const handleConfirmButtonClick = () => {
        updateCompilation({ compilationId: compilation.id, name, description, isPublic: compilation.isPublic })
        handleEditModeToggle()
    }

    if (updatingCompilation) {
        return <Loader />
    }

    return isEditing ? (
        <Stack divider={<Divider />} gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={1}>
                    <TextField
                        inputProps={{ maxLength: 50 }}
                        onKeyDown={handleCompilationNameKeyDown}
                        label={translateText(155)}
                        value={name}
                        variant="filled"
                        size="extralarge"
                        onChange={(e) => handleNameChange(e.target.value)}
                    />
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={0.5}>
                        <Button title={translateText(70)} onClick={handleEditModeToggle} startIcon={<Icon name="close" />} />
                        <Button
                            title={translateText(9)}
                            onClick={handleConfirmButtonClick}
                            startIcon={<Icon name="check" />}
                            color="success"
                            ref={confirmationRef}
                            disabled={!name}
                        />
                    </Stack>
                </Stack>
                <CompilationDeleteNavigation compilation={compilation} openCompilations={openCompilationList} />
            </Stack>
            <TextField
                label={translateText(617)}
                variant="filled"
                size="large"
                inputProps={{ maxLength: 200 }}
                ref={descriptionRef}
                value={description}
                onChange={(e) => handleDescriptionChange(e.target.value)}
                onKeyDown={handleDescriptionKeyDown}
            />
        </Stack>
    ) : (
        <Stack divider={<Divider />} gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={1}>
                    <Typography variant="h3">{name}</Typography>
                    <Button
                        title={translateText(122)}
                        onClick={handleEditModeToggle}
                        startIcon={<Icon name="edit_change" />}
                        disabled={!compilation.isOwn}
                    />
                </Stack>
                <CompilationDeleteNavigation compilation={compilation} openCompilations={openCompilationList} />
            </Stack>
            <Typography variant="body2">{description || translateText(12802)}</Typography>
        </Stack>
    )
}
