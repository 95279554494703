import { useCallback, useMemo } from "react"
import { Box, Checkbox } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ClickableText } from "../../../../../parts/src/components/ListV2/components/Filters/FiltersStyledComponents"
import { Filter } from "@tm/utils"
import { isAvailabilityFilter } from "../../../../../tyres/src/components/list/business/helpers"
import { AvailabilityFilter } from "../../../../../tyres/src/components/list/components"

export function SensorFilterItem({
    filter,
    checked,
    onChange,
}: {
    filter: AvailabilityFilter | Filter
    checked: boolean
    onChange?: (filter: Filter | AvailabilityFilter) => void
}) {
    const { translateText } = useLocalization()

    const handleChange = useCallback(() => {
        onChange?.(filter)
    }, [onChange, filter])

    const text = useMemo(() => {
        if (isAvailabilityFilter(filter)) {
            return <strong>{filter.name}</strong>
        }
    }, [filter])

    const plainText = useMemo(() => {
        if (isAvailabilityFilter(filter)) {
            return filter.name
        }
        return `${filter?.name}`
    }, [filter])

    return (
        <Box>
            <Checkbox sx={{ p: "0 8px 0 4px" }} size="small" checked={checked} onChange={handleChange} />
            <ClickableText onClick={handleChange} title={`${plainText}\n(${translateText(936)})`}>
                {text}
            </ClickableText>
        </Box>
    )
}
