import { memo } from "react"
import { Box, Skeleton, styled } from "@tm/components"

const ArticleContainer = styled("div")(() => ({
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    borderBottom: "3px #c3c3c3 solid",
    margin: "12px 0 -5px 0",
}))

type Props = {
    animation?: "pulse" | "wave" | false
}

function SkeletonArticleItemTileComponent(props: Props) {
    const { animation } = props
    return (
        <ArticleContainer>
            <Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Skeleton sx={{ marginX: "36px" }} height="160px" width="160px" animation={animation} />
                    <Skeleton sx={{ marginX: "12px", marginTop: "27px" }} height="40px" width="40px" animation={animation} />
                </Box>
                <Skeleton sx={{ marginX: "12px" }} height="40px" width="100px" animation={animation} />
                <Skeleton sx={{ marginX: "12px" }} height="40px" width="160px" animation={animation} />
                <Box display="flex" flexDirection="row">
                    <Skeleton sx={{ marginX: "12px" }} height="60px" width="90px" animation={animation} />
                    <Skeleton sx={{ marginX: "12px" }} height="60px" width="90px" animation={animation} />
                </Box>
                <Box display="flex" flexDirection="row">
                    <Skeleton sx={{ marginX: "12px" }} height="45px" width="130px" animation={animation} />
                    <Skeleton sx={{ marginX: "12px" }} height="45px" width="40px" animation={animation} />
                    <Skeleton sx={{ marginX: "12px" }} height="45px" width="40px" animation={animation} />
                </Box>
            </Box>
        </ArticleContainer>
    )
}

export const SkeletonArticleItemTile = memo(SkeletonArticleItemTileComponent)
