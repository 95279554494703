import { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { SuggestionTextField, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType } from "@tm/models"
import { BundleActions } from "../../business"
import { registrationNosSelector, vehicleSelector } from "../../data/helpers"

export default function CertificateTypeFiels() {
    const { translateText } = useLocalization()
    const { attachToWorkTask, workTask } = useWorkTask() ?? {}
    const dispatch = useDispatch()
    const { registrationNos, selectedRegistrationNo } = useSelector(registrationNosSelector)
    const vehicle = useSelector(vehicleSelector)
    const style = useMemo(() => getStyle(), [])
    const _suggestionFieldRef = useRef<SuggestionTextField>(null)

    const handleGetRegistrationNoSuggestions = (): Array<string> => {
        return registrationNos || (vehicle?.registrationNo ? [vehicle.registrationNo] : [])
    }

    const handleChangeRegistrationNo = (newRegistrationNo: string) => {
        if (newRegistrationNo === selectedRegistrationNo) {
            return
        }

        workTask?.vehicle &&
            attachToWorkTask?.({
                vehicle: {
                    ...workTask.vehicle,
                    registrationNo: newRegistrationNo,
                    registrationTypeId: RegistrationNoType.TypenscheinSchweizHoststettler,
                },
            })

        dispatch(BundleActions.setSelectedRegistrationNo(newRegistrationNo))
    }

    useEffect(() => {
        if (!selectedRegistrationNo) {
            _suggestionFieldRef.current?.handleFocus()
        }
    }, [selectedRegistrationNo, _suggestionFieldRef.current])

    return (
        <Toolbar className={style.toolbar} title={translateText(1039)}>
            <SuggestionTextField
                className={style.suggestion}
                preventBubbling
                preventConfirmOnBlur
                floatingLabel
                label={translateText(1730)}
                value={selectedRegistrationNo || ""}
                getSuggestionData={handleGetRegistrationNoSuggestions}
                onChangeConfirm={handleChangeRegistrationNo}
                formatter={(value: string) => value?.toUpperCase()}
                ref={_suggestionFieldRef}
            />
        </Toolbar>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        toolbar: {
            $nest: {
                ".toolbar__inner": {
                    flex: 1,
                    padding: "unset",
                    $nest: {
                        "&>.subtitle--xs": {
                            fontSize: theme.font.subtitleSize.m,
                            lineHeight: theme.font.lineHeight,
                            marginBottom: ".75em",
                        },
                    },
                },
            },
            marginBottom: "1em",
        },
        suggestion: {
            flex: 1,
        },
    })(CertificateTypeFiels)
}
