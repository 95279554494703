import {
    Article,
    ArticleAttribute,
    ArticleSearchHitInfo,
    AttributeComparisonModel,
    ReplaceButtonBundle,
    ErpInformation,
    OrderVoucherSupplierItem,
    RepairTimeProvider,
    RequestArticleDetailsPayload,
    SearchFilters,
} from "@tm/models"
import { ReactNode, useCallback, useEffect, useState } from "react"

import { useAddToBasketModuleParameters } from "@tm/context-distribution"
import { SearchType } from "../../../business"
import ArticleItem from "../../_shared/article-item"

import { getArticleUniqueId } from "../business/helpers"
import { ArticleListHint } from "./molecules/ArticleListHint"
import { getArticleErpInformation } from "./utils"

type Props = {
    articles: Array<Article>
    compactView: boolean
    searchType: SearchType

    activeDataSupplierFilter?: Array<number>
    canFilterArticleAttributes: boolean
    customerId?: string
    foundBySearchTerm?: string
    hitInfos?: { [key: string]: Array<ArticleSearchHitInfo> }
    ignoreAttributeKey?: boolean
    partToReplaceId?: string
    previouslyOrderedArticles?: Array<OrderVoucherSupplierItem>
    productGroupAsDescription?: boolean
    repairTimeAvailabilities?: { [id: number]: Array<RepairTimeProvider> }
    selectedArticleAttributes?: Array<string>
    selectedArticleIds?: Array<string>
    showActions?: boolean
    showAdditionalPrices?: boolean
    showErpPawnItems?: boolean
    showArticleImages?: boolean
    showDocumentsInline?: boolean
    showDocumentsInCompact?: boolean
    showReferenceLinksInCompact?: boolean
    openDocumentsAsModal?: boolean
    showMissingVehicleConnection?: boolean
    vehicleEngineCode?: string
    vehicleId?: string
    vehicleRecordsComparisons?: Array<AttributeComparisonModel>
    workTaskId?: string
    advertisementCategoryId?: string

    getArticleSearchHitInfo?(article: Article): void
    getRepairTimesUrl?(article: Article, rtProviders: Array<RepairTimeProvider> | RepairTimeProvider): string | undefined
    onArticleAddToBasket?(article: Article): void
    onArticleAttributeSelect?(article: Article, attribute: ArticleAttribute): void
    onArticleSelect?(articleUniqueId: string): void
    onDataSupplierFilterToggle?(supplierId: number): void
    onRequestArticleAlternatives?(article: Article): void
    onRequestArticleDetails?(request: RequestArticleDetailsPayload): void
    onRequestArticleDirectSearch?(query: string, searchFilter?: SearchFilters): void
    onShowArticleFeedback?(article: Article): void

    renderBuyActions?(article: Article): ReactNode
    hideCostEstimationButton: boolean

    replaceButtonBundle?: ReplaceButtonBundle

    isLoading?: boolean

    defaultErpData?: ErpInformation[]
}

export function ArticleTable(props: Props) {
    const [articleCollapsedState, setArticleCollapsedState] = useState<Array<string>>([])
    const { showCostEstimationButton } = useAddToBasketModuleParameters()

    useEffect(() => {
        setArticleCollapsedState([])
    }, [props.compactView])

    const hasArticleViewStateToggled = useCallback(
        (articleId: string): boolean => {
            return articleCollapsedState.includes(articleId)
        },
        [articleCollapsedState]
    )

    const handleToggleCollapseItem = useCallback((id: string) => {
        setArticleCollapsedState((prevState) => (prevState.includes(id) ? prevState.filter((x) => x !== id) : [...prevState, id]))
    }, [])

    const renderItem = (article: Article) => {
        const {
            selectedArticleIds,
            repairTimeAvailabilities,
            onArticleAddToBasket,
            previouslyOrderedArticles,
            activeDataSupplierFilter,
            onDataSupplierFilterToggle,
        } = props
        const articleUniqueId = getArticleUniqueId(article)
        const isSelected = selectedArticleIds?.indexOf(articleUniqueId) !== -1
        // Wie Teuer ist das an dieser Stelle?
        const previouslyOrdered = previouslyOrderedArticles?.some(
            (x) => x.supplierArticleNumber === article.supplierArticleNo && x.supplierId === article.supplier.id
        )

        return (
            <ArticleItem
                {...props}
                key={articleUniqueId}
                id={articleUniqueId}
                article={article}
                hideVehicleDescriptions={props.searchType === SearchType.ACCESSORYLIST}
                hitInfos={props.searchType === SearchType.DIRECT ? props.hitInfos : undefined}
                isCompact={props.compactView ? !hasArticleViewStateToggled(articleUniqueId) : hasArticleViewStateToggled(articleUniqueId)}
                isDataSupplierFilterActive={activeDataSupplierFilter?.includes(article.supplier.id)}
                isSelected={isSelected}
                onAddToBasket={onArticleAddToBasket}
                onDataSupplierClick={onDataSupplierFilterToggle}
                onToggleCollapse={handleToggleCollapseItem}
                previouslyOrdered={previouslyOrdered}
                rtProviders={repairTimeAvailabilities?.[article.productGroup.id]}
                showArticleImage={props.showArticleImages}
                partErpData={getArticleErpInformation(article.id, props.defaultErpData)}
                hideCostEstimationButton={!showCostEstimationButton}
            />
        )
    }

    return (
        <div className="article-list">
            <ArticleListHint searchType={props.searchType} />
            {props.articles.map(renderItem)}
        </div>
    )
}
