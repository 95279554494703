import { batch, useSelector } from "react-redux"
import Morpheus, { useActions } from "@tm/morpheus"
import { Box } from "@tm/components"
import { Button, Scrollbar } from "@tm/controls"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Article, RepairTimeProvider, SystemType } from "@tm/models"
import { useCallback, useEffect } from "react"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Wheels } from "@tm/data"
import { ArticleListFeaturesDisableProvider } from "@bundles/parts/components/ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { WheelSelectionSteps } from "../../../data/enums"
import { Actions } from "../business"
import { ArticleType, RequestType } from "../../../../../parts/src/components/WheelsList/ContextProvider/WheelsList"
import { getBundleParams } from "../../../utils"
import { NextPageLoader } from "../../../../../parts/src/components/ListV2/components/NextPageLoader"
import { WheelsList } from "../../../../../parts/src"
import { MainState } from "../../main"
import { ParticularStateProvider, WheelsListType } from "../../../../../parts/src/components/PureList/ContextProviders/Particular/ParticularContext"

export function SensorsListV2() {
    const { translateText } = useLocalization()
    const user = useUser()
    const workTask = useWorkTask()
    const { tecrmiTdGenartsRoute, haynesProTdGenartsRoute } = getBundleParams()
    const isLKQ = getBundleParams()?.version === "LKQ"
    const actions = useActions(
        Actions,
        "loadNextSensorsList",
        "getProductGroupTopicIds",
        "changeStep",
        "sendArticleToOverview",
        "saveTpmsTab",
        "selectRDKSArticle"
    )

    const { articles, request, productGroupTopicIds, vehicle, selectedArticles, selectedAvailability } = useSelector((s: MainState) => ({
        articles: s.rdksList.articles,
        request: s.rdksList.request,
        productGroupTopicIds: s.rdksList.productGroupTopicIds,
        vehicle: s.manager.vehicle,
        selectedArticles: s.rdksList.selectedArticles,
        selectedAvailability: s.rdksList.selectedFilters.availability,
    }))

    const workTaskId = encodeUniqueId(workTask?.workTaskId ?? "")
    const productGroupId = articles.data.first()?.productGroup.id || -1
    const isNotStandalone = user.userContext.system.systemType === SystemType.Next
    const initializeScrollContainerSelector = "scrollbar__container__wheels__sensors"

    const isListFiltered =
        !!articles.autoNextCount && !!selectedAvailability && !articles.loadingAutoItems && !articles.loadingNextItems && !articles.noMoreRdksArticles

    useEffect(() => {
        if (Object.keys(productGroupTopicIds).length === 0 && vehicle && productGroupId) {
            actions.getProductGroupTopicIds(vehicle, [productGroupId])
        }
    }, [])

    const onAddToBasket = useCallback(
        (article: Article, quantity: number) => {
            batch(() => {
                const newArticle = !quantity ? article : { ...article, quantity }
                actions.selectRDKSArticle(newArticle, isLKQ)
                actions.sendArticleToOverview()
                actions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, true)
                actions.saveTpmsTab(newArticle)
            })
        },
        [actions]
    )

    const toggleSelectedArticle = useCallback(
        (article: Article) => {
            actions.selectRDKSArticle(article, isLKQ)
        },
        [actions]
    )

    const onChangeQuantity = useCallback(
        (article: Article, quantity: number) => {
            const existingArticle = selectedArticles?.find((item) => item.internalId === article.internalId)

            if (!existingArticle) {
                return
            }

            const newArticle = !quantity ? existingArticle : { ...existingArticle, quantity }
            actions.selectRDKSArticle(newArticle, isLKQ)
        },
        [actions]
    )

    const handleAutoNextPage = useCallback(() => {
        if (!request) {
            return
        }

        if (!articles.autoNextCount) {
            actions.loadNextSensorsList(true)
        }
    }, [actions, articles.autoNextCount])

    const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
        const el = e.target as HTMLElement
        if (!articles.noMoreRdksArticles && !isListFiltered && el.scrollHeight - el.scrollTop <= el.clientHeight + 175) {
            actions.loadNextSensorsList()
        }
    }

    const fetchArticles = useCallback(async (request: RequestType): Promise<ArticleType> => {
        try {
            const response = await Wheels.loadSensorItems(request)
            return response.articles
        } catch (error) {
            console.error("Failed to fetch articles:", error)
            throw error
        }
    }, [])

    const handleHaynesProTechnicalDataClick = () => {
        if (productGroupId && haynesProTdGenartsRoute) {
            Morpheus.showView(
                "1",
                renderRoute(haynesProTdGenartsRoute, {
                    workTaskId,
                    topicId: productGroupTopicIds?.[productGroupId]?.[RepairTimeProvider.HaynesProCar],
                })
            )
        }
    }

    const handleTecrmiTechnicalDataClick = () => {
        if (productGroupId && tecrmiTdGenartsRoute) {
            Morpheus.showView(
                "1",
                renderRoute(tecrmiTdGenartsRoute, {
                    workTaskId,
                    productGroupId,
                })
            )
        }
    }

    const loadNextPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        actions.loadNextSensorsList()
    }

    const renderTehnicalDataButtons = () => {
        return (
            <>
                {!!haynesProTdGenartsRoute && productGroupTopicIds?.[productGroupId]?.[RepairTimeProvider.HaynesProCar] && isNotStandalone && (
                    <Button
                        onClick={handleHaynesProTechnicalDataClick}
                        icon="tech-data"
                        className="article-group__description__left__td-button"
                        layout={["holo"]}
                    >
                        {`HaynesPro - ${translateText(941)}`}
                    </Button>
                )}
                {!!tecrmiTdGenartsRoute && workTask?.workTask?.vehicle?.dataAvailabilities?.technicalData?.tecRMI && isNotStandalone && (
                    <Button
                        onClick={handleTecrmiTechnicalDataClick}
                        icon="tech-data"
                        className="article-group__description__left__td-button"
                        layout={["holo"]}
                    >
                        {`TecRMI - ${translateText(941)}`}
                    </Button>
                )}
            </>
        )
    }

    const context: WheelsListType = {
        type: "wheelsSensorsList",
        request,
        selectedArticles,
        onAddToBasket,
        onChangeQuantity,
        toggleSelectedArticle,
        selectedAvailability: selectedAvailability ?? 0,
        handleAutoNextPage,
        fetchArticles,
        initializeScrollContainerSelector,
        articleItemType: "default",
        headerTitle: translateText(12513),
        headerTitleContent: renderTehnicalDataButtons(),
        largeCheckbox: isLKQ,
    }

    return (
        <Scrollbar onScroll={handleScroll} className={initializeScrollContainerSelector}>
            <Box display="flex" flexDirection="column">
                <ArticleListFeaturesDisableProvider disableCostEstimationButton={isLKQ} disableBasketButton={isLKQ} displayOnlyQuantity={isLKQ}>
                    <ParticularStateProvider value={context}>
                        <WheelsList />
                    </ParticularStateProvider>
                </ArticleListFeaturesDisableProvider>
                {(articles.loadingNextItems || articles.loadingAutoItems) && <NextPageLoader canLoadNextArticles skeletonArticleCount={1} />}
                {isListFiltered && (
                    <Box display="flex" justifyContent="center">
                        <Button skin="highlight" onClick={loadNextPage}>
                            {translateText(12430)}
                        </Button>
                    </Box>
                )}
            </Box>
        </Scrollbar>
    )
}
