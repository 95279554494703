import { Article, CompilationsContainsResponse } from "@tm/models"

export function isArticleInWatchlist(article: Article, containedArticle?: CompilationsContainsResponse): boolean {
    return (
        (containedArticle &&
            containedArticle.productGroupId === article.productGroup.id &&
            containedArticle.supplierId === article.supplier.id &&
            containedArticle.supplierArticleNumber === article.supplierArticleNo) ??
        false
    )
}
