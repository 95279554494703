import { ErpPriceAdditionalInfo, Grid, Icon, styled, Tooltip, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ErpPrice } from "@tm/models"
import { CustomOrderPriceCalculation } from "../../../../../../../data/model"

type Props = {
    priceLabel: string
    erpPrice?: ErpPrice
    customOrderPriceCalculation?: CustomOrderPriceCalculation
    customOrderPrice?: ErpPrice
}

const WhiteTypography = styled(Typography)({
    color: "white",
})

export default function PriceIcon({ priceLabel, erpPrice, customOrderPriceCalculation, customOrderPrice }: Props) {
    const { currency, translateText } = useLocalization()
    if (!erpPrice) {
        return null
    }

    return customOrderPriceCalculation ? (
        <Tooltip
            title={
                <Grid display="grid" gridTemplateColumns="auto auto">
                    <WhiteTypography sx={{ textDecoration: "line-through" }}>{`${priceLabel}:`}</WhiteTypography>
                    <WhiteTypography sx={{ textDecoration: "line-through" }}>
                        {`${currency(erpPrice.value, erpPrice.currencySymbol || erpPrice.currencyCode_Iso_4217)}`}
                    </WhiteTypography>
                    {customOrderPriceCalculation.rebate && (
                        <>
                            <WhiteTypography>{`${translateText(54)}:`}</WhiteTypography>
                            <WhiteTypography>{customOrderPriceCalculation.rebate.toString()}%</WhiteTypography>
                        </>
                    )}
                    {customOrderPrice && (
                        <>
                            <WhiteTypography>{`${priceLabel}:`}</WhiteTypography>
                            <WhiteTypography>
                                {currency(customOrderPrice.value, customOrderPrice.currencySymbol || customOrderPrice.currencyCode_Iso_4217)}
                            </WhiteTypography>
                        </>
                    )}
                </Grid>
            }
        >
            <Icon name="edited" size="12px" />
        </Tooltip>
    ) : (
        <ErpPriceAdditionalInfo price={erpPrice} />
    )
}
