import { Select, FormControl, InputLabel, ListSubheader, MenuItem, styled, Box } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { FastCalculator } from "@tm/data"
import { ArticleInput, DropDownItems } from "../../../data/model"
import { handleDropdownItemClick } from "../../../business"
import { useStoreContext } from "../../../state"

type Props = {
    item: ArticleInput
    genArtLabel: string
}

const ListSubText = styled(ListSubheader)({
    padding: 0,
    lineHeight: "24px",
    fontSize: ".85rem",
})

const StyledSelect = styled(Select)({
    height: "100%",
    "&& .MuiSelect-select": {
        display: "flex",
        gap: ".5em",
    },
})

function ArticleInputComp({ item, genArtLabel }: Props) {
    const instance = useStoreContext()
    const { translateText } = useLocalization()

    const selectedItem = item.dropDownItems.find((x) => x.isSelected)

    const handleChange = (dropdownItem: DropDownItems) => {
        handleDropdownItemClick(instance, item as unknown as FastCalculator.CalcInput, dropdownItem as unknown as FastCalculator.CalcDropDownItem)
    }
    return (
        <FormControl sx={{ height: "100%" }} fullWidth>
            <InputLabel id="calc_consumables_article">{genArtLabel}</InputLabel>
            <StyledSelect
                labelId="calc_consumables_article"
                id="demo-simple-select"
                value={selectedItem?.label ?? ""}
                defaultValue=""
                label={genArtLabel}
                variant="outlined"
            >
                <Box sx={{ display: "grid", gridAutoColumns: "minmax(0, 1fr)", gridAutoFlow: "column", p: "0 16px" }}>
                    <ListSubText>{translateText(87)}</ListSubText>
                    <ListSubText>{translateText(25)}</ListSubText>
                    <ListSubText>{translateText(56)}</ListSubText>
                </Box>
                {item.dropDownItems.map((dropDownItem, key) => (
                    <MenuItem
                        sx={{ display: "grid", gridAutoColumns: "minmax(0, 1fr)", gridAutoFlow: "column" }}
                        onClick={() => {
                            handleChange(dropDownItem)
                        }}
                        key={key}
                        value={dropDownItem.label}
                    >
                        {dropDownItem.labelColumns.map((label, key) => (
                            <div key={key}>{label}</div>
                        ))}
                    </MenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    )
}

export default ArticleInputComp
