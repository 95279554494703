import { Stack } from "@tm/components"
import { RegistrationNoType, VehicleType } from "@tm/models"
import { useSetRecoilState } from "recoil"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useHistory } from "react-router"
import { useWorkTaskId } from "@tm/context-distribution"
import { VehicleSearchfieldV1Props } from "../VehicleSearchFieldSwitch"
import { LicensePlateSearchFieldV2 } from "./LicensePlateSearchFieldV2"
import { useSelectedSimpleLookupConfig } from "../../../data/hooks/useSelectedSimpleLookupConfig"
import { showVehicles } from "../../../data/repositories/vrm-lookup"
import { vehicleSearchResults } from "../../../data/hooks/vehicleSearchResult"
import { getBundleParams } from "../../../utils"
import { VehicleSearchFieldV2 } from "./VehicleSearchFieldV2"

export function VehicleSearchV2(props: VehicleSearchfieldV1Props) {
    const { selectedConfig } = useSelectedSimpleLookupConfig()
    const setResults = useSetRecoilState(vehicleSearchResults)
    const history = useHistory()
    const worktaskId = useWorkTaskId()
    const { inputFieldRef, className, ...restProps } = props

    // TODO's
    // In the future, we want to handle the search logic in this component, or to enter it in hooks from here.
    // The search data should be saved and transferred at +1 result
    // There should only be one point from which a search addresses the backend
    const searchForVehicles = async (query: string, lookupId?: RegistrationNoType) => {
        const result = await showVehicles({
            lookupTypeId: lookupId,
            query,
            forceUpdateRegistrationNoDetails: false,
            vehicleType: VehicleType.PassengerCar,
        })
        if (result.carModels.length === 1) {
            if (result.carModels[0]) {
                props.onUniqueVehicleFound?.(result.carModels[0], query) // support old Logic
            }
        }
        // Wenn mehr als 1 Ergebniss - Springe auf die Fahrzeugseite
        else {
            setResults(result)
            // WorktaskId und so....
            const workId = encodeUniqueId(worktaskId || uniqueId())
            const searchResultUrl = renderRoute(getBundleParams().vehicleSearchUrl, {
                workTaskId: workId,
                vehicleType: VehicleType.PassengerCar,
                // lookUpTypeId: selectedConfig?.lookupId || "0",
                query: encodeURIComponent(query),
            })

            // go to Searchresult list...
            history.push(searchResultUrl)
        }
    }

    // Default search trigger
    const handleStartSearch = (query: string, regNoType?: RegistrationNoType) => {
        searchForVehicles(query, regNoType)
    }

    // Trigger if we have a extra licensSearchField - Than always use SelectedLookupID!
    const handleStartSearchLicensPlate = (query: string) => {
        searchForVehicles(query, selectedConfig?.lookupId)
    }

    const showSecondInput = selectedConfig?.showAdditionalSearchField && !props?.hideSpecialLicensePlateSearchField
    const inputWidth = showSecondInput ? 550 : 400

    return (
        <Stack className={className} spacing={0.5} direction={props.direction} flex={0.8} width={props.direction === "row" ? inputWidth : undefined}>
            {showSecondInput && (
                <LicensePlateSearchFieldV2
                    quota={selectedConfig.quota}
                    licensePlateCode={selectedConfig.licensePlateCode}
                    onStartSearch={handleStartSearchLicensPlate}
                />
            )}
            <VehicleSearchFieldV2 {...restProps} onStartSearch={handleStartSearch} inputFieldRef={!showSecondInput ? inputFieldRef : undefined} />
        </Stack>
    )
}
