import {
    useAddCatalogPartList,
    useAddCustomPartList,
    useAddExternalPartList,
    useAddOePartList,
    useAddWholesalerPartList,
    useBasketUpdateWorkflow,
    useRemovePartList,
} from "../../data/hooks/workTaskBasket"

async function resolve() {}

export function useBasketParts() {
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const addCustomPartList = useAddCustomPartList(handleBasketUpdateWorkflow)
    const addCatalogPartList = useAddCatalogPartList(handleBasketUpdateWorkflow)
    const addExternalPartList = useAddExternalPartList(handleBasketUpdateWorkflow)
    const addOePartList = useAddOePartList(handleBasketUpdateWorkflow)
    const addWholesalerPartList = useAddWholesalerPartList(handleBasketUpdateWorkflow)
    const removePartList = useRemovePartList(handleBasketUpdateWorkflow)

    return { addCatalogPartList, addCustomPartList, addExternalPartList, addOePartList, addWholesalerPartList, removePartList }
}
