import { ajaxAuth } from "@tm/utils"
import { ShowOrdersByArticleRequest, ShowOrdersByArticleResponse } from "@tm/models"
import {
    FindConnectedVouchersRequest,
    FindVoucherRequest,
    ShowOpenOrdersRequest,
    ShowCompletedOrdersRequest,
    ShowComplaintsRequest,
    ShowOpenOrdersResponse,
    ShowCompletedOrdersResponse,
    ShowComplaintsResponse,
    ShowOrderDetailsRequest,
    FindVouchersResponse,
    ShowOrderDetailsResponse,
    ShowOrderDetailsPdfRequest,
    ShowAllOrdersRequest,
    ShowAllOrdersResponse,
    PrintVoucherDetailsRequest,
    PrintVoucherListRequest,
    SendVoucherConfirmationRequest,
    SendVoucherConfirmationResponse,
    ShowOrderVoucherTypeIdRequest,
    ShowOrderVoucherTypeIdResponse,
} from "../../model"
import { getCisServiceUrl } from "../.."

function getServiceUrl() {
    return `${getCisServiceUrl()}/Vouchers`
}

export async function findConnectedVouchers(body: FindConnectedVouchersRequest) {
    const url = `${getServiceUrl()}/FindConnectedVouchers`
    return ajaxAuth<FindVouchersResponse>({ url, body }, undefined, undefined, true)
}

export async function findVouchers(body: FindVoucherRequest) {
    const url = `${getServiceUrl()}/FindVouchers`
    return ajaxAuth<FindVouchersResponse>({ url, body }, undefined, undefined, true)
}

export async function showOpenOrders(body: ShowOpenOrdersRequest) {
    const url = `${getServiceUrl()}/ShowOpenOrders`
    return ajaxAuth<ShowOpenOrdersResponse>({ url, body }, undefined, undefined, true)
}

export async function showCompletedOrders(body: ShowCompletedOrdersRequest) {
    const url = `${getServiceUrl()}/ShowCompletedOrders`
    return ajaxAuth<ShowCompletedOrdersResponse>({ url, body }, undefined, undefined, true)
}

export async function showAllOrders(body: ShowAllOrdersRequest) {
    const url = `${getServiceUrl()}/ShowAllOrders`
    return ajaxAuth<ShowAllOrdersResponse>({ url, body }, undefined, undefined, true)
}

export async function showComplaints(body: ShowComplaintsRequest) {
    const url = `${getServiceUrl()}/ShowComplaints`
    return ajaxAuth<ShowComplaintsResponse>({ url, body }, undefined, undefined, true)
}

export async function showOrdersByArticle(body: ShowOrdersByArticleRequest) {
    const url = `${getServiceUrl()}/ShowOrdersByArticle`
    return ajaxAuth<ShowOrdersByArticleResponse>({ url, body }, undefined, undefined, true)
}

export async function showOrdersDetails(body: ShowOrderDetailsRequest) {
    const url = `${getServiceUrl()}/ShowOrderDetails`
    return ajaxAuth<ShowOrderDetailsResponse>({ url, body }, undefined, undefined, true)
}

export async function showOrderDetailsPdf(body: ShowOrderDetailsPdfRequest) {
    const url = `${getServiceUrl()}/ShowOrderDetailsPdf`

    return ajaxAuth<Array<number>>({ url, body, responseType: "blob", method: "POST" })
}

export async function printVoucherDetails(body: PrintVoucherDetailsRequest) {
    const url = `${getServiceUrl()}/PrintVoucherDetails`

    return ajaxAuth<Array<number>>({ url, body, responseType: "blob", method: "POST" })
}

export async function printVoucherList(body: PrintVoucherListRequest) {
    const url = `${getServiceUrl()}/PrintVoucherList`

    return ajaxAuth<Array<number>>({
        url,
        body,
        responseType: "blob",
        method: "POST",
    })
}

export async function sendVoucherConfirmation(body: SendVoucherConfirmationRequest) {
    const url = `${getServiceUrl()}/SendVoucherConfirmation`

    return ajaxAuth<SendVoucherConfirmationResponse>({ url, body, method: "POST" })
}

export async function showOrderVoucherTypeId(body: ShowOrderVoucherTypeIdRequest) {
    const url = `${getServiceUrl()}/ShowOrderVoucherTypeId`
    return ajaxAuth<ShowOrderVoucherTypeIdResponse>({ url, body }, undefined, undefined, true)
}
