import { Icon, LinkButton, TableVariants } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { VoucherType } from "@tm/models"
import { encodeUniqueId, mapVoucherTypeForUrl, renderRoute, uniqueId } from "@tm/utils"
import { useMemo } from "react"
import { useParams } from "react-router"
import { VoucherRouteParams } from "../../business"
import { getBundleParams } from "../../utils"

type Props = {
    variant: TableVariants
    voucherType: VoucherType
    voucherId: string
    voucherWorkTaskId?: string
}

export default function VoucherDetailsButton({ voucherId, voucherType, voucherWorkTaskId, variant }: Props) {
    const { translateText } = useLocalization()
    const params = useParams<VoucherRouteParams>()

    const workTaskId = useMemo(() => {
        if (params.workTaskId) {
            return params.workTaskId
        }
        if (voucherWorkTaskId) {
            return encodeUniqueId(voucherWorkTaskId)
        }

        return encodeUniqueId(uniqueId())
    }, [params.workTaskId, voucherWorkTaskId])

    function getRowLink() {
        const listUrl = renderRoute(getBundleParams().vouchersDetailsRoute, {
            ...params,
            workTaskId,
            type: mapVoucherTypeForUrl(voucherType),
            id: voucherId,
        })

        return listUrl
    }

    return (
        <LinkButton to={getRowLink()} title={translateText(43)} startIcon={variant === "small" ? <Icon name="details" /> : undefined}>
            {variant === "normal" && translateText(43)}
        </LinkButton>
    )
}
