import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Icon, Button } from "@tm/components"
import { ArticleInfoType, PriceType } from "@tm/models"
import { ValueLabelStack } from "../../../../ValueLabelStack"
import { StackRow, NumberTextField } from "../../../../StyledComponents"
import { BasketPart } from "../../../../../models"
import { findPrice } from "../../../../../helpers"
import EditedPartErpPrices from "../../../../_shared/EditedPartErpPrices"

type Props = {
    articleInfoType?: ArticleInfoType
    defaultCurrencyCode: string
    hidePurchasePrice?: boolean
    part?: BasketPart
    rebate?: number
    surcharge?: number
    resetValues?: boolean
    quantity?: number
    onChangePartPrice(rebate: number, surcharge: number, regularPrice: number): void
    onChangeFields(fieldsChanged: boolean): void
    onChangeQuantity?(quantity: number): void
}

const PERCENTAGE_WIDTH = 95
const MINIMUM_QUANTITY = 1
export default function PartPriceCalculation(props: Props) {
    const { part, defaultCurrencyCode, surcharge, rebate, articleInfoType, hidePurchasePrice, resetValues, quantity } = props
    const { partItem, calculatedPart, erpInfoResponse } = part || {}
    const { articleInformation, costEstimationItem } = partItem || {}
    const { translateText } = useLocalization()

    const erpPrices = erpInfoResponse?.prices ? Object.values(erpInfoResponse?.prices) : undefined
    const purchasePrice = findPrice(erpPrices, PriceType.Purchase)
    const retailPrice = findPrice(erpPrices, PriceType.Retail)

    const initialRebate = calculatedPart?.rebate ?? rebate ?? 0
    const initialSurcharge = calculatedPart?.surcharge ?? surcharge ?? 0
    const initialRegularPrice = calculatedPart?.regularPrice?.value ?? 0
    const initiallRegularPriceWithSurcharge = calculatedPart?.regularPriceWithSurcharge?.value ?? 0
    const initialOfferPrice = calculatedPart?.offerPrice?.value ?? 0
    const [actualRegularPrice, setRegularPrice] = useState(initialRegularPrice)
    const [actualRebate, setRebate] = useState(initialRebate)
    const [actualSurcharge, setSurcharge] = useState(initialSurcharge)
    const [actualQuantity, setQuantity] = useState(quantity)
    const [actualRegularPriceWithSurcharge, setRegularPriceWithSurcharge] = useState(initiallRegularPriceWithSurcharge)
    const [actualOfferPrice, setOfferPrice] = useState(initialOfferPrice)

    const [originalPricesExpanded, setOriginalPricesExpanded] = useState<boolean>(true)

    const isCustomPart = articleInformation?.articleInfoType === ArticleInfoType.CustomArticle || articleInfoType === ArticleInfoType.CustomArticle
    const currencyCode = costEstimationItem?.currencyCode ?? defaultCurrencyCode

    useEffect(() => {
        if (resetValues) {
            setRegularPrice(initialRegularPrice)
            setRebate(initialRebate)
            setSurcharge(initialSurcharge)
            setRegularPriceWithSurcharge(initiallRegularPriceWithSurcharge)
            setOfferPrice(initialOfferPrice)
            props.onChangeFields(false)
        }
    }, [initialOfferPrice, initialRebate, initialRegularPrice, initialSurcharge, initiallRegularPriceWithSurcharge, resetValues])

    useEffect(() => {
        if (calculatedPart?.regularPrice?.value) {
            handleRegularPriceChange(calculatedPart.regularPrice.value)
        }
    }, [calculatedPart?.regularPrice?.value])

    function calculatePrices(newSurcharge: number | undefined, newGaragePrice: number | undefined, newRebate: number | undefined) {
        const newGaragePriceWithSurcharge = (newGaragePrice ?? 0) + (newGaragePrice ?? 0) * ((newSurcharge ?? 0) / 100)
        setRegularPriceWithSurcharge(newGaragePriceWithSurcharge)
        setOfferPrice(newGaragePriceWithSurcharge - newGaragePriceWithSurcharge * ((newRebate ?? 0) / 100))
        props.onChangePartPrice(newRebate ?? 0, newSurcharge ?? 0, newGaragePrice ?? 0)
    }

    function handleChangeSurcharge(value: number) {
        setSurcharge(value ?? 0)
        calculatePrices(value, actualRegularPrice, actualRebate)
        if (value !== actualSurcharge) {
            props.onChangeFields(true)
        }
    }

    function handleRegularPriceChange(value: number | undefined) {
        setRegularPrice(value ?? 0)
        calculatePrices(actualSurcharge, value, actualRebate)
        if (value !== actualRegularPrice) {
            props.onChangeFields(true)
        }
    }

    function handleChangeRebate(value: number) {
        setRebate(value ?? 0)
        calculatePrices(actualSurcharge, actualRegularPrice, value)
        if (value !== actualRebate) {
            props.onChangeFields(true)
        }
    }

    function handleChangeQuantity(value: number) {
        setQuantity(value === 0 ? MINIMUM_QUANTITY : value)
        if (value !== actualQuantity) {
            props.onChangeQuantity?.(value)
        }
    }

    function renderQuantity() {
        if (isCustomPart && !part) {
            return (
                <NumberTextField
                    label={translateText(89)}
                    value={actualQuantity}
                    required
                    onChange={(e) => handleChangeQuantity(Number(e.target.value))}
                    inputProps={{
                        min: 1,
                        max: 9999,
                        step: 1,
                    }}
                />
            )
        }
        return <ValueLabelStack label={translateText(89)} value={MINIMUM_QUANTITY.toString()} alignValueCenter />
    }

    function renderOriginalPrices() {
        return (
            <StackRow gap={1}>
                {!isCustomPart && (
                    <EditedPartErpPrices hidePurchasePrice={hidePurchasePrice} purchasePrice={purchasePrice} retailPrice={retailPrice} />
                )}
                <NumberTextField
                    label={`${translateText(53)} ${currencyCode}`}
                    value={actualRegularPrice}
                    onChange={(e) => handleRegularPriceChange(Number(e.target.value))}
                    inputProps={{
                        min: 0,
                        max: 9999999,
                        step: 1,
                    }}
                />
                <NumberTextField
                    label={`${translateText(12475)} %`}
                    value={actualSurcharge}
                    sx={{ width: PERCENTAGE_WIDTH }}
                    inputProps={{
                        min: 0,
                        max: 9999,
                        step: 1,
                    }}
                    onChange={(e) => handleChangeSurcharge(Number(e.target.value))}
                />
                <ValueLabelStack label={`${translateText(57)} ${currencyCode}`} value={actualRegularPriceWithSurcharge} />
            </StackRow>
        )
    }

    return (
        <StackRow gap={1}>
            {renderQuantity()}
            {originalPricesExpanded && renderOriginalPrices()}
            {part && (
                <Button
                    onClick={() => setOriginalPricesExpanded(!originalPricesExpanded)}
                    startIcon={<Icon name={originalPricesExpanded ? "arrow-right" : "arrow-left"} />}
                    variant="text"
                    size="large"
                />
            )}
            <NumberTextField
                label={`${translateText(54)} %`}
                value={actualRebate}
                sx={{ width: PERCENTAGE_WIDTH }}
                inputProps={{
                    min: 0,
                    max: 100,
                    step: 1,
                }}
                onChange={(e) => handleChangeRebate(Number(e.target.value))}
            />
            <ValueLabelStack label={`${translateText(57)} ${currencyCode}`} value={actualOfferPrice} />
        </StackRow>
    )
}
