import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { IFrame } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { classes, decodeUniqueId, RouteComponentProps, useMessage, withRouter } from "@tm/utils"
import { percent } from "csx"
import { useSelector } from "react-redux"
import { FC, useEffect, useRef, useState } from "react"
import { LoginRequest } from "../../data"
import { buildBaseRequestParameters, compareAndSetRequestUrl } from "../../data/helpers/common"
import { WrapperFrame } from "../_shared/wrapperFrame"
import { Actions, HaynesproTdGenartsState } from "./business"
import { getWorktaskId } from "../../data/helpers"
import ReplaceArticleModal from "../_shared/replaceArticleModal"

type RouteProps = {
    workTaskId: string
    topicId: string
    extModule: string
}

type Props = RouteComponentProps<RouteProps> & {
    id: string
    loginUrl: string
    loginRequest: LoginRequest
    className?: string
}

const HaynesproTdGenartsFrame: FC<Props> = ({ id, loginRequest, loginUrl, match: { params }, className }) => {
    const { languageId } = useLocalization()
    const workTaskId = getWorktaskId() ?? decodeUniqueId(params.workTaskId ?? "")
    const actions = useActions(Actions)
    const userContext = useUser()?.userContext
    const vehicle = useWorkTask()?.workTask?.vehicle

    const parameters = useSelector((s: HaynesproTdGenartsState) => s.parameters)

    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState("")
    const mounted = useRef(false)

    const buildRequestParameters = () => {
        const request = buildBaseRequestParameters(loginRequest, languageId, workTaskId, userContext, vehicle)

        request.topicIds = params.topicId

        if (params.extModule) {
            request.extModule = params.extModule
        }

        return request
    }

    const handleLoadingCallback = () => {
        mounted.current && setLoading(false)
    }

    useMessage((data) => {
        if (data.invalidSessionMvc) {
            compareAndSetRequestUrl(
                buildRequestParameters,
                loginUrl,
                setLoading,
                setUrl,
                actions.updateParameters,
                parameters,
                url,
                data.invalidSessionMvc
            )
        }
    })

    useEffect(() => {
        mounted.current = true

        return () => {
            mounted.current = false
        }
    }, [])

    useEffect(() => {
        if (workTaskId && userContext?.id && (params.topicId || params.extModule) && vehicle) {
            compareAndSetRequestUrl(buildRequestParameters, loginUrl, setLoading, setUrl, actions.updateParameters, parameters, url)
        }
    }, [vehicle?.id, vehicle?.engineCode, vehicle?.longlife, userContext?.id, workTaskId, params.topicId, params.extModule])

    return (
        <>
            <ReplaceArticleModal />
            <WrapperFrame loading={loading}>
                {url && (
                    <IFrame
                        url={url}
                        className={classes(style.wrapper, className, loading ? style.hide : "")}
                        contextDependent
                        id={`iframe_${id}`}
                        onLoad={handleLoadingCallback}
                        refreshOnUrlChanged
                    />
                )}
            </WrapperFrame>
        </>
    )
}

const style = useStyle({
    hide: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        height: percent(100),
        width: percent(100),
    },
})(HaynesproTdGenartsFrame)

export default withRouter(HaynesproTdGenartsFrame)
