import { Box, EasyPopover, Icon, IconButton } from "@tm/components"
import { getModuleFromUserContext, useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { ShareContent } from "./components/ShareContent"

export function WorktaskShareButton() {
    const { userContext } = useUser()
    const qrCodeShareEnabled = useMemo(() => getModuleFromUserContext(userContext, UserModuleType.Catalog)?.qrCodeWorkTask, [userContext])

    if (!qrCodeShareEnabled) {
        return null
    }

    return (
        <Box display="flex" alignItems="center">
            <EasyPopover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <EasyPopover.ActionButton>
                    <IconButton>
                        <Icon name="share" />
                    </IconButton>
                </EasyPopover.ActionButton>
                <EasyPopover.Content>
                    <ShareContent />
                </EasyPopover.Content>
            </EasyPopover>
        </Box>
    )
}
