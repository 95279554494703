import { getModuleFromUserContext, useModuleNavigation, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Headline, Icon } from "@tm/controls"
import { Box, Loader, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { SystemType, UserModuleType, getCurrentWorkTaskId } from "@tm/models"
import ManagerComponent from "../moduleManager"
import NavigationComponent from "../navigation/component"
import LoadingScreen from "../loading-screen/component"
import VehicleSelection from "../vehicle-selection/component"
import { WheelsList } from "../wheels-list/component"
import RdksList from "../rdks-list/component"
import TyresList from "../tyres-list/component"
import Overview from "../overview/component"
import { MainState } from "."
import { BundleActions } from "../../business"
import { vehicleSelector } from "../../data/helpers"
import { getBundleParams } from "../../utils"
import { DisplayStepLoader } from "../_shared/displayStepLoader"
import { WheelsDetails } from "../wheels-details/component"

type Props = RouteComponentProps<{ view: string; workTaskId: string }> & {
    vehicleSelectionRoute: string
    wheelsRoute: string
    repairTimesRoute: string
    articleAlternativesRoute: string
    detailsModalRoute: string
}

function WrappedModule({ children }: { children: React.ReactNode }) {
    const { showTab } = useModuleNavigation()

    useEffect(() => {
        showTab({
            icon: "wheel-and-car",
            title: "{{608}}",
        })
    }, [showTab])

    return (
        <Box flex={1} display="flex">
            {children}
        </Box>
    )
}

const WrappedBox = styled(Box)({
    flex: "1 1 100% !important",
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
    "&--details": {
        flexBasis: "auto",
    },
})

function Main({ vehicleSelectionRoute, wheelsRoute, repairTimesRoute, articleAlternativesRoute, detailsModalRoute, match }: Props) {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()

    const { userContext, userSettings } = useUser()
    const vehicle = useSelector(vehicleSelector)
    const { isHostettler, priceAvailabilityInformation, version } = getBundleParams()
    const workTaskVehicle = useWorkTask()?.workTask?.vehicle
    const showAvailability = priceAvailabilityInformation ? !userSettings?.hideWheelsAvailability : false
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const isTyresAvailable = useMemo(
        () => (version === "LKQ" ? !!getModuleFromUserContext(userContext, UserModuleType.TMTires) : true),
        [userContext]
    )

    const { keyValuesLoading, registrationNos, linearProgress, historyInit } = useSelector((s: MainState) => ({
        keyValuesLoading: s.manager.keyValueDataLoading,
        registrationNos: s.vehicleSelection.registrationNos,
        linearProgress: s.manager.linearProgress,
        historyInit: s.manager.historyInit,
    }))

    useEffect(() => {
        if (vehicle && (userContext.system.systemType == SystemType.Redesign || vehicle.id == workTaskVehicle?.id)) {
            dispatch(BundleActions.initWheelsLogic(isHostettler, vehicle, isTyresAvailable))
        }
    }, [vehicle?.id, workTaskVehicle?.id])

    useEffect(() => {
        if (historyInit) {
            return
        }
        // Hostettler case
        if (isHostettler && !!registrationNos) {
            dispatch(BundleActions.loadData(showAvailability, vehicle, getCurrentWorkTaskId(), telesalesCustomerNo, isTyresAvailable))
        }
        // other cases
        else if (!isHostettler && vehicle?.id) {
            dispatch(BundleActions.loadData(showAvailability, vehicle, getCurrentWorkTaskId(), telesalesCustomerNo, isTyresAvailable))
        }
    }, [isHostettler, registrationNos, vehicle?.id])

    if (keyValuesLoading) {
        return (
            <WrappedBox className="init-something">
                <ManagerComponent matchParamsWorkTask={match.params.workTaskId} />
                <div className="loading-screen">
                    <Icon name="wheel-and-car" />
                    <Headline>{translateText(608)}</Headline>
                    {!linearProgress && <Loader style={{ flex: 0 }} />}
                    {linearProgress && <DisplayStepLoader currentStep={linearProgress.currentStep} totalSteps={linearProgress.totalSteps} />}
                </div>
            </WrappedBox>
        )
    }

    return (
        <WrappedBox className={match.params.view}>
            <ManagerComponent matchParamsWorkTask={match.params.workTaskId} />
            <NavigationComponent wheelsRoute={wheelsRoute} repairTimesRoute={repairTimesRoute} />

            {!match.params.view && (
                <WrappedModule>
                    <LoadingScreen vehicleSelectionRoute={vehicleSelectionRoute} />
                </WrappedModule>
            )}
            {match.params.view === "vehicle-selection" && (
                <WrappedModule>
                    <VehicleSelection />
                </WrappedModule>
            )}
            {match.params.view === "wheels-list" && (
                <WrappedModule>
                    <WheelsList />
                </WrappedModule>
            )}
            {match.params.view === "wheels-details" && (
                <WrappedModule>
                    <WheelsDetails repairTimesRoute={repairTimesRoute} />
                </WrappedModule>
            )}
            {match.params.view === "rdks-list" && (
                <WrappedModule>
                    <RdksList
                        articleAlternativesRoute={articleAlternativesRoute}
                        repairTimesRoute={repairTimesRoute}
                        detailsModalRoute={detailsModalRoute}
                    />
                </WrappedModule>
            )}
            {match.params.view === "tyres-list" && (
                <WrappedModule>
                    <TyresList detailsModalRoute={detailsModalRoute} repairTimesRoute={repairTimesRoute} />
                </WrappedModule>
            )}
            {match.params.view === "overview" && (
                <WrappedModule>
                    <Overview repairTimesRoute={repairTimesRoute} detailsModalRoute={detailsModalRoute} />
                </WrappedModule>
            )}
        </WrappedBox>
    )
}

export default withRouter(Main)
