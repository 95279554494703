import { Article, ArticleErpInfos } from "@tm/models"
import { useDetailedErpInfoStore, useErpConfig } from "@tm/utils"
import { useMemo } from "react"
import { useWorkTaskId } from "@tm/context-distribution"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ArticleErpInfoFactory } from "../useErpInfos"
import { ErpInfosData } from "../../models"

/**
 * Try to get the already loaded ERP data for a list of articles.
 * For one article only use `useArticleErpInfo` instead.
 */
export function useArticlesErpInfos(articles: Article[], quantity: number, erpInfosData: ErpInfosData): ArticleErpInfos[] {
    const { erpInfos } = erpInfosData
    const { erpSystemConfigs } = useErpConfig()
    const defaultErpSystemId = useDefaultErpSystem()?.id
    const alternativeErpSystemId = erpSystemConfigs?.find((x) => !x.isDefaultGetErpInfoSystem)?.id

    return useMemo(() => {
        if (!defaultErpSystemId) {
            return []
        }

        return articles.map<ArticleErpInfos>((article) => {
            const defaultErpInfo = erpInfos.find(
                ({ key }) => key.id === ArticleErpInfoFactory.createKeyFromArticle({ ...article, quantity }, defaultErpSystemId).id
            )
            const alternativeErpInfo = alternativeErpSystemId
                ? erpInfos.find(
                      ({ key }) => key.id === ArticleErpInfoFactory.createKeyFromArticle({ ...article, quantity }, alternativeErpSystemId).id
                  )
                : undefined

            return {
                default: defaultErpInfo,
                alternative: alternativeErpInfo,
            }
        })
    }, [defaultErpSystemId, articles, erpInfos, alternativeErpSystemId, quantity])
}

/**
 * Try to get the already loaded ERP data for one article.
 * For multiple articles use `useArticlesErpInfo` instead.
 */
export function useArticleErpInfos(article: Article, quantity: number, erpInfosData: ErpInfosData): ArticleErpInfos {
    const workTaskId = useWorkTaskId()
    const articles = useMemo(() => [article], [article])
    const detailedErpStore = useDetailedErpInfoStore(workTaskId)
    const defaultErpSystem = useDefaultErpSystem()

    const articleErpInfos = useArticlesErpInfos(articles, quantity, erpInfosData).first() ?? { default: undefined, alternative: undefined }
    const hasTeccomRequest = articleErpInfos.default?.state === "success" && articleErpInfos.default.response.isTeccomRequestAvailable

    return useMemo(() => {
        let result = articleErpInfos

        // When it's a Teccom result, then look if there's an info loaded from the details
        if (hasTeccomRequest) {
            const storedInfo = detailedErpStore.findInfo(article)
            if (storedInfo && defaultErpSystem) {
                result = {
                    default: ArticleErpInfoFactory.createFromTeccomResponse(article, storedInfo, defaultErpSystem, 0),
                }
            }
        }
        return result
    }, [detailedErpStore.infos, defaultErpSystem, articleErpInfos])
}
