import { Box, Button, Checkbox, ListItemIcon, Loader, TextField, Typography } from "@tm/components"
import { ChangeEvent, useMemo, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useFastServiceStore, useWorkContext, worksActions } from "../../data"
import { ListItemComponent } from "./genArts/shared/ListItemComponent"
import { Genart } from "../../data/models"

type Props = {
    genArt: Genart
    handleShowAlternatives: (internalIdentifier: string) => void
}

export default function ProductGroupSearch({ genArt, handleShowAlternatives }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { step, path } = useWorkContext()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const { loading, selectedItems, inputsLocked } = useFastServiceStore((state) => ({
        loading: state.articlesLoading,
        selectedItems: state.worksState.selectedItems,
        inputsLocked: state.inputsLocked,
    }))

    const customPartInternalIdentifier = `${step}_${genArt.label}`

    const [alreadySet, setAlreadySet] = useState(false)
    const [checked, setChecked] = useState<boolean>(false)
    const [description, setDescription] = useState<string>(genArt.label)
    const [quantity, setQuantity] = useState(1)
    const [price, setPrice] = useState("0")
    const [articleNumber, setArticleNumber] = useState("")

    const customPart = useMemo(() => {
        return selectedItems[step]?.[path]?.selectedCustomParts?.find((cP) => cP.internalIdentifier === customPartInternalIdentifier)
    }, [step, path, selectedItems])

    useEffect(() => {
        if (alreadySet) {
            return
        }
        if (!customPart) {
            return
        }

        if (description === "" && customPart.description) {
            setDescription(customPart.description)
        }

        if (price === "0" && customPart.retailPrice) {
            setPrice(customPart.retailPrice.toString())
        }

        if (customPart.quantityValue && quantity !== customPart.quantityValue) {
            setQuantity(customPart.quantityValue)
        }

        if (articleNumber === "" && customPart.articleNumber) {
            setArticleNumber(customPart.articleNumber)
        }

        if (checked === false && customPart.isChecked) {
            setChecked(true)
        }

        setAlreadySet(true)
    }, [customPart])

    useEffect(() => {
        if (
            customPart?.articleNumber !== articleNumber ||
            customPart?.description !== description ||
            customPart?.quantityValue !== quantity ||
            customPart?.retailPrice !== +price ||
            customPart?.isChecked !== checked
        ) {
            handleCustomArticleChange()
        }
    }, [description, price, quantity, articleNumber, checked])

    const handleSetArticleNumber = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (customPart?.articleNumber !== event.target.value) {
            setArticleNumber(event.target.value)
        }
    }

    const handleSetDesignation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (customPart?.description !== event.target.value) {
            setDescription(event.target.value)
        }
    }

    const handleSetPrice = (event: ChangeEvent<HTMLInputElement>) => {
        const inputPrice = event.target.value
        const regex = /^[0-9]*([.|,][0-9]{0,2})?$/

        if (regex.test(inputPrice) || inputPrice === "0" || inputPrice === "") {
            setPrice(inputPrice)
        }
    }

    const handleSetQuantity = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (customPart?.quantityValue !== +event.target.value) {
            setQuantity(+event.target.value)
        }
    }

    const handleCustomArticleChange = () => {
        let formattedPrice = 0
        if (typeof price === "string") {
            formattedPrice = +price.replace(/,/g, ".")
        }

        worksActions.setUpdateCustomPart(step, path, customPartInternalIdentifier, articleNumber, quantity, formattedPrice, description, checked)
    }

    const showAlternativesClick = () => {
        handleShowAlternatives(customPartInternalIdentifier)
    }

    return (
        <ListItemComponent disablePadding>
            <Box padding="16px" width="100%">
                <Box display="flex" alignItems="center">
                    <ListItemIcon>
                        <Checkbox size="medium" onChange={() => setChecked(!checked)} checked={checked} disabled={inputsLocked} />
                    </ListItemIcon>
                    <Typography variant="h4" id={genArt.label}>
                        {loading ? <Loader size="small" /> : genArt.label}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="0.5em">
                    <TextField
                        label={translateText(87)}
                        disabled={!checked || inputsLocked}
                        onChange={handleSetArticleNumber}
                        value={articleNumber}
                        required
                        size="extralarge"
                    />
                    <Box display="flex" flexDirection="row" gap="1em" justifyContent="flex-start">
                        <TextField
                            label={translateText(13467)}
                            onChange={handleSetDesignation}
                            value={description}
                            size="extralarge"
                            sx={{ width: "20em" }}
                            required
                            disabled={!checked || inputsLocked}
                        />
                        <Button color="highlight" onClick={showAlternativesClick} disabled={!checked || inputsLocked}>
                            {translateText(13723)}
                        </Button>
                    </Box>

                    <TextField label={currencyCode} onChange={handleSetPrice} value={price} size="extralarge" disabled={!checked || inputsLocked} />
                    <TextField
                        label={translateText(89)}
                        type={!inputsLocked ? "number" : ""}
                        onChange={handleSetQuantity}
                        size="extralarge"
                        value={quantity}
                        disabled={!checked || inputsLocked}
                    />
                </Box>
            </Box>
        </ListItemComponent>
    )
}
