import { Box, Icon, styled, Typography } from "@tm/components"

type Props = {
    title: string
    subtitle: string
    open: boolean
    onClick: () => void
}

export function SummaryAccordionTitle({ title, subtitle, open, onClick }: Props) {
    return (
        <StyledAccordionSummary onClick={onClick}>
            <Icon name={open ? "down" : "up"} height="2em" width="2em" />
            <Box>
                <Typography variant="body1">{title}</Typography>
                <Typography variant="body3">{subtitle}</Typography>
            </Box>
        </StyledAccordionSummary>
    )
}

const StyledAccordionSummary = styled(Box)({
    height: "3.5em",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    cursor: "pointer",
})
