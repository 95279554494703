import { useCallback, useEffect, useMemo } from "react"
import { batch, useSelector } from "react-redux"
import { isArray } from "lodash"
import { Box } from "@tm/components"
import { useArticleSelectionActions } from "@bundles/parts/components/ListV2/hooks"
import { mapTyreAttributeToTyreFilter } from "@bundles/tyres/data/helpers/mapTyreAttributeToTyreFilter"
import { mapTyreFilterToListFilter } from "@bundles/tyres/data/helpers/mapTyreFilterToListFilter"
import { ListFilter, TyreArticleAttribute, TyreFilter } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { ParticularStateProvider, TyresListType } from "../../../../../parts/src/components/PureList/ContextProviders/Particular/ParticularContext"
import { TyresList } from "../../../../../parts/src"
import { MainState } from "../../main"
import { getBundleParams } from "../../../utils"
import { IListFilters } from "../business"
import { MainActions } from "../../main/business"

export function TyresListV2() {
    const request = useSelector((s: MainState) => s.list?.request)
    const selectedAvailability = useSelector((s: MainState) => s.list?.selectedFilters.availability)
    const selectedFilters = useSelector((s: MainState) => s.list?.selectedFilters)

    const articles = useSelector((s: MainState) => s.list.articles)
    const { maxAutoRequestV2 } = getBundleParams()

    const actions = useActions(MainActions, "updateAttributeFilter", "loadTyresList")

    // Relevant for Multiselect, resets on reload, list change or new search
    const { deselectAll } = useArticleSelectionActions()

    useEffect(() => {
        deselectAll()
    }, [request])

    const selectedFiltersMapped: ListFilter[] = useMemo(
        () =>
            Object.keys(selectedFilters)
                .filter((x) => x !== "availability")
                .map((filterKey) => {
                    if (isArray(selectedFilters[filterKey])) {
                        const selectedFiltersGroup = selectedFilters[filterKey] as TyreFilter[]
                        return selectedFiltersGroup.map((filter) => mapTyreFilterToListFilter(filter))
                    }

                    const filter = selectedFilters[filterKey] as TyreFilter

                    if (!filter) {
                        return undefined
                    }

                    return [mapTyreFilterToListFilter(filter)]
                })
                .flatMap((x) => x)
                .filter((x) => x !== undefined) as ListFilter[],
        [selectedFilters]
    )

    const handleClickAttribute = useCallback(
        (articleAttribute: TyreArticleAttribute) => {
            const filter = mapTyreAttributeToTyreFilter(articleAttribute)

            if (!filter) {
                return
            }

            batch(() => {
                actions.updateAttributeFilter(filter.group as IListFilters, filter)
                actions.loadTyresList(undefined, true)
            })
        },
        [actions]
    )

    const context: TyresListType = {
        type: "tyresList",
        request,
        selectedAvailability,
        loadNextItems: articles.loadingNextItems || articles.loadingAutoItems || articles.loading,
        maxAutoRequest: maxAutoRequestV2,
        selectedFilters: selectedFiltersMapped,
        handleClickAttribute,
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <ParticularStateProvider value={context}>
                <TyresList />
            </ParticularStateProvider>
        </Box>
    )
}
