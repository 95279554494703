import { useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Typography, Stack, Icon, Modal, SelectorPaper, styled } from "@tm/components"
import { channel, RequestArticleDetailsPayload, PartsBundleParams, Article } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { notUndefinedOrNull } from "@tm/utils"
import { ArticleComparison, openPartDetailsInModal } from "@bundles/parts"
import { CombinedParticularState, useParticularContext } from "../PureList/ContextProviders/Particular/ParticularContext"
import { useTyreArticleSelection } from "./hooks"

type Props = {
    selectedParts: Array<Article>
    workTaskId: string
    hideAddToBasketButtonInArticleComparison?: boolean
    onUnselectAll(): void
}

const StyledPaper = styled(SelectorPaper)({
    position: "static",
    height: "2.1em",
    display: "flex",
    alignItems: "center",
})

export default function PartsSelection({ workTaskId, selectedParts, hideAddToBasketButtonInArticleComparison, onUnselectAll }: Props) {
    const { translateText } = useLocalization()
    const combinedContext = useParticularContext<CombinedParticularState>()
    const [openCompare, setOpenCompare] = useState(false)
    const [selectedPart, setSelectedPart] = useState<Article | null>(null)
    const itemCount = selectedParts.length

    const selection = useTyreArticleSelection(
        (combinedContext?.selectedArticles as Article[]) ?? [],
        combinedContext?.toggleSelectedArticle as (article: Article) => void
    )

    const disabledComparisionButton = useMemo(() => {
        return selectedParts.length <= 1 || selectedParts.some((part) => !part)
    }, [selectedParts])

    function handleRequestArticleDetails(request: RequestArticleDetailsPayload) {
        const { partsRoutes } = Morpheus.getParams<PartsBundleParams>("parts")

        if (!workTaskId) {
            openPartDetailsInModal(request, {}, partsRoutes)
        } else {
            channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
        }
    }

    function handleOnCloseModal() {
        setOpenCompare(false)

        if (!selectedPart) {
            return
        }

        onUnselectAll()
        selection.toggleSelectedArticle(selectedPart)
    }

    return (
        <>
            <StyledPaper>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="label" sx={{ "&&": { fontSize: "12px", fontWeight: "400" } }}>
                        {translateText(701)}: {itemCount} {translateText(479)}
                    </Typography>
                    <Button onClick={() => onUnselectAll()} size="small">
                        {translateText(703)}
                    </Button>
                    <Button onClick={() => setOpenCompare(true)} disabled={disabledComparisionButton} size="small">
                        {translateText(13947)}
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<Icon name="cancel-x" />}
                        size="small"
                        title={translateText(317)}
                        onClick={() => onUnselectAll()}
                    />
                </Stack>
            </StyledPaper>
            <Modal open={openCompare} onOutsideClick={handleOnCloseModal}>
                <ArticleComparison
                    partsToCompare={selectedParts.map((part) => part).filter(notUndefinedOrNull)}
                    previouslyOrderedArticles={[]}
                    showArticleImages
                    onRequestArticleDetails={handleRequestArticleDetails}
                    hideAddToBasketButton={hideAddToBasketButtonInArticleComparison}
                    onCloseWithSelection={(part) => setSelectedPart(part)}
                />
            </Modal>
        </>
    )
}
