import { Checkbox, Icon, Loader, Table, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { equals } from "@tm/utils"
import { batch, useDispatch, useSelector } from "react-redux"
import { MuiCheckbox } from "@tm/components"
import { TireSize } from "../../../../data/model"
import { WheelsDetailsState, Actions } from "../../business"
import { MainState } from "../../../main"

type Props = {
    colorCheckIcon?: boolean
    tireSizeSelectionEnabled?: boolean
    largeCheckbox?: boolean
}

function TireSizeComponent({ colorCheckIcon, tireSizeSelectionEnabled, largeCheckbox }: Props) {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const selectedSize = useSelector<MainState, WheelsDetailsState["selectedTireSize"]>((s) => s.wheelsDetails.selectedTireSize)
    const { data, loading } = useSelector<MainState, WheelsDetailsState["rimInfoTireSizesResponse"]>((s) => s.wheelsDetails.rimInfoTireSizesResponse)
    const checkIcon = colorCheckIcon ? "check" : "check-filled"

    const handleSetTireSize = (item: TireSize) => {
        if (tireSizeSelectionEnabled) {
            batch(() => {
                dispatch(Actions.selectTireSize(item))
                dispatch(Actions.loadTiresRestrictions())
            })
        }
    }

    const renderTyreSize = (item: TireSize) => (
        <Table.Cell>
            <div>{item.tyreSize}</div>
        </Table.Cell>
    )

    const renderOperatingLicence = (item: TireSize) => (
        <Table.Cell>
            <Icon
                className="search-icon"
                name={(item.operatingLicence && checkIcon) || "close"}
                size="l"
                skin={item.operatingLicence && colorCheckIcon ? "success" : undefined}
            />
        </Table.Cell>
    )

    const renderShowChain = (item: TireSize) => (
        <Table.Cell>
            <Icon
                className="search-icon"
                name={(item.showChain && checkIcon) || "close"}
                size="l"
                skin={item.showChain && colorCheckIcon ? "success" : undefined}
            />
        </Table.Cell>
    )

    const renderModelSize = (item: TireSize) => (
        <Table.Cell>
            <Icon name={(item.modelSize && checkIcon) || "close"} size="l" skin={item.modelSize && colorCheckIcon ? "success" : undefined} />
        </Table.Cell>
    )

    const renderCheckedTireList = (item: TireSize) => (
        <Table.Cell>
            {tireSizeSelectionEnabled ? (
                <div className={equals(selectedSize, item) ? "article-check" : ""}>
                    {largeCheckbox ? (
                        <MuiCheckbox checked={equals(selectedSize, item)} sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: "2.5em" } }} />
                    ) : (
                        <Checkbox checked={equals(selectedSize, item)} />
                    )}
                </div>
            ) : (
                <div />
            )}
        </Table.Cell>
    )

    const renderEce = (item: TireSize) => (
        <Table.Cell>
            <Icon name={(item.ece && checkIcon) || "close"} size="l" skin={item.ece && colorCheckIcon ? "success" : undefined} />
        </Table.Cell>
    )

    if (loading) {
        return <Loader />
    }

    if (!data.length) {
        return (
            <div className="article-list__panel article-list__status">
                <Text>{translateText(323)}</Text>
            </div>
        )
    }

    return (
        <div className="tire-sizes-table">
            <Table
                scrollable
                getRowClassName={(_) => "is-clickable"}
                onClickRow={handleSetTireSize}
                columns={[
                    <Table.Column renderItemContent={renderTyreSize}>{translateText(968)}</Table.Column>,

                    <Table.Column renderItemContent={renderOperatingLicence}>{translateText(969)}</Table.Column>,

                    <Table.Column renderItemContent={renderShowChain}>{translateText(970)}</Table.Column>,

                    <Table.Column renderItemContent={renderModelSize}>{translateText(971)}</Table.Column>,

                    <Table.Column renderItemContent={renderEce}>ECE</Table.Column>,

                    <Table.Column renderItemContent={renderCheckedTireList}>{translateText(1350)}</Table.Column>,
                ]}
                data={data}
            />
        </div>
    )
}

export default TireSizeComponent
