import { useStyle } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { rem } from "csx"
import { FC } from "react"

type Props = {
    textId: number
}

const HeadlineBox: FC<Props> = ({ textId }) => {
    const { translate } = useLocalization()

    return (
        <div className={style.boxHeadline}>
            <div className={style.before} />
            {translate(textId)}
        </div>
    )
}

const style = useStyle({
    boxHeadline: {
        fontSize: rem(1.5),
        display: "flex",
        alignItems: "center",
        marginLeft: rem(-1),
        marginBottom: rem(1),
        opacity: 0.87,
    },
    before: {
        content: "*",
        display: "block",
        width: rem(1),
        height: rem(0.5),
        background: "#2196f3",
        marginRight: rem(1),
    },
})(HeadlineBox)

export default HeadlineBox
