import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { mapCarParkResponse } from "./mapper"
import { RimArticleFilter, RimArticleFilterResponse } from "./model"

export function loadRimArticleFilter(registrationNo: string, filter: string, carParkId: number): Promise<RimArticleFilterResponse> {
    const url = `${getServiceUrl()}/GetRimArticleAndFilter`
    const authorization = getStoredAuthorization()
    const body = { registrationNo, filter, carParkId }

    return ajax<RimArticleFilter>({ url, body, authorization, method: "GET" }).then((response) => {
        if (response) {
            return mapCarParkResponse(response)
        }
        // return type must be rim filters and rim articles
        throw new Error("cartype request failed")
    })
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}
