import { IBaseActions, BaseActions, BaseComponentActionType, reduce as baseReducer } from "./baseReducer"
import { IConfigActions, ConfigActions, ConfiguratorComponentActionType, reduce as configuratorReducer } from "./configuratorReducer"
import { IBundleActions, BundleActions } from "../../../business"

import { combineReducers } from "redux"

export * from "./model"

export const reduce = combineReducers({ base: baseReducer, configurator: configuratorReducer })

export type ComponentActionType = ConfiguratorComponentActionType | BaseComponentActionType

export type IActions = IBundleActions & IBaseActions & IConfigActions
export const Actions: IActions = { ...BundleActions, ...BaseActions, ...ConfigActions }
