import { Box, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEdsStore } from "../../data/state"
import GraphicDetails from "./components/GraphicDetails"
import GraphicSelection from "./components/GraphicSelection"
import SelectionTree from "./components/SelectionTree"

function GraphicSearch() {
    const { translateText } = useLocalization()

    const selectedUnit = useEdsStore((state) => state.graphic.selectedUnit)
    const error = useEdsStore((state) => state.treeNavigation.isError)

    if (error) {
        return (
            <Box sx={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <Icon name="no-results" width="15rem" height="4rem" />
                <Typography variant="h2">{translateText(13413)}</Typography>
            </Box>
        )
    }

    if (selectedUnit) {
        return <GraphicDetails />
    }

    return (
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 5fr", height: "100%", width: "100%" }}>
            <SelectionTree />
            <GraphicSelection />
        </Box>
    )
}

export default GraphicSearch
