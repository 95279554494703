import { Icon } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { TmaHelper, encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useMemo } from "react"
import { useHistory, useParams } from "react-router"
import { LinkTarget } from "@tm/models"
import { getBundleParams } from "../../utils"

type ConfigProps = {
    className?: string
    targetIcon?: string
}

type Props = ConfigProps & {
    openPromoPartsList?: boolean
}

function StartpageButtonComponent(props: Props) {
    const history = useHistory()
    const matchParams = useParams<{ workTaskId: string }>()
    const { className, targetIcon } = props
    const { translateText } = useLocalization()
    const { detailsUrl } = getBundleParams()
    const { userContext } = useUser()

    const mdmProps = useMemo(() => {
        const offerModule = userContext?.externalModules?.find((m) =>
            m.parameter?.some((param) => param.key === "DocumentTypeID" && param.value === "Offers")
        )
        if (!offerModule) {
            return
        }

        const key = "Target"
        const target = (offerModule[key] ?? offerModule.parameter?.find((param) => param.key === key)?.value) as LinkTarget | undefined
        return { url: offerModule.replacedUrl, target }
    }, [userContext?.externalModules])

    function handleClick() {
        if (mdmProps?.target === LinkTarget.NewWindow && mdmProps?.url) {
            window.open(mdmProps?.url)
        } else if (props.openPromoPartsList) {
            window.top?.postMessage({ openPromoPartsList: true }, "*")
        } else {
            const url = renderRoute(detailsUrl, { workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()) })
            history.push(url)
            TmaHelper.GeneralCountEvent.CallModule(url)
        }
    }

    return (
        <Widget
            id="offers__startpage-button"
            size="1x1"
            active={false}
            className={`tk-offers startpage-button ${className || ""}`}
            onClick={handleClick}
            cover={
                <Widget.Cover>
                    <Icon className="widget__cover-icon" name="sales" />
                    <div className="widget__cover-text">{translateText(1276)}</div>
                    {targetIcon && <Icon name={targetIcon} className="target-icon" />}
                </Widget.Cover>
            }
        />
    )
}

export default StartpageButtonComponent
