import { withRouter, RouteComponentProps, renderRoute, isSameDay } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { ChatList as ComChatList, ChatCard, Icon, TextField, styled } from "@tm/components"
import { ReactNode, useState } from "react"
import { addDaysToDate, getIconNameByMimeType, getTextByMimeType } from "../../../_shared/helper"
import { ChatInfo, useChatList, useLastReadInfoDate, useOwnUserId } from "../../../../data/hooks"

type Props = RouteComponentProps<{ chatId?: string }>

const InputWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
}))

const MarginIcon = styled(Icon)({
    margin: "0 4px",
})

function ChatList({ match, history }: Props) {
    const { translateText, date: formatDate } = useLocalization()
    const ownUserId = useOwnUserId()
    let list = useChatList()
    const [query, setQuery] = useState<string>()
    const [selectedChatId, setSelectedChatId] = useState<string>(match.params.chatId || "")
    const { lastReadInfoDate } = useLastReadInfoDate()

    if (!list?.length) {
        return null
    } // TODO: show nice message

    const handleClick = (chat: ChatInfo) => {
        setSelectedChatId(chat.chatId)
        history.push(renderRoute(match.path, { ...match.params, chatId: chat.chatId }))
    }

    const previewText = (chat: ChatInfo): { text: string; prefix: ReactNode } => {
        let pretext = ""
        let icon
        let text = ""
        if (chat.lastMessage) {
            const message = chat.lastMessage
            if (message.authorId === ownUserId) {
                pretext = `${translateText(3099)}: `
            } else if (chat.users.length > 1) {
                const found = chat.users.find((u) => u.userId === message.authorId)?.displayName
                if (found) {
                    pretext = `${found}: `
                }
            }
            if (message.attachment) {
                icon = getIconNameByMimeType(message.attachment.mimeType)
            }

            if (message.text) {
                text = message.text
            } else if (message.attachment) {
                text = translateText(getTextByMimeType(message.attachment.mimeType))
            }
        }
        return {
            text,
            prefix: (
                <>
                    {`${pretext} `} {icon && <MarginIcon name={icon} />}
                </>
            ),
        }
    }

    if (query) {
        list = list.filter((chat) => chat.users.some((u) => u.displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0))
    }

    return (
        <>
            <InputWrapper>
                <TextField
                    size="small"
                    placeholder={translateText(3088)}
                    type="search"
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                    sx={{ width: "100%" }}
                />
            </InputWrapper>

            <ComChatList>
                {list.map((chat) => {
                    let date = ""
                    let lastMessageDate
                    if (chat.lastMessage) {
                        lastMessageDate = new Date(chat.lastMessage.inserted)

                        if (isSameDay(lastMessageDate, new Date())) {
                            date = formatDate(lastMessageDate, "t")
                        } else if (isSameDay(lastMessageDate, addDaysToDate(new Date(), -1))) {
                            date = translateText(3101)
                        } else {
                            date = formatDate(lastMessageDate, "d")
                        }
                    }
                    const sublineData = previewText(chat)
                    return (
                        <ChatCard
                            key={chat.chatId}
                            id={chat.chatId}
                            onClick={() => handleClick(chat)}
                            isNew={!!lastMessageDate && !!lastReadInfoDate && lastMessageDate > lastReadInfoDate}
                            selected={selectedChatId === chat.chatId}
                            title={chat.users.map((u) => u.displayName).join(", ")}
                            lastUpdate={date}
                            subline={sublineData.text}
                            sublinePrefix={sublineData.prefix}
                        />
                    )
                })}
            </ComChatList>
        </>
    )
}

export default withRouter(ChatList)
