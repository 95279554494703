import { Typography } from "@tm/components"
import { FastServiceSelection, Item, SelectionKeys } from "../../../data/models"
import { maintenancePlanActions, useFastServiceStore } from "../../../data"
import CheckToken from "./CheckToken"
import Select from "./Select"
import { submitSelection } from "../../../helpers/maintenancePlan"

type Props = {
    selection: FastServiceSelection
    onCheckOpened?: (checkOffsetTop: number) => void
}

export default function SelectionCheck(props: Props) {
    const { selection, onCheckOpened } = props

    const { userSelections, inputsLocked } = useFastServiceStore((state) => ({
        userSelections: state.maintenancePlan.userSelections,
        inputsLocked: state.inputsLocked
    }))

    function handleSelection(element: string[], selectionId: string, selectionKey: SelectionKeys) {
        submitSelection({ items: element, selectionId }, undefined, selectionKey)
    }

    const storeSelectionsBySelection = (selectionKey: SelectionKeys, id: string, selectionId: string) => {
        maintenancePlanActions.setUpdateSelections(selectionKey, id, selectionId)
    }

    const renderHeader = (items: Item[]) => {
        return (
            <div>
                {items.map((item, index) => (
                    <Typography key={item.id ?? index} variant="h3" fontWeight="600">
                        {item.label}
                    </Typography>
                ))}
            </div>
        )
    }

    return (
        <CheckToken
            onCheckOpened={onCheckOpened}
            name={selection.label}
            isCompleted={selection.isSubmitted}
            headerData={renderHeader(selection.items.filter((x) => x.isSelected))}
        >
            <Select
                selectionKey={SelectionKeys.Selections}
                withSubmit
                onSelect={handleSelection}
                items={selection.items}
                multiSelect={selection.multiSelect}
                selectionId={selection.selectionId}
                updateMultiSelections={(id: string, selectionId: string) => storeSelectionsBySelection(SelectionKeys.Selections, id, selectionId)}
                selectedIds={userSelections?.[SelectionKeys.Selections]?.[selection.id] || []}
                proposedItem={!selection.multiSelect && !selection.isSubmitted && selection.items.find((i) => i.isSelected === true) !== undefined}
                readonly={inputsLocked}
            />
        </CheckToken>
    )
}
