import { Suspense, useMemo, useEffect, useState } from "react"
import { elementId, useModelDetails, generateHash, decodeUniqueId } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { useModuleNavigation, useReplaceUrlTags, useUser, useWorkTask } from "@tm/context-distribution"
import { IFrame, ControlsConfig } from "@tm/controls"
import { channel } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useLocation, useParams } from "react-router"
import { Button, Icon, styled } from "@tm/components"
import { State, IActions, Actions } from "./business"

type Props = {
    state: State
    actions: IActions
    createUrl: string
    hideCloseButton?: boolean
    className?: string
    contextDependent?: boolean
    id?: string
    token?: string
    // TODO: getToken is deprecated
    getToken?: string
}
type IframeControlsConfig = {
    hideInsteadOfRemovingIframe?: boolean
}

// Wrapper added so useModelDetails won't crash becasue of recoil state
function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <IFrameModuleComponent {...props} />
        </Suspense>
    )
}

const CloseButton = styled(Button)(() => ({
    position: "absolute",
    right: "2em",
    top: "1.2em",
    zIndex: "1",
}))

CloseButton.defaultProps = { startIcon: <Icon name="close" />, variant: "contained" }

function IFrameModuleComponent(props: Props) {
    const localization = useLocalization()
    const { userContext } = useUser() ?? {}
    const { workTask } = useWorkTask() ?? {}
    const { closeTab } = useModuleNavigation()
    const { vehicle, id: workTaskId } = workTask ?? {}
    const location = useLocation()
    const matchParams = useParams<{ workTaskId?: string }>()

    const modelDetailsResponse = useModelDetails({ vehicleType: vehicle?.vehicleType, modelId: vehicle?.tecDocTypeId })

    const { token, getToken, state, actions, hideCloseButton, contextDependent, id, className } = props
    const [config] = useState<IframeControlsConfig>(ControlsConfig.get<IframeControlsConfig>("iframe-module"))

    const searchParams = useMemo(() => {
        return new URLSearchParams(location.search)
    }, [location.search])

    let url = getUrlRaw()

    url = url ? decodeUrl(url) : url
    const replacedExternalCatalogUrl = useReplaceUrlTags(url, { languageId: localization.languageId })

    const title = useMemo(() => searchParams.get("title"), [searchParams])
    const worktaskIdWithFallback = workTaskId || (matchParams?.workTaskId ? decodeUniqueId(matchParams?.workTaskId) : undefined)

    useEffect(() => {
        const unsubscriptions: Array<Function> = []
        if (token) {
            const [tokenType, tokenKey] = token.split(":")
            actions.getToken(tokenType, tokenKey)
        } else if (getToken) {
            actions.getTokenDeprecated(getToken, props.id || elementId("IFRAME_WIDGET_TOKEN_"))
        }

        if (worktaskIdWithFallback) {
            if (title) {
                channel("WORKTASK", worktaskIdWithFallback).publish("MODULE/OPENED", {
                    isSelected: true,
                    url: location.pathname + location.search,
                    title,
                })
            }
            unsubscriptions.push(
                channel("WORKTASK", worktaskIdWithFallback).subscribe("MODULE/CLOSED", (unqualifiedUrl) => {
                    if (config.hideInsteadOfRemovingIframe) {
                        const checkExternalUrl = unqualifiedUrl.split("url=")

                        let url = unqualifiedUrl
                        if (checkExternalUrl.length > 1) {
                            url = decodeUrl(checkExternalUrl[1])
                        }

                        let moduleUrl = getUrlRaw()

                        if (!moduleUrl) {
                            return
                        }

                        moduleUrl = decodeUrl(moduleUrl)
                        if (url == props.id || url == moduleUrl) {
                            const iframeId = generateHash(worktaskIdWithFallback ? `${worktaskIdWithFallback}#${url}` : url)
                            const iframe = document.querySelector<HTMLIFrameElement>(`${"#iframe-"}${iframeId}`)
                            iframe?.remove()
                        }
                    }
                })
            )
        }
        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    useEffect(() => {
        const unsub = channel("WORKTASK", id).subscribe(
            "MODULE/SELECTED",
            (mod) => {
                if (mod.url === location.pathname + location.search) {
                    actions.setHideCloseButton(true)
                }
            },
            true
        )
        return unsub
    }, [location.pathname, location.search])

    function getUrlRaw(): string | undefined {
        if (!props.createUrl) {
            const url = searchParams.get("url") || undefined

            return url
        }
        const createUrl = eval(`[${props.createUrl}][0]`)
        if (!userContext || typeof createUrl != "function") {
            return
        }

        if ((token || getToken) && !state.token) {
            return
        }

        const url = createUrl(userContext, vehicle, workTask?.customer, location, modelDetailsResponse?.modelDetails, state.token, localization)
        return url
    }

    function decodeUrl(url: string) {
        let iframe = url
        const targetUrlParts = url.split("?")
        const iframeUrl = targetUrlParts.reverse().pop()
        if (iframeUrl && /%[\w\d]{2}/.test(iframeUrl)) {
            targetUrlParts.push(decodeURIComponent(iframeUrl))
            targetUrlParts.reverse()
            iframe = targetUrlParts.join("?")
        }

        return iframe
    }

    function handleClose() {
        const fullUrl = location.pathname + location.search
        closeTab(fullUrl)
    }

    if (((token || getToken) && !state.token) || !replacedExternalCatalogUrl) {
        return null
    }

    const newClassName = `bundle-misc iframe-module${className ? ` ${className}` : ""}`
    const iframeId = generateHash(workTaskId ? `${workTaskId}#${replacedExternalCatalogUrl}` : replacedExternalCatalogUrl)
    const iframeClass = "iframe-control" // want to add worktask id to be able to close all worktask dependent and hidden iframes on wk close, but when adding wkid the iframe will not render..., just the string works fine

    const renderIFrame = () => {
        if (contextDependent) {
            return <IFrame url={replacedExternalCatalogUrl} className={iframeClass} contextDependent id={id ?? ""} />
        }

        if (config.hideInsteadOfRemovingIframe) {
            return <IFrame url={replacedExternalCatalogUrl} className={iframeClass} contextDependent={false} id={`iframe-${iframeId}`} />
        }

        return <iframe title="iframe" width="100%" height="100%" src={replacedExternalCatalogUrl} allowFullScreen />
    }

    return (
        <div className={newClassName}>
            {!!title || hideCloseButton || state.hideCloseButton || <CloseButton onClick={handleClose} />}
            {renderIFrame()}
        </div>
    )
}

export default connectComponent(Actions, Wrapper)
