import { ajaxAuth } from "@tm/utils"
import {
    DeleteCustomRepairTimesRequest,
    ShowCustomRepairTimeItemsRequest,
    ShowCustomRepairTimeItemsResponse,
    AddCustomRepairTimeItemsRequest,
    AddCustomRepairTimeItemsResponse,
    EditCustomRepairTimeItemRequest,
    EditCustomRepairTimeItemResponse,
} from "../../model/custom-items"
import { getBundleParams } from "../../../utils"

function getServiceUrl() {
    return getBundleParams().customRepairTimesServiceUrl
}

export function showCustomRepairTimeItems(body: ShowCustomRepairTimeItemsRequest) {
    const url = `${getServiceUrl()}/ShowCustomRepairTimeItems`
    return ajaxAuth<ShowCustomRepairTimeItemsResponse>({ url, body }).then((response) => ({
        data: response?.customRepairTimeItems ?? [],
        pageIndex: body.pageIndex,
        endOfList: (response?.customRepairTimeItems?.length ?? 0) < body.pageSize,
    }))
}

export function addCustomRepairTimeItems(body: AddCustomRepairTimeItemsRequest) {
    const url = `${getServiceUrl()}/AddCustomRepairTimeItems`
    return ajaxAuth<AddCustomRepairTimeItemsResponse>({ url, body, method: "POST" })
}

export function editCustomRepairTimeItem(body: EditCustomRepairTimeItemRequest) {
    const url = `${getServiceUrl()}/EditCustomRepairTimeItem`
    return ajaxAuth<EditCustomRepairTimeItemResponse>({ url, body, method: "POST" })
}

export function deleteCustomRepairTimes(body: DeleteCustomRepairTimesRequest) {
    return ajaxAuth<void>({ url: `${getServiceUrl()}/Delete`, body, method: "POST" })
}
