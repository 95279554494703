import { QRCodeSVG } from "qrcode.react"
import { Box, LoadingContainer, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useFastUpUpload } from "../../hooks/fastUp"

const qrCodeSize = 200

type Props = {
    onFileUploaded: (url: string) => void
}

export function FastUpUpload({ onFileUploaded }: Props) {
    const { translateText } = useLocalization()
    function onUploadCompleted(uri: string) {
        onFileUploaded(uri)
    }

    const { uploadUri, isLoading, uploadStarted } = useFastUpUpload(onUploadCompleted)

    const renderQrCode = () => {
        if (!uploadUri) {
            return <div />
        }

        return <QRCodeSVG value={uploadUri} size={qrCodeSize} />
    }

    return (
        <LoadingContainer height="auto" display="flex" justifyContent="center" isLoading={isLoading || uploadStarted}>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1em" height="100%" padding="1em">
                <Box display="flex" alignItems="center" justifyContent="center" position="relative">
                    {renderQrCode()}
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography sx={{ wordBreak: "break-word" }}>{translateText(12680)}</Typography>
                </Box>
            </Box>
        </LoadingContainer>
    )
}
