import { useEffect, useMemo } from "react"
import {
    mergeArticlesWithErpAlternatives,
    replaceArticlesQuantityWithQuantityReplacement,
    usePartsViewOptions,
} from "@bundles/parts/components/ListV2/hooks"
import { Article } from "@tm/models"
import { ArticlesRequest } from "../../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../../data/repositories/uni-parts"
import { useArticleListConfiguration } from "../../../../ArticleListConfiguration"
import { fetchUniversalPartsArticles } from "../../../../data/fetchUniversalPartsArticles"
import { replaceArticlesQuantityWithSuggestedQuantity, useArticlesBase } from "../../../../hooks/useArticlesBase"
import { BaseArticleData, UniversalPartsListParams } from "../../../../models"
import { usePartsAlternativesModuleState } from "../../../../PartsAlternatives/PartsAlternativesModuleState"

const QUERY_KEY = "UniversalParts.useArticlesByNode"

export function useArticlesByNodeOrProductGroups(params: UniversalPartsListParams, isEnabled: boolean): BaseArticleData {
    const { productGroups, suppliers, attributes, startParams, extendedAssortment, isFiltersLoading } = params

    const { pageSize } = useArticleListConfiguration()
    const partToReplaceQuantity = usePartsAlternativesModuleState((x) => x?.partToReplaceQuantity)
    const {
        partsViewSettings: { quantitySuggestionEnabled },
    } = usePartsViewOptions()

    const request = useMemo<ArticlesRequest | null>(() => {
        if (!isEnabled || isFiltersLoading || startParams.type === "unisearch" || !productGroups.length) {
            return null
        }

        return {
            pageSize,
            selectedProductGroups: productGroups,
            selectedSuppliers: suppliers,
            selectedCriteria: attributes,
            extendedAssortment,
        }
    }, [isEnabled, isFiltersLoading, pageSize, productGroups, suppliers, startParams, attributes, extendedAssortment])

    useEffect(
        function resetNoResult() {
            params.setNoResult(false)
        },
        [request]
    )

    function createQuerySelectFunc(): ((articles: Article[]) => Article[]) | undefined {
        if (!partToReplaceQuantity && !quantitySuggestionEnabled) {
            return undefined
        }
        return (articles) => {
            if (partToReplaceQuantity) {
                articles = replaceArticlesQuantityWithQuantityReplacement(articles, partToReplaceQuantity)
            } else if (quantitySuggestionEnabled) {
                articles = replaceArticlesQuantityWithSuggestedQuantity(articles)
            }
            return articles
        }
    }

    return useArticlesBase(
        request,
        (context) => fetchUniversalPartsArticles(PartsRepository.getArticlesBySearchTree, context),
        QUERY_KEY,
        createQuerySelectFunc()
    )
}
