import { Vehicle, Article, ErpInformation, RegisteredModels, KeyValueStateContainer, ShowCarModelDetailsResponse, TyreFilter } from "@tm/models"
import { Container } from "@tm/nexus"
import { AsyncAction } from "@tm/morpheus"
import { WheelSelectionSteps } from "./data/enums"
import { RimItem, CarParkAttribute, CarPark } from "./data/model"
import { SelectedFilters } from "./components/wheels-list/business/model"
import { FiltersSensorsResponse } from "./data/repositories/articles-loadSensorFilters/model"
import { HistoryData } from "./data/models"
import { generateKey, initHistoryNav } from "./data/helpers"
import { MainState } from "./components/main"

export enum AvailabilityFilterType {
    None = 0,
    Primary = 1,
    Secondary = 2,
}

export type BundleActionTypes =
    // response
    | {
          type: "CAR_PARK_LOADED"
          payload: { carParks: CarPark[]; headers: CarParkAttribute[]; hasMultipleCarParks: boolean; latestVehicleUsed?: string }
      }
    | { type: "SEND_SENSOR_FILTERS_RESPONSE"; payload: FiltersSensorsResponse }
    | { type: "SEND_SENSOR_ITEMS_RESPONSE"; payload: Article[] }

    // navigation
    | {
          type: "CHANGE_STEP"
          payload: {
              step: WheelSelectionSteps
              disableNextSteps?: boolean
              params?: Record<string, string>
              quantity?: number
              enablePreviousStep?: boolean
          }
      }

    // send selected
    | { type: "SEND_SELECTED_CARD_PARK"; payload: CarPark }
    | {
          type: "SEND_RIM_ARTICLE_TO_DETAILS"
          payload: { rimItem: RimItem; selectedFilters: SelectedFilters; configuratorImage?: string; size?: string }
      }
    | { type: "SEND_SENSOR_ARTICLE_TO_DETAILS"; payload: Article }
    | { type: "SEND_SENSOR_TO_OVERVIEW"; payload: Article }
    | { type: "SEND_TIRE_TO_OVERVIEW"; payload: Article }
    | {
          type: "SEND_RIM_ARTICLE_TO_OVERVIEW"
          payload: { rimItem: Article; tireSizes: TyreFilter[]; selectedTireSize?: TyreFilter; configuratorImage?: string }
      }

    // default
    | { type: "VEHICLE_SET"; payload: Vehicle }
    | { type: "RESET_WHEELS_BUNDLE" }
    | { type: "CHANGE_ARTICLE_QUANTITY"; payload: { article: any; quantity: number } }
    | { type: "REGISTRATION_NOS_SET"; payload: string[] }
    | { type: "SELECTED_REGISTRATION_NO_SET"; payload: string }
    | { type: "MOUNTING_SWITCH_SET"; payload: boolean }

    // erp
    | { type: "SET_ERP_INFORMATIONS"; payload: ErpInformation[] }

    // history
    | { type: "HISTORY_DATA_LOADED" }
    | { type: "@@redux/INIT" }
    | { type: "KEY_VALUES_LOADING"; payload: string | undefined }
    | { type: "KEY_VALUES_FAILED" }
    | { type: "INIT_KEY_VALUES" }
    | { type: "KEY_VALUES_SAVING"; payload: HistoryData }
    | { type: "KEY_VALUES_LOADED"; payload?: Partial<MainState> | undefined }
    | { type: "KEY_VALUES_LOADING_STEPS"; payload: { currentStep: number; totalSteps: number } }
    | { type: "SET_SHOW_VEHICLE_SELECTION" }
    | { type: "MODEL_DETAILS_ERROR" }
    | { type: "INIT_WHEELS_LOGIC"; payload: { isTyresAvailable: boolean } }

function changeQuantity(article: Article, quantity: number): BundleActionTypes {
    return { type: "CHANGE_ARTICLE_QUANTITY", payload: { article, quantity } }
}

function setVehicle(vehicle: Vehicle): BundleActionTypes {
    return { type: "VEHICLE_SET", payload: vehicle }
}

function setSelectedRegistrationNo(registrationNo: string) {
    return { type: "SELECTED_REGISTRATION_NO_SET", payload: registrationNo }
}

function setMountingSwitchValue(enabled: boolean) {
    return { type: "MOUNTING_SWITCH_SET", payload: enabled }
}

function setErpInformations(erpInfo: ErpInformation[]): BundleActionTypes {
    return { type: "SET_ERP_INFORMATIONS", payload: erpInfo }
}

function changeStep(
    payload: { step: WheelSelectionSteps; disableNextSteps?: boolean; params?: Record<string, string>; quantity?: number },
    fromSelectButton: boolean
): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch) => {
        dispatch({ type: "CHANGE_STEP", payload })
        if (!fromSelectButton) {
            dispatch(saveData({ activeStep: payload.step }))
        }
    }
}

const resetStore = (): BundleActionTypes => ({ type: "@@redux/INIT" })

export function saveData(data: HistoryData): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch, getState) => {
        const { vehicle, keyValueData, savedWorktaskId } = getState().manager
        // TODO: send selectedCarParkId when saveData is invoked
        const { selectedCarParkId } = getState().wheelsList.base

        if (!vehicle) {
            return
        }

        const saveKey = generateKey(vehicle?.tecDocTypeId, savedWorktaskId)
        const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer

        const dataToSave: HistoryData = { ...keyValueData, ...data, selectedCarParkId }

        // check if request are the same or not
        container
            .action("saveKeyValue")(saveKey, JSON.stringify(dataToSave))
            .then((_) => {
                dispatch({ type: "KEY_VALUES_SAVING", payload: dataToSave })
            })
    }
}

export function loadData(
    showAvailability: boolean,
    vehicle?: Vehicle,
    workTaskId?: string,
    telesalesCustomerNo?: string,
    isTyresAvailable?: boolean
): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch, getState) => {
        const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
        container
            .action("loadKeyValue")(generateKey(vehicle?.tecDocTypeId, workTaskId))
            .then(
                async (response) => {
                    dispatch({ type: "KEY_VALUES_LOADING", payload: workTaskId })

                    const newData = await JSON.parse(response)

                    const newReducerData = await initHistoryNav(
                        getState(),
                        (currentStep, totalSteps) => {
                            dispatch({ type: "KEY_VALUES_LOADING_STEPS", payload: { currentStep, totalSteps } })
                        },
                        vehicle,
                        newData,
                        workTaskId,
                        showAvailability,
                        telesalesCustomerNo,
                        isTyresAvailable
                    )

                    dispatch({ type: "KEY_VALUES_LOADED", payload: newReducerData })
                },
                (_) => {
                    dispatch({ type: "KEY_VALUES_FAILED" })
                }
            )
            .catch((_) => dispatch({ type: "KEY_VALUES_FAILED" }))
    }
}

function loadModelDetails(vehicle: Vehicle): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch) => {
        if (!vehicle) {
            return
        }

        dispatch({ type: "SET_SHOW_VEHICLE_SELECTION" })

        if (vehicle.registrationNo) {
            dispatch({ type: "SELECTED_REGISTRATION_NO_SET", payload: vehicle.registrationNo })
        }

        const container = Container.getInstance<ShowCarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
        container
            .subscribe({ modelId: vehicle.tecDocTypeId })
            .load()
            .then(
                (response) => {
                    dispatch({
                        type: "REGISTRATION_NOS_SET",
                        payload: response?.modelDetails?.registrationNos
                            ? response.modelDetails?.registrationNos
                            : vehicle.registrationNo
                              ? [vehicle.registrationNo]
                              : [],
                    })
                },
                () => dispatch({ type: "MODEL_DETAILS_ERROR" })
            )
    }
}

export function initWheelsLogic(
    isHostettler: boolean | undefined,
    vehicle: Vehicle,
    isTyresAvailable: boolean
): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch) => {
        if (isHostettler) {
            dispatch(loadModelDetails(vehicle))
        }
        dispatch({ type: "INIT_WHEELS_LOGIC", payload: { isTyresAvailable } })
    }
}

export type IBundleActions = typeof BundleActions

export const BundleActions = {
    changeStep,
    changeQuantity,
    setVehicle,
    setSelectedRegistrationNo,
    setErpInformations,
    setMountingSwitchValue,
    resetStore,
    saveData,
    loadData,
    initWheelsLogic,
    loadModelDetails,
}
