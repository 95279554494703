import { useMemo } from "react"
import { CisVoucherType } from "@tm/models"
import { Box, Loader } from "@tm/components"
import { FindInvoicesResponse } from "../../../data/model"
import InvoiceHeader from "./InvoiceDetailsHeader"
import Totals from "../../_shared/Totals"
import StandardVoucherItemsTable from "../../_shared/voucher-items-table/StandardVoucherItemsTable"
import TexkatVoucherItemsTable from "../../_shared/voucher-items-table/TexkatVoucherItemsTable"
import { CisDisplayMode } from "../../../business/model"
import { useOrderDetails } from "../../../data/hooks/useOrderDetails"
import { ColumnStack } from "../../_shared/StyledComponents"
import { NoResultHint } from "../../_shared/NoResultHint"
import { ErrorMessage } from "../../_shared/ErrorMessage"

type Props = {
    voucherTypeId: CisVoucherType
    cisDisplayMode?: CisDisplayMode
    endOfList: boolean
    invoices: FindInvoicesResponse
    selectedInvoiceId: string
}

export default function InvoiceDetails({ endOfList, selectedInvoiceId, invoices, cisDisplayMode, voucherTypeId }: Props) {
    const selectedVoucher = useMemo(() => {
        return invoices.invoices.find((voucher) => voucher.invoiceId === selectedInvoiceId)
    }, [invoices.invoices, selectedInvoiceId])

    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && { voucherId: selectedVoucher.invoiceId, voucherTypeId }
    )

    // TODO: it would be better if FindInvoicesResponse includes the totalNet and totalGros
    const [totalNet, totalGross] = useMemo(() => {
        let net = 0
        let gross = 0

        orderDetails?.voucherItems?.forEach((item) => {
            if (item) {
                if (item.totalNet) {
                    net += item.totalNet
                }
                if (item.totalGross) {
                    gross += item.totalGross
                }
            }
        })

        return [net, gross]
    }, [orderDetails?.voucherItems])

    let content = null
    const selectedInvoce = invoices.invoices.find((invoice) => invoice.invoiceId === selectedInvoiceId)
    if (selectedInvoce) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (orderDetails?.voucherItems) {
            content = (
                <ColumnStack>
                    <InvoiceHeader invoice={selectedInvoce} voucherDocuments={orderDetails.voucherDocuments} />
                    {cisDisplayMode === "texkat" ? (
                        <TexkatVoucherItemsTable voucherItems={orderDetails.voucherItems} />
                    ) : (
                        <StandardVoucherItemsTable voucherItems={orderDetails.voucherItems} />
                    )}
                    <Totals totalGross={totalGross} totalNet={totalNet} currencyCode={invoices.currencyCode} />
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    } else if (endOfList) {
        content = <NoResultHint />
    }

    return <Box flex={1}>{content}</Box>
}
