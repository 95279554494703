import { Article, ArticleAttributes, DataSupplier, ProductGroup } from "@tm/models"
import { RimDetailsArticle } from "./model"

export function mapRimDetailsArticleWithArticleToGenerailItem(rimDetailsArticle: RimDetailsArticle): Partial<Article> {
    const attributes: ArticleAttributes[] =
        rimDetailsArticle.articleDetails?.attributes?.map((attribute) => ({
            topAttributes: [
                ...attribute.topAttributes,
                ...attribute.vehicleAttributes,
                ...attribute.partsListAttributes,
                ...attribute.articleAttributes,
            ],
            articleAttributes: [],
            partsListAttributes: [],
            vehicleAttributes: [],
        })) ?? []

    return {
        ...rimDetailsArticle.articleDetails,
        quantity: rimDetailsArticle.quantity,
        id: rimDetailsArticle.articleDetails?.id ? rimDetailsArticle.articleDetails.id.toString() : "",
        internalId: +(rimDetailsArticle?.articleDetails?.id ?? rimDetailsArticle.idRim ?? 0),
        productGroup: {
            id: rimDetailsArticle.productGroupId,
            name: rimDetailsArticle.productGroupName,
        } as ProductGroup,
        supplier: {
            id: rimDetailsArticle.supplierId,
            name: rimDetailsArticle.supplierName,
        } as DataSupplier,
        supplierArticleNo: rimDetailsArticle?.artNr ?? "",
        attributes,
    }
}

export function mapRimDetailsArticleWithoutArticleToGenerailItem(rimDetailsArticle: RimDetailsArticle): Partial<Article> {
    return {
        internalId: +(rimDetailsArticle?.articleDetails?.id ?? rimDetailsArticle.idRim ?? 0),
        attributes: [],
        description: rimDetailsArticle.artDescription,
        productGroup: {
            id: rimDetailsArticle.productGroupId,
            name: rimDetailsArticle.productGroupName,
        } as ProductGroup,
        supplier: {
            id: rimDetailsArticle.supplierId,
            name: rimDetailsArticle.supplierName,
        } as DataSupplier,
        quantity: rimDetailsArticle.quantity,
        supplierArticleNo: rimDetailsArticle.artNr,
        thumbnail: rimDetailsArticle.logoUrl,
        traderArticleNo: rimDetailsArticle.articleDetails?.traderArticleNo,
    }
}

export function mapArticleImage(image: any) {
    return {
        url: image.imageUrl,
        thumbnailUrl: image.thumbnailUrl,
        description: image.description,
    }
}
