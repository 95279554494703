import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { AttributeFiltersRequest, AttributeFiltersResponse } from "../model"

export async function getAttributeFilters(request: AttributeFiltersRequest) {
    const url = `${getBundleParams().articleListServiceUrlByQuery}/AttributeFiltersCrits`
    const authorization = getStoredAuthorization()
    const body = request
    const response = await ajax<AttributeFiltersResponse>({ method: "POST", url, body, authorization })

    if (!response) {
        throw new Error("No data")
    }

    return response
}
