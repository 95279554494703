import { Tag } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FC, useEffect, useState } from "react"
import { mapTooltipArrayToElement } from "../../data/helpers/tooltip-attribute"
import { ArticleAttributeItem } from "../../data/model/calculationContext"

type Props = {
    item: ArticleAttributeItem
}

const AttributesTag: FC<Props> = ({ item }) => {
    const { translateText } = useLocalization()
    const [tooltip, setTooltip] = useState<JSX.Element>()

    useEffect(() => {
        if (item.alternatives && item.alternatives.length) {
            const tooltipText: string[] = []
            tooltipText.push(`${translateText(item.isOptimized ? 1642 : 1643)}:`)

            item.alternatives.forEach((attr) => {
                const value = `${attr.value} ${attr.unit || ""}`.trim()
                tooltipText.push(`${attr.abbreviation}: ${value}`)
            })

            setTooltip(mapTooltipArrayToElement(tooltipText))
        }
    }, [item])

    return (
        <Tag
            layout="ghost"
            className="article__attribute"
            label={item.shortDescription}
            value={`${item.value} ${item.unit || ""}`.trim()}
            tooltip={tooltip}
        />
    )
}

export default AttributesTag
