import { useState, useEffect, useCallback, useMemo } from "react"
import { decodeUniqueId } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { ServiceBase, RepairTimeProvider, RegisteredModels, ConfigParams } from "@tm/models"
import { Container } from "@tm/nexus"
import { Icon, Button, Badge, styled, useTheme, buttonClasses } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { useShowNewArticleList, useAddToBasketModuleParameters } from "@tm/context-distribution"

type CostEstimationButtonProps = {
    size?: "small" | "medium" | "large" | "extralarge"
}

type Props<T extends ServiceBase> = CostEstimationButtonProps & {
    service: T
    provider: RepairTimeProvider
    workTaskId: string
    onAddToBasket(service: T): void
    onRemoveRepairTime(id: string, service: T): void
}

const AddToCostEstimationButton = styled(Button)(({ theme }) => ({
    [`& .${buttonClasses.startIcon}`]: {
        color: theme.overwrites?.components?.addToCostEstimation?.icon?.color,
    },
    "&:hover": {
        [`& .${buttonClasses.startIcon}`]: {
            color: theme.overwrites?.components?.addToCostEstimation?.icon?.hover?.color,
        },
    },
    ...(theme.name === "create-business" && {
        ".MuiButton-startIcon>svg.icon-add-repair-times": {
            width: 36,
        },
        ".MuiButton-startIcon>svg.icon-add-repair-times-international": {
            width: 36,
        },
    }),
}))

export default function AddToCostEstimation<T extends ServiceBase>({
    service,
    provider,
    workTaskId,
    onAddToBasket,
    onRemoveRepairTime,
    size,
}: Props<T>) {
    const { translateText, languageId } = useLocalization()
    const showNewArticleList = useShowNewArticleList()
    const theme = useTheme()
    const backgroundColor = theme.overwrites?.components?.addToCostEstimation?.button?.backgroundColor
    const { showCostEstimationButton } = useAddToBasketModuleParameters()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [basketId, setBasketId] = useState<string>()

    useEffect(() => {
        setBasketId(undefined)

        const subscription = Container.getInstance(RegisteredModels.Basket_HasRepairTimes).subscribe({
            workTaskId: decodeUniqueId(workTaskId),
            repairTimesProvider: provider,
            repairTimeProviderWorkId: service.repairTimeNo,
        })

        const unregister = subscription.addListener(
            "loaded",
            (response) => {
                setBasketId(response.id)
            },
            () => {
                setBasketId(undefined)
            }
        )

        subscription.load()

        return unregister
    }, [provider, workTaskId, service.repairTimeNo])

    const handleClick = useCallback(() => {
        if (basketId) {
            onRemoveRepairTime(basketId, service)
        } else {
            onAddToBasket(service)
        }
    }, [basketId, service, onRemoveRepairTime, onAddToBasket])

    const icon = useMemo(() => {
        if (basketId) {
            return "delete"
        }
        if (!showNewArticleList) {
            return languageId === "1" ? "voucher-kva" : "voucher-kva-international"
        }
        return "add-repair-times"
    }, [basketId, languageId, showNewArticleList])

    const color = useMemo(() => {
        if (backgroundColor) {
            return "highlight"
        }
        return basketId || deleteButtonColorError ? "error" : "highlight"
    }, [backgroundColor, basketId, deleteButtonColorError])

    if (!showCostEstimationButton) {
        return null
    }

    return (
        <>
            {!!basketId && <Badge size="medium" badgeContent={<Icon name="check" size="12px" />} sx={{ left: "55px", top: "2px" }} />}
            <AddToCostEstimationButton
                sx={{ width: "5em" }}
                variant="bordered"
                color={color}
                title={basketId ? translateText(624) : translateText(916)}
                onClick={handleClick}
                startIcon={<Icon name={icon} width={30} />}
                size={size}
            />
        </>
    )
}
