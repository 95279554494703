import { Article, ArticleInfoType, OE, OrderItem } from "@tm/models"
import { PropsWithChildren, useMemo } from "react"
import { createContext, useContextSelector } from "use-context-selector"
import { useLocation } from "react-router"
import { Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useGetPartToReplaceErpAlternatives } from "./hooks/useGetPartToReplaceErpAlternatives"
import { useGetPartToReplaceData } from "./hooks/useGetPartToReplaceData"

export type PartToReplaceData = {
    partToReplaceId?: string
    orderItem?: OrderItem
    article?: Article
    oeArticle?: OE.OePart
    articleInfoType?: ArticleInfoType
}

export type PartsAlternativesModuleState =
    | {
          partToReplaceErpAlternatives?: Array<Article>
          partToReplaceData?: PartToReplaceData
          partToReplaceId?: string
          isLoading?: boolean
          isLoaded?: boolean
          partToReplaceQuantity?: number
      }
    | undefined

/**
 * Shall not be used directly outside of this file.
 * Use export `usePartsAlternativesModuleState` instead.
 */
const PartsAlternativesModuleStateContext = createContext<PartsAlternativesModuleState>(undefined)

export function PartsAlternativesModuleStateProvider(props: PropsWithChildren<unknown>) {
    const { translateText } = useLocalization()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const partToReplaceId = searchParams.get("partToReplaceId") ?? undefined
    const partToReplaceQuantity = searchParams.get("partToReplaceQuantity") ?? undefined
    const { partToReplaceData, isLoaded: isLoadedArticle } = useGetPartToReplaceData(partToReplaceId)
    const {
        partToReplaceErpAlternatives,
        isLoading,
        isLoaded: isLoadedErpArticles,
    } = useGetPartToReplaceErpAlternatives(isLoadedArticle, partToReplaceData)

    const partsAlternativesModuleState: PartsAlternativesModuleState = useMemo(
        () => ({
            partToReplaceId,
            partToReplaceData,
            partToReplaceErpAlternatives,
            isLoading,
            isLoaded: partToReplaceData?.article ? isLoadedArticle && isLoadedErpArticles : isLoadedArticle,
            partToReplaceQuantity: partToReplaceQuantity ? Number(partToReplaceQuantity) : undefined,
        }),
        [partToReplaceId, partToReplaceData, partToReplaceErpAlternatives, isLoading, isLoadedArticle, isLoadedErpArticles]
    )

    const renderNoData = () => {
        return (
            <Stack justifyContent="center" height="100%" alignItems="center">
                <Icon width="6em" height="6em" name="no-results" />
                <Typography variant="h2">{translateText(13877)}</Typography>
                <Typography variant="body2">{translateText(13897)}</Typography>
            </Stack>
        )
    }

    return (
        <PartsAlternativesModuleStateContext.Provider value={partsAlternativesModuleState}>
            {!!partToReplaceId && partToReplaceData?.articleInfoType === ArticleInfoType.Undefined ? renderNoData() : props?.children}
        </PartsAlternativesModuleStateContext.Provider>
    )
}

export function usePartsAlternativesModuleState<TContext extends PartsAlternativesModuleState>(): TContext
export function usePartsAlternativesModuleState<TContext extends PartsAlternativesModuleState, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function usePartsAlternativesModuleState<TContext extends PartsAlternativesModuleState, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(PartsAlternativesModuleStateContext, (context) => {
        if (!selector) {
            return context
        }

        return selector(context as TContext)
    }) as TContext | TReturnType
}
