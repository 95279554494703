import { ajaxAuth } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { ErpBundleParams } from "@tm/models"
import { GetErpInformationBasketRequest, GetErpInformationBasketResponse, UpdateOrderRequest, UpdateOrderResponse } from "../model"

function getServiceUrl() {
    return Morpheus.getParams<ErpBundleParams>("erp").serviceUrl
}

export function getBasketErpInformation(body: GetErpInformationBasketRequest) {
    const url = `${getServiceUrl()}/GetBasketErpInfos`
    return ajaxAuth<GetErpInformationBasketResponse>({ url, body, method: "POST" }, undefined, undefined, true)
}

export function updateOrder(body: UpdateOrderRequest) {
    const url = `${getServiceUrl()}/UpdateOrder`
    return ajaxAuth<UpdateOrderResponse>({ url, body, method: "POST" }, undefined, undefined, true)
}
