import { useCallback, useMemo } from "react"
import { Box, Checkbox, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { TyreFilter } from "@tm/models"
import { useTyresV2 } from "@tm/context-distribution"
import { ClickableText, ListItemMaxWidth } from "../../../../../parts/src/components/ListV2/components/Filters/FiltersStyledComponents"
import IconWithTooltip from "../../../../../parts/src/components/_shared/icon-with-tooltip/IconWithTooltip"
import { isAvailabilityFilter } from "../business/helpers/isAvailabilityFilter"
import { AvailabilityFilter } from "./FiltersArea"

type FilterProptype = TyreFilter | AvailabilityFilter

export function FilterItem({
    filter,
    checked,
    onChange,
    onInfoInlineClick,
}: {
    filter: FilterProptype
    checked: boolean
    onChange: (filter: TyreFilter | AvailabilityFilter) => void
    onInfoInlineClick?: (value?: string) => void
}) {
    const { translateText } = useLocalization()
    const tyresV2 = useTyresV2()

    const handleChange = useCallback(() => {
        onChange(filter)
    }, [onChange, filter])

    const handleInfoInlineClick = useCallback(() => {
        if (!isAvailabilityFilter(filter)) {
            onInfoInlineClick?.(filter.valueKey)
        }
    }, [onInfoInlineClick, filter])

    const text = useMemo(() => {
        if (isAvailabilityFilter(filter)) {
            return <strong>{filter.name}</strong>
        }
        const { value, info, articleCount } = filter

        return (
            <>
                <strong>{value}</strong> {info ?? ""}
                {tyresV2 && articleCount ? ` (${articleCount})` : ""}
            </>
        )
    }, [tyresV2, filter])

    const plainText = useMemo(() => {
        if (isAvailabilityFilter(filter)) {
            return filter.name
        }
        return `${filter.value} ${filter.info ?? ""}`
    }, [filter])

    return (
        <ListItemMaxWidth>
            <Checkbox sx={{ p: "0 8px 0 4px" }} size="small" checked={checked} onChange={handleChange} />
            <ClickableText onClick={handleChange} title={`${plainText}\n(${translateText(936)})`}>
                {text}
            </ClickableText>
            <StyledBox>
                {onInfoInlineClick && <IconWithTooltip variant="info" iconProps={{ size: "16px" }} onClick={handleInfoInlineClick} />}
            </StyledBox>
        </ListItemMaxWidth>
    )
}

const StyledBox = styled(Box)({
    marginLeft: "auto",
    marginRight: "1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
})
