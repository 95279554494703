import { useEffect, useMemo } from "react"
import { PartsBundleParams, ShowConfigResponse, Vehicle, VehicleType } from "@tm/models"
import { usePureArticleLists } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"

import { useModelDetailsWithoutCache } from "../../data/hooks/modelDetailsWithoutCache"
import { enhanceVehicle } from "../../data/mapper"
import type { DetailsProps } from "./component"
import DetailsComponent from "./Details"
import { useRegNoAndType } from "./hooks/useRegNoAndType"
import { useOrderedPartsByVehicle } from "./hooks/useOrderedPartsByVehicle"
import { mapOrderedPartsToArticleIdentifiers } from "./business/actions"

type Props = DetailsProps & {
    vehicleType: VehicleType
    vehicle?: Vehicle
    workTaskId?: string
    isWorkTaskVehicle?: boolean
    availableVehicleSearches?: ShowConfigResponse
}

export function DetailsByVehicle(props: Props) {
    const { vehicle, location, availableVehicleSearches, vehicleType, actions } = props
    const { regNo, regNoType } = useRegNoAndType(
        vehicle?.vehicleType ?? vehicleType,
        vehicle,
        location.search,
        availableVehicleSearches?.defaultVehicleLookup
    )
    const { modelDetails, error, loadModelDetails, clearConflict } = useModelDetailsWithoutCache(vehicle, vehicleType, regNoType, regNo)
    const orderedParts = useOrderedPartsByVehicle(vehicle?.id)
    const { setRequest } = usePureArticleLists()
    const { listV2 } = Morpheus.getParams<PartsBundleParams>("parts")

    useEffect(() => {
        listV2 &&
            setRequest("ordered-articles", {
                articleIdentifier: orderedParts && vehicle && mapOrderedPartsToArticleIdentifiers(orderedParts, vehicle.tecDocTypeId),
            })

        actions.loadArticleList(orderedParts, vehicle?.tecDocTypeId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderedParts, vehicle?.tecDocTypeId])

    const enhancedVehicle = useMemo(() => {
        if (!vehicle || !modelDetails) {
            return vehicle
        }

        return enhanceVehicle(vehicle, modelDetails.modelDetails?.registrationNoDetails, regNoType || vehicle.registrationTypeId)
    }, [vehicle, modelDetails, regNoType])

    return (
        <DetailsComponent
            {...props}
            vehicle={enhancedVehicle}
            modelDetails={modelDetails}
            isGsiVehicle
            error={error}
            orderedParts={orderedParts}
            reload={loadModelDetails}
            clearVehicleConflict={clearConflict}
        />
    )
}
