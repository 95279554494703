import { Box } from "@tm/components"
import { useDisplayListV2, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Article, EReplaceButtonBundle, OE, channel } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { clone, closeModalOrDrawer, useUnsubscribe } from "@tm/utils"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { ICalculationItem } from "../../../../data/model"
import { calculationSelector } from "../../../../selectors"
import { MainActions } from "../../../main/business"
import { createRequestArticleListPayload } from "../../business/helper"
import { ArticleItem } from "./components"
import { ArticleListV2 } from "./components/ArticleListV2"

export default function ArticleList() {
    const { userContext } = useUser()
    const { translateText } = useLocalization()
    const subscriptionReplacePart = useUnsubscribe()

    const { items } = useSelector(calculationSelector)
    const { erpArticles } = useSelector(calculationSelector)
    const displayListV2 = useDisplayListV2()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const isV2 = displayListV2 && !isWm
    const actions = useActions(MainActions, "updateTotals", "replaceArticle", "setErpArticles", "selectArticle", "changeOeArticleQuantity")

    const requestAlternatives = useCallback(
        (item: ICalculationItem) => {
            subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce("PARTS/REPLACE_PART", ({ part, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.DMGDat) {
                    return false
                }

                if (!part) {
                    return
                }

                closeModalOrDrawer(isNewList)

                actions.replaceArticle(item, part)

                let filteredErpArticles = clone(erpArticles)

                if ((part as OE.OePart).number) {
                    if (item.selectedPart) {
                        filteredErpArticles = filteredErpArticles
                            .filter((x) => x.dataSupplierArticleNumber !== item.selectedPart!.supplierArticleNo)
                            .distinct()
                    }

                    actions.updateTotals(translateText, filteredErpArticles)
                } else {
                    if (isV2) {
                        // eslint-disable-next-line no-param-reassign
                        part.quantity = item.oeArticle.quantityValue
                    }
                    channel("GLOBAL").subscribeOnce("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
                        const newErpArticle = erpInfo.filter((x) => x.dataSupplierArticleNumber === (part as Article).supplierArticleNo)

                        if (item.isSelected) {
                            actions.updateTotals(translateText, [...erpArticles, ...newErpArticle].distinct())
                        } else {
                            actions.setErpArticles([...erpArticles, ...newErpArticle])
                        }
                    })
                }
            })

            channel("WORKTASK").publish("PARTS/REQUEST_LIST", createRequestArticleListPayload(item, userContext))
        },
        [userContext, actions, erpArticles, translateText]
    )
    return (
        <Box className="tk-parts">
            <Box className="list">
                <Box padding={(theme) => theme.spacing(0.5, 1)} className="article-list calculation-items">
                    {!isV2 ? (
                        items?.map((item: ICalculationItem) => (
                            <ArticleItem key={item.uniqueId} item={item} requestAlternatives={requestAlternatives} />
                        ))
                    ) : (
                        <ArticleListV2 requestAlternatives={requestAlternatives} />
                    )}
                </Box>
            </Box>
        </Box>
    )
}
