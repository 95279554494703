import { Component } from "react"
import { withUserContext, WithUserContextProps, WithWorkTaskProps, withWorkTask } from "@tm/context-distribution"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { connectComponent, importMicro, WithMicroProps } from "@tm/morpheus"
import { bindSpecialReactMethods, RouteComponentProps, withRouter } from "@tm/utils"
import { Article, channel, IMicros } from "@tm/models"
import { Text } from "@tm/controls"
import ArticleImageComponent from "../_shared/ArticleImageComponent"
import ArticleCell from "../_shared/article-cell"
import { IActions, Actions, PartState } from "./business"
import ArticleNumbers, { StyledStack } from "../_shared/ArticleNumbers"

type PartRoute = {
    supplierId: string
    supplierArticleNo: string
    productGroupId: string
    initialQuantity: string
}

type Props = LocalizationProps &
    RouteComponentProps<PartRoute> &
    WithMicroProps<IMicros> &
    WithWorkTaskProps &
    WithUserContextProps & {
        state: PartState
        actions: IActions

        hideBasketButton?: boolean
        hideWarehouseTable?: boolean
        showAddToBasket?: boolean
        showErpInfos?: boolean
    }

@importMicro
class PartComponent extends Component<Props> {
    private unsubscribe: Function

    unsubscribeArticleQuantityChange?: () => void

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        this.unsubscribe = props.actions.init()
    }

    componentWillUnmount() {
        this.unsubscribe()
        this.unsubscribeArticleQuantityChange?.()
    }

    componentDidMount() {
        const { params } = this.props.match
        const supplierId = parseInt(params.supplierId)
        const productGroupId = parseInt(params.productGroupId)
        const initialQuantity = parseInt(params.initialQuantity)

        if (supplierId && productGroupId && initialQuantity && !isNaN(supplierId) && !isNaN(productGroupId) && !isNaN(initialQuantity)) {
            this.props.actions.load(supplierId, decodeURIComponent(params.supplierArticleNo), productGroupId, initialQuantity)
        }

        this.unsubscribeArticleQuantityChange = this.subscribeToArticleQuantityChange(this.props)
    }

    componentDidUpdate(prevProps: Props) {
        const currentParams = this.props.match.params
        const lastParams = prevProps.match.params

        if (
            this.props.state.vehicle != prevProps.state.vehicle ||
            currentParams.productGroupId != lastParams.productGroupId ||
            currentParams.supplierId != lastParams.supplierId ||
            currentParams.supplierArticleNo != lastParams.supplierArticleNo ||
            currentParams.initialQuantity != lastParams.initialQuantity
        ) {
            const supplierArticleNo = decodeURIComponent(currentParams.supplierArticleNo)
            const supplierId = parseInt(currentParams.supplierId)
            const productGroupId = parseInt(currentParams.productGroupId)
            const initialQuantity = parseInt(currentParams.initialQuantity)
            if (supplierId && productGroupId && initialQuantity && !isNaN(supplierId) && !isNaN(productGroupId) && !isNaN(initialQuantity)) {
                this.props.actions.load(supplierId, supplierArticleNo, productGroupId, initialQuantity)
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.unsubscribeArticleQuantityChange?.()
        this.unsubscribeArticleQuantityChange = this.subscribeToArticleQuantityChange(nextProps)
    }

    subscribeToArticleQuantityChange(props: Props) {
        return channel("WORKTASK").subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", ({ article, quantity }) =>
            props.actions.changeQuantity(article!, quantity)
        )
    }

    renderThumbnail(part: Article) {
        return (
            <ArticleCell bemModifier="thumbnail">
                <ArticleImageComponent
                    thumbnailClassName="article__thumbnail"
                    thumbnailUrl={part.thumbnail}
                    internalArticleId={part.internalId}
                    vehicleLinkageId={part.vehicleLinkageId}
                    enableImageViewer={part.existImage}
                    title={`${part.supplier.name} | ${part.supplierArticleNo} | ${part.description}`}
                />
            </ArticleCell>
        )
    }

    renderSupplier(part: Article) {
        return (
            <ArticleCell bemModifier="supplier">
                <Text className="supplier__name" title={part.supplier.name}>
                    {part.supplier.name}
                </Text>
            </ArticleCell>
        )
    }

    renderNumbers(part: Article) {
        return (
            <ArticleCell bemModifier="numbers">
                <StyledStack direction={this.props.userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
                    {!this.props.userContext?.parameter.hideDealerPartNumber && (
                        <ArticleNumbers wholesalerArticleNumber={part.traderArticleNo || " "} />
                    )}
                    <ArticleNumbers supplierArticleNumber={part.supplierArticleNo || " "} />
                </StyledStack>
            </ArticleCell>
        )
    }

    renderDescription(part: Article) {
        let content = part!.description || ""

        // show product group name before article description
        if (part.productGroup.name) {
            // if article description is not empty add a separator
            if (content) {
                content = ` | ${content}`
            }

            content = part.productGroup.name + content
        }

        return (
            <ArticleCell bemModifier="description">
                <Text className="article__description">{content}</Text>
            </ArticleCell>
        )
    }

    getTmaInfos(): { vehicleId?: string; customerId?: string; foundBySearchTerm?: string } {
        const { customer, vehicle } = this.props.state
        const queryParams = new URLSearchParams(this.props.location.search)

        let vehicleId: string | undefined
        let foundBySearchTerm: string | undefined

        if (queryParams.has("foundBySearchTerm")) {
            foundBySearchTerm = queryParams.get("foundBySearchTerm") || undefined
        } else if (queryParams.has("vehicleLinkageId")) {
            vehicleId = vehicle ? vehicle.id : undefined
        }

        return {
            vehicleId,
            customerId: customer ? customer.id : undefined,
            foundBySearchTerm,
        }
    }

    renderAddToBasket(part: Article) {
        if (!part || !part.showAddToBasket) {
            return
        }

        const { vehicleId, customerId, foundBySearchTerm } = this.getTmaInfos()

        const micro = this.props.renderMicro!("basket", "add-to-basket", {
            data: [part],
            vehicleId,
            customerId,
            foundBySearchTerm,
            hideBasketButton: this.props.hideBasketButton,
            erpType: "details",
        })

        if (micro) {
            return <ArticleCell bemModifier="actions">{micro}</ArticleCell>
        }
    }

    renderErpInformation(part: Article) {
        const { vehicleId, foundBySearchTerm } = this.getTmaInfos()

        const micro = this.props.renderMicro!("erp", "erp-info-details", {
            data: part,
            foundBySearchTerm,
            foundByVehicleId: vehicleId,
            hideWarehouseTable: this.props.hideWarehouseTable,
        })

        if (micro) {
            return <ArticleCell bemModifier="erp-information">{micro}</ArticleCell>
        }
    }

    render() {
        const { part } = this.props.state

        if (!part) {
            return null
        }

        return (
            <div className="bdl-parts article-list part">
                <div className="panel article-list__item">
                    {this.renderThumbnail(part)}
                    {this.renderSupplier(part)}
                    {this.renderNumbers(part)}
                    {this.renderDescription(part)}
                    <div>
                        {this.props.showAddToBasket && this.renderAddToBasket(part)}
                        {this.props.showErpInfos && this.renderErpInformation(part)}
                    </div>
                </div>
            </div>
        )
    }
}

export default connectComponent(Actions, withLocalization(withRouter(withWorkTask(withUserContext(PartComponent)))))
