import { channel, SuccessArticleErpInfo } from "@tm/models"
import { useEffect, useMemo } from "react"
import { useArticlesContext, useTradeReferenceContext } from ".."
import { useArticleListFeaturesDisable } from "../../ArticleListConfiguration/ArticleListFeaturesDisable"
import { mapWholesalerArticleToArticle } from "../../helpers"
import { OffersArticleData, PureListArticleData, WholesalerArticleData } from "../../models"
import { useListParamsContext } from "../ListParams"
import { ErpInfosContext, ErpInfosProviderProps } from "./ErpInfosContext"
import { useErpInfos } from "./useErpInfos"

export function MultiErpInfosProvider({ isEnabled, erpInfos, setErpInfos, children }: ErpInfosProviderProps) {
    const { startParams } = useListParamsContext()
    const { articles, wholesalerArticles, articleGroups } = useArticlesContext<PureListArticleData | OffersArticleData | WholesalerArticleData>()
    const { tradeReferences, tradeReferenceNumbersLoaded } = useTradeReferenceContext()
    const disableErpChannel = useArticleListFeaturesDisable((x) => x.disableErpChannel)

    const erpArticles = useMemo(() => {
        const wholesalerToArticle = wholesalerArticles?.map((x) => mapWholesalerArticleToArticle(x)) ?? []
        const addWholesalerArticles = articleGroups.flatMap((group) => {
            if (group.type === "WholesalerArticle") {
                const { articles: moreWholesalerArticles } = group
                return moreWholesalerArticles?.map((x) => mapWholesalerArticleToArticle(x)) ?? []
            }
            return []
        })

        return [...articles, ...wholesalerToArticle, ...addWholesalerArticles]
    }, [articles, wholesalerArticles, articleGroups])

    const erpInfosData = useErpInfos({
        articles: erpArticles,
        isEnabled: isEnabled && tradeReferenceNumbersLoaded,
        startParams,
        erpInfos,
        setErpInfos,
        tradeReferences,
    })

    useEffect(() => {
        if (erpInfosData.isReady && !disableErpChannel && erpInfosData.erpInfos.every((x) => x.state === "success")) {
            channel("GLOBAL").publish(
                "ERP/ERP_INFORMATION_LOADED",
                erpInfosData.erpInfos.map((x) => (x as SuccessArticleErpInfo).response)
            )
        }
    }, [erpInfosData.erpInfos])

    return <ErpInfosContext.Provider value={erpInfosData}>{children}</ErpInfosContext.Provider>
}
