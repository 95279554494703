import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FC } from "react"
import { getComponentStyles } from "../styles"

type Props = {
    loading: boolean
    noResult: boolean
    showLoadArticlesButton: boolean
    pageIndex: number
    handleLoadUntilFirstAvailableArticle: () => void
    loadNextArticles: () => void
}

const classNames = getComponentStyles()

export const LoadArticlesButton: FC<Props> = (props) => {
    const { loading, noResult, showLoadArticlesButton, pageIndex, handleLoadUntilFirstAvailableArticle, loadNextArticles } = props
    const { translateText } = useLocalization()

    if (loading || !showLoadArticlesButton) {
        return null
    }

    return (
        <div className={classNames.loadArticlesButtonWrapper}>
            <Button
                className={classNames.loadArticlesButton}
                onClick={noResult && pageIndex <= 10 ? handleLoadUntilFirstAvailableArticle : loadNextArticles}
                skin="highlight"
                size="xl"
            >
                {translateText(12430)}
            </Button>
        </div>
    )
}
