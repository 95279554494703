import { Box, styled, Loader, Typography, Icon } from "@tm/components"
import { useCountryCodeToLicensePlate, useSelectedVehicleLookup, useUser, useWorkTask } from "@tm/context-distribution"
import { DateField, LicensePlateField, SuggestionTextField, TextField, VinField, VinFieldProps } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType, SystemType } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { getSystemTypeFromUserContext, getVehicleRegistrationNoLabelTextIds, useAvailableVehicleSearchOptions } from "@tm/utils"
import { useSelector } from "react-redux"
import { useCallback, useEffect, useMemo } from "react"
import { useGetVins } from "../../../data/hooks/useGetVins"
import { managerSelector, summarySelector } from "../../../selectors"
import { getBundleParams } from "../../../utils"
import { MainActions } from "../../main/business"
import { validateFields } from "../business/helper"
import { Formatters } from "../../../data/helpers"

export default function VehicleInfoFields() {
    const user = useUser()
    const workTask = useWorkTask()
    const { translateText } = useLocalization()
    const { vehicle } = useSelector(managerSelector)
    const { fields, showVehicleModelDetailsLoading, datVinSearchIsValid, vinSearchInformalMessage, checkVinLoading, isGermanOrAustrianVehicle } =
        useSelector(summarySelector)
    const modelState = validateFields(fields, translateText, isGermanOrAustrianVehicle)
    const actions = useActions(MainActions, "checkVehicleDatInfo", "updateFieldValue", "validateVin", "initVehicle")
    const { enableDATVinSearch, enabledVinSearchHOTFIX } = getBundleParams()
    const { datPricePerRequest } = Morpheus.getParams("vehicle")
    const { selectedVehicleLookup } = useSelectedVehicleLookup()
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const vehicleVinSuggestions: string[] = useGetVins("", vehicle!.tecDocTypeId) ?? []

    if (!vehicle) {
        return null
    }
    const { plateCode: shortCountryCode, countryCode } = useCountryCodeToLicensePlate(vehicle.countryCode)

    useEffect(() => {
        if (
            !showVehicleModelDetailsLoading &&
            enabledVinSearchHOTFIX &&
            enableDATVinSearch &&
            getSystemTypeFromUserContext(user?.userContext) === SystemType.Next &&
            vehicle?.registrationTypeId === RegistrationNoType.DatVin &&
            !datVinSearchIsValid
        ) {
            actions.checkVehicleDatInfo(workTask?.workTask?.vehicle || vehicle, translateText)
        }
    }, [vehicle, showVehicleModelDetailsLoading, datVinSearchIsValid])

    useEffect(() => {
        actions.initVehicle(countryCode)
    }, [vehicle])

    const defaultVehicleLookup = useMemo(
        () => availableVehicleSearches.configuredVehicleLookups.filter((lookup) => lookup.isDefault),
        [availableVehicleSearches]
    )

    const renderRegNoField = () => {
        if (!isGermanOrAustrianVehicle) {
            return
        }

        const registarionTypeId =
            vehicle?.registrationTypeId === RegistrationNoType.TopmotiveVin ? RegistrationNoType.Kba : vehicle?.registrationTypeId
        let itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(registarionTypeId || RegistrationNoType.Kba)

        if (!itemLabelTextIds?.label) {
            itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(selectedVehicleLookup.lookupTypeId ?? defaultVehicleLookup[0].lookupTypeId)
        }

        if (!itemLabelTextIds?.label) {
            return
        }

        return (
            <Col>
                <SuggestionTextField
                    floatingLabel
                    label={translateText(itemLabelTextIds?.label)}
                    value={fields.registrationNo}
                    getSuggestionData={() => fields.registrationNos}
                    onChangeConfirm={(value) => actions.updateFieldValue("registrationNo", value)}
                    path={["registrationNo"]}
                    modelState={modelState}
                    formatter={Formatters.uppercase}
                />
            </Col>
        )
    }

    const handleGetVinSuggestions = useCallback(
        (query: string): Array<string> => {
            return vehicleVinSuggestions?.length && query?.length
                ? vehicleVinSuggestions?.filter((vin) => vin.startsWith(query))
                : vehicleVinSuggestions
        },
        [vehicle, fields.vin, vehicleVinSuggestions]
    )

    const renderVehicleFields = () => {
        const vinFieldProps: VinFieldProps = {
            floatingLabel: true,
            label: translateText(101),
            coloredBorder: true,
            value: fields.vin,
            onChangeConfirm: (value) => actions.updateFieldValue("vin", value),
            onChange: (value) => actions.updateFieldValue("vin", value),
            getSuggestionData: handleGetVinSuggestions,
            path: ["vin"],
            modelState,
            ...(getSystemTypeFromUserContext(user?.userContext) === SystemType.Next &&
                enabledVinSearchHOTFIX &&
                enableDATVinSearch && {
                    datVinSearchIsValid,
                    vinSearchOptional: true,
                    datPricePerRequest,
                    vinSearchInformalMessage,
                    checkVinLoading,
                    translateText,
                    validateVin: () => actions.validateVin(workTask?.attachToWorkTask, translateText),
                }),
        }

        return (
            <>
                <FieldsArea>
                    {renderRegNoField()}
                    <Col>
                        <DateField
                            floatingLabel
                            label={translateText(124)}
                            value={fields.initialRegistration}
                            minDate={fields.minDate}
                            openToDate={fields.initialRegistration || fields.minDate}
                            onChange={(value) => actions.updateFieldValue("initialRegistration", value)}
                            path={["initialRegistration"]}
                            modelState={modelState}
                        />
                    </Col>
                    <Col sx={{ width: "15em" }}>
                        <VinField {...vinFieldProps} />
                    </Col>
                    <Col sx={{ width: "13em", "& > *": { width: "100%" } }}>
                        <LicensePlateField
                            size="l"
                            placeholder={translateText(21)}
                            value={fields.plateNumber}
                            shortCountryCode={shortCountryCode}
                            onChange={(value) => actions.updateFieldValue("plateNumber", value)}
                            maxLength={17}
                            path={["plateNumber"]}
                            modelState={modelState}
                            showErrorBorder
                        />
                    </Col>
                    <Col>
                        <TextField
                            floatingLabel
                            label={translateText(125)}
                            value={fields.mileage || undefined}
                            onChange={(value) => actions.updateFieldValue("mileage", value)}
                            pattern={/[\d.]*/}
                            formatter={(value) => value.replace(/[^\d]/, "").replace(",", "").replace(".", "")}
                        />
                    </Col>
                </FieldsArea>

                {!isGermanOrAustrianVehicle && (
                    <Typography fontWeight={600} sx={{ paddingLeft: ".5em" }}>
                        {translateText(13286)}
                    </Typography>
                )}
            </>
        )
    }

    return (
        <Box sx={{ borderTop: ".1em solid #e2e2e2" }}>
            <Title>
                <Icon name="car-front" size="2em" />
                <Typography fontSize="large" marginLeft={(theme) => theme.spacing(0.5)}>
                    {translateText(99)}
                </Typography>
            </Title>

            <Box>
                {showVehicleModelDetailsLoading && (
                    <>
                        <Loader size="small" />
                        <Typography>{translateText(12583)}</Typography>
                    </>
                )}

                {!showVehicleModelDetailsLoading && renderVehicleFields()}
            </Box>
        </Box>
    )
}

const Title = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(0.5),
    alignItems: "center",
}))

const FieldsArea = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    flexBasis: "100%",
}))

const Col = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0.5, 0, 0.5, 1),
    width: "12em",
    ".suggest__box .vin-search": {
        margin: "0 0 .5em 0",
    },
}))
