import { ajaxAuth, Required } from "@tm/utils"

import { DMS } from "@tm/models"
import { getServiceUrl } from "."

export type ImportVoucherHistoryRequest = {
    workTaskId: string
    parts: DMS.Part[]
}

export function importVoucherHistory(request: ImportVoucherHistoryRequest): Promise<void> {
    return ajaxAuth({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportVoucherHistory`,
        body: request,
    })
}

export type ImportVoucherRequest = DMS.Voucher & {
    controlIndicators?: Indicator[]
}

type Indicator = {
    type: number
    parameters: IndicatorParameter[]
}

type IndicatorParameter = {
    id: string
    value: string
}

export type ImportVoucherResponse = {
    worktaskId: string
}

export function importVoucher(request: ImportVoucherRequest) {
    return ajaxAuth<ImportVoucherResponse>({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportVoucher`,
        body: request,
    })
}

export type ExportVoucherRequest = {
    workTaskId: string
    referenceId?: string
}

export type ExportVoucherResponse = Required<DMS.Voucher, "workTaskId">

export type ExportVoucherErrorResponse = {
    message: string
    traceId: string
}

export function exportVoucher(request: ExportVoucherRequest) {
    return ajaxAuth<ExportVoucherResponse | ExportVoucherErrorResponse>({
        method: "POST",
        url: `${getServiceUrl()}/connect/ExportVoucher`,
        body: request,
    })
}

export type ExtendVoucherRequest = {
    workTaskId: string
    customer?: DMS.Customer
    vehicle?: DMS.Vehicle
    parts: DMS.Part[]
    repairTimes: DMS.RepairTime[]
}

export type ExtendVoucherResponse = {
    portalUrl: string
}

export function extendVoucher(request: ExtendVoucherRequest) {
    return ajaxAuth<ExtendVoucherResponse>({
        method: "POST",
        url: `${getServiceUrl()}/connect/ExtendVoucher`,
        body: request,
    })
}
