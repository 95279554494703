import { Loader, styled, Box, Icon, Typography } from "@tm/components"
import { Modal } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CarModel, VehicleShortInfo, VehicleType } from "@tm/models"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useShowModelsByVrcInfo } from "../../../../../data/hooks/useShowModelsByVrcInfo"
import { VrcInfo } from "../../../../../data/model/vrc-lookup"
import { VrmLookupErrorTextIds } from "../../../../../helpers"
import { CustomerVehicles } from "../../../../search/components/CustomerVehicles"
import ModelListSimple from "../../../../_shared/model-list-simple"
import NoResultDialog from "./no-result-dialog"

type Props = RouteComponentProps<{ workTaskId?: string }> & {
    vrcInfo: VrcInfo
    saving: boolean
    datVinRequestActive: boolean
    onClose(noResult?: boolean): void
    applyVehicleAndCustomer(model: CarModel, datVinErrorTextId?: VrmLookupErrorTextIds): void
    applyCustomerVehicle(vehicle: VehicleShortInfo): void
}

const ListWrapper = styled("div")({
    display: "flex",
    flexFlow: "column",
    width: "100%",
})

function VehicleRequest(props: Props) {
    const { translate } = useLocalization()
    const { applyVehicleAndCustomer, applyCustomerVehicle } = props
    const { data, isLoading } = useShowModelsByVrcInfo(props.vrcInfo, props.datVinRequestActive)

    if (props.saving || isLoading) {
        return <Loader />
    }

    const noResult = !data?.models?.length && !data?.customerVehicles?.length

    if (!noResult) {
        const { models, customerVehicles } = data

        if (models.length === 1 && customerVehicles?.length === 0) {
            applyVehicleAndCustomer(data?.models[0] as CarModel)
            return null
        }
        if (models.length === 0 && customerVehicles?.length === 1) {
            applyCustomerVehicle(data?.customerVehicles[0])
            return null
        }

        if (models?.length || customerVehicles?.length) {
            return (
                <Modal onClose={() => props.onClose()}>
                    <ListWrapper>
                        <CustomerVehicles
                            vehicleType={VehicleType.PassengerCar}
                            vehicles={customerVehicles}
                            onApply={props.applyCustomerVehicle}
                            onSelect={props.applyCustomerVehicle}
                        />
                        <Typography variant="h2" mb={1} className="models__headline">
                            {translate(12501)}:
                        </Typography>
                        <Box mb={2}>
                            <Typography>
                                {models?.length ? (
                                    translate(12502)
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon name="bulb" />
                                        <span style={{ marginLeft: "0.25em" }}>{translate(12503)}</span>
                                    </div>
                                )}
                            </Typography>
                        </Box>
                        <ModelListSimple
                            models={models}
                            vehicleType={VehicleType.PassengerCar}
                            showManufacturerThumbnail
                            showModelThumbnail
                            onApply={props.applyVehicleAndCustomer}
                        />
                    </ListWrapper>
                </Modal>
            )
        }
    }

    return <NoResultDialog onClose={props.onClose} />
}

export default withRouter(VehicleRequest)
