import { useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ErpInformation, ErpPrice, ErpSystemConfig } from "@tm/models"
import { mapArticleToAddCatalogPartListRequest, useDefaultOrderWarehouse, useDefaultErpSystem, showWarehouseDataMissingError } from "@tm/utils"
import { Suspense } from "react"
import { style } from "typestyle"
import { useBasketParts } from "@bundles/basket"

type Props = {
    graduatedPrices: Array<ErpPrice>
    article: Article
    erpInfo?: ErpInformation
    overwriteErpSystemConfig?: ErpSystemConfig
}

function GraduatedPricesDropdownContent({ graduatedPrices, article, erpInfo, overwriteErpSystemConfig }: Props) {
    const { translateText, currency } = useLocalization()
    const workTask = useWorkTask()?.workTask
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { erpSystemConfig } = useDefaultErpSystem(overwriteErpSystemConfig)
    const { warehouseData, refetchWarehouseData } = useDefaultOrderWarehouse(
        { telesalesCustomerNo, distributorId: erpSystemConfig?.id },
        enableServiceCalls
    )
    const { addCatalogPartList } = useBasketParts()

    const handleGraduatedPricesClick = (quantity: number) => {
        if (!warehouseData || warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            refetchWarehouseData()
            return
        }

        if (workTask) {
            const request = mapArticleToAddCatalogPartListRequest(
                [{ ...article, quantity }],
                workTask.id,
                workTask.vehicle?.id,
                workTask.customer?.id,
                undefined,
                undefined,
                erpInfo ? [erpInfo] : undefined,
                warehouseData?.defaultWarehouse,
                undefined,
                erpSystemConfig?.id,
                erpSystemConfig?.description
            )
            addCatalogPartList(request)
        }
    }

    return (
        <div className="price__graduated__tooltip">
            <div className="price__graduated__tooltip__ab">
                {graduatedPrices.map((price, idx) => {
                    const { priceUnit } = price

                    let onClick
                    let className
                    if (priceUnit) {
                        className = "price__graduated__tooltip--clickable"
                        onClick = () => {
                            handleGraduatedPricesClick(priceUnit)
                        }
                    }

                    return (
                        <div key={idx} className={className} onClick={onClick}>
                            <Text>{translateText(707)}</Text>
                        </div>
                    )
                })}
            </div>
            <div className="price__graduated__tooltip__quantity">
                {graduatedPrices.map((price, idx) => {
                    const { priceUnit } = price

                    let onClick
                    let className
                    if (priceUnit) {
                        className = "price__graduated__tooltip--clickable"
                        onClick = () => {
                            handleGraduatedPricesClick(priceUnit)
                        }
                    }

                    return (
                        <div key={idx} className={className} onClick={onClick}>
                            <Text modifiers="highlight">{priceUnit}</Text>
                        </div>
                    )
                })}
            </div>
            <div className="price__graduated__tooltip__price">
                {graduatedPrices.map((price, idx) => {
                    const { priceUnit, value, currencySymbol } = price

                    let onClick
                    let className
                    if (priceUnit) {
                        className = "price__graduated__tooltip--clickable"
                        onClick = () => {
                            handleGraduatedPricesClick(priceUnit)
                        }
                    }

                    return (
                        <div key={idx} className={className} onClick={onClick}>
                            <Text className={style({ whiteSpace: "nowrap" })}>{currency(value, currencySymbol)}</Text>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <GraduatedPricesDropdownContent {...props} />
        </Suspense>
    )
}
