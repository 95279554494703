import { List } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { FC } from "react"
import { IArticle } from "../../../data/models"
import { DOPState } from "../business/model"
import { DopCollapsible } from "../_shared"
import ArticleItem from "../_shared/articleItem"

const selector = initSelector((s: DOPState) => s.model.articles)

// eslint-disable-next-line no-empty-pattern
const Articles: FC = () => {
    const [articles] = useSelector(selector)
    const { translateText } = useLocalization()

    if (!articles?.length) {
        return null
    }

    const renderArticle = (x: IArticle) => <ArticleItem item={x} />

    return (
        <DopCollapsible title={translateText(90)}>
            <List items={articles} view={renderArticle} />
        </DopCollapsible>
    )
}

export default Articles
