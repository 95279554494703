import { Box, styled } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CarModelDetails, VehicleType } from "@tm/models"
import { rem } from "csx"
import { FC } from "react"
import { constructionYearToString } from "../../../../data/helpers"
import { DictionaryItem } from "../../../../model"
import Dictionary from "../../dictionary"

type Props = {
    modelDetails?: CarModelDetails
}

const VehicleDetails: FC<Props> = ({ modelDetails }) => {
    const { translateText } = useLocalization()
    const vehicle = useWorkTask()?.workTask?.vehicle

    if (!modelDetails) {
        return null
    }

    const getPowerHp = (): DictionaryItem | undefined => {
        let item

        if (modelDetails.enginePowerHp) {
            item = `${modelDetails.enginePowerHp}`
        }

        if (!item) {
            return
        }
        return { key: `${translateText(94)} (${translateText(330)})`, value: item }
    }

    const getPowerKw = (): DictionaryItem | undefined => {
        let item

        if (modelDetails.enginePowerKw) {
            item = `${modelDetails.enginePowerKw}`
        }

        if (!item) {
            return
        }
        return { key: `${translateText(94)} (${translateText(329)})`, value: item }
    }

    const items: Array<DictionaryItem> = [{ key: translateText(105), value: (modelDetails.engineCodes || []).join(", ") }]

    if (modelDetails.vehicleType && parseInt(modelDetails.vehicleType) == VehicleType.CommercialVehicle && getPowerHp && getPowerKw) {
        const kw = getPowerKw()
        const hp = getPowerHp()

        if (kw && hp) {
            items.push(kw, hp)
        }
    } else {
        items.push({ key: translateText(20), value: `${modelDetails.enginePowerKw}/${modelDetails.enginePowerHp}` })
    }

    items.push(
        { key: translateText(306), value: modelDetails.engineType || translateText(1779) },
        { key: translateText(123), value: modelDetails.id },
        { key: translateText(303), value: modelDetails.bodyType || translateText(1779) },
        { key: translateText(298), value: modelDetails.driveType || translateText(1779) },
        { key: translateText(128), value: modelDetails.engineCapacityCcm || translateText(1779) },
        { key: translateText(305), value: modelDetails.cylinderCount || translateText(1779) },
        { key: translateText(304), value: modelDetails.valvesPerCylinder || translateText(1779) },
        {
            key: translateText(127),
            value: `${constructionYearToString(modelDetails.constructionYearFrom)} - ${constructionYearToString(modelDetails.constructionYearTo)}`,
        },
        { key: translateText(1491), value: vehicle?.mileAge || translateText(1779) },
        { key: translateText(101), value: vehicle?.vin || translateText(1779) },
        { key: translateText(12592), value: vehicle?.registrationNo || translateText(1779) }
    )

    return (
        <StyledWrapper>
            <PanelSection>
                <Dictionary items={items} />
            </PanelSection>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(0, rem(0.5)),
}))

export default VehicleDetails
