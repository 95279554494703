import { Component } from "react"
import { classes, registerOutsideClick } from "@tm/utils"
import { Button } from "@tm/controls"

type Props = {
    className?: string
    renderContent?: JSX.Element
}

export type CustomTooltipPosition = "bottom-right" | "bottom-left" | "middle-right" | "middle-left" | "top-right" | "top-left"

class CustomTooltip extends Component<Props> {
    tooltipRef: HTMLDivElement | null

    textRef: HTMLDivElement | null

    contentRef: HTMLDivElement | null

    position?: CustomTooltipPosition

    clientY: number

    clientX: number

    maximumHeight?: number

    static ID = "eurotax_parts_tooltip"

    static hide() {
        const el = document.getElementById(CustomTooltip.ID) // TODO find another solutions for this
        if (el) {
            el.style.display = "none"
        }
    }

    componentDidUpdate() {
        setTimeout(() => this.updateTooltipPostion(), 1)
    }

    updateTooltipPostion() {
        const contentWidth = this.contentRef?.clientWidth ?? 0
        const contentHeight = this.contentRef?.clientHeight ?? 0

        if (!this.position || !this.tooltipRef) {
            return
        }

        switch (this.position) {
            case "bottom-left": {
                this.tooltipRef.style.left = `${this.clientX - contentWidth / 2}px`
                this.tooltipRef.style.top = `${this.clientY}px`
                break
            }
            case "bottom-right": {
                this.tooltipRef.style.left = `${this.clientX + contentWidth / 2}px`
                this.tooltipRef.style.top = `${this.clientY}px`
                break
            }
            case "middle-left": {
                this.tooltipRef.style.left = `${this.clientX - contentWidth / 2}px`
                this.tooltipRef.style.top = `${this.clientY - contentHeight / 2}px`
                break
            }
            case "middle-right": {
                this.tooltipRef.style.left = `${this.clientX + contentWidth / 2}px`
                this.tooltipRef.style.top = `${this.clientY - contentHeight / 2}px`
                break
            }
            case "top-left": {
                this.tooltipRef.style.left = `${this.clientX - contentWidth / 2}px`
                this.tooltipRef.style.top = `${this.clientY - contentHeight}px`
                break
            }
            case "top-right": {
                this.tooltipRef.style.left = `${this.clientX + contentWidth / 2}px`
                this.tooltipRef.style.top = `${this.clientY - contentHeight}px`
                break
            }
            default: {
                this.tooltipRef.style.left = `${this.clientX + 10}px`
                this.tooltipRef.style.top = `${this.clientY + 10}px`
                break
            }
        }
    }

    show(ev: MouseEvent, position?: CustomTooltipPosition, text?: string, maxHeight?: number) {
        const { renderContent } = this.props
        this.maximumHeight = maxHeight
        const parentSvgDimensions = document.getElementById("svg-wrapper")?.getBoundingClientRect()
        const maxBottomPoint = (parentSvgDimensions && parentSvgDimensions.y + parentSvgDimensions.height - 10) || 0

        if (this.tooltipRef) {
            if (renderContent) {
                !renderContent.props.removeOutsideClick && registerOutsideClick(this.tooltipRef, () => this.hide())
            } else if (this.textRef) {
                this.textRef.innerHTML = text ?? ""
            }

            this.position = position
            this.tooltipRef.style.left = `${ev.clientX + 10}px`

            if (ev.clientY > maxBottomPoint) {
                this.tooltipRef.style.top = `${ev.clientY - 40}px`
            } else {
                this.tooltipRef.style.top = `${ev.clientY + 10}px`
            }

            this.clientY = ev.clientY
            this.clientX = ev.clientX
            this.tooltipRef.style.display = "block"
        }
    }

    hide() {
        if (this.tooltipRef) {
            this.tooltipRef.style.display = "none"
        }
    }

    render() {
        const { className, renderContent } = this.props
        const hideXBtn = !renderContent || renderContent.props.hideXBtn
        const renderTooltipContent = renderContent && {
            ...renderContent,
            props: { ...renderContent!.props, closeTooltip: () => this.hide(), maximumHeight: this.maximumHeight },
        } // send close callback to parts container

        return (
            <div
                id={renderContent && CustomTooltip.ID}
                className={classes("tooltip tooltip--primary", className)}
                style={{ position: "fixed", display: "none", zIndex: 2 }}
                ref={(ref) => {
                    if (ref) {
                        this.tooltipRef = ref
                    }
                }}
            >
                <div className="tooltip__wrapper tooltip__wrapper--bottom is-visible">
                    {/* <div className="tooltip__arrow"></div> */}
                    <div className="tooltip__content" ref={(ref) => (this.contentRef = ref)} style={{ position: "relative" }}>
                        {!renderContent && <div ref={(ref) => (this.textRef = ref)} className="text text--m" />}

                        {renderContent != undefined && (
                            <>
                                {!hideXBtn && (
                                    <div style={{ position: "absolute", top: 0, right: 0 }}>
                                        <Button layout={["ghost"]} icon="close" onClick={() => this.hide()} />
                                    </div>
                                )}
                                {renderTooltipContent}
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomTooltip
