import { UsedCriteria } from "../../../../data/repositories/wheels-loadTiresArticles/model"
import { TiresFiltersResponse } from "../../../../data/repositories/wheels-loadTiresFilters/model"

export function filterUsedCriteria(filters: TiresFiltersResponse, usedCriteria: UsedCriteria | undefined) {
    if (!usedCriteria || !filters) {
        return undefined
    }

    const availableLoadIndexes = filters.loadIndex?.map((x) => x.query) ?? []
    const availableSpeedIndexes = filters.speedIndex?.map((x) => x.query) ?? []

    const adjustedLoadIndex = usedCriteria.loadIndex?.filter((l) => availableLoadIndexes.includes(l.query))
    const adjustedSpeedIndex = usedCriteria.speedIndex?.filter((s) => availableSpeedIndexes.includes(s.query))

    return {
        loadIndex: adjustedLoadIndex,
        speedIndex: adjustedSpeedIndex,
    }
}
