import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Box, styled, Typography, Card, CardContent, Grid, TextField, DatePicker } from "@tm/components"
import { ButtonKeyDefinition, isValidDate } from "@tm/utils"
import { OfferDetails } from "../../../data/model"

const EmptyWrapper = styled(Box)({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
})

const EmptyCover = styled("div")(() => ({
    position: "absolute",
    backgroundColor: "#fff",
    opacity: "0.8",
    width: "100%",
    height: "100%",
}))

type Props = {
    details: OfferDetails
    disabled: boolean
    hasWorktask: boolean
    onUpdateReferenceNumber(referenceNumber?: string): void
    onUpdateMechanic(mechanic?: string): void
    onUpdateClientAdvisor(clientAdvisor?: string): void
    onUpdateStartDate(startDate?: Date): void
    onUpdateEndDate(endDate?: Date): void
    onUpdateDueDate(dueDate?: Date): void
}

export default function DetailsComponent(props: Props) {
    const { details, disabled, hasWorktask } = props
    const { translateText, language } = useLocalization()
    const [referenceNumber, setReferenceNumber] = useState<string>()
    const [mechanic, setMechanic] = useState<string>()
    const [clientAdvisor, setClientAdvisor] = useState<string>()
    const [startDate, setStartDate] = useState<Date | undefined>()
    const [endDate, setEndDate] = useState<Date | undefined>()
    const [dueDate, setDueDate] = useState<Date | undefined>()

    useEffect(() => {
        if (details) {
            setClientAdvisor(details.clientAdvisor)
            setDueDate(details.dueDate)
            setReferenceNumber(details.referenceNumber)
            setEndDate(details.endDate)
            setMechanic(details.mechanic)
            setStartDate(details.startDate)
        }
    }, [details])

    function handleReferenceNumberChange(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === ButtonKeyDefinition.Enter) {
            props.onUpdateReferenceNumber(referenceNumber)
        }
    }

    function handleClientAdvisorChange(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === ButtonKeyDefinition.Enter) {
            props.onUpdateClientAdvisor(clientAdvisor)
        }
    }

    function handleMechanicChange(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === ButtonKeyDefinition.Enter) {
            props.onUpdateMechanic(mechanic)
        }
    }

    function handleStartDateChangeKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === ButtonKeyDefinition.Enter && isValidDate(startDate)) {
            props.onUpdateStartDate(startDate)
        }
    }

    function handleEndDateChangeKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === ButtonKeyDefinition.Enter && isValidDate(endDate)) {
            props.onUpdateEndDate(endDate)
        }
    }

    function handleDueDateChangeKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === ButtonKeyDefinition.Enter && isValidDate(dueDate)) {
            props.onUpdateDueDate(dueDate)
        }
    }

    function handleStartDateBlur() {
        if (isValidDate(startDate)) {
            props.onUpdateStartDate(startDate)
        }
    }

    function handleEndDateBlur() {
        if (isValidDate(endDate)) {
            props.onUpdateEndDate(endDate)
        }
    }

    function handleDueDateBlur() {
        if (isValidDate(dueDate)) {
            props.onUpdateDueDate(dueDate)
        }
    }

    return (
        <Card sx={{ position: "relative", overflow: "initial" }}>
            <CardContent>
                {!hasWorktask && (
                    <EmptyWrapper>
                        <Typography sx={{ zIndex: 10 }} variant="body1">
                            {translateText(13141)}
                        </Typography>
                        <EmptyCover />
                    </EmptyWrapper>
                )}
                <Typography variant="h2" mb={0.5}>
                    {translateText(43)}
                </Typography>
                <Grid container spacing={0.5} mb={1} columns={6} flex={1} flexWrap="wrap">
                    <Grid item sm={1}>
                        <TextField
                            disabled={disabled}
                            label={translateText(97)}
                            size="large"
                            variant="filled"
                            inputProps={{ maxLength: 100 }}
                            fullWidth
                            value={referenceNumber}
                            onKeyUp={handleReferenceNumberChange}
                            onBlur={() => props.onUpdateReferenceNumber(referenceNumber)}
                            onChange={(e) => setReferenceNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <TextField
                            disabled={disabled}
                            label={translateText(34)}
                            size="large"
                            variant="filled"
                            inputProps={{ maxLength: 100 }}
                            fullWidth
                            value={mechanic}
                            onKeyUp={handleMechanicChange}
                            onBlur={() => props.onUpdateMechanic(mechanic)}
                            onChange={(e) => setMechanic(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={1}>
                        <DatePicker
                            textfieldProps={{
                                sx: { width: "100%" },
                                onBlur: handleStartDateBlur,
                                onKeyUp: (e) => handleStartDateChangeKeyUp(e),
                            }}
                            localisation={{ locale: language, region: null }}
                            onAccept={(e) => props.onUpdateStartDate(e ?? undefined)}
                            onChange={(e) => setStartDate(e ?? undefined)}
                            value={startDate || null}
                            size="large"
                            label={`${translateText(343)} ${translateText(344)}`}
                        />
                    </Grid>
                    <Grid item sm={1}>
                        <DatePicker
                            textfieldProps={{
                                sx: { width: "100%" },
                                onBlur: handleEndDateBlur,
                                onKeyUp: (e) => handleEndDateChangeKeyUp(e),
                            }}
                            localisation={{ locale: language, region: null }}
                            onAccept={(e) => props.onUpdateEndDate(e ?? undefined)}
                            onChange={(e) => setEndDate(e ?? undefined)}
                            value={endDate || null}
                            size="large"
                            label={`${translateText(343)} ${translateText(95)}`}
                        />
                    </Grid>
                    <Grid item sm={1}>
                        <DatePicker
                            textfieldProps={{
                                sx: { width: "100%" },
                                onBlur: handleDueDateBlur,
                                onKeyUp: (e) => handleDueDateChangeKeyUp(e),
                            }}
                            localisation={{ locale: language, region: null }}
                            onAccept={(e) => props.onUpdateDueDate(e ?? undefined)}
                            onChange={(e) => setDueDate(e ?? undefined)}
                            value={dueDate || null}
                            size="large"
                            label={`${translateText(64)} / ${translateText(65)}`}
                        />
                    </Grid>
                    <Grid item sm={1} />
                    <Grid item sm={2}>
                        <TextField
                            disabled={disabled}
                            label={`${translateText(342)} / ${translateText(93)}`}
                            size="large"
                            variant="filled"
                            inputProps={{ maxLength: 100 }}
                            value={clientAdvisor}
                            fullWidth
                            onKeyUp={handleClientAdvisorChange}
                            onBlur={() => props.onUpdateClientAdvisor(clientAdvisor)}
                            onChange={(e) => setClientAdvisor(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
