import { useRef, useMemo, useEffect } from "react"
import { QRCodeSVG } from "qrcode.react"
import { Button, Loader, Text } from "@tm/controls"
import { SharePopover } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { getComponentStyles } from "./styles"
import { useStorageUris } from "../../../../../data/hooks/fast-up"
import type { VrcUploadedFile } from "../../../component"
import { TransferInfoResponse } from "../../../../../data/repositories/fast-up"

type Props = {
    transferInfo: TransferInfoResponse
    onFileUpload(file: VrcUploadedFile): void
    fastUpTransferType: string
}

const qrCodeSize = 200

export default function FastUp(props: Props) {
    const classNames = getComponentStyles()

    const { transferInfo } = props
    const { translateText, language } = useLocalization()
    const { storageUris, isLoading } = useStorageUris(transferInfo.transferId)
    const canvasWrapperRef = useRef<HTMLDivElement>(null)

    const hasStarted = useMemo(() => {
        return storageUris?.some((uri) => uri.endsWith(".start"))
    }, [storageUris])

    useEffect(() => {
        const isFinished = storageUris?.some((uri) => uri.endsWith(".finish"))
        const fileUrl = storageUris?.find((uri) => !uri.endsWith(".finish") && !uri.endsWith(".start"))

        if (isFinished && fileUrl) {
            props.onFileUpload({ url: fileUrl, fastUpId: transferInfo.transferId, isImage: !fileUrl.endsWith(".pdf"), rotation: 0 })
        }
    }, [storageUris])

    if (!transferInfo.uploadUri) {
        return <Loader />
    }

    const uploadUri = `${transferInfo.uploadUri}&transferType=${props.fastUpTransferType}&lang=${language}`
    const message = `${translateText(12783)}\n\n${encodeURI(uploadUri)}`
    const messageTitle = translateText(12617)

    const handleQrButtonClick = () => {
        const svg = canvasWrapperRef.current?.querySelector("svg")
        if (svg) {
            const asText = new XMLSerializer().serializeToString(svg).replace(`height="${qrCodeSize}"`, "").replace(`width="${qrCodeSize}"`, "")
            const blob = new Blob([asText], { type: "image/svg+xml" })
            const url = URL.createObjectURL(blob)
            const win = window.open(url)
            if (win) {
                win.onload = () => URL.revokeObjectURL(url)
            }
        }
    }

    return (
        <div className={classNames.main}>
            <Text modifiers="block">{translateText(12680)}</Text>
            {hasStarted ? (
                <>
                    <div className={classNames.loaderWrapper}>
                        <Text className={classNames.loaderUploadText} size="l">
                            {translateText(12681)}
                        </Text>
                        <Loader />
                    </div>
                    <div>{/* placeholder so that justifyContent: "space-between" works */}</div>
                </>
            ) : (
                <>
                    <div className={classNames.canvasWrapper} ref={canvasWrapperRef}>
                        <QRCodeSVG value={uploadUri} size={qrCodeSize} />
                    </div>
                    <div className={classNames.toolbarWrapper}>
                        <div>
                            <Button className={classNames.button} onClick={handleQrButtonClick}>
                                {translateText(12682)}
                            </Button>
                        </div>
                        <div>
                            <SharePopover
                                title={translateText(12786)}
                                link={encodeURI(uploadUri)}
                                buttonProps={{ size: "extralarge", variant: "outlined" }}
                            >
                                <SharePopover.ShareButtonWrapper>
                                    <SharePopover.WhatsAppButton message={message} />
                                    <SharePopover.EmailButton subject={messageTitle} body={message} />
                                </SharePopover.ShareButtonWrapper>
                            </SharePopover>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
