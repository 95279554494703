import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { ArticleQuantities, WholesalerArticle, WholesalerArticleDto } from "@tm/models"
import { useArticlesContext } from "../Articles/ArticlesContext"
import { useWholesalerArticleBasketQuantities } from "./useBasketQuantities"

export const WholesalerBasketQuantitiesContext = createContext<WholesalerArticleBasketQuantities | undefined>(undefined)

type WholesalerArticleBasketQuantities = {
    basketQuantities: ArticleQuantities<WholesalerArticleDto>[]
    updateBasketQuantities: (articles: WholesalerArticle[]) => Promise<void>
}

export function WholesalerBasketQuantitiesProvider({ isEnabled, children }: PropsWithChildren<{ isEnabled: boolean }>) {
    const { wholesalerArticles } = useArticlesContext()
    const articleBasketQuantities = useWholesalerArticleBasketQuantities(wholesalerArticles ?? [], isEnabled)

    return <WholesalerBasketQuantitiesContext.Provider value={articleBasketQuantities}>{children}</WholesalerBasketQuantitiesContext.Provider>
}

export function useWholesalerBasketQuantitiesContext(): WholesalerArticleBasketQuantities | undefined {
    const context = useContext(WholesalerBasketQuantitiesContext)
    // return ensureContextAvailability("WholesalerBasketQuantitiesContext", context)
    return context
}
