import { useLocalization } from "@tm/localization"
import { renderRoute, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { GlobalNavigationItem } from "@tm/components"
import { matchPath, useLocation } from "react-router"
import { ECounterType } from "@tm/models"

type Props = RouteComponentProps & {
    orderRoute: string
}

function ShowAllOrdersButtonComponent(props: Props) {
    const { translateText } = useLocalization()
    const { pathname, search } = useLocation()
    const { match, orderRoute } = props

    const handleOrderTabClick = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.MyAccount)
        props.history.push(renderRoute(orderRoute, match.params))
    }

    // const isSelected = match.url === orderRoute

    const isActive = matchPath(pathname + search, { path: orderRoute })

    return (
        <GlobalNavigationItem
            className="central-ordering"
            active={!!isActive}
            onIconClick={handleOrderTabClick}
            name="orders"
            componentType="icon"
            tooltip={translateText(1991)}
        />
    )
}

export default withRouter(ShowAllOrdersButtonComponent)
