import { Stack, Typography } from "@tm/components"
import { usePureArticleLists, useUser, useWorkTaskId } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import {
    Article,
    ArticleActionButton,
    ArticleActionIcon,
    ArticleErpInfo,
    ArticleListType,
    ConfigParams,
    EArticleModificationState,
    ECounterType,
    ErpIconType,
    ModificationState,
    Note,
    NoteTypes,
    SystemType,
} from "@tm/models"
import Morpheus from "@tm/morpheus"
import { TmaHelper, createQueryString, renderRoute } from "@tm/utils"
import { useCallback, useMemo } from "react"
import { useRouteMatch } from "react-router"
import { sortBy } from "lodash"
import { printArticleDetails } from "../../../../../data/repositories"
import { getBundleParams } from "../../../../../utils"
import { createPrintArticleDetailsRequest } from "../../../../_shared/helper"
import { ArticleFeedbackConfiguration } from "../../../ArticleListConfiguration/useArticleFeedbackConfiguration"
import { useVehicle } from "../../useVehicle"
import { useHandleClickDetails } from "../useHandleClickDetails"
import { useSpecialIcons } from "./useSpecialIcons"
import { ShowFeedbackForArticleType } from "../../../models"

const ALTERNATIVE_ARTICLES_LIST_ID = "details-erp-alternative-articles"

export type UseArticleActionsProps = {
    article: Article
    articleErpInfo?: ArticleErpInfo
    feedbackConfiguration: ArticleFeedbackConfiguration
    isNotesEnabled?: boolean
    listType: ArticleListType
    notes?: Note[]
    repairTimesUrl?: string
    showDocumentsInline?: boolean
    onClickDetails: ReturnType<typeof useHandleClickDetails>
    toggleExpand: (expand?: boolean) => void
    toggleNotes?: () => void
    hideAlternatives?: boolean
    disableDeleteButton?: boolean
    onDelete?: (article: Article) => void
    displayDetailsInMenuArea?: boolean
}
/**
 * @returns a tuple with the action and if it will be shown in the ...-menu
 */
export function useArticleActions(props: UseArticleActionsProps) {
    const {
        article,
        articleErpInfo,
        listType,
        feedbackConfiguration,
        isNotesEnabled,
        notes,
        repairTimesUrl,
        showDocumentsInline,
        onClickDetails,
        toggleExpand,
        toggleNotes,
        hideAlternatives,
        disableDeleteButton,
        onDelete,
        displayDetailsInMenuArea,
    } = props
    const { translateText } = useLocalization()
    const vehicle = useVehicle()
    const { userContext } = useUser()
    const routeMatch = useRouteMatch()
    const { showArticleDocumentsAsButton, hideAccessoryList, hideArticleRepairTimes, hideAdditionalArticleAlternatives, imsIcon } = getBundleParams()
    const workTaskId = useWorkTaskId()
    const { reset: resetPureArticleListState } = usePureArticleLists()

    // const { addMessage: addSnackbarMessage } = useSnackbar()

    const articleErpInfoResponse = articleErpInfo?.state === "success" ? articleErpInfo.response : undefined

    const isRedesignSystem = useMemo(() => userContext.system.systemType === SystemType.Redesign, [userContext])

    const handleClickDetails = useCallback(() => onClickDetails(), [onClickDetails])

    const onClickDocuments = useMemo(() => {
        if (article.existPdf && !showDocumentsInline && !showArticleDocumentsAsButton) {
            return () => {
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDocuments)
                onClickDetails("documents")
            }
        }
    }, [showDocumentsInline, article, showArticleDocumentsAsButton, onClickDetails])

    const onClickRepairTimes = useMemo(() => {
        if (!hideArticleRepairTimes && vehicle?.id && repairTimesUrl) {
            return () => {
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleLabourtimes)
                Morpheus.showView("1", repairTimesUrl)
            }
        }
    }, [repairTimesUrl, hideArticleRepairTimes, vehicle?.id])

    const onFeedback = useMemo(() => {
        const showArticleFeedback = () => {
            if (feedbackConfiguration.isEnabled && feedbackConfiguration.feedbackPath) {
                switch (feedbackConfiguration.showFeedbackForArticleType) {
                    case ShowFeedbackForArticleType.OnlyTraderArticles:
                        return !!article.traderArticleNo
                    case ShowFeedbackForArticleType.ArticlesWithoutTraderReference:
                        return !article.traderArticleNo
                    default:
                        return true
                }
            }
            return false
        }

        if (showArticleFeedback()) {
            return () => {
                if (feedbackConfiguration.openInArticleDetails) {
                    onClickDetails("mvc-article-feedback")
                } else {
                    const url =
                        renderRoute(feedbackConfiguration.feedbackPath, {
                            ...routeMatch.params,
                            workTaskId,
                            productGroupId: article.productGroup.id,
                            supplierId: article.supplier.id,
                            supplierName: article.supplier.name,
                            supplierArticleNo: article.supplierArticleNo,
                            itemId: article.id,
                            quantityValue: article.quantity || 1,
                            productGroupName: article.productGroup.name,
                            traderArticleNo: article.traderArticleNo || undefined,
                        }) + createQueryString({ articleDescription: article.description })
                    Morpheus.showView("1", url)
                }
            }
        }
    }, [article, feedbackConfiguration, routeMatch])

    const onClickSearchAlternatives = useMemo(() => {
        if (
            listType === "universal" ||
            Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp ||
            getBundleParams().hideAdditionalArticleAlternatives ||
            isRedesignSystem
        ) {
            return
        }
        return () => {
            // TODO: This Url shouldn't be hardoded. It should be taken from the ui config props
            const articleAlternativesRoute = "/:workTaskId/modal^/part-alternatives/list/alternatives"
            const { supplierArticleNo, productGroup } = article

            if (!articleAlternativesRoute) {
                return
            }

            const queryParams = new URLSearchParams()
            queryParams.set("query", supplierArticleNo)
            queryParams.set("productGroupIds", productGroup.id.toString())

            if (article.supplier?.manufacturerId) {
                queryParams.set("oeManufacturerIds", article.supplier.manufacturerId.toString())
            }

            const url = `${renderRoute(articleAlternativesRoute, { ...routeMatch.params })}?${queryParams.toString()}`

            Morpheus.showView("1", url)
        }
    }, [article, isRedesignSystem, routeMatch.params])

    const onClickPrint = useMemo(() => {
        return async () => {
            // const { removeMessage } = addSnackbarMessage(
            //     <Stack>
            //         <Box>{translateText(13510)}</Box>
            //         <LinearProgress />
            //     </Stack>,
            //     "info",
            //     <Icon name="print" color="#fff" />,
            //     false,
            //     false
            // )
            await printArticleDetails(
                createPrintArticleDetailsRequest(
                    article,
                    vehicle?.tecDocTypeId,
                    vehicle?.vehicleType,
                    vehicle?.registrationNo,
                    vehicle?.registrationTypeId
                ),
                translateText
            )
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticlePrint)
            // removeMessage()
        }
    }, [article, /* addSnackbarMessage, */ translateText, vehicle])

    const onClickPartsList = useMemo(() => {
        if (!article.isPartsListAvailable || isRedesignSystem) {
            return
        }
        return () => onClickDetails("partslist")
    }, [article.isPartsListAvailable, onClickDetails, isRedesignSystem])

    const onClickAccessoryList = useMemo(() => {
        if (hideAccessoryList || !article.isAccessoryListAvailable || isRedesignSystem) {
            return
        }
        return () => onClickDetails("accessories")
    }, [article.isAccessoryListAvailable, onClickDetails, isRedesignSystem])

    const onClickErpAlternatives = useMemo(() => {
        if (
            (!Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp && !articleErpInfoResponse?.hasAlternatives) ||
            isRedesignSystem ||
            hideAdditionalArticleAlternatives ||
            hideAlternatives
        ) {
            return
        }

        return () => {
            resetPureArticleListState(ALTERNATIVE_ARTICLES_LIST_ID)
            onClickDetails("alternative-articles")
        }
    }, [
        articleErpInfoResponse?.hasAlternatives,
        onClickDetails,
        isRedesignSystem,
        hideAdditionalArticleAlternatives,
        hideAlternatives,
        resetPureArticleListState,
    ])

    const onClickImsIcon = useMemo(() => {
        const { references, additionalReferenceArticleInformation, articleModificationState, referencedArticleModification } = article
        const hasModificationState =
            articleModificationState === EArticleModificationState.ArticleAdded ||
            articleModificationState === EArticleModificationState.VehicleLinkageAdded
        const hasAdditionalInfo = !!additionalReferenceArticleInformation?.textInformation.length
        const hasReferences = references?.some((x) => x && x.modificationState === ModificationState.Added)
        const hasReferencedArticleModification = referencedArticleModification?.isAddedReferencedArticle || false

        const hasAdditionalInformation = hasModificationState || hasAdditionalInfo || hasReferences || hasReferencedArticleModification

        if (hasAdditionalInformation) {
            return toggleExpand.bind(undefined, true)
        }
    }, [article, toggleExpand])

    const specialIcons = useSpecialIcons({ articleErpInfo, onClickDetails })

    const onClickDelete = useCallback(() => {
        if (disableDeleteButton) {
            return
        }
        onDelete?.(article)
    }, [article, disableDeleteButton, onDelete])

    const articleActions = useMemo(() => {
        const { showArticleRepairTimesAsButton, showArticlePrintAsButton } = getBundleParams()

        const informationArea: (ArticleActionButton | ArticleActionIcon)[] = []
        const erpArea: ArticleActionIcon[] = []
        const imageArea: ArticleActionIcon[] = []
        const menuArea: ArticleActionButton[] = []

        // Details
        if (displayDetailsInMenuArea) {
            menuArea.push({
                iconType: ErpIconType.Details,
                displayType: "BUTTON",
                sort: 1,
                icon: "details",
                text: translateText(43),
                handler: handleClickDetails,
            })
        } else {
            informationArea.push({
                iconType: ErpIconType.Details,
                displayType: "BUTTON",
                sort: 1,
                icon: "details",
                text: translateText(43),
                handler: handleClickDetails,
            })
        }

        // Delete
        if (!onClickDelete) {
            informationArea.push({
                iconType: ErpIconType.Details,
                displayType: "ICON",
                sort: 2,
                icon: "delete",
                tooltip: translateText(624),
                handler: onClickDelete,
            })
        }

        // Print
        if (onClickPrint) {
            const printAction = {
                iconType: ErpIconType.Print,
                sort: 3,
                icon: "print",
                text: translateText(49),
                tooltip: translateText(49),
                handler: onClickPrint,
            }
            if (showArticlePrintAsButton) {
                informationArea.push({
                    ...printAction,
                    displayType: "ICON",
                })
            } else {
                menuArea.push({
                    ...printAction,
                    displayType: "BUTTON",
                })
            }
        }

        // Alternatives
        if (onClickErpAlternatives) {
            informationArea.push({
                iconType: ErpIconType.AlternativeItemAvailable,
                displayType: "BUTTON",
                sort: 4,
                icon: "alternative-article",
                text: translateText(13722),
                handler: onClickErpAlternatives,
            })
        }

        // Notes
        if (isNotesEnabled && toggleNotes) {
            if (!notes?.length) {
                informationArea.push({
                    iconType: ErpIconType.Notes,
                    displayType: "BUTTON",
                    sort: 10,
                    icon: "note",
                    text: translateText(14),
                    tooltip: translateText(12899),
                    handler: toggleNotes,
                })
            } else {
                const tooltip = (
                    <Stack gap={1}>
                        {notes.map((x) => (
                            <Stack key={x.noteId}>
                                <Typography variant="label" color="inherit">
                                    {translateText(x.type === NoteTypes.VEHICLE_ARTICLE ? 12875 : 12874)}
                                </Typography>
                                {x.text}
                            </Stack>
                        ))}
                    </Stack>
                )
                informationArea.push({
                    iconType: ErpIconType.Notes,
                    displayType: "BUTTON",
                    sort: 10,
                    icon: "notes-added",
                    text: translateText(14),
                    tooltip,
                    handler: toggleNotes,
                    isHighlight: true,
                })
            }
        }

        // Feedback
        if (onFeedback) {
            const feedbackAction = {
                iconType: ErpIconType.Feedback,
                icon: "feedback",
                sort: 11,
                text: translateText(41),
                tooltip: translateText(41),
                handler: onFeedback,
            }
            if (feedbackConfiguration.showFeedbackAsButton) {
                informationArea.push({
                    ...feedbackAction,
                    displayType: "ICON",
                })
            } else {
                menuArea.push({
                    ...feedbackAction,
                    displayType: "BUTTON",
                })
            }
        }

        // Repair times
        if (onClickRepairTimes) {
            const repairTimesAction: ArticleActionButton = {
                iconType: ErpIconType.Repairtimes,
                displayType: "BUTTON",
                sort: 4,
                text: translateText(83),
                icon: "repairtimes",
                handler: onClickRepairTimes,
            }
            if (showArticleRepairTimesAsButton) {
                informationArea.push(repairTimesAction)
            } else {
                menuArea.push(repairTimesAction)
            }
        }

        // Documents
        if (onClickDocuments) {
            const documentsAction = {
                iconType: ErpIconType.Documents,
                sort: 5,
                icon: "file-pdf",
                text: translateText(350),
                tooltip: translateText(350),
                handler: onClickDocuments,
            }
            if (showArticleDocumentsAsButton) {
                informationArea.push({
                    ...documentsAction,
                    displayType: "ICON",
                })
            } else {
                menuArea.push({
                    ...documentsAction,
                    displayType: "BUTTON",
                })
            }
        }

        // Accessories
        if (onClickAccessoryList) {
            informationArea.push({
                iconType: ErpIconType.Accessories,
                displayType: "BUTTON",
                sort: 9,
                icon: "accessories",
                text: translateText(349),
                handler: onClickAccessoryList,
            })
        }

        // Parts list
        if (onClickPartsList) {
            informationArea.push({
                iconType: ErpIconType.Partslist,
                displayType: "BUTTON",
                sort: 10,
                icon: "article_partslist",
                text: translateText(1525),
                handler: onClickPartsList,
            })
        }

        // Additional information
        if (onClickImsIcon) {
            informationArea.push({
                iconType: ErpIconType.AdditionalInformations,
                displayType: "ICON",
                sort: 1000,
                icon: "trader-icon",
                tooltip: translateText(912),
                handler: onClickImsIcon,
            })
        }

        return {
            informationArea: sortBy([...informationArea, ...specialIcons.informationArea], (x) => x.sort),
            erpArea: sortBy([...erpArea, ...specialIcons.erpArea], (x) => x.sort),
            imageArea: sortBy([...imageArea, ...specialIcons.imageArea], (x) => x.sort),
            menuArea: sortBy(menuArea, (x) => x.sort),
        }
    }, [
        disableDeleteButton,
        handleClickDetails,
        isNotesEnabled,
        notes,
        onClickAccessoryList,
        onClickImsIcon,
        onClickDelete,
        onClickDocuments,
        onClickErpAlternatives,
        onClickPartsList,
        onClickPrint,
        onClickRepairTimes,
        onClickSearchAlternatives,
        onFeedback,
        showArticleDocumentsAsButton,
        toggleNotes,
        translateText,
        imsIcon,
        specialIcons,
    ])

    return articleActions
}
