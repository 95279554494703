import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { CalculationHistoryStatus } from "../../models"

export type ManagerSlice = {
    isFullScreen?: boolean
    worktaskId?: string
    calculationStatus: CalculationHistoryStatus
    calculationHistoryId?: string
    historyLoading?: boolean
    hydrateWorktaskSwitch?: boolean
    version?: number
}

export function getInitialState(): ManagerSlice {
    return {
        hydrateWorktaskSwitch: false,
        historyLoading: false,
        calculationStatus: CalculationHistoryStatus.None,
        calculationHistoryId: undefined,
        version: 4,
    }
}

export const createManagerSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], ManagerSlice> = (set, get, store) => ({
    ...getInitialState(),
})
