import { useEffect, useMemo, useState } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Headline, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ECalcOrigin, EReplaceButtonBundle, OE, RequestArticleListPayload, Vehicle, channel } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { RouteComponentProps, renderRoute, withRouter } from "@tm/utils"
import { Box, LoadingContainer } from "@tm/components"
import { FastCalculator } from "@tm/data"
import { useBasketImports } from "@bundles/basket"
import { submitVehicleFieldInputs } from "@bundles/fast-calculator/data/helpers/submitVehicleFieldInput"
import {
    getProductGroupIds,
    getTopProductGroups,
    isVehicleChanged,
    mapCalcArticleToArticleIdentifier,
    mapCalcArticleToOePart,
} from "../../data/helpers"
import { getCurrencyFromUserContext, getLabourRate } from "../../data/helpers/userSettingsContextMethods"
import { AlternativeCalcStateContext, CalcArticle, CalcGenArt, DialogContext, SummaryContext } from "../../data/model"
import { CalcStateButton } from "../_shared"
import Calculation from "../calculation/component"
import Navigation from "../navigation/component"
import QuestionComponent from "../questionPrompt/component"
import QueuedStatusComponent from "../queueStatus/component"
import Summary from "../summary/component"
import { useQueueStatus } from "../../hooks/useQueueStatus"
import { mainActions, StoreContext, StoreInstanceEnum, useFastCalculatorStore, useStoreContext } from "../../state"
import { handleButtonClick, initFastCalculator, initialData, startCalculationWithProductGroups } from "../../business"

type Routes = {
    costEstimation: string
}

type ConfigProps = {
    inModal?: boolean
    routes: Routes
    urls: Routes // urls will be automatically created (based on "routes") by Morpheus with route params being already set (e.g. workTaskId)
}

export type MainProps = ConfigProps & RouteComponentProps<RouteParams> & {}

type RouteParams = {
    productGroupIds?: string
    origin?: string
}

function MainComponent(props: MainProps) {
    let scrollTimeoutId: number | undefined
    const instance = useStoreContext()
    const [firstMount, setFirstMount] = useState(true)
    const [fastCalcCallDone, setFastCalcCallDone] = useState(false)
    const [oldVehicle, setOldVehicle] = useState<Vehicle>()

    const { userSettings, userContext } = useUser() ?? {}
    const { translateText, languageId } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const worktaskVehicle = useMemo(() => workTask?.vehicle, [workTask])

    const { importFastCalculation } = useBasketImports()

    const [vehicleId, setvehicleId] = useState<string | undefined>()

    const queryParams = new URLSearchParams(props.location.search)
    const initialCalcId = queryParams.get("initialCalcId")
    const isWorkshop = queryParams.get("isWorkshop") === "true"
    const forceReset = queryParams.get("forceReset") === "true"
    const isReplaceArticle = queryParams.get("isReplaceArticle") === "true"

    const [showPricesInHeader, setShowPricesInHeader] = useState(false)

    const { vehicle, selectedCalcState, selectedDialogCalcState, loading, error, alternativeCalcArticles, skipReload, context } =
        useFastCalculatorStore((state) => ({
            vehicle: state.vehicle,
            selectedCalcState: state.selectedCalcState,
            selectedDialogCalcState: state.selectedDialogCalcState,
            loading: state.loading,
            error: state.error,
            alternativeCalcArticles: state.alternativeCalcArticles,
            skipReload: state.skipReload,
            context: state.selectedCalcState?.context as SummaryContext | undefined,
        }))

    const { inputs } = useMemo(
        () => ({
            inputs: context?.inputs ?? [],
        }),
        [context]
    )

    const alternativesContext = alternativeCalcArticles?.context as AlternativeCalcStateContext

    useEffect(() => {
        if (!firstMount || !worktaskVehicle) {
            return
        }

        if (isVehicleChanged(worktaskVehicle, vehicle)) {
            setOldVehicle(vehicle)
            mainActions.setVehicle(instance, worktaskVehicle)
        }

        setFirstMount(false)
    }, [firstMount, worktaskVehicle])

    useEffect(() => {
        if (!worktaskVehicle || !fastCalcCallDone || !oldVehicle) {
            return
        }

        submitVehicleFieldInputs(inputs, worktaskVehicle, instance, oldVehicle)
        setOldVehicle(undefined)
    }, [worktaskVehicle, fastCalcCallDone, oldVehicle])

    const { isQueued } = useQueueStatus(() => initFastCalculator(instance, false, selectedCalcState?.calcId, false))

    useEffect(() => {
        const {
            urls: { costEstimation },
            inModal,
        } = props

        mainActions.setConfigProps(instance, costEstimation, inModal ?? false)

        if (isReplaceArticle) {
            return
        }

        if (skipReload) {
            mainActions.setSkipReload(instance, false)
            return
        }

        if (vehicle?.id !== vehicleId) {
            setvehicleId(vehicle?.id)
            const productGroupIds = getProductGroupIds(props.match?.params?.productGroupIds)

            if (productGroupIds) {
                const topProductGroups: FastCalculator.TopProductGroup[] | undefined = getTopProductGroups(props.match?.params?.productGroupIds)
                mainActions.setCalcOrigin(instance, ECalcOrigin.Next | ECalcOrigin.Searchtree)

                if (topProductGroups && topProductGroups?.length > 0) {
                    startCalculationWithProductGroups(
                        instance,
                        productGroupIds,
                        languageId,
                        getLabourRate(userSettings),
                        props.match?.params?.origin,
                        topProductGroups
                    )
                } else {
                    startCalculationWithProductGroups(instance, productGroupIds, languageId, getLabourRate(userSettings), props.match?.params?.origin)
                }
            } else {
                mainActions.setCalcOrigin(instance, ECalcOrigin.Next)
                initFastCalculator(instance, false, initialCalcId || undefined, isWorkshop)
                setFastCalcCallDone(true)
            }
        }
    }, [vehicle])

    useEffect(() => {
        const { match, history } = props

        if (forceReset && selectedCalcState?.type == FastCalculator.ECalcState.FastCockpitNext) {
            const resetButton = selectedCalcState?.buttonGroups
                .find((x) => x.index == 1)
                ?.buttons.find((x) => x.type == FastCalculator.ECalcButtonState.Reset)

            if (!resetButton) {
                return
            }

            handleGeneralReset(selectedCalcState)
            history.replace(renderRoute(match.path, { ...match.params }))

            return
        }

        if (selectedCalcState?.type == FastCalculator.ECalcState.InitialData) {
            const productGroupIds = getProductGroupIds(props.match?.params?.productGroupIds)

            if (productGroupIds) {
                const topProductGroups: FastCalculator.TopProductGroup[] | undefined = getTopProductGroups(props.match?.params?.productGroupIds)
                mainActions.setCalcOrigin(instance, ECalcOrigin.Next | ECalcOrigin.Searchtree)

                if (topProductGroups && topProductGroups?.length > 0) {
                    startCalculationWithProductGroups(
                        instance,
                        productGroupIds,
                        languageId,
                        getLabourRate(userSettings),
                        props.match?.params?.origin,
                        topProductGroups
                    )
                } else {
                    startCalculationWithProductGroups(instance, productGroupIds, languageId, getLabourRate(userSettings), props.match?.params?.origin)
                }
            } else {
                mainActions.setCalcOrigin(instance, ECalcOrigin.Next)
                initialData(instance, languageId, getLabourRate(userSettings))
            }
        }
    }, [selectedCalcState?.type])

    const handleScroll = (e: any) => {
        window.clearTimeout(scrollTimeoutId)

        scrollTimeoutId = window.setTimeout(() => {
            setShowPricesInHeader(e.target.scrollTop >= 125)
        }, 100)
    }

    const requestAlternatives = (request: RequestArticleListPayload, genArt: CalcGenArt, part?: CalcArticle) => {
        const { match, inModal, location } = props

        mainActions.startArticleReplace(instance, { match, search: location.search, genArt, inModal: inModal ?? false, part })

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            ...request,
            fastCalculator: request.fastCalculator ?? {},
            replaceButtonMicro: EReplaceButtonBundle.FastCalculatorNext,
            forceReload: true,
            inModal: true,
        })
    }

    const requestProductGroupAlternatives = (genArt: CalcGenArt) => {
        requestAlternatives(
            {
                productGroups: {
                    ids: [genArt.genArtNr],
                },
                fittingPosition: genArt.binKrit100,
            },
            genArt
        )
    }

    const requestPartAlternatives = (genArt: CalcGenArt, part: CalcArticle) => {
        const { showoePrice } = Morpheus.getParams<{ [key: string]: string }>("fast-calculator-next")

        requestAlternatives(
            {
                productGroups: {
                    ids: [genArt.genArtNr],
                },
                fastCalculator: {
                    alternatives: alternativesContext?.articles?.map((x) =>
                        mapCalcArticleToArticleIdentifier(x, genArt.genArtNr, vehicle?.tecDocTypeId)
                    ),
                    ...(Boolean(+showoePrice) && { oeNrs: [mapPartToOe(part, genArt)] }),
                },
                fittingPosition: part.binKrit100,
            },
            genArt,
            part
        )
    }

    const mapPartToOe = (part: CalcArticle, genArt: CalcGenArt): OE.OePosition => {
        const oePart = mapCalcArticleToOePart(part)

        return {
            number: part.hArtNr,
            description: part.supplierName,
            isSelected: true,
            replacements: genArt.oeNrs
                ?.filter((x) => part.binKrit100! & x.binKrit100!)
                ?.map((x) => {
                    return {
                        number: x.oeNr,
                        parts: [
                            {
                                description: x.attributeLabel,
                                id: x.id,
                                manufacturerName: x.herBez,
                                number: x.oeNr,
                                quantity: x.customQuantity ?? 1,
                                prices: [{ value: x.price ?? 0, currency: getCurrencyFromUserContext(userContext) }],
                                disabled: true,
                                // eArtNr: part.supplierArtNr,
                                // iArtNr: part.iArtNr
                            },
                        ],
                    }
                })
                ?.filter((x, idx, arr) => x.number != oePart.number && arr?.findIndex((y) => y.number == x.number) == idx),
        }
    }

    const requestOePartAlternatives = (genArt: CalcGenArt, part: CalcArticle) => {
        const oePart = mapCalcArticleToOePart(part)

        requestAlternatives(
            {
                oePositions: [
                    {
                        number: oePart.number,
                        description: part.oeGroups.findFirst((x) => x.isDisabled)?.description ?? oePart.description,
                        replacements: alternativesContext?.articles
                            ?.map((x) => {
                                const alternativeOePart = mapCalcArticleToOePart(x)

                                return {
                                    number: alternativeOePart.number,
                                    parts: [alternativeOePart],
                                }
                            })
                            ?.filter((x, idx, arr) => x.number != oePart.number && arr?.findIndex((y) => y.number == x.number) == idx), // Filter out duplicate oe numbers
                    },
                ],
                fittingPosition: part.binKrit100,
            },
            genArt,
            part
        )
    }

    const handleGeneralReset = (selectedCalcState?: FastCalculator.CalcState) => {
        const resetBtn = selectedCalcState?.buttonGroups?.[0]?.buttons?.find((x) => x.type == FastCalculator.ECalcButtonState.Reset)
        resetBtn && handleButtonClick(instance, resetBtn, languageId, importFastCalculation, getLabourRate(userSettings))
        channel("APP").publish("TOAST_MESSAGE/SHOW", {
            message: `Fast Calculator ${translateText(1825)}`,
            skin: "warning",
            icon: "warning",
        })
    }

    if (!vehicle && !loading) {
        return (
            <LoadingContainer isLoading={loading}>
                <Headline>No Vehicle</Headline>
            </LoadingContainer>
        )
    }

    if (isReplaceArticle) {
        return <LoadingContainer isLoading />
    }

    if (!selectedCalcState || selectedCalcState.type === FastCalculator.ECalcState.InitialData) {
        if (isQueued) {
            return (
                <LoadingContainer isLoading={loading}>
                    <QueuedStatusComponent workTaskId={workTask?.id || ""} isQueued={isQueued} isError={false} />
                </LoadingContainer>
            )
        }
        return <LoadingContainer isLoading={loading} />
    }

    if (error) {
        const resetBtn = selectedCalcState?.buttonGroups?.[0]?.buttons?.find((x) => x.type == FastCalculator.ECalcButtonState.Reset)
        return (
            <LoadingContainer isLoading={loading}>
                <div className="article-list__panel article-list__no-result">
                    <div className="headline">{translateText(787)}</div>
                </div>
                {(resetBtn && <CalcStateButton item={resetBtn} />) || "Error"}
            </LoadingContainer>
        )
    }

    if (isQueued) {
        const resetBtn = selectedCalcState?.buttonGroups?.[0]?.buttons?.find((x) => x.type == FastCalculator.ECalcButtonState.Reset)
        return (
            <LoadingContainer isLoading={loading}>
                <Navigation inModal={props.inModal} showPrices={showPricesInHeader} selectedCalcState={selectedCalcState} disableTabs />
                <QueuedStatusComponent workTaskId={workTask?.id || ""} isQueued={isQueued} isError={false} resetBtn={resetBtn} />
            </LoadingContainer>
        )
    }

    if (selectedCalcState.type === FastCalculator.ECalcState.LastCalcPreviewNext) {
        return <LoadingContainer isLoading={loading} />
    }

    return (
        <LoadingContainer isLoading={loading}>
            <Navigation inModal={props.inModal} showPrices={showPricesInHeader} selectedCalcState={selectedCalcState} />
            <Box margin="1em 0.5em" display="flex" flex="1">
                <Scrollbar onScroll={handleScroll}>
                    {selectedCalcState.type === FastCalculator.ECalcState.FastCockpitNext && <Summary />}
                    {selectedCalcState.type === FastCalculator.ECalcState.CalculationNext && (
                        <Calculation
                            showProductGroupAlternatives={requestProductGroupAlternatives}
                            showOePartAlternatives={requestOePartAlternatives}
                            showPartAlternatives={requestPartAlternatives}
                            hideAlternatives={props.inModal}
                        />
                    )}
                </Scrollbar>
            </Box>
            {selectedDialogCalcState && <QuestionComponent selectedDialogContext={selectedDialogCalcState?.context as DialogContext | undefined} />}
        </LoadingContainer>
    )
}

function Wrapper(props: MainProps) {
    const [isGroupSearch, setIsGroupSearch] = useState(false)
    const [hasWorktaskId, setHasWorktaskId] = useState(false)
    const worktask = useWorkTask()?.workTask

    useEffect(() => {
        if (!hasWorktaskId && worktask) {
            setHasWorktaskId(true)
        }
    }, [worktask, hasWorktaskId])

    useEffect(() => {
        const productGroupIds = getProductGroupIds(props.match?.params?.productGroupIds)

        if (productGroupIds) {
            setIsGroupSearch(true)
        }
    }, [])

    const modalContext = useMemo(() => ({ storeInstance: StoreInstanceEnum.modal, worktaskId: worktask?.id }), [worktask?.id])
    const normalContext = useMemo(() => ({ storeInstance: StoreInstanceEnum.normal, worktaskId: worktask?.id }), [worktask?.id])

    if (!hasWorktaskId) {
        return null
    }

    if (isGroupSearch) {
        return (
            <StoreContext.Provider value={modalContext}>
                <MainComponent {...props} />
            </StoreContext.Provider>
        )
    }

    return (
        <StoreContext.Provider value={normalContext}>
            <MainComponent {...props} />
        </StoreContext.Provider>
    )
}

export default withRouter(Wrapper)
