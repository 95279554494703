import { ArticleAttribute } from "@tm/models"
import { classes } from "@tm/utils"
import { Box, Tooltip, Button, styled, Theme } from "@tm/components"

type SelectableArticleAttribute = ArticleAttribute & { selected?: boolean }

type Props = {
    items: SelectableArticleAttribute[]
    onSelect: (a: ArticleAttribute) => void
}

export function TyresIcons({ items, onSelect }: Props) {
    const renderIcon = (attr: SelectableArticleAttribute, key: number) => (
        <Tooltip key={key} title={attr.description}>
            <StyledButtonNumbers
                colorValue={attr.value}
                size="small"
                className={classes(attr.value, attr.selected && "selected")}
                onClick={() => onSelect(attr)}
            >
                {attr.value}
            </StyledButtonNumbers>
        </Tooltip>
    )

    return <StyledBox>{items.map(renderIcon)}</StyledBox>
}

const getColorByValue = (value: string, theme?: Theme) => {
    switch (value) {
        case "A":
            return "#2d7327"
        case "B":
            return "#55d545"
        case "C":
            return "#b5e941"
        case "D":
            return "#f6ed4e"
        case "E":
            return "#f6a328"
        case "F":
            return "#dd901f"
        case "G":
            return "#e12816"
        default: // Default color
            return theme?.palette?.primary?.main
    }
}

const StyledBox = styled(Box)(({ theme }) => ({
    "& .selected": {
        outline: "1px",
        outlineColor: theme.colors?.primary,
        outlineStyle: "auto",
    },
    minWidth: "6em",
}))

const StyledButtonNumbers = styled(Button)<{ colorValue?: string; theme?: Theme }>(({ colorValue, theme }) => ({
    "&&": {
        cursor: "pointer",
        width: "25px",
        height: "25px",
        marginLeft: "0.2em",
        color: theme.colors?.light,
        textShadow: "0 0 3px #202020",
        backgroundColor: `${getColorByValue(colorValue ?? "", theme)}`,
    },
    "&:hover": {
        textShadow: "none",
    },
    "& .btn content": {
        opacity: 1,
    },
}))
