import { ajaxAuth } from "@tm/utils"
import { getVehicleRecordsServiceUrl } from ".."

function getServiceUrl() {
    return `${getVehicleRecordsServiceUrl()}/Vehicle`
}

export function updateVehicleRecords(vehicleId: string): Promise<void> {
    return ajaxAuth({
        url: `${getServiceUrl()}/UpdateVehicleDetails`,
        body: { vehicleId },
        method: "POST",
    })
}
