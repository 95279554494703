import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Loader, Typography } from "@tm/components"
import { BasketWork } from "../../../../../models"

type Props = {
    work: BasketWork
    loading?: boolean
}

export default function WorkPrice({ loading, work }: Props) {
    const { workItem, calculatedWork } = work
    const { hourlyRateWithSurcharge, isFixedPrice, regularPrice } = calculatedWork ?? {}
    const { currency, translate } = useLocalization()

    const hourlyRate = useMemo(() => {
        if (hourlyRateWithSurcharge) {
            return hourlyRateWithSurcharge.value
        }
        if (workItem.hourlyRate) {
            return workItem.hourlyRate
        }
        return 0
    }, [workItem.hourlyRate, hourlyRateWithSurcharge])

    if (loading) {
        return <Loader size="small" />
    }

    if (isFixedPrice && regularPrice) {
        return (
            <Box textAlign="end">
                <Typography variant="label">{translate(246)}</Typography> <br />{" "}
                {currency(regularPrice?.value, regularPrice.currencySymbol || regularPrice.currencyCode_Iso_4217)}{" "}
            </Box>
        )
    }

    return <>{currency(hourlyRate, workItem.currencySymbol || workItem.currencyCode)}</>
}
