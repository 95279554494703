import { RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { checkHasMemo } from "./checkHasMemo"
import { getMemoImportantNote } from "./getMemoImportantNote"
import { getKTypeMemoInfo } from "./getKTypeMemoInfo"

export * from "./checkHasMemo"
export * from "./getMemoImportantNote"
export * from "./getKTypeMemoInfo"
export * from "./getMemoInfo"
export * from "./getServiceNodes"
export * from "./getServicesByNodeId"
export * from "./getMemoTechnicalInfo"

export const initRepositories = () => {
    Container.register({
        name: RegisteredModels.MemoTool,
        containerActions: {
            getKTypeMemoInfo,
            checkHasMemo,
            getMemoImportantNote,
        },
    })
}
