import { useMemo } from "react"
import { getStyleTheme, useStyle, useUser } from "@tm/context-distribution"
import { AmountField, NumberField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FastCalculator } from "@tm/data"
import { bem, getCalcStateLabel, getCurrencyFromUserContext } from "../../../data/helpers"
import { useCallbackDebounce } from "../../../hooks/useCallbackDebounce"
import { handleInputSubmit } from "../../../business"
import { useStoreContext } from "../../../state"

type Props = {
    item: FastCalculator.CalcInput
    onChangeCallBack?: (data: number) => void
    fromWidget?: boolean
}

export default function CalcInputNumber({ item, onChangeCallBack, fromWidget }: Props) {
    const instance = useStoreContext()
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const stepSize = item.decimalPlaces && 0.01
    const label = getCalcStateLabel(item.type, getCurrencyFromUserContext(userContext), translateText)
    const style = useMemo(() => getStyle(), [])

    const missingValue = [
        FastCalculator.ECalcInputType.ArticlePrice,
        FastCalculator.ECalcInputType.GlobalLabourRate,
        FastCalculator.ECalcInputType.RepairTimeQuantity,
        FastCalculator.ECalcInputType.ConsumablePrice,
        FastCalculator.ECalcInputType.GenArtPrice,
        FastCalculator.ECalcInputType.ConsumableQuantity,
        FastCalculator.ECalcInputType.OeArticlePrice,
    ].includes(item.type)

    const handleChange = (value: string) => {
        const newValue = item.decimalPlaces ? parseFloat(value) : parseInt(value)

        if (newValue != item.value) {
            handleInputSubmit(instance, { ...item, value: newValue || 0 })
        }
    }

    const handleDelayedChange = useCallbackDebounce(handleChange)

    const handleRTQuantityChange = (value: string) => {
        if (value == null) {
            if (item.value) {
                handleInputSubmit(instance, { ...item })
            }
            return
        }

        handleDelayedChange(value)
    }

    const handleMileageChange = (value: string) => {
        const newValue = parseInt(value.replace(/[\.,\D]/g, ""))

        if (newValue != item.value) {
            !fromWidget && handleInputSubmit(instance, { ...item, value: newValue || 0 })
            onChangeCallBack?.(newValue || 0)
        }
    }

    if (
        [
            FastCalculator.ECalcInputType.ArticleQuantity,
            FastCalculator.ECalcInputType.OeArticleQuantity,
            FastCalculator.ECalcInputType.GenArtQuantity,
        ].includes(item.type)
    ) {
        return (
            <AmountField
                disabled={item.isDisabled}
                value={item.value}
                stepSize={stepSize}
                onChange={(amountItem) => {
                    ~~amountItem.value != ~~item.value && handleDelayedChange(`${amountItem.value}`)
                }}
            />
        )
    }

    if (item.type === FastCalculator.ECalcInputType.Mileage) {
        return (
            <TextField
                floatingLabel
                label={item.label || label}
                disabled={item.isDisabled}
                value={item.value || undefined}
                onChangeConfirm={handleMileageChange}
                placeholder={item.placeholder ?? ""}
                maxLength={9}
                formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            />
        )
    }
    const valueValidator = (calcItem: FastCalculator.CalcInput) => {
        if (calcItem.type === FastCalculator.ECalcInputType.RepairTimeQuantity) {
            return !calcItem.isInvalid ? calcItem.value : undefined
        }
        return calcItem.value || undefined
    }

    return (
        <NumberField
            floatingLabel
            label={item.label || label}
            disabled={item.isDisabled}
            value={valueValidator(item)}
            onChangeConfirm={item.type === FastCalculator.ECalcInputType.RepairTimeQuantity ? handleRTQuantityChange : handleDelayedChange}
            placeholder={item.placeholder ?? ""}
            stepSize={0.01}
            nullable
            minimum={0}
            enforceDecimalDigits={!!item.decimalPlaces}
            className={bem(style.custom, missingValue && valueValidator(item) === undefined && "error")}
        />
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        custom: {
            $nest: {
                "&--error": {
                    borderColor: theme.colors.danger,
                    boxShadow: " 0 1px 5px rgba(0,0,0,.2)",
                },
            },
        },
    })(CalcInputNumber)
}
