export enum FilterType {
    None = 0,
    Season = 1 << 0,
    Manufacturer = 1 << 1,
    LoadIndex = 1 << 2,
    SpeedIndex = 1 << 3,
    OeIdentifier = 1 << 4,
    Extras = 1 << 5,
    Availability = 1 << 6,
    RangeSliders = 1 << 8,
}
