import { useStyle } from "@tm/context-distribution"
import { Headline, Image, SubTitle, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { em, percent } from "csx"
import { FC, useState } from "react"
import { Box, Icon, styled } from "@tm/components"
import { RimItem } from "../../../data/model"
import { getBundleParams } from "../../../utils"
import { useSupplierLogo } from "../../../data/hooks/useSupplierLogo"

type Props = {
    rimItem: RimItem
}

const ArticleAtributes: FC<Props> = ({ rimItem }) => {
    const { translateText } = useLocalization()
    const [logo, setLogo] = useState(true)
    const { supplierLogo } = useSupplierLogo({ supplierId: rimItem.supplierId })
    const isLKQ = getBundleParams()?.version === "LKQ"
    const showWinterProofed = rimItem.isWinterproofed && isLKQ

    const handleImageError = () => {
        setLogo(false)
    }

    const showLogo = () => {
        let content = <Text>{rimItem.suplierName}</Text>

        if (logo && supplierLogo) {
            content = <Image onError={handleImageError} url={supplierLogo} alt="logo" />
        }

        return content
    }

    const renderLogo = () => {
        return (
            <div className={style.attribute}>
                {getBundleParams()?.showSupplierLogos && (
                    <>
                        <SubTitle className={style.text}>{translateText(1032)}</SubTitle>
                        {showLogo()}
                    </>
                )}
                {!getBundleParams()?.showSupplierLogos && (
                    <>
                        <SubTitle>{translateText(1032)}</SubTitle>
                        <Text>{rimItem.suplierName}</Text>
                    </>
                )}
            </div>
        )
    }

    return (
        <div className={style.wrapper}>
            <Headline>{rimItem.modelCode}</Headline>
            <div className={style.container}>
                <div className={style.attribute}>
                    <SubTitle>{translateText(944)}</SubTitle>
                    <Text>{rimItem.color}</Text>
                </div>
                {renderLogo()}
            </div>
            {showWinterProofed && (
                <StyledBox>
                    <Icon name="snow" size="2em" />
                </StyledBox>
            )}
        </div>
    )
}

const StyledBox = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-4em",
    marginRight: "25%",
})

const style = useStyle({
    wrapper: {
        width: percent(100),
    },
    container: {
        display: "flex",
    },
    attribute: {
        marginRight: em(0.5),
        display: "flex",
        flexDirection: "column",
        $nest: {
            img: {
                maxHeight: em(2),
            },
        },
    },
    text: {
        textAlign: "center",
    },
})(ArticleAtributes)

export default ArticleAtributes
