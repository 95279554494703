import { IAttributesItem } from "../../../../data/models"

export * from "./createArticleListRequest"
export * from "./createAttributeFiltersRequest"
export * from "./createNextArticlesListRequest"
export * from "./filterUsedCriteria"
export * from "./getSelectedCriterias"
export * from "./isAvailabilityFilter"

export const getAttributeString = (attr: IAttributesItem) => `${attr.id}|${attr.value}`
