import { PropsWithChildren } from "react"
import { createContext, useContextSelector } from "use-context-selector"
import { ensureContextAvailability } from "../../../helpers"
import { useOeInformation } from "./useOeInformation"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { useDirectSearchListParamsContext } from "../ListParams"
import { OeInformationData } from "../../../models"

export const OeInformationContext = createContext<OeInformationData | undefined>(undefined)

export function OeInformationProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const params = useDirectSearchListParamsContext()
    const enableOeInformation = useArticleListConfiguration((x) => x.oeInformation.enableOeInformation)

    const data = useOeInformation(params, enableOeInformation && props.isEnabled)

    return <OeInformationContext.Provider value={data}>{props.children}</OeInformationContext.Provider>
}
export function useOeInformationContext<TContext extends OeInformationData>(): TContext
export function useOeInformationContext<TContext extends OeInformationData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useOeInformationContext<TContext extends OeInformationData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useContextSelector(OeInformationContext, (context) => {
        const validatedContext = ensureContextAvailability("OeInformationContext", context)
        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
