import { useState } from "react"
import { useSelector } from "react-redux"
import { styled } from "@tm/components"
import { Checkbox, PanelSection, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { AvailabilityFilterType } from "../../../business"
import { FilterType, displayStateResetRDKSSelector, filtersSelector, getEnumByPath, selectedFiltersSelector } from "../../../data/helpers"
import { FilterComponent, ResetButtonFromState, SelectionCheckbox, SelectionSensorFilters } from "../../_shared"
import { Actions, ISensorFilters } from "../business"
import { useAvailabilityStatus } from "@tm/context-distribution"

type Props = {
    className?: string
}

type CollapsibleFilters = ISensorFilters | "availability"

type State = {
    [key in CollapsibleFilters]?: {
        searchValue?: string
        opened?: boolean
        searchEnabled?: boolean
    }
}

const FilterPanel = styled(PanelSection)(({ theme }) => ({
    margin: theme.spacing(2, 1, 0, 1),
    display: "flex",
    flexDirection: "column",
    ".panel__content": {
        flex: 1,
        height: "100%",
    },
}))

export default function SensorFilters({ className }: Props) {
    const { translateText } = useLocalization()

    const actions = useActions(
        Actions,
        "loadSensorsList",
        "updateFilter",
        "resetFilter",
        "changeAvailabilityFilter",
        "toggleExtendedAssortmentFilter"
    )
    const selectedFilters = useSelector(selectedFiltersSelector)
    const filters = useSelector(filtersSelector)
    const displayStateReset = useSelector(displayStateResetRDKSSelector)
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()

    const [filterState, setFilterState] = useState<State>({ manufacturer: { opened: true }, availability: { opened: true } })

    const handleFilterChange = (path: ISensorFilters, value: any) => {
        actions.updateFilter(path, value)
        actions.loadSensorsList()
    }

    const handleSearchTextChange = (path: CollapsibleFilters, value: string) => {
        setFilterState((state) => ({
            ...state,
            [path]: {
                ...state[path],
                searchValue: value,
            },
        }))
    }
    const handleSearchVisibility = (path: CollapsibleFilters) => {
        setFilterState((state) => ({
            ...state,
            [path]: {
                ...state[path],
                searchEnabled: !state[path]?.searchEnabled,
                opened: true,
            },
        }))
    }
    const handleCollapsibleChange = (path: CollapsibleFilters) => {
        setFilterState((state) => ({
            ...state,
            [path]: {
                ...state[path],
                opened: !state[path]?.opened,
            },
        }))
    }

    const handleFilterReset = (path: ISensorFilters = "manufacturer") => {
        handleSearchTextChange(path, "")
        actions.resetFilter(path)
        actions.loadSensorsList()
    }

    const renderSelectionFilter = (path: ISensorFilters, title: string) => {
        return (
            <FilterComponent
                key={path + title}
                title={title}
                filterId={getEnumByPath(path)}
                loading={filters.loading}
                onReset={() => handleFilterReset(path)}
                disabled={!filters[path].length}
                onSearchChange={(val) => handleSearchTextChange(path, val)}
                onSearchVisibility={() => handleSearchVisibility(path)}
                searchValue={filterState[path]?.searchValue}
                active={filterState[path]?.opened}
                searchEnabled={filterState[path]?.searchEnabled}
                resetBtnDisabled={!selectedFilters[path]?.length}
                onCollapsibleChange={(_) => handleCollapsibleChange(path)}
            >
                <SelectionSensorFilters
                    extendedAssortment={selectedFilters.extendedAssortment}
                    searchValue={filterState[path]?.searchValue}
                    key={`${path + title}_sensors`}
                    items={filters[path]}
                    selectedItems={selectedFilters[path] ?? []}
                    onchange={handleFilterChange}
                />
            </FilterComponent>
        )
    }

    const renderAvailability = () => {
        if (!availabilityStatusIdsToShow?.length && !availabilityStatusIdsToShowSecondary?.length) {
            return null
        }

        const primarySelected = selectedFilters.availability === AvailabilityFilterType.Primary
        const secondarySelected = selectedFilters.availability === AvailabilityFilterType.Secondary

        return (
            <FilterComponent
                filterId={FilterType.Availability}
                title={translateText(412)}
                onCollapsibleChange={(_) => handleCollapsibleChange("availability")}
                active={filterState.availability?.opened}
                onReset={() => actions.changeAvailabilityFilter(AvailabilityFilterType.None)}
                resetBtnDisabled={!selectedFilters.availability}
                loading={filters.loading}
                disabled={!filters.manufacturer.length || filters.loading}
            >
                {!!availabilityStatusIdsToShow?.length && (
                    <SelectionCheckbox
                        label={translateText(1623)}
                        selected={primarySelected}
                        onChange={() =>
                            actions.changeAvailabilityFilter(primarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Primary)
                        }
                    />
                )}
                {!!availabilityStatusIdsToShowSecondary?.length && (
                    <SelectionCheckbox
                        label={translateText(12860)}
                        selected={secondarySelected}
                        onChange={() =>
                            actions.changeAvailabilityFilter(secondarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Secondary)
                        }
                    />
                )}
            </FilterComponent>
        )
    }

    const renderExtendedAssortment = () => {
        const selected = selectedFilters.extendedAssortment

        const handleOnToggle = () => {
            if (selectedFilters.extendedAssortment && selectedFilters.manufacturer?.some((x) => !x.isTopPriority)) {
                return
            }
            actions.toggleExtendedAssortmentFilter(!selected)
        }

        return (
            <div key="collapsible-filter--clipped-extended-assortment" className="collapsible filter filter--clipped filter--extended-assortment">
                <div className="collapsible__header">
                    <div className="collapsible__caption">
                        <Checkbox size="s" label={translateText(227)} checked={selected} onToggle={handleOnToggle} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <FilterPanel className={className}>
            {displayStateReset && <ResetButtonFromState onReset={handleFilterReset} />}
            <Scrollbar>
                {renderExtendedAssortment()}
                {renderAvailability()}
                {renderSelectionFilter("manufacturer", translateText(71))}
            </Scrollbar>
        </FilterPanel>
    )
}
