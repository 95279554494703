import { create, StoreApi, UseBoundStore } from "zustand"
import { devtools } from "zustand/middleware"
import { useShallow } from "zustand/react/shallow"
import { isDevtoolEnabled } from "@tm/utils"
import { createMainSlice, MainSlice } from "./main"
import { StoreInstance, useStoreContext } from "./storeContext"

export type FastCalculatorStore = MainSlice

const storeMap = new Map<string, UseBoundStore<StoreApi<MainSlice>>>()

const createStore = () =>
    create<FastCalculatorStore>()(
        devtools(
            (...a) => ({
                ...createMainSlice(...a),
            }),
            { name: "Fast Calculator store", enabled: isDevtoolEnabled() }
        )
    )

export const fastCalculatorStore = (instance: StoreInstance) => {
    const instanceKey = `${instance.worktaskId}_${instance.storeInstance}`
    if (!storeMap.has(instanceKey)) {
        storeMap.set(instanceKey, createStore())
    }

    const store = storeMap.get(instanceKey)!

    return store
}

export const useFastCalculatorStore = <T>(selector: (state: FastCalculatorStore) => T): T => {
    const instance = useStoreContext()
    return fastCalculatorStore(instance)(useShallow(selector))
}
