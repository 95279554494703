import { getBundleParams } from "@bundles/vehicle/utils"
import { VehicleImportData, VehicleType } from "@tm/models"
import { Box } from "@mui/material"
import { useEffect } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { VehicleSearchResultList } from "./VehicleSearchResultList"
import { publishModuleInfo } from "../../helpers"
import { OldSearchResultList } from "./OldSearchResultList"

export type RouteParams = {
    vehicleType: string
    query: string
}

export type ExternalProps = {
    moduleProps?: {
        publishModuleInfo?: boolean
    }
}

export type VehicleSearchProps = ExternalProps & {
    query: string
    vehicleType: VehicleType
    autoApplySingleVehicle?: boolean
    importedData: VehicleImportData | undefined
    importedDataLoading: boolean
    countryCode?: string
    allowVehicleChange?: boolean
}

export default function ComponentSwitch(props: VehicleSearchProps) {
    const { simpleVehicleSearch } = getBundleParams()
    const { workTaskId } = useWorkTask() ?? {}

    // inform the navigation that we change somthing :D
    useEffect(() => {
        if (props.moduleProps?.publishModuleInfo && workTaskId) {
            publishModuleInfo(workTaskId, "{{1601}}", "{{135}}")
        }
    }, [workTaskId, props.moduleProps])

    if (simpleVehicleSearch) {
        return <VehicleSearchResultList {...props} />
    }
    return <OldSearchResultList {...props} />
}
