import { initRepositories } from "./data/repositories"
import { MemoToolBundleParams } from "@tm/models"

let bundleParams: MemoToolBundleParams

export const version = {
    name: "memo-tool",
    version: "0.1.0",
}

function checkBundleParams({}: MemoToolBundleParams) {
    // if (!isDevtoolEnabled()) {
    //     return
    // }
    // check params
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle ${version.name}`)
    }

    return bundleParams
}

export function initMemoToolBundle(params: MemoToolBundleParams) {
    bundleParams = params
    checkBundleParams(params)
    initRepositories()
    return bundleParams
}
