import { useCallback } from "react"
import { styled } from "@tm/components"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { bundleChannel } from "../.."

type Props = {
    article: Article
    isInSensorDetails?: boolean
}

const ButtonWithMargin = styled(Button)(({ theme }) => ({
    marginRight: theme.margin?.s,
}))

export default function GoToOverviewButton({ article, isInSensorDetails }: Props) {
    const { translateText } = useLocalization()

    const handleClick = useCallback(() => {
        if (isInSensorDetails) {
            bundleChannel().publish("SEND_RDKS_ARTICLE_TO_OVERVIEW", { article })
        } else {
            bundleChannel().publish("SEND_TIRE_ARTICLE_TO_OVERVIEW", { article })
        }

        const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
        if (articleDetailsInModal) {
            Morpheus.closeView("1")
        }
    }, [article])

    return (
        <ButtonWithMargin size="m" skin="primary" layout={["iconRight"]} onClick={handleClick}>
            {translateText(isInSensorDetails ? 12465 : 991)}
        </ButtonWithMargin>
    )
}
