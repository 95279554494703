import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSetVehicleProperties(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { workTask, attachToWorkTask } = this.props
    const { setVehicleProperties } = data

    if (workTask && workTask.vehicle && setVehicleProperties) {
        attachToWorkTask({
            vehicle: {
                ...workTask.vehicle,
                ...(setVehicleProperties.plateId && {
                    plateId: setVehicleProperties.plateId,
                }),
                ...(setVehicleProperties.vin && {
                    vin: setVehicleProperties.vin,
                }),
                ...(setVehicleProperties.mileAge && {
                    mileAge: setVehicleProperties.mileAge,
                }),
                ...(setVehicleProperties.initialRegistration && {
                    initialRegistration: setVehicleProperties.initialRegistration,
                }),
            },
        })
    }
}
