import { useHotline, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Button, Text } from "@tm/controls"
import { useMemo } from "react"
import { style } from "typestyle"
import { useTheme, Hotline } from "@tm/components"
import UserSettingsArea from "./UserSettingsArea"
import { LicensorData } from "../business"

type Props = {
    licensorData?: LicensorData
}

const linkClassName = style({
    textDecoration: "none",
})

export function SupportCard(props: Props) {
    const { licensorData } = props
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const hotlineInfo = useHotline(userContext.parameter.hotline ?? "")
    const theme = useTheme()

    const supportMail = (licensorData && licensorData?.supportEmail) || userContext?.parameter.supportEmail

    const hotlineItems = useMemo(() => {
        const hotline = `${userContext?.parameter.hotline}`
        if (userContext?.parameter?.hotline) {
            return hotline.split(/\n/g)
        }
    }, [userContext?.parameter?.hotline])

    function renderHotline() {
        if (hotlineInfo) {
            return <Hotline headline={hotlineInfo.headline} email={hotlineInfo.email} phone={hotlineInfo.phone} valueColor={theme.colors?.dark} />
        }

        if (hotlineItems) {
            return hotlineItems.map((item, index) => (
                <Text key={`hotline_${index}`} modifiers="block">
                    {item}
                </Text>
            ))
        }
    }

    return (
        <UserSettingsArea icon="suggestion" title={translateText(823)}>
            {hotlineItems || hotlineInfo ? (
                renderHotline()
            ) : (
                <>
                    {licensorData && !!licensorData.supportNo && (
                        <Text modifiers="block">
                            {translateText(110)}:{" "}
                            <a className={linkClassName} href="tel:licensorData.supportNo">
                                {licensorData.supportNo}
                            </a>
                        </Text>
                    )}
                </>
            )}

            {supportMail && !hotlineInfo?.email && (
                <Text modifiers="block">
                    {translateText(109)}:{" "}
                    <a className={linkClassName} href={`mailto:${supportMail}}`}>
                        {supportMail}
                    </a>
                </Text>
            )}

            <Text modifiers="block">
                {translateText(2001)}: {(window as any).__version}
            </Text>

            {licensorData && !!licensorData.remoteMaintenanceUrl && (
                <Button layout={["holo"]} linkTo={licensorData.remoteMaintenanceUrl} target="_blank">
                    {translateText(1326)}
                </Button>
            )}
        </UserSettingsArea>
    )
}
