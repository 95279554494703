import { CisSalesOutlet } from "@tm/models"
import Note from "./Note"
import Address from "./Address"
import Area from "./Area"
import Contact from "./Contact"
import { StyledStack } from "./StyledComponents"

type Props = {
    salesOutlet: CisSalesOutlet
}

export default function SalesOutletComponent({ salesOutlet }: Props) {
    const { name, address, contacts, notes, description } = salesOutlet

    return (
        <Area name={name || description}>
            {address && <Address address={address} />}
            <StyledStack>{contacts?.map((contact, id) => <Contact key={id} contact={contact} />)}</StyledStack>
            <StyledStack>{notes?.map((note, id) => <Note key={id} note={note} />)}</StyledStack>
        </Area>
    )
}
