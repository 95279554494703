import { Box, DatePicker, formHelperTextClasses, styled, TextField } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Controller, UseFormReturn } from "react-hook-form"
import { useEffect } from "react"
import { UploadSchemaType } from "../../formSchemas/uploadSchema"
import { FileCategory } from "../../models"

const MAXMILEAGE = 9999999

export const StyledTextFieldsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: ".5em",
    alignItems: "center",
    marginLeft: "1em",
    marginBottom: "1em",
})

type Props = {
    formMethods: UseFormReturn<UploadSchemaType>
}

export function UploadData({ formMethods }: Props) {
    const { translateText, language } = useLocalization()

    const {
        control,
        watch,
        formState: { errors },
        setValue,
        resetField,
    } = formMethods

    const showInvoiceTotal = watch("category") === FileCategory.Invoice
    const mileage = watch("mileage")
    const invoiceTotal = watch("invoiceTotal")
    const documentDate = watch("documentDate")

    useEffect(() => {
        if (showInvoiceTotal) {
            resetField("invoiceTotal")
        } else {
            setValue("invoiceTotal", null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showInvoiceTotal])

    return (
        <StyledTextFieldsWrapper>
            <Controller
                name="mileage"
                control={control}
                render={({ field }) => (
                    <StyledTextField
                        {...field}
                        type="number"
                        size="large"
                        variant="filled"
                        label={translateText(125)}
                        onChange={(e) => field.onChange(e.target.value)}
                        error={!!errors.mileage}
                        helperText={errors.mileage?.message}
                        required
                        hasValue={Boolean(mileage)}
                        inputProps={{
                            max: MAXMILEAGE,
                        }}
                    />
                )}
            />
            <Controller
                name="documentDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        localisation={{ locale: language, region: null }}
                        onChange={(date) => field.onChange(date)}
                        size="large"
                        label={translateText(3089)}
                        InputProps={{
                            error: !!errors.documentDate,
                        }}
                        textfieldProps={{
                            error: !!errors.documentDate,
                        }}
                        renderInputOverride={
                            <StyledTextField
                                size="large"
                                variant="filled"
                                required
                                error={!!errors.documentDate}
                                helperText={errors.documentDate?.message}
                                hasValue={Boolean(documentDate)}
                                FormHelperTextProps={{
                                    error: !!errors.documentDate,
                                }}
                                InputProps={{
                                    error: !!errors.documentDate,
                                }}
                                InputLabelProps={{
                                    error: !!errors.documentDate,
                                }}
                            />
                        }
                    />
                )}
            />
            {showInvoiceTotal && (
                <Controller
                    name="invoiceTotal"
                    control={control}
                    render={({ field }) => (
                        <StyledTextField
                            {...field}
                            type="number"
                            size="large"
                            variant="filled"
                            label={translateText(3090)}
                            onChange={(e) => field.onChange(e.target.value)}
                            error={!!errors.invoiceTotal}
                            helperText={errors.invoiceTotal?.message}
                            required
                            hasValue={Boolean(invoiceTotal)}
                        />
                    )}
                />
            )}
        </StyledTextFieldsWrapper>
    )
}

export const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "hasValue" && prop !== "required",
})<{ hasValue?: boolean }>(({ hasValue }) => ({
    [`.${formHelperTextClasses.root}`]: {
        [`&.${formHelperTextClasses.contained}`]: {
            [`&.${formHelperTextClasses.error}`]: {
                display: "block",
                [`&.${formHelperTextClasses.focused}`]: {
                    display: "block",
                },
                transform: "translateY(calc(100% - 4px))",
                padding: "4px",
                zIndex: "1",
            },
        },
    },
    "& .MuiInputLabel-root": {
        transform: "translateY(10px)",
        fontSize: "12px",
        "&.Mui-focused::before": {
            marginLeft: "0px",
            fontSize: "12px",
        },
        "&::before": {
            content: '"*"',
            color: "red",
            ...(!hasValue && {
                fontSize: "18px",
            }),
            marginRight: "0.2em",
            marginLeft: "0.2em",
            verticalAlign: "middle",
        },
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
    },
}))
