import { useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import { Box, Loader } from "@tm/components"
import { channel } from "@tm/models"
import { useHistory } from "react-router"
import { fastServiceStore, managerActions, saveHistoryForWorktaskShare, useFastServiceStore } from "../../data"
import Main from "./component"
import { useFastServiceShareHistory } from "../../helpers/hooks/useFastServiceShareHistory"

const FastServiceShareKeyQuery = "fastShareKey"

type Props = RouteComponentProps<{ view: string }> & {
    route: string
}

export function HistoryWrapper(props: Props) {
    const history = useHistory()
    const [hydrated, setHydrated] = useState(false)
    const [historyRestored, setHistoryRestored] = useState(false)
    const [hasWorktaskId, setHasWorktaskId] = useState(false)
    const worktask = useWorkTask()?.workTask
    const { savedWorktaskId, hydrateWorktaskSwitch } = useFastServiceStore((state) => ({
        savedWorktaskId: state.worktaskId,
        hydrateWorktaskSwitch: state.hydrateWorktaskSwitch,
    }))

    const bundleShareKeyValue = new URLSearchParams(window.location.search)?.get(FastServiceShareKeyQuery)

    const { isLoading: sharedHistoryLoading, history: sharedHistory } = useFastServiceShareHistory(bundleShareKeyValue)

    useEffect(() => {
        if (!bundleShareKeyValue) {
            return
        }

        if (!sharedHistoryLoading && sharedHistory) {
            managerActions.setStateFromHistory(sharedHistory)
            managerActions.setHistoryLoading(false)
            setHydrated(true)
            const queryParams = new URLSearchParams(window.location.search)
            queryParams.delete(FastServiceShareKeyQuery)
            history.replace({
                search: queryParams.toString(),
            })
        }
    }, [bundleShareKeyValue, sharedHistory, sharedHistoryLoading])

    useEffect(() => {
        if (!hasWorktaskId && worktask) {
            setHasWorktaskId(true)
        }
    }, [worktask, hasWorktaskId])

    useEffect(() => {
        const worktaskShareUnsub = channel("WORKTASK").subscribe("WORKTASK/SHARE", () => {
            channel("WORKTASK").publish("WORKTASK/SHARE_RECEIVED", { module: "fast-service" })
            saveHistoryForWorktaskShare().then((data) =>
                channel("WORKTASK").publish("WORKTASK/SHARE_READY", {
                    module: "fast-service",
                    shareKey: FastServiceShareKeyQuery,
                    shareKeyValue: data?.calculationId,
                })
            )
        })

        const unsubHydrate = fastServiceStore.persist.onHydrate(() => setHydrated(false))
        const unsubFinishHydration = fastServiceStore.persist.onFinishHydration(() => {
            setHydrated(true)
            managerActions.hydrateWorktaskSwitch(false)
        })

        return () => {
            worktaskShareUnsub()
            unsubHydrate()
            unsubFinishHydration()
            setHistoryRestored(false)
            setHasWorktaskId(false)
            setHydrated(false)
        }
    }, [])

    useEffect(() => {
        if (bundleShareKeyValue || !hasWorktaskId) {
            return
        }

        if (!hydrated || (savedWorktaskId && savedWorktaskId !== worktask?.id)) {
            managerActions.resetStore()
            managerActions.hydrateWorktaskSwitch(true)
        }
    }, [hasWorktaskId, bundleShareKeyValue, hydrated, savedWorktaskId, worktask?.id])

    useEffect(() => {
        if (!hydrated && hydrateWorktaskSwitch) {
            fastServiceStore.persist.rehydrate()
        }
    }, [hydrated, hydrateWorktaskSwitch])

    useEffect(() => {
        if (!hydrated || !hasWorktaskId) {
            return
        }

        restoreHistory()
    }, [hydrated, hasWorktaskId])

    function restoreHistory() {
        setHistoryRestored(true)
    }

    if (historyRestored) {
        return <Main {...props} />
    }

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loader />
        </Box>
    )
}

export default withRouter(HistoryWrapper)
