import { create } from "zustand"
import { ServiceType } from "../models"

type ServicesState = {
    loadingServices: boolean
    searchValue: string
    services?: Array<ServiceType[]>
    setLoadingServices: (loading: boolean) => void
    setSearchValue: (value: string) => void
    setServices: (nodes?: Array<ServiceType[]>) => void
    resetServiceStore: () => void
}

const initialState = {
    loadingServices: false,
    searchValue: "",
    services: [],
}

export const useServicesStore = create<ServicesState>((set) => ({
    ...initialState,
    setLoadingServices: (loading) => set({ loadingServices: !!loading }),
    setSearchValue: (value) => set({ searchValue: value }),
    setServices: (services) => set({ services, loadingServices: false }),
    resetServiceStore: () => set(initialState),
}))
