import { useActions } from "@tm/morpheus"
import { initSelector, mapOePartToOeOePart } from "@tm/utils"
import { useSelector } from "react-redux"
import { useCallback, useMemo } from "react"
import { Article, OE } from "@tm/models"
import { ArticleListFeaturesDisableProvider } from "@bundles/parts/components/ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { DamageModulesType, ParticularStateProvider } from "@bundles/parts/components/PureList/ContextProviders/Particular/ParticularContext"
import { PureList } from "@bundles/parts"
import { ICalculationItem } from "@bundles/damage-calculation-eurotax/data/models"
import { itemsSelector, MainActions } from "../../main/business"

const selector = initSelector(itemsSelector)

type Props = {
    handleAlternativeClick: (item: ICalculationItem) => void
}

export function ArticleListV2(props: Props) {
    const actions = useActions(
        MainActions,
        "selectPart",
        "changeOeArticleQuantity",
        "selectOeArticle",
        "setErpArticles",
        "selectOePart",
        "changeArticleQuantity"
    )
    const [items] = useSelector(selector)

    function mapArticle(item: ICalculationItem) {
        if (item.selectedPart) {
            return item.selectedPart
        }
        return mapOePartToOeOePart(item.oeArticle)
    }

    const articles = useMemo(() => items?.map((i) => mapArticle(i)), [items])

    const toggleSelectedArticle = useCallback(
        (article: Article | OE.OePart) => {
            const { id } = article

            const item = items?.find((i) => i.oeArticle.oeArticleNumber === id || i.selectedPart?.id === id)
            if (!item) {
                return
            }
            return actions.selectOeArticle(item)
        },
        [items, actions]
    )

    const selectedArticles = useMemo(() => items?.filter((x) => x.isSelected).map((i) => mapArticle(i)), [items])

    const isArticleSelected = useCallback(
        (article: Article | OE.OePart) => {
            const { id } = article
            return !!selectedArticles?.find((x) => x.id === id)
        },
        [selectedArticles]
    )

    const onChangeQuantity = useCallback(
        (article: Article | OE.OePart, quantity: number) => {
            if ((article as OE.OePart).number) {
                const foundOe = items?.find((x) => x.oeArticle.oeArticleNumber === article.id)
                if (foundOe) {
                    return actions.changeOeArticleQuantity(foundOe, quantity)
                }
            }
            const foundArticle = items?.find((x) => x.selectedPart?.id === article.id)
            return actions.changeArticleQuantity(foundArticle!, quantity)
        },
        [items, actions]
    )

    const handleReplaceButton = useCallback(
        (item: Article | OE.OePart) => {
            if ((item as OE.OePart).number) {
                const oeFound = items?.find((x) => x.oeArticle.oeArticleNumber === item.id)
                if (oeFound) {
                    return props.handleAlternativeClick(oeFound)
                }
            }
            const articleFound = items?.find((x) => x.selectedPart?.id === item.id)
            return props.handleAlternativeClick(articleFound!)
        },
        [items, props.handleAlternativeClick]
    )

    const oeArticlesWithArticles = useMemo(
        () =>
            items?.map((item) => ({
                article: item.selectedPart || null,
                oeArticle: item.selectedPart ? null : mapOePartToOeOePart(item.oeArticle),
            })),
        [items]
    )

    const context: DamageModulesType = useMemo(
        () => ({
            type: "damageModulesList",
            articles: oeArticlesWithArticles,
            request: JSON.stringify(articles),
            selectedArticles,
            toggleSelectedArticle,
            isArticleSelected,
            handleReplaceButton,
            onChangeQuantity,
            isErpChannelActivated: true,
        }),
        [oeArticlesWithArticles, articles, selectedArticles, toggleSelectedArticle, isArticleSelected, handleReplaceButton, onChangeQuantity]
    )

    return (
        <ArticleListFeaturesDisableProvider disableErpChannel={false}>
            <ParticularStateProvider value={context}>
                <PureList listId="damageModulesList" listType="damageModulesList" />
            </ParticularStateProvider>
        </ArticleListFeaturesDisableProvider>
    )
}
