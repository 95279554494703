import { Box, Button, ButtonProps, Icon, Loader, Popover, Stack, Typography, buttonClasses, lighten, styled, TextField } from "@tm/components"

export const BoxWithAlignContent = styled(Box)({
    display: "flex",
    alignContent: "center",
})

export const StackRow = styled(Stack)({
    alignItems: "center",
})
StackRow.defaultProps = { direction: "row" }

export const LoaderSmall = styled(Loader)({})
LoaderSmall.defaultProps = { size: "extrasmall" }

export const OverflowTypography = styled(Typography)({
    overflow: "hidden",
    textOverflow: "ellipsis",
})

export const NumberTextField = styled(TextField)({
    position: "relative",
    width: 115,
    whiteSpace: "nowrap",
})
NumberTextField.defaultProps = { type: "number", size: "extralarge" }

type StyledBoxProps = {
    backgroundColor?: string
    boxWidth?: number
    color?: string
    padding?: string
}

export const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "backgroundColor" && prop !== "boxWidth" && prop !== "color" && prop !== "padding",
})<StyledBoxProps>(({ theme, backgroundColor, boxWidth, color, padding }) => ({
    backgroundColor: backgroundColor ?? theme.palette.highlight.main,
    width: boxWidth ?? 450,
    color: color ?? "",
    padding: padding ?? "",
}))

export const SmallIcon = styled(Icon)({
    width: "16px",
    height: "16px",
})

export const TotalStack = styled(Stack)({
    alignSelf: "flex-end",
    minWidth: "500px",
})
TotalStack.defaultProps = { px: 2, spacing: 0.5 }

export const HighlightContrastTypography = styled(Typography)(({ theme }) => ({
    color:
        theme.overwrites?.components?.basketSummary?.summaryTotals?.color ||
        theme.palette.getContrastText(
            theme.overwrites?.components?.basketSummary?.summaryTotals?.backgroundColor ||
                theme.overwrites?.components?.basketSummary?.component?.backgroundColor ||
                theme.palette.highlight.main
        ),
    opacity: 1,
}))

export const SummaryPopover = styled(Popover)({})
SummaryPopover.defaultProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "right",
    },
    sx: { pointerEvents: "none" },
}

export const LightContrastTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

export const LightColoredIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

export const OrderButton = styled(Button)<ButtonProps>(({ theme }) => {
    if (!theme.overwrites?.components?.orderButton) {
        return
    }
    const { backgroundColor, foregroundColor, hover } = theme.overwrites?.components?.orderButton || {}
    return {
        [`&.${buttonClasses.contained}`]: {
            backgroundColor,
            foregroundColor,
            "&:hover": {
                backgroundColor: hover?.backgroundColor,
                foregroundColor: hover?.foregroundColor,
            },
        },
    }
})
