import Morpheus from "@tm/morpheus"
import { VouchersBundleParams } from "@tm/models"
import { initCisBundle } from "@bundles/cis"
import { registerActions } from "./data"

export const version = {
    name: "vouchers",
    version: "1.0",
}

let bundleParams: VouchersBundleParams

export function initVouchersBundle(params: VouchersBundleParams) {
    bundleParams = params
    const { userContext } = window

    registerActions()
    if (userContext.hasMailRetoure) {
        // TODO: this is a temporary workaround. Should be removed when the Monolith is implemented
        initCisBundle(Morpheus.getParams("cis"))
    }
}

export function getBundleParams() {
    if (!bundleParams) {
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}
