import { memo, useEffect, useMemo, useRef, useState, MouseEvent, useCallback, PropsWithChildren, ReactNode } from "react"
import { Box, Collapse, List, Stack, TextField, Typography } from "@tm/components"
import {
    AccordionDetailsSmall,
    AccordionHeadline,
    AccordionSimple,
} from "../../../../../parts/src/components/ListV2/components/Filters/FiltersStyledComponents"
import IconWithTooltip from "../../../../../parts/src/components/_shared/icon-with-tooltip/IconWithTooltip"
import { Filter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { AvailabilityFilterType } from "../../../business"
import { ISensorFilters } from "../business"
import { SensorFilterItem } from "./SensorFilterItem"

export type AvailabilityFilter = {
    name: string
    value: AvailabilityFilterType
}

type FiltersAreaProps = PropsWithChildren<{
    path?: ISensorFilters
    headline: string
    data?: Filter[]
    onChange?: (filter: Filter | AvailabilityFilter | null, path?: ISensorFilters) => void
    onResetFilters?: (path: ISensorFilters | undefined) => void
    hideFilterSearch?: boolean
    hideActions?: boolean
    isExpanded?: boolean
    expandWhenChildIsSelected?: boolean
    seasons?: Filter[]
    children?: ReactNode
    onAccordionClick?: (key: string, value: boolean) => void
    dropdownPath?: "availability"
    selectedSensorsFilters?: Filter[] | undefined
    extendedAssortment?: boolean
}>

export const SensorsFiltersArea = memo(function FiltersAreaComponent(props: FiltersAreaProps) {
    const {
        data,
        headline,
        onResetFilters,
        hideFilterSearch,
        hideActions,
        path,
        onChange,
        isExpanded,
        children,
        onAccordionClick,
        dropdownPath,
        selectedSensorsFilters,
        extendedAssortment,
    } = props

    const { translateText } = useLocalization()

    const [showInput, setShowInput] = useState(false)
    const [groupFilter, setGroupFilter] = useState("")
    const [showMore, setShowMore] = useState(false)
    const textFieldRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (textFieldRef.current && showInput) {
            textFieldRef.current.focus()
        }
    }, [showInput])

    useEffect(() => {
        setShowMore(!!extendedAssortment)
    }, [extendedAssortment])

    const handleResetFilters = (event: MouseEvent) => {
        event.stopPropagation()
        onResetFilters?.(path)
    }

    const handleFilterCategory = (event: MouseEvent) => {
        event.stopPropagation()
        setShowInput(!showInput)
    }

    const handleSensorFilterChange = useCallback(
        (filter: AvailabilityFilter | Filter) => {
            onChange?.(filter, path)
        },
        [onChange, path]
    )

    const filteredData = useMemo(() => {
        if (data) {
            return data?.filter((filter) => filter.name?.toLowerCase().includes(groupFilter.toLowerCase())) || []
        }
    }, [data, groupFilter])

    const handleAccordionClick = () => {
        onAccordionClick?.(path || dropdownPath!, !isExpanded)
    }

    const getIsChecked = (filter: Filter) => {
        if (selectedSensorsFilters) {
            return selectedSensorsFilters.some((selected) => selected.name === filter.name)
        }
        return false
    }

    const handleShowMore = () => {
        setShowMore((prev) => !prev)
    }

    // #region MoreItems
    const renderMoreItems = () => {
        const noPriorityList = filteredData?.filter((filter) => filter.priority === undefined || filter.priority === 0)

        return noPriorityList?.map((filter, index) => {
            const isChecked = getIsChecked(filter)
            const handleChange = () => {
                handleSensorFilterChange(filter)
            }
            return <SensorFilterItem key={index} filter={filter} checked={isChecked} onChange={handleChange} />
        })
    }
    // #endregion MoreItems

    // #region SensorsList
    const renderSensorsList = () => {
        if (!data) {
            return
        }
        return (
            <List sx={{ p: 0 }}>
                {filteredData
                    ?.filter((filter) => filter?.priority === 1)
                    ?.map((filter, index) => {
                        const isChecked = getIsChecked(filter)
                        const handleChange = () => {
                            handleSensorFilterChange(filter)
                        }

                        return <SensorFilterItem key={index} filter={filter} checked={isChecked} onChange={handleChange} />
                    })}
                {filteredData && (
                    <Box className="selection-list__indent">
                        <Typography className=" is-clickable" fontSize="12px" marginTop="0.5em" onClick={handleShowMore}>
                            {translateText(showMore ? 169 : 168)}
                        </Typography>
                        {showMore && filteredData?.some((filter) => !filter?.priority) && renderMoreItems()}
                    </Box>
                )}
            </List>
        )
    }
    // #endregion SensorsList

    return (
        <AccordionSimple expanded={isExpanded} onChange={handleAccordionClick}>
            <AccordionHeadline sx={{ display: "flex" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                    <Typography ml={1}>{headline}</Typography>
                    <Stack direction="row" spacing={1} sx={{ mr: ".8em" }}>
                        {!(hideFilterSearch || hideActions) && <IconWithTooltip variant="search" onClick={handleFilterCategory} />}
                        {!hideActions && !!onResetFilters && <IconWithTooltip variant="remove-filter" onClick={handleResetFilters} />}
                    </Stack>
                </Stack>
            </AccordionHeadline>
            <AccordionDetailsSmall>
                <Collapse in={showInput} unmountOnExit>
                    <TextField
                        inputRef={textFieldRef}
                        size="small"
                        onChange={(e) => setGroupFilter(e.target.value)}
                        sx={{ margin: "4px 8px" }}
                        clearButton
                        variant="filled"
                        value={groupFilter}
                    />
                </Collapse>
                {children || <Box>{renderSensorsList()}</Box>}
            </AccordionDetailsSmall>
        </AccordionSimple>
    )
})
