import { Box, Icon, styled, Typography } from "@tm/components"
import Switch from "@mui/material/Switch"
import { useLocalization } from "@tm/localization"

type Props = {
    checked: boolean
    handleChange: (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}
export function SummaryHeaderV2({ checked, handleChange }: Props) {
    const { translateText } = useLocalization()

    return (
        <Box style={{ display: "flex", alignItems: "center", padding: "0.5em 1em" }}>
            <StyledSwitch
                size="medium"
                icon={<StyledIcon name="tire" />}
                checkedIcon={<StyledIcon name="vehicle-front" />}
                checked={checked}
                onChange={handleChange}
            />
            <Typography variant="h3">{checked ? translateText(13921) : translateText(13922)}</Typography>
        </Box>
    )
}

const StyledSwitch = styled(Switch)(({ theme }) => ({
    width: 80,
    height: 45,
    marginRight: "1em",
    "& .MuiSwitch-switchBase": {
        backgroundColor: theme.palette.primary.light,
        height: 45,
        width: 45,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
        "&.Mui-checked": {
            transform: "translateX(35px)",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
            },
            "& + .MuiSwitch-track": {
                backgroundColor: "black",
                opacity: "0.1",
            },
        },
        "& + .MuiSwitch-track": {
            opacity: "0.1",
            borderRadius: 10,
        },
    },
}))

const StyledIcon = styled(Icon)({
    fill: "#fff",
    width: "2em",
    height: "2em",
})
