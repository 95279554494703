import { Article } from "@tm/models"
import { mapRimDetailsArticleWithArticleToGenerailItem, mapRimDetailsArticleWithoutArticleToGenerailItem } from "../mapper"
import { RimDetailsArticle } from "../model"

export function mapRimItemForOverview(selectedRimArticle: RimDetailsArticle): Article {
    if (selectedRimArticle.articleDetails && Object.keys(selectedRimArticle.articleDetails).length > 1) {
        return mapRimDetailsArticleWithArticleToGenerailItem(selectedRimArticle) as Article
    }

    return mapRimDetailsArticleWithoutArticleToGenerailItem(selectedRimArticle) as Article
}
