import { css, StyleProps, withStyle } from "@tm/context-distribution"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { bindSpecialReactMethods, RouteComponentProps, withRouter } from "@tm/utils"
import { em } from "csx"
import { Component } from "react"
import { connector } from "../../../../data/helpers"
import { MainState } from "../../../main"
import { GraphicViewer } from "../../../_shared"
import { Actions, DetailsState, IActions } from "../../business"
import DetailsImages from "./detailsImages"

type Props = LocalizationProps &
    StyleProps<typeof stylesheet> &
    StoreProps &
    RouteComponentProps & {
        actions: IActions
    }

type StoreProps = {
    parts: DetailsState["parts"]
    mainVehicle: MainState["summary"]["selectedMmtGroup"]
    selectedCriterias: DetailsState["selectedCriterias"]
    selectedVKNImage: DetailsState["selectedVKNImage"]
    vknImages: DetailsState["vknImages"]
}

export class Category extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleXButton(e: React.MouseEvent<HTMLElement>) {
        const { actions } = this.props
        e.stopPropagation()
        // actions.criteriaReset() TODO
        actions.loadImagePositions()
    }

    handleGraphicalClick(value: string) {
        const { actions, mainVehicle } = this.props

        if (mainVehicle) {
            const erePosition = mainVehicle.positions?.find((x: { name: string }) => x.name == value)
            erePosition && actions.selectErePosition(erePosition)
            actions.loadVKNImages()
        }
    }

    render() {
        const { mainVehicle, style } = this.props
        const items = mainVehicle?.positions?.map((x: { name: any; description: any }) => ({ id: x.name, tooltip: x.description, isVisible: true }))

        return (
            <div className={style.category}>
                {mainVehicle && (
                    <GraphicViewer
                        className={style.graphic}
                        svgImage={mainVehicle.svg}
                        selected={mainVehicle.selectedErePosition?.name}
                        items={items}
                        onClick={this.handleGraphicalClick}
                    />
                )}

                <DetailsImages />
            </div>
        )
    }
}

function mapStateToProps(state: MainState): StoreProps {
    const { selectedCriterias, parts, selectedVKNImage, vknImages } = state.details
    const { selectedMmtGroup: mainVehicle } = state.summary

    return {
        mainVehicle,
        parts,
        selectedCriterias,
        selectedVKNImage,
        vknImages,
    }
}

function stylesheet() {
    return css({
        category: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
        },
        graphic: {
            height: em(14),
        },
    })
}

export default connector(withLocalization(withRouter(withStyle(stylesheet, Category))), mapStateToProps, Actions)
