import { useState, useRef, KeyboardEvent, useMemo, useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { RepairTimeProvider, VatRate, WorkCategory, WorkType } from "@tm/models"
import { ButtonKeyDefinition } from "@tm/utils"
import { Stack, Switch, TextField, Icon, Button, Typography, Tooltip, MenuItem, SelectTextField, ToggleSwitch } from "@tm/components"
import { EditWorkMode } from "../../../business"
import { ValueLabelStack } from "../../../../ValueLabelStack"
import { CostEstimation, VatRateSelectionItem } from "../../../../../data/model"
import { StackRow, NumberTextField } from "../../../../StyledComponents"
import DialogHeaderButtons from "../../../../_shared/DialogHeaderButtons"
import { BasicBasketWorkInfo, BasketWork } from "../../../../../models"
import { VatRateSelector } from "../../../../VatRateSelector"
import { useWorkCategoryItems } from "../../../../../hooks/useWorkCategoryItems"

type Props = {
    work?: BasketWork
    costEstimation: CostEstimation
    currencyCode?: string
    icon?: string
    iconTooltip?: string
    repairTimeDivision: number
    showRepairTimesInHours: boolean
    showSaveAsCustomRepairTimeButton?: boolean
    title: string
    onClose(): void
    onConfirm(saveAsCustomRepairTime: boolean, editBasketWorkInfo: BasicBasketWorkInfo, editWorkMode?: EditWorkMode): void
}

const MAX_POSSIBLE_NUMBER = 99999.99
const PERCENTAGE_WIDTH = 95

export default function WorkDialogFields(props: Props) {
    const {
        work,
        costEstimation,
        icon,
        title,
        iconTooltip,
        showRepairTimesInHours,
        repairTimeDivision,
        showSaveAsCustomRepairTimeButton,
        currencyCode,
    } = props
    const { translateText } = useLocalization()
    const { workItem, estimatedWork, calculatedWork } = work || {}
    const { hourlyRates, customerDefaults, defaultWorkVatRates } = costEstimation

    const calculatedPrice = calculatedWork?.offerPrice?.value
    const description = estimatedWork?.description ?? workItem?.description
    const fixedPriceValue = calculatedWork?.regularPrice?.value
    const hourlyRate = calculatedWork?.hourlyRate?.value ?? workItem?.hourlyRate
    const isFixedPrice = calculatedWork?.isFixedPrice
    const numberAndDescriptionMandatory = workItem?.provider !== undefined && workItem.provider !== RepairTimeProvider.Eurotax
    const price = calculatedWork?.regularPrice?.value
    const rebate = work ? (calculatedWork?.rebate ?? workItem?.rebate) : customerDefaults?.worksRebate
    const surcharge = work ? (calculatedWork?.surcharge ?? workItem?.surcharge) : customerDefaults?.worksSurcharge
    const time = calculatedWork?.repairTime ?? estimatedWork?.displayTime?.value
    const workCategory = workItem?.category
    const workNumber = workItem?.displayNumber || workItem?.providerWorkId
    const workType = workItem?.type
    const vatRates = useMemo(() => {
        return work ? workItem?.vatRates : defaultWorkVatRates
    }, [work, workItem?.vatRates, defaultWorkVatRates])

    const rates = Object.values(hourlyRates)
    const originalHourlyRate = useMemo(() => {
        return hourlyRate || (rates[WorkCategory.WorkshopWork] ? rates[WorkCategory.WorkshopWork] : rates[WorkCategory.NotCategorized])
    }, [hourlyRate, rates])

    const actualCurrencyCode = currencyCode ?? workItem?.currencyCode

    const workCategoryItems = useWorkCategoryItems(hourlyRates)

    const [actualWorkNumber, setWorkNumber] = useState<string | undefined>(workNumber)
    const [actualDescription, setDescription] = useState<string | undefined>(description)
    const [actualTime, setTime] = useState<number | undefined>(time)
    const [actualHourlyRate, setHourlyRate] = useState<number | undefined>(originalHourlyRate)
    const [actualFixedPrice, setFixedPrice] = useState<number | undefined>(fixedPriceValue)
    const [actualWorkCategory, setWorkCategory] = useState<WorkCategory | undefined>(workCategory || WorkCategory.WorkshopWork)
    const originalRebate = rebate ?? 0
    const [actualRebate, setRebate] = useState(originalRebate)
    const originalSurcharge = surcharge ?? 0
    const [actualSurcharge, setSurcharge] = useState(originalSurcharge)
    const [actualCalculatedPriceHourlyRate, setCalculatedPriceHourlyRate] = useState<number | undefined>(calculatedPrice)
    const [actualCalculatedPriceFixedPrice, setCalculatedPriceFixedPrice] = useState<number | undefined>(calculatedPrice)
    const [actualPrice, setPrice] = useState<number | undefined>(price)
    const [saveAsCustomRepairTime, setSaveAsCustomRepairTime] = useState(false)
    const [originalFieldsExpanded, setOriginalFieldsExpanded] = useState<boolean>(true)
    const [showHourlyRateFields, setShowHourlyRateFields] = useState<boolean>(!isFixedPrice)
    const [selectedVatRate, setSelectedVatRate] = useState<VatRateSelectionItem | undefined>(vatRates?.find((v) => v.isSelected))
    const [fieldsChanged, setFieldsChanged] = useState(false)
    const [confirmButtonClicked, setConfirmButtonClicked] = useState(false)

    const workNumberRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLInputElement>(null)
    const workCategoryRef = useRef<HTMLSelectElement>(null)
    const timeRef = useRef<HTMLInputElement>(null)
    const confirmationRef = useRef<HTMLButtonElement>(null)

    const repairTimesTextId = showRepairTimesInHours ? 84 : 1550

    const invalidFields = useMemo(() => {
        if (
            !actualDescription ||
            (numberAndDescriptionMandatory && !actualWorkNumber) ||
            (showHourlyRateFields && (!actualHourlyRate || actualHourlyRate === 0 || !actualTime || actualTime === 0)) ||
            (!showHourlyRateFields && !actualFixedPrice)
        ) {
            return true
        }
        return false
    }, [numberAndDescriptionMandatory, actualWorkNumber, actualDescription, actualFixedPrice, actualTime, actualHourlyRate, showHourlyRateFields])

    const resetFields = useCallback(() => {
        if (fieldsChanged) {
            setWorkNumber(workNumber)
            setDescription(description)
            setSaveAsCustomRepairTime(false)
            setWorkCategory(workCategory)
            setFixedPrice(fixedPriceValue)
            setTime(time)
            setSurcharge(originalSurcharge)
            setRebate(originalRebate)
            setSelectedVatRate(vatRates?.find((val) => val.isSelected))
            setHourlyRate(originalHourlyRate)
        }
    }, [fieldsChanged, workNumber, description, workCategory, fixedPriceValue, time, originalSurcharge, originalRebate, vatRates, originalHourlyRate])

    const handleSaveAsCustomWork = useCallback(() => {
        setFieldsChanged(true)
        setSaveAsCustomRepairTime((prev) => !prev)
    }, [])

    const mappedVatRate: VatRate | undefined = useMemo(() => {
        return selectedVatRate
            ? {
                  vatRate: selectedVatRate?.vatValue,
                  vatType: selectedVatRate?.vatType,
              }
            : undefined
    }, [selectedVatRate])

    function handleWorkNumberChange(newWorkNumber: string) {
        setWorkNumber(newWorkNumber)
        if (newWorkNumber !== workNumber) {
            setFieldsChanged(true)
        }
    }

    function handleArticleDescriptionChange(newDescription: string) {
        setDescription(newDescription)
        if (newDescription !== description) {
            setFieldsChanged(true)
        }
    }

    function calculateHourlyRatePrices(
        newSurcharge: number | undefined,
        newHourlyRate: number | undefined,
        newTime: number | undefined,
        newRebate: number | undefined
    ) {
        const rate = (newHourlyRate || 0) + (newHourlyRate || 0) * ((newSurcharge || 0) / 100)
        const newPrice = (rate * (newTime || 0)) / (repairTimeDivision > 0 ? repairTimeDivision : 1)
        setPrice(newPrice)
        setCalculatedPriceHourlyRate(newPrice - newPrice * ((newRebate || 0) / 100))
        setFieldsChanged(true)
    }

    function calculateFixedPricePrices(fixedPrice: number | undefined, rebate: number | undefined) {
        setCalculatedPriceFixedPrice((fixedPrice || 0) - (fixedPrice || 0) * ((rebate || 0) / 100))
        setFieldsChanged(true)
    }

    function handleWorkNumberKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter) {
            descriptionRef.current?.focus()
        }
    }

    function handleDescriptionKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter) {
            workCategoryRef.current?.focus()
        }
    }

    function handleWorkCategoryChange(newCategory: WorkCategory) {
        setWorkCategory(newCategory)
        const newHourlyRate = rates[newCategory] ?? rates[WorkCategory.NotCategorized]
        setHourlyRate(newHourlyRate)
        calculateHourlyRatePrices(actualSurcharge, newHourlyRate, actualTime, actualRebate)
        if (newCategory !== workCategory) {
            setFieldsChanged(true)
        }
    }

    function handleHourlyRateKeyDown(e: KeyboardEvent) {
        if (e.key === (ButtonKeyDefinition.Enter || ButtonKeyDefinition.Tab)) {
            timeRef.current?.focus()
        }
    }

    function handleHourlyRateChange(value: number | undefined) {
        if (actualWorkCategory) {
            setHourlyRate(value || 0)
            calculateHourlyRatePrices(actualSurcharge, value, actualTime, actualRebate)
        }
    }

    function handleTimeChange(value: number | undefined) {
        setTime(value || 0)
        calculateHourlyRatePrices(actualSurcharge, actualHourlyRate, value, actualRebate)
    }

    function handleLastFieldKeyDown(e: KeyboardEvent) {
        if (e.key === (ButtonKeyDefinition.Enter || ButtonKeyDefinition.Tab)) {
            if (!actualWorkNumber) {
                workNumberRef.current?.focus()
            } else if (!actualDescription) {
                descriptionRef.current?.focus()
            } else {
                confirmationRef.current?.focus()
            }
        }
    }

    function handleChangeRebate(newRebate: number) {
        setRebate(newRebate || 0)
        calculateHourlyRatePrices(actualSurcharge, actualHourlyRate, actualTime, newRebate)
        calculateFixedPricePrices(actualFixedPrice, newRebate)
    }

    function handleChangeSurcharge(newSurcharge: number) {
        setSurcharge(newSurcharge || 0)
        calculateHourlyRatePrices(newSurcharge, actualHourlyRate, actualTime, actualRebate)
    }

    function handleFixedPriceChange(value: number | undefined) {
        setFixedPrice(value || 0)
        calculateFixedPricePrices(value, actualRebate)
    }

    function handleVatRateSelection(newVatRate: VatRateSelectionItem) {
        setSelectedVatRate(newVatRate)
        if (newVatRate !== selectedVatRate) {
            setFieldsChanged(true)
        }
    }

    function handleConfirmButtonClick() {
        if (invalidFields) {
            setConfirmButtonClicked(true)
        } else {
            setConfirmButtonClicked(false)
            let editWorkMode
            if (
                workType &&
                workType !== WorkType.CustomWork &&
                (workNumber !== actualWorkNumber || description !== actualDescription || workCategory !== actualWorkCategory)
            ) {
                editWorkMode = EditWorkMode.replaceWorkWithCustomWork
            } else if (workType && workType === WorkType.CustomWork && (actualWorkNumber || actualDescription)) {
                editWorkMode = EditWorkMode.editCustomWork
            } else if (workType && (actualWorkNumber || actualDescription)) {
                editWorkMode = EditWorkMode.editRepairTime
            }

            props.onConfirm(
                saveAsCustomRepairTime,
                {
                    workNumber: actualWorkNumber || "",
                    categoryOfWork: actualWorkCategory || WorkCategory.WorkshopWork,
                    description: actualDescription || "",
                    time: actualTime,
                    hourlyRate: showHourlyRateFields ? actualHourlyRate : undefined,
                    fixedPriceValue: !showHourlyRateFields ? actualFixedPrice : undefined,
                    rebate: actualRebate,
                    surcharge: showHourlyRateFields ? actualSurcharge : undefined,
                    vatRate: mappedVatRate,
                },
                editWorkMode
            )
            setSaveAsCustomRepairTime(false)
            props.onClose()
        }
    }

    function renderHeader() {
        return (
            <StackRow justifyContent="space-between">
                <StackRow gap={1} flex="0 0 auto">
                    {icon && (
                        <Tooltip title={iconTooltip || ""} variant="light">
                            <Icon name={icon} />
                        </Tooltip>
                    )}
                    <Typography>{title}</Typography>
                </StackRow>
                <StackRow gap={2}>
                    {showSaveAsCustomRepairTimeButton && (
                        <Switch
                            label={translateText(9)}
                            checked={saveAsCustomRepairTime}
                            labelPlacement="start"
                            size="small"
                            sx={{ mr: 0.5 }}
                            onChange={handleSaveAsCustomWork}
                        />
                    )}
                    <DialogHeaderButtons
                        disableConfirmButton={!fieldsChanged || (confirmButtonClicked && invalidFields)}
                        disableResetButton={!fieldsChanged}
                        onCloseButtonClick={props.onClose}
                        onConfirmButtonClick={handleConfirmButtonClick}
                        onResetButtonClick={resetFields}
                    />
                </StackRow>
            </StackRow>
        )
    }

    function renderBasicInfo() {
        return (
            <StackRow gap={1} flex="1 1 auto">
                <TextField
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    inputRef={workNumberRef}
                    label={translateText(58)}
                    size="extralarge"
                    required={numberAndDescriptionMandatory}
                    validateNow={confirmButtonClicked}
                    value={actualWorkNumber}
                    onChange={(e) => handleWorkNumberChange(e.target.value)}
                    onKeyDown={handleWorkNumberKeyDown}
                />
                <TextField
                    fullWidth
                    inputProps={{ maxLength: 200 }}
                    inputRef={descriptionRef}
                    label={translateText(131)}
                    required
                    validateNow={confirmButtonClicked}
                    size="extralarge"
                    value={actualDescription}
                    onChange={(e) => handleArticleDescriptionChange(e.target.value)}
                    onKeyDown={handleDescriptionKeyDown}
                />
                <SelectTextField
                    fullWidth
                    value={
                        actualWorkCategory
                            ? workCategoryItems.find((x) => x.category === actualWorkCategory)?.category
                            : workCategoryItems[0].category
                    }
                    onChange={(e) => handleWorkCategoryChange(parseInt(e.target.value))}
                    inputRef={workCategoryRef}
                    size="large"
                >
                    {workCategoryItems.map((item, idx) => {
                        return (
                            <MenuItem key={idx} value={item.category}>
                                {translateText(item.textId)}
                            </MenuItem>
                        )
                    })}
                </SelectTextField>
            </StackRow>
        )
    }

    function renderHourlyRateFields() {
        return (
            <StackRow gap={1}>
                <NumberTextField
                    label={`${translateText(12475)} %`}
                    value={actualSurcharge}
                    sx={{ width: PERCENTAGE_WIDTH }}
                    inputProps={{
                        min: 0,
                        max: 9999,
                        step: 1,
                    }}
                    onChange={(e) => handleChangeSurcharge(Number(e.target.value))}
                />
                <NumberTextField
                    onKeyDown={handleHourlyRateKeyDown}
                    label={translateText(63)}
                    value={actualHourlyRate}
                    required={showHourlyRateFields}
                    validateNow={confirmButtonClicked}
                    inputProps={{
                        min: 0.1,
                        max: MAX_POSSIBLE_NUMBER,
                        step: 0.1,
                    }}
                    onChange={(e) => handleHourlyRateChange(Number(e.target.value))}
                />
                <NumberTextField
                    onKeyDown={handleLastFieldKeyDown}
                    ref={timeRef}
                    label={translateText(repairTimesTextId)}
                    value={actualTime}
                    required={showHourlyRateFields}
                    validateNow={confirmButtonClicked}
                    inputProps={{
                        min: 0.1,
                        max: MAX_POSSIBLE_NUMBER,
                        step: 0.1,
                    }}
                    onChange={(e) => handleTimeChange(Number(e.target.value))}
                />
                <ValueLabelStack label={`${translateText(57)} ${actualCurrencyCode}`} value={actualPrice ?? 0} />
            </StackRow>
        )
    }

    function renderFixedPriceFields() {
        return (
            <StackRow gap={1}>
                <NumberTextField
                    onKeyDown={handleLastFieldKeyDown}
                    inputRef={timeRef}
                    label={translateText(repairTimesTextId)}
                    value={actualTime}
                    inputProps={{
                        min: 0,
                        max: MAX_POSSIBLE_NUMBER,
                        step: 1,
                    }}
                    onChange={(e) => handleTimeChange(Number(e.target.value))}
                />
                <NumberTextField
                    onKeyDown={handleLastFieldKeyDown}
                    label={translateText(246)}
                    value={actualFixedPrice}
                    required={!showHourlyRateFields}
                    validateNow={confirmButtonClicked}
                    inputProps={{
                        min: 0,
                        max: MAX_POSSIBLE_NUMBER,
                        step: 1,
                    }}
                    onChange={(e) => handleFixedPriceChange(Number(e.target.value))}
                />
            </StackRow>
        )
    }

    function renderCalculationFields() {
        return (
            <StackRow gap={1}>
                {originalFieldsExpanded && (
                    <StackRow gap={1}>
                        <ToggleSwitch
                            size="small"
                            right={{ value: translateText(246), key: true }}
                            left={{ value: translateText(63), key: true }}
                            selected={!showHourlyRateFields}
                            onChange={() => setShowHourlyRateFields(!showHourlyRateFields)}
                        />
                        {showHourlyRateFields ? renderHourlyRateFields() : renderFixedPriceFields()}
                    </StackRow>
                )}
                {work && (
                    <Button
                        onClick={() => setOriginalFieldsExpanded(!originalFieldsExpanded)}
                        startIcon={<Icon name={originalFieldsExpanded ? "arrow-right" : "arrow-left"} />}
                        variant="text"
                        size="large"
                    />
                )}
                <NumberTextField
                    label={`${translateText(54)} %`}
                    value={actualRebate}
                    sx={{ width: PERCENTAGE_WIDTH }}
                    inputProps={{
                        min: 0,
                        max: 100,
                        step: 1,
                    }}
                    onChange={(e) => handleChangeRebate(Number(e.target.value))}
                />
                <ValueLabelStack
                    label={`${translateText(57)} ${currencyCode}`}
                    value={showHourlyRateFields ? (actualCalculatedPriceHourlyRate ?? 0) : (actualCalculatedPriceFixedPrice ?? 0)}
                />
                {vatRates && <VatRateSelector vatRates={vatRates} onChange={handleVatRateSelection} />}
            </StackRow>
        )
    }

    return (
        <Stack spacing={2}>
            {renderHeader()}
            {renderBasicInfo()}
            {renderCalculationFields()}
        </Stack>
    )
}
