import {
    Article,
    GetArticleListByMatchCodeRequest,
    ListFilter,
    OE,
    OeArticlesWithArticles,
    PureArticleListType,
    TyreArticleAttribute,
} from "@tm/models"
import { createContext, useContextSelector } from "use-context-selector"
import { ReactNode } from "react"
import { AvailabilityFilterType } from "../../../../business"
import { ArticleType, RequestType } from "../../../WheelsList/ContextProvider/WheelsList"

type Base<TArticle = unknown, TArticleAction = unknown, TRequest = string> = {
    type: PureArticleListType
    articleItemType?: "default" | "Tyres"
    articles?: TArticle[]
    request: TRequest | undefined
    onDelete?: (item: TArticleAction) => void
    onChangeQuantity?: (article: TArticleAction, quantity: number) => void
    onAddToBasket?: (article: TArticleAction, quantity: number) => void
    isErpChannelActivated?: boolean
    loadNextItems?: boolean
}

export type WheelsOverviewType = Base<Article, Article, string> &
    SelectionItem<Article> & {
        type: "wheelsOverview"
        selectedArticles?: Article[]
    }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TyresListType = Base<any, Article, GetArticleListByMatchCodeRequest> & {
    type: "tyresList"
    selectedAvailability: AvailabilityFilterType
    maxAutoRequest?: number
    selectedFilters?: ListFilter[]
    handleClickAttribute?: (articleAttribute: TyreArticleAttribute) => void
}

export type DamageModulesType = Base<OeArticlesWithArticles, Article | OE.OePart, string> &
    SelectionItem<Article | OE.OePart> & {
        type: "damageModulesList"
        handleReplaceButton?: (article: Article | OE.OePart) => void
        selectedArticles?: (Article | OE.OePart)[]
    }

export type WheelsListType = Base<never, Article, RequestType> &
    SelectionItem<Article> & {
        type: "wheelsSensorsList" | "wheelsTyresList"
        fetchArticles: (request: RequestType) => Promise<ArticleType>
        selectedArticles?: Article[]
        toggleSelectedArticle: (article: Article) => void
        selectedAvailability: AvailabilityFilterType
        handleAutoNextPage(): void
        initializeScrollContainerSelector?: string
        articleGroupHeader?: ReactNode
        headerTitle: string
        headerTitleContent?: ReactNode
        setArticleCount?: (number: number) => void
        selectedFilters?: ListFilter[]
        handleClickAttribute?: (articleAttribute: TyreArticleAttribute) => void
    }

type MergeUnionOfRecordTypes<U extends Record<string, unknown>> = {
    [K in U extends unknown ? keyof U : never]: U extends unknown ? (K extends keyof U ? U[K] : never) : never
}

type SelectionItem<T extends { id?: number | string } | undefined> = T extends undefined
    ? undefined
    : {
          maximumPartsToCompare?: number
          toggleSelectedArticle(article: T): void
          isArticleSelected?(article: T): boolean
          largeCheckbox?: boolean
      }

export type ArticleSelectionItem = SelectionItem<undefined>

export type ParticularState = WheelsOverviewType | TyresListType | WheelsListType | DamageModulesType

export type CombinedParticularState = MergeUnionOfRecordTypes<ParticularState>

const ParticularContext = createContext<ParticularState | undefined>(undefined)

export const ParticularStateProvider = ParticularContext.Provider

export function useParticularContext<TContext extends CombinedParticularState | ParticularState>(): TContext | undefined
export function useParticularContext<TContext extends CombinedParticularState | ParticularState, TReturnType>(
    selector: (value: TContext) => TReturnType | undefined
): TReturnType | undefined
export function useParticularContext<TContext extends CombinedParticularState | ParticularState, TReturnType>(
    selector?: (value: TContext) => TReturnType | undefined
): TContext | TReturnType | undefined {
    return useContextSelector(ParticularContext, (context) => {
        if (!selector) {
            return context
        }

        return selector(context as TContext)
    }) as TContext | TReturnType
}
