import { AddCustomPartListRequest } from "@tm/models"

import { TmaHelper } from "@tm/utils"
import { PostMessageRequest } from "../../../data"
import { getBasketPositionMemo } from "../business/helpers"
import { PostMessageControllerComponent } from "../component"

export default function handleAddFillQuantityToBasket(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { addFillQuantityToBasket, sourceId } = data
    const { workTask, userSettings, workTaskTruckData, addCustomPartList } = this.props

    if (addFillQuantityToBasket && workTask) {
        const request: AddCustomPartListRequest = {
            workTaskId: workTask.id,
            customerId: workTask.customer && workTask.customer.id,
            vehicleId: workTask.vehicle && workTask.vehicle.id,
            customParts: [
                {
                    description: addFillQuantityToBasket.amount,
                    quantityValue: addFillQuantityToBasket.quantity,
                    memo: getBasketPositionMemo(userSettings?.orderOptions, workTask, workTaskTruckData),
                },
            ],
            log: TmaHelper.PostMessage.AddToBasket.GetUserInteractionLog(sourceId),
        }

        addCustomPartList(request, true)
    }
}
