import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { Restriction } from "../../model"
import { RimRestrictionRequest } from "./model"

export function loadRimRestrictions(request: RimRestrictionRequest) {
    const url = `${getServiceUrl()}/GetRimsRestrictions`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Restriction[]>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => resolve(response?.rimsRestrictions ?? []), reject)
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}
