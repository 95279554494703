import { useDispatch, useSelector } from "react-redux"
import { styled } from "@tm/components"
import { useEffect } from "react"
import { MainState } from "../../main"
import { Actions } from "../business"
import RimInformation from "./rim-information"
import RimDetails from "./rim-details"
import { ContinueButton } from "./continueButton"

type WheelsDetailsComponentProps = {
    repairTimesRoute: string
}

const StyledWrapper = styled("div")(() => ({
    display: "flex",
    flex: 1,
}))

export function RimDetailsV1({ repairTimesRoute }: WheelsDetailsComponentProps) {
    const dispatch = useDispatch()

    const { selectedSize, initialized } = useSelector((s: MainState) => s.wheelsDetails)

    useEffect(() => {
        selectedSize && !initialized && dispatch(Actions.loadRimDetailsArticles())
    }, [])

    return (
        <StyledWrapper className="wheels-details">
            <ContinueButton />
            <RimDetails repairTimesRoute={repairTimesRoute} />
            <RimInformation />
        </StyledWrapper>
    )
}
