import { ButtonKeyDefinition, concat } from "@tm/utils"
import { Dropdown, TextField, ButtonLayout } from "@tm/controls"
import { ComponentType, useCallback, useEffect, useRef, useState } from "react"
import { TyreFilter } from "@tm/models"
import { Box, Button, Icon, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { getTextIdByFilterName } from "@bundles/tyres/data/helpers"
import { IAllFilters } from "./DiameterFilters"

type Props = {
    filterName: IAllFilters
    isActive: boolean
    items: TyreFilter[]
    selectedValue?: TyreFilter
    defaultItem?: string
    withResetButton?: boolean
    disabled?: boolean
    onChange?(filterName: IAllFilters, value: TyreFilter | undefined): void
    hasInput?: boolean
    loading?: boolean
    label?: string
    layout?: ButtonLayout[]
    className?: string
    maxItemsToShow?: number
    submitOnTab?: boolean
    onDropdownClick?: () => void
    disableAutoSelect?: boolean
    ItemView?: ComponentType<TyreFilter>
    CoverView?: ComponentType
    customCoverView?: boolean
}

export function CustomDropdown({
    filterName,
    onDropdownClick,
    isActive,
    label,
    items,
    defaultItem,
    selectedValue,
    withResetButton,
    className,
    disabled,
    hasInput,
    loading,
    layout,
    maxItemsToShow,
    submitOnTab,
    onChange,
    disableAutoSelect,
    ItemView,
    CoverView,
    customCoverView,
}: Props) {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const inputRef = useRef<TextField | null>(null)
    const dropdownRef = useRef<Dropdown<TyreFilter> | null>(null)
    const { translateText } = useLocalization()

    const [inputValue, setInputValue] = useState("")

    const dropDownItems = !inputValue ? [...items] : items.filter((x) => x.query.includes(inputValue))

    if (defaultItem) {
        const value = defaultItem.charAt(0).toUpperCase() + defaultItem.slice(1)
        dropDownItems.unshift({ query: "default", value, group: filterName }) // add default item into array
    }

    const foundItem = dropDownItems.find((item) => item.query === selectedValue?.query)
    const selectedItem = foundItem || (!disableAutoSelect && !!defaultItem && dropDownItems.length === 2 ? dropDownItems[1] : dropDownItems.first())

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = null
        }

        timeoutRef.current = setTimeout(() => {
            if (!dropdownRef?.current) {
                return
            }

            if (isActive && !dropdownRef.current.state.open) {
                dropdownRef.current.toggleDropdownMenu()
                return
            }

            if (!isActive && dropdownRef.current.state.open) {
                dropdownRef.current.toggleDropdownMenu()
            }
        }, 50)
    }, [isActive, loading])

    useEffect(() => {
        if (disableAutoSelect || !inputValue || !selectedItem) {
            return
        }

        const firstItem = dropDownItems?.filter((x) => x.query !== "default")?.first()

        if (dropDownItems?.length === (defaultItem ? 2 : 1) && firstItem?.query === inputValue) {
            setInputValue("")
            onChange?.(filterName, firstItem!)
        }
    }, [inputValue])

    const handleDropdownOpen = () => setTimeout(() => inputRef?.current?.focus(), 100)

    const handleOnClose = () => {
        if (inputValue?.length) {
            setTimeout(() => setInputValue(""), 50)
        }

        if (!dropdownRef.current?.state.alreadyFocused) {
            dropdownRef.current?.setState({ alreadyFocused: true })
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        switch (e.key) {
            case ButtonKeyDefinition.Enter:
            case ButtonKeyDefinition.Tab:
                {
                    e.preventDefault()
                    e.stopPropagation()

                    if (!dropdownRef?.current?.state) {
                        return
                    }

                    const { startShowIndex, preSelectedItemIndex } = dropdownRef.current.state

                    let selectedListItem = dropDownItems[preSelectedItemIndex + startShowIndex]

                    if (selectedListItem?.query === "default" && items.some((x) => x.query === inputValue)) {
                        selectedListItem = items.find((x) => x.query === inputValue)!
                    }
                    onChange?.(filterName, selectedListItem)
                    handleOnClose()
                }
                break
            default:
                break
        }
    }

    const handleDropDownChange = (dropDownItem: TyreFilter | undefined) => {
        if (dropDownItem?.query === "default") {
            if (items.some((x) => x.query === inputValue)) {
                dropDownItem = items.find((x) => x.query === inputValue)!
            } else {
                dropDownItem = undefined
            }
        }
        onChange?.(filterName, dropDownItem)
        handleOnClose()
    }

    const handleInputConfirm = (value: string) => {
        const selectedInputItem = items.find((x) => x.query === value)
        if (selectedInputItem) {
            onChange?.(filterName, selectedInputItem)
        }
        handleOnClose()
    }

    const handleOnTextInputChange = (value: string) => {
        setInputValue(value)
    }

    const renderDropDownInput = () => {
        return (
            <TextField
                onKeyDown={handleKeyPress}
                label={label}
                floatingLabel
                value={inputValue}
                ref={inputRef}
                layout={["holo"]}
                autoFocus // needed because it loses focus when we change the state
                preventConfirmOnBlur
                preventBubbling
                onChange={handleOnTextInputChange}
                onChangeConfirm={handleInputConfirm}
            />
        )
    }

    const DropDownItemView = useCallback((item: TyreFilter) => {
        if (ItemView) {
            return <ItemView {...item} />
        }
        return <Box style={{ textAlign: "center" }}>{concat(" ", item.value, item.info)}</Box>
    }, [])

    const CustomCoveriew: ComponentType = useCallback(
        () => (
            <SizeFiltersCoverView>
                <DropdownLabel variant="label">{translateText(getTextIdByFilterName(filterName))}</DropdownLabel>
                <Typography variant="body2">{selectedValue?.value ?? translateText(getTextIdByFilterName(filterName))}</Typography>
            </SizeFiltersCoverView>
        ),
        [selectedValue?.value]
    )

    return (
        <>
            <StyledDropdown
                className={className}
                ref={dropdownRef}
                disabled={!items.length || disabled}
                items={loading ? undefined : dropDownItems}
                itemView={DropDownItemView}
                coverView={CoverView || CustomCoveriew}
                customCoverView={customCoverView}
                layout={layout}
                enableLoaderInDropdown
                onDropdownOpen={handleDropdownOpen}
                onPopOverClose={handleOnClose}
                onDropdownClose={handleOnClose}
                amountItemsToShow={maxItemsToShow || 6}
                value={selectedItem}
                onClick={onDropdownClick}
                onChange={handleDropDownChange}
                inputView={hasInput ? renderDropDownInput : undefined}
                submitOnTab={submitOnTab}
            />
            {withResetButton && (
                <Button
                    variant="text"
                    disabled={!selectedValue || !items.length || disabled}
                    size="small"
                    startIcon={<Icon name="synchronize" />}
                    onClick={() => handleDropDownChange({ value: "", query: "default", group: filterName })}
                />
            )}
        </>
    )
}

const StyledDropdown = styled(Dropdown<TyreFilter>)({
    maxWidth: "calc(100% - 2em)",
    "& .btn__content": {
        overflow: "hidden",
    },
    "& .btn__content > div": {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
})

const SizeFiltersCoverView = styled(Box)({
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    marginRight: ".5em",
})

const DropdownLabel = styled(Typography)(({ theme }) => ({
    fontFamily: theme.font?.fontFamily?.condensed ? theme.font.fontFamily.condensed : theme.font.fontFamily.secondary,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
}))
