import { ICalculationItem } from "@bundles/damage-calculation-glass/data/models"
import { PureList } from "@bundles/parts"
import { ArticleListFeaturesDisableProvider } from "@bundles/parts/components/ListV2/ArticleListConfiguration/ArticleListFeaturesDisable"
import { DamageModulesType, ParticularStateProvider } from "@bundles/parts/components/PureList/ContextProviders/Particular/ParticularContext"
import { useCallback, useMemo } from "react"
import { Article, OE } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { mapOePartToOeOePart } from "@tm/utils"
import { mapDatGlassPartToArticle } from "../business/helpers/mapDatGlassPartToArticle"
import { MainActions } from "../../main/business"

type Props = {
    items: ICalculationItem[]
    handleAlternativeClick?: (item: ICalculationItem) => void
}

export function ArticleListV2(props: Props) {
    const { items, handleAlternativeClick } = props

    const actions = useActions(MainActions, "changeOeArticleQuantity", "selectArticle")

    function mapArticle(item: ICalculationItem) {
        if (item.selectedPart) {
            return mapDatGlassPartToArticle(item.selectedPart)
        }
        return mapOePartToOeOePart(item.oeArticle)
    }

    const oeArticlesWithArticles = useMemo(
        () =>
            items.map((item) => ({
                article: (item.selectedPart && mapDatGlassPartToArticle(item.selectedPart)) || null,
                oeArticle: item.selectedPart ? null : mapOePartToOeOePart(item.oeArticle),
            })),
        [items]
    )

    const articles = useMemo(() => items.map((i) => mapArticle(i)), [items])

    const selectedArticles = items.filter((x) => x.isSelected).map((i) => mapArticle(i))

    const handleReplaceButton = useCallback(
        (item: Article | OE.OePart) => {
            if ((item as OE.OePart).number) {
                const oeFound = items.find((x) => x.oeArticle.oeArticleNumber === item.id)
                if (oeFound) {
                    return handleAlternativeClick?.(oeFound)
                }
            }
            const articleFound = items.find((x) => x.selectedPart && mapDatGlassPartToArticle(x.selectedPart)?.id === item.id)
            return handleAlternativeClick?.(articleFound!)
        },
        [items, handleAlternativeClick]
    )

    const onChangeQuantity = useCallback(
        (article: Article | OE.OePart, quantity: number) => {
            if ((article as OE.OePart).number) {
                const foundOe = items.find((x) => x.oeArticle.oeArticleNumber === article.id)
                if (foundOe) {
                    return actions.changeOeArticleQuantity(foundOe, quantity)
                }
            }
            const foundArticle = items.find((x) => x.selectedPart && mapDatGlassPartToArticle(x.selectedPart)?.id === article.id)
            if (foundArticle && foundArticle.selectedPart) {
                foundArticle.selectedPart.quantityValue = article.quantity ?? 1
            }
            return actions.changeOeArticleQuantity(foundArticle!, quantity)
        },
        [items, actions]
    )

    const toggleSelectedArticle = useCallback(
        (article: Article | OE.OePart) => {
            const { id } = article

            const item = items.find(
                (i) => i.oeArticle.oeArticleNumber === id || (i.selectedPart && mapDatGlassPartToArticle(i.selectedPart)?.id === id)
            )
            if (!item) {
                return
            }
            return actions.selectArticle(item)
        },
        [items, actions]
    )

    const isArticleSelected = useCallback(
        (article: Article | OE.OePart) => {
            const { id } = article
            return !!selectedArticles.find((x) => x.id === id)
        },
        [selectedArticles]
    )

    const context: DamageModulesType = useMemo(
        () => ({
            type: "damageModulesList",
            articles: oeArticlesWithArticles,
            request: JSON.stringify(articles),
            selectedArticles,
            toggleSelectedArticle,
            isArticleSelected,
            handleReplaceButton,
            onChangeQuantity,
            isErpChannelActivated: true,
        }),
        [oeArticlesWithArticles, articles, selectedArticles, toggleSelectedArticle, isArticleSelected, handleReplaceButton, onChangeQuantity]
    )

    return (
        <ArticleListFeaturesDisableProvider disableErpChannel={false}>
            <ParticularStateProvider value={context}>
                <PureList listId="damageModulesList" listType="damageModulesList" />
            </ParticularStateProvider>
        </ArticleListFeaturesDisableProvider>
    )
}
