import { useLocalization } from "@tm/localization"
import { ErpBundleParams, ErpPrice, PriceType, Quantity } from "@tm/models"
import { Stack, Typography, Box, useTheme, useMediaQuery, Theme } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { useMemo } from "react"
import { findPrice } from "../../../../../../../helpers"
import { BasketPart } from "../../../../../../../models"
import { LoaderSmall } from "../../../../../../StyledComponents"
import ErpMemos from "./ErpMemos"
import SmallScreenPrices from "./SmallScreenPrices"
import BigScreenPrices from "./BigScreenPrices"
import { CustomOrderPriceCalculation } from "../../../../../../../data/model"

type Props = {
    part: BasketPart
    loading?: boolean
    showEditedPriceFlag?: boolean
    showProcurementPrice?: boolean
    showPurchasePrice?: boolean
    showRetailPrice?: boolean
    showTotalPrices: boolean
}

export type BasePricesProps = {
    quantity?: Quantity
    showExtraInfo?: boolean
    theme: Theme
}

export type PricesProps = BasePricesProps & {
    calculatedPurchasePrice?: ErpPrice
    purchasePriceLabel: string
    calculatedRetailPrice?: ErpPrice
    retailPriceLabel: string
    customPurchasePriceCalculation?: CustomOrderPriceCalculation
    customPurchasePrice?: ErpPrice
    showPurchasePrice?: boolean
    showRetailPrice?: boolean
    showTotalPrices: boolean
    totalCalculatedPurchasePrice?: ErpPrice
    totalPurchasePriceLabel: string
    totalCalculatedRecommendedPrice?: ErpPrice
    totalRecommendedPriceLabel: string
    totalCalculatedRetailPrice?: ErpPrice
    totalRetailPriceLabel: string
    totalErpPurchasePrice?: ErpPrice
    totalErpRetailPrice?: ErpPrice
    totalErpRecommendedPrice?: ErpPrice
    erpPurchasePrice?: ErpPrice
    erpRetailPrice?: ErpPrice
}

export default function PartPrices(props: Props) {
    const { part, showPurchasePrice, loading, showProcurementPrice, showRetailPrice, showTotalPrices, showEditedPriceFlag } = props
    const { erpInfoResponse, calculatedOrderItem, partItem } = part
    const { hasCustomOrderPriceCalculation, customOrderPriceCalculation } = partItem.orderItem || {}
    const { translateText, currency } = useLocalization()
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    const { quantity, prices, totalPrices } = erpInfoResponse || {}
    const { erpPrices: calculatedPrices, erpTotals, customOrderPrices } = calculatedOrderItem || {}
    const erpPrices = prices ? Object.values(prices) : undefined

    const calculatedErpPrices = calculatedPrices ? Object.values(calculatedPrices) : undefined
    const erpTotalPrices = totalPrices ? Object.values(totalPrices) : undefined
    const calculcatedErpTotalPrices = erpTotals ? Object.values(erpTotals) : undefined
    const customPrices = customOrderPrices ? Object.values(customOrderPrices) : undefined

    const {
        calculatedPurchasePrice,
        calculatedRetailPrice,
        customPurchasePriceCalculation,
        customPurchasePrice,
        purchasePriceLabel,
        retailPriceLabel,
        depositPrice,
        environmentalPrice,
        replacementPrice,
        procurementPrice,
        erpPurchasePrice,
        erpRetailPrice,
    } = useMemo(() => {
        return {
            calculatedPurchasePrice: findPrice(calculatedErpPrices, PriceType.Purchase),
            calculatedRetailPrice: findPrice(calculatedErpPrices, PriceType.Retail) ?? findPrice(calculatedErpPrices, PriceType.RecommendedRetail),
            customPurchasePriceCalculation:
                showEditedPriceFlag && hasCustomOrderPriceCalculation && customOrderPriceCalculation?.priceType === PriceType.Purchase
                    ? customOrderPriceCalculation
                    : undefined,
            customPurchasePrice: findPrice(customPrices, PriceType.Purchase),
            erpPurchasePrice: findPrice(erpPrices, PriceType.Purchase),
            erpRetailPrice: findPrice(erpPrices, PriceType.Retail) ?? findPrice(erpPrices, PriceType.RecommendedRetail),
            purchasePriceLabel: translateText(55),
            retailPriceLabel: translateText(1620),
            depositPrice: findPrice(calculatedErpPrices ?? erpPrices, PriceType.Deposit),
            environmentalPrice: findPrice(calculatedErpPrices ?? erpPrices, PriceType.Environmental),
            replacementPrice: findPrice(calculatedErpPrices ?? erpPrices, PriceType.Replacement),
            procurementPrice: findPrice(calculatedErpPrices ?? erpPrices, PriceType.ProcurementCosts),
        }
    }, [
        calculatedErpPrices,
        showEditedPriceFlag,
        hasCustomOrderPriceCalculation,
        customOrderPriceCalculation,
        erpPrices,
        customPrices,
        translateText,
    ])

    const {
        totalCalculatedPurchasePrice,
        totalPurchasePriceLabel,
        totalCalculatedRetailPrice,
        totalRetailPriceLabel,
        totalCalculatedRecommendedPrice,
        totalRecommendedPriceLabel,
        totalErpPurchasePrice,
        totalErpRetailPrice,
        totalErpRecommendedPrice,
    } = useMemo(() => {
        return {
            totalCalculatedPurchasePrice: findPrice(calculcatedErpTotalPrices, PriceType.Purchase),
            totalPurchasePriceLabel: translateText(1197),
            totalCalculatedRetailPrice: findPrice(calculcatedErpTotalPrices, PriceType.Retail),
            totalRetailPriceLabel: translateText(1198),
            totalCalculatedRecommendedPrice: findPrice(calculcatedErpTotalPrices, PriceType.RecommendedRetail),
            totalRecommendedPriceLabel: translateText(13644),
            totalErpPurchasePrice: findPrice(erpTotalPrices, PriceType.Purchase),
            totalErpRetailPrice: findPrice(erpTotalPrices, PriceType.Retail),
            totalErpRecommendedPrice: findPrice(erpTotalPrices, PriceType.RecommendedRetail),
        }
    }, [erpTotalPrices, calculcatedErpTotalPrices, translateText])

    const additionalPrices = []
    if (depositPrice?.value) {
        additionalPrices.push({
            name: translateText(1099),
            value: currency(depositPrice.value, depositPrice.currencySymbol || depositPrice.currencyCode_Iso_4217 || ""),
        })
    }
    if (environmentalPrice?.value) {
        additionalPrices.push({
            name: translateText(Morpheus.getParams<ErpBundleParams>("erp").environmentalPriceTextId),
            value: currency(environmentalPrice.value, environmentalPrice.currencySymbol || environmentalPrice.currencyCode_Iso_4217 || ""),
        })
    }
    if (replacementPrice?.value) {
        additionalPrices.push({
            name: translateText(1614),
            value: currency(replacementPrice.value, replacementPrice.currencySymbol || replacementPrice.currencyCode_Iso_4217 || ""),
        })
    }
    if (showProcurementPrice && procurementPrice?.value) {
        additionalPrices.push({
            name: translateText(1576),
            value: currency(procurementPrice.value, procurementPrice.currencySymbol || procurementPrice.currencyCode_Iso_4217 || ""),
        })
    }

    if (loading) {
        return <LoaderSmall />
    }

    if (!erpPrices && !calculatedErpPrices && !erpTotalPrices && !calculcatedErpTotalPrices) {
        return null
    }

    const pricesProps: PricesProps = {
        calculatedPurchasePrice,
        purchasePriceLabel,
        quantity,
        calculatedRetailPrice,
        retailPriceLabel,
        showPurchasePrice,
        showRetailPrice,
        showTotalPrices,
        theme,
        totalCalculatedPurchasePrice,
        totalPurchasePriceLabel,
        totalCalculatedRecommendedPrice,
        totalRecommendedPriceLabel,
        totalCalculatedRetailPrice,
        totalRetailPriceLabel,
        totalErpPurchasePrice,
        totalErpRetailPrice,
        totalErpRecommendedPrice,
        erpPurchasePrice,
        erpRetailPrice,
        customPurchasePriceCalculation,
        customPurchasePrice,
    }

    return (
        <Stack spacing={0.5}>
            {isBigScreen ? <BigScreenPrices {...pricesProps} /> : <SmallScreenPrices {...pricesProps} />}
            {!!additionalPrices.length && (
                <Box whiteSpace="nowrap">
                    {additionalPrices.map((price) => (
                        <Typography key={price.name} variant="label">
                            {price.name} {price.value}
                        </Typography>
                    ))}
                </Box>
            )}
            <ErpMemos erpInfoResponse={erpInfoResponse} />
        </Stack>
    )
}
