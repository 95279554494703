import { useUser, useCostEstimationModuleParameters } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros, RepairTimeProvider, RepairTimeRD, SystemType } from "@tm/models"
import { useActions, useMicro } from "@tm/morpheus"
import { initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { Button } from "@tm/components"
import { createMemo, mapIAMPartToArticle, mapWorkToService } from "../../../data/helpers"
import { CustomArticle, ExtendedOePart } from "../../../data/models"
import { customerSelector, MainActions, vehicleSelector } from "../../main/business"
import { useBasketImports } from "@bundles/basket"

const selector = initSelector(
    vehicleSelector,
    customerSelector,
    (s) => s.calculation.items,
    (s) => s.calculation.works,
    (s) => s.calculation.erpArticles
)

export default function CalculationActions() {
    const user = useUser()
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const { importToCostEstimation } = useBasketImports()
    const [vehicle, customer, items, works, erpArticles] = useSelector(selector)

    const actions = useActions(MainActions, "addToShoppingBasket")

    const { hasCostEstimation } = useCostEstimationModuleParameters()

    const selectedOeArticles: ExtendedOePart[] = items
        ?.filter((item) => item.isSelected && !item.selectedPart)
        .map((item) => ({
            ...item.oeArticle,
            replacements: item.alternativeParts?.map((alternativePart) => alternativePart.dataSupplierArticleNumber),
        }))
    const selectedIAMArticles: CustomArticle[] = items
        ?.filter((item) => item.isSelected && item.selectedPart && item.selectedPart.article)
        .map((item) =>
            mapIAMPartToArticle(
                item.selectedPart!,
                erpArticles?.find((erpArticle) => erpArticle.dataSupplierArticleNumber === item.selectedPart!.dataSupplierArticleNumber)
            )
        )

    const selectedWorks: RepairTimeRD[] = works?.filter((x) => x.isSelected).map(mapWorkToService)

    const handleAddToBasket = () => {
        const memo = user?.userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(vehicle, customer) : undefined
        actions.addToShoppingBasket(importToCostEstimation, memo)
    }

    const renderKVAButton = () => {
        if (hasCostEstimation && user?.userContext?.system.systemType === SystemType.Next) {
            return (
                <Button color="highlight" onClick={handleAddToBasket}>
                    {translateText(1607)}
                </Button>
            )
        }

        if (user?.userContext?.system.systemType === SystemType.Redesign) {
            return renderMicro("standalone", "rd-add-articles-rt-to-basket", {
                oeArticles: selectedOeArticles,
                // todo remove any
                iamArticles: selectedIAMArticles as any,
                services: selectedWorks,
                repairTimeDivision: user?.userSettings?.repairTimeOptions?.division,
                provider: RepairTimeProvider.Dat,
                vehicle,
                sourceId: "TM_DMG_GLASS" as any,
                buttonText: translateText(358),
            })
        }

        return null
    }

    return renderKVAButton() ? <Toolbar title={translateText(916)}>{renderKVAButton()}</Toolbar> : <></>
}
