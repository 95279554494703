import { createElement, DOMElement } from "react"

const innerFunction = (element: Element, props?: any): DOMElement<any, Element> => {
    const { tagName } = element
    const childrenProps = props ? { ...props } : {}

    for (let i = 0; i < element.attributes.length; i++) {
        childrenProps[element.attributes[i].nodeName] = element.attributes[i].nodeValue
    }

    const children = Array.from(element.children).map((item) => innerFunction(item))

    return createElement(tagName, childrenProps, children)
}

export const convertStringToReactNode = (elementString: string, type: DOMParserSupportedType, props?: any) => {
    const domParser = new DOMParser()
    const element = domParser.parseFromString(elementString, type)

    try {
        return innerFunction(element.documentElement, props)
    } catch (ex) {
        return null
    }
}
