import { notUndefinedOrNull } from "@tm/utils"
import { max } from "lodash"
import { useCallback, useEffect, useRef } from "react"

/**
 * Auto resize the right colum in the
 * @param container
 * @param enabled
 * @returns
 */
export function useResizeErpColumnInDefaultArticleItem(container: HTMLElement | null, enabled: boolean) {
    return useResizeErpColumn(container, "rightColumn", "erpContainer", enabled)
}

const LOADING_ERPCONTAINER_CLASSNAME_REGEX = /(-loading|-queued)/g

function useResizeErpColumn(container: HTMLElement | null, columnClassName: string, erpContainerClassName: string, enabled: boolean) {
    const columnList = useRef<HTMLElement[]>([])
    const maxWidth = useRef(0)
    const mutationObserver = useRef<MutationObserver>()

    // Returns all columns where the erp infos has already loaded (don't check the images)
    function getReadyColumns(checkImageComplete?: boolean) {
        return columnList.current.filter((column) => {
            const isLoading = new RegExp(LOADING_ERPCONTAINER_CLASSNAME_REGEX).test(
                column.querySelector(`.${erpContainerClassName}`)?.className ?? ""
            )
            if (checkImageComplete) {
                const imagesNotLoaded = Array.from(column.querySelectorAll("img")).some((x) => !x.complete)
                return !isLoading && !imagesNotLoaded
            }
            return !isLoading
        })
    }

    // Set columns with loading erp to auto width. So that we are able to determine the auto sized width, when it's been loaded.
    function resetWidthOnColumnsWithLoadingErp() {
        columnList.current.forEach((column) => {
            const erpContainer = column.querySelector(`.${erpContainerClassName}`)
            if (!!erpContainer && new RegExp(LOADING_ERPCONTAINER_CLASSNAME_REGEX).test(erpContainer.className)) {
                column.style.width = ""
            }
        })
    }

    // Get max width of all columns and set it to all columns when it's bigger than before or to all columns which hasn't a style width yet.
    // There's no max width yet. The width depends on the defined availability icons, when they're to big, the width could be too big.
    function verifyAndSetMaxWidth() {
        const readyColumns = getReadyColumns(true)
        const verifyMaxWidth = max(readyColumns.map((column) => column.getBoundingClientRect().width)) ?? 0
        if (verifyMaxWidth > maxWidth.current) {
            maxWidth.current = verifyMaxWidth
            readyColumns.forEach((el) => {
                el.style.width = `${verifyMaxWidth}px`
            })
        } else {
            readyColumns
                .filter((x) => !x.style.width)
                .forEach((el) => {
                    el.style.width = `${verifyMaxWidth}px`
                })
        }
    }

    // When the image has loaded, call verifyAndSetMaxWidth()
    const handleImageLoad = useCallback((ev: Event) => {
        const img = ev.target as HTMLImageElement
        if (img.complete) {
            img.removeEventListener("load", handleImageLoad)
            verifyAndSetMaxWidth()
        }
    }, [])

    const mutationHandler: MutationCallback = (mutationList) => {
        // Get only Mutations on the column
        const mutatedColumns = mutationList
            .map((mutation) => {
                if (!mutation.addedNodes.length) {
                    return
                }
                const element = mutation.target as HTMLElement
                if (element.classList.contains(columnClassName)) {
                    return element
                }

                return element.querySelector(`.${columnClassName}`) as HTMLElement
            })
            .filter(notUndefinedOrNull)
        if (!mutatedColumns.length) {
            return
        }

        // Add the column element to the list, if not already there
        mutatedColumns.forEach((element) => {
            if (!columnList.current.includes(element)) {
                columnList.current.push(element)
            }
        })

        // Every column where erp is loading, should has an auto width
        resetWidthOnColumnsWithLoadingErp()

        // Get all images in the column which hasn't being loaded and add an event handler
        const imagesNotLoaded = getReadyColumns()
            .flatMap((x) => Array.from(x.querySelectorAll("img")))
            .filter((x) => !x.complete)

        imagesNotLoaded.forEach((x) => {
            x.addEventListener("load", handleImageLoad)
        })

        // Initially verify and set width when it's bigger than before
        verifyAndSetMaxWidth()
    }

    useEffect(() => {
        if (!enabled || !container) {
            mutationObserver.current?.disconnect()
            return
        }
        if (!mutationObserver.current) {
            mutationObserver.current = new MutationObserver(mutationHandler)
        }
        mutationObserver.current.observe(container, { attributes: true, childList: true, subtree: true })
        return () => mutationObserver.current?.disconnect()
    }, [enabled, container])
}
