import { RimFilters } from "../../../components/wheels-list/business"
import { FilterCriterias } from "../../enums"
import { HSCombination, RimItem, TireSize } from "../../model"
import { RegNoRimArticles, RegNoRimFilter, RimArticleFilter, RimArticleFilterResponse } from "./model"

export function mapCarParkResponse(response: RimArticleFilter): RimArticleFilterResponse {
    const filters = mapRimFilters(response.filters)
    const articles = mapRimArticles(response.articles)
    return {
        filters,
        rimArticles: articles,
    }
}

function mapRimArticles(items?: RegNoRimArticles[]): RimItem[] {
    if (!items) {
        return []
    }

    return items.map((item) => {
        const obj: Record<string, HSCombination[]> = {}

        item.artCombs.forEach((x) => {
            obj[x.inch] = x.articles?.map((y) => {
                return {
                    articleNumber: y.articleNumber || "",
                    reifen: mapTireSizes(y.reifen, x.inch),
                    vsa: y.vsa || "",
                    oe: y.oe || "",
                    information: y.information,
                    montageauflagen: y.montageauflagen,
                }
            })
        })

        return {
            sizeInfo: item.artCombs.map((x) => {
                return { size: x.inch, rimFrameId: x.preysEAN }
            }),
            availability: false,
            color: item.color,
            currency: "",
            description: item.description,
            frontPicture: item.pictureFUrl,
            idRimDesign: item.rimdesignID,
            logoUrl: item.logoUrl,
            modelCode: item.modelCode,
            perspectivePicture: item.picturePUrl,
            quantity: 4,
            startingPrice: 0,
            suplierName: item.supplierName,
            supplierId: item.supplierID,
            hostettlerCombinations: obj,
            carParkId: item.carparkID,
            isConti: item.isConti,
            isSSW: item.isSSW,
            rimErpInformations: item.rimErpInformations || [],
        }
    })
}

function extractValue(property: string, value: string) {
    const tireSizes = value.includes("R") ? value.trim().split("R")[0].split("/") : value.trim().split("/")
    switch (property) {
        case "width":
            return tireSizes[0]
        case "height":
            return tireSizes[1]
        default:
            break
    }
}

export function mapTireSizes(tires?: string, diameter?: string): TireSize[] {
    if (!tires) {
        return []
    }

    const tireSizes = tires.split(";").map((x) => (x.includes("R") ? x.split("R")[0].trim() : x.replace(/[^0-9/]/g, "").trim()))
    return tireSizes.map((x, idx) => {
        return {
            abbreviation: "Tyre dimensions",
            description: "Tyre size",
            group: "Tyre size",
            id: idx,
            idTyreSize: 104,
            name: `${x}R${diameter}`,
            pathToCertificate: "",
            priority: 1,
            query: "Tyre dimensions",
            tyreSize: `${x} R${diameter}`,
            value: `${x}R${diameter}`,
            ece: false,
            width: extractValue("width", x),
            height: extractValue("height", x),
            inch: diameter,
        }
    })
}

function mapRimFilters(response: Record<string, RegNoRimFilter[]>): RimFilters {
    if (!response) {
        return {
            colors: [],
            winterprofed: [],
            widths: [],
            snowChainsCompatible: [],
            offsets: [],
            noModification: [],
            manufacturers: [],
            inches: [],
            ece: [],
            designs: [],
            type: [],
        }
    }

    return {
        manufacturers:
            response[FilterCriterias.rimSupplier]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        colors:
            response[FilterCriterias.rimColor]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.attribVal }
            }) || [],
        designs:
            response[FilterCriterias.rimSort]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribKey, info: x.attribVal }
            }) || [],
        inches:
            response[FilterCriterias.rimDiameter]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        offsets:
            response[FilterCriterias.rimOffset]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        widths:
            response[FilterCriterias.rimWidth]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        winterprofed:
            response[FilterCriterias.rimAttribute1]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        snowChainsCompatible:
            response[FilterCriterias.rimAttribute2]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        noModification:
            response[FilterCriterias.rimAttribute3]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        ece:
            response[FilterCriterias.rimAttribute4]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        OE:
            response[FilterCriterias.OE]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        VSA:
            response[FilterCriterias.VSA]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribVal, info: x.info }
            }) || [],
        type:
            response[FilterCriterias.rimType]?.map((x) => {
                return { attributeId: x.idAttrib, attributeValue: x.attribKey, info: x.attribVal }
            }) || [],
    }
}
