import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from "react"
import SwipeableViews from "react-swipeable-views"
import { Box, styled } from "@tm/components"
import RenderPages from "./steps-content"
import StepperNavigation from "./stepper-navigation"
import { useFastServiceStore } from "../../data"
import { navigationStepComponent } from "../../helpers/navigation-step-component"
import { StepContext } from "../../data/context/stepContext"

type Props = {
    mainWrapperRef: MutableRefObject<HTMLDivElement | null>
    isLoading?: boolean
}

export default function Steps({ mainWrapperRef, isLoading }: Props) {
    const divRef = useRef<HTMLDivElement>(null)
    const swipeableRef = useRef<any>(null)
    const [distance, setDistance] = useState<number | undefined>(undefined)
    const stepsWrapperHeight = distance || getSwipeAbleContentHeight()

    const { navigationSteps, activeStep, disableTyresSystem } = useFastServiceStore((state) => ({
        navigationSteps: state.stepNavigationState.steps,
        activeStep: state.navigation.active,
        disableTyresSystem: state.tyresWheels.disableTyresSystem,
    }))

    useLayoutEffect(() => {
        window.addEventListener("resize", handleResize)
        document.addEventListener("fullscreenchange", handleResize)
        handleResize()
        return () => {
            window.removeEventListener("resize", handleResize)
            document.removeEventListener("fullscreenchange", handleResize)
        }
    }, [])

    useEffect(() => {
        const mainWrapperHeight = mainWrapperRef?.current?.getBoundingClientRect().height
        if (mainWrapperHeight) {
            handleResize()
        }
    }, [mainWrapperRef?.current?.getBoundingClientRect().height])

    const handleResize = () => {
        divRef.current && setDistance(getSwipeAbleContentHeight())
        setTimeout(() => {
            swipeableRef.current && (swipeableRef.current as any).updateHeight()
        }, 250)
    }

    function getSwipeAbleContentHeight() {
        return (divRef.current && window.innerHeight - divRef.current.getBoundingClientRect().top - 52) || 0
    }

    const getSwipeAbleContent = (stepIndex: number, stepName: string) => {
        const StepComponent = navigationStepComponent(!!disableTyresSystem)[stepName]

        return (
            <SwipeableViews axis="y" index={stepIndex} disabled animateHeight style={{ flex: 1 }} ref={swipeableRef}>
                {navigationSteps.map((step, index) => (
                    <RenderPages key={index} height={stepsWrapperHeight}>
                        <StepContext.Provider value={{ step: stepName }}>
                            {stepName === step.stepName && <StepComponent key={stepName} step={stepName} />}
                        </StepContext.Provider>
                    </RenderPages>
                ))}
            </SwipeableViews>
        )
    }

    return (
        <StyledBox isLoading={isLoading} display="flex" width="100%">
            <Box display="flex" width="100%" ref={divRef}>
                {getSwipeAbleContent(navigationSteps.findIndex((x) => x.stepName === activeStep) ?? 0, activeStep || "start")}
                <StepperNavigation stepsWrapperHeight={stepsWrapperHeight} />
            </Box>
        </StyledBox>
    )
}

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isLoading",
})<{ isLoading?: boolean }>(({ theme, isLoading }) => ({
    ...(isLoading && {
        pointerEvents: "none",
        opacity: theme.opacity?.disabled,
    }),
}))
