import { Box, Stack } from "@tm/components"
import { CostOverviewTable } from "./components/CostOverviewTable"
import { useCalculatedCosts } from "../../data/hooks/useCalculatedCosts"
import { useFilterStore } from "../../business/state"

// TODO: has to be refactored
// At the moment we do not know the size of the navigation toolbar and
// other contentes that might pop up at the top or bottom of the table.
const TABLE_HEIGHT = "calc(100vh - 18.5em)"

// Story: https://jira.dvse.de/browse/NEXT-25679
// Epos: https://jira.dvse.de/browse/NEXT-25545
// VIN queries or damage calculation queries that caused costs will save some information
// to a history. This history will be provided by the RepairShops service and displayed
// in a table, using this component. The component is part of the CIS module and only
// visible if the user has the CIS module parameter "modulesCost" set to true.
export default function CostOverviewComponent() {
    const filters = useFilterStore((state) => state.filters)
    const { calculatedCosts, calculatedCostsLoading } = useCalculatedCosts(filters?.costOverview)

    return (
        <Stack direction="column" spacing={1} width="100%" bgcolor="white" padding={2} overflow="auto">
            <Box width="100%" height={TABLE_HEIGHT}>
                <CostOverviewTable calculatedCosts={calculatedCosts} variant="normal" calculatedCostsLoading={calculatedCostsLoading}/>
            </Box>
        </Stack>
    )
}
