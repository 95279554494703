import { Box, Button, Icon, styled, Tooltip, Typography, IconProps, FormControlLabel, Checkbox } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Tyre, TyreSelection } from "@bundles/tyres/data/models"
import { Table, Toolbar, Text, TableProps } from "@tm/controls"
import { ETyresSeason } from "@tm/models"
import { useState } from "react"
import { extractTyreParameters } from "../business"
import { SummaryAccordionTitle } from "../../_shared/SummaryAccordionTitle"

type Props = {
    tyreList: Tyre[]
    sameSize?: boolean
    handleTyreSelect: (tyreItem: Tyre, season?: ETyresSeason) => void
    handleVariantSelect?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tyreItem: Tyre, selection: TyreSelection) => void
}
export function TyreListAccordion({ tyreList, sameSize, handleTyreSelect, handleVariantSelect }: Props) {
    const { translateText } = useLocalization()
    const [open, setOpen] = useState(true)
    const scrollable = sameSize ? tyreList.length > 4 : tyreList.length > 3

    const renderTooltip = (tyreItem: Tyre, front?: boolean) => {
        const tooltipContent = () => {
            return (
                <Box display="flex">
                    <Text size="s">{translateText(1029)}&nbsp;</Text>
                    <Text size="s" modifiers={["strong"]}>
                        {front ? tyreItem.rimSizeFront : tyreItem.rimSizeRear}
                    </Text>
                    <Text size="s">
                        &nbsp;{" | "}
                        {translateText(1028)}&nbsp;
                    </Text>
                    <Text size="s" modifiers={["strong"]}>
                        {front ? tyreItem.rimOffsetFront : tyreItem.rimOffsetRear}
                    </Text>
                </Box>
            )
        }

        return (
            <Tooltip title={tooltipContent()} variant="light" placement="right" color="primary">
                <StyledIcon />
            </Tooltip>
        )
    }

    const renderSize = (tyreItem: Tyre, front?: boolean) => {
        if (tyreItem[front ? "seasonSummerFront" : "seasonSummerRear"] && tyreItem[front ? "seasonWinterFront" : "seasonWinterRear"]) {
            tyreItem[front ? "seasonFront" : "seasonRear"] = ETyresSeason.allSeason
        } else if (tyreItem[front ? "seasonSummerFront" : "seasonSummerRear"]) {
            tyreItem[front ? "seasonFront" : "seasonRear"] = ETyresSeason.summer
        } else if (tyreItem[front ? "seasonWinterFront" : "seasonWinterRear"]) {
            tyreItem[front ? "seasonFront" : "seasonRear"] = ETyresSeason.winter
        }

        const [width, ratio, diameter] = extractTyreParameters(tyreItem, front)

        return (
            <Box style={{ display: "flex", alignItems: "center", flex: 1 }}>
                <Box display="flex">
                    <Typography fontWeight={700}>{width}&nbsp;</Typography>
                    <Typography>
                        {`/ ${ratio} ${diameter} `}
                        {tyreItem.loadIndexFront}
                        {tyreItem.speedIndexFront}
                    </Typography>
                </Box>
                {renderTooltip(tyreItem, front)}
            </Box>
        )
    }

    const onTitleClick = () => {
        setOpen((prev) => !prev)
    }

    return (
        <StyledBox open={open} scrollable={scrollable}>
            <SummaryAccordionTitle
                title={translateText(sameSize ? 240 : 13913)}
                subtitle={translateText(sameSize ? 13923 : 13924)}
                onClick={onTitleClick}
                open={open}
            />
            {open && (
                <StyledTable
                    scrollable={scrollable}
                    data={tyreList}
                    onClickRow={handleTyreSelect}
                    getRowClassName={() => "link is-clickable"}
                    columns={[
                        <Table.Column
                            key={sameSize ? "tyre-icon" : "axle-icon"}
                            className={sameSize ? "tyre-icon" : "axle-icon"}
                            renderItemContent={() => (
                                <Table.Cell>
                                    {sameSize ? (
                                        <Icon name="tire" sx={{ fill: "GrayText" }} />
                                    ) : (
                                        <Box display="flex" flexDirection="column">
                                            <AxleIcon front />
                                            <AxleIcon />
                                        </Box>
                                    )}
                                </Table.Cell>
                            )}
                        />,
                        <Table.Column
                            key="size"
                            className="size"
                            renderItemContent={(item: Tyre) => (
                                <Table.Cell>
                                    {sameSize ? (
                                        renderSize(item, true)
                                    ) : (
                                        <Box style={{ display: "flex", flexDirection: "column" }}>
                                            {renderSize(item, true)}
                                            {renderSize(item)}
                                        </Box>
                                    )}
                                </Table.Cell>
                            )}
                        />,
                        ...(!sameSize
                            ? [
                                  <Table.Column
                                      key="selection"
                                      className="selection"
                                      renderItemContent={(item: Tyre) => (
                                          <Table.Cell>
                                              <Box style={{ display: "flex", flexDirection: "column" }}>
                                                  <FormControlLabel
                                                      label={translateText(1906)}
                                                      labelPlacement="start"
                                                      sx={{ ml: 1 }}
                                                      control={
                                                          <Checkbox
                                                              size="small"
                                                              checked={item.selection === "front"}
                                                              onClick={(e) => handleVariantSelect?.(e, item, "front")}
                                                          />
                                                      }
                                                  />
                                                  <FormControlLabel
                                                      label={translateText(1907)}
                                                      labelPlacement="start"
                                                      sx={{ ml: 1 }}
                                                      control={
                                                          <Checkbox
                                                              size="small"
                                                              checked={item.selection === "rear"}
                                                              onClick={(e) => handleVariantSelect?.(e, item, "rear")}
                                                          />
                                                      }
                                                  />
                                              </Box>
                                          </Table.Cell>
                                      )}
                                  />,
                              ]
                            : []),
                        <Table.Column
                            key="season"
                            className="season"
                            renderItemContent={(item: Tyre) => (
                                <Table.Cell>
                                    <Toolbar modifiers={["title-before", "no-border"]}>
                                        <Tooltip title={translateText(800)}>
                                            <Button
                                                className="tyre-search__frame"
                                                startIcon={<Icon name="sun" />}
                                                disabled={
                                                    sameSize
                                                        ? item.seasonFront === ETyresSeason.winter
                                                        : item.seasonFront === ETyresSeason.winter && item.seasonRear === ETyresSeason.winter
                                                }
                                                onClick={() => handleTyreSelect(item, ETyresSeason.summer)}
                                            />
                                        </Tooltip>
                                        <Tooltip title={translateText(725)}>
                                            <Button
                                                className="tyre-search__frame"
                                                startIcon={<Icon name="all-weather" />}
                                                disabled={
                                                    sameSize
                                                        ? !(item.seasonFront === ETyresSeason.allSeason)
                                                        : !(item.seasonFront === ETyresSeason.allSeason && item.seasonRear === ETyresSeason.allSeason)
                                                }
                                                onClick={() => handleTyreSelect(item, ETyresSeason.allSeason)}
                                            />
                                        </Tooltip>
                                        <Tooltip title={translateText(801)}>
                                            <Button
                                                className="tyre-search__frame"
                                                startIcon={<Icon name="snow" />}
                                                disabled={
                                                    sameSize
                                                        ? item.seasonFront === ETyresSeason.summer
                                                        : item.seasonFront === ETyresSeason.summer && item.seasonRear === ETyresSeason.summer
                                                }
                                                onClick={() => handleTyreSelect(item, ETyresSeason.winter)}
                                            />
                                        </Tooltip>
                                    </Toolbar>
                                </Table.Cell>
                            )}
                        />,
                    ]}
                />
            )}
        </StyledBox>
    )
}

const StyledTable = styled((props: TableProps<Tyre>) => <Table {...props} />)(({ scrollable }: TableProps<Tyre>) => ({
    margin: "0 2em",
    height: scrollable ? "80%" : "unset",
    "& .size": {
        flex: 1,
        display: "flex",
    },
}))

const AxleIcon = styled(({ front, ...props }: IconProps & { front?: boolean }) => (
    <Icon name={front ? "axle-car_only_front" : "axle-car_only_back"} {...props} />
))(({ theme }) => ({
    fill: theme.palette.primary.light,
}))

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "scrollable",
})<{ open: boolean; scrollable: boolean }>(({ open, scrollable }) => ({
    flex: open ? (scrollable ? "1 1 100%" : "0 1 auto") : "0 0 auto",
    margin: "0 4.5em",
}))

const StyledIcon = styled((props: IconProps) => <Icon name="info" {...props} />)({
    marginLeft: "1em",
    fill: "GrayText",
    ":hover": {
        fill: "currentcolor",
    },
})
