import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Collapse,
    Divider,
    Icon,
    IconButton,
    Stack,
    styled,
    Tooltip,
    Typography,
} from "@tm/components"
import { useCallback, useEffect, useMemo, useState, memo } from "react"
import { useLocalization } from "@tm/localization"
import { batch, useDispatch, useSelector } from "react-redux"
import { useAvailabilityStatus, useTyresV2, useNewTyreSearch } from "@tm/context-distribution"
import { TyreFilter } from "@tm/models"
import { Scrollbar } from "@tm/controls"

import { FiltersSkeleton } from "../../../../../parts/src/components/ListV2/components/Filters/FiltersSkeleton"
import { MainState } from "../../main"

import { AvailabilityFilterType, IListFilters } from "../business"
import { MainActions } from "../../main/business"

import { getBundleParams } from "../../../utils"
import { AvailabilityFilter, EuTyreLabel, FilterItem, FiltersArea, SeasonComponent } from "."
import { FiltersComponent as Filters } from "../../summary/components/filters"
import { isAvailabilityFilter } from "../business/helpers/isAvailabilityFilter"

type AccordionStates = {
    availability?: boolean
    season?: boolean
    manufacturer?: boolean
    loadIndex?: boolean
    speedIndex?: boolean
    oeIdentifier?: boolean
    extras?: boolean
    wetGripClass?: boolean
}

function TyreFiltersV2() {
    const dispatch = useDispatch()
    const { isFiltersOnRightSide } = getBundleParams()
    const { translateText } = useLocalization()
    const { filters, selectedFilters } = useSelector((s: MainState) => ({ filters: s.list.filters, selectedFilters: s.list.selectedFilters }))
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()

    const [visibilityButtonMinimized, setVisibilityButtonMinimized] = useState(false)
    const [filtersVisible, setFiltersVisible] = useState(true)
    const hasTyresV2 = useTyresV2()
    const hasNewTyreSearch = useNewTyreSearch()

    const availabilityArray: AvailabilityFilter[] = []

    if (availabilityStatusIdsToShow?.length) {
        availabilityArray.push({
            name: translateText(1623),
            value: AvailabilityFilterType.Primary,
        })
    }
    if (availabilityStatusIdsToShowSecondary?.length) {
        availabilityArray.push({
            name: translateText(12860),
            value: AvailabilityFilterType.Secondary,
        })
    }

    const [accordionsState, setAccordionsState] = useState<AccordionStates>({
        ...(availabilityArray.length > 0 && { availability: true }),
        ...(filters.season?.length > 0 && { season: true }),
        ...(filters.manufacturer?.length > 0 && { manufacturer: !!selectedFilters.manufacturer?.length }),
        ...(filters.loadIndex?.length > 0 && { loadIndex: !!selectedFilters.loadIndex?.length }),
        ...(filters.speedIndex?.length > 0 && { speedIndex: !!selectedFilters.speedIndex?.length }),
        ...(filters.oeIdentifier?.length > 0 && { oeIdentifier: !!selectedFilters.oeIdentifier?.length }),
        ...(filters.extras?.length > 0 && { extras: !!selectedFilters.extras?.length }),
        ...((filters.wetGripClass?.length > 0 || filters.fuelEfficiency?.length > 0 || filters.externalRolling?.length > 0) && {
            wetGripClass: !!selectedFilters.wetGripClass || !!selectedFilters.fuelEfficiency || !!selectedFilters.externalRolling,
        }),
    })

    const isFilterCollapsed = useMemo(() => !Object.values(accordionsState).some((value) => value === true), [accordionsState])

    useEffect(() => {
        const getAccordionState = () => {
            const performanceFilters = [filters.wetGripClass?.length > 0, filters.fuelEfficiency?.length > 0, filters.externalRolling?.length > 0]

            return {
                availability: availabilityArray.length > 0 || false,
                season: filters.season?.length > 0 || false,
                manufacturer: (filters.manufacturer?.length > 0 && !!selectedFilters.manufacturer?.length) || false,
                loadIndex: (filters.loadIndex?.length > 0 && !!selectedFilters.loadIndex?.length) || false,
                speedIndex: (filters.speedIndex?.length > 0 && !!selectedFilters.speedIndex?.length) || false,
                oeIdentifier: (filters.oeIdentifier?.length > 0 && !!selectedFilters.oeIdentifier?.length) || false,
                extras: (filters.extras?.length > 0 && !!selectedFilters.extras?.length) || false,
                wetGripClass:
                    (performanceFilters.some(Boolean) &&
                        (!!selectedFilters.wetGripClass || !!selectedFilters.fuelEfficiency || !!selectedFilters.externalRolling)) ||
                    false,
            }
        }

        setAccordionsState(getAccordionState())
    }, [
        filters.season,
        filters.manufacturer,
        filters.loadIndex,
        filters.speedIndex,
        filters.oeIdentifier,
        filters.extras,
        filters.wetGripClass,
        filters.fuelEfficiency,
        filters.externalRolling,
    ])

    useEffect(() => {
        return () => {
            if (selectedFilters?.availability !== AvailabilityFilterType.None) {
                dispatch(MainActions.changeAvailabilityFilter(AvailabilityFilterType.None))
            }
        }
    }, [selectedFilters?.availability])

    const updateAccordionState = (key: string, value: boolean) => {
        setAccordionsState((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    const toggleAccordionsState = () => {
        const isAnyFilterOpen = Object.values(accordionsState).some((value) => value === true)

        setAccordionsState((prevState) => {
            const newState = Object.keys(prevState).reduce((acc: AccordionStates, key) => {
                acc[key] = !isAnyFilterOpen
                return acc
            }, {})

            return newState
        })
    }

    const handleToggleFilterVisibility = () => {
        setFiltersVisible((prev) => !prev)
        setVisibilityButtonMinimized((prev) => !prev)
    }

    function getCollapseIcon(): string {
        if (isFiltersOnRightSide) {
            return filtersVisible ? "arrow-right" : "arrow-left"
        }
        return filtersVisible ? "arrow-left" : "arrow-right"
    }

    const handleCollapseExit = useCallback(() => {
        if (filtersVisible) {
            setTimeout(() => setVisibilityButtonMinimized(false), 500)
        }
    }, [filtersVisible])

    const handleFilterChange = (filter: TyreFilter | AvailabilityFilter | null, path?: IListFilters) => {
        if (filter === null) {
            return
        }

        batch(() => {
            if (isAvailabilityFilter(filter)) {
                dispatch(
                    MainActions.changeAvailabilityFilter(selectedFilters.availability === filter.value ? AvailabilityFilterType.None : filter.value)
                )
                dispatch(MainActions.loadTyresList(false, true))
                return
            }
            if (path) {
                dispatch(MainActions.updateAttributeFilter(path, filter))
                dispatch(MainActions.loadTyresList(undefined, true))
            }
        })
    }

    const handleFilterReset = (path?: IListFilters) => {
        if (!path) {
            dispatch(MainActions.changeAvailabilityFilter(AvailabilityFilterType.None))
            return
        }
        batch(() => {
            dispatch(MainActions.resetAttributeFilter(path))
            dispatch(MainActions.loadTyresList(undefined, true))
        })
    }

    const handleEuFiltersReset = () => {
        batch(() => {
            dispatch(MainActions.resetAttributeFilter("fuelEfficiency"))
            dispatch(MainActions.resetAttributeFilter("wetGripClass"))
            dispatch(MainActions.resetAttributeFilter("externalRolling"))
            dispatch(MainActions.loadTyresList(undefined, true))
        })
    }

    const handleResetAllFilters = () => {
        batch(() => {
            dispatch(MainActions.changeAvailabilityFilter(AvailabilityFilterType.None))
            dispatch(MainActions.resetAttributeFilter("season"))
            dispatch(MainActions.resetAttributeFilter("manufacturer"))
            dispatch(MainActions.resetAttributeFilter("loadIndex"))
            dispatch(MainActions.resetAttributeFilter("speedIndex"))
            dispatch(MainActions.resetAttributeFilter("oeIdentifier"))
            dispatch(MainActions.resetAttributeFilter("extras"))
            dispatch(MainActions.resetAttributeFilter("fuelEfficiency"))
            dispatch(MainActions.resetAttributeFilter("wetGripClass"))
            dispatch(MainActions.resetAttributeFilter("externalRolling"))
            dispatch(MainActions.loadTyresList(undefined, true))
        })
    }

    const renderSelectedFilterChip = (filter: TyreFilter | AvailabilityFilter, path?: IListFilters) => {
        const isAvailability = isAvailabilityFilter(filter)

        return (
            <StyledChip
                key={isAvailability ? filter.name : `${filter.group}_${filter.value}`}
                label={isAvailability ? filter.name : `${filter.value} ${filter.info ?? ""}`}
                variant="outlined"
                size="small"
                deleteIcon={<Icon name="close" size="15px" />}
                onDelete={() => handleFilterChange(filter, path)}
            />
        )
    }

    const renderSelectedFilters = () => {
        const availabilityFilter = availabilityArray.find((filter) => filter.value === selectedFilters.availability)
        const isEmptySelectedFilters =
            !availabilityFilter &&
            !selectedFilters.manufacturer?.length &&
            !selectedFilters.loadIndex?.length &&
            !selectedFilters.speedIndex?.length &&
            !selectedFilters.oeIdentifier?.length &&
            !selectedFilters.extras?.length &&
            !selectedFilters.fuelEfficiency &&
            !selectedFilters.wetGripClass &&
            !selectedFilters.externalRolling

        return (
            <Stack direction="row" gap="0.25em" flexWrap="wrap" margin={isEmptySelectedFilters ? "0 0.4em 0 0" : "0.5em 0.4em 0.5em 0"}>
                {availabilityFilter && renderSelectedFilterChip(availabilityFilter)}
                {selectedFilters.manufacturer?.map((filter) => renderSelectedFilterChip(filter, "manufacturer"))}
                {selectedFilters.loadIndex?.map((filter) => renderSelectedFilterChip(filter, "loadIndex"))}
                {selectedFilters.speedIndex?.map((filter) => renderSelectedFilterChip(filter, "speedIndex"))}
                {selectedFilters.oeIdentifier?.map((filter) => renderSelectedFilterChip(filter, "oeIdentifier"))}
                {selectedFilters.extras?.map((filter) => renderSelectedFilterChip(filter, "extras"))}
                {selectedFilters.fuelEfficiency && renderSelectedFilterChip(selectedFilters.fuelEfficiency, "fuelEfficiency")}
                {selectedFilters.wetGripClass && renderSelectedFilterChip(selectedFilters.wetGripClass, "wetGripClass")}
                {selectedFilters.externalRolling && renderSelectedFilterChip(selectedFilters.externalRolling, "externalRolling")}
            </Stack>
        )
    }

    return (
        <StyledStacked isFiltersOnRightSide={isFiltersOnRightSide}>
            <Stack flex="0 0 auto" direction="row" alignItems="center" alignSelf="flex-start" sx={{ width: "100%" }}>
                <Tooltip title={translateText(visibilityButtonMinimized ? 13483 : 13484)} enterDelay={1000}>
                    <IconButton onClick={handleToggleFilterVisibility}>
                        <Icon size="16px" name={getCollapseIcon()} />
                    </IconButton>
                </Tooltip>
                <Box display="flex" justifyContent="space-between" width="100%">
                    {!visibilityButtonMinimized && <Typography>{translateText(209)}</Typography>}
                    {!visibilityButtonMinimized && (
                        <Box display="flex" alignItems="center">
                            {!hasTyresV2 && (
                                <Tooltip title={isFilterCollapsed ? translateText(13866) : translateText(13865)} enterDelay={1000}>
                                    <Icon
                                        sx={{ mr: "1.2em", cursor: "pointer" }}
                                        name={isFilterCollapsed ? "filter-expand" : "filter-collaps"}
                                        onClick={toggleAccordionsState}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip title={translateText(13857)} enterDelay={1000}>
                                <Icon
                                    sx={{ mr: hasTyresV2 ? "0.4em" : "1.2em", cursor: "pointer" }}
                                    name="remove-filter"
                                    onClick={handleResetAllFilters}
                                />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            </Stack>
            {!visibilityButtonMinimized && hasTyresV2 && renderSelectedFilters()}
            <Divider sx={{ borderColor: "#ccc", mr: hasTyresV2 && !visibilityButtonMinimized ? "0.4em" : "" }} />
            {!visibilityButtonMinimized && hasTyresV2 && (
                <Stack direction="row" alignItems="center" mt="0.5em">
                    <Icon name={isFilterCollapsed ? "filter-expand" : "filter-collaps"} onClick={toggleAccordionsState} sx={{ cursor: "pointer" }} />
                    <Typography onClick={toggleAccordionsState} sx={{ fontSize: "12px", fontWeight: "600", pl: "0.25em", cursor: "pointer" }}>
                        {isFilterCollapsed ? translateText(13866) : translateText(13865)}
                    </Typography>
                </Stack>
            )}
            <Collapse in={filtersVisible} orientation="horizontal" sx={{ overflow: "hidden", flex: 1 }} addEndListener={handleCollapseExit}>
                {filters.leftLoading && filtersVisible ? (
                    <Box width={290} key="loading">
                        <FiltersSkeleton />
                    </Box>
                ) : (
                    <Box width={290} height="100%" maxWidth="19em" sx={{ overflowY: "auto" }}>
                        <Scrollbar>
                            <Box
                                width={hasTyresV2 ? 290 : "auto"}
                                maxWidth={hasTyresV2 ? "19em" : "auto"}
                                display="flex"
                                flexDirection="column"
                                className="panel__content"
                            >
                                {hasNewTyreSearch && (
                                    <Accordion disableGutters elevation={0} sx={{ backgroundColor: "transparent", margin: "0 0.4rem 0 0" }}>
                                        <StyledAccordionSummary onClick={() => dispatch(MainActions.toggleTyresAccordion())}>
                                            <Icon name="tyre-search" sx={{ mr: "5px", opacity: 1 }} />
                                            <Typography sx={{ fontSize: "inherit", fontWeight: 600, opacity: 1 }}>{translateText(3162)}</Typography>
                                        </StyledAccordionSummary>
                                        <AccordionDetails sx={{ p: 0 }}>
                                            <Filters />
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {!!availabilityArray.length && (
                                    <FiltersArea
                                        headline={translateText(746)}
                                        onChange={handleFilterChange}
                                        hideFilterSearch
                                        onResetFilters={handleFilterReset}
                                        isExpanded={!!accordionsState.availability}
                                        onAccordionClick={updateAccordionState}
                                        dropdownPath="availability"
                                    >
                                        {availabilityArray?.map((filter) => (
                                            <FilterItem
                                                key={filter.name}
                                                filter={filter}
                                                checked={filter.value === selectedFilters.availability}
                                                onChange={handleFilterChange}
                                            />
                                        ))}
                                    </FiltersArea>
                                )}
                                {!!filters.season?.length && (
                                    <FiltersArea
                                        path="season"
                                        headline={translateText(1235)}
                                        onResetFilters={handleFilterReset}
                                        hideFilterSearch
                                        isExpanded={!!accordionsState.season}
                                        onAccordionClick={updateAccordionState}
                                    >
                                        <SeasonComponent
                                            handleFilterChange={handleFilterChange}
                                            seasons={filters.season}
                                            selectedFilters={selectedFilters.season}
                                        />
                                    </FiltersArea>
                                )}
                                {!!filters.manufacturer?.length && (
                                    <FiltersArea
                                        path="manufacturer"
                                        data={filters.manufacturer}
                                        headline={translateText(71)}
                                        onChange={handleFilterChange}
                                        selectedFilters={selectedFilters.manufacturer}
                                        onResetFilters={handleFilterReset}
                                        isExpanded={!!accordionsState.manufacturer}
                                        onAccordionClick={updateAccordionState}
                                    />
                                )}
                                {!!filters.loadIndex?.length && (
                                    <FiltersArea
                                        path="loadIndex"
                                        data={filters.loadIndex}
                                        headline={translateText(12403)}
                                        onChange={handleFilterChange}
                                        selectedFilters={selectedFilters.loadIndex}
                                        onResetFilters={handleFilterReset}
                                        isExpanded={!!accordionsState.loadIndex}
                                        onAccordionClick={updateAccordionState}
                                    />
                                )}
                                {!!filters.speedIndex?.length && (
                                    <FiltersArea
                                        path="speedIndex"
                                        data={filters.speedIndex}
                                        headline={translateText(719)}
                                        onChange={handleFilterChange}
                                        selectedFilters={selectedFilters.speedIndex}
                                        onResetFilters={handleFilterReset}
                                        isExpanded={!!accordionsState.speedIndex}
                                        onAccordionClick={updateAccordionState}
                                    />
                                )}
                                {!!filters.oeIdentifier?.length && (
                                    <FiltersArea
                                        path="oeIdentifier"
                                        data={filters.oeIdentifier}
                                        headline={translateText(720)}
                                        onChange={handleFilterChange}
                                        selectedFilters={selectedFilters.oeIdentifier}
                                        onResetFilters={handleFilterReset}
                                        isExpanded={!!accordionsState.oeIdentifier}
                                        onAccordionClick={updateAccordionState}
                                    />
                                )}
                                {!!filters.extras?.length && (
                                    <FiltersArea
                                        path="extras"
                                        data={filters.extras}
                                        headline={translateText(744)}
                                        onChange={handleFilterChange}
                                        selectedFilters={selectedFilters.extras}
                                        onResetFilters={handleFilterReset}
                                        hasInlineInfo
                                        isExpanded={!!accordionsState.extras}
                                        onAccordionClick={updateAccordionState}
                                    />
                                )}
                                {(!!filters.wetGripClass?.length || !!filters.fuelEfficiency?.length || !!filters.externalRolling?.length) && (
                                    <FiltersArea
                                        path="wetGripClass"
                                        headline={translateText(12404)}
                                        onChange={handleFilterChange}
                                        onResetFilters={handleFilterReset}
                                        onResetEuFilters={handleEuFiltersReset}
                                        hideFilterSearch
                                        isExpanded={!!accordionsState.wetGripClass}
                                        onAccordionClick={updateAccordionState}
                                    >
                                        <EuTyreLabel onChange={handleFilterChange} onResetFilters={handleEuFiltersReset} />
                                    </FiltersArea>
                                )}
                            </Box>
                        </Scrollbar>
                    </Box>
                )}
            </Collapse>
        </StyledStacked>
    )
}

export const TyresFiltersV2 = memo(TyreFiltersV2)

const StyledAccordionSummary = styled(AccordionSummary)({
    flexDirection: "row",
    mr: "5px",
    ".MuiAccordionSummary-expandIconWrapper .icon": {
        opacity: "1",
    },
    ".MuiAccordionSummary-content": {
        alignItems: "center",
    },
    marginRight: ".8em",
})

const StyledStacked = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "isFiltersOnRightSide",
})<{
    isFiltersOnRightSide?: boolean
}>(({ theme, isFiltersOnRightSide }) => ({
    overflow: "hidden",
    marginRight: "1em",
    maxWidth: "19em",
    backgroundColor: theme.overwrites?.components?.partListV2?.backgroundColor,
    paddingLeft: ".5em",
    marginLeft: isFiltersOnRightSide ? "1em" : "0em",
}))

const StyledChip = styled(Chip)({
    borderRadius: "0.25em",
    fontSize: "12px",
    fontWeight: "600",
    ".MuiChip-label": {
        padding: "0 0.75em 0 0.5em",
    },
    ".MuiChip-deleteIcon": {
        marginRight: "0.2em",
    },
})
