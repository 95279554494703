import { useTyresV2 } from "@tm/context-distribution"
import { TyresSearchWidgetCompactV2 } from "./components/TyresSearchWidgetCompactV2"
import { TyresSearchWidgetCompactV1 } from "./components/TyresSearchWidgetCompactV1"

export function TyresSearchWidgetCompact() {
    const tyresWidgetV2 = useTyresV2()

    if (tyresWidgetV2) {
        return <TyresSearchWidgetCompactV2 />
    }

    return <TyresSearchWidgetCompactV1 />
}
