import { useEffect, useState, useMemo } from "react"
import { Box, Icon, Typography, Button, TextField, Stack, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { AddressSelectionItem, Item, OrderOptions, TitleType } from "@tm/models"
import { equals } from "@tm/utils"
import { getBundleParams } from "../../../../utils"
import { RowStack } from "./StyledComponents"

function normalizeAddress(address: AddressSelectionItem | undefined): AddressSelectionItem | undefined {
    if (address) {
        const normalizedAddress = { ...address }
        Object.keys(normalizedAddress).forEach((key) => {
            if (normalizedAddress[key] === "") {
                delete normalizedAddress[key]
            }
        })
        return normalizedAddress
    }
    return address
}

type Props = {
    addressToEdit: AddressSelectionItem
    options: OrderOptions
    onSetDeliveryAddress(address: AddressSelectionItem, selectedOptionsItem?: Item): void
    onCloseEditMode(): void
}

const RowStackWithPaddingTop = styled(RowStack)({
    paddingTop: "12px",
})

export default function DeliveryAddressesInformationTextFieldsComponent({ addressToEdit, options, onCloseEditMode, onSetDeliveryAddress }: Props) {
    const { deliveryAddresses, selectedOptionsItem } = options
    const { translateText } = useLocalization()
    const { hideOrderOptionsContactData } = getBundleParams()
    const [isSaving, setIsSaving] = useState(false)
    const [addressHasChanges, setAddressHasChanges] = useState(false)
    const [addressBeingEdited, setAddressBeingEdited] = useState<AddressSelectionItem>(addressToEdit)

    useEffect(() => {
        setIsSaving(false)
    }, [deliveryAddresses])

    const selectedDeliveryAddress = useMemo(() => {
        if (deliveryAddresses?.addresses.length) {
            const defaultDeliveryAddress = deliveryAddresses.addresses.find((deliveryAddress) => deliveryAddress.isSelected)
            return defaultDeliveryAddress
        }
    }, [deliveryAddresses])

    useEffect(() => {
        if (equals(normalizeAddress(addressBeingEdited), normalizeAddress(selectedDeliveryAddress))) {
            setAddressHasChanges(false)
        } else {
            setAddressHasChanges(true)
        }
    }, [selectedDeliveryAddress, addressBeingEdited])

    function handleChangeTitle(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, title: value } : prev))
    }

    function handleChangeFirstName(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, firstName: value } : prev))
    }

    function handleChangeLastName(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, lastName: value } : prev))
    }

    function handleChangeCompanyName(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, companyName: value } : prev))
    }

    function handleChangeStreet(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, street: value } : prev))
    }

    function handleChangeZip(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, zip: value } : prev))
    }

    function handleChangeCity(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, city: value } : prev))
    }

    function handleChangeCountry(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, country: value } : prev))
    }

    function handleChangePhone(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, phone: value } : prev))
    }

    function handleChangeEmail(value: string) {
        setAddressBeingEdited((prev) => (prev ? { ...prev, eMail: value } : prev))
    }

    function handleSaveDeliveryAddressChange() {
        if (addressBeingEdited && deliveryAddresses) {
            onSetDeliveryAddress(addressBeingEdited, selectedOptionsItem)
            onCloseEditMode()
        }
    }

    function handleClearAddressBeingEdited() {
        setAddressBeingEdited((prev) =>
            prev
                ? {
                      ...prev,
                      addressAddition: prev.addressAddition === undefined ? undefined : "",
                      city: prev.city === undefined ? undefined : "",
                      companyName: prev.companyName === undefined ? undefined : "",
                      country: prev.country === undefined ? undefined : "",
                      eMail: prev.eMail === undefined ? undefined : "",
                      firstName: prev.firstName === undefined ? undefined : "",
                      lastName: prev.lastName === undefined ? undefined : "",
                      phone: prev.phone === undefined ? undefined : "",
                      street: prev.street === undefined ? undefined : "",
                      title: prev.title === undefined ? undefined : "",
                      titleType: TitleType.Undefined,
                      zip: prev.zip === undefined ? undefined : "",
                  }
                : prev
        )
    }
    return (
        <Stack spacing={0.5} width="67rem" pt="4px" pl="34.4px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">
                    {translateText(13223).replace("{0}", addressBeingEdited?.description || translateText(13221))}
                </Typography>
                <RowStack gap={0.5}>
                    <Button startIcon={<Icon name="refresh" />} title={translateText(13288)} onClick={handleClearAddressBeingEdited} />
                    {!addressToEdit.isExpanded && (
                        <Button disabled={isSaving} startIcon={<Icon name="close" />} title={translateText(70)} onClick={onCloseEditMode} />
                    )}
                    <Button
                        color={addressHasChanges ? "success" : undefined}
                        disabled={!addressHasChanges}
                        startIcon={<Icon name="check" />}
                        title={translateText(9)}
                        onClick={handleSaveDeliveryAddressChange}
                    />
                </RowStack>
            </Box>
            <RowStackWithPaddingTop>
                {addressBeingEdited?.companyName !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 100 }}
                        label={translateText(108).toUpperCase()}
                        sx={{ flexGrow: 1 }}
                        value={addressBeingEdited.companyName}
                        onChange={(e) => handleChangeCompanyName(e.target.value)}
                    />
                )}
                {addressBeingEdited?.title !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 15 }}
                        label={translateText(118).toUpperCase()}
                        value={addressBeingEdited.title}
                        onChange={(e) => handleChangeTitle(e.target.value)}
                    />
                )}
                {addressBeingEdited?.firstName !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 100 }}
                        label={translateText(119).toUpperCase()}
                        sx={{ flexGrow: 1 }}
                        value={addressBeingEdited.firstName}
                        onChange={(e) => handleChangeFirstName(e.target.value)}
                    />
                )}
                {addressBeingEdited?.lastName !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 100 }}
                        label={translateText(104).toUpperCase()}
                        sx={{ flexGrow: 1 }}
                        value={addressBeingEdited.lastName}
                        onChange={(e) => handleChangeLastName(e.target.value)}
                    />
                )}
            </RowStackWithPaddingTop>
            <RowStackWithPaddingTop>
                {addressBeingEdited?.street !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 100 }}
                        label={translateText(12439).toUpperCase()}
                        sx={{ flexGrow: 1 }}
                        value={addressBeingEdited.street}
                        onChange={(e) => handleChangeStreet(e.target.value)}
                    />
                )}
                {addressBeingEdited?.zip !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 10 }}
                        label={translateText(112).toUpperCase()}
                        value={addressBeingEdited.zip}
                        onChange={(e) => handleChangeZip(e.target.value)}
                    />
                )}
                {addressBeingEdited?.city !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 100 }}
                        label={translateText(845).toUpperCase()}
                        sx={{ flexGrow: 1 }}
                        value={addressBeingEdited.city}
                        onChange={(e) => handleChangeCity(e.target.value)}
                    />
                )}
                {addressBeingEdited?.country !== undefined && (
                    <TextField
                        clearButton
                        inputProps={{ maxLength: 35 }}
                        label={translateText(114).toUpperCase()}
                        sx={{ flexGrow: 1 }}
                        value={addressBeingEdited.country}
                        onChange={(e) => handleChangeCountry(e.target.value)}
                    />
                )}
            </RowStackWithPaddingTop>
            {!hideOrderOptionsContactData && (
                <RowStack>
                    {addressBeingEdited?.phone !== undefined && (
                        <TextField
                            clearButton
                            inputProps={{ maxLength: 50 }}
                            label={translateText(110).toUpperCase()}
                            sx={{ flexGrow: 1 }}
                            value={addressBeingEdited.phone}
                            onChange={(e) => handleChangePhone(e.target.value)}
                        />
                    )}
                    {addressBeingEdited?.eMail !== undefined && (
                        <TextField
                            clearButton
                            inputProps={{ maxLength: 200 }}
                            label={translateText(109).toUpperCase()}
                            sx={{ flexGrow: 1 }}
                            value={addressBeingEdited.eMail}
                            onChange={(e) => handleChangeEmail(e.target.value)}
                        />
                    )}
                </RowStack>
            )}
        </Stack>
    )
}
