import { memo, useState } from "react"

import { isWM } from "@bundles/compilations/utils"
import { Button, ConfirmationDialog, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ConfigParams, ConfirmationDialogKeys } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Compilation } from "../../../../../data"
import { useDeleteCompilations } from "../../../../../data/hooks/useCompilations"

export interface IProps {
    compilation: Compilation
    openCompilations(): void
}

export const CompilationDeleteNavigation = memo<IProps>(({ compilation, openCompilations }) => {
    const { deleteCompilations } = useDeleteCompilations()
    const { translateText } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

    function handleDeleteDialogOpenStateChange() {
        setShowDeleteDialog((prev) => !prev)
    }

    const handlePartsWarningConfirm = () => {
        deleteCompilations([compilation.id])
        openCompilations()
    }

    return (
        <>
            <Button
                disabled={!compilation.isOwn}
                onClick={handleDeleteDialogOpenStateChange}
                startIcon={<Icon name="delete" />}
                title={translateText(69)}
                size={isWM() ? "small" : "medium"}
                className="delete-compilation"
                color={deleteButtonColorError ? "error" : undefined}
            />
            <ConfirmationDialog
                open={showDeleteDialog}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                onConfirm={handlePartsWarningConfirm}
                onCancel={handleDeleteDialogOpenStateChange}
                confirmationKey={ConfirmationDialogKeys.DeleteCompilations}
            >
                <Typography>{translateText(1313)}</Typography>
            </ConfirmationDialog>
        </>
    )
})
