export function findIconByAppTypeId(appTypeID: string) {
    switch (appTypeID) {
        case "1": // TGA
            return "CERT"
        case "2": // ABE
            return "TUV"
        case "3": // ECE
            return "E1"
        case "ASA":
            return "asa"
        case "OE":
            return "oe"
        default:
            return ""
    }
}
