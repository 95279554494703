import { EFilterNames, TyreFilter } from "@tm/models"
import { ListState } from "../model"
import { SummaryState } from "../../../summary/business"

export function getSelectedCriteria(state: ListState, byMatchCode?: boolean): TyreFilter[] {
    const {
        filters,
        selectedFilters,
        lastSearch: { seasons },
        seasonInitialized,
    } = state
    const fuelEfficiency = getAllFiltersBefore(filters.fuelEfficiency, selectedFilters.fuelEfficiency)
    const wetGripClass = getAllFiltersBefore(filters.wetGripClass, selectedFilters.wetGripClass)
    const externalRolling = getAllFiltersBefore(filters.externalRolling, selectedFilters.externalRolling)

    let selectedSeasons: TyreFilter[] = []

    if (!byMatchCode) {
        if (selectedFilters.season?.length) {
            selectedSeasons = selectedFilters.season
        } else if (seasons?.length && !seasonInitialized) {
            selectedSeasons = seasons.map((season) => <TyreFilter>{ query: season, value: season, group: EFilterNames.season })
        }
    }

    return [
        ...fuelEfficiency,
        ...wetGripClass,
        ...externalRolling,
        ...(selectedFilters.speedIndex ?? []),
        ...(selectedFilters.loadIndex ?? []),
        ...(selectedFilters.manufacturer ?? []),
        ...(selectedFilters.weight ?? []),
        ...(selectedFilters.oeIdentifier ?? []),
        ...(selectedFilters.extras ?? []),
        // TODO check extra unused keys
        // ...selectedFilters.studdable ?? [],
        // ...selectedFilters.studed ?? [],
        // ...selectedFilters.silent ?? [],
        ...selectedSeasons,
    ]
}

function getAllFiltersBefore(items: TyreFilter[], selected?: TyreFilter) {
    return !selected ? [] : items.filter((item) => item.query <= selected.query)
}

export function getSelectedCriteriaForSummary(state: SummaryState): TyreFilter[] {
    const { selectedFilters } = state

    return [
        ...((selectedFilters.speedIndex && [selectedFilters.speedIndex]) ?? []),
        ...((selectedFilters.loadIndex && [selectedFilters.loadIndex]) ?? []),
        ...((selectedFilters.oeIdentifier && [selectedFilters.oeIdentifier]) ?? []),
        ...((selectedFilters.season && [...selectedFilters.season]) ?? []),
    ]
}
