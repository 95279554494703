import { useEffect, useMemo } from "react"
import { classes, getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, DateField, Headline, Icon, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { maintenanceReviewActions, stepNavigationActions, testDriveActions, worksActions, useFastServiceStore } from "../../data"
import { getTranslationByLocation } from "../../helpers"
import NextStep from "../_shared/nextStep"
import { getComponentStyles } from "../_shared/styles"
import { StepNames } from "../../data/models"

const currentStepLocation = StepNames.TestDrive

export default function TestDrive() {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])
    const classNames = getComponentStyles()
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const { vehicle } = workTask || {}

    const { testDriveState, workData, selectedItems, inputsLocked } = useFastServiceStore((state) => ({
        testDriveState: state.testDriveState,
        workData: state.worksState.works[currentStepLocation],
        selectedItems: state.worksState.selectedItems,
        inputsLocked: state.inputsLocked
    }))

    const { date, mileageStart, mileageEnd, timeStart, timeEnd, testDriveStatus } = testDriveState
    const selectedItemForStep = selectedItems[currentStepLocation] ?? []

    const handleUpdateMileage = (mileage: string) => {
        const mileageNumber = parseInt(mileage.replace(/\D/g, ""))

        if (vehicle && (!vehicle?.mileAge || mileageNumber)) {
            attachToWorkTask?.({ vehicle: { ...vehicle, mileAge: mileageNumber } })
        }
    }

    useEffect(() => {
        if (testDriveStatus) {
            stepNavigationActions.completeStep(currentStepLocation)
            stepNavigationActions.updateNextStep(currentStepLocation)
        }
    }, [testDriveStatus])

    useEffect(() => {
        if (Object.keys(selectedItemForStep).length === 0) {
            return
        }

        maintenanceReviewActions.sendItemsToMaintenanceReview(getTranslationByLocation(currentStepLocation), selectedItemForStep)
    }, [selectedItemForStep])

    const handleDate = (value: Date) => {
        if (value && typeof value === "object") {
            testDriveActions.setDate(value)
        }
    }
    const handleTimeEnd = (value: string) => {
        if (value) {
            testDriveActions.setTimeEnd(value)
        }
    }
    const handleTimeStart = (value: string) => {
        testDriveActions.setTimeStart(value)
    }
    const handleMileageEnd = (value: string) => {
        testDriveActions.setMileageEnd(value)
        handleUpdateMileage(value)
    }
    const handleMileageStart = (value: string) => {
        testDriveActions.setMileageStart(value)
    }

    const handeleOkTestDrive = () => {
        testDriveActions.setTestDriveStatus(true)
        const selectedWorkData = workData?.find((w) => w.providerId === "1000")
        const path = selectedWorkData?.label
        if (path) {
            worksActions.updateWorkField({ key: "selectedStatusButton", value: "checkButton" }, path, currentStepLocation)
        }
    }

    return (
        <>
            <div className={classes(style.wrapper, classNames.wrapper)}>
                <Headline className={classNames.marginBottomL} size="xs">
                    {" "}
                    {translateText(12943)}
                </Headline>
                <div className={classes(classNames.flexContainerWithGap, classNames.flexOne)}>
                    <div className={classNames.flexColumn}>
                        <div className={classes(classNames.flex, classNames.marginBottomL, classNames.marginBottomS)}>
                            <Icon name="calendar" />
                            <Text className={style.textLeftMargin} size="xl">
                                {translateText(98)}
                            </Text>
                        </div>
                        <DateField
                            className={classNames.marginBottomS}
                            size="xl"
                            value={date}
                            onChange={handleDate}
                            openToDate={date}
                            ignoreOnSelect
                            disabled={inputsLocked}
                        />
                    </div>
                    <div className={classNames.flexColumn}>
                        <div className={classes(classNames.flex, classNames.marginBottomS)}>
                            <Icon name="time" />
                            <Text className={style.textLeftMargin} size="xl">
                                {translateText(12944)}
                            </Text>
                        </div>
                        <TextField
                            className={classNames.marginBottomS}
                            size="xl"
                            placeholder={translateText(12945)}
                            value={timeStart || undefined}
                            onChangeConfirm={handleTimeStart}
                            maxLength={5}
                            formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, ":")}
                            inputMode="numeric"
                            disabled={inputsLocked}
                        />
                        <TextField
                            className={classNames.marginBottomS}
                            size="xl"
                            placeholder={translateText(12946)}
                            value={timeEnd || undefined}
                            onChangeConfirm={handleTimeEnd}
                            maxLength={5}
                            formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, ":")}
                            inputMode="numeric"
                            disabled={inputsLocked}
                        />
                    </div>
                    <div className={classNames.flexColumn}>
                        <div className={classes(classNames.flex, classNames.marginBottomS)}>
                            <Icon name="kilometerstand" />
                            <Text className={style.textLeftMargin} size="xl">
                                {translateText(125)}
                            </Text>
                        </div>
                        <TextField
                            className={classNames.marginBottomS}
                            size="xl"
                            placeholder={translateText(12947)}
                            value={mileageStart || undefined}
                            onChangeConfirm={handleMileageStart}
                            maxLength={9}
                            formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            inputMode="numeric"
                            disabled={inputsLocked}
                        />
                        <TextField
                            className={classNames.marginBottomS}
                            size="xl"
                            placeholder={translateText(12948)}
                            value={mileageEnd || undefined}
                            onChangeConfirm={handleMileageEnd}
                            maxLength={9}
                            formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            inputMode="numeric"
                            disabled={inputsLocked}
                        />
                    </div>
                </div>
                <div className={classes(classNames.flexColumn, classNames.flexOne, classNames.marginBottomXl)}>
                    <Headline className={classNames.marginBottomL} size="xs">
                        {" "}
                        {translateText(12949)}
                    </Headline>
                    <div className={classes(classNames.flexContainerWithGap)}>
                        <Button className={classNames.buttonPadding} size="xl" disabled={inputsLocked}>
                            {translateText(12950)}
                        </Button>
                        <Button skin="highlight" className={classNames.buttonPadding} size="xl" onClick={handeleOkTestDrive} disabled={inputsLocked}>
                            {translateText(585)}
                        </Button>
                    </div>
                </div>
            </div>

            <NextStep currentStepName={currentStepLocation} buttonTextId={12941} icon="arrows_down" active={testDriveStatus} />
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            padding: "0em 1em 0 1.5em",
        },
        textLeftMargin: {
            marginLeft: theme.margin.m,
        },
    })(TestDrive)
}
