import { IMicros } from "@tm/models"
import AvailabilityWrapperComponent from "../../../_shared/availability-wrapper"

type Props = IMicros["erp"]["display-only-availability"]

export default function ErpInfoDisplayOnlyAvailabilityComponent(props: Props) {
    return (
        <AvailabilityWrapperComponent
            onClick={props.onClick}
            availability={props.availability}
            tour={props.tour}
            isCompact={props.isCompact}
            warehouses={props.warehouses}
            requestedQuantity={undefined}
        />
    )
}
