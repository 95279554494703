import { useCallback, useEffect, useState } from "react"
import {
    withRouter,
    renderRoute,
    createQueryString,
    parseQueryString,
    RouteComponentProps,
    TmaHelper,
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    encodeUniqueId,
    uniqueId,
} from "@tm/utils"
import { useDispatch, useSelector } from "react-redux"
import { useLocalization } from "@tm/localization"
import { Button, styled, Tooltip, Switch as SwitchMui, Box, Icon, useTheme } from "@tm/components"
import { Switch, Toolbar } from "@tm/controls"
import { useDisplayListV2, useUser } from "@tm/context-distribution"
import Morpheus, { useMicro } from "@tm/morpheus"
import { Article, getCurrentWorkTaskId, IMicros, RepairTimeProvider } from "@tm/models"
import WheelsSelectionStepsComponent from "./components/wheels-selection-steps"
import { getMatchParamFromActiveStep } from "./business/helper"
import { WheelSelectionSteps } from "../../data/enums"
import { bundleChannel } from "../.."
import { PurchasePriceSwitch } from "../_shared"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import ApplyButton from "../_shared/applyBtn"
import { Actions } from "./business"
import MountingSwitch from "../_shared/mountingSwitch"
import OptionsSwitch from "../_shared/optionsSwitch"
import { getBundleParams } from "../../utils"

type RouteParams = {
    workTaskId: string
    view: string
}

type NavigationComponentProps = RouteComponentProps<RouteParams> & {
    wheelsRoute: string
    repairTimesRoute: string
}

const CompactViewToolbar = styled(Toolbar)({
    ":before": {
        borderLeft: "transparent !important",
    },
})

function NavigationComponent({ wheelsRoute, match, history, repairTimesRoute }: NavigationComponentProps) {
    const dispatch = useDispatch()
    const user = useUser()

    const { translateText } = useLocalization()
    const theme = useTheme()
    const isHistoryActivated = false

    const displayListV2 = useDisplayListV2()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const isLKQ = getBundleParams()?.version === "LKQ"
    const { renderMicro } = useMicro<IMicros>()
    const { repairTimeProviders } = getRepairTimeProviders()
    const { userSettings } = useUser()

    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const toggleTooltip = useCallback(() => setIsTooltipOpen((prev) => !prev), [])
    const closeTooltip = useCallback(() => setIsTooltipOpen(false), [])

    const { selectedTabs, activeStep, configuratorImage, rimItem, tireItem, sensorItem, mountingEnabled, mountingItem } = useSelector(
        (state: MainState) => ({
            selectedTabs: state.navigation.selectedTabs,
            activeStep: state.navigation.activeStep,
            configuratorImage: state.overview.configuratorImage,
            rimItem: state.overview.rimItem,
            tireItem: state.overview.tireItem,
            sensorItem: state.overview.sensorItem,
            mountingEnabled: state.overview.mountingEnabled,
            mountingItem: state.overview.mountingItem,
        })
    )

    const items = React.useMemo(
        () => [rimItem, sensorItem, tireItem, mountingEnabled ? mountingItem : undefined].filter((x) => !!x) as Article[],
        [rimItem, sensorItem, tireItem, mountingEnabled, mountingItem]
    )

    useEffect(() => {
        if (match.params.view != getMatchParamFromActiveStep(activeStep) && isHistoryActivated) {
            // onRefresh
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.NONE, disableNextSteps: true }, false))
        }

        const unsubscribeSendTireToOverview = bundleChannel().subscribe("SEND_TIRE_ARTICLE_TO_OVERVIEW", ({ article }) => {
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, true))
            dispatch(Actions.sendTireToOverview(article))
        })

        const unsubscribeSendRdksToTires = bundleChannel().subscribe("SEND_RDKS_ARTICLE_TO_OVERVIEW", ({ article }) => {
            dispatch(MainActions.sendSensorToOverview(article))
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, true))
        })

        return () => {
            unsubscribeSendTireToOverview?.()
            unsubscribeSendRdksToTires?.()
        }
    }, [])

    useEffect(() => {
        if (activeStep in WheelSelectionSteps) {
            const routeParams =
                activeStep == WheelSelectionSteps.NONE
                    ? { workTaskId: match.params.workTaskId, ...selectedTabs[activeStep]?.params, view: getMatchParamFromActiveStep(activeStep) }
                    : { ...match.params, ...selectedTabs[activeStep]?.params, view: getMatchParamFromActiveStep(activeStep) }
            let url = renderRoute(wheelsRoute, routeParams)
            const queryParams: Record<string, any> = parseQueryString(location.search) ?? {}
            delete queryParams.initialQuantity

            if (url[url.length - 1] == "/") {
                // if last char is / it has to be removed -> Need for Module-Broadcast
                url = url.substr(0, url.length - 1)
            }

            if (activeStep == WheelSelectionSteps.NONE) {
                history.replace(url + createQueryString(queryParams))
            } else if (
                activeStep == WheelSelectionSteps.TIREDETAILS ||
                (activeStep == WheelSelectionSteps.RDKSDETAILS && selectedTabs[activeStep]?.quantity)
            ) {
                queryParams.initialQuantity = selectedTabs[activeStep]?.quantity
                history.push(url + createQueryString(queryParams))
            } else {
                history.push(url + createQueryString(queryParams))
            }
        }
    }, [activeStep])

    const getRepairTimesUrl = (article: any, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
        if (article.productGroup && repairTimeProviders.length && userSettings) {
            let provider
            if (Array.isArray(rtProviders)) {
                const activeRTProvider = userSettings.activeVehicleDataProviders.repairTimes

                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider) {
                return
            }

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide,
                    workTaskId: encodeUniqueId(uniqueId()),
                })
            )
        }
    }

    const handleNavigateTo = (step: WheelSelectionSteps) => {
        let quantity
        if (step == WheelSelectionSteps.TIREDETAILS) {
            quantity = selectedTabs[WheelSelectionSteps.TIREDETAILS]?.quantity
        }
        dispatch(MainActions.changeStep({ step, quantity }, false))
    }

    const handleTireDetailsSkip = () => {
        dispatch(MainActions.changeStep({ step: activeStep + 1 }, false))
    }
    const handleChangeCompactView = (compactView: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    compactView,
                },
            })
        }
    }

    const handleChangeShowArticleImages = (showArticleImages: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    showArticleImages,
                },
            })
        }
    }
    const renderArtListSettings = () => {
        if (displayListV2 && !isWm) {
            return (
                <>
                    <CompactViewToolbar title={translateText(795)}>
                        <SwitchMui
                            checked={user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages}
                            onChange={(event, checked) => handleChangeShowArticleImages(checked)}
                        />
                    </CompactViewToolbar>
                    {renderOptions()}
                    <CompactViewToolbar title={translateText(3009)}>
                        <SwitchMui
                            checked={user?.userSettings?.articleListSettings?.viewOptions?.compactView}
                            onChange={(event, checked) => handleChangeCompactView(checked)}
                        />
                    </CompactViewToolbar>
                </>
            )
        }

        return (
            <>
                <Toolbar className="tk-parts switch" title={translateText(795)}>
                    <Switch
                        status={user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages}
                        onChange={handleChangeShowArticleImages}
                        alignLabel="left"
                    />
                </Toolbar>
                {renderOptions()}
                <Toolbar className="tk-parts switch" title={translateText(794)}>
                    <Switch
                        status={user?.userSettings?.articleListSettings?.viewOptions?.compactView}
                        onChange={handleChangeCompactView}
                        alignLabel="left"
                    />
                </Toolbar>
            </>
        )
    }

    const renderOptions = () => {
        if (getBundleParams()?.priceAvailabilityInformation && ["wheels-details", "wheels-list"].includes(match.params.view)) {
            const borderColor = isTooltipOpen ? theme.palette.primary.main : undefined

            return (
                <Toolbar title={translateText(361)}>
                    <Tooltip
                        open={isTooltipOpen}
                        variant="light"
                        onClickAway={closeTooltip}
                        title={<OptionsSwitch />}
                        sx={{
                            "& .MuiTooltip-tooltip, & .MuiTooltip-arrow::before": {
                                borderColor,
                            },
                        }}
                    >
                        <Button variant="outlined" style={{ borderColor, color: "gray", padding: "0.25em" }} onClick={toggleTooltip}>
                            <Icon name="settings" color={borderColor} />
                        </Button>
                    </Tooltip>
                </Toolbar>
            )
        }

        if (!["vehicle-selection"].includes(match.params.view)) {
            return <PurchasePriceSwitch />
        }
    }

    if (!match.params.view) {
        return null
    }

    const renderNextActionsButtons = () => {
        TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext("wheels", "", getCurrentWorkTaskId())

        return (
            <Box display="flex" margin=".6em 0 0 auto" gap=".2em">
                {repairTimeProviders?.length > 0 &&
                    items.filter((x) => x.isSelected).length > 0 &&
                    renderMicro!("basket", "add-to-cost-estimate", {
                        data: items.filter((x) => x.isSelected),
                        repairTimeProviders,
                        getRepairTimesUrl,
                        vehicleImageBase64: configuratorImage,
                    })}
                {items.filter((x) => x.isSelected).length > 0 &&
                    renderMicro!("basket", "add-to-basket", {
                        data: items.filter((x) => x.isSelected),
                        buttonText: translateText(133),
                        disabled: !items.length,
                        vehicleImageBase64: configuratorImage,
                        width: "12em",
                        hideBadge: true,
                        hideQuantityField: true,
                    })}
            </Box>
        )
    }

    return (
        <div className="tk-wheels__navigation" id="wheels-navigation" style={{ minHeight: "3em" }}>
            <WheelsSelectionStepsComponent key="wheels_steps" onChange={handleNavigateTo} />
            <DetailsSkipBtn onStepSkip={handleTireDetailsSkip} />
            {!["tyres-list", "rdks-list", "overview"].includes(match.params.view) && <>{renderOptions()}</>}

            {["tyres-list", "rdks-list", "overview"].includes(match.params.view) && (
                <>
                    {renderArtListSettings()}
                    <MountingSwitch />
                </>
            )}
            {["overview"].includes(match.params.view) && isLKQ && <>{renderNextActionsButtons()}</>}
        </div>
    )
}

export default withRouter(NavigationComponent)

function DetailsSkipBtn({ onStepSkip: handleSkip }: { onStepSkip: () => void }) {
    const { activeStep } = useSelector((s: MainState) => ({
        activeStep: s.navigation.activeStep,
    }))

    const { translateText } = useLocalization()

    return (
        <>
            {activeStep == WheelSelectionSteps.TIREDETAILS && <ApplyButton showSkipBtn onSkip={handleSkip} />}
            {activeStep == WheelSelectionSteps.RDKSDETAILS && <ApplyButton showSkipBtn onSkip={handleSkip} skipBtnText={translateText(1117)} />}
        </>
    )
}
