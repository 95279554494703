import { Quota, RegistrationNoType, VehicleType } from "@tm/models"
import { ajaxAuth } from "@tm/utils"

import { getBundleParams } from "../../utils"

export type VehicleLookupConfigSlim = {
    countryCode: string
    lookupId: RegistrationNoType
    showAdditionalSearchField?: boolean
    supportedVehicleTypes: VehicleType[]
    textId: number
    sortIndex: number
    quota?: Quota
    licensePlateCode?: string
}

export type VehicleLookupConfigSlimResponse = {
    lookupConfigs: VehicleLookupConfigSlim[]
}

function getOptionsServiceUrl() {
    return getBundleParams().optionsServiceUrl
}

export function getShowVehicleLookupConfigs() {
    return ajaxAuth<VehicleLookupConfigSlimResponse>({
        url: `${getOptionsServiceUrl()}/ShowVehicleLookupConfig`,
    }).then((response) => {
        if (!response) {
            throw new Error()
        }
        const mappedConfigs = response.lookupConfigs.map(fixDefaultLookupId)
        return { lookupConfigs: mappedConfigs }
    })
}

function fixDefaultLookupId(config: VehicleLookupConfigSlim): VehicleLookupConfigSlim {
    const mappedConfig: VehicleLookupConfigSlim = { ...config, lookupId: config.lookupId || RegistrationNoType.Kba }
    return mappedConfig
}
