import { FC } from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { DateField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getComponentStyles } from "../../_shared/styles"
import { inspectionWorkActions, useFastServiceStore } from "../../../data"

const IntervalDate: FC = () => {
    const classNames = getComponentStyles()
    const { translateText } = useLocalization()

    const { kmValue, date, inputsLocked } = useFastServiceStore((state) => ({
        kmValue: state.inspectionKmValue,
        date: state.inspectionDate,
        inputsLocked: state.inputsLocked
    }))

    const handleDateChange = (value: Date | undefined) => {
        if (value) {
            inspectionWorkActions.saveInspectionDate(value)
        }
    }

    const handleKmValue = (value: string) => {
        inspectionWorkActions.saveInspectionKm(value)
    }

    return (
        <div className={style.wrapper}>
            <DateField size="xl" useUtc value={date} minDate={new Date()} openToDate={date || new Date()} onChange={handleDateChange} disabled={inputsLocked} />
            <TextField
                className={classes(classNames.marginBottomS, style.marginLeft)}
                size="xl"
                placeholder={translateText(125)}
                value={kmValue}
                onChange={handleKmValue}
                maxLength={9}
                formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                inputMode="numeric"
                disabled={inputsLocked}
            />
        </div>
    )
}

export default IntervalDate

const style = useStyle({
    wrapper: {
        display: "flex",
        marginTop: "1em",
    },
    marginLeft: {
        marginLeft: "3em",
    },
})(IntervalDate)
