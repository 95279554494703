import { useStyle, useUser } from "@tm/context-distribution"
import { Button, Grid, Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getCurrentWorkTaskId, SystemType, TmaEModule } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { bem, decodeUniqueId, renderRoute, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { em, percent } from "csx"
import { useSelector } from "react-redux"
import { FC } from "react"
import Graphical from "../../components/summary/components/graphical"
import { ErrorMessage, EurotaxPanel } from "../../components/_shared"
import { bundleChannel } from "../../data/channel"
import { useVehicle } from "../../data/hooks"
import { ErePosition, MmtGroup } from "../../data/models"
import { Actions } from "./bussines"
import { SearchMicroState } from "./bussines/model"

type Props = RouteComponentProps<any> & {}

type SizeValues = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const grids: Record<number, SizeValues[]> = {
    1: [12],
    2: [6, 6],
    3: [6, 6, 12],
    4: [6, 6, 6, 6],
    5: [4, 4, 4, 6, 6],
    6: [4, 4, 4, 4, 4, 4],
}

const PartsSearchWidget: FC<Props> = ({ match, history }) => {
    const {
        vehicle,
        cars: { groups, error, loading },
    } = useSelector((s: SearchMicroState) => s)
    const actions = useActions(Actions)
    const { translateText } = useLocalization()
    const user = useUser()
    const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(match.params.workTaskId ?? "")

    useVehicle(workTaskId, vehicle, (newVeh) => {
        actions.setVehicle(newVeh)
        actions.loadEurotaxCars()
    })

    const handleSelect = (item: MmtGroup, position: ErePosition) => {
        bundleChannel().publish("INIT_EUROTAX_FROM_WIDGET", {
            erePosition: position,
            groups,
            selectedMmtGroup: item,
        })
        const url = renderRoute("/:workTaskId/eurotax/details", match.params)
        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.WithRedirect(url, "", TmaEModule.GPI_EUROTAX)
        history.push(url)
    }

    if (loading) {
        return (
            <div className={bem(style.summary, "loading")}>
                <Loader />
            </div>
        )
    }

    if (error || !groups.length) {
        const additionalContent = user?.userContext?.system.systemType == SystemType.Next && (
            <Button icon="dashboard" onClick={() => {}}>
                {" "}
                {translateText(760)}
            </Button>
        )

        return (
            <EurotaxPanel>
                {vehicle && <ErrorMessage description={`${vehicle?.manufacturer + vehicle?.modelSeries} ${translateText(1493)}`} />}

                {!vehicle && (
                    <ErrorMessage icon="no-results" title={100}>
                        {additionalContent}
                    </ErrorMessage>
                )}
            </EurotaxPanel>
        )
    }

    return (
        <Grid container className={style.summary}>
            {groups.map((item, idx) => (
                <Grid key={idx} m={grids[groups.length]?.[idx] ?? 3} auto className={style.gridItem}>
                    <Graphical
                        index={idx}
                        item={item}
                        disabledZoom
                        withoutPanel
                        onSelect={handleSelect.bind(undefined, item)}
                        multiselection={groups.length > 1}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

const style = useStyle({
    summary: {
        height: percent(100),
        minHeight: em(15),
        display: "flex",
        flex: "1",
        overflow: "hidden",
        $nest: {
            "&--loading": {
                justifyContent: "center",
                alignItems: "center",
            },
        },
    },
    gridItem: {
        display: "flex",
    },
})(PartsSearchWidget)

export default withRouter(PartsSearchWidget)
