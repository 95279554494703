import { Button, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { FC } from "react"
import { getEdsRdArticles } from "../../data/helpers"
import { getRdSession } from "../../utils"

type Props = IMicros["standalone"]["rd-eds-openOeArticleList"]

const OpenEdsOeArticleList: FC<Props> = ({ type, part, getParts, manufacturerName, children, className, title, size }) => {
    const { translateText } = useLocalization()

    const handlePartSelect = () => {
        const oeArticles = getEdsRdArticles(part, getParts?.() ?? [])

        const data = {
            sessionId: getRdSession(),
            method: "openOEArticleList",
            sourceId: "TM_EDS",
            oeArticles,
            manufacturerName,
            title: translateText(628),
        }

        window.parent.postMessage(data, "*")
    }

    if (type === "text") {
        return (
            <Typography variant={size} onClick={handlePartSelect} className={className}>
                {children}
            </Typography>
        )
    }

    return (
        <Button size={size} title={title} onClick={handlePartSelect} className={className}>
            {children}
        </Button>
    )
}

export default OpenEdsOeArticleList
