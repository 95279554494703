import { Article, ArticleSelectionParams } from "@tm/models"
import { createContext, useContext } from "react"

export type ArticleSelection = ArticleSelectionParams<Article>

const ArticleSelectionContext = createContext<ArticleSelection | undefined>(undefined)

export const ArticleSelectionProvider = ArticleSelectionContext.Provider

export function useArticleSelection() {
    const context = useContext(ArticleSelectionContext)

    if (!context) {
        throw new Error(`Used ${useArticleSelection.name} outside of ${ArticleSelectionProvider.name}`)
    }

    return context
}
