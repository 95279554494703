import { AmountItem } from "@tm/controls"
import { Article } from "@tm/models"
import { Box, Checkbox, ListItemIcon, Typography } from "@tm/components"
import { useMemo } from "react"
import { useFastServiceStore, useWorkContext, worksActions } from "../../../data"
import { Genart } from "../../../data/models"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"
import ArticleComponent from "./shared/ArticleComponent"

type Props = {
    genArt: Genart
    fsArticle?: FSArticle
    article: Article
    selectedGenArts?: Genart[]
    onQuantityArticleChange: (articleId: string, quantity: number) => void
    showPartAlternatives: (genArt: Genart, article?: FSArticle, oeArticle?: boolean) => void
    disableCheckToggle?: boolean
    enableOeReplace?: boolean
    enableReplaceWithoutFsArticle?: boolean
}

export default function PartItem({
    genArt,
    fsArticle,
    article,
    onQuantityArticleChange,
    showPartAlternatives,
    disableCheckToggle,
    enableOeReplace,
    selectedGenArts,
    enableReplaceWithoutFsArticle,
}: Props) {
    const { step, path } = useWorkContext()

    const { stateVehicle, selectedItems, inputsLocked } = useFastServiceStore((state) => ({
        stateVehicle: state.maintenancePlan.stateVehicle,
        selectedItems: state.worksState.selectedItems,
        inputsLocked: state.inputsLocked
    }))

    const selectedArticles = useMemo(() => selectedItems[step][path].selectedArticles, [step, path, selectedItems])

    const checkSelectedArticles = selectedArticles?.some((x) => x.internalId === article.internalId && x.id === article.id) || false
    const checkSelectedGenArts = selectedGenArts?.some((x) => x.label === genArt.label && x.genArtNr === genArt.genArtNr) || false

    const handleOnSelect = () => {
        worksActions.setUpdateSelectedArticles(step, path, article)
    }

    const handleQuantityChange = (item: AmountItem) => {
        onQuantityArticleChange(article.id, item.value)
        worksActions.setUpdateArticleQuantity(step, path, article, item.value)
    }

    const handleShowPatAlternative = () => {
        showPartAlternatives(genArt, fsArticle, enableOeReplace)
    }

    const getContent = () => {
        const disableReplace = enableReplaceWithoutFsArticle ? !enableReplaceWithoutFsArticle : !fsArticle

        if (genArt.oeReplace) {
            return (
                <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" alignItems="center">
                        <ListItemIcon>
                            <Checkbox
                                size="medium"
                                onChange={handleOnSelect}
                                checked={(checkSelectedArticles && checkSelectedGenArts) || genArt.isMandatory}
                                disabled={genArt.isMandatory || inputsLocked}
                            />
                        </ListItemIcon>
                        <Typography variant="h4" id={genArt.label}>
                            {genArt.label}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="0.5em">
                        <ArticleComponent
                            article={article}
                            vehicleId={stateVehicle?.id}
                            disableReplace={inputsLocked ? true : disableReplace}
                            handleQuantityChange={handleQuantityChange}
                            handleShowPartAlternative={handleShowPatAlternative}
                        />
                    </Box>
                </Box>
            )
        }

        return (
            <>
                <Checkbox size="medium" onChange={handleOnSelect} checked={checkSelectedArticles} disabled={disableCheckToggle || inputsLocked} />
                <ArticleComponent
                    article={article}
                    vehicleId={stateVehicle?.id}
                    disableReplace={inputsLocked ? true : disableReplace}
                    handleQuantityChange={handleQuantityChange}
                    handleShowPartAlternative={handleShowPatAlternative}
                />
            </>
        )
    }

    return (
        <Box paddingLeft="2px" className="tk-parts">
            <Box display="flex" gap="1em" className="article-list list">
                {getContent()}
            </Box>
        </Box>
    )
}
