import { DatePicker, Icon, SearchButton, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CalculatedCostType, CausedCostsType } from "@tm/models"
import { useEffect, useState } from "react"
import { isValidDate } from "@tm/utils"
import { RowStack } from "../../../_shared/StyledComponents"
import { CisFilters } from "../../../../business/model"
import { today } from "../../../../business/helper"
import CausedCostsFilterSelect from "./CausedCostsFilterSelect"
import CostTypeSelect from "./CostTypeSelect"
import { useCalculatedCostTypes } from "../../../../data/hooks/useCalculatedCostTypes"

type Props = {
    setFilters(filter: CisFilters): void
    resetFilters(): void
    filters: CisFilters
}

export default function CostOverviewFilter(props: Props) {
    const { setFilters, resetFilters, filters } = props
    const { translateText, language } = useLocalization()
    const [selectedCauseCostsType, setSelectedCauseCostsType] = useState<CausedCostsType>(CausedCostsType.All)
    const [selectedCostType, setSelectedCostType] = useState<CalculatedCostType>(CalculatedCostType.Unknown)

    const { calculatedCostTypes } = useCalculatedCostTypes()

    function handleChangeDateFrom(dateFrom: Date | null) {
        setFilters({ ...filters, dateFrom: dateFrom || undefined })
    }

    function handleChangeDateTo(dateTo: Date | null) {
        setFilters({ ...filters, dateTo: dateTo || undefined })
    }

    function handleChangeSelectedType(calculatedCostType: CalculatedCostType) {
        setFilters({ ...filters, calculatedCostType })
    }

    function handleChangeCausedCostsFilter(causedCostsType: CausedCostsType) {
        setFilters({ ...filters, causedCostsType })
    }

    useEffect(() => {
        setSelectedCauseCostsType(filters?.causedCostsType ?? CausedCostsType.All)
    }, [filters?.causedCostsType])

    useEffect(() => {
        setSelectedCostType(filters?.calculatedCostType ?? CalculatedCostType.Unknown)
    }, [filters?.calculatedCostType])

    return (
        <RowStack justifyContent="flex-start" alignContent="center" alignItems="end" padding="12px">
            <CostTypeSelect
                onSelectedTypeChanged={handleChangeSelectedType}
                value={selectedCostType}
                availableCostTypes={calculatedCostTypes ?? [CalculatedCostType.Unknown]}
            />
            <CausedCostsFilterSelect onCausedCostsFilterChanged={handleChangeCausedCostsFilter} value={selectedCauseCostsType} />
            <Stack direction="column">
                <Typography variant="label">{translateText(98)}</Typography>
                <Stack direction="row">
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={handleChangeDateFrom}
                        value={filters.dateFrom || null}
                        maxDate={isValidDate(filters.dateTo) ? filters.dateTo : today}
                        size="large"
                        label={translateText(344)}
                    />
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={handleChangeDateTo}
                        value={filters.dateTo || null}
                        minDate={filters.dateFrom || undefined}
                        maxDate={today}
                        size="large"
                        label={translateText(95)}
                    />
                    <SearchButton startIcon={<Icon name="refresh" />} onClick={resetFilters} sx={{ marginLeft: "4px" }} />
                </Stack>
            </Stack>
        </RowStack>
    )
}
