import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { CisQueryType } from "@tm/models"
import { ButtonKeyDefinition, isValidDate } from "@tm/utils"
import { DatePicker, Icon, SearchButton, Stack, TextField, Typography } from "@tm/components"
import { today } from "../../../business/helper"
import { CisFilters } from "../../../business/model"
import { DropdownInputViewProps } from "../component"
import { useFilterStore } from "../../../business/state"

type Props = {
    filters?: CisFilters
    queryTypes?: Array<CisQueryType>
    searchFieldLabelId: number
    setFilters(filters: CisFilters): void
}

export default function PvOrdersFilterComponent({ queryTypes, filters, searchFieldLabelId, setFilters }: Props) {
    const { language, translateText } = useLocalization()
    const loading = useFilterStore((state) => state.loading)
    const [inputQuery, setInputQuery] = useState(filters?.query)

    function handleChangeDateFrom(dateFrom: Date | null) {
        setFilters({
            ...filters,
            dateFrom: dateFrom || undefined,
            deliveryNoteDate: dateFrom || undefined,
            invoiceDate: dateFrom || undefined,
        })
    }

    function handleChangeDateTo(dateTo: Date | null) {
        setFilters({ ...filters, dateTo: dateTo || undefined })
    }

    function handleButtonClick() {
        setFilters({ ...filters, query: inputQuery })
    }

    function handleResetClick() {
        setFilters({
            ...filters,
            queryTypeId: undefined,
            dateTo: undefined,
            dateFrom: undefined,
            deliveryNoteDate: undefined,
            invoiceDate: undefined,
            query: undefined,
        })
    }

    function searchQuery(query: string) {
        let change = false

        if (query !== filters?.query) {
            change = true

            if (filters?.query === undefined && query.trim() === "") {
                change = false
            }
        }

        if (change) {
            setFilters({ ...filters, query })
        }
    }

    function handleSearchKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === ButtonKeyDefinition.Enter) {
            const query = (e.target as HTMLInputElement).value
            searchQuery(query)
        }
    }

    let queryTypesItems: Array<DropdownInputViewProps> = []
    if (queryTypes) {
        queryTypesItems = queryTypes.map((queryType) => ({
            id: queryType.id,
            description: queryType.description,
            showSingleDateFilter: queryType.showSingleDateFilter,
        }))
    }

    const selectedQueryType = queryTypesItems.find((queryType) => queryType.id === filters?.queryTypeId) || queryTypesItems[0]

    return (
        <Stack direction="row" spacing={1}>
            <Stack direction="column">
                <Typography variant="label">{translateText(135)}</Typography>
                <TextField
                    value={inputQuery}
                    label={translateText(searchFieldLabelId)}
                    onKeyDown={handleSearchKeyDown}
                    onBlur={(e) => searchQuery(e.target.value)}
                    onChange={(e) => setInputQuery(e.target.value)}
                    inputProps={{ min: 0, maxLength: 50 }}
                    size="large"
                    sx={{ width: "20em" }}
                />
            </Stack>

            <Stack direction="row" alignItems="flex-end">
                <Stack direction="column">
                    <Typography variant="label">{translateText(98)}</Typography>
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={handleChangeDateFrom}
                        value={filters?.dateFrom || null}
                        maxDate={isValidDate(filters?.dateTo) ? filters?.dateTo : today}
                        size="large"
                        label={!selectedQueryType?.showSingleDateFilter ? translateText(344) : ""}
                    />
                </Stack>

                <Stack direction="row">
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={handleChangeDateTo}
                        value={filters?.dateTo || null}
                        minDate={filters?.dateFrom || undefined}
                        maxDate={today}
                        size="large"
                        label={translateText(95)}
                    />
                    <SearchButton disabled={loading} onClick={handleButtonClick} sx={{ marginRight: "4px" }} />
                    <SearchButton startIcon={<Icon name="refresh" />} onClick={handleResetClick} />
                </Stack>
            </Stack>
            <Typography variant="body3">{`*${translateText(694)}`}</Typography>
        </Stack>
    )
}
