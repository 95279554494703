import { ReactNode, useMemo } from "react"
import { Checkbox, DateField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes, formatVin, getRegistrationNumberTextIdByVrcLookupModule, ModelState } from "@tm/utils"
import { ShowVrcInformationResponse, VrcLookupModule } from "../../../../../data/model/vrc-lookup"
import { kba } from "../../../../../helpers"
import { getComponentStyles } from "../styles"

type Props = {
    enabled: boolean
    setEnabled(): void
    vrcInformationResponse: ShowVrcInformationResponse
    setVrcInformationResponse(vrmLookup: ShowVrcInformationResponse): void
    saving: boolean
    requestVehicles: boolean
    modelState: ModelState | undefined

    showNoResultError: boolean
    setShowNoResultError(value: boolean): void

    vrcLookupModule: VrcLookupModule
    vrcModuleIsUsingKbaNumbers: boolean

    appendix?: ReactNode
}

const VIN_LENGTH = 17

export function VehicleForm(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const {
        enabled,
        setEnabled,
        vrcInformationResponse,
        setVrcInformationResponse,
        saving,
        requestVehicles,
        modelState,
        showNoResultError,
        setShowNoResultError,
        vrcModuleIsUsingKbaNumbers,
        appendix,
        vrcLookupModule,
    } = props
    const { translateText } = useLocalization()

    const regNoTypeLabel = getRegistrationNumberTextIdByVrcLookupModule(vrcLookupModule)
    const vehicleDisabled = saving || requestVehicles || !enabled

    function setModelAndResetError(model: ShowVrcInformationResponse) {
        setVrcInformationResponse(model)

        if (showNoResultError) {
            setShowNoResultError(false)
        }
    }

    return (
        <div className={classes(classNames.form, !enabled && classNames.formDisabled)}>
            <Checkbox label={translateText(99)} size="l" checked={enabled} disabled={saving || requestVehicles} onToggle={setEnabled} />

            <div>
                <DateField
                    floatingLabel
                    label={translateText(124)}
                    value={vrcInformationResponse.info.registrationDate}
                    model={vrcInformationResponse}
                    path={["info", "registrationDate"]}
                    onChange={(registrationDate: Date) =>
                        setVrcInformationResponse({ ...vrcInformationResponse, info: { ...vrcInformationResponse.info, registrationDate } })
                    }
                    disabled={vehicleDisabled}
                />
            </div>
            <TextField
                floatingLabel
                label={regNoTypeLabel ? translateText(regNoTypeLabel) : undefined}
                model={vrcInformationResponse}
                path={["info", "registrationNumber"]}
                onChange={setModelAndResetError}
                disabled={vehicleDisabled}
                formatter={vrcModuleIsUsingKbaNumbers ? kba : undefined}
                modelState={modelState}
                forceShowErrorBorder={showNoResultError}
            />
            <TextField
                floatingLabel
                label={translateText(101)}
                model={vrcInformationResponse}
                path={["info", "vin"]}
                onChange={setModelAndResetError}
                disabled={vehicleDisabled}
                formatter={formatVin}
                maxLength={VIN_LENGTH}
                showLength
                modelState={modelState}
                forceShowErrorBorder={showNoResultError}
            />
            <TextField
                floatingLabel
                label={translateText(21)}
                model={vrcInformationResponse}
                path={["info", "numberPlate"]}
                onChange={setModelAndResetError}
                disabled={vehicleDisabled}
                modelState={modelState}
                forceShowErrorBorder={showNoResultError}
            />

            {appendix}
        </div>
    )
}
