import { useLocalization } from "@tm/localization"
import { CustomWork, VatRate, WorkCategory, WorkType, NoteTypes } from "@tm/models"
import {
    Button,
    CellContentPosition,
    Icon,
    Table,
    TableCellData,
    TableColumnData,
    Tooltip,
    NotesButton,
    Loader,
    Typography,
    styled,
} from "@tm/components"
import { getCategoryOfWorkDescriptionTextId, useVatRateText } from "@tm/utils"
import { WorkTaskInfo } from "@tm/context-distribution"
import WorkActions from "./WorkActions"
import { BasicBasketWorkInfo, BasketWork } from "../../../../../models"
import WorkPrice from "./WorkPrice"
import WorkTime from "./WorkTime"
import { CostEstimation } from "../../../../../data/model"
import { EditWorkMode } from "../../../business"
import WorkFooter from "./WorkFooter"

type Props = {
    calculationLoading?: boolean
    creatingCostEstimation: boolean
    costEstimation?: CostEstimation
    repairTimeDivision: number
    showManufacturer: boolean
    showRepairTimesInHours: boolean
    showSupplierArticleNumbers: boolean
    useRepairTimeCalculation?: boolean
    workEstimationLoading: boolean
    workTask: WorkTaskInfo
    works: BasketWork[]
    onEditWork(work: BasketWork, repairTimeDivision: number, editWorkMode: EditWorkMode, editBasketWorkInfo: BasicBasketWorkInfo): void
    onIncludeExcludeWork(part: BasketWork): void
    onRemoveWorks(workIds: string[]): void
    onReplaceWorkWithCustomWork(workToReplace: BasketWork, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string): void
    onResetRepairTimes(work: BasketWork): void
    onSelectWork(workId: string): void
    onShowHideIncludes(workIds: string[]): void
}

const ColumnHeader = styled(Typography)({})
ColumnHeader.defaultProps = { variant: "body2" }

export default function WorksTable(props: Props) {
    const {
        calculationLoading,
        creatingCostEstimation,
        works,
        showManufacturer,
        showSupplierArticleNumbers,
        useRepairTimeCalculation,
        showRepairTimesInHours,
        workEstimationLoading,
        workTask,
        costEstimation,
        repairTimeDivision,
    } = props

    const { translate, translateText, currency } = useLocalization()
    const { vatRateTypeDisplayShortText } = useVatRateText(translateText)

    function renderRebate(rebate?: number) {
        if (!rebate) {
            return null
        }
        return `${rebate.round(0.01)} %`
    }

    function renderDescription(work: BasketWork) {
        const { estimatedWork, workItem } = work
        let description = estimatedWork?.description || workItem.description
        if (estimatedWork?.oeReferenceNumber || workItem.oeReferenceNumber) {
            description += ` (OE ${estimatedWork?.oeReferenceNumber ?? workItem.oeReferenceNumber})`
        }
        return <ColumnHeader>{description}</ColumnHeader>
    }

    function getColumns() {
        const columns: TableColumnData[] = [{ header: "" }]
        if (showSupplierArticleNumbers) {
            columns.push({ header: <ColumnHeader>{translate(58)}</ColumnHeader> })
        }

        columns.push({ header: <ColumnHeader>{translate(25)}</ColumnHeader> }, { header: "" }, { header: "" })

        if (showManufacturer) {
            columns.push({ header: <ColumnHeader>{translate(278)}</ColumnHeader> })
        }

        const repairTimesTextId = showRepairTimesInHours ? 84 : 1550
        columns.push(
            { header: <ColumnHeader>{translate(59)}</ColumnHeader> },
            { header: <ColumnHeader>{translate(63)}</ColumnHeader>, alignContent: CellContentPosition.right },
            { header: <ColumnHeader>{translate(repairTimesTextId)}</ColumnHeader>, alignContent: CellContentPosition.right }
        )

        if (useRepairTimeCalculation) {
            columns.push({
                header: <ColumnHeader>{`${translate(repairTimesTextId)} ${translate(85)}`}</ColumnHeader>,
                alignContent: CellContentPosition.right,
            })
        }

        columns.push(
            { header: <ColumnHeader>{translate(54)}</ColumnHeader>, alignContent: CellContentPosition.right },
            { header: <ColumnHeader>{translate(706)}</ColumnHeader>, alignContent: CellContentPosition.right },
            { header: <ColumnHeader>{translate(57)}</ColumnHeader>, alignContent: CellContentPosition.right },
            { header: "", alignContent: CellContentPosition.right }
        )
        return columns
    }

    function getModuleCellData(work: BasketWork): TableCellData[] {
        const { workItem, estimatedWork, calculatedWork, states } = work
        const cellData: TableCellData[] = [
            {
                displayValue: (
                    <Button
                        disabled={!workItem.includedWorks?.some((includedWork) => includedWork.isVisible)}
                        startIcon={<Icon name={states.isExpanded ? "up" : "down"} />}
                        title={translateText(61)}
                        onClick={() => props.onShowHideIncludes([workItem.id])}
                        variant="text"
                    />
                ),
                id: "work_1",
            },
        ]

        if (showSupplierArticleNumbers) {
            cellData.push({
                displayValue: (
                    <ColumnHeader>
                        {estimatedWork?.displayNumber || workItem.displayNumber || estimatedWork?.providerWorkId || workItem.providerWorkId}
                    </ColumnHeader>
                ),
                id: "work_2",
            })
        }

        cellData.push(
            { displayValue: renderDescription(work), id: "work_3" },
            {
                displayValue: (workItem.type === WorkType.CustomWork || workItem.type === WorkType.CustomMainWork) && (
                    <Tooltip variant="light" title={workItem.type === WorkType.CustomWork ? translateText(920) : translateText(1473)}>
                        <Icon name={workItem.type === WorkType.CustomWork ? "individual-repairtimes" : "article-related-repairtimes"} />
                    </Tooltip>
                ),
                id: "work_4",
            },
            {
                displayValue:
                    estimatedWork?.note || workItem.note ? (
                        <NotesButton
                            articleNotes={[
                                { type: NoteTypes.VEHICLE_ARTICLE, message: estimatedWork?.note || workItem.note, title: translateText(12874) },
                            ]}
                            hasNote={!!workItem.note}
                            iconOnly
                        />
                    ) : undefined,
                id: "work_5",
            }
        )

        if (showManufacturer) {
            cellData.push({ displayValue: <ColumnHeader>{workItem.providerName}</ColumnHeader>, id: "work_6" })
        }

        cellData.push(
            {
                displayValue: (
                    <ColumnHeader title={translateText(getCategoryOfWorkDescriptionTextId(workItem.categoryShortCode))}>
                        {workItem.categoryShortCode}
                    </ColumnHeader>
                ),
                id: "work_7",
            },
            {
                displayValue: <WorkPrice work={work} loading={workEstimationLoading || calculationLoading} />,
                id: "work_8",
            },
            {
                displayValue: (
                    <WorkTime
                        loading={workEstimationLoading || calculationLoading}
                        timeValue={calculatedWork?.repairTime ?? estimatedWork?.displayTime?.value}
                        hasErrors={estimatedWork?.hasCalculationError}
                    />
                ),
                id: "work_9",
            }
        )
        if (useRepairTimeCalculation) {
            cellData.push({
                displayValue: (
                    <WorkTime
                        loading={workEstimationLoading || calculationLoading}
                        timeValue={calculatedWork?.repairTimeCalculated ?? estimatedWork?.displayTime?.calculatedValue}
                        hasErrors={estimatedWork?.hasCalculationError}
                    />
                ),
                id: "work_10",
            })
        }

        const vatRate = workItem.vatRates?.find((v) => v.isSelected)
        cellData.push(
            { displayValue: renderRebate(calculatedWork?.rebate || workItem.rebate), id: "work_11" },
            {
                displayValue: vatRate ? (
                    <ColumnHeader>
                        {vatRateTypeDisplayShortText({
                            vatRate: calculatedWork?.vatRate.vatRate || vatRate.vatValue,
                            vatType: calculatedWork?.vatRate.vatType || vatRate.vatType,
                        })}
                    </ColumnHeader>
                ) : (
                    "-"
                ),
                id: "work_12",
            },
            {
                displayValue: calculationLoading ? (
                    <Loader size="small" />
                ) : (
                    <ColumnHeader fontWeight="bold">
                        {currency(calculatedWork?.offerPrice?.value || 0, workItem?.currencySymbol || workItem?.currencyCode)}
                    </ColumnHeader>
                ),
                id: "work_13",
            },
            {
                displayValue: (
                    <WorkActions
                        costEstimation={costEstimation}
                        disableControls={!!creatingCostEstimation || !!workEstimationLoading || work.states.isBeingUpdated}
                        repairTimeDivision={repairTimeDivision}
                        showRepairTimesInHours={showRepairTimesInHours}
                        work={work}
                        workTask={workTask}
                        onEditWork={props.onEditWork}
                        onIncludeExcludeWork={props.onIncludeExcludeWork}
                        onRemoveWorks={props.onRemoveWorks}
                        onReplaceWorkWithCustomWork={props.onReplaceWorkWithCustomWork}
                        onResetRepairTimes={(work) => props.onResetRepairTimes(work)}
                        onSelectWork={props.onSelectWork}
                    />
                ),
                id: "work_14",
            }
        )
        return cellData
    }

    const displayData = works?.map((work, index) => ({
        cells: getModuleCellData(work),
        id: `${index}`,
        active: false,
        extendedContent: <WorkFooter work={work} showSupplierArticleNumbers={showSupplierArticleNumbers} />,
    }))

    return <Table columns={getColumns()} rows={displayData} headerBackground="transparent" rowCap={4} />
}
