import {
    CategoryType,
    channel,
    Customer,
    ErpInformation,
    ErpInformationRequestItem,
    FittingPosition,
    getWorkTaskId,
    SearchFilters,
    TreeNode,
    Vehicle,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"

import { Models } from "./data"
import { CriterionFilter, CriterionFilterGroup } from "./data/model/uni-parts"

export type TabInfo = { moduleView?: string; moduleFilter?: string }

export type BundleActionType =
    | { type: "SET_SEARCHTYPE"; payload: SearchType }
    | { type: "FILTERS_LOADING"; payload: FiltersLoadingPayload }
    | { type: "FILTERS_LOADED"; payload: FiltersLoadedPayload }
    | { type: "FILTERS_ERROR"; payload: Error }
    | { type: "FILTERS_CHANGED"; payload: FiltersChangedPayload; wasReceived?: boolean }
    | { type: "VEHICLE_SET"; payload?: Vehicle }
    | { type: "CUSTOMER_SET"; payload?: Customer }
    | {
          type: "SET_SECTION_URL"
          payload: { categoryType: CategoryType; sectionType: SectionType; url: string | undefined }
      }
    | { type: "DETAILSHEAD_LOADING"; payload: { request: Models.DetailsRequest; foundBySearchTerm?: string } }
    | { type: "DETAILSHEAD_LOADED"; payload: Models.ArticleDetailsResponse }
    | { type: "DETAILSHEAD_RESET" }
    | { type: "SET_ARTICLE_LIST_COMPACT_VIEW"; payload: boolean }
    | { type: "SET_ARTICLE_LIST_SHOW_ARTICLE_IMAGES"; payload: boolean }
    | { type: "MODULE_OPENED"; payload: { icon: string; title: string } }
    | { type: "SHOW_SEARCH_HINT"; payload: string }
    | { type: "SET_TAB_INFO"; payload: TabInfo }
    | {
          type: "SET_SEARCHTREE_DATA"
          payload: {
              treeType: CategoryType
              groups: Array<TreeNode>
              breadcrumbs: Array<TreeNode>
              selectedNode: TreeNode | undefined
          }
      }
    | { type: "TOGGLE_FILTERS_OPEN"; payload: boolean }
    | {
          type: "ERP_INFO_DETAILS_LOADED"
          payload: { request: ErpInformationRequestItem; response: ErpInformation | undefined }
      }
    | { type: "QUERY_HISTORY_LOADED"; payload: Array<string> }

/**
 * @deprecated doesn't always work perfectly, consider just using the vehicle and customer from useWorkTask or withWorkTask
 */
export function init(
    afterVehicleChange?: (vehicle: Vehicle) => void,
    beforeVehicleChange?: (vehicle: Vehicle) => void
): AsyncAction<BundleActionType> {
    return (dispatch) => {
        // subscribe to worktask channel and get the loaded customer and vehicle
        const unsubs: Function[] = []
        let hasUnsubscribed = false

        getWorkTaskId((workTaskId) => {
            if (hasUnsubscribed) {
                return
            }

            unsubs.push(
                channel("WORKTASK", workTaskId).subscribe(
                    "CUSTOMER/LOADED",
                    (customer) => dispatch({ type: "CUSTOMER_SET", payload: customer }),
                    true
                )
            )
            unsubs.push(
                channel("WORKTASK", workTaskId).subscribe(
                    "VEHICLE/LOADED",
                    (vehicle) => {
                        beforeVehicleChange?.(vehicle)
                        dispatch({ type: "VEHICLE_SET", payload: vehicle })
                        afterVehicleChange?.(vehicle)
                    },
                    true
                )
            )
        })

        return function unsubscribeChannels() {
            unsubs.forEach((unsub) => unsub())
            unsubs.splice(0)
            hasUnsubscribed = true
        }
    }
}

function setSearchTreeData(
    treeType: CategoryType,
    groups: Array<TreeNode>,
    breadcrumbs: Array<TreeNode>,
    selectedNode: TreeNode | undefined
): BundleActionType {
    return { type: "SET_SEARCHTREE_DATA", payload: { treeType, groups, breadcrumbs, selectedNode } }
}

export type IBundleActions = Omit<typeof BundleActions, "init"> & {
    init(...args: Parameters<typeof init>): () => void
}

export const BundleActions = {
    init,
    setSearchTreeData,
}

export enum SearchType {
    HIDE = -1, // SPECIAL SEARCHTYPE: means the article list should be hidden completly
    NONE = 0,
    DIRECT = 1,
    NODE = 2,
    SYNONYM = 3,
    SALE = 4,
    PRODUCTGROUPS = 5,
    OE = 6,
    OERESEARCH = 7,
    TRADERARTICLENOS = 8,
    PARTSLIST = 9,
    ACCESSORYLIST = 10,
    PASSED_IN_ARTICLES = 11,
    UNINODE = 12,
    UNISEARCH = 13,
    ARTICLE_IDENTIFIER = 14,
    UNIPRODUCTGROUPS = 15,
    ALTERNATIVES = 16,
    OE_POSITIONS = 17,
    OFFERS = 18,
}

export type FiltersLoadingPayload = {
    productGroups?: boolean
    suppliers?: boolean
    constructionYear?: boolean
    articleAttributes?: boolean
    uniCritera?: boolean
    productGroupOptions?: boolean
    isLoading?: boolean
}

export type FiltersLoadedPayload = {
    productGroups?: Array<Models.ProductGroupFilter>
    suppliers?: Array<Models.DataSupplierFilter>
    constructionYear?: Array<Models.ConstructionYearFilter>
    articleAttributes?: Array<Models.ArticleAttributeFilter>
    showExtendedAssortment?: boolean
    uniCriteria?: Array<CriterionFilterGroup>
    resetFilters?: boolean
    isLoading?: boolean
    requestedFilters?: string
}

export type FiltersChangedPayload = {
    query?: {
        text: string
        searchFilter?: SearchFilters
    }
    productGroupIds?: Array<number>
    productGroupOptions?: Array<any>
    supplierIds?: Array<number>
    constructionYear?: number
    oeReferenceNos?: Array<string>
    articleAttributes?: Array<string>
    fittingPosition?: FittingPosition
    extendedAssortment?: boolean
    uniCriteria?: Array<CriterionFilter>
    availability?: AvailabilityFilterType
    isLoading?: boolean
}

export enum AvailabilityFilterType {
    None = 0,
    Primary = 1,
    Secondary = 2,
}

export enum FilterType {
    None = 0,
    ProductGroup = 1 << 0,
    Supplier = 1 << 1,
    ArticleAttribute = 1 << 2,
    FittingPosition = 1 << 4,
    ExtendedAssortment = 1 << 5,
    UniCriterion = 1 << 6,
    TreeNode = 1 << 8,
    Availability = 1 << 9,
    ConstructionYear = 1 << 10,
}

export type SectionType = "list" | "details"

export type BaseDetailsRouteProps = {
    workTaskId?: string
    supplierArticleNo: string
    productGroupId: string
    supplierId: string
    partsDetailsSubPage?: string
    scrollTo?: string
    subModule?: string
}
