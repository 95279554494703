import { useCallback, MouseEvent as ReactMouseEvent } from "react"
import { useLocalization } from "@tm/localization"
import { Box, styled } from "@tm/components"
import { TyreFilter } from "@tm/models"
import { useSelector } from "react-redux"
import { AvailabilityFilter } from "@bundles/wheels/components/rdks-list/components/SensorsFiltersArea"
import { CustomRangeSlider } from "../../_shared/customRangeSlider"
import { IListFilters } from "../business/model"
import { MainState } from "../../main"

type Props = {
    onChange: (filter: TyreFilter | AvailabilityFilter, path: IListFilters) => void
    onResetFilters?: (path: IListFilters, event?: ReactMouseEvent) => void
}

export function EuTyreLabel({ onChange, onResetFilters }: Props) {
    const { translateText } = useLocalization()
    const { filters, selectedFilters } = useSelector((s: MainState) => ({
        filters: s.list.filters,
        selectedFilters: s.list.selectedFilters,
    }))

    const handleFilterChange = useCallback(
        (filter: TyreFilter | AvailabilityFilter, path: string) => {
            onChange(filter, path as IListFilters)
        },
        [onChange]
    )

    return (
        <StyledBox>
            <CustomRangeSlider
                title={translateText(722)}
                items={filters.fuelEfficiency}
                icon="gas-station"
                onChange={(filter) => handleFilterChange(filter, "fuelEfficiency")}
                onReset={() => onResetFilters?.("fuelEfficiency")}
                selectedItem={selectedFilters.fuelEfficiency}
                isV2
            />
            <CustomRangeSlider
                title={translateText(13279)}
                items={filters.wetGripClass}
                icon="rain"
                onChange={(filter) => handleFilterChange(filter, "wetGripClass")}
                onReset={() => onResetFilters?.("wetGripClass")}
                selectedItem={selectedFilters.wetGripClass}
                isV2
            />
            <CustomRangeSlider
                title={translateText(13280)}
                items={filters.externalRolling}
                icon="sound"
                onChange={(filter) => handleFilterChange(filter, "externalRolling")}
                onReset={() => onResetFilters?.("externalRolling")}
                selectedItem={selectedFilters.externalRolling}
                minimized
                isV2
            />
        </StyledBox>
    )
}

const StyledBox = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    width: "95%",
    padding: "0",
})
