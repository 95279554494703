import { withUserSettings, WithUserSettingsProps } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { IMicros, RepairTimeProvider } from "@tm/models"
import Morpheus from "@tm/morpheus"
import {
    bindSpecialReactMethods,
    encodeUniqueId,
    getRepairTimeProviders,
    getRepairTimeProvidersByNames,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    renderRoute,
    RouteComponentProps,
    uniqueId,
    withRouter,
} from "@tm/utils"
import { Component } from "react"
import { AddKVAToRDBasketState } from "../add-rt-to-rd-basket/business/model"

type Props = WithUserSettingsProps &
    RouteComponentProps &
    LocalizationProps &
    IMicros["standalone"]["open-rt-modal"] & {
        state: AddKVAToRDBasketState
    }
class AddItemsToKvaComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleStandaloneKVAClick() {
        const { items } = this.props

        const { repairTimeProviderIds } = getRepairTimeProviders()
        const rtProviders = getRepairTimeProvidersByNames(repairTimeProviderIds)

        const url = this.getRepairTimesUrl(items[0], rtProviders)
        if (url) {
            Morpheus.showView("1", url)
        }
    }

    getRepairTimesUrl(article: any, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) {
        const { match, userSettings, repairTimesRoute, repairTimeProviders, sourceId } = this.props

        if (article.productGroup && repairTimeProviders.length && userSettings) {
            let provider
            if (Array.isArray(rtProviders)) {
                const activeRTProvider = userSettings.activeVehicleDataProviders.repairTimes

                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider) {
                return
            }

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    ...match.params,
                    workTaskId: encodeUniqueId(uniqueId()),
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide,
                    sourceId,
                })
            )
        }
    }

    render() {
        if (!this.props.repairTimeProviders?.length) {
            return null
        }

        return (
            <div className="add-to-cost-estimate">
                <Button
                    className="cost-estimation-btn"
                    icon="labor-times"
                    layout={["bordered"]}
                    onClick={this.handleStandaloneKVAClick}
                    disabled={false}
                />
            </div>
        )
    }
}

export default withUserSettings(withRouter(withLocalization(AddItemsToKvaComponent)))
