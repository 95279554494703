import { useWorkTask } from "@tm/context-distribution"
import { channel, ModuleGroupId, ModuleId, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { renderRoute, useMessage } from "@tm/utils"
import { FC, useEffect, useState } from "react"
import { DemoBanner } from "../demo-module-alert/components/DemoBanner"

type Props = {
    moduleGroupId: ModuleGroupId
    activationRoute?: string
}

type ModuleData = {
    moduleMode?: string | undefined
    moduleId?: ModuleId | string | undefined
    moduleName?: string | undefined
}

export function DemoAlertTruck({ activationRoute, moduleGroupId }: Props) {
    const { workTaskId } = useWorkTask() || {}

    const [preventRender, setPreventRender] = useState<boolean>(false)
    const [moduleData, setModuleData] = useState<ModuleData | undefined>()

    useMessage((data) => {
        if (!data?.setModuleMode?.moduleMode) {
            return
        }

        const setModuleMode = data?.setModuleMode as ModuleData

        if (setModuleMode.moduleMode === "ACTIVATION") {
            if (activationRoute) {
                const comparePageUrl = renderRoute(activationRoute, {})

                Morpheus.showView("1", comparePageUrl)
            }
            return
        }

        setPreventRender(false)

        if (!!setModuleMode.moduleId && !!setModuleMode.moduleName) {
            setModuleData({ ...setModuleMode })
        } else {
            setModuleData({ moduleMode: setModuleMode?.moduleMode })
        }
    })

    useEffect(() => {
        if (moduleData?.moduleId) {
            channel("GLOBAL").publish("MVC/RESIZE", { moduleMode: moduleData.moduleMode })
        }
    }, [moduleData?.moduleMode, moduleData?.moduleId])

    if (preventRender || !workTaskId) {
        return null
    }

    if (
        !moduleGroupId ||
        !moduleData?.moduleName ||
        !moduleData?.moduleId ||
        !moduleData?.moduleMode ||
        !["TD", "SD"].includes(moduleData?.moduleMode)
    ) {
        return null
    }

    return (
        <DemoBanner
            moduleGroupId={moduleGroupId}
            moduleId={moduleData.moduleId as ModuleId}
            vehicleType={VehicleType.CommercialVehicle}
            moduleName={moduleData.moduleName}
            activationRoute={activationRoute}
        />
    )
}
