import { useActions } from "@tm/morpheus"
import { batch, useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { FC, memo, useEffect } from "react"
import { Box, styled } from "@tm/components"
import { WheelSelectionSteps } from "../../data/enums"
import { useImagePaths } from "../../data/hooks/useImagePaths"
import { loaded, Wrapper as Wrapper2D } from "../2d-configurator/component"
import PreyssConfigurator from "../3d-configurator/component"
import { MainState } from "../main"
import { Actions } from "./business"
import { createImagePathsRequest, generateConfiguratorImage } from "./business/helper"
import { ConfiguratorDialog, TopActions, RimSettings, RimInfo, LeftActions, RimFilters, RimArticles } from "./components"
import ApplyButtonComponent from "./components/applyButton"
import { getBundleParams } from "../../utils"

const selector = createSelector(
    (s: MainState) => ({
        selectedRimItem: s.wheelsList.base.selectedRimItem,
        selectedFilters: s.wheelsList.base.selectedFilters,
        display: s.wheelsList.configurator.display,
        has3DConfig: s.wheelsList.configurator.has3DConfig,
        has2DConfig: s.wheelsList.configurator.has2DConfig,
        show3D: s.wheelsList.configurator.show3D,
        renderHidden: s.wheelsList.configurator.renderHidden,
        rimPositions: s.wheelsList.configurator._2D.rimPositions,
        _3D: s.wheelsList.configurator._3D,
        selectedSize: s.wheelsList.base.selectedSize,
        articlesLoading: s.wheelsList.base.articles.loading,
        loading2D: s.wheelsList.configurator._2D.loading,
        selectedRimLoading: s.wheelsList.base.selectedRimLoading,
        showRimSettings: s.wheelsList.base.showRimSettings,
        carPark: s.wheelsList.base.carPark,
        selectedCarParkId: s.wheelsList.base.selectedCarParkId,
        initFromHistory: s.wheelsList.base.initFromHistory,
        initialized: s.wheelsList.base.initialized,
        selectedColor: s.wheelsList.configurator.selectedColor,
        imageId2D: s.wheelsList.base.carPark.imageId2D,
        articles: s.wheelsList.base.articles.data,
    }),
    (x) => x
)

const StyledWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
})

const StyledFiltersBox = styled(Box)({
    display: "flex",
    ".diameter-filters": {
        ".toolbar__inner": {
            flex: 1,
            ".dropdown": {
                flex: 1,
            },
        },
    },
    ".wheels-list_filters": {
        flex: 1,
        width: "100%",
    },
})

export function WheelsList() {
    let waitTntervalPicture: number | NodeJS.Timeout | undefined
    const {
        selectedFilters,
        selectedRimItem,
        display,
        has2DConfig,
        has3DConfig,
        show3D,
        rimPositions,
        renderHidden,
        _3D,
        selectedSize,
        articlesLoading,
        loading2D,
        selectedRimLoading,
        showRimSettings,
        carPark,
        initialized,
        initFromHistory,
        selectedColor,
        imageId2D,
        articles,
    } = useSelector(selector)
    const actions = useActions(
        Actions,
        "loadRimFilterAndArticles",
        "saveWheelLisTab",
        "sendRimArticleToDetails",
        "changeStep",
        "renderHidden",
        "changeApplyBtnLoading",
        "switchConfiguratorVersion",
        "load2DRimData",
        "load2DConfiguratorData",
        "load3DConfiguratorData",
        "loadRimFilters",
        "loadDriveRightTyres",
        "loadVehicleInformations",
        "saveLatestCarPark",
        "getErpInfos"
    )
    const showAvailability = getBundleParams()?.priceAvailabilityInformation ? !useUser()?.userSettings?.hideWheelsAvailability : false
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const isHostettler = getBundleParams()?.isHostettler
    const isLKQ = getBundleParams()?.version === "LKQ"

    useEffect(() => {
        if (carPark && !!carPark.carparkId && !initialized) {
            batch(() => {
                actions.saveLatestCarPark(carPark.carparkId)
                actions.load3DConfiguratorData()
                actions.load2DConfiguratorData()
                actions.loadRimFilterAndArticles()
                actions.loadDriveRightTyres()
                actions.loadVehicleInformations()
            })
        } else if (selectedRimItem && initFromHistory) {
            batch(() => {
                actions.load3DConfiguratorData()
                actions.load2DConfiguratorData()
                if (!isHostettler) {
                    actions.loadRimFilters()
                }
                actions.loadDriveRightTyres()
                actions.loadVehicleInformations()
            })
        }
    }, [carPark])

    useEffect(() => {
        if (showAvailability) {
            actions.getErpInfos(telesalesCustomerNo)
        }
    }, [showAvailability, articles.length])

    useEffect(() => {
        if (!selectedRimItem || !selectedSize) {
            return
        }

        const preysRim = _3D.available3DRims.find(
            (x) => x.rimId == selectedRimItem.sizeInfo.find((y) => y.size == selectedSize)?.rimFrameId && x.inch == selectedSize
        )
        if (has3DConfig && preysRim) {
            if (!show3D) {
                actions.switchConfiguratorVersion()
            }
        } else {
            if (show3D) {
                actions.switchConfiguratorVersion()
            }
            actions.load2DRimData()
        }
    }, [selectedRimItem, selectedSize, has3DConfig, rimPositions])

    const handleApplyButtonClick = () => {
        if (!selectedRimItem) {
            return
        }

        if (has3DConfig || has2DConfig) {
            if (display) {
                generateImage()
            } else {
                actions.renderHidden(true)
                waitTntervalPicture = setInterval(() => {
                    if ((has2DConfig && !show3D && loaded) || (has3DConfig && show3D)) {
                        clearInterval(waitTntervalPicture as number)
                        generateImage()
                    }
                }, 100)
            }
        } else {
            batch(() => {
                actions.changeStep({ step: WheelSelectionSteps.WHEELDETAILS, disableNextSteps: true }, true)
                actions.sendRimArticleToDetails(selectedRimItem, selectedFilters, selectedSize!, undefined)
            })
        }
        actions.saveWheelLisTab(selectedRimItem, selectedSize!)
    }

    const generateImage = () => {
        if (selectedRimItem) {
            actions.changeApplyBtnLoading(true)
            generateConfiguratorImage(has2DConfig, has3DConfig, show3D, rimPositions)
                .then((blob) => {
                    batch(() => {
                        actions.changeApplyBtnLoading(false)
                        actions.changeStep({ step: WheelSelectionSteps.WHEELDETAILS, disableNextSteps: true }, true)
                        actions.sendRimArticleToDetails(selectedRimItem, selectedFilters, selectedSize!, blob)
                        actions.renderHidden(false)
                    })
                })
                .catch(() => {
                    batch(() => {
                        actions.changeApplyBtnLoading(false)
                        actions.changeStep({ step: WheelSelectionSteps.WHEELDETAILS, disableNextSteps: true }, true)
                        actions.sendRimArticleToDetails(selectedRimItem, selectedFilters, selectedSize!, undefined)
                        actions.renderHidden(false)
                    })
                })
        }
    }

    return (
        <Box className="wheels-list">
            <ConfiguratorDialog
                selectedRimItem={selectedRimItem}
                has2DConfig={has2DConfig}
                has3DConfig={has3DConfig}
                show3D={show3D}
                renderHidden={renderHidden}
                selectedRimLoading={selectedRimLoading}
                selectedSize={selectedSize}
            />
            <ApplyButtonComponent
                onClick={handleApplyButtonClick}
                disableButton={!selectedRimItem || loading2D || articlesLoading || selectedRimLoading}
                withLoading
            />
            <TopActions />
            {isLKQ ? (
                <Box sx={{ display: "flex", height: "100%" }}>
                    <StyledWrapper>
                        <Box className="header_wheels_list">
                            {display && showRimSettings && <RimSettings />}
                            {imageId2D && <Image2DPaths imageId2D={imageId2D} selectedColor={selectedColor!} />}
                            <RimInfo />

                            <Wrapper2D />
                            {(display || renderHidden) && has3DConfig && show3D && <PreyssConfigurator />}

                            {display && ((has3DConfig && show3D) || (has2DConfig && !show3D)) && <LeftActions />}
                        </Box>
                        <Box sx={{ display: "flex", flex: "1" }}>
                            <RimArticles />
                        </Box>
                    </StyledWrapper>
                    <StyledFiltersBox>
                        <RimFilters />
                    </StyledFiltersBox>
                </Box>
            ) : (
                <>
                    <Box className="header_wheels_list">
                        {display && showRimSettings && <RimSettings />}
                        {imageId2D && <Image2DPaths imageId2D={imageId2D} selectedColor={selectedColor!} />}
                        <RimInfo />

                        <Wrapper2D />
                        {(display || renderHidden) && has3DConfig && show3D && <PreyssConfigurator />}

                        {display && ((has3DConfig && show3D) || (has2DConfig && !show3D)) && <LeftActions />}
                    </Box>

                    <Box className="wheels-list_content">
                        <RimFilters />
                        <RimArticles />
                    </Box>
                </>
            )}
        </Box>
    )
}

const Image2DPaths: FC<{ selectedColor: string; imageId2D: string }> = memo(({ selectedColor, imageId2D }) => {
    const dispatch = useDispatch()
    const { imgPaths, isLoading } = useImagePaths(createImagePathsRequest(selectedColor || "000000", imageId2D))

    useEffect(() => {
        if (!isLoading && imgPaths) {
            dispatch(Actions.update2DConfigurator(imgPaths?.images, imgPaths?.rimsPosition))
        }
    }, [imgPaths])

    useEffect(() => {
        isLoading && dispatch(Actions.setConfigLoader())
    }, [isLoading])

    return null
})
