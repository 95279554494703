import { Badge, Icon, Image } from "@tm/controls"

type Props = {
    hideBadge?: boolean
    quantities: number
    imageUrl: string | undefined
    iconName?: string
}

function ImageWithBadge({ hideBadge, quantities, imageUrl, iconName }: Props) {
    let imageElement
    if (imageUrl) {
        imageElement = <Image url={imageUrl} className="availability__standalone-image" />
    } else if (iconName) {
        imageElement = <Icon name={iconName} />
    }

    return (
        <div style={{ position: "relative" }}>
            {!hideBadge && <Badge value={quantities || "0"} skin={quantities ? "dark" : "danger"} />}
            {imageElement}
        </div>
    )
}

export default ImageWithBadge
