import { getBundleParams } from "@bundles/wheels/utils"
import { RimDetailsV1 } from "./components/rimDetailsV1"
import { RimDetailsV2 } from "./components/rimDetailsV2"

type WheelsDetailsComponentProps = {
    repairTimesRoute: string
}
export function WheelsDetails({ repairTimesRoute }: WheelsDetailsComponentProps) {
    const isLKQ = getBundleParams()?.version === "LKQ"

    if (isLKQ) {
        return <RimDetailsV2 repairTimesRoute={repairTimesRoute} />
    }

    return <RimDetailsV1 repairTimesRoute={repairTimesRoute} />
}
