import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { useLocalization } from "@tm/localization"
import { equals } from "@tm/utils"
import { useArticleListSorting } from "../../../hooks/useArticleListSorting"
import { ListParams, OffersStartParams } from "../../../models"
import { advertisementCategoryDivider } from "../../../../../utils"
import { emptyFunc } from "../../../helpers"

function getStartParamsFromUrl(url: string, translateText: (key: number | string) => string): OffersStartParams {
    const { displaySelectedOffersCatagory } = Morpheus.getParams<ConfigParams>()
    
    const searchParams = new URLSearchParams(url)
    const tabTitle = searchParams.get("tabTitle") ?? undefined
    const adCategory = searchParams.get("advertisementCategory")?.split(advertisementCategoryDivider)

    const advertisementCategory =
        adCategory?.length === 2
            ? {
                  id: adCategory[0],
                  description: adCategory[1],
              }
            : undefined

    const title = displaySelectedOffersCatagory && advertisementCategory ?
        `Selection - ${advertisementCategory.description}` : translateText(1276)

    return {
        type: "default",
        advertisementCategory,
        tabTitle,
        title,
    }
}

/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useListParams(): ListParams<OffersStartParams> {
    const location = useLocation()
    const { translateText } = useLocalization()

    const [startParams, setStartParams] = useState(() => getStartParamsFromUrl(location.search, translateText))

    const [pageIndex, setPageIndex] = useState(0)
    const [noResult, setNoResult] = useState(false)
    const [isFiltersLoading, setIsFiltersLoading] = useState(false)

    useEffect(
        function resetOnStartParamsChanged() {
            const newStartParams = getStartParamsFromUrl(location.search, translateText)

            if (!equals(newStartParams, startParams)) {
                setStartParams(newStartParams)
            }
        },
        [location, startParams]
    )

    const nextPage = useCallback(() => {
        setPageIndex((index) => index + 1)
    }, [])

    const sorting = useArticleListSorting()

    return {
        listType: "offers",
        startParams,
        pageIndex,
        productGroups: [],
        suppliers: [],
        attributes: [],
        extendedAssortment: false,
        showAvailable: false,
        showAvailableSecondary: false,
        noResult,
        setProductGroups: emptyFunc,
        setSuppliers: emptyFunc,
        setAttributes: emptyFunc,
        toggleProductGroup: emptyFunc,
        toggleSupplier: emptyFunc,
        toggleAttribute: emptyFunc,
        setExtendedAssortment: emptyFunc,
        setAvailability: emptyFunc,
        setAvailabilitySecondary: emptyFunc,
        setNoResult,
        nextPage,
        sorting,
        isFiltersLoading,
        setIsFiltersLoading,
    }
}
