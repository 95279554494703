import { IBundle, IBundleWithTemplates } from "@tm/morpheus"

import AdComponent from "./components/ad-tile"
import CisAccount from "./components/cis-account"
import CisAccountNavigation from "./components/cis-account-navigation"
import InfoPanel from "./components/info-panel"
import ProductArea from "./components/product-area"
import StartFooter from "./components/start-footer"
import TyresWidget from "./components/tyres-widget"
import UserSettingsMenu from "./components/usersettings-menu"
import PaperCatLinkComponent from "./components/papercatalogs-link"
import LinkWidgetComponent from "./components/link-widget"
import EventCountdown from "./components/event-countdown"
import TrainingsLinkComponent from "./components/trainings-link"

import { ArticleDetailsHeadTemplate } from "./templates/article-details-head"
import { ArticleGroupTemplate } from "./templates/article-group"
import { ArticleItemTemplate } from "./templates/article-list-item"
import { OeItemTemplate } from "./templates/oe-item"
import { WholesalerItemTemplate } from "./templates/wholesaler-item"
import { initTraderWmBundle, version } from "./utils"

const bundle: IBundle & IBundleWithTemplates = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        AdComponent,
        CisAccount,
        CisAccountNavigation,
        InfoPanel,
        LinkWidgetComponent,
        ProductArea,
        StartFooter,
        TyresWidget,
        UserSettingsMenu,
        PaperCatLinkComponent,
        EventCountdown,
        TrainingsLinkComponent,
    ],
    templates: [ArticleDetailsHeadTemplate, ArticleGroupTemplate, ArticleItemTemplate, OeItemTemplate, WholesalerItemTemplate],
    init: initTraderWmBundle,
}

export default bundle
