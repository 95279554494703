import { Box, Button, Loader, Modal, TextField, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { useUser } from "@tm/context-distribution"
import { decodeJwtToken, getStoredAuthorization, validateField } from "@tm/utils"
import { AuthTokenPayload } from "@tm/models"
import { changeUserEmail, setUser } from "../../../data/repositories/user"

type Props = {
    username: string
    initialName: string
    initialEmail: string
    openModal: boolean
    setOpenModal(open: boolean): void
}

export function EditProfileModal(props: Props) {
    const { username, initialName, initialEmail, openModal, setOpenModal } = props
    const { translateText } = useLocalization()
    const { userContext, updateUserContextGeneralContactName } = useUser()
    const { initialFirstname, initialLastname } = getInitialName()

    const [firstname, setFirstname] = useState(initialFirstname)
    const [lastname, setLastname] = useState(initialLastname)
    const [email, setEmail] = useState(initialEmail)
    const [saveProfileDisabled, setSaveProfileDisabled] = useState(true)
    const [resetProfileDisabled, setResetProfileDisabled] = useState(true)
    const [saveEmailDisabled, setSaveEmailDisabled] = useState(true)
    const [resetEmailDisabled, setResetEmailDisabled] = useState(true)
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined)
    const [emailSavingActive, setEmailSavingActive] = useState(false)
    const [profileSavingActive, setProfileSavingActive] = useState(false)
    const [emailTextFieldFocused, setEmailTextFieldFocused] = useState(false)
    let profileSaved = false
    let emailSaved = false

    function Label(label: string) {
        return <Box fontSize={12}>{label} *</Box>
    }

    function getInitialName() {
        const splittedName = initialName.split(";")

        if (splittedName.length !== 2) {
            return { firstname: "", lastname: "" }
        }

        return { initialFirstname: splittedName[0], initialLastname: splittedName[1] }
    }

    async function saveProfile() {
        if (!firstname || firstname.length === 0 || !lastname || lastname.length === 0 || !userContext.account?.lfdNr) {
            return
        }

        setProfileSavingActive(true)
        setSaveProfileDisabled(true)
        setResetProfileDisabled(true)

        await setUser(firstname, lastname, userContext.account?.lfdNr)
        await updateUserContextGeneralContactName(`${firstname};${lastname}`)

        setProfileSavingActive(false)
        profileSaved = true
    }

    function resetProfile() {
        setFirstname(initialFirstname)
        setLastname(initialLastname)
        setSaveProfileDisabled(true)
        setResetProfileDisabled(true)
    }

    async function saveEmail() {
        setSaveEmailDisabled(true)
        setResetEmailDisabled(true)

        const auth = getStoredAuthorization()
        const { catalogname } = decodeJwtToken<AuthTokenPayload>(auth?.credentials ?? "")

        setEmailSavingActive(true)
        const response = await changeUserEmail(email, window.location.hostname, catalogname, username)
        setEmailSavingActive(false)

        if (!response?.success) {
            setEmailErrorMessage(response?.message ?? "")
            setResetEmailDisabled(false)
            setEmailTextFieldFocused(true)
        }

        emailSaved = true
    }

    function resetEmail() {
        setEmail(initialEmail)
        setEmailErrorMessage(undefined)
        setSaveEmailDisabled(true)
        setResetEmailDisabled(true)
    }

    function onChangeFirstname(value: string) {
        setFirstname(value)
        setSaveProfileDisabled(value === initialFirstname && lastname === initialLastname)
        setResetProfileDisabled(value === initialFirstname && lastname === initialLastname)
    }

    function onChangeLastname(value: string) {
        setLastname(value)
        setSaveProfileDisabled(value === initialFirstname && lastname === initialLastname)
        setResetProfileDisabled(value === initialFirstname && lastname === initialLastname)
    }

    function onChangeEmail(value: string) {
        const errorMessages = validateField(value).min(1, translateText(289)).messages
        if (errorMessages?.length > 0) {
            setEmailErrorMessage(errorMessages[0])
        } else {
            setEmailErrorMessage(undefined)
        }

        setEmail(value)
        setSaveEmailDisabled(value === initialEmail || value?.length === 0)
        setResetEmailDisabled(value === initialEmail)
    }

    function onClose() {
        if (!profileSaved) {
            setFirstname(initialFirstname)
            setLastname(initialLastname)
            setSaveProfileDisabled(true)
            setResetProfileDisabled(true)
        }

        if (!emailSaved) {
            setEmail(initialEmail)
            setSaveEmailDisabled(true)
            setResetEmailDisabled(true)
            setEmailErrorMessage(undefined)
        }
        setOpenModal(false)
    }

    return (
        <Modal open={openModal} onOutsideClick={onClose}>
            <Box m={1} pt={3} px={3}>
                <Box mb={2}>
                    <Typography variant="h2">{translateText(13887)}</Typography>
                </Box>
                <Box mb={2}>
                    <Button
                        onClick={async () => {
                            await saveProfile()
                        }}
                        disabled={saveProfileDisabled}
                        sx={{ marginRight: 0.5 }}
                        color="success"
                    >
                        {translateText(9)}
                    </Button>
                    <Button onClick={resetProfile} disabled={resetProfileDisabled}>
                        {translateText(48)}
                    </Button>
                    {profileSavingActive && <Loader sx={{ span: { marginTop: 1 }, display: "inline", marginLeft: 1 }} size="small" />}
                </Box>

                <Box mb={1}>
                    <Typography variant="h3">{translateText(1126)}</Typography>
                </Box>

                <Box>
                    <TextField
                        fullWidth
                        contentEditable={false}
                        InputProps={{ readOnly: true }}
                        sx={{ fontSize: 14 }}
                        label={Label(translateText(186))}
                        size="extralarge"
                        value={username}
                    />
                </Box>

                <Box mb={2}>
                    <Typography variant="label">{translateText(13888)}</Typography>
                </Box>

                <TextField
                    fullWidth
                    label={Label(translateText(119))}
                    size="extralarge"
                    InputProps={{ style: { color: "black" } }}
                    sx={{ marginBottom: 2, fontSize: 14 }}
                    onChange={(e) => onChangeFirstname(e.target.value)}
                    value={firstname}
                    error={firstname?.length === 0}
                    helperText={validateField(firstname).min(1, translateText(289)).messages}
                />
                <TextField
                    fullWidth
                    size="extralarge"
                    label={Label(translateText(104))}
                    InputProps={{ style: { color: "black" } }}
                    sx={{ marginBottom: 4, fontSize: 14 }}
                    onChange={(e) => onChangeLastname(e.target.value)}
                    value={lastname}
                    error={lastname?.length === 0}
                    helperText={validateField(firstname).min(1, translateText(289)).messages}
                />

                <Box mb={2}>
                    <Typography variant="h3">{translateText(13889)}</Typography>
                </Box>

                <Box>
                    <Typography display="inline">{translateText(13890)} </Typography>
                    <Typography display="inline" sx={{ textDecoration: "underline" }}>
                        {translateText(13891)}
                    </Typography>
                    <Typography display="inline"> {translateText(13892)}</Typography>
                </Box>

                <Box mb={2} mt={3}>
                    <Button
                        onClick={async () => {
                            await saveEmail()
                        }}
                        disabled={saveEmailDisabled}
                        sx={{ marginRight: 0.5 }}
                        color="success"
                    >
                        {translateText(9)}
                    </Button>
                    <Button onClick={resetEmail} disabled={resetEmailDisabled}>
                        {translateText(48)}
                    </Button>
                    {emailSavingActive && <Loader sx={{ span: { marginTop: 1 }, display: "inline", marginLeft: 1 }} size="small" />}
                </Box>

                <Box>
                    <TextField
                        fullWidth
                        size="extralarge"
                        sx={{ fontSize: 14 }}
                        InputProps={{ style: { color: "black" } }}
                        label={Label(translateText(109))}
                        onChange={(e) => onChangeEmail(e.target.value)}
                        value={email}
                        error={emailErrorMessage !== undefined}
                        helperText={emailErrorMessage}
                        focused={emailTextFieldFocused}
                        onBlur={() => setEmailTextFieldFocused(false)}
                    />
                </Box>
            </Box>
        </Modal>
    )
}
