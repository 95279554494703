import { channel } from "@tm/models"
import { encodeUniqueId, isValidWorkTaskId, renderRoute, RouteComponentProps, TmaHelper, uniqueId } from "@tm/utils"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { MatchParams } from "@bundles/tyres/data/models"
import { bundleChannel } from "../../data/channels"

type Props = RouteComponentProps<any> & {
    tyresListUrl: string
}

function ManagerComponent({ tyresListUrl }: Props) {
    const matchParams = useParams<MatchParams>()
    const history = useHistory()

    useEffect(() => {
        const unsubscribe = channel("GLOBAL").subscribe("TYRES/SEARCH_TYRE_SIZE", ({ size, carType, season, origin }) => {
            if (!season || !carType) {
                season = undefined
                carType = undefined
            }

            const newSearch = [size, carType, season].filter(Boolean).join("&")

            bundleChannel().publish("LOAD_LIST", { byMatchCode: !season && !carType, carType, season, matchCode: size })
            const listURL = renderRoute(tyresListUrl, {
                ...matchParams,
                workTaskId: isValidWorkTaskId(matchParams.workTaskId) ? matchParams.workTaskId : encodeUniqueId(uniqueId()),
                search: newSearch,
            })
            history.push(listURL)

            TmaHelper.UniParts.Search.TyresSelection(size, origin)
        })

        return unsubscribe
    }, [matchParams, history])

    return null
}

export default ManagerComponent
