import { VehicleType } from "@tm/models"
import { TiresFiltersRequest } from "../../../../data/repositories/wheels-loadTiresFilters/model"
import { TyresListState } from "../model"
import { mapVehicleTypeToTyresCarType } from "../../../../data/helpers"

export function createAttributeFiltersRequest(state: TyresListState, vehicleType: VehicleType | undefined): TiresFiltersRequest | undefined {
    const { selectedFilters } = state

    if (!selectedFilters.tyreSize) {
        return
    }

    const query = selectedFilters.tyreSize.value.replace(/ /g, "")

    const width = extractValue("width", query)
    const height = extractValue("height", query)
    const inch = extractValue("inch", query)

    const carType = mapVehicleTypeToTyresCarType(vehicleType)

    return {
        query,
        searchLevel: 2,
        carTypes: [carType],
        width: width ?? "",
        height: height ?? "",
        inch: inch ?? "",
    }
}

function extractValue(property: string, value: string) {
    const tireSizes = value.trim().split("R")
    const widthAndHeight = tireSizes[0].split("/")

    switch (property) {
        case "width":
            return widthAndHeight[0]
        case "height":
            return widthAndHeight[1]
        case "inch":
            return tireSizes[1]
        default:
            return undefined
    }
}
