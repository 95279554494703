import { useLocalization } from "@tm/localization"
import { getAlertColor, isSameDay } from "@tm/utils"
import { IMicros, PriceType } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useUser } from "@tm/context-distribution"
import { Alert, Box, CellContentPosition, TableCellData, TableColumnData, Typography } from "@tm/components"
import { VoucherItem } from "../../../../data/model"
import VoucherItemOptionsCell from "../../../_shared/cells/VoucherItemOptionsCell"
import { NoResultHint } from "../../../_shared/NoResultHint"
import { BoxRowFullWithSpaceBetween, BoxRowContentEnd, StyledTable, RowStack } from "../../../_shared/StyledComponents"
import ArticleDescriptionCell from "../../../_shared/cells/ArticleDescriptionCell"
import PriceCell from "../../../_shared/cells/PriceCell"
import ArticleNumberCell from "../../../_shared/cells/ArticleNumberCell"

type Props = {
    currencyCode?: string
    voucherItems: VoucherItem[]
}

const today = new Date()
export default function PvOrderItemsTable({ currencyCode, voucherItems }: Props) {
    const { translateText, date, translate, currency } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    function renderTourInfo(item: VoucherItem) {
        return (
            <RowStack>
                {item.tourInformation?.map((tour, index) => {
                    if (tour.deliveryTime) {
                        const weekday = isSameDay(tour.deliveryTime, today) ? translateText(142) : date(tour.deliveryTime, "dddd")
                        return (
                            <Typography variant="body3" key={index.toString() + tour.deliveryTime.toString()}>
                                {`${tour.deliveredQuantity} : ${date(tour.deliveryTime, "g")} : ${weekday}`}
                            </Typography>
                        )
                    }
                    if (tour.hint) {
                        return (
                            <Typography variant="body3" key={index.toString() + tour.hint}>
                                {`${tour.deliveredQuantity} : ${tour.hint}`}
                            </Typography>
                        )
                    }
                    return null
                })}
            </RowStack>
        )
    }

    function renderMessages(voucherItem: VoucherItem) {
        return (
            <BoxRowFullWithSpaceBetween>
                <Box>
                    {voucherItem.messages && voucherItem.messages.length && voucherItem.messages.toString().trim() !== "" && (
                        <Alert variant="filled" size="small" severity={getAlertColor(voucherItem.messageType)} fullwidth={false}>
                            {voucherItem.messages.toString()}
                        </Alert>
                    )}
                </Box>
                {voucherItem.deliveryCosts?.value && voucherItem.deliveryCosts?.currencyCode && (
                    <BoxRowContentEnd marginRight="15px">
                        <Typography variant="body2" pr="5px">
                            {voucherItem.deliveryCosts.description}
                        </Typography>
                        <Typography variant="body2">{currency(voucherItem.deliveryCosts?.value, voucherItem.deliveryCosts?.currencyCode)}</Typography>
                    </BoxRowContentEnd>
                )}
            </BoxRowFullWithSpaceBetween>
        )
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(477) }, // number
            { header: translateText(377) }, // description
            { header: translateText(412) }, // availability
            { header: `${translate(89)} & ${translate(1866)}`, alignContent: CellContentPosition.center }, // tour info
            { header: translateText(89), alignContent: CellContentPosition.center }, // quantity
        ]

        if (!hidePurchasePrice) {
            columns.push({ header: translateText(55), alignContent: CellContentPosition.right }) // purchase price
        }

        columns.push({ header: translateText(1620), alignContent: CellContentPosition.right }) // retail price
        if (!hidePurchasePrice) {
            columns.push({ header: translateText(1197), alignContent: CellContentPosition.right }) // total net
        }

        columns.push({ header: translateText(1198), alignContent: CellContentPosition.right }, {}) // total gross

        return columns
    }

    function getCells(voucherItem: VoucherItem) {
        const cellData: TableCellData[] = [
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="supplier" />, id: "item_1" },
            {
                displayValue: (
                    <ArticleDescriptionCell genericArticle={voucherItem.genericArticle} articleDescription={voucherItem.articleDescription} />
                ),
                id: "item_2",
            },
            {
                displayValue: <>{renderMicro("erp", "display-only-availability", { availability: voucherItem.availabilityState })}</>,
                id: "item_3",
            },
            { displayValue: renderTourInfo(voucherItem), id: "item_4" },
            { displayValue: `${voucherItem.quantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_5" },
        ]

        if (!hidePurchasePrice) {
            cellData.push({ displayValue: <PriceCell priceType={PriceType.Purchase} prices={voucherItem.prices} />, id: "item_6" })
        }

        cellData.push({ displayValue: <PriceCell priceType={PriceType.Retail} prices={voucherItem.prices} />, id: "item_7" })
        if (!hidePurchasePrice) {
            cellData.push({
                displayValue: currency(voucherItem.totalNet || 0, currencyCode || voucherItem.currencyCode),
                id: "item_8",
            })
        }

        cellData.push(
            {
                displayValue: currency(voucherItem.totalGross || 0, currencyCode || voucherItem.currencyCode),
                id: "item_9",
            },
            { displayValue: <VoucherItemOptionsCell item={voucherItem} showNote />, id: "item_10" }
        )
        return cellData
    }

    const displayData = voucherItems.map((voucherItem, index) => ({
        cells: getCells(voucherItem),
        id: `${index}`,
        customRow: false,
        active: false,
        extendedContent: renderMessages(voucherItem),
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} headerBackground="paper" />
}
