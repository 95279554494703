import { PropsWithChildren, useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router"
import { bindActionCreators } from "redux"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, Collapsible, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Tooltip } from "@tm/components"
import { FastDtcRouteParams } from "../../../models"
import { Actions } from "../business"

type Props = {
    errorCode: string
    renderAppendix?: () => JSX.Element | null
    initiallyOpened?: boolean
    noContent?: boolean
}

export default function CollapsibleWrapper({ children, errorCode, noContent, renderAppendix, initiallyOpened }: PropsWithChildren<Props>) {
    const dispatch = useDispatch()
    const actions = bindActionCreators(Actions, dispatch)
    const { translateText } = useLocalization()
    const history = useHistory()
    const { search } = useLocation()
    const params = useParams<FastDtcRouteParams>()

    const vehicle = useWorkTask()?.workTask?.vehicle
    const style = useMemo(() => getStyle(), [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hasHaynesPro = useMemo(() => !!vehicle?.dataAvailabilities?.technicalData?.haynesPro, [vehicle?.id, vehicle?.dataAvailabilities])

    const handleRemoveError = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()

            actions.removeError(errorCode)

            const dtcParams = new URLSearchParams(search).get("errorCodes") || ""
            const errorCodesList = dtcParams.split(",").filter((x) => x !== errorCode && x !== "")

            history.push({
                search: `errorCodes=${errorCodesList.join(",")}`,
            })
        },
        [actions, errorCode, history, search]
    )

    const handleOpenHaynesPro = useCallback(
        (e: React.MouseEvent<Element>) => {
            e.stopPropagation()
            // TODO put in uiconfig
            const route = `/${params.workTaskId}/td/haynespro?moduleMode=TD&errorCodes=${errorCode}`
            history.push(route)
        },
        [history, params.workTaskId, errorCode]
    )

    const handlePreventOnClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e?.preventDefault()
        e?.stopPropagation()
    }, [])

    const renderCollapseHeader = () => {
        return (
            <div className={style.header}>
                <Text onClick={handlePreventOnClick} modifiers={["strong"]} className={style.code}>
                    {errorCode.toUpperCase()}
                </Text>
                {noContent && <Text>{translateText(809)}</Text>}

                <div className={style.headerAppendix}>
                    {renderAppendix && renderAppendix()}
                    {!noContent && hasHaynesPro && (
                        <Tooltip title={translateText(238)}>
                            <Button disabled={!hasHaynesPro} onClick={handleOpenHaynesPro} icon="tech-data" />
                        </Tooltip>
                    )}
                    <Button fakeButton onClick={handleRemoveError} icon="delete" />
                </div>
            </div>
        )
    }

    return (
        <Collapsible
            disabled={noContent}
            initiallyOpened={initiallyOpened}
            className={`${style.collapse} ${noContent ? style.noDropdown : ""}`}
            skin="dark"
            layout="holo"
            renderHeaderAppendix={renderCollapseHeader}
        >
            {children}
        </Collapsible>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useStyle({
        header: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
        },
        headerAppendix: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "0.25em",
        },
        noDropdown: {
            $nest: {
                ".collapsible__header": {
                    $nest: {
                        "&>.icon:first-child": {
                            visibility: "hidden",
                        },
                    },
                },
            },
        },
        collapse: {
            margin: "0.5rem 1rem 0rem 1rem",
            $nest: {
                ".collapsible__content": {
                    margin: "1rem",
                },
            },
        },
        paddingText: {
            paddingRight: theme.margin.xl,
        },
        code: {
            marginRight: theme.margin.m,
            paddingLeft: theme.margin.s,
            paddingRight: theme.margin.s,
            borderRadius: theme.radius?.default,
            cursor: "auto",
        },
    })(CollapsibleWrapper)
}
