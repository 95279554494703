import { Dialog } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EventListenerManager } from "@tm/utils"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { styled } from "@tm/components"
import { generateDialogContent } from "@bundles/tyres/data/helpers/infoDialogHelper"
import { FilterType } from "../../data/enums"

type Props = {
    filterId: FilterType | string
    onDialogCLose(): void
}

export function InfoDialog({ filterId, onDialogCLose }: Props) {
    const dialogRef = useRef<Dialog>(null)
    const { translateText } = useLocalization()
    let unregisterOutsideClick: () => void

    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        openDialog()
        return () => {
            dialogRef.current?.hide()
        }
    }, [])

    useLayoutEffect(() => {
        if (dialogRef?.current?.innerRef.current) {
            unregisterOutsideClick = EventListenerManager.registerEventListener("outsideClick", dialogRef.current.innerRef.current, handleClose, true)
        }
        return () => {
            unregisterOutsideClick?.()
        }
    }, [dialogOpen])

    const openDialog = () => {
        dialogRef.current?.show()
        setDialogOpen(true)
    }

    const handleClose = () => {
        dialogRef.current?.hide()
        setDialogOpen(false)
        onDialogCLose()
    }

    const getDialogContent = () => {
        return generateDialogContent(filterId, translateText)
    }

    return <StyledDialog ref={dialogRef} preText={getDialogContent()?.[1]} text={getDialogContent()?.[0]} />
}

const StyledDialog = styled(Dialog)({
    ".dialog-prompt": {
        "&__text": {
            whiteSpace: "pre-wrap",
        },
        "&__inner": {
            width: "60%",
        },
        "&__pre-text": {
            fontSize: "1.25em",
            fontWeight: "bold",
            marginBottom: "1em",
        },
        "&__content": {
            alignItems: "start",
        },
    },
})
