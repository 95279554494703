import { Article, getCurrentWorkTaskId, GetSearchTreeResponse, GetUniArticlesResponse, SearchTreeProductGroup, TreeNode } from "@tm/models"
import { ajaxAuth, decodeUniqueId, notUndefinedOrNull, TmaHelper } from "@tm/utils"

import { Articles } from "@tm/data"
import { getBundleParams } from "../../utils"
import * as Mappers from "../mapper"
import {
    GetBestsellerCriteriaRequest,
    GetBestsellerCriteriaResponse,
    GetBestsellerSearchTreeRequest,
    GetBestsellerSearchtreeResponse,
    GetProductGroupAndCriterionFilterResponse,
    UniParts as Models,
} from "../model"

function getServiceUrl() {
    const { uniPartsServiceUrl } = getBundleParams()

    if (uniPartsServiceUrl) {
        return uniPartsServiceUrl
    }
}

export function getSearchTree(request: Models.SearchTreeRequest): Promise<GetSearchTreeResponse> {
    type Response = {
        nodes?: Array<TreeNode>
        topProductGroups?: Array<SearchTreeProductGroup>
    }

    return ajaxAuth<Response>({
        url: `${getServiceUrl()}/SearchTree`,
        body: request,
    }).then((response) => Mappers.mapGetSearchTreeResponse(response))
}

export function getSearchTreeNodes(request: Models.SearchTreeNodesRequest): Promise<Array<Models.SearchTreeNode>> {
    type Response = {
        searchTreeNodes: Array<Models.SearchTreeNode>
    }

    return ajaxAuth<Response>({
        url: `${getServiceUrl()}/SearchTreeNodes`,
        body: request,
    }).then((response) => response?.searchTreeNodes ?? [])
}

export function getFiltersBySearchTree(request: Models.FiltersRequest): Promise<Models.FiltersResponse> {
    return ajaxAuth<Models.FiltersResponse>({
        method: "POST",
        url: `${getServiceUrl()}/AttributeFiltersBySearchTree`,
        body: request,
    }).then((response) => ({
        productGroupFilters: (response?.productGroupFilters ?? []).map((x) => ({ ...x, showOnTop: x.showOnTop || x.hasTopPrioritySuppliers })),
        supplierFilters: response?.supplierFilters ?? [],
        criterionFilterGroups: response?.criterionFilterGroups ?? [],
        showExtendedAssortmentFilter: response?.showExtendedAssortmentFilter ?? false,
    }))
}

export function getFiltersByQuery(request: Models.FiltersByQueryRequest): Promise<Models.FiltersResponse> {
    return ajaxAuth<Models.FiltersResponse>({
        method: "POST",
        url: `${getServiceUrl()}/AttributeFilters`,
        body: request,
    }).then((response) => ({
        productGroupFilters: response?.productGroupFilters ?? [],
        supplierFilters: response?.supplierFilters ?? [],
        criterionFilterGroups: response?.criterionFilterGroups ?? [],
        showExtendedAssortmentFilter: response?.showExtendedAssortmentFilter ?? false,
    }))
}

export function getArticlesBySearchTree(request: Models.ArticlesRequest): Promise<Models.ArticlesResponse> {
    const url = `${getServiceUrl()}/UniArticlesBySearchTree`

    return ajaxAuth<GetUniArticlesResponse>({
        method: "POST",
        url,
        body: request,
    }).then((response) => {
        let mappedArticles: Article[] = []

        if (response?.uniArticles) {
            mappedArticles = response.uniArticles.map(Articles.mapArticle).filter(notUndefinedOrNull) // do not reassign response.uniArticles here!
        }

        sendTmaInfo(url, request, response)

        return {
            data: mappedArticles,
            pageIndex: response?.pageIndex ?? 0,
            pageSize: response?.pageSize ?? 25,
            count: response?.articleListCount,
        }
    })
}

export function getArticlesByQuery(request: Models.ArticlesByQueryRequest): Promise<Models.ArticlesResponse> {
    const url = `${getServiceUrl()}/UniArticles`

    return ajaxAuth<GetUniArticlesResponse>({
        method: "POST",
        url,
        body: request,
    }).then((response) => {
        let mappedArticles: Article[] = []

        if (response?.uniArticles) {
            mappedArticles = response.uniArticles.map(Articles.mapArticle).filter(notUndefinedOrNull) // do not reassign response.uniArticles here!
        }

        sendTmaInfo(url, request, response)

        return {
            data: mappedArticles,
            pageIndex: response?.pageIndex ?? 0,
            pageSize: response?.pageSize ?? 25,
            count: response?.articleListCount,
        }
    })
}

export function getProductGroupsBySearchTree(request: Models.ProductGroupsBySearchTreeRequest): Promise<GetProductGroupAndCriterionFilterResponse> {
    return ajaxAuth<GetProductGroupAndCriterionFilterResponse>({
        url: `${getServiceUrl()}/ProductGroupsBySearchTree`,
        body: request,
    }).then((response) => {
        return {
            productGroupFilters: response?.productGroupFilters || [],
            selectedCriteria: response?.selectedCriteria || [],
        }
    })
}

export function getBestsellerSearchtree(request: GetBestsellerSearchTreeRequest) {
    return ajaxAuth<GetBestsellerSearchtreeResponse>({
        body: request,
        url: `${getServiceUrl()}/GetBestsellerSearchTree`,
    })
}

export function getGetBestsellerCriteria(request: GetBestsellerCriteriaRequest) {
    return ajaxAuth<GetBestsellerCriteriaResponse>({
        body: request,
        method: "POST",
        url: `${getServiceUrl()}/GetBestsellerCriteria`,
    })
}

function sendTmaInfo(url: string, request: Models.ArticlesRequest, response?: GetUniArticlesResponse) {
    const match = /nodeId=(\d+)/.exec(document.location.search)
    const searchedNodeId = match?.last()

    setTimeout(() => {
        let worktaskId = getCurrentWorkTaskId() ?? ""

        if (worktaskId == "") {
            const m = /[\w\d]+/.exec(document.location.pathname)
            if (m?.[0] && m[0] != "parts") {
                worktaskId = decodeUniqueId(m[0]) ?? ""
            }
        }

        TmaHelper.UniParts.List.Results(request, response, worktaskId, url, searchedNodeId)
    }, 1000)
}
