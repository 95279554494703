import {
    AddCustomWorkListRequest,
    AddRepairTimeListRequest,
    ChangeItemsResponse,
    FittingGroupRequest,
    FittingGroupResponse,
    RepairTimeProvider,
} from "@tm/models"
import { ajaxAuth, createBufferedRequestFunction, TmaHelper } from "@tm/utils"
import { getBasketServiceUrl } from "../.."
import {
    BasketHasRepairTime,
    ChangeWorkItemListResponse,
    ChangeWorkItemResponse,
    EditCustomWorkRequest,
    EditRepairTimeCalculationRequest,
    ReplaceByCustomWorkRequest,
    ResetRepairTimeListRequest,
} from "../../model"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Works`
}

export function addRepairTimeList(request: AddRepairTimeListRequest) {
    const url = `${getServiceUrl()}/AddRepairTimeList`
    request.log = TmaHelper.AddRepairTimeList.GetUserInteractionLog(request.provider)
    return ajaxAuth<ChangeItemsResponse>({ url, body: request, method: "POST" })
}

export function addCustomWorkList(body: AddCustomWorkListRequest) {
    const url = `${getServiceUrl()}/AddCustomWorkList`
    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function editCustomWork(body: EditCustomWorkRequest) {
    const url = `${getServiceUrl()}/EditCustomWork`
    return ajaxAuth<ChangeWorkItemResponse>({ url, body, method: "POST" })
}

export function editRepairTimeCalculation(body: EditRepairTimeCalculationRequest) {
    const url = `${getServiceUrl()}/EditRepairTimeCalculation`
    return ajaxAuth<ChangeWorkItemResponse>({ url, body, method: "POST" })
}

export function resetRepairTimeList(body: ResetRepairTimeListRequest) {
    const url = `${getServiceUrl()}/ResetRepairTimeList`
    return ajaxAuth<ChangeWorkItemListResponse>({ url, body, method: "POST" })
}

export function removeWorkList(workTaskId: string, idList: string[]) {
    const url = `${getServiceUrl()}/RemoveWorkList`
    const body = { workTaskId, idList }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function replaceByCustomWork(body: ReplaceByCustomWorkRequest) {
    const url = `${getServiceUrl()}/ReplaceByCustomWork`
    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

type NexusHasRepairTimesRequest = {
    workTaskId: string
    repairTimesProvider: RepairTimeProvider
    repairTimeProviderWorkId: string
    resolve: (items: BasketHasRepairTime) => void
    reject: (error: string) => void
}

const nexusQueue: NexusHasRepairTimesRequest[] = []
let nexusBufferTimeout: number

const bufferTimespanMs = 25 // defines the timespan (in ms) in which the requests will be buffered
const maxQueueLength: number | undefined = undefined // defines the maximum number of items in a single service call

// TODO: Delete when no longer used from the registered models
/**
 * @memberof Basket
 * Get basket part information with buffering so multiple nearly simultaneous requests would only result in one single service call
 * @param {boolean} sendImmediately Optional: if provided the request will be send immediately without buffering
 */
export function hasRepairTimesNexus(
    request: { workTaskId: string; repairTimesProvider: RepairTimeProvider; repairTimeProviderWorkId: string },
    sendImmediately?: boolean
): Promise<BasketHasRepairTime> {
    return new Promise<BasketHasRepairTime>((resolve, reject) => {
        const nexusRequest: NexusHasRepairTimesRequest = { ...request, resolve, reject }

        if (sendImmediately) {
            requestHasRepairTimesNexus(nexusRequest)
            return
        }

        // if there is already an identical request pending do nothing
        if (
            nexusQueue.some(
                (x) =>
                    x.workTaskId === nexusRequest.workTaskId &&
                    x.repairTimesProvider === nexusRequest.repairTimesProvider &&
                    x.repairTimeProviderWorkId === nexusRequest.repairTimeProviderWorkId
            )
        ) {
            return
        }

        nexusQueue.push(nexusRequest)

        clearTimeout(nexusBufferTimeout)

        if (maxQueueLength && nexusQueue.length >= maxQueueLength) {
            requestHasRepairTimesNexus()
            return
        }

        nexusBufferTimeout = window.setTimeout(() => requestHasRepairTimesNexus(), bufferTimespanMs)
    })
}

function requestHasRepairTimesNexus(request?: NexusHasRepairTimesRequest) {
    const url = `${getServiceUrl()}/HasRepairTimes`

    // If supplied the given request will be used instead of the queue
    const requestItems = request ? [request] : nexusQueue.splice(0, nexusQueue.length) // splice to remove request items from queue...

    if (!requestItems.length) {
        return
    }

    function sendRequest(workTaskId: string, providerId: number, requestItems: NexusHasRepairTimesRequest[]) {
        const body = {
            workTaskId,
            repairTimesProvider: providerId,
            repairTimeProviderWorkIds: requestItems.map((x) => x.repairTimeProviderWorkId),
        }

        ajaxAuth({ url, body, method: "POST" }).then(
            (data) => {
                let items: BasketHasRepairTime[] = data?.hasRepairTimeList ?? []
                items = items.filter((x) => !!x)

                requestItems.forEach((requestItem) => {
                    const responseItem = items.find(
                        (x) =>
                            requestItem.workTaskId === body.workTaskId &&
                            requestItem.repairTimesProvider === body.repairTimesProvider &&
                            requestItem.repairTimeProviderWorkId === x.repairTimeProviderWorkId
                    )

                    if (responseItem) {
                        requestItem.resolve(responseItem)
                    } else {
                        requestItem.reject("No corresponding item in response")
                    }
                })
            },
            (error) => requestItems.forEach((item) => item.reject(error))
        )
    }

    // Group requests by workTaskId and then by repairTimesProvider
    // and send out a single requests for each combination
    /** @todo Service should be refactored to support workTaskId and repairTimesProvider for each item */
    Object.entries(requestItems.groupBy((x) => x.workTaskId)).forEach(([workTaskId, requests]) => {
        Object.entries(requests.groupBy((x) => x.repairTimesProvider)).forEach(([provider, requests]) => {
            sendRequest(workTaskId, parseInt(provider), requests)
        })
    })
}

function callService(requests: { workTaskId: string; request: FittingGroupRequest }[]) {
    const url = `${getServiceUrl()}/HasRepairTimesForProductGroup`
    const body = {
        workTaskId: requests[requests.length - 1].workTaskId,
        fittingGroups: requests.map((r) => r.request),
    }

    return ajaxAuth<{ fittingGroups?: FittingGroupResponse[] }>({ url, body, method: "POST" }).then((data) => data?.fittingGroups || [])
}

export const getHasRepairTimesForProductGroup = createBufferedRequestFunction({
    callService,
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return (
            serviceResponse.find(
                (item) => item.productGroupId === request.request.productGroupId && item.fittingSide === request.request.fittingSide
            ) || {
                productGroupId: request.request.productGroupId,
                fittingSide: request.request.fittingSide,
                hasWorks: false,
            }
        )
    },
})
