import { useLocalization } from "@tm/localization"
import { Article, ErpSystemConfigMode } from "@tm/models"
import { TableColumnData, Loader, CellContentPosition, Table } from "@tm/components"
import { useErpConfig } from "@tm/utils"
import Row from "./PartnerSystemsPopoverRow"

type Props = {
    article: Article
    onMultiplePartnerSystemsPopoverClosed(isHidden: boolean): void
}

export default function PartnerSystemsPopoverTableComponent({ article, onMultiplePartnerSystemsPopoverClosed }: Props) {
    const { translateText } = useLocalization()
    const { erpSystemConfigs, mode } = useErpConfig()

    if (mode !== ErpSystemConfigMode.Partnersystems || !erpSystemConfigs?.length) {
        return null
    }
    if (!article) {
        return <Loader />
    }

    const columns: TableColumnData[] = [
        { header: translateText(839).toUpperCase() } /* Partner */,
        { header: translateText(13387).toLocaleUpperCase(), alignContent: CellContentPosition.right } /* Nettopreis  */,
        { header: translateText(53).toLocaleUpperCase(), alignContent: CellContentPosition.right } /* Preis  */,
        { header: translateText(412).toLocaleUpperCase(), alignContent: CellContentPosition.center } /* Verfügbarkeitsicon */,
        {} /* AddToBasket */,
    ]

    const rows = erpSystemConfigs?.map((config, index) => Row({ article, erpSystemConfig: config, index, onMultiplePartnerSystemsPopoverClosed }))

    return <Table headerStyle="default" headerBackground="white" columns={columns} rows={rows} overflowY="auto" />
}
