import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { RimsDetailsArticlesRequest } from "./model"
import { RimDetailsArticle } from "../../model"
import { mapTireSizes } from "../wheels-loadRimArticleFilter/mapper"

export function loadRimDetailsArticles(request: RimsDetailsArticlesRequest) {
    const url = `${getServiceUrl()}/GetRims`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Record<string, RimDetailsArticle[]>>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => {
            if (response && response.rimsDetails && Object.keys(response.rimsDetails).length) {
                resolve(mapRimDetails(response.rimsDetails))
            } else {
                reject()
            }
        }, reject)
    )
}

function mapRimDetails(data: Record<string, RimDetailsArticle[]>) {
    const obj: typeof data = {}
    Object.keys(data).forEach((key) => {
        obj[key] = data[key].map((x) => ({
            ...x,
            quantity: x.quantity || 4,
            sizes: mapTireSizes(x.tireSizes, x.wheelSize),
        }))
    })
    return obj
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}
