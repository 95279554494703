import {
    getParameterFromUserContextModule,
    useDisplayListV2,
    useStyle,
    useTyresMatchCodeSearchType,
    useTyresV2,
    useUser,
} from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ConfigParams, UserModuleType } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { classes, createQueryString, parseQueryString, renderRoute } from "@tm/utils"
import { batch, useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Box, LinkTab, Tabs, Button, styled } from "@tm/components"
import { Link } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { Toolbar } from "@tm/controls"
import { bundleChannel } from "../../data/channels"
import { decodeAllParams } from "../../data/helpers"
import { EncodedMatchParams, NavigationSteps } from "../../data/models"
import { TopFiltersComponent, TopFiltersV2 } from "../list/components"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { getBundleParams } from "../../utils"
import { MatchCodeSearch } from "../_shared/matchCodeSearch"
import { SummaryHeader } from "../summary/components/SummaryHeader"

type Props = {
    route: string
}

export default function NavigationComponent({ route }: Props) {
    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
    const history = useHistory()
    const dispatch = useDispatch()
    const matchParams = useParams<EncodedMatchParams>()
    const state = useSelector((s: MainState) => s.navigation)
    const searchValue = useSelector((state: MainState) => state.list.searchValue)
    const { translateText } = useLocalization()
    const { view } = matchParams
    const actions = useActions(
        MainActions,
        "changeStep",
        "initNav",
        "loadTyresList",
        "setDefaultQuantity",
        "updateSearchValue",
        "updateSummaryFilters"
    )
    let blockChange = false
    const { useNewNavbar } = getBundleParams()
    const displayListV2 = useDisplayListV2()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const tyresV2 = useTyresV2()
    const { userContext } = useUser()
    const defaultQuantity = getParameterFromUserContextModule(userContext, UserModuleType.TMTires, "DefaultAmount", "number")
    const showMatchCodeSearch = useTyresMatchCodeSearchType()
    const style = useMemo(() => getStyle(view), [view])

    const [, carType, season] = decodeURIComponent(matchParams.search || "").split("&") ?? []

    useEffect(() => {
        if (matchParams.view && matchParams.view !== state.active && !blockChange && state.initialized) {
            actions.changeStep(matchParams.view)
            blockChange = true
            setTimeout(() => {
                blockChange = false
            }, 1000)
        }
    }, [matchParams.view])

    useEffect(() => {
        const { active, params } = state

        if (active && !blockChange) {
            const rrparams: any = { ...matchParams, ...params, view: active }
            const newSearch = [rrparams.size, rrparams.carType, rrparams.season].filter(Boolean).join("&")
            const routeParams = { ...rrparams, ...(newSearch && { search: newSearch }), workTaskId: matchParams.workTaskId }

            const url = renderRoute(route, routeParams)

            const queryParams = parseQueryString(location.search)
            queryParams.initialQuantity = params?.quantity

            if (matchParams.view === active) {
                history.replace(url + createQueryString(queryParams))
            } else {
                history.push(url + createQueryString(queryParams))
            }

            blockChange = true
            setTimeout(() => {
                blockChange = false
            }, 1000)
        }
    }, [state.active, state.params])

    useEffect(
        () =>
            batch(() => {
                const decodedParams = decodeAllParams(matchParams)
                actions.setDefaultQuantity(defaultQuantity)

                // load from url
                if (!state.initialized || decodedParams.workTaskId !== state.params.workTaskId) {
                    actions.initNav(decodedParams, tyresV2)
                    if (matchParams.search) {
                        actions.loadTyresList(!season || !carType)
                    }
                    return
                }

                // load dashboard widget
                const loadRequest = bundleChannel().last(1, "LOAD_LIST")?.[0]?.content
                if (loadRequest?.matchCode) {
                    actions.initNav(decodedParams, tyresV2)
                    actions.loadTyresList(!season || !carType)
                    bundleChannel().clear("LOAD_LIST")
                }
            }),
        []
    )

    const isListDisabled = () => !state.params?.size && view !== "list"
    const isDetailsDisabled = () =>
        (!state.params?.productGroupId || !state.params.supplierArticleNo || !state.params.supplierId) && view !== "details"

    const handleMatchCodeSearch = (size: string) => {
        dispatch(MainActions.updateSearchValue(size))

        const params = {
            size,
            season: undefined,
            carType: undefined,
        }

        dispatch(MainActions.loadTyresList(true))
        dispatch(MainActions.changeStep("list", params))
    }

    const displayDefaultTabLayout = () => {
        return (
            <>
                <Button onClick={() => actions.changeStep("summary")} color={view === "summary" ? "primary" : undefined}>
                    {translateText(409)}
                </Button>
                <Button disabled={isListDisabled()} onClick={() => actions.changeStep("list")} color={view === "list" ? "primary" : undefined}>
                    {translateText(6)}
                </Button>
                {!articleDetailsInModal && (
                    <Button
                        disabled={isDetailsDisabled()}
                        onClick={() => actions.changeStep("details")}
                        color={view === "details" ? "primary" : undefined}
                    >
                        {translateText(43)}
                    </Button>
                )}
            </>
        )
    }

    const displayNewTabLayout = () => {
        return (
            <>
                <Tabs value={view} sx={{ marginRight: matchParams.view === "summary" ? "10em !important" : 0 }}>
                    <LinkTab
                        component={Link}
                        value="summary"
                        label={translateText(409)}
                        onClick={() => actions.changeStep("summary")}
                        disableRipple
                        sx={{ color: "black" }}
                    />
                    <LinkTab
                        disabled={isListDisabled()}
                        component={Link}
                        value="list"
                        label={translateText(6)}
                        onClick={() => actions.changeStep("list")}
                        disableRipple
                        sx={{ color: "black" }}
                    />
                    {!articleDetailsInModal && (
                        <LinkTab
                            disabled={isDetailsDisabled()}
                            onClick={() => actions.changeStep("details")}
                            value="details"
                            label={translateText(43)}
                            disableRipple
                            sx={{ color: "black" }}
                        />
                    )}
                </Tabs>
                {matchParams.view === "summary" && <SummaryHeader />}
            </>
        )
    }

    return (
        <Box flex="0" display="flex" id="nav-wrapper" className={classes("module", useNewNavbar ? style.newWrapper : "toolbar")}>
            <StyledHeadline className="headline" view={view}>
                {translateText(240)}
            </StyledHeadline>
            <Toolbar title={translateText(176)}>{useNewNavbar ? displayNewTabLayout() : displayDefaultTabLayout()}</Toolbar>
            {matchParams.view === "summary" && tyresV2 && showMatchCodeSearch && (
                <Toolbar title={translateText(135)}>
                    <MatchCodeSearch value={searchValue} onButtonClick={handleMatchCodeSearch} />
                </Toolbar>
            )}
            {matchParams.view === "list" &&
                (displayListV2 && !isWm ? (
                    <Toolbar>
                        <TopFiltersV2 />
                    </Toolbar>
                ) : (
                    <TopFiltersComponent />
                ))}
        </Box>
    )
}

const StyledHeadline = styled(Box, {
    shouldForwardProp: (prop) => prop !== "view",
})<{ view?: string }>(({ view }) => ({
    display: "flex",
    alignItems: "center",
    paddingRight: "2rem",
    paddingTop: view === "list" ? ".3em" : "initial",
}))
function getStyle(view: NavigationSteps | undefined) {
    return useStyle({
        newWrapper: {
            flex: 0,
            display: "flex",
            backgroundColor: "white",
            padding: view === "summary" ? "1rem 1.5rem .6rem 1.5rem" : "1rem 1.5rem",
            margin: "-.6rem 1rem .4rem 1rem",
            $nest: {
                ".toolbar .toolbar__inner .subtitle": {
                    marginBottom: view === "summary" ? "-.3rem" : "initial",
                },
            },
        },
    })(NavigationComponent)
}
