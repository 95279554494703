import { Tooltip, styled } from "@tm/components"
import { Button } from "@tm/controls"
import { ArticleAttribute } from "@tm/models"

type SelectableArticleAttribute = ArticleAttribute & { selected?: boolean }

type Props = {
    items: SelectableArticleAttribute[]
    onSelect: (a: ArticleAttribute) => void
}

const Container = styled("div")(({ theme }) => ({
    display: "flex",
}))

function getBackgroundColorForValue(value: string): string | undefined {
    switch (value) {
        case "A":
            return "#2d7327"
        case "B":
            return "#55d545"
        case "C":
            return "#b5e941"
        case "D":
            return "#f6ed4e"
        case "E":
            return "#f6a328"
        case "F":
            return "#dd901f"
        case "G":
            return "#e12816"
        default:
            return "#2196f3"
    }
}

const NumberButton = styled(Button)<{ selected: boolean; title: string }>(({ theme, selected, title }) => {
    const backgroundColor = getBackgroundColorForValue(title)

    return {
        cursor: "pointer",
        width: "25px",
        height: "25px",
        marginLeft: "0.2em",
        textShadow: "0 0 3px #202020",
        color: theme.colors?.light,
        backgroundColor,
        ...(selected
            ? {
                  outline: "1px",
                  outlineColor: theme.palette.primary.main,
                  outlineStyle: "auto",
              }
            : {}),
        "&:active, &:focus": {
            backgroundColor,
        },
        "&:hover": {
            textShadow: "none",
        },
        ".btn content": {
            opacity: 1,
        },
    }
})

export default function TiresIcons({ items, onSelect }: Props) {
    function renderIcon(attr: SelectableArticleAttribute, key: number) {
        return (
            <Tooltip key={key} title={attr.description}>
                <NumberButton size="s" selected={attr.selected ?? false} title={attr.value} onClick={() => onSelect(attr)}>
                    {attr.value}
                </NumberButton>
            </Tooltip>
        )
    }

    return <Container>{items.map(renderIcon)}</Container>
}
