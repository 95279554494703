import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useQuery } from "react-query"
import { BestsellerSearchtreeNode, CriterionFilters } from "../../../data/model"
import { CriterionSelection } from "./CriterionSelection"
import { getGetBestsellerCriteria } from "../../../data/repositories/uni-parts"

type Props = {
    id?: number
    bestsellerSearchTreeNode?: BestsellerSearchtreeNode
    selectedCriteria?: CriterionFilters[]
    setSelectedCriterias: Dispatch<SetStateAction<CriterionFilters[] | undefined>>
    onSearch: () => void
    enableCall: boolean
    onSelectionOpend: () => void
}

/**
 * renders all Select components for filtergroup, handle selction change, creates nodeState based on Selection group
 * @param props
 * @returns
 */
export function Selections(props: Props) {
    const { id, bestsellerSearchTreeNode, selectedCriteria, setSelectedCriterias, onSearch, enableCall, onSelectionOpend } = props
    const { translateText, languageId } = useLocalization()

    const [groupsChange, setGroupsChange] = useState(false)

    const { data, isLoading, isFetching, isPreviousData } = useQuery(
        ["parts__unisearch_getGetBestsellerCriteria", languageId, bestsellerSearchTreeNode, selectedCriteria],
        () => getGetBestsellerCriteria({ selectedProductGroups: bestsellerSearchTreeNode?.selectedProductGroups || [], selectedCriteria }),
        {
            onSuccess: () => {
                setGroupsChange(false)
            },
            keepPreviousData: true,
            enabled: enableCall,
            initialData: {
                criterionFilterGroups: bestsellerSearchTreeNode?.criterionFilterGroups || [],
                productGroupFilters: bestsellerSearchTreeNode?.selectedProductGroups || [],
            },
        }
    )

    // Just a little workaround to allow switching of Tree nodes with different count of Selection fields
    // otherwise the "keepPreviousData" will return the wrong data, after switching from node to node
    // and without this, we will remove the Select inputs after each selection, because we wait for data
    useEffect(() => {
        setGroupsChange(true)
    }, [bestsellerSearchTreeNode])

    const criteriaGroups = useMemo(() => {
        if (!enableCall || (groupsChange && isPreviousData)) {
            return bestsellerSearchTreeNode?.criterionFilterGroups
        }
        return data?.criterionFilterGroups
    }, [data, bestsellerSearchTreeNode, enableCall, groupsChange, isPreviousData])

    const handleSelectionChange = useCallback(
        (criterionFilter?: CriterionFilters) => {
            if (criterionFilter) {
                setSelectedCriterias((prev) => {
                    return prev ? [...prev, criterionFilter] : [criterionFilter]
                })
            }
        },
        [setSelectedCriterias]
    )

    const handleRemoveSelectedCriteria = useCallback(
        (criterionFilter?: CriterionFilters) => {
            setSelectedCriterias((prev) => {
                return prev ? prev.filter((val) => val.id !== criterionFilter?.id) : undefined
            })
        },
        [setSelectedCriterias]
    )

    return (
        <>
            {criteriaGroups?.map((cretGroup) => {
                return (
                    <CriterionSelection
                        description={cretGroup.description}
                        criterionFilters={cretGroup.criterionFilters}
                        key={`${cretGroup.key}_${id}`}
                        onSelectionChange={handleSelectionChange}
                        removeSelectedCriteria={handleRemoveSelectedCriteria}
                        onSelectionOpend={onSelectionOpend}
                        loading={isLoading || isFetching}
                    />
                )
            })}
            <Button startIcon={<Icon name="search" />} onClick={onSearch}>
                {translateText(13649)}
            </Button>
        </>
    )
}
