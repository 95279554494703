import { WholesalerArticle, StringDictionary, Article, SupplierArticle, Advertisement } from "@tm/models"
import { QueryFunctionContext } from "react-query"
import { getArticlesByWholesalerArticleNos, getArticlesBySupplierArticleNos } from "../../../../../data/repositories"
import { getArticlesFromTraderArticleDictionary, getArticlesFromSupplierArticleDictionary } from "../../../../list/business/helpers"
import { ArticlePage, QueryKey } from "../../../hooks"

export type OfferArticleRequest = {
    pageSize: number
    advertisements: Advertisement[]
}

export async function fetchArticles({
    queryKey: [, , request],
    pageParam,
}: QueryFunctionContext<QueryKey<OfferArticleRequest>, number>): Promise<ArticlePage & { wholesalerParts?: WholesalerArticle[] }> {
    if (!request) {
        return Promise.reject()
    }

    const pageIndex = pageParam ?? 1

    const proms: [Promise<StringDictionary<Article[]>> | undefined, Promise<StringDictionary<Article[]>> | undefined] = [undefined, undefined]

    const startIndex = request.pageSize * (pageIndex - 1)
    const endIndex = request.pageSize * pageIndex
    const lastPage = endIndex >= request.advertisements.length

    const pagedAds = request.advertisements.slice(startIndex, endIndex)

    const wholesalerArticleNumbers = pagedAds.filter((ads) => ads.wholesalerArticleNumber).map((x) => x.wholesalerArticleNumber)
    if (wholesalerArticleNumbers.length) {
        proms[0] = getArticlesByWholesalerArticleNos(wholesalerArticleNumbers, undefined, true)
    }

    const supplierArticleNumbers: Array<SupplierArticle> = []
    pagedAds.forEach((ad) => {
        if (ad.dataSupplierId && ad.dataSupplierArticleNumber) {
            supplierArticleNumbers.push({
                supplierId: ad.dataSupplierId,
                supplierArticleNo: ad.dataSupplierArticleNumber,
            })
        }
    })

    if (supplierArticleNumbers.length) {
        proms[1] = getArticlesBySupplierArticleNos(supplierArticleNumbers)
    }

    if (!proms[0] && !proms[1]) {
        // error
        return { data: [], nextPageIndex: undefined }
    }

    const [wholesalerArticleResponse, supplierArticlesResponse] = await Promise.all(proms)

    const { articles, wholesalerParts } = getArticlesFromTraderArticleDictionary(wholesalerArticleResponse, request.advertisements)
    articles.push(...getArticlesFromSupplierArticleDictionary(supplierArticlesResponse))

    return {
        data: articles,
        wholesalerParts,
        nextPageIndex: !lastPage ? pageIndex + 1 : undefined,
    }
}
