import { useMemo } from "react"
import { Box, ScrollContainer, Typography, LoadingContainer } from "@tm/components"
import { FastCalculator } from "@tm/data"
import CalcStateButton from "../../calcStateButton"
import BorderedCollapsible from "../../borderedCollapsible"
import SelectionItems from "../../selectionItems"
import { getWorks } from "../../../../data/helpers/context"

type Props = {
    calcState: FastCalculator.CalcState
    loading: boolean | undefined
    // eslint-disable-next-line react/no-unused-prop-types
    onClose?: () => void
}

export default function WorkContent({ calcState, loading }: Props) {
    const getContent = useMemo(() => getWorks(calcState), [calcState.type, calcState.context])

    const renderContent = () => {
        const additionalWork: (FastCalculator.CalcInput & { items: FastCalculator.CalcSelectionItem[] })[] | undefined = getContent

        if (!additionalWork) {
            return null
        }

        return (
            <>
                {additionalWork?.map((work) => (
                    <BorderedCollapsible key={work.id} name={work?.label ?? ""} initiallyOpened>
                        <Box>
                            <SelectionItems items={work.items} isOverlayCalcState />
                        </Box>
                    </BorderedCollapsible>
                ))}
            </>
        )
    }

    return (
        <LoadingContainer isLoading={loading}>
            <ScrollContainer>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">{calcState?.context?.label}</Typography>
                    <Box display="flex" alignItems="center" gap="0.5em">
                        {calcState?.buttons?.map((button) => <CalcStateButton item={button} key={button.id} isOverlayCalcState />)}
                    </Box>
                </Box>
                {renderContent()}
            </ScrollContainer>
        </LoadingContainer>
    )
}
