import { Article } from "@tm/models"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { FC } from "react"
import { mapRimItemtoArticle } from "../../../data/helpers"
import { RimDetailsArticle } from "../../../data/model"
import { MainState } from "../../main"
import { DetailsArticleItem } from "../../_shared"

type RimArticleProps = {
    isSelected: boolean
    item: RimDetailsArticle
    repairTimesRoute: string
    largeCheckbox?: boolean
    onSelectItem: (rim: RimDetailsArticle) => void
    onRimQuantityChange: (article: RimDetailsArticle, value: number) => void
}

const selector = createSelector(
    (s: MainState) => s.manager.vehicle,
    (x) => x?.id
)

const RimArticle: FC<RimArticleProps> = ({ isSelected, item, repairTimesRoute, largeCheckbox, onSelectItem, onRimQuantityChange }) => {
    const vehicleId = useSelector(selector)
    const rimPart = mapRimItemtoArticle(item) as Article

    return (
        <DetailsArticleItem
            item={item}
            vehicleId={vehicleId}
            rimPart={rimPart}
            isSelected={isSelected}
            onArticleAttributeSelect={onSelectItem}
            repairTimesRoute={repairTimesRoute}
            onQuantyChnage={onRimQuantityChange}
            largeCheckbox={largeCheckbox}
        />
    )
}

export default RimArticle
