import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, Icon, Text } from "@tm/controls"
import { Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { initSelector, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { em, percent } from "csx"
import { useSelector } from "react-redux"
import { FC } from "react"
import { getBatteryVoltageIconName, getBatteryVoltageIconSkin, VOLTAGE_THRESHOLD_WARNING } from "../../../data/helpers"
import { getBundleParams } from "../../../data/params"
import { statusDataSelector } from "../../main/business"
import { Cell } from "../../shared"

type Props = RouteComponentProps<any> & {}

const selector = initSelector(statusDataSelector)

const MiscComponent: FC<Props> = ({ match }) => {
    const { translateText } = useLocalization()
    const [statusData] = useSelector(selector)

    if (!statusData) {
        return null
    }

    const { odometer, telematicsDeviceVoltage, odometerUnit, batteryUnit } = statusData

    const worktask = useWorkTask()
    const hasFastCalc = worktask?.workTask?.vehicle?.dataAvailabilities?.fastCalculator.hasAny

    const { batteryCalculationUrl } = getBundleParams()
    const modalUrl = batteryCalculationUrl && renderRoute(batteryCalculationUrl, { ...match.params, productGroupId: 1 })

    const handleOpenCalculation = () => {
        if (!modalUrl) {
            return
        }

        const route = renderRoute(modalUrl, { ...match.params, productGroupId: 1 })
        Morpheus.showView("1", route as string)
    }
    return (
        <div className={style.misc}>
            {odometer && (
                <Cell miscCell>
                    <div className={style.before} />
                    <Icon name="cylinder-raceflag" size="xl" />
                    <Text size="s">{translateText(1491)}</Text>
                    <Text modifiers={["strong"]}>{`${odometer} ${odometerUnit}`}</Text>
                </Cell>
            )}
            {telematicsDeviceVoltage && (
                <Cell miscCell>
                    <div className={style.before} />
                    <Tooltip placement="top" title={telematicsDeviceVoltage < VOLTAGE_THRESHOLD_WARNING ? translateText(934) : ""}>
                        <div>
                            <Icon name="battery" size="xl" />
                            <Icon
                                size="xl"
                                name={getBatteryVoltageIconName(telematicsDeviceVoltage)}
                                skin={getBatteryVoltageIconSkin(telematicsDeviceVoltage)}
                            />
                        </div>
                    </Tooltip>
                    <Text size="s">{translateText(12464)}</Text>
                    <Text modifiers={["strong"]}>{`${telematicsDeviceVoltage.format(2)} ${batteryUnit}`}</Text>
                    {telematicsDeviceVoltage < VOLTAGE_THRESHOLD_WARNING && !!batteryCalculationUrl && (
                        <Button icon="fastclick" onClick={handleOpenCalculation} disabled={!hasFastCalc}>
                            {translateText(935)}
                        </Button>
                    )}
                </Cell>
            )}
        </div>
    )
}

const style = useStyle({
    misc: {
        display: "flex",
        padding: 0,
    },
    before: {
        content: "",
        display: "block",
        width: percent(100),
        height: em(1),
        top: em(-2),
        position: "relative",
        background: "#dfe4e7",
    },
})(MiscComponent)

export default withRouter(MiscComponent)
