import { BonusSystemImage } from "@tm/controls"
import { Stack, Box, ArticleThumbnailImage } from "@tm/components"
import { BasketPart } from "../../../models"

type Props = {
    part: BasketPart
}

export default function ArticleThumbnail({ part }: Props) {
    const { partItem, bonusSystemsWithoutPoints } = part
    const { articleInformation, mainPartId } = partItem
    const { articleInfoType } = articleInformation
    const isLinkedItem = !!mainPartId

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            {bonusSystemsWithoutPoints?.map((bonusSystem) => (
                <Box
                    key={bonusSystem.id}
                    width="1em"
                    sx={{ transform: " rotate(-90deg) translateX(-100%)", transformOrigin: "right", ".image": { height: "30px", width: "60px" } }}
                >
                    <BonusSystemImage id={bonusSystem.id} name={bonusSystem.name} imageUrl={bonusSystem.imageUrl} />
                </Box>
            ))}
            <ArticleThumbnailImage isLinkedItem={isLinkedItem} articleInfoType={articleInfoType} thumbnailUrl={articleInformation.thumbnailUrl} />
        </Stack>
    )
}
