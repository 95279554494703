import { Suspense, useCallback, useEffect, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { createQueryString, parseQueryString, renderRoute, TmaHelper, useErpConfig } from "@tm/utils"
import { TabControl } from "@tm/controls"
import { ErpSystemConfigMode, UserModuleType, VehicleType } from "@tm/models"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { connectComponent } from "@tm/morpheus"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { BaseDetailsRouteProps } from "../../business"
import { DetailsNavigationState, Actions, NavigationItem } from "./business"
import { ArticleDetailsResponse } from "../../data/model"
import { bundleChannel } from "../../bundle-channel"
import { getBundleParams } from "../../utils"
import IndustrialInfoTab from "./components/industrial-info-tab"

type Props = {
    state: DetailsNavigationState
    tabs: { [key: string]: NavigationItem }
}

function DetailsNavigationComponent({ state, tabs }: Props) {
    const {
        detailsResponse,
        showAlternativeArticlesTab,
        showReplacementArticlesTab,
        showSafetyDataSheetsTab,
        showPawnArticlesTab,
        showSpecialProcurement,
    } = state
    const { push } = useHistory()
    const location = useLocation()
    const match = useRouteMatch<BaseDetailsRouteProps>()
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { workTask } = useWorkTask() ?? {}
    const { mode } = useErpConfig()

    const defaultTabIdentifier = "overview"
    const documentTypePdf = 2
    const navigateTo = useCallback(
        (tabIdentifier: string) => {
            let searchQuery = location.search
            let scrollTo
            if (tabIdentifier === "vehicles") {
                scrollTo = getFirstAvailableVehicleType(detailsResponse)
            }

            const { article } = detailsResponse ?? {}

            const hasPdf = detailsResponse?.documents?.some((document) => document.documentType === documentTypePdf)
            if (tabIdentifier === "documents" && hasPdf) {
                TmaHelper.ArticleDetailsPDFClick.Parts.PDFClick.Click(article, workTask?.vehicle?.id)
            }

            if (tabIdentifier === "overview") {
                TmaHelper.DetailsFormCall.Call(article, workTask?.vehicle?.id)
            }

            if (tabIdentifier === "mvc-article-feedback") {
                const queryParams = parseQueryString(searchQuery)
                const newQueryParams = {
                    ...queryParams,
                    supplierName: article?.supplier.name,
                    productGroupId: article?.productGroup.id,
                    supplierId: article?.supplier.id,
                    supplierArticleNo: article?.supplierArticleNo,
                }

                searchQuery = createQueryString(newQueryParams)
            }

            push(renderRoute(match.path, { ...match.params, partsDetailsSubPage: tabIdentifier, scrollTo }) + (searchQuery || ""))
        },
        [detailsResponse, match.path, match.params, location.search, push, workTask?.vehicle?.id]
    )

    useEffect(() => {
        return bundleChannel("DETAILS").subscribe("NAVIGATE", (data) => navigateTo(data.subPage))
    }, [navigateTo])

    useEffect(() => {
        if (!match.params.partsDetailsSubPage && defaultTabIdentifier === "overview") {
            TmaHelper.DetailsFormCall.Call(detailsResponse?.article, workTask?.vehicle?.id)
        }
    }, [])

    const industrialInfoValidIds = useMemo(() => {
        const mdmParam = userContext?.parameter.supplierInformationValidIds
        if (mdmParam && (typeof mdmParam === "string" || typeof mdmParam === "number")) {
            return typeof mdmParam === "number"
                ? [mdmParam]
                : mdmParam
                      .split(",")
                      .map((x) => parseInt(x))
                      .filter((x) => !!x)
        }
        return []
    }, [userContext?.parameter.supplierInformationValidIds])

    const filterNavigationItems = (item: NavigationItem): boolean => {
        const { article } = detailsResponse ?? {}
        const feedbackModule = userContext.modules?.find((x) => x.type === UserModuleType.Feedback)

        if (!detailsResponse || !article) {
            return false
        }

        const { requestErpInfo } = article

        switch (item.path) {
            case "references":
                if (article.references?.length) {
                    return true // detailsResponse.article.references.some((x) => x && visibleReferenceTypes.indexOf(x.referenceType) !== -1)
                }
                return false
            case "partslist":
                return article.isPartsListAvailable || false
            case "accessories":
                return article.isAccessoryListAvailable || false
            case "mainpartslist":
                return !!detailsResponse.partsListMainArticles?.length
            case "mainaccessories":
                return !!detailsResponse.accessoryListMainArticles?.length
            case "vehicles":
                return getVehicleCount(detailsResponse) > 0 && !userContext?.parameter.catalogLight
            case "alternative-articles":
                return (requestErpInfo && showAlternativeArticlesTab) || false
            case "replacement-articles":
                return (requestErpInfo && showReplacementArticlesTab) || false
            case "stocks":
                return (requestErpInfo && userContext.externalModules?.some((m) => m.isInfoEnabled)) || false
            case "documents":
                return !!detailsResponse.documents?.length
            case "formulars":
                return !!detailsResponse.externalDocuments?.length
            case "safety-data-sheet":
                return (requestErpInfo && showSafetyDataSheetsTab) || false
            case "pawn-articles":
                return (requestErpInfo && showPawnArticlesTab) || false
            case "partnersystems":
                return (requestErpInfo && mode === ErpSystemConfigMode.Partnersystems) || false
            case "article_notes":
                return !!getBundleParams().enableArticleNotes
            case "industrial-info":
                return industrialInfoValidIds.includes(article.supplier.id)
            case "special-procurement":
                return (requestErpInfo && showSpecialProcurement) || false
            case "mvc-article-feedback":
                return !!feedbackModule && !!getBundleParams().enableMVCFeedbackOnDetails
            default:
                break
        }

        return true
    }

    const getNavigationItemText = (item: NavigationItem): string | JSX.Element => {
        const configText = translateText(item.text)

        switch (item.path) {
            case "vehicles": {
                return `${configText} (${getVehicleCount(detailsResponse)})`
            }
            case "industrial-info": {
                if (detailsResponse?.article?.supplier.id && detailsResponse.article.supplier.name) {
                    const { id, name } = detailsResponse.article.supplier
                    return <IndustrialInfoTab supplierId={id} supplierName={name} active={match.params.partsDetailsSubPage === "industrial-info"} />
                }
                break
            }
            default:
                break
        }

        return configText
    }

    const renderNavigationItem = (item: NavigationItem) => {
        if (!item) {
            return
        }

        return (
            <TabControl.Tab identifier={item.path} key={`details${item.path ? `-${item.path}` : ""}`}>
                {getNavigationItemText(item)}
            </TabControl.Tab>
        )
    }

    const filteredTabs = Object.keys(tabs)
        .map((key) => tabs[key])
        .filter(filterNavigationItems)
        .orderBy((x) => (x.sort != null ? x.sort : 1000))

    if (!filteredTabs || !filteredTabs.length) {
        return null
    }

    return (
        <TabControl selectedTabIdentifier={match.params.partsDetailsSubPage || defaultTabIdentifier} onTabSelect={navigateTo}>
            {filteredTabs.map(renderNavigationItem)}
        </TabControl>
    )
}

function getVehicleCount(response?: ArticleDetailsResponse): number {
    if (!response) {
        return 0
    }
    return (response.referencedVehicleCount || 0) + (response.referencedBikesCount || 0) + (response.referencedTrucksCount || 0)
}

function getFirstAvailableVehicleType(response?: ArticleDetailsResponse): VehicleType {
    if (response?.referencedVehicleCount) {
        return VehicleType.PassengerCar
    }
    if (response?.referencedBikesCount) {
        return VehicleType.Motorcycle
    }
    if (response?.referencedTrucksCount) {
        return VehicleType.CommercialVehicle
    }
    return VehicleType.PassengerCar
}

function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <DetailsNavigationComponent {...props} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
