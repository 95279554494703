import { useWorkTask } from "@tm/context-distribution"
import { useLoadWorkTaskNote, useDeleteWorkTaskNote, useSaveWorkTaskNote } from "@tm/utils"
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from "react"
import { NoteTooltip } from "./note-tooltip"

const WorkTaskNoteTooltipComponent = memo(() => {
    const { workTaskId } = useWorkTask() ?? {}
    const { workTaskNote } = useLoadWorkTaskNote(workTaskId ? [workTaskId] : [])
    const { deleteWorkTaskNote } = useDeleteWorkTaskNote()
    const { saveWorkTaskNote } = useSaveWorkTaskNote()

    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [noteText, setNoteText] = useState<string>()

    const activeWorkTaskNote = useMemo(() => {
        return workTaskNote[0] ?? { text: "", noteId: workTaskId }
    }, [workTaskNote, workTaskId])

    const noteAvailable = !!activeWorkTaskNote.text

    useEffect(() => {
        setNoteText(activeWorkTaskNote.text)
    }, [activeWorkTaskNote])

    const onClickAway = useCallback(() => {
        setShowDialog(false)
        setNoteText(activeWorkTaskNote.text)
    }, [activeWorkTaskNote])

    const handleOnToggle = () => {
        setShowDialog(!showDialog)
    }

    const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNoteText(event.target.value)
    }

    const saveNote = useCallback(() => {
        if (activeWorkTaskNote.text !== noteText && noteText) {
            saveWorkTaskNote({ ...activeWorkTaskNote, text: noteText })
        }
    }, [activeWorkTaskNote, noteText, saveWorkTaskNote])

    const deleteNote = () => {
        if (workTaskNote[0]) {
            deleteWorkTaskNote(activeWorkTaskNote.noteId)
            setNoteText("")
        }
    }

    return (
        <NoteTooltip
            noteText={noteText}
            savedNoteText={activeWorkTaskNote.text}
            addNoteIcon="add-note"
            editNoteIcon="note"
            deleteNoteIcon="delete"
            showEditOptions={showDialog}
            noteAvailable={noteAvailable}
            onClickAway={onClickAway}
            handleOnToggle={handleOnToggle}
            handleNoteChange={handleNoteChange}
            saveNote={saveNote}
            deleteNote={deleteNote}
        />
    )
})

export default WorkTaskNoteTooltipComponent
