import { css, getStyleTheme, StyleProps, withStyle } from "@tm/context-distribution"
import { Loader, PanelSection } from "@tm/controls"
import { bindSpecialReactMethods } from "@tm/utils"
import { Component } from "react"
import { ESufficentSelections } from "../../../../data/enums"
import { bem, connector } from "../../../../data/helpers"
import { Criteria } from "../../../../data/models"
import { MainState } from "../../../main"
import { MonoselectFilters } from "../../../_shared"
import { Actions, DetailsState, IActions } from "../../business"

type Props = StyleProps<typeof stylesheet> &
    StoreProps & {
        actions: IActions
    }

type StoreProps = {
    loading?: boolean
    selectedCriterias: Criteria[]
    criteriasItems: Criteria[]
    sufficientSelection?: ESufficentSelections
}

class VKNCriterias extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleVknCriteriaChange(criteria: Criteria) {
        const { actions, selectedCriterias } = this.props
        // TODO
        actions.updateVknCriterias(criteria, false)
        // actions.loadVKNImages()
        if (!selectedCriterias.find((x) => x.code == criteria.code)) {
            actions.loadImagePositions()
        }
    }

    handleVknCriteriaReset(group: string) {
        const { actions } = this.props
        actions.resetVknCriteriaGroup(group)
        // actions.loadVKNImages()
    }

    render() {
        const { criteriasItems, loading, style, selectedCriterias } = this.props

        if (loading && !criteriasItems.length) {
            return (
                <div className={bem(style.filters, "loading")}>
                    <Loader />
                </div>
            )
        }

        return (
            <div className={bem(style.filters, loading && "covered")}>
                {!!criteriasItems.length && (
                    <PanelSection className={style.panelSection}>
                        <MonoselectFilters
                            criterias={criteriasItems}
                            onChange={this.handleVknCriteriaChange}
                            selectedCriterias={selectedCriterias}
                            onReset={this.handleVknCriteriaReset}
                        />
                    </PanelSection>
                )}
            </div>
        )
    }
}

function mapStateToProps({ details: state }: MainState): StoreProps {
    return {
        loading: state.criterias.loading,
        criteriasItems: state.criterias.vkn,
        selectedCriterias: state.selectedCriterias,
        sufficientSelection: state.vknImages.sufficientSelection,
    }
}

function stylesheet() {
    const theme = getStyleTheme()
    return css({
        filters: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            // marginRight: theme.margin.m,
            $nest: {
                "&--loading": {
                    justifyContent: "center",
                    alignItems: "center",
                },
                "&--covered": {
                    pointerEvents: "none",
                    opacity: theme.opacity.disabled,
                },
            },
        },
        panelSection: {
            padding: theme.margin.s,
        },
    })
}

export default connector(withStyle(stylesheet, VKNCriterias), mapStateToProps, Actions)
