import { Button, Tooltip } from "@tm/components"
import { OE } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useCallback } from "react"

type OeNumberProps = {
    oeNumber: OE.OeNumber
    onSelect(oeNumber: OE.OeNumber): void
    isSelected?: boolean
    hasSelectedReplacement?: boolean
    hideDescription?: boolean
}

export function OeNumber(props: OeNumberProps) {
    const { translateText } = useLocalization()
    const { oeNumber, onSelect, isSelected, hasSelectedReplacement, hideDescription } = props
    const { number, description, additionalDescription } = oeNumber

    const value = description && !hideDescription ? [description, number] : [number]

    const handleClick = useCallback(() => (!isSelected || hasSelectedReplacement) && onSelect(oeNumber), [isSelected, oeNumber, onSelect])

    const button = (
        <Button
            variant={!isSelected || hasSelectedReplacement ? "outlined" : "contained"}
            color={isSelected ? "primary" : "inherit"}
            onClick={handleClick}
        >
            {value.join(" | ")}
            {additionalDescription && !hideDescription && (
                <>
                    {" "}
                    | <strong>{additionalDescription}</strong>
                </>
            )}
        </Button>
    )

    if (!isSelected || hasSelectedReplacement) {
        return <Tooltip title={translateText(1813).replace("{0}", translateText(758))}>{button}</Tooltip>
    }

    return button
}
