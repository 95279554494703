import { useLocalization } from "@tm/localization"
import { EFilterNames } from "@tm/models"
import { Box, Button, Icon, styled, Typography } from "@tm/components"
import { SpecialCarTypes } from "../../data/enums"
import { Statics } from "../../data/statics"
import { useWidgetFilters } from "../../hooks/widgetFilters"
import { CarTypeSeasonFilters } from "../../components/_shared/carTypeSeasonFilters"
import { DiameterFilters } from "../../components/_shared/DiameterFilters"

export default function TyresSearch() {
    const { translateText } = useLocalization()

    const {
        state: { loading, filters, selectedFilters, selectedFiltersDefaultVehicle },
        handlers: { handleFilterChange, handleResetAll, handleNormalSearch },
    } = useWidgetFilters()

    return (
        <StyledWidgetBox>
            <StyledWrapperBox>
                <Typography verticaAlign="sub">{translateText(1562)}</Typography>
                <StyledSeparator />
                <CarTypeSeasonFilters
                    filterName={EFilterNames.carType}
                    disabled={loading}
                    items={Statics.getCarTypes()}
                    selectedItemValue={selectedFiltersDefaultVehicle ?? selectedFilters?.carType}
                    onChange={handleFilterChange}
                />

                <StyledSeparator />
                <CarTypeSeasonFilters
                    filterName={EFilterNames.season}
                    disabled={loading || selectedFilters?.carType.every((x) => SpecialCarTypes.includes(+x.query))}
                    items={Statics.seasons}
                    selectedItemValue={selectedFilters.season}
                    onChange={handleFilterChange}
                />

                <StyledSeparator />
                <StyledFiltersBox loading={loading}>
                    <DiameterFilters
                        widths={filters.width}
                        heights={filters.height}
                        inches={filters.inch}
                        selectedHeight={selectedFilters.height}
                        selectedInch={selectedFilters.inch}
                        selectedWidth={selectedFilters.width}
                        onChange={handleFilterChange}
                        loading={loading}
                        onResetAll={handleResetAll}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            color="highlight"
                            startIcon={<Icon name="search" />}
                            onClick={handleNormalSearch}
                            disabled={!selectedFilters.width || !selectedFilters.inch}
                        >
                            {translateText(135)}
                        </Button>
                    </Box>
                </StyledFiltersBox>
            </StyledWrapperBox>
        </StyledWidgetBox>
    )
}

const StyledWidgetBox = styled(Box)({
    backgroundImage: "url('/styles/base/images/bitmap-copy.png')",
    backgroundPositionY: "2em",
    backgroundRepeat: "no-repeat",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
})

const StyledWrapperBox = styled(Box)({
    margin: "0 13em",
    width: "25em",
})

const StyledFiltersBox = styled(Box)<{ loading?: boolean }>(({ theme, loading }) => ({
    display: "flex",
    flexDirection: "column",

    opacity: loading ? theme.opacity?.disabled : "1",
    pointerEvents: loading ? "none" : "auto",
    "& .dropdown": {
        position: "static",
        "& .popover": {
            top: "31.7em",
            width: "8em",
        },
    },
}))

const StyledSeparator = styled("hr")(({ theme }) => ({
    border: 0,
    opacity: theme.opacity?.secondary,
    height: 0,
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
}))
