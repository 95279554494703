import { AsyncAction } from "@tm/morpheus"
import { clone } from "@tm/utils"
import { batch } from "react-redux"
import { SummaryState } from "."
import { BundleActionTypes } from "../../../business"
import * as Repositories from "../../../data"
import { EurotaxSelectionSteps } from "../../../data/enums"
import { Statics } from "../../../data/helpers"
import { ErePosition, MmtGroup } from "../../../data/models"
import { MainActions, MainActionsType, MainState } from "../../main"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "EUROTAX_CARS_LOADING" }
    | { type: "EUROTAX_CARS_ERROR" }
    | { type: "EUROTAX_CARS_LOADED"; payload: { groups: MmtGroup[]; usedTecDoc: number } }
    | { type: "SELECT_ERE_POSITION"; payload: { position: ErePosition; group?: MmtGroup } }

const DEFAULT_STATE: SummaryState = {
    cars: {
        groups: [],
    },
}

export function reduce(state = clone(DEFAULT_STATE), action: MainActionsType): SummaryState {
    switch (action.type) {
        case "EUROTAX_CARS_LOADING": {
            return {
                ...state,
                cars: {
                    ...state.cars,
                    groups: [],
                    loading: true,
                    error: false,
                },
            }
        }
        case "EUROTAX_CARS_ERROR": {
            return {
                ...state,
                cars: {
                    ...state.cars,
                    groups: [],
                    loading: false,
                    error: true,
                },
            }
        }
        case "EUROTAX_CARS_LOADED": {
            const selectedPosition =
                !state.selectedMmtGroup?.selectedErePosition &&
                action.payload.groups.length == 1 &&
                action.payload.groups[0].positions.find((x) => x.parentName == Statics.defaultGraphicParts)
            return {
                ...state,
                cars: {
                    ...state.cars,
                    groups: action.payload.groups,
                    usedTecDoc: action.payload.usedTecDoc,
                    loading: false,
                    error: false,
                },
                ...(selectedPosition && {
                    selectedMmtGroup: {
                        ...action.payload.groups[0],
                        selectedErePosition: selectedPosition,
                    },
                }),
            }
        }
        case "SELECT_ERE_POSITION": {
            const { group, position } = action.payload
            const usedGroup = group ?? state.selectedMmtGroup
            return {
                ...state,
                ...(usedGroup && {
                    selectedMmtGroup: {
                        ...usedGroup,
                        selectedErePosition: position,
                    },
                }),
            }
        }
        case "INIT_WIDGET_SEARCH": {
            const { erePosition, groups } = action.payload
            return {
                ...state,
                selectedMmtGroup: {
                    ...action.payload.selectedMmtGroup,
                    selectedErePosition: erePosition,
                },
                cars: {
                    groups,
                },
            }
        }
        case "RESET_LOCAL_STORE": {
            return DEFAULT_STATE
        }
    }
    return state
}

function loadEurotaxCars(): AsyncAction<MainActionsType, MainState> {
    return (dispatch, getState) => {
        const {
            summary: { cars },
            manager: { vehicle },
        } = getState()
        if (vehicle?.tecDocTypeId == cars.usedTecDoc) {
            return
        }

        dispatch({ type: "EUROTAX_CARS_LOADING" })

        if (vehicle && vehicle.tecDocTypeId) {
            Repositories.getCarInfo(vehicle.tecDocTypeId).then(
                (response) => {
                    // batch(() => {
                    dispatch({ type: "EUROTAX_CARS_LOADED", payload: { groups: response.mmtGroups, usedTecDoc: vehicle.tecDocTypeId } })

                    if (response.mmtGroups.length == 1 && response.mmtGroups[0].positions.find((x) => x.parentName == Statics.defaultGraphicParts)) {
                        batch(() => {
                            dispatch(sendDataToEurotaxDetails())
                            dispatch(MainActions.loadVKNImages())
                            dispatch(MainActions.changeStep(EurotaxSelectionSteps.DETAILS))
                        })
                    }
                    // })
                },
                (_error) => dispatch({ type: "EUROTAX_CARS_ERROR" })
            )
        }
    }
}

function selectErePosition(group: MmtGroup, position: ErePosition): MainActionsType {
    return { type: "SELECT_ERE_POSITION", payload: { group, position } }
}

function sendDataToEurotaxDetails(): AsyncAction<MainActionsType, MainState> {
    return (dispatch, getState) => {
        dispatch({
            type: "SEND_DATA_TO_EUROTAX_DETAILS",
            payload: { selectedMmtGroup: getState().summary.selectedMmtGroup!, vehicle: getState().manager.vehicle },
        })
    }
}
export type IActions = typeof Actions

export const Actions = {
    loadEurotaxCars,
    selectErePosition,
    sendDataToEurotaxDetails,
}
