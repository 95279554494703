import { Box, Button, Checkbox, FormControlLabel, Icon, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ChangeEvent, useEffect, useState } from "react"
import { uniqueId } from "@tm/utils"
import EmailEntry from "./EmailEntry"

type EmailListProps = {
    onEmailsChanged: (emails: string[]) => void
    onSendEmailCopy?: (sendCopy: boolean) => void
}

export default function EmailList({ onEmailsChanged, onSendEmailCopy }: EmailListProps) {
    const { translateText } = useLocalization()
    const customer = useWorkTask()?.workTask?.customer

    const [emails, setEmails] = useState<{ id: string; email: string }[]>(
        customer?.email ? [{ id: "0", email: customer.email }] : [{ id: "0", email: "" }]
    )

    const emailChanged = (id: string, email: string) => {
        const editedEmails = emails.map((e) => {
            if (e.id === id) {
                e.email = email
            }

            return e
        })

        setEmails(editedEmails)
    }

    const handleAddEmail = () => {
        const id = uniqueId()
        setEmails([...emails, { id, email: "" }])
    }

    const handleCheckToggle = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onSendEmailCopy?.(checked)
    }

    useEffect(() => {
        onEmailsChanged(emails.filter((e) => e.email !== "").map((e) => e.email))
    }, [emails, onEmailsChanged])

    return (
        <Box display="flex" flexDirection="column" gap="0.5em">
            <Typography variant="body1" fontWeight="medium">
                {translateText(13777)}
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr">
                <Box display="flex" flexDirection="column" gap="0.5em">
                    {emails.map((email) => {
                        return <EmailEntry key={email.id} id={email.id} onEmailModified={emailChanged} startingEmail={email.email} />
                    })}
                </Box>
                <Box display="flex" justifyContent="flex-start">
                    <Button
                        variant="outlined"
                        size="medium"
                        startIcon={<Icon name="add" />}
                        onClick={handleAddEmail}
                        sx={{ marginLeft: "0.2em", marginRight: "0.2em", alignSelf: "end" }}
                    />
                </Box>
            </Box>
            <FormControlLabel control={<Checkbox onChange={handleCheckToggle} />} label={translateText(13824)} />
        </Box>
    )
}
