import { styled, Box, LinearProgress, linearProgressClasses } from "@tm/components"
import { Headline } from "@tm/controls"
import { useLocalization } from "@tm/localization"

const Wrapper = styled(Box)(({ theme }) => ({
    [`& .${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: `${theme.colors?.primary}80`,
    },
    [`& .${linearProgressClasses.bar1Determinate}`]: {
        backgroundColor: theme.colors?.primary,
    },
}))

type Props = {
    currentStep: number
    totalSteps: number
}

const calculateProgres = (intialValue: number, totalValue: number): number => {
    return (intialValue / totalValue) * 100
}

export function DisplayStepLoader({ currentStep, totalSteps }: Props) {
    const { translateText } = useLocalization()
    return (
        <Wrapper>
            <LinearProgress variant="determinate" value={calculateProgres(currentStep, totalSteps)} />
            <Headline>{`${translateText(13912)} ${currentStep} / ${totalSteps}`}</Headline>
        </Wrapper>
    )
}
