import { Button, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FittingPosition } from "@tm/models"
import { Tooltip } from "@tm/components"
import { FC } from "react"
import { Models } from "../../../data"
import { FiltersChangedPayload } from "../../../business"
import { ArticleAttributeFilter } from "../../../data/model"
import { ListState, IActions } from "../business"

type Props = {
    actions: IActions
    state: ListState
}

export const NoResult: FC<Props> = (props) => {
    const { state, actions } = props
    const {
        filters: { articleAttributes: knownFilters },
        usedFilters: { articleAttributes: activeFilters },
    } = state
    const { translateText } = useLocalization()

    const isFilterSelected = (query: any, filterType: string, isSelected: boolean | undefined, name?: string): boolean => {
        switch (filterType) {
            case "productGroup":
                if (state.usedFilters.productGroupIds.includes(query)) {
                    return true
                }
                break
            case "supplier":
                if (state.usedFilters.supplierIds.includes(query)) {
                    return true
                }
                break
            case "constructionYear":
                if (state.usedFilters.constructionYear === query) {
                    return true
                }
                break
            case "oeReference":
                if (state.usedFilters.oeReferenceNos.includes(name || query)) {
                    return true
                }
                break
            default:
                return !!isSelected
        }
        return false
    }

    const getFittingPosition = (): string => {
        let { fittingPosition } = state.usedFilters
        const fittingPositions: number[] = []

        while (fittingPosition !== 0) {
            let n = 1
            while (n * 2 <= fittingPosition) {
                n *= 2
            }
            fittingPositions.push(n)
            fittingPosition -= n
        }

        let fittingPositionMessage = ""
        // sorts fitting positions in ascending order
        fittingPositions.sort((a, b) => {
            return a - b
        })

        for (let i = 0; i < fittingPositions.length; i++) {
            switch (fittingPositions[i]) {
                case FittingPosition.FrontAxle:
                    fittingPositionMessage += translateText(353)
                    break
                case FittingPosition.RearAxle:
                    fittingPositionMessage += translateText(354)
                    break
                case FittingPosition.Front:
                    fittingPositionMessage += translateText(1906)
                    break
                case FittingPosition.Rear:
                    fittingPositionMessage += translateText(1907)
                    break
                case FittingPosition.Left:
                    fittingPositionMessage += translateText(355)
                    break
                case FittingPosition.Right:
                    fittingPositionMessage += translateText(356)
                    break
                case FittingPosition.Top:
                    fittingPositionMessage += translateText(1908)
                    break
                case FittingPosition.Bottom:
                    fittingPositionMessage += translateText(1909)
                    break
                case FittingPosition.Inside:
                    fittingPositionMessage += translateText(1910)
                    break
                case FittingPosition.Outside:
                    fittingPositionMessage += translateText(1911)
                    break
                case FittingPosition.WheelSided:
                    fittingPositionMessage += translateText(1912)
                    break
                case FittingPosition.GearboxSided:
                    fittingPositionMessage += translateText(1913)
                    break
                default:
            }
            if (!(i + 1 === fittingPositions.length)) {
                fittingPositionMessage += ", "
            }
        }
        return fittingPositionMessage
    }

    const renderDeselectFilterButton = (deselectFilter: () => void, value: string, name?: string | undefined, unit?: string, text?: string) => {
        return (
            <div className="labeled-dropdown" key={value}>
                <Button
                    scaleIcon
                    skin="highlight"
                    layout={["iconRight"]}
                    appendItem={<Button fakeButton layout={["ghost"]} icon="close" className="labeled-dropdown__icon" />}
                    size="xs"
                    onClick={deselectFilter}
                >
                    {name && (
                        <Text
                            size={!text && !unit ? "s" : "xs"}
                            modifiers={!text && !unit ? ["block"] : ["sub", "block"]}
                            className="labeled-dropdown__label"
                        >
                            {name}&nbsp;
                        </Text>
                    )}

                    {(!!text || !!unit) && (
                        <Text size="s" modifiers={["block"]}>
                            {text + (unit ? ` ${unit}` : "")}
                        </Text>
                    )}
                </Button>
            </div>
        )
    }

    const createFilterButtons = (
        filter: Array<Models.ProductGroupFilter> | Array<Models.DataSupplierFilter> | Array<Models.ConstructionYearFilter>,
        filterButtons: JSX.Element[],
        filterType: string
    ): JSX.Element[] => {
        const payload: FiltersChangedPayload = {}

        for (let i = 0; i < filter.length; i++) {
            if (
                isFilterSelected(filter[i].id, filterType, filter[i].isSelected, filter[i].name) ||
                filterType === "availability" ||
                filterType === "fittingPosition"
            ) {
                switch (filterType) {
                    case "productGroup":
                        payload.productGroupIds = []
                        payload.productGroupIds?.push(filter[i].id)
                        filterButtons.push(
                            renderDeselectFilterButton(
                                actions.deselectProductGroups.bind(this, filter[i].id),
                                filter[i].id.toString(),
                                filter[i].name
                            )
                        )
                        break
                    case "supplier":
                        payload.supplierIds = []
                        payload.supplierIds?.push(filter[i].id)
                        filterButtons.push(
                            renderDeselectFilterButton(actions.deselectSupplier.bind(this, filter[i].id), filter[i].id.toString(), filter[i].name)
                        )
                        break
                    case "constructionYear":
                        payload.constructionYear = filter[i].id
                        filterButtons.push(
                            renderDeselectFilterButton(
                                actions.deselectConstructionYear.bind(this, filter[i].id),
                                filter[i].id.toString(),
                                filter[i].name
                            )
                        )
                        break
                    case "availability":
                        filterButtons.push(renderDeselectFilterButton(actions.deselectAvailability, "", translateText(412)))
                        return filterButtons
                    case "fittingPosition":
                        filterButtons.push(renderDeselectFilterButton(actions.deselectFittingPosition, "", getFittingPosition()))
                        return filterButtons
                    default:
                        return []
                }
            }
        }

        return filterButtons
    }

    let filterButtons: JSX.Element[] = []

    const mapped: Array<ArticleAttributeFilter> = []
    activeFilters.forEach((query) => {
        const match = knownFilters.find((filter) => filter.query === query)
        if (match) {
            mapped.push(match)
        }
    })

    mapped.forEach((filter) => {
        filterButtons.push(
            renderDeselectFilterButton(
                actions.deselectArticleAttribute.bind(this, filter.query),
                filter.query,
                filter.name || filter.description,
                filter.unit,
                filter.text
            )
        )
    })

    filterButtons = createFilterButtons(state.filters.constructionYear, filterButtons, "constructionYear")
    filterButtons = createFilterButtons(state.filters.productGroups, filterButtons, "productGroup")
    filterButtons = createFilterButtons(state.filters.suppliers, filterButtons, "supplier")
    if (state.usedFilters.availability) {
        filterButtons = createFilterButtons(state.filters.suppliers, filterButtons, "availability")
    }
    if (state.usedFilters.fittingPosition !== 0) {
        filterButtons = createFilterButtons(state.filters.suppliers, filterButtons, "fittingPosition")
    }

    return (
        <div className="article-list__no-result__filters product-group-filter-selection">
            {filterButtons.map((x) => {
                return x
            })}
            {!!mapped.length && (
                <Tooltip title={translateText(655)}>
                    <Button icon="remove-filter" layout={["ghost"]} onClick={actions.resetArticleAttributes} size="xl" />
                </Tooltip>
            )}
        </div>
    )
}
