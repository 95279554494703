import { Container } from "@tm/nexus"
import { RegisteredModels } from "@tm/models"
import { getBundleParams } from "../utils"
import * as BasketOrderRepository from "./repositories/basket/order"
import { hasRepairTimesNexus, getHasRepairTimesForProductGroup } from "./repositories/basket/works"
import * as BasketCostEstimationRepository from "./repositories/basket/CostEstimation"

export * from "./repositories/basket/CostEstimation"
export * from "./repositories/basket/FastCalculation"
export * from "./repositories/basket/order"
export * from "./repositories/basket/parts"
export * from "./repositories/basket/RepairEstimation"
export * from "./repositories/basket/ThirdPartyBasket"
export * from "./repositories/basket/works"
export * from "./repositories/basket/Basket"
export * from "./repositories/custom-items/articles"
export * from "./repositories/custom-items/repair-times"
export * from "./repositories/erp"
export * from "./repositories/repairTimes"
export * from "./repositories/viewState"

export function registerActions() {
    Container.register({
        name: RegisteredModels.Basket_HasRepairTimes,
        modelActions: {
            load: hasRepairTimesNexus,
        },
    })
    Container.register({
        name: RegisteredModels.Basket_HasRepairTimesForProductGroup,
        modelActions: {
            load: getHasRepairTimesForProductGroup,
        },
    })
    Container.register({
        name: RegisteredModels.Basket_Order_ShowItem,
        modelActions: {
            load: BasketOrderRepository.showItem,
        },
    })
    // TODO: Since costEstimation is now part of the basket bundle, could the registered models that start with Basket_CostEstimation be removed from nexus?
    Container.register({
        name: RegisteredModels.Basket_CostEstimation_ShowTotalNumbersByWorkTask,
        modelActions: {
            load: BasketCostEstimationRepository.showTotalNumbersByWorkTask,
        },
    })
}

export function getBasketServiceUrl(): string {
    return getBundleParams().basketServiceUrl
}

export function getDmsServiceUrl(): string {
    return getBundleParams().dmsServiceUrl
}
