import { Box, Button, styled, Typography, VehicleImage } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { bem, useModelDetails } from "@tm/utils"
import { useSelector } from "react-redux"
import { VehicleType } from "@tm/models"
import { MainState } from "../../main"

type Props = {
    handleVehicleRedirect: () => void
}

export function VehicleDetails({ handleVehicleRedirect }: Props) {
    const { translateText } = useLocalization()
    const vehicle = useSelector((state: MainState) => state.manager.vehicle)
    const isIcon = vehicle?.vehicleType === VehicleType.CommercialVehicle || vehicle?.vehicleType === VehicleType.Motorcycle
    const details = useModelDetails({
        modelId: vehicle?.tecDocTypeId,
        vehicleType: vehicle?.vehicleType,
        registrationNoTypeId: vehicle?.registrationTypeId,
    })

    if (!vehicle) {
        return <Box style={{ minWidth: "18em" }} />
    }

    return (
        <Box style={{ minWidth: "18em", padding: "1.5em" }}>
            <Typography variant="h3">{translateText(453)}</Typography>
            <VehicleBox>
                <StyledImageBox>
                    <VehicleImage
                        modelImage={vehicle.modelThumbnail ?? ""}
                        modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                        vehicleType={vehicle.vehicleType || 1}
                        className={bem(isIcon ? "" : "reverse")}
                        style={{ height: "2em" }}
                    />
                </StyledImageBox>
                <Typography>{`${vehicle.manufacturer} ${vehicle.modelSeries.replace(/\(.*?\)/g, "").trim()}`}</Typography>
            </VehicleBox>
            <Box display="flex">
                <StyledDetail paddingRight="0.5em">
                    <Typography color="GrayText" variant="body2">
                        {translateText(74)}
                    </Typography>
                    <Typography color="GrayText" variant="body2">
                        {translateText(127)}
                    </Typography>
                </StyledDetail>
                <StyledDetail>
                    <Typography fontWeight="bold" variant="body2">{`${vehicle.model}`}</Typography>
                    <Typography
                        fontWeight="bold"
                        variant="body2"
                    >{`${details?.modelDetails?.constructionYearFrom?.month}/${details?.modelDetails?.constructionYearFrom?.year} - ${details?.modelDetails?.constructionYearTo?.month}/${details?.modelDetails?.constructionYearTo?.year}`}</Typography>
                </StyledDetail>
            </Box>
            <Button onClick={handleVehicleRedirect} style={{ margin: "1em 0.5em" }}>
                {translateText(13932)}
            </Button>
        </Box>
    )
}

const VehicleBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    padding: "0.5em",
})

const StyledImageBox = styled(Box)({
    width: "25%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "0.5em",
})

const StyledDetail = styled(Box)({
    display: "flex",
    flexDirection: "column",
})
