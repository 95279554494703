import { useLayoutEffect, useMemo, useState } from "react"
import { QueryFunctionContext, QueryStatus } from "react-query"
import { Advertisement, WholesalerArticle } from "@tm/models"
import { OffersArticleData, OffersListParams } from "../../../models"
import { QUERY_KEY, QueryKey, replaceArticlesQuantityWithSuggestedQuantity, useArticlesBase, usePartsViewOptions } from "../../../hooks"
import { getArticleGroups } from "../../../helpers"
import { DefaultArticleListPageSize, getBundleParams } from "../../../../../utils"
import { fetchArticles, OfferArticleRequest } from "./fetchArticles"

export function useArticles(params: OffersListParams, isEnabled: boolean, advertisements: Advertisement[] | undefined): OffersArticleData {
    const { startParams } = params
    const {
        partsViewSettings: { quantitySuggestionEnabled },
    } = usePartsViewOptions()

    const [wholesalerArticles, setWholesalerArticles] = useState<WholesalerArticle[] | undefined>(undefined)

    const request = useMemo<OfferArticleRequest | null>(() => {
        if (!isEnabled || !startParams.advertisementCategory?.id) {
            return null
        }

        return {
            pageSize: getBundleParams().articleListPageSize ?? DefaultArticleListPageSize,
            advertisements: advertisements ?? [],
        }
    }, [isEnabled, startParams.advertisementCategory?.id, advertisements])

    const [requestStatus, setRequestStatus] = useState<QueryStatus>("idle")

    const articlesBase = useArticlesBase(
        request,
        async ({ queryKey, pageParam, meta }: QueryFunctionContext<QueryKey<OfferArticleRequest>, number>) => {
            const { data, wholesalerParts, nextPageIndex } = await fetchArticles({ queryKey, pageParam, meta })

            setWholesalerArticles(wholesalerParts)

            return { data, nextPageIndex }
        },
        QUERY_KEY,
        quantitySuggestionEnabled ? replaceArticlesQuantityWithSuggestedQuantity : undefined
    )
    const { isSuccess, articles, hasNextPage } = articlesBase

    const articleGroups = useMemo(() => getArticleGroups(articles, hasNextPage), [articles, hasNextPage])

    useLayoutEffect(
        function waitUntilRendered() {
            if (isSuccess) {
                setRequestStatus("success")
            }
        },
        [isSuccess]
    )

    return {
        ...articlesBase,
        isEnabled,
        isFrontendFiltered: false,
        requestStatus,
        articleGroups,
        wholesalerArticles,
    }
}
