import { useStyle } from "@tm/context-distribution"
import { Button, ButtonProps, Loader } from "@tm/controls"
import { classes } from "@tm/utils"
import { FC } from "react"

/**
 * @memberof Basket
 * @todo move to @tm/controls - already exists (pretty much the same) in DMS bundle */
export const ButtonWithLoader: FC<ButtonProps & { loading?: boolean }> = (props) => {
    return (
        <Button {...props} icon={props.loading ? undefined : props.icon} className={classes(props.className, styles.button)}>
            {props.loading && <Loader visible delay={0} className={styles.loader} />}
            {props.children}
        </Button>
    )
}

const styles = useStyle({
    button: {
        $nest: {
            ".btn__content": {
                display: "flex",
                alignItems: "center",
            },
        },
    },
    loader: {
        width: "24px",
        height: "24px",
        margin: "-8px 0.5em -8px -2px",
    },
})(ButtonWithLoader)
