import { useFastServiceStore } from "@bundles/fast-service/data"
import { Box } from "@tm/components"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { AmountField, AmountItem, Button } from "@tm/controls"
import { Article, IMicros, RequestArticleDetailsPayload, channel } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { bem } from "@tm/utils"
import { useMemo, useState } from "react"

type Props = {
    vehicleId: string | undefined
    article: Article
    disableReplace: boolean
    handleQuantityChange: (item: AmountItem) => void
    handleShowPartAlternative: () => void
}

export default function ArticleComponent({ vehicleId, article, disableReplace, handleQuantityChange, handleShowPartAlternative }: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const inputsLocked = useFastServiceStore((state) => state.inputsLocked)

    const [opened, setOpened] = useState(false)
    const style = useMemo(() => getStyle(), [])

    function handleToggleOpened() {
        setOpened((prev) => !prev)
    }

    const handelArticleDetails = (request: RequestArticleDetailsPayload) => {
        channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
    }
    const renderActions = () => {
        return (
            <>
                <AmountField value={article.quantity} onChange={handleQuantityChange} disabled={inputsLocked} />
                <Button className={style.button} icon="replace" skin="highlight" onClick={handleShowPartAlternative} disabled={disableReplace || inputsLocked} />
            </>
        )
    }

    return (
        <Box width="100%">
            {renderMicro?.("parts", "part-item", {
                hiddenCells: {
                    additionalButtons: true,
                    additionalActions: true,
                    documents: true,
                },
                part: article,
                vehicleId,
                className: bem(style.actions, Morpheus.getParams("parts")?.templates?.articleItem?.bundle),
                shouldLoadVehicleRecords: false,
                isCompact: !opened,
                showActions: true,
                showArticleImage: true,
                onRequestArticleDetails: handelArticleDetails,
                onToggleCollapse: handleToggleOpened,
                renderBuyActions: renderActions,
                canFilterArticleAttributes: false,
                renderCustomErpInformation: () => null,
            })}
        </Box>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        actions: {
            flex: 1,
            $nest: {
                "&--wm .article-actions button.price": {
                    marginRight: "0.5em",
                    width: "6.25em",
                },
                "> :not(:last-child)": {
                    marginRight: ".25em",
                },
                ".article-actions_price": {
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: ".5",
                    paddingRight: "1.5em",
                    fontSize: theme.font.subtitleSize.m,

                    $nest: {
                        ".text": {
                            padding: 0,
                            color: theme.colors.primary,
                            fontWeight: theme.font.boldWeight,
                        },
                    },
                },
            },
        },
        button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "6em",
            marginLeft: "auto",
            marginRight: "unset",
        },
    })(ArticleComponent)
}
