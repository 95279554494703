import { Box, Button, CloseOutlined, Dialog, styled, Typography } from "@tm/components"
import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { Icon, IFrame } from "@tm/controls"
import { Authority } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { channel, GetMemoImportantNoteResponse, RegisteredModels } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { classes, decodeUniqueId, renderRoute, RouteComponentProps, useKeyValue, useMessage, withRouter } from "@tm/utils"
import { margin, percent, px } from "csx"
import { useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { getBundleParams } from "../../utils"
import { LoginRequest } from "../../data"
import { buildBaseRequestParameters, compareAndSetRequestUrl } from "../../data/helpers/common"
import { WrapperFrame } from "../_shared/wrapperFrame"
import { Actions, AutodataState } from "./business"
import { getWorktaskId } from "../../data/helpers"

type RouteProps = {
    workTaskId: string
}

type Props = RouteComponentProps<RouteProps> & {
    id: string
    loginUrl: string
    loginRequest: LoginRequest
    className?: string
}

function AutodataFrame({ id, loginRequest, loginUrl, match: { params }, className }: Props) {
    const { languageId, translateText } = useLocalization()
    const workTaskId = getWorktaskId() ?? decodeUniqueId(params.workTaskId ?? "")
    const actions = useActions(Actions)
    const userContext = useUser()?.userContext
    const vehicle = useWorkTask()?.workTask?.vehicle
    const { isLoading: isDemoLoading } = Authority.useGetActivatableModules(userContext)
    const parameters = useSelector((s: AutodataState) => s.parameters)

    const [loading, setLoading] = useState(true)
    const [loadingMissingDataFrame, setLoadingMissingDataFrame] = useState(true)
    const [url, setUrl] = useState("")
    const [vehicleModelId, setVehicleModelId] = useState("")
    const [feedbackUrl, setFeedbackUrl] = useState()
    const mounted = useRef(false)

    const { memoToolEnabled, comparePage } = getBundleParams()
    const [memoImportantNoteJson, setMemoImportantNoteJson] = useKeyValue({
        key: `MEMOTOOL_IMPORTANTNOTICE_${vehicle?.id}`,
        ownedByRepairShop: false,
    })
    const [memoImportantNote, setMemoImportantNote] = useState<GetMemoImportantNoteResponse | null>(
        memoImportantNoteJson && JSON.parse(memoImportantNoteJson)
    )
    const [showMemoDialog, setShowMemoDialog] = useState(false)
    const [showMissingDataDialog, setShowMissingDataDialog] = useState(true)
    const [, setResize] = useState<number | undefined>()

    const getDomainRegex = new RegExp(/.+?(?=Sub)/g)
    const domain = loginUrl.match(getDomainRegex)?.[0]

    const buildRequestParameters = () => {
        const request = buildBaseRequestParameters(loginRequest, languageId, workTaskId, userContext, vehicle)

        request.vehicleModelId = vehicleModelId

        return request
    }

    useMessage((data) => {
        if (!!data.getFeedbackResult && data.getFeedbackResult) {
            Morpheus.closeView("1")
        }

        if (data.setVehicleModel) {
            setVehicleModelId(data.setVehicleModel.modelID)
        }

        if (data.getFeedbackUrl) {
            setFeedbackUrl(data.getFeedbackUrl)
            setShowMissingDataDialog(true)
        }

        if (data.getFeedbackResult) {
            setShowMissingDataDialog(false)
        }

        if (data.invalidSessionMvc) {
            compareAndSetRequestUrl(
                buildRequestParameters,
                loginUrl,
                setLoading,
                setUrl,
                actions.updateParameters,
                parameters,
                url,
                data.invalidSessionMvc
            )
        }
    })

    useEffect(() => {
        mounted.current = true
        const unSub = channel("GLOBAL").subscribe("MVC/RESIZE", () => mounted.current && setResize(Math.random()))

        return () => {
            unSub?.()
            mounted.current = false
        }
    }, [])

    useEffect(() => {
        if (workTaskId && userContext && vehicle && !isDemoLoading) {
            compareAndSetRequestUrl(buildRequestParameters, loginUrl, setLoading, setUrl, actions.updateParameters, parameters, url)
        }
    }, [vehicle?.id, vehicle?.engineCode, vehicle?.longlife, userContext?.id, workTaskId, isDemoLoading])

    useEffect(() => {
        if (memoToolEnabled && vehicle && !memoImportantNoteJson) {
            Container.getInstance(RegisteredModels.MemoTool)
                .action("getMemoImportantNote")({ manufacturerName: vehicle.manufacturer })
                .then((response) => {
                    setMemoImportantNote(response)
                    setShowMemoDialog(!!response)
                    !!setMemoImportantNoteJson && !!response && setMemoImportantNoteJson(JSON.stringify(response))
                })
        }
    }, [vehicle?.tecDocTypeId, memoToolEnabled])

    if (feedbackUrl && comparePage) {
        const comparePageUrl = renderRoute(comparePage, { module: "HaynesPro", feedbackUrl: domain + feedbackUrl })

        Morpheus.showView("1", comparePageUrl)
        setFeedbackUrl(undefined)
    }

    const handleLoadingCallback = () => {
        mounted.current && setLoading(false)
    }

    const handleLoadingMissingDataCallback = () => {
        mounted.current && setLoadingMissingDataFrame(false)
    }

    return (
        <>
            <WrapperFrame loading={loading}>
                {url && (
                    <IFrame
                        url={url}
                        className={classes(style.wrapper, className, loading ? style.hide : "")}
                        contextDependent
                        id={`iframe_${id}`}
                        onLoad={handleLoadingCallback}
                        refreshOnUrlChanged
                    />
                )}
            </WrapperFrame>

            {memoToolEnabled && (
                <Dialog open={showMemoDialog} skin="warning" position="middle" onOutsideClick={() => setShowMemoDialog(false)}>
                    <Box>
                        <Box
                            sx={{ display: "inline-flex", flex: 1, alignItems: "center", margin: (theme) => theme.spacing(1, 0) }}
                            key={memoImportantNote?.id}
                        >
                            <Icon className={style.icon} name="warning-dark" size="xl" />
                            <Typography variant="h4">
                                {translateText(71)}: {memoImportantNote?.id}
                            </Typography>
                        </Box>
                        <Typography> {memoImportantNote?.label?.memoStr}</Typography>

                        <Button variant="outlined" onClick={() => setShowMemoDialog(false)}>
                            {translateText(316)}
                        </Button>
                    </Box>
                </Dialog>
            )}

            {feedbackUrl && !comparePage && (
                <Dialog
                    open={showMissingDataDialog}
                    onOutsideClick={() => setShowMissingDataDialog(false)}
                    PaperProps={{ style: { overflow: "hidden" } }}
                >
                    <Button
                        onClick={() => setShowMissingDataDialog(false)}
                        variant="text"
                        startIcon={<CloseOutlined />}
                        sx={{ marginLeft: "95%", marginBottom: "-.5em" }}
                    />
                    <StyledIframe>
                        <WrapperFrame loading={loadingMissingDataFrame}>
                            <iframe
                                src={domain + feedbackUrl}
                                className={classes(style.wrapper, className, loadingMissingDataFrame ? style.hide : "")}
                                id="iframe_MissingData"
                                onLoad={handleLoadingMissingDataCallback}
                            />
                        </WrapperFrame>
                    </StyledIframe>
                </Dialog>
            )}
        </>
    )
}

const StyledIframe = styled(Box)(() => ({
    flex: 1,
    height: "calc(100vh - 120px)",
    maxHeight: px(850),
    minWidth: px(500),
    zIndex: 99999,
    " > div": {
        flex: 1,
        height: percent(100),
        width: percent(100),
    },
}))

const style = useStyle({
    hide: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        height: percent(100),
        width: percent(100),
    },
    icon: {
        margin: margin(0, 1),
    },
})(AutodataFrame)

export default withRouter(AutodataFrame)
