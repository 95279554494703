import { ChangeEvent, FC, useCallback, useEffect, useMemo } from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { Headline, Icon, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box, TextField, styled } from "@tm/components"
import NextStep from "../_shared/nextStep"
import { VehicleAndCustomer } from "../_shared"
import { getComponentStyles } from "../_shared/styles"
import { stepNavigationActions, testerActions, useFastServiceStore } from "../../data"
import { StepNames } from "../../data/models"

const currentStepName = StepNames.Tester

const Tester: FC = () => {
    const { translateText } = useLocalization()
    const classNames = getComponentStyles()

    const { firstName, lastName, inputsLocked } = useFastServiceStore((state) => ({
        firstName: state.tester.firstName,
        lastName: state.tester.lastName,
        inputsLocked: state.inputsLocked
    }))

    const nextStepActive = useMemo(() => !!firstName && !!lastName, [firstName, lastName])

    useEffect(() => {
        if (nextStepActive) {
            stepNavigationActions.completeStep(currentStepName)
            stepNavigationActions.updateNextStep(currentStepName)
        }
    }, [nextStepActive])

    const handleFormat = useCallback((value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }, [])

    const saveFirstName = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        testerActions.setFirstName(handleFormat(event.target.value))
    }, [])

    const saveLastName = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        testerActions.setLastName(handleFormat(event.target.value))
    }, [])

    return (
        <Scrollbar className={classNames.flexColumn}>
            <div className={classes(style.wrapper, classNames.wrapper)}>
                <VehicleAndCustomer showServiceText headerText={translateText(12537)} />
                <div className={classes(classNames.flexColumn, classNames.flexCenter, classNames.flexOne)}>
                    <Icon className={classNames.bigIcon} name="inspector" />
                    <div className={classNames.flexOne}>
                        <Headline className={classes(classNames.textCenter, classNames.marginBottomL)} size="l">
                            {" "}
                            {translateText(12907)}
                        </Headline>
                        <div className={classes(classNames.marginBottomL, classNames.textCenter)}>{translateText(12908)}</div>
                        <div className={classes(classNames.marginBottomL, classNames.textCenter)}>{translateText(12909)}</div>
                    </div>
                    <InputWrapper>
                        <TextField
                            size="extralarge"
                            value={firstName}
                            label={translateText(119)}
                            placeholder={translateText(119)}
                            onChange={saveFirstName}
                            disabled={inputsLocked}
                        />
                        <TextField
                            size="extralarge"
                            value={lastName}
                            label={translateText(104)}
                            placeholder={translateText(104)}
                            onChange={saveLastName}
                            disabled={inputsLocked}
                        />
                    </InputWrapper>
                </div>
            </div>
            <NextStep currentStepName={currentStepName} icon="arrows_down" buttonTextId={3113} active={nextStepActive} />
        </Scrollbar>
    )
}

export default Tester

const style = useStyle({
    wrapper: {
        padding: "0 1em",
    },
})(Tester)

const InputWrapper = styled(Box)({
    marginTop: "2em",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "50%",
    flex: "1",
    gap: "1em",
})
