import { Box, Stack, Theme, Typography } from "@tm/components"
import { ErpPrice, MemoType, Quantity } from "@tm/models"
import { useLocalization } from "@tm/localization"
import PriceMemo from "./PriceMemo"
import PriceIcon from "./PriceIcon"
import { CustomOrderPriceCalculation } from "../../../../../../../data/model"

type Props = {
    quantity?: Quantity
    calculatedPrice?: ErpPrice
    erpPrice?: ErpPrice
    priceLabel: string
    primaryColor?: boolean
    theme: Theme
    showPricePerUnitLabel?: boolean
    customOrderPriceCalculation?: CustomOrderPriceCalculation
    customOrderPrice?: ErpPrice
}

export default function SmallScreenPrice(props: Props) {
    const {
        calculatedPrice,
        erpPrice,
        priceLabel,
        primaryColor,
        theme,
        quantity,
        showPricePerUnitLabel,
        customOrderPriceCalculation,
        customOrderPrice,
    } = props
    const fontFamilyOverwrite = theme?.overwrites?.components?.basketPrices?.fontFamily || theme.typography.fontFamily

    const { translate, currency } = useLocalization()
    const price = calculatedPrice ?? erpPrice
    if (!price) {
        return null
    }

    return (
        <>
            {/* first row */}
            <Typography variant="label" textAlign="end" noWrap>
                {priceLabel}
            </Typography>
            <Box />

            {/* second row */}
            <Typography
                color={primaryColor ? "primary" : undefined}
                fontFamily={fontFamilyOverwrite || theme.typography.fontFamily}
                fontWeight="bold"
                noWrap
                textAlign="end"
            >
                {currency(price.value, price.currencySymbol || price.currencyCode_Iso_4217)}
            </Typography>
            <Stack ml={1} justifyContent="center" alignItems="center">
                <PriceIcon
                    erpPrice={erpPrice}
                    customOrderPriceCalculation={customOrderPriceCalculation}
                    priceLabel={priceLabel}
                    customOrderPrice={customOrderPrice}
                />
            </Stack>

            {/* third row */}
            <Box>
                {!!price.priceUnit && showPricePerUnitLabel && (price.priceUnit > 1 || (quantity && !!quantity.quantityUnit)) && (
                    <Typography variant="label" textAlign="end" display="block">
                        {translate(1312)} {price.priceUnit} {quantity && quantity.quantityUnit}
                    </Typography>
                )}
                {price.memos?.filter((memo) => memo.type === MemoType.Note).map((memo, index) => <PriceMemo memo={memo} key={index} alignLabelEnd />)}
            </Box>
            <Box />
        </>
    )
}
