import { EReplaceButtonBundle, ReplaceButtonPayload, channel } from "@tm/models"
import { useCallback, useEffect } from "react"
import { useHistory } from "react-router"
import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { replaceArticleCancel, replaceArticleFinish } from "../moduleManager/business"
import { fastCalculatorStore, StoreInstance, StoreInstanceEnum } from "../../state"

export function Manager() {
    const history = useHistory()
    const { languageId } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const workTask = useWorkTask()?.workTask

    const handleReplaceCallback = useCallback(
        ({ part: replacePart, bundle, cancelAction }: ReplaceButtonPayload) => {
            if (bundle !== EReplaceButtonBundle.FastCalculatorNext) {
                return false
            }

            let instance: StoreInstance = { storeInstance: StoreInstanceEnum.normal, worktaskId: workTask?.id }
            const { replaceArticle } = fastCalculatorStore(instance).getState()
            if (!replaceArticle || replaceArticle?.inModal) {
                // If we have a replace article and is in a modal then we need to switch instance
                // If we don't have a replace article in the normal module instance, we should use the modal instance
                instance = { storeInstance: StoreInstanceEnum.modal, worktaskId: workTask?.id }
            }

            if (cancelAction) {
                replaceArticleCancel({ instance, history, languageId, userSettings })
                return
            }

            if (!replacePart) {
                return
            }

            replaceArticleFinish({ instance, replacePart, history })
        },
        [history, workTask?.id]
    )

    useEffect(() => {
        const unsub = channel("GLOBAL").clearAndSubscribe("PARTS/REPLACE_PART", handleReplaceCallback)

        return unsub
    }, [handleReplaceCallback])

    return null
}
