import { classes, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { IMicros } from "@tm/models"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { useCallback } from "react"
import { AddRepairTimesToRdBasket } from "../../data/models"
import { RedesignHelper } from "../rd-helper"
import { Actions } from "./business"

type Props = IMicros["standalone"]["rd-add-articles-rt-to-basket"]

function AddItemsRtToRdBasketComponent({
    oeArticles,
    iamArticles,
    services,
    repairTimeDivision,
    provider,
    vehicle,
    sourceId,
    buttonText,
    useDefaultButtonStyle,
}: Props) {
    const dispatch = useDispatch()

    const addItemsToRDBasket = bindActionCreators(Actions.addIAMArticlesToRDBasket, dispatch)

    const handleClick = useCallback(() => {
        !!oeArticles?.length && RedesignHelper.addOeItemsToRDBasket(oeArticles, sourceId)

        !!iamArticles?.length && RedesignHelper.addItemsToRDBasket(iamArticles, addItemsToRDBasket, sourceId)

        if (vehicle && repairTimeDivision && !!services?.length) {
            const request: AddRepairTimesToRdBasket[] = [
                {
                    addToKVA: true,
                    docType: 10,
                    provider: provider as number,
                    repairTimeDivision,
                    kTypeId: vehicle.tecDocTypeId,
                    repairTimes: services,
                    vehicleId: vehicle.id,
                    workTaskId: "",
                },
            ]

            RedesignHelper.addItemsToKVA(request, sourceId)
        }
    }, [oeArticles, iamArticles, services, repairTimeDivision, provider, vehicle, sourceId, buttonText])

    return (
        <div className="tk-standalone rd-add-articles-rt-to-basket">
            <Button
                title={buttonText}
                className={classes("add-to-basket__button", !useDefaultButtonStyle && style.cart)}
                layout={["bordered"]}
                skin="highlight"
                onClick={handleClick}
                icon="cart"
                disabled={false}
            >
                {buttonText && <span className="btn__text">{buttonText}</span>}
            </Button>
        </div>
    )
}

const style = useStyle({
    cart: {
        position: "absolute",
        right: "2.5em",
        zIndex: 1,
    },
})(AddItemsRtToRdBasketComponent)

export default AddItemsRtToRdBasketComponent
