import { useEffect, useState } from "react"
import { Button } from "@tm/controls"
import { AuthorityContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { getBundleParams } from "../../../utils"
import UserSettingsArea from "./UserSettingsArea"

export default function Cms() {
    const [showComponent, setShowComponent] = useState(false)
    const [loginUrl, setLoginUrl] = useState<string>()

    async function getLoginUrl() {
        const modules = window.userContext?.externalModules
        if (!modules) {
            return
        }
        const module = modules.find((x) => x.parameter?.some((y) => y.key === "CatalogType" && y.value === "DevspireCms"))
        if (!module) {
            return
        }
        if (!module.parameter?.some((x) => x.key === "IsAdmin" && x.value === "1")) {
            return
        }
        setShowComponent(true)

        const authorityContainer = Container.getInstance(RegisteredModels.Authority) as AuthorityContainer
        const tokenResponse = await authorityContainer.action("getExternalToken")("DevspireCms")
        if (!tokenResponse) {
            return
        }
        const url = getBundleParams().cmsLoginUrl
        if (url) {
            setLoginUrl(url.replace("{{token}}", tokenResponse.token))
        }
    }

    useEffect(() => {
        getLoginUrl()
    }, [])

    if (!showComponent) {
        return null
    }

    return (
        <UserSettingsArea icon="cms" title="CMS">
            {loginUrl && (
                <Button layout={["holo"]} linkTo={loginUrl} target="_blank">
                    Login
                </Button>
            )}
        </UserSettingsArea>
    )
}
