import { ETyreCountType } from "@tm/models"
import { CombinedTyresPosition } from "../enums"

export const getIconsForCombinedTyresSearch = (tyreCount: string | undefined, position: CombinedTyresPosition) => {
    if (tyreCount === ETyreCountType.carTires.toString()) {
        if (position === CombinedTyresPosition.frontTyres) {
            return "axle-car-front-tires"
        }
        if (position === CombinedTyresPosition.rearTyres) {
            return "axle-car-rear-tires"
        }
    }

    if (tyreCount === ETyreCountType.motorcycleTires.toString()) {
        // To be defined
        return undefined
    }
}
