import { Box, Button, Icon, Image } from "@tm/components"
import { ERIKContentService } from "@tm/data"
import { useCallback } from "react"

type Props = {
    file: ERIKContentService.FileMeta | undefined
    onNextFile: (direction: "next" | "prev") => void
}

export function DialogContent({ file, onNextFile }: Props) {
    if (!file) {
        return null
    }

    const getPreviewContent = useCallback(() => {
        switch (file?.mimeType) {
            case "image/avif":
            case "image/png":
            case "image/jpeg":
            case "image/gif":
            case "image/webp":
                return (
                    <Box maxHeight="100%" alignContent="center">
                        <Image src={file.thumbnail?.url ?? ""} type="car" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </Box>
                )

            case "application/pdf":
                return (
                    <Box width="80%" height="80%" flex="1" alignSelf="center" display="flex">
                        <iframe style={{ flex: 1 }} id="vefiles-pdf-preview" src={file.url} />
                    </Box>
                )

            default:
                return null
        }
    }, [file, onNextFile])

    return (
        <Box sx={{ display: "flex", justifyContent: "center", padding: "24px", height: "100%" }}>
            <NextAction type="prev" onClick={onNextFile} />
            {getPreviewContent()}
            <NextAction type="next" onClick={onNextFile} />
        </Box>
    )
}

type NextActionProps = {
    type: "next" | "prev"
    onClick: (type: "next" | "prev") => void
}

function NextAction({ type, onClick }: NextActionProps) {
    return (
        <Box sx={{ height: "100%", margin: "auto 50px", alignContent: "center" }}>
            <Button
                size="extralarge"
                variant="contained"
                sx={{ "&&": { borderRadius: "50%" } }}
                onClick={() => onClick(type)}
                startIcon={<Icon name={type} height="30px" width="30px" />}
            />
        </Box>
    )
}
