import { TyreFilter } from "@tm/models"
import { useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { FC, memo, useState } from "react"
import { InfoDialog, SelectionCheckbox } from "."
import { isInfoAvailable } from "../../data/helpers"

type Props = {
    items: TyreFilter[]
    selectedValues?: TyreFilter[]
    searchValue?: string
    onChange(selectedValue: TyreFilter): void
    onReset(): void
}

const SelectionItems: FC<Props> = ({ items, searchValue, onChange, selectedValues }) => {
    const renderItem = (item: TyreFilter, idx: number) => {
        return <SelectionBox key={idx} onChange={onChange} selectedValues={selectedValues} item={item} />
    }
    const filteredItems = items.filter((x) => x.value && x.value.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase() ?? ""))
    return <div style={{ paddingRight: "0.35em" }}>{filteredItems.map(renderItem)}</div>
}

export default SelectionItems

type SelectionProps = {
    selectedValues?: TyreFilter[]
    item: TyreFilter
    onChange(selectedValue: TyreFilter): void
}

const SelectionBox: FC<SelectionProps> = memo(({ selectedValues, item, onChange }) => {
    const [displayDialog, setDisplayDialog] = useState(false)
    const checked = !!selectedValues?.find((x) => x.group == item.group && item.value == x.value)

    const handleInfoClick = () => {
        setDisplayDialog(true)
    }

    const handleDialogCLose = () => {
        setDisplayDialog(false)
    }

    return (
        <>
            <div className={style.wrapper}>
                <SelectionCheckbox
                    key={`${item.value}`}
                    label={item.value}
                    onChange={onChange?.bind(this, item)}
                    selected={checked}
                    info={item.info}
                />

                {item.valueKey && isInfoAvailable(item.valueKey) && (
                    <Button layout={["ghost"]} onClick={handleInfoClick} icon="info" className={style.shrinkButton} size="xs" />
                )}
            </div>
            {displayDialog && item.valueKey && <InfoDialog filterId={item.valueKey} onDialogCLose={handleDialogCLose} />}
        </>
    )
})

const style = useStyle({
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    shrinkButton: {
        padding: 0,
    },
})(SelectionBox)
