import { channel, Vehicle } from "@tm/models"
import { useState, useEffect } from "react"
import { isVehicleChanged } from "../../components/moduleManager/business/helpers"

export const useVehicle = (workTaskId?: string, perviousVehicle?: Vehicle, calBack?: (veh: Vehicle) => void) => {
    const [vehicle, setVehicle] = useState<Vehicle | undefined>(perviousVehicle)

    useEffect(() => {
        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    setVehicle(newVeh)
                    calBack?.(newVeh)
                }
            },
            true
        )
        return unsubVehicle
    }, [perviousVehicle])

    return vehicle
}
