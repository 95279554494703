import { ChangeEvent, memo, useMemo } from "react"
import { Button, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Tooltip, Box, TextField, Icon, Typography, styled, tooltipClasses, useTheme } from "@tm/components"
import { style } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { getComponentStyles } from "./styles"

type Props = {
    noteText: string | undefined
    savedNoteText: string | undefined
    addNoteIcon: string
    editNoteIcon: string
    deleteNoteIcon: string
    noteAvailable: boolean
    showEditOptions: boolean
    onClickAway(): void
    handleOnToggle(): void
    handleNoteChange(event: ChangeEvent<HTMLInputElement>): void
    saveNote(): void
    deleteNote(): void
}

const IconWrapper = styled("div")(() => ({ display: "flex", cursor: "pointer", margin: "auto 0" }))
const CtaWrapper = styled("div")(({ theme }) => ({ display: "flex", paddingTop: theme.spacing(1), width: "30rem" }))
const WorkTaskNoteWrapper = styled("div")(() => ({ display: "flex", justifyContent: "space-between", alignItems: "baseline" }))

const FullWidthTooltip = styled(Tooltip)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
    },
}))

export const NoteTooltip = memo<Props>(
    ({
        noteText,
        savedNoteText,
        addNoteIcon,
        editNoteIcon,
        deleteNoteIcon,
        noteAvailable,
        showEditOptions,
        onClickAway,
        handleOnToggle,
        handleNoteChange,
        saveNote,
        deleteNote,
    }) => {
        const { translateText } = useLocalization()
        const className = useMemo(() => style(getComponentStyles()), [])
        const theme = useTheme()
        const saveNoteCloseDialog = () => {
            saveNote()
            onClickAway()
        }
        const isActive = !!(noteText && noteText.length > 0 && noteText !== savedNoteText)

        const editNoteOptions = useMemo(() => {
            return (
                <>
                    <WorkTaskNoteWrapper>
                        <Typography>
                            <strong>{translateText(13012)}</strong>
                        </Typography>
                        <Button icon="cancel-x" layout={["ghost"]} onClick={onClickAway} />
                    </WorkTaskNoteWrapper>
                    <CtaWrapper>
                        <TextField inputProps={{ maxLength: 500 }} size="small" fullWidth onChange={handleNoteChange} value={noteText} />
                        <Box width={10} />
                        <Button onClick={saveNoteCloseDialog} isActive={isActive}>
                            {translateText(9)}
                        </Button>
                        <Box width={10} />
                        <Button onClick={deleteNote} icon={deleteNoteIcon} size="s" />
                    </CtaWrapper>
                </>
            )
        }, [translateText, saveNote, deleteNote, onClickAway, handleNoteChange, noteText])

        const noteTooltip = () => {
            return noteText || translateText(13011)
        }

        const noteIcon = () => {
            return (
                <IconWrapper>
                    <Icon
                        width={20}
                        height={20}
                        name={noteAvailable ? editNoteIcon : addNoteIcon}
                        color={
                            noteAvailable
                                ? (theme?.overwrites?.components?.worktaskModuleNavigation?.notesButton?.active ?? "primary")
                                : theme?.overwrites?.components?.worktaskModuleNavigation?.foregroundColor
                        }
                    />
                </IconWrapper>
            )
        }

        return (
            <Toolbar className={className}>
                {showEditOptions ? (
                    <FullWidthTooltip
                        variant="light"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open
                        onClickAway={onClickAway}
                        onClick={onClickAway}
                        title={editNoteOptions}
                    >
                        {noteIcon()}
                    </FullWidthTooltip>
                ) : (
                    <Tooltip title={noteTooltip()} onClick={handleOnToggle}>
                        {noteIcon()}
                    </Tooltip>
                )}
            </Toolbar>
        )
    }
)
