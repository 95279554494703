import { createElement, FC } from "react"
import { Provider } from "react-redux"
import { Store } from "redux"

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(comp: FC<Omit<P, keyof StoreModel>>): FC<P> {
    const component: FC<P> = (props) => {
        const { store } = props
        return createElement(Provider, { store }, createElement(comp, props))
    }
    return component
}
