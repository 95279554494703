import { useEffect, useMemo, useState } from "react"
import { Badge, Box, Checkbox, FormControlLabel, Paper, styled } from "@tm/components"
import { Icon, TabControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { stepNavigationActions, tyresWheelsActions, useFastServiceStore, WorkContext } from "../../data"
import { useIsFirstRender } from "../../helpers"
import { CustomPanel, NextStep, ScrollWrapper, TitleHeader } from "../_shared"
import { WheelCheckTab } from "./components/wheelCheckTab"
import { ActiveButtons, StepNames } from "../../data/models"
import { Works } from "../works/component"

const currentStepName = StepNames.TiresWheels
export default function TyresWheels() {
    const { translateText } = useLocalization()
    const { setUserSetting, userSettings } = useUser()

    const { selectedTab, tabs, workData, isAreaDisabled, isAreaCompleted, disableTyresSystem, inputsLocked } = useFastServiceStore((state) => ({
        selectedTab: state.tyresWheels.selectedTab,
        tabs: state.tyresWheels.tabs,
        workData: state.worksState.works[currentStepName],
        isAreaDisabled: state.tyresWheels.isAreaDisabled,
        isAreaCompleted: state.tyresWheels.isAreaCompleted,
        disableTyresSystem: state.tyresWheels.disableTyresSystem,
        inputsLocked: state.inputsLocked
    }))

    const [isCompleted, setIsCompleted] = useState(false)

    useEffect(() => {
        setIsCompleted(isAreaCompleted)
    }, [isAreaCompleted])

    const tyreWork = workData?.[0]

    const completeTabs = useMemo(() => tabs.every((x) => x.isComplete), [tabs])
    const isFirstMount = useIsFirstRender()

    const handleTabSelect = (tabName: string) => {
        const newSelectedTab = tabs.find((x) => x.name === tabName)
        newSelectedTab && tyresWheelsActions.selectTab(newSelectedTab)
    }

    useEffect(() => {
        !isFirstMount && stepNavigationActions.completeStep(currentStepName)

        if (completeTabs) {
            stepNavigationActions.updateNextStep(currentStepName)
            tyresWheelsActions.setIsTyresWheelsCompleted(true)
        }
    }, [completeTabs])

    const handleActivationChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        tyresWheelsActions.setIsTyresWheelsDisabled(!checked)
        tyresWheelsActions.setIsTyresWheelsCompleted(checked ? completeTabs : false)
        setUserSetting("FAST_SERVCE_SETTINGS", { ...userSettings?.fastServiceSettings, isTyresWheelsDisabled: !checked })
        setIsCompleted(checked ? completeTabs : false)
    }

    const handleCompleteArea = (buttonId: ActiveButtons) => {
        if (isAreaDisabled || disableTyresSystem) {
            tyresWheelsActions.setIsTyresWheelsCompleted(buttonId === "checkButton")
            stepNavigationActions.completeStep(currentStepName)
        }
    }

    const renderPanelTitle = (title: string) => {
        return (
            <TitleHeader
                title={title}
                onStatusButtonClick={handleCompleteArea}
                selectedStatusButton={(isAreaCompleted && "checkButton") || "none"}
                readonly={disableTyresSystem ? !disableTyresSystem : !isAreaDisabled}
                disabledButtons={["defectButton", "repairedButton"]}
            />
        )
    }

    const handleCompletionChange = (completed: boolean) => {
        setIsCompleted(completed)
    }

    const renderChecks = () => {
        return isAreaDisabled ? (
            <Box height="80%">
                <Works step={StepNames.TiresWheels} onCompletionChange={handleCompletionChange} />
            </Box>

        ) : (
            <CustomPanel title={renderPanelTitle(translateText(13063))} initiallyClosed={false} />
        )
    }

    return (
        <>
            <ScrollWrapper>
                <StyledWrapper>
                    <StyledContainer>
                        {renderChecks()}
                        {!disableTyresSystem && (
                            <Box>
                                <FormControlLabel
                                    label={translateText(13880)}
                                    sx={{ ml: 1 }}
                                    control={<Checkbox checked={!isAreaDisabled} onChange={handleActivationChange} />}
                                    disabled={inputsLocked}
                                />
                                <StyledTabControl selectedTabIdentifier={selectedTab.name} onTabSelect={handleTabSelect}>
                                    {tabs.map((item) => (
                                        <TabControl.Tab
                                            className={item.index === selectedTab.index ? "hola" : ""}
                                            key={item.index}
                                            identifier={item.name}
                                            disabled={isAreaDisabled}
                                        >
                                            {translateText(item.translation)}
                                            {(item.isComplete || item.index === selectedTab.index) && (
                                                <StyledBadge isComplete={item.isComplete} badgeContent={<Icon skin="success" name="check" size="m" />} />
                                            )}
                                        </TabControl.Tab>
                                    ))}
                                </StyledTabControl>
                                <Paper sx={{ flexGrow: 1 }}>
                                    {tabs.map((item) => (
                                        <div key={item.index}>
                                            {selectedTab.index === item.index && (
                                                <WorkContext.Provider value={{ work: tyreWork }}>
                                                    <WheelCheckTab tab={item} work={tyreWork} />
                                                </WorkContext.Provider>
                                            )}
                                        </div>
                                    ))}
                                </Paper>
                            </Box>
                        )}
                    </StyledContainer>
                </StyledWrapper>
            </ScrollWrapper>
            <NextStep currentStepName={currentStepName} buttonTextId={414} icon="arrows_down" active={isCompleted} />
        </>
    )
}

const StyledWrapper = styled("div")({
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    position: "relative",
})

const StyledContainer = styled(Box)({
    paddingLeft: "1em",
    paddingRight: "0.75em",
    paddingTop: "0.5em",
})

const StyledTabControl = styled(TabControl)(({ theme }) => ({
    display: "flex",
    marginTop: "1em",
    flexGrow: 1,
    "& .tab": {
        flexGrow: 1,
        justifyContent: "center",
        position: "relative",
        margin: theme.margin?.xs ?? "0.1em",
        border: "1px solid transparent",
        borderRadius: theme.radius?.default,
        "&:first-of-type": {
            marginLeft: "0",
        },
        "&:last-of-type": {
            marginRight: "0",
        },
    },
    "& .hola": {
        border: `1px solid ${theme.colors?.primary ?? "inherit"} !important`,
        background: "white !important",
        color: "black !important",
    },
}))

const StyledBadge = styled(Badge, {
    shouldForwardProp: (prop) => prop !== "isComplete",
})<{ isComplete: boolean }>(({ isComplete, theme }) => ({
    position: "absolute",
    top: "1px",
    right: "15px",
    "& .MuiBadge-badge": {
        padding: ".25rem",
        background: isComplete ? `${theme.colors?.success}` : "white",
        height: "unset",
        borderRadius: "1rem",
        borderColor: isComplete ? "white !important" : `${theme.colors?.success} !important`,
        border: "1px solid",
        "& .icon": {
            fill: isComplete ? "white !important" : `${theme.colors?.success} !important`,
        },
    },
}))
