import { useLocalization } from "@tm/localization"
import { Warehouse } from "@tm/models"
import { PanelSection } from "@tm/controls"
import { RefObject } from "react"
import WarehousesTable from "../../../_shared/warehouses-table"

type Props = {
    warehouses?: Array<Warehouse>
    wrapperRef: RefObject<HTMLDivElement> | undefined
    requestedQuantity: number | undefined
}

export default function WarehousesComponent(props: Props) {
    const { translate } = useLocalization()
    if (!props.warehouses?.filter((w) => w.quantities?.length).length) {
        return null
    }

    return (
        <div ref={props.wrapperRef}>
            <PanelSection className="stock-info__warehouses" title={translate(412)} size="s">
                <WarehousesTable warehouses={props.warehouses} requestedQuantity={props.requestedQuantity} isTooltip={false} />
            </PanelSection>
        </div>
    )
}
