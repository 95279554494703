import { useState } from "react"
import { ArticlesContext, ArticlesProviderBaseProps, useArticlesContext } from "../../../ContextProvider/Articles"
import { FiltersContext } from "../../../ContextProvider/Filters"
import { useAfterPaintEffect } from "../../../helpers"
import { useVehicle } from "../../../hooks"
import { WholesalerArticleData } from "../../../models"
import { useWholeSalerArticleNumberListParamsContext } from "../ListParams"
import { useFiltersAndResults } from "./useFiltersAndResults"

export function FiltersAndResultsProvider({ children, setHasRendered }: ArticlesProviderBaseProps) {
    const vehicle = useVehicle()
    const params = useWholeSalerArticleNumberListParamsContext()
    const [startRender, setStartRender] = useState(false)
    const { articleData, filters } = useFiltersAndResults(vehicle, params, startRender, true)

    useAfterPaintEffect(() => {
        setStartRender(true)
    }, [])

    useAfterPaintEffect(() => {
        if (articleData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articleData.requestStatus])

    return (
        <FiltersContext.Provider value={filters}>
            <ArticlesContext.Provider value={articleData}>{children}</ArticlesContext.Provider>
        </FiltersContext.Provider>
    )
}

export function useWholesalerArticlesContext<TContext extends WholesalerArticleData>(): TContext
export function useWholesalerArticlesContext<TContext extends WholesalerArticleData, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useWholesalerArticlesContext<TContext extends WholesalerArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useArticlesContext(selector as never)
}
