import { Button, Checkbox, Collapsible, SearchField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useSelector } from "react-redux"
import { FC, useState } from "react"
import { RimFilter } from "../../data/model"
import { SelectedFilters } from "../wheels-list/business"
import { FilterCriterias } from "../../data/enums"
import { MainState } from "../main"
import { containsExactString } from "../wheels-list/business/helper"

type Props = {
    selected: boolean
    onChange: (item: RimFilter) => void
    element: RimFilter
}

const AttributeCheckbox: FC<Props> = ({ selected, onChange, element }) => {
    const handleOnChange = () => {
        onChange(element)
    }

    const { translateText } = useLocalization()

    // TODO hardcoded EFilterCriterias
    const getTranslatedText = (id: number) => {
        switch (id) {
            case FilterCriterias.rimAttribute1:
                return translateText(1136)
            case FilterCriterias.rimAttribute2:
                return translateText(1558)
            case FilterCriterias.rimAttribute3:
                return translateText(1568)
            case FilterCriterias.rimAttribute4:
                return "ECE"
            case FilterCriterias.OE:
                return translateText(13364)
            default:
                return undefined
        }
    }

    if (!element.info) {
        return null
    }

    return (
        <div className="filter-item selection-list__item selection-list__item--low-priority">
            <Checkbox size="s" checked={selected} onToggle={handleOnChange} />
            <div onClick={handleOnChange} className="selection-list__item__value-wrapper">
                <Text size="s" className="selection-list__item__value">
                    {getTranslatedText(element.attributeId)?.toUpperCase() ?? element.info?.toUpperCase()}
                </Text>
            </div>
        </div>
    )
}

type SelectionCheckboxes = {
    filters: RimFilter[]
    collapsibleText: string
    displaySearchButton?: boolean
    disabledCollapsible?: boolean
    selectedFilters: SelectedFilters
    initiallyClosed?: boolean
    path?: string
    onChange: (path: string, filter: string) => void
    onReset?: (e: React.MouseEvent<HTMLElement>, filter: string) => void
    onAccordionClick?: (key: string, value: boolean) => void
}

const selector = (s: MainState) => s.wheelsList.base.isAdditionalManufacturerSelected

export const AttributeSelectionCheckboxes: FC<SelectionCheckboxes> = ({
    filters,
    collapsibleText,
    displaySearchButton,
    onChange,
    onReset,
    disabledCollapsible,
    selectedFilters,
    initiallyClosed,
    onAccordionClick,
    path,
}) => {
    const { translateText } = useLocalization()
    const [searchValue, setSearchValue] = useState("")
    const [showSearchInput, setShowSearchInput] = useState(false)
    const isAdditionalManufacturerSelected = useSelector(selector)
    const [showMore, setShowMore] = useState<boolean>(isAdditionalManufacturerSelected)

    const filteredManufacturers = filters.filter((x) => !searchValue || x.info?.toUpperCase().includes(searchValue?.toUpperCase()))
    let topPriorityList: RimFilter[] = filteredManufacturers
    let notSoPriorityList: RimFilter[] = []

    if (filteredManufacturers.some((filter) => filter.hasTradeReference)) {
        topPriorityList = filteredManufacturers.filter((x) => x.hasTradeReference)
        notSoPriorityList = filteredManufacturers.filter((x) => !x.hasTradeReference)
    }

    const handleShowMore = () => {
        setShowMore((prevState) => !prevState)
    }

    const handleAccordionClick = () => {
        onAccordionClick?.(path ?? "", initiallyClosed ?? false)
    }

    const handleOnChange = (item: RimFilter) => {
        // nice workaround because of the data..
        if (
            [
                FilterCriterias.rimAttribute1,
                FilterCriterias.rimAttribute2,
                FilterCriterias.rimAttribute3,
                FilterCriterias.rimAttribute4,
                FilterCriterias.OE,
                FilterCriterias.VSA,
            ].includes(item.attributeId)
        ) {
            if (selectedFilters[item.attributeId].includes(item.attributeValue)) {
                onChange(item.attributeId.toString(), "")
            } else {
                onChange(item.attributeId.toString(), item.attributeValue)
            }
        } else if (
            (FilterCriterias.rimColor == item.attributeId ||
                FilterCriterias.rimType == item.attributeId ||
                FilterCriterias.rimSort == item.attributeId) &&
            item.attributeKey
        ) {
            onChange(item.attributeId.toString(), item.attributeKey)
        } else {
            onChange(item.attributeId.toString(), item.attributeValue)
        }
    }

    const handleSearchButton = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        setShowSearchInput((x) => !x)
    }
    const handleOnResetClick = (e: any) => {
        onReset && onReset(e, filters.first()?.attributeId.toString() || "")
    }

    const renderHeader = () => {
        return (
            <>
                {displaySearchButton && <Button layout={["ghost"]} icon="search" onClick={handleSearchButton} />}
                {onReset && <Button onClick={handleOnResetClick} layout={["ghost"]} icon="remove-filter" />}
            </>
        )
    }

    const renderItem = (item: RimFilter, idx: number) => {
        const selectedValue =
            (item.attributeId == FilterCriterias.rimColor ||
                item.attributeId == FilterCriterias.rimType ||
                item.attributeId == FilterCriterias.rimSort) &&
            item.attributeKey
                ? item.attributeKey
                : item.attributeValue
        let selected
        if (item.attributeId == FilterCriterias.rimSort) {
            selected = containsExactString(selectedFilters[item.attributeId], selectedValue)
        } else {
            selected = selectedFilters[item.attributeId].includes(selectedValue)
        }

        return <AttributeCheckbox key={idx} element={item} selected={selected} onChange={handleOnChange} />
    }

    return (
        <Collapsible
            name={collapsibleText}
            initiallyOpened={!initiallyClosed}
            renderHeaderAppendix={renderHeader}
            disabled={disabledCollapsible}
            onChangeOpen={handleAccordionClick}
        >
            {showSearchInput && <SearchField className="manufacturers-search" showClear value={searchValue} onChange={setSearchValue} />}
            {topPriorityList.map(renderItem)}
            {notSoPriorityList.length > 0 && (
                <div className="selection-list__indent">
                    <Text className="is-clickable" size="xs" onClick={handleShowMore}>
                        {translateText(showMore ? 169 : 168)}
                    </Text>
                    {showMore && notSoPriorityList.map(renderItem)}
                </div>
            )}
        </Collapsible>
    )
}

export default AttributeSelectionCheckboxes
