import { useStyle } from "@tm/context-distribution"
import { Image, Text } from "@tm/controls"
import { classes } from "@tm/utils"
import { em, percent } from "csx"
import { FC } from "react"
import { IPlate } from "../../../data/models"

type Props = { plate: IPlate }

const minWidth = 22
const maxHeight = 20

enum typeOfPlateRatios {
    auto = 4.7272725,
    amerikaans = 2.8181818,
    vierkant = 1.6190476,
    motor = 1.4685315,
    brommerAndSnorfiets = 0.5714286,
}

const Plate: FC<Props> = ({ plate }) => {
    let styleType = "auto"
    let height = 3

    switch (plate?.aspectRatio) {
        case typeOfPlateRatios.auto:
            styleType = plate.showEuroField ? "auto auto-has-euro" : "auto"
            break
        case typeOfPlateRatios.amerikaans:
            styleType = "amerikaans"
            break
        case typeOfPlateRatios.vierkant:
            styleType = "vierkant"
            break
        case typeOfPlateRatios.motor:
            styleType = "motor"
            break
        case typeOfPlateRatios.brommerAndSnorfiets:
            styleType = "brommerAndSnorfiets"
            break
        default:
            styleType = plate.showEuroField ? "auto auto-has-euro" : "auto"
    }

    let width = height * (plate?.aspectRatio ?? 1)

    if (width < minWidth) {
        const a = minWidth / width
        width *= a
        height *= a
    }

    if (height > maxHeight) {
        const a = maxHeight / height
        width *= a
        height *= a
    }

    if (!plate) {
        return null
    }

    return (
        <div className={classes(style.wrapper, styleType)}>
            <div
                className={classes(style.plate, styleType)}
                style={{ backgroundColor: plate?.backgroundColor, width: em(width), borderColor: plate.textColor, height: em(height) }}
            >
                {plate.showEuroField && (
                    <Image
                        className={classes(style.euro, styleType)}
                        style={{ height: typeOfPlateRatios.auto === plate?.aspectRatio ? em(height) : em(height / 2) }}
                        url="/styles/wm/images/nl-plate-logo.svg"
                    />
                )}
                {plate?.text.map((t, idx) => (
                    <Text key={idx} className={classes(style.text, styleType)} style={{ color: plate.textColor }}>
                        {t}
                    </Text>
                ))}
            </div>
        </div>
    )
}

const style = useStyle({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: percent(100),
    },
    plate: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "6px",
        boxSizing: "content-box",
        border: "2px solid",
        boxShadow: "2px 2px 5px rgb(0 0 0 / 25%)",
        overflow: "hidden",
        $nest: {
            "&.auto": {
                flexDirection: "row",
                justifyContent: "space-between",
            },
            "&.amerikaans": {
                borderWidth: "3px",
            },
            "&.vierkant": {
                borderWidth: "4px",
            },
            "&.motor": {
                borderWidth: "4px",
            },
            "&.brommerAndSnorfiets": {
                justifyContent: "space-between",
                borderWidth: "4px",
            },
        },
    },
    euro: {
        position: "absolute",
        left: 0,
        top: 0,
    },
    text: {
        fontSize: em(3.6),
        fontWeight: 500,
        letterSpacing: "2px",
        fontFamily: "Kenteken, 'Kenteken Web', 'MS Gothic', Consolas, monospace",
        whiteSpace: "nowrap",
        marginBottom: "-1px",
        textShadow: "1px 1px 2px rgb(0 0 0 / 20%)",
        $nest: {
            "&.auto": {
                width: "100%",
                textAlign: "center",
            },
            "&.auto-has-euro": {
                marginLeft: em(0.45),
                letterSpacing: "-1px",
                width: "100%",
                textAlign: "center",
            },
            "&.amerikaans": {
                fontSize: em(4),
                letterSpacing: "-2px",
            },
            "&.vierkant": {
                fontSize: em(5.6),
                letterSpacing: "-2px",
                borderWidth: "3px",
            },
            "&.motor": {
                fontSize: em(5.7),
                letterSpacing: "0",
                borderWidth: "3px",
            },
            "&.brommerAndSnorfiets": {
                fontSize: em(4.4),
                letterSpacing: "-1px",
            },
        },
    },
})(Plate)

export default Plate
