import { Typography } from "@tm/components"
import { ArticleInfoType } from "@tm/models"
import ArticleNumbers from "../ArticleNumbers"
import ArticleThumbnail from "../PartsTableCells/ArticleThumbnail"
import { StackRow } from "../../StyledComponents"
import { BasketPart } from "../../../models"

type Props = {
    part: BasketPart
    showManufacturer?: boolean
    showSupplierArticleNumbers?: boolean
    showWholesalerArticleNumbers?: boolean
}

export default function PartBasicInfo({ part, showSupplierArticleNumbers, showManufacturer, showWholesalerArticleNumbers }: Props) {
    const { partItem } = part
    const { articleInformation, externalId } = partItem
    const { supplierName, description, articleNumber, productGroupName, wholesalerArticleNumber } = articleInformation

    const isOePart = articleInformation?.articleInfoType === ArticleInfoType.OeArticle
    const isTecDocPart = articleInformation?.articleInfoType === ArticleInfoType.TecdocArticle

    return (
        <StackRow gap={3} flex={1}>
            <ArticleThumbnail part={part} />
            {showManufacturer && <Typography>{supplierName}</Typography>}
            {(showSupplierArticleNumbers || showWholesalerArticleNumbers) && (
                <ArticleNumbers
                    externalBasketItemId={externalId}
                    oeArticleNumber={showSupplierArticleNumbers && isOePart ? articleNumber : undefined}
                    supplierArticleNumber={showSupplierArticleNumbers && isTecDocPart ? articleNumber : undefined}
                    wholesalerArticleNumber={showWholesalerArticleNumbers ? wholesalerArticleNumber : undefined}
                />
            )}
            <Typography textAlign="center" variant="body2">
                {description || productGroupName}
            </Typography>
        </StackRow>
    )
}
