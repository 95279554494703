import { FC, useCallback, useState } from "react"
import { em } from "csx"
import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, ButtonProps } from "@tm/controls"
import { Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId } from "@tm/utils"
import { PdfDocumentReference } from "../../../data/model"

type Props = {
    document: PdfDocumentReference
    openDocumentsAsModal?: boolean
}

const DocumentComponent: FC<Props> = ({ document, openDocumentsAsModal }) => {
    const { translate } = useLocalization()
    const { workTaskId } = useWorkTask() || {}
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const handleDocumentClick = useCallback(() => {
        if (workTaskId) {
            Morpheus.showView("1", `/${encodeUniqueId(workTaskId)}^/modal/external/?url=${document.documentUrl}`)
        }
    }, [workTaskId, document.documentUrl])

    const showTooltip = useCallback(() => {
        setIsTooltipOpen(true)
    }, [])

    const hideTooltip = useCallback(() => {
        setIsTooltipOpen(false)
    }, [])

    if (document.documentType === 97) {
        return (
            <Tooltip
                open={isTooltipOpen}
                disableHoverListener
                disableFocusListener
                title={<iframe src={document.documentUrl} />}
                placement="bottom"
                color="primary"
                variant="light"
                onClickAway={hideTooltip}
                componentsProps={{ popper: { style: { maxWidth: "none" } } }}
            >
                <Button className={style.docButtons} onClick={showTooltip}>
                    {document.description || translate(891)}
                </Button>
            </Tooltip>
        )
    }

    const buttonProps: ButtonProps = {}
    if (openDocumentsAsModal) {
        buttonProps.onClick = handleDocumentClick
    } else {
        buttonProps.linkTo = document.documentUrl
        buttonProps.target = "_blank"
    }

    if (document.documentType === 4) {
        buttonProps.icon = "external-link"
    }

    return (
        <Button className={style.docButtons} {...buttonProps}>
            {document.description || "PDF"}
        </Button>
    )
}

export default DocumentComponent

const style = useStyle({
    docButtons: {
        marginBottom: em(0.2),
        marginLeft: em(0.2),
    },
})(DocumentComponent)
