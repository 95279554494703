import { QueryKey, useQueryClient } from "react-query"
import { useCallback } from "react"
import { BasketErpInformationResponseItem, GetErpInformationBasketResponse, PartItem, ShowWorkTaskBasketResponse } from "../../../model"
import { BASKET_ERP_KEY } from "../queries/useErpInfosBasketData"

export function useRemovePartsMutations(workTaskId: string, workTaskBasketQueryKey: any[]) {
    const queryClient = useQueryClient()

    // If no partIds are given, all parts should be removed.
    const removeItemsFromQueries = useCallback(
        (partIds?: string[]) => {
            let partsToBeDeleted: PartItem[] = []

            queryClient.setQueryData<ShowWorkTaskBasketResponse | undefined>(workTaskBasketQueryKey, (prev) => {
                if (!prev?.parts) {
                    return
                }
                partsToBeDeleted = partIds?.length ? prev.parts.filter((part) => partIds.includes(part.id)) : []
                return {
                    ...prev,
                    parts: partIds?.length ? prev.parts.filter((part) => !partIds.includes(part.id)) : [],
                    orderGroups: prev.orderGroups?.map((orderGroup) => ({
                        ...orderGroup,
                        partIds: partIds?.length ? orderGroup.partIds.filter((partId) => !partIds.includes(partId)) : [],
                    })),
                    erpInfoGroups: prev.erpInfoGroups?.map((erpInfoGroup) => ({
                        ...erpInfoGroup,
                        partIds: partIds?.length ? erpInfoGroup.partIds.filter((partId) => !partIds.includes(partId)) : [],
                    })),
                }
            })

            let pageSize = 0

            const groupedErpInfo: [QueryKey, BasketErpInformationResponseItem[]][] = []

            // When more than one page is requested and parts from a single page are removed resulting in less pages:
            // i.e. pageSize is 5 and initially 6 parts were invoked having 2 pages: 1 with 5 partas and the other with 1.
            // if 2 parts are removed from the first page, the new first (and unique page) must be assembled in the Frontend
            queryClient
                .getQueriesData<GetErpInformationBasketResponse>({ queryKey: [BASKET_ERP_KEY, workTaskId], active: true })
                .forEach(([key, response]) => {
                    const responseItems = response.items ?? []

                    pageSize = Math.max(pageSize, responseItems.length)
                    const queryKeyWithErpInformationItem: [QueryKey, BasketErpInformationResponseItem[]] = [
                        key,
                        partIds?.length ? responseItems.filter((item) => !partIds.includes(item.itemId)) : [],
                    ]

                    groupedErpInfo.push(queryKeyWithErpInformationItem)
                })

            groupedErpInfo.forEach((value) => {
                const [queryKey, items] = value

                if (!items) {
                    return
                }

                if (!items.length) {
                    queryClient.removeQueries(queryKey)
                    return
                }

                queryClient.setQueryData<GetErpInformationBasketResponse | undefined>(queryKey, (prev) => {
                    if (!prev?.items) {
                        return
                    }

                    return {
                        ...prev,
                        items: items.splice(0, pageSize),
                    }
                })
            })

            return partsToBeDeleted
        },
        [queryClient, workTaskBasketQueryKey, workTaskId]
    )

    return removeItemsFromQueries
}
