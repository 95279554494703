import { PropsWithChildren, useEffect } from "react"
import { useModuleNavigation } from "@tm/context-distribution"
import { Box, styled } from "@tm/components"
import { useParams } from "react-router"
import { MatchParams } from "@bundles/tyres/data/models"
import List from "../list/component"
import ModuleManager from "../moduleManager/component"
import Navigation from "../navigation/component"
import Summary from "../summary/component"

type Props = {
    repairTimesRoute: string
    detailsModalRoute?: string
    route: string
    vehicleSelectionRoute: string
}

function WrappedModule({ children }: PropsWithChildren<{}>) {
    const { showTab } = useModuleNavigation()

    useEffect(() => {
        showTab({
            icon: "tire",
            title: "{{240}}",
        })
    }, [showTab])

    return (
        <Box flex="1" display="flex">
            {children}
        </Box>
    )
}

function Main({ repairTimesRoute, route, vehicleSelectionRoute, detailsModalRoute }: Props) {
    const matchParams = useParams<MatchParams>()
    return (
        <StyledWrapper>
            <Navigation route={route} />
            <ModuleManager />
            {matchParams.view === "list" && (
                <WrappedModule>
                    <List detailsModalRoute={detailsModalRoute} repairTimesRoute={repairTimesRoute} />
                </WrappedModule>
            )}
            {matchParams.view === "summary" && (
                <WrappedModule>
                    <Summary vehicleSelectionRoute={vehicleSelectionRoute} />
                </WrappedModule>
            )}
        </StyledWrapper>
    )
}

export default Main

const StyledWrapper = styled(Box)({
    flexBasis: "100%",
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
})
