import { getModuleFromUserContext, getParameterFromModule, useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"
import { getBundleParams } from "../../../utils"
import { ShowFeedbackForArticleType } from "../models"

const DEAFULT_ARTICLE_FEEDBACK_PATH =
    "/:workTaskId/modal/^article-feedback/:productGroupId/:supplierId/:supplierName/:supplierArticleNo/:traderArticleNo?"

export type ArticleFeedbackConfiguration = {
    feedbackPath: string
    isEnabled: boolean
    showFeedbackAsButton: boolean
    showFeedbackForArticleType: ShowFeedbackForArticleType
    openInArticleDetails?: boolean
}

export function useArticleFeedbackConfiguration(): ArticleFeedbackConfiguration {
    const { userContext } = useUser()
    const { showArticleFeedbackAsButton, articleFeedbackDisplayBehavior, articleFeedbackPath, enableMVCFeedbackOnDetails } = getBundleParams()

    const feedbackModule = getModuleFromUserContext(userContext, UserModuleType.Feedback)
    const isExternalFeedback = !enableMVCFeedbackOnDetails && !!articleFeedbackPath

    let openInArticleDetails: boolean = false
    let showFeedbackForArticleType: ShowFeedbackForArticleType = 0

    if (isExternalFeedback) {
        showFeedbackForArticleType = getShowFeedbackForArticleType(articleFeedbackDisplayBehavior)
    } else if (feedbackModule) {
        const parameterValue = getParameterFromModule(feedbackModule, "DisplayBehavior")
        showFeedbackForArticleType = getShowFeedbackForArticleType(parameterValue)
        openInArticleDetails = getParameterFromModule(feedbackModule, "Target") == "4"
    }

    return {
        isEnabled: !!feedbackModule || isExternalFeedback,
        showFeedbackAsButton: showArticleFeedbackAsButton ?? false,
        showFeedbackForArticleType,
        feedbackPath: articleFeedbackPath ?? DEAFULT_ARTICLE_FEEDBACK_PATH,
        openInArticleDetails,
    }
}

function getShowFeedbackForArticleType(parameterValue: string | undefined): ShowFeedbackForArticleType {
    switch (parameterValue) {
        case "500":
            return ShowFeedbackForArticleType.AllArticles
        case "501":
        case "only-trader-articles":
            return ShowFeedbackForArticleType.OnlyTraderArticles
        case "502":
            return ShowFeedbackForArticleType.ArticlesWithoutTraderReference
        default:
            return ShowFeedbackForArticleType.AllArticles
    }
}
