import { equals } from "@tm/utils"

export function addOrRemoveItem<T>(array: T[] = [], object?: T, comparator?: (a: T, b: T) => boolean): T[] {
    if (!object) {
        return array
    }

    const comp = comparator ?? equals

    const isInArray = array.some((x) => comp(x, object))

    if (isInArray) {
        // eslint-disable-next-line no-param-reassign
        array = array.filter((x) => !comp(x, object))
    } else {
        array.push(object)
    }

    return [...array]
}
