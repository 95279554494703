import { IBundle } from "@tm/morpheus"
import WidgetStart from "./components/widget-start"
import Chats from "./components/chats"
import NewAlerts from "./components/new-alerts"
import SendCostEstimationButton from "./micros/send-cost-estimation-button"
import CrmButton from "./micros/crm-button"
import { initNotificationsBundle, version } from "./utils"
import NotificationCenter from "./components/notification-center"
import NotificationPanel from "./components/notification-panel"
import { useIdStorageByWorkTaskLoadable, useSendChatMessageLoadable } from "./data/hooks"

const bundle: IBundle = {
    ...version,
    components: [WidgetStart, Chats, NewAlerts, NotificationPanel, NotificationCenter],
    micros: [SendCostEstimationButton, CrmButton],
    init: initNotificationsBundle,
}

export default bundle

export { useSendChatMessageLoadable, useIdStorageByWorkTaskLoadable }
