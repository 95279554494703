import { useCallback } from "react"
import { AmountItem } from "@tm/controls"
import { Article } from "@tm/models"
import { batch, useDispatch, useSelector } from "react-redux"
import { WheelSelectionSteps } from "../../../data/enums"
import { MainActions } from "../../main/business"
import { Actions } from "../business"
import OverviewItem from "./overviewItem"
import { MainState } from "../../main"
import type { OverviewComponentProps } from "../component"

export function OverviewList({ repairTimesRoute, detailsModalRoute }: OverviewComponentProps) {
    const dispatch = useDispatch()

    const { rimItem, tireItem, sensorItem } = useSelector((s: MainState) => ({
        rimItem: s.overview.rimItem,
        tireItem: s.overview.tireItem,
        sensorItem: s.overview.sensorItem,
    }))

    const handleRimDelete = useCallback((item: Article) => {
        if (rimItem?.id != item.id && rimItem) {
            console.error("can not delete rim item")
            return
        }

        if (!rimItem) {
            return
        }

        batch(() => {
            // TODO: RESET HISTORY NAV
            dispatch(Actions.updateRimsItems(undefined))
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.WHEELSLIST, disableNextSteps: true }, false))
        })
    }, [])

    const handleTireDelete = useCallback((item: Article) => {
        if (tireItem?.internalId != item.internalId) {
            console.error("can not delete tire item")
            return
        }

        if (!tireItem) {
            return
        }

        dispatch(Actions.updateTiresItems(undefined))
    }, [])

    const handleSensorDelete = useCallback((item: Article) => {
        if (sensorItem?.internalId != item.internalId) {
            console.error("can not delete sensor item")
            return
        }

        if (!sensorItem) {
            return
        }

        dispatch(Actions.updateSensorItems(undefined))
    }, [])

    const handleRimQuanityChange = useCallback((item: Article, amountItem: AmountItem) => {
        if (!rimItem) {
            return
        }

        const rim = { ...rimItem, ...(item.internalId == rimItem.internalId && { quantity: amountItem.value }) }
        dispatch(Actions.updateRimsItems(rim))
    }, [])

    const handleTireQuantityChange = useCallback((item: Article, amountItem: AmountItem) => {
        if (!tireItem) {
            return
        }

        const tire = { ...tireItem, ...(item.internalId == tireItem.internalId && { quantity: amountItem.value }) }
        dispatch(Actions.updateTiresItems(tire))
    }, [])

    const handleSensorQuantityChange = useCallback((item: Article, amountItem: AmountItem) => {
        if (!sensorItem) {
            return
        }

        const sensor = { ...sensorItem, ...(item.internalId == sensorItem.internalId && { quantity: amountItem.value }) }
        dispatch(Actions.updateSensorItems(sensor))
    }, [])

    return (
        <div className="tk-parts">
            <div className="article-list">
                {rimItem && (
                    <OverviewItem
                        repairTimesRoute={repairTimesRoute}
                        key={rimItem.internalId}
                        item={rimItem}
                        onDeleteClick={handleRimDelete}
                        onQuantityChange={handleRimQuanityChange}
                        detailsModalRoute={detailsModalRoute}
                    />
                )}
            </div>

            <div className="article-list">
                {sensorItem && (
                    <OverviewItem
                        key={sensorItem.internalId}
                        item={sensorItem}
                        repairTimesRoute={repairTimesRoute}
                        onDeleteClick={handleSensorDelete}
                        onQuantityChange={handleSensorQuantityChange}
                        detailsModalRoute={detailsModalRoute}
                    />
                )}
            </div>

            <div className="article-list">
                {tireItem && (
                    <OverviewItem
                        key={tireItem.internalId}
                        item={tireItem}
                        repairTimesRoute={repairTimesRoute}
                        onDeleteClick={handleTireDelete}
                        onQuantityChange={handleTireQuantityChange}
                        detailsModalRoute={detailsModalRoute}
                    />
                )}
            </div>
        </div>
    )
}
