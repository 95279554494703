import { Stack, Typography, styled } from "@tm/components"
import { StandardTyreSize } from "@tm/models"

const StyledLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    padding: ".3em",
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: "3px",
    cursor: "pointer",
    fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
    lineHeight: "1",
}))

type Props = {
    tyreSizes?: StandardTyreSize[]
    direction?: "row" | "column"
    onSizeClick(size: string): void
}
export function StandardTyreSizes({ direction, tyreSizes, onSizeClick }: Props) {
    if (!tyreSizes) {
        return null
    }

    return (
        <Stack direction={direction ?? "row"} gap={1} alignItems="center">
            {tyreSizes.map((size) => (
                <StyledLabel key={size.id} variant="body2" onClick={() => onSizeClick(size.description)}>
                    {size.description}
                </StyledLabel>
            ))}
        </Stack>
    )
}
