import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { Stack, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useRecoilState } from "recoil"
import { ListFilter } from "@tm/models"
import { AccordionDetailsSmall, AccordionHeadline, AccordionSimple, AccordionSummaryMore } from "./FiltersStyledComponents"
import { FiltersArea } from "./FiltersArea"
import { FilterTypeVisibilitiesState } from "../../states"
import { useListType } from "./useListType"
import { ListFilterGroup } from "../../models"
import IconWithTooltip from "../../../_shared/icon-with-tooltip/IconWithTooltip"

type GroupedAttributeFiltersAreaProps = {
    headline: string
    data: ListFilterGroup[]
    onChange: (filter: ListFilter) => void
    onResetFilters?: () => void
    hideFilterSearch?: boolean
}

export type FiltersAreaActions = {
    expand(): void
    collapse(): void
}

export const SubFiltersArea = styled(FiltersArea)({
    ".MuiAccordionDetails-root": {
        paddingLeft: 0,
        ".MuiAccordionDetails-root": {
            paddingLeft: 0,
        },
    },
})

const filterVisibilitiesType = "groupedAttributes"

export const GroupedAttributeFiltersArea = memo(
    forwardRef<FiltersAreaActions, GroupedAttributeFiltersAreaProps>(function FiltersAreaComponent(props, ref) {
        const listType = useListType()
        const { translateText } = useLocalization()
        const { data, headline, onResetFilters, hideFilterSearch, onChange } = props

        const [expanded, setExpanded] = useState(false)
        const [showInput, setShowInput] = useState(false)
        const textFieldRef = useRef<HTMLInputElement>()

        const [visibilities, setVisibilities] = useRecoilState(FilterTypeVisibilitiesState(listType))
        const isAccordionExpanded = useMemo(() => visibilities[filterVisibilitiesType] ?? true, [visibilities])

        const dataGroups = useMemo(
            () => ({
                display: data.filter((group) => group.priority === 1).map((filter) => mapListFilterGroup(filter, true)),
                more: data.filter((group) => group.priority !== 1).map((filter) => mapListFilterGroup(filter, false)),
            }),
            [data]
        )

        useEffect(() => {
            if (filterVisibilitiesType) {
                setVisibilities((prev) => {
                    const currentState = prev[filterVisibilitiesType] ?? true
                    return { ...prev, [filterVisibilitiesType]: currentState }
                })
            }
        }, [filterVisibilitiesType])

        useEffect(() => {
            if (textFieldRef.current && showInput) {
                textFieldRef.current.focus()
            }
        }, [showInput, textFieldRef])

        useImperativeHandle(
            ref,
            () => ({
                expand() {
                    setExpanded(true)
                },
                collapse() {
                    setExpanded(false)
                },
            }),
            []
        )

        useEffect(() => {
            if (textFieldRef.current && showInput) {
                textFieldRef.current.focus()
            }
        }, [showInput, textFieldRef])

        const handleResetFilters = (event: React.MouseEvent<SVGSVGElement>) => {
            event.stopPropagation()
            onResetFilters?.()
        }

        const handleFilterCategory = (event: React.MouseEvent<SVGSVGElement>) => {
            event.stopPropagation()
            setShowInput(!showInput)
        }

        const handleChangeExpansion = useCallback(
            (_: unknown, expand: boolean) => {
                setVisibilities((prev) => {
                    const currentState = prev[filterVisibilitiesType] ?? true
                    if (expand !== currentState) {
                        return { ...prev, [filterVisibilitiesType]: expand }
                    }
                    return prev
                })
            },
            [setVisibilities]
        )

        return (
            <AccordionSimple expanded={isAccordionExpanded} onChange={handleChangeExpansion}>
                <AccordionHeadline sx={{ display: "flex" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                        <Typography ml={1}>{headline}</Typography>
                        <Stack direction="row" spacing={1}>
                            {!hideFilterSearch && <IconWithTooltip variant="search" onClick={handleFilterCategory} />}
                            {!!onResetFilters && <IconWithTooltip variant="remove-filter" onClick={handleResetFilters} />}
                        </Stack>
                    </Stack>
                </AccordionHeadline>
                <AccordionDetailsSmall>
                    {dataGroups.display.map(({ key, headline, data }) => (
                        <SubFiltersArea
                            key={key}
                            data={data}
                            headline={headline}
                            onChange={onChange}
                            hideActions
                            hideAbbreviation
                            expandWhenChildIsSelected
                        />
                    ))}
                    {dataGroups.more.length > 0 && (
                        <AccordionSimple expanded={expanded} onChange={(_, state: boolean) => setExpanded(state)}>
                            <AccordionSummaryMore id={`${headline}_more`}>
                                <Typography variant="label">{translateText(expanded ? 877 : 876)}</Typography>
                            </AccordionSummaryMore>
                            <AccordionDetailsSmall>
                                {dataGroups.more.map(({ key, headline, data }) => (
                                    <SubFiltersArea
                                        key={key}
                                        data={data}
                                        headline={headline}
                                        onChange={onChange}
                                        hideActions
                                        hideAbbreviation
                                        startCollapsed
                                    />
                                ))}
                            </AccordionDetailsSmall>
                        </AccordionSimple>
                    )}
                </AccordionDetailsSmall>
            </AccordionSimple>
        )
    })
)

function mapListFilterGroup({ key, description, filters: attributeFilters }: ListFilterGroup, withPriority: boolean) {
    if (withPriority) {
        return {
            key,
            headline: description,
            data: {
                display: attributeFilters.filter((x) => x[0].priority === 1),
                more: attributeFilters.filter((x) => !x[0].priority),
            },
        }
    }
    return {
        key,
        headline: description,
        data: {
            display: attributeFilters,
            more: [],
        },
    }
}
